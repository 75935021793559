<template>
    <div class="SicboStat">
        <h5>สถิติการเล่น</h5>
        <div class="content-his">
            <div v-show="stat.game_result" v-for="stat of statArrReverse" :key="stat.gbs_id" class="row-content-his">
                <ul class="state-user">
                    <li>
                        {{stat.gbs_id}} #{{stat.round_main}}/{{stat.round_sub}}
                    </li>
                    <li :class="classComputed(stat.game_result)">
                        {{resultDisplay(stat.game_result)}}
                    </li>
                    <li style="color: white;">{{calDicePoint(stat)}}</li>
                </ul>
                <div class="box-dices">
                    <div class="list-dice">
                        <span v-for="(i, key) of getDice(stat)" :key="key" :class="`dice dice-${i}`"></span>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>
<script>

    import {GAME_RESULT_MAP} from './Constant'

    const classMap = {
        0: '',
        1: 'result-white',
        2: 'result-green',
        3: 'result-yellow',
        4: '',
        5: 'result-red',
    }

    export default {
        name: 'SicboStat',
        props: [
            'statArr',
        ],
        computed: {
            statArrReverse() {
                return [...this.statArr].reverse()
            },
        },
        methods: {
            resultDisplay(raw) {
                if (!raw) {
                    return ''
                }
                return GAME_RESULT_MAP[raw]['title']
            },
            classComputed(raw) {
                if (!raw) {
                    return ''
                }
                return classMap[raw]
            },
            getDice(stat) {
                if (!stat) {
                    return []
                }
                if (!stat.game_info) {
                    return []
                }
                return stat.game_info.dice
            },
            calDicePoint(stat) {

                const dice = this.getDice(stat)

                if (dice.length === 0) {
                    return ''
                }
                const total = dice.reduce((sum, item) => {
                    return parseInt(sum) + parseInt(item)
                }, 0)
                const calculated = total % 4
                return (calculated === 0) ? 4 : calculated
            },
        }
    }
</script>
<style scoped>
    .SicboStat {
        background-color: rgba(0, 0, 0, 0.85);
        height: 100vh;
        overflow-y: auto;
        color: white;
        padding: 4px 10px;
        font-family: "Athiti", sans-serif;
    }

    .SicboStat h5 {
        font-weight: bold !important;
        font-size: 1em !important;
        border-bottom: solid 1px #5a5353 !important;
        width: 100% !important;
        padding: 5px !important;
    }
    .state-user li:first-child{margin-right: 10px;}
    .row-content-his {
        justify-content: space-between;
        padding: 2px;
    }

    .row-content-his li:first-child {
        width: auto !important;
    }

    .result-white {
        color: white;
    }

    .result-green {
        color: #28a745;
    }

    .result-yellow {
        color: #fab600;
    }

    .result-red {
        color: #c52828;
    }
    @media only screen
        /*and (max-device-width: 1300px)*/
    and (orientation: landscape) {
        /*.SicboStat {*/
        /*    position: absolute;*/
        /*    width: 250px;*/
        /*    margin: auto;*/
        /*    right: 0;*/
        /*    left: 0;*/
        /*}*/
    }
</style>