<template>
    <div v-if="provider" :class="`GamelistGameList ${provider.wrapperClass}`">
        <div v-if="provider" class="GameList-box-title">
            <span class="close-GameList-mainGame" @click="closeGameListModal()"></span>
            <h4><img :src="getProviderIcon(provider)"/>{{provider.label}}</h4>
        </div>
        <div @scroll="onScrollHandle" ref="contentWrapper" class="GameList-mainGame-content">
            <div class="GameList-box-title-bg">
                <div v-if="popularList.length > 0" class="GameList-box GameList-box-popular">
                    <div class="GameList-box-title">
                        <h4>
    <!--                        <img src="@/assets/images/hot.gif"/>-->
                            <i class="fas fa-fire"></i> ยอดนิยม</h4>
                    </div>
                    <div class="flexListgame">
                        <carousel
                                :per-page="provider.perPage"
                                :mouseDrag="true"
                                :navigationEnabled="true"
                                :paginationEnabled="false"
                                navigationNextLabel="❯"
                                navigationPrevLabel="❮"
                                paginationPosition="bottom">
                            <slide v-for="(i, key) of popularIndexList" :key="key">
                                <div v-if="popularList[i]" @click="play(popularList[i])" class="box-list-games" >
                                    <div class="box-icon-games">
                                        <el-image :src="getGameImage(popularList[i])">
                                            <el-image slot="error" :src="popularList[i].Image1">
                                                <div class="noneImageSlot" slot="error"><i class="fas fa-image"></i></div>
                                            </el-image>
                                        </el-image>
                                    </div>
                                    <div class="box-detail-games">
                                        <h4>{{popularList[i].Name}}</h4>
                                    </div>
                                </div>
                                <div v-if="popularList[i+1]" @click="play(popularList[i+1])" class="box-list-games" >
                                    <div class="box-icon-games">
                                        <el-image :src="getGameImage(popularList[i+1])">
                                            <el-image slot="error" :src="popularList[i+1].Image1">
                                                <div class="noneImageSlot" slot="error"><i class="fas fa-image"></i></div>
                                            </el-image>
                                        </el-image>
                                    </div>
                                    <div class="box-detail-games">
                                        <h4>{{popularList[i+1].Name}}</h4>
                                    </div>
                                </div>
                            </slide>
                        </carousel>
                    </div>
                </div>
                <div v-if="recommendList.length > 0" class="GameList-box GameList-box-recommend">
                    <div class="GameList-box-title">
                        <h4>
    <!--                        <img style="height: 20px;" src="@/assets/images/fav.png"/>-->
                            <i class="fas fa-star"></i> แนะนำ</h4>
                    </div>
                    <div class="flexListgame">
                        <carousel
                                :per-page="provider.perPage"
                                :mouseDrag="true"
                                :navigationEnabled="true"
                                :paginationEnabled="false"
                                navigationNextLabel="❯"
                                navigationPrevLabel="❮"
                                paginationPosition="bottom">
                            <slide v-for="(game, key) of recommendList" :key="key">
                                <div @click="play(game)" class="box-list-games" >
                                    <div class="box-icon-games">
                                        <el-image :src="getGameImage(game)">
                                            <el-image slot="error" :src="game.Image1">
                                                <div class="noneImageSlot" slot="error"><i class="fas fa-image"></i></div>
                                            </el-image>
                                        </el-image>
                                    </div>
                                    <div class="box-detail-games">
                                        <h4>{{game.Name}}</h4>
                                    </div>
                                </div>
                            </slide>
                        </carousel>
                    </div>
                </div>
            </div>
            <div class="GameList-box-content">
    <!--            <h3>เกมทั้งหมด</h3>-->
                <div class="box-sublist-area-topTap" style="height: 12px;">
                    <!-- <carousel
                            :mouseDrag="true"
                            :navigationEnabled="true"
                            :paginationEnabled="false"
                            navigationNextLabel="❯"
                            navigationPrevLabel="❮"
                            paginationPosition="bottom"
                    >
                        <slide><span @click="selectTag('all')" :class="{'active': currentTag === 'all'}">All</span></slide>
                        <slide v-for="(tag, key) of tagList" :key="key">
                            <span @click="selectTag(tag)" :class="{'active': currentTag === tag}">{{tag}}</span>
                        </slide>
                    </carousel> -->
                </div>
                <div class="flexListgame flexListgame-all">
                    <div @click="play(game)" v-for="(game, key) of gameList" :key="key" class="box-list-games" >
                        <div class="box-icon-games">
                            <el-image :src="getGameImage(game)">
                                <el-image slot="error" :src="game.Image1">
                                    <div class="noneImageSlot" slot="error"><i class="fas fa-image"></i></div>
                                </el-image>
                            </el-image>
                        </div>
                        <div class="box-detail-games">
                            <h4>{{game.Name}}</h4>
                        </div>
                    </div>
                </div>
                <div class="load-more-section">
                    <Loading v-if="isOnLoad" mode="spinner"></Loading>
                    <button v-else-if="isCanLoadMore" @click="loadMore" type="button" class="btn btnLoadMore"></button>
                </div>
            </div>

        </div>
    </div>
</template>
<script>

import { Carousel, Slide } from 'vue-carousel'
import axiosWrapper from '@/axiosWrapper'
import Loading from '@/components/Loading'
import GameListMixin from './GameListMixin'
import { ALL_PROVIDER_LIST } from './Constant'
import store from '@/store'
import localStorageCache from '@/lib/localStorageCache'
const AT_BOTTOM_OFFSET = 600

export default {
    name: 'GamelistGameList',
    mixins: [
        GameListMixin,
    ],
    components: {
        Carousel,
        Slide,
        Loading,
    },
    props: [
        'provider',
        'cat',
        'perPage',
    ],
    data() {
        return {
            page: 1,
            itemsPerPage: 15,
            gameList: [],
            popularList: [],
            recommendList: [],
            isOnLoad: false,
            currentTag: 'all',
            tagList: [
                'Progressive',
                'Video Slots',
                'Scratch card',
                'Baccarat',
                'Blackjack',
                'Baccarat New',
                'Classic Slots',
                'Keno',
                'Roulette',
                'Video Poker',
            ],
            isCanLoadMore: false,
        }
    },
    computed: {
        popularIndexList() {
            const arr = []
            for(let i = 0;i < this.popularList.length;i+=2) {
                arr.push(i)
            } 
            return arr
        },
        menuBarNavigation: {
            get() {
                return store.state.menuBarNavigation
            },
            set(value) {
                store.commit('updateMenuBarNavigation', value)
            }
        },
    },
    watch: {
        async currentTag(newVal) {
            if(newVal) {
                this.load()
            }
        },
    },
    methods: {
        async getGameList() {

            this.isOnLoad = true

            const provider = this.provider.slug
            const cat = this.cat
            const page = this.page
            const itemsPerPage = this.itemsPerPage

            const localStorageCacheKey = `GameList;${provider};${cat};${page};${itemsPerPage}`

            // const dataFromCache = localStorageCache.get(localStorageCacheKey)

            // if(dataFromCache) {
            //     this.isOnLoad = false
            //     this.isCanLoadMore = true
            //     return dataFromCache
            // }

            const res = await axiosWrapper({
                method: 'post',
                url: `${this.envDomains.VUE_APP_API}/api/gamelist-api/game`,
                data: {
                    page: page,
                    itemsPerPage: itemsPerPage,
                    provider: provider,
                    cat: cat,
                }
            })

            this.isOnLoad = false

            if(res.status !== 200) {
                this.$bvToast.toast(res.data.message, {
                    title: 'แจ้งเตือน',
                    toaster: 'b-toaster-top-full'
                })
                return null
            }
            this.isCanLoadMore = !!res.data.next

            if(res.data.items.length > 0) {
                localStorageCache.set(localStorageCacheKey, res.data.items, 1)
            }
            return res.data.items
        },
        async load() {
            this.gameList = []
            this.page = 1
            const gameList = await this.getGameList()
            if(gameList) {
                this.gameList = gameList
            }
        },
        async loadMore() {
            if(this.isOnLoad || !this.isCanLoadMore) {
                return
            }
            this.page++
            const gameList = await this.getGameList()
            if(gameList) {
                this.gameList = this.gameList.concat(gameList)
            }
        },
        async getHot() {

            this.isOnLoad = true

            const provider = this.provider.slug
            const cat = this.cat
            const page = 1
            const itemsPerPage = 8
            const tag = 'HOT'

            const localStorageCacheKey = `GameList;${provider};${cat};${page};${itemsPerPage};${tag}`

            // const dataFromCache = localStorageCache.get(localStorageCacheKey)

            // if(dataFromCache) {
            //     this.isOnLoad = false
            //     this.popularList = dataFromCache
            //     return
            // }

            const res = await axiosWrapper({
                method: 'post',
                url: `${this.envDomains.VUE_APP_API}/api/gamelist-api/game`,
                data: {
                    page: page,
                    itemsPerPage: itemsPerPage,
                    provider: provider,
                    cat: cat,
                    tag: tag,
                }
            })

            this.isOnLoad = false

            if(res.status === 200) {
                this.popularList = res.data.items
                if(res.data.items.length > 0) {
                    localStorageCache.set(localStorageCacheKey, res.data.items, 1)
                }
            }
        },
        async getRecommend() {

            this.isOnLoad = true

            const provider = this.provider.slug
            const cat = this.cat
            const page = 1
            const itemsPerPage = 4
            const tag = 'RECOMMEND'

            const localStorageCacheKey = `GameList;${provider};${cat};${page};${itemsPerPage};${tag}`

            // const dataFromCache = localStorageCache.get(localStorageCacheKey)

            // if(dataFromCache) {
            //     this.isOnLoad = false
            //     this.recommendList = dataFromCache
            //     return
            // }

            const res = await axiosWrapper({
                method: 'post',
                url: `${this.envDomains.VUE_APP_API}/api/gamelist-api/game`,
                data: {
                    page: page,
                    itemsPerPage: itemsPerPage,
                    provider: provider,
                    cat: cat,
                    tag: tag,
                }
            })

            this.isOnLoad = false

            if(res.status === 200) {
                this.recommendList = res.data.items
                if(res.data.items.length > 0) {
                    localStorageCache.set(localStorageCacheKey, res.data.items, 1)
                }
            }
        },
        onScrollHandle() {
            const contentWrapper = this.$refs.contentWrapper
            if((window.innerHeight + contentWrapper.scrollTop + AT_BOTTOM_OFFSET) >= contentWrapper.scrollHeight) {
                this.loadMore()
            }
        },
        selectTag(tag) {
            this.currentTag = tag
        },
        closeGameListModal() {
            this.menuBarNavigation.provider = null
            this.$emit('close')
        },
        getProviderIcon(provider) {
            const providerInfo = ALL_PROVIDER_LIST.find((item) => {
                return item.slug === provider.slug
            })
            if(!providerInfo) {
                return ''
            }
            return providerInfo.image
        },
        getGameImage(game) {
            return `${this.envDomains.VUE_APP_API}/images/gameSlot/${game.ApiName}_${game.GameCode}.png`
        },
    },
    mounted() {
        this.menuBarNavigation.provider = this.provider
        this.getHot()
        this.getRecommend()
        this.load()
    }
}
</script>
<style scoped>
.load-more-section {
    display: flex;
    justify-content: center;
    width: 100%;
}
</style>