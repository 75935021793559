<template>
    <div v-if="list && list.length > 0" class="GameTopPlayer box-top-user">
        <div class="box-top-user-logo">
            <div class="f3 blink delay-x1" style="top: -37px; left: -117px; "></div>
            <div class="f3 blink delay-x15" style="top: -15px; left: -77px;"></div>
            <div class="f3 blink delay-x1" style="top: -32px; right: 0;  "></div>
            <div class="f3 blink delay-x15" style="top: -11px; right: 20px;"></div>
        </div>
        <div class="box-top-user-flex">
            <div class="box-slide-show">
                <PlayerPlayList
                :list="list"
                :bet_list="bet_list"
                :tableType="tableType"></PlayerPlayList>
            </div>
        </div>
    </div>
</template>
<script>

import PlayerPlayList from './PlayerPlayList'

export default {
    name: 'GameTopPlayer',
    components: {
        PlayerPlayList,
    },
    props: [
        'list',
        'bet_list',
        'tableType',
    ],
}
</script>