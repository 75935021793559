import axiosWrapper from '@/axiosWrapper'
import sort_wrapper from './sort_wrapper'

const getReward = async function(lotto_type){

    try{
        const progress_res = await axiosWrapper({
            method: 'POST',
            url: `${this.envDomains.VUE_APP_LOTTO_API}/api/price/load_current`,
            data: {
                lotto_type: lotto_type
            }
        });
        if (progress_res.status === 200) {
            return progress_res.data;
        } else {
            return null;
        }
    }catch(err){
        return null;
    }
}
const getDynamicReward = async function(lotto_type){

    try{
        const progress_res = await axiosWrapper({
            method: 'POST',
            url: `${this.envDomains.VUE_APP_LOTTO_API}/api/price/load_dynamic`,
            data: {
                lotto_type: lotto_type
            }
        });
        if(progress_res.status === 200) {
            return {
                price_dynamic_arr: progress_res.data.data_arr,
                price_dynamic_arr_3: progress_res.data.data_arr_3
            }
        }else{
            return null;
        }
    }catch(err){
        return null;
    }
}

const getMaxReward = (reward_arr) => {
    try{
        return sort_wrapper([...reward_arr], 'reward', true)[0].reward;
    }catch(err){
        return 0;
    }
}

export default {
    getReward,
    getDynamicReward,
    getMaxReward,
}