import {Howl} from 'howler'

export const SOUND = {
    RESULT: {
        1: new Howl({src: ['/sounds/game/dragon-tiger/result/dragon_th.mp3'], preload: false}),
        2: new Howl({src: ['/sounds/game/dragon-tiger/result/tiger_th.mp3'], preload: false}),
        3: new Howl({src: ['/sounds/game/dragon-tiger/result/tie_th.mp3'], preload: false}),
    },
    POINT: {
        DRAGON: {
            1: new Howl({src: ['/sounds/game/dragon-tiger/point/dragon/dragon_has_1_th.mp3'], preload: false}),
            2: new Howl({src: ['/sounds/game/dragon-tiger/point/dragon/dragon_has_2_th.mp3'], preload: false}),
            3: new Howl({src: ['/sounds/game/dragon-tiger/point/dragon/dragon_has_3_th.mp3'], preload: false}),
            4: new Howl({src: ['/sounds/game/dragon-tiger/point/dragon/dragon_has_4_th.mp3'], preload: false}),
            5: new Howl({src: ['/sounds/game/dragon-tiger/point/dragon/dragon_has_5_th.mp3'], preload: false}),
            6: new Howl({src: ['/sounds/game/dragon-tiger/point/dragon/dragon_has_6_th.mp3'], preload: false}),
            7: new Howl({src: ['/sounds/game/dragon-tiger/point/dragon/dragon_has_7_th.mp3'], preload: false}),
            8: new Howl({src: ['/sounds/game/dragon-tiger/point/dragon/dragon_has_8_th.mp3'], preload: false}),
            9: new Howl({src: ['/sounds/game/dragon-tiger/point/dragon/dragon_has_9_th.mp3'], preload: false}),
            10: new Howl({src: ['/sounds/game/dragon-tiger/point/dragon/dragon_has_10_th.mp3'], preload: false}),
            11: new Howl({src: ['/sounds/game/dragon-tiger/point/dragon/dragon_has_11_th.mp3'], preload: false}),
            12: new Howl({src: ['/sounds/game/dragon-tiger/point/dragon/dragon_has_12_th.mp3'], preload: false}),
            13: new Howl({src: ['/sounds/game/dragon-tiger/point/dragon/dragon_has_13_th.mp3'], preload: false}),
        },
        TIGER: {
            1: new Howl({src: ['/sounds/game/dragon-tiger/point/tiger/tiger_has_1_th.mp3'], preload: false}),
            2: new Howl({src: ['/sounds/game/dragon-tiger/point/tiger/tiger_has_2_th.mp3'], preload: false}),
            3: new Howl({src: ['/sounds/game/dragon-tiger/point/tiger/tiger_has_3_th.mp3'], preload: false}),
            4: new Howl({src: ['/sounds/game/dragon-tiger/point/tiger/tiger_has_4_th.mp3'], preload: false}),
            5: new Howl({src: ['/sounds/game/dragon-tiger/point/tiger/tiger_has_5_th.mp3'], preload: false}),
            6: new Howl({src: ['/sounds/game/dragon-tiger/point/tiger/tiger_has_6_th.mp3'], preload: false}),
            7: new Howl({src: ['/sounds/game/dragon-tiger/point/tiger/tiger_has_7_th.mp3'], preload: false}),
            8: new Howl({src: ['/sounds/game/dragon-tiger/point/tiger/tiger_has_8_th.mp3'], preload: false}),
            9: new Howl({src: ['/sounds/game/dragon-tiger/point/tiger/tiger_has_9_th.mp3'], preload: false}),
            10: new Howl({src: ['/sounds/game/dragon-tiger/point/tiger/tiger_has_10_th.mp3'], preload: false}),
            11: new Howl({src: ['/sounds/game/dragon-tiger/point/tiger/tiger_has_11_th.mp3'], preload: false}),
            12: new Howl({src: ['/sounds/game/dragon-tiger/point/tiger/tiger_has_12_th.mp3'], preload: false}),
            13: new Howl({src: ['/sounds/game/dragon-tiger/point/tiger/tiger_has_13_th.mp3'], preload: false}),
        }
    }
}

export const soundLoad = () => {
    for(const key in SOUND['RESULT']) {
        SOUND['RESULT'][key].load()
    }
    for(const key in SOUND['POINT']['DRAGON']) {
        SOUND['POINT']['DRAGON'][key].load()
    }
    for(const key in SOUND['POINT']['TIGER']) {
        SOUND['POINT']['TIGER'][key].load()
    }
}