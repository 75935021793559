<template>
    <div ref="richMessageItem" class="rich-message-item custom" :class="{'extend': isShowGrid}">
        <div @click="openLink(0)" class="rich-message-box" :class="{'show-grid': isShowGrid}" 
        style="width: 100%; height: 100%; border: none;">
            <span class="rich-message-box-label">A</span>
        </div>
    </div>
</template>
<script>
import RichMessagetTemplateMixin from './mixins'
export default {
    name: 'RichMessageTemplateType0',
    mixins: [
        RichMessagetTemplateMixin,
    ],
}
</script>
<style scoped src="./style.css"></style>