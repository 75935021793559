<template>
    <div class="GameTicketList">
        <h5>ประวัติการเล่น
            <small>({{ticketList.length}})</small>
        </h5>
        <div class="wrapper-box-ticket">
            <div class="ticket-row ticket-row-top">
                <div class="ticket-row row-th">
                    เวลา รอบ
                </div>
                <div class="ticket-row row-th">
                    จำนวนเงิน
                </div>
                <div class="ticket-row row-th">
                    เล่น
                </div>
                <div class="ticket-row row-th" style="width: 180px;">
                    ผลของเกมส์
                </div>
                <div class="ticket-row row-th">
                    ได้เสีย
                </div>
            </div>
            <div
                    v-for="(ticket, key) of ticketList" :key="key"
                    class="ticket-row">
                <div class="ticket-col">
                    <div class="ticket-result-detail left">
                        <div>
                            {{ticket.create_datetime | ticketDateTime}} 
                        </div>
                        <div>
                            {{ticket.bet_list.gbs_id}}
                            #{{ticket.bet_list.round_main}}/{{ticket.bet_list.round_sub}}
                        </div>
                    </div>
                </div>
                <div class="ticket-col">
                    {{ticket.cost | numberFormat}}
                </div>
                <div class="ticket-col">
                    {{userChooseDisplay(ticket)}}
                </div>
                <div :class="`ticket-col ${calGameResultClass(ticket)}`" style="width: 180px;">
                    <div class="ticket-result-detail">
                        <div>
                            <span v-if="ticket.game === 'sicbo' || ticket.game === 'bean'">
                                {{calDicePoint(ticket)}}
                            </span> 
                            <span>
                                {{gameResultDisplay(ticket)}}
                            </span>
                        </div>
                        <div v-if="ticket.game === 'sicbo' || ticket.game === 'bean'" class="box-dices">
                            <div class="list-dice">
                                <span v-for="(i, key) of getDice(ticket)" :key="key" :class="`dice dice-${i}`"></span>
                            </div>
                        </div>
                        <div class="custom-card-list-content" v-if="ticket.game === 'baccarat' || ticket.game === 'baccarat2' || ticket.game === 'baccarat3'" v-show="getCards(ticket)" >
                            <div class="custom-card-list-box">
                                <div class="custom-card-list-title">
                                    ผู้เล่น {{getCardsPoint(ticket, 0)}}
                                </div>
                                <div class="custom-card-list">
                                    <Card :card="getCard(ticket, 0, 2)"></Card>
                                    <Card :card="getCard(ticket, 0, 1)"></Card>
                                    <Card :card="getCard(ticket, 0, 0)"></Card>
                                </div>
                            </div>
                            <div class="custom-card-list-box">
                                <div class="custom-card-list-title">
                                    เจ้ามือ {{getCardsPoint(ticket, 1)}}
                                </div>
                                <div class="custom-card-list">
                                    <Card :card="getCard(ticket, 1, 0)"></Card>
                                    <Card :card="getCard(ticket, 1, 1)"></Card>
                                    <Card :card="getCard(ticket, 1, 2)"></Card>
                                </div>
                            </div>
                        </div>
                        <div class="custom-card-list-content"  v-if="ticket.game === 'dragontiger'" v-show="getCards(ticket)">
                            <div class="custom-card-list-box custom-card-list-box-DT">
                                <div class="custom-card-list-title expand">
                                    <div style="font-size: 9px;">
                                        เสือ {{getCardPointDragonTiger(getCard(ticket, 1, 0))}}
                                    </div>
                                    <div style="font-size: 9px;">
                                        มังกร {{getCardPointDragonTiger(getCard(ticket, 0, 0))}}
                                    </div>
                                </div>
                                <div class="custom-card-list expand">
                                    <Card :card="getCard(ticket, 1, 0)" size="medium"></Card>
                                    <Card :card="getCard(ticket, 0, 0)" size="medium"></Card>
                                </div>
                            </div>
                        </div>
                    </div>

                </div>
                <div :class="`ticket-col ${calProfitLossClass(ticket)}`">
                    <span v-if="ticket.game_result === TICKET_RESULT_KEY.CANCEL">
                        {{ticketResultDisplay(ticket)}}
                    </span>
                    <span v-else>{{ticket.profit_loss | floatFormat}}</span>
                </div>
            </div>
        </div>
    </div>
</template>
<script>

    import moment from 'moment-timezone'

    moment.tz.setDefault('Asia/Bangkok')
    import {
        TABLE_TYPE_KEY,
        GAME_RESULT,
        DRAGON_TIGER_GAME_RESULT,
        USER_CHOOSE_MAP,
        DRAGON_TIGER_USER_CHOOSE_MAP,
        TICKET_RESULT_MAP,
        TICKET_RESULT_KEY,
    } from './Constant'
    import {
        GAME_RESULT as SICBO_GAME_RESULT,
        USER_CHOOSE_MAP as SICBO_USER_CHOOSE_MAP
    } from './sicbo/Constant'
    import {
        GAME_RESULT as FANTAN_GAME_RESULT,
        USER_CHOOSE_MAP as FANTAN_USER_CHOOSE_MAP
    } from './fantan/Constant'

    const USER_CHOOSE_MAP_ARR = [
        USER_CHOOSE_MAP,
        SICBO_USER_CHOOSE_MAP,
        FANTAN_USER_CHOOSE_MAP,
        DRAGON_TIGER_USER_CHOOSE_MAP,
        USER_CHOOSE_MAP,
        USER_CHOOSE_MAP,
    ]

    import Card from './CardStatic'
    const RESULT_MAP = [
        GAME_RESULT,
        SICBO_GAME_RESULT,
        FANTAN_GAME_RESULT,
        DRAGON_TIGER_GAME_RESULT,
        GAME_RESULT,
        GAME_RESULT,
    ]

    const RESULT_CLASS = [
        {
            0: '',
            1: 'text-color-red',
            2: 'text-color-blue',
            3: 'text-color-yellow',
            4: '',
        },
        {
            0: '',
            1: 'text-color-red',
            2: 'text-color-yellow',
            3: 'text-color-green',
            4: '',
        },
        {
            0: '',
            1: 'text-color-white',
            2: 'text-color-green',
            3: 'text-color-yellow',
            4: '',
            5: 'text-color-red',
        },
        {
            0: '',
            1: 'text-color-blue',
            2: 'text-color-red',
            3: 'text-color-yellow',
            5: '',
            4: '',
        },
        {
            0: '',
            1: 'text-color-red',
            2: 'text-color-blue',
            3: 'text-color-yellow',
            4: '',
        },
        {
            0: '',
            1: 'text-color-red',
            2: 'text-color-blue',
            3: 'text-color-yellow',
            4: '',
        },
    ]

    export default {
        name: 'GameTicketList',
        components: {
            Card,
        },
        props: [
            'ticketList',
        ],
        data() {
            return {
                TICKET_RESULT_KEY: null,
            }
        },
        filters: {
            ticketDateTime(raw) {
                return moment(raw).format('MM/DD HH:mm')
            },
        },
        methods: {
            userChooseDisplay(ticket) {

                const user_choose = parseInt(ticket.bet_list.user_choose)
                const userChooseMapIndex = parseInt(TABLE_TYPE_KEY[ticket.game])

                return USER_CHOOSE_MAP_ARR[userChooseMapIndex][user_choose]['TH']
            },
            ticketResultDisplay(ticket) {
                const game_result = parseInt(ticket.game_result)
                return TICKET_RESULT_MAP[game_result]['TH']
            },
            gameResultDisplay(ticket) {
                try {
                    const resultMapIndex = parseInt(TABLE_TYPE_KEY[ticket.game])
                    let game_result = 0
                    if(ticket.bet_list.game_result) {
                        game_result = parseInt(ticket.bet_list.game_result)
                    }
                    return RESULT_MAP[resultMapIndex][game_result]['TH']
                } catch(err) {
                    console.log('TicketList gameResultDisplay err', err.message)
                    return ''
                }
            },
            calTicketResultClass(ticket) {
                const game_result = parseInt(ticket.game_result)
                return TICKET_RESULT_MAP[game_result]['colorClass']
            },
            calGameResultClass(ticket) {
                try {
                    const resultMapIndex = parseInt(TABLE_TYPE_KEY[ticket.game])
                    let game_result = 0
                    if(ticket.bet_list.game_result) {
                        game_result = parseInt(ticket.bet_list.game_result)
                    }
                    return RESULT_CLASS[resultMapIndex][game_result]
                } catch(err) {
                    console.log('TicketList calGameResultClass err', err.message)
                    return ''
                }
            },
            calProfitLossClass(ticket) {
                if (ticket.profit_loss < 0) {
                    return 'text-color-red'
                } else if (ticket.profit_loss > 0) {
                    return 'text-color-green'
                } else {
                    return ''
                }
            },
            getCards(ticket) {
                if(!ticket){
                    return [];
                }
                return ticket.bet_list.game_info.card;
            },
            getCard(ticket, side, index) {
                
                const cards = this.getCards(ticket)

                if(!cards || cards.length === 0) {
                    return null
                }

                const card = cards.find((card) => {
                    return parseInt(card.side) === parseInt(side) && parseInt(card.index) === parseInt(index)
                })

                if(!card) {
                    return null
                }

                if(!card.card_number) {
                    return null
                }

                return card
            },
            getCardPoint(card) {

                if(!card) {
                    return 0;
                }

                const cardNumber = card.card_number;

                if (parseInt(cardNumber) >= 10) {
                    return 0;
                }
                return parseInt(cardNumber);
            },
            getCardPointDragonTiger(card) {

                if(!card) {
                    return 0;
                }
                return parseInt(card.card_number);
            },
            getCardsPoint(ticket, side) {

                let point = 0;
                for(let i = 0;i < 3;i++) {
                    point += this.getCardPoint(this.getCard(ticket, side, i));
                }
                return point % 10

            },
            getBankerPoint() {

            },
            getDice(ticket) {
                if(!ticket){
                    return []
                }
                return ticket.bet_list.game_info.dice
            },
            calDicePoint(ticket) {

                const dice = this.getDice(ticket)

                if(!dice) {
                    return '-'
                }
                if (dice.length === 0) {
                    return '-'
                }

                const total = dice.reduce((sum, item) => {
                    return parseInt(sum) + parseInt(item)
                }, 0)

                if(ticket.game === 'sicbo') {
                    return total
                } else if(ticket.game === 'bean') {
                    const calculated = total % 4
                    return (calculated === 0) ? 4 : calculated
                }
            },
            getTableName(ticket) {
                if(!ticket) {
                    return ''
                }
                if(!ticket.bet_list.table_name) {
                    return ''
                }
                return ticket.bet_list.table_name
            },
        },
        created() {
            this.TICKET_RESULT_KEY = TICKET_RESULT_KEY
        },
    }
</script>
<style scoped>
    .wrapper-box-ticket {
        max-height: 80vh;
        overflow-y: auto;
        position: fixed;
        width: 290px;
    }

    .GameTicketList {
        height: 100vh;
        overflow-y: auto;
        color: #fff;
        font-size: 14px;
        padding: 15px 5px 15px 10px;
        background-color: #111;
        font-family: "Athiti", sans-serif;
    }

    .GameTicketList small {
        color: #ffcc66;
    }

    .GameTicketList h5 {
        font-size: 17px;
        font-weight: bold;
        border-bottom: solid 1px #222;
        padding-bottom: 5px;
    }
    .ticket-row-top{
        position: sticky;
        top: -1px;
        z-index: 2;
        background-color: #111!important;
    }
    .ticket-row {
        width: 100%;
        display: flex;
        align-items: center;
        justify-content: center;
        border-bottom: solid 1px #000;
        font-size: 12px;
    }
    .ticket-row:nth-of-type(odd) {
        background-color: rgba(255, 255, 255, 0.05);
    }
    .ticket-col {
        display: flex;
        width: 25%;
        color: #b3acac;
        padding: 2px 0px;
        justify-content: center !important;
    }

    .ticket-col:first-child,
    .row-th:first-child {
        width: 32%;
        justify-content: left !important;
    }

    .ticket-col:nth-child(2), .row-th:nth-child(2){
        width: 30%;
        text-align: center;
    }

    .row-th {
        width: 25%;
        font-weight: 600;
        padding: 5px 0px;
        color: #ddd;
        background-color: transparent!important;
        border-bottom: solid 1px #555;
    }

    .text-color-blue {
        color: #006aff;
    }
    .text-color-red {
        color: #ff2929;
    }

    .text-color-yellow {
        color: #fab600;
    }

    .text-color-white {
        color: white;
    }

    .text-color-green {
        color: green;
    }

    .ticket-result-detail {
        display: flex;
        flex-direction: column;
        align-items: center;
        width: 100%;
    }
    .ticket-result-detail.left {
        align-items: flex-start;
    }
    .custom-card-list-content{
        display: flex;
        flex-direction: row;
        margin-bottom: 10px;

    }
    .custom-card-list-box{
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        margin-right: 5px;
    }
    .custom-card-list-box-DT{
        width: 70px;
        text-align: center;
        margin-right: 0
    }
    .custom-card-list-title {
        display: flex;
        justify-content: center;
        color: white;
        font: 10px/1 Helvetica;
        padding-bottom: 3px;
    }

    .custom-card-list-title.expand {
        justify-content: space-between;
    }
    .custom-card-list {
        display: flex;
    }
    .custom-card-list.expand {
        justify-content: space-between;
    }
    .custom-card-list * {
        margin: 0 1px;
    }
</style>