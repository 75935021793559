<script>

import GameTableListItemMixin from '@/mixins/GameTableListItem'
import {GAME_RESULT_MAP} from './Constant'
const classMap = {
    0: '',
    1: 'text-hi',
    2: 'text-lo',
    3: 'text-hilo',
    4: '',
}

const LIMIT = 4
const PADDING_COUNT_FOR_LATEST = 0

export default {
    name: 'SicboTableListItem',
    props: [
        'table_id',
        'table_round_main',
        'limit',
    ],
    mixins: [
        GameTableListItemMixin,
    ],
    data() {
        return {
            secondStart: 22,
        }
    },
    computed: {
        rawStatArr() {
            return this.getStatArrByTable(this.table_id, this.table_round_main)
        },
        _limit() {
            if(!this.limit) {
                return LIMIT
            }
            return this.limit
        },
        statArr() {

            const start = Math.max(0, this.rawStatArr.length - 1 - PADDING_COUNT_FOR_LATEST - this._limit)
            const notInclude = this.rawStatArr.length - 1 - PADDING_COUNT_FOR_LATEST

            return this.rawStatArr.slice(start, notInclude)
        },
        statLatest() {
            if(this.rawStatArr.length > 1) {
                return this.rawStatArr[this.rawStatArr.length - 1 - PADDING_COUNT_FOR_LATEST]
            } else {
                return null
            }
        },
    },
    methods: {
        resultDisplay(raw) {
            if(!raw) {
                return ''
            }
            return GAME_RESULT_MAP[raw]['title']
        },
        classComputed(raw) {
            if(!raw) {
                return ''
            }
            return classMap[raw]
        },
        getDice(stat) {
            if(!stat) {
                return []
            }
            if(!stat.game_info) {
                return []
            }
            return stat.game_info.dice
        },
        calDicePoint(stat) {

            const dice = this.getDice(stat)

            if(dice.length === 0) {
                return ''
            }
            return dice.reduce((sum, item) => {
                return parseInt(sum) + parseInt(item)
            }, 0)
        },
    },
}
</script>