<template>
<div class="ChatWithAdmin">
    <div v-if="isAvailable" @click="togglePanel" class="chatlive">
        <div class="run-text">
            <marquee scrolldelay="250">Live Support ยินดีให้บริการค่ะ</marquee>
<!--            <p >Live Support ยินดีให้บริการค่ะ</p>-->

        </div>
        <div v-show="unread" class="chat-with-admin-unread">
            <div>
                {{unread}}
            </div>
        </div>
    </div>
    <div v-if="isAvailable && isAlreadyOpen" class="chatlive-content" :class="{'show': isShowPanel, 'hide': !isShowPanel}">
        <div class="fullscreen-chatlive" :class="{'show': isShowImageFullScreen, 'hide': !isShowImageFullScreen}">
            <button @click="closeImageFullScreen" type="submit"><i class="fas fa-times"></i></button>
            <img :src="imageFullScreen"/>
        </div>
        <div class="chatlive-title">
            <h2>Chat with us!</h2>
            <button @click="togglePanel" type="button"><i class="fas fa-chevron-down"></i></button>
        </div>
        <div class="chatlive-title-sub">
            <div class="chatlive-group-left">
                <div class="chatlive-image">
                    <img v-if="appInfo.app_image_profile" :src="appInfo.app_image_profile"/>
                    <img v-else src="@/assets/images/avatar.png" />
                </div>
                <div class="chatlive-name">
                    <h4>Chat Live</h4>
                    <span>{{appInfo.VUE_APP_TITLE}}</span>
                </div>
            </div>
            <!-- <div class="chatlive-group">
                <span class="thumb-up"></span>
                <span class="thumb-down"></span>
            </div> -->
        </div>
        <div @click="clickChatBodyHandle" ref="messages" class="chatlive-boxChat">
            <div v-if="isCanLoadMore" class="load-more-section">
                <button @click="loadMore" class="form-control" :disabled="isOnLoadMore">
                    <span v-if="isOnLoadMore">
                        กำลังโหลด...
                    </span>
                    <span v-else>
                        โหลดข้อความก่อนหน้า
                    </span>
                </button>
            </div>
            <div v-for="(groupedMessage, key) of groupedMessages" :key="key" :class="calMessageClass(groupedMessage.type, groupedMessage.side)">
                <div v-if="groupedMessage.type === 'message'" class="chatlive-boxChat-info">
                    <span v-for="(message, key) of groupedMessage.messages" :key="key">
                        <div v-if="message.type === 'text'" class="box-bubble">
                            <span>{{message.text}}</span>
                        </div>
                        <div v-else-if="message.type === 'image'" 
                        @click="openImageFullScreen(getImageSrc(message.detail))" 
                        class="box-bubble box-bubble-img">
                            <img :src="getImageSrc(message.detail)"/>
                        </div>
                        <div v-else-if="message.type === 'transaction-created-notify'" class="box-bubble">
                            <span>
                                {{transactionCreatedNofifyDisplay(message.detail)}}
                                <div>(ข้อความอัตโนมัติ)</div>
                            </span>
                        </div>
                    </span>
                    <small>{{timestampDisplay(groupedMessage.timestamp)}}</small>
                </div>
                <div v-else-if="groupedMessage.type === 'date_seperator'">
                    {{groupedMessage.timestamp | seperatorDateTimeFormat}}
                </div>
            </div>
        </div>
        <div v-if="!isOnload" class="chatlive-bottom">
            <button v-if="firstEnter" @click="firstEnter = false" type="button" class="intoChat">Chat Now</button>
            <div v-else class="chatlive-bottom-input">
                <input @focus="messageTextInputFocusHandle" @blur="messageTextInputBlurHandle" @keyup.enter="sendMessage" v-model="messageForm.text" ref="messageTextInput" type="text" placeholder="เขียนข้อความ">
                <div class="chatlive-bottom-group">
                    <input onclick="this.value=null;" @change="fileChangedHandle" ref="fileInput" type="file" accept="image/*" style="display: none;"/>
                    <div @click="selectImage" class="chatlive-bottom-input-image"></div>
                    <button @click="sendMessage" type="submit"></button>
                </div>
            </div>
        </div>
    </div>
</div>
</template>
<script>

import store from '@/store'
import axiosWrapper from '@/axiosWrapper'
import ReconnectingWebSocket from 'reconnecting-websocket'
import debounce from '@/debounce'
import {groupBy} from '@/lib/ArrHandle'
import fileToBase64 from '@/lib/fileToBase64.js'
import Jimp from 'jimp'
import UserMixin from '@/mixins/UserMixin'
    import $ from 'jquery'
    window.jQuery = $;
    window.$ = $;

const AT_BOTTOM_OFFSET = 100

export default {
    name: 'ChatWithAdmin',
    mixins: [
        UserMixin,
    ],
    props:['showChatWithAdmin'],
    data() {
        return {
            appInfo: null,
            isShowPanel: false,
            messageType: 'text', // text, image
            messageForm: {
                text: '',
                detail: null,
            },
            messages: [],
            loading: null,
            isOnload: false,
            firstEnter: false,
            isCanLoadMore: false,
            isOnLoadMore: false,
            page: 1,
            imageFullScreen: '',
            isShowImageFullScreen: false,
            isLockMessagetTextInputFocus: false,
            base64: '',
            unread: 0,
            isAlreadyInit: false,
            isAlreadyOpen: false,
        }
    },
    computed: {
        pes_profile() {
            return store.state.pes_profile
        },
        user_profile() {
            return store.state.user_profile
        },
        token() {
            return this.pes_profile.token
        },
        channel() {
            return store.state.channel
        },
        message_api_id() {
            return store.state.message_api_id
        },
        isAvailable() {
            return this.pes_profile && this.appInfo
        },
        messageScroll() {
            return this.$refs.messages
        },
        groupedMessages() {
            const messages = []
            let previousSide = ''
            let index = ''
            for(const message of this.messages) {
                const side = (message.user_id) ? 'customer' : 'admin'
                if(side !== previousSide) {
                    index++
                }
                const groupKey = `${this.$moment(message.created_at).format('YYYY-MM-DD HH:mm')};${side};${index}`
                messages.push(Object.assign(message, {groupKey}))
                previousSide = side
            }
            const groupedMap = groupBy(messages, 'groupKey')
            const groupeds = []
            let previousMessage = null
            
            for(const key in groupedMap) {
                const keySplited = key.split(';')
                const timestamp = keySplited[0]
                const side = keySplited[1]
                const messages = groupedMap[key]
                const currentMessage = messages[messages.length - 1]

                const seperator = this._dateSeperate((previousMessage) ? previousMessage.created_at : null, currentMessage.created_at)
                if(seperator) {
                    groupeds.push(seperator)
                }

                groupeds.push({
                    type: 'message',
                    timestamp: timestamp,
                    side: side,
                    messages: messages,
                })

                previousMessage = currentMessage
            }
            return groupeds
        },
        needChatWithAdmin() {
            return store.state.needChatWithAdmin
        },
        is_member() {
            return store.state.is_member
        },
    },
    watch: {
        async isShowPanel(newVal) {
            if(newVal) {
                await this._read()
                this._scrollToBottom('auto', 200)
            } 
        },
        needChatWithAdmin(newVal) {
            if(newVal) {
                this.togglePanel()
                store.commit('updateNeedChatWithAdmin', false)
            }
        },
        showChatWithAdmin(newVal){
            if(newVal==1) {
                this.togglePanel();
            }
            
        }
    },
    methods: {
        togglePanel() {
            this.isShowPanel = !this.isShowPanel
            if(this.isShowPanel && !this.isAlreadyOpen) {
                this.isAlreadyOpen = true
            }
        },
        _clearMessageForm() {
            this.messageForm = {
                text: '',
                detail: null,
            }
        },
        selectMessageType(messageType) {
            this.messageType = messageType
        },
        async _getAppInfo() {
            const res = await axiosWrapper({
                method: 'post',
                url: `${this.envDomains.VUE_APP_LIFF_API}/api/channel/get-app-info`,
                data: {
                    channel: this.channel,
                    line: this.message_api_id,
                }
            })
            if(res.status === 200 && res.data.VUE_APP_TITLE) {
                this.appInfo = res.data
            }
        },
        async _initRoom() {
            this.isOnload = true
            await axiosWrapper({
                method: 'post',
                url: `${this.envDomains.VUE_APP_LIFF_API}/api/chat-with-admin/room/create`,
                data: {
                    token: this.token,
                }
            })
        },
        _webSocketOpenHandle() {
            if(this.webSocket.readyState === ReconnectingWebSocket.OPEN){
                this.webSocket.send(JSON.stringify({
                    task: 'subscribe',
                    data: {
                        token: this.token,
                    }
                }))
            }
        },
        _webSocketMessageRecieveHandle(event) {
            this.isOnload = false
            const message = JSON.parse(event.data)
            if(message.task === 'init') {
                this._taskInit(message.data.messages, message.data.room)
            } else if(message.task === 'message_send') {
                this._taskMessageSend(message.data)
            }
        },
        _initWebSocket() {
            this.webSocket = new ReconnectingWebSocket(`${this.envDomains.VUE_APP_LIFF_WS}/api/chat-with-admin/user/subscribe`, [], {
                debug: false, 
                reconnectInterval: 3000
            })
            this.webSocket.addEventListener('open', this._webSocketOpenHandle)
            this.webSocket.addEventListener('message', this._webSocketMessageRecieveHandle)
        },
        _closeWebSocket() {
            if(!this.webSocket) {
                return
            }
            this.webSocket.close()
        },
        _taskInit(messages, room) {
            if(messages.length > 0) {
                this.firstEnter = false
                this.page = 1
                this.isCanLoadMore = true
                this.isOnLoadMore = false
            } else {
                this.firstEnter = true
            }
            this.messages = messages.reverse()

            if(this.isAlreadyInit) {
                return
            }

            this._scrollToBottom('auto', 1000)

            // const readTimestamp = (room.read) ? this.$moment(room.read) : null
            // for(const message of this.messages) {
            //     const messageTimestamp = this.$moment(message.created_at)
            //     if(!messageTimestamp.isSameOrBefore(readTimestamp) && message.user_id !== this.user_profile.id) {
            //         this.unread++
            //     }
            // }
            this.unread  = room.unread;
            updateNotification(this.unread)

            this.isAlreadyInit = true
        },
        _taskMessageSend(data) {
            if(!this.isShowPanel) {
                this.unread++
                updateNotification(this.unread);
            } else {
                this._read()
            }
            this.messages.push(data)
            if(this._checkIsOnBottom() || this.isLockMessagetTextInputFocus) {
                this._scrollToBottom()
            }
        },
        calMessageClass(type, side) {
            if(type === 'message') {
                return (side === 'customer') ? 'customer-chat' : 'admin-chat'
            } else if(type === 'date_seperator') {
                return 'date-seperator'
            }
        },
        getImageSrc(detail) {
            try {
                const path = JSON.parse(detail).path
                if(path.includes('http') || path.includes('data:image')) {
                    return path
                } else {
                    return `${this.envDomains.VUE_APP_LIFF_API}${path}`
                }
            } catch (err) {
                console.log('getImageSrc err', err.message)
                return ''
            }
        },
        timestampDisplay(timestamp) {
            return this.$moment(timestamp).format('HH:mm')
        },
        sendMessage: debounce(async function() {
            if(!this.messageForm.text) {
                return
            }
            this.$refs.messageTextInput.focus()
            this.isLockMessagetTextInputFocus = true
            this.messageType = 'text'
            await this._sendMessage()
        }, 100),
        async _sendMessage() {
            
            this.messages.push({
                type: this.messageType,
                user_id: this.user_profile.id,
                created_at: this.$moment().toISOString(),
                text: this.messageForm.text,
                detail: (this.messageType === 'image') ? JSON.stringify({path: this.base64}) : null,
            })
            if(this._checkIsOnBottom() || this.isLockMessagetTextInputFocus) {
                this._scrollToBottom('smooth', (this.messageType === 'image') ? 1000 : 100)
            }
            setTimeout(() => {
                this._clearMessageForm()
            }, 100)
            const res = await axiosWrapper({
                method: 'post',
                url: `${this.envDomains.VUE_APP_LIFF_API}/api/chat-with-admin/message/user-send`,
                data: {
                    token: this.token,
                    type: this.messageType,
                    text: (this.messageType === 'image') ? 'image' : this.messageForm.text,
                    detail: this.messageForm.detail,
                }
            })
            if(res.status !== 200) {
                console.log('sendMessage err', res.data)
            }
        },
        _read: debounce(async function() {
            const res = await axiosWrapper({
                method: 'post',
                url: `${this.envDomains.VUE_APP_LIFF_API}/api/chat-with-admin/room/user-read`,
                data: {
                    token: this.token,
                }
            })
            if(res.status !== 200) {
                console.log('_read err', res.data)
                return
            }
            this.unread = 0
            updateNotification(this.unread);
        }, 100),
        _checkIsOnBottom() {
            if(!this.messageScroll) {
                return false
            }
            return !!((window.innerHeight + this.messageScroll.scrollTop + AT_BOTTOM_OFFSET) >= this.messageScroll.scrollHeight)
        },
        _scrollToBottom(mode = 'smooth', delay = 100){
            if(!this.isShowPanel) {
                return
            }
            setTimeout(() => {
                if(this.messageScroll){
                    this.messageScroll.style['scroll-behavior'] = mode
                    this.messageScroll.scrollTo(0, this.messageScroll.scrollHeight)
                }
                window.scrollTo(0, document.body.scrollHeight)
            }, delay)
        },
        _dateSeperate(prev, cur){
            if(!prev){
                return null
            }
            const prevDate = this.$moment(prev).format('YYYY-MM-DD')
            const curDate = this.$moment(cur).format('YYYY-MM-DD')
            if(prevDate !== curDate) {
                return {
                    type: 'date_seperator',
                    timestamp: cur,
                }
            } else {
                return null
            }
        },
        async loadMore() {
            this.isOnLoadMore = true
            this.page++
            const res = await axiosWrapper({
                method: 'POST',
                url: `${this.envDomains.VUE_APP_LIFF_API}/api/chat-with-admin/message/get-previous-messages`,
                data: {
                    token: this.token,
                    latest_message_id: this.messages[0].id,
                    limit: 50,
                },
            })
            this.isOnLoadMore = false
            if(res.status === 200) {
                this.messages = res.data.reverse().concat(this.messages)
                if(res.data.length > 0) {
                    this.isCanLoadMore = true
                } else {
                    this.isCanLoadMore = false
                }
            } else {
                console.log('loadMore err', res.data)
            }
        },
        selectImage: debounce(function() {
            const fileInput = this.$refs.fileInput
            fileInput.click()
        }, 100),
        async fileChangedHandle(event) {

            const LIMIT_SIZE = 1048576 // 1 mb

            const selectedFile = event.target.files[0]
            if(!selectedFile) {
                return
            }

            this.loading = this.$loading()

            const fileSize = selectedFile.size

            if(fileSize > LIMIT_SIZE) {

                try {

                    const calculateScaleRatio = (originalSize) => {
                        const OFFSET_RATIO = 0.9
                        const divideFactor = originalSize / LIMIT_SIZE
                        const sizeToBe = (originalSize / divideFactor) * OFFSET_RATIO
                        return (sizeToBe / originalSize)
                    }

                    const imageBlobSrc = URL.createObjectURL(selectedFile)
                    const jimpImage = await Jimp.read(imageBlobSrc)

                    const scaleRatio = calculateScaleRatio(fileSize)
                    if(scaleRatio > 1) {
                        return
                    }

                    const jimpImageResized = await jimpImage.scale(scaleRatio).quality(scaleRatio * 100)
                    const imageResizeBuffer = await jimpImageResized.getBufferAsync(Jimp.AUTO)
                    const fileResizedSize = Buffer.byteLength(imageResizeBuffer)

                    if(fileResizedSize > LIMIT_SIZE) {
                        this.$notify({
                            title: 'แจ้งเตือน',
                            message: 'ไฟล์ต้องใหญ่ไม่เกิน 1 mb',
                            type: 'warning',
                            duration: 0,
                            position: 'bottom-right',
                        })
                        return
                    }

                    this.base64 = await jimpImageResized.getBase64Async(Jimp.AUTO)

                } catch(err) {
                    console.log('image resize error', err.message)
                    this.$notify({
                        title: 'แจ้งเตือน',
                        message: 'เกิดความผิดพลาดบางอย่าง กรุณาลองใหม่อีกครั้ง',
                        type: 'warning',
                        duration: 0,
                        position: 'bottom-right',
                    })
                    return
                }
            } else {
                const base64Progress = await fileToBase64(selectedFile)
                if(!base64Progress.success) {
                    console.log('base64Progress err', base64Progress.data)
                    return
                }
                this.base64 = base64Progress.data
            }
            
            const res = await axiosWrapper({
                method: 'POST',
                url: `${this.envDomains.VUE_APP_LIFF_API}/api/chat-with-admin/user/image-upload`,
                data: {
                    token: this.token,
                    base64: this.base64,
                },
            })
            this.loading.close()
            if(res.status !== 200) {
                console.log('/api/chat-with-admin/user/image-upload err', res.data)
                if(res.data.message_code === 'file_size_exceed') {
                    this.$notify({
                        title: 'แจ้งเตือน',
                        message: res.data.message,
                        type: 'warning',
                        duration: 0,
                        position: 'bottom-right',
                    })
                    return
                }
                return
            }
            this.messageType = 'image'
            this.messageForm.detail = JSON.stringify(res.data)
            await this._sendMessage()
        },
        openImageFullScreen(image) {
            this.isShowImageFullScreen = true
            this.imageFullScreen = image
        },
        closeImageFullScreen() {
            this.isShowImageFullScreen = false
            this.imageFullScreen = ''
        },
        messageTextInputFocusHandle() {
            this.isLockMessagetTextInputFocus = true
        },
        messageTextInputBlurHandle() {
            if(this.isLockMessagetTextInputFocus) {
                this.$refs.messageTextInput.focus()
            }
        },
        clickChatBodyHandle() {
            this.isLockMessagetTextInputFocus = false
            this.$refs.messageTextInput.blur()
        },
        transactionCreatedNofifyDisplay(detail) {
            const TRANSACTION_TYPE_MAP = {
                deposit: 'ฝากเงิน',
                withdraw: 'ถอนเงิน',
            }
            const detailObject = JSON.parse(detail)
            const type = detailObject.type
            const amount = this.$options.filters.floatFormat(detailObject.amount)
            return `แจ้งเตือน${TRANSACTION_TYPE_MAP[type]} ${amount} บาท`
        },
    },
    async mounted() {
        await this._getAppInfo()
        if(this.isAvailable) {
            await this._initRoom()
            this._initWebSocket()
        }
        if(this.$route.query['redirect-page'] === 'chat-with-admin') {
            if(!this.is_member) {
                this.notifyToSignUp('เพื่อแชทกับแอดมิน')
                return
            }
            if(!this.isAvailable) {
                return
            }
            this.togglePanel()
        }
    },
    beforeDestroy() {
        this._closeWebSocket()
    },
    
}
function updateNotification(count){
    if(count>0){
        $('div.nevNotification').html(`<span>${count}</span>`).removeClass('hide');
    }else{
        $('div.nevNotification').html(`<span>${count}</span>`).addClass('hide');
    }
    
}
</script>
<style scoped>
.date-seperator {
    display: flex;
    justify-content: center;
    font-size: 12px;
}
.load-more-section {
    display: flex;
    padding: 0 4px;
}
.chat-with-admin-unread {
    position: absolute;
    top: 1px;
    right: 1px;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 20px;
    height: 20px;
    font-size: 11px;
    border-radius: 50%;
    background-color: red;
    color: white;
}
</style>