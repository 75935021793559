<template>
    <div v-if="league.odd_list.length > 0" class="TableOddRow">
        <div v-if="showFavorite('league', league.league_id)" class="row-header" :class="{
        'league-bg-color-live': mode === 'live',
        'league-bg-color-non-live': mode === 'nonlive',
        'custom-sub-header-sticky': is_header_fix
        }">
            <div class="flex-basis-50"></div>
            <div style="flex-basis: 500px;">
                <div class="flex">
<!--                    <FavoriteButton mode="league" :fav_id="league.league_id" :odd_list="league.odd_list" :league_id="league.league_id"/>-->
                    &nbsp; 
                    <div>{{league.league_name}}</div>
                </div>
            </div>
        </div>
        <div class="sub-header-position"></div>
        <div v-for="odd in league.odd_list" :key="odd.odds_id">
            <div v-if="showFavorite('match', odd.odd_info.match_id)">
                <div v-if="lineMode === 'single'">
                    <div class="v-row" :class="{ 'bg-color-live': mode === 'live', 'bg-color-non-live': mode === 'nonlive' }">
                        <div class="flex-basis-50 border-white">
                            <div v-if="!isLive" style="flex-direction: column;">
                                <div>{{odd.timestamp}}</div>
                                <div>{{odd.live_half}}</div>
                            </div>
                            <div v-if="isLive"  style="flex-direction: column;">
                                <div v-if="odd.timestamp && odd.match_home_name">{{odd.odd_info.info_home_score | nullToZero}}:{{odd.odd_info.info_away_score | nullToZero}}</div>
                                <div v-if="odd.timestamp && odd.match_home_name" style="color: red;">{{odd.live_half | halfToH}}&nbsp;{{odd.timestamp | strOverflowHidden}}'</div>
                            </div>
                        </div>
                        <div class="flex-basis-200 border-white" style="text-align: start; margin-left: 4px;">
                            <div class="flex item-center-vertical">
                                &nbsp;
                                <div v-if="odd.match_home_name">
<!--                                    <FavoriteButton mode="match" :fav_id="odd.odd_info.match_id" :league_id="league.league_id"/>-->
                                </div>
                                &nbsp;
                                <div class="flex-column color-blue">
                                    <div v-if="odd.match_home_name" class="team-name" :class="{ 'color-red': odd.hdp.odds_hdp > 0}">
                                        {{odd.match_home_name}}
                                        <RedCard :quantity="odd.live_home_red_card"/>
                                    </div>
                                    <div v-if="odd.match_away_name" class="team-name" :class="{ 'color-red': odd.hdp.odds_hdp < 0}">
                                        {{odd.match_away_name}}
                                        <RedCard :quantity="odd.live_away_red_card"/>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="flex-basis-50 border-white">
                            <div class="flex-column color-blue">
                                <div class="hdp-wrapper">
                                    <span v-if="odd.hdp.odds_n1 && odd.hdp.odds_hdp >= 0">{{odd.hdp.odds_hdp | changeHDP}}</span>
                                </div>
                                <div class="hdp-wrapper">
                                    <span v-if="odd.hdp.odds_n1 && odd.hdp.odds_hdp < 0">{{odd.hdp.odds_hdp | changeHDP}}</span>
                                </div>
                            </div>
                        </div>
                        <div class="flex item-center flex-basis-50 border-white">
                            <OddPrice
                                    :is_live="isLive"
                                    :odd_info="odd.odd_info"
                                    :odd_id="odd.hdp.odds_id"
                                    odd_name="odds_n1"
                                    :price="odd.hdp.odds_n1"
                                    :odds_x3="odd.hdp.odds_x3"
                                    mode="HDP"
                                    mode_sub="FT"
                                    :mode_value="odd.hdp.odds_hdp"
                                    choose="H"/>
                        </div>
                        <div class="flex item-center flex-basis-50 border-white">
                            <OddPrice
                                    :is_live="isLive"
                                    :odd_info="odd.odd_info"
                                    :odd_id="odd.hdp.odds_id"
                                    odd_name="odds_n2"
                                    :price="odd.hdp.odds_n2"
                                    :odds_x3="odd.hdp.odds_x3"
                                    mode="HDP"
                                    mode_sub="FT"
                                    :mode_value="odd.hdp.odds_hdp"
                                    choose="A"/>
                        </div>
                        <div class="flex-basis-50 border-white">
                            <div class="flex-column color-blue">
                                <div class="hdp-wrapper">
                                    <span v-if="odd.goal.odds_n1 && odd.goal.odds_hdp >= 0">{{odd.goal.odds_hdp | changeHDP}}</span>
                                </div>
                                <div class="hdp-wrapper">
                                    <span v-if="odd.goal.odds_n1 && odd.goal.odds_hdp < 0">{{odd.goal.odds_hdp | changeHDP}}</span>
                                </div>
                            </div>
                        </div>
                        <div class="flex item-center flex-basis-50 border-white">
                            <OddPrice
                                    :is_live="isLive"
                                    :odd_info="odd.odd_info"
                                    :odd_id="odd.goal.odds_id"
                                    odd_name="odds_n1"
                                    :price="odd.goal.odds_n1"
                                    :odds_x3="odd.goal.odds_x3"
                                    mode="OU"
                                    mode_sub="FT"
                                    :mode_value="odd.goal.odds_hdp"
                                    choose="Over"/>
                        </div>
                        <div class="flex item-center flex-basis-50 border-white">
                            <OddPrice
                                    :is_live="isLive"
                                    :odd_info="odd.odd_info"
                                    :odd_id="odd.goal.odds_id"
                                    odd_name="odds_n2"
                                    :price="odd.goal.odds_n2"
                                    :odds_x3="odd.goal.odds_x3"
                                    mode="OU"
                                    mode_sub="FT"
                                    :mode_value="odd.goal.odds_hdp"
                                    choose="Under"/>
                        </div>
                        <div class="flex-basis-50 border-white">
                            <div class="flex-column color-blue">
                                <div class="hdp-wrapper">
                                    <span v-if="odd.fh_hdp.odds_n1 && odd.fh_hdp.odds_hdp >= 0">{{odd.fh_hdp.odds_hdp | changeHDP}}</span>
                                </div>
                                <div class="hdp-wrapper">
                                    <span v-if="odd.fh_hdp.odds_n1 && odd.fh_hdp.odds_hdp < 0">{{odd.fh_hdp.odds_hdp | changeHDP}}</span>
                                </div>
                            </div>
                        </div>
                        <div class="flex item-center flex-basis-50 border-white">
                            <OddPrice
                                    :is_live="isLive"
                                    :odd_info="odd.odd_info"
                                    :odd_id="odd.fh_hdp.odds_id"
                                    odd_name="odds_n1"
                                    :price="odd.fh_hdp.odds_n1"
                                    :odds_x3="odd.fh_hdp.odds_x3"
                                    mode="HDP"
                                    mode_sub="FH"
                                    :mode_value="odd.fh_hdp.odds_hdp"
                                    choose="H"/>
                        </div>
                        <div class="flex item-center flex-basis-50 border-white">
                            <OddPrice
                                    :is_live="isLive"
                                    :odd_info="odd.odd_info"
                                    :odd_id="odd.fh_hdp.odds_id"
                                    odd_name="odds_n2"
                                    :price="odd.fh_hdp.odds_n2"
                                    :odds_x3="odd.fh_hdp.odds_x3"
                                    mode="HDP"
                                    mode_sub="FH"
                                    :mode_value="odd.fh_hdp.odds_hdp"
                                    choose="A"/>
                        </div>
                        <div class="flex-basis-50 border-white">
                            <div class="flex-column color-blue">
                                <div class="hdp-wrapper">
                                    <span v-if="odd.fh_goal.odds_n1 && odd.fh_goal.odds_hdp >= 0">{{odd.fh_goal.odds_hdp | changeHDP}}</span>
                                </div>
                                <div class="hdp-wrapper">
                                    <span v-if="odd.fh_goal.odds_n1 && odd.fh_goal.odds_hdp < 0">{{odd.fh_goal.odds_hdp | changeHDP}}</span>
                                </div>
                            </div>
                        </div>
                        <div class="flex item-center flex-basis-50 border-white">
                            <OddPrice
                                    :is_live="isLive"
                                    :odd_info="odd.odd_info"
                                    :odd_id="odd.fh_goal.odds_id"
                                    odd_name="odds_n1"
                                    :price="odd.fh_goal.odds_n1"
                                    :odds_x3="odd.fh_goal.odds_x3"
                                    mode="OU"
                                    mode_sub="FH"
                                    :mode_value="odd.fh_goal.odds_hdp"
                                    choose="Over"/>
                        </div>
                        <div class="flex item-center flex-basis-50 border-white">
                            <OddPrice
                                    :is_live="isLive"
                                    :odd_info="odd.odd_info"
                                    :odd_id="odd.fh_goal.odds_id"
                                    odd_name="odds_n2"
                                    :price="odd.fh_goal.odds_n2"
                                    :odds_x3="odd.fh_goal.odds_x3"
                                    mode="OU"
                                    mode_sub="FH"
                                    :mode_value="odd.fh_goal.odds_hdp"
                                    choose="Under"/>
                        </div>
                        <div class="flex-basis-50"></div>
                    </div>
                </div>
                <div v-if="lineMode === 'double'">
                    <div class="v-row double" :class="{ 'bg-color-live': mode === 'live', 'bg-color-non-live': mode === 'nonlive' }">
                        <div class="flex-basis-50 border-white">
                            <div v-if="!isLive" style="flex-direction: column;">
                                <div>{{odd.timestamp}}</div>
                                <div>{{odd.live_half}}</div>
                            </div>
                            <div v-if="isLive"  style="flex-direction: column;">
                                <div v-if="odd.timestamp && odd.match_home_name">{{odd.odd_info.info_home_score | nullToZero}}:{{odd.odd_info.info_away_score | nullToZero}}</div>
                                <div v-if="odd.timestamp && odd.match_home_name" style="color: red;">{{odd.live_half | halfToH}}&nbsp;{{odd.timestamp | strOverflowHidden}}'</div>
                            </div>
                        </div>
                        <div class="flex-basis-200 border-white" style="text-align: start; margin-left: 4px;">
                            <div class="flex item-center-vertical">
                                &nbsp;
                                <div v-if="odd.match_home_name">
<!--                                    <FavoriteButton mode="match" :fav_id="odd.odd_info.match_id" :league_id="league.league_id"/>-->
                                </div>
                                &nbsp;
                                <div class="flex-column color-blue">
                                    <div v-if="odd.match_home_name" class="team-name" :class="{ 'color-red': odd.hdp.odds_hdp > 0}">
                                        {{odd.match_home_name}}
                                        <RedCard :quantity="odd.live_home_red_card"/>
                                    </div>
                                    <div v-if="odd.match_away_name" class="team-name" :class="{ 'color-red': odd.hdp.odds_hdp < 0}">
                                        {{odd.match_away_name}}
                                        <RedCard :quantity="odd.live_away_red_card"/>
                                    </div>
                                    <div v-if="odd.match_home_name" style="color: black;">
                                        Draw
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="flex border-white" style="flex-basis: 340px;">
                            <div class="flex-basis-50" style="text-align: start; padding-left: 5px;">
                                <div class="flex-column color-blue" style="height: 100%; justify-content: space-around;">
                                    <div class="hdp-wrapper">
                                        <span v-if="odd.hdp.odds_n1 && odd.hdp.odds_hdp >= 0">{{odd.hdp.odds_hdp | changeHDP}}</span>
                                    </div>
                                    <div class="hdp-wrapper">
                                        <span v-if="odd.hdp.odds_n1 && odd.hdp.odds_hdp < 0">{{odd.hdp.odds_hdp | changeHDP}}</span>
                                    </div>
                                </div>
                            </div>
                            <div class="flex-column flex-basis-50" style="justify-content: space-around;">
                                <OddPrice
                                        :is_live="isLive"
                                        :odd_info="odd.odd_info"
                                        :odd_id="odd.hdp.odds_id"
                                        odd_name="odds_n1"
                                        :price="odd.hdp.odds_n1"
                                        :odds_x3="odd.hdp.odds_x3"
                                        mode="HDP"
                                        mode_sub="FT"
                                        :mode_value="odd.hdp.odds_hdp"
                                        choose="H"/>
                                <!--                        <div class="enter-custom"></div>-->
                                <OddPrice
                                        :is_live="isLive"
                                        :odd_info="odd.odd_info"
                                        :odd_id="odd.hdp.odds_id"
                                        odd_name="odds_n2"
                                        :price="odd.hdp.odds_n2"
                                        :odds_x3="odd.hdp.odds_x3"
                                        mode="HDP"
                                        mode_sub="FT"
                                        :mode_value="odd.hdp.odds_hdp"
                                        choose="A"/>
                                <div>&nbsp;</div>
                            </div>
                            <div class="flex-basis-50" style="text-align: start; padding-left: 5px;">
                                <div class="flex-column color-blue" style="height: 100%; justify-content: space-around;">
                                    <div class="hdp-wrapper">
                                        <span v-if="odd.goal.odds_n1 && odd.goal.odds_hdp >= 0">{{odd.goal.odds_hdp | changeHDP}}</span>
                                    </div>
                                    <div class="enter-custom"></div>
                                    <div class="hdp-wrapper">
                                        <span v-if="odd.goal.odds_n1 && odd.goal.odds_hdp < 0">{{odd.goal.odds_hdp | changeHDP}}</span>
                                    </div>
                                </div>
                            </div>
                            <div class="flex-column" style="flex-basis: 70px; justify-content: space-around;">
                                <div v-if="odd.goal.odds_n1" class="flex">
                                    o&nbsp;&nbsp;
                                    <OddPrice
                                            :is_live="isLive"
                                            :odd_info="odd.odd_info"
                                            :odd_id="odd.goal.odds_id"
                                            odd_name="odds_n1"
                                            :price="odd.goal.odds_n1"
                                            :odds_x3="odd.goal.odds_x3"
                                            mode="OU"
                                            mode_sub="FT"
                                            :mode_value="odd.goal.odds_hdp"
                                            choose="Over"/>
                                </div>
                                <!--                        <div class="enter-custom"></div>-->
                                <div v-if="odd.goal.odds_n2" class="flex">
                                    u&nbsp;&nbsp;
                                    <OddPrice
                                            :is_live="isLive"
                                            :odd_info="odd.odd_info"
                                            :odd_id="odd.goal.odds_id"
                                            odd_name="odds_n2"
                                            :price="odd.goal.odds_n2"
                                            :odds_x3="odd.goal.odds_x3"
                                            mode="OU"
                                            mode_sub="FT"
                                            :mode_value="odd.goal.odds_hdp"
                                            choose="Under"/>
                                </div>
                                <div>&nbsp;</div>
                            </div>
                            <div class="flex-column flex-basis-50" style="justify-content: space-around;">
                                <div class="flex">
                                    <OddPrice
                                            :is_live="isLive"
                                            :odd_info="odd.odd_info"
                                            :odd_id="odd._1x2.odds_id"
                                            odd_name="odds_n1"
                                            :price="odd._1x2.odds_n1"
                                            :odds_x3="odd._1x2.odds_x3"
                                            mode="1X2"
                                            mode_sub="FT"
                                            mode_value=""
                                            choose="1"/>
                                </div>
                                <!--                        <div class="enter-custom"></div>-->
                                <div class="flex">
                                    <OddPrice
                                            :is_live="isLive"
                                            :odd_info="odd.odd_info"
                                            :odd_id="odd._1x2.odds_id"
                                            odd_name="odds_n3"
                                            :price="odd._1x2.odds_n3"
                                            :odds_x3="odd._1x2.odds_x3"
                                            mode="1X2"
                                            mode_sub="FT"
                                            mode_value=""
                                            choose="2"/>
                                </div>
                                <!--                        <div class="enter-custom"></div>-->
                                <div class="flex">
                                    <OddPrice
                                            :is_live="isLive"
                                            :odd_info="odd.odd_info"
                                            :odd_id="odd._1x2.odds_id"
                                            odd_name="odds_n2"
                                            :price="odd._1x2.odds_n2"
                                            :odds_x3="odd._1x2.odds_x3"
                                            mode="1X2"
                                            mode_sub="FT"
                                            mode_value=""
                                            choose="X"/>
                                </div>
                            </div>
                            <div class="flex-column" style="flex-basis: 70px; justify-content: space-around;">
                                <div v-if="odd.oe.odds_n1" class="flex">
                                    o&nbsp;&nbsp;
                                    <OddPrice
                                            :is_live="isLive"
                                            :odd_info="odd.odd_info"
                                            :odd_id="odd.oe.odds_id"
                                            odd_name="odds_n1"
                                            :price="odd.oe.odds_n1"
                                            :odds_x3="odd.oe.odds_x3"
                                            mode="OE"
                                            mode_sub="FT"
                                            mode_value=""
                                            choose="Odd"/>
                                </div>
                                <!--                        <div class="enter-custom"></div>-->
                                <div v-if="odd.oe.odds_n2" class="flex">
                                    e&nbsp;&nbsp;
                                    <OddPrice
                                            :is_live="isLive"
                                            :odd_info="odd.odd_info"
                                            :odd_id="odd.oe.odds_id"
                                            odd_name="odds_n2"
                                            :price="odd.oe.odds_n2"
                                            :odds_x3="odd.oe.odds_x3"
                                            mode="OE"
                                            mode_sub="FT"
                                            mode_value=""
                                            choose="Even"/>
                                </div>
                                <div>&nbsp;</div>
                            </div>
                        </div>
                        <div class="flex border-white" style="flex-basis: 270px;">
                            <div class="flex-basis-50" style="text-align: start; padding-left: 5px;">
                                <div class="flex-column color-blue" style="height: 100%; justify-content: space-around;">
                                    <div class="hdp-wrapper">
                                        <span v-if="odd.fh_hdp.odds_n1 && odd.fh_hdp.odds_hdp >= 0">{{odd.fh_hdp.odds_hdp | changeHDP}}</span>
                                    </div>
                                    <div class="hdp-wrapper">
                                        <span v-if="odd.fh_hdp.odds_n1 && odd.fh_hdp.odds_hdp < 0">{{odd.fh_hdp.odds_hdp | changeHDP}}</span>
                                    </div>
                                </div>
                            </div>
                            <div class="flex-column flex-basis-50" style="justify-content: space-around;">
                                <OddPrice
                                        :is_live="isLive"
                                        :odd_info="odd.odd_info"
                                        :odd_id="odd.fh_hdp.odds_id"
                                        odd_name="odds_n1"
                                        :price="odd.fh_hdp.odds_n1"
                                        :odds_x3="odd.fh_hdp.odds_x3"
                                        mode="HDP"
                                        mode_sub="FH"
                                        :mode_value="odd.fh_hdp.odds_hdp"
                                        choose="H"/>
                                <!--                        <div class="enter-custom"></div>-->
                                <OddPrice
                                        :is_live="isLive"
                                        :odd_info="odd.odd_info"
                                        :odd_id="odd.fh_hdp.odds_id"
                                        odd_name="odds_n2"
                                        :price="odd.fh_hdp.odds_n2"
                                        :odds_x3="odd.fh_hdp.odds_x3"
                                        mode="HDP"
                                        mode_sub="FH"
                                        :mode_value="odd.fh_hdp.odds_hdp"
                                        choose="A"/>
                                <div>&nbsp;</div>
                            </div>
                            <div class="flex-basis-50" style="text-align: start; padding-left: 5px;">
                                <div class="flex-column color-blue" style="height: 100%; justify-content: space-around;">
                                    <div class="hdp-wrapper">
                                        <span v-if="odd.fh_goal.odds_n1 && odd.fh_goal.odds_hdp >= 0">{{odd.fh_goal.odds_hdp | changeHDP}}</span>
                                    </div>
                                    <div class="enter-custom"></div>
                                    <div class="hdp-wrapper">
                                        <span v-if="odd.fh_goal.odds_n1 && odd.fh_goal.odds_hdp < 0">{{odd.fh_goal.odds_hdp | changeHDP}}</span>
                                    </div>
                                </div>
                            </div>
                            <div class="flex-column" style="flex-basis: 70px; justify-content: space-around;">
                                <div v-if="odd.fh_goal.odds_n1" class="flex">
                                    o&nbsp;&nbsp;
                                    <OddPrice
                                            :is_live="isLive"
                                            :odd_info="odd.odd_info"
                                            :odd_id="odd.fh_goal.odds_id"
                                            odd_name="odds_n1"
                                            :price="odd.fh_goal.odds_n1"
                                            :odds_x3="odd.fh_goal.odds_x3"
                                            mode="OU"
                                            mode_sub="FH"
                                            :mode_value="odd.fh_goal.odds_hdp"
                                            choose="Over"/>
                                </div>
                                <!--                        <div class="enter-custom"></div>-->
                                <div v-if="odd.fh_goal.odds_n2" class="flex">
                                    u&nbsp;&nbsp;
                                    <OddPrice
                                            :is_live="isLive"
                                            :odd_info="odd.odd_info"
                                            :odd_id="odd.fh_goal.odds_id"
                                            odd_name="odds_n2"
                                            :price="odd.fh_goal.odds_n2"
                                            :odds_x3="odd.fh_goal.odds_x3"
                                            mode="OU"
                                            mode_sub="FH"
                                            :mode_value="odd.fh_goal.odds_hdp"
                                            choose="Under"/>
                                </div>
                                <div>&nbsp;</div>
                            </div>
                            <div class="flex-column flex-basis-50" style="justify-content: space-around;">
                                <div class="flex">
                                    <OddPrice
                                            :is_live="isLive"
                                            :odd_info="odd.odd_info"
                                            :odd_id="odd.fh_1x2.odds_id"
                                            odd_name="odds_n1"
                                            :price="odd.fh_1x2.odds_n1"
                                            :odds_x3="odd.fh_1x2.odds_x3"
                                            mode="1X2"
                                            mode_sub="FH"
                                            mode_value=""
                                            :choose="1"/>
                                </div>
                                <!--                        <div class="enter-custom"></div>-->
                                <div class="flex">
                                    <OddPrice
                                            :is_live="isLive"
                                            :odd_info="odd.odd_info"
                                            :odd_id="odd.fh_1x2.odds_id"
                                            odd_name="odds_n3"
                                            :price="odd.fh_1x2.odds_n3"
                                            :odds_x3="odd.fh_1x2.odds_x3"
                                            mode="1X2"
                                            mode_sub="FH"
                                            mode_value=""
                                            :choose="2"/>
                                </div>
                                <!--                        <div class="enter-custom"></div>-->
                                <div class="flex">
                                    <OddPrice
                                            :is_live="isLive"
                                            :odd_info="odd.odd_info"
                                            :odd_id="odd.fh_1x2.odds_id"
                                            odd_name="odds_n2"
                                            :price="odd.fh_1x2.odds_n2"
                                            :odds_x3="odd.fh_1x2.odds_x3"
                                            mode="1X2"
                                            mode_sub="FH"
                                            mode_value=""
                                            choose="X"/>
                                </div>
                            </div>
                        </div>
                        <div class="flex-basis-50"></div>
                    </div>
                </div>
            </div>

        </div>

    </div>
    
</template>

<script>

    import _find from 'lodash/find'
    import OddPrice from './OddPrice.vue'
    import store from '@/store'
    import RedCard from './RedCard.vue'

    export default {
        name: 'TableOddRow',
        components: {
            OddPrice,
            RedCard,
        },
        props: ['lineMode', 'mode', 'league', 'header_position_fix', 'i'],
        computed: {
            isLive: function(){
                if(this.mode === 'live'){
                    return 1;
                }else{
                    return 0;
                }
            },
            favorite_list: function(){
                return store.state.favorite_list;
            },
            favorite_selected: function(){
                return store.state.favorite_selected;
            },
            is_header_fix: function(){
                return this.i === this.header_position_fix;
            }
        },
        methods: {
            showFavorite: function(mode, fav_id){

                if(!this.favorite_selected){
                    return true;
                }

                let found;

                if(mode === 'league'){
                    found = _find(this.favorite_list, function(o){
                        return o.league_id === fav_id;
                    });
                }else if(mode === 'match'){
                    found = _find(this.favorite_list, function(o){
                        return o.fav_id === fav_id;
                    });
                }else{
                    return false;
                }

                return !!found;
            }
        }
    }
</script>

<style scoped>

    .row-header {
        display: flex;
        text-align: start;
        color: #ffffff;
        font-size: 14px;
    }
    .row-header > div {
        margin: 5px 0;
    }

    .bg-color-live {
        background-color: #FFDDCC;
    }

    .bg-color-non-live {
        background-color: #f2f2f2;
    }

    .league-bg-color-live {
        background-color: #b64531;
    }

    .league-bg-color-non-live {
        background-color: #23577e;
    }

    .v-row {
        display: flex;
        font-size: 14px;
        /*height: 45px;*/
        height: auto;
    }
    .v-row > div {
        padding: 5px 0;
    }
    .v-row.double {
        /*height: 57px;*/
        height: auto;
    }
    .v-row > div {
        padding: 2px 0;
    }

    .hdp-wrapper {
        height: 16px;
    }

    .enter-custom {
        height: 2px;
    }

    .border-white {
        border: solid white;
        border-width: 0 1px 1px 0;
    }

    @media only screen and (max-width: 820px) {
        .custom-sub-header-sticky{
            top: calc(45px + 38px + 30px);
        }
    }

    @media only screen and (min-width: 820px){
        .custom-sub-header-sticky{
            top: calc(50px + 30px);
        }
    }

    .custom-sub-header-sticky{
        position: sticky;
        z-index: 998;
    }
    .team-name {
        display: flex;
        align-items: center;
    }
</style>