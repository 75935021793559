<template>
    <div class="Home ChatRoom ChatRoomGame ChatRoom-horizontal" :class="{'chat-embed-mode': embedMode}" >
<!--        <div class="main-container-chat">-->
            <div class="btn-close-popupAllgame"></div>
            <MenuBar></MenuBar>
            <div class="box-profile-chat">
                <div class="image-profile-chat">
                    <img src="/images/set-profile/set1/026.png">
                    <marquee scrolldelay="150">streamer2</marquee>
                </div>
            </div>
            <div class="box-view-chat">
                <ul>
                    <li class="icon-view-chat">สด</li>
                    <li class="numView">
                        <span>211</span>
                    </li>
                </ul>
            </div>
            <transition name="slide-left">
                <div class="live-list-wrapper" v-show="isShowMenuRight">
                    <LiveList @close="closeMenuRight"></LiveList>
                </div>
            </transition>

            <div v-if="false" class="alertify-logs"
                 :class="{'fadeIN': is_slide_message_show, 'fadeOUT': !is_slide_message_show}">
                <marquee><i class="fa fa-bullhorn"></i> ขอแสดงความยินดีกับ <span>K.Devil45</span> ได้รับ
                    <strong>แจ็คพ็อต </strong> <span>4,500,000</span> จากเกมส์สล็อต
                </marquee>
            </div>

            <div v-if="false" @click="openGame('mini_wheel')" class="box-mini-wheel"></div>

            <AdvertiseGame v-if="false" />
            <div class="floatTopRight" style="top: 70px;">
                <div class="box-float-noti-shop">
                    <div class="shop-page" @click="toShopPage"></div>
                </div>

                <div class="box-float-sticker">
                    <div><img src="@/assets/images/profile1.jpg"></div>
                    <div class="box-txt-sticker">
                        <span>Chayapong Prommanee</span>
                        <span class="txt-small">ส่งของขวัญแล้ว 10 coin</span>
                        <div class="float-sticker"><img src="@/assets/images/sticker/pink-heart.png"></div>
                        <div class="float-number">x<strong>6</strong></div>
                    </div>
                </div>

                <LottoShortcut></LottoShortcut>
            </div>


            <transition v-if="false" name="slide">
                <MiniBaccarat v-if="current_game === 'mini_baccarat'" @exit="closeGame"></MiniBaccarat>
                <MiniWheel v-if="current_game === 'mini_wheel'" @exit="closeGame"></MiniWheel>
                <MiniSlot v-if="current_game === 'mini_slot'" @exit="closeGame"></MiniSlot>
            </transition>
            <div class="main-video">
            <div class="main-video-box">
                <div v-if="false" class="fullscreen-vertical">
                    <iframe width="560" height="315" src="https://www.youtube.com/embed/3M-fMMsCBoo" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
                </div>
                <!--                    <img src="@/assets/images/live-stream/3.jpg">-->
                <!--                    <iframe width="560" height="315" src="https://www.youtube.com/embed/G__Ee6hfPQY?controls=0&amp;start=1" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>&lt;!&ndash;                    <img src="@/assets/images/live-stream/2.jpg"/>&ndash;&gt;-->
                <div class="fullscreen-horizontal">
                    <iframe width="560" height="315" src="https://www.youtube.com/embed/o2qTLU6gyJg?start=1" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
                </div>

            </div>
        </div>
            <Chat></Chat>

            <div class="popup-sticker">
                <div class="wrapper-box-sticker">
                    <div class="box-icon-right"><i class="fa fa-box"></i> </div>
                    <nav>
                        <div class="nav nav-tabs nav-tabs-sticker" id="nav-tab" role="tablist">
                            <a class="nav-item nav-link active" id="nav-home-tab" data-toggle="tab" href="#nav-home"
                               role="tab" aria-controls="nav-home" aria-selected="true">กิจกรรม</a>
                            <a class="nav-item nav-link" id="nav-profile-tab" data-toggle="tab" href="#nav-profile"
                               role="tab" aria-controls="nav-profile" aria-selected="false">ฮอต</a>
                            <a class="nav-item nav-link" id="nav-contact-tab" data-toggle="tab" href="#nav-contact"
                               role="tab" aria-controls="nav-contact" aria-selected="false">กำหนดเอง</a>
                        </div>
                    </nav>
                    <div class="tab-content" id="nav-tabContent">
                        <div class="tab-pane fade show active" id="nav-home" role="tabpanel" aria-labelledby="nav-home-tab">
                            <div class="wrap-overflow-sticker">
                                <div>
                                    <div class="box-s-child active">
                                        <span>130xp</span>
                                        <img src="@/assets/images/sticker/1.gif">
                                        <p><img src="@/assets/images/icon-coin.png"> 1</p>
                                    </div>
                                    <div class="box-s-child">
                                        <img src="@/assets/images/sticker/2.gif">
                                        <p><img src="@/assets/images/icon-coin.png"> 10</p>
                                    </div>
                                </div>
                                <div>
                                    <div class="box-s-child">
                                        <img src="@/assets/images/sticker/3.gif">
                                        <p><img src="@/assets/images/icon-coin.png"> 30</p>
                                    </div>
                                    <div class="box-s-child">
                                        <img src="@/assets/images/sticker/4.gif">
                                        <p><img src="@/assets/images/icon-coin.png"> 199</p>
                                    </div>
                                </div>
                                <div>
                                    <div class="box-s-child">
                                        <img src="@/assets/images/sticker/5.gif">
                                        <p><img src="@/assets/images/icon-coin.png"> 299</p>
                                    </div>
                                    <div class="box-s-child">
                                        <img src="@/assets/images/sticker/6.gif">
                                        <p><img src="@/assets/images/icon-coin.png"> 1555</p>
                                    </div>
                                </div>
                                <div>
                                    <div class="box-s-child">
                                        <img src="@/assets/images/sticker/1.gif">
                                        <p><img src="@/assets/images/icon-coin.png"> 1999</p>
                                    </div>
                                    <div class="box-s-child">
                                        <img src="@/assets/images/sticker/1.gif">
                                        <p><img src="@/assets/images/icon-coin.png"> 2999</p>
                                    </div>
                                </div>
                                <div>
                                    <div class="box-s-child">
                                        <img src="@/assets/images/sticker/1.gif">
                                        <p><img src="@/assets/images/icon-coin.png"> 10</p>
                                    </div>
                                    <div class="box-s-child">
                                        <img src="@/assets/images/sticker/1.gif">
                                        <p><img src="@/assets/images/icon-coin.png"> 10</p>
                                    </div>
                                </div>
                            </div>
                            <div class="box-sending">
                                <div><span><img src="@/assets/images/icon-coin.png" style="width: 10px;"> 0</span> <i
                                        class="fa fa-chevron-right"></i></div>
                                <div>
                                    <div class="btn-group btn-group-sm" role="group"
                                         aria-label="Button group with nested dropdown">
                                        <div class="btn-group btn-group-sm" role="group">
                                            <button id="btnGroupDrop1" type="button"
                                                    class="btn btn-outline-pink dropdown-toggle" data-toggle="dropdown"
                                                    aria-haspopup="true" aria-expanded="false">
                                                1
                                            </button>
                                            <div class="dropdown-menu" aria-labelledby="btnGroupDrop1">
                                                <a class="dropdown-item" href="#">1</a>
                                                <a class="dropdown-item" href="#">10</a>
                                            </div>
                                        </div>
                                        <button type="button" class="btn btn-pink">ส่ง</button>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="tab-pane fade" id="nav-profile" role="tabpanel" aria-labelledby="nav-profile-tab">...
                        </div>
                        <div class="tab-pane fade" id="nav-contact" role="tabpanel" aria-labelledby="nav-contact-tab">...
                        </div>
                    </div>
                </div>
            </div>
            <el-dialog
                    :title="quickBetData['league_title'] || 'ทายผลฟุตบอล'"
                    :visible.sync="quickBetDialogVisible"
                    width="100%"
                    custom-class="custom-dialog"
                    append-to-body>
            <span v-if="quickBetDialogVisible">
                <FootballQuickBet
                        :match_id="quickBetData['match_id']"
                        :query_amount="quickBetData['amount']"
                        :query_team="quickBetData['team']"
                        @exit="quickBetDialogVisible = false"></FootballQuickBet>
            </span>
            </el-dialog>

<!--        </div>-->

        <div class="main-game-bottom">
<!--            MiniBaccarat-->
<!--            MiniDragonTiger-->
<!--            MiniFantan-->
<!--            MiniSicbo-->


            <MiniBaccarat noChat="yes"></MiniBaccarat>
<!--             <iframe src="https://h5c.cqgame.games/214/?language=zh-cn&token=guest"></iframe>-->
<!--            <img src="@/assets/images/simple-6.jpg">-->
        </div>
    </div>
</template>


<script>
    import LiveList from '@/components/LiveList'
    import AdvertiseGame from '@/components/AdvertiseGame'
    import MenuBar from '@/components/MenuBar'
    import MiniSlot from '@/components/MiniSlot'
    import MiniWheel from '@/components/MiniWheel'
    import MiniBaccarat from '@/components/game/MiniBaccarat'
    // import MiniDragonTiger from '@/components/game/MiniDragonTiger'
    // import MiniFantan from '@/components/game/MiniFantan'
    // import MiniSicbo from '@/components/game/MiniSicbo'
    import store from '@/store'
    import UserMixin from '@/mixins/UserMixin'
    import LayoutMixin from '@/mixins/Layout'
    import Chat from '@/components/Chat'
    import LottoShortcut from '@/components/LottoShortcut'
    import FootballQuickBet from '@/components/football/QuickBet'
    import SwipeMixin from '@/mixins/Swipe'

    export default {
        name: "ChatRoom",
        components: {
            AdvertiseGame,
            MenuBar,
            MiniSlot,
            MiniWheel,
            MiniBaccarat,
            // MiniDragonTiger,
            // MiniFantan,
            // MiniSicbo,
            Chat,
            LottoShortcut,
            FootballQuickBet,
            LiveList
        },
        mixins: [
            UserMixin,
            LayoutMixin,
            SwipeMixin,
        ],
        data() {
            return {
                is_slide_message_show: false,
                quickBetDialogVisible: false,
                quickBetData: {
                    match_id: '',
                    amount: 0,
                    team: '',
                },
                isShowMenuRight: false,
            }
        },
        computed: {
            token() {
                return this.pes_profile.token
            },
            pes_profile() {
                return store.state.pes_profile
            },
            is_member() {
                return store.state.is_member
            },
            embedMode() {
                return store.state.embedMode
            },
        },
        methods: {
            openGame(game) {
                this.closeLeftMenu()
                this.current_game = game
            },
            openWindow(url) {
                window.open(url, '_blank')
            },
            closeGame() {
                this.current_game = ''
            },
            toShopPage() {
                this.$router.push({name: 'Package'})
            },
            openMenuRight() {
                this.isShowMenuRight = true
            },
            closeMenuRight() {
                this.isShowMenuRight = false
            },
            handleSwipeLeft() {
                this.openMenuRight()
            },
            handleSwipeRight() {
                this.closeMenuRight()
            },
        },
        mounted() {

            const redirectPage = this.$route.query['redirect-page']

            if(redirectPage === 'football-quick-bet') {
                if(this.$route.query['amount']) {
                    this.quickBetData['amount'] = this.$route.query['amount']
                }
                if(this.$route.query['team']) {
                    this.quickBetData['team'] = this.$route.query['team']
                }
                if(this.$route.query['league_title']) {
                    this.quickBetData['league_title'] = this.$route.query['league_title']
                }
                if(this.$route.query['match_id']) {
                    this.quickBetData['match_id'] = this.$route.query['match_id']
                    this.quickBetDialogVisible = true
                }
            }

            // this.is_slide_message_show = true
            // setTimeout(() => {
            //     this.is_slide_message_show = false
            //     }, 1000*5)
            // setInterval(() => {
            //     this.is_slide_message_show = true
            //     setTimeout(() => {
            //     this.is_slide_message_show = false
            //     }, 1000*5)
            // }, 1000*7)
        }
    };
</script>

<style src="@/assets/css/slot.css" scoped></style>
<style>
    .Home .menu-click {
        display: block
    }
</style>
<style src="@/assets/css/home.css" scoped></style>
