<template>
    <div class="VideoPlayerFLV2Canvas">
        <div ref="flvplayer-app" class="flvplayer-app" style="pointer-events:none;"></div>
        <button @click="toggleMute" class="btn btn-light toggle-mute-button">
            <i v-show="isPlayerMute" class="fa fa-volume-mute"></i>
            <i v-show="!isPlayerMute" class="fa fa-volume-up"></i>
        </button>
    </div>
</template>
<script>

import FlvPlayer from 'flvplayer'
import './flvplayer-control.js'
import NoSleep from 'nosleep.js'
const noSleep = new NoSleep();
const REFRESH_RETRY_LIMIT = 10

export default {
    name: 'VideoPlayerFLV2Canvas',
    props: [
        'src',
        'width',
        'height',
        'playerBus',
        'isMute',
    ],
    data() {
        return {
            player: null,
            isPlayerMute: true,
            refreshRetry: 0,
            isLive: true,
        }
    },
    computed: {
        playerContainer() {
            return this.$refs['flvplayer-app']
        },
    },
    // watch: {
    //     width(newVal) {
    //         if(newVal) {
    //             this.setWrapperSize(newVal, this.height)
    //         }
    //     },
    //     height(newVal) {
    //         this.setWrapperSize(this.width, newVal)
    //     },
    // },
    methods: {
        setWrapperSize(width, height) {

            if(!this.player.$canvas) {
                return
            }

            this.player.$canvas.style.width = `${width}px`
            this.player.$canvas.style.height = `${height}px`
        },
        toggleMute() {
            this.isPlayerMute = !this.isPlayerMute
            if(this.player) {
                this.player.muted = this.isPlayerMute
            }
        },
        initPlayer() {
            if (FlvPlayer.isSupported()) {
                if(this.player) {
                    this.player.destroy()
                }
                this.player = new FlvPlayer({
                    url: this.src,
                    container: this.playerContainer,
                    poster: '/images/loading-spinner.gif',
                    debug: false,
                    live: this.isLive,
                    muted: true,
                    touchResume: true,
                    videoChunk: 1024 * 1024,
                    audioChunk: 16 * 1024,
                    autoPlay: true,
                    hasAudio: true,
                    cache: true,
                    control: false,
                    withCredentials: false,
                    filesize: Infinity,
                    cors: true,
                    volume: 1,
                    frameRate: 30,
                    width: this.width,
                    height: this.height,
                    decoder: (this.isLive) ? '/flvplayer-decoder-multiple.js' : '/flvplayer-decoder-baseline.js',
                });
                this.player.on('ready', () => {

                    if(typeof this.isMute === "boolean" && this.isMute) {
                        this.player.muted = true
                    }

                    // this.setWrapperSize(this.width, this.height)

                    const unlock = () => {
                        if(typeof this.isMute === "boolean" && this.isMute) {
                            this.player.muted = true
                        }
                        document.body.removeEventListener('touchstart', unlock, false);
                        document.body.removeEventListener('click', unlock, false);
                    };
                    document.body.addEventListener('touchstart', unlock, false);
                    document.body.addEventListener('click', unlock, false);
                })
                this.player.on('streamStart', () => {
                    console.log('VideoPlayerFLV2Canvas on streamStart')
                })
                this.player.on('play', () => {
                    this.refreshRetry = 0
                    console.log('VideoPlayerFLV2Canvas on play')
                    this.$emit('ready')
                    if(typeof this.isMute === "boolean" && !this.isMute) {
                        this.unMute()
                    }
                })
                this.player.on('waiting', () => {
                    console.log('VideoPlayerFLV2Canvas on waiting')
                })
                this.player.on('pause', () => {
                    console.log('VideoPlayerFLV2Canvas on pause')
                })
                this.player.on('streamEnd', () => {
                    console.log('VideoPlayerFLV2Canvas on streamEnd')
                    if(this.isLive) {
                        this.refresh()
                    }
                })
            } else {
                console.warn('Your browser does not support Flvplayer.js');
            }
        },
        refresh() {
            if(this.refreshRetry < REFRESH_RETRY_LIMIT) {
                this.initPlayer()
                this.refreshRetry++
            } else {
                this.$emit('error', 'VideoPlayerFLV2Canvas  on streamEnd')
            }
        },
        fullscreen() {

            const element = document.querySelector('.VideoPlayerFLV2Canvas canvas')
            
            const requestFullScreen = element.requestFullScreen || element.mozRequestFullScreen || element.webkitRequestFullScreen;
            if (!requestFullScreen) {
                this.$bvToast.toast('เบราว์เซอร์ไม่รองรับ ขออภัยในความไม่สะดวก', {
                    title: 'แจ้งเตือน',
                    toaster: 'b-toaster-top-full'
                })
                return
            }
            requestFullScreen.bind(element)();
            if (screen.orientation && screen.orientation.lock) {
                screen.orientation.lock('landscape');
            }
        },
        mute() {
            this.isPlayerMute = true
            if(this.player) {
                this.player.muted = this.isPlayerMute
            }
            
        },
        unMute() {
            this.isPlayerMute = false
            if(this.player) {
                this.player.muted = this.isPlayerMute
            }
        },
    },
    mounted() {
        this.initPlayer()
        document.addEventListener('click', function enableNoSleep() {
            document.removeEventListener('click', enableNoSleep, false);
            noSleep.enable();
        }, false);

        if(this.playerBus) {
            this.playerBus.$on('fullscreen', this.fullscreen)
            this.playerBus.$on('mute', this.mute)
            this.playerBus.$on('unMute', this.unMute)
        }
    },
    beforeDestroy() {
        if(this.player) {
            this.player.destroy()
        }
        noSleep.disable();

        if(this.playerBus) {
            this.playerBus.$off('fullscreen', this.fullscreen)
            this.playerBus.$off('mute', this.mute)
            this.playerBus.$off('unMute', this.unMute)
        }
    }
}
</script>
<style scoped>
.VideoPlayerFLV2Canvas {
    position: relative;
}
/*.toggle-mute-button {*/
/*    position: absolute;*/
/*    z-index: 102;*/
/*    bottom: 50px;*/
/*    left: 10px;*/
/*    width: 40px;*/
/*    height: 40px;*/
/*    border-radius: 50%;*/
/*}*/
@media (orientation: landscape) {
    .toggle-mute-button {
        /*bottom: 90px;*/
    }
}
</style>
<style>
/* .flvplayer-player {
    display: flex;
    justify-content: center;
    align-items: center;
} */
</style>