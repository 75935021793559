<template>
<div class="LineLoginLanding">
    <div v-if="error">
        <div>{{error}}</div>
        <div>{{error_description}}</div>
        <button @click="goHome">กลับ</button>
    </div>
    <div v-else-if="errMessage">
        <div>{{errMessage}}</div>
        <button @click="goHome">กลับ</button>
    </div>
    <div v-else>
        กำลังโหลด... กรุณารอ
    </div>
</div>
</template>
<script>

import store from '@/store'
import Line from '@/lib/Line'
import axiosWrapper from '@/axiosWrapper'
import {IMAGE_MAP} from '@/components/user/Constant'
import randomBetween from '@/lib/randomBetween'
import UserMixin from '@/mixins/UserMixin'

export default {
    name: 'LineLoginLanding',
    mixins: [
        UserMixin,
    ],
    props: [
        'mode', // login, sync
    ],
    data() {
        return {
            errMessage: '',
            userProfile: null,
        }
    },
    computed: {
        code() {
            return this.$route.query.code
        },
        state() {
            return this.$route.query.state
        },
        error() {
            return this.$route.query.error
        },
        error_description() {
            return this.$route.query.error_description
        },
        channel() {
            return store.state.channel
        },
        message_api_id() {
            return store.state.message_api_id
        },
        referral_code() {
            return store.state.referral_code
        },
        reward_id() {
            return store.state.reward_id
        },
        appInfo() {
            return store.state.appInfo
        },
        client_secret() {
            return this.appInfo.login_channel_secret
        },
        client_id() {
            return this.appInfo.login_client_id
        },
        redirectURL() {
            return localStorage.getItem('login_redirect_url')
        },
        pes_profile() {
            return store.state.pes_profile
        },
    },
    methods: {
        goHome() {
            if(this.mode === 'sync') {
                this.$router.replace({name: 'Account'})
            } else {
                this.$router.replace({name: 'Index'})
            }  
        },
        async _createNewUser() {
            const lineLoginProgress = await axiosWrapper({
                method: 'POST',
                url: `${this.envDomains.VUE_APP_LIFF_API}/api/user/line-login`,
                data: {
                    channel: this.channel,
                    message_api_id: this.message_api_id,
                    referral_code: this.referral_code,
                    reward_id: this.reward_id,
                    user_agent: navigator.userAgent,
                    web_referrer: document.referrer,
                    picture_url: IMAGE_MAP[0][randomBetween(0, IMAGE_MAP[0].length - 1)],
                    line_user_id: this.userProfile.userId,
                }
            })

            if(lineLoginProgress.status === 200) {
                if(lineLoginProgress.data.token) {
                    this.signIn(lineLoginProgress.data.token)
                    if(this.redirectURL) {
                        this.$router.replace(decodeURIComponent(this.redirectURL))
                    }
                    setTimeout(() => {
                        location.reload()
                    }, 100)
                } else {
                    console.log('lineLoginProgress err no token return')
                    this.errMessage = 'เกิดความผิดพลาดบางอย่าง กรุณาลองใหม่อีกครั้ง'
                }
            } else {
                console.log('lineLoginProgress err', lineLoginProgress.data.message)
                this.errMessage = lineLoginProgress.data.message
            }
        },
        async _syncWithExistUser() {
            const lineSyncProgress = await axiosWrapper({
                method: 'POST',
                url: `${this.envDomains.VUE_APP_LIFF_API}/api/user/line-sync`,
                data: {
                    token: this.pes_profile.token,
                    line_user_id: this.userProfile.userId,
                }
            })
            if(lineSyncProgress.status === 200) {
                if(this.redirectURL) {
                    this.$router.replace(decodeURIComponent(this.redirectURL))
                } else {
                    this.$router.replace({name: 'Account'})
                }
                setTimeout(() => {
                    location.reload()
                }, 100)
            } else {
                console.log('lineSyncProgress err', lineSyncProgress.data.message)
                this.errMessage = lineSyncProgress.data.message
            }
        },
    },
    async mounted() {
        try {
            if(this.error) {
                return
            }
            if(window.location.hostname.includes('ngrok')) {
                return
            }
            let redirect_uri =  ''
            if(this.mode === 'login') {
                redirect_uri =  `${window.location.origin}/line/login`
            } else if(this.mode === 'sync') {
                redirect_uri =`${window.location.origin}/line/sync`
            }
            if(process.env.NODE_ENV === 'development') {
                redirect_uri = 'https://dd580c58ebcf.ngrok.io/line/sync'
            }
            const getAccessTokenProcess = await Line.getAccessToken(this.code, this.client_id, this.client_secret, redirect_uri)
            if(getAccessTokenProcess.status !== 200) {
                this.errMessage = `${getAccessTokenProcess.data.error} ${getAccessTokenProcess.data.error_description}`
                console.log('getAccessTokenProcess err', getAccessTokenProcess.data)
                return
            }
            const accessToken = getAccessTokenProcess.data.access_token
            const getProfileProgress = await Line.getProfile(accessToken)
            if(getProfileProgress.status !== 200) {
                this.errMessage = `${getAccessTokenProcess.data.error} ${getAccessTokenProcess.data.error_description}`
                console.log('getProfileProgress err', getProfileProgress.data)
                return
            }
            this.userProfile = getProfileProgress.data

            if(this.mode === 'login') {
                this._createNewUser()
            } else if(this.mode === 'sync') {
                this._syncWithExistUser()
            }

        } catch(err) {
            console.log('LineLoginLanding err', err.message)
            this.errMessage = 'เกิดความผิดพลาดบางอย่าง กรุณาลองใหม่อีกครั้ง'
        }  
    },
}
</script>