<template>
    <div class="GameTableList">
        <div class="container-all-list-table">
            <div class="all-list-table">
                <div class="baccarat-game">
                    <div @click="play(table)" v-for="(table, i) of tableListFilter" :key="i" class="box-list-table">
                        <ul v-if="table.table_type!=5">
                            <li style="font-size: 12px;">{{table.table_name}}</li>
                            <li>
                                <i class="fas fa-user"></i>
                                <span>{{table.user_online}}</span>
                            </li>
                        </ul>
                        <div>
                            <SicboTableListItem v-if="table.table_type === TABLE_TYPE_KEY.sicbo"
                            :table_id="table.table_id"
                            :table_round_main="table.table_round_main"></SicboTableListItem>
                            <FantanTableListItem v-if="table.table_type === TABLE_TYPE_KEY.bean"
                            :table_id="table.table_id"
                            :table_round_main="table.table_round_main"></FantanTableListItem>
                            <DragonTigerTableListItem v-if="table.table_type === TABLE_TYPE_KEY.dragontiger"
                            :table_id="table.table_id"
                            :table_round_main="table.table_round_main"></DragonTigerTableListItem>
                            <BaccaratTableListItem v-if="table.table_type === TABLE_TYPE_KEY.baccarat || table.table_type === TABLE_TYPE_KEY.baccarat2"
                            :table_id="table.table_id"
                            :table_round_main="table.table_round_main"></BaccaratTableListItem>
                        </div>
                        <div v-if="false" class="content-table-info">
                            <div class="content-table-info-left">

                                <!--Hilo-->

                                <div class="sec-Hilo box-table-list">
                                    <div class="b-child-tb">
                                        <div class="user-list-detail">
                                            <div class="row-content-his">
                                                <div class="box-dices">
                                                    <div class="list-dice dice-cs">
                                                        <span class="dice dice-3"></span>
                                                        <span class="dice dice-1"></span>
                                                        <span class="dice dice-1"></span>
                                                        <span class="text-hi">สูง</span>
                                                        <span class="dice-txt">5</span>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="user-list-detail">
                                            <div class="row-content-his">
                                                <div class="box-dices">
                                                    <div class="list-dice dice-cs">
                                                        <span class="dice dice-3"></span>
                                                        <span class="dice dice-1"></span>
                                                        <span class="dice dice-1"></span>
                                                        <span class="text-lo">ต่ำ</span>
                                                        <span class="dice-txt">5</span>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="user-list-detail">
                                            <div class="row-content-his">
                                                <div class="box-dices">
                                                    <div class="list-dice dice-cs">
                                                        <span class="dice dice-3"></span>
                                                        <span class="dice dice-1"></span>
                                                        <span class="dice dice-1"></span>
                                                        <span class="text-hi">สูง</span>
                                                        <span class="dice-txt">5</span>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="user-list-detail">
                                            <div class="row-content-his">
                                                <div class="box-dices">
                                                    <div class="list-dice dice-cs">
                                                        <span class="dice dice-3"></span>
                                                        <span class="dice dice-1"></span>
                                                        <span class="dice dice-1"></span>
                                                        <span class="text-lo">ต่ำ</span>
                                                        <span class="dice-txt">5</span>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>

                                <!--Fantan-->

                                <div class="sec-Fantan box-table-list hide">
                                    <div class="b-child-tb">
                                        <div class="user-list-detail">
                                            <div class="row-content-his">
                                                <div class="box-dices">
                                                    <div class="list-dice dice-cs">
                                                        <span class="dice dice-3"></span>
                                                        <span class="dice dice-1"></span>
                                                        <span class="dice dice-1"></span>
                                                        <span class="text-bgwhite color-white">1</span>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="user-list-detail">
                                            <div class="row-content-his">
                                                <div class="box-dices">
                                                    <div class="list-dice dice-cs">
                                                        <span class="dice dice-3"></span>
                                                        <span class="dice dice-1"></span>
                                                        <span class="dice dice-1"></span>
                                                        <span class="text-bgwhite color-red">4</span>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="user-list-detail">
                                            <div class="row-content-his">
                                                <div class="box-dices">
                                                    <div class="list-dice dice-cs">
                                                        <span class="dice dice-3"></span>
                                                        <span class="dice dice-1"></span>
                                                        <span class="dice dice-1"></span>
                                                        <span class="text-bgwhite color-green">2</span>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="user-list-detail">
                                            <div class="row-content-his">
                                                <div class="box-dices">
                                                    <div class="list-dice dice-cs">
                                                        <span class="dice dice-3"></span>
                                                        <span class="dice dice-1"></span>
                                                        <span class="dice dice-1"></span>
                                                        <span class="text-bgwhite color-yellow">3</span>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>

                                <!--Baccarat, DragonTiger-->

                                <div class="sec-Baccarat box-table-list hide">
                                    <div class="b-child-tb">
                                        <div class="info-panel-play-state">
                                            <div class="box-score-table" id="stat-box-bottom" style="height: 110px;">
                                                <div class="column1">
                                                    <div class="score-scroll score-scroll1" id="big_road">
                                                        <table border="1" cellpadding="0" cellspacing="0">
                                                            <tbody>
                                                            <tr>
                                                                <td width="1" height="2" class="board-backgroundx"
                                                                    style="border-width:1px; border-style:dotted; border-color:#DDDDDD">
                                                                    <div class="text-score_bigroad text-center text-box-score-right_all"><span
                                                                            class="box-lot-cell_sm border-green"></span>
                                                                    </div>
                                                                </td>
                                                                <td width="1" height="2" class="board-backgroundx"
                                                                    style="border-width:1px; border-style:dotted; border-color:#DDDDDD">
                                                                    <div class="text-score_bigroad text-center text-box-score-right_all"><span
                                                                            class="box-lot-cell_sm border-blue"></span>
                                                                    </div>
                                                                </td>
                                                                <td width="1" height="2" class="board-backgroundx"
                                                                    style="border-width:1px; border-style:dotted; border-color:#DDDDDD">
                                                                    <div class="text-score_bigroad text-center text-box-score-right_all"><span
                                                                            class="box-lot-cell_sm border-red"></span>
                                                                    </div>
                                                                </td>
                                                                <td width="1" height="2" class="board-backgroundx"
                                                                    style="border-width:1px; border-style:dotted; border-color:#DDDDDD">
                                                                    <div class="text-score_bigroad text-center text-box-score-right_all"><span
                                                                            class="box-lot-cell_sm border-red"></span>
                                                                    </div>
                                                                </td>
                                                                <td width="1" height="2" class="board-backgroundx"
                                                                    style="border-width:1px; border-style:dotted; border-color:#DDDDDD">
                                                                    <div class="text-score_bigroad text-center text-box-score-right_all"><span
                                                                            class="box-lot-cell_sm border-blue"></span>
                                                                    </div>
                                                                </td>
                                                                <td width="1" height="2" class="board-backgroundx"
                                                                    style="border-width:1px; border-style:dotted; border-color:#DDDDDD">
                                                                    <div class="text-score_bigroad text-center text-box-score-right_all"><span
                                                                            class="box-lot-cell_sm border-blue"></span>
                                                                    </div>
                                                                </td>
                                                                <td width="1" height="2" class="board-backgroundx"
                                                                    style="border-width:1px; border-style:dotted; border-color:#DDDDDD">
                                                                    <div class="text-score_bigroad text-center text-box-score-right_all"><span
                                                                            class="box-lot-cell_sm border-blue"></span>
                                                                    </div>
                                                                </td>
                                                            </tr>
                                                            <tr>
                                                                <td width="1" height="2" class="board-backgroundx"
                                                                    style="border-width:1px; border-style:dotted; border-color:#DDDDDD">
                                                                    <div class="text-score_bigroad text-center text-box-score-right_all"><span
                                                                            class="box-lot-cell_sm border-red"></span>
                                                                    </div>
                                                                </td>
                                                                <td width="1" height="2" class="board-backgroundx"
                                                                    style="border-width:1px; border-style:dotted; border-color:#DDDDDD">
                                                                    <div class="text-score_bigroad text-center text-box-score-right_all"><span
                                                                            class="box-lot-cell_sm border-blue"></span>
                                                                    </div>
                                                                </td>
                                                                <td width="1" height="2" class="board-backgroundx"
                                                                    style="border-width:1px; border-style:dotted; border-color:#DDDDDD">
                                                                    <div class="text-score_bigroad text-center text-box-score-right_all"></div>
                                                                </td>
                                                                <td width="1" height="2" class="board-backgroundx"
                                                                    style="border-width:1px; border-style:dotted; border-color:#DDDDDD">
                                                                    <div class="text-score_bigroad text-center text-box-score-right_all"></div>
                                                                </td>
                                                                <td width="1" height="2" class="board-backgroundx"
                                                                    style="border-width:1px; border-style:dotted; border-color:#DDDDDD">
                                                                    <div class="text-score_bigroad text-center text-box-score-right_all"></div>
                                                                </td>
                                                                <td width="1" height="2" class="board-backgroundx"
                                                                    style="border-width:1px; border-style:dotted; border-color:#DDDDDD">
                                                                    <div class="text-score_bigroad text-center text-box-score-right_all"></div>
                                                                </td>
                                                                <td width="1" height="2" class="board-backgroundx"
                                                                    style="border-width:1px; border-style:dotted; border-color:#DDDDDD">
                                                                    <div class="text-score_bigroad text-center text-box-score-right_all"></div>
                                                                </td>
                                                            </tr>
                                                            <tr>
                                                                <td width="1" height="2" class="board-backgroundx"
                                                                    style="border-width:1px; border-style:dotted; border-color:#DDDDDD">
                                                                    <div class="text-score_bigroad text-center text-box-score-right_all"><span
                                                                            class="box-lot-cell_sm border-red"></span>
                                                                    </div>
                                                                </td>
                                                                <td width="1" height="2" class="board-backgroundx"
                                                                    style="border-width:1px; border-style:dotted; border-color:#DDDDDD">
                                                                    <div class="text-score_bigroad text-center text-box-score-right_all"><span
                                                                            class="box-lot-cell_sm border-blue"></span>
                                                                    </div>
                                                                </td>
                                                                <td width="1" height="2" class="board-backgroundx"
                                                                    style="border-width:1px; border-style:dotted; border-color:#DDDDDD">
                                                                    <div class="text-score_bigroad text-center text-box-score-right_all"></div>
                                                                </td>
                                                                <td width="1" height="2" class="board-backgroundx"
                                                                    style="border-width:1px; border-style:dotted; border-color:#DDDDDD">
                                                                    <div class="text-score_bigroad text-center text-box-score-right_all"></div>
                                                                </td>
                                                                <td width="1" height="2" class="board-backgroundx"
                                                                    style="border-width:1px; border-style:dotted; border-color:#DDDDDD">
                                                                    <div class="text-score_bigroad text-center text-box-score-right_all"></div>
                                                                </td>
                                                                <td width="1" height="2" class="board-backgroundx"
                                                                    style="border-width:1px; border-style:dotted; border-color:#DDDDDD">
                                                                    <div class="text-score_bigroad text-center text-box-score-right_all"></div>
                                                                </td>
                                                                <td width="1" height="2" class="board-backgroundx"
                                                                    style="border-width:1px; border-style:dotted; border-color:#DDDDDD">
                                                                    <div class="text-score_bigroad text-center text-box-score-right_all"></div>
                                                                </td>
                                                            </tr>
                                                            <tr>
                                                                <td width="1" height="2" class="board-backgroundx"
                                                                    style="border-width:1px; border-style:dotted; border-color:#DDDDDD">
                                                                    <div class="text-score_bigroad text-center text-box-score-right_all"></div>
                                                                </td>
                                                                <td width="1" height="2" class="board-backgroundx"
                                                                    style="border-width:1px; border-style:dotted; border-color:#DDDDDD">
                                                                    <div class="text-score_bigroad text-center text-box-score-right_all"></div>
                                                                </td>
                                                                <td width="1" height="2" class="board-backgroundx"
                                                                    style="border-width:1px; border-style:dotted; border-color:#DDDDDD">
                                                                    <div class="text-score_bigroad text-center text-box-score-right_all"></div>
                                                                </td>
                                                                <td width="1" height="2" class="board-backgroundx"
                                                                    style="border-width:1px; border-style:dotted; border-color:#DDDDDD">
                                                                    <div class="text-score_bigroad text-center text-box-score-right_all"></div>
                                                                </td>
                                                                <td width="1" height="2" class="board-backgroundx"
                                                                    style="border-width:1px; border-style:dotted; border-color:#DDDDDD">
                                                                    <div class="text-score_bigroad text-center text-box-score-right_all"></div>
                                                                </td>
                                                                <td width="1" height="2" class="board-backgroundx"
                                                                    style="border-width:1px; border-style:dotted; border-color:#DDDDDD">
                                                                    <div class="text-score_bigroad text-center text-box-score-right_all"></div>
                                                                </td>
                                                                <td width="1" height="2" class="board-backgroundx"
                                                                    style="border-width:1px; border-style:dotted; border-color:#DDDDDD">
                                                                    <div class="text-score_bigroad text-center text-box-score-right_all"></div>
                                                                </td>
                                                            </tr>
                                                            <tr>
                                                                <td width="1" height="2" class="board-backgroundx"
                                                                    style="border-width:1px; border-style:dotted; border-color:#DDDDDD">
                                                                    <div class="text-score_bigroad text-center text-box-score-right_all"></div>
                                                                </td>
                                                                <td width="1" height="2" class="board-backgroundx"
                                                                    style="border-width:1px; border-style:dotted; border-color:#DDDDDD">
                                                                    <div class="text-score_bigroad text-center text-box-score-right_all"></div>
                                                                </td>
                                                                <td width="1" height="2" class="board-backgroundx"
                                                                    style="border-width:1px; border-style:dotted; border-color:#DDDDDD">
                                                                    <div class="text-score_bigroad text-center text-box-score-right_all"></div>
                                                                </td>
                                                                <td width="1" height="2" class="board-backgroundx"
                                                                    style="border-width:1px; border-style:dotted; border-color:#DDDDDD">
                                                                    <div class="text-score_bigroad text-center text-box-score-right_all"></div>
                                                                </td>
                                                                <td width="1" height="2" class="board-backgroundx"
                                                                    style="border-width:1px; border-style:dotted; border-color:#DDDDDD">
                                                                    <div class="text-score_bigroad text-center text-box-score-right_all"></div>
                                                                </td>
                                                                <td width="1" height="2" class="board-backgroundx"
                                                                    style="border-width:1px; border-style:dotted; border-color:#DDDDDD">
                                                                    <div class="text-score_bigroad text-center text-box-score-right_all"></div>
                                                                </td>
                                                                <td width="1" height="2" class="board-backgroundx"
                                                                    style="border-width:1px; border-style:dotted; border-color:#DDDDDD">
                                                                    <div class="text-score_bigroad text-center text-box-score-right_all"></div>
                                                                </td>
                                                            </tr>
                                                            <tr>
                                                                <td width="1" height="2" class="board-backgroundx"
                                                                    style="border-width:1px; border-style:dotted; border-color:#DDDDDD">
                                                                    <div class="text-score_bigroad text-center text-box-score-right_all"></div>
                                                                </td>
                                                                <td width="1" height="2" class="board-backgroundx"
                                                                    style="border-width:1px; border-style:dotted; border-color:#DDDDDD">
                                                                    <div class="text-score_bigroad text-center text-box-score-right_all"></div>
                                                                </td>
                                                                <td width="1" height="2" class="board-backgroundx"
                                                                    style="border-width:1px; border-style:dotted; border-color:#DDDDDD">
                                                                    <div class="text-score_bigroad text-center text-box-score-right_all"></div>
                                                                </td>
                                                                <td width="1" height="2" class="board-backgroundx"
                                                                    style="border-width:1px; border-style:dotted; border-color:#DDDDDD">
                                                                    <div class="text-score_bigroad text-center text-box-score-right_all"></div>
                                                                </td>
                                                                <td width="1" height="2" class="board-backgroundx"
                                                                    style="border-width:1px; border-style:dotted; border-color:#DDDDDD">
                                                                    <div class="text-score_bigroad text-center text-box-score-right_all"></div>
                                                                </td>
                                                                <td width="1" height="2" class="board-backgroundx"
                                                                    style="border-width:1px; border-style:dotted; border-color:#DDDDDD">
                                                                    <div class="text-score_bigroad text-center text-box-score-right_all"></div>
                                                                </td>
                                                                <td width="1" height="2" class="board-backgroundx"
                                                                    style="border-width:1px; border-style:dotted; border-color:#DDDDDD">
                                                                    <div class="text-score_bigroad text-center text-box-score-right_all"></div>
                                                                </td>
                                                            </tr>
                                                            <tr>
                                                                <td width="1" height="2" class="board-backgroundx"
                                                                    style="border-width:1px; border-style:dotted; border-color:#DDDDDD">
                                                                    <div class="text-score_bigroad text-center text-box-score-right_all"></div>
                                                                </td>
                                                                <td width="1" height="2" class="board-backgroundx"
                                                                    style="border-width:1px; border-style:dotted; border-color:#DDDDDD">
                                                                    <div class="text-score_bigroad text-center text-box-score-right_all"></div>
                                                                </td>
                                                                <td width="1" height="2" class="board-backgroundx"
                                                                    style="border-width:1px; border-style:dotted; border-color:#DDDDDD">
                                                                    <div class="text-score_bigroad text-center text-box-score-right_all"></div>
                                                                </td>
                                                                <td width="1" height="2" class="board-backgroundx"
                                                                    style="border-width:1px; border-style:dotted; border-color:#DDDDDD">
                                                                    <div class="text-score_bigroad text-center text-box-score-right_all"></div>
                                                                </td>
                                                                <td width="1" height="2" class="board-backgroundx"
                                                                    style="border-width:1px; border-style:dotted; border-color:#DDDDDD">
                                                                    <div class="text-score_bigroad text-center text-box-score-right_all"></div>
                                                                </td>
                                                                <td width="1" height="2" class="board-backgroundx"
                                                                    style="border-width:1px; border-style:dotted; border-color:#DDDDDD">
                                                                    <div class="text-score_bigroad text-center text-box-score-right_all"></div>
                                                                </td>
                                                                <td width="1" height="2" class="board-backgroundx"
                                                                    style="border-width:1px; border-style:dotted; border-color:#DDDDDD">
                                                                    <div class="text-score_bigroad text-center text-box-score-right_all"></div>
                                                                </td>
                                                            </tr>
                                                            <tr>
                                                                <td width="1" height="2" class="board-backgroundx"
                                                                    style="border-width:1px; border-style:dotted; border-color:#DDDDDD">
                                                                    <div class="text-score_bigroad text-center text-box-score-right_all"></div>
                                                                </td>
                                                                <td width="1" height="2" class="board-backgroundx"
                                                                    style="border-width:1px; border-style:dotted; border-color:#DDDDDD">
                                                                    <div class="text-score_bigroad text-center text-box-score-right_all"></div>
                                                                </td>
                                                                <td width="1" height="2" class="board-backgroundx"
                                                                    style="border-width:1px; border-style:dotted; border-color:#DDDDDD">
                                                                    <div class="text-score_bigroad text-center text-box-score-right_all"></div>
                                                                </td>
                                                                <td width="1" height="2" class="board-backgroundx"
                                                                    style="border-width:1px; border-style:dotted; border-color:#DDDDDD">
                                                                    <div class="text-score_bigroad text-center text-box-score-right_all"></div>
                                                                </td>
                                                                <td width="1" height="2" class="board-backgroundx"
                                                                    style="border-width:1px; border-style:dotted; border-color:#DDDDDD">
                                                                    <div class="text-score_bigroad text-center text-box-score-right_all"></div>
                                                                </td>
                                                                <td width="1" height="2" class="board-backgroundx"
                                                                    style="border-width:1px; border-style:dotted; border-color:#DDDDDD">
                                                                    <div class="text-score_bigroad text-center text-box-score-right_all"></div>
                                                                </td>
                                                                <td width="1" height="2" class="board-backgroundx"
                                                                    style="border-width:1px; border-style:dotted; border-color:#DDDDDD">
                                                                    <div class="text-score_bigroad text-center text-box-score-right_all"></div>
                                                                </td>
                                                            </tr>
                                                            </tbody>
                                                        </table>
                                                    </div>
                                                </div>
                                            </div>
                                            <!--<img src="@/assets/images/simple-4.jpg">-->
                                        </div>
                                    </div>
                                </div>


                            </div>
                            <div class="content-table-info-right">

                                <!--Hilo-->

                                <div class="sec-Hilo w-100">
                                    <div class="box-game-result">
                                        <div class="user-list-detail border-0">
                                            <div class="box-blocker">
                                                <div class="box-dices">
                                                    <div class="list-dice dice-lg">
                                                        <span class="dice dice-3"></span>
                                                        <span class="dice dice-1"></span>
                                                        <span class="dice dice-1"></span>
                                                    </div>
                                                </div>
                                                <div><span class="dice-txt text-white">14</span> <span class="text-lo">ต่ำ</span>
                                                </div>
                                            </div>
                                        </div>

                                    </div>
                                </div>

                                <!--Fantan-->

                                <div class="sec-Fantan w-100 hide">
                                    <div class="box-game-result">
                                        <div class="user-list-detail border-0">
                                            <div class="box-blocker">
                                                <div class="box-dices">
                                                    <div class="list-dice dice-lg">
                                                        <span class="dice dice-3"></span>
                                                        <span class="dice dice-1"></span>
                                                        <span class="dice dice-1"></span>
                                                    </div>
                                                </div>
                                                <div><span class="text-bgwhite color-red">4</span>
                                                </div>
                                            </div>
                                        </div>

                                    </div>
                                </div>

                                <!--Baccarat-->

                                <div class="sec-baccarat w-100 hide">
                                    <div class="box-current-result">
                                        <div class="current-box color-red">Player</div>
                                        <p><small>1 : 1</small></p>
                                        <p class="point-result">24</p>
                                    </div>
                                </div>

                                <!--<div class="bx-left-info">-->
                                    <!--<div class="bx-info-float">-->
                                        <!--<strong>P</strong>-->
                                        <!--<p>Player</p>-->
                                        <!--<p>1:1</p>-->
                                    <!--</div>-->
                                <!--</div>-->
                                <!--<div class="bx-right-info">-->
                                    <!--<div class="bx-info-float">-->
                                        <!--<strong>B</strong>-->
                                        <!--<p>Banker</p>-->
                                        <!--<p>1:0.95</p>-->
                                    <!--</div>-->
                                <!--</div>-->


                                <!--&lt;!&ndash;DragonTiger&ndash;&gt;-->

                                <!--<div class="bx-left-info">-->
                                    <!--<div class="bx-info-float">-->
                                        <!--<strong>P</strong>-->
                                        <!--<p>Player</p>-->
                                        <!--<p>1:1</p>-->
                                    <!--</div>-->
                                <!--</div>-->
                                <!--<div class="bx-right-info">-->
                                    <!--<div class="bx-info-float">-->
                                        <!--<strong>B</strong>-->
                                        <!--<p>Banker</p>-->
                                        <!--<p>1:0.95</p>-->
                                    <!--</div>-->
                                <!--</div>-->


                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="all-list-game" id="AllListGame">
                <ul>
                    <li class="btn-Baccarat"
                        :class="{'active': currentTableType === 'all'}"
                        @click="selectTableType('all')">
                        <img src="@/assets/images/simple-1.jpg"/>
                        <span>ทั้งหมด</span>
                    </li>
                    <li v-for="(tableType, key) of tableTypeArr" :key="key"
                        class="btn-Baccarat"
                        :class="{'active': currentTableType === key}"
                        @click="selectTableType(key)">
                        <img src="@/assets/images/simple-1.jpg"/>
                        <span>{{tableType.title}}</span>
                    </li>
                </ul>
            </div>
        </div>
    </div>
</template>
<script>

    import {TABLE_TYPE_MAP, TABLE_TYPE_KEY} from './Constant'
    import SicboTableListItem from './sicbo/TableListItem'
    import FantanTableListItem from './fantan/TableListItem'
    import DragonTigerTableListItem from './dragontiger/TableListItem'
    import BaccaratTableListItem from './baccarat/TableListItem'

    export default {
        name: 'GameTableList',
        props: [
            'tableList'
        ],
        components: {
            SicboTableListItem,
            FantanTableListItem,
            DragonTigerTableListItem,
            BaccaratTableListItem,
        },
        data() {
            return {
                tableTypeArr: [],
                TABLE_TYPE_KEY: null,
                currentTableType: 'all',
            }
        },
        computed: {
            tableListFilter() {

                const sort = (list) => {
                    const compare = function (a, b) {
                        const table_type_a = parseFloat(a['table_type'])
                        const table_type_b = parseFloat(b['table_type'])
                        const tableNameA = a['table_name']
                        const tableNameB = b['table_name']
                        if(table_type_a > table_type_b) {
                            return 1
                        }
                        if(table_type_b > table_type_a) {
                            return -1
                        }
                        if(tableNameA > tableNameB) {
                            return 1
                        }
                        if(tableNameB > tableNameA) {
                            return -1
                        }
                        return 0;
                    };
                    return list.sort(compare);
                }

                // const tableListFiltered = this.tableList.filter((table) => {
                //     if(!this.isOnDev) {
                //         if(parseInt(table.table_type) === TABLE_TYPE_KEY.baccarat2) {
                //             return false
                //         }
                //         if(parseInt(table.table_type) === TABLE_TYPE_KEY.baccarat3) {
                //             return false
                //         }
                //     }
                //     return true
                // })

                // if (this.currentTableType === 'all') {
                //     return sort(tableListFiltered)
                // }
                // return sort(tableListFiltered).filter((table) => {
                //     return parseInt(table.table_type) === parseInt(this.currentTableType)
                // })

                if (this.currentTableType === 'all') {
                    return sort(this.tableList)
                }
                return sort(this.tableList).filter((table) => {
                    return parseInt(table.table_type) === parseInt(this.currentTableType)
                })
            },
        },
        methods: {
            selectTableType(tableType) {
                this.currentTableType = tableType
            },
            play(table) {
                this.$router.replace({
                    name: TABLE_TYPE_MAP[table.table_type]['routeName'],
                    query: {
                        table_id: table.table_id
                    },
                })
            }
        },
        created() {
            for (const key in TABLE_TYPE_MAP) {
                // if(!this.isOnDev) {
                //     if(parseInt(key) === TABLE_TYPE_KEY.baccarat2) {
                //         continue
                //     }
                //     if(parseInt(key) === TABLE_TYPE_KEY.baccarat3) {
                //         continue
                //     }
                // }
                if(parseInt(key) === TABLE_TYPE_KEY.baccarat3) {
                        continue
                }
                this.tableTypeArr.push(TABLE_TYPE_MAP[key])
            }
            this.TABLE_TYPE_KEY = TABLE_TYPE_KEY
        }
    }
</script>

<style scoped>
    .box-table-list {
        width: 100%;
        background-color: #fff;
        color: #000;
        padding: 5px;
        display: flex;
    }

    .box-table-list > .b-child-tb {
        width: 100%;
    }

    .dice-cs {
        font-size: inherit;
        color: #000;
        margin: 0px;
    }

    .dice-cs span {
        margin-right: 2px;
    }

    .dice-txt {
        color: #000;
        font-size: 14px;
    }

    .content-table-info-right {

    }

    .box-game-result {
        text-align: center;
        width: 100%;
        color: #fff;

    }

    .user-list-detail {
        border-bottom: solid 1px #eee;
    }

    .dice-lg span.dice {
        display: inline-block;
        min-height: 2em;
        width: 19px;
        margin: 0 auto;
        background-size: 18px;
        background-position: center;
        background-repeat: no-repeat;
    }

    span.text-lo, span.text-hi, span.text-bgwhite {
        width: 17px !important;
        height: 17px !important;
        padding: 1px 2px;
        line-height: 12px;
        font-size: 12px !important;
        margin: 0px 5px;
        box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
    }

    span.text-bgwhite {
        color: #000;
        padding: 0px 8px;
        font-weight: 600;
        border-radius: 3px;
        box-shadow: rgba(0, 0, 0, 0.16) 0px 1px 4px;
    }

    .box-blocker {
        margin-top: 30px;
    }

    .color-white {
        background-color: #fff;
        color: #000;
    }

    .color-red {
        background-color: #c52828 !important;
        color: #fff !important;
    }

    .color-green {
        background-color: #28a745 !important;
        color: #fff !important;
    }

    .color-yellow {
        background-color: #fab600;
        color: #fff !important;
    }

    .box-score-table {
        display: flex;
    }

    .box-score-table table {
        border: 0 !important;
        width: 100%;
        height: 100%;
    }

    .box-score-table td {
        border-collapse: collapse;
    }

    .column {
        display: flex;
        float: left;
        width: 41%;
        border-right: solid 1px;
        /*border-right: 1px;*/
        /*border-left: 1px;*/
        /*border-style: solid;*/
        /*border-color: rgb(66, 65, 65);*/
    }

    .column1 {
        float: left;
        width: 100%;
    }

    .box-score-table .score-scroll {
        margin: 0;
        width: auto;
    }

    .score-scroll:hover {
        overflow-x: overlay;
    }

    div.score-scroll {
        display: block;
        position: relative;
        width: 100%;
        overflow-x: hidden;
        overflow-y: hidden;
        white-space: nowrap;
        border-bottom: solid 1px #000;
    }

    .score-scroll {
        background-color: transparent;
    }

    .board-backgroundx {
        border: solid 1px #ddd !important;
    }

    .text-score_bigroad {
        /* font-size: 14px; */
        width: 9px;
        height: 9px;
        display: block;
        margin: 1px;
    }

    .text-score {
        width: 15.5px;
        height: 15.5px;
        display: block;
        margin: 2px;
    }

    .text-score, .text-score-status, .text-score_all, .text-score-topic, .text-status-primary, .text-status-secondary, .text-red-bcc, .text-score_eye, .text-score_bigroad, .text-blue-bcc, .text-score_bigeye, .text-score_small {
        text-shadow: 0.5px 0.5px 0.5px #eee;
    }

    .text-box-score-right_eye {
        width: 4px;
        height: 2px;
    }

    .text-box-score-right_eye {
        display: table;
        margin-top: 1px;
        margin-bottom: 1px;
        margin-right: 1px;
        margin-left: 1px;
        padding-top: 1px;
    }

    .text-box-score-right_small {
        width: 2px;
        height: 2px;
    }

    .text-box-score-right_small {
        display: table;
        margin-top: 1px;
        margin-bottom: 1px;
        margin-right: 1px;
        margin-left: 1px;
        padding-top: 1px;
    }

    .color-red {
        background-color: #c52828 !important;
    }

    .color-blue {
        background-color: #006aff !important;
    }

    .color-yellow {
        background-color: #fab600;
    }

    .box-lot-cell {
        text-align: center;
        font-size: 13px;
        width: 18px;
        height: 18px;
        border-radius: 50%;
        cursor: pointer;
        display: inline-block;
        line-height: 17px;
        color: #fff;
        float: left;
        margin: 0 auto;
        font-family: "Athiti", sans-serif;
        margin-top: -1px;
        margin-left: -2px;
        position: relative;
    }

    .box-lot-cell_sm, .box-lot-cell_mini {
        width: 15px;
        height: 15px;
        border-radius: 50%;
        display: inline-block;
        float: left;
        font-family: "Athiti", sans-serif;
        margin-top: -1px;
        margin-left: -2px;
        border: solid 2px;
    }

    .box-lot-cell_mini {
        width: 6px;
        height: 6px;
        float: right;
    }

    .float-dot {
        display: block;
        width: 8px;
        height: 8px;
        border-radius: 50%;
        position: absolute;
        border: solid 1px #fff;
    }

    .dot-red {
        background-color: #c52828 !important;
        top: -1px;
        left: -2px;
    }

    .dot-blue {
        background-color: #006aff !important;
        bottom: -1px;
        right: 0px;
    }

    .border-red {
        border-color: #c52828 !important;
    }

    .border-blue {
        border-color: #006aff !important;
    }

    .border-green {
        border-color: #28a745 !important;
    }

    .content-table-info-right strong {
        font-size: 8vw !important;
    }

    .box-st {
        padding: 5px;
        border-bottom: solid 1px #000;
    }

    .cols-1, .cols-2 {
        display: flex;
        color: #fff;
        text-align: center;
    }

    .cols-1 span, .cols-2 span {
        padding: 4px;
        margin: 2px;
        font-size: 16px;
        font-weight: 500;
        border: solid 1px #000;
        background-color: rgba(0, 0, 0, 0.4);
    }

    .cols-1 span {
        width: 33.3%;
    }

    .cols-2 span {
        width: 50%;
    }

    .box-current-result{
        text-align: center;
        color: #fff;
        font-family: "Teko", sans-serif;
        padding: 10px 5px;
    }

    .box-current-result p{
        margin: 0px;
        font-size: 1.6em;
    }

    .box-current-result > .current-box{
        display: inline-block;
        margin-bottom: 10px;
        border-radius: 5px;
        font-size: 2em;
        padding: 2px 10px 0px 10px;
        text-transform: uppercase;
    }

    .point-result{
        font-size: 2.2em !important;
    }




</style>