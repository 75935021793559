<template>
    <div class="ResultPrevious">
        <table v-if="arr.length > 0">
            <thead>
            <tr>
<!--                <th>{{lotto_type | lottoTypeDisplay}}งวดวันที่</th>-->
                <th>วันที่</th>
                <th v-if="lotto_type === 'government'">รางวัลที่ 1</th>
                <th v-if="lotto_type !== 'government'">3 ตัวบน</th>
                <th>2 ตัวล่าง</th>
                <th v-if="lotto_type === 'government'">3 ตัวหน้า</th>
                <th v-if="lotto_type === 'government'">3 ตัวล่าง</th>
            </tr>
            </thead>
            <tbody>
            <tr v-for="(item, index) of arr" :key="index" v-show="index > 0">
                <td>{{item.round.date | dateFormat}}</td>
                <td v-if="lotto_type === 'government'">{{item.first || 'XXXXXX'}}</td>
                <td v-if="lotto_type !== 'government'">{{item.sam_tua_bon || 'XXX'}}</td>
                <td>{{item.song_tua_lang || 'XX'}}</td>
                <td v-if="lotto_type === 'government'">{{item.sam_tua_nha_1 || 'XXX'}} , {{item.sam_tua_nha_2 || 'XXX'}}</td>
                <td v-if="lotto_type === 'government'">{{item.sam_tua_lang_1 || 'XXX'}} , {{item.sam_tua_lang_2 || 'XXX'}}</td>
            </tr>
            </tbody>
        </table>
        <Loading v-else/>
    </div>
</template>

<script>

    import AppMixin from '../mixins/App'
    import axiosWrapper from '@/axiosWrapper'
    import Loading from "./Loading";

    export default {
        name: "ResultPrevious",
        mixins: [
            AppMixin
        ],
        components: {Loading},
        props: ['lotto_type'],
        data(){
            return {
                arr: []
            }
        },
        watch: {
            lotto_type(new_val){
                if(new_val){
                    this.loadData()
                }
            }
        },
        methods: {
            async loadData(){
                if(this.lotto_type){
                    const res = await axiosWrapper({
                        method: 'POST',
                        url: `${this.envDomains.VUE_APP_LOTTO_API}/api/result/getPrevious`,
                        data: {
                            lotto_type: this.lotto_type
                        }
                    })
                    if(res.status === 200){
                        this.arr = res.data
                    }else{
                        this.$bvToast.toast(res.data.message, {title: 'แจ้งเตือน'});
                    }
                }
            }
        },
        mounted() {
            this.loadData()
        }
    }
</script>

<style scoped>

</style>