<template>
    <div class="UserChangeImageProfile content-edit-profile">
<!--        ซื้อ Item-->
        <div class="box-award" :class="{'hide':hideBoxAward }">
            <div class="box-alert-content">
                <div class="title title-center">
                    <h1>{{is_purchase?'ซื้อรูปพื้นหลัง':'เปลี่ยนรูปพื้นหลัง'}}</h1>
                </div>
                <div class="content-all">
                    <div class="content">
                        <div class="row-box-award">
                            <ul>
                                <li>
                                    <div class="item-award">
                                        <div class="item sp-premium-1" :style="{'background-image':'url('+purchase_item.url+')'}"></div>
                                        <span class="Value-item">{{is_purchase?'ซื้อรูปพื้นหลัง 1 ชิ้น':'เปลี่ยนรูปพื้นหลัง'}}</span>
                                    </div>
                                    <h4>{{is_purchase?purchase_item.price:'ซื้อแล้ว'}}</h4>
                                </li><!---->
                            </ul>
                        </div>
                        <div class="footer footer-center">
                            <button type="button" class="btn bg-gradient-btn" @click="confirmPurchase(purchase_item)">{{is_purchase?'ยืนยันการซื้อ':'ยืนยันการเปลี่ยน'}}</button>
                            <button type="button" class="btn btn-outline-secondary" @click="cancelPurchase()">ยกเลิก</button></div>
                    </div>
                    <div class="content-bg"></div>
                </div>
            </div>
        </div>


        <div class="content-edit-profile-info">
            <div class="header">
                <div class="content-edit-profile-info-topic">
                    <div @click="$emit('close')" class="close-boxeditProfile"></div>
                    <div class="tabbable">
                        <ul class="nav nav-tabs">
                            <li class="nav-item">
                                <a class="active" ID=t1 href="#organization" @click="changeTab('profile')" data-toggle="tab">เปลี่ยนรูปโปรไฟล์</a>

                            </li>
                            <li class="nav-item" ><a ID=t2 href="#webmaster"  @click="changeTab('bg')" data-toggle="tab">เปลี่ยนรูปพื้นหลัง</a></li>
                        </ul>
                    </div>
                </div>

                <div class="content-default-profile">
                    <div class="profile-thumb">
                        <el-image :src="imagePreview">
                            <img slot="error" src="/images/avatar.png" />
                        </el-image>
                        <div class="bg-profile" :class="{'sp-premium-5':avatar.bg}"  :style="{'background-image':'url('+(avatar.bg?avatar.bg.url:'')+')'}" ></div>
                    </div>
                </div>

            </div>
            <div class="content tab-content">

                <div class="content-all-set tab-pane active" id="organization">
                    <div class="nav-set-all">
                        <carousel
                                :per-page="3.3"
                                :mouseDrag="true"
                                :navigationEnabled="true"
                                :paginationEnabled="false"
                                navigationNextLabel="❯"
                                navigationPrevLabel="❮"
                        >
                            <slide  v-for="(item, key) of imageSet"
                                    :key="key">
                                <div
                                        class="nav"
                                        :class="{'active': setSelected === item.slug}"
                                        @click="selectImageSet(item.slug)">
                                    <span :class="item.class"></span>
                                    {{item.title}}
                                </div>
                            </slide>
                        </carousel>

                    </div>
                    <div class="container-set-all">
                        <div class="all-thumb-set">
                            <ul>
                                <li v-for="(image, key) of imageArr" 
                                :key="key"
                                :class="{'active': imageSelected === image}"
                                @click="selectImage(image)">
                                    <span class="pfa-set" :style="{'background-image' : `url(${image})`}"></span>
                                </li>
                            </ul>
                        </div>
                    </div>
                </div>
                <div class="content-all-set-bg tab-pane fade" id="webmaster">
                    <div class="row-content-all-set-bg">
                        <h3>รูปพื้นหลัง<span>ฟรี</span></h3>
                        <div class="info-content-all-set-bg">
                            <div class="box-item" :class="{'active':avatar.bg==null}" @click="showConfirmPurchase(null)">
                                <div class="item sp-default"></div>
                            </div>
                             
                            <div v-for="(a,key) of avatar_bg_list" :key="key">
                                <div v-if="a.type==0 && a.item_type==0"   class="box-item"  @click="showConfirmPurchase(a)" :class="{'active':avatar.bg?avatar.bg.id==a.id:false }">
                                    <div class="item sp-free-1" :style="{'background-image':'url('+a.url+')'}"></div>
                                </div>
                                
                            </div>
                            <!-- <div class="box-item">
                                <div class="item sp-free-2"></div>
                            </div>
                            <div class="box-item">
                                <div class="item sp-free-3"></div>
                            </div>
                            <div class="box-item">
                                <div class="item sp-free-4"></div>
                            </div> -->
                        </div>

                    </div>
                    <div class="row-content-all-set-bg premium-set">
                        <h3>รูปพื้นหลัง<span><i class="fas fa-crown"></i> พรีเมียม</span></h3>
                        <div class="info-content-all-set-bg">
                            <div v-for="(a,key) of avatar_bg_list" :key="key">
                                <div v-if="a.type==1  && a.item_type==0" class="box-item" @click="showConfirmPurchase(a)" :class="{'active':avatar.bg?avatar.bg.id==a.id:false }">
                                    <div class="item sp-premium-2" :style="{'background-image':'url('+a.url+')'}"></div>
                                    <div :class="a.id in avatar.bg_list?'btnpremium-get':'btnGet-premium'">
                                        <span>{{a.id in avatar.bg_list?'ซื้อแล้ว': a.price}}</span>
                                    </div>
                                </div>
                            </div>
                                <!-- <div class="box-item">
                                    <div class="item sp-premium-1"></div>
                                    <div class="btnpremium-get">
                                        <span>ซื้อแล้ว</span>
                                    </div>
                                </div>

                            <div class="box-item">
                                <div class="item sp-premium-3"></div>
                                <div class="btnGet-premium">
                                    <span>50</span>
                                </div>
                            </div>
                            <div class="box-item">
                                <div class="item sp-premium-4"></div>
                                <div class="btnGet-premium">
                                    <span>50</span>
                                </div>
                            </div>
                            <div class="box-item">
                                <div class="item sp-premium-5"></div>
                                <div class="btnGet-premium">
                                    <span>50</span>
                                </div>
                            </div>
                            <div class="box-item active">
                                <div class="item sp-premium-6"></div>
                                <div class="btnpremium-get">
                                    <span>ซื้อแล้ว</span>
                                </div>
                            </div>
                            <div class="box-item">
                                <div class="item sp-premium-7"></div>
                                <div class="btnGet-premium">
                                    <span>50</span>
                                </div>
                            </div>
                            <div class="box-item">
                                <div class="item sp-premium-8"></div>
                                <div class="btnGet-premium">
                                    <span>50</span>
                                </div>
                            </div> -->
                        </div>

                    </div>
                </div>
            </div>
            <div class="footer">
                <div v-if="(avatar.bg?(avatar.bg.url!=user_profile.picture_bg_url):user_profile.picture_bg_url!='') || (user_profile.picture_url!=setSelected && setSelected!=null)" @click="changeBgProfile()" class="confirm-boxeditProfile">
                    <span>อัพเดท</span>
                </div>
            </div>
        </div>
    </div>
</template>
<script>


import {IMAGE_SET, IMAGE_MAP} from './Constant'
import AccountMixin from './AccountMixin'
import { Carousel, Slide } from 'vue-carousel'
import axiosWrapper from '@/axiosWrapper'
import store from '@/store'
export default {
    name: 'UserChangeImageProfile',
    components: {
        Carousel,
        Slide,
    },
    mixins: [
        AccountMixin,
    ],
    data() {
        return {
            setSelected: 0,
            imageSelected: '',
            imageSet: [],
            avatar_bg_list:[],
            avatar:{},
            hideBoxAward:true,
            is_purchase :true,
            purchase_item:{},
            tabName:'profile',
        }
    },
    computed: {
        imageArr() {
            return IMAGE_MAP[this.setSelected]
        },
        imagePreview() {
            if(this.imageSelected) {
                return this.imageSelected
            } else {
                return this.user_profile.picture_url
            }
        },
            is_member() {
                return store.state.is_member
            },
            pes_profile() {
                return store.state.pes_profile
            },
            token() {
                return this.pes_profile.token
            },
            user_profile() {
                return store.state.user_profile
            },
            
    },
    methods: {
        selectImageSet(imageSet) {
            this.setSelected = imageSet
        },
        selectImage(image) {
            this.imageSelected = image
        },
        submit() {
            this.confirmChangeImageProfile(this.imageSelected)
            this.$emit('close')
        },
        changeTab(tabName){
            this.tabName=tabName;
        },
        async changeBgProfile(){
                if(this.tabName=='profile'){
                    this.submit();
                }else{
                    var ref_id = this.avatar.bg? this.avatar.bg.id:null;
                    ref_id = ref_id?ref_id:'';
                    const res = await axiosWrapper({
                                method: 'post',
                                url: `${this.envDomains.VUE_APP_PES_BASE_URL}/api/game/v1/avatar/bg/change.php?token=`+this.token+'&ref_id='+ref_id,
                    });
                    const response= res.data;


                    if(response.code==1){
                        this.hideBoxAward =true;
                        this.purchase_item = {};
                        
                        if(ref_id){
                            this.avatar.bg = {
                                id: ref_id,
                                url:this.pes_profile.sp_bonus_item.avatar.bg_list[ref_id],
                            }
                            
                            this.avatar.bg_list[ref_id]=this.avatar.bg.url;
                            
                            this.pes_profile.avatar =this.avatar;
                            this.user_profile.picture_bg_url = this.avatar.bg.url;
                        }else{
                            this.avatar.bg =null;
                            this.pes_profile.avatar =this.avatar;
                            this.user_profile.picture_bg_url = null;
                            
                            // this.avatar.bg_list[a.id]=a.url;
                        }

                        
                        // this.update_pes_data();
                        this.update_user_data();
                        
                        this.$emit('close');
                        // this.requestUserReload();

                    }else{
                        alert(response.msg);
                    }
                }
                
        },
        async getAvatar() {
                    const res = await axiosWrapper({
                        method: 'get',
                        url: `${this.envDomains.VUE_APP_PES_BASE_URL}/api/game/v1/avatar/bg/list.php?token=`+this.token,
                    });
                    return res.data.data;
        },
        showConfirmPurchase(a){
            if(a){
                if(a.id in this.avatar.bg_list){
                    this.is_purchase = false;
                    // this.hideBoxAward =false;
                    // this.purchase_item = a;
                    this.avatar.bg ={
                        id:  a.id,
                        url: a.url
                    };
                }else{
                    this.is_purchase = true;
                    this.hideBoxAward =false;
                    this.purchase_item = a;
                }
            }else{
                    this.is_purchase = false;
                    // this.hideBoxAward =false;
                    this.purchase_item = {
                        price:0,
                        url:'',
                        id:0,
                    };
                    this.avatar.bg =null;
            }
            
        },
        cancelPurchase(){
                this.hideBoxAward =true;
                this.purchase_item = {};
        },
        update_pes_data(){
                store.commit('updatePesProfile', this.pes_profile);
        },
        update_user_data(){
                // console.log(this.user_profile);
                store.commit('updateUserProfile', this.user_profile);
        },
        async confirmPurchase(a){
            if(this.is_purchase){
                const res = await axiosWrapper({
                            method: 'post',
                            url: `${this.envDomains.VUE_APP_PES_BASE_URL}/api/game/v1/avatar/bg/purchase.php?token=`+this.token+'&ref_id='+a.id,
                });
                const response= res.data;

                if(response.code==1){
                    this.hideBoxAward =true;
                    this.purchase_item = {};
                    this.avatar.bg = {
                        id: a.id,
                        url:a.url,
                    }
                    
                    this.avatar.bg_list[a.id]=a.url;
                    this.pes_profile.avatar =this.avatar;
                    this.user_profile.picture_bg_url = a.url;
                    this.update_pes_data();
                    this.update_user_data();

                }else{
                    alert(response.msg);
                }
            }else{
                const res = await axiosWrapper({
                            method: 'post',
                            url: `${this.envDomains.VUE_APP_PES_BASE_URL}/api/game/v1/avatar/bg/change.php?token=`+this.token+'&ref_id='+a.id,
                });
                const response= res.data;

                if(response.code==1){
                    this.hideBoxAward =true;
                    this.purchase_item = {};
                    
                    if(a.id>0){
                        this.avatar.bg = {
                            id: a.id,
                            url:a.url,
                        }
                        
                        this.avatar.bg_list[a.id]=a.url;
                        
                        this.pes_profile.avatar =this.avatar;
                        this.user_profile.picture_bg_url = a.url;
                    }else{
                        this.avatar.bg =null;
                        this.pes_profile.avatar =this.avatar;
                        this.user_profile.picture_bg_url = null;
                        
                        // this.avatar.bg_list[a.id]=a.url;
                    }

                    
                    this.update_pes_data();
                    this.update_user_data();

                }else{
                    alert(response.msg);
                }
            }

        }
    },
    created() {
        this.imageSet = IMAGE_SET
    },
    async mounted() {
        let avatar =this.pes_profile.sp_bonus_item.avatar;
        // console.log(this.pes_profile.sp_bonus_item);
        if(!avatar){
            avatar = {
                bg:null,
                bg_list:{},
                sticker_list:[]
            }
        }
        // console.log(avatar);
        this.avatar = avatar;
        this.avatar_bg_list = await this.getAvatar();
    }
}






</script>
<style src="@/assets/css/profile.css" scoped></style>