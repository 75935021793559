<template>
    <div class="MessageSperator box-separator">
        <span>{{message.timestamp | seperatorDateTimeFormat}}</span>
    </div>
</template>
<script>
    export default {
        name: 'MessageDateSeperator',
        props: ['message']
    }
</script>