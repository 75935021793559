<template>
<!--    <div class="MessageMinigame MessageBaccaratAlert incoming_msg box-fload fload-slide-in-right">-->
<!--        <div class="received_msg type-activity">-->
<!--            <div class="box-top-user-flex">-->
<!--                <div class="box-top-user-list-info minigame-fantan">-->
<!--                    <div class="box-games-list box-jackpot">-->
<!--                        <div class="list-jackpot">-->
<!--                            <div class="topic-jackpot">-->
<!--                                <div class="f3 blink delay-x1" style="top: -37px; left: -78px; "></div>-->
<!--                                <div class="f3 blink delay-x15" style="top: -15px; left: -90px;"></div>-->
<!--                                <div class="f3 blink delay-x1" style="top: -14px; right: 150px;  "></div>-->
<!--                                <div class="f3 blink delay-x15" style="top: -11px; right: 130px;"></div>-->
<!--                            </div>-->
<!--                            <div class="detail-jackpot">-->
<!--                                <div class="box-slide-show">-->
<!--                                    <div class="box-slide-show-info">-->
<!--                                        <div class="box-top-user-list">-->
<!--                                            <div class="user-list-detail">-->
<!--                                                <div class="user-list-detail-content">-->
<!--                                                    <div class="Avatar">-->
<!--                                                                <span class="el-avatar el-avatar&#45;&#45;large el-avatar&#45;&#45;circle">-->
<!--                                                                    <img src="/images/avatar.png" style="object-fit: cover;">-->
<!--                                                                </span>-->
<!--                                                    </div>-->
<!--                                                    <strong>4133</strong>-->
<!--                                                    <div class="groups-bx">-->
<!--                                                        <div class="fantan-bet-peg bet-1"> 1 </div>-->
<!--                                                    </div>-->
<!--                                                    <span>100 บาท</span>-->
<!--                                                </div>-->
<!--                                            </div>-->
<!--                                        </div>-->
<!--                                        <div class="box-top-user-list">-->
<!--                                            <div class="user-list-detail">-->
<!--                                                <div class="user-list-detail-content">-->
<!--                                                    <div class="Avatar">-->
<!--                                                                <span class="el-avatar el-avatar&#45;&#45;large el-avatar&#45;&#45;circle">-->
<!--                                                                    <img src="/images/avatar.png" style="object-fit: cover;"></span></div>-->
<!--                                                    <strong>คิวล</strong>-->
<!--                                                    <div class="groups-bx">-->
<!--                                                        <div>-->
<!--                                                            <div class="fantan-bet-peg bet-1"> 1 </div>-->
<!--                                                            <div class="fantan-bet-peg bet-2"> 2 </div>-->
<!--                                                            <div class="fantan-bet-peg bet-4"> 4 </div>-->
<!--                                                        </div></div><span>160 บาท</span></div></div></div>-->
<!--                                    </div>-->
<!--                                </div>-->
<!--                            </div>-->
<!--                        </div>-->
<!--                    </div>-->
<!--                </div>-->
<!--            </div>-->
<!--        </div>-->
<!--    </div>-->
    <div :class="`MessageMinigame MessageBaccaratAlert incoming_msg box-fload fload-slide-in-right ${rowColorClass}`"
    @click="play">
        <div class="received_msg type-activity">
            <div class="box-top-user-flex">
                <div :class="`box-top-user-list-info ${iconClass}`">
                    <div class="box-games-list box-jackpot">
                        <div class="list-jackpot">
                            <div class="topic-jackpot">
                                <div class="f3 blink delay-x1" style="top: -37px; left: -78px; "></div>
                                <div class="f3 blink delay-x15" style="top: -15px; left: -90px;"></div>
                                <div class="f3 blink delay-x1" style="top: -14px; right: 150px;  "></div>
                                <div class="f3 blink delay-x15" style="top: -11px; right: 130px;"></div>
                            </div>
                            <div class="detail-jackpot">
                                <div class="box-slide-show">
                                    <div class="PlayerPlayList box-slide-show-info">
                                        <div v-for="(item, key) of list" :key="key" class="box-top-user-list">
                                            <div class="user-list-detail">
                                                <div class="user-list-detail-content">
                                                    <Avatar mode="image" :src="getUserPictureUrl(item.user_id)"></Avatar>
                                                    <strong>{{getUserDisplayName(item.user_id) | displayNameShort}}</strong>
                                                    <div class="groups-bx">
                                                        <div v-if="parseInt(table_type) === parseInt(TABLE_TYPE_KEY.bean)">
                                                            <!-- <div v-for="(bet, key) of getBetArr(item.user_id)" :key="key"
                                                                    :class="`${getBetListClass(bet.user_choose)}`">
                                                                {{getFantanUserChooseLabel(bet.user_choose)}}
                                                            </div> -->
                                                            <div :class="`${getBetListClass(item.bet_list.user_choose)}`">
                                                                {{getFantanUserChooseLabel(item.bet_list.user_choose)}}
                                                            </div>

                                                        </div>
                                                        <div v-else-if="parseInt(table_type) === parseInt(TABLE_TYPE_KEY.sicbo)">
                                                            <div :class="`${getBetListClass(item.bet_list.user_choose)}`" style="vertical-align: middle;display: inline-block;margin-right: 5px;">
                                                                {{getSicboUserChooseLabel(item.bet_list.user_choose)}}
                                                            </div>
                                                        </div>
                                                        <div v-else>
                                                            <div :class="`${getBetListClass(item.bet_list.user_choose)}`"></div>
                                                        </div>
                                                    </div>
                                                    <span>{{item.amount | numberFormat}} บาท</span>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>
<script>

import {TABLE_TYPE} from './Constant'
import {TABLE_TYPE_KEY, BET_LIST_CLASS_MAP} from '@/components/game/Constant'
import {USER_CHOOSE_MAP as USER_CHOOSE_MAP_FANTAN} from '@/components/game/fantan/Constant'
import {USER_CHOOSE_MAP as USER_CHOOSE_MAP_SICBO} from '@/components/game/sicbo/Constant'
import Avatar from '@/components/Avatar'
import store from '@/store'

export default {
    name: 'BalloonGame',
    props: ['data'],
    components: {
        Avatar,
    },
    data() {
        return {
            TABLE_TYPE_KEY: null,
        }
    },
    computed: {
        user_info_arr(){
            return store.state.user_info_arr
        },
        gameData() {
            return this.data.data
        },
        list() {
            return this.gameData.list
        },
        table_type() {
            if(!this.gameData) {
                return null
            }
            return this.gameData.table_type
        },
        iconClass() {
            if(this.table_type === null) {
                return ''
            }
            return TABLE_TYPE[this.table_type]['iconClass']
        },
        rowColorClass() {
            if(this.table_type === null) {
                return ''
            }
            return TABLE_TYPE[this.table_type]['rowColorClass']
        },
    },
    methods: {
        getUserInfo(pes_user_id) {
            return this.user_info_arr.find((user_info) => {
                return parseInt(user_info.pes_user_id) === parseInt(pes_user_id)
            })
        },
        getUserDisplayName(pes_user_id) {
            const userInfo = this.getUserInfo(pes_user_id)
            return (userInfo) ? userInfo.display_name : ''
        },
        getUserPictureUrl(pes_user_id) {
            const userInfo = this.getUserInfo(pes_user_id)
            return (userInfo) ? userInfo.picture_url : ''
        },
        getBetListClass(user_choose) {
            if(this.table_type === null) {
                return ''
            }
            return BET_LIST_CLASS_MAP[this.table_type][user_choose]
        },
        getFantanUserChooseLabel(user_choose) {
            return USER_CHOOSE_MAP_FANTAN[user_choose]['number']
        },
        getSicboUserChooseLabel(user_choose) {
            return USER_CHOOSE_MAP_SICBO[user_choose]['TH']
        },
        play() {
            if(!this.table_type) {
                return
            }
            this.$router.push({
                name: TABLE_TYPE[this.table_type]['routerName']
            })
        },
    },
    created() {
        this.TABLE_TYPE_KEY = TABLE_TYPE_KEY
    }
}
</script>