<template>
    <div class="MessageLottoResult MessageLotto incoming_msg" :class="{'box-fload fload-slide-in-right': float}">
        <div @click="play" :class="`box-msg ${iconClass}`">
            <div v-if="message_data" class="top-lotto-detail">
                <ul>
                    <li class="top-lotto-detail-image2"><img src="@/assets/images/bg-topUp/lotto-result-icon.png" alt="" /></li>
                    <li>
                        <div class="lotto-date-bg">
                            <span>{{message_data.round.date | lottoPriceDateTimeFormat}}</span>
                            <span v-if="lotto_type === 'yeekee'"> รอบที่ {{message_data.round.sub_round}}</span>
                        </div>
                    </li>
                </ul>
            </div>
            <div v-if="message_data" class="box-top-user-flex-lt">
                <ul v-if="lotto_type === 'government'" class="box-result-lt">
                    <li>
                        <div class="n-box-result">
                            <p>รางวัลที่ 1</p>
                            <span><strong>{{message_data.first}}</strong></span>
                        </div>
                    </li>
                    <li>
                        <div class="n-box-result">
                            <p>เลขหน้า 3 ตัว</p>
                            <span>
                                <strong>{{message_data.sam_tua_nha_1}}</strong>
                                <p>,</p>
                                <strong>{{message_data.sam_tua_nha_2}}</strong>
                            </span>
                        </div>
                    </li>
                    <li>
                        <div class="n-box-result">
                            <p>เลขท้าย 3 ตัว</p>
                            <span>
                                <strong>{{message_data.sam_tua_lang_1}}</strong>
                                <p>,</p>
                                <strong>{{message_data.sam_tua_lang_2}}</strong>
                            </span>
                        </div>
                    </li>
                    <li>
                        <div class="n-box-result">
                            <p>เลขท้าย 2 ตัว</p>
                            <span><strong>{{message_data.song_tua_lang}}</strong></span>
                        </div>
                    </li>
                </ul>
                <ul v-else class="box-result-lt">
                    <li>
                        <div class="n-box-result">
                            <p>3 ตัวบน</p>
                            <span><strong>{{message_data.sam_tua_bon}}</strong></span>
                        </div>
                    </li>
                    <li>
                        <div class="n-box-result">
                            <p>2 ตัวล่าง</p>
                            <span><strong>{{message_data.song_tua_lang}}</strong></span>
                        </div>
                    </li>
                </ul>
            </div>
        </div>
    </div>
</template>
<script>

import {CHAT_ICON_CLASS} from '@/components/lotto/Constant'
import store from '@/store'

export default {
    name: 'MessageLottoResult',
    props: [
        'message',
        'float',
    ],
    computed: {
        message_data(){
            return (this.message) ? this.message.data : null
        },
        timestamp(){
            return (this.message) ? this.message.timestamp : ''
        },
        lotto_type() {
            return (this.message_data) ? this.message_data.lotto_type : ''
        },
        iconClass() {
            return CHAT_ICON_CLASS[this.lotto_type]
        },
    },
    methods: {
        play() {
            store.commit('lotto/updatePageBetQueryLottoType', this.lotto_type)
            store.commit('lotto/updateCurrentPage', 'bet')
            this.$router.push({name: 'LottoBet'})
        },
    },
}
</script>
