<template>
    <div class="CategorySelected text-center">
                                                                <span v-for="select_category of select_category_list"
                                                                      :key="select_category.slug"
                                                                      class="bt-selected"
                                                                      style="margin-right: 4px;">{{select_category.name}}</span>
        <span v-if="category_option && !category_option.number_list"
              class="bt-selected"
              style="margin-right: 4px; background-color: grey;">{{category_option.name}}</span>
    </div>
</template>

<script>
    export default {
        name: "CategorySelected",
        props: [
            'select_category_list',
            'category_option',
        ]
    }
</script>

<style scoped>

</style>