<template>
    <div class="AdminToolFootball">

    </div>
</template>
<script>

import axios from 'axios'
import store from '@/store'

export default {
    name: 'AdminToolFootball',
    props: ['bus'],
    data(){
        return {
            data: null
        }
    },
    computed: {
        admin(){
            return store.state.admin
        },
        token(){
            return this.admin.token
        }
    },
    methods: {
        async loadFootball(){

            const loading = this.$loading()

            try{

                const res = await axios({
                    method: 'POST',
                    url: `${this.envDomains.VUE_APP_API}/api/chat/admin-get-football`,
                    data: {token: this.token}
                })
                if(!res.data.success){
                    this.$alert(res.data.message, 'แจ้งเตือน', {
                        type: 'error',
                        confirmButtonText: 'ตกลง',
                        customClass: 'custom-message-box'
                    })
                    loading.close()
                    return
                }
                this.data = res.data.data

                const filteredMatches = []
                for(const match of this.data) {
                    const kickoff_time = this.$moment(match.kickoff_time)
                    const now = this.$moment()
                    if(now.isSameOrBefore(kickoff_time)) {
                        filteredMatches.push(match)
                    }
                }
                this.data = filteredMatches

                this.$emit('change', {
                    data: this.data,
                    type: 'football'
                })
                loading.close()
            }catch(err){
                this.$alert('ไม่สามารถโหลดข้อมูล กรุณาลองอีกครั้ง', 'แจ้งเตือน', {
                    type: 'error',
                    confirmButtonText: 'ตกลง',
                    customClass: 'custom-message-box'
                })
                loading.close()
                return
            }
        }
    },
    mounted(){
        this.bus.$on('loadFootball', this.loadFootball)
        if(!this.data){
            this.loadFootball()
        }
    },
    beforeDestroy(){
        this.bus.$off('loadFootball', this.loadFootball)
    }
}
</script>