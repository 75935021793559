<template>
<!--    <div class="GameType">-->
        <MiniDragonTiger @exit="$router.back()" :key="queryTableId"></MiniDragonTiger>
<!--    </div>-->
</template>
<script>

import MiniDragonTiger from '@/components/game/MiniDragonTiger'

export default {
    name: 'MiniDragonTigerView',
    components: {
        MiniDragonTiger,
    },
    computed: {
        queryTableId() {
            return this.$route.query.table_id
        },
    },
}
</script>