<script>
export default {
    name: 'StatScrollMixin',
    methods: {
        mainScroll(data) {
            const {existStatWidth, colWidth} = data
            const el = this.$refs['column-left']
            this.statScroll(el, existStatWidth, colWidth)
        },
        bigRoadScroll(data) {
            const {existStatWidth, colWidth} = data
            const el = this.$refs['column-right']
            this.statScroll(el, existStatWidth, colWidth)
            const wrapper = this.$refs['big-road-wrapper']
            this.statScroll(wrapper, existStatWidth, colWidth)
        },
        bigEyeBoyScroll(data) {
            const {existStatWidth, colWidth} = data
            const el = this.$refs['big-eye-boy-wrapper']
            this.statScroll(el, existStatWidth, colWidth)
        },
        smallRoadScroll(data) {
            const {existStatWidth, colWidth} = data
            const el = this.$refs['small-road-wrapper']
            this.statScroll(el, existStatWidth, colWidth)
        },
        cockroachPigScroll(data) {
            const {existStatWidth, colWidth} = data
            const el = this.$refs['cockroach-pig-wrapper']
            this.statScroll(el, existStatWidth, colWidth)
        },
        statScroll(el, existStatWidth, colWidth) {
            const widthHidden = existStatWidth - el.offsetWidth
            const positionToScroll = (widthHidden > 0) ? widthHidden + (colWidth * 2) : 0
            el.scrollTo(positionToScroll, 0)
        }
    },
}
</script>