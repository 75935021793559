<template>
    <modal name="free-bet-modal" :height="'auto'" width="98%" :pivotY="0.2" :scrollable="true" :clickToClose="false"
            @opened="$emit('modal-opened', 'free-bet')"
            @closed="$emit('modal-closed')">
        <div class="v-modal-content-wrapper flex-column">
            <div class="v-modal-body">
                <h3 style="text-align: center;">Free Bet</h3>
                <div v-for="(sp_bonus, i) of sp_bonus_arr" :key="i">
                    <input type="radio" :id="i" :value="sp_bonus.amount" v-model="picked">
                    {{sp_bonus.amount | toFixed(2)}} x {{sp_bonus.quantity}}
                </div>
            </div>
            <div class="v-modal-footer flex">
                <button @click="useFreeBonus">ตกลง</button>
                <button @click="closeModal">ปิด</button>
            </div>
        </div>
    </modal>
</template>

<script>

    import store from '@/store'

    export default {
        name: "FreeBetModal",
        data(){
            return {
                sp_bonus_arr: [],
                picked: null,
            }
        },
        computed: {
            user(){
                return store.state.pes_profile
            },
            amount: {
                get(){
                    return store.state.football.amount
                },
                set(value){
                    store.commit('football/updateAmount', value)
                }
            }
        },
        methods: {
            async getBonus(){

                if(this.user || this.$route.query.token){

                    const token = this.user.token || this.$route.query.token;

                    const res = await this.axiosWrapper({
                        method: 'get',
                        url: `${this.envDomains.VUE_APP_123API}/proxy/balance?token=${token}`
                    });
                    const data = res.data;
                    if(data.code === 1){
                        return this.bonusExtract(data.data.sp_bonus_item.sport)
                    }else{
                        return []
                    }
                }else{
                    return []
                }
            },
            bonusExtract(sp_bonus_item){
                const arr = []
                for(let key in sp_bonus_item){
                    if(parseInt(sp_bonus_item[key]) > 0){
                        arr.push({
                            amount: key,
                            quantity: sp_bonus_item[key]
                        })
                    }
                }
                return arr
            },
            closeModal(){
                this.$modal.hide('free-bet-modal')
                this.$emit('modal-clicked', 'close-free-bet')
            },
            useFreeBonus(){
                if(this.picked){
                    this.amount = this.picked
                }
                this.closeModal()
            }
        },
        async mounted(){
            if(this.$route.meta.name === 'home'){
                this.sp_bonus_arr = await this.getBonus()
                if(this.sp_bonus_arr.length > 0){
                    this.$modal.show('free-bet-modal')
                }
            }
            
        }
    }
</script>

<style scoped>
    .v-modal-content-wrapper {
        padding: 14px 18px;
        border: solid 2px black;
        overflow-y: auto;
        min-height: 180px;
    }
    .v-modal-body{
        word-break: break-all;
        flex: 1;
        overflow-y: auto;
    }
    .v-modal-footer{
        height: 24px;
        justify-content: flex-end;
        align-items: flex-end;
    }
    .v-blink-blink {
        animation: v-blinker 1s linear infinite;
    }
    @keyframes v-blinker {
        50% {
            opacity: 0;
        }
    }
</style>