<script>

import Timer from '@/lib/Timer'

export default {
    name: 'CountDownMixin',
    props: [
        'secondStart',
        'secondStartRemain',
    ],
    data() {
        return {
            secondCountDown: 0,
        }
    },
    computed: {
        secondRemain() {
            const now = this.$moment()
            if(!this.secondStartRemain) {
                return this.secondStart
                
            }
            const timestamp = this.$moment(this.secondStartRemain)
            const remain = parseInt(this.$moment.duration(now.diff(timestamp)).asSeconds())
            return (this.secondStart - remain > 0) ? this.secondStart - remain : 0
        },
    },
    watch: {
        secondRemain(newVal, oldVal) {
            if(oldVal === 0 && newVal > 0) {
                this.restartCountDown()
            }
        }
    },
    methods: {
        startCountDown: function() {

            this.secondCountDown = this.secondRemain

            if(this.timer) {
                this.timer.start()
                return
            }
            this.timer = Timer(() => {
                if(this.secondCountDown <= 0) {
                    this.stopCountDown()
                    return
                }
                this.secondCountDown--
                return    
            }, 1000)
        },
        stopCountDown: function() {
            if(!this.timer) {
                return
            }
            this.timer.stop()
        },
        restartCountDown() {
            this.stopCountDown()
            this.startCountDown()
        },
    },
}
</script>