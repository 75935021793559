<template>
    <div class="AdminToolLive">
        <div>URL</div>
        <el-input v-model="form.url" placeholder="https://www.youtube.com/embed/ykCpjm5mLMU"></el-input>
        <div class="v-line-seperator"></div>
        <div>หัวข้อ</div>
        <el-input v-model="form.title" placeholder="ถ่ายทอดสดการออกรางวัลสลากกินแบ่งรัฐบาล"></el-input>
        <div class="v-line-seperator"></div>
        <div>คำอธิบาย</div>
        <el-input v-model="form.description" placeholder="งวด 16 ตุลาคม 2563"></el-input>
        <div class="v-line-seperator"></div>
        <div>thumbnail</div>
        <Uploader @done="(url) => {form.thumbnail = url}" :data="{token}"/>
        <div class="v-line-seperator"></div>
    </div>
</template>
<script>

import Uploader from '@/components/Uploader'
import store from '@/store'

export default {
    name: 'AdminToolLive',
    components: {
        Uploader
    },
    props: ['bus'],
    data(){
        return {
            form: {
                url: '',
                title: '',
                description: '',
                thumbnail: ''
            }
        }
    },
    computed: {
        admin(){
            return store.state.admin
        },
        token(){
            return this.admin.token
        }
    },
    watch: {
        form: {
            deep: true,
            handler(new_val){
                this.$emit('change', {
                    data: new_val,
                    type: 'live'
                })
            }
        }
    },
    methods: {
        clear(){
            this.form = {
                url: '',
                title: '',
                description: '',
                thumbnail: ''
            }
        }
    },
    mounted(){
        this.bus.$on('clear', this.clear)
    }
}
</script>