<template>
    <div class="box-game-animated animate-baccarat">
        <div class="box-animated">
            <div class="hand">
                <div ref="gifImage" class="hand-active_baccarat"></div>
                <div class="sec-cards">
                    <div class="sec-half">
                        <div class="box-show-cards fa-pull-right">
                            <div class="pos-1">
                                <div class="flip-cards horizontal-card"
                                :class="{'floating-cards-effect': cardPlayer2State === 'float'}">
                                    <CardAnimation
                                    v-show="cardPlayer2State === 'float' || cardPlayer2State === 'place'"
                                    :state="cardPlayer2FaceState"
                                    :type="(customCardPlayer2) ? customCardPlayer2.card_type : 0"
                                    :number="(customCardPlayer2) ? customCardPlayer2.card_number : 0"></CardAnimation>
                                </div>
                            </div>
                            <div class="pos-2">
                                <div class="flip-cards"
                                :class="{'floating-cards-effect': cardPlayer0State === 'float'}">
                                    <CardAnimation
                                    v-show="state === 'end_game' || cardPlayer0State !== 'idle' || customCardPlayer0"
                                    :state="cardPlayer0FaceState"
                                    :type="(customCardPlayer0) ? customCardPlayer0.card_type : 0"
                                    :number="(customCardPlayer0) ? customCardPlayer0.card_number : 0"
                                    flipAngle="x"></CardAnimation>
                                </div>
                                <div class="flip-cards"
                                :class="{'floating-cards-effect': cardPlayer1State === 'float'}">
                                    <CardAnimation
                                    v-show="state === 'end_game' || cardPlayer1State !== 'idle' || customCardPlayer1"
                                    :state="cardPlayer1FaceState"
                                    :type="(customCardPlayer1) ? customCardPlayer1.card_type : 0"
                                    :number="(customCardPlayer1) ? customCardPlayer1.card_number : 0"
                                    flipAngle="x"></CardAnimation>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="sec-half">
                        <div class="box-show-cards fa-pull-left">
                            <div class="pos-1">
                                <div class="flip-cards horizontal-card"
                                :class="{'floating-cards-effect': cardBanker2State === 'float'}">
                                    <CardAnimation
                                    v-show="cardBanker2State === 'float' || cardBanker2State === 'place'"
                                    :state="cardBanker2FaceState"
                                    :type="(customCardBanker2) ? customCardBanker2.card_type : 0"
                                    :number="(customCardBanker2) ? customCardBanker2.card_number : 0"></CardAnimation>
                                </div>
                            </div>
                            <div class="pos-2">
                                <div class="flip-cards"
                                :class="{'floating-cards-effect': cardBanker0State === 'float'}">
                                    <CardAnimation
                                    v-show="state === 'end_game' || cardBanker0State !== 'idle' || customCardBanker0"
                                    :state="cardBanker0FaceState"
                                    :type="(customCardBanker0) ? customCardBanker0.card_type : 0"
                                    :number="(customCardBanker0) ? customCardBanker0.card_number : 0"
                                    flipAngle="x"></CardAnimation>
                                </div>
                                <div class="flip-cards"
                                :class="{'floating-cards-effect': cardBanker1State === 'float'}">
                                    <CardAnimation
                                    v-show="state === 'end_game' || cardBanker1State !== 'idle' || customCardBanker1"
                                    :state="cardBanker1FaceState"
                                    :type="(customCardBanker1) ? customCardBanker1.card_type : 0"
                                    :number="(customCardBanker1) ? customCardBanker1.card_number : 0"
                                    flipAngle="x"></CardAnimation>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>
<script>

import {GAME_STATUS_KEY, GAME_RESULT_KEY} from '@/components/game/Constant'
import axios from 'axios'
import CardAnimation from '@/components/game/CardAnimation'
import waitForSecond from '@/lib/waitForSecond'
const STATE_ARR = [
    'put_card',
    'jua',
    'jua_player',
    'jua_banker',
    'open_card',
    'open_jua_card',
    'open_jua_banker',
    'open_jua_player',
    'banker_win',
    'player_win',
    'draw',
    'end_game',
]

export default {
    name: 'BaccaratGamePlay3D',
    components: {
        CardAnimation,
    },
    props: [
        'gameStatus',
        'gameResult',
        'cardPlayer0',
        'cardPlayer1',
        'cardPlayer2',
        'cardBanker0',
        'cardBanker1',
        'cardBanker2',
        'isOnWarranty',
    ],
    data() {
        return {
            GAME_STATUS_KEY: null,
            STATE_ARR: [],
            state: 'end_game',
            base64Map: null,
            cardPlayer0State: 'idle', //idle float, place
            cardPlayer1State: 'idle',
            cardPlayer2State: 'idle',
            cardBanker0State: 'idle',
            cardBanker1State: 'idle',
            cardBanker2State: 'idle',
            cardPlayer0FaceState: 'back', //back, front
            cardPlayer1FaceState: 'back', //back, front
            cardPlayer2FaceState: 'back', //back, front
            cardBanker0FaceState: 'back', //back, front
            cardBanker1FaceState: 'back', //back, front
            cardBanker2FaceState: 'back', //back, front
            customCardPlayer0: null,
            customCardPlayer1: null,
            customCardPlayer2: null,
            customCardBanker0: null,
            customCardBanker1: null,
            customCardBanker2: null,
            waitJuaDuration: 0,
            isOnOpenCard: false,
        }
    },
    computed: {
        el() {
            return this.$refs['hand']
        },
        isCardComplete() {
            return !!(this.customCardPlayer0 && this.customCardPlayer1 && this.customCardBanker0 && this.customCardBanker1)
        },
    },
    watch: {
        async gameStatus(newVal, oldVal) {

            if(newVal === GAME_STATUS_KEY.DEALING) {
                this.cardPlaceAll()
            }else if(oldVal === GAME_STATUS_KEY.DEALING && newVal === GAME_STATUS_KEY.FINISH) {
                
                this.cardPlaceAll()

                this.waitJuaDuration = 0
                // console.log('init waitJuaDuration', this.waitJuaDuration)

                let state = ''

                if(parseInt(this.gameResult) === GAME_RESULT_KEY.BANKER_WIN) {
                    state = 'banker_win'
                } else if(parseInt(this.gameResult) === GAME_RESULT_KEY.PLAYER_WIN) {
                    state = 'player_win'
                } else if(parseInt(this.gameResult) === GAME_RESULT_KEY.TIE) {
                    state = 'draw'
                }

                // await waitForSecond(5 * 1000)

                // console.log('finish waitJuaDuration', this.waitJuaDuration)

                await waitForSecond(500)
                
                // console.log('show result', state)

                this.$emit('stateChanged', 'showResult')

                this.state = state

            }else if(
                (
                    parseInt(oldVal) === GAME_STATUS_KEY.SHUFFLING || 
                    parseInt(oldVal) === GAME_STATUS_KEY.WAIT || 
                    parseInt(oldVal) === GAME_STATUS_KEY.FINISH
                ) && 
                parseInt(newVal) === GAME_STATUS_KEY.COUNTDOWN
            ) {

                // await waitForSecond(8 * 1000)

                // console.log('countdown waitJuaDuration', this.waitJuaDuration)
                
                await waitForSecond(this.waitJuaDuration)

                // console.log('clear card')

                this.$emit('stateChanged', 'clearCard')

                this.clearCustomCard()
                this.cardIdleAll()
                this.cardFaceStateBackAll()

                await waitForSecond(2 * 1000)

                this.state = 'put_card'
            } else if(oldVal === GAME_STATUS_KEY.DEALING && newVal === GAME_STATUS_KEY.COUNTDOWN) {
                return
            } else {
                this.state = 'end_game'
                this.cardFaceStateBackAll()
            }
        },
        async state(newVal) {

            if(newVal) {

                this.setImage(newVal)

                if(newVal === 'put_card') {

                    await waitForSecond(1000)

                    this.cardPlayer0State = 'float'
                    setTimeout(() => {
                        this.cardPlayer0State = 'place'
                    }, 1000)

                    await waitForSecond(800)

                    this.cardBanker0State = 'float'
                    setTimeout(() => {
                        this.cardBanker0State = 'place'
                    }, 1000)

                    await waitForSecond(1500)

                    this.cardPlayer1State = 'float'
                    setTimeout(() => {
                        this.cardPlayer1State = 'place'
                    }, 1000)

                    await waitForSecond(1000)

                    this.cardBanker1State = 'float'
                    setTimeout(() => {
                        this.cardBanker1State = 'place'
                    }, 1000)
                }
            }
        },
        cardPlayer0(newVal) {
            if(newVal) {
                this.customCardPlayer0 = newVal
            }
        },
        cardPlayer1(newVal) {
            if(newVal) {
                this.customCardPlayer1 = newVal
            }
        },
        async cardPlayer2(newVal) {
            if(newVal) {
                this.customCardPlayer2 = newVal

                if(newVal.card_number && !this.isOnOpenCard && this.cardPlayer2State === 'idle') {

                    this.waitJuaDuration += 3000

                    this.state = 'jua_player'

                    await waitForSecond(1000)

                    this.cardPlayer2State = 'float'
                    await waitForSecond(1000)
                    this.cardPlayer2State = 'place'

                    this.state = 'open_jua_player'

                    await waitForSecond(500)

                    this.cardPlayer2FaceState = 'front'
                }
            }
        },
        cardBanker0(newVal) {
            if(newVal) {
                this.customCardBanker0 = newVal
            }
        },
        cardBanker1(newVal) {
            if(newVal) {
                this.customCardBanker1 = newVal
            }
        },
        async cardBanker2(newVal) {
            if(newVal) {
                this.customCardBanker2 = newVal

                if(newVal.card_number && !this.isOnOpenCard && this.cardBanker2State === 'idle') {

                    this.waitJuaDuration += 3000

                    this.state = 'jua_banker'

                    await waitForSecond(1000)

                    this.cardBanker2State = 'float'
                    await waitForSecond(1000)
                    this.cardBanker2State = 'place'

                    this.state = 'open_jua_banker'

                    await waitForSecond(500)

                    this.cardBanker2FaceState = 'front'
                }
            }
        },
        async isCardComplete(newVal) {

            if(newVal) {

                if(!(
                    (this.gameStatus === GAME_STATUS_KEY.DEALING) || 
                    (this.isOnWarranty && this.gameStatus === GAME_STATUS_KEY.COUNTDOWN)) 
                    )
                {
                    return
                }

                this.isOnOpenCard = true

                this.state = 'open_card'

                this.$emit('stateChanged', 'openCard')

                await waitForSecond(2000)

                this.cardPlayer0FaceState = 'front'
                this.cardBanker0FaceState = 'front'

                await waitForSecond(3000)

                this.cardPlayer1FaceState = 'front'

                await waitForSecond(2000)

                this.cardBanker1FaceState = 'front'

                this.isOnOpenCard = false

                if(this.customCardPlayer2 && this.customCardPlayer2.card_number && 
                this.customCardBanker2 && this.customCardBanker2.card_number) {

                    this.waitJuaDuration += 3000 * 2

                    this.state = 'jua'

                    await waitForSecond(1000)

                    this.cardPlayer2State = 'float'
                    await waitForSecond(1000)
                    this.cardPlayer2State = 'place'

                    await waitForSecond(500)

                    this.cardBanker2State = 'float'
                    await waitForSecond(1000)
                    this.cardBanker2State = 'place'

                    this.state = 'open_jua_card'

                    await waitForSecond(500)

                    this.cardPlayer2FaceState = 'front'
                    this.cardBanker2FaceState = 'front'

                } else if(this.customCardPlayer2 && this.customCardPlayer2.card_number) {

                    this.waitJuaDuration += 3000

                    this.state = 'jua_player'

                    await waitForSecond(1000)

                    this.cardPlayer2State = 'float'
                    await waitForSecond(1000)
                    this.cardPlayer2State = 'place'

                    this.state = 'open_jua_player'

                    await waitForSecond(500)

                    this.cardPlayer2FaceState = 'front'

                } else if(this.customCardBanker2 && this.customCardBanker2.card_number) {

                    this.waitJuaDuration += 3000

                    this.state = 'jua_banker'

                    await waitForSecond(1000)

                    this.cardBanker2State = 'float'
                    await waitForSecond(1000)
                    this.cardBanker2State = 'place'

                    this.state = 'open_jua_banker'

                    await waitForSecond(500)

                    this.cardBanker2FaceState = 'front'
                }
            }
        },
    },
    methods: {
        setImage(state) {
            const el = this.$refs['gifImage']
            if(!state || !el || !this.base64Map) {
                return
            }
            el.style['background-image'] =  `url(${this.base64Map[state].replace("image/gif","image/gif;rnd="+Math.random())})`
            el.style['background-size'] = '110%'
        },
        cardPlaceAll() {
            this.cardPlayer0State = 'place'
            this.cardPlayer1State = 'place'
            // this.cardPlayer2State = 'place'
            this.cardBanker0State = 'place'
            this.cardBanker1State = 'place'
            // this.cardBanker2State = 'place'
        },
        cardIdleAll() {
            this.cardPlayer0State = 'idle'
            this.cardPlayer1State = 'idle'
            this.cardPlayer2State = 'idle'
            this.cardBanker0State = 'idle'
            this.cardBanker1State = 'idle'
            this.cardBanker2State = 'idle'
        },
        cardFaceStateBackAll() {
            this.cardPlayer0FaceState = 'back'
            this.cardPlayer1FaceState = 'back'
            this.cardPlayer2FaceState = 'back'
            this.cardBanker0FaceState = 'back'
            this.cardBanker1FaceState = 'back'
            this.cardBanker2FaceState = 'back'
        },
        clearCustomCard() {
            this.customCardPlayer0 = null
            this.customCardPlayer1 = null
            this.customCardPlayer2 = null
            this.customCardBanker0 = null
            this.customCardBanker1 = null
            this.customCardBanker2 = null
        },
    },
    async created() {
        this.GAME_STATUS_KEY = GAME_STATUS_KEY
        this.STATE_ARR = STATE_ARR
        const base64Res = await axios.get('/images/game/baccarat/animation/base64.json')
        this.base64Map = base64Res.data
        this.setImage(this.state)
    },
}
</script>
<style scoped src="@/assets/css/animate-games.css"></style>