<script>
import axiosWrapper from '@/axiosWrapper'
import store from '@/store'
export default {
    name: 'AdditionalDataMixin',
    computed: {
        user_info_arr(){
            return store.state.user_info_arr
        }
    },
    methods: {
        async loadUserInfoArr(id_list){

            const is_need_load = () => {
                for(const id of id_list){
                    const exist = this.user_info_arr.find((user_info) => {
                        return user_info.id === id
                    })
                    if(!exist){
                        return true
                    }
                }
                return false
            }

            if(!is_need_load()){
                return
            }

            const get_to_display_res = await axiosWrapper({
                method: 'POST',
                url: `${this.envDomains.VUE_APP_LIFF_API}/api/user/get-to-display-for-chat`,
                data: {
                    id_list,
                }
            })
            if(get_to_display_res.status === 200){

                const pesUserIdArr = this.toPesUserIdArr(get_to_display_res.data)
                const pesInfo = await this.getPesUserInfo(pesUserIdArr)

                for(const d of get_to_display_res.data){
                    const level = this.getPesUserLevel(pesInfo, d.pes_user_id)
                    store.commit('addUserInfoArr', Object.assign(d, {level}))
                }
            }
            return
        },
        async loadUserInfoArrByPesUsername(pes_username_list){

            const is_need_load = () => {
                for(const pes_username of pes_username_list){
                    const exist = this.user_info_arr.find((user_info) => {
                        return user_info.pes_username === pes_username
                    })
                    if(!exist){
                        return true
                    }
                }
                return false
            }

            if(!is_need_load()){
                return
            }

            const get_to_display_res = await axiosWrapper({
                method: 'POST',
                url: `${this.envDomains.VUE_APP_LIFF_API}/api/user/get-to-display-for-chat-by-pes-username`,
                data: {
                    pes_username_list,
                }
            })
            if(get_to_display_res.status === 200){
                
                const pesUserIdArr = this.toPesUserIdArr(get_to_display_res.data)
                const pesInfo = await this.getPesUserInfo(pesUserIdArr)

                for(const d of get_to_display_res.data){
                    const level = this.getPesUserLevel(pesInfo, d.pes_user_id)
                    store.commit('addUserInfoArr', Object.assign(d, {level}))
                }
            }
            return
        },
        async loadUserInfoArrByPesUserId(pes_id_list){

            const is_need_load = () => {
                for(const pes_user_id of pes_id_list){
                    const exist = this.user_info_arr.find((user_info) => {
                        return user_info.pes_user_id === pes_user_id
                    })
                    if(!exist){
                        return true
                    }
                }
                return false
            }

            if(!is_need_load()){
                return
            }

            const get_to_display_res = await axiosWrapper({
                method: 'POST',
                url: `${this.envDomains.VUE_APP_LIFF_API}/api/user/get-to-display-for-chat-by-pes-user-id`,
                data: {
                    pes_id_list,
                }
            })
            if(get_to_display_res.status === 200){
                
                const pesUserIdArr = this.toPesUserIdArr(get_to_display_res.data)
                const pesInfo = await this.getPesUserInfo(pesUserIdArr)

                for(const d of get_to_display_res.data){
                    const level = this.getPesUserLevel(pesInfo, d.pes_user_id)
                    store.commit('addUserInfoArr', Object.assign(d, {level}))
                }
            }
            return
        },
        toPesUserIdArr(rawArr) {
            return rawArr.map((raw) => {
                return raw.pes_user_id
            })
        },
        getPesUserLevel(pesInfo, pes_user_id) {
            if(!pesInfo) {
                return 0
            }
            return (pesInfo[pes_user_id]) ? pesInfo[pes_user_id] : 0
        },
        async getPesUserInfo(pesUserIdArr) {

            const pesUserIdArrStr = pesUserIdArr.join()

            const get_to_display_res = await axiosWrapper({
                method: 'post',
                url: `${this.envDomains.VUE_APP_API}/api/pes/get-user-level`,
                data: {
                    user_id: pesUserIdArrStr,
                }
            })
            if(get_to_display_res.status === 200){
                return get_to_display_res.data.data
            } else {
                return null
            }
        }
    }
}
</script>