<template>
    <span v-if="price" class="Price" :class="{'blink_blink': isChange}">
        <span class="price" :class="{'line-through': (price_change !== price) && !accept_any_odd}">{{price | floatFormat}}</span>
        &nbsp;
        <span v-show="(price_change !== price) && !accept_any_odd" class="price">{{price_change | floatFormat}}</span>
        <span v-show="priceUp" class="price-up"></span>
        <span v-show="priceDown" class="price-down"></span>
    </span>
</template>

<script>

    import PriceMixin from '../mixins/PriceMixin'
    import store from '@/store'

    export default {
        name: 'Price',
        mixins: [PriceMixin],
        computed: {
            accept_any_odd: function(){
                return store.state.lotto.accept_any_odd;
            }
        }
    }
</script>

<style scoped>
    .Price {
        width: 100%;
        /*margin: 0 2px;*/
    }
    .Price span{
        /*margin-right: 4px;*/
    }
    .price-up {
        display: inline-flex;
        height: 15px;
        width: 10px;;
        background-image: url(../../../assets/images/lotto/priceup.gif);
        background-position: right 5px;
        background-repeat: no-repeat;
    }
    .price-down {
        display: inline-flex;
        height: 15px;
        width: 10px;;
        background-image: url(../../../assets/images/lotto/pricedown.gif);
        background-position: right 5px;
        background-repeat: no-repeat;
    }
    .blink_blink {
        animation: blinker 1s linear infinite;
    }
    @keyframes blinker {
        50% {
            background-color: yellow;
        }
    }
    .line-through {
        text-decoration: line-through;
    }
</style>