<template>
    <div class="BaccaratStatBigEyeBoy">
        <div v-for="(box, key) of gridBoxs" :key="key" class="baccarat-stat-big-eye-boy-grid">
            <div>
                <div style="display: flex;">
                    <div class="score-item-block">
                        <Mark :stat="getMark(box.upperLeft)" :roadType="roadType"></Mark>
                    </div>
                    <div class="score-item-block">
                        <Mark :stat="getMark(box.upperRight)" :roadType="roadType"></Mark>
                    </div>
                </div>
                <div style="display: flex;">
                    <div class="score-item-block">
                        <Mark :stat="getMark(box.lowerLeft)" :roadType="roadType"></Mark>
                    </div>
                    <div class="score-item-block">
                        <Mark :stat="getMark(box.lowerRight)" :roadType="roadType"></Mark>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>
<script>

import {GAME_RESULT_KEY, DRAGON_TIGER_GAME_RESULT_KEY} from '../Constant'
import Mark from './BigEyeBoyMark'
const MAX_MARK_LENGTH = 504
const ROW_MAX = 6
const MAX_GRID_MARK_LENGTH = MAX_MARK_LENGTH / 4
const GRID_ROW_MAX = ROW_MAX / 2
const COL_WIDTH = 6
const ROAD_TYPE = {
    'big-eye-boy': {
        n: 1,
    },
    'small-road': {
        n: 2,
    },
    'cockroach-pig': {
        n: 3,
    },
}

export default {
    name: 'BaccaratStatBigEyeBoy',
    components: {
        Mark,
    },
    props: [
        'roadType', 
        'statArr', 
        'gameType',
    ],
    data() {
        return {
            // statArr: [
            //     {game_result: 2},
            //     {game_result: 2},
            //     {game_result: 2},
            //     {game_result: 2},
            //     {game_result: 2},
            //     {game_result: 2},
            //     {game_result: 2},
            //     {game_result: 2},
            //     {game_result: 2},
            //     {game_result: 2},
            //     {game_result: 1},
            //     {game_result: 2},
            //     {game_result: 1},
            //     {game_result: 2},
            //     {game_result: 1},
            //     {game_result: 2},
            //     {game_result: 1},
            //     {game_result: 1},
            //     {game_result: 1},
            //     {game_result: 1},
            //     {game_result: 1},
            //     {game_result: 1},
            //     {game_result: 1},
            //     {game_result: 1},
            //     {game_result: 1},
            //     {game_result: 1},
            //     {game_result: 2},
            //     {game_result: 2},
            //     {game_result: 2},
            //     {game_result: 2},
            //     {game_result: 2},
            //     {game_result: 2},
            //     {game_result: 2},
            //     {game_result: 2},
            //     {game_result: 2},
            //     {game_result: 2},
            //     {game_result: 1},
            //     {game_result: 2},
            //     {game_result: 1},
            //     {game_result: 2},
            //     {game_result: 1},
            //     {game_result: 1},
            //     {game_result: 1},
            //     {game_result: 1},
            //     {game_result: 1},
            //     {game_result: 1},
            //     {game_result: 1},
            //     {game_result: 2},
            //     {game_result: 2},
            //     {game_result: 2},
            //     {game_result: 2},
            //     {game_result: 2},
            //     {game_result: 2},
            //     {game_result: 2},
            //     {game_result: 2},
            //     {game_result: 2},
            //     {game_result: 2},
            //     {game_result: 1},
            //     {game_result: 1},
            //     {game_result: 1},
            //     {game_result: 1},
            //     {game_result: 1},
            //     {game_result: 1},
            //     {game_result: 1},
            //     {game_result: 2},
            //     {game_result: 2},
            //     {game_result: 2},
            //     {game_result: 2},
            //     {game_result: 2},
            //     {game_result: 2},
            //     {game_result: 2},
            //     {game_result: 2},
            //     {game_result: 2},
            //     {game_result: 2},
            //     {game_result: 1},
            //     {game_result: 1},
            //     {game_result: 1},
            //     {game_result: 1},
            //     {game_result: 1},
            //     {game_result: 1},
            //     {game_result: 1},
            //     {game_result: 1},
            //     {game_result: 2},
            //     {game_result: 2},
            //     {game_result: 2},
            //     {game_result: 2},
            //     {game_result: 2},
            //     {game_result: 2},
            //     {game_result: 2},
            //     {game_result: 2},
            // ],
            boxArr: [],
            gridBoxs: [],
            // bigRoadArr: [
            //     {
            //         col: 0,
            //         row: 0,
            //         result: 2, //red
            //     },
            //     {
            //         col: 1,
            //         row: 0,
            //         result: 1,
            //     },
            //     {
            //         col: 2,
            //         row: 0,
            //         result: 2,
            //     },
            //     {
            //         col: 2,
            //         row: 1,
            //         result: 2,
            //     },
            //     {
            //         col: 3,
            //         row: 0,
            //         result: 1,
            //     },
            //     {
            //         col: 4,
            //         row: 0,
            //         result: 2,
            //     },
            //     {
            //         col: 4,
            //         row: 1,
            //         result: 2,
            //     },
            //     {
            //         col: 4,
            //         row: 2,
            //         result: 2,
            //     },
            //     {
            //         col: 4,
            //         row: 3,
            //         result: 2,
            //     },
            //     {
            //         col: 5,
            //         row: 0,
            //         result: 1,
            //     },
            //     {
            //         col: 6,
            //         row: 0,
            //         result: 2,
            //     },
            //     {
            //         col: 7,
            //         row: 0,
            //         result: 1,
            //     },
            //     {
            //         col: 8,
            //         row: 0,
            //         result: 2,
            //     },
            //     {
            //         col: 8,
            //         row: 1,
            //         result: 2,
            //     },
            //     {
            //         col: 9,
            //         row: 0,
            //         result: 1,
            //     },
            //     {
            //         col: 9,
            //         row: 1,
            //         result: 1,
            //     },
            //     {
            //         col: 9,
            //         row: 2,
            //         result: 1,
            //     },
            //     {
            //         col: 10,
            //         row: 0,
            //         result: 2,
            //     },
            //     {
            //         col: 10,
            //         row: 1,
            //         result: 2,
            //     },
            //     {
            //         col: 10,
            //         row: 2,
            //         result: 2,
            //     },
            //     {
            //         col: 11,
            //         row: 0,
            //         result: 1,
            //     },
            //     {
            //         col: 11,
            //         row: 1,
            //         result: 1,
            //     },
            //     {
            //         col: 12,
            //         row: 0,
            //         result: 2,
            //     },
            //     {
            //         col: 13,
            //         row: 0,
            //         result: 1,
            //     },
            //     {
            //         col: 13,
            //         row: 1,
            //         result: 1,
            //     },
            //     {
            //         col: 13,
            //         row: 2,
            //         result: 1,
            //     },
            // ],
            marks: [],
        }
    },
    computed: {
        n() {
            return ROAD_TYPE[this.roadType]['n']
        },
        dragonTigerBigRoadArr() {

            const arr = []

            let onLongRow = false
            let currentLongRow = ROW_MAX
            let latestLongCol = -1
            let latestCol = -1

            let row = 0
            let col = 0

            let latestResult = null

            this.statArr.map((stat) => {

                if(stat.game_result) {

                    const newResult = stat.game_result

                    const isTie = newResult === DRAGON_TIGER_GAME_RESULT_KEY.TIE

                    if(!latestResult) {
                        row = 0
                        col = 0
                    } else if(isTie) {
                        row = row + 0
                        col = col + 0
                    } else if(latestResult === newResult) {

                        if(row === currentLongRow - 1) {// long row case
                            
                            col += 1

                            onLongRow = true
                            latestLongCol = col
                            if(latestCol < 0) {
                                latestCol = col
                            }

                        } else {
                            row += 1
                        }
                        
                    } else {

                        if(onLongRow) { // change from long row case

                            onLongRow = false

                            if(currentLongRow === 1) { //Sextuple Dragon case start new col after latestLongCol
                                col = latestLongCol + 1
                            } else {
                                col = latestCol
                            }
                            
                            row = 0

                            latestCol = -1
                            currentLongRow -= 1

                        } else {
                            col += 1
                            row = 0
                        }
                    }

                    if(col > latestLongCol) {
                        currentLongRow = ROW_MAX
                    }

                    if(isTie) {
                        if(arr.length > 0) {
                            arr[arr.length - 1].isTie = 1
                        }
                    } else {

                        const data = {
                            row,
                            col,
                            result: stat.game_result,
                            longRowOnCol: (onLongRow) ? (latestCol - 1) : -1,
                        }

                        arr.push(data)

                        latestResult = stat.game_result
                    }
                }
            })
            
            return arr
        },
        baccaratBigRoadArr() {

            const arr = []

            let onLongRow = false
            let currentLongRow = ROW_MAX
            let latestLongCol = -1
            let latestCol = -1

            let row = 0
            let col = 0

            let latestResult = null

            this.statArr.map((stat) => {

                if(stat.game_result) {

                    const newResult = stat.game_result

                    const isTie = newResult === GAME_RESULT_KEY.TIE

                    if(!latestResult) {
                        row = 0
                        col = 0
                    } else if(isTie) {
                        row = row + 0
                        col = col + 0
                    } else if(latestResult === newResult) {

                        if(row === currentLongRow - 1) {// long row case
                            
                            col += 1

                            onLongRow = true
                            latestLongCol = col
                            if(latestCol < 0) {
                                latestCol = col
                            }

                        } else {
                            row += 1
                        }
                        
                    } else {

                        if(onLongRow) { // change from long row case

                            onLongRow = false

                            if(currentLongRow === 1) { //Sextuple Dragon case start new col after latestLongCol
                                col = latestLongCol + 1
                            } else {
                                col = latestCol
                            }
                            
                            row = 0

                            latestCol = -1
                            currentLongRow -= 1

                        } else {
                            col += 1
                            row = 0
                        }
                    }

                    if(col > latestLongCol) {
                        currentLongRow = ROW_MAX
                    }

                    if(isTie) {
                        if(arr.length > 0) {
                            arr[arr.length - 1].isTie = 1
                            arr[arr.length - 1].is_banker_pair = (!arr[arr.length - 1].is_banker_pair) ? stat.is_banker_pair : arr[arr.length - 1].is_banker_pair
                            arr[arr.length - 1].is_player_pair = (!arr[arr.length - 1].is_player_pair) ? stat.is_player_pair : arr[arr.length - 1].is_player_pair
                        }
                    } else {

                        const data = {
                            row,
                            col,
                            result: stat.game_result,
                            is_banker_pair: stat.is_banker_pair,
                            is_player_pair: stat.is_player_pair,
                            longRowOnCol: (onLongRow) ? (latestCol - 1) : -1,
                        }

                        arr.push(data)

                        latestResult = stat.game_result
                    }
                }
            })
            
            return arr
        },
        bigRoadArr() {
            if(this.gameType === 'dragon-tiger') {
                return this.dragonTigerBigRoadArr
            } else {
                return this.baccaratBigRoadArr
            }
        },
        bigEyeBoyArr() {

            let compareDirection = ''
            let prevMark = ''
            let col = 0
            let row = 0

            let onLongRow = false
            let currentLongRow = ROW_MAX
            let latestLongCol = -1
            let latestCol = -1

            const marks = []

            for(const stat of this.bigRoadArr) {
                if(!this._checkResultCanCompute(stat.result)) {
                    continue
                }
                if(!this._checkEnoughBigRoad(stat.col, stat.row)) {
                    continue
                }
                if(stat.row === 0) {
                    compareDirection = 'COMPARE_LEFT_COLUMNS'
                } else {
                    compareDirection = 'COMPARE_LEFT'
                }

                let mark = ''
                if(stat.longRowOnCol > -1) { //dragon tail
                    mark = 'red'
                } else {
                    const isEqual = this._compare(compareDirection, stat.col, stat.row)
                    if(!isEqual) {
                        mark = 'blue'
                    } else {
                        mark = 'red'
                    }
                }
                if(prevMark) {
                    if(mark === prevMark) {
                        if(row === currentLongRow - 1) {// long row case
                            col += 1
                            onLongRow = true
                            latestLongCol = col
                            if(latestCol < 0) {
                                latestCol = col
                            }
                        } else {
                            row += 1
                        }
                    } else {
                        if(onLongRow) { // change from long row case
                            onLongRow = false
                            if(currentLongRow === 1) { //Sextuple Dragon case start new col after latestLongCol
                                col = latestLongCol + 1
                            } else {
                                col = latestCol
                            }
                            row = 0
                            latestCol = -1
                            currentLongRow -= 1
                        } else {
                            col += 1
                            row = 0
                        }
                    }
                    if(col > latestLongCol) {
                        currentLongRow = ROW_MAX
                    }
                }
                marks.push({
                    col: col,
                    row: row,
                    mark: mark,
                    longRowOnCol: (onLongRow) ? (latestCol - 1) : -1,
                })
                prevMark = mark
            }

            return marks
        },
        latestBigEyeBoyArr() {
            if(this.bigEyeBoyArr.length > 0) {
                return this.bigEyeBoyArr[this.bigEyeBoyArr.length - 1]
            } else {
                return 0
            }
        },
        latestStatArrSortCol() {
            if(!this.latestBigEyeBoyArr) {
                return 0
            }
            return this.latestBigEyeBoyArr.col
        },
        existStatWidth() {
            return parseInt(this.latestStatArrSortCol * COL_WIDTH)
        },
    },
    watch: {
        existStatWidth(newVal) {
            if(newVal) {
                this.$emit('existStatWidthChange', {
                    existStatWidth: newVal, 
                    colWidth: COL_WIDTH,
                })
            }
        },
    },
    methods: {
        generateBoxArr() {

            let row = 0
            let col = 0

            for(let i = 0;i < MAX_MARK_LENGTH;i++) {
                col = parseInt(i / ROW_MAX)
                this.boxArr.push({
                    row,
                    col,
                })
                row = (row + 1) % ROW_MAX
            }
        },
        generateGridBoxs() {

            let row = 0
            let col = 0

            let upperLeft = 0
            let upperRight = 0
            let lowerLeft = 0
            let lowerRight = 0

            for(let i = 0;i < MAX_GRID_MARK_LENGTH;i++) {

                const index = i + 1
                const mod = index % GRID_ROW_MAX

                if(mod === 1) { // first row
                    upperLeft = lowerRight + 1
                    upperRight = upperLeft + ((Math.min(3, MAX_GRID_MARK_LENGTH - (index - 1))) * 2)
                    lowerLeft = upperLeft + 1
                    lowerRight = upperRight + 1
                } else {
                    upperLeft = lowerLeft + 1
                    upperRight = lowerRight + 1
                    lowerLeft = upperLeft + 1
                    lowerRight = upperRight + 1
                }

                col = parseInt(i / GRID_ROW_MAX)
                this.gridBoxs.push({
                    index,
                    row,
                    col,
                    upperLeft: upperLeft - 1,
                    upperRight: upperRight -1,
                    lowerLeft: lowerLeft - 1,
                    lowerRight: lowerRight -1,
                })
                row = (row + 1) % GRID_ROW_MAX
            }
        },
        getMark(index) {
            const position = this._getMarkPosition(index)
            const mark = this.bigEyeBoyArr.find((mark) => {
                return position.col === mark.col && position.row === mark.row
            })
            return mark
        },
        _getMarkPosition(index) {
            const col = parseInt(index / ROW_MAX)
            const row = parseInt(index  % ROW_MAX)
            return {
                col,
                row,
            }
        },
        _checkResultCanCompute(result) {
            if(this.gameType === 'dragon-tiger') {
                return parseInt(result) === DRAGON_TIGER_GAME_RESULT_KEY.DRAGON_WIN || parseInt(result) === DRAGON_TIGER_GAME_RESULT_KEY.TIGER_WIN
            } else {
                return parseInt(result) === GAME_RESULT_KEY.BANKER_WIN || parseInt(result) === GAME_RESULT_KEY.PLAYER_WIN
            }
        },
        _checkEnoughBigRoad(col, row) {
            const colPosition = col + 1
            const rowPosition = row + 1
            if(colPosition >= this.n + 1 && rowPosition >= 2) {
                return true
            }
            if(colPosition >= this.n + 2 && rowPosition >= 1) {
                return true
            }
            return false
        },
        _getRowCount(col) {
            const rows = this.bigRoadArr.filter((stat) => {
                return col === stat.col
            })
            const dragonTails = this.bigRoadArr.filter((stat) => {
                return col === stat.longRowOnCol
            })
            return rows.length + dragonTails.length
        },
        _getCellResult(col, row) {
            const cell = this.bigRoadArr.find((stat) => {
                return col === stat.col && row === stat.row
            })
            if(cell) {
                return cell.result
            } else {
                return -1
            }
        },
        _compare(compareDirection, col, row) {
            if(compareDirection === 'COMPARE_LEFT') {
                const activeCell = this._getCellResult(col - this.n, row)
                const leftNCell = this._getCellResult(col - this.n, row - 1)
                return activeCell === leftNCell
            } else if(compareDirection === 'COMPARE_LEFT_COLUMNS') {
                const activeRowCount = this._getRowCount(col - 1)
                const leftNRowCount = this._getRowCount(col - 1 - this.n)
                return activeRowCount === leftNRowCount
            }
        },
    },
    created() {
        this.generateBoxArr()
        this.generateGridBoxs()
    },
    mounted() {
        setTimeout(() => {
            this.$emit('existStatWidthChange', {
                existStatWidth: this.existStatWidth, 
                colWidth: COL_WIDTH,
            })
        }, 1000)
    }
}
</script>
<style scoped>
.BaccaratStatBigEyeBoy {
    display: flex;
    flex-direction: column;
    flex-wrap: wrap;
}
.baccarat-stat-big-eye-boy-grid {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 11px;
    width: 11px;
    border-collapse: collapse;
    border-right: solid 1px #ddd;
    border-bottom: solid 1px #ddd;
}
.score-item-block {
    width: 6px;
    height: 6px;
    display: flex;
    justify-content: center;
    align-items: center;
}
</style>