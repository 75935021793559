<template>
    <div class="MiniFantanView">
        <MiniFantan @exit="$router.back()" :key="queryTableId"></MiniFantan>
    </div>
</template>
<script>

import MiniFantan from '@/components/game/MiniFantan'

export default {
    name: 'MiniFantanView',
    components: {
        MiniFantan,
    },
    computed: {
        queryTableId() {
            return this.$route.query.table_id
        },
    },
}
</script>