import {Howl} from 'howler'

export const RATIO_MAP = {
    1: '0.95:1',
    2: '1:1',
    3: '8:1',
    5: '11:1',
    6: '11:1',
    7: '',
    8: '',
    9: '',
}

export const SOUND = {
    RESULT: {
        1: new Howl({src: ['/sounds/game/baccarat/result/banker_th.mp3'], preload: false}),
        2: new Howl({src: ['/sounds/game/baccarat/result/player_th.mp3'], preload: false}),
        3: new Howl({src: ['/sounds/game/baccarat/result/tie_th.mp3'], preload: false}),
    },
    POINT: {
        PLAYER: {
            0: new Howl({src: ['/sounds/game/baccarat/point/player/player_has_0_th.mp3'], preload: false}),
            1: new Howl({src: ['/sounds/game/baccarat/point/player/player_has_1_th.mp3'], preload: false}),
            2: new Howl({src: ['/sounds/game/baccarat/point/player/player_has_2_th.mp3'], preload: false}),
            3: new Howl({src: ['/sounds/game/baccarat/point/player/player_has_3_th.mp3'], preload: false}),
            4: new Howl({src: ['/sounds/game/baccarat/point/player/player_has_4_th.mp3'], preload: false}),
            5: new Howl({src: ['/sounds/game/baccarat/point/player/player_has_5_th.mp3'], preload: false}),
            6: new Howl({src: ['/sounds/game/baccarat/point/player/player_has_6_th.mp3'], preload: false}),
            7: new Howl({src: ['/sounds/game/baccarat/point/player/player_has_7_th.mp3'], preload: false}),
            8: new Howl({src: ['/sounds/game/baccarat/point/player/player_has_8_th.mp3'], preload: false}),
            9: new Howl({src: ['/sounds/game/baccarat/point/player/player_has_9_th.mp3'], preload: false}),
        },
        BANKER: {
            0: new Howl({src: ['/sounds/game/baccarat/point/banker/banker_has_0_th.mp3'], preload: false}),
            1: new Howl({src: ['/sounds/game/baccarat/point/banker/banker_has_1_th.mp3'], preload: false}),
            2: new Howl({src: ['/sounds/game/baccarat/point/banker/banker_has_2_th.mp3'], preload: false}),
            3: new Howl({src: ['/sounds/game/baccarat/point/banker/banker_has_3_th.mp3'], preload: false}),
            4: new Howl({src: ['/sounds/game/baccarat/point/banker/banker_has_4_th.mp3'], preload: false}),
            5: new Howl({src: ['/sounds/game/baccarat/point/banker/banker_has_5_th.mp3'], preload: false}),
            6: new Howl({src: ['/sounds/game/baccarat/point/banker/banker_has_6_th.mp3'], preload: false}),
            7: new Howl({src: ['/sounds/game/baccarat/point/banker/banker_has_7_th.mp3'], preload: false}),
            8: new Howl({src: ['/sounds/game/baccarat/point/banker/banker_has_8_th.mp3'], preload: false}),
            9: new Howl({src: ['/sounds/game/baccarat/point/banker/banker_has_9_th.mp3'], preload: false}),
        }
    }
}

export const soundLoad = () => {
    for(const key in SOUND['RESULT']) {
        SOUND['RESULT'][key].load()
    }
    for(const key in SOUND['POINT']['PLAYER']) {
        SOUND['POINT']['PLAYER'][key].load()
    }
    for(const key in SOUND['POINT']['BANKER']) {
        SOUND['POINT']['BANKER'][key].load()
    }
}