<template>
    <div class="OptionNumberListPanel OptionNumberListPanel-group">
        <div class="v-title">
            {{title}}
        </div>
        <div class="v-list">
            <button @click="selectNumber(number)" v-for="number of number_list" :key="number"
            class="btn" :class="{'active': isSelected(number)}">{{number}}</button>
        </div>
    </div>
</template>

<script>

    import {CATEGORY_SONG_TUA_LIST} from '../Constant'
    const category_arr = CATEGORY_SONG_TUA_LIST
    import {rood_lang_number_list, rood_nha_number_list, sib_kao_pratu_number_list} from "../lib/cal_bet_mode";
    import store from '@/store'
    import BetMixin from '../mixins/BetMixin'

    export default {
        name: "OptionNumberListPanel",
        mixins: [BetMixin],
        props: ['category', 'number_setting_arr'],
        data(){
            return {
                number_list: [0, 1, 2, 3, 4, 5, 6, 7, 8, 9]
            }
        },
        computed: {
            category_obj: function(){
                return category_arr.find((o) => {
                    return o.slug === this.category;
                });
            },
            title: function(){
                return this.category_obj.name;
            },
            option_step_list: function(){
                return store.state.lotto.option_step_list;
            },
            bet_list_index(){
                return store.state.lotto.bet_list_index;
            },
        },
        methods: {
            selectNumber: function(number){

                let number_list = [];

                number = number.toString();

                if (this.category === 'sib_kao_pratu') {
                    number_list = sib_kao_pratu_number_list(number);
                } else if (this.category === 'rood_nha') {
                    number_list = rood_nha_number_list(number);
                } else if (this.category === 'rood_lang') {
                    number_list = rood_lang_number_list(number);
                }

                let category = {
                    slug: this.category,
                    number: number,
                    number_list: number_list,
                    bet_list_index: this.bet_list_index,
                };

                const option_step = this.option_step_list.find((o) => {
                    return o.slug === this.category && o.number === number;
                });

                if(option_step){

                    const i = this.option_step_list.findIndex((o) => {
                        return o.slug === this.category && o.number === number;
                    });

                    store.commit('lotto/remove_option_step_list', i);
                    this.$emit('cancel', option_step.bet_list_index);
                }else{
                    store.commit('lotto/add_option_step_list', category);
                    this.$emit('select_category_option', category);
                }
            },
            isSelected: function(number){

                number = number.toString();

                return this.option_step_list.find((o) => {
                    return o.slug === this.category && o.number === number;
                });
            }
        }
    }
</script>

<style scoped>
    .v-list {
        display: flex;
    }
</style>