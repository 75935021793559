<template>
    <div class="MyBet" style="max-height: 400px; overflow-y: auto;">
        <div class="flex" style="display: none; justify-content: center; background-color: white;font-size: 12px; padding: 2px 0;">
            <RefreshButton start_seconds="120" v-on:click="loadData()"></RefreshButton>
        </div>
        <div v-for="(myBet, index) in my_bet_list" :key="myBet.gid" class="v-row" :class="{'highlight': index%2 === 0, 'live': myBet.is_live === 1, 'nonlive': myBet.is_live === 0}">
            <div class="v-inner">
                <div class="flex" style="justify-content: space-between; margin-bottom: 4px;">
                    <div :class="{'text-live': myBet.is_live === 1, 'text-nonlive': myBet.is_live === 0}" style="font-size: 12px;">{{myBet.gid}}</div>
                    <div style="font-size: 12px;">{{myBet.create_datetime | dateTimeFormat}}</div>
                </div>



                <div v-for="d in myBet.bet_list" :key="d.match_id || d.match_sbo_id">
                    <div class="v-step" :class="{'live': d.is_live === 1, 'nonlive': d.is_live === 0}" style="margin-bottom: 4px;">
                        <div class="flex" style="align-items: baseline;">
                            <div class="v-choose" :class="{'color-blue': d.favorite === d.choose}">
                                {{d.choose | calChooseName(d.match_home_name, d.match_away_name, d.mode)}}
                            </div>&nbsp;
                            <div v-if="d.is_live === 0" class="v-mode-value">{{d.mode_value_show}}</div>
                        </div>
                        <div class="flex" style="align-items: flex-start;">
                            <div v-if="d.is_live === 1" style="font-weight: bold;">[{{d.mode_value_show}}@{{d.score | scoreNotNull}}]&nbsp;</div>
                            <div class="v-price">@{{d.odds}}</div>&nbsp;
                            <div class="v-odd-style">{{d.odds_type}}</div>
                        </div>
                    </div>
                    <div style="text-align: start; font-size: 12px; margin-bottom: 4px;">{{d.match_home_name}} VS {{d.match_away_name}}</div>
                    <div style="text-align: start; font-size: 11px; color: grey; margin-bottom: 4px;">{{d.league_name_en}}</div>
                    <div v-if="myBet.mode === 'single'" class="flex" style="justify-content: space-between; align-items: baseline;">
<!--                        <div style="font-size: 12px;">FTB</div>-->
                        <div style="font-size: 12px;">{{d.mode_sub}}.{{d.mode}}</div>
                        <div :class="{'background-live': myBet.is_live === 1, 'background-nonlive': myBet.is_live === 0}" style="font-size: 12px; color: white; padding: 4px;">{{myBet.cost | toFixed(2)}}</div>
                        <div :class="{'text-live': myBet.is_live === 1, 'text-nonlive': myBet.is_live === 0}" style="font-size: 12px; text-align: end;">{{myBet.game_status_str}}</div>
                    </div>
                    <div class="flex" v-if="myBet.mode === 'combo'">
<!--                        <div style="font-size: 12px;">FTB</div>&nbsp;&nbsp;-->
                        <div style="font-size: 12px;">{{d.mode_sub}}.{{d.mode}}</div>
                    </div>
                </div>
                <div class="flex" v-if="myBet.mode === 'combo'" style="font-size: 12px;">Mix Parlay @{{myBet.mixParlay | toFixed(2)}} EU</div>
                <div class="flex" v-if="myBet.mode === 'combo'" style="margin-top: 4px; align-items: baseline; justify-content: space-around;">
                    <div :class="{'background-live': myBet.is_live === 1, 'background-nonlive': myBet.is_live === 0}" style="font-size: 12px; color: white; padding: 4px;">{{myBet.cost | toFixed(2)}}</div>&nbsp;
                    <div style="font-size: 12px;">MP</div>
                    <div :class="{'text-live': myBet.is_live === 1, 'text-nonlive': myBet.is_live === 0}" style="font-size: 12px; text-align: end; flex: 1;">{{myBet.game_status_str}}</div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    import store from '@/store'
    import BigNumber from 'bignumber.js'
    import RefreshButton from './RefreshButton.vue'
    import axiosWrapper from '@/axiosWrapper'
    export default {
        name: "MyBet",
        components: {
            RefreshButton
        },
        data: function(){
            return{
                my_bet_list: []
            }
        },
        computed: {
            user: function(){
                return store.state.pes_profile;
            },
            sport_selected: function () {
                return store.state.football.sport_selected;
            },
            tab1Index: function(){
                return store.state.football.tab1Index;
            },
            my_bet_list_count: function(){
                return this.my_bet_list.length;
            }
        },
        watch: {
            tab1Index: function(newVal){
                if(newVal === 1){
                    this.loadData();
                }
            },
            my_bet_list_count: function(newVal){
                this.$emit('my_bet_list_count_updated', newVal);
            }
        },
        methods: {
            loadData: async function(){
                this.my_bet_list = [];
                const res = await axiosWrapper({
                    method: 'get',
                    url: `${this.envDomains.VUE_APP_123API}/proxy/my_bet?date=Today&is_finish=0&token=${this.user.token}&sport=${this.sport_selected}`
                });
                const data = res.data;
                if(data.code === 1){
                    for(let i = 0;i < data.data.length;i++){
                        let bet = data.data[i];
                        let bet_list = [];
                        for(let b in bet.bet_list){
                            bet_list.push(bet.bet_list[b]);
                        }
                        let mode = 'single';
                        if(bet.game_match > 1){
                            mode = 'combo'
                            bet.mixParlay = await this.calMixParlay(bet_list);
                        }
                        bet.mode = mode;
                        bet.bet_list = bet_list;
                        this.my_bet_list.push(bet);
                    }
                }
            },
            calMixParlay: async function (bet_list) {
                let p_total_odds = new BigNumber(1);
                for(let i = 0;i < bet_list.length;i++){
                    let bet = bet_list[i];
                    let row_eu_odd = new BigNumber(this.$options.filters.priceByCurrency(bet.odds));
                    p_total_odds = row_eu_odd.multipliedBy(p_total_odds);
                }
                return p_total_odds;
            },
        }
    }
</script>

<style scoped>
    .v-row{
        background-color: #ffffff;
        color: black;
    }
    .v-row.highlight{
        background-color: #eaebea;
    }
    .v-row.live {
        border-left: solid 5px #b64531;
    }
    .v-row.nonlive {
        border-left: solid 5px #23577e;
    }
    .v-inner{
        padding: 6px 8px;
    }
    .v-step{
        padding: 0 4px;
        height: 44px;
    }
    .v-step.live{
        border-left: solid 1px #b64531;
    }
    .v-step.nonlive{
        border-left: solid 1px #23577e;
    }
    .background-live{
        background-color: #b64531;
    }
    .background-nonlive{
        background-color: #23577e;
    }
    .text-live{
        color: #b64531;
    }
    .text-nonlive{
        color: #23577e;
    }
    .v-choose{
        font-size: 12px;
    }
    .v-mode-value{
        font-size: 12px;
    }
    .v-price{
        font-size: 16px;
        font-weight: bold;
    }
    .v-odd-style{
        font-size: 16px;
        font-weight: bold;
    }
</style>