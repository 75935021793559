<template>
    <modal class="v-custom-drawer list-panel-bet" name="bet_panel_drawer" :transition="transition"
           :pivotY="1" :adaptive="true" width="100%" height="70%"
           @opened="modal_state_update(true)" @closed="modal_state_update(false)">
        <slot name="v_drawer_body"/>
    </modal>
</template>

<script>

    import store from '@/store'

    export default {
        name: "Drawer",
        props: [
            'transition'
        ],
        methods: {
            modal_state_update: function(state){
                store.commit('lotto/update_is_modal_open', state);
                this.$emit('modal_state_update', state);
            }
        }
    }
</script>

<style scoped>

</style>
<style>
    .modal-slide-up-enter-active,
    .modal-slide-up-leave-active {
        transition: all 0.4s;
    }

    .modal-slide-up-enter,
    .modal-slide-up-leave-active {
        opacity: 0;
        transform: translateY(100%);
    }
</style>