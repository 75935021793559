<template>

    <modal class="pleaseWait" name="please-wait" :height="'auto'" width="98%" :pivotY="0.2" :scrollable="true" :clickToClose="false">
        <div class="v-modal-content-wrapper flex-column">
            <div class="v-modal-body flex item-center">
                <div class="v-blink-blink" style="font-size: 36px;">รอสักครู่ ...</div>
            </div>
            <div class="v-modal-footer flex">
                <el-button @click="$emit('cancel')">ยกเลิก</el-button>
            </div>
        </div>
    </modal>
</template>

<script>
    export default {
        name: "PleaseWaitModal"
    }
</script>

<style scoped>
    .v-modal-content-wrapper {
        padding: 14px 18px;
        overflow-y: auto;
        min-height: 180px;
        display: flex;
        justify-items: center;
        align-items: center;
    }

    .v-modal-body {
        word-break: break-all;
        flex: 1;
        overflow-y: auto;
    }

    .v-modal-footer {
        /*height: 24px;*/

    }

    .v-blink-blink {
        animation: v-blinker 1s linear infinite;
    }
    .pleaseWait .v-modal-box.v-modal{

    }
    .pleaseWait{

    }
    @keyframes v-blinker {
        50% {
            opacity: 0;
        }
    }
</style>
<style>
    @media ( min-width: 768px){

    }
</style>