<template>
    <!--Live-->
    <div class="MessageLive incoming_msg">
<!--        <div class="incoming_msg_img">-->
<!--            <img src="@/assets/images/img-other/live3.gif" alt="" />-->
<!--        </div>-->
        <div class="received_video-live">
                <div class="box-video-live">
                            <div class="box-top-user-list">
                                <div class="box-games-list box-live">
                                    <div class="box-icon-live">สด</div>
                                    <div class="bg-hover-video" @click="$emit('click', url)"><i class="far fa-play-circle"></i></div>
                                    <div ref="image_thumbnail" class="box-img-game box-video-ads">
                                    </div>
                                    <div class="box-info-game box-info-float box-rect">
                                        <h3>
<!--                                            <span class="text-blink"> -->
<!--                                                <img src="@/assets/images/img-other/live3.gif" style="width: 10px;">-->
<!--                                            </span>-->
                                            {{title}}
                                        </h3>
                                        <div class="txt-detail">{{description}}</div>
                                    </div>
                                </div>
                            </div>
                        </div>
                <span class="time_date"> {{timestamp | chatMessageDateTimeFormat}}</span>
            </div>
    </div>

</template>

<script>
    export default {
        name: "MessageLive",
        props: [
            'message'
        ],
        computed: {
            message_data(){
                return (this.message) ? this.message.data : null
            },
            url(){
                return (this.message_data) ? this.message_data.url : ''
            },
            title(){
                return (this.message_data) ? this.message_data.title : ''
            },
            description(){
                return (this.message_data) ? this.message_data.description : ''
            },
            timestamp(){
                return (this.message) ? this.message.timestamp : ''
            },
            thumbnail(){
                return (this.message_data) ? `${this.envDomains.VUE_APP_API}/${this.message_data.thumbnail}` : ''
            }
        },
        watch: {
            thumbnail(new_val){
                const image_thumbnail = this.$refs.image_thumbnail
                if(new_val){
                    image_thumbnail.style['background-image'] = `url(${new_val})`
                }
            }
        },
        mounted(){
            const image_thumbnail = this.$refs.image_thumbnail
            if(image_thumbnail && this.thumbnail){
                image_thumbnail.style['background-image'] = `url(${this.thumbnail})`
            }
        }
    }
</script>

<style scoped>

    /* .box-video-ads{
        background-image: url(../../assets/images/live.jpg);
    } */

    .box-img-game{
        border-radius: 0px;
    }

    /*.received_msg,*/
    /*.box-live,*/
    /*.box-top-user-list{*/
    /*    margin-right:0;*/
    /*    width: 99% !important;*/
    /*}*/

</style>