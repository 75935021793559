export default function perm(xs, to_str = true) {

    let ret = [];

    for (let i = 0; i < xs.length; i = i + 1) {

        let rest = perm(xs.slice(0, i).concat(xs.slice(i + 1)));

        if(!rest.length) {
            if(to_str){
                ret.push(arr_to_str([xs[i]]))
            }else{
                ret.push([xs[i]])
            }
        } else {
            for(let j = 0; j < rest.length; j = j + 1) {
                if(to_str){
                    ret.push(arr_to_str([xs[i]].concat(rest[j])))
                }else{
                    ret.push([xs[i]].concat(rest[j]))
                }
            }
        }
    }
    return ret;
}

function arr_to_str(arr){
    let number_str = '';
    for(let choose_number of arr){
        number_str += choose_number+'';
    }
    return number_str;
}