<template>
    <div class="MyBet" style="padding: 2px;">

        <h3 style="font-size: 22px; font-weight: bold;">My bets</h3>
        <div class="flex" style="justify-content: flex-start">
            <select v-model="sport_type">
                    <option v-for="sport in sports" :key="sport.value" :value="sport.value">{{sport.name}}</option>
                </select>
            <div class="diy-space small"></div>
            <datepicker
                    @selected="selectDatePicker"
                    :value="momentToDate"
                    format="yyyy-MM-dd">

            </datepicker>
            <div class="diy-space small"></div>
            <el-button size="mini" v-on:click="selectDate('today')">วันนี้</el-button>
            <div class="diy-space small"></div>
            <el-button size="mini" v-on:click="selectDate('yesterday')">เมื่อวานนี้</el-button>
        </div>
        <div class="flex item-center-vertical v-table-header" style="margin-top: 10px;">
            <div class="flex v-column" style="flex-basis: 50px;"></div>
            <div class="flex v-column" style="flex-basis: 150px;">Details</div>
            <div class="flex v-column" style="flex-basis: 220px;">Selection</div>
            <div class="flex v-column" style="flex-basis: 70px;">Odds</div>
            <div class="flex v-column" style="flex-basis: 140px;">Stake</div>
            <div class="flex v-column" style="flex-basis: 140px;">Win/Loss</div>
            <div class="flex v-column" style="flex: 1; border: none;">status</div>
        </div>
        {{paramsChanged}}
        <h2 v-if="isLoading" class="v-blink_blink">Loading...</h2>
        <div v-for="(data, i) in my_bet_list" :key="data.gid">
            <div class="flex v-result-row" :class="{'highlight': i%2 === 0}">
                <div class="flex v-column" style="flex-basis: 50px;">{{i+1}}</div>
                <div class="flex v-column" style="flex-basis: 150px; justify-content: flex-start;">
                    <div style="text-align: left; margin: 0 4px;">
                        <div class="color-blue">{{data.gid}}</div>
                        <div style="font-weight: bold;">
                            <span v-if="data.mode === 'combo'">
                                Mix Parlay
                            </span>
                            <span v-if="data.mode === 'single'">
                                {{sport_type}}
                            </span>
                        </div>
                        <div>{{data.create_datetime | dateTimeFormat}}</div>
                    </div>
                </div>
                <div class="flex v-column" style="flex-basis: 220px; justify-content: flex-start; padding: 4px 0;">
                    <div style="text-align: left; margin: 0 4px;">
                        <div v-for="d in data.bet_list" :key="d.match_id || d.match_sbo_id+'first'" style="margin-bottom: 4px;">
<!--                            <div :class="{'color-blue': d.favorite === d.choose}">-->
                            <div class="color-blue">
                                {{d.choose | calChooseName(d.team_home_name, d.team_away_name, d.mode)}}
                                <span v-if="d.is_live === 1">@Live</span>
                                {{d.mode_value_show}}
                                <span v-if="d.is_live === 1"><span class="nbsp"></span>@{{d.score}}</span>
                                <span v-if="data.mode === 'combo'">@{{d.odds}}</span>
                            </div>
                            <div style="color: grey;">{{d.mode_sub}}<span class="nbsp"></span>{{d.mode}}</div>
                            <div>{{d.team_home_name}} VS {{d.team_away_name}}</div>
                            <div style="color: grey; font-size: 11px;">{{d.league_name_en}}</div>
                            <div style="font-size: 11px;">@{{d.kickoff_time | dateTimeFormat}}</div>
                            <div v-if="data.mode === 'combo'" style="font-size: 10px;">
                                <span v-if="d.FH_score && d.FH_score !== 'null-null'" style="color: #85b8f1;">HT {{d.FH_score}}</span>
                                <span v-if="d.FT_score && d.FT_score !== 'null-null'">/</span>
                                <span v-if="d.FT_score && d.FT_score !== 'null-null'" style="color: #6279b0;">FT {{d.FT_score}}</span>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="flex v-column" style="flex-basis: 70px;">
                    <div style="font-weight: bold;">
                        <div v-if="data.mode === 'combo'">
                            <div class="color-blue">{{data.mixParlay | toFixed(2)}}</div>
                            <div>EU</div>
                        </div>
                        <div v-if="data.mode === 'single'">
                            <div v-for="d in data.bet_list" :key="d.match_id || d.match_sbo_id+'second'">
                                <div class="color-blue">{{d.odds}}</div>
                                <div>{{d.odds_type}}</div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="flex v-column color-blue" style="flex-basis: 140px; font-weight: bold;">
                    <div style="text-align: right;">
                        <div>{{data.cost | numberFormat}}</div>
                        <div style="font-size: 10px; color: grey;">{{data.result_cost | numberFormat}}</div>
                    </div>
                </div>
                <div class="flex v-column" style="flex-basis: 140px;">
                    <div v-if="!parseInt(data.profit_loss)"> - </div>
                    <div v-if="parseInt(data.profit_loss)" :class="{'v-loss-color': is_loss(data.profit_loss), 'v-profit-color': !is_loss(data.profit_loss)}" style="text-align: right;">
                        <div>{{data.profit_loss | numberFormat}}</div>
                        <div v-if="data.commission_value" style="font-size: 10px; color: darkorange;">{{data.commission_value | toFixed(2)}}</div>
                    </div>
                </div>
                <div class="flex v-column" style="flex: 1; border-right: none;">
                    <div>
                        <div>{{data.game_status_str}}</div>
                        <div v-if="sport_type === 'muaythai' || sport_type === 'boxing'" style="text-align: left;">
                            <div v-for="(d, i) in data.bet_list" :key="i">
                                <div>Winner:<strong> {{d.winner}}</strong></div>
                                <div>Result:<strong> {{d.roundval}}</strong></div>
                            </div>
                        </div>
                        <div v-if="(sport_type === 'volleyball' || sport_type === 'tennis') && (data.game_status_str === 'Won' || data.game_status_str === 'Lose')" style="text-align: left;">
                            <div v-for="(d, i) in data.bet_list" :key="i" style="margin-bottom: 8px;">
                                <div>G1:<strong> {{d.G1}}</strong></div>
                                <div>G2:<strong> {{d.G2}}</strong></div>
                                <div>G3:<strong> {{d.G3}}</strong></div>
                                <div>G4:<strong> {{d.G4}}</strong></div>
                                <div>G5:<strong> {{d.G5}}</strong></div>
                            </div>
                        </div>
                        <div v-if="data.mode === 'single' && sport_type !== 'volleyball' && sport_type !== 'tennis'" style="font-size: 10px;">
                            <div v-if="data.bet_list[0].FH_score && data.bet_list[0].FH_score !== 'null-null'" style="color: #85b8f1;">HT {{data.bet_list[0].FH_score}}</div>
                            <div v-if="data.bet_list[0].FT_score && data.bet_list[0].FT_score !== 'null-null'" style="color: #6279b0;">FT {{data.bet_list[0].FT_score}}</div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div v-if="my_bet_list.length === 0" style="width: 100%;">
            <div style="margin-left: 40px;">
                no data
            </div>
        </div>
        <div class="flex v-result-row" style="font-weight: bold; background-color: lightgrey;">
            <div class="flex v-column" style="flex-basis: 490px; border-right: none;"></div>
            <div class="flex v-column" style="flex-basis: 140px; border-right: none; font-size: 14px; justify-content: flex-end;">
                รวม
            </div>
            <div class="flex v-column" style="flex-basis: 140px; border-right: none; justify-content: flex-end; padding: 5px 0;">
                <div v-if="!parseInt(total_profit_loss)">-</div>
                <div v-if="parseInt(total_profit_loss)" :class="{'v-loss-color': is_loss(total_profit_loss), 'v-profit-color': !is_loss(total_profit_loss)}" style="text-align: right;">
                    <div>{{total_profit_loss | numberFormat}}</div>
                    <div v-if="total_commission" style="font-size: 10px; color: darkorange;">{{total_commission | toFixed(2)}}</div>
                </div>
            </div>
            <div class="flex v-column" style="flex: 1; border-right: none;"></div>
        </div>
    </div>
</template>

<script>

    import moment from 'moment'
    import Datepicker from 'vuejs-datepicker';
    import store from '@/store';
    import BigNumber from 'bignumber.js'
    import debounce from '@/debounce'
    import axiosWrapper from '@/axiosWrapper'

    export default {
        name: "MyBet",
        components: {
            Datepicker
        },
        data() {
            return {
                sports: [
                    {
                        value: 'football',
                        name: 'ฟุตบอล',
                    },
                    {
                        value: 'basketball',
                        name: 'บาสเก็ตบอล',
                    },
                    {
                        value: 'volleyball',
                        name: 'วอลเลย์บอล',
                    },
                    {
                        value: 'muaythai',
                        name: 'มวยไทย',
                    },
                    {
                        value: 'tennis',
                        name: 'เทนนิส',
                    },
                    {
                        value: 'boxing',
                        name: 'มวยสากล',
                    },
                    {
                        value: 'badminton',
                        name: 'แบดมินตัน',
                    },
                ],
                startdate: moment(),
                sport_type: 'football',
                my_bet_list: [],
                isLoading: false,
                total_profit_loss: 0,
                total_commission: 0
            }
        },
        computed: {
            paramsChanged: function(){
                this.getData(this.startdate, this.sport_type)
                return "";
            },
            momentToDate: function(){
                return this.startdate.toDate();
            },
            dateStr: function(){
                // let dateFormat = this.startdate.format('YYYY-MM-DD');
                // if(moment().format('YYYY-MM-DD') === dateFormat){
                //     return 'Today';
                // }else if(moment().subtract(1, 'd').format('YYYY-MM-DD') === dateFormat){
                //     return 'Yesterday';
                // }else{
                //     return dateFormat;
                // }
                return this.startdate.format('YYYY-MM-DD')
            },
            user: function(){
                return store.state.pes_profile;
            },
        },
        methods: {
            selectDate: function(startdate){

                if(startdate === 'today'){
                    this.startdate = moment()
                }else if(startdate === 'yesterday'){
                    this.startdate = moment().subtract(1, 'd')
                }else{
                    this.startdate = startdate;
                }
            },
            selectDatePicker: function(date){
                this.startdate = moment(date);
            },
            getData: debounce(async function(){

                this.isLoading = true;

                this.my_bet_list = [];
                this.total_profit_loss = 0;
                this.total_commission = 0;

                const res = await axiosWrapper({
                    method: 'get',
                    url: `${this.envDomains.VUE_APP_123API}/proxy/my_bet?date=${this.dateStr}&is_finish=all&token=${this.user.token}&sport=${this.sport_type}`
                });
                this.isLoading = false;
                const data = res.data;
                if(data.code === 1){
                    for(let i = 0;i < data.data.length;i++){
                        let bet = data.data[i];
                        let bet_list = [];
                        for(let b in bet.bet_list){
                            bet_list.push(bet.bet_list[b]);
                        }
                        let mode = 'single';
                        if(bet.game_match > 1){
                            mode = 'combo'
                            bet.mixParlay = await this.calMixParlay(bet_list);
                        }
                        bet.mode = mode;
                        bet.bet_list = bet_list;

                        if(parseFloat(bet.profit_loss)){
                            this.total_profit_loss += parseFloat(bet.profit_loss)
                        }
                        if(parseFloat(bet.commission_value)){
                            this.total_commission += parseFloat(bet.commission_value)
                        }
                        this.my_bet_list.push(bet);
                    }
                }
            }, 400),
            calMixParlay: async function (bet_list) {

                let p_total_odds = new BigNumber(1);

                for(let i = 0;i < bet_list.length;i++){

                    let bet = bet_list[i];
                    let row_eu_odd = new BigNumber(this.$options.filters.priceByCurrency(bet.odds, bet.odds_type));
                    p_total_odds = row_eu_odd.multipliedBy(p_total_odds);
                }

                return p_total_odds;
            },
            is_loss: function(d){
                return (d < 0)
            }
        }
    }
</script>

<style scoped>
    .v-table-header {
        background-color: #273644;
        color: white;
        height: 28px;
        font-size: 14px;
        font-weight: bold;
    }
    .v-table-header .v-column{
        /*margin: 0 0 0 4px;*/
        border-right: solid 1px lightgray;
        height: 100%;
        align-items: center;
        justify-content: center;
    }
    .v-result-row{
        font-size: 12px;
        /*height: 100px;*/
        justify-content: flex-start;
    }
    .v-result-row .v-column{
        /*margin: 0 0 0 4px;*/
        border-right: solid 1px lightgray;
        border-bottom: solid 1px lightgray;
        height: auto;
        align-items: center;
        justify-content: center;
    }
    .v-result-row.highlight{
        background-color: #f2f2f2;
    }

    .v-blink_blink {
        animation: blinker 1s linear infinite;
    }
    @keyframes blinker {
        50% {
            opacity: 0;
        }
    }
    .v-loss-color{
        color: red!important;
    }
    .v-profit-color{
        color: green!important;
    }
</style>