import store from '@/store'
import UserMixin from '@/mixins/UserMixin'
export default {
    name: 'GameListMixin',
    mixins: [
        UserMixin,
    ],
    computed: {
        is_member() {
            return store.state.is_member
        },
        pes_profile() {
            return store.state.pes_profile
        },
        token() {
            return this.pes_profile.token
        },
        gameProviderList() {
            return store.state.gameProviderList
        },
        appImageProfile(){
            return store.state.appInfo.app_image_profile
        },
    },
    methods: {
        play(game) {
            if (!this.is_member) {
                this.notifyToSignUp('เพื่อเล่น')
                return
            }
            var params =[
                
            ];
            let openNewTab=false;
            if(game.ApiName=='amb'){
                params.push('productId='+game.Provider);
                params.push('gameId='+game.GameCode);
                if(game.Provider.toLowerCase()=='sagame'){
                    openNewTab=true;
                }
            }else if(game.ApiName=='qt'){
                params.push('gameId='+game.GameCode);
            }else if(game.ApiName=='pragmatic'){
                params.push('code='+game.GameCode);
            }else if(game.service=='sa'){
                params.push('code='+game.code);
                openNewTab=true;
            }
            if(this.appImageProfile){
                params.push('logo_img='+this.appImageProfile);
            }
            const url = `${this.envDomains.VUE_APP_PES_BASE_URL}/game/casinoonline/sa_sexy_redirect.php?mode=mobile&service=${game.ApiName}&${params.join('&')}&token=${this.token}`
            if(openNewTab==false){
                this.$router.push({ name: 'GamePlayFrame', "query": { 'url': encodeURIComponent(url) } })
            }else{
                window.location.href=url
            }
            
            
        },
        checkGameProviderAvailable(slug) {
            try {
                if(!this.gameProviderList) {
                    return false
                }
                const provider = this.gameProviderList.find((provider) => {
                    return provider.Name === slug
                })
                if(!provider) {
                    return false
                }
                return provider.IsAvailable
            } catch(err) {
                console.log('GameListMixin.checkGameProviderAvailable err', err.message)
                return false
            }
        },
    }
}