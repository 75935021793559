<template>
    <div class="LottoPrice">

    </div>
</template>
<script>

import axios from 'axios'

export default {
    name: "LottoPrice",
    props: ['bus'],
    data(){
        return {
            data: null
        }
    },
    methods: {
        async loadLottoPrice(){

            const loading = this.$loading()

            try{

                const res = await axios({
                    method: 'POST',
                    url: `${this.envDomains.VUE_APP_LOTTO_ADMIN_API}/api/price/load_table`
                })
                if(res.status !== 200){
                    this.$alert('ไม่สามารถโหลดข้อมูล กรุณาลองอีกครั้ง', 'แจ้งเตือน', {
                        type: 'error',
                        confirmButtonText: 'ตกลง',
                        customClass: 'custom-message-box'
                    })
                    loading.close()
                    return
                }
                this.data = res.data
                this.$emit('change', {
                    data: this.data,
                    type: 'lotto_price'
                })
                loading.close()
            }catch(err){
                this.$alert('ไม่สามารถโหลดข้อมูล กรุณาลองอีกครั้ง', 'แจ้งเตือน', {
                    type: 'error',
                    confirmButtonText: 'ตกลง',
                    customClass: 'custom-message-box'
                })
                loading.close()
                return
            }
        }
    },
    mounted(){
        this.bus.$on('loadLottoPrice', this.loadLottoPrice)
        if(!this.data){
            this.loadLottoPrice()
        }
    },
    beforeDestroy(){
        this.bus.$off('loadLottoPrice', this.loadLottoPrice)
    }
}
</script>