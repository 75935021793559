<template>
    <div class="PromotionItem">
        <span>{{packet.packet_name}}</span>
        <span>({{packet_type_list[packet.packet_type]}})</span>
        
    </div>
</template>
<script>
export default {
    name: 'PromotionItem',
    props: [
        'packet'
    ],
    data() {
        return {
           packet_type_list:['สล็อต','สปิน','โบนัสสล็อต'],
        }
    },
}
</script>