<template>
    <div class="SuperAdminAdmin">
        <div class="v-content-inner">
            <div class="v-toolbar">
                <h4>ตั้งค่า</h4>
            </div>
            <div class="v-list">
                <div>โหมด Maintenance</div>
                <div>สถานะ: <el-tag :type="onMaintenanceStatusType">{{onMaintenanceStatusDisplay}}</el-tag></div>
                <div>ดำเนินการ</div>
                <div>
                    <el-button v-if="on_maintenance" @click="toUpdateOnMaintenance(false)" type="primary">ปิด</el-button>
                    <el-button v-else @click="toUpdateOnMaintenance(true)" type="warning">เปิด</el-button>
                </div>
            </div>
        </div>
    </div>
</template>
<script>

import axiosWrapper from '@/axiosWrapper'
import store from '@/store'

export default {
    name: 'SuperAdminSetting',
    data() {
        return {
            on_maintenance: false,
        }
    },
    computed: {
        super_admin(){
            return store.state.super_admin
        },
        token(){
            return this.super_admin.token
        },
        onMaintenanceStatusDisplay() {
            return (this.on_maintenance) ? 'เปิด (เว็บไซต์อยู่ในโหมด maintenance)' : 'ปิด (เว็บไซต์เปิดให้ให้งานตามปกติ)'
        },
        onMaintenanceStatusType() {
            return (this.on_maintenance) ? 'warning': 'primary'
        },
    },
    methods: {
        async loadOnMaintenance() {
            const res = await axiosWrapper({
                method: 'post',
                url: `${this.envDomains.VUE_APP_API}/api/super-admin/on-maintenance/gets`,
                data: {
                    token: this.token,
                },
            })
            if(res.status !== 200) {
                this.$message({
                    message: res.data.message,
                    type: 'error'
                });
                return
            }
            this.on_maintenance = (res.data === 'yes') ? true : false
        },
        toUpdateOnMaintenance(on_maintenance) {

            const label = (on_maintenance) ? 'ต้องการเปิดโหมด Maintenance?' : 'ต้องการปิดโหมด Maintenance?'

            this.$confirm(label, 'แจ้งเตือน', {
                confirmButtonText: 'ตกลง',
                cancelButtonText: 'ยกเลิก',
                type: 'warning'
            }).then(() => {
                this.updateOnMaintenance(on_maintenance)
            })
        },
        async updateOnMaintenance(on_maintenance) {
            const res = await axiosWrapper({
                method: 'post',
                url: `${this.envDomains.VUE_APP_API}/api/super-admin/on-maintenance/update`,
                data: {
                    token: this.token,
                    on_maintenance: (on_maintenance) ? 'yes' : 'no',
                }
            })
            if(res.status !== 200) {
                this.$message({
                    message: res.data.message,
                    type: 'error'
                });
                return
            }
            this.$message({
                message: 'ตั้งค่าสำเร็จ',
                type: 'success'
            });
            this.on_maintenance = (res.data === 'yes') ? true : false
        }
    },
    mounted() {
        this.loadOnMaintenance()
    },
}
</script>