<template>
    <div class="Avatar">
        <el-avatar :size="size" v-if="mode === 'default'" src="/images/avatar.png"></el-avatar>
        <el-avatar :size="size" v-if="mode === 'image'" 
        :src="src || '/images/avatar.png'" @error="errorHandler">
            <img src="/images/avatar.png">
        </el-avatar>
        <el-avatar v-else :size="size" class="app-color-primary">{{title | displayNameShort}}</el-avatar>
    </div>
</template>
<script>
export default {
    name: 'Avatar',
    props: [
        'mode',
        'title',
        'dataSize',
        'src',
    ],
    data() {
        return {
            size: 'large',
        }
    },
    methods: {
        errorHandler() {
            return true
        },
    },
    created() {
        if(this.dataSize) {
            this.size = this.dataSize
        }
    },
}
</script>