<script>

import store from '@/store'
import UserMixin from '@/mixins/UserMixin'

export default {
    name: 'LayoutMixin',
    mixins: [
        UserMixin
    ],
    computed: {
        showMenuLeft: {
            get() {
                return store.state.showMenuLeft
            },
            set(value) {
                store.commit('updateShowMenuLeft', value)
            }
        },
        current_game: {
            get() {
                return store.state.current_game
            },
            set(value) {
                store.commit('updateCurrentGame', value)
            }
        },
        showDrawer: {
            get() {
                return store.state.showDrawer
            },
            set(value) {
                store.commit('updateShowDrawer', value)
            }
        }
    },
    methods: {
        openLeftMenu() {
            this.showMenuLeft = true
        },
        closeLeftMenu() {
            this.showMenuLeft = false
        },
        openDrawer() {
            this.showDrawer = true
        },
        closeDrawer() {
            this.showDrawer = false
        },
    }
}
</script>