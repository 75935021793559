import axiosWrapper from '@/axiosWrapper'
export default {
    name: 'NumberSettingMixin',
    methods: {
        async getNumberSettingArr(lotto_type){
            try{
                const res = await axiosWrapper({
                    method: 'POST',
                    url: `${this.envDomains.VUE_APP_LOTTO_ADMIN_API}/api/number/get-number-arr`,
                    data: {
                        lotto_type
                    }
                })
                if(res.status === 200){
                    return res.data
                }else{
                    return null
                }
            }catch(err){
                console.log('numberSettingArr.getNumberSettingArr', err.message)
                return null
            }
        }
    }
}