<template>
	<div v-on:click="refresh" class="RefreshButton flex item-center">
		<div class="icon-refresh"></div>
		<div>{{seconds}}</div>
	</div>
</template>
<script>

	import {mixin as VueTimers} from 'vue-timers'

export default {
	name: "RefreshButton",
	mixins: [VueTimers],
	props: ['start_seconds'],
	timers: {
		countDown: {
			time: 1000,
			autostart: true,
			repeat: true
		}
	},
	data: function() {
		return {
			seconds: 60
		};
    },
    watch: { 
        seconds: function(newVal) {
            if(newVal === 0){
                this.refresh();
            }
        }
    },
	methods: {
		refresh: function() {
			if(this.start_seconds){
				this.seconds = this.start_seconds
			}else{
				this.seconds = 60;
			}
            this.$emit('click');
        },
        countDown: function(){
            this.seconds -= 1;
        }
	},
	mounted() {
		if(this.start_seconds){
			this.seconds = this.start_seconds
		}
	}
};
</script>
<style scoped>
.icon-refresh {
	width: 15px;
	height: 15px;
	background-image: url(../../assets/images/soccer-game/table-icon.png);

	background-position: -46px -42px;
}
.RefreshButton {
	cursor: pointer;
	margin: 0 4px;
}
</style>