export default ({
    namespaced: true,
    state: {
        currentPage: 'home',
        channel: null,
        select_category_list: [],
        bet_list: [],
        is_modal_open: false,
        accept_any_odd: false,
        option_step_list : [],
        category_list: null,
        price_dynamic: null,
        price_dynamic_run: null,
        price_dynamic_3: null,
        price_dynamic_3_run: null,
        amount_all: null,
        round_obj: null,
        bet_list_index: 0,
        price_table: null,
        reward_limit: null,
        pageBetQueryLottoType: '',
        pageBetQueryRoundId: '',
        isOnBetDoneModalShow: false,
    },
    mutations: {
        updateCurrentPage (state, ctx) {
            state.currentPage = ctx
        },
        update_channel: function(state, ctx){
            state.channel = ctx;
        },
        add_select_category: function(state, category){
            state.select_category_list.push(category);
        },
        remove_select_category: function(state, i){
            state.select_category_list.splice(i, 1);
        },
        clear_select_category: function(state){
            state.select_category_list = [];
        },
        add_bet_list: function(state, ctx){
            state.bet_list.push(ctx);
            // state.bet_list.splice(0, 0, ctx);
        },
        update_bet_list: function(state, ctx){
            state.bet_list.splice(ctx.i, 1, ctx);
        },
        remove_bet_list: function(state, i){
            state.bet_list.splice(i, 1);
        },
        clear_bet_list: function(state){
            state.bet_list = [];
        },
        update_is_modal_open: function(state, is_modal_open){
            state.is_modal_open = is_modal_open;
        },
        update_accept_any_odd: function(state, ctx){
            state.accept_any_odd = ctx;
        },
        add_option_step_list: function(state, ctx){
            state.option_step_list.push(ctx);
        },
        remove_option_step_list: function(state, i){
            state.option_step_list.splice(i, 1);
        },
        clear_option_step_list: function(state){
            state.option_step_list = [];
        },
        update_category_list: function(state, ctx){
            state.category_list = ctx;
        },
        update_category_list_reward: function(state, ctx){
            state.category_list[ctx.key].reward = ctx.value;
        },
        update_price_dynamic: function(state, ctx){
            state.price_dynamic = ctx;
        },
        updatePriceDynamicRun: function(state, ctx){
            state.price_dynamic_run = ctx;
        },
        update_price_dynamic_3: function(state, ctx){
            state.price_dynamic_3 = ctx;
        },
        updatePriceDynamic3Run: function(state, ctx){
            state.price_dynamic_3_run = ctx;
        },
        update_amount_all: function(state, ctx){
            state.amount_all = ctx;
        },
        update_round_obj: function(state, ctx){
            state.round_obj = ctx;
        },
        update_bet_list_index(state, ctx){
            state.bet_list_index = ctx;
        },
        updatePriceTable(state, ctx){
            state.price_table = ctx
        },
        updateRewardLimit(state, ctx){
            state.reward_limit = ctx
        },
        updatePageBetQueryLottoType(state, ctx) {
            state.pageBetQueryLottoType = ctx
        },
        updatePageBetQueryRoundId(state, ctx) {
            state.pageBetQueryRoundId = ctx
        },
        updateIsOnBetDoneModalShow(state, ctx) {
            state.isOnBetDoneModalShow = ctx
        },
    },
    actions: {
        clearBetListOnly: async function({commit}){
            commit('clear_bet_list');
        },
        clearBetList: async function({commit}){
            commit('clear_bet_list');
            commit('clear_option_step_list');
        },
        removeBetListByNumber: async function({state}, number){
            state.bet_list = state.bet_list.filter((o) => {
                return number !== o.number;
            });
        },
        removeBetListByNumberArr: async function({state}, number_arr){
            state.bet_list = state.bet_list.filter((bet) => {
                const found = number_arr.find((number) => {
                    return bet.number === number;
                });
                return !found;
            });
        },
        removeBetListByBetListIndex: async function({state}, bet_list_index){
            state.bet_list = state.bet_list.filter((bet) => {
                return bet.bet_list_index !== bet_list_index
            });
        },
    },
})