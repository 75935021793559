export const DOMAINMAPPING = {
  "chat.liffline.com": { c: "1654414133", m: "@461dlprb" },
  localhost: {
    c: "1654414133",
    m: "@461dlprb",
  },
  'ad2liff.com':{
    c:'1656832785',
    m:'@514yrqge'
  },
  'ad2pe.com':{
    c:'1656900743',
    m:'@052irbfa'
  },
};

