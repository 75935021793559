<template>
    <div class="scroreboard-wrapper">
        <div class="box-score-table" id="stat-box-bottom" style="height: 139px;">
            <div class="column" ref="column-left" style="overflow-x: scroll;">
                <Main @existStatWidthChange="mainScroll" :statArr="statArr"></Main>
            </div>
            <div ref="column-right" class="scoreboard-column-right">
                <div ref="big-road-wrapper" class="big-road-wrapper">
                    <BigRoad @existStatWidthChange="bigRoadScroll" :statArr="statArr"></BigRoad>
                </div>
                <div ref="big-eye-boy-wrapper" class="big-eye-boy-wrapper">
                    <BigEyeBoy 
                    @existStatWidthChange="bigEyeBoyScroll" 
                    roadType="big-eye-boy" 
                    :statArr="statArr"
                    gameType="dragon-tiger"></BigEyeBoy>
                </div>
                <div class="derived-road-wrapper">
                    <div ref="small-road-wrapper" class="small-road-wrapper">
                        <BigEyeBoy 
                        @existStatWidthChange="smallRoadScroll" 
                        roadType="small-road" 
                        :statArr="statArr"
                        gameType="dragon-tiger"></BigEyeBoy>
                    </div>
                    <div ref="cockroach-pig-wrapper" class="cockroach-pig-wrapper">
                        <BigEyeBoy 
                        @existStatWidthChange="cockroachPigScroll" 
                        roadType="cockroach-pig" 
                        :statArr="statArr"
                        gameType="dragon-tiger"></BigEyeBoy>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>
<script>

import Main from './StatMain'
import BigRoad from './StatBigRoad'
import StatScrollMixin from '../StatScrollMixin'
import BigEyeBoy from '../baccarat/StatBigEyeBoy'

export default {
    name: 'DragonTigerScoreboard',
    props: [
        'statArr'
    ],
    mixins: [
        StatScrollMixin,
    ],
    components: {
        Main,
        BigRoad,
        BigEyeBoy,
    },
}
</script>
<style scoped>
.scroreboard-wrapper {
    width: 100%;
    background-color: white;
}
.box-score-table {
    border: solid 1px;
    display: flex;
}

.box-score-table table {
    border: 0 !important;
    width: 100%;
    height: 100%;
}

.box-score-table td {
    border-collapse: collapse;
}

.column {
    display: flex;
    float: left;
    width: 41%;
    border-right: solid 1px;
    /*border-right: 1px;*/
    /*border-left: 1px;*/
    /*border-style: solid;*/
    /*border-color: rgb(66, 65, 65);*/
}

.column1 {
    display: flex;
    float: left;
    width: 59%;
}

.box-score-table .score-scroll {
    margin: 0;
    width: auto;
}

.score-scroll:hover {
    overflow-x: overlay;
}

div.score-scroll {
    display: block;
    position: relative;
    width: 100%;
    overflow-x: hidden;
    overflow-y: hidden;
    white-space: nowrap;
    border-bottom: solid 1px #000;
}

.score-scroll {
    background-color: transparent;
}

.board-backgroundx {
    border: solid 1px #ddd !important;
}

.text-score_bigroad {
    /* font-size: 14px; */
    width: 9px;
    height: 9px;
    display: block;
    margin: 1px;
}

.text-score {
    width: 15.5px;
    height: 15.5px;
    display: block;
    margin: 2px;
}

.text-score, .text-score-status, .text-score_all, .text-score-topic, .text-status-primary, .text-status-secondary, .text-red-bcc, .text-score_eye, .text-score_bigroad, .text-blue-bcc, .text-score_bigeye, .text-score_small {
    text-shadow: 0.5px 0.5px 0.5px #eee;
}

.text-box-score-right_eye {
    width: 4px;
    height: 2px;
}

.text-box-score-right_eye {
    display: table;
    margin-top: 1px;
    margin-bottom: 1px;
    margin-right: 1px;
    margin-left: 1px;
    padding-top: 1px;
}

.text-box-score-right_small {
    width: 2px;
    height: 2px;
}

.text-box-score-right_small {
    display: table;
    margin-top: 1px;
    margin-bottom: 1px;
    margin-right: 1px;
    margin-left: 1px;
    padding-top: 1px;
}

.color-red {
    background-color: #c52828 !important;
}

.color-blue {
    background-color: #006aff !important;
}

.color-yellow {
    background-color: #fab600;
}

.box-lot-cell {
    text-align: center;
    font-size: 13px;
    width: 18px;
    height: 18px;
    border-radius: 50%;
    cursor: pointer;
    display: inline-block;
    line-height: 17px;
    color: #fff;
    float: left;
    margin: 0 auto;
    font-family: "Athiti", sans-serif;
    margin-top: -1px;
    margin-left: -2px;
    position: relative;
}

.box-lot-cell_sm, .box-lot-cell_mini {
    width: 11px;
    height: 11px;
    border-radius: 50%;
    display: inline-block;
    float: left;
    font-family: "Athiti", sans-serif;
    margin-top: -1px;
    margin-left: -2px;
    border: solid 2px;
}

.box-lot-cell_mini {
    width: 6px;
    height: 6px;
    float: right;
}

.float-dot{
    display: block;
    width: 8px;
    height: 8px;
    border-radius: 50%;
    position: absolute;
    border: solid 1px #fff;
}

.dot-red{
    background-color: #c52828 !important;
    top: -1px;
    left: -2px;
}

.dot-blue{
    background-color: #006aff !important;
    bottom: -1px;
    right: 0px;
}

.border-red {
    border-color: #c52828 !important;
}

.border-blue {
    border-color: #006aff !important;
}
.scoreboard-column-right {
    width: 59%;
}
.big-road-wrapper {
    display: flex;
    width: 100%;
    height: 68px; 
    overflow-x: scroll; 
    border-bottom: solid 1px black;
}
.big-eye-boy-wrapper {
    display: flex;
    width: 100%;
    height: 35px;
    overflow-x: scroll;
    border-bottom: solid 1px black;
}
.derived-road-wrapper {
    display: flex;
    height: 34px;
    overflow-x: scroll;
}
.small-road-wrapper, .cockroach-pig-wrapper {
    display: flex;
    width: 50%;
    height: 100%;
    overflow-x: scroll;
}
.small-road-wrapper {
    border-right: solid 1px black;
}
</style>