<template>

    <div>
<!--    <div class="GameType GameUnbox">-->
<!--edit-->
        <!-- <FloatButtomRightPanel  :callChatWithAdmin="showChatWithAdminNumber"/> -->
        <MenuBar  v-if="false"></MenuBar>
<!--        <MenuBar v-if="!embedMode" @openGame="openGame" @toggleMenu="openLeftMenu"/>-->
<!--        <transition name="slide">-->
<!--            <MenuLeft @close="closeLeftMenu" v-show="showMenuLeft"></MenuLeft>-->
<!--        </transition>-->
        <div class="box-img" v-if="false">
<!--            <div class="box-user-img"><img src="@/assets/images/icon/set-icon/icon-spin.png"/></div>-->
            <h4>เปิดกล่อง</h4>
        </div>
<!--        <div class="content-page">-->
        <div class="">
            <div class="content-page-spin">

                <div class="container-fluid-game container-fluid-game-spin">
                    <div class="wrap-content-games">
                        <div v-for="(bonus, key) of bonus_list"
                    :key="key" class="wrap-games-list full-width text-center" :class="['bt-jackpot-'+bonus['bonus_color']]">
                            <div class="container-fluid-jackpot">
                                <div class="container-fluid-jackpot-info">
                                    <div class="container-jackpot-grand">
                                        <div class="detail">
                                            <h2>GRAND</h2>
                                            <ul>
                                                <li>
                                                    <span :class="'bonus-'+ key+'-0'">{{bonus.bonus_current[0]}}</span>
                                                </li>
                                                <li><p>{{bonus.bonus_range[0]}}</p></li>
                                            </ul>
                                        </div>
                                        <div class="progress">
                                            <div class="progress-bar"  role="progressbar" v-bind:aria-valuenow="bonus.bonus_percent[0]"  aria-valuemin="0" aria-valuemax="100" v-bind:style="{ width: bonus.bonus_percent[0]+'%' }">
                                            <div class="wink"></div>
                                            </div>
                                        </div>
                                        <div class="jackpot-bg"></div>
                                    </div>
                                    <div class="container-jackpot-minor container-jackpot-major">
                                        <div class="detail">
                                            <h2>MAJOR</h2>
                                            <ul>
                                                <li><span :class="'bonus-'+ key+'-1'">{{bonus.bonus_current[1]}}</span></li>
                                                <li><p>{{bonus.bonus_range[1]}}</p></li>
                                            </ul>
                                        </div>
                                        <div class="progress">
                                            <div class="progress-bar"  role="progressbar" v-bind:aria-valuenow="bonus.bonus_percent[1]"  aria-valuemin="0" aria-valuemax="100" v-bind:style="{ width: bonus.bonus_percent[1]+'%' }">
                                                <div class="wink"></div>
                                            </div>
                                        </div>
                                        <div class="jackpot-bg"></div>
                                    </div>
                                    <div class="container-jackpot-mini">
                                        <div class="detail">
                                            <h2>MINI</h2>
                                            <ul>
                                                <li><span :class="'bonus-'+ key+'-3'" >{{bonus.bonus_current[3]}}</span></li>
                                                <li><p>{{bonus.bonus_range[3]}}</p></li>
                                            </ul>
                                        </div>
                                        <div class="progress">
                                            <div class="progress-bar"  role="progressbar" v-bind:aria-valuenow="bonus.bonus_percent[3]"  aria-valuemin="0" aria-valuemax="100" v-bind:style="{ width: bonus.bonus_percent[3]+'%' }">
                                                <div class="wink"></div>
                                            </div>
                                        </div>
                                        <div class="jackpot-bg"></div>
                                    </div>
                                    <div class="container-jackpot-bonus">
                                        <div class="detail">
                                            <h2>FIXED</h2>
                                            <ul>
                                                <li><p>{{bonus.bonus_range[4]}}</p></li>
                                            </ul>
                                        </div>
                                        <div class="jackpot-bg"></div>
                                    </div>
                                </div>
                                <div class="container-fluid-jackpot-bg"></div>
                            </div>
                            <div class="row">
                                <div class="bx-desktop bx-ipad">
                                    <div class="content-jackpot-box">
                                        <div class="content-jackpot-box-info">
                                            <div v-for="(case_obj, case_key) of bonus.case_list"
                                                 :key="case_key" class="" >
                                                <div class="box-jackpot-item">
                                                    <router-link :to="{name: 'UnboxPlay',params: { id: case_obj.id }}">
                                                        <div class="info-jackpot-item" >
                                                            <div v-if="case_obj.bonus_count>0" class="shop-ribbon free-ribbon-top" style="right: -8px; left: auto; top: 0px;">
                                                                <strong data-v-77a61008="">ฟรีสปิน</strong>
                                                                <h4>x{{case_obj.bonus_count}}</h4>
                                                                <div class="shop-ribbon-info"></div>
                                                            </div>
                                                            <div class="item-jackpot-case-image">
                                                                <div :style="'background-image: url('+case_obj.choose_case+')'"></div>
                                                            </div>
                                                            <div class="item-detail">
                                                                <h2>฿{{case_obj.price_case}} </h2>
                                                                <h4>{{case_obj.name_case}}</h4>
                                                            </div>
                                                            <div class="background-bg-case"></div>
                                                        </div>
                                                    </router-link>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div class="bx-mobile bx-ipad">
                                    <div class="content-jackpot-box">
                                        <carousel
                                                :per-page="2.6"
                                                :mouseDrag="true"
                                                :navigationEnabled="true"
                                                :paginationEnabled="false"
                                                navigationNextLabel="❯"
                                                navigationPrevLabel="❮"
                                                paginationPosition="bottom"
                                        >

                                            <slide v-for="(case_obj, case_key) of bonus.case_list"
                                                   :key="case_key" class="" >
                                                <div class="box-jackpot-item">
                                                    <router-link :to="{name: 'UnboxPlay',params: { id: case_obj.id }}">
                                                        <div class="info-jackpot-item" >
                                                            <div v-if="case_obj.bonus_count>0" class="shop-ribbon free-ribbon-top" style="right: -8px; left: auto; top: 0px;">
                                                                <strong data-v-77a61008="">ฟรีสปิน</strong>
                                                                <h4>x{{case_obj.bonus_count}}</h4>
                                                                <div class="shop-ribbon-info"></div>
                                                            </div>
                                                            <div class="item-jackpot-case-image">
                                                                <div :style="'background-image: url('+case_obj.choose_case+')'"></div>
                                                            </div>
                                                            <div class="item-detail">
                                                                <h2>฿{{case_obj.price_case}} </h2>
                                                                <h4>{{case_obj.name_case}}</h4>
                                                            </div>
                                                            <div class="background-bg-case"></div>
                                                        </div>
                                                    </router-link>
                                                </div>
                                            </slide>

                                        </carousel>
                                    </div>
                                </div>


                            </div>
                        </div>

                    </div>
                </div>
            </div>
        </div>
    </div>

</template>
<script>
    import MenuBar from '@/components/MenuBar'
    import LayoutMixin from '@/mixins/Layout'
    import store from '@/store'
    import axiosWrapper from '@/axiosWrapper'
    import ReconnectingWebSocket from 'reconnecting-websocket'
    import $ from 'jquery'
    import { Carousel, Slide } from 'vue-carousel'
    // import FloatButtomRightPanel from '@/components/FloatButtomRightPanel'

    window.jQuery = $;
window.$ = $;
var ws;
var _vue;
var BONUS_LEVEL_MODAL=['ModalGrand','ModalMajor','ModalMinor','ModalMini','ModalBonus'];

export default {
    name: 'UnBoxPage',
    components: {
        // FloatButtomRightPanel,
        // MenuBarUnbox,
        MenuBar,
        Carousel,
        Slide,
    },
    mixins: [
        LayoutMixin,
    ],
    data() {

        
        return {
            showChatWithAdminNumber:0,
            showDepositWithdrawNumber:0,
            item: null,
            gameGroups: [
                {
                    icon : '/images/casino/logo-sexy-baccarat.png',
                    title : 'Sexy Baccarat',
                },
                {
                    icon : '/images/casino/sa-casino.png',
                    title : 'SA Baccarat',
                },
                {
                    icon : '/images/casino/logo-111.png',
                    title : 'Pragmatic Play',
                },
                {
                    icon : '/images/casino/play-bacarat.jpg',
                    title : `King's Poker`,
                },
            ],
            all_case:null,
            bonus_list :[],

            bonus:[
                {bonus_current:null},
                {bonus_current:null},
                {bonus_current:null},
                {bonus_current:null},
                {bonus_end:null},
            ]

        }
    },
    destroyed: function() {
    //  console.log('my-component destroyed');
     ws.close();
    },
    computed: {
            is_member() {
                return store.state.is_member
            },
            pes_profile() {
                    return store.state.pes_profile?store.state.pes_profile:{
                        sp_bonus_item:{ 
                            spiner:{}
                        }
                    }
                },
                token() {
                    return  this.pes_profile.token?this.pes_profile.token:null;
                },
        },
        methods: {
            showChatWithAdmin(){
                this.showChatWithAdminNumber=1;
                let self =this;
                setTimeout(()=>{
                    self.showChatWithAdminNumber=0;
                },100);
                
            },
            showDepositWithdrawModal(){
                this.showDepositWithdrawNumber=1;
                let self =this;
                setTimeout(()=>{
                    self.showDepositWithdrawNumber=0;
                },100);
                
        },

                async getAllCase() {

                    const res = await axiosWrapper({
                        method: 'get',
                        url: `${this.envDomains.VUE_APP_PES_BASE_URL}/api/game/v0/getAllCase.php`,
                    });
                    return res.data;
                },
                openGame() {
                },
                initWs() {
                    var hostname =`${this.envDomains.VUE_APP_PES_WS_BASE_URL}`;
                    var token = this.token;
                    ws = new ReconnectingWebSocket(hostname, token, {debug: false, reconnectInterval: 3000});
                    ws.onmessage = function (msg) {
                        var response = JSON.parse(msg.data);
                        manageWSContent(response, 'all');

                    }
                    ws.onopen = function () {
                        var data = {'type': 'subscribe', list: ['chat', 'spiner']};
                        wsSendData(data);
                    };

                },
               


                formatToPrice(value) {
                return `${value.toFixed(3)}`;
                }
        },
        async mounted() {
            var allCase=(await this.getAllCase());
            var spiner =this.pes_profile.sp_bonus_item.spiner;
            // console.log(spiner);
            for(var aIndex=0;aIndex<allCase.length;aIndex++){
                for(var bIndex=0;bIndex<allCase[aIndex].length;bIndex++){
                    if(allCase[aIndex][bIndex].id in spiner){
                        allCase[aIndex][bIndex].bonus_count = spiner[allCase[aIndex][bIndex].id];
                        

                    }else{
                        allCase[aIndex][bIndex].bonus_count=0;
                    }
                    // console.log(aIndex,allCase[aIndex][bIndex].bonus_count);
                }
                
            }
            this.all_case =allCase;


            var case_list = allCase[0];
            var blv = allCase[1];
            var bl = allCase[3];
            var bonus_list={};
            for(var index =0;index<bl.length;index++){
                var gbc_id = bl[index].gbc_id;
                bonus_list[gbc_id]=bl[index];
            }
           
            var bonus_list_key={};
            var new_bonus_list={};
            
            for(var i=0;i<case_list.length;i++){
                var c = case_list[i];
                if(c.bonus_config_id){
                    var bonus_config_id = c.bonus_config_id;
                    if (!(bonus_config_id in bonus_list_key)){

                        bonus_list_key[bonus_config_id]=0;
                        var b=bonus_list[bonus_config_id];
                        
                        var obj={
                            bonus_color:b.bonus_color,
                            bonus_range :[
                                new Intl.NumberFormat('en-IN', { maximumSignificantDigits: 2 }).format(b.bonus_lv_0_start)+'-'+new Intl.NumberFormat('en-IN', { maximumSignificantDigits: 2 }).format(b.bonus_lv_0_end),
                                new Intl.NumberFormat('en-IN', { maximumSignificantDigits: 2 }).format(b.bonus_lv_1_start)+'-'+new Intl.NumberFormat('en-IN', { maximumSignificantDigits: 2 }).format(b.bonus_lv_1_end),
                                new Intl.NumberFormat('en-IN', { maximumSignificantDigits: 2 }).format(b.bonus_lv_2_start)+'-'+new Intl.NumberFormat('en-IN', { maximumSignificantDigits: 2 }).format(b.bonus_lv_2_end),
                                new Intl.NumberFormat('en-IN', { maximumSignificantDigits: 2 }).format(b.bonus_lv_3_start)+'-'+new Intl.NumberFormat('en-IN', { maximumSignificantDigits: 2 }).format(b.bonus_lv_3_end),
                                new Intl.NumberFormat('en-IN', { maximumSignificantDigits: 2 }).format(b.bonus_lv_4_end)
                            ],
                            bonus_percent :[
                                0,
                                0,
                                0,
                                0,
                                0
                            ],
                             
                            bonus_current :[
                                0,0,0,0,0
                            ],
                            case_list :[],
                        }
                        new_bonus_list[bonus_config_id] =obj;
                    }
                    // console.log(obj.bonus_percent)
                    bonus_list_key[bonus_config_id]++;
                    new_bonus_list[bonus_config_id].case_list.push(c);
                    
                }
            }

             for(var j=0;j<blv.length;j++){
                var gbc_ref_id = blv[j].gbc_ref_id;
                var bonus_level =blv[j].bonus_level;
                var bonus_status  =blv[j].bonus_status;
                var bonus_current = blv[j].bonus_current;
                var bonus_end = blv[j].bonus_end;
                if(bonus_status==0){
                    if ((gbc_ref_id in new_bonus_list)){
                        var percent = parseInt((bonus_current/bonus_end)*100);
                        new_bonus_list[gbc_ref_id]['bonus_percent'][bonus_level]=percent;
                        new_bonus_list[gbc_ref_id]['bonus_current'][bonus_level]=formatNumber(parseFloat(bonus_current).toFixed(2));
                        // new Intl.NumberFormat('en-IN', { maximumSignificantDigits: 3 }).format(bonus_current);
                    }
                }
            }
            this.bonus_list = new_bonus_list;
            this.initWs();
            _vue = this;
        },


}

function  manageWSContent(response,page){
                    var type = response.type;
                    var data = response.data;
                    if(page=='all') {
                        if (type == 'chat') {
                            var init1 = response.init;
                            init1;
                        }else if(type=='balance'){
                            setTimeout(function(){
                                _vue.pes_profile.THB_free = data; 
                            },1000*10.5)
                            
                        }else if(type=='unbox-packet'){

                            if(!(data.ref_id+'' in _vue.pes_profile.sp_bonus_item.spiner)){
                                _vue.pes_profile.sp_bonus_item.spiner[data.ref_id]=0;
                            }
                            _vue.pes_profile.sp_bonus_item.spiner[data.ref_id]=data.count;
                            _vue.pes_profile.THB_free = data.balance;
                            for(var key in _vue.case_list){
                                if(_vue.case_list[key].id==data.ref_id){
                                    _vue.case_list[key].bonus_count = data.count;
                                    _vue.$set(_vue.case_list[key],'bonus_count',  data.count);
                                }
                            }
                            
                        } else if (type == 'bonus') {
                            for (var i = 0; i < data.length; i++) {
                                var b = data[i];
                                if (b != null) {
                                    var bonus_config_id = b.gbc_ref_id;
                                    var bonus_current = b.bonus_current;
                                    var bonus_status = b.bonus_status;
                                    var bonus_level = b.bonus_level;
                                    // var bonus_start = b.bonus_start;
                                    var bonus_end = b.bonus_end;
                                
                                    _vue.bonus_list[bonus_config_id].bonus_percent[bonus_level] = parseInt((bonus_current/bonus_end)*100);
                                    if (bonus_status == 0 && bonus_level != 4) {
                                        updateBonusNumber('.bonus-'+bonus_config_id+'-'+bonus_level,bonus_current,2);
                                    } else if (bonus_level != 4) {
                                        updateBonusNumber('.bonus-'+bonus_config_id+'-'+bonus_level,bonus_current,2);
                                    }
                                    if(bonus_level==0 || bonus_level==1){
                                        bonus_level;
                                        // manageShowUnboxBonus(b);

                                    }
                                }

                            }
                        } else if (type == 'bonus_reward') {
                            showBonusReward(response);
                            type;
                            // for (var i = 0; i < data.length; i++) {
                            //     updateContentGame(data[i],9000);
                            // }
                        } else if (type == 'item_recent') {
                            type;
                            // // $('#item_recent_left').html('');
                            // for (var i = 0; i < data.length; i++) {
                            //     updateContentGame(response.data[i]);
                            // }
                        }else if(type=='noti_msg') {
                            type;
                            // var noti_count = data.noti_count;
                            // $('div.noti-box').html('<span class="label">' + noti_count + '</span>');
                            // $('span.noti-box-right').html(noti_count);
                        }else if(type=='lotto_noti1') {
                            // console.log("have>>>>");
                            type;
                            // manageLottoNoti1(data);


                        }else if(type=='lotto_noti2'){
                            type;
                            // updateContentGame(response.data);
                        } else if (response.code == 1) {
                            type;
                            // manageGameData(response);
                        }
                    }else if(page=='spiner'){
                        page;

                    }else if(page=='mini_game'){
                        if (type == 'chat') {
                            var init2 = response.init;
                            init2;
                            // manageChat(data, init);
                        }else if(type=='last_result'){
                            type;
                            // var is_mobile = path.includes("/m/");
                            // if(response.hasOwnProperty('data')){
                            //     var list = response.data;

                            //     for(var i=0;i<list.length;i++){
                            //         var html = is_mobile?lastResultTrMobile(list[i]):lastResultTr(list[i]);
                            //         if(is_mobile){
                            //             $('#collapseTopJackpot').children('div').append(html);
                            //         }else{
                            //             $('#ul-game-list').append(html);
                            //         }

                            //     }
                            // }else{
                            //     var html = is_mobile?lastResultTrMobile(response):lastResultTr(response);
                            //     var second = 7000;
                            //     if(response.game_name=='Hilo'){
                            //         second=3000;
                            //     }else if(response.hasOwnProperty('delay')){
                            //         second=delay_second[response.delay];
                            //     }
                            //     setTimeout(function(){
                            //         if(is_mobile){
                            //             $('#collapseTopJackpot').children('div').prepend(html);
                            //         }else{
                            //             $('#ul-game-list').append(html);
                            //         }
                            //     },second);

                            // }
                        }else if(type=='noti_msg'){
                            type;
                            // var noti_count=data.noti_count;
                            // $('div.noti-box').html('<span class="label">'+noti_count+'</span>');
                            // $('span.noti-box-right').html(noti_count);
                        }
                    }else{
                        type;
                    }

                } 
function formatNumber(num) {
  return num.toString().replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,')
}

function  wsSendData(_data) {
     ws.send(JSON.stringify(_data));
}
function updateBonusNumber(id,value){

    $(id).each(function() {
    var self = $(this),
    countTo = value;

    $({
        countNum: self.text().replace(",",'')
    }).animate({
        countNum: countTo
        },

        {
        duration: 500,
        easing: 'linear',
        step: function() {
        
            self.text(commaSeparateNumber(parseFloat(this.countNum).toFixed(3).replace(/(\d)(?=(\d{3})+\.)/g, '$1,')));
            
        },
        complete: function() {
            self.text(commaSeparateNumber(parseFloat(this.countNum).toFixed(3).replace(/(\d)(?=(\d{3})+\.)/g, '$1,')));
            // alert('finished');
        }
        }
    );

    });
}
function commaSeparateNumber(val) {
  while (/(\d+)(\d{3})/.test(val.toString())) {
    val = val.toString().replace(/(\d+)(\d{3})/, '$1' + ',' + '$2');
  }
  return val;
}
function showBonusReward(b){

    if(b.game=='spiner'){
        var bonus = b.data[0];
        var bonus_modal = BONUS_LEVEL_MODAL[bonus.bonus_level];
        $('#'+bonus_modal).show();
        _vue.bonus[bonus.bonus_level].bonus_current = parseFloat(bonus.bonus_current).toFixed(2);
        _vue.bonus[bonus.bonus_level].bonus_end = bonus.bonus_end;
    }
}
</script>
<style src="@/assets/css/unbox.css" scoped></style>
<style src="@/assets/css/package.css" scoped></style>

<style>
    /*.GameType .MenuBar.box-all-games{display: none!important}*/
    @media (orientation: landscape) {
        /*.GameType .MenuBar.box-all-games{display: block!important}*/
    }
</style>

<style>
    .GameUnbox span.box-txt-back:before{
        content: '\e88a';
        font: 25px "Material Icons";
        margin-right: 5px;
    }
</style>
<style scoped>
    .VueCarousel-slide{
        /*flex-basis: initial;*/
    }
    .GameUnbox .content-page-spin{position: relative}
    .GameUnbox .box-img{
        top: 5px!important;
        left: 35px;
        display: flex;
        align-items: center;
        justify-content: center;
        height: 35px!important;
        z-index: 0;
    }
    .GameUnbox .box-img h4{
        font: 800 20px/1 Athiti;
        margin: 0;
    }
    .GameUnbox{
        background-image: linear-gradient(
                135deg
                , #F372D4 10%, #c765f0 100%);
    }

    .wrap-menu-top{
        padding: 10px 5px 10px 5px;
        background-image: none;
    }

    .content-page {
        background-color: #fff;
        border-radius: 20px 20px 0 0;
        padding: 5px 0;
    }

    .box-user-img,
    .box-user-img img {
        display: inline-block;
        width: 40px;
        height: 40px;
        border-radius: 50%;
        margin-right: 10px;
    }

    .box-img small {
        display: block;
        margin-top: -5px;
    }

    .box-list-menu {
        padding: 0px 10px;
    }

    .box-list-menu p {
        font-size: 14px;
        font-weight: bold;
        margin-bottom: 10px;
        color: #54117f;
        font-family: "Athiti", sans-serif;
    }

    .wrapper-box{
        margin-top: 15px;
    }

    .box-games-list{
        width: 145px;
        padding: 0px;
        border-radius: 10px;
    }
    .box-img-list{
        border-radius: 10px;
        background-size: cover;
        background-position: center;
        height: 80px;
        background-image: url(../assets/images/casino/sexy-baccarat/sexy-bacarat.png);
    }

    .box-type{
        font-size: 13px;
        margin-bottom: 5px;
        clear: both;
        height: 20px;
    }

    .box-type > .txt-header{
        color: #54117f;
        font-weight: 600;
    }

    .box-type a{
        color: #888;
    }

    .box-type img{
        height: 20px;
        margin-top: -5px;
    }

    .wrapper-box-type{
        margin-bottom: 10px;
    }

    @media only screen
    and (max-device-width: 1300px)
    and (orientation: landscape){
        /*.GameType{*/
        /*    overflow-y: auto;*/
        /*    height: 100vh;*/
        /*    position: relative;*/
        /*}*/
        /*.MenuBarUnbox .MenuBar.box-all-games{display: block}*/
        /*.content-page {*/
        /*    position: relative;*/
        /*    margin: 20px 10px 0 42%;*/
        /*}*/
        /*.MenuBarUnbox .wrapbox-list-games{*/
        /*    height: calc(100vh - 50px);*/
        /*}*/
        /*.wrap-menu-top {*/
        /*    padding: 5px 5px 40px 5px;*/
        /*}*/
        /*.GameType .content-page-spin .box-img .box-user-img{display: none!important}*/
        /*.GameType .content-page-spin .box-img{*/
        /*    top: -48px!important;*/
        /*    left: 30px!important;*/
        /*}*/
    }
</style>