export const TABLE_TYPE = {
    0: 'บาคาร่า',
    1: 'ไฮโล',
    2: 'หยิบถั่ว',
    3: 'เสือมังกร',
}

export const TABLE_TYPE_KEY = {
    BACCARAT: 0,
    SICBO: 1,
    BEAN: 2,
    DT: 3,
}

export const CARD_TYPE_IMAGE = {
    0: '04',
    1: '02',
    2: '03',
    3: '01',
}

export const CARD_NUMBER_IMAGE = {
    1: '01',
    2: '02',
    3: '03',
    4: '04',
    5: '05',
    6: '06',
    7: '07',
    8: '08',
    9: '09',
    10: '10',
    11: '11',
    12: '12',
    13: '13',
}

export const CARD_FACE = {
    0: {
        emoji: '♠️',
    },
    1: {
        emoji: '♦️',
    },
    2: {
        emoji: '❤️',
    },
    3: {
        emoji: '♣️',
    },
}

export const CARD_POINT = {
    1: {
        point: '1',
    },
    2: {
        point: '2',
    },
    3: {
        point: '3',
    },
    4: {
        point: '4',
    },
    5: {
        point: '5',
    },
    6: {
        point: '6',
    },
    7: {
        point: '7',
    },
    8: {
        point: '8',
    },
    9: {
        point: '9',
    },
    10: {
        point: '10',
    },
    11: {
        point: 'J',
    },
    12: {
        point: 'Q',
    },
    13: {
        point: 'K',
    },
}

export const DICE_FACE = {
    1: {
        emoji: '⚀',
    },
    2: {
        emoji: '⚁',
    },
    3: {
        emoji: '⚂',
    },
    4: {
        emoji: '⚃',
    },
    5: {
        emoji: '⚄',
    },
    6: {
        emoji: '⚅',
    },
}

export const TABLE_GAME_STATUS = {
    0: {
        EN: 'SHUFFLING',
        TH: 'สับไพ่'
    },
    1: {
        EN: 'WAIT',
        TH: 'รอเริ่มเกม'
    },
    2: {
        EN: 'COUNTDOWN',
        TH: 'เริ่มเกมใหม่เปิดให้ bet ได้'
    },
    3: {
        EN: 'DEALING',
        TH: 'แสดงผลของเกม'
    },
    4: {
        EN: 'FINISH',
        TH: 'จบเกม'
    },
    5: {
        EN: 'PAUSE',
        TH: 'หยุดเกมชั่วคร่าว'
    },
    6: {
        EN: 'CANCEL',
        TH: 'ยกเลิกเกม'
    },
}

export const TABLE_GAME_STATUS_KEY = {
    SHUFFLING: 0,
    WAIT: 1,
    COUNTDOWN: 2,
    DEALING: 3,
    FINISH: 4,
    PAUSE: 5,
    CANCEL: 6,
}

export const DT_SIDE = {
    0: 'มังกร',
    1: 'เสือ',
}

export const DT_GAME_RESULT = {
    0: {
        EN: 'WAIT',
        TH: 'รอผลออก'
    },
    1: {
        EN: 'DRAGON_WIN',
        TH: 'มังกร'
    },
    2: {
        EN: 'TIGER_WIN',
        TH: 'เสือ'
    },
    3: {
        EN: 'TIE',
        TH: 'เสมอ'
    },
    5: {
        EN: 'TIE',
        TH: 'คู่ เสมอ'
    },
    4: {
        EN: 'CANCEL',
        TH: 'ยกเลิก'
    },
}

export const BACCARAT_SIDE = {
    0: 'ผู้เล่น',
    1: 'เจ้ามือ',
}

export const BACCARAT_CARD_ORDER = {
    0: {
        2: {
            order: '1',
        },
        1: {
            order: '2',
        },
        0: {
            order: '3',
        },
    },
    1: {
        0: {
            order: '1',
        },
        1: {
            order: '2',
        },
        2: {
            order: '3',
        },
    },
}

export const BACCARAT_GAME_RESULT = {
    0: {
        EN: 'WAIT',
        TH: 'รอผลออก'
    },
    1: {
        EN: 'BANKER_WIN',
        TH: 'เจ้ามือ'
    },
    2: {
        EN: 'PLAYER_WIN',
        TH: 'ผู้เล่น'
    },
    3: {
        EN: 'TIE',
        TH: 'เสมอ'
    },
    4: {
        EN: 'CANCEL',
        TH: 'ยกเลิก'
    },
}

export const SICBO_GAME_RESULT = {
    0: {
        EN: 'WAIT',
        TH: 'รอผลออก'
    },
    1: {
        EN: 'hi',
        TH: 'สูง'
    },
    2: {
        EN: 'lo',
        TH: 'ต่ำ'
    },
    3: {
        EN: '11 hilo',
        TH: 'ไฮไล'
    },
    4: {
        EN: 'CANCEL',
        TH: 'ยกเลิก'
    },
}

export const BEAN_GAME_RESULT = {
    0: {
        EN: 'WAIT',
        TH: 'รอผลออก'
    },
    1: {
        EN: 'white',
        TH: 'ขาว'
    },
    2: {
        EN: 'green',
        TH: 'เขียว'
    },
    3: {
        EN: 'yellow',
        TH: 'เหลือง'
    },
    4: {
        EN: 'CANCEL',
        TH: 'ยกเลิก'
    },
    5: {
        EN: 'red',
        TH: 'แดง'
    },
}