<template>
    <div class="GameHeader top-stream">
        <table>
            <tr>
                <td>
                    <span>ID :</span>
                    <span>{{id}}</span>
                </td>
                <td>
                    <span>โต๊ะ {{name}}</span>
                    <span>รอบ {{round_main}}/{{round_sub}}</span>
                </td>
            </tr>
        </table>
    </div>
</template>
<script>
export default {
    name: 'GameHeader',
    props: [
        'table',
    ],
    computed: {
        id() {
            return (this.table) ? this.table.table_id : '-'
        },
        name() {
            return (this.table) ? this.table.table_name : '-'
        },
        round_main() {
            return (this.table) ? this.table.table_round_main : '-'
        },
        round_sub() {
            return (this.table) ? this.table.table_round_sub : '-'
        },
    }
}
</script>