<template>
    <div class="MessageResultMiniGame incoming_msg">
        <div class="modalList-table">
            <div class="wrapper-box-type">
                <carousel
                    :per-page="1.1"
                    :mouseDrag="true"
                    :spacePadding="1"
                    :navigationEnabled="true"
                    :paginationEnabled="false"
                    navigationNextLabel="❯"
                    navigationPrevLabel="❮"
                    paginationPosition="bottom">
                    <slide  v-for="(item, key) of message_data" :key="key">
                        <div @click="play(item)" class="box-table-stat mb-4" :class="calculateWrapperClass(item.table_type)">
                            <div class="content-table-info">
                                <div class="table-name"><span>โต๊ะ {{item.table_name}}</span></div>
                                <BaccaratItem v-if="parseInt(item.table_type) === TABLE_TYPE_KEY.baccarat"
                                    :table_id="item.table_id"
                                    :table_round_main="getTableRoundMain(item.table_id)"></BaccaratItem>
                                <DragonTigerItem v-if="parseInt(item.table_type) === TABLE_TYPE_KEY.dragontiger"
                                    :table_id="item.table_id"
                                    :table_round_main="getTableRoundMain(item.table_id)"></DragonTigerItem>
                                <SicboItem v-if="parseInt(item.table_type) === TABLE_TYPE_KEY.sicbo"
                                    :table_id="item.table_id"
                                    :table_round_main="getTableRoundMain(item.table_id)"
                                    :limit="8"></SicboItem>
                                <FantanItem v-if="parseInt(item.table_type) === TABLE_TYPE_KEY.bean"
                                    :table_id="item.table_id"
                                    :table_round_main="getTableRoundMain(item.table_id)"
                                    :limit="8"></FantanItem>
                            </div>
                        </div>
                    </slide>
                </carousel>
            </div>
        </div>
    </div>
</template>
<script>

import { Carousel, Slide } from 'vue-carousel'
import {TABLE_TYPE_KEY, TABLE_TYPE_MAP} from '@/components/game/Constant'
import BaccaratItem from '@/components/game/baccarat/GameTypeItem'
import DragonTigerItem from '@/components/game/dragontiger/GameTypeItem'
import SicboItem from '@/components/game/sicbo/GameTypeItem'
import FantanItem from '@/components/game/fantan/GameTypeItem'
import store from '@/store'
const WRAPPER_CLASS_MAP = {
    0: 'stat-baccarat',
    1: 'stat-sicbo',
    2: 'stat-fantan',
    3: 'stat-dragonTiger',
    4: 'stat-baccarat',
}

export default {
    name: 'ResultMiniGame',
    props: ['message'],
    components: {
        Carousel,
        Slide,
        BaccaratItem,
        DragonTigerItem,
        SicboItem,
        FantanItem,
    },
    data() {
        return {
            TABLE_TYPE_KEY: null,
        }
    },
    computed: {
        message_data(){
            return (this.message) ? this.message.data : null
        },
        timestamp(){
            return (this.message) ? this.message.timestamp : ''
        },
        tables() {
            return store.state.game.baccaratTableList
        },
    },
    methods: {
        play(table) {
            this.$router.push({
                name: TABLE_TYPE_MAP[table.table_type]['routeName'],
                query: {
                    table_id: table.table_id
                },
            })
        },
        calculateWrapperClass(table_type) {
            return WRAPPER_CLASS_MAP[table_type]
        },
        getTable(table_id) {
            return this.tables.find((table) => {
                return parseInt(table.table_id) === parseInt(table_id) 
            })
        },
        getTableRoundMain(table_id) {
            const table = this.getTable(table_id)
            if(!table) {
                return -1
            }
            return table.table_round_main
        },
    },
    created() {
        this.TABLE_TYPE_KEY = TABLE_TYPE_KEY
    }
}
</script>
<style scoped>
    .box-top-user-list{
        position: relative;
    }
</style>