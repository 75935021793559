<template>
    <div v-if="src" class="VideoPlayerFLV">
        <vue-flv-player :autoplay="true" :controls="false" :muted="isPlayerMute" ref="myPlayer" 
        :source="src" 
        type="flv" 
        :width="width"
        :height="height"
        poster="/images/loading-spinner.gif"/>
        <button @click="toggleMute" class="btn btn-light toggle-mute-button">
            <i v-show="isPlayerMute" class="fa fa-volume-mute"></i>
            <i v-show="!isPlayerMute" class="fa fa-volume-up"></i>
        </button>
    </div>
</template>
<script>

import vueFlvPlayer from 'vue-flv-player'

export default {
    name: 'VideoPlayerFLV',
    components: {
        vueFlvPlayer,
    },
    props: [
        'src',
        'width',
        'height',
        'playerBus',
        'isMute',
    ],
    data() {
        return {
            isPlayerMute: true,
        }
    },
    computed: {
        player() {
            return this.$refs['myPlayer']
        }
    },
    methods: {
        toggleMute() {
            this.isPlayerMute = !this.isPlayerMute
        },
        fullscreen() {
            
            const element = document.querySelector('.VideoPlayerFLV video')
            
            const requestFullScreen = element.requestFullScreen || element.mozRequestFullScreen || element.webkitRequestFullScreen;
            if (!requestFullScreen) {
                this.$bvToast.toast('เบราว์เซอร์ไม่รองรับ ขออภัยในความไม่สะดวก', {
                    title: 'แจ้งเตือน',
                    toaster: 'b-toaster-top-full'
                })
                return
            }
            requestFullScreen.bind(element)();
            if (screen.orientation && screen.orientation.lock) {
                screen.orientation.lock('landscape');
            }
        },
        mute() {
            this.isPlayerMute = true
        },
        unMute() {
            this.isPlayerMute = false
        },
    },
    mounted() {
        if(typeof this.isMute === "boolean") {
            this.isPlayerMute = this.isMute
        }
        if(this.player) {
            this.player.on('error', (e) => {
                console.log('error', e)
                this.$emit('error', e)
            })
            this.player.on('media_info', (e) => {
                this.$emit('ready', e)
            })
        } else {
            setTimeout(() => {
                const player = this.$refs['myPlayer']
                if(!player) {
                    return
                }
                player.on('error', (e) => {
                    console.log('error', e)
                    this.$emit('error', e)
                })
                player.on('media_info', (e) => {
                    this.$emit('ready', e)
                })
            }, 1000)
        }
        if(this.playerBus) {
            this.playerBus.$on('fullscreen', this.fullscreen)
            this.playerBus.$on('mute', this.mute)
            this.playerBus.$on('unMute', this.unMute)
        }
    },
    beforeDestroy() {
        if(this.playerBus) {
            this.playerBus.$off('fullscreen', this.fullscreen)
            this.playerBus.$off('mute', this.mute)
            this.playerBus.$off('unMute', this.unMute)
        }
    }
}
</script>
<style scoped>
.VideoPlayerFLV {
    position: relative;
}
/*.toggle-mute-button {*/
/*    position: absolute;*/
/*    z-index: 102;*/
/*    bottom: 50px;*/
/*    left: 10px;*/
/*    width: 40px;*/
/*    height: 40px;*/
/*    border-radius: 50%;*/
/*}*/
@media (orientation: landscape) {
    .toggle-mute-button {
        /*bottom: 90px;*/
    }
}
</style>