<template>
    <div class="GameList GameList-electronic" v-if="list2">
        <div class="box-list-game-type">
            <ul class="nav nav-tabs tab-cst tab-style" id="myTab" role="tablist">
                <li class="nav-item">
                    <a class="nav-link active" id="home-tab" data-toggle="tab" href="#tab-all" role="tab"
                       aria-controls="home" aria-selected="true"><strong>ALL</strong></a>
                </li>
                <li class="nav-item"  v-if="service_list.includes('pragmatic')">
                    <a class="nav-link" id="profile-tab" data-toggle="tab" href="#tab-prag" role="tab" ref="pragmatic"
                       aria-controls="profile" aria-selected="false"><img src="@/assets/images/casino/logo-112.png"
                                                                          style="height: 20px; margin-top: -5px;"> Pragmatic Play</a>
                </li>
                <li class="nav-item"  v-if="service_list.includes('cqgame')">
                    <a class="nav-link" id="contact-tab" data-toggle="tab" href="#tab-cq" role="tab" ref="cqgame"
                       aria-controls="contact" aria-selected="false"><img src="@/assets/images/casino/cq9.png"
                                                                          style="height: 15px;"> CQ9</a>
                </li>
            </ul>
            <div class="tab-content" id="myTabContent">
                <div class="tab-pane fade show active" id="tab-all" role="tabpanel" aria-labelledby="home-tab">
                    <div class="wrapper-box-list">
                        <div class="content-menu-electronics">
                            <div class="content-menu-electronics-info">
                                <div class="content-header-slot content-header-slot-1-1 jackpot-style jackpot-style-1-1">
                                    <div class="f1" style="top: 8vw; left: 14vw;"></div>
                                    <div class="f2" style="top: 22px; left: -34px;"></div>
                                    <div class="f3 blink delay-x1" style="top: 25px; left: -49px;"></div>
                                    <div class="f3 blink delay-x15" style="top: -15px; left: -74px;"></div>
                                    <div class="f3 blink delay-x1" style="top: -14px; right: -61px;"></div>
                                    <div class="f3 blink delay-x15" style="top: 15px; right: -44px;"></div>
                                    <div class="f2" style="top: 25px; right: -61px;"></div>
                                    <div class="grand-j"><span>1,801,287.740</span></div>
                                    <div class="major-j"><span>242,327.740</span></div>
                                    <div class="mini-j"><span>122,407.740</span></div>
                                    <div class="fix-j"><span>32,467.740</span></div>
                                    <div class="header-jackpot-1 header-jackpot-1-1"></div>
                                </div>
                                <div class="content-list-slot">
                                    <carousel
                                            :per-page="2.3"
                                            :mouseDrag="true"
                                            :navigationEnabled="true"
                                            :paginationEnabled="false"
                                            navigationNextLabel="❯"
                                            navigationPrevLabel="❮"
                                    >
                                        <slide v-for="(game,key) of list2.slot[2][0]"  :key="key" >
                                            <div  class="box-s-list" @click="openGame2(game,'pragmatic')" >
                                                <div class="btnPlay-s-list"></div>
                                                <img  :src="game.img" />
                                                <span>{{game.name}}</span>
                                            </div>
                                        </slide>
                                    </carousel>

                                </div>
                            </div>

                            <div class="content-menu-electronics-info">
                                <div class="content-header-slot content-header-slot-2-1 jackpot-style jackpot-style-2 jackpot-style-2-1">
                                    <div class="f1" style="top: 55px; left: 135px;"></div>
                                    <div class="f2" style="top: 22px; left: -34px;"></div>
                                    <div class="f3 blink delay-x1" style="top: 25px; left: -49px;"></div>
                                    <div class="f3 blink delay-x15" style="top: -15px; left: -74px;"></div>
                                    <div class="f3 blink delay-x1" style="top: -14px; right: -61px;"></div>
                                    <div class="f3 blink delay-x15" style="top: 15px; right: -44px;"></div>
                                    <div class="f2" style="top: 25px; right: -61px;"></div>
                                    <div class="grand-j"><span>1,800,496.200</span></div>
                                    <div class="major-j"><span>300,496.200</span></div>
                                    <div class="mini-j"><span>120,496.200</span></div>
                                    <div class="header-jackpot-1 header-jackpot-2"></div>
                                </div>
                                <div class="content-list-slot">
                                    <div class="content-list-slot">
                                        <carousel
                                                :per-page="2.3"
                                                :mouseDrag="true"
                                                :navigationEnabled="true"
                                                :paginationEnabled="false"
                                                navigationNextLabel="❯"
                                                navigationPrevLabel="❮"
                                        >
                                            <slide v-for="(game,key) of list2.slot[2][1]"  :key="key" >
                                                <div  class="box-s-list" @click="openGame2(game,'pragmatic')" >
                                                    <div class="btnPlay-s-list"></div>
                                                    <img  :src="game.img" />
                                                    <span>{{game.name}}</span>
                                                </div>
                                            </slide>
                                        </carousel>

                                    </div>
                                </div>
                            </div>

<!--                            <hr class="new4">-->
                            <div class="bg-2-game-list">
                                <div class="header-title-menu" v-if="service_list.includes('pragmatic')">
                                    <div><h4><img src="@/assets/images/casino/logo-112.png" style="width: 25px;">Pragmatic Play</h4></div>
                                    <div @click="clickToGame('pragmatic')">
                                        All <i class="far fa-arrow-alt-circle-right"></i>
                                    </div>
                                </div>
                                <div class="content-list-slot " v-if="service_list.includes('pragmatic')">
                                        <div v-for ="(game_data,key) of pragmatic_selected_list" :key="key">
                                            <div class="box-s-list" @click="openGame2(game_data[0],'pragmatic')">

                                                <div class="btnPlay-s-list"></div>
                                                <img :src="game_data[0].img"/>
                                                <span>{{game_data[0].name}}</span>
                                            </div>
                                            <div class="box-s-list" @click="openGame2(game_data[1],'pragmatic')">
                                                <div class="btnPlay-s-list"></div>
                                                <img :src="game_data[1].img"/>
                                                <span>{{game_data[1].name}}</span>
                                            </div>
                                        </div>
                                    <div>
<!--                                        <div class="btn-all" @click="clickToGame('pragmatic')"><span>ALL</span></div>-->
                                    </div>
                                </div>
                                <div class="header-title-menu" v-if="service_list.includes('cqgame')">
                                    <div><h4><img src="@/assets/images/casino/cq9.png" style="width: 47px;">CQ9</h4></div>
                                    <div @click="clickToGame('cqgame')">
                                        All <i class="far fa-arrow-alt-circle-right"></i>
                                    </div>
                                </div>
                                <div class="content-list-slot"  v-if="service_list.includes('cqgame')">
                                        <div v-for="(game_data,key) of cqgame_selected_list" :key="key">
                                            <div class="box-s-list" @click="openGame2(game_data[0],'cqgame')">

                                                <div class="btnPlay-s-list"></div>
                                                <img :src="game_data[0].img"/>
                                                <span>{{game_data[0].name}}</span>

                                            </div>
                                            <div class="box-s-list" @click="openGame2(game_data[1],'cqgame')">

                                                <div class="btnPlay-s-list"></div>
                                                <img :src="game_data[1].img"/>
                                                <span>{{game_data[1].name}}</span>

                                            </div>

                                        </div>
<!--                                    <div>-->
<!--                                        <div class="btn-all" @click="clickToGame('cqgame')"><span>ALL</span></div>-->
<!--                                    </div>-->
                                </div>
                            </div>

                        </div>
                    </div>
                </div>
                <div class="tab-pane fade" id="tab-prag" role="tabpanel" aria-labelledby="profile-tab">
                    <div class="box-sublist-games sub-menu-electronics">
                        <carousel
                                :per-page="4.7"
                                :mouseDrag="true"
                                :navigationEnabled="true"
                                :paginationEnabled="false"
                                navigationNextLabel="❯"
                                navigationPrevLabel="❮"
                        >
                                <slide><span @click="showPragmaticGame('All')" :class="{'active': pragmatic_tab['All']}">เกมทั้งหมด</span></slide>
                                <slide><span @click="showPragmaticGame('Progressive')" :class="{'active': pragmatic_tab['Progressive']}">Progressive</span></slide>
                                <slide><span @click="showPragmaticGame('Video Slots')" :class="{'active': pragmatic_tab['Video Slots']}">Video Slots</span></slide>
                                <slide><span @click="showPragmaticGame('Scratch card')" :class="{'active': pragmatic_tab['Scratch card']}">Scratch card</span></slide>
                                <slide><span @click="showPragmaticGame('Baccarat')" :class="{'active': pragmatic_tab['Baccarat']}">Baccarat</span></slide>
                                <slide><span @click="showPragmaticGame('Blackjack')" :class="{'active': pragmatic_tab['Blackjack']}">Blackjack</span></slide>
                                <slide><span @click="showPragmaticGame('Baccarat New')" :class="{'active': pragmatic_tab['Baccarat New']}">Baccarat New</span></slide>
                                <slide><span @click="showPragmaticGame('Classic Slots')" :class="{'active': pragmatic_tab['Classic Slots']}">Classic Slots</span></slide>
                                <slide><span @click="showPragmaticGame('Keno')" :class="{'active': pragmatic_tab['Keno']}">Keno</span></slide>
                                <slide><span @click="showPragmaticGame('Roulette')" :class="{'active': pragmatic_tab['Roulette']}">Roulette</span></slide>
                                <slide><span @click="showPragmaticGame('Video Poker')" :class="{'active': pragmatic_tab['Video Poker']}">Video Poker</span></slide>
                        </carousel>
                    </div>
                    <div class="wrapper-box-list">
                        <div class="content-menu-electronics">
                            <div class="section-all">
                                <div class="wrapper-progressive" style="margin-top: 10px;">
                                    <div class="content-menu-electronics-info">
                                        <div class="content-header-slot content-header-slot-1-1 jackpot-style jackpot-style-1-1">
                                            <div class="f1" style="top: 8vw; left: 14vw;"></div>
                                            <div class="f2" style="top: 22px; left: -34px;"></div>
                                            <div class="f3 blink delay-x1" style="top: 25px; left: -49px;"></div>
                                            <div class="f3 blink delay-x15" style="top: -15px; left: -74px;"></div>
                                            <div class="f3 blink delay-x1" style="top: -14px; right: -61px;"></div>
                                            <div class="f3 blink delay-x15" style="top: 15px; right: -44px;"></div>
                                            <div class="f2" style="top: 25px; right: -61px;"></div>
                                            <div class="grand-j"><span>1,801,287.740</span></div>
                                            <div class="major-j"><span>242,327.740</span></div>
                                            <div class="mini-j"><span>122,407.740</span></div>
                                            <div class="fix-j"><span>32,467.740</span></div>
                                            <div class="header-jackpot-1 header-jackpot-1-1"></div>
                                        </div>
                                        <div class="content-list-slot">
                                            <carousel
                                                    :per-page="2.3"
                                                    :mouseDrag="true"
                                                    :navigationEnabled="true"
                                                    :paginationEnabled="false"
                                                    navigationNextLabel="❯"
                                                    navigationPrevLabel="❮"
                                            >
                                                <slide v-for="(game,key) of list2.slot[2][0]"  :key="key" >
                                                    <div  class="box-s-list" @click="openGame2(game,'pragmatic')" >
                                                        <div class="btnPlay-s-list"></div>
                                                        <img  :src="game.img" />
                                                        <span>{{game.name}}</span>
                                                    </div>
                                                </slide>
                                            </carousel>

                                        </div>
                                    </div>

                                    <div class="content-menu-electronics-info">
                                        <div class="content-header-slot content-header-slot-2-1 jackpot-style jackpot-style-2 jackpot-style-2-1">
                                            <div class="f1" style="top: 55px; left: 135px;"></div>
                                            <div class="f2" style="top: 22px; left: -34px;"></div>
                                            <div class="f3 blink delay-x1" style="top: 25px; left: -49px;"></div>
                                            <div class="f3 blink delay-x15" style="top: -15px; left: -74px;"></div>
                                            <div class="f3 blink delay-x1" style="top: -14px; right: -61px;"></div>
                                            <div class="f3 blink delay-x15" style="top: 15px; right: -44px;"></div>
                                            <div class="f2" style="top: 25px; right: -61px;"></div>
                                            <div class="grand-j"><span>1,800,496.200</span></div>
                                            <div class="major-j"><span>300,496.200</span></div>
                                            <div class="mini-j"><span>120,496.200</span></div>
                                            <div class="header-jackpot-1 header-jackpot-2"></div>
                                        </div>
                                        <div class="content-list-slot">
                                            <carousel
                                                    :per-page="2.5"
                                                    :mouseDrag="true"
                                                    :navigationEnabled="true"
                                                    :paginationEnabled="false"
                                                    navigationNextLabel="❯"
                                                    navigationPrevLabel="❮"
                                            >
                                                <slide v-for="(game,key) of list2.slot[2][1]"  :key="key" >
                                                    <div  class="box-s-list" @click="openGame2(game,'pragmatic')" >
                                                        <div class="btnPlay-s-list"></div>
                                                        <img  :src="game.img" />
                                                        <span>{{game.name}}</span>

                                                    </div>
                                                </slide>
                                            </carousel>

                                        </div>
                                    </div>

                                </div>

<!--                                <hr class="new4">-->
                                <div class="bg-2-game-list bg-2-game-list-pm">
                                    <div class="header-title-menu">
                                        <h4><img src="@/assets/images/casino/logo-112.png" style="width: 25px;"> Pragmatic Play</h4>
                                        <!--<div>-->
                                        <!--<i class="far fa-arrow-alt-circle-right"></i>-->
                                        <!--</div>-->
                                    </div>
                                    <div class="content-lists" v-if="pragmatic_tab['All']" >
                                        <div  v-for="(game,key) of pragmatic_list['All']" :key="key" class="box-s-list">
                                            <div @click="openGame2(game,'pragmatic')">
                                                <div class="btnPlay-s-list"></div>
                                                <img :src="game.img" />
                                                <span>{{game.name}}</span>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="content-lists" v-if="pragmatic_tab['Progressive']" >
                                        <div  v-for="(game,key) of pragmatic_list['Pregresssive']" :key="key" class="box-s-list">
                                            <div  @click="openGame2(game,'pragmatic')">
                                                <div class="btnPlay-s-list"></div>
                                                <img :src="game.img" />
                                                <span>{{game.name}}</span>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="content-lists" v-if="pragmatic_tab['Video Slots']" >
                                        <div  v-for="(game,key) of pragmatic_list['Video Slots']" :key="key" class="box-s-list">
                                            <div  @click="openGame2(game,'pragmatic')">
                                                <div class="btnPlay-s-list"></div>
                                                <img :src="game.img" />
                                                <span>{{game.name}}</span>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="content-lists" v-if="pragmatic_tab['Scratch card']" >
                                        <div  v-for="(game,key) of pragmatic_list['Scratch card']" :key="key" class="box-s-list">
                                            <div  @click="openGame2(game,'pragmatic')">
                                                <div class="btnPlay-s-list"></div>
                                                <img :src="game.img" />
                                                <span>{{game.name}}</span>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="content-lists" v-if="pragmatic_tab['Baccarat']" >
                                        <div  v-for="(game,key) of pragmatic_list['Baccarat']" :key="key" class="box-s-list">
                                            <div  @click="openGame2(game,'pragmatic')">
                                            
                                                <div class="btnPlay-s-list"></div>
                                                <img :src="game.img" />
                                                <span>{{game.name}}</span>
                                             
                                            </div>
                                        </div>
                                    </div>
                                    <div class="content-lists" v-if="pragmatic_tab['Blackjack']" >
                                        <div  v-for="(game,key) of pragmatic_list['Blackjack']" :key="key" class="box-s-list">
                                            <div  @click="openGame2(game,'pragmatic')">
                                                <div class="btnPlay-s-list"></div>
                                                <img :src="game.img" />
                                                <span>{{game.name}}</span>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="content-lists" v-if="pragmatic_tab['Baccarat New']" >
                                        <div  v-for="(game,key) of pragmatic_list['Baccarat New']" :key="key" class="box-s-list">
                                            <div  @click="openGame2(game,'pragmatic')">
                                                <div class="btnPlay-s-list"></div>
                                                <img :src="game.img" />
                                                <span>{{game.name}}</span>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="content-lists" v-if="pragmatic_tab['Classic Slots']" >
                                        <div  v-for="(game,key) of pragmatic_list['Classic Slots']" :key="key" class="box-s-list">
                                            <div  @click="openGame2(game,'pragmatic')">
                                                <div class="btnPlay-s-list"></div>
                                                <img :src="game.img" />
                                                <span>{{game.name}}</span>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="content-lists" v-if="pragmatic_tab['Keno']" >
                                        <div  v-for="(game,key) of pragmatic_list['Keno']" :key="key" class="box-s-list">
                                            <div  @click="openGame2(game,'pragmatic')">
                                                <div class="btnPlay-s-list"></div>
                                                <img :src="game.img" />
                                                <span>{{game.name}}</span>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="content-lists" v-if="pragmatic_tab['Roulette']" >
                                        <div  v-for="(game,key) of pragmatic_list['Roulette']" :key="key" class="box-s-list">
                                            <div  @click="openGame2(game,'pragmatic')">
                                                <div class="btnPlay-s-list"></div>
                                                <img :src="game.img" />
                                                <span>{{game.name}}</span>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="content-lists" v-if="pragmatic_tab['Video Poker']" >
                                        <div  v-for="(game,key) of pragmatic_list['Video Poker']" :key="key" class="box-s-list">
                                            <div  @click="openGame2(game,'pragmatic')">
                                                <div class="btnPlay-s-list"></div>
                                                <img :src="game.img" />
                                                <span>{{game.name}}</span>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="tab-pane fade" id="tab-cq" role="tabpanel" aria-labelledby="contact-tab">
                    <div>
                        <div class="box-sublist-games sub-menu-electronics" style="display: flex; justify-content: center; margin-bottom: 15px;">
                            <div><span  @click="showCqGame('All')" :class="{'active': cqgame_tab['All']}">เกมทั้งหมด</span></div>
                            <div><span  @click="showCqGame('Slot')" :class="{'active': cqgame_tab['Slot']}">Slot</span></div>
                            <div><span  @click="showCqGame('Table Game')" :class="{'active': cqgame_tab['Table Game']}">Table</span></div>
                            <div><span  @click="showCqGame('Arcade')" :class="{'active': cqgame_tab['Arcade']}">Arcade</span></div>
                        </div>
                    </div>
                    <div class="wrapper-box-list">
                        <div class="content-menu-cq9">
                            <div v-for="(gameList,key) of cqgame_list" :key="key">
                                <div class="content-lists" v-if="cqgame_tab[key]" >
                                        <div  v-for="(game,key_game) of gameList" :key="key_game" class="box-s-list">
                                            <div  @click="openGame2(game,'cqgame')">

                                                <div class="btnPlay-s-list"></div>
                                                <img :src="game.img" />
                                                <span>{{game.name}}</span>

                                            </div>
                                        </div>
                            </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>


<script>

                                            var game_name_list = [
                                                'vs25asgardjp', 
                                                // 'vs25asgardjp_cv21',
                                                'vs25safari_jp', 'vs30catz_jp', 'vs7monkeys_jp',

                                                'vs10madamejp',
                                                //  'vs10madamejp_cv21',
                                                  'vs1dragon8_jp', 'vs20doghousejp', 'vs20fruitswjp', 'vs5aztecgems_jp', 'vs9madmonkey_jp'
                                            ];
                                            var game_name_full_list = [
                                                'Asgard', 
                                                // 'Asgard',
                                                 'Hot Safari', 'The Catfather Part II', '7 Monkeys',
                                                'Madame Destiny', 
                                                // 'Madame Destiny', 
                                                '888 Dragons', 'The Dog House', 'Sweet Bonanza', 'Aztec Gems', 'Monkey Madness'
                                            ];
    import axiosWrapper from '@/axiosWrapper'
    import randomBetween from '@/lib/randomBetween'
    import store from '@/store'
    import UserMixin from '@/mixins/UserMixin'
    import $ from 'jquery'
                                            import { Carousel, Slide } from 'vue-carousel'
    window.jQuery = $;
    window.$ = $;

    export default {
        name: 'GameList',
        mixins: [UserMixin],
        components: {
            Carousel,
            Slide,
        },
        data() {
            return {
                games: [],
                icons: [
                    '/images/icon/set-icon/icon-casino.png',
                    '/images/icon/set-icon/icon-slot.png',
                    '/images/icon/set-icon/icon-casino.png',
                    '/images/icon/set-icon/icon-football.png',
                    '/images/icon/set-icon/icon-fishing.png',
                    '/images/icon/set-icon/icon-lotto.png',
                    '/images/icon/set-icon/icon-roulette.png',
                    '/images/icon/set-icon/icon-case.png',
                ],
                selectedBrand: 'queenmaker',
                'game_name_list':game_name_list,
                'game_name_full_list':game_name_full_list,
                polling:null,
                bonus_level_list:[
                    'fix-j',
                    'mini-j',
                    'major-j',
                    'grand-j',
                ],
                pragmatic_selected_list:[
                ],
                pragmatic_list:{
                    'All':[],
                    'Progressive':[],
                    'Video Slots':[],
                    'Scratch card':[],
                    'Baccarat':[],
                    'Blackjack':[],
                    'Baccarat New':[],
                    'Classic Slots':[],
                    'Keno':[],
                    'Roulette':[],
                    'Video Poker':[],
                },
                cqgame_selected_list:[
                ],
                cqgame_list:{
                    'All':[],
                    'Slot':[],
                    'Table Game':[],
                    'Arcade':[]
                },
                 cqgame_tab:{
                    'All':true,
                    'Slot':false,
                    'Table Game':false,
                    'Arcade':false
                },
                pragmatic_tab:{
                    'All':true,
                    'Progressive':false,
                    'Video Slots':false,
                    'Scratch card':false,
                    'Baccarat':false,
                    'Blackjack':false,
                    'Baccarat New':false,
                    'Classic Slots':false,
                    'Keno':false,
                    'Roulette':false,
                    'Video Poker':false,
                },
                list2:null,
                service_list : `${process.env.VUE_APP_PES_SERVICE_LIST}`.split(","),
            }
        },
        
        computed: {
            is_member() {
                return store.state.is_member
            },
            pes_profile() {
                return store.state.pes_profile
            },
            token() {
                return this.pes_profile.token
            },
            getAllGameList(){
                return store.state.allGameList;
            },
            appImageProfile(){
                return store.state.appInfo.app_image_profile
            },
        },
        methods: {
            openGame2(game,service){
                if (!this.is_member) {
                    this.notifyToSignUp('เพื่อเล่น')
                    return
                }
                var params =[
                    
                ];
                
                
                if(service=='pragmatic'){
                    params.push('code='+game.gameId);
                }else if(service=='sa'){
                    params.push('code='+game.code);
                }
                if(this.appImageProfile){
                    params.push('logo_img='+this.appImageProfile);
                }
                
                const url = `${this.envDomains.VUE_APP_PES_BASE_URL}/game/casinoonline/sa_sexy_redirect.php?mode=mobile&service=${service}&${params.join('&')}&token=${this.token}`
                if(service=='sa'){
                    window.location.href=url
                }else{
                    this.$router.push({ name: 'GamePlayFrame', "query": { 'url': encodeURIComponent(url) } })
                }
                
                
            },
            getToken(){
                return this.pes_profile.token;
            },
            getBaseUrl(){
                return `${this.envDomains.VUE_APP_PES_BASE_URL}`;
            },
            async getGameList(brand = 'queenmaker') {

                const res = await axiosWrapper({
                    method: 'post',
                    url: `${this.envDomains.VUE_APP_API}/api/game/list`,
                    data: {
                        brand
                    }
                })

                if (!res.data.success) {
                    this.$alert(res.data.message, 'แจ้งเตือน', {
                        confirmButtonText: 'ตกลง',
                        customClass: 'custom-message-box'
                    })
                    return
                }

                return res.data.data
            },
            async getGameData(service) {

                    const res = await axiosWrapper({
                        method: 'get',
                        url: `${this.envDomains.VUE_APP_PES_BASE_URL}/api/game/v0/service/`+service+'/games.php',
                    });
                    return res.data;
                },
            //https://demo.cqgame.games/api/frontend/game/list?lang=en
                        async getGameByUrl(url) {

                    const res = await axiosWrapper({
                        method: 'get',
                        url: url,
                    });
                    return res.data;
                },
            calIcon(key) {
                return this.icons[key % this.icons.length]
            },
            getPlayingCount() {
                return randomBetween(111, 1111)
            },
            openGame(game) {

                if (!this.is_member) {
                    this.notifyToSignUp('เพื่อเล่น')
                    return
                }

                const baseUrl = `/game/casinoonline/sa_sexy_redirect.php?service=${this.selectedBrand}`

                let query = ''
                if (this.selectedBrand === 'queenmaker') {
                    query = `&gpcode=${game.gpcode}&gcode=${game.gcode}`
                } else if (this.selectedBrand === 'kp') {
                    query = `&gameId=${game.gameId}`
                } else if (this.selectedBrand === 'cqgame') {
                    query = `&gamehall=${game.gamehall}&gamecode=${game.gamecode}`
                }

                if(this.appImageProfile){
                    query = query+'&logo_img='+this.appImageProfile
                }
                

                const url = `${this.envDomains.VUE_APP_PES_BASE_URL}${baseUrl}${query}&token=${this.token}`
                window.open(url, '_blank')
            },
            pollData () {
                
                // this.polling = setInterval(() => {
                //     ///api/game/v0/service/pragmatic/jackpots.php
                //     this.getJackPot();

                // }, 1000*15)
            },
            getJackPot(){
                var self = this;
                $.get(`${this.envDomains.VUE_APP_PES_BASE_URL}/api/game/v0/service/pragmatic/jackpots.php`,function(response){
                    var thb_rate=(response.data.THB_rate);
                    
                    var data_list = response.data.data;
                    var bonus1_list =[];
                    var bonus2_list=[];
                    for(var i=0;i<data_list.length;i++){
                        if(data_list[i].name=="XT-01"){
                            bonus1_list.push(data_list[i]);

                        }else if(data_list[i].name=="XT-02"){
                            bonus2_list.push(data_list[i]);
                        }
                    }
                    for(var b1_key in bonus1_list){
                        var bonus_level =self.bonus_level_list[b1_key];
                        var value = thb_rate* bonus1_list[b1_key].amount;
                        updateBonusNumber('div.content-header-slot-1-1>.'+bonus_level,value)
                    }
                    for(var b2_key in bonus2_list){
                        var bonus_level2 =self.bonus_level_list[b2_key];
                        var value2 = thb_rate* bonus2_list[b2_key].amount;
                        updateBonusNumber('div.content-header-slot-2-1>.'+bonus_level2,value2)
                    }

                });

            },
            created () {
                this.pollData()
                window.addEventListener('scroll', this.handleScroll);
            },
            destroyed () {
                window.removeEventListener('scroll', this.handleScroll);
            },
            handleScroll (event) {
                // Any code to be executed when the window is scrolled
                console.log(event);
                },
            showPragmaticGame(tab_name){
                if(this.pragmatic_tab[tab_name]==false){
                    for(var key in this.pragmatic_tab){
                        this.pragmatic_tab[key]=false;
                    }
                    this.pragmatic_tab[tab_name]=true;
                }
                
            },
            showCqGame(tab_name){
                if(this.cqgame_tab[tab_name]==false){
                    for(var key in this.cqgame_tab){
                        this.cqgame_tab[key]=false;
                    }
                    this.cqgame_tab[tab_name]=true;
                }
                
            },
            clickToGame(game){
                var elem=this.$refs[game];
                elem.click();
            },
            xx(){
                // console.log('cal xxx');
                var pragmatic_list={
                    'All':[],
                    'Progressive':[],
                    'Video Slots':[],
                    'Scratch card':[],
                    'Baccarat':[],
                    'Blackjack':[],
                    'Baccarat New':[],
                    'Classic Slots':[],
                    'Keno':[],
                    'Roulette':[],
                    'Video Poker':[],
                    'Fishing games':[],
                    'Live games':[]
            };
            var data = this.list2.slot[0];
            for(var j=0;j<data.length;j++){
                var _data = data[j];
                var typeDesc = _data.typeName;
                
                pragmatic_list['All'].push(_data);
                pragmatic_list[typeDesc].push(_data);
                
            }
            this.pragmatic_list = pragmatic_list;
            this.pragmatic_selected_list=[
                [],
                [],
                []
            ]
            for(var i1=0;i1<6;i1++){
                this.pragmatic_selected_list[i1%3].push(this.pragmatic_list['All'][i1]);
            }
           
            var game_list2 = this.list2.slot[1];
            var cqgame_list={
                'All':[],
                'Slot':[],
                'Table Game':[],
                'Arcade':[],
                'Fishing':[]
            }
            for(var i3=0;i3<game_list2.length;i3++){
                var game = game_list2[i3];
                var typeName;
                if(game.type==1){
                    typeName='Slot';
                }else if(game.type==2){
                    typeName='Fishing';
                }else if(game.type==3){
                    typeName='Table Game';
                }else if(game.type==4){
                    typeName='Arcade';
                }
                game.typeName = typeName;
                
                cqgame_list['All'].push(game);
                
                cqgame_list[game.typeName].push(game);
                

            }
            this.cqgame_list =cqgame_list;
            this.cqgame_selected_list=[
                [],
                [],
                []
            ]
            for(var i2=0;i2<6;i2++){
                this.cqgame_selected_list[i2%3].push(this.cqgame_list['All'][i2]);
            }
            
            }
            },
            
        async mounted() {
            // this.getJackPot();
            // this.pollData();
            this.list2=this.getAllGameList;
            var self= this;
            if(this.list2==null){
                var clearInv=setInterval(function(){
                    self.list2 = self.getAllGameList;
                    if(self.list2!=null){
                        // self.manageGameSlot();
                        self.xx();
                        // console.log('clear');
                        clearInterval(clearInv);
                    }

                },500)
            }else{
                // this.manageGameSlot();
                self.xx();
            }

             


        },
    }

function updateBonusNumber(id,value){

$(id).each(function() {
  var self = $(this),
    countTo = value;

  $({
    countNum: self.text().replace(/,/g,'')
  }).animate({
      countNum: countTo
    },

    {
      duration: 500,
      easing: 'linear',
      step: function() {
    
        self.text(commaSeparateNumber(parseFloat(this.countNum).toFixed(3).replace(/(\d)(?=(\d{3})+\.)/g, '$1,')));
        
      },
      complete: function() {
        self.text(commaSeparateNumber(parseFloat(this.countNum).toFixed(3).replace(/(\d)(?=(\d{3})+\.)/g, '$1,')));
        // alert('finished');
      }
    }
  );

});
}
function commaSeparateNumber(val) {
  while (/(\d+)(\d{3})/.test(val.toString())) {
    val = val.toString().replace(/(\d+)(\d{3})/, '$1' + ',' + '$2');
  }
  return val;
}
</script>
<style scoped>
    .VueCarousel-slide{
        flex-basis: initial;
    }
</style>