<template>
  <div
    class="wrapContentIndex wrapContentIndexPopular"
    :class="{ wrapContentAllInto: currentProvider }"
  >
    <div class="contentOverlay" :class="{ popupProvider: isHideProvider }">
      <div class="btn-close-popupAllgame" @click="ClosePopupProvider()"></div>
      <ProviderList @openGameList="ClosePopupProvider"></ProviderList>
    </div>
    <FloatButtomRightPanel :callChatWithAdmin="showChatWithAdminNumber" />
    <div class="wrapContentIndexTitle">
      <div class="hamburger-lines" @click="OpenListProvider()">
        <span class="line line1"></span>
        <span class="line line2"></span>
        <span class="line line3"></span>
      </div>
      <h3>ยอดนิยม</h3>
    </div>
    <div class="navTopDesktop">
      <MenuNavTop
        @showMenuNav="showMenuNav"
        @showChatWithAdmin="showChatWithAdmin" @showDepositWithdrawModal="showDepositWithdrawModal"
      ></MenuNavTop>
    </div>

    <MenuBar :callMenu="menuNumber" :callDWMenu="showDepositWithdrawNumber"></MenuBar>
    <div class="wrapContentIndexInfo">
      <div class="popSlide bx-mobile bx-ipad">
        <carousel
          :autoplayTimeout="5000"
          :perPage="1"
          :autoplay="true"
          :mouseDrag="true"
          :loop="true"
          :centerMode="true"
          :navigationEnabled="true"
          :paginationEnabled="false"
          navigationNextLabel="❯"
          navigationPrevLabel="❮"
        >
          <slide>
            <div>
              <img src="@/assets/images/banner/banner1.jpg" />
            </div>
          </slide>
          <slide>
            <div>
              <img src="@/assets/images/banner/banner2.jpg" />
            </div>
          </slide>
          <slide>
            <div>
              <img src="@/assets/images/banner/banner3.jpg" />
            </div>
          </slide>
          <slide>
            <div>
              <img src="@/assets/images/banner/banner4.jpg" />
            </div>
          </slide>
          <slide>
            <div>
              <img src="@/assets/images/banner/banner5.jpg" />
            </div>
          </slide>
          <slide>
            <div>
              <img src="@/assets/images/banner/banner6.jpg" />
            </div>
          </slide>
        </carousel>
      </div>
      <div class="contentFlexIndex">
        <div class="contentFlexIndexLeft active">
          <MenuLeft></MenuLeft>
        </div>
        <div class="contentFlexIndexRight">
          <span class="topText">
            <marquee>
              <h2>คาสิโนออนไลน์ บาคาร่า ไฮโล เสือมังกร เว็บตรง</h2>
            </marquee>
          </span>
          <div
            class="popSlide bx-desktop bx-ipad"
            v-if="isHideListGameContent == false"
          >
            <carousel
              :perPage="3"
              :perPageCustom="[
                [768, 2],
                [1400, 3],
              ]"
              :autoplay="true"
              :mouseDrag="true"
              :loop="true"
              :centerMode="true"
              :spacePadding="20"
              :navigationEnabled="true"
              :paginationEnabled="false"
              navigationNextLabel="❯"
              navigationPrevLabel="❮"
            >
              <slide>
                <div>
                  <img src="@/assets/images/banner/banner1.jpg" />
                </div>
              </slide>
              <slide>
                <div>
                  <img src="@/assets/images/banner/banner2.jpg" />
                </div>
              </slide>
              <slide>
                <div>
                  <img src="@/assets/images/banner/banner3.jpg" />
                </div>
              </slide>
              <slide>
                <div>
                  <img src="@/assets/images/banner/banner4.jpg" />
                </div>
              </slide>
              <slide>
                <div>
                  <img src="@/assets/images/banner/banner5.jpg" />
                </div>
              </slide>
              <slide>
                <div>
                  <img src="@/assets/images/banner/banner6.jpg" />
                </div>
              </slide>
            </carousel>
          </div>
          <!--                    <div class="contentFlexIndexRightInfo">-->
          <div class="container">
            <div class="box-sublist-area-topTap">
              <span
                v-for="(item, key) of categories"
                :key="key"
                :class="{ active: item === currentCategory }"
                @click="selectCategory(item)"
              >
                {{ item.label }}
              </span>
              <!-- <span class="active"> เกมยอดนิยม </span>
                            <span> แนะนำเกม </span> -->
              <!-- <span class="boxTopTapInput">
                                <i class="fas fa-search"></i>
                                <input v-model="keyword" type="text" placeholder="ค้นหา" />
                            </span> -->
            </div>
            <div
              class="containerGameList"
              :class="{ GameListContainer: isHideListGameContent }"
            >
              <div class="containerGameListinfo">
                <CasinoList></CasinoList>
                <nav class="nav-menu" style="display: none">
                  <div class="nav-menu-topic">
                    <img src="@/assets/images/iconCasinoSide.png" />
                    <h4>คาสิโนออนไลน์ ยอดนิยม</h4>
                  </div>
                  <ul class="nav nav-pills nav-pills-casino">
                    <li class="nav-item">
                      <div class="nav-item-info">
                        <div class="overlay">
                          <div class="overlay-info">
                            <button type="button" class="btn btn-warning">
                              เข้าเล่น
                            </button>
                            <button type="button" class="btn btn-outline-light">
                              ทดลองเล่น
                            </button>
                          </div>
                        </div>
                        <a href="#">
                          <img
                            src="https://chat.liffline.com/images/gameSlot/pragmatic_vswayshammthor.png"
                          />
                        </a>
                      </div>

                      <div class="-text-nav-menu">สุ่มเกม</div>
                    </li>
                    <li class="nav-item">
                      <div class="nav-item-info">
                        <div class="overlay">
                          <div class="overlay-info">
                            <button type="button" class="btn btn-warning">
                              เข้าเล่น
                            </button>
                            <button type="button" class="btn btn-outline-light">
                              ทดลองเล่น
                            </button>
                          </div>
                        </div>
                        <a href="#">
                          <img
                            src="https://chat.liffline.com/images/gameSlot/pragmatic_vs40wildwest.png"
                          />
                        </a>
                      </div>

                      <div class="-text-nav-menu">สุ่มเกม</div>
                    </li>
                    <li class="nav-item">
                      <div class="nav-item-info">
                        <div class="overlay">
                          <div class="overlay-info">
                            <button type="button" class="btn btn-warning">
                              เข้าเล่น
                            </button>
                            <button type="button" class="btn btn-outline-light">
                              ทดลองเล่น
                            </button>
                          </div>
                        </div>
                        <a href="#">
                          <img
                            src="https://chat.liffline.com/images/gameSlot/pragmatic_vswaysdogs.png"
                          />
                        </a>
                      </div>

                      <div class="-text-nav-menu">สุ่มเกม</div>
                    </li>
                    <li class="nav-item">
                      <div class="nav-item-info">
                        <div class="overlay">
                          <div class="overlay-info">
                            <button type="button" class="btn btn-warning">
                              เข้าเล่น
                            </button>
                            <button type="button" class="btn btn-outline-light">
                              ทดลองเล่น
                            </button>
                          </div>
                        </div>
                        <a href="#">
                          <img
                            src="https://chat.liffline.com/images/gameSlot/pragmatic_vs20fruitsw.png"
                          />
                        </a>
                      </div>

                      <div class="-text-nav-menu">สุ่มเกม</div>
                    </li>
                  </ul>
                </nav>
                <nav class="nav-menu" style="display: none">
                  <div class="nav-menu-topic">
                    <img src="@/assets/images/iconSlotSide.png" />
                    <h4>สล็อตออนไลน์ ยอดนิยม</h4>
                  </div>
                  <ul class="nav nav-pills nav-pills-slot">
                    <li class="nav-item">
                      <div class="nav-item-info">
                        <div class="overlay">
                          <div class="overlay-info">
                            <button type="button" class="btn btn-warning">
                              เข้าเล่น
                            </button>
                            <button type="button" class="btn btn-outline-light">
                              ทดลองเล่น
                            </button>
                          </div>
                        </div>
                        <a href="#">
                          <img
                            src="https://chat.liffline.com/images/gameSlot/amb_ways-of-qilin.png"
                          />
                        </a>
                      </div>

                      <div class="-text-nav-menu">สุ่มเกม</div>
                    </li>
                    <li class="nav-item">
                      <div class="nav-item-info">
                        <div class="overlay">
                          <div class="overlay-info">
                            <button type="button" class="btn btn-warning">
                              เข้าเล่น
                            </button>
                            <button type="button" class="btn btn-outline-light">
                              ทดลองเล่น
                            </button>
                          </div>
                        </div>
                        <a href="#">
                          <img
                            src="https://chat.liffline.com/images/gameSlot/amb_lucky-neko.png"
                          />
                        </a>
                      </div>

                      <div class="-text-nav-menu">สุ่มเกม</div>
                    </li>
                    <li class="nav-item">
                      <div class="nav-item-info">
                        <div class="overlay">
                          <div class="overlay-info">
                            <button type="button" class="btn btn-warning">
                              เข้าเล่น
                            </button>
                            <button type="button" class="btn btn-outline-light">
                              ทดลองเล่น
                            </button>
                          </div>
                        </div>
                        <a href="#">
                          <img
                            src="https://chat.liffline.com/images/gameSlot/amb_cai-shen-wins.png"
                          />
                        </a>
                      </div>

                      <div class="-text-nav-menu">สุ่มเกม</div>
                    </li>
                    <li class="nav-item">
                      <div class="nav-item-info">
                        <div class="overlay">
                          <div class="overlay-info">
                            <button type="button" class="btn btn-warning">
                              เข้าเล่น
                            </button>
                            <button type="button" class="btn btn-outline-light">
                              ทดลองเล่น
                            </button>
                          </div>
                        </div>
                        <a href="#">
                          <img
                            src="https://chat.liffline.com/images/gameSlot/amb_jurassic-kdm.png"
                          />
                        </a>
                      </div>

                      <div class="-text-nav-menu">สุ่มเกม</div>
                    </li>
                    <li class="nav-item">
                      <div class="nav-item-info">
                        <div class="overlay">
                          <div class="overlay-info">
                            <button type="button" class="btn btn-warning">
                              เข้าเล่น
                            </button>
                            <button type="button" class="btn btn-outline-light">
                              ทดลองเล่น
                            </button>
                          </div>
                        </div>
                        <a href="#">
                          <img
                            src="https://chat.liffline.com/images/gameSlot/amb_wild-bandito.png"
                          />
                        </a>
                      </div>

                      <div class="-text-nav-menu">สุ่มเกม</div>
                    </li>
                    <li class="nav-item">
                      <div class="nav-item-info">
                        <div class="overlay">
                          <div class="overlay-info">
                            <button type="button" class="btn btn-warning">
                              เข้าเล่น
                            </button>
                            <button type="button" class="btn btn-outline-light">
                              ทดลองเล่น
                            </button>
                          </div>
                        </div>
                        <a href="#">
                          <img
                            src="https://chat.liffline.com/images/gameSlot/amb_sct-cleopatra.png"
                          />
                        </a>
                      </div>

                      <div class="-text-nav-menu">สุ่มเกม</div>
                    </li>
                    <li class="nav-item">
                      <div class="nav-item-info">
                        <div class="overlay">
                          <div class="overlay-info">
                            <button type="button" class="btn btn-warning">
                              เข้าเล่น
                            </button>
                          </div>
                        </div>
                        <a href="#">
                          <img
                            src="https://chat.liffline.com/images/gameSlot/amb_sct-cleopatra.png"
                          />
                        </a>
                      </div>

                      <div class="-text-nav-menu">สุ่มเกม</div>
                    </li>
                  </ul>
                </nav>
                <HorizontalShortList2
                  v-for="(provider, key) of providerList"
                  :key="`${key}:${tag}:${cat}`"
                  :provider="provider"
                  :tag="tag"
                  :cat="provider.cat"
                  :showMore="true"
                  @seeAll="OpenListAllGame"
                >
                </HorizontalShortList2>
                <!-- <nav class="nav-menu GameList-box GameList-box-wide">
                                    <div class="nav-menu-topic ">
                                        <h4>ไลฟ์สด</h4>
                                    </div>
                                    <ul class="nav nav-pills nav-pills-slot">
                                        <li class="nav-item" v-for="(game,key) of game_pop_live_baccarat" :key="key">
                                            <div class="nav-item-info" @click="openGame2(game,game.service)" >
                                                <a href="#">
                                                    <img :src="game.img"/>
                                                </a>
                                            </div>

                                            <div class="-text-nav-menu">{{game.name}}</div>

                                        </li>
                                        
                                    </ul>
                                </nav> -->
                <!-- <nav class="nav-menu GameList-box GameList-box-wide">
                  <div class="nav-menu-topic">
                    <h4>ยิงปลา</h4>
                  </div>
                  <ul class="nav nav-pills nav-pills-slot" v-if="fishingList">
                    <li
                      class="nav-item"
                      v-for="(game, key) of fishingList"
                      :key="key"
                    >
                      <div
                        class="nav-item-info"
                        @click="openGame2(game, game.service)"
                      >
                        <a href="#">
                          <img :src="game.img" />
                        </a>
                      </div>

                      <div class="-text-nav-menu">{{ game.name }}</div>
                    </li>
                  </ul>
                </nav> -->
              </div>
            </div>
            <div
              v-if="providerInfo"
              class="containerGameListinfoAll AllContainerGameList"
              :class="[
                providerInfo.wrapperClass,
                isHideListGame ? 'allListGameProvider' : '',
              ]"
            >
              <div class="nav-menu-topic">
                <button
                  type="button"
                  class="btn btn-link"
                  @click="CloseListAllGame()"
                >
                  <i class="fas fa-angle-left"></i> <span>กลับ</span>
                </button>
                <img :src="providerInfo.img" />
                <h4>{{ providerInfo.name }}</h4>
                <div class="box-sublist-area-topTap">
                  <span class="boxTopTapInput">
                    <i class="fas fa-search"></i>
                    <input v-model="keyword" type="text" placeholder="ค้นหา" />
                  </span>
                </div>
              </div>
              <ListAllGame
                :list="showList"
                :provider="currentProvider"
                :cat = "providerInfo.cat"
                :keyword="keyword"
              >
              </ListAllGame>
            </div>
          </div>
        </div>
      </div>
      <div class="navButtonMobile">
        <MenuNav
          @showMenuNav="showMenuNav"
          @showChatWithAdmin="showChatWithAdmin" @showDepositWithdrawModal="showDepositWithdrawModal"
        ></MenuNav>
      </div>
    </div>
  </div>
</template>
<script>
import FloatButtomRightPanel from "@/components/FloatButtomRightPanel";
import ListAllGame from "@/components/ListAllGame2";
import ProviderList from "@/components/ProviderList";
import MenuBar from "@/components/MenuBar";
import MenuLeft from "@/components/MenuLeft";
import MenuNav from "@/components/MenuNav";
import MenuNavTop from "@/components/MenuNavTop";
import { Carousel, Slide } from "vue-carousel";
import axiosWrapper from "@/axiosWrapper";
import store from "@/store";
import randomBetween from "@/lib/randomBetween";
import { isStringContainThaiAlpha, removeThaiAlpha } from "@/lib/TextHandle";
import HorizontalShortList2 from "@/components/gamelist/HorizontalShortList2";
import CasinoList from "@/components/gamelist/CasinoList";
import {
  ALL_PROVIDER_LIST,
  PES_PROVIDER_LIST,
} from "@/components/gamelist/Constant";
import { TABLE_TYPE_MAP } from "@/components/game/Constant";
const QUICK_TABLES = [0, 3, 1, 2];
const QUICK_TABLE_ICONS = {
  0: "/images/icon/set-icon/icon-baccarat-22.png",
  1: "/images/icon/set-icon/icon-hilo-4.png",
  2: "/images/icon/set-icon/icon-fantan3.png",
  3: "/images/icon/set-icon/icon-tiger-dagon3.png",
};
export default {
  name: "IndexPopular",
  components: {
    FloatButtomRightPanel,
    MenuBar,
    MenuLeft,
    MenuNav,
    MenuNavTop,
    Carousel,
    Slide,
    HorizontalShortList2,
    CasinoList,
    ListAllGame,
    ProviderList,
  },
  data() {
    return {
      showChatWithAdminNumber: 0,
      showDepositWithdrawNumber:0,
      menuNumber: 0,
      showList: [],
      isHideProvider: false,
      isHideListGame: true,
      isHideListGameContent: false,
      gameTypeArr: [
        {
          slug: "queenmaker",
          title: "QUEENMAKER",
        },
        {
          slug: "kp",
          title: "KP",
        },
        {
          slug: "cqgame",
          title: "CQGAME",
        },
      ],
      games: null,
      icons: {
        queenmaker: [
          "/images/icon/set-icon/icon-casino.png",
          "/images/icon/set-icon/icon-slot.png",
          "/images/icon/set-icon/icon-casino.png",
          "/images/icon/set-icon/icon-football.png",
          "/images/icon/set-icon/icon-fishing.png",
          "/images/icon/set-icon/icon-lotto.png",
          "/images/icon/set-icon/icon-roulette.png",
          "/images/icon/set-icon/icon-case.png",
        ],
        kp: [
          "/images/icon/set-icon/icon-casino.png",
          "/images/icon/set-icon/icon-football.png",
          "/images/icon/set-icon/icon-fishing.png",
          "/images/icon/set-icon/icon-lotto.png",
          "/images/icon/set-icon/icon-roulette.png",
          "/images/icon/set-icon/icon-case.png",
          "/images/icon/set-icon/icon-casino.png",
          "/images/icon/set-icon/icon-slot.png",
        ],
        cqgame: [
          "/images/icon/set-icon/icon-fishing.png",
          "/images/icon/set-icon/icon-lotto.png",
          "/images/icon/set-icon/icon-roulette.png",
          "/images/icon/set-icon/icon-case.png",
          "/images/icon/set-icon/icon-casino.png",
          "/images/icon/set-icon/icon-slot.png",
          "/images/icon/set-icon/icon-casino.png",
          "/images/icon/set-icon/icon-football.png",
        ],
      },
      currentBrand: "queenmaker", // queenmaker, kp, cqgame
      game_pop_list: {
        sa: [],
        sexy: [],
        pragmatic: [],
        fishing: [],
      },
      game_pop_list_selected: {
        sa: [],
        sexy: [],
        pragmatic: [],
        fishing: [],
      },
      list2: null,
      service_list: `${process.env.VUE_APP_PES_SERVICE_LIST}`.split(","),
      providerList: [
        {
          slug: "PGSOFT",
          label: "PGSOFT",
          image: "/images/slot/provider/PGSOFT.png",
          wrapperClass: "GameList-box-square",
          perPage: 2.8,
        },
        {
          slug: "BPG",
          label: "Blueprint",
          image: "/images/slot/provider/Blueprint.png",
          wrapperClass: "GameList-box-wide",
          perPage: 2.3,
        },

        {
          slug: "PRAGMATIC",
          label: "PragmaticPlay",
          image: "/images/slot/provider/PragmaticPlay.png",
          wrapperClass: "GameList-box-wide",
          perPage: 2.5,
        },
        {
          slug: "AMBPOKER",
          label: "Amb Poker",
          image: "/images/slot/provider/AmbPoker.png",
          wrapperClass: "GameList-box-height",
          perPage: 3.2,
        },
        {
          slug: "MICRO",
          label: "MicroGaming",
          image: "/images/slot/provider/MicroGaming.png",
          wrapperClass: "GameList-box-square",
          perPage: 3.2,
        },
        {
          slug: "RLX",
          label: "RelaxGaming",
          image: "/images/slot/provider/RelaxGaming.png",
          wrapperClass: "GameList-box-wide",
          perPage: 2.2,
        },
        {
          slug: "YGG",
          label: "Yggdrasil",
          image: "/images/slot/provider/Yggdrasil.png",
          wrapperClass: "GameList-box-wide",
          perPage: 2.5,
        },
        {
          slug: "SAGAME",
          label: "ไลฟ์สด",
          image: "/images/slot/provider/SAGaming-icon.png",
          wrapperClass: "GameList-box-wide",
        },
        {
          slug: null,
          cat: "FISHING",
          label: "ยิงปลา",
          image: "",
          wrapperClass: "GameList-box-wide",
        },
      ],
      currentProvider: null,
      isShowGameListModal: false,
      cat: null,
      catPrev: null,
      tag: "HOT",
      tagPrev: "HOT",
      keyword: "",
      keywordPrev: "",
      horizontalListSortBy: null,
      horizontalListSortDesc: null,
      categories: [
        {
          key: "HOT",
          label: "เกมยอดนิยม",
        },
        {
          key: "RECOMMEND",
          label: "แนะนำเกม",
        },
      ],
      currentCategory: null,
      game_pop_live_baccarat: [],
      fishingList: [],
      quickTables: [],
      providerInfo: null,
    };
  },
  computed: {
    is_member() {
      return store.state.is_member;
    },
    pes_profile() {
      return store.state.pes_profile;
    },
    token() {
      return this.pes_profile.token;
    },
    gameProviderList() {
      return store.state.gameProviderList;
    },
    appImageProfile() {
      return store.state.appInfo.app_image_profile;
    },
  },
  watch: {
    keyword(newVal) {
      if (newVal) {
        if (isStringContainThaiAlpha(newVal)) {
          this.$bvToast.toast("กรุณากรอกข้อมูลเป็นภาษาอังกฤษ", {
            title: "แจ้งเตือน",
            toaster: "b-toaster-top-full",
          });
          this.keyword = removeThaiAlpha(newVal);
        }
      }
    },
  },
  methods: {
    showChatWithAdmin() {
      this.showChatWithAdminNumber = 1;
      let self = this;
      setTimeout(() => {
        self.showChatWithAdminNumber = 0;
      }, 100);
    },
    showDepositWithdrawModal(){
                this.showDepositWithdrawNumber=1;
                let self =this;
                setTimeout(()=>{
                    self.showDepositWithdrawNumber=0;
                },100);
                
        },

    showMenuNav(menuNumber) {
      this.menuNumber = menuNumber;
      let self = this;
      setTimeout(() => {
        self.menuNumber = 0;
      }, 1000);
    },
    OpenListProvider() {
      this.isHideProvider = true;
    },
    ClosePopupProvider(provider,cat) {
      if (provider) {
        if (this.currentProvider) {
          this.currentProvider = null;
          this.providerInfo = null;
          let self = this;
          setTimeout(() => {
            self.providerInfo = provider;
            self.currentProvider = provider.slug;
            self.catPrev = this.cat;
            self.cat = null;
            self.providerInfo.cat = cat?cat:null
            self.tagPrev = this.tag;
            self.tag = null;
            self.keywordPrev = this.keyword;
            self.keyword = "";
            self.OpenListAllGame(provider.slug, null,cat);
            self.isHideProvider = false;
          }, 50);
        } else {
          this.providerInfo = provider;
          this.currentProvider = provider.slug;
          this.catPrev = this.cat;
          this.cat = null
          this.providerInfo.cat = cat?cat:null
          this.tagPrev = this.tag;
          this.tag = null;
          this.keywordPrev = this.keyword;
          this.keyword = "";
          this.OpenListAllGame(provider.slug, null,cat);
          this.isHideProvider = false;
        }
      } else {
        this.isHideProvider = false;
      }
    },
    async getGameServiceList() {
      const res = await axiosWrapper({
        method: "get",
        url: `${this.envDomains.VUE_APP_PES_BASE_URL}/api/game/v0/slot/get.php`,
      });
      return res.data;
    },
    async getGameList(brand = "queenmaker") {
      const res = await axiosWrapper({
        method: "post",
        url: `${this.envDomains.VUE_APP_API}/api/game/list`,
        data: {
          brand,
        },
      });

      // if (!res.data.success) {
      //     this.$alert(res.data.message, 'แจ้งเตือน', {
      //         confirmButtonText: 'ตกลง',
      //         customClass: 'custom-message-box'
      //     })
      //     return
      // }

      return res.data.data;
    },
    async getGameListAll() {
      const queenmaker = await this.getGameList("queenmaker");
      const kp = await this.getGameList("kp");
      const cqgame = await this.getGameList("cqgame");
      return {
        queenmaker,
        kp,
        cqgame,
      };
    },
    getGameArr(slug, isFull = false) {
      try {
        if (!this.games) {
          return [];
        }
        if (!this.games[slug]) {
          return [];
        }
        if (!Array.isArray(!this.games[slug])) {
          return [];
        }
        if (isFull) {
          return this.games[slug];
        }
        return this.games[slug].slice(0, 2);
      } catch (err) {
        console.log("getGameArr err", err.message);
      }
    },
    calIcon(brand, key) {
      return this.icons[brand][key % this.icons[brand].length];
    },
    getPlayingCount() {
      return randomBetween(111, 1111);
    },
    openGame(brand, game) {
      if (!this.is_member) {
        this.notifyToSignUp("เพื่อเล่น");
        return;
      }

      const baseUrl = `/game/casinoonline/sa_sexy_redirect.php?service=${brand}`;

      let query = "";
      if (brand === "queenmaker") {
        query = `&gpcode=${game.gpcode}&gcode=${game.gcode}`;
      } else if (brand === "kp") {
        query = `&gameId=${game.gameId}`;
      } else if (brand === "cqgame") {
        query = `&gamehall=${game.gamehall}&gamecode=${game.gamecode}`;
      }
      if (this.appImageProfile) {
        query = query + "&logo_img=" + this.appImageProfile;
      }

      const url = `${this.envDomains.VUE_APP_PES_BASE_URL}${baseUrl}${query}&token=${this.token}`;
      window.open(url, "_blank");
    },
    openGame2(game, service) {
      if (!this.is_member) {
        this.notifyToSignUp("เพื่อเล่น");
        return;
      }
      var params = [];

      if (service == "pragmatic") {
        params.push("code=" + game.gameId);
      } else if (service == "sa") {
        params.push("code=" + game.code);
      }
      if (this.appImageProfile) {
        params.push("logo_img=" + this.appImageProfile);
      }
      const url = `${
        this.envDomains.VUE_APP_PES_BASE_URL
      }/game/casinoonline/sa_sexy_redirect.php?mode=mobile&service=${service}&${params.join(
        "&"
      )}&token=${this.token}`;
      if (service == "sa") {
        window.location.href = url;
      } else {
        this.$router.push({
          name: "GamePlayFrame",
          query: { url: encodeURIComponent(url) },
        });
      }
    },
    showMoreGame(brand) {
      this.currentBrand = brand;
      this.$modal.show("more-game-modal");
    },
    showMoreGame2(type) {
      this.currentBrand = type;
      this.$modal.show("more-game-modal");
    },
    openSexyBaccaratGame() {
      const provider = {
        slug: "SEXY",
        label: "Sexy Baccarat",
        image: "/images/slot/provider/SexyBaccarat-icon.png",
      };
      this.openGameListModal(provider);
    },
    openSaGame() {
      const provider = {
        slug: "SAGAME",
        label: "SA Gaming",
        image: "/images/slot/provider/SAGaming-icon.png",
      };
      this.openGameListModal(provider);
    },
    openGameListModal(provider) {
      this.currentProvider = provider;
      this.isShowGameListModal = true;
      this.providerInfo = this.getProviderInfo(provider);
    },
    closeGameListModal() {
      this.currentProvider = null;
      this.isShowGameListModal = false;
      this.providerInfo = null;
    },
    selectCategory(item) {
      this.currentCategory = item;
      this.calculateFilterAndSort();
    },
    calculateFilterAndSort() {
      this.clearFilterAndSort();
      const currentCategoryKey = this.currentCategory.key;
      if (currentCategoryKey === "HOT" || currentCategoryKey === "RECOMMEND") {
        this.tag = currentCategoryKey;
      } else if (currentCategoryKey === "Name") {
        this.tag = null;
        this.horizontalListSortBy = currentCategoryKey;
        this.horizontalListSortDesc = "false";
      } else {
        this.clearFilterAndSort();
      }
    },
    clearFilterAndSort() {
      this.tag = "HOT";
      this.horizontalListSortBy = null;
      this.horizontalListSortDesc = null;
    },

    OpenListAllGame(service, list,cat) {
      this.showList = list;
      this.currentProvider = service?service:'FISHING';
      this.providerInfo = this.getProviderInfo(service);
      if(cat){
        this.providerInfo.cat = cat;
      }
      this.isHideListGame = false;
      this.isHideListGameContent = true;
    },
    CloseListAllGame() {
      this.isHideListGame = true;
      this.isHideListGameContent = false;
      this.providerInfo = null;
      this.currentProvider = null;
      this.tag = this.tagPrev;
      this.cat = this.catPrev;
      this.keyword = this.keywordPrev;
    },

    getProviderInfo(provider) {
      if (provider) {
        const PROVIDER = provider.toUpperCase();
        var info = null;
        for (let i = 0; i < PES_PROVIDER_LIST.length; i++) {
          let p = PES_PROVIDER_LIST[i];
          if (p.slug == PROVIDER) {
            info = {
              img: p.image,
              name: p.label,
              wrapperClass: p.wrapperClass,
            };
            break;
          }
        }
        if (info) return info;
        for (let i = 0; i < ALL_PROVIDER_LIST.length; i++) {
          let p = ALL_PROVIDER_LIST[i];
          if (p.slug == PROVIDER) {
            info = {
              img: p.image,
              name: p.label,
              wrapperClass: p.wrapperClass,
            };
            break;
          }
        }
        if (info) {
          return info;
        } else {
          return {
            name: "",
            img: "",
            wrapperClass: "",
          };
        }
      } else {
        info = {
              img: "",
              name: "ยิงปลา",
              wrapperClass: "GameList-box-wide",
              cat:'FISHING',
        };
        return info;
      }
    },
  },
  async mounted() {
    // this.list2 = await this.getGameServiceList();
    // store.commit("updateAllGameList", this.list2);
    // // if(this.list2.pop_list.baccarat){
    // //     for(let i=0;i<this.list2.pop_list.baccarat.length;i++){
    // //         let game = this.list2.pop_list.baccarat[i];
    // //         if(this.service_list.includes(game.service)){
    // //             this.game_pop_live_baccarat.push(game);
    // //         }
    // //     }
    // // }
    // if (this.list2.pop_list.fishing[0]) {
    //   for (let i = 0; i < this.list2.pop_list.fishing[0].length; i++) {
    //     let game = this.list2.pop_list.fishing[0][i];
    //     this.fishingList.push(game);
    //   }
    // }

    this.currentCategory = this.categories[0];
    this.calculateFilterAndSort();
    this.quickTables = QUICK_TABLES.map((table) => {
      return {
        title: TABLE_TYPE_MAP[table].title,
        routeName: TABLE_TYPE_MAP[table].routeName,
        icon: QUICK_TABLE_ICONS[table],
      };
    });
  },
};
</script>
<style>
.wrapContentIndexPopular .active .itemList li.item.itemPopular {
  background-color: white;
  border: solid 1px rgba(0, 0, 0, 0.84);
  box-shadow: 0 5px 10px rgba(0, 0, 0, 0.3);
}
.wrapContentIndexPopular .active .itemList li.item.itemPopular p,
.wrapContentIndexPopular .active .itemList li.item.itemPopular span {
  color: black;
}


  /*Theme*/
.themeBlackGold .wrapContentIndexPopular .active .itemList li.item.itemPopular {
  background-image: linear-gradient(to bottom, #fec225 0%, #e19d0e 100%) !important;
  background-color: transparent;
  border: solid 1px rgba(254, 194, 37, 0.84);
  box-shadow: 0 5px 10px rgba(254, 194, 37, 0.3);
}
.themeDarkGray .wrapContentIndexPopular .active .itemList li.item.itemPopular {
  background-color: #5f646a!important;
  border: solid 1px rgba(95, 100, 106, 0.84);
  box-shadow: 0 5px 10px rgba(59, 59, 59, 0.3);
}
.themeDarkGray .wrapContentIndexPopular .active .itemList li.item.itemPopular p,
.themeDarkGray .wrapContentIndexPopular .active .itemList li.item.itemPopular span {
  color: #fff!important;
}
.themeLightGray .wrapContentIndexPopular .active .itemList li.item.itemPopular {
  background-color: #edeef5 !important;
  border: solid 1px rgb(232, 232, 232) !important;
  box-shadow: none;
}
.themeLightGray .wrapContentIndexPopular .active .itemList li.item.itemPopular p,
.themeLightGray .wrapContentIndexPopular .active .itemList li.item.itemPopular span {
  color: #222!important;
}
</style>
