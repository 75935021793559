<template>
    <div class="DragonTigerScoreboardHeader">
        <div class="scoreboard-header-item">
            <div class="bx-TD-tiger"></div>
            <span>{{tigerCount}}</span>
        </div>
        <div class="scoreboard-header-item">
            <div class="bx-TD-pair"></div>
            <span>{{pairCount}}</span>
        </div>
        <div class="scoreboard-header-item">
            <div class="bx-TD-tie"></div>
            <span>{{tieCount}}</span>
        </div>
        <div class="scoreboard-header-item">
            <div class="bx-TD-dragon"></div>
            <span>{{dragonCount}}</span>
        </div>
    </div>
</template>
<script>

import {DRAGON_TIGER_GAME_RESULT_KEY} from '../Constant'

export default {
    name: 'DragonTigerScoreboardHeader',
    props: [
        'statArr',
    ],
    computed: {
        tigerCount() {
            return this.statArr.filter((stat) => {
                return stat.game_result === DRAGON_TIGER_GAME_RESULT_KEY.TIGER_WIN
            }).length
        },
        dragonCount() {
            return this.statArr.filter((stat) => {
                return stat.game_result === DRAGON_TIGER_GAME_RESULT_KEY.DRAGON_WIN
            }).length
        },
        tieCount() {
            return this.statArr.filter((stat) => {
                return stat.game_result === DRAGON_TIGER_GAME_RESULT_KEY.TIE
            }).length
        },
        pairCount() {
            return this.statArr.filter((stat) => {
                return stat.game_result === DRAGON_TIGER_GAME_RESULT_KEY.PAIR
            }).length
        },
    }
}
</script>
<style scoped>
.DragonTigerScoreboardHeader {
    display: flex; 
    align-items: center;
}
.scoreboard-header-item {
    margin: 0 5px;
}
.bx-baccarat-pp::before {
    font-size: 14px;
}
.bx-baccarat-bp::before {
    font-size: 14px;
}
</style>