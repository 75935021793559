<template>
    <div class="PlayerPlayList box-slide-show-info">
        <div v-for="(item, key) of list" :key="key" class="box-top-user-list">
            <div class="user-list-detail">
                <div class="user-list-detail-content">
                    <Avatar mode="image" :src="getUserPictureUrl(item.username)"></Avatar>
                    <strong>{{getUserDisplayName(item.username) | displayNameShort}}</strong>
                    <div class="groups-bx">
                        <div v-if="tableType === TABLE_TYPE_KEY.bean">
                            <div v-for="(bet, key) of getBetArr(item.user_id)" :key="key"
                                    :class="`${getBetListClass(bet.user_choose)}`">
                                {{getFantanUserChooseLabel(bet.user_choose)}}
                            </div>
                        </div>
                        <div v-else-if="tableType === TABLE_TYPE_KEY.sicbo">
                            <div v-for="(bet, key) of getBetArr(item.user_id)" :key="key"
                                    :class="`${getBetListClass(bet.user_choose)}`" style="vertical-align: middle;display: inline-block;margin-right: 5px;">
                                {{getSicboUserChooseLabel(bet.user_choose)}}
                            </div>
                        </div>
                        <div v-else>
                            <div v-for="(bet, key) of getBetArr(item.user_id)" :key="key"
                                    :class="`${getBetListClass(bet.user_choose)}`">
                            </div>
                        </div>
                    </div>
                    <span>{{item.amount | numberFormat}} บาท</span>
                </div>
            </div>
        </div>
    </div>
</template>
<script>

import store from '@/store'
import {TABLE_TYPE_KEY} from './Constant'
import {USER_CHOOSE_MAP as USER_CHOOSE_MAP_FANTAN} from './fantan/Constant'
import {USER_CHOOSE_MAP as USER_CHOOSE_MAP_SICBO} from './sicbo/Constant'
import Avatar from '@/components/Avatar'

const BET_LIST_CLASS_MAP = {
    0: {
        1: 'bx-baccarat-b',
        2: 'bx-baccarat-p',
        3: 'bx-baccarat-t',
        5: 'bx-baccarat-bp',
        6: 'bx-baccarat-pp',
        7: '',
        8: '',
        9: '',
    },
    1: {
        1: 'text-hi',
        2: 'text-lo',
        3: 'text-hilo',
        4: 'text-white',
        5: 'text-white',
        6: 'text-white',
        7: 'text-white',
        8: 'text-white',
        9: 'text-white',
        10: 'text-white',
        11: 'text-white',
        12: 'text-white',
        13: 'text-white',
        14: 'text-white',
        15: 'text-white',
        16: 'text-white',
        17: 'text-white',
        18: 'text-white',
        19: 'text-white',
        20: 'text-white',
        22: 'text-white',
        23: 'text-white',
        24: 'text-white',
        25: 'text-white',
        26: 'text-white',
        27: 'text-white',
        28: 'text-white',
        29: 'text-white',
        30: 'text-white',
        31: 'text-white',
        32: 'text-white',
        33: 'text-white',
    },
    2: {
        1: 'fantan-bet-peg bet-1',
        2: 'fantan-bet-peg bet-2',
        3: 'fantan-bet-peg bet-3',
        5: 'fantan-bet-peg bet-4',
    },
    3: {
        1: 'bx-TD-dragon',
        2: 'bx-TD-tiger',
        3: 'bx-TD-tie',
        5: 'bx-TD-pair',
    },
    4: {
        1: 'bx-baccarat-b',
        2: 'bx-baccarat-p',
        3: 'bx-baccarat-t',
        5: 'bx-baccarat-bp',
        6: 'bx-baccarat-pp',
        7: '',
        8: '',
        9: '',
    },
    5: {
        1: 'bx-baccarat-b',
        2: 'bx-baccarat-p',
        3: 'bx-baccarat-t',
        5: 'bx-baccarat-bp',
        6: 'bx-baccarat-pp',
        7: '',
        8: '',
        9: '',
    },
}

export default {
    name: 'PlayerPlayList',
    components: {
        Avatar,
    },
    props: [
        'list',
        'bet_list',
        'tableType',
    ],
    data() {
        return {
            TABLE_TYPE_KEY: null,
        }
    },
    computed: {
        user_info_arr(){
            return store.state.user_info_arr
        },
    },
    methods: {
        getUserInfo(pes_username) {
            return this.user_info_arr.find((user_info) => {
                return user_info.pes_username === pes_username
            })
        },
        getUserDisplayName(pes_username) {
            const userInfo = this.getUserInfo(pes_username)
            return (userInfo) ? userInfo.display_name : pes_username
        },
        getUserPictureUrl(pes_username) {
            const userInfo = this.getUserInfo(pes_username)
            return (userInfo) ? userInfo.picture_url : ''
        },
        getBetArr(user_id) {
            const arr = []
            for(const key in this.bet_list[user_id]) {
                arr.push({
                    user_choose: key,
                    amount: this.bet_list[user_id][key],
                })
            }
            return arr
        },
        getBetListClass(user_choose) {
            if(this.tableType < 0) {
                return ''
            }
            return BET_LIST_CLASS_MAP[this.tableType][user_choose]
        },
        getFantanUserChooseLabel(user_choose) {
            return USER_CHOOSE_MAP_FANTAN[user_choose]['number']
        },
        getSicboUserChooseLabel(user_choose) {
            return USER_CHOOSE_MAP_SICBO[user_choose]['TH']
        },
    },
    created() {
        this.TABLE_TYPE_KEY = TABLE_TYPE_KEY
    }
}
</script>