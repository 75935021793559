<template>
    <div class="Admin">
        <el-menu v-if="need_layout" :default-active="activeIndex" mode="horizontal" @select="handleSelect">
            <el-menu-item index="toggleLeftMenu"><el-button icon="el-icon-s-fold" size="small"/></el-menu-item>
            <el-menu-item index="AdminUser" style="font-size: 22px;">CHAT ADMIN</el-menu-item>
            <el-menu-item index="logout" style="float: right;"><el-button>LOGOUT</el-button></el-menu-item>
        </el-menu>
        <div v-if="need_layout" class="v-content-wrapper">
            <div class="v-content-left">
                <el-menu :default-active="activeIndex" class="el-menu-vertical-demo" :collapse="isCollapse" @select="handleSelect">
                    <el-menu-item index="AdminUser">
                        <i class="el-icon-user"/>
                        <span slot="title">ผู้ใช้</span>
                    </el-menu-item>
                    <el-menu-item index="AdminMessage">
                        <i class="el-icon-position"/>
                        <span slot="title">ข้อความ</span>
                    </el-menu-item>
                    <el-menu-item index="AdminBot">
                        <i class="el-icon-cpu"/>
                        <span slot="title">บอท</span>
                    </el-menu-item>
                    <el-menu-item index="AdminTools">
                        <i class="el-icon-s-tools"/>
                        <span slot="title">เครื่องมือ</span>
                    </el-menu-item>
                    <el-menu-item index="AdminStreamer">
                        <i class="el-icon-video-camera"/>
                        <span slot="title">สตรีมเมอร์</span>
                    </el-menu-item>
                </el-menu>
            </div>
            <div class="v-content-right">
                <router-view/>
            </div>
        </div>
        <div v-else>
            <router-view @logout="logout"/>
        </div>
    </div>
</template>
<script>

import store from '@/store'
import axios from 'axios'

export default {
    name: 'Admin',
    data(){
        return {
            isCollapse: true,
        }
    },
    computed: {
        need_layout: function(){
            return this.$route.name !== 'login' && this.$route.name !== 'AdminGameChatBot';
        },
        activeIndex: function(){
            return this.$route.name;
        },
        admin: {
            get() {
                return store.state.admin
            },
            set(value) {
                store.commit('updateAdmin', value)
            }
        },
        token(){
            return (this.admin) ? this.admin.token : ''
        },
    },
    methods: {
        handleSelect(key) {
            if(key === 'toggleLeftMenu'){
                this.toggleLeftMenu();
            }else if(key === 'logout'){
                this.logout();
            }else if(this.$route.name !== key){
                this.$router.push({name: key});
            }
        },
        toggleLeftMenu: function(){
            this.isCollapse = !this.isCollapse;
        },
        logout: async function(){
            await axios({
                method: 'POST',
                url: `${this.envDomains.VUE_APP_API}/api/admin/logout`,
                data: {
                    token: this.token,
                }
            })
            await store.dispatch('adminLogout')
            window.location.reload()
        },
        async isAdmin(){
            if(this.admin){
                const res = await axios({
                    method: 'POST',
                    url: `${this.envDomains.VUE_APP_API}/api/admin/auth`,
                    data: {
                        token: this.token,
                    }
                })
                return res.data.success
            }else{
                return false
            }
        },
    },
    async mounted(){
        if(!await this.isAdmin()){
            this.logout()
            return
        }
    }
}
</script>
<style>
.Admin{background-color: #fff}

    .v-flex {
        display: flex;
    }
    .v-flex-row-center {
        justify-content: center;
    }
    .el-menu-vertical-demo {
        min-height: 100%;
    }
    .el-menu-vertical-demo:not(.el-menu--collapse) {
        width: 200px;
    }
    .v-content-wrapper {
        display: flex;
    }
    .v-content-left {
        height: calc(100vh - 61px);
    }
    .v-content-right {
        height: calc(100vh - 61px);
        width: 100%;
        overflow-y: auto;
    }
    .v-content-inner {
        padding: 8px;
    }
    .v-toolbar {
        display: flex;
        justify-content: space-between;
        align-items: center;
        padding: 4px;
        margin-bottom: 4px;
    }
    .v-toolbar .toolbar-item {
        display: flex;
    }
    .v-list {
        padding: 4px;
        max-width: 100vw;
        overflow-x: auto;
    }
    .v-toolbar-bottom {
        display: flex;
        justify-content: center;
        padding: 8px;
    }
    .v-table-tools {
        justify-content: flex-start;
        display: flex;
    }
    .color-primary {
        color: #409EFF;
    }
    .color-success {
        color: #67C23A;
    }
    .color-warning {
        color: #E6A23C;
    }
    .color-danger {
        color: #F56C6C;
    }
    .color-info {
        color: #909399;
    }
    .text-color-regular {
        color: #606266;
    }
</style>
<style scoped>
    /*@media only screen*/
    /*and (min-device-width: 1300px) and (orientation: landscape){*/
    /*    body{background-color: #fff!important}*/
    /*    #app {*/
    /*        width: 100%;*/
    /*        height: 100vh!important;*/
    /*        position: fixed;*/
    /*        border: solid 1px #999;*/
    /*        margin: auto!important;*/
    /*        left: 0;*/
    /*        right: 0;*/
    /*        top: 0;*/
    /*        bottom: 0;*/
    /*        overflow: hidden;*/
    /*    }*/
    /*}*/
</style>