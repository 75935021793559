<template>
    <div @click="$emit('click')" ref="CoinGush" class="CoinGush" />
</template>
<script>

import {DivSugar} from '@/divsugar'

export default {
    name: 'CoinGush',
    props: ['coin_count', 'coin_duration'],
    mounted(){

        const _this = this

        const coin_duration = this.coin_duration || 5

        let re_new_coin = true
        setTimeout(() => {
            re_new_coin = false
        }, 1000*coin_duration)

        // create a scene
        var scn = new DivSugar.Scene().setSize(800, 600)
        .setImage('transparent')
        // .setImage('https://github.com/kitao/divsugar/raw/master/examples/assets/sunset.jpg')
        .appendTo(this.$refs.CoinGush);

        // maximize the scene size
        function resize() { 
            scn.adjustLayout(window.innerWidth, window.innerHeight, 'cover'); 
        }
        window.addEventListener('resize', resize, true);
        resize();

        // define an animation class which inherits the task class
        function Coin() {
            // call the base class's constructor
            this.constructor.uber.constructor();

            this.vec = new DivSugar.Vector();
            this.pos = new DivSugar.Vector(Math.random() * 800, 900, Math.random() * 800 - 1000);
            this.vel = new DivSugar.Vector(Math.random() * 6 - 3, Math.random() * 8 - 20, Math.random() * 6 - 3);
            this.rot = new DivSugar.Vector(Math.random() * 0.1, Math.random() * 0.1, Math.random() * 0.1);

            this.center = new DivSugar.Node().setPosition(this.pos)
                .rotate(Math.random() * 360, Math.random() * 360, Math.random() * 360).appendTo(scn);

            this.front = new DivSugar.Node().setSize(60, 60).setPosition(-50, -50, 0).setBackface(false)
                .setImage('/images/coin1.png').setImageClip(0, 0, 0.5, 1).appendTo(this.center);

            this.back = new DivSugar.Node().setSize(60, 60).setPosition(50, -50, 0).setBackface(false)
                .setImage('/images/coin1.png').setImageClip(0.5, 0, 1, 1).rotate(0, 180, 0).appendTo(this.center);
        }

        DivSugar.inherit(Coin, DivSugar.Task);

        Coin.prototype.onUpdate = function() {
            this.vel.y += this.deltaTime * 0.01;
            this.pos.add(this.vec.set(this.vel).mul(this.deltaTime * 0.06));
            this.center.setPosition(this.pos).rotate(this.deltaTime * this.rot.x, this.deltaTime * this.rot.y, this.deltaTime * this.rot.z);

            // when fall enough, destroy this task and create an another task
            if (this.pos.y > 1000) {
                this.destroy();
                if(re_new_coin){
                    new Coin().appendTo(DivSugar.rootTask);
                }
                if(DivSugar.rootTask._children.length === 1){
                    _this.$emit('done')
                }
            }
        };

        Coin.prototype.onDestroy = function() { scn.remove(this.center); };

        const coin_count = this.coin_count || 50

        // create and register instances of the animation class
        for (var i = 0; i < coin_count; i++) { new Coin().appendTo(DivSugar.rootTask); }
    }
}
</script>