import {Howl} from 'howler'

export const USER_CHOOSE = {
    HI: 1,
    LOW: 2,
    HILO11: 3,
    CANCEL: 4,
    123: 5,
    63: 6,
    42: 7,
    53: 8,
    51: 9,
    456: 10,
    52: 11,
    61: 12,
    46: 13,
    '6LOW': 14,
    '5LOW': 15,
    13: 16,
    41: 17,
    62: 18,
    1: 19,
    2: 20,
    3: 21,
    4: 22,
    5: 23,
    6: 24,
    12: 25,
    23: 26,
    34: 27,
    45: 28,
    56: 29,
    '1LOW': 30,
    '3LOW': 31,
    '4HI': 32,
    '6HI': 33,
}

export const USER_CHOOSE_MAP = {
    1: {
        TH: 'สูง',
    },
    2: {
        TH: 'ต่ำ',
    },
    3: {
        TH: '11 ไฮโล',
    },
    4: {
        TH: 'ยกเลิก',
    },
    5: {
        TH: '1.2.3',
    },
    6: {
        TH: '6.3',
    },
    7: {
        TH: '4.2',
    },
    8: {
        TH: '5.3',
    },
    9: {
        TH: '5.1',
    },
    10: {
        TH: '4.5.6',
    },
    11: {
        TH: '5.2',
    },
    12: {
        TH: '6.1',
    },
    13: {
        TH: '4.6',
    },
    14: {
        TH: '6 ต่ำ',
    },
    15: {
        TH: '5 ต่ำ',
    },
    16: {
        TH: '1.3',
    },
    17: {
        TH: '4.1',
    },
    18: {
        TH: '6.2',
    },
    19: {
        TH: '1',
    },
    20: {
        TH: '2',
    },
    21: {
        TH: '3',
    },
    22: {
        TH: '4',
    },
    23: {
        TH: '5',
    },
    24: {
        TH: '6',
    },
    25: {
        TH: '1.2',
    },
    26: {
        TH: '2.3',
    },
    27: {
        TH: '3.4',
    },
    28: {
        TH: '4.5',
    },
    29: {
        TH: '5.6',
    },
    30: {
        TH: '1 ต่ำ',
    },
    31: {
        TH: '3 ต่ำ',
    },
    32: {
        TH: '4 สูง',
    },
    33: {
        TH: '6 สูง',
    },
}

export const USER_CHOOSE_MAP_TO_BET = {
    HI: 'bet_hi',
    LOW: 'bet_low',
    HILO11: 'bet_hilo11',
    CANCEL: '',
    123: 'bet_123',
    63: 'bet_63',
    42: 'bet_42',
    53: 'bet_53',
    51: 'bet_51',
    456: 'bet_456',
    52: 'bet_52',
    61: 'bet_61',
    46: 'bet_46',
    '6LOW': 'bet_6low',
    '5LOW': 'bet_5low',
    13: 'bet_13',
    41: 'bet_41',
    62: 'bet_62',
    1: 'bet_1',
    2: 'bet_2',
    3: 'bet_3',
    4: 'bet_4',
    5: 'bet_5',
    6: 'bet_6',
    12: 'bet_12',
    23: 'bet_23',
    34: 'bet_34',
    45: 'bet_45',
    56: 'bet_56',
    '1LOW': 'bet_1low',
    '3LOW': 'bet_3low',
    '4HI': 'bet_4hi',
    '6HI': 'bet_6hi',
}

export const GAME_RESULT_KEY = {
    WAIT:0, //รอผลออก
    HI: 1, //ออกสูง
    LOW: 2,//ออกต่ำ
    HILO11: 3, // 11 ไฮโล
    CANCEL: 4,//ยกเลิก
}

export const GAME_RESULT_MAP = {
    0: {
        title: 'รอผลออก'
    },
    1: {
        title: 'สูง'
    },
    2: {
        title: 'ต่ำ'
    },
    3: {
        title: 'ไฮโล'
    },
    4: {
        title: 'ยกเลิก'
    },
}

export const GAME_RESULT = {
    0: {
        EN: 'WAIT',
        TH: 'รอผลออก'
    },
    1: {
        EN: 'hi',
        TH: 'สูง'
    },
    2: {
        EN: 'lo',
        TH: 'ต่ำ'
    },
    3: {
        EN: '11 hilo',
        TH: 'ไฮไล'
    },
    4: {
        EN: 'CANCEL',
        TH: 'ยกเลิก'
    },
}

export const SOUND = {
    DICE: {
        1: new Howl({src: ['/sounds/game/sicbo/dice/dice_1_th.mp3'], preload: false}),
        2: new Howl({src: ['/sounds/game/sicbo/dice/dice_2_th.mp3'], preload: false}),
        3: new Howl({src: ['/sounds/game/sicbo/dice/dice_3_th.mp3'], preload: false}),
        4: new Howl({src: ['/sounds/game/sicbo/dice/dice_4_th.mp3'], preload: false}),
        5: new Howl({src: ['/sounds/game/sicbo/dice/dice_5_th.mp3'], preload: false}),
        6: new Howl({src: ['/sounds/game/sicbo/dice/dice_6_th.mp3'], preload: false}),
    },
    POINT: {
        1: new Howl({src: ['/sounds/game/sicbo/point/total_1_th.mp3'], preload: false}),
        2: new Howl({src: ['/sounds/game/sicbo/point/total_2_th.mp3'], preload: false}),
        3: new Howl({src: ['/sounds/game/sicbo/point/total_3_th.mp3'], preload: false}),
        4: new Howl({src: ['/sounds/game/sicbo/point/total_4_th.mp3'], preload: false}),
        5: new Howl({src: ['/sounds/game/sicbo/point/total_5_th.mp3'], preload: false}),
        6: new Howl({src: ['/sounds/game/sicbo/point/total_6_th.mp3'], preload: false}),
        7: new Howl({src: ['/sounds/game/sicbo/point/total_7_th.mp3'], preload: false}),
        8: new Howl({src: ['/sounds/game/sicbo/point/total_8_th.mp3'], preload: false}),
        9: new Howl({src: ['/sounds/game/sicbo/point/total_9_th.mp3'], preload: false}),
        10: new Howl({src: ['/sounds/game/sicbo/point/total_10_th.mp3'], preload: false}),
        11: new Howl({src: ['/sounds/game/sicbo/point/total_11_th.mp3'], preload: false}),
        12: new Howl({src: ['/sounds/game/sicbo/point/total_12_th.mp3'], preload: false}),
        13: new Howl({src: ['/sounds/game/sicbo/point/total_13_th.mp3'], preload: false}),
        14: new Howl({src: ['/sounds/game/sicbo/point/total_14_th.mp3'], preload: false}),
        15: new Howl({src: ['/sounds/game/sicbo/point/total_15_th.mp3'], preload: false}),
        16: new Howl({src: ['/sounds/game/sicbo/point/total_16_th.mp3'], preload: false}),
        17: new Howl({src: ['/sounds/game/sicbo/point/total_17_th.mp3'], preload: false}),
        18: new Howl({src: ['/sounds/game/sicbo/point/total_18_th.mp3'], preload: false}),
    },
    SHAKE: [
        new Howl({src: ['/sounds/game/sicbo/shake/01.mp3'], preload: false}),
        new Howl({src: ['/sounds/game/sicbo/shake/02.mp3'], preload: false}),
        new Howl({src: ['/sounds/game/sicbo/shake/03.mp3'], preload: false}),
        new Howl({src: ['/sounds/game/sicbo/shake/04.mp3'], preload: false}),
        new Howl({src: ['/sounds/game/sicbo/shake/05.mp3'], preload: false}),
        new Howl({src: ['/sounds/game/sicbo/shake/06.mp3'], preload: false}),
        new Howl({src: ['/sounds/game/sicbo/shake/07.mp3'], preload: false}),
        new Howl({src: ['/sounds/game/sicbo/shake/08.mp3'], preload: false}),
        new Howl({src: ['/sounds/game/sicbo/shake/09.mp3'], preload: false}),
        new Howl({src: ['/sounds/game/sicbo/shake/10.mp3'], preload: false}),
        new Howl({src: ['/sounds/game/sicbo/shake/11.mp3'], preload: false}),
        new Howl({src: ['/sounds/game/sicbo/shake/12.mp3'], preload: false}),
    ],
}

export const soundLoad = () => {
    for(const key in SOUND['DICE']) {
        SOUND['DICE'][key].load()
    }
    for(const key in SOUND['POINT']) {
        SOUND['POINT'][key].load()
    }
    for(const key in SOUND['SHAKE']) {
        SOUND['SHAKE'][key].load()
    }
}