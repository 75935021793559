<template>
  <div class="contentListProviderLeft" v-if="is_ready">
    <div class="contentListProviderLeftInfo">
      <h3>คาสิโนสด</h3>
      <ul>
        <li
          v-show="checkGameProviderAvailable(provider.slug)"
          v-for="(provider, key) of topProviderList"
          :key="key"
          @click="openGameList(provider,'CASINO')"
        >
          <div
            class="boxProviderLeft"
            :class="{ disabledNavItem: checkGameMaintainance(provider.slug) }"
          >
            <img :src="provider.image" />
            <h4>{{ provider.label }}</h4>
          </div>
        </li>
      </ul>
    </div>
    <div class="contentListProviderLeftInfo">
      <h3>สล็อตออนไลน์</h3>
      <ul>
        <li
          v-show="checkGameProviderAvailable(provider.slug)"
          v-for="(provider, key) of allProviderList"
          :key="key"
          @click="openGameList(provider,'SLOT')"
        >
          <div
            class="boxProviderLeft"
            :class="{ disabledNavItem: checkGameMaintainance(provider.slug) }"
          >
            <img :src="provider.image" />
            <h4>{{ provider.label }}</h4>
          </div>
        </li>
      </ul>
    </div>
  </div>
</template>
<script>
import { PROVIDER_LIST } from "@/components/gamelist/Constant";
import GameListMixin from "@/components/gamelist/GameListMixin";
import store from "@/store";
export default {
  name: "PromotionList",
  mixins: [GameListMixin],
  components: {},
  data() {
    return {
      topProviderList: [
        {
          slug: "SAGAME",
          label: "SA Gaming",
          image: "/images/slot/provider/SAGaming-icon.png",
        },
        {
          slug: "SEXY",
          label: "Sexy Baccarat",
          image: "/images/slot/provider/SexyBaccarat-icon.png",
        },
        {
          slug: "PRAGMATIC",
          label: "Pragmatic Play",
          image: "/images/slot/provider/PragmaticPlay.png",
          wrapperClass: "GameList-box-wide",
          perPage: 2.8,
        },
        {
          slug: "PRETTY",
          label: "Pretty",
          image: "/images/slot/provider/PRETTY.png",
          wrapperClass: "GameList-box-wide",
        },
        {
          slug: "EVO",
          label: "Evolution Gaming",
          image: "/images/slot/provider/EvolutionGaming.png",
          wrapperClass: "GameList-box-wide",
        },
      ],
      allProviderList: [],
      currentProvider: null,
      is_ready: false,
    };
  },
  computed: {
    pes_profile() {
      return store.state.pes_profile;
    },
    service() {
      // return localStorage.service
      return store.state.service;
    },
  },
  methods: {
    openGameList(provider,cat) {
      this.currentProvider = provider;
      let _cat =null;
      if(provider.slug=='PRAGMATIC'){
        _cat = cat;
      }
      console.log('cat',_cat);
      let mainTainance=this.checkGameMaintainance(provider.slug);
      if(!mainTainance){
        this.$emit("openGameList", this.currentProvider,_cat);
      }
      
    },
    checkGameMaintainance(provider) {
      const _provider = provider.toLowerCase();
     
      const service = this.service;
    //   console.log(service);
      let is_maintainance = false;
      if (service[_provider]) {
        if (service[_provider].login == false) {
          console.log(_provider, "maintainance");
          is_maintainance = true;
        }
      } else if (service["amb_" + _provider]) {
        if (service["amb_" + _provider].login == false) {
          console.log(_provider, "maintainance");
          is_maintainance = true;
        }
      } else if (service["qt_" + provider]) {
        if (service["qt_" + provider].login == false) {
          console.log(provider, "maintainance");
          is_maintainance = true;
        }
      } else {
        // console.log("no service", _provider);
      }
      if(is_maintainance){
        let elementList=document.getElementsByClassName('item-game-'+_provider);
        for(let i=0;i<elementList.length;i++){
          elementList[i].classList.add('disabledNavItem')
        }
      }
      return is_maintainance;
    },
  },
  async mounted() {
    let checkPesProfile = setInterval(() => {
      if (this.service) {
        clearInterval(checkPesProfile);
        this.allProviderList = PROVIDER_LIST;
        this.is_ready = true;
        console.log("ready for check maintainance");
      }
    }, 500);
  },
};
</script>
