<template>
<div class="LineSyncView">
    <LineLoginLanding mode="sync"></LineLoginLanding>
</div>
</template>
<script>

import LineLoginLanding from '@/components/user/LineLoginLanding'

export default {
    name: 'LineLoginView',
    components: {
        LineLoginLanding,
    },
}
</script>
<style scoped>
.LineSyncView {
    height: 100vh;
    width: 100vw;
    display: flex;
    justify-content: center;
    align-items: center;
}
</style>
