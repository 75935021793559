<template>
    <div class="AdminToolContent">
        <div v-if="type === 'lotto_news'" style="text-align: center;">
            <el-button @click="toSelectLottoNews" type="primary" style="margin-bottom: 5px;">เลือกจากข่าวที่มี [{{lottoNewsArr.length}}]</el-button>
            <div style="margin-bottom: 5px; font-size: 12px;">หรือ กรอกข้อมูลด้วยตนเอง</div>
        </div>
        <div>URL</div>
        <el-input v-model="form.url" placeholder="https://www.youtube.com/embed/ykCpjm5mLMU"></el-input>
        <div class="v-line-seperator"></div>
        <div>หัวข้อ</div>
        <el-input v-model="form.title" placeholder="ถ่ายทอดสดการออกรางวัลสลากกินแบ่งรัฐบาล"></el-input>
        <div class="v-line-seperator"></div>
        <div>คำอธิบาย</div>
        <el-input v-model="form.description" placeholder="งวด 16 ตุลาคม 2563"></el-input>
        <div class="v-line-seperator"></div>
        <div>thumbnail</div>
        <Uploader  
            :data="{token}"
            :bus="uploadBus"
            :limit="uploadLimit"
            :multiple="uploadMultiple"
            @done="handleUploadDone"
            @remove="handleUploadRemove"/>
        <div class="v-line-seperator"></div>
        <el-button @click="toRemove" icon="el-icon-close" class="content-remove-button" circle plain></el-button>

        <el-dialog
        title="เลือกข่าว"
        :visible.sync="selectLottoNewsDialogVisible"
        width="30%">
            <el-card v-for="(lottoNews, key) of lottoNewsArr" :key="key" 
                :body-style="{ 
                    padding: '2px', 
                    cursor: 'pointer',
                }"
                style="margin: 4px;">
                <div @click="selectLottoNews(lottoNews)" style="display: flex;">
                    <div>
                        <img :src="`${envDomains.VUE_APP_CBJADMIN_BASE_URL}${lottoNews.picture}`" style="width: 100px; height: 100px; object-fit: cover;">
                    </div>
                    <div style="padding: 4px;">
                        <div>
                            <h6><strong>{{lottoNews.subject}}</strong></h6>
                        </div>
                        <div>
                            {{lottoNews.huey_lot_name}}
                        </div>
                        <div :class="{'lotto-news-expired': lottoNewsIsExpire(lottoNews)}">
                            <strong>หมดอายุ: </strong>{{lottoNewsEndDisplay(lottoNews)}}
                        </div>
                    </div>
                </div>
            </el-card>
            <span slot="footer" class="dialog-footer">
                <el-button @click="selectLottoNewsDialogVisible = false">ปิด</el-button>
            </span>
        </el-dialog>
    </div>
</template>

<script>

import Uploader from '@/components/Uploader'
import store from '@/store'
import Vue from 'vue'

export default {
    name: 'AdminToolContent',
    components: {
        Uploader
    },
    props: ['index', 'form', 'bus', 'type', 'lottoNewsArr'],
    data(){
        return {
            uploadBus: new Vue(),
            selectLottoNewsDialogVisible: false,
        }
    },
    computed: {
        admin(){
            return store.state.admin
        },
        token(){
            return this.admin.token
        },
        uploadLimit(){
            if(this.type === 'gallery'){
                return 3
            }else{
                return 0
            }
        },
        uploadMultiple(){
            if(this.type === 'gallery'){
                return true
            }else{
                return false
            }
        }
    },
    methods: {
        toRemove(){
            this.$alert(`ต้องการลบ?`, 'แจ้งเตือน', {
                type: 'warning',
                showCancelButton: true,
                confirmButtonText: 'ตกลง',
                cancelButtonText: 'ยกเลิก',
                customClass: 'custom-message-box',
                callback: action => {
                    if(action === 'confirm'){
                        this.remove()
                    }
                }
            })
        },
        remove(){
            this.uploadBus.$emit('clear')
            this.$emit('remove', this.index)
        },
        handleUploadDone(data){
            this.form.thumbnail_mode = 'upload'
            if(this.uploadLimit){
                this.form.thumbnails.push(data)
            }else{
                this.form.thumbnail = data
            }
        },
        handleUploadRemove(data){
            if(this.uploadLimit){
                const indexToRemove= this.form.thumbnails.findIndex((t) => {
                    return t === data
                })
                this.form.thumbnails.splice(indexToRemove, 1)
            }else{
                this.form.thumbnail = ''
            }
        },
        toSelectLottoNews() {
            this.selectLottoNewsDialogVisible = true
        },
        selectLottoNews(lottoNews) {
            if(this.lottoNewsIsExpire(lottoNews)) {
                this.$alert(`ข่าวหมดอายุ กรุณาเลือกข่าวอื่น`, 'แจ้งเตือน', {
                    confirmButtonText: 'ตกลง',
                })
                return
            }
            this.form.url = lottoNews.url
            this.form.title = lottoNews.subject
            this.form.description = `${lottoNews.huey_type} ${lottoNews.huey_lot_name}`
            this.form.thumbnail = lottoNews.picture
            this.form.thumbnail_mode = 'hot-link'
            this.form.expire = this.lottoNewsEndDisplay(lottoNews)
            this.selectLottoNewsDialogVisible = false
        },
        lottoNewsEnd(lottoNews) {
            if(lottoNews.date_end && lottoNews.time_end) {
                return this.$moment(`${lottoNews.date_end} ${lottoNews.time_end}`)
            } else if(lottoNews.date_end) {
                return this.$moment(`${lottoNews.date_end}`)
            } else {
                return null
            }
        },
        lottoNewsEndDisplay(lottoNews) {
            const lottoNewsEnd = this.lottoNewsEnd(lottoNews)
            if(!lottoNewsEnd) {
                return ''
            }
            return lottoNewsEnd.format('YYYY-MM-DD HH:mm:ss')
        },
        lottoNewsIsExpire(lottoNews) {
            const lottoNewsEnd = this.lottoNewsEnd(lottoNews)
            if(!lottoNewsEnd) {
                return false
            }
            const now = this.$moment()
            return !now.isSameOrBefore(lottoNewsEnd)
        },
    },
    mounted(){
        this.bus.$on('clear', () => {
            this.uploadBus.$emit('clear')
        })
    }
}
</script>

<style scoped>

.AdminToolContent {
    position: relative;
    width: 400px;
    border: solid gray 1px;
    padding: 30px 10px 10px 10px;
    border-radius: 5px;
}

.content-remove-button{
    position: absolute;
    top: 4px;
    right: 4px;
}

.lotto-news-expired {
    color: red;
}

</style>