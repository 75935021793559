export function sib_kao_pratu_number_list(number_str){

    const arr = ['0', '1', '2', '3', '4', '5', '6', '7', '8', '9'];

    let number_list = [];

    for(let i of arr){
        if(i === number_str){
            for(let j of arr){
                number_list.push(number_str+j);
            }
        }else{
            number_list.push(i+number_str);
        }
    }

    return number_list;
}

export function rood_nha_number_list(number_str){

    const arr = ['0', '1', '2', '3', '4', '5', '6', '7', '8', '9'];

    return arr.map((o) => { return number_str+o });
}

export function rood_lang_number_list(number_str){

    const arr = ['0', '1', '2', '3', '4', '5', '6', '7', '8', '9'];

    return arr.map((o) => { return o+number_str });
}