<template>
    <div v-if="gameList.length > 0 && checkGameProviderAvailable(provider.slug)" :class="`GameList-box ${provider.wrapperClass}`">
        <div class="GameList-box-title">
            <h4><img :src="provider.image"/>{{provider.label}}</h4>
            <div @click="seeAll" class="btnMore"></div>
        </div>
        <div class="GameListHorizontalShortList flexListgame">
            <carousel
                    :per-page="provider.perPage"
                    :mouseDrag="true"
                    :navigationEnabled="true"
                    :paginationEnabled="false"
                    navigationNextLabel="❯"
                    navigationPrevLabel="❮"
                    paginationPosition="bottom"
                    data-ref="carousel"
                    ref="carousel">
                <slide v-for="(game, key) of gameList" :key="key">
                    <div @click="play(game)" class="box-list-games">
                        <div class="box-icon-games">
                            <el-image :src="getGameImage(game)" @load="imageLoaded" @error="imageLoaded">
                                <el-image slot="error" :src="game.Image1">
                                    <div class="noneImageSlot" slot="error"><i class="fas fa-image"></i></div>
                                </el-image>
                            </el-image>
                        </div>
                        <div class="box-detail-games">
                            <h4>{{game.Name}}</h4>
                        </div>
                    </div>
                </slide>
                <slide>
                    <div @click="seeAll" class="btn-list-games">
                        <span>All</span>
                    </div>
                </slide>
            </carousel>
        </div>
    </div>
</template>
<script>

import { Carousel, Slide } from 'vue-carousel'
import axiosWrapper from '@/axiosWrapper'
import GameListMixin from './GameListMixin'
import localStorageCache from '@/lib/localStorageCache'

export default {
    name: 'GameListHorizontalShortList',
    mixins: [
        GameListMixin,
    ],
    components: {
        Carousel,
        Slide,
    },
    props: [
        'provider',
        'cat',
        'perPage',
        'tag',
        'sortBy',
        'sortDesc',
        'keyword',
    ],
    data() {
        return {
            isOnLoad: false,
            gameList: [],
            isRefreshCarouselWidth: false,
        }
    },
    methods: {
        async loadGameList() {

            this.isOnLoad = true

            const provider = this.provider.slug
            const cat = this.cat
            const tag = this.tag
            const sortBy = this.sortBy
            const sortDesc = this.sortDesc
            const keyword = this.keyword

            const localStorageCacheKey = `horizontalShortList;${provider};${cat};${tag};${sortBy};${sortDesc}:${keyword}`

            // const dataFromCache = localStorageCache.get(localStorageCacheKey)

            // if(dataFromCache) {
            //     this.gameList = dataFromCache
            //     this.isOnLoad = false
            //     return
            // }

            const res = await axiosWrapper({
                method: 'post',
                url: `${this.envDomains.VUE_APP_API}/api/gamelist-api/game`,
                data: {
                    page: 1,
                    itemsPerPage: 4,
                    provider: provider,
                    cat: cat,
                    tag: tag,
                    sortBy: sortBy,
                    sortDesc: sortDesc,
                    keyword: keyword,
                }
            })

            this.isOnLoad = false

            if(res.status !== 200) {
                this.$bvToast.toast(res.data.message, {
                    title: 'แจ้งเตือน',
                    toaster: 'b-toaster-top-full'
                })
                return null
            }
            this.gameList = res.data.items
            if(res.data.items.length > 0) {
                localStorageCache.set(localStorageCacheKey, this.gameList, 1)
            }
        },
        seeAll() {
            this.$emit('seeAll', this.provider)
        },
        getGameImage(game) {
            return `${this.envDomains.VUE_APP_API}/images/gameSlot/${game.ApiName}_${game.GameCode}.png`
        },
        imageLoaded() {
            this.refreshCarouselWidth()
        },
        refreshCarouselWidth(){
            if(this.isRefreshCarouselWidth) {
                return
            }
            if(!this.$refs.carousel) {
                return
            }
            this.$refs.carousel.computeCarouselWidth()
            this.isRefreshCarouselWidth = true
        },
    },
    mounted() {
        this.loadGameList()
    }
}
</script>