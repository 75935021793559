<template>
    <div>
        <!-- <div class="fixedEditTheme" @click="showSelectTheme()">
            <i class="fas fa-fill-drip"></i>
        </div> -->
        <div class="wrapEditTheme" :class="{'EditTheme-hide':theme_hide}" >
            <div class="boxEditTheme">
                <div class="close-box-bonus" @click="hideSelectTheme()"></div>
                <h4>เลือกสี Theme</h4>
                <ul>
                    <li :class="{'active':currentTheme=='pink'}"><span class="bx-pink"  @click="selectTheme('pink')"></span></li>
                    <li :class="{'active':currentTheme=='Blue'}"><span class="bx-Blue" @click="selectTheme('Blue')"></span></li>
                    <li :class="{'active':currentTheme=='Green'}"><span class="bx-Green" @click="selectTheme('Green')"></span></li>
                    <li :class="{'active':currentTheme=='Red'}"><span class="bx-Red" @click="selectTheme('Red')"></span></li>
                    <li :class="{'active':currentTheme=='Black'}"><span class="bx-Black" @click="selectTheme('Black')"></span></li>
                    <li :class="{'active':currentTheme=='BlackGold'}"><span class="bx-BlackGold" @click="selectTheme('BlackGold')"></span></li>
                    <li :class="{'active':currentTheme=='BlueLight'}"><span class="bx-BlueLight" @click="selectTheme('BlueLight')"></span></li>
                    <li :class="{'active':currentTheme=='DarkGray'}"><span class="bx-DarkGray" @click="selectTheme('DarkGray')"></span></li>
                    <li :class="{'active':currentTheme=='LightGray'}"><span class="bx-LightGray" @click="selectTheme('LightGray')"></span></li>
                </ul>
            </div>
        </div>
        <div class="wrap-menu-top" >
            <!--<AdvertiseGame v-if="isShowMenu"/>-->
            <div class="box-menu-top" >
                <!-- Log -->
                <div class="box-menu-top-info">
                    <div class="box-menu-top-info-left">
                        <div class="box-txt box-txt-home" style="display: flex">

                            <!-- show on embedMode -->
                            <!--                        <div v-if="embedMode" :class="{'change': isShowMenu}"-->
                            <!--                        class="menu-click" @click="toggleMenu"-->
                            <!--                        style="margin-left: 10px;">-->
                            <!--                            <div class="iconMenubar"></div>-->
                            <!--                        </div>-->
                            <!-- end show on embedMode -->
                            <!-- hide on embedMode -->
                            <!--                        <div v-else>-->
                            <router-link v-if="noBack === 'yes'" :to="{name: 'Index'}">
                                <span class="logo-chat">หน้าแรก</span>
                                <!--                    <span>CHAT</span>-->
                            </router-link>
                            <a v-else-if="backRouteName=='JILI'" href="#" @click="removeDivBeforeBackHome()"><span class="box-txt-back"></span></a>
                            <a v-else-if="backRouteName" href="#" @click="updateProviderServiceBeforeBack()"><span class="box-txt-back"></span></a>
                            <router-link v-else :to="{name: 'Index'}">
                                <span class="box-txt-back"></span>
                            </router-link>
                            <!--                        </div>-->
                            <!-- end hide on embedMode -->

                        </div>
                        <div :class="{'change': isShowMenu}" class="menu-click" @click="openMenu">
                            <div class="iconMenubar"></div>
                            <!--                            <i class="fas fa-bars"></i>-->
                            <!--                            <div class="bar1"></div>-->
                            <!--                            <div class="bar2"></div>-->
                            <!--                            <div class="bar3"></div>-->
                        </div>

                    </div>



                </div>

            </div>

            <!--//////////////////////////////////////////////////////////////////////////////////////////-->

            <div v-if="mode !== 'top-only'" class="MenuBar box-all-games">

                <!-- All Provider  ///////////////////////////////////////////////////////////////       -->
                <div class="listProvide-home" >
                    <div class="listProvide-home-group">
                        <h6>คาสิโนสด</h6>
                        <div class="listProvide-home-group-info">
                            <div v-show="checkGameProviderAvailable(provider.slug)" v-for="(provider, key) of casinoProviderList" :key="key" @click="openGameListModal(provider)" class="listTap-home">
                                <span><img :src="provider.image"/></span>
                                <p>{{provider.label}}</p>
                            </div>
                            <!-- <div class="listTap-home">
                                <span><img src="@/assets/images/slot/SAGaming-icon.png"/></span>
                                <p>SA</p>
                            </div>
                            <div class="listTap-home">
                                <span><img src="@/assets/images/slot/SexyBaccarat-icon.png"/></span>
                                <p>Sexy</p>
                            </div> -->
                        </div>
                    </div>
                    <div class="listProvide-home-group">
                        <h6>สล็อต</h6>
                        <div class="listProvide-home-group-info">
                            <div v-show="checkGameProviderAvailable(provider.slug)" v-for="(provider, key) of slotProviderList" :key="key" @click="openGameListModal(provider)" class="listTap-home">
                                <span><img :src="provider.image"/></span>
                                <p>{{provider.label}}</p>
                            </div>
                            <!-- <div class="listTap-home">
                                <span><img src="@/assets/images/slot/provider/PGSOFT.png"/></span>
                                <p>PGSOFT</p>
                            </div>
                            <div class="listTap-home">
                                <span><img src="@/assets/images/slot/provider/Blueprint.png"/></span>
                                <p>BluePrint</p>
                            </div>
                            <div class="listTap-home">
                                <span><img src="@/assets/images/slot/provider/MicroGaming.png"/></span>
                                <p>Micro</p>
                            </div>
                            <div class="listTap-home">
                                <span><img src="@/assets/images/slot/provider/PragmaticPlay.png"/></span>
                                <p>Pragmatic</p>
                            </div> -->
                        </div>
                    </div>
                </div>

                <!-- All mini Game  ///////////////////////////////////////////////////////////////       -->
                <div class="list-btn-gameslist-btn-games">
                    <carousel
                        :per-page="6.8"
                        :mouseDrag="true"
                        :navigationEnabled="true"
                        :paginationEnabled="false"
                        navigationNextLabel="❯"
                        navigationPrevLabel="❮"
                >
                    <!-- <slide>
                        <div class="icon-top-menu box-btn-baccarat box-btn-test"></div>
                    </slide> -->
                    <slide>
                        <div @click="showSelectTableModal(TABLE_TYPE_KEY.baccarat)"
                        class="icon-top-menu box-btn-baccarat"
                        :class="{'active': parseInt(TABLE_TYPE_KEY.baccarat) === parseInt(currentTableType)}">
                            <span>บาคาร่า</span>
                        </div>
                    </slide>
                    <!-- <slide>
                        <div @click="showSelectTableModal(TABLE_TYPE_KEY.baccarat3)"
                        class="icon-top-menu box-btn-baccarat3"
                        :class="{'active': parseInt(TABLE_TYPE_KEY.baccarat3) === parseInt(currentTableType)}">
                            <span>บาคาร่าวีไอพี</span>
                        </div>
                    </slide> -->
                    <slide>
                        <div @click="showSelectTableModal(TABLE_TYPE_KEY.sicbo)"
                        class="icon-top-menu box-btn-hilo"
                        :class="{'active': parseInt(TABLE_TYPE_KEY.sicbo) === parseInt(currentTableType)}">
                            <span>ไฮโล</span>
                        </div>
                    </slide>
                    <slide>
                        <div @click="showSelectTableModal(TABLE_TYPE_KEY.dragontiger)"
                        class="icon-top-menu box-btn-dragontiger"
                        :class="{'active': parseInt(TABLE_TYPE_KEY.dragontiger) === parseInt(currentTableType)}">
                            <span>สล็อต</span>
                        </div>
                    </slide>
                    <slide>
                        <div @click="showSelectTableModal(TABLE_TYPE_KEY.bean)"
                        class="icon-top-menu box-btn-fantan"
                        :class="{'active': parseInt(TABLE_TYPE_KEY.bean) === parseInt(currentTableType)}">
                            <span>สล็อต</span>
                        </div>
                    </slide>
                    <slide>
                        <div @click="openGame('unbox')" class="icon-top-menu box-btn-spin"><span>สปิน</span></div>
                    </slide>
                        <slide>
                            <div @click="openGame('lotto')" class="icon-top-menu box-btn-lotto">
                                <span>หวย</span>
                            </div>
                        </slide>
                    <slide>
                        <div @click="toggleFishing" class="icon-top-menu box-btn-animal"><span>ยิงปลา</span></div>
                    </slide>
                    <slide>
                        <div @click="openGame('football')" class="icon-top-menu box-btn-sport">
                            <span>ฟุตบอล</span>
                        </div>
                    </slide>

                    <slide>
                        <div @click="openGame('game-type')" class="icon-top-menu box-btn-casino">
                            <span>คาสิโน</span>
                        </div>
                    </slide>
                </carousel>
                </div>

                <!-- เกมส์-->
                <div class="box-dropdown-list" :class="{'box-dropdown-list-show': isShowMenu}">
                    <div class="box-dropdown-list-info">
                        <div class="box-dropdown-list-header">
                            <div class="title">
                                <h3>เกมส์</h3>
                                <div class="box-dropdown-list-header-link" v-if="false">
                                    <span  @click="openGame('football')" style="background-color: #0d00ff"><i class="far fa-futbol"></i><p>แทงบอล</p></span>
                                    <span @click="openGame('lotto')" style="background-color: red"><i class="fas fa-award"></i><p>หวย</p></span>
                                    <span @click="openGame('unbox')"><i class="fas fa-box-open"></i><p>เปิดกล่อง</p></span>
                                </div>
                            </div>
                            <div class="btn-close-popupAllgame" @click="ClosePopupGame()"></div>
                            <ul v-if="true" class="nav nav-tabs nav-tabs-list" id="myTab" role="tablist">

                                <li class="nav-item" role="presentation">
                                    <a class="nav-link active" id="home-tab" data-toggle="tab" href="#tab-games" role="tab"
                                       aria-controls="home" aria-selected="true">
                                        <img src="@/assets/images/icon_fire.png"/>
<!--                                        <img src="@/assets/images/fire.gif"/>-->
                                        <div class="nav-item-shadow"></div>
                                        <span>ยอดนิยม</span>
                                        <small>Popular</small>
                                    </a>
                                </li>
                                <li class="nav-item" role="presentation">
                                    <a class="nav-link" id="casino-tab" data-toggle="tab" href="#tab-casino" role="tab"
                                       aria-controls="profile" aria-selected="false">
                                        <img src="@/assets/images/icon_casino.png"/>
                                        <div class="nav-item-shadow"></div>
                                        <span>คาสิโน</span>
                                        <small>Casino</small>
                                    </a>
                                </li>
                                <li class="nav-item" role="presentation" >
                                    <a class="nav-link" id="recommend-tab" data-toggle="tab" href="#tab-recommends" role="tab"
                                       aria-controls="home" aria-selected="true">
                                        <img src="@/assets/images/icon_slot.png"/>
                                        <div class="nav-item-shadow"></div>
                                        <span>สล็อต</span>
                                        <small>Slot</small>
                                    </a>
                                </li>
                                <!-- <li v-if="isOnDev" class="nav-item" role="presentation" >
                                    <a class="nav-link" id="recommend-tab" data-toggle="tab" href="#tab-recommends" role="tab"
                                       aria-controls="home" aria-selected="true">
                                        <img src="@/assets/images/icon_slot.png"/>
                                        <div class="nav-item-shadow"></div>
                                        <span>สล็อต</span>
                                        <small>Slot</small>
                                    </a>
                                </li>
                                <li v-else class="nav-item" role="presentation">
                                    <a class="nav-link" id="contact-tab" data-toggle="tab" href="#tab-elec" role="tab"
                                       aria-controls="contact" aria-selected="false">
                                        <img src="@/assets/images/icon_slot.png"/>
                                        <div class="nav-item-shadow"></div>
                                        <span>สล็อต</span>
                                        <small>Slot</small>
                                    </a>
                                </li> -->
                                <li class="nav-item" role="presentation">
                                    <a class="nav-link" id="profile-tab" data-toggle="tab" href="#tab-profile" role="tab"
                                       aria-controls="contact" aria-selected="false">
                                        <img src="@/assets/images/icon_fishslot.png"/>
                                        <div class="nav-item-shadow"></div>
                                        <span>ยิงปลา</span>
                                        <small>Fishing</small>
                                    </a>
                                </li>
                                <li class="nav-item" role="presentation" v-if="service_list.includes('kp') || service_list.includes('queenmaker')">
                                    <a class="nav-link" id="p2p-tab" data-toggle="tab" href="#tab-p2p" role="tab"
                                       aria-controls="contact" aria-selected="false">P2P</a>
                                </li>
                            </ul>
                        </div>


                        <div class="tab-content tab-content-dropdown-list" id="myTabContent">

                            <div class="tab-pane fade show active" id="tab-games" role="tabpanel"
                                 aria-labelledby="home-tab">
                                <GameListPopular v-if="currentTab === 'home-tab' || tabMap['home-tab'].isAlreadyOpen"></GameListPopular>
                            </div>
                            <div class="tab-pane fade" id="tab-casino" role="tabpanel" aria-labelledby="profile-tab">
                                <GameListCasino v-if="currentTab === 'casino-tab' || tabMap['casino-tab'].isAlreadyOpen"></GameListCasino>
                            </div>
                            <div class="tab-pane fade" id="tab-recommends" role="tabpanel"
                                 aria-labelledby="tab-recommend">
                                <GameListRemommend v-if="currentTab === 'recommend-tab' || tabMap['recommend-tab'].isAlreadyOpen"></GameListRemommend>
                            </div>
                            <div class="tab-pane fade" id="tab-elec" role="tabpanel" aria-labelledby="contact-tab">
                                <GameList v-if="currentTab === 'contact-tab'"></GameList>
                            </div>
                            <div class="tab-pane fade" id="tab-profile" role="tabpanel" aria-labelledby="contact-tab">
                                <div v-if="is_member" class="profile-content hide">
                                    <div @click="goProfilePage">
                                        <ImageTitleDescription
                                                :image="user_profile.picture_url"
                                                :title="user_profile.display_name"
                                                :description="`${$options.filters.numberFormat(pes_profile.THB_free)} THB`"
                                        ></ImageTitleDescription>
                                    </div>
                                    <div class="to-account-mannage">
                                        <div>
                                            <router-link :to="{name: 'Account'}">
                                                จัดการบัญชี
                                            </router-link>
                                        </div>
                                        <div>
                                            <router-link :to="{name: 'DepositWithdraw'}">
                                                ฝาก-ถอน
                                            </router-link>
                                        </div>
                                        <div>
                                            <el-button type="text" @click="goSignOut">ลงชื่อออก</el-button>
                                        </div>
                                    </div>
                                </div>
                                <div v-else class="sign-up-content hide">
                                    <div class="p-3">
                                        <el-button
                                                round
                                                plain
                                                type="primary"
                                                icon="el-icon-position"
                                                @click="goSignUp">ล็อกอิน/สมัครสมาชิก
                                        </el-button>
                                    </div>
                                </div>
                                <GameListFish v-if="currentTab === 'profile-tab' || tabMap['profile-tab'].isAlreadyOpen"></GameListFish>
                            </div>
                            <div class="tab-pane fade" id="tab-p2p" role="tabpanel" aria-labelledby="contact-tab">
                                <GameListPP></GameListPP>
                            </div>
                        </div>
                    </div>
                </div>

            </div>


        </div>
        <div class="box-nav-menu" id="nav-menus">
            <div class="menu-list-top">
                            <span @click="lineAddFriend" class="box-lineAdd">
                                <div class="box-lineAdd-info">
                                    <i class="fab fa-line"></i>
                                </div>
                                 <p>เพิ่มเพื่อน</p>
                            </span>
                <div class="area-interface area-interface-profile">
                    <div @click="themeToggle" class="box-interface" :class="{'active': theme === 'dark'}">
                        <span class="dark-mode"></span>
                        <span class="light-mode"></span>
                    </div>
                </div>
                <!--                            <span style="display: none;"><div class="box-noti-top"><p>NEW</p></div><i class="fas fa-gift"></i></span>-->
                <!--                            <span style="display: none;"><i class="far fa-calendar-alt"></i></span>-->
                <span v-if="false" class="iconTopMenu icon-profile box-noti"></span>
                <span v-if="false" class="iconTopMenu icon-AccountPage"></span>
                <!--                    <span v-if="is_member" @click="toDepositWithdrawPage"><i class="fas fa-donate"></i></span>-->
                <span v-if="false" class="iconTopMenu icon-gift"></span>
                <span v-if="false" class="iconTopMenu box-noti"></span>
                <span v-if="false"><i class="fas fa-bell"></i></span>
                <span v-if="is_member" class="box-balance-total">
                    <div class="box-balance-total-info">
                        <span @click="refreshBalance" :class="{'on-refresh': isOnLoadUserProfile}"><i class="fas fa-redo"></i></span>
                        <strong @click="toDepositWithdrawPage"> {{pes_profile.THB_free | numberFormat(2)}}</strong>
                        <div class="icon-coin-bar"></div>
                    </div>
                    <div class="box-balance-total-info boxBalanceDiamond">
                        <strong>30,000</strong>
                        <div class="icon-coin-bar"></div>
                    </div>
                </span>

            </div>
            <div v-if="!is_member" class="btnLogin">
                <button @click="goSignUp" type="button" class="btn bg-gradient-btn">เข้าสู่ระบบ/สมัคร </button>
            </div>
            <!-- hide on embedMode -->
            <div v-if="is_member"  class="boxMenuTopRight" :class="{'hideDropdown':dropdown_hide}">
                <div class="bx-profile" @click="toggleListPlayer()">
                    <div class="bx-profile-noti"></div>
                    <img :src="`${user_profile.picture_url || '/images/avatar.png'}`"/>
                </div>
                <div tabindex="0" @focusout="handleListPlayerFocusOut"
                     ref="listPlayerWrapper" class="dropdown-content"
                     style="outline: none;">
                    <!--                                :class="level_not_claim>0?'box-noti':''"-->
                    <div v-if="is_member"  @click="toProfilePage" class="row-list-dropdown" >
                        <div class="row-list-dropdown-profile">
                            <img :src="`${user_profile.picture_url || '/images/avatar.png'}`"/>
                        </div>
                        <div class="row-list-dropdown-detail">
                            <h3>{{user_profile.display_name}} </h3>
                            <div class="row-list-dropdown-detail-info">
                                <div v-if="sp_info" :class="'box-level-incoming_msg bx-lv-'+(Math.floor(sp_info.level.sp_user_level/10))"><span>LV {{sp_info.level.sp_user_level}}</span></div>
                                <span>ดูโปรไฟล์ของคุณ</span>
                            </div>

                        </div>
                        <!--                                    <strong>0</strong>-->
                        <strong v-if="profile_not_claim_count>0">{{profile_not_claim_count}}</strong>
                    </div>
                    <div v-if="!user_profile.line_user_id" class="row-list-dropdown row-list-dropdown-2">
                                    <span @click="lineAddFriend"  class="box-lineAdd" style="display: flex">
                                        <div class="box-lineAdd-info">
                                            <i  class="fab fa-line"></i>
                                        </div>
                                        <p >เพิ่มเพื่อนใน Line</p>
                                    </span>
                    </div>
                    <div @click="toHome" class="row-list-dropdown">
                        <div class="row-list-left">
                            <i class="fas fa-home"></i>
                        </div>
                        <div class="row-list-right">
                            <span>หน้าแรก</span>
                        </div>
                    </div>
                    <!--                                toDepositWithdrawPage-->
                    <div @click="toDepositWithdrawPage" class="row-list-dropdown">
                        <div class="row-list-left">
                            <i class="fas fa-donate"></i>
                        </div>
                        <div class="row-list-right">
                            <span>ฝาก - ถอนเงิน</span>
                        </div>
                    </div>
                    <div  v-if="is_member" @click="toAccountPage"  class="row-list-dropdown">
                        <div class="row-list-left">
                            <i class="fas fa-wallet"></i>
                        </div>
                        <div class="row-list-right">
                            <span>บัญชีธนาคาร</span>
                        </div>
                    </div>
                    <div @click="toShopPage" class="row-list-dropdown">
                        <div class="row-list-left">
                            <i class="fas fa-store"></i>
                        </div>
                        <div class="row-list-right">
                            <span>โปรโมชั่น</span>
                        </div>
                    </div>
                    <div  id="daily_bonus_show_btn" @click="showDailyBonus()" class="row-list-dropdown rowListDropdownDailyBonus">
                        <div class="row-list-left">
                            <i class="far fa-calendar-alt"></i>
                        </div>
                        <div class="row-list-right">
                            <span>เช็กอินรายวัน</span>
                            <strong v-if="daily_bonus_cliam">{{daily_bonus_cliam}}</strong>
                        </div>
                    </div>
                    <div  id="daily_mission_show_btn" class="row-list-dropdown rowListDropdownDailyMission" @click="showDailyMission()">
                        <div class="row-list-left">
                            <i class="far fa-calendar-check"></i>
                        </div>
                        <div class="row-list-right">
                            <span>ภาระกิจรายวัน</span>
                            <strong v-if="daily_mission_show">{{daily_mission_show}}</strong>
                        </div>
                    </div>
                    <div v-if="false" class="row-list-dropdown" @click="OpenModalRefer()">
                        <div class="row-list-left">
                            <i class="fas fa-user-friends"></i>
                        </div>
                        <div class="row-list-right">
                            <span>ชวนเพื่อน</span>
                        </div>
                    </div>
                    <div @click="toStatement" class="row-list-dropdown">
                        <div class="row-list-left">
                            <i class="fas fa-flag"></i>
                        </div>
                        <div class="row-list-right">
                            <span>รายงานการเงิน</span>
                        </div>
                    </div>
                    <div class="row-list-dropdown row-list-dropdown-2" v-if="false">
                        <div class="row-list-left">
                            <i class="fas fa-adjust"></i>
                        </div>
                        <div class="row-list-right">
                            <div class="row-list-right-info">
                                <span>การแสดงผล</span>
                                <div class="area-interface">
                                    <div @click="themeToggle" class="box-interface" :class="{'active': theme === 'dark'}">
                                        <span class="dark-mode"></span>
                                        <span class="light-mode"></span>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div @click="goSignOut" class="row-list-dropdown row-list-dropdown-2">
                        <div class="row-list-left">
                            <i class="fas fa-sign-in-alt"></i>
                        </div>
                        <div class="row-list-right">
                            <span>ออกจากระบบ</span>
                        </div>
                    </div>
                </div>

                <!--                            <div v-if="!embedMode" :class="{'change': isShowMenu}" class="menu-click" @click="toggleMenu">-->
                <!--                                <div class="iconMenubar"></div>-->
                <!--                                &lt;!&ndash;                            <i class="fas fa-bars"></i>&ndash;&gt;-->
                <!--                                &lt;!&ndash;                            <div class="bar1"></div>&ndash;&gt;-->
                <!--                                &lt;!&ndash;                            <div class="bar2"></div>&ndash;&gt;-->
                <!--                                &lt;!&ndash;                            <div class="bar3"></div>&ndash;&gt;-->
                <!--                            </div>-->

            </div>

            <!-- end hide on embedMode -->

            <!-- <div class="icontab" style="display: none"></div> -->

        </div>
        <!--//////////////////////////////////////////////////////////////////////////////////////////-->
        <b-modal modal-class="editModal editModalCountdown" ref="delay_for_back"
                 :no-close-on-backdrop="true"
                 :no-close-on-esc="true"
                 :hide-footer="true"
                 hide-header
                 :hide-header-close="true"
                 title=""
        >
            <div class="style-modal modal-notify-text-center">
                <i class="fas fa-hourglass-half"></i>
<!--                <i class="fas fa-check-circle"></i>-->
                <p>กำลังกลับไปหน้าหลักภายใน </p>
                <h2>{{count_number}}<small>วินาที</small></h2>
            </div>
        </b-modal>

        <b-modal ref="go-to-add-bank-account"
                 modal-class="editModal" button-size="lg"
        hide-header
        centered
        ok-only 
        no-close-on-backdrop
        no-close-on-esc
        ok-title="ตกลง"
        @ok="() => {$router.push({name: 'Account', query: {to_create_account: 'yes',packet_id:new_member_packet.packet_id}})}">
            <div class="style-modal modal-notify-text-center">
                <i class="fas fa-check-circle" style="color: #0b6b2d; margin-top: 15px;"></i>
                <h5>เพิ่มเลขบัญชีเพื่อสามารถซื้อแพ็คเกจนี้ได้</h5>
            </div>
        </b-modal>


        <!--        Bonus รายวัน-->
        <div class="box-bonus-day boxDailyBonus" id="modal_daily_bonus" v-if="daily_bonus_obj" :class="modal_daily_bonus_hide" >
            <div class="content-bonus-day">
                <div class="title">
                    <div class="title-logo">
<!--                        <img src="@/assets/images/bg-topUp/icon-bonus.gif" alt="" >-->
                        <img src="@/assets/images/bg-topUp/icon-bonus2.png" alt="" >
                    </div>

                    <!--                    <h1>โบนัสรายวัน</h1>-->
                    <!--                    <p>ล็อกอินทุกวันเพื่อรับของรางวัล</p>-->
                    <div class="close-box-bonus" @click="closeDailyBonusModal()" bonus_claim_type="daily_bonus_obj"></div>
                </div>
                <div class="content-all">

                    <div class="content" style="padding-top: 0;">
                        <div class="contentTopBonusDay">
                            <div class="TopBonusDayLeft"><span></span></div>
                            <div class="TopBonusDayRight"><span></span></div>

                        </div>
                        <div class="contentTopBonusDayShadow"></div>
                        <div class="contentInfoBonus" >
                            <p class="content-alert">
                                ***ลูกค้าต้องฝากเงินรวม {{min_claim_bonus}} บาทขึ้นไปในวัน เพื่อรับรางวัลได้
                            </p>
                            <h4>** รับเครดิตรายวัน **</h4>
<!--                            get-bonus-->
                            <div class="box-bonusContent">
                                <div class="box-bonus1" :class="daily_bonus_obj.reward_item_list[0]._add_class">
                                    <span class="day-bonus">วันที่ {{daily_bonus_obj.reward_item_list[0].day}}</span>
                                    <div class="box-bonus1-content">
                                        <div class="box-bonus1-item">
                                            <img src="@/assets/images/coin-small-1.png" alt="" />
                                        </div>
                                        <div class="spin-win"></div>
                                        <div class="box-bonus1-value">{{daily_bonus_obj.reward_item_list[0].bonus_item_name}}</div>
                                        <div class="box-bonus1-btn claim_daily_bonus"
                                             @click="showClaimBonus(
                                     daily_bonus_obj.reward_item_list[0]._add_class,
                                     daily_bonus_obj.reward_item_list[0].bonus_id,
                                     daily_bonus_obj.reward_item_list[0].bonus_item_name,
                                     daily_bonus_obj.reward_item_list[0].bonus_type,
                                     daily_bonus_obj.reward_item_list[0].bonus_item_type_name,
                                     daily_bonus_obj.reward_item_list[0].bonus_item_value,
                                     '/images/coin-small-1.png',daily_bonus_obj.reward_item_list[0].ref1,
                                     )"
                                        >
                                            <button type="button" class="btn">{{daily_bonus_obj.reward_item_list[0].bonus_item_name}}</button>
                                        </div>
                                    </div>
                                </div>
                                <div class="box-bonus1" :class="daily_bonus_obj.reward_item_list[1]._add_class">
                                    <span class="day-bonus">วันที่ {{daily_bonus_obj.reward_item_list[1].day}}</span>
                                    <div class="box-bonus1-content">
                                        <div class="box-bonus1-item"><img src="@/assets/images/coin-small-1.png" alt="" /></div>
                                        <div class="spin-win"></div>
                                        <div class="box-bonus1-value">{{daily_bonus_obj.reward_item_list[1].bonus_item_name}}</div>
                                        <div class="box-bonus1-btn claim_daily_bonus"
                                             @click="showClaimBonus(
                                     daily_bonus_obj.reward_item_list[1]._add_class,
                                     daily_bonus_obj.reward_item_list[1].bonus_id,
                                     daily_bonus_obj.reward_item_list[1].bonus_item_name,
                                     daily_bonus_obj.reward_item_list[1].bonus_type,
                                     daily_bonus_obj.reward_item_list[1].bonus_item_type_name,
                                     daily_bonus_obj.reward_item_list[1].bonus_item_value,
                                     '/images/coin-small-1.png',daily_bonus_obj.reward_item_list[1].ref1,)"

                                        >
                                            <button type="button" class="btn">{{daily_bonus_obj.reward_item_list[1].bonus_item_name}}</button>
                                        </div>
                                    </div>
                                </div>
                                <div class="box-bonus1" :class="daily_bonus_obj.reward_item_list[2]._add_class">
                                    <span class="day-bonus">วันที่ {{daily_bonus_obj.reward_item_list[2].day}}</span>
                                    <div class="box-bonus1-content">
                                        <div class="box-bonus1-item"><img src="@/assets/images/coin-small-1.png" alt="" /></div>
                                        <div class="spin-win"></div>
                                        <div class="box-bonus1-value">{{daily_bonus_obj.reward_item_list[2].bonus_item_name}}</div>
                                        <div class="box-bonus1-btn claim_daily_bonus"
                                             @click="showClaimBonus(
                                     daily_bonus_obj.reward_item_list[2]._add_class,
                                     daily_bonus_obj.reward_item_list[2].bonus_id,
                                     daily_bonus_obj.reward_item_list[2].bonus_item_name,
                                     daily_bonus_obj.reward_item_list[2].bonus_type,
                                     daily_bonus_obj.reward_item_list[2].bonus_item_type_name,
                                     daily_bonus_obj.reward_item_list[2].bonus_item_value,
                                     '/images/coin-small-1.png',daily_bonus_obj.reward_item_list[2].ref1,)"
                                        >
                                            <button type="button" class="btn">{{daily_bonus_obj.reward_item_list[2].bonus_item_name}}</button>
                                        </div>
                                    </div>
                                </div>
                                <div class="box-bonus1" :class="daily_bonus_obj.reward_item_list[3]._add_class">
                                    <span class="day-bonus">วันที่ {{daily_bonus_obj.reward_item_list[3].day}}</span>
                                    <div class="box-bonus1-content">
                                        <div class="box-bonus1-item"><img src="@/assets/images/coin-small-1.png" alt="" /></div>
                                        <div class="spin-win"></div>
                                        <div class="box-bonus1-value">{{daily_bonus_obj.reward_item_list[3].bonus_item_name}}</div>
                                        <div class="box-bonus1-btn claim_daily_bonus"
                                             @click="showClaimBonus(
                                     daily_bonus_obj.reward_item_list[3]._add_class,
                                     daily_bonus_obj.reward_item_list[3].bonus_id,
                                     daily_bonus_obj.reward_item_list[3].bonus_item_name,
                                     daily_bonus_obj.reward_item_list[3].bonus_type,
                                     daily_bonus_obj.reward_item_list[3].bonus_item_type_name,
                                     daily_bonus_obj.reward_item_list[3].bonus_item_value,
                                     '/images/coin-small-1.png',daily_bonus_obj.reward_item_list[3].ref1,)"
                                        >
                                            <button type="button" class="btn"></button>
                                        </div>
                                    </div>
                                </div>
                                <div class="box-bonus1" :class="daily_bonus_obj.reward_item_list[4]._add_class">
                                    <span class="day-bonus">วันที่ {{daily_bonus_obj.reward_item_list[4].day}}</span>
                                    <div class="box-bonus1-content">
                                        <div class="box-bonus1-item"><img src="@/assets/images/coin-small-1.png" alt="" /></div>
                                        <div class="spin-win"></div>
                                        <div class="box-bonus1-value">{{daily_bonus_obj.reward_item_list[4].bonus_item_name}}</div>
                                        <div class="box-bonus1-btn claim_daily_bonus"
                                             @click="showClaimBonus(
                                     daily_bonus_obj.reward_item_list[4]._add_class,
                                     daily_bonus_obj.reward_item_list[4].bonus_id,
                                     daily_bonus_obj.reward_item_list[4].bonus_item_name,
                                     daily_bonus_obj.reward_item_list[4].bonus_type,
                                     daily_bonus_obj.reward_item_list[4].bonus_item_type_name,
                                     daily_bonus_obj.reward_item_list[4].bonus_item_value,
                                     '/images/coin-small-1.png',daily_bonus_obj.reward_item_list[4].ref1,)"
                                        >
                                            <button type="button" class="btn">{{daily_bonus_obj.reward_item_list[4].bonus_item_name}}</button>
                                        </div>
                                    </div>
                                </div>
                                <div class="box-bonus1" :class="daily_bonus_obj.reward_item_list[5]._add_class">
                                    <span class="day-bonus">วันที่ {{daily_bonus_obj.reward_item_list[5].day}}</span>
                                    <div class="box-bonus1-content">
                                        <div class="box-bonus1-item"><img src="@/assets/images/coin-small-1.png" alt="" /></div>
                                        <div class="spin-win"></div>
                                        <div class="box-bonus1-value">{{daily_bonus_obj.reward_item_list[5].bonus_item_name}}</div>
                                        <div class="box-bonus1-btn claim_daily_bonus"
                                             @click="showClaimBonus(
                                     daily_bonus_obj.reward_item_list[5]._add_class,
                                     daily_bonus_obj.reward_item_list[5].bonus_id,
                                     daily_bonus_obj.reward_item_list[5].bonus_item_name,
                                     daily_bonus_obj.reward_item_list[5].bonus_type,
                                     daily_bonus_obj.reward_item_list[5].bonus_item_type_name,
                                     daily_bonus_obj.reward_item_list[5].bonus_item_value,
                                     '/images/coin-small-1.png',daily_bonus_obj.reward_item_list[5].ref1,)"
                                        >
                                            <button type="button" class="btn">{{daily_bonus_obj.reward_item_list[5].bonus_item_name}}</button>
                                        </div>
                                    </div>
                                </div>
                                <div class="box-bonus1" :class="daily_bonus_obj.reward_item_list[6]._add_class">
                                    <span class="day-bonus">วันที่ {{daily_bonus_obj.reward_item_list[6].day}}</span>
                                    <div class="box-bonus1-content">
                                        <div class="box-bonus1-item" :class="{'box-bonus1-item-freeSpin':daily_bonus_obj.reward_item_list[6].bonus_type==1}">
                                            <img :src="daily_bonus_obj.reward_item_list[6].bonus_type==1? daily_bonus_obj.reward_item_list[6].bonus_item_img:'/images/coin-small-1.png'" alt="" />
                                        </div>
                                        <div class="spin-win"></div>
                                        <div class="box-bonus1-value">{{daily_bonus_obj.reward_item_list[6].bonus_item_name}}</div>
                                        <div class="box-bonus1-btn claim_daily_bonus"
                                             @click="showClaimBonus(
                                     daily_bonus_obj.reward_item_list[6]._add_class,
                                     daily_bonus_obj.reward_item_list[6].bonus_id,
                                     daily_bonus_obj.reward_item_list[6].bonus_item_name,
                                     daily_bonus_obj.reward_item_list[6].bonus_type,
                                     daily_bonus_obj.reward_item_list[6].bonus_item_type_name,
                                     daily_bonus_obj.reward_item_list[6].bonus_item_value,
                                     daily_bonus_obj.reward_item_list[6].bonus_type==1? daily_bonus_obj.reward_item_list[6].bonus_item_img:'/images/coin-small-1.png',daily_bonus_obj.reward_item_list[6].ref1,)"
                                        >
                                            <button type="button" class="btn">{{daily_bonus_obj.reward_item_list[6].bonus_item_name}}</button>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="content-bg"></div>
                </div>
                <div class="spin-win spin-win-bg"></div>
            </div>
            <div class="overlay-box"></div>
        </div>

        <!--        ภาระกิจ รายวัน-->
        <div class="box-bonus-day boxDailyMission" id="modal_daily_mission" :class="modal_mission_bonus_hide" v-if="daily_mission_obj">
            <div class="content-bonus-day content-mission-day">
                <div class="title">
                    <div class="title-logo">
                        <img src="@/assets/images/bg-topUp/icon-bonus-mission.png" alt="" >
                    </div>
                    <!--                    <h1>ภาระกิจรายวัน</h1>-->
                    <!--                    <p>ล็อกอินทุกวันเพื่อรับของรางวัล</p>-->
                    <div class="close-box-bonus" :bonus_claim_type="'daily_mission_obj'" @click="closeModalDailyMission()"></div>
                </div>
                <div class="content-all">
                    <div class="contentTopOver" >
                        
                        <div class="btn-clear-all">
                            <span>เคลียรกิจกรรมทั้งหมดรับเพิ่ม {{parseInt(daily_mission_obj.sp_daily_mission_bonus_item[daily_mission_obj.sp_daily_mission_bonus_config['all']].bonus_item_value)}} บาท</span>
                            <div class="btn-clear-all-info">
                                <div v-if="daily_mission_obj.sp_daily_mission_bonus['all'].status==2" class="btn-cleared" >
                                    <span><i class="far fa-check-circle"></i> ภาระกิจรายวันเสร็จสิ้น</span>
                                </div>
                                <button 
                                v-else-if="daily_mission_obj.sp_daily_mission_bonus['all'].status==1" 
                                                        :_add_class="'claim_daily_mission'"
                                                        :bonus_id="'all'"
                                                        :bonus_item_name="daily_mission_obj.sp_daily_mission_bonus_item[daily_mission_obj.sp_daily_mission_bonus_config['all']].bonus_item_name"
                                                        :bonus_item_type_name="daily_mission_obj.sp_daily_mission_bonus_item[daily_mission_obj.sp_daily_mission_bonus_config['all']].bonus_item_type_name"
                                                        :bonus_type_name ="daily_mission_obj.sp_daily_mission_bonus_item[daily_mission_obj.sp_daily_mission_bonus_config['all']].bonus_type_name"
                                                        :bonus_type ="daily_mission_obj.sp_daily_mission_bonus_item[daily_mission_obj.sp_daily_mission_bonus_config['all']].bonus_type"
                                                        :bonus_item_value ="daily_mission_obj.sp_daily_mission_bonus_item[daily_mission_obj.sp_daily_mission_bonus_config['all']].bonus_item_value"
                                                        :bonus_item_img ="daily_mission_obj.sp_daily_mission_bonus_item[daily_mission_obj.sp_daily_mission_bonus_config['all']].bonus_item_img"
                                                        @click="showClaimBonus('claim_daily_mission',
                                                    'all',
                                                    daily_mission_obj.sp_daily_mission_bonus_item[daily_mission_obj.sp_daily_mission_bonus_config['all']].bonus_item_name,
                                                    daily_mission_obj.sp_daily_mission_bonus_item[daily_mission_obj.sp_daily_mission_bonus_config['all']].bonus_type,
                                                    daily_mission_obj.sp_daily_mission_bonus_item[daily_mission_obj.sp_daily_mission_bonus_config['all']].bonus_item_type_name,
                                                    daily_mission_obj.sp_daily_mission_bonus_item[daily_mission_obj.sp_daily_mission_bonus_config['all']].bonus_item_value,
                                                    daily_mission_obj.sp_daily_mission_bonus_item[daily_mission_obj.sp_daily_mission_bonus_config['all']].bonus_item_img)"
                                type="button" class="btn claim_daily_mission active" >฿{{parseInt(daily_mission_obj.sp_daily_mission_bonus_item[daily_mission_obj.sp_daily_mission_bonus_config['all']].bonus_item_value)}}</button>
                                <button v-else type="button" class="btn ">฿{{parseInt(daily_mission_obj.sp_daily_mission_bonus_item[daily_mission_obj.sp_daily_mission_bonus_config['all']].bonus_item_value)}}</button>
                                
                            </div>
                          </div>
                    </div>
                    <div class="content">
                        <p class="content-alert" style="">
                            ***ลูกค้าต้องฝากเงินรวม {{min_claim_bonus}} บาทขึ้นไปในวัน เพื่อรับรางวัลได้
                        </p>
                        <div class="boxDailyMissionContent">

                            <!--                        <div class="box-nav-mission">-->
                            <!--                            <div class="nav-mission active">ภาระกิจรายวัน</div>-->
                            <!--                            <div class="nav-mission">ภาระกิจ อีเว้น</div>-->
                            <!--                            <div class="nav-mission">ภาระกิจ เลเวล</div>-->
                            <!--                        </div>-->
<!--                            <div class="box-top-mission">-->
<!--                                <div class="time-mission"><span>{{sp_daily_mission_bonus_date}}</span></div>-->
<!--                            </div>-->
                            <div class="box-content-mission">
                                <div v-for="(mission_id,key) of daily_mission_obj.sp_daily_mission_bonus_config" :key="key" class="box-list-mission" :style="{display:key=='all'?'none':'block'}">
                                    <ul>
                                        <li>
                                            <div class="iconGame-List">
                                                <img v-if="key==1" src="@/assets/images/icon/set-icon/icon-calenda.png" alt="" />
                                                <img v-else-if="key==2" src="@/assets/images/icon/set-icon/icon-spin.png" alt="" />
                                                <img v-else-if="key==3" src="@/assets/images/icon/set-icon/icon-baccarat-2.png" alt="" />
                                                <img v-else-if="key==4" src="@/assets/images/icon/set-icon/icon-hilo-2.png" alt="" />
                                                <img v-else-if="key==5" src="@/assets/images/icon/set-icon/icon-fantan.png" alt="" />
                                                <img v-else-if="key==6" src="@/assets/images/icon/set-icon/icon-tiger-dagon.png" alt="" />
                                                <img v-else-if="key!='all'" src="@/assets/images/icon/set-icon/icon-casino.png" alt="" />
                                                <img v-else  src="@/assets/images/icon/set-icon/icon-calenda.png" alt="" />
                                            </div>
                                        </li>
                                        <li>
                                            <div class="box-detail">
                                                <strong v-if="key==1">{{daily_mission_obj.mission_list[key]  +' ('+ (daily_mission_obj.sp_daily_mission_bonus[key].value> min_claim_bonus ? daily_mission_obj.sp_daily_mission_target_mission_list[key]:daily_mission_obj.sp_daily_mission_bonus[key].value)+'/'+min_claim_bonus+')'}}</strong>
                                                <strong v-else>{{daily_mission_obj.mission_list[key] +' ('+ (daily_mission_obj.sp_daily_mission_bonus[key].value>daily_mission_obj.sp_daily_mission_target_mission_list[key]?daily_mission_obj.sp_daily_mission_target_mission_list[key]:daily_mission_obj.sp_daily_mission_bonus[key].value)+'/'+daily_mission_obj.sp_daily_mission_target_mission_list[key]+')'}}</strong>
                                                <p>
                                                    เครดิตเล่นเกม
                                                    <span>{{parseInt(daily_mission_obj.sp_daily_mission_bonus_item[mission_id].bonus_item_value)}} บาท</span>
                                                </p>
<!--                                                <span>เครดิตเล่นเกม {{daily_mission_obj.sp_daily_mission_bonus_item[mission_id].bonus_item_description}}</span>-->
                                            </div>

                                        </li>
                                        <li>
                                            <div class="btn-right">
                                                <button v-if="daily_mission_obj.sp_daily_mission_bonus[key].status==2" type="button" class="btn btn-get" ></button>
                                                <button v-else-if="daily_mission_obj.sp_daily_mission_bonus[key].status==1" type="button" class="btn  claim_daily_mission"
                                                        :_add_class="'claim_daily_mission'"
                                                        :bonus_id="key"
                                                        :bonus_item_name="daily_mission_obj.sp_daily_mission_bonus_item[mission_id].bonus_item_name"
                                                        :bonus_item_type_name="daily_mission_obj.sp_daily_mission_bonus_item[mission_id].bonus_item_type_name"
                                                        :bonus_type_name ="daily_mission_obj.sp_daily_mission_bonus_item[mission_id].bonus_type_name"
                                                        :bonus_type ="daily_mission_obj.sp_daily_mission_bonus_item[mission_id].bonus_type"
                                                        :bonus_item_value ="daily_mission_obj.sp_daily_mission_bonus_item[mission_id].bonus_item_value"
                                                        :bonus_item_img ="daily_mission_obj.sp_daily_mission_bonus_item[mission_id].bonus_item_img"
                                                        @click="showClaimBonus('claim_daily_mission',
                                                    key,
                                                    daily_mission_obj.sp_daily_mission_bonus_item[mission_id].bonus_item_name,
                                                    daily_mission_obj.sp_daily_mission_bonus_item[mission_id].bonus_type,
                                                    daily_mission_obj.sp_daily_mission_bonus_item[mission_id].bonus_item_type_name,
                                                    daily_mission_obj.sp_daily_mission_bonus_item[mission_id].bonus_item_value,
                                                    daily_mission_obj.sp_daily_mission_bonus_item[mission_id].bonus_item_img)" ></button>
                                                <button v-else-if="daily_mission_obj.sp_daily_mission_bonus[key].status==0" type="button" class="btn btn-play-game" @click="gotToLink(key);"></button>
                                            </div>
                                        </li>
                                    </ul>
                                    <div class="progress">
                                        <div v-if="key==1" class="progress-bar progress-bar-striped active" role="progressbar" :aria-valuenow="(daily_mission_obj.sp_daily_mission_bonus[key].value>min_claim_bonus  ? daily_mission_obj.sp_daily_mission_target_mission_list[key]:daily_mission_obj.sp_daily_mission_bonus[key].value)/ min_claim_bonus" aria-valuemin="0" aria-valuemax="100"  :style="{width:((daily_mission_obj.sp_daily_mission_bonus[key].value> min_claim_bonus ?daily_mission_obj.sp_daily_mission_target_mission_list[key]:daily_mission_obj.sp_daily_mission_bonus[key].value)/ min_claim_bonus *100)+'%'}">
                                            <span>{{(daily_mission_obj.sp_daily_mission_bonus[key].value> min_claim_bonus ?daily_mission_obj.sp_daily_mission_target_mission_list[key]:daily_mission_obj.sp_daily_mission_bonus[key].value)+'/'+min_claim_bonus}}</span>
                                        </div>
                                        <div v-else class="progress-bar progress-bar-striped active" role="progressbar" :aria-valuenow="(daily_mission_obj.sp_daily_mission_bonus[key].value>daily_mission_obj.sp_daily_mission_target_mission_list[key]?daily_mission_obj.sp_daily_mission_target_mission_list[key]:daily_mission_obj.sp_daily_mission_bonus[key].value)/daily_mission_obj.sp_daily_mission_target_mission_list[key]" aria-valuemin="0" aria-valuemax="100"  :style="{width:((daily_mission_obj.sp_daily_mission_bonus[key].value>daily_mission_obj.sp_daily_mission_target_mission_list[key]?daily_mission_obj.sp_daily_mission_target_mission_list[key]:daily_mission_obj.sp_daily_mission_bonus[key].value)/daily_mission_obj.sp_daily_mission_target_mission_list[key]*100)+'%'}">
                                            <span>{{(daily_mission_obj.sp_daily_mission_bonus[key].value>daily_mission_obj.sp_daily_mission_target_mission_list[key]?daily_mission_obj.sp_daily_mission_target_mission_list[key]:daily_mission_obj.sp_daily_mission_bonus[key].value)+'/'+daily_mission_obj.sp_daily_mission_target_mission_list[key]}}</span>
                                        </div>
                                    </div>
                                </div>
                                <div v-if="false">
                                    <div class="box-list-mission">
                                        <ul>
                                            <li>
                                                <div class="iconGame-List"><img src="@/assets/images/icon/set-icon/icon-casino.png" alt="" /></div>
                                            </li>
                                            <li>
                                                <div class="box-detail">
                                                    <strong>เล่นเกม บาคาร่า</strong>
                                                    <span>฿10</span>
                                                </div>

                                            </li>
                                            <li>
                                                <div class="btn-right">
                                                    <button type="button" class="btn bg-gradient-btn"></button>
                                                </div>
                                            </li>
                                        </ul>
                                    </div>
                                    <div class="box-list-mission">
                                        <ul>
                                            <li>
                                                <div class="iconGame-List"><img src="@/assets/images/icon/set-icon/icon-calenda.png" alt="" /></div>
                                            </li>
                                            <li>
                                                <div class="box-detail">
                                                    <strong>เข้าสู่ระบบ</strong>
                                                    <span>฿10</span>
                                                </div>

                                            </li>
                                            <li>
                                                <div class="btn-right">
                                                    <button type="button" class="btn bg-gradient-btn btn-get"></button>
                                                </div>
                                            </li>
                                        </ul>
                                    </div>
                                    <div class="box-list-mission">
                                        <ul>
                                            <li>
                                                <div class="iconGame-List"><img src="@/assets/images/icon/set-icon/icon-casino.png" alt="" /></div>
                                            </li>
                                            <li>
                                                <div class="box-detail">
                                                    <strong>เล่นเกม บาคาร่า</strong>
                                                    <span>฿10</span>
                                                </div>

                                            </li>
                                            <li>
                                                <div class="btn-right">
                                                    <button type="button" class="btn bg-gradient-btn"></button>
                                                </div>
                                            </li>
                                        </ul>
                                    </div>
                                    <div class="box-list-mission">
                                        <ul>
                                            <li>
                                                <div class="iconGame-List"><img src="@/assets/images/icon/set-icon/icon-calenda.png" alt="" /></div>
                                            </li>
                                            <li>
                                                <div class="box-detail">
                                                    <strong>เข้าสู่ระบบ</strong>
                                                    <span>฿10</span>
                                                </div>

                                            </li>
                                            <li>
                                                <div class="btn-right">
                                                    <button type="button" class="btn bg-gradient-btn btn-get"></button>
                                                </div>
                                            </li>
                                        </ul>
                                    </div>
                                    <div class="box-list-mission">
                                        <ul>
                                            <li>
                                                <div class="iconGame-List"><img src="@/assets/images/icon/set-icon/icon-casino.png" alt="" /></div>
                                            </li>
                                            <li>
                                                <div class="box-detail">
                                                    <strong>เล่นเกม บาคาร่า</strong>
                                                    <span>฿10</span>
                                                </div>

                                            </li>
                                            <li>
                                                <div class="btn-right">
                                                    <button type="button" class="btn bg-gradient-btn"></button>
                                                </div>
                                            </li>
                                        </ul>
                                    </div>
                                    <div class="box-list-mission">
                                        <ul>
                                            <li>
                                                <div class="iconGame-List"><img src="@/assets/images/icon/set-icon/icon-calenda.png" alt="" /></div>
                                            </li>
                                            <li>
                                                <div class="box-detail">
                                                    <strong>เข้าสู่ระบบ</strong>
                                                    <span>฿10</span>
                                                </div>

                                            </li>
                                            <li>
                                                <div class="btn-right">
                                                    <button type="button" class="btn bg-gradient-btn btn-get"></button>
                                                </div>
                                            </li>
                                        </ul>
                                    </div>
                                    <div class="box-list-mission">
                                        <ul>
                                            <li>
                                                <div class="iconGame-List"><img src="@/assets/images/icon/set-icon/icon-casino.png" alt="" /></div>
                                            </li>
                                            <li>
                                                <div class="box-detail">
                                                    <strong>เล่นเกม บาคาร่า</strong>
                                                    <span>฿10</span>
                                                </div>

                                            </li>
                                            <li>
                                                <div class="btn-right">
                                                    <button type="button" class="btn bg-gradient-btn"></button>
                                                </div>
                                            </li>
                                        </ul>
                                    </div>
                                    <div class="box-list-mission">
                                        <ul>
                                            <li>
                                                <div class="iconGame-List">
                                                    <img  src="@/assets/images/icon/set-icon/icon-calenda.png"  alt="" />
                                                </div>
                                            </li>
                                            <li>
                                                <div class="box-detail">
                                                    <strong>เข้าสู่ระบบ</strong>
                                                    <span>฿10</span>
                                                </div>

                                            </li>
                                            <li>
                                                <div class="btn-right">
                                                    <button type="button" class="btn bg-gradient-btn btn-get"></button>
                                                </div>
                                            </li>
                                        </ul>
                                    </div>
                                    <div class="box-list-mission">
                                        <ul>
                                            <li>
                                                <div class="iconGame-List"><img src="@/assets/images/icon/set-icon/icon-casino.png" alt="" /></div>
                                            </li>
                                            <li>
                                                <div class="box-detail">
                                                    <strong>เล่นเกม บาคาร่า</strong>
                                                    <span>฿10</span>
                                                </div>

                                            </li>
                                            <li>
                                                <div class="btn-right">
                                                    <button type="button" class="btn bg-gradient-btn"></button>
                                                </div>
                                            </li>
                                        </ul>
                                    </div>
                                </div>
                            </div>
                        </div>

                    </div>
                    <div class="content-bg"></div>
                </div>
            </div>
            <div class="overlay-box"></div>
        </div>

        <!--        Modal แจ้งเตือน-->
        <div class="box-alert" id="modal_alert" v-if="alert_obj" :class="alert_obj_show">
            <div class="box-alert-content">
                <div class="content-all">
                    <div class="title">
                        <h1>{{alert_obj.title}}</h1>
                    </div>
                    <div class="content-allInfo">
                        <div class="content">
                            <span>{{alert_obj.content}}</span>
                        </div>
                        <div class="footer">
                            <button v-if="alert_obj.close_btn" type="button" class="btn btn-default-outline" id="close_modal_alert_btn" >Close
                            </button>
                            <button v-if="alert_obj.ok_btn" type="button" class="btn" @click="closeModalAlert()">ตกลง</button>
                        </div>
                    </div>

                </div>

            </div>
            <div class="overlay-box"></div>
        </div>
        
        <!--        สิทธิพิเศษ-->
        <div class="box-bonus-day box-bonus-user" v-if="alert_obj3" :class="alert_obj3_show">
            <div class="content-bonus-day content-mission-day">
                <div class="title">
                    <div class="title-logo">
                        <img class="title-logo-img" src="@/assets/images/bg-topUp/topic-bg-05.png" alt="" >
                        <img class="bg-spin" src="@/assets/images/spint-gold.png"  />
                    </div>
                    <div class="close-box-bonus"  @click="closeModalAlert()"></div>
                </div>
                <div class="content-all">
                    <div class="content">
                        <div class="contentInfo">
                            <p>ลูกค้าต้องฝากเงินรวม {{min_claim_bonus}} บาทขึ้นไปในวัน เพื่อรับรางวัลได้</p>
                            <!--                        <h2>โปรโมชั่นฝากเงินครั้งแรก</h2>-->
                            <div class="contentRowBonus">
                                <div class="listRowBonus">
                                    <div class="listRowBonusInfo">
                                        <div class="topic">
                                            <img src="@/assets/images/icon/set-icon/icon-calenda.png">
                                        </div>
                                        <strong><span>{{mission_bonus_list[1]}}</span><small>฿</small></strong>
                                        <div class="TagTopic">ฝากรวม</div>
                                        <div class="buttonBonus" :class="{ 'activeGetBonus': sp_info.daily_mission.sp_daily_mission_bonus[1].status>0  }" @click="toDepositWithdrawPage(sp_info.daily_mission.sp_daily_mission_bonus[1].status>0)">
                                            <span>ฝาก ฿{{min_claim_bonus}}</span>
                                        </div>
                                    </div>
                                </div>
                                <div class="listRowBonus">
                                    <div class="listRowBonusInfo">
                                        <div class="topic">
                                            <img src="@/assets/images/icon/set-icon/icon-spin3.png">
                                        </div>
                                        <strong><span>{{mission_bonus_list[2]}}</span><small>฿</small></strong>
                                        <div class="TagTopic">เปิดกล่องสุ่ม</div>
                                        <div class="buttonBonus buttonBonusFree" :class="{ 'activeGetBonus': sp_info.daily_mission.sp_daily_mission_bonus[2].status>0  }">
                                            <span>ฟรี</span>
                                        </div>
                                    </div>
                                </div>
                                <div class="listRowBonus">
                                    <div class="listRowBonusInfo">
                                        <div class="topic">
                                            <img src="@/assets/images/icon/set-icon/icon-baccarat-21.png">
                                        </div>
                                        <strong><span>{{mission_bonus_list[3]}}</span><small>฿</small></strong>
                                        <div class="TagTopic">เล่นบาคาร่า</div>
                                        <div class="buttonBonus buttonBonusFree" :class="{ 'activeGetBonus': sp_info.daily_mission.sp_daily_mission_bonus[3].status>0  }">
                                            <span>ฟรี</span>
                                        </div>
                                    </div>
                                </div>
                                <div class="listRowBonus">
                                    <div class="listRowBonusInfo">
                                        <div class="topic">
                                            <img src="@/assets/images/icon/set-icon/icon-hilo-21.png">
                                        </div>
                                        <strong><span>{{mission_bonus_list[4]}}</span><small>฿</small></strong>
                                        <div class="TagTopic">เล่นไฮโล</div>
                                        <div class="buttonBonus buttonBonusFree" :class="{ 'activeGetBonus': sp_info.daily_mission.sp_daily_mission_bonus[4].status>0  }">
                                            <span>ฟรี</span>
                                        </div>
                                    </div>
                                </div>
                                <div class="listRowBonus">
                                    <div class="listRowBonusInfo">
                                        <div class="topic">
                                            <img src="@/assets/images/icon/set-icon/icon-fantan2.png">
                                        </div>
                                        <strong><span>{{mission_bonus_list[5]}}</span><small>฿</small></strong>
                                        <div class="TagTopic">เล่นถั่วหยิบ</div>
                                        <div class="buttonBonus buttonBonusFree" :class="{ 'activeGetBonus': sp_info.daily_mission.sp_daily_mission_bonus[5].status>0  }">
                                            <span>ฟรี</span>
                                        </div>
                                    </div>
                                </div>
                                <div class="listRowBonus">
                                    <div class="listRowBonusInfo">
                                        <div class="topic">
                                            <img src="@/assets/images/icon/set-icon/icon-tiger-dagon2.png">
                                        </div>
                                        <strong><span>{{mission_bonus_list[6]}}</span><small>฿</small></strong>
                                        <div class="TagTopic">เล่นดราก้อนไทเกอร์</div>
                                        <div class="buttonBonus buttonBonusFree" :class="{ 'activeGetBonus': sp_info.daily_mission.sp_daily_mission_bonus[6].status>0  }">
                                            <span>ฟรี</span>
                                        </div>
                                    </div>
                                </div>
                                <div class="listRowBonus">
                                    <div class="listRowBonusInfo">
                                        <div class="topic">
                                            <img src="@/assets/images/coin-big-1.png">
                                        </div>
                                        <strong><span>{{mission_bonus_list['all']}}</span><small>฿</small></strong>
                                        <div class="TagTopic">เครียร์ทั้งหมด</div>
                                        <div class="buttonBonus buttonBonusFree" :class="{ 'activeGetBonus': sp_info.daily_mission.sp_daily_mission_bonus['all'].status>0  }">
                                            <span>ฟรี</span>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <ul style="display: none">
                                <!--                            <li><img  src="@/assets/images/icon/set-icon/icon-calenda.png" alt="" />ภาระกิจรายวัน สูงสุด 60 บาท</li>-->
                                <li>ภาระกิจรายวัน สูงสุด 60 บาท</li>
                                <li>โบนัสรายวัน สูงสุด 720 บาท</li>
                                <li>รับดอกเบี้ย ทุก8ชั่วโมง </li>
                                <li>รับเครดิตคืน เมื่อเล่นเสีย</li>
                                <li>รับเครดิตเพิ่มจากการ Lv.</li>
                                <li>มีสร้างรายได้จาการแนะนำเพื่อน</li>
                            </ul>
                            <div class="footer footer-center">
                                <button type="button" class="btn bg-gradient-btn" :class="{ 'activeGetBonus': sp_info.daily_mission.sp_daily_mission_bonus[1].status>0  }" @click="toDepositWithdrawPage(sp_info.daily_mission.sp_daily_mission_bonus[1].status>0)">ฝากเงิน {{min_claim_bonus}}฿ ฟรี {{mission_bonus_list[1]}}฿</button>
                            </div>
                        </div>

                    </div>
                    <div class="content-bg"></div>
                </div>
            </div>
            <div class="overlay-box"></div>
        </div>
                <!--        Modal แจ้งเตือน-->
        <div class="box-alert" id="modal_alert" v-if="alert_obj2" :class="alert_obj2_show">
<!--        <div class="box-alert" style="display: block">-->
            <div class="content-bonus-day content-mission-day">
                <div class="content-all">

                    <div class="content">

                                                    <h2>{{alert_obj2.title}}</h2>
<!--                            <h2>แจ้งเตือน</h2>-->

                        <p>{{alert_obj2.content}}</p>
<!--                        <p>Service is offline</p>-->
                                            <div class="footer">
                                                <button v-if="alert_obj2.close_btn" type="button" class="btn btn-default-outline" @click="closeModalAlert()" >Close</button>
                                                <button v-if="alert_obj2.ok_btn" type="button" class="btn" @click="closeModalAlert()">OK</button>
                                            </div>
<!--                        <div class="footer">-->
<!--                            <button type="button" class="btn">Close</button>-->
<!--                            <button type="button" class="btn bg-gradient-btn">OK</button>-->
<!--                        </div>-->
                    </div>
                    <div class="content-bg"></div>

                </div>

            </div>
            <div class="overlay-box"></div>
        </div>
        <!--        ได้รับของรางวัล-->
        <div class="box-award boxAwardDesign" v-if="claim_bonus_obj">
            <div class="box-alert-content">
                <div class="boxAwardDesignFlare"></div>
                <div class="title title-center">
                    <img  src="@/assets/images/bg-topUp/icon-getAward.png" alt="" >
<!--                    <h1>ได้รับของรางวัล</h1>-->
                </div>
                <div class="content-all">

                    <div class="contentTopOver">
                        <div class="footer footer-center">
                            <button type="button" class="btn cliam_bonus_btn" @click="claimBonus(
                                claim_bonus_obj.bonus_claim_type,claim_bonus_obj.bonus_type,claim_bonus_obj.bonus_item_value,claim_bonus_obj.bonus_id,claim_bonus_obj.ref1)"
                            >รับของรางวัล</button>
                        </div>
                    </div>
                    <div class="content">
                        <div class="row-box-award">
                            <ul>
                                <li>
                                    <div class="item-award">
                                        <div class="set-coin"><img :src="claim_bonus_obj.bonus_item_img" alt="" /></div>
                                        <span class="Value-item">{{claim_bonus_obj.bonus_item_name}}</span>
                                    </div>
                                    <h5>{{claim_bonus_obj.bonus_item_type_name}}</h5>
                                </li>
                                <li v-if="false">
                                    <div class="item-award" >
                                        <div class="set-coin"><img src="@/assets/images/coin-small-1.png" alt="" /></div>
                                        <span class="Value-item">฿10</span>
                                    </div>
                                    <h5>Credit</h5>
                                </li>
                            </ul>
                        </div>
                    </div>
<!--                    <div class="content-bg"></div>-->
                </div>
                <img class="giftBox" src="@/assets/images/bg-topUp/giftBox.png" alt="" >
                <div class="spin-win spin-win-bg"></div>

            </div>

            <div class="overlay-box"></div>
        </div>


        <!--            Lv อัพ-->
        <div class="box-award box-level-up" id="modal_level_up" v-if="level_up_obj">

            <div class="box-alert-content">
                <div class="close-box-bonus" id="modal_level_up_close_btn"></div>
                <div class="boxAwardDesignFlare"></div>
                <div class="content-all">
                    <div class="contentTopOver" >
                        <div class="footer">
                            <button type="button" class="btn" id="modal_level_up_confirm_btn" :level="level_up_obj.level" @click="claimBonusLevel(level_up_obj);" >รับของรางวัล</button>
                        </div>
                    </div>
                    <div class="content">
                        <div class="box-level-up">
                            <div class="box-level-up-topic">
                                <h5>Level Up !</h5>
                                <div class="box-level-up-bg">
                                    <span>{{level_up_obj.level}} <small>Level</small></span>
                                </div>
                            </div>



                            <div class="box-level-up-detail">
                                <h5>ขอแสดงความยินดี</h5>
                                <span>ขณะนี้คุณได้ level {{level_up_obj.level}} แล้ว</span>
                                <strong>คุณได้รับของรางวัล</strong>
                            </div>
                            <div class="row-box-award">
                                <ul>
                                    <li>
                                        <div class="item-award">
                                            <div v-if="level_up_obj.level%10!=0" class="set-coin"><img src="@/assets/images/coin-small-1.png" alt=""  /></div>
                                            <div v-if="level_up_obj.level%10==0" class="set-coin"><img :src="level_up_obj.img" alt=""   /></div>
                                            <span class="Value-item">{{level_up_obj.bonus_item_name}}</span>
                                        </div>
                                        <h5>{{level_up_obj.type}}</h5>
                                    </li>
                                </ul>
                            </div>




                        </div>

                    </div>
                    <div class="content-bg"></div>
                </div>
                <div class="spin-win spin-win-bg"></div>


            </div>
            <div class="overlay-box"></div>
        </div>
        <!--        คุณได้โบนัสสมาชิกใหม่  -->
        <div class="box-award boxAwardNewUser"  v-if="new_member_packet && packet_show==1">
            <div class="box-alert-content">
                <div class="title title-center">
                    <img src="@/assets/images/bg-topUp/logo-newUser.png" alt="" >
<!--                    <h1>คุณได้โบนัส ลูกค้าใหม่</h1>-->
                </div>
                <div class="content-all">
                    <div class="content">
                        <div class="row-box-award">
                            <div class="boxAwardNewUserTop">
                                <span class="Value-item">{{new_member_packet.packet_name}}</span>
                                <p>กดเพิ่มบัญชีเพื่อรับฟรี</p>
                                <div class="footer footer-center">
                                    <button type="button" class="btn cliam_bonus_btn"  @click="confirmPurchasePakcet(new_member_packet.packet_id,new_member_packet.packet_type)" >เพิ่มบัญชี</button>
                                </div>
                            </div>
                            <ul>
                                <li style="width: auto">
                                    <div class="item-award">
                                        <div class="set-coin" v-if="new_member_packet.packet_type==0">
                                            <img  style="margin-bottom: 20px;" src="@/assets/images/coin-small-1.png"/>
                                            
                                        </div>
                                        <div class="set-coin" v-else-if="new_member_packet.packet_type==1">
                                            <img  style="margin-bottom: 20px;" :src="new_member_packet.item_img"  />
                                            
                                        </div>

                                    </div>
                                </li>
                            </ul>
                            <div class="rowBoxAwardBG"></div>
                            <div class="spinWinCoupon"></div>
                        </div>

                    </div>
                    <div class="content-bg"></div>
                </div>



            </div>
            <div class="overlay-box"></div>
        </div>

        <!--            คุณได้รับเครดิตฟรี-->
        <div class="box-award box-level-up"  v-if="new_member_packet && packet_show==2" >
            <div class="box-alert-content">
                <div class="close-box-bonus" ></div>
                <div class="content-all">
                    <div class="content" style="padding-top: 50px;">
                        <div class="box-level-up">
                            <div class="box-cong-topic">
                                <div class="box-cong-bg">
                                    <span>ขอแสดงความยินดี</span>
                                </div>
                            </div>
                            <div class="box-level-up-detail">
                                <strong style="font-size: 20px;">{{new_member_packet.packet_name}}</strong>
                            </div>
                            <div class="row-box-award">
                                <ul>
                                    <li  style="width: auto;">
                                        <div class="item-award">
                                            <div class="set-coin" v-if="new_member_packet.packet_type==0">
                                            <img  style="margin-bottom: 20px;" src="@/assets/images/coin-small-1.png"/>
                                            
                                        </div>
                                        <div class="set-coin" v-else-if="new_member_packet.packet_type==1">
                                            <img  style="margin-bottom: 20px;" :src="new_member_packet.item_img"  />
                                            
                                        </div>
                                        </div>
                                        <h5  style="font-size: 16px;">กดรับรางวัลฟรี</h5>
                                    </li>
                                </ul>
                            </div>
                            <div class="footer footer-center">
                                <button type="button" class="btn bg-gradient-btn" @click="confirmPurchasePakcet(new_member_packet.packet_id,new_member_packet.packet_type)">รับของรางวัล</button>
                            </div>
                        </div>
                    </div>
                    <div class="content-bg"></div>
                </div>
            </div>
            <div class="overlay-box"></div>
        </div>

        <!--            Free Spin-->
        <div class="box-award box-level-up" v-if="free_spin_show">
            <div class="box-alert-content box-freeSpin-content" >


                <div class="content-all">
                    <div class="close-box-bonus" @click="closeFreeSpin();"></div>
                    <div class="title" @click="closeFreeSpin();">
                        <div class="title-logo">
                            <img src="@/assets/images/bg-topUp/freeSpin-bg.png" alt="" >
                        </div>
                    </div>
                    <div class="content">

                        <div class="box-level-up">
                            <div class="box-level-up-box">
                                <img :src="free_spin.choose_case"  />
                                <img class="bg-spin" src="@/assets/images/spint-gold.png"  />
                            </div>
                            <div class="box-level-up-detail">
                                <div class="box-detail-freespin">
                                    <div class="box-detail-freespin-info">
                                        <h5>฿{{free_spin.price_case}}</h5>
                                        <h4>{{free_spin.case_name}}</h4>
                                    </div>
                                </div>
                                <strong>ได้รับ ฟรีสปิน X{{free_spin.amount}}</strong>
                            </div>
                            <div class="footer footer-center">
                                <button type="button" class="btn bg-gradient-btn" @click="linkToUnboxPage()" ><i class="fas fa-box-open"></i> รับของรางวัล</button>
                            </div>



                        </div>

                    </div>
                    <div class="content-bg"></div>
                </div>



            </div>
            <div class="overlay-box"></div>
        </div>


<!--        ฝากถอน-->
        <div class="box-award box-level-up boxUserDepositWithdrawModal" v-if="depositWithdrawModalHide==false">
            <div class="box-alert-content box-freeSpin-content" >
                <div class="content-all">
                    <div class="close-box-bonus" @click="closeDepositWithdrawModal()" ></div>

                    <div class="content">
                        <div class="boxUserDepositWithdraw">
                            <UserDepositWithdraw></UserDepositWithdraw>

                        </div>

                    </div>
                    <div class="content-bg"></div>
                </div>



            </div>
            <div class="overlay-box"></div>
        </div>


        <!-- เปิดโต๊ะเกม           d-open-->
        <div ref="modalListTable" class="modalList-table modalList-table-menu">
            <div v-if="currentTableType > -1" class="wrapper-box-type">
                <div class="box-type">
                    <div class="fa-pull-left txt-header">
                        <img :src="currentTableType | tableTypeIcon"/> {{currentTableType | tableTypeDisplay}}
                    </div>
                </div>
                <div class="box-top-user-flex">
                    <carousel
                            :per-page="1.1"
                            :mouseDrag="true"
                            :spacePadding="1"
                            :navigationEnabled="true"
                            :paginationEnabled="false"
                            navigationNextLabel="❯"
                            navigationPrevLabel="❮"
                            paginationPosition="bottom"
                    >
                        <slide @slideclick="play(table)" v-for="(table, key) of getTableArrByType(currentTableType)" :key="key">
                            <div class="box-table-stat mb-4">
                                <div class="content-table-info">
                                    <div class="table-name"><span>โต๊ะ {{table.table_name}}</span></div>
                                    <BaccaratItem v-if="parseInt(currentTableType) === TABLE_TYPE_KEY.baccarat || parseInt(currentTableType) === TABLE_TYPE_KEY.baccarat3"
                                                  :table_id="table.table_id"
                                                  :table_round_main="table.table_round_main"></BaccaratItem>
                                    <DragonTigerItem v-if="parseInt(currentTableType) === TABLE_TYPE_KEY.dragontiger"
                                                     :table_id="table.table_id"
                                                     :table_round_main="table.table_round_main"></DragonTigerItem>
                                    <SicboItem v-if="parseInt(currentTableType) === TABLE_TYPE_KEY.sicbo"
                                               :table_id="table.table_id"
                                               :table_round_main="table.table_round_main"
                                               :limit="8"></SicboItem>
                                    <FantanItem v-if="parseInt(currentTableType) === TABLE_TYPE_KEY.bean"
                                                :table_id="table.table_id"
                                                :table_round_main="table.table_round_main"
                                                :limit="8"></FantanItem>
                                </div>
                            </div>

                        </slide>
                    </carousel>


                </div>
            </div>
            <div @click="hideSelectTableModal" class="btn-control-bottom"></div>
        </div>
        <Drawer v-show="showDrawer"></Drawer>
        <div class="GameList-mainGame" :class="{'GameListMainGameShow':isShowGameListModal}">
            <GamelistGameList v-if="currentProvider" :provider="currentProvider" @close="closeGameListModal"></GamelistGameList>
        </div>

<!--        Modal Refer-->
        <div class="referModalfloat" :class="{'referModalHide': isReferModal_hide}">
<!--        <div class="referModalfloat">-->
            <div class="referalModal">
                <div class="referalModal-content">
                    <div class="close-box-bonus" @click="CloseModalRefer()"></div>
                    <div class="referalModal-top">
                        <h2>ชวนเพื่อน</h2>
                        <p>Refer A Friend</p>
                    </div>
                    <div class="referalModal-step">
                        <ul>
                            <li>
                                <img src="@/assets/images/refer/bg-refer2.png"/>
                                <span>เชิญเพื่อน</span>
                            </li>
                            <li>
                                <img src="@/assets/images/refer/bg-refer3.png"/>
                                <span>เพื่อนเข้าร่วม</span>
                            </li>
                            <li>
                                <img src="@/assets/images/refer/bg-refer4.png"/>
                                <span>รับของรางวัล</span>
                            </li>
                        </ul>

                    </div>
                    <div class="referalModal-friend">
                        <div class="referalModal-friend-progress">
                            <h4>แนะนำเพื่อน</h4>
                            <div class="progress">
                                <div class="progress-bar"  style="width: 60%;"></div>
                                <span>1/5</span>
                            </div>
                            <div class="referalModal-friend-progress-award">
                                <img src="@/assets/images/coin-small-1.png"/>
                                <p>฿20</p>
                            </div>
                        </div>
                        <div class="referalModal-friend-balance">
                            <h4>รายได้ปัจจุบัน</h4>
                            <strong>฿200</strong>
                            <button class="btnClimBalance">รับ</button>
                        </div>
                    </div>
                    <div class="referalModal-friend-detail">
                        <ul>
                            <li>
                                <span>จำนวนเพื่อนที่แนะนำ</span>
                                <h2>2</h2>
                            </li>
                            <li>
                                <span>จำนวนเพื่อนที่เข้าร่วม</span>
                                <h2>2</h2>
                            </li>
                        </ul>
                    </div>
                    <div class="referalModal-footer">
                        <div class="referalModal-topic">
                            <h3>ลิงค์รับทรัพย์</h3>
                            <small>กดที่ปุ่มเพื่อคัดลอกโค้ด</small>
                        </div>
                        <div class="referalModal-footer-content">
                            <div class="referalModalFooterLeft active">
                                <span>ลิงค์ของคุณ</span>
                            </div>
                            <div class="referalModalFooterRight">
                                <span>โค้ดรับทรัพย์ </span>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="referalModal-bg"></div>
            </div>
        </div>

    </div>

</template>

<script>

    // $(function () {
    //     $("div.box-btn-test").click(function(){
    //         $(".modalList-table").addClass("d-open");
    //     });
    // });
    // $(function () {
    //     $(".btn-control-bottom").click(function () {
    //         $(".modalList-table").removeClass("d-open");
    //     });
    // });

    import UserDepositWithdraw from '@/components/user/DepositWithdraw'
    import store from '@/store'
    import Drawer from '@/components/Drawer'
    import LayoutMixin from '@/mixins/Layout'
    import GameList from '@/components/GameList'
    import GameListCasino from '@/components/GameListCasino'
    import GameListPP from '@/components/GameListPP'
    import GameListFish from '@/components/GameListFish'
    import GameListPopular from '@/components/GameListPopular'
    import GameListRemommend from '@/components/GameListRemommend'
    import ImageTitleDescription from '@/components/ImageTitleDescription'
    import UserMixin from '@/mixins/UserMixin'
    import axiosWrapper from '@/axiosWrapper'
    import $ from 'jquery'
    window.jQuery = $;
    window.$ = $;
    import moment from 'moment';
    import BigNumber from 'bignumber.js'
    import Vue from 'vue'
    import VueCookies from 'vue-cookies'
    import { Carousel, Slide } from 'vue-carousel'
    import GameTypeMixin from '@/mixins/GameType'
    import {TABLE_TYPE_MAP, TABLE_TYPE_KEY, IMAGE_ICON_PATH} from '@/components/game/Constant'
    import BaccaratItem from '@/components/game/baccarat/GameTypeItem'
    import DragonTigerItem from '@/components/game/dragontiger/GameTypeItem'
    import SicboItem from '@/components/game/sicbo/GameTypeItem'
    import FantanItem from '@/components/game/fantan/GameTypeItem'
    import GamelistGameList from '@/components/gamelist/GameList'
    import GameListMixin from '@/components/gamelist/GameListMixin'

    Vue.use(VueCookies)
    var balance_inv;
    export default {
        name: 'MenuBar',
        components: {
            Drawer,
            GameList,
            GameListCasino,
            GameListPP,
            GameListFish,
            GameListPopular,
            GameListRemommend,
            ImageTitleDescription,
            Carousel,
            Slide,
            BaccaratItem,
            DragonTigerItem,
            SicboItem,
            FantanItem,
            GamelistGameList,
            UserDepositWithdraw,
        },
        mixins: [
            LayoutMixin,
            UserMixin,
            GameTypeMixin,
            GameListMixin,
        ],
        props: ['mode', 'backRouteName', 'noBack','level_up_obj','alert_obj','alert_obj_show', 'previousRoute','callMenu','callDWMenu'],
        data() {
            return {
                currentTheme : 'pink',
                mission_bonus_list :{
                    1:10,
                    2:10,
                    3:10,
                    4:10,
                    5:10,
                    6:10,
                    'all':10,
                },
                min_claim_bonus :50,
                count_number:10,
                trigger: 0,
                isReferModal_hide: true,
                isShowMenu: false,
                depositWithdrawModalHide:true,
                sp_info:null,
                // level_up_obj :null,
                daily_bonus_obj:null,
                new_member_packet:null,
                packet_show : 0,
                daily_mission_obj:null,
                claim_bonus_obj:null,
                daily_bonus_show:false,
                daily_bonus_cliam : 0,
                daily_mission_show:0,
                daily_mission_show2:0,
                sp_daily_mission_bonus_date:null,
                modal_daily_bonus_hide:'',
                modal_mission_bonus_hide:'',
                gameTableList: [],
                currentTableType: -1,
                level_not_claim:0,
                service_list : `${process.env.VUE_APP_PES_SERVICE_LIST}`.split(","),
                alert_obj2:null,
                alert_obj2_show:'',
                alert_obj3:null,
                alert_obj3_show:'',
                free_spin_show:false,
                free_spin : null,
                dropdown_hide: true,
                isOnPlayerListOutFocusHandle: false,
                profile_not_claim_count :0,
                theme_hide:true,
                currentTab: '',
                casinoProviderList: [
                    {
                        slug: 'SAGAME',
                        label: 'SA',
                        image: '/images/slot/SAGaming-icon.png',
                    },
                    {
                        slug: 'SEXY',
                        label: 'Sexy',
                        image: '/images/slot/SexyBaccarat-icon.png',
                    },
                ],
                slotProviderList: [
                    {
                        slug: 'PGSOFT',
                        label: 'PGSOFT',
                        image: '/images/slot/provider/PGSOFT.png',
                        perPage : 3.2
                    },
                    {
                        slug: 'PRAGMATIC',
                        label: 'Pragmatic',
                        image: '/images/slot/provider/PragmaticPlay.png',
                        wrapperClass: 'GameList-box-wide',
                        perPage : 2.8
                    },
                    {
                        slug: 'BPG',
                        label: 'BluePrint',
                        image: '/images/slot/provider/Blueprint.png',
                        wrapperClass: 'GameList-box-wide',
                        perPage : 2.3
                    },
                    {
                        slug: 'RLX',
                        label: 'Relax',
                        image: '/images/slot/provider/RelaxGaming.png',
                        wrapperClass: 'GameList-box-wide',
                        perPage : 2.3
                    },
                ],
                currentProvider: null,
                isShowGameListModal: false,
                tabMap: {
                    'home-tab': {
                        query: {
                            'redirect-page': 'IndexPopular'
                        },
                        isAlreadyOpen: false,
                    },
                    'casino-tab': {
                        query: {
                            'redirect-page': 'IndexCasino'
                        },
                        isAlreadyOpen: false,
                    },
                    'recommend-tab': {
                        query: {
                            'redirect-page': 'IndexSlot'
                        },
                        isAlreadyOpen: false,
                    },
                    'profile-tab': {
                        query: {
                            'redirect-page': 'IndexFishing'
                        },
                        isAlreadyOpen: false,
                    },
                },
            }
        },
        computed: {
            user_profile() {
                return store.state.user_profile
            },
            pes_profile(){
                return store.state.pes_profile
            },
            
            is_member() {
                return store.state.is_member
            },
            get_agent_id (){
                return store.state.agent_id
            },
            token() {
                return (this.pes_profile) ? this.pes_profile.token : ''
            },
            needManageDailyBonusMockup() {
                return store.state.needManageDailyBonusMockup
            },
            redirectPage() {
                return store.state.redirectPage
            },
            embedMode() {
                return store.state.embedMode
            },
            TABLE_TYPE_KEY() {
                return TABLE_TYPE_KEY
            },
            appInfo() {
                return store.state.appInfo
            },
            packetId(){
                return this.$route.query['packet_id']
            },
            currentRouteName() {
                return this.$route.name;
            },
            theme: {
                get() {
                    return store.state.theme
                },
                set(value) {
                    store.commit('updateTheme', value)
                }
            },
            getTheme (){
                return store.state.theme
            },
            menu_setting(){
                return localStorage.getItem('menu_setting')? JSON.parse(localStorage.getItem('menu_setting')) :null;
            },
            menu_left_setting(){
                return localStorage.getItem('menu_left_setting')? JSON.parse(localStorage.getItem('menu_left_setting')) :null;
            },
           
            menuBarNavigation: {
                get() {
                    return store.state.menuBarNavigation
                },
                set(value) {
                    store.commit('updateMenuBarNavigation', value)
                }
            },
            isOnLoadUserProfile() {
                return store.state.isOnLoadUserProfile
            },
        },
        filters: {
            tableTypeIcon(raw) {
                return IMAGE_ICON_PATH[raw]
            },
            tableTypeDisplay(raw) {
                return TABLE_TYPE_MAP[raw]['title']
            },
        },
        watch: {
            needManageDailyBonusMockup(newVal) {
                if(newVal) {
                    this.manageDailyBonusMockup()
                    store.commit('updateNeedManageDailyBonusMockup', false)
                }
            },
            previousRoute(newVal, oldVal) {
                if(!oldVal && newVal) {
                    if(newVal.name === 'GamePlayFrame') {
                        this.openPreviousMenu()
                    }
                }
            },
            callMenu: function(newVal, oldVal) {
                newVal
                oldVal
                if(newVal==1){
                    this.showDailyMission();
                }else if(newVal==2){
                    this.alert_obj3=1;
                }
                
            },
            callDWMenu: function(newVal, oldVal) {
                newVal
                oldVal
                if(newVal==1){
                    console.log('callDWMenu',newVal)
                    this.depositWithdrawModalHide=false;
                }
                
            },
        },
        created: function () {

        },
        destroyed: function() {
            // console.log('clearInterval');
            clearInterval(balance_inv);
        },
        methods: {
            
            setTheme (value){
                store.commit('updateTheme', value)
            },
            setMenuSetting(value){
                store.commit('updateMenuSetting',value)
            },
            setMenuLeftSetting(value){
                store.commit('updateMenuLeftSetting',value)
            },
            selectTheme(theme){
                console.log(theme);
                this.currentTheme= theme;
                this.setTheme(theme)
                
                document.body.className = theme!='pink'?  'theme'+theme : ''
                // document.body.classList.add('Theme'+theme)
                // window.location.reload()
            },
            showSelectTheme(){
                this.theme_hide = false;
            },
            hideSelectTheme(){
                this.theme_hide = true;
            },
            removeDivBeforeBackHome(){
                this.$refs['delay_for_back'].show();
                $('iframe:eq(0)').remove('');
                let inv = setInterval(()=>{
                    this.count_number--;
                    if(this.count_number==0){
                        clearInterval(inv);
                        // this.$router.push({'name':this.backRouteName})
                        this.$router.push({'name':'Index'})
                    }
                },1000);
                
            },
            updateProviderServiceBeforeBack(){
                this.$router.push({'name':this.backRouteName})
            },
            OpenModalRefer() {
                this.isReferModal_hide = false;
            },
            CloseModalRefer() {
                this.isReferModal_hide = true;
            },
            closeDepositWithdrawModal(){
                this.depositWithdrawModalHide= true;
            },
            // isShowMenu
            ClosePopupGame() {
                this.isShowMenu = false;
            },
            toggleListPlayer() {
                if(this.isOnPlayerListOutFocusHandle) {
                    return
                }
                if(this.dropdown_hide) {
                    setTimeout(() => {
                        this.$refs['listPlayerWrapper'].focus()
                    }, 1)
                    this.dropdown_hide = false
                } else {
                    this.dropdown_hide = true
                }
            },
            themeToggle() {
                if (this.theme === 'light') {
                    this.theme = 'dark'
                } else if (this.theme === 'dark') {
                    this.theme = 'light'
                }
            },
            isNewMember(){
                return sessionStorage.getItem('new_member_register');
            },
            isPacketShow(){
                return sessionStorage.getItem('is_packet_show');
            },
            update_pes_profile(val){
                store.commit('updatePesProfile', val)
            },
            update_service(val){
                store.commit('updateService', val)
            },
            openMenu() {
                this.isShowMenu = true
                if(!this.currentTab) {
                    this.currentTab = 'home-tab'
                    this.tabMap[this.currentTab].isAlreadyOpen = true
                }
                this.menuBarNavigation.tab = this.currentTab
                this.menuBarNavigation.provider = null
            },
            toggleMenu() {
                // if (!this.is_member) {
                //     this.notifyToSignUp('เพื่อเข้าสู่เมนู')
                //     return
                // }
                this.isShowMenu = !this.isShowMenu
                // this.$emit('toggleMenu')
                this.menuBarNavigation.tab = this.currentTab
            },
            toggleGameHome() {
                this.toggleMenu()
                this.$jquery('.nav-tabs a[href="#tab-games"]').tab('show')
                store.commit('updateRedirectPage', '')
            },
            toggleCasino() {
                 this.$router.push({name: 'IndexCasino'})
                // this.toggleMenu()
                // this.$jquery('.nav-tabs a[href="#tab-casino"]').tab('show')
                // store.commit('updateRedirectPage', '')
            },
            toggleSlot() {
                this.$router.push({name: 'IndexSlot'})
                // this.toggleMenu()
                // this.$jquery('.nav-tabs a[href="#tab-recommends"]').tab('show')
                // store.commit('updateRedirectPage', '')
            },
            toggleFishing() {
                this.$router.push({name: 'IndexFishing'})
                // this.toggleMenu()
                // this.$jquery('.nav-tabs a[href="#tab-profile"]').tab('show')
                // store.commit('updateRedirectPage', '')
            },
            toggleP2p() {
                this.toggleMenu()
                this.$jquery('.nav-tabs a[href="#tab-p2p"]').tab('show')
                store.commit('updateRedirectPage', '')
            },
            openGame(game) {
                if (game === 'game-type') {
                    // this.$router.push({name: 'GameType'})
                    // return
                    this.toggleGameHome()
                    return
                }
                if (game === 'unbox') {
                    this.$router.push({name: 'Unbox'})
                    return
                }
                if (game === 'spin_circle') {
                    this.$router.push({name: 'SpinCircle'})
                    return
                }
                if (game === 'mini_sicbo') {
                    this.$router.push({name: 'MiniSicbo'})
                    return
                }
                if (game === 'mini_fantan') {
                    this.$router.push({name: 'MiniFantan'})
                    return
                }
                if (game === 'mini_dragontiger') {
                    this.$router.push({name: 'MiniDragonTiger'})
                    return
                }
                if (game === 'live_stream') {
                    this.$router.push({name: 'LiveStream'})
                    return
                }
                if (game === 'football') {
                    // this.current_game = game
                    // this.openDrawer()
                    this.$router.push({name: 'Football'})
                    return
                }                
                if (game === 'lotto') {
                    this.$router.push({name: 'LottoHome'})
                }
                this.$emit('openGame', game)
            },
            goProfilePage() {
                this.$router.push({name: 'Profile'})
            },
            goSignOut() {
                this.signOut()
                setTimeout(() => {
                    location.reload()
                }, 100)
            },
            toHome() {
                this.$router.push({name: 'Index'})
            },
            toProfilePage() {
                this.$router.push({name: 'Profile'})
            },
            toAccountPage() {
                this.$router.push({name: 'Account'})
            },
            toStatement() {
                this.$router.push({name: 'Statement'})
            },
            toDepositWithdrawPage(deposited) {
                if(deposited!=true){
                    this.$router.push({name: 'DepositWithdraw'})
                }
                
            },
            toShopPage() {
                this.$router.push({name: 'Package'})
            },

            gotToLink(mission_id){
                console.log(mission_id);
                if(mission_id==1){
                    this.$router.push({name:'DepositWithdraw',query: {}});
                }else if(mission_id==2){
                    this.$router.push({name:'Unbox',query: {}});
                }else if(mission_id==3){
                     this.$router.push({name:'Baccarat',query: {}});
                }else if(mission_id==4){
                     this.$router.push({name:'MiniSicbo',query: {}});
                }else if(mission_id==5){
                     this.$router.push({name:'MiniFantan',query: {}});
                }else if(mission_id==6){
                    this.$router.push({name:'MiniDragonTiger',query: {}});
                }
            },
            async getSPInfo() {
                    const res = await axiosWrapper({
                        method: 'get',
                        url: `${this.envDomains.VUE_APP_PES_BASE_URL}/api/v3/sp/info.php?token=`+this.pes_profile.token,
                    });
                    let pes_profile=this.pes_profile;
                    let balance= res.data.data.balance;
                    pes_profile.THB_free = balance.THB_free;
                    pes_profile.THB_used = balance.THB_used;
                    pes_profile.THB_total = balance.THB_total;
                    let service = res.data.data.service;
                    pes_profile.service = service;
                    this.update_pes_profile(pes_profile);
                    this.update_service(service);
                    return res.data.data;
            },
            getSPInfoNoWait() {
                var self = this;
                    $.get(`${this.envDomains.VUE_APP_PES_BASE_URL}/api/v3/sp/info.php?token=`+this.pes_profile.token,function(response){
                        if(response.code==1){
                            self.sp_info = response.data;
                            self.manageDailyMission();
                        }
                    });
            },
            async getSPInfoV1() {
                    let agent_id = this.get_agent_id ? this.get_agent_id : localStorage.getItem('agent_id')
                    const res = await axiosWrapper({
                        method: 'get',
                        url: `${this.envDomains.VUE_APP_PES_BASE_URL}/api/v1/sp/info.php?agent=${agent_id}`,
                    });
                    let service = res.data.data.service;
                    this.update_service(service);
                    let theme = this.theme;
                    let menu_setting = this.menu_setting
                    let menu_left_setting = this.menu_left_setting
                    if(theme!=res.data.data.agent_theme){
                        this.setTheme(res.data.data.agent_theme)
                        // setTimeout(()=>{
                        //     this.refresh()
                        // },500)
                    }
                    let agent_menu = res.data.data.agent_menu
                    let agent_left_menu = res.data.data.agent_left_menu
                    if(menu_setting==null || menu_left_setting==null){
                        console.log('null in');
                        this.setMenuSetting(agent_menu)
                        this.setMenuLeftSetting(agent_left_menu)
                        setTimeout(()=>{
                                window.location.reload()
                        },500)
                    }else{
                        for(let _menu in agent_menu){
                            let _menu_id =  agent_menu[_menu].id;
                            if(_menu_id!=menu_setting[_menu].id){
                                this.setMenuSetting(agent_menu)
                                this.setMenuLeftSetting(agent_left_menu)
                                console.log('menu 1',);
                                setTimeout(()=>{
                                    window.location.reload()
                                },500)
                            }
                        }
                        for(let _menu in  agent_left_menu){
                            let _menu_id =  agent_left_menu[_menu].id;
                            if(_menu_id!= menu_left_setting[_menu].id || agent_left_menu[_menu].is_show!= menu_left_setting[_menu].is_show ){
                                this.setMenuSetting(agent_menu)
                                this.setMenuLeftSetting(agent_left_menu)
                                console.log('menu 2')
                                setTimeout(()=>{
                                    window.location.reload()
                                },500)
                            }
                        }
                    }
                    return res.data.data;
            },
            manageDailyMission(){
                var daily_mission = this.sp_info.daily_mission;
                this.min_claim_bonus = this.pes_profile.min_claim_bonus;
                this.sp_daily_mission_bonus_date = moment(daily_mission.sp_daily_mission_bonus_date).format('DD/MM/YYYY');
                this.mission_bonus_list[1]=parseFloat(this.sp_info.daily_mission.sp_daily_mission_bonus_item[this.sp_info.daily_mission.sp_daily_mission_bonus_config[1]].bonus_item_value)
                this.mission_bonus_list[2]=parseFloat(this.sp_info.daily_mission.sp_daily_mission_bonus_item[this.sp_info.daily_mission.sp_daily_mission_bonus_config[2]].bonus_item_value)
                this.mission_bonus_list[3]=parseFloat(this.sp_info.daily_mission.sp_daily_mission_bonus_item[this.sp_info.daily_mission.sp_daily_mission_bonus_config[3]].bonus_item_value)
                this.mission_bonus_list[4]=parseFloat(this.sp_info.daily_mission.sp_daily_mission_bonus_item[this.sp_info.daily_mission.sp_daily_mission_bonus_config[4]].bonus_item_value)
                this.mission_bonus_list[5]=parseFloat(this.sp_info.daily_mission.sp_daily_mission_bonus_item[this.sp_info.daily_mission.sp_daily_mission_bonus_config[5]].bonus_item_value)
                this.mission_bonus_list[6]=parseFloat(this.sp_info.daily_mission.sp_daily_mission_bonus_item[this.sp_info.daily_mission.sp_daily_mission_bonus_config[6]].bonus_item_value)
                this.mission_bonus_list['all']=parseFloat(this.sp_info.daily_mission.sp_daily_mission_bonus_item[this.sp_info.daily_mission.sp_daily_mission_bonus_config['all']].bonus_item_value)
                if(daily_mission.sp_daily_mission_bonus.all.status<=2){
                    daily_mission.mission_list ={
                        // 1: 'เข้าระบบ',
                        1: `ฝากเงินรวม ${this.min_claim_bonus} บาทขึ้นไปในวัน`,
                        2: `เปิดกล่อง 1 กล่อง`,
                        3: `เล่นบาคาร่า 50 เครดิต`,
                        4: `เล่นไฮโล 50 เครดิต`,
                        5: `เล่นถั่วหยิบ 1 ครั้ง`,
                        6: `เล่นดราก้อนไทเกอร์ 50 เครดิต`,
                        
                        'all':'เครียร์ทั้งหมด'

                    };
                    var count=0;
                    var _count=0;
                    for(var key_index in daily_mission.sp_daily_mission_bonus){
                        // console.log(daily_mission.sp_daily_mission_bonus[key_index].status)
                        if(daily_mission.sp_daily_mission_bonus[key_index].status==1){
                            count++;
                            // this.profile_not_claim_count++;
                            // console.log("mission +1");
                        }else if(daily_mission.sp_daily_mission_bonus[key_index].status==0){
                            _count++;
                        }
                    }
                    for(var key in daily_mission.sp_daily_mission_bonus_item){
                        var bonus_type_name='';
                        if(daily_mission.sp_daily_mission_bonus_item[key].bonus_type==1){
                            bonus_type_name= daily_mission.sp_daily_mission_bonus_item[key].bonus_item_description;
                           
                            
                        }else{
                            bonus_type_name='Credit';
                             daily_mission.sp_daily_mission_bonus_item[key].bonus_item_img='/images/coin-small-1.png';
                        }
                        daily_mission.sp_daily_mission_bonus_item[key].bonus_type=bonus_type_name;
                    }
                    // this.daily_mission_obj  = daily_mission;
                    this.sp_info.daily_mission =daily_mission;
                    this.daily_mission_show=count;
                    this.daily_mission_show2=_count;
                    

                }else{
                    // console.log("daily mission clear...");
                }
                var _showDailyMission = sessionStorage.getItem('showDailyMission');
                if(_showDailyMission){
                    sessionStorage.removeItem('showDailyMission');
                    this.showDailyMission();

                }
                
            },
            manageDailyBonus(){
                var _showDailyBonus = sessionStorage.getItem('showDailyBonus');
                var sp_bonus_login_7day=this.$cookies.get("sp_bonus_login_7day");
                var dont_first_deposit_today=this.$cookies.get("dont_first_deposit_today");
                if(_showDailyBonus){
                    sp_bonus_login_7day=0;
                    sessionStorage.removeItem('showDailyBonus');
                }
                var sp_daily_mission_bonus = this.sp_info.daily_mission.sp_daily_mission_bonus;
                if(sp_bonus_login_7day==1 && dont_first_deposit_today!=1 && sp_daily_mission_bonus[1].status==0){
                    this.alert_obj3=1;
                }

                var daily_bonus=(this.sp_info.daily_bonus);
               
                var sp_daily_login_bonus_day = daily_bonus.sp_daily_login_bonus_day;
                var sp_daily_login_bonus_date = daily_bonus.sp_daily_login_bonus_date;
                var sp_daily_login_bonus_id = daily_bonus.sp_daily_login_bonus_id;
                var item_list = daily_bonus.sp_daily_login_bonus_item[sp_daily_login_bonus_id];
                var current_date = moment().format('YYYY-MM-DD');
                this.daily_bonus_cliam = current_date!=sp_daily_login_bonus_date?1:0;

                // var current_day  = moment().format('DD');
                if((current_date==sp_daily_login_bonus_date && !_showDailyBonus)){
                    this.daily_bonus_show=0;
                    this.showFreeSpin();
                    // console.log("cliamed..");
                    // if(sp_daily_login_bonus_day<7+1){
                    //     var _begin_day1 = 1;
                    //     var _end_day1 = 8;
                    //     var _reward_item_list1 =[]; 
                    //     for(;_begin_day1<_end_day1;_begin_day1++){
                    //         var _item1=item_list['day_'+_begin_day1+'_bonus_item'];
                    //         if(sp_daily_login_bonus_day>_begin_day1){
                    //             _item1['_add_class']='get-bonus';
                    //         }else if(sp_daily_login_bonus_day==_begin_day1){
                    //             _item1['_add_class']='';
                    //         }else{
                    //             _item1['_add_class']='';
                    //         }
                    //         if(_item1.bonus_type==0){
                    //             _item1.bonus_type_name='Credit';
                    //         }else if(_item1.bonus_type==1){
                    //             _item1.bonus_type_name=_item1.bonus_item_description;
                    //         }else{
                    //             _item1.bonus_type_name='Credit';
                    //         }
                            
                    //         _reward_item_list1.push(_item1);
                    //     }
                    //     this.daily_bonus_obj={
                    //         reward_item_list : _reward_item_list1
                    //     }
                    // }
                }else{
                    // this.profile_not_claim_count++;
                    // console.log("daily bonus +1");
                    console.log("not claim");
                    if(sp_daily_login_bonus_day==28 && (sp_daily_login_bonus_date==null || sp_daily_login_bonus_date!=current_date)){
                        sp_daily_login_bonus_day=1;
                        
                    }else{
                        sp_daily_login_bonus_day++;
                    }
                    if(sp_daily_login_bonus_id==null){
                        sp_daily_login_bonus_id=1;
                        item_list = daily_bonus.sp_daily_login_bonus_item[sp_daily_login_bonus_id];
                    }
                    if(sp_daily_login_bonus_day<7+1){//7
                        console.log("week 1");
                        var begin_day1 = 1;
                        var end_day1 = 8;
                        var reward_item_list1 =[]; 
                        for(;begin_day1<end_day1;begin_day1++){
                            var item1=item_list['day_'+begin_day1+'_bonus_item'];
                            item1.day = begin_day1;
                            if(sp_daily_login_bonus_day>begin_day1){
                                item1['_add_class']='get-bonus';
                            }else if(sp_daily_login_bonus_day==begin_day1){
                                if(sp_daily_login_bonus_date!=current_date){
                                    item1['_add_class']='get-bonus-active';
                                }else{
                                    item1['_add_class']='';
                                }
                                
                            }else{
                                item1['_add_class']='';
                            }
                            if(item1.bonus_type==0){
                                item1.bonus_type_name='Credit';
                            }else if(item1.bonus_type==1){
                                item1.bonus_type_name=item1.bonus_item_description;
                                // console.log(item1);
                                
                            }else{
                                item1.bonus_type_name='Credit';
                            }
                            
                            reward_item_list1.push(item1);
                        }
                        if(this.isNewMember() || this.packetId || this.currentRouteName=='DepositWithdraw' || this.currentRouteName=='Account' || this.currentRouteName=='UnboxPlay' || sp_bonus_login_7day==1 ){ 
                            this.daily_bonus_show=0;
                            this.showFreeSpin();
                        }else{
                            this.daily_bonus_obj={
                                reward_item_list : reward_item_list1
                            }
                            this.daily_bonus_show=1;
                        }
                    }else if(sp_daily_login_bonus_day<(7*2)+1){//14
                        console.log("week 2");
                        
                        var begin_day2 = 8;
                        var end_day2 = 15;
                        var reward_item_list2 =[]; 
                        for(;begin_day2<end_day2;begin_day2++){

                            var item2=item_list['day_'+begin_day2+'_bonus_item'];
                            item2.day = begin_day2;
                            if(sp_daily_login_bonus_day>begin_day2){
                                item2['_add_class']='get-bonus';
                            }else if(sp_daily_login_bonus_day==begin_day2){
                                if(sp_daily_login_bonus_date!=current_date){
                                    item2['_add_class']='get-bonus-active';
                                }else{
                                    item2['_add_class']='';
                                }
                            }else{
                                item2['_add_class']='';
                            }
                            if(item2.bonus_type==0){
                                item2.bonus_type_name='Credit';
                            }else if(item2.bonus_type==1){
                                item2.bonus_type_name=item2.bonus_item_description;
                                // console.log(item2);
                            }else{
                                item2.bonus_type_name='Credit';
                            }
                            
                            reward_item_list2.push(item2);
                        }
                        if(this.isNewMember() || this.packetId || this.currentRouteName=='DepositWithdraw' || this.currentRouteName=='Account' || this.currentRouteName=='UnboxPlay' || sp_bonus_login_7day==1 ){ 
                            this.daily_bonus_show=0;
                            this.showFreeSpin();
                        }else{
                            this.daily_bonus_obj={
                                reward_item_list : reward_item_list2
                            }
                            this.daily_bonus_show=1;
                        }                       
                    }else if(sp_daily_login_bonus_day<(7*3)+1){//21
                        var begin_day3 = 15;
                        var end_day3 = 22;
                        var reward_item_list3 =[]; 
                        for(;begin_day3<end_day3;begin_day3++){
                            var item3=item_list['day_'+begin_day3+'_bonus_item'];
                            item3.day = begin_day3;
                            if(sp_daily_login_bonus_day>begin_day3){
                                item3['_add_class']='get-bonus';
                            }else if(sp_daily_login_bonus_day==begin_day3){
                                if(sp_daily_login_bonus_date!=current_date){
                                    item3['_add_class']='get-bonus-active';
                                }else{
                                    item3['_add_class']='';
                                }
                            }else{
                                item3['_add_class']='';
                            }
                            if(item3.bonus_type==0){
                                item3.bonus_type_name='Credit';
                            }else if(item3.bonus_type==1){
                                item3.bonus_type_name=item3.bonus_item_description;
                                // console.log(item3);
                            }else{
                                item3.bonus_type_name='Credit';
                            }
                            
                            reward_item_list3.push(item3);
                        }
                        if(this.isNewMember() || this.packetId || this.currentRouteName=='DepositWithdraw' || this.currentRouteName=='Account' || this.currentRouteName=='UnboxPlay' || sp_bonus_login_7day==1 ){ 
                            this.daily_bonus_show=0;
                            this.showFreeSpin();
                        }else{
                            this.daily_bonus_obj={
                                reward_item_list : reward_item_list3
                            }
                            this.daily_bonus_show=1;
                        }

                    }else{//28
                        console.log("week 4");
                        var begin_day4 = 22;
                        var end_day4 = 28;
                        var reward_item_list4 =[]; 
                        for(;begin_day4<=end_day4;begin_day4++){
                            // console.log(item_list);
                            var item4=item_list['day_'+begin_day4+'_bonus_item'];
                            item4.day = begin_day4;
                            if(sp_daily_login_bonus_day>begin_day4){
                                item4['_add_class']='get-bonus';
                            }else if(sp_daily_login_bonus_day==begin_day4){
                                if(sp_daily_login_bonus_date!=current_date){
                                    item4['_add_class']='get-bonus-active';
                                }else{
                                    item4['_add_class']='';
                                }
                            }else{
                                item4['_add_class']='';
                            }
                            if(item4.bonus_type==0){
                                item4.bonus_type_name='Credit';
                            }else if(item4.bonus_type==1){
                                item4.bonus_type_name=item4.bonus_item_description;
                                // console.log(item4);
                            }else{
                                item4.bonus_type_name='Credit';
                            }
                            
                            reward_item_list4.push(item4);
                        }
                        if(this.isNewMember() || this.packetId || this.currentRouteName=='DepositWithdraw' || this.currentRouteName=='Account' || this.currentRouteName=='UnboxPlay' || this.currentRouteName=='SpinCircle' || sp_bonus_login_7day==1 ){ 
                            this.showFreeSpin();
                            this.daily_bonus_show=0;
                        }else{
                            this.daily_bonus_obj={
                                reward_item_list : reward_item_list4
                            }
                            this.daily_bonus_show=1;
                        }
                        
                    }

                    
                    
                }
               
                

                
                
            },
            manageOther(){
                var dayOfWeek = moment().format('e');
                var sp_info=this.sp_info;
                var free_bonus = sp_info.free_bonus;
                var referral  = sp_info.referral;
                var cash_back = sp_info.cash_back;
                if(free_bonus.count>0 && sp_info.balance.THB_total<free_bonus.min_amount && moment().format('x') - parseInt(free_bonus.timestamp)+free_bonus.remaining >0){
                    this.profile_not_claim_count++;
                    // console.log("free bonus +1")
                }
                // cash_back.sp_amount=10;
                if(cash_back.sp_amount>0 && dayOfWeek==1){
                    this.profile_not_claim_count++;   
                    // console.log("cash back +1");      
                }
                if(referral.referral_bonus.amount && dayOfWeek==1){
                    this.profile_not_claim_count++;   
                    // console.log("referral bonus +1") 
                }
                let referral_count_checked= referral.referral_count_checked;
                for(let i=0;i<referral.referral_list.length;i++){
                    if(referral.referral_count_claim < referral.referral_list[i].count && referral_count_checked>=referral.referral_list[i].count){
                        this.profile_not_claim_count++;
                        // console.log("referral level +1");    
                    }

                }




            },
            manageDailyBonusMockup(){
                var daily_bonus=(this.sp_info.daily_bonus);
               
                var sp_daily_login_bonus_day = 1;
                var sp_daily_login_bonus_id = daily_bonus.sp_daily_login_bonus_id;
                var item_list = daily_bonus.sp_daily_login_bonus_item[sp_daily_login_bonus_id];

                var begin_day1 = 1;
                        var end_day1 = 8;
                        var reward_item_list1 =[]; 
                        for(;begin_day1<end_day1;begin_day1++){
                            var item1=item_list['day_'+begin_day1+'_bonus_item'];
                            item1.day = begin_day1;
                            if(sp_daily_login_bonus_day>begin_day1){
                                item1['_add_class']='get-bonus';
                            }else if(sp_daily_login_bonus_day==begin_day1){
                                item1['_add_class']='get-bonus-active';
                            }else{
                                item1['_add_class']='';
                            }
                            if(item1.bonus_type==0){
                                item1.bonus_type_name='Credit';
                            }else if(item1.bonus_type==1){
                                item1.bonus_type_name=item1.bonus_item_description;
                                // console.log(item1);
                                
                            }else{
                                item1.bonus_type_name='Credit';
                            }
                            
                            reward_item_list1.push(item1);
                        }
                        this.daily_bonus_obj={
                            reward_item_list : reward_item_list1
                        }

                    this.daily_bonus_show=1;
                
               
                

                
                
            },

            showDailyMission(){
                this.modal_mission_bonus_hide='';
                console.log(this.sp_info);
                this.daily_mission_obj = this.sp_info.daily_mission;
                
                // this.daily_mission_obj = 
                // console.log(this.sp_info.daily_mission);
                // var _add_class='claim_daily_mission';
                // var _add_class = $(this).attr('_add_class');
                // var bonus_id = $(this).attr('bonus_id');
                // var bonus_item_name = $(this).attr('bonus_item_name');
                // var bonus_item_type_name = $(this).attr('bonus_item_type_name');
                // var bonus_type = $(this).attr('bonus_type');
                // var bonus_item_value = $(this).attr('bonus_item_value');
                // var bonus_item_img = $(this).attr('bonus_item_img');
                // self.showClaimBonus(_add_class,bonus_id,bonus_item_name,bonus_type, bonus_item_type_name,bonus_item_value,bonus_item_img);
            },
            showDailyBonus(){
                sessionStorage.setItem('showDailyBonus',1);
                this.modal_daily_bonus_hide='';
                this.manageDailyBonus();
            },
            closeModalDailyMission(){
                this.modal_mission_bonus_hide ='hide';
                var self = this;
                setTimeout(function(){
                    self.daily_mission_obj  =null;
                },1000*0.3);
                
            },
            closeDailyBonusModal(){
                this.modal_daily_bonus_hide='hide';
                var self = this;
                setTimeout(function(){
                    self.daily_bonus_obj =null;
                },1000*0.3);
                this.$cookies.set("sp_bonus_login_7day",1,60*60*24);
            },
            showClaimBonus(_class,bonus_id,bonus_item_name,bonus_type,bonus_item_type_name,bonus_item_value,bonus_item_img,ref1){
                // console.log(_class);
                if(_class=='get-bonus-active' || _class=='claim_daily_mission'){
                    var bonus_claim_type='';
                    if(_class=='get-bonus-active'){
                        
                        var is_claim=this.sp_info.daily_bonus.sp_daily_login_bonus_date == moment().format('YYYY-MM-DD');
                        if(is_claim==false){
                            bonus_claim_type ='daily_bonus';        
                        }
                    }else if(_class=='claim_daily_mission'){
                        bonus_claim_type='daily_mission';
                    }
                    if(bonus_claim_type){
                        // console.log(bonus_item_img);
                        this.claim_bonus_obj ={
                            bonus_claim_type:bonus_claim_type,
                            bonus_id:bonus_id,
                            bonus_item_name:bonus_item_name,
                            bonus_type:bonus_type,
                            bonus_item_type_name :bonus_item_type_name,
                            bonus_item_value :bonus_item_value,
                            bonus_item_img : bonus_item_img,
                            ref1 :ref1
                        }
                        // console.log(this.claim_bonus_obj)
                    }


                }
            },
            claimBonus(bonus_claim_type,bonus_type,bonus_item_value,bonus_id,ref1){
                // var bonus_claim_type = this.claim_bonus_obj.bonus_claim_type;
                // var bonus_type = this.claim_bonus_obj.bonus_type;
                // var bonus_item_value = this.claim_bonus_obj.bonus_item_value;
                // var bonus_id  =this.claim_bonus_obj.bonus_id;
                var is_claim = 0;
                var self = this;
                if(!is_claim){
                    
                    if(bonus_claim_type=='daily_bonus'){
                        if(self.pes_profile){
                            $.post(`${this.envDomains.VUE_APP_PES_BASE_URL}/api/v3/sp/claim_daily_login_bonus.php`,{token:self.pes_profile.token},function(response1){
                                if(response1.code==1){
                                    
                                    // bonus_type;
                                    // $('#modal_daily_bonus').hide();
                                    
                                    self.sp_info.daily_bonus.sp_daily_login_bonus_date=moment().format('YYYY-MM-DD');
                                    self.daily_bonus_obj=null;
                                    self.claim_bonus_obj=null;
                                    if(bonus_type==0  || bonus_type=='Credit'){
                                        // console.log(self.pes_profile.THB_free);
                                        // console.log(bonus_item_value)
                                        var balance =  new BigNumber(self.pes_profile.THB_free).plus(bonus_item_value).toNumber();
                                        self.pes_profile.THB_free = balance;
                                        // console.log(self.pes_profile.THB_free);
                                        store.commit('updateNeedUserReload', true);
                                    }else if(bonus_type==1){
                                        window.location.href='/unbox-play/'+ref1;
                                    }
                                    // if(self.new_member_packet){
                                    //     if(self.new_member_packet.balance_user_count>0){
                                    //         self.packet_show=1;

                                    //         sessionStorage.setItem('new_member_register',1);
                                    //         sessionStorage.setItem('is_packet_show',1);
                                    //     }else{
                                    //         console.log("balance user count");
                                    //         console.log("self.new_member_packet.balance_user_count",self.new_member_packet.balance_user_count)
                                    //     }

                                    // }else{
                                        
                                    //     console.log("no new member_packet");
                                        
                                    // }

                                    //for test register
                                    // sessionStorage.setItem('is_new_member',1);
                                    // self.getNewMemberPakcet();


                                }else{
                                    self.daily_bonus_obj=null;
                                    self.claim_bonus_obj=null;
                                    let msg = response1.msg;
                                    if(msg=='Need deposit first'){
                                        msg='*ต้องมียอดเติมครั้งแรกก่อนกดรับ';
                                        self.alert_obj3=1;
                                        return ;
                                    }
                                    self.alert_obj2 ={
                                        title:'แจ้งเตือน',
                                        content: msg,
                                        ok_btn:true,
                                        close_bnt:false,
                                    };
                                    
                                    
                                }
                                // setTimeout(function(){
                                //     _self.removeAttr('is_claim');
                                // },1000)
                            });
                        
                        }else{
                            self.goSignUp();
                        }

                    }else if(bonus_claim_type=='daily_mission'){
                        $.post(`${this.envDomains.VUE_APP_PES_BASE_URL}/api/v3/sp/claim_daily_mission_bonus.php`,{mission_id:bonus_id,token:self.pes_profile.token},function(response2){
                            if(response2.code==1){
                                self.claim_bonus_obj=null;
                                self.sp_info.daily_mission.sp_daily_mission_bonus[bonus_id].status=2;
                                self.daily_mission_obj.sp_daily_mission_bonus[bonus_id].status=2;
                                if(self.daily_mission_show>0){
                                    self.daily_mission_show = self.daily_mission_show -1;
                                }
                                if(bonus_type==0 || bonus_type=='Credit'){
                                    console.log(self.pes_profile.THB_free);
                                    console.log(bonus_item_value)
                                    var balance =  new BigNumber(self.pes_profile.THB_free).plus(bonus_item_value).toNumber();
                                    self.pes_profile.THB_free = balance;
                                    console.log(self.pes_profile.THB_free);
                                    store.commit('updateNeedUserReload', true);
                                 }else if(bonus_type==1){
                                        window.location.href='/unbox-play/'+ref1;
                                }

                            }else{
                                self.claim_bonus_obj=null;
                                let msg =response2.msg;
                                if(msg=='Need deposit first'){
                                    msg='ต้องมียอดเติมครั้งแรกก่อนกดรับ'
                                    self.alert_obj3=1;
                                    return ;
                                }
                                self.alert_obj2 ={
                                title:'แจ้งเตือน',
                                content: msg,
                                ok_btn:true,
                                close_bnt:false,
                                };
                                
                            }
                            // setTimeout(function(){
                            //     _self.removeAttr('is_claim');
                            // },1000)
                        });
                    
                    }
                }
            },
            manageBalance(){
                var path  = window.location.pathname;
                var self = this;
                var last_balance =self.pes_profile.THB_free;
                balance_inv =setInterval(function(){
                    if(last_balance!=self.pes_profile.THB_free){
                        // console.log("balance change");
                        last_balance = self.pes_profile.THB_free;
                        if(self.sp_info.daily_mission.sp_daily_mission_bonus.all.status<2){
                            if(path.includes("/unbox-play") || path.includes("/spin-circle")){
                                // console.log("unbox status",self.sp_info.daily_mission.sp_daily_mission_bonus[1].status);
                                if(self.sp_info.daily_mission.sp_daily_mission_bonus[1].status==0){
                                    self.getSPInfoNoWait();
                                }
                            }else{
                                console.log(path);
                            }
                            // console.log("have mission");
                        }
                    }
                }, 1500);
            },
            manageLevel(){
                this.level_not_claim =  this.sp_info.level.sp_user_level - this.sp_info.level.sp_user_level_claim;
                this.profile_not_claim_count+=this.level_not_claim;
                // console.log("level +"+this.level_not_claim);
            },
            claimBonusLevel(level_obj) {
                        
                var level = level_obj.level;
                var self = this;
                console.log('class:', $(`div.box-level-list:eq(${level})`).attr('class'))
                $(`div.box-level-list:eq(${level-1})`).addClass('active-after').removeClass('active')
                $.post(`${this.envDomains.VUE_APP_PES_BASE_URL}/api/v3/sp/claim_user_level_bonus.php`, {
                    level: level,
                    token: this.token
                }, function (response) {
                    if (response.code == 1) {
                        // setTimeout(() => {
                        //     self.level_up_obj = undefined;
                        // }, 1000 * 0.3);
                        // var data = response.data;
                        // var bonus_type = data.bonus_type;
                        // self.sp_info.level.sp_user_level_claim = level;
                        // if (bonus_type == 0) {
                        //     var balance = new BigNumber(data.bonus_item_value).plus(self.pes_profile.THB_free).toNumber();
                        //     self.pes_profile.THB_free = balance;


                        // } else if (bonus_type == 1) {
                        //     bonus_type
                        // } else {
                        //     bonus_type
                        // }
                        $('#modal_level_up').addClass('hide')
                        
                        $(`div.box-level-list:eq(${level-1})`).addClass('active-after').removeClass('active')
                    } else {
                        $('#modal_level_up').addClass('hide')
                        let msg = response.msg;
                        if(msg=='Need deposit first'){
                            msg='*ต้องมียอดเติมครั้งแรกก่อนกดรับ';
                            self.alert_obj3=1;
                            return ;
                        }
                        self.alert_obj2 = {
                            title: 'แจ้งเตือน',
                            content:msg,
                            ok_btn: true,
                            close_bnt: false,
                        };
                    }
                });
            },
            getTableArrByType(type) {
                return this.baccaratTableList.filter((table) => { return parseInt(table.table_type) === parseInt(type)})
            },
            showSelectTableModal(type) {
                this.currentTableType = type
                this.$refs['modalListTable'].classList.add('d-open')
            },
            hideSelectTableModal() {
                this.currentTableType = -1
                this.$refs['modalListTable'].classList.remove('d-open')
            },
            play(table) {
                this.$router.push({
                    name: TABLE_TYPE_MAP[table.table_type]['routeName'],
                    query: {
                        table_id: table.table_id
                    },
                })
            },
            lineAddFriend() {
                const link = (this.appInfo) ? this.appInfo.app_line_add_friend_link : ''
                if(!link) {
                    return
                }
                window.open(link)
            },
            showFreeSpin(){
                
                if(this.free_spin){
                    if(!(this.currentRouteName=='UnboxPlay' || this.currentRouteName=='SpinCircle' || this.currentRouteName=='DepositWithdraw' || this.currentRouteName=='Account' || this.currentRouteName=='Profile' || this.currentRouteName=='Package')){
                        this.free_spin_show=true;
                    }
                    
                }
            },
            closeFreeSpin(){
                this.free_spin_show=false;
            },
            manageFreeSpin(){
                let free_spin=this.sp_info.sp_bonus_item.free_spin;
                if(free_spin){
                    this.free_spin = free_spin;
                }

                
            },
            linkToUnboxPage(){
                
                if(this.free_spin){
                    let amount = this.free_spin.amount;
                    var id = this.free_spin.id;
                    if(amount>1){
                        this.$router.push({'name':'UnboxPlay',params:{id:id},query:{amount:amount>3?3:amount}});
                    }else{
                        this.$router.push({'name':'SpinCircle',params:{id:id}});
                    }
                }
            },
            async getNewMemberPakcet(){
                const res = await axiosWrapper({
                    method: 'GET',
                    url: `${this.envDomains.VUE_APP_PES_BASE_URL}/api/game/v1/game/packet/get.php?token=${this.token}&packet_id=${process.env.VUE_APP_PES_NEW_MEMBER_PACKET}`,
                    
                });
                if(res.status === 200) {
                    let new_member_packet = res.data.data;
                    new_member_packet.title ='ยืนยันการซื้อ';
                    new_member_packet.btn_title ='ยืนยันการซื้อ';
                    if(new_member_packet.item_img){
new_member_packet.img  =new_member_packet.item_img;
                    }else{
new_member_packet.img =`/images/coin-big-1.png`;
                    }
                    // console.log("new_member_packet",new_member_packet);
                    this.new_member_packet = new_member_packet;
                    let is_packet_show = this.isPacketShow();
                    this.packet_show=is_packet_show;//for test
                    if(new_member_packet.balance_user_count>0){
                        this.packet_show=is_packet_show?is_packet_show:1
                    }
                    // console.log(res.data.data);
                }
            },
            async  confirmPurchasePakcet(){
                var gp = this.new_member_packet;
                var packet_id  = gp.packet_id;
                var self = this;
                this.packet_show=0;
                sessionStorage.removeItem('is_packet_show');
                // var round =0;
                // for test
                // if(this.$route.query['register']){
                //     round =1;
                // }else{
                //     round =2;
                // }
                if(self.pes_profile){
                    $.post( `${this.envDomains.VUE_APP_PES_BASE_URL}/api/game/v1/game/packet/purchase.php`,{packet_id:packet_id,token:this.token},function(response){
                        
                        if(response.code==1){
                            sessionStorage.removeItem('new_member_register');
                            self.new_member_packet =null;
                            if(gp.packet_type==0){
                                location.reload();
                            }else if(gp.packet_type==1){
                                location.href='/unbox-play/'+gp.ref_id;
                            }
                            

                        }else{
                            var title = response.msg;
                            if(title=='Require bank account'){
                                sessionStorage.removeItem('new_member_register');
                                // sessionStorage.removeItem('is_packet_show');
                                self.$router.push({name: 'Account', query: {to_create_account: 'yes',packet_id:packet_id}})
                                // self.$refs['go-to-add-bank-account'].show()
                            }else{
                                self.claim_bonus_obj=null;
                                var alert_obj2={
                                    title:'Message',
                                    content:response.msg,
                                    ok_btn:true,
                                    close_btn:false,
                                    // after_ok: alert_obj_after_ok,
                                }
                                self.alert_obj2 = alert_obj2;
                                self.alert_show2 ='';
                            // alert(response.msg);
                            }
                            
                        }
                        

                    });
                }else{
                    this.$modal.show('AuthModal');
                }
            },
            cancelPurchase(){
                this.new_member_packet =null;
                this.packet_show=0;
                sessionStorage.removeItem('new_member_register');
                sessionStorage.removeItem('is_packet_show');

            },
            closeModalAlert(){

                
                if(this.alert_obj2){
                    this.alert_obj2=null;
                }else if(this.alert_obj){
                    this.alert_obj=null;
                }else if(this.alert_obj3){
                   this.alert_obj3=null;
                   this.$cookies.set("dont_first_deposit_today",1,60*60*24);

                }
                
            
            },
            showModalPacket(){
                    // let is_new_member =this.isNewMember();
                    // if(is_new_member==1){
                    //     this.getNewMemberPakcet();
                    // }else if(this.pes_profile ){
                    //     if(((moment().format('x') - moment(this.pes_profile.create_datetime).format('x') < (1000*60*15)) && this.pes_profile.line_id)){
                    //         // console.log("get new member packet");
                    //         this.getNewMemberPakcet();
                    //     }else{
                    //         // console.log("can't get new member packet");
                    //         // console.log("this.pes_profile.line_id",this.pes_profile.line_id);
                    //         // console.log("(moment().format('x') - moment(this.pes_profile.create_datetime).format('x')",(moment().format('x') - moment(this.pes_profile.create_datetime).format('x')));
                    //         // console.log('current_time', moment().format('HH:mm:ss'))
                    //         // console.log('create_time', moment(this.pes_profile.create_datetime).format('HH:mm:ss'))
                    //     }
                        
                    // }else{

                    //     // console.log("No User Profile")
                    // }
            },
            handleListPlayerFocusOut() {
                if(!this.dropdown_hide){
                    this.isOnPlayerListOutFocusHandle = true
                    this.dropdown_hide = true;
                    setTimeout(() => {
                        this.isOnPlayerListOutFocusHandle = false
                    }, 100)
                }
            },
            openGameListModal(provider) {
                this.currentProvider = provider
                this.isShowGameListModal = true
                this.menuBarNavigation.tab = ''
            },
            closeGameListModal() {
                this.currentProvider = null
                this.isShowGameListModal = false
            },
            openPreviousMenu() {
                if(!this.menuBarNavigation) {
                    return
                }
                if(this.menuBarNavigation.tab) {
                    const tab = this.menuBarNavigation.tab
                    if(tab === 'home-tab') {
                        this.toggleGameHome()
                        this.currentTab = tab
                    } else if(tab === 'casino-tab') {
                        this.toggleCasino()
                        this.currentTab = tab
                    } else if(tab === 'recommend-tab') {
                        this.toggleSlot()
                        this.currentTab = tab
                    } else if(tab === 'profile-tab') {
                        this.toggleFishing()
                        this.currentTab = tab
                    }
                }
                if(this.menuBarNavigation.provider) {
                    const provider = this.menuBarNavigation.provider
                    this.currentProvider = provider
                    this.isShowGameListModal = true
                }
            },
            refreshBalance() {
                if(this.isOnLoadUserProfile) {
                    return
                }
                this.requestUserReloadWithForceRefreshBalance()
            },
        },
        async mounted() {
            if(this.getTheme){
                this.currentTheme= this.getTheme;
            }
            
            
            // this.$refs['delay_for_back'].show();
            //for test
            // if(this.$route.query['register']){
            //     sessionStorage.setItem('new_member_register',1);
            //     sessionStorage.setItem('is_packet_show',1);
            // }
            // console.log(this.currentRouteName);
            
            

            this.showModalPacket();
            console.log(this.redirectPage)
            if(this.redirectPage === 'popular-game') {
                this.toggleGameHome()
                this.currentTab = 'home-tab'
            } else if(this.redirectPage === 'casino-game') {
                this.toggleCasino()
                this.currentTab = 'casino-tab'
            } else if(this.redirectPage === 'slot-game') {
                this.toggleSlot()
                this.currentTab = 'recommend-tab'
            } else if(this.redirectPage === 'fishing') {
                this.toggleFishing()
                this.currentTab = 'profile-tab'
            }
         

        //    console.log(pathname.toLowerCase());
            if(this.pes_profile){
                    this.sp_info=await this.getSPInfo();
                    this.manageFreeSpin();
                    this.manageDailyBonus();
                    this.manageDailyMission();
                    this.manageBalance();
                    this.manageLevel();
                    this.manageOther();
                    

            }else{
                if(this.get_agent_id || localStorage.getItem('agent_id')){
                    
                    this.sp_info=await this.getSPInfoV1();
                }else{
                    await new Promise(r => setTimeout(r, 1000));
                    if(this.get_agent_id){
                        console.log('get spinfo [2]')
                        this.sp_info=await this.getSPInfoV1();
                        
                    }else{
                        await new Promise(r => setTimeout(r, 1000));
                        if(this.get_agent_id){
                            console.log('get spinfo [3]')
                            this.sp_info=await this.getSPInfoV1();
                        }else{
                            await new Promise(r => setTimeout(r, 5000));
                            if(this.get_agent_id){
                                console.log('get spinfo [4]')
                                this.sp_info=await this.getSPInfoV1();
                            }else{
                                let agent_id =localStorage.getItem('agent_id');
                                console.log(agent_id,this.get_agent_id)
                                console.log('get spino [else]')
                            }
                            
                        }
                    }
                    
                    
                   
                }
                
                //    this.manageDailyBonusMockup();
                //    setTimeout(() => {
                    
                //        this.manageDailyBonusMockup();
                //    }, 1000*15);
                
            }
        //    if(this.currentRouteName!='Profile'){
            
        //    }
//            var self = this;
//            setTimeout(function(){
// console.log(self.daily_bonus_obj);
//            },3000);
           
            this.$jquery('a[data-toggle="tab"]').on('shown.bs.tab', (e) => {
                this.currentTab = e.target.getAttribute('id')
                if(this.tabMap[this.currentTab]) {
                    this.tabMap[this.currentTab].isAlreadyOpen = true
                }
                this.menuBarNavigation.tab = this.currentTab
            })

        }
    }

</script>
<style>
    .editModalCountdown{
        display: flex;
        justify-content: center;
        align-items: center;
    }
    .editModalCountdown i{
        color: #d20a84;
        margin-top: 15px;
    }
    .editModalCountdown p{
        font: 400 18px/1 Helvetica!important;
    }
    .editModalCountdown h2{
        font: 800 45px/1 Helvetica!important;
    }
    .editModalCountdown h2 small{
        margin-left: 5px;
        font: 400 20px/1 Helvetica!important;
    }
</style>