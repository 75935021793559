<template>
    <div class="MessageLottoNews incoming_msg">
<!--        <div class="incoming_msg_img">-->
<!--            <img src="@/assets/images/icon/2102206.png" alt="sunil" />-->
<!--        </div>-->
        <div class="received_msg">
            <div class="received_withd_msg">

                    <div class="box-top-user-flex-LottoNews">
                        <carousel
                                :per-page="1.3"
                                :mouseDrag="true"
                                :spacePadding="1"
                                :navigationEnabled="true"
                                :paginationEnabled="false"
                                navigationNextLabel="❯"
                                navigationPrevLabel="❮"
                                paginationPosition="bottom"
                        >
                            <slide v-for="(m, i) of message_data" :key="i">
                                <div class="box-top-user-list">
                                    <div class="box-games-list">
                                        <ImageBackground
                                                :url="generateThumbnailUrl(m.thumbnail, m.thumbnail_mode)"
                                                customClass="box-img-game box-lotto-ads image-background"/>
                                        <a :href="m.url" target="blank">
                                            <div class="box-info-game box-info-float">
                                                <h3>{{m.title || 'หัวข้อ'}}</h3>
                                                <div class="txt-detail">
                                                    {{m.description || 'คำอธิบาย'}}
                                                </div>
                                            </div>
                                        </a>
                                    </div>
                                </div>
                            </slide>
                        </carousel>
                    </div>
                <span class="time_date"> {{timestamp | chatMessageDateTimeFormat}}</span>
            </div>
        </div>
    </div>
</template>
<script>

import ImageBackground from '@/components/ImageBackground'
import { Carousel, Slide } from 'vue-carousel'

export default {
    name: 'MessageLottoNews',
    props: ['message'],
    components: {
        ImageBackground,
        Carousel,
        Slide,
    },
    computed: {
        message_data(){
            return (this.message) ? this.message.data : null
        },
        timestamp(){
            return (this.message) ? this.message.timestamp : ''
        }
    },
    methods: {
        generateThumbnailUrl(src, thumbnail_mode){
            if(!src) {
                return ''
            }
            if(thumbnail_mode === 'hot-link') {
                if(src.includes('http')) {
                    return src
                }
                if(src.startsWith('/')) {
                    return `${this.envDomains.VUE_APP_CBJADMIN_BASE_URL}${src}`    
                }
                return `${this.envDomains.VUE_APP_CBJADMIN_BASE_URL}/${src}`
            } else {
                return `${this.envDomains.VUE_APP_API}/${src}`
            }
        }
    }
}
</script>
<style scoped>
    .box-top-user-list{
        position: relative;
    }
</style>