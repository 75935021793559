<template>
    <div :class="`${customClass} ${(isOnBet) ? 'active': ''} ${isPanelWinClass}`"
         @click="selectBet($event)">
        <div v-show="isShowCoinPanel" class="coins-box">
            <div class="box-panel-play-tool box-panels">
<!--                <small>เลือกเหรียญ</small>-->
                <div class="box-coins-ft">
                    <GameChipForSelect
                            v-show="parseInt(amountRemain) > parseInt(chip.chip) && parseInt(chip.quantity) > 0"
                            v-for="(chip, i) of chipArr" :key="i"
                            :value="chip.chip"
                            :active="amount === chip.chip"
                            @click="selectChip(chip.chip)">
                    </GameChipForSelect>
                </div>
                <div class="box-btn-right box-response-btn">
                    <div class="btn-right-clear btnClearSicbo"
                         @click="cancelBet">
                        ยกเลิก
                    </div>
                    <div class="btn-right-play btn-right-play-success btnPlaySicbo"
                         @click="confirmBet">
                        หยิบ
                    </div>
                </div>
            </div>
        </div>
        <div class="box-list-money">
            <div class="box-left">
                <div class="value-select"><span>{{amountRemain}}</span></div>
                <Chip @selectChip="selectChip" v-show="parseInt(amountRemain) > parseInt(chip.chip) && parseInt(chip.quantity) > 0" v-for="(chip, key) of chipArrLeft" :key="key"
                      :value="chip.chip">
                    <p>{{chip.quantity}}X</p>
                </Chip>
            </div>
            <div class="box-right">
                <Chip @selectChip="selectChip" v-show="parseInt(amountRemain) > parseInt(chip.chip) && parseInt(chip.quantity) > 0" v-for="(chip, key) of chipArrRight" :key="key"
                      :value="chip.chip">
                    <p>{{chip.quantity}}X</p>
                </Chip>
            </div>
        </div>
        <span>
            {{label}}
        </span>
        <DummyUser></DummyUser>
        <UserBetChip 
        v-if="isOnBet" 
        :state="shipState"
        :chip="(isShowExistBet) ? latestChip : amount" 
        :value="(isShowExistBet) ? calTotalAmount : totalAmountToBet" 
        :panel="`${panelIndex}:${choice}`">
        </UserBetChip>
        <!-- <div v-show="isOnBet" class="on-bet active-select">
            <div class="on-bet-amount">
                <span v-if="isShowExistBet">
                    {{calTotalAmount}}
                </span>
                <span v-else>
                    {{totalAmountToBet}}
                </span>
            </div>
            <div v-if="isShowExistBet">
                <GameChip :value="latestUserBetByUserChoose.latest_chip"></GameChip>
            </div>
            <div v-else>
                <GameChip :value="amount"></GameChip>
            </div>
        </div> -->
        <slot></slot>
    </div>
</template>
<script>

    import Chip from './Chip'
    // import GameChip from '../Chip'
    import GameChipForSelect from '../ChipForSelect'
    import UserBetChip from '../UserBetChip'
    import DummyUser from '../DummyUser'

    export default {
        name: 'FantanPanelPlay',
        props: [
            'label',
            'customClass',
            'chipArr',
            'amountRemain',
            'panelIndex',
            'currentPanelIndex',
            'user_choose',
            'amount',
            'choice',
            'userBetArr',
            'isPanelWin',
            'shipState',
            'isOnSendBet',
        ],
        components: {
            Chip,
            GameChipForSelect,
            // GameChip,
            UserBetChip,
            DummyUser
        },
        computed: {
            userBetArrWait() {
                return this.userBetArr.filter((userBet) => {
                    return userBet.state === 'wait'
                })
            },
            userBetArrConfirm() {
                return this.userBetArr.filter((userBet) => {
                    return userBet.state === 'confirm'
                })
            },
            isShowExistBet() {
                return this.userBetArrConfirm.length > 0 && this.userBetArrWait.length === 0
            },
            latestChip() {
                return this.latestUserBetByUserChoose.latest_chip
            },
            totalAmountToBet() {
                return this.userBetArrWait.reduce((sum, bet) => {
                    return parseInt(sum) + parseInt(bet.amount)
                }, 0)
            },
            chipArrWithQuantity() {

                const calQuantity = (divided, chip) => {
                    if(parseInt(divided) < parseInt(chip)) {
                        return 0
                    }
                    const cal = parseInt(divided / parseInt(chip))
                    if(parseInt(divided) < parseInt(cal)) {
                        return 0
                    }
                    return cal
                }

                const divided = parseInt(parseInt(this.amountRemain) / this.chipArr.length)

                let amountRemain = this.amountRemain

                for(const chip of this.chipArr) {
                    const quantity = calQuantity(divided, chip)
                    const amountUse = quantity * parseInt(chip)
                    amountRemain -= amountUse
                    console.log(`${chip}, ${quantity}, ${amountUse}, ${amountRemain}`)
                }

                return []
            },
            chipArrLeft() {
                return this.chipArr.slice(0, 3)
            },
            chipArrRight() {
                return this.chipArr.slice(3, 7)
            },
            isShowCoinPanel() {
                return (this.choice === this.user_choose && this.panelIndex === this.currentPanelIndex) && !this.isOnSendBet
            },
            active() {
                return (this.choice === this.user_choose && this.panelIndex === this.currentPanelIndex) ? 'active' : ''
            },
            latestUserBetByUserChoose() {
                return this.userBetArr[this.userBetArr.length - 1]
            },
            calTotalAmount() {
                return this.userBetArrConfirm.reduce((sum, bet) => {
                    return parseInt(sum) + parseInt(bet.amount)
                }, 0)
            },
            isOnBet() {
                if (this.user_choose === this.choice && this.panelIndex === this.currentPanelIndex) {
                    return true
                }
                const foundInBetArr = this.userBetArr.find((bet) => {
                    return bet.user_choose === this.choice &&
                        bet.panelIndex === this.panelIndex
                })
                return (foundInBetArr) ? true : false
            },
            isPanelWinClass() {
                if(this.isPanelWin) {
                    return 'active-blinking'
                } else {
                    return ''
                }
            },
        },
        methods: {
            selectChip(chip) {
                this.$emit('selectChip', chip)
            },
            selectBet(e) {

                if (e.target.classList.contains('btnClearSicbo') || e.target.classList.contains('btnPlaySicbo')) {
                    return
                }

                this.$emit('selectBet', {
                    panelIndex: this.panelIndex,
                    user_choose: this.choice,
                })
            },
            confirmBet() {
                if (!this.amount) {
                    return
                }
                this.$emit('confirmBet')
            },
            cancelBet() {
                this.$emit('cancelBet')
            },
        },
    }
</script>
<style src="@/assets/css/fantan.css" scoped></style>
<style scoped>
    .btn-right-play-success {
        background-color: #3abe69;
        margin-right: 5px;
    }

    .btn-right-clear {
        background-color: #c52828;
    }

    .active-select {
        background-color: unset !important;;
    }

    .on-bet {
        position: absolute;
        top: 40%;
        left: 50%;
        width: 40px;
        height: 40px;
        transform: translate(-50%, -50%);
    }

    .on-bet-amount {
        background-color: #f5ba62;
        border-radius: 4px;
        position: absolute;
        left: 0;
        top: 35px;
        right: 0;
        width: 51px;
        z-index: 5;
        margin: 0 auto;
    }


    .on-bet-amount span {
        font-size: 12px !important;
        color: black !important;
    }

    .box-coins-ft {
        display: flex;
        margin-bottom: 5px;
        flex-wrap: nowrap;
        overflow-x: auto;
        justify-content: space-between;
    }

    .coins-box{
        /*width: 273px;*/
        top: -70px;
        z-index: 99;
        box-shadow: rgba(255, 255, 255, 0.35) 0px 5px 15px;
    }
    .box-coins-ft{padding: 10px 0 0 5px}
    .box-coins-ft > .b-coin{
        margin: 0 5px 10px 3px;
        width: 48px;
        height: 48px;
    }
    .box-coins-ft .b-coin.active{margin: 0 10px}
    .box-panel-play-tool {
        display: block !important;
    }
    .box-panel-play-tool small{opacity: .5; color: white; font-size: 16px;}
    .box-btn-right {
        width: 100%;
        background: none;
        justify-content: center;
        /*position: absolute;*/
    }

    .btn-right-play, .btn-right-clear {
        flex: 1;
        /*width:70px;*/
        /*height: 70px;*/
        /*border-radius: 50%;*/
        padding: 15px 0 !important;
        margin: 0 3px;
        font: 800 20px Helvetica Neue, Helvetica, Arial, sans-serif;
    }



    .bx-coin{
        width: 51px !important;
        height: 51px !important;
        top: 10px;
    }

</style>
