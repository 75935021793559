<template>
    <div class="MessageTimeline incoming_msg">
<!--        <div class="incoming_msg_img"><img src="@/assets/images/img-other/bell.png" alt="" /></div>-->
        <div class="received_msg">
            <div class="received_withd_msg">
                        <carousel
                                :speed="100"
                                :per-page="1.3"
                                :mouseDrag="true"
                                :spacePadding="10"
                                :navigationEnabled="true"
                                :paginationEnabled="false"
                                navigationNextLabel="❯"
                                navigationPrevLabel="❮"
                                paginationPosition="bottom">
                            <slide v-for="(item, key) of message_data" :key="key">
                                <div class="box-games-list">
                                    <div class="heading-table-lotto bg-heading-fb">
                                        <div class="heading-line">
                                            <h2>{{item.title}}</h2>
                                            <div>{{item.description}}</div>
                                        </div>
                                    </div>
                                    <div class="box-detail-line">
                                        <div class="timeline">
                                            <div 
                                            v-for="(step, key) of item.steps" 
                                            :key="key" 
                                            class="containers"
                                            :class="{'left': isLeft(key), 'right': !isLeft(key)}">
                                                <div class="contents">
                                                    <div>{{step}}</div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </slide>
                        </carousel>

                <span class="time_date"> {{timestamp | chatMessageDateTimeFormat}}</span>
            </div>
        </div>
    </div>
</template>
<script>
    import { Carousel, Slide } from 'vue-carousel'
export default {
    name: 'MessageTimeline',
    props: [
        'message'
    ],
    components: {
        Carousel,
        Slide,
    },
    computed: {
        message_data(){
            return (this.message) ? this.message.data : null
        },
        title(){
            return (this.message_data) ? this.message_data.title : ''
        },
        description(){
            return (this.message_data) ? this.message_data.description : ''
        },
        timestamp(){
            return (this.message) ? this.message.timestamp : ''
        }
    },
    methods: {
        isLeft(key){
            return key%2 === 0
        }
    }
}
</script>
<style scoped>
.right {
    left: 49%;
    text-align: left;
}
.left {
    left: 0;
    text-align: right;
}
</style>