import _find from 'lodash/find'
import _findIndex from 'lodash/findIndex'

export default {
    namespaced: true,
    state: {
        odd_style: localStorage.getItem('odd_style') || 'EU',
        odd_style_eu_only: false,
        sport_selected: 'football',
        bet_list: [],
        seconds_interval: 10000,
        accept_any_odd: false,
        tab1Index: 0,
        favorite_list: JSON.parse(localStorage.getItem('favorite_list')) || [],
        favorite_selected: '',
        modal_is_open: false,
        modal_mode: 'info',
        modal_message: '',
        modal_code: '',
        amount: 20,
    },
    mutations: {
        changeOddStyle (state, odd_style) {
            state.odd_style = odd_style
            localStorage.setItem('odd_style', odd_style)
        },
        updateEuOnly (state, bool){
            state.odd_style_eu_only = bool;
        },
        updateSportSelected(state, select){
            state.sport_selected = select;
        },
        addBet(state, bet){
            state.bet_list.push(bet);
        },
        updateBet(state, ctx){
            state.bet_list.splice(ctx.i ,1);
            state.bet_list.push(ctx.bet);
        },
        updateBetSamePos(state, ctx){
            state.bet_list.splice(ctx.i ,1, ctx.bet);
        },
        removeBet(state, i){
            state.bet_list.splice(i, 1);
        },
        removeBetAll(state){
            state.bet_list = [];
        },
        updateSecondsInterval(state, seconds){
            state.seconds_interval = seconds;
        },
        updateAcceptAnyOdd(state, accept){
            state.accept_any_odd = accept;
        },
        updateTab1Index(state, i){
            state.tab1Index = i;
        },
        addFavoriteList(state, fav){
            state.favorite_list.push(fav);
            localStorage.setItem('favorite_list', JSON.stringify(state.favorite_list))
        },
        removeFavoriteList(state, i){
            state.favorite_list.splice(i, 1);
            localStorage.setItem('favorite_list', JSON.stringify(state.favorite_list))
        },
        updateFavoriteSelected(state, fav){
            state.favorite_selected = fav;
        },
        update_modal_is_open(state, is_open){
            state.modal_is_open = is_open;
        },
        update_modal_message(state, message){
            state.modal_message = message;
        },
        update_modal_code(state, name){
            state.modal_code = name;
        },
        update_modal_mode(state, mode){
            state.modal_mode = mode;
        },
        updateAmount(state, ctx){
            state.amount = ctx
        },
    },
    actions: {
        async toBet({ commit, state }, bet){

            if(state.user || bet.quick){
                if(state.bet_list.length > 0){

                    let i = _findIndex(state.bet_list, function(o) { return o.match_id === bet.match_id; });
                    if(i > -1){
                        commit('updateBet', {
                            bet: bet,
                            i: i
                        });
                    }else{
                        commit('addBet', bet);
                    }
                }else{
                    commit('addBet', bet);
                }
            }
        },
        async cancelBet({commit, state}, bet){
            let i = _findIndex(state.bet_list, function(o) { return o.odd_id === bet.odd_id && o.odd_name === bet.odd_name; });
            commit('removeBet', i);
        },
        async cancelBetAll({commit}){
            commit('removeBetAll');
        },
        async toUpdateBet({commit, state}, ctx){
            let match_id = ctx.match_id;
            let odd_info = ctx.odd_info;
            let bet, oldVal, newVal;
            let i = _findIndex(state.bet_list, function(o) { return o.match_id === match_id; });
            if(i > -1){
                bet = state.bet_list[i];
                oldVal = bet.odds;
                if(state.accept_any_odd){
                    bet.odds = odd_info[bet.odd_name];
                }else{
                    bet.odds_changed = odd_info[bet.odd_name];
                }
                newVal = odd_info[bet.odd_name];
                commit('updateBetSamePos', {
                    bet: bet,
                    i: i
                });
            }
            return {
                bet: bet,
                newVal: newVal,
                oldVal: oldVal
            };
        },
        async clearBet({commit}){
            return commit('removeBetAll');
        },
        async favorite({commit, state}, ctx){

            let found = _find(state.favorite_list, function(o){
                return o.sport === ctx.sport && o.mode === ctx.mode && o.fav_id === ctx.fav_id
            });
            if(!found){
                commit('addFavoriteList', ctx);
            }
        },
        async unFavorite({commit, state}, ctx){
            let found = _findIndex(state.favorite_list, function(o){
                return o.sport === ctx.sport && o.mode === ctx.mode && o.fav_id === ctx.fav_id
            });
            if(found > -1){
                commit('removeFavoriteList', found);
            }
        },
        async clear_modal_info({commit}){
            commit('update_modal_message', '');
            commit('update_modal_code', '');
            commit('update_modal_mode', 'info');
        },
    },
}