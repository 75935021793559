<template>
<div class="LiffGameHome">
    <div v-if="errMessage">
        <div class="error-message">
            {{errMessage}}
        </div>
    </div>
    <div v-else>
        <Baccarat v-if="table_type === TABLE_TYPE_KEY.baccarat" 
        noChat="yes" 
        noTopSection="yes"
        @liffCloseWindow="_liffCloseWindow"></Baccarat>
        <DragonTiger v-if="table_type === TABLE_TYPE_KEY.dragontiger" 
        noChat="yes" 
        noTopSection="yes"
        @liffCloseWindow="_liffCloseWindow"></DragonTiger>
        <Sicbo v-if="table_type === TABLE_TYPE_KEY.sicbo" 
        noChat="yes" 
        noTopSection="yes"
        @liffCloseWindow="_liffCloseWindow"></Sicbo>
        <Fantan v-if="table_type === TABLE_TYPE_KEY.bean" 
        noChat="yes" 
        noTopSection="yes"
        @liffCloseWindow="_liffCloseWindow"></Fantan>
    </div>
</div>
</template>
<script>

import Baccarat from '@/components/game/MiniBaccarat'
import DragonTiger from '@/components/game/MiniDragonTiger'
import Sicbo from '@/components/game/MiniSicbo'
import Fantan from '@/components/game/MiniFantan'
import {TABLE_TYPE_KEY} from '@/components/game/Constant'
import Liff from '@/lib/Liff'

export default {
    name: 'LiffGameHome',
    components: {
        Baccarat,
        DragonTiger,
        Sicbo,
        Fantan,
    },
    data() {
        return {
            TABLE_TYPE_KEY: null,
            errMessage: '',
            liff: null,
        }
    },
    computed: {
        table_type() {
            return parseInt(this.$route.query.table_type)
        },
    },
    methods: {
        _liffCloseWindow() {
            console.log('_liffCloseWindow')
            if(!this.liff) {
                return
            }
            this.errMessage = 'ขออภัย! คุณกลับไปที่ห้องแชทเพราะว่าไม่ได้วางเดิมพันมาหลายรอบ'
            setTimeout(() => {
                this.liff.closeWindow()
            }, 5 * 1000)
        },
        async _liffInit() {
            const liffId = this.$route.query['liff-id']
            const liffInitProcess = await Liff.initLiff(liffId)
            if(!liffInitProcess.success) {
                console.log('initLiff err', liffInitProcess.message_code)
                this.errMessage = liffInitProcess.message
                return
            }
            this.liff = liffInitProcess.liff
        },
    },
    created() {
        this.TABLE_TYPE_KEY = TABLE_TYPE_KEY
    },
    async mounted() {
        this._liffInit()
    },
}
</script>
<style scoped>
.error-message {
    padding: 20px;
    text-align: center;
    font-size: 24px;
}
</style>

<style>
    .LiffGameHome .MiniBaccarat .middle-stream-info{height: 40px; padding: 5px 0;}
    .LiffGameHome .MiniBaccarat .box-panel-play-state{
        display: none;
        /*position: absolute;*/
        /*width: 100%;*/
        /*bottom: 0;*/
    }
    .LiffGameHome .MiniBaccarat .circle-wrap p{margin-bottom: 0}
    .LiffGameHome .MiniBaccarat .circle-wrap {
        position: absolute;
        right: auto;
        left: auto;
        display: flex;
        align-items: center;
        top: auto;
        width: auto;
        height: 30px;
        border-radius: 0;
        background-color: transparent;
        /*background-image: linear-gradient(to right,  rgba(0,0,0,0) 1%,rgba(201,13,130,0.65) 47%,rgba(0,0,0,0) 100%);*/
        color: #ffc200;
        text-align: center;
        z-index: 25;
        font: 800 20px/1 Athiti;
        border: 0;
        padding: 0 5px;
        margin: 0 5px ;
    }
    .LiffGameHome .MiniBaccarat .circle-wrap i{
        font-size: 11px;
        margin-right: 3px;
    }
    .LiffGameHome .MiniBaccarat .circle-wrap span.txt-status {
        font: 800 14px/30px Athiti!important;
        display: block;
        margin-top: 0;
    }
    .LiffGameHome .MiniBaccarat .box-progressBar {
        margin: 0 10px 0 10px;
    }
    .LiffGameHome .MiniBaccarat .bottom-stream{margin-top: 5px;}
    .LiffGameHome .MiniBaccarat .balance-box-total {
        font:600 12px/1 Athiti;
        padding: 3px 10px;
        color: #fff;
        margin: 3px 5px 8px 0;
        border-radius: 6px;
        background-color: transparent;
    }
    .LiffGameHome .MiniBaccarat .box-balance-game .balance-box-total:before{
        content: '\f51e';
        font: 800 12px "Font Awesome\ 5 Free";
        color: #ffc200;
        margin-right: 3px;
    }
    .LiffGameHome .MiniBaccarat .top-stream{
        border-bottom: solid 1px rgba(238, 238, 238, 0.4);
    }
    .LiffGameHome .MiniBaccarat .box-balance-game{
        display: flex;
        align-items: center;
        justify-content: space-between;
    }
    .LiffGameHome .MiniBaccarat .box-balance-game .balance-box{
        margin: 0 5px 0 0!important;
        bottom: auto!important;
        padding: 0 3px!important;
        border-radius: 6px!important;
        display: flex;
        align-items: center;
        height: 20px;

    }
    .LiffGameHome .MiniBaccarat .box-balance-game .circle-wrap,
    .LiffGameHome .MiniBaccarat .box-balance-game .balance-box{
        position: relative;
    }
    .LiffGameHome .MiniBaccarat .boxBetValue p{margin-bottom: 0}
    .LiffGameHome .MiniBaccarat .boxBetValue{
        display: flex;
    }
    .LiffGameHome .MiniBaccarat .boxBetValue span {
        display: flex;
        align-items: center;
        justify-content: center;
        border-radius: 4vw;
        color: #fab600;
        font: 600 14px/1 Athiti;
    }
</style>