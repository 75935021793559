<template>
    <div class="Keyboard type_msg">
        <!--        ยืนยันการซื้อ-->
        <div class="box-award" :class="purchase.id==undefined?'hide':''">
            <div class="box-alert-content">
                <div class="content-all">
                    <div class="content">
                        <div class="row-box-award">
                            <ul>
                                <li>
                                    <div class="item-award">
                                        <div v-if="purchase.url" class="sticker-3-1" :style="{'background-image':'url('+purchase.url+')'}"></div>
                                        <span class="Value-item">ซื้อ สติ๊กเกอร์ 1 ชุด</span>
                                    </div>
                                    <h4>{{purchase.price}}</h4>
                                </li>
                            </ul>
                        </div>
                        <div class="footer footer-center">
                            <button type="button" class="btn bg-gradient-btn" @click="purchaseSticker()">ยืนยันการซื้อ</button>
                            <button type="button" class="btn btn-outline-secondary" @click="cancelPurchase()">ยกเลิก</button>
                        </div>
                    </div>
                    <div data-v-2beaf5a2="" class="content-bg"></div>
                </div>
            </div>
        </div>
        <div class="input_msg_write">
            <div class="state-input">
                <input type="text" v-model="message" @keyup.enter="send(message)" class="write_msg" placeholder="ข้อความแชท"/>
                <button v-if="isOnDev" @click="toggleEmoji" class="sticker_btn" id="EmoBtn" type="button">
                    <i class="fas fa-smile"></i>
                </button>
                <!--                    v-if="false"-->
                <button  v-if="false"  @click="toggleSticker" class="photo_btn" type="button">
                    <i class="fas fa-sticky-note"></i>
                </button>
                <!--                    <button class="game_btn hide" type="button"><i class="fas fa-gamepad"></i></button>-->
            </div>
            <button @click="send(message)" class="msg_send_btn" type="button">
                <i class="fas fa-paper-plane"></i>
            </button>
        </div>
        <div v-if="false"    class="box-emoji" >
            <ul class="nav nav-tabs tab-select-emoji" role="tablist">
                <li>
                    <a class="active" data-toggle="tab" href="#emo01"><div class="emo-1-010"></div></a>
                </li>
                <li>
                    <a data-toggle="tab" href="#emo02"><div class="emo-2-010"></div></a>
                </li>
            </ul>
            <div class="tab-content content-sticker-chat-info">
                <div role="tabpanel" id="emo01" class="tab-pane active">
                    <div class="content-sticker-chat">
                        <div class="emo-1-01"></div>
                        <div class="emo-1-02"></div>
                        <div class="emo-1-03"></div>
                        <div class="emo-1-04"></div>
                        <div class="emo-1-05"></div>
                        <div class="emo-1-06"></div>
                        <div class="emo-1-07"></div>
                        <div class="emo-1-08"></div>
                        <div class="emo-1-09"></div>
                        <div class="emo-1-010"></div>
                        <div class="emo-1-011"></div>
                        <div class="emo-1-012"></div>
                        <div class="emo-1-013"></div>
                        <div class="emo-1-014"></div>
                        <div class="emo-1-015"></div>
                        <div class="emo-1-016"></div>
                        <div class="emo-1-017"></div>
                        <div class="emo-1-018"></div>
                        <div class="emo-1-019"></div>
                        <div class="emo-1-020"></div>
                        <div class="emo-1-021"></div>
                        <div class="emo-1-022"></div>
                        <div class="emo-1-023"></div>
                        <div class="emo-1-024"></div>
                        <div class="emo-1-025"></div>
                        <div class="emo-1-026"></div>
                        <div class="emo-1-027"></div>
                        <div class="emo-1-028"></div>
                        <div class="emo-1-029"></div>
                        <div class="emo-1-030"></div>
                        <div class="emo-1-031"></div>
                        <div class="emo-1-032"></div>
                        <div class="emo-1-033"></div>
                        <div class="emo-1-034"></div>
                        <div class="emo-1-035"></div>
                        <div class="emo-1-036"></div>
                    </div>
                </div>
                <div role="tabpanel" id="emo02" class="tab-pane fade">
                    <div class="content-sticker-chat">
                        <div class="emo-2-01"></div>
                        <div class="emo-2-02"></div>
                        <div class="emo-2-03"></div>
                        <div class="emo-2-04"></div>
                        <div class="emo-2-05"></div>
                        <div class="emo-2-06"></div>
                        <div class="emo-2-07"></div>
                        <div class="emo-2-08"></div>
                        <div class="emo-2-09"></div>
                        <div class="emo-2-010"></div>
                        <div class="emo-2-011"></div>
                        <div class="emo-2-012"></div>
                        <div class="emo-2-013"></div>
                        <div class="emo-2-014"></div>
                        <div class="emo-2-015"></div>
                        <div class="emo-2-016"></div>
                    </div>
                </div>
            </div>
        </div>
        <!--            v-show="isShowEmojiPanel"-->
        <div v-if="isAlreadyOpen" class="box-sticker" id="BoxEmoji">
            <ul class="nav nav-tabs tab-select-sticker" role="tablist">
                <li  v-for="(a,key) of sticker_list" :key="key">
                    <a :class=" key==0?'active':''" data-toggle="tab" :href="'#stk'+key">
                        <div class="sticker-1-2" :style="{'background-image':'url('+a.url+')'}">
                        </div>
                    </a>
                </li>
                <!-- <li>
                    <a class="active" data-toggle="tab" href="#stk01"><div class="sticker-1-2"></div></a>
                </li>
                <li>
                    <a  data-toggle="tab" href="#stk02"><div class="sticker-2-1"></div></a>
                </li>
                <li>
                    <a href="#stk03" data-toggle="tab"><div class="sticker-3-1"></div></a>
                </li>
                <li>
                    <a href="#stk04"  data-toggle="tab"><div class="sticker-4-1"></div></a>
                </li>
                <li>
                    <a href="#stk05" data-toggle="tab"><div class="sticker-5-1"></div></a>
                </li>
                <li>
                    <a href="#stk06" data-toggle="tab"><div class="sticker-6-1"></div></a>
                </li> -->
            </ul>
            <div class="tab-content content-sticker-chat-info">
                <div v-for="(sticker,key) of sticker_list"  :key="key" role="tabpanel" :id="'stk'+key" class="tab-pane" :class="key==0?'active':''">
                    <div class="content-sticker-chat">
                        <div v-if="sticker.type==1 && !(avatar.sticker_list.includes(sticker.id+''))" class="bg-overlay-sticker-chat">
                            <div class="buySticker-chat">
                                <div class="buySticker-chat-left">
                                    <div class="sticker-4-1" :style="{'background-image':'url('+sticker.url+')'}"></div>
                                    <div class="buySticker-chat-detail">
                                        <h3>Set{{key+1}}</h3>
                                        <span>{{sticker.price}}</span>
                                    </div>
                                    <div class="btnBuy-Sticker" @click="showConfirmPurchaseSticker(sticker)">
                                        <span>ซื้อสติ๊กเกอร์</span>
                                    </div>
                                </div>
                            </div>

                        </div>
                        <div v-for="n in parseInt(sticker.item_content.qty)" :key="n" @click="sendSticker('sticker-'+sticker.id+'-'+(n+1), {'class': 'sticker-1-1','url':sticker.item_content.folder+n+'.'+sticker.item_content.file_type })" class="sticker-1-1" :style="{'background-image':'url('+sticker.item_content.folder+n+'.'+sticker.item_content.file_type+ ')'}" ></div>
                        
                    </div>
                </div>
                <!-- <div role="tabpanel" id="stk02" class="tab-pane fade">
                    <div class="content-sticker-chat">
                        <div class="sticker-2-1"></div>
                        <div class="sticker-2-2"></div>
                        <div class="sticker-2-3"></div>
                        <div class="sticker-2-4"></div>
                        <div class="sticker-2-5"></div>
                        <div class="sticker-2-6"></div>
                        <div class="sticker-2-7"></div>
                        <div class="sticker-2-8"></div>
                        <div class="sticker-2-9"></div>
                        <div class="sticker-2-10"></div>
                        <div class="sticker-2-11"></div>
                        <div class="sticker-2-12"></div>
                        <div class="sticker-2-13"></div>
                        <div class="sticker-2-14"></div>
                        <div class="sticker-2-15"></div>
                        <div class="sticker-2-16"></div>
                        <div class="sticker-2-17"></div>
                        <div class="sticker-2-18"></div>
                        <div class="sticker-2-19"></div>
                        <div class="sticker-2-20"></div>
                        <div class="sticker-2-21"></div>
                        <div class="sticker-2-22"></div>
                        <div class="sticker-2-23"></div>
                        <div class="sticker-2-24"></div>
                        <div class="sticker-2-25"></div>
                        <div class="sticker-2-26"></div>
                        <div class="sticker-2-27"></div>
                        <div class="sticker-2-28"></div>
                    </div>
                </div>
                <div role="tabpanel" id="stk03" class="tab-pane fade">
                    <div class="content-sticker-chat">
                        <div class="sticker-3-1"></div>
                        <div class="sticker-3-2"></div>
                        <div class="sticker-3-3"></div>
                        <div class="sticker-3-4"></div>
                        <div class="sticker-3-5"></div>
                        <div class="sticker-3-6"></div>
                        <div class="sticker-3-7"></div>
                        <div class="sticker-3-8"></div>
                        <div class="sticker-3-9"></div>
                        <div class="sticker-3-10"></div>
                        <div class="sticker-3-11"></div>
                        <div class="sticker-3-12"></div>
                        <div class="sticker-3-13"></div>
                        <div class="sticker-3-14"></div>
                        <div class="sticker-3-15"></div>
                        <div class="sticker-3-16"></div>
                        <div class="sticker-3-17"></div>
                        <div class="sticker-3-18"></div>
                        <div class="sticker-3-19"></div>
                        <div class="sticker-3-20"></div>
                    </div>
                </div>
                <div role="tabpanel" id="stk04" class="tab-pane fade">
                    <div class="content-sticker-chat">
                        <div class="bg-overlay-sticker-chat">
                            <div class="buySticker-chat">
                                <div class="buySticker-chat-left">
                                    <div class="sticker-4-1"></div>
                                    <div class="buySticker-chat-detail">
                                        <h3>Set4</h3>
                                        <span>10</span>
                                    </div>
                                    <div class="btnBuy-Sticker">
                                        <span>ซื้อสติ๊กเกอร์</span>
                                    </div>
                                </div>
                            </div>

                        </div>
                        <div class="sticker-4-1"></div>
                        <div class="sticker-4-2"></div>
                        <div class="sticker-4-3"></div>
                        <div class="sticker-4-4"></div>
                        <div class="sticker-4-5"></div>
                        <div class="sticker-4-6"></div>
                        <div class="sticker-4-7"></div>
                        <div class="sticker-4-8"></div>
                    </div>
                </div>
                <div role="tabpanel" id="stk05" class="tab-pane fade">
                    <div class="content-sticker-chat">
                        <div class="bg-overlay-sticker-chat">
                            <div class="buySticker-chat">
                                <div class="buySticker-chat-left">
                                    <div class="sticker-5-1"></div>
                                    <div class="buySticker-chat-detail">
                                        <h3>Set5</h3>
                                        <span>10</span>
                                    </div>
                                    <div class="btnBuy-Sticker">
                                        <span>ซื้อสติ๊กเกอร์</span>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="sticker-5-1"></div>
                        <div class="sticker-5-2"></div>
                        <div class="sticker-5-3"></div>
                        <div class="sticker-5-4"></div>
                        <div class="sticker-5-5"></div>
                        <div class="sticker-5-6"></div>
                        <div class="sticker-5-7"></div>
                        <div class="sticker-5-8"></div>
                        <div class="sticker-5-9"></div>
                        <div class="sticker-5-10"></div>
                        <div class="sticker-5-11"></div>
                        <div class="sticker-5-12"></div>
                    </div>
                </div>
                <div role="tabpanel" id="stk06" class="tab-pane fade">
                    <div class="content-sticker-chat">
                        <div class="bg-overlay-sticker-chat">
                            <div class="buySticker-chat">
                                <div class="buySticker-chat-left">
                                    <div class="sticker-6-1"></div>
                                    <div class="buySticker-chat-detail">
                                        <h3>Set6</h3>
                                        <span>10</span>
                                    </div>
                                    <div class="btnBuy-Sticker">
                                        <span>ซื้อสติ๊กเกอร์</span>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="sticker-6-1"></div>
                        <div class="sticker-6-2"></div>
                        <div class="sticker-6-3"></div>
                        <div class="sticker-6-4"></div>
                        <div class="sticker-6-5"></div>
                        <div class="sticker-6-6"></div>
                        <div class="sticker-6-7"></div>
                        <div class="sticker-6-8"></div>
                        <div class="sticker-6-9"></div>
                        <div class="sticker-6-10"></div>
                        <div class="sticker-6-11"></div>
                        <div class="sticker-6-12"></div>
                    </div>
                </div> -->
            </div>
        </div>
    </div>

</template>

<script>

    import store from '@/store'
    import axiosWrapper from '@/axiosWrapper'
    import UserMixin from '@/mixins/UserMixin'

    export default {
        name: 'Keyboard',
        props: ['socket_status'],
        mixins: [
            UserMixin,
        ],
        data(){
            return {
                isShowEmojiPanel: false,
                message: '',
                sticker_list:[],
                avatar:{
                        bg:null,
                        bg_list:{},
                        sticker_list:[]
                },
                purchase :{},
                isAlreadyOpen: false,
            }
        },
        computed: {
            is_member(){
                return store.state.is_member
            },
                pes_profile() {
                    return store.state.pes_profile?store.state.pes_profile:{
                        sp_bonus_item:{
                            spiner:{}
                        }
                    }
                },
                token() {
                    return  this.pes_profile.token?this.pes_profile.token:null;
                },
            user_profile() {
                return store.state.user_profile
            },
            
        },
        watch: {
            isShowEmojiPanel(newVal) {
                this.$emit('isShowEmojiPanelChange', newVal)
            },
        },
        methods: {
            toggleEmoji(){
                if(!this.is_member && this.socket_status !== 'open'){
                    this.notifyToSignUp('เพื่อส่งสติกเกอร์')
                    return
                }
                this.isShowEmojiPanel = !this.isShowEmojiPanel
                if(this.isShowEmojiPanel && !this.isAlreadyOpen) {
                    this.isAlreadyOpen = true
                }
            },
            send(message){
                if(this.is_member && this.socket_status !== 'open'){
                    return
                }
                this.$emit('send', {
                    type: 'text',
                    message: message,
                    detail: '',
                })
                this.message = ''
            },
            sendSticker(message, detail) {
                if(this.is_member && this.socket_status !== 'open'){
                    return
                }
                this.$emit('send', {
                    type: 'sticker',
                    message: message,
                    detail: detail,
                })
            },
            async getAvatar() {
                    const res = await axiosWrapper({
                        method: 'get',
                        url: `${this.envDomains.VUE_APP_PES_BASE_URL}/api/game/v1/avatar/bg/list.php?token=`+this.token,
                    });
                    return res.data.data;
            },
            showConfirmPurchaseSticker(sticker){
                this.purchase = sticker;
            },
            cancelPurchase(){
                this.purchase={};
            },
            async purchaseSticker(){
                const res = await axiosWrapper({
                            method: 'post',
                            url: `${this.envDomains.VUE_APP_PES_BASE_URL}/api/game/v1/avatar/bg/purchase.php?token=`+this.token+'&ref_id='+this.purchase.id,
                });
                const response= res.data;

                if(response.code==1){
                    this.avatar.sticker_list.push(this.purchase.id+"");
                    // console.log(this.avatar.sticker_list);
                    this.pes_profile.avatar =this.avatar;
                    this.update_pes_data();
                    this.purchase={};

                }else{
                    alert(response.msg);
                }
            },
            update_pes_data(){
                store.commit('updatePesProfile', this.pes_profile);
            },

            
        },
        async mounted(){
                let avatar =this.pes_profile.sp_bonus_item.avatar;
                // console.log(this.pes_profile.sp_bonus_item);
                if(!avatar){
                    avatar = {
                        bg:null,
                        bg_list:{},
                        sticker_list:[]
                    }
                }else if(!avatar.sticker_list){
                    avatar.sticker_list=[];
                }
                // console.log(avatar);
                this.avatar = avatar;
            
                var avatar_list = await this.getAvatar();
                for(let i=0;i<avatar_list.length;i++){
                    if(avatar_list[i].item_type==1){
                        this.sticker_list.push(avatar_list[i]);
                    }
                }
        }
    }

    // $('#myTabs a').click(function (e) {
    //     e.preventDefault()
    //     $(this).tab('show')
    // })
</script>
<style scoped>
.input_msg_write {
    padding: 8px 10px;
}
</style>