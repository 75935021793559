export const ALL_PROVIDER_LIST = [
    {
        slug: 'SAGAME',
        label: 'SA Gaming',
        image: '/images/slot/provider/SAGaming-icon.png',
        wrapperClass: 'GameList-box-wide',
        perPage : 2.2,
        perPageCustom : [[768, 4], [1400, 4]]
    },
    {
        slug: 'SEXY',
        label: 'Sexy Baccarat',
        image: '/images/slot/provider/SexyBaccarat-icon.png',
        wrapperClass: 'GameList-box-wide',
    },
    {
        slug: 'PRETTY',
        label: 'Pretty',
        image: '/images/slot/provider/PRETTY.png',
        wrapperClass: 'GameList-box-wide',
    },
    {
        slug: 'EVO',
        label: 'Evolution Gaming',
        image: '/images/slot/provider/EvolutionGaming.png',
        wrapperClass: 'GameList-box-wide',
    },
    {
        slug: 'PGSOFT',
        label: 'PGSOFT',
        image: '/images/slot/provider/PGSOFT.png',
        wrapperClass: 'GameList-box-square',
        perPage : 2.2,
        perPageCustom : [[768, 5], [1400, 5]]
    },
    {
        slug: 'BPG',
        label: 'Blueprint',
        image: '/images/slot/provider/Blueprint.png',
        wrapperClass: 'GameList-box-wide',
        perPage : 1.5,
        perPageCustom : [[768, 4], [1400,4]]
    },
    {
        slug: 'MICRO',
        label: 'Micro Gaming',
        image: '/images/slot/provider/MicroGaming.png',
        wrapperClass: 'GameList-box-square',
        perPage : 1.5,
        perPageCustom : [[768, 4], [1400,4]]
    },
    {
        slug: 'CQ9',
        label: 'CQ9',
        image: '/images/slot/provider/CQ9.png',
        wrapperClass: 'GameList-box-square',
    },
    {
        slug: 'RLX',
        label: 'Relax Gaming',
        image: '/images/slot/provider/RelaxGaming.png',
        wrapperClass: 'GameList-box-wide',
        perPage : 1.5 ,
        perPageCustom : [[768, 3], [1400,3]]
    },
    {
        slug: 'PRAGMATIC',
        label: 'Pragmatic Play',
        image: '/images/slot/provider/PragmaticPlay.png',
        wrapperClass: 'GameList-box-wide',
        perPage : 2.2,
        perPageCustom : [[768, 4], [1400, 4]]
    },
    {
        slug: 'AMBPOKER',
        label: 'Ambpoker',
        image: '/images/slot/provider/AmbPoker.png',
        wrapperClass: 'GameList-box-square',
        perPage : 4.2 ,
        perPageCustom : [[768, 4], [1400,4]]
    },
    {
        slug: 'JILI',
        label: 'JILI',
        image: '/images/slot/provider/JILI.png',
        wrapperClass: 'GameList-box-square',
    },
    {
        slug: 'SLOTXO',
        label: 'SLOTXO',
        image: '/images/slot/provider/SLOTXO.png',
        wrapperClass: 'GameList-box-square',
    },
    {
        slug: 'DS',
        label: 'Dragoon Soft',
        image: '/images/slot/provider/DragoonSoft.png',
        wrapperClass: 'GameList-box-wide',
    },
    {
        slug: 'NE',
        label: 'NETENT',
        image: '/images/slot/provider/NETENT.png',
        wrapperClass: 'GameList-box-wide',
    },
    {
        slug: 'YGG',
        label: 'Yggdrasil',
        image: '/images/slot/provider/Yggdrasil.png',
        wrapperClass: 'GameList-box-wide',
        perPage : 1.5,
        perPageCustom : [[768, 3], [1400,3]]
    },
/////////////////////////////////////////////////////////////////////////
    {
        slug: '1X2',
        label: '1X2 Gaming',
        image: '/images/slot/provider/1X2gaming.png',
        wrapperClass: 'GameList-box-wide',
    },


    {
        slug: 'AUG',
        label: 'August Gaming',
        image: '/images/slot/provider/AugustGaming.png',
        wrapperClass: 'GameList-box-wide',
    },
    {
        slug: 'BBG',
        label: 'BB Games',
        image: '/images/slot/provider/BBGames.png',
        wrapperClass: 'GameList-box-wide',
    },
    {
        slug: 'BTV',
        label: 'BetGames TV',
        image: '/images/slot/provider/BetGamesTV.png',
        wrapperClass: 'GameList-box-wide',
    },
    {
        slug: 'ELK',
        label: 'ELK Studio',
        image: '/images/slot/provider/Elk.png',
        wrapperClass: 'GameList-box-wide',
    },
    {
        slug: 'EVP',
        label: 'EvoPlay',
        image: '/images/slot/provider/EvoPlay.png',
        wrapperClass: 'GameList-box-wide',
    },
    {
        slug: 'EZU',
        label: 'Ezugi',
        image: '/images/slot/provider/EZUGI.png',
        wrapperClass: 'GameList-box-wide',
    },
    {
        slug: 'GA',
        label: 'GameArt',
        image: '/images/slot/provider/GameArt.png',
        wrapperClass: 'GameList-box-wide',
    },
    {
        slug: 'GFG',
        label: 'Gamefish Global',
        image: '/images/slot/provider/Gamefish.png',
        wrapperClass: 'GameList-box-wide',
    },
    {
        slug: 'HAK',
        label: 'Hacksaw Gaming',
        image: '/images/slot/provider/HacksawGaming.png',
        wrapperClass: 'GameList-box-wide',
    },
    {
        slug: 'IDS',
        label: 'Iron Dog Studio',
        image: '/images/slot/provider/IronDog.png',
        wrapperClass: 'GameList-box-wide',
    },
    {
        slug: 'KIR',
        label: 'Kiron',
        image: '/images/slot/provider/Kiron.png',
        wrapperClass: 'GameList-box-wide',
    },
    {
        slug: 'LL',
        label: 'Lady Luck',
        image: '/images/slot/provider/LadyLuck.png',
        wrapperClass: 'GameList-box-wide',
    },
    {
        slug: 'MAV',
        label: 'Maverick',
        image: '/images/slot/provider/Maverick.png',
        wrapperClass: 'GameList-box-wide',
    },
    {
        slug: 'MOB',
        label: 'Mobilots',
        image: '/images/slot/provider/Mobilots.png',
        wrapperClass: 'GameList-box-wide',
    },
    {
        slug: 'NLC',
        label: 'Nolimit City',
        image: '/images/slot/provider/NolimitCity.png',
        wrapperClass: 'GameList-box-wide',
    },
    {
        slug: 'OMI',
        label: 'OMI Gaming',
        image: '/images/slot/provider/OMIGaming.png',
        wrapperClass: 'GameList-box-wide',
    },
    {
        slug: 'OT',
        label: 'One Touch',
        image: '/images/slot/provider/OneTouch.png',
        wrapperClass: 'GameList-box-wide',
    },
    {
        slug: 'PP',
        label: 'Playpearls',
        image: '/images/slot/provider/Playpearls.png',
        wrapperClass: 'GameList-box-wide',
    },
    {
        slug: 'QS',
        label: 'Quickspin',
        image: '/images/slot/provider/Quickspin.png',
        wrapperClass: 'GameList-box-wide',
    },
    {
        slug: 'RG',
        label: 'Realtime gaming',
        image: '/images/slot/provider/RealtimeGaming.png',
        wrapperClass: 'GameList-box-wide',
    },
    {
        slug: 'RTG',
        label: 'Red Tiger',
        image: '/images/slot/provider/RedTiger.png',
        wrapperClass: 'GameList-box-wide',
    },
    {
        slug: 'SM',
        label: 'Slotmill',
        image: '/images/slot/provider/Slotmill.png',
        wrapperClass: 'GameList-box-wide',
    },
    {
        slug: 'SPR',
        label: 'Spearhead Studios',
        image: '/images/slot/provider/Spearhead.png',
        wrapperClass: 'GameList-box-wide',
    },
    {
        slug: 'TK',
        label: 'Thunderkick',
        image: '/images/slot/provider/Thunderkick.png',
        wrapperClass: 'GameList-box-wide',
    },
    {
        slug: 'WOO',
        label: 'Woohoo Games',
        image: '/images/slot/provider/WoohooGames.png',
        wrapperClass: 'GameList-box-wide',
    },
    {
        slug: 'HAB',
        label: 'HABANERO',
        image: '/images/slot/provider/Habanero.png',
        wrapperClass: 'GameList-box-wide',
    },
    {
        slug: 'SIMPLEPLAY',
        label: 'Simple Play',
        image: '/images/slot/provider/SimplePlay.png',
        wrapperClass: 'GameList-box-square',
    },
    {
        slug: 'FNG',
        label: 'Funky Games',
        image: '/images/slot/provider/FunkyGames.png',
        wrapperClass: 'GameList-box-wide',
    },
    // {
    //     slug: '',
    //     label: 'Boomerang',
    //     image: '/images/slot/provider/Boomerang.png',
    // },
    // {
    //     slug: '',
    //     label: 'Booongo',
    //     image: '/images/slot/provider/Booongo.png',
    // },
    // {
    //     slug: '',
    //     label: 'Chance Interactive',
    //     image: '/images/slot/provider/ChanceInteractive.png',
    // },
    // {
    //     slug: '',
    //     label: 'Cubix Gaming',
    //     image: '/images/slot/provider/CubixGaming.png',
    // },


    // {
    //     slug: '',
    //     label: 'Esports Construct',
    //     image: '/images/slot/provider/EsportsConstruct.png',
    // },

    // {
    //     slug: '',
    //     label: 'Fantasma Games',
    //     image: '/images/slot/provider/FantasmaGames.png',
    // },
    // {
    //     slug: '',
    //     label: 'Fugaso',
    //     image: '/images/slot/provider/Fugaso.png',
    // },

    // {
    //     slug: '',
    //     label: 'Games Lab',
    //     image: '/images/slot/provider/GamesLab.png',
    // },

    // {
    //     slug: '',
    //     label: 'Kalamba Games',
    //     image: '/images/slot/provider/kalambagames.png',
    // },

    // {
    //     slug: '',
    //     label: 'Lightning Box',
    //     image: '/images/slot/provider/LightningBox.png',
    // },

    // {
    //     slug: '',
    //     label: 'Merkur Sun',
    //     image: '/images/slot/provider/MerkurSun.png',
    // },


    // {
    //     slug: '',
    //     label: 'NetGame',
    //     image: '/images/slot/provider/Netgame.png',
    // },

    // {
    //     slug: '',
    //     label: 'PLAY’n GO',
    //     image: '/images/slot/provider/PLAYnGO.png',
    // },

    // {
    //     slug: '',
    //     label: 'Playson',
    //     image: '/images/slot/provider/Playson.png',
    // },
    // {
    //     slug: '',
    //     label: 'Push Gaming',
    //     image: '/images/slot/provider/PushGaming.png',
    // },

    // {
    //     slug: '',
    //     label: 'Revolver Gaming',
    //     image: '/images/slot/provider/RevolverGaming.png',
    // },
    // {
    //     slug: '',
    //     label: 'Skywind Casino',
    //     image: '/images/slot/provider/SkywindCasino.png',
    // },

    // {
    //     slug: '',
    //     label: 'Snowborn',
    //     image: '/images/slot/provider/Snowborn.png',
    // },

    // {
    //     slug: '',
    //     label: 'Splitrock Gaming',
    //     image: '/images/slot/provider/SplitrockGaming.png',
    // },


    // {
    //     slug: '',
    //     label: 'ASKMEBET',
    //     image: '/images/slot/provider/ASKMEBET.png',
    // },

    // {
    //     slug: '',
    //     label: 'SPADE Gaming',
    //     image: '/images/slot/provider/SPADEGaming.png',
    // },

    // {
    //     slug: '',
    //     label: 'Iconic Gaming',
    //     image: '/images/slot/provider/IconicGaming.png',
    // },
    // {
    //     slug: '',
    //     label: 'Mega888',
    //     image: '/images/slot/provider/mega888.png',
    // },
    // {
    //     slug: '',
    //     label: 'Xin Gaming',
    //     image: '/images/slot/provider/XinGaming.png',
    // },
    // {
    //     slug: '',
    //     label: 'Live22',
    //     image: '/images/slot/provider/Live22.png',
    // },
    // {
    //     slug: '',
    //     label: 'Gamatron',
    //     image: '/images/slot/provider/Gamatron.png',
    // },
    // {
    //     slug: '',
    //     label: 'Allwayspin',
    //     image: '/images/slot/provider/Allwayspin.png',
    // },
    // {
    //     slug: '',
    //     label: 'Eurasian Gaming',
    //     image: '/images/slot/provider/EurasianGaming.png',
    // },
    // {
    //     slug: '',
    //     label: 'JDB',
    //     image: '/images/slot/provider/JDB.png',
    // },
    // {
    //     slug: '',
    //     label: 'WAZDAN',
    //     image: '/images/slot/provider/WAZDAN.png',
    // },

    // {
    //     slug: '',
    //     label: 'ENDORPHINA',
    //     image: '/images/slot/provider/ENDORPHINA.png',
    // },
    // {
    //     slug: '',
    //     label: 'KA Gaming',
    //     image: '/images/slot/provider/KAGaming.png',
    // },
    // {
    //     slug: '',
    //     label: 'Joker123',
    //     image: '/images/slot/provider/Joker123.png',
    // },
    // {
    //     slug: '',
    //     label: 'Mannaplay',
    //     image: '/images/slot/provider/Mannaplay.png',
    // },

    // {
    //     slug: '',
    //     label: 'Slotmill',
    //     image: '/images/slot/provider/Slotmill.png',
    // },
    // {
    //     slug: '',
    //     label: 'UPG Slot',
    //     image: '/images/slot/provider/UPGSlot.png',
    // },
    // {
    //     slug: '',
    //     label: 'Gioco Plus',
    //     image: '/images/slot/provider/GiocoPlus.png',
    // },
    // {
    //     slug: '',
    //     label: 'AMEBA',
    //     image: '/images/slot/provider/AMEBA.png',
    // },
]

export const PROVIDER_LIST = [
    {
        slug: 'PGSOFT',
        label: 'PGSOFT',
        image: '/images/slot/provider/PGSOFT.png',
        perPage : 3.2
    },
    {
        slug: 'BPG',
        label: 'Blueprint',
        image: '/images/slot/provider/Blueprint.png',
        wrapperClass: 'GameList-box-wide',
        perPage : 2.3
    },
    {
        slug: 'PRAGMATIC',
        label: 'Pragmatic Play',
        image: '/images/slot/provider/PragmaticPlay.png',
        wrapperClass: 'GameList-box-wide',
        perPage : 2.8
    },

    {
        slug: 'CQ9',
        label: 'CQ9',
        image: '/images/slot/provider/CQ9.png',
    },
    {
        slug: 'RLX',
        label: 'Relax Gaming',
        image: '/images/slot/provider/RelaxGaming.png',
        wrapperClass: 'GameList-box-wide',
        perPage : 2.3
    },
    {
        slug: 'MICRO',
        label: 'Micro Gaming',
        image: '/images/slot/provider/MicroGaming.png',
        perPage : 3.2
    },
    {
        slug: 'AMBPOKER',
        label: 'Ambpoker',
        image: '/images/slot/provider/AmbPoker.png',
        wrapperClass: 'GameList-box-vertical',
        perPage : 4.2
    },
    {
        slug: 'JILI',
        label: 'JILI',
        image: '/images/slot/provider/JILI.png',
        perPage : 3.2
    },
    {
        slug: 'SLOTXO',
        label: 'SLOTXO',
        image: '/images/slot/provider/SLOTXO.png',
    },
    {
        slug: 'DS',
        label: 'Dragoon Soft',
        image: '/images/slot/provider/DragoonSoft.png',
        wrapperClass: 'GameList-box-wide',
    },
    {
        slug: 'NE',
        label: 'NETENT',
        image: '/images/slot/provider/NETENT.png',
        wrapperClass: 'GameList-box-wide',
    },
    {
        slug: 'YGG',
        label: 'Yggdrasil',
        image: '/images/slot/provider/Yggdrasil.png',
        wrapperClass: 'GameList-box-wide',
    },
/////////////////////////////////////////////////////////////////////////
    {
        slug: '1x2',
        label: '1X2 Gaming',
        image: '/images/slot/provider/1X2gaming.png',
        wrapperClass: 'GameList-box-wide',
    },


    {
        slug: 'AUG',
        label: 'August Gaming',
        image: '/images/slot/provider/AugustGaming.png',
        wrapperClass: 'GameList-box-wide',
    },
    {
        slug: 'BBG',
        label: 'BB Games',
        image: '/images/slot/provider/BBGames.png',
        wrapperClass: 'GameList-box-wide',
    },
    {
        slug: 'BTV',
        label: 'BetGames TV',
        image: '/images/slot/provider/BetGamesTV.png',
        wrapperClass: 'GameList-box-wide',
    },
    {
        slug: 'ELK',
        label: 'ELK Studio',
        image: '/images/slot/provider/Elk.png',
        wrapperClass: 'GameList-box-wide',
    },
    {
        slug: 'EVP',
        label: 'EvoPlay',
        image: '/images/slot/provider/EvoPlay.png',
        wrapperClass: 'GameList-box-wide',
    },
    {
        slug: 'EZU',
        label: 'Ezugi',
        image: '/images/slot/provider/EZUGI.png',
        wrapperClass: 'GameList-box-wide',
    },
    {
        slug: 'GA',
        label: 'GameArt',
        image: '/images/slot/provider/GameArt.png',
        wrapperClass: 'GameList-box-wide',
    },
    {
        slug: 'GFG',
        label: 'Gamefish Global',
        image: '/images/slot/provider/Gamefish.png',
        wrapperClass: 'GameList-box-wide',
    },
    {
        slug: 'HAK',
        label: 'Hacksaw Gaming',
        image: '/images/slot/provider/HacksawGaming.png',
        wrapperClass: 'GameList-box-wide',
    },
    {
        slug: 'IDS',
        label: 'Iron Dog Studio',
        image: '/images/slot/provider/IronDog.png',
        wrapperClass: 'GameList-box-wide',
    },
    {
        slug: 'KIR',
        label: 'Kiron',
        image: '/images/slot/provider/Kiron.png',
        wrapperClass: 'GameList-box-wide',
    },
    {
        slug: 'LL',
        label: 'Lady Luck',
        image: '/images/slot/provider/LadyLuck.png',
        wrapperClass: 'GameList-box-wide',
    },
    {
        slug: 'MAV',
        label: 'Maverick',
        image: '/images/slot/provider/Maverick.png',
        wrapperClass: 'GameList-box-wide',
    },
    {
        slug: 'MOB',
        label: 'Mobilots',
        image: '/images/slot/provider/Mobilots.png',
        wrapperClass: 'GameList-box-wide',
    },
    {
        slug: 'NLC',
        label: 'Nolimit City',
        image: '/images/slot/provider/NolimitCity.png',
        wrapperClass: 'GameList-box-wide',
    },
    {
        slug: 'OMI',
        label: 'OMI Gaming',
        image: '/images/slot/provider/OMIGaming.png',
        wrapperClass: 'GameList-box-wide',
    },
    {
        slug: 'OT',
        label: 'One Touch',
        image: '/images/slot/provider/OneTouch.png',
        wrapperClass: 'GameList-box-wide',
    },
    {
        slug: 'PP',
        label: 'Playpearls',
        image: '/images/slot/provider/Playpearls.png',
        wrapperClass: 'GameList-box-wide',
    },
    {
        slug: 'QS',
        label: 'Quickspin',
        image: '/images/slot/provider/Quickspin.png',
        wrapperClass: 'GameList-box-wide',
    },
    {
        slug: 'RG',
        label: 'Realtime gaming',
        image: '/images/slot/provider/RealtimeGaming.png',
        wrapperClass: 'GameList-box-wide',
    },
    {
        slug: 'RTG',
        label: 'Red Tiger',
        image: '/images/slot/provider/RedTiger.png',
        wrapperClass: 'GameList-box-wide',
    },
    {
        slug: 'SM',
        label: 'Slotmill',
        image: '/images/slot/provider/Slotmill.png',
        wrapperClass: 'GameList-box-wide',
    },
    {
        slug: 'SPR',
        label: 'Spearhead Studios',
        image: '/images/slot/provider/Spearhead.png',
        wrapperClass: 'GameList-box-wide',
    },
    {
        slug: 'TK',
        label: 'Thunderkick',
        image: '/images/slot/provider/Thunderkick.png',
        wrapperClass: 'GameList-box-wide',
    },
    {
        slug: 'WOO',
        label: 'Woohoo Games',
        image: '/images/slot/provider/WoohooGames.png',
        wrapperClass: 'GameList-box-wide',
    },
    {
        slug: 'HAB',
        label: 'HABANERO',
        image: '/images/slot/provider/Habanero.png',
        wrapperClass: 'GameList-box-wide',
    },
    {
        slug: 'SIMPLEPLAY',
        label: 'Simple Play',
        image: '/images/slot/provider/SimplePlay.png',
        wrapperClass: 'GameList-box-square',
    },
    {
        slug: 'FNG',
        label: 'Funky Games',
        image: '/images/slot/provider/FunkyGames.png',
        wrapperClass: 'GameList-box-wide',
    },
    // {
    //     slug: '',
    //     label: 'Boomerang',
    //     image: '/images/slot/provider/Boomerang.png',
    // },
    // {
    //     slug: '',
    //     label: 'Booongo',
    //     image: '/images/slot/provider/Booongo.png',
    // },
    // {
    //     slug: '',
    //     label: 'Chance Interactive',
    //     image: '/images/slot/provider/ChanceInteractive.png',
    // },
    // {
    //     slug: '',
    //     label: 'Cubix Gaming',
    //     image: '/images/slot/provider/CubixGaming.png',
    // },


    // {
    //     slug: '',
    //     label: 'Esports Construct',
    //     image: '/images/slot/provider/EsportsConstruct.png',
    // },

    // {
    //     slug: '',
    //     label: 'Fantasma Games',
    //     image: '/images/slot/provider/FantasmaGames.png',
    // },
    // {
    //     slug: '',
    //     label: 'Fugaso',
    //     image: '/images/slot/provider/Fugaso.png',
    // },

    // {
    //     slug: '',
    //     label: 'Games Lab',
    //     image: '/images/slot/provider/GamesLab.png',
    // },

    // {
    //     slug: '',
    //     label: 'Kalamba Games',
    //     image: '/images/slot/provider/kalambagames.png',
    // },

    // {
    //     slug: '',
    //     label: 'Lightning Box',
    //     image: '/images/slot/provider/LightningBox.png',
    // },

    // {
    //     slug: '',
    //     label: 'Merkur Sun',
    //     image: '/images/slot/provider/MerkurSun.png',
    // },


    // {
    //     slug: '',
    //     label: 'NetGame',
    //     image: '/images/slot/provider/Netgame.png',
    // },

    // {
    //     slug: '',
    //     label: 'PLAY’n GO',
    //     image: '/images/slot/provider/PLAYnGO.png',
    // },

    // {
    //     slug: '',
    //     label: 'Playson',
    //     image: '/images/slot/provider/Playson.png',
    // },
    // {
    //     slug: '',
    //     label: 'Push Gaming',
    //     image: '/images/slot/provider/PushGaming.png',
    // },

    // {
    //     slug: '',
    //     label: 'Revolver Gaming',
    //     image: '/images/slot/provider/RevolverGaming.png',
    // },
    // {
    //     slug: '',
    //     label: 'Skywind Casino',
    //     image: '/images/slot/provider/SkywindCasino.png',
    // },

    // {
    //     slug: '',
    //     label: 'Snowborn',
    //     image: '/images/slot/provider/Snowborn.png',
    // },

    // {
    //     slug: '',
    //     label: 'Splitrock Gaming',
    //     image: '/images/slot/provider/SplitrockGaming.png',
    // },


    // {
    //     slug: '',
    //     label: 'ASKMEBET',
    //     image: '/images/slot/provider/ASKMEBET.png',
    // },

    // {
    //     slug: '',
    //     label: 'SPADE Gaming',
    //     image: '/images/slot/provider/SPADEGaming.png',
    // },

    // {
    //     slug: '',
    //     label: 'Iconic Gaming',
    //     image: '/images/slot/provider/IconicGaming.png',
    // },
    // {
    //     slug: '',
    //     label: 'Mega888',
    //     image: '/images/slot/provider/mega888.png',
    // },
    // {
    //     slug: '',
    //     label: 'Xin Gaming',
    //     image: '/images/slot/provider/XinGaming.png',
    // },
    // {
    //     slug: '',
    //     label: 'Live22',
    //     image: '/images/slot/provider/Live22.png',
    // },
    // {
    //     slug: '',
    //     label: 'Gamatron',
    //     image: '/images/slot/provider/Gamatron.png',
    // },
    // {
    //     slug: '',
    //     label: 'Allwayspin',
    //     image: '/images/slot/provider/Allwayspin.png',
    // },
    // {
    //     slug: '',
    //     label: 'Eurasian Gaming',
    //     image: '/images/slot/provider/EurasianGaming.png',
    // },
    // {
    //     slug: '',
    //     label: 'JDB',
    //     image: '/images/slot/provider/JDB.png',
    // },
    // {
    //     slug: '',
    //     label: 'WAZDAN',
    //     image: '/images/slot/provider/WAZDAN.png',
    // },

    // {
    //     slug: '',
    //     label: 'ENDORPHINA',
    //     image: '/images/slot/provider/ENDORPHINA.png',
    // },
    // {
    //     slug: '',
    //     label: 'KA Gaming',
    //     image: '/images/slot/provider/KAGaming.png',
    // },
    // {
    //     slug: '',
    //     label: 'Joker123',
    //     image: '/images/slot/provider/Joker123.png',
    // },
    // {
    //     slug: '',
    //     label: 'Mannaplay',
    //     image: '/images/slot/provider/Mannaplay.png',
    // },

    // {
    //     slug: '',
    //     label: 'Slotmill',
    //     image: '/images/slot/provider/Slotmill.png',
    // },
    // {
    //     slug: '',
    //     label: 'UPG Slot',
    //     image: '/images/slot/provider/UPGSlot.png',
    // },
    // {
    //     slug: '',
    //     label: 'Gioco Plus',
    //     image: '/images/slot/provider/GiocoPlus.png',
    // },
    // {
    //     slug: '',
    //     label: 'AMEBA',
    //     image: '/images/slot/provider/AMEBA.png',
    // },
]

export const RECOMMEND_PROVIDER_LIST = [
    // {
    //     slug: 'PGSOFT',
    //     label: 'PGSOFT',
    //     image: '/images/slot/provider/PGSOFT.png',
    //     perPage : 3.2
    // },
    // {
    //     slug: 'BPG',
    //     label: 'Blueprint',
    //     image: '/images/slot/provider/Blueprint.png',
    //     wrapperClass: 'GameList-box-wide',
    //     perPage : 2.3
    // },
    // {
    //     slug: 'PRAGMATIC',
    //     label: 'Pragmatic Play',
    //     image: '/images/slot/provider/PragmaticPlay.png',
    //     wrapperClass: 'GameList-box-wide',
    //     perPage : 2.8
    // },
    //
    // {
    //     slug: 'CQ9',
    //     label: 'CQ9',
    //     image: '/images/slot/provider/CQ9.png',
    // },
    // {
    //     slug: 'RLX',
    //     label: 'Relax Gaming',
    //     image: '/images/slot/provider/RelaxGaming.png',
    //     wrapperClass: 'GameList-box-wide',
    //     perPage : 2.3
    // },
    // {
    //     slug: 'MICRO',
    //     label: 'Micro Gaming',
    //     image: '/images/slot/provider/MicroGaming.png',
    //     perPage : 3.2
    // },
    // {
    //     slug: 'AMBPOKER',
    //     label: 'Ambpoker',
    //     image: '/images/slot/provider/AmbPoker.png',
    //     wrapperClass: 'GameList-box-vertical',
    //     perPage : 4.2
    // },
    // {
    //     slug: 'JILI',
    //     label: 'JILI',
    //     image: '/images/slot/provider/JILI.png',
    //     perPage : 3.2
    // },
    // {
    //     slug: 'SLOTXO',
    //     label: 'SLOTXO',
    //     image: '/images/slot/provider/SLOTXO.png',
    // },
    // {
    //     slug: 'DS',
    //     label: 'Dragoon Soft',
    //     image: '/images/slot/provider/DragoonSoft.png',
    //     wrapperClass: 'GameList-box-wide',
    // },
    // {
    //     slug: 'NE',
    //     label: 'NETENT',
    //     image: '/images/slot/provider/NETENT.png',
    //     wrapperClass: 'GameList-box-wide',
    // },
    // {
    //     slug: 'YGG',
    //     label: 'Yggdrasil',
    //     image: '/images/slot/provider/Yggdrasil.png',
    //     wrapperClass: 'GameList-box-wide',
    // },
/////////////////////////////////////////////////////////////////////////
    {
        slug: '1x2',
        label: '1X2 Gaming',
        image: '/images/slot/provider/1X2gaming.png',
        wrapperClass: 'GameList-box-wide',
    },


    {
        slug: 'AUG',
        label: 'August Gaming',
        image: '/images/slot/provider/AugustGaming.png',
        wrapperClass: 'GameList-box-wide',
    },
    {
        slug: 'BBG',
        label: 'BB Games',
        image: '/images/slot/provider/BBGames.png',
        wrapperClass: 'GameList-box-wide',
    },
    {
        slug: 'BTV',
        label: 'BetGames TV',
        image: '/images/slot/provider/BetGamesTV.png',
        wrapperClass: 'GameList-box-wide',
    },
    {
        slug: 'ELK',
        label: 'ELK Studio',
        image: '/images/slot/provider/Elk.png',
        wrapperClass: 'GameList-box-wide',
    },
    {
        slug: 'EVP',
        label: 'EvoPlay',
        image: '/images/slot/provider/EvoPlay.png',
        wrapperClass: 'GameList-box-wide',
    },
    {
        slug: 'EZU',
        label: 'Ezugi',
        image: '/images/slot/provider/EZUGI.png',
        wrapperClass: 'GameList-box-wide',
    },
    {
        slug: 'GA',
        label: 'GameArt',
        image: '/images/slot/provider/GameArt.png',
        wrapperClass: 'GameList-box-wide',
    },
    {
        slug: 'GFG',
        label: 'Gamefish Global',
        image: '/images/slot/provider/Gamefish.png',
        wrapperClass: 'GameList-box-wide',
    },
    {
        slug: 'HAK',
        label: 'Hacksaw Gaming',
        image: '/images/slot/provider/HacksawGaming.png',
        wrapperClass: 'GameList-box-wide',
    },
    {
        slug: 'IDS',
        label: 'Iron Dog Studio',
        image: '/images/slot/provider/IronDog.png',
        wrapperClass: 'GameList-box-wide',
    },
    {
        slug: 'KIR',
        label: 'Kiron',
        image: '/images/slot/provider/Kiron.png',
        wrapperClass: 'GameList-box-wide',
    },
    {
        slug: 'LL',
        label: 'Lady Luck',
        image: '/images/slot/provider/LadyLuck.png',
        wrapperClass: 'GameList-box-wide',
    },
    {
        slug: 'MAV',
        label: 'Maverick',
        image: '/images/slot/provider/Maverick.png',
        wrapperClass: 'GameList-box-wide',
    },
    {
        slug: 'MOB',
        label: 'Mobilots',
        image: '/images/slot/provider/Mobilots.png',
        wrapperClass: 'GameList-box-wide',
    },
    {
        slug: 'NLC',
        label: 'Nolimit City',
        image: '/images/slot/provider/NolimitCity.png',
        wrapperClass: 'GameList-box-wide',
    },
    {
        slug: 'OMI',
        label: 'OMI Gaming',
        image: '/images/slot/provider/OMIGaming.png',
        wrapperClass: 'GameList-box-wide',
    },
    {
        slug: 'OT',
        label: 'One Touch',
        image: '/images/slot/provider/OneTouch.png',
        wrapperClass: 'GameList-box-wide',
    },
    {
        slug: 'PP',
        label: 'Playpearls',
        image: '/images/slot/provider/Playpearls.png',
        wrapperClass: 'GameList-box-wide',
    },
    {
        slug: 'QS',
        label: 'Quickspin',
        image: '/images/slot/provider/Quickspin.png',
        wrapperClass: 'GameList-box-wide',
    },
    {
        slug: 'RG',
        label: 'Realtime gaming',
        image: '/images/slot/provider/RealtimeGaming.png',
        wrapperClass: 'GameList-box-wide',
    },
    {
        slug: 'RTG',
        label: 'Red Tiger',
        image: '/images/slot/provider/RedTiger.png',
        wrapperClass: 'GameList-box-wide',
    },
    {
        slug: 'SM',
        label: 'Slotmill',
        image: '/images/slot/provider/Slotmill.png',
        wrapperClass: 'GameList-box-wide',
    },
    {
        slug: 'SPR',
        label: 'Spearhead Studios',
        image: '/images/slot/provider/Spearhead.png',
        wrapperClass: 'GameList-box-wide',
    },
    {
        slug: 'TK',
        label: 'Thunderkick',
        image: '/images/slot/provider/Thunderkick.png',
        wrapperClass: 'GameList-box-wide',
    },
    {
        slug: 'WOO',
        label: 'Woohoo Games',
        image: '/images/slot/provider/WoohooGames.png',
        wrapperClass: 'GameList-box-wide',
    },
    {
        slug: 'HAB',
        label: 'HABANERO',
        image: '/images/slot/provider/Habanero.png',
        wrapperClass: 'GameList-box-wide',
    },
    {
        slug: 'SIMPLEPLAY',
        label: 'Simple Play',
        image: '/images/slot/provider/SimplePlay.png',
        wrapperClass: 'GameList-box-square',
    },
    {
        slug: 'FNG',
        label: 'Funky Games',
        image: '/images/slot/provider/FunkyGames.png',
        wrapperClass: 'GameList-box-wide',
    },
    // {
    //     slug: '',
    //     label: 'Boomerang',
    //     image: '/images/slot/provider/Boomerang.png',
    // },
    // {
    //     slug: '',
    //     label: 'Booongo',
    //     image: '/images/slot/provider/Booongo.png',
    // },
    // {
    //     slug: '',
    //     label: 'Chance Interactive',
    //     image: '/images/slot/provider/ChanceInteractive.png',
    // },
    // {
    //     slug: '',
    //     label: 'Cubix Gaming',
    //     image: '/images/slot/provider/CubixGaming.png',
    // },


    // {
    //     slug: '',
    //     label: 'Esports Construct',
    //     image: '/images/slot/provider/EsportsConstruct.png',
    // },

    // {
    //     slug: '',
    //     label: 'Fantasma Games',
    //     image: '/images/slot/provider/FantasmaGames.png',
    // },
    // {
    //     slug: '',
    //     label: 'Fugaso',
    //     image: '/images/slot/provider/Fugaso.png',
    // },

    // {
    //     slug: '',
    //     label: 'Games Lab',
    //     image: '/images/slot/provider/GamesLab.png',
    // },

    // {
    //     slug: '',
    //     label: 'Kalamba Games',
    //     image: '/images/slot/provider/kalambagames.png',
    // },

    // {
    //     slug: '',
    //     label: 'Lightning Box',
    //     image: '/images/slot/provider/LightningBox.png',
    // },

    // {
    //     slug: '',
    //     label: 'Merkur Sun',
    //     image: '/images/slot/provider/MerkurSun.png',
    // },


    // {
    //     slug: '',
    //     label: 'NetGame',
    //     image: '/images/slot/provider/Netgame.png',
    // },

    // {
    //     slug: '',
    //     label: 'PLAY’n GO',
    //     image: '/images/slot/provider/PLAYnGO.png',
    // },

    // {
    //     slug: '',
    //     label: 'Playson',
    //     image: '/images/slot/provider/Playson.png',
    // },
    // {
    //     slug: '',
    //     label: 'Push Gaming',
    //     image: '/images/slot/provider/PushGaming.png',
    // },

    // {
    //     slug: '',
    //     label: 'Revolver Gaming',
    //     image: '/images/slot/provider/RevolverGaming.png',
    // },
    // {
    //     slug: '',
    //     label: 'Skywind Casino',
    //     image: '/images/slot/provider/SkywindCasino.png',
    // },

    // {
    //     slug: '',
    //     label: 'Snowborn',
    //     image: '/images/slot/provider/Snowborn.png',
    // },

    // {
    //     slug: '',
    //     label: 'Splitrock Gaming',
    //     image: '/images/slot/provider/SplitrockGaming.png',
    // },


    // {
    //     slug: '',
    //     label: 'ASKMEBET',
    //     image: '/images/slot/provider/ASKMEBET.png',
    // },

    // {
    //     slug: '',
    //     label: 'SPADE Gaming',
    //     image: '/images/slot/provider/SPADEGaming.png',
    // },

    // {
    //     slug: '',
    //     label: 'Iconic Gaming',
    //     image: '/images/slot/provider/IconicGaming.png',
    // },
    // {
    //     slug: '',
    //     label: 'Mega888',
    //     image: '/images/slot/provider/mega888.png',
    // },
    // {
    //     slug: '',
    //     label: 'Xin Gaming',
    //     image: '/images/slot/provider/XinGaming.png',
    // },
    // {
    //     slug: '',
    //     label: 'Live22',
    //     image: '/images/slot/provider/Live22.png',
    // },
    // {
    //     slug: '',
    //     label: 'Gamatron',
    //     image: '/images/slot/provider/Gamatron.png',
    // },
    // {
    //     slug: '',
    //     label: 'Allwayspin',
    //     image: '/images/slot/provider/Allwayspin.png',
    // },
    // {
    //     slug: '',
    //     label: 'Eurasian Gaming',
    //     image: '/images/slot/provider/EurasianGaming.png',
    // },
    // {
    //     slug: '',
    //     label: 'JDB',
    //     image: '/images/slot/provider/JDB.png',
    // },
    // {
    //     slug: '',
    //     label: 'WAZDAN',
    //     image: '/images/slot/provider/WAZDAN.png',
    // },

    // {
    //     slug: '',
    //     label: 'ENDORPHINA',
    //     image: '/images/slot/provider/ENDORPHINA.png',
    // },
    // {
    //     slug: '',
    //     label: 'KA Gaming',
    //     image: '/images/slot/provider/KAGaming.png',
    // },
    // {
    //     slug: '',
    //     label: 'Joker123',
    //     image: '/images/slot/provider/Joker123.png',
    // },
    // {
    //     slug: '',
    //     label: 'Mannaplay',
    //     image: '/images/slot/provider/Mannaplay.png',
    // },

    // {
    //     slug: '',
    //     label: 'Slotmill',
    //     image: '/images/slot/provider/Slotmill.png',
    // },
    // {
    //     slug: '',
    //     label: 'UPG Slot',
    //     image: '/images/slot/provider/UPGSlot.png',
    // },
    // {
    //     slug: '',
    //     label: 'Gioco Plus',
    //     image: '/images/slot/provider/GiocoPlus.png',
    // },
    // {
    //     slug: '',
    //     label: 'AMEBA',
    //     image: '/images/slot/provider/AMEBA.png',
    // },
]
export const PES_PROVIDER_LIST=[
    {
        slug: 'SA',
        label: 'SA Gaming',
        image: '/images/slot/SAGaming-icon.png',
        wrapperClass: '',
    },
    {
        slug: 'PRAGMATIC',
        label: 'PRAGMATIC PLAY',
        image: '/images/slot/provider/PragmaticPlay.png',
        wrapperClass: 'GameList-box-wide',
    },
]