<template>
  <div
    class="wrapContentIndex wrapContentIndexSlot"
    :class="{ wrapContentAllInto: currentProvider }"
  >
    <div class="contentOverlay" :class="{ popupProvider: isHideProvider }">
      <div class="btn-close-popupAllgame" @click="ClosePopupProvider()"></div>
      <ProviderList @openGameList="ClosePopupProvider"></ProviderList>
    </div>
    <FloatButtomRightPanel :callChatWithAdmin="showChatWithAdminNumber" />
    <div class="wrapContentIndexTitle">
      <div class="hamburger-lines" @click="OpenListProvider()">
        <span class="line line1"></span>
        <span class="line line2"></span>
        <span class="line line3"></span>
      </div>
      <h3>สล็อต</h3>
    </div>
    <div class="navTopDesktop">
      <MenuNavTop
        @showMenuNav="showMenuNav"
        @showChatWithAdmin="showChatWithAdmin" @showDepositWithdrawModal="showDepositWithdrawModal"
      ></MenuNavTop>
    </div>
    <MenuBar :callMenu="menuNumber" :callDWMenu="showDepositWithdrawNumber"></MenuBar>
    <div class="wrapContentIndexInfo">
      <div class="contentFlexIndex">
        <div class="contentFlexIndexLeft active">
          <MenuLeft></MenuLeft>
        </div>
        <div class="contentFlexIndexRight">
          <div class="container">
            <div class="box-sublist-area-topTap">
              <span
                v-for="(item, key) of categories"
                :key="key"
                :class="{ active: item === currentCategory }"
                @click="selectCategory(item)"
              >
                {{ item.label }}
              </span>
              <!-- <span class="boxTopTapInput">
                                <i class="fas fa-search"></i>
                                <input v-model="keyword" type="text" placeholder="ค้นหา" />
                            </span> -->
              <!-- <span class="boxTopTapInput">
                                <i class="fas fa-search"></i>
                                <input type="text" placeholder="ค้นหา">
                            </span> -->
            </div>
            <div
              class="containerGameList"
              :class="{ GameListContainer: isHideListGameContent }"
            >
              <div class="containerGameListinfo">
                <HorizontalShortList2
                  v-for="(provider, key) of providerList"
                  :key="`${key}:${tag}:${horizontalListSortBy}:${keyword}:1`"
                  :provider="provider"
                  :cat="provider.slug == 'PRAGMATIC' ? 'SLOT' : cat"
                  :tag="tag"
                  :keyword="keyword"
                  :sortBy="horizontalListSortBy"
                  :sortDesc="horizontalListSortDesc"
                  :showMore="true"
                  @seeAll="OpenListAllGame"
                >
                </HorizontalShortList2>
                <HorizontalShortList2
                  v-for="(provider, key) of allProviderList"
                  :key="`${key}:${tag}:${horizontalListSortBy}:${keyword}:2`"
                  :provider="provider"
                  :cat="provider.slug == 'PRAGMATIC' ? 'SLOT' : cat"
                  :tag="tag"
                  :keyword="keyword"
                  :sortBy="horizontalListSortBy"
                  :sortDesc="horizontalListSortDesc"
                  :showMore="true"
                  @seeAll="OpenListAllGame"
                >
                </HorizontalShortList2>
                <HorizontalShortList2
                  v-for="(provider, key) of topProviderList"
                  :key="`${key}:${tag}:${horizontalListSortBy}:${keyword}:3`"
                  :provider="provider"
                  :cat="provider.slug == 'PRAGMATIC' ? 'SLOT' : cat"
                  :tag="tag"
                  :keyword="keyword"
                  :sortBy="horizontalListSortBy"
                  :sortDesc="horizontalListSortDesc"
                  :showMore="true"
                  @seeAll="OpenListAllGame"
                >
                </HorizontalShortList2>
              </div>
            </div>
            <div
              v-if="providerInfo"
              class="containerGameListinfoAll AllContainerGameList"
              :class="[
                providerInfo.wrapperClass,
                isHideListGame ? 'allListGameProvider' : '',
              ]"
            >
              <div class="nav-menu-topic">
                <button
                  type="button"
                  class="btn btn-link"
                  @click="CloseListAllGame()"
                >
                  <i class="fas fa-angle-left"></i> <span>กลับ</span>
                </button>
                <img :src="providerInfo.img" />
                <h4>{{ providerInfo.name }}</h4>
                <div class="box-sublist-area-topTap">
                  <span class="boxTopTapInput">
                    <i class="fas fa-search"></i>
                    <input
                      v-model="keyword"
                      :provider="currentProvider"
                      type="text"
                      placeholder="ค้นหา"
                    />
                  </span>
                </div>
              </div>

              <ListAllGame
                :list="showList"
                :provider="currentProvider"
                :cat="providerInfo.cat? providerInfo.cat : (currentProvider == 'pragmatic' ? 'SLOT' : cat)"
                :keyword="keyword"
              ></ListAllGame>
              <div class="load-more-section">
                <Loading v-if="isOnLoad" mode="spinner"></Loading>
                <button
                  v-else-if="isCanLoadMore"
                  @click="loadMore"
                  type="button"
                  class="btn btnLoadMore"
                ></button>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="navButtonMobile">
        <MenuNav
          @showMenuNav="showMenuNav"
          @showChatWithAdmin="showChatWithAdmin" @showDepositWithdrawModal="showDepositWithdrawModal"
        ></MenuNav>
      </div>
    </div>
  </div>
</template>
<script>
import FloatButtomRightPanel from "@/components/FloatButtomRightPanel";
import MenuBar from "@/components/MenuBar";
import MenuLeft from "@/components/MenuLeft";
import MenuNav from "@/components/MenuNav";
import MenuNavTop from "@/components/MenuNavTop";
import ListAllGame from "@/components/ListAllGame2";
import HorizontalShortList2 from "@/components/gamelist/HorizontalShortList2";
import {
  ALL_PROVIDER_LIST,
  PES_PROVIDER_LIST,
} from "@/components/gamelist/Constant";
import { isStringContainThaiAlpha, removeThaiAlpha } from "@/lib/TextHandle";
import ProviderList from "@/components/ProviderList";
export default {
  name: "IndexSlot",
  components: {
    FloatButtomRightPanel,
    MenuBar,
    MenuLeft,
    MenuNav,
    MenuNavTop,
    HorizontalShortList2,
    ProviderList,
    ListAllGame,
  },
  watch: {
    keyword(newVal) {
      if (newVal) {
        if (isStringContainThaiAlpha(newVal)) {
          this.$bvToast.toast("กรุณากรอกข้อมูลเป็นภาษาอังกฤษ", {
            title: "แจ้งเตือน",
            toaster: "b-toaster-top-full",
          });
          this.keyword = removeThaiAlpha(newVal);
        }
      }
    },
  },
  data() {
    return {
      showChatWithAdminNumber: 0,
      showDepositWithdrawNumber:0,
      menuNumber: 0,
      isHideProvider: false,
      categories: [
        {
          key: "HOT",
          label: "เกมยอดนิยม",
        },
        {
          key: "RECOMMEND",
          label: "แนะนำเกม",
        },
      ],
      currentCategory: null,
      currentProvider: null,
      providerInfo: null,
      cat: null,
      catPrev: null,
      tag: "HOT",
      tagPrev: "",
      horizontalListSortBy: null,
      horizontalListSortDesc: null,
      topProviderList: [
        // {
        //     slug: 'SAGAME',
        //     label: 'SA Gaming',
        //     image: '/images/slot/provider/SAGaming-icon.png',
        // },
        // {
        //     slug: 'SEXY',
        //     label: 'Sexy Baccarat',
        //     image: '/images/slot/provider/SexyBaccarat-icon.png',
        // },
        // {
        //     slug: 'PRAGMATIC',
        //     label: 'Pragmatic Play',
        //     image: '/images/slot/provider/PragmaticPlay.png',
        //     wrapperClass: 'GameList-box-wide',
        //     perPage : 2.8
        // },
        // {
        //     slug: 'PRETTY',
        //     label: 'Pretty',
        //     image: '/images/slot/provider/PRETTY.png',
        //     wrapperClass: 'GameList-box-wide',
        // },
        // {
        //     slug: 'EVO',
        //     label: 'Evolution Gaming',
        //     image: '/images/slot/provider/EvolutionGaming.png',
        //     wrapperClass: 'GameList-box-wide',
        // },
      ],
      providerList: [
        {
          slug: "PGSOFT",
          value: "PG Slot",
          label: "PG Slot",
          image: "/images/slot/pop/PGSlot.jpg",
          perPage: 3.2,
        },
        {
          slug: "BPG",
          value: "BluePrint",
          label: "BluePrint",
          image: "/images/slot/pop/BluePint.jpg",
          wrapperClass: "GameList-box-wide",
          perPage: 2.3,
        },
        {
          slug: "PRAGMATIC",
          value: "Pragmatic Play",
          label: "Pragmatic Play",
          image: "/images/slot/pop/Pragmatic-Play.jpg",
          wrapperClass: "GameList-box-wide",
          perPage: 2.8,
        },
        {
          slug: "AMBPOKER",
          value: "AMB Poker",
          label: "AMB Poker",
          image: "/images/slot/pop/ambpoker.jpg",
          wrapperClass: "GameList-box-height",
          perPage: 3.7,
        },
        {
          slug: "MICRO",
          value: "Micro Gaming",
          label: "Micro Gaming",
          image: "/images/slot/pop/MicroGaming.jpg",
          perPage: 3.2,
        },
        {
          slug: "CQ9",
          value: "CQ9",
          label: "CQ9",
          image: "/images/slot/pop/cq9.jpg",
          wrapperClass: "GameList-box-square",
        },
        {
          slug: "RLX",
          value: "Relax Gaming",
          label: "Relax Gaming",
          image: "/images/slot/pop/relax_gaming.jpg",
          wrapperClass: "GameList-box-wide",
          perPage: 2.3,
        },

        {
          slug: "JILI",
          value: "JILI",
          label: "JILI",
          image: "/images/slot/pop/JILI.jpg",
          perPage: 3.2,
        },
        {
          slug: "SLOTXO",
          value: "Slot XO",
          label: "Slot XO",
          image: "/images/slot/pop/slotxo.jpg",
        },
        {
          slug: "DS",
          value: "Dragoon Soft",
          label: "Dragoon Soft",
          image: "/images/slot/pop/DragoonSoft.jpg",
          wrapperClass: "GameList-box-wide",
        },
        {
          slug: "NE",
          value: "NETENT",
          label: "NETENT",
          image: "/images/slot/pop/NETENT.jpg",
          wrapperClass: "GameList-box-wide",
        },
        {
          slug: "YGG",
          value: "Yggdrasil",
          label: "Yggdrasil",
          image: "/images/slot/pop/Yggdrasil.jpg",
          wrapperClass: "GameList-box-wide",
          perPage: 2.3,
        },
      ],
      allProviderList: [],
      keyword: "",
      keywordPrev: "",
      isHideListGame: true,
      isHideListGameContent: false,
      isOnLoad: false,
      isCanLoadMore: false,
    };
  },
  methods: {
    showChatWithAdmin() {
      this.showChatWithAdminNumber = 1;
      let self = this;
      setTimeout(() => {
        self.showChatWithAdminNumber = 0;
      }, 100);
    },
    showDepositWithdrawModal(){
                this.showDepositWithdrawNumber=1;
                let self =this;
                setTimeout(()=>{
                    self.showDepositWithdrawNumber=0;
                },100);
                
        },

    showMenuNav(menuNumber) {
      this.menuNumber = menuNumber;
      let self = this;
      setTimeout(() => {
        self.menuNumber = 0;
      }, 1000);
    },
    OpenListProvider() {
      this.isHideProvider = true;
    },
    OpenListAllGame(service, list,cat) {
      this.catPrev = this.cat;
      this.tagPrev = this.tag;
      this.showList = list;
      this.currentProvider = service.toLowerCase();
      this.providerInfo = this.getProviderInfo(service);
      if(cat){
        this.providerInfo.cat = cat;
      }
      this.isHideListGame = false;
      this.isHideListGameContent = true;
    },
    ClosePopupProvider(provider,cat) {
      if (provider) {
        if (this.currentProvider) {
          this.currentProvider = null;
          this.providerInfo = null;
          let self = this;
          setTimeout(() => {
            self.providerInfo = provider;

            self.currentProvider = provider.slug;
            self.catPrev = this.cat;
            self.cat = null;
            self.providerInfo.cat = cat?cat:null
            self.tagPrev = this.tag;
            self.tag = null;
            self.keywordPrev = this.keyword;
            self.keyword = "";
            self.OpenListAllGame(provider.slug, null,cat);
            self.isHideProvider = false;
          }, 50);
        } else {
          this.providerInfo = provider;
          this.currentProvider = provider.slug;
          this.catPrev = this.cat;
          this.cat = null;
        this.providerInfo.cat = cat?cat:null
          this.tagPrev = this.tag;
          this.tag = null;
          this.keywordPrev = this.keyword;
          this.keyword = "";
          this.OpenListAllGame(provider.slug, null,cat);
          this.isHideProvider = false;
        }
      } else {
        this.isHideProvider = false;
      }
    },
    CloseListAllGame() {
      this.isHideListGame = true;
      this.isHideListGameContent = false;
      this.providerInfo = null;
      this.currentProvider = null;
      this.tag = this.tagPrev;
      this.cat = this.catPrev;
      this.keyword = this.keywordPrev;
    },
    calculateFilterAndSort() {
      this.clearFilterAndSort();
      const currentCategoryKey = this.currentCategory.key;
      if (currentCategoryKey === "HOT" || currentCategoryKey === "RECOMMEND") {
        this.tag = currentCategoryKey;
      } else if (currentCategoryKey === "Name") {
        this.tag = null;
        this.horizontalListSortBy = currentCategoryKey;
        this.horizontalListSortDesc = "false";
      } else {
        this.clearFilterAndSort();
      }
    },
    clearFilterAndSort() {
      this.tag = "HOT";
      this.horizontalListSortBy = null;
      this.horizontalListSortDesc = null;
    },
    selectCategory(item) {
      this.currentCategory = item;
      this.calculateFilterAndSort();
    },
    getProviderInfo(provider) {
      const PROVIDER = provider.toUpperCase();
      var info = null;
      for (let i = 0; i < PES_PROVIDER_LIST.length; i++) {
        let p = PES_PROVIDER_LIST[i];
        if (p.slug == PROVIDER) {
          info = {
            img: p.image,
            name: p.label,
            wrapperClass: p.wrapperClass,
          };
          break;
        }
      }
      if (info) return info;
      for (let i = 0; i < ALL_PROVIDER_LIST.length; i++) {
        let p = ALL_PROVIDER_LIST[i];

        if (p.slug == PROVIDER) {
          info = {
            img: p.image,
            name: p.label,
            wrapperClass: p.wrapperClass,
          };
          break;
        }
      }
      if (info) {
        return info;
      } else {
        return {
          name: "",
          img: "",
          wrapperClass: "",
        };
      }
    },
  },
  mounted() {
    this.currentCategory = this.categories[0];
    this.calculateFilterAndSort();
    // this.allProviderList = RECOMMEND_PROVIDER_LIST
  },
};
</script>
<style>
.wrapContentIndexSlot .ListRecommend-sub {
  display: none;
}
.wrapContentIndexSlot .pop-provider {
  padding-top: 10px;
}
.wrapContentIndexSlot .active .itemList li.item.itemSlot {
  background-color: white;
  border: solid 1px rgba(0, 0, 0, 0.84);
  box-shadow: 0 5px 10px rgba(0, 0, 0, 0.3);
}
.wrapContentIndexSlot .active .itemList li.item.itemSlot p,
.wrapContentIndexSlot .active .itemList li.item.itemSlot span {
  color: black;
}
/*Theme*/
.themeBlackGold .wrapContentIndexSlot .active .itemList li.item.itemSlot {
  background-image: linear-gradient(to bottom, #fec225 0%, #e19d0e 100%) !important;
  background-color: transparent;
  border: solid 1px rgba(254, 194, 37, 0.84);
  box-shadow: 0 5px 10px rgba(254, 194, 37, 0.3);
}
.themeDarkGray .wrapContentIndexSlot .active .itemList li.item.itemSlot {
  background-color: #5f646a!important;
  border: solid 1px rgba(95, 100, 106, 0.84);
  box-shadow: 0 5px 10px rgba(59, 59, 59, 0.3);
}
.themeDarkGray .wrapContentIndexSlot .active .itemList li.item.itemSlot p,
.themeDarkGray .wrapContentIndexSlot .active .itemList li.item.itemSlot span {
  color: #fff!important;
}
.themeLightGray .wrapContentIndexSlot .active .itemList li.item.itemSlot {
  background-color: #edeef5 !important;
  border: solid 1px rgb(232, 232, 232) !important;
  box-shadow: none;
}
.themeLightGray .wrapContentIndexSlot .active .itemList li.item.itemSlot p,
.themeLightGray .wrapContentIndexSlot .active .itemList li.item.itemSlot span {
  color: #222!important;
}
</style>
