import { render, staticRenderFns } from "./PromotionTime.vue?vue&type=template&id=012bb8bc&"
import script from "./PromotionTime.vue?vue&type=script&lang=js&"
export * from "./PromotionTime.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports