<template>
    <span v-if="stat" :class="`${backgroundClass}`"></span>
</template>
<script>

const backgroundClassMap = {
    'big-eye-boy': {
        'red': 'box-lot-cell_mini border-red',
        'blue': 'box-lot-cell_mini border-blue',
    },
    'small-road': {
        'red': 'box-lot-cell_mini color-red',
        'blue': 'box-lot-cell_mini color-blue',
    },
    'cockroach-pig': {
        'red': 'cockroach-pig cockroach-pig-red',
        'blue': 'cockroach-pig cockroach-pig-blue',
    },
}

export default {
    name: 'BaccaratBigEyeBoyMark',
    props: ['stat', 'roadType'],
    computed: {
        backgroundClass() {
            if(!this.stat) {
                return ''
            }
            return backgroundClassMap[this.roadType][this.stat.mark]
        },
    }
}
</script>
<style scoped>
.box-lot-cell_mini {
    width: 5px;
    height: 5px;
    border-radius: 50%;
    display: inline-block;
    border: solid 1px;
    position: relative;
}
.border-red {
    border-color: #c52828 !important;
}
.border-blue {
    border-color: #006aff !important;
}
.color-red {
    background-color: #c52828 !important;
}
.color-blue {
    background-color: #006aff !important;
}
.cockroach-pig {
    display: inline-block;
    width: 4px;
    height: 5px;
    transform: rotate(-140deg);
}
.cockroach-pig-red {
    border-left: solid 2px #c52828;
}
.cockroach-pig-blue {
    border-left: solid 2px #006aff;
}
</style>