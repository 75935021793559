<template>
<div class="LineLoginButtonWrapper">
    <div @click="toLogin" class="LineLoginButton" :class="{'disable': !isAvailable}">
        <div class="image-wrapper">
            <img src="/images/line/line_40.png" />
        </div>
        <div class="text-wrapper">
            {{labelDisplay}}
        </div>
    </div>
    <b-modal ref="choiceModal" hide-footer hide-header centered modal-class="editModal choiceModalContent" >
        <h2>เลือกรายการที่ต้องการ</h2>
        <div class="choiceModalCol">
            <b-button @click="login" block variant="outline-dark">ล็อกอิน/สมัครสมาชิกใหม่</b-button>
            <b-button @click="toSync" block variant="outline-dark">เชื่อมต่อกับบัญชีเดิม</b-button>
        </div>

        <div class="choiceModalFooter">
            <b-button @click="closeChoiceModal" block variant="outline-dark">ยกเลิก</b-button>
        </div>
    </b-modal>
    <b-modal ref="syncModal" hide-footer hide-header centered modal-class="editModal choiceModalContent" >
        <h2>เชื่อมต่อกับบัญชีเดิม</h2>
        <div class="sign-in-wrapper">
                <div class="auth-row" style="margin: 0;">
                    <div class="auth-row-tab">
                        <ul class="nav nav-tabs">
                            <li class="nav-item">
                                <a class="nav-link active" data-toggle="tab" href="#sync-with-password-tab">ยูสเซอร์เนม/รหัสผ่าน</a>
                            </li>
                            <li class="nav-item">
                                <a class="nav-link" data-toggle="tab" href="#sync-with-phone-number-tab">หมายเลขโทรศัพท์</a>
                            </li>
                        </ul>
                        <div class="tab-content">
                            <div id="sync-with-password-tab" class="tab-pane active ">
                                <form>
                                    <div class="auth-row" style="justify-content: start;">
                                        ชื่อผู้ใช้
                                    </div>
                                    <div class="auth-row">
                                        <input v-model="username" type="text" class="form-control">
                                    </div>
                                    <div class="auth-row" style="justify-content: start;">
                                        รหัสผ่าน
                                    </div>
                                    <div class="auth-row">
                                        <input v-model="password" type="password" class="form-control">
                                    </div>
                                    <div class="auth-row">
                                        <vue-recaptcha @verify="loginWithPasswordRecaptchaVerified"
                                            :sitekey="googleCaptchaSiteKey">
                                        </vue-recaptcha>
                                    </div>
                                    <div class="auth-row">
                                        <button @click="syncWithPassword" type="button" class="btn btn-primary">เชื่อมต่อ</button>
                                    </div>
                                </form>
                            </div>
                            <div id="sync-with-phone-number-tab" class="tab-pane fade">
                                <OTP @submit="syncWithMobilePhoneNumber" submitText="เชื่อมต่อ"></OTP>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        <div class="choiceModal-row">
            <b-button @click="closeSyncModal" block variant="outline-dark">ยกเลิก</b-button>
        </div>
    </b-modal>
</div>
</template>
<script>

import store from '@/store'
import randomstring from 'randomstring'
import UserMixin from '@/mixins/UserMixin'
import OTP from '@/components/OTP'
import VueRecaptcha from 'vue-recaptcha'
import axiosWrapper from '@/axiosWrapper'

export default {
    name: 'LineLoginButton',
    components: {
        OTP,
        VueRecaptcha,
    },
    props: [
        'mode', // login, sync
        'label',
    ],
    mixins: [
        UserMixin,
    ],
    data() {
        return {
            username: '',
            password: '',
            isLoginWithPasswordRecaptchaVerified: false,
            loginWithPasswordRecaptchaToken: '',
        }
    },
    computed: {
        channel() {
            return store.state.channel
        },
        appInfo() {
            return store.state.appInfo
        },
        client_id() {
            return this.appInfo.login_client_id
        },
        labelDisplay() {
            if(this.mode === 'sync') {
                return (this.isAvailable) ? 'เชื่อมต่อกับ LINE' : 'เชื่อมต่อกับ LINE แล้ว'
            } else if(this.label) {
                return this.label
            } else {
                return 'ล็อกอิน/สมัครสมาชิกด้วย LINE'
            }
        },
        user_profile() {
            return store.state.user_profile
        },
        isAvailable() {
            if(this.mode === 'sync') {
                return this.user_profile.mobile_phone_number && !this.user_profile.line_user_id
            }
            return true
        },
    },
    methods: {
        async toLogin() {
            this.$refs.choiceModal.show()
            // if(this.isOnDev) {
            //     this.$refs.choiceModal.show()
            // } else {
            //     this.login()
            // }
        },
        async login() {
            if(!this.isAvailable) {
                return
            }
            localStorage.setItem('login_redirect_url', this.$route.fullPath)
            let redirectURL =  ''
            if(this.mode === 'login') {

                const loading = this.$loading()

                const isRouteQueryChannelAvailable = await this.checkRouteQueryChannelAvailable()
                if(!isRouteQueryChannelAvailable) {
                    loading.close()
                    return
                }

                redirectURL =  `${window.location.origin}/line/login`
            } else if(this.mode === 'sync') {
                redirectURL =`${window.location.origin}/line/sync`
            }
            this._redirectToLine(redirectURL)
        },
        _redirectToLine(redirectURL) {
            const state = randomstring.generate()
            const nonce = randomstring.generate()
            let lineAuthURL = `https://access.line.me/oauth2/v2.1/authorize?response_type=code`
            lineAuthURL += `&client_id=${this.client_id}&redirect_uri=${encodeURIComponent(redirectURL)}&state=${state}`
            lineAuthURL += `&scope=profile%20openid&nonce=${nonce}`
            window.location = lineAuthURL
        },
        toSync() {
            this.$refs.syncModal.show()
        },
        async syncWithPassword() {

            if(!this.isLoginWithPasswordRecaptchaVerified) {
                this.$bvToast.toast('กรุณายืนยันไม่ใช่หุ่นยนต์', {
                    title: 'แจ้งเตือน',
                    toaster: 'b-toaster-top-full'
                })
                return
            }

            const username = this.username
            const password = this.password

            this.loading = this.$loading()

            const res = await axiosWrapper({
                method: 'POST',
                url: `${this.envDomains.VUE_APP_LIFF_API}/api/user/auth-with-password`,
                data: {
                    username,
                    password,
                    recaptchaToken: this.loginWithPasswordRecaptchaToken,
                    domain: window.location.hostname,
                }
            })
            if(res.status === 200) {
                this.signIn(res.data.data.token)
                setTimeout(() => {
                    this.sync()
                }, 100)
            } else {
                this.$bvToast.toast(res.data.message, {
                    title: 'แจ้งเตือน',
                    toaster: 'b-toaster-top-full'
                })
            }
            this.loading.close()
        },
        async syncWithMobilePhoneNumber(data) {

            const channel = this.channel
            const {mobile_phone_number, otp} = data

            this.loading = this.$loading()

            const res = await axiosWrapper({
                method: 'POST',
                url: `${this.envDomains.VUE_APP_LIFF_API}/api/user/auth-with-mobile-phone-number`,
                data: {
                    channel,
                    mobile_phone_number,
                    otp,
                }
            })
            if(res.status === 200) {
                this.signIn(res.data.pes_profile.token)
                setTimeout(() => {
                    this.sync()
                }, 100)
            } else {
                this.$bvToast.toast(res.data.message, {
                    title: 'แจ้งเตือน',
                    toaster: 'b-toaster-top-full'
                })
            }

            this.loading.close()
        },
        sync() {
            const redirectURL =`${window.location.origin}/line/sync`
            this._redirectToLine(redirectURL)
        },
        loginWithPasswordRecaptchaVerified(response) {
            this.isLoginWithPasswordRecaptchaVerified = true
            this.loginWithPasswordRecaptchaToken = response
        },
        closeChoiceModal() {
            this.$refs.choiceModal.hide()
        },
        closeSyncModal() {
            this.$refs.syncModal.hide()
        },
    },
}
</script>
<style scoped>
.LineLoginButton {
    display: flex;
    align-items: center;
    height: 50px;
    padding: 0 5px;
    background-color: #00C300;
    font-weight: bold;
    color: #FFFFFF;
    border-radius: 10px;
    cursor: pointer;
}
.LineLoginButton:hover {
    background-color: #00E000;
}
.LineLoginButton:active {
    background-color: #00B300;
}
.LineLoginButton.disable {
    background-color: #c6c6c6;
}
.LineLoginButton:active .image-wrapper{
    border-right: solid 1px #009800;
}
.LineLoginButton.disable .image-wrapper{
    border-right: solid 1px #b5b5b5;
}
.image-wrapper {
    height: 50px;
    padding: 0 5px;
    border-right: solid 1px rgb(0, 179, 0);
    align-items: center;
    display: flex;
    position: absolute;
    left: 0;
}
.image-wrapper:hover {
    border-right: solid 1px rgba(0, 201, 0, 0.4);
}
.text-wrapper {
    flex: 1; 
    text-align: center;
}
.choiceModalContent .modal-body{
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    width: 100%;
}
.choiceModalContent h2{
    text-align: center;
    font: 800 26px Athiti;
    border-bottom: solid 1px #eee;
}
.choiceModalCol{
    padding: 15px;
    background-color: rgba(213, 217, 223, 0.48);
    border-radius: 10px;
}
.choiceModalCol button{
    border-radius: 10px;
    padding: 10px 0;
    font: 600 18px Athiti;
    background-color: white;
    /*border: solid 1px #ccd0d6;*/
    box-shadow: 0 2px 0 0 #696c71;
}
.choiceModalCol button:hover{
    color: black;
    box-shadow: none;
}
.choiceModalFooter{
    display: flex;
    justify-content: center;
    text-align: center;
    margin-top: 15px;
}
.choiceModalFooter button{
    border-radius: 10px;
    font: 600 16px Athiti;
    width: 40%;
    color: white;
    background-color: #930008;
    /*border: solid 1px #ccd0d6;*/
}
.choiceModal-row {
    margin: 4px;
}
.auth-row {
    display: flex;
    justify-content: center;
    margin: 10px;
}
</style>