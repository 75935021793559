<template>
    <div class="box-advertise-games-top">
        <carousel 
        :per-page="1" 
        :autoplay="true"
        :mouseDrag="true"
        :paginationPadding="1"
        :paginationSize="5"
        :autoplayTimeout="5000"
        paginationPosition="bottom-overlay"
        paginationColor="#eeeeee"
        paginationActiveColor="#888888"
        :loop="true">
            <slide>
<!--                <div class="item-carousel first" @click="$router.push({name: 'MiniSicbo'})"></div>-->
                <div class="item-carousel first" @click="$router.push({name: 'Football'})"></div>
            </slide>
            <slide v-if="false">
                <div class="item-carousel second" @click="$router.push({name: 'Baccarat'})"></div>
            </slide>
            <slide v-if="false">
<!--                <div class="item-carousel third" @click="$router.push({name: 'MiniFantan'})"></div>-->
<!-- p2p -->
                <div class="item-carousel third" @click="toggleMenubar('p2p')"></div>
            </slide>
            <slide>
<!--                <div class="item-carousel four" @click="$router.push({name: 'MiniDragonTiger'})"></div>-->
<!-- fishing -->
                <div class="item-carousel four" @click="toggleMenubar('fishing')"></div>
            </slide>
            <slide v-if="false"><div class="item-carousel five" @click="toggleMenubar('slot')"></div></slide>
            <slide>
                <div class="item-carousel six" @click="$router.push({name: 'Unbox'})"></div>
            </slide>
        </carousel>
    </div>


</template>
<script>

    import store from '@/store'
    
    import { Carousel, Slide } from 'vue-carousel'
    import LayoutMixin from '@/mixins/Layout'
    
    export default {
        name: 'AdvertiseGame',
        components: {
            Carousel,
            Slide
        },
        mixins: [
            LayoutMixin,
        ],
        data() {
            return {
                
            }
        },
        destroyed: function() {

        },
        computed: {

        },
        methods: {
            playGame(game) {
                if(game === 'football') {
                    this.current_game = game
                    this.openDrawer()
                    return
                }
            },
            toggleMenubar(page) {
                store.commit('updateRedirectPage', page)
            },
        },
        async mounted() {
           
        },
    }

</script>
<style scoped>
    .item-carousel {
        height: 50px;
        margin: 1px;
        background-size: cover;
        background-repeat: no-repeat;
        border: solid 1px white;
        box-shadow: 0 0 1px 1px #000;
        border-radius: 10px;
        overflow: hidden;
        object-fit: cover;
    }
    .item-carousel.first {
        /*background-image: url("https://chat.liffline.com/images/carousel/2.jpg");*/
        background-image: url(../assets/images/icon/set-icon/mini-icon-bet.png);
    }
    .item-carousel.second {
        /*background-image: url("https://chat.liffline.com/images/carousel/2.jpg");*/
        background-image: url(../assets/images/icon/set-icon/mini-icon-casino.png);
    }
    .item-carousel.third {
        /*background-image: url("https://chat.liffline.com/images/carousel/3.jpg");*/
        background-image: url(../assets/images/icon/set-icon/mini-icon-p2p.png);
    }
    .item-carousel.four {
        /*background-image: url("https://chat.liffline.com/images/carousel/3.jpg");*/
        background-image: url(../assets/images/icon/set-icon/mini-icon-shooting.png);
    }
    .item-carousel.five {
        background-image: url(../assets/images/icon/set-icon/mini-icon-slot.png);
    }
    .item-carousel.six {
        background-image: url(../assets/images/icon/set-icon/mini-icon-unbox.png);
    }
    .box-advertise-games-top{
        position: absolute;
        z-index: 99;
        width: 50px;
        top: 185px;
        right: 3px;
        transition: all .1s;
    }

    .VueCarousel-dot{

        width: 5px !important;
        height: 3px !important;
    }
    @media only screen
    and (min-device-width: 500px) {
        .box-advertise-games-top {
            right: -60px;
        }
    }
</style>