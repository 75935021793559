<template>
    <div v-if="message_data" class="MessageLottoPrice incoming_msg">
<!--        <div class="incoming_msg_img">-->
<!--            <img src="@/assets/images/icon/2102206.png" alt="sunil" />-->
<!--        </div>-->
        <div class="received_msg">
            <!--<h3>Lotto</h3>-->
            <div class="received_withd_msg">

                    <carousel
                            :speed="100"
                            :per-page="1.45"
                            :mouseDrag="true"
                            :spacePadding="1"
                            :navigationEnabled="true"
                            :paginationEnabled="false"
                            navigationNextLabel="❯"
                            navigationPrevLabel="❮"
                            paginationPosition="bottom">
                        <slide v-for="(lotto_type, i) of lotto_type_arr" :key="i" class="box-top-user-list">
                            <div class="box-games-list">
                                <div class="box-games-list-info">
                                    <div class="heading-table-lotto">
                                        <div>
                                            <LottoTypeIcon :lotto_type="lotto_type" customClass="custom-mesage-lotto-price"></LottoTypeIcon>
                                        </div>
                                        <div class="heading-line">
                                            <h2>{{message_data[lotto_type]['title']}}</h2>
                                            <div>งวด {{message_data[lotto_type]['round']['date'] | lottoPriceDateTimeFormat}}</div>
                                        </div>
                                    </div>
                                    <div class="box-detail-line">
                                        <table class="table-striped">
                                            <thead>
                                            <tr>
                                                <th>ประเภท</th>
                                                <th>ราคา</th>
                                                <th>อัตราจ่าย</th>
                                            </tr>
                                            </thead>
                                            <tbody>
                                            <tr v-show="isHaveSamTuaNhaLang(lotto_type, lotto_bet_type)" v-for="(lotto_bet_type, i) of lotto_bet_type_arr" :key="i">
                                                <td><span>{{getBetTypeLabel(lotto_bet_type)}}</span></td>
                                                <td><span>บาทละ</span></td>
                                                <td :class="{'bg-active': isHighlight(lotto_bet_type)}">
                                                    <span>{{message_data[lotto_type][lotto_bet_type] | numberFormat}}</span>
                                                </td>
                                            </tr>

                                            </tbody>
                                        </table>
                                        <div @click="play(lotto_type)" class="bt-play-game bt-black">
                                            เล่นเลย
                                        </div>
                                    </div>
                                </div>

                            </div>
                        </slide>
                    </carousel>

                <span class="time_date"> {{timestamp | chatMessageDateTimeFormat}}</span>
            </div>
        </div>
    </div>
</template>
<script>

import {LOTTO_TYPE, LOTTO_TYPE_FLAG, LOTTO_BET_TYPE, LOTTO_BET_TYPE_LABEL} from '@/constant'
import store from '@/store'
import UserMixin from '@/mixins/UserMixin'
import LayoutMixin from '@/mixins/Layout'
import LottoTypeIcon from '@/components/lotto/components/LottoTypeIcon'
import { Carousel, Slide } from 'vue-carousel'

export default {
    name: 'MessageLottoPrice',
    props: ['message'],
    mixins: [
        UserMixin,
        LayoutMixin,
    ],
    components: {
        LottoTypeIcon,
        Carousel,
        Slide,
    },
    data(){
        return {
            lotto_type_arr: [],
            lotto_bet_type_arr: [],
            lotto_type_flag_map: null
        }
    },
    computed: {
        message_data(){
            return (this.message) ? this.message.data : null
        },
        timestamp(){
            return (this.message) ? this.message.timestamp : ''
        },
        is_member(){
            return store.state.is_member
        },
        pes_profile(){
            return store.state.pes_profile
        },
        token(){
            return this.pes_profile.token
        }
    },
    methods: {
        isHighlight(bet_type){
            return bet_type === 'sam_tua_bon' || bet_type == 'song_tua_lang'
        },
        getBetTypeLabel(bet_type){
            return LOTTO_BET_TYPE_LABEL[bet_type]
        },
        isHaveSamTuaNhaLang(lotto_type, bet_type){
            if(lotto_type === 'government'){
                return true
            }
            if(bet_type === 'sam_tua_nha' || bet_type === 'sam_tua_lang'){
                return false
            }
            return true
        },
        play(lotto_type){

            // if(!this.is_member){
            //     this.notifyToSignUp('เพื่อเล่น')
            //     return
            // }

            // const url = `${this.envDomains.VUE_APP_LOTTO_API}/bet?token=${this.token}&lotto_type=${lotto_type}`
            // this.openWindow(url)

            // this.current_game = 'lotto'
            // this.openDrawer()
            store.commit('lotto/updatePageBetQueryLottoType', lotto_type)
            store.commit('lotto/updateCurrentPage', 'bet')
            this.$router.push({name: 'LottoBet'})
        },
        openWindow(url) {
            window.open(url, '_blank')
        },
    },
    mounted(){
        this.lotto_type_arr = LOTTO_TYPE
        this.lotto_bet_type_arr = LOTTO_BET_TYPE
        this.lotto_type_flag_map = LOTTO_TYPE_FLAG
    }
}
</script>
<style scoped>
.box-games-list {
    /*height: fit-content;*/
    background-color: #fff;
}
</style>