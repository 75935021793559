<template>
    <div class="AdminToolContents">
        <div class="admin-tool-contents" v-if="type === 'rich'">
            <Rich v-for="(content, index) of content_arr" 
            :key="index" 
            :index="index"
            :form="content"
            :bus="contentBus"
            :type="type"
            @remove="remove"/>
            <div @click="add" class="add-more-content-button">
                <i class="el-icon-plus" style="font-size: 6rem;"></i>
            </div>
        </div>
        <div class="admin-tool-contents" v-else>
            <Content v-for="(content, index) of content_arr" 
            :key="index" 
            :index="index"
            :form="content"
            :bus="contentBus"
            :type="type"
            :lottoNewsArr="lottoNewsArr"
            @remove="remove"/>
            <div @click="add" class="add-more-content-button">
                <i class="el-icon-plus" style="font-size: 6rem;"></i>
            </div>
        </div>
    </div>
</template>
<script>

import Content from './Content'
import Rich from './Rich'
import Vue from 'vue'
import axios from 'axios'
import store from '@/store'

export default {
    name: 'AdminToolContents',
    props: {
        bus: {},
        limit: {
            default: 10
        },
        type: {}
    },
    components: {
        Content,
        Rich,
    },
    data(){
        return {
            content_arr: [],
            contentBus: new Vue(),
            lottoNewsArr: [],
        }
    },
    computed: {
        admin(){
            return store.state.admin
        },
        token(){
            return this.admin.token
        },
    },
    watch: {
        content_arr: {
            deep: true,
            handler(new_val){
                this.$emit('change', {
                    data: new_val,
                    type: this.type
                })
            }
        },
    },
    methods: {
        add(){
            if(this.content_arr.length >= this.limit){
                this.$alert(`เพิ่มได้ไม่เกิน ${this.limit}`, 'แจ้งเตือน', {
                    type: 'info',
                    confirmButtonText: 'ตกลง',
                    customClass: 'custom-message-box'
                })
                return
            }
            this.content_arr.push({
                url: '',
                title: '',
                description: '',
                thumbnail: '',
                thumbnails: [],
                template_type: 1,
                urls: [],
            })
        },
        remove(index){
            this.content_arr.splice(index, 1)
        },
        clear(){
            this.content_arr = []
            this.add()
            this.contentBus.$emit('clear')
        },
        async loadLottoNews() {

            const loading = this.$loading()

            try{

                const res = await axios({
                    method: 'POST',
                    url: `${this.envDomains.VUE_APP_API}/api/chat/admin-get-lotto-news`,
                    data: {token: this.token}
                })
                if(!res.data.success){
                    console.log('loadLottoNews error', res.data.message)
                    loading.close()
                    return
                }
                this.lottoNewsArr = res.data.data
                loading.close()
            }catch(err){
                console.log('loadLottoNews error', 'ไม่สามารถโหลดข้อมูล กรุณาลองอีกครั้ง')
                loading.close()
                return
            }
        },
    },
    mounted(){

        this.add()
        this.bus.$on('clear', this.clear)

        if(this.type === 'lotto_news') {
            this.bus.$on('loadLottoNews', this.loadLottoNews)
            this.loadLottoNews()
        }
    }
}
</script>

<style scoped>
.AdminToolContents {
    display: flex;
}
.admin-tool-contents {
    display: flex;
    width: calc(100vw - 80px);
    overflow: scroll
}
.admin-tool-contents * {
    margin-left: 10px;
    flex-shrink: 0;
}
.admin-tool-contents::-webkit-scrollbar {
    display: block!important;
    height: 20px!important;
}
.add-more-content-button {
    display: flex;
    justify-content: center;
    align-items: center;
    border: solid 1px #b3d8ff;
    border-radius: 5px;
    color: #409EFF;
    background: #ecf5ff;
    width: 400px;
    height: 400px;
    cursor: pointer;
}
</style>