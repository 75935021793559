<template>
    <div class="Game-PlayFrame" v-if="is_jili!=null">
        <MenuBar v-if="is_jili" :backRouteName="route_name"></MenuBar>
        <MenuBar v-else :backRouteName="route_name"></MenuBar>
        <!--        <div class="box-img">-->
        <!--            <strong>กลับ</strong>-->
        <!--        </div>-->
        <div class="topic-Game-PlayFrame">
            <img :src="this.appImageTopBar"/>
        </div>
        <div class="content-page">
            <iframe :src="decodeURIComponent(this.$route.query.url)"></iframe>
        </div>
        <FloatButtomRightPanel/>
    </div>
</template>
<script>
    import store from "@/store";
    import MenuBar from "@/components/MenuBar";
    import FloatButtomRightPanel from "@/components/FloatButtomRightPanel";
    import UserMixin from "@/mixins/UserMixin.vue";

    export default {
        name: "GamePlayFrame",
        mixins: [UserMixin],
        components: {
            MenuBar,
            FloatButtomRightPanel,
        },
        data() {
            return {
                url_string: null,
                is_jili: null,
                route_name: '',
            };
        },
        computed: {
            appImageTopBar() {
                return store.state.appInfo.app_image_topbar;
            },
        },
        beforeDestroy() {
            this.requestUserReloadWithForceRefreshBalance();
        },
        mounted() {
            let url_string = decodeURIComponent(this.$route.query.url);
            this.url_string = url_string;
            let url = new URL(url_string);
            let productId = url.searchParams.get("productId");
            let routeName = this.$route.query.routeName
            if (productId == "JILI") {
                this.is_jili = true;
                this.route_name = 'JILI';
            } else {
                this.is_jili = false;
                this.route_name = routeName;
            }
        },
    };
</script>

<style>
    .Game-PlayFrame .wrap-menu-top{
        z-index: 110;
    }
    .Game-PlayFrame span.box-txt-back {
        top: 8px;
    }

    .Game-PlayFrame span.box-txt-back:before {
        content: '\f104';
        font: 800 30px/45px "Font Awesome\ 5 Free";
        margin-left: 10px;
        color: white;
    }

    .Game-PlayFrame span.box-txt-back {
        position: absolute;
        z-index: 110;
        cursor: pointer;
        padding-right: 10px;
    }

    .Game-PlayFrame .box-txt-back:after {
        align-items: center;
        display: none;
        content: "กลับ";
        font: 800 18px/1 Athiti;
        color: white;
        height: 45px;
        margin-left: 10px;
    }

    .Game-PlayFrame .close-box-bonus {
        right: -61px;
    }
    .Game-PlayFrame {
        background-image: none;
        background-color: black;
    }

    .Game-PlayFrame .content-page {
        background-color: white;
        height: 100vh;
    }

    .Game-PlayFrame .content-page iframe {
        position: absolute;
        /*top: 45px;*/
        top: 0;
        width: 100%;
        /*height: calc(100vh - 45px);*/
        height: 100vh;
        z-index: 0;
    }

    .Game-PlayFrame .box-img {
        height: 45px !important;
        display: flex;
        align-items: center;
    }

    .Game-PlayFrame .box-img strong {
        font: 800 18px/1 Athiti;
    }

    .Game-PlayFrame .topic-Game-PlayFrame img {
        height: 30px;
    }

    .Game-PlayFrame .topic-Game-PlayFrame {
        position: absolute;
        top: 10px;
        /*margin: auto;*/
        width: auto;
        padding: 5px 15px 5px 30px;
        left: 10px;
        display: flex;
        align-items: center;
        z-index: 100;
        background-color: rgba(0, 0, 0, 0.58);
        border-radius: 20px;
    }

    @media only screen and (orientation: landscape) {
        /*.Game-PlayFrame .content-page iframe {*/
        /*    position: fixed;*/
        /*    top: 45px;*/
        /*    width: 375px;*/
        /*    height: calc(100% - 45px);*/
        /*    z-index: 1;*/
        /*}*/
    }
    .Game-PlayFrame .box-balance-total {
        display: none !important;
    }
</style>
