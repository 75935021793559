<template>
    <div>
        <div class="box-animated-hilo">
            <div :class="`box-game-animated ${gameTypeClass}`"></div>
            <div :class="{'on-top': state === 'dealing'}" ref="dealing" class="box-game-step"></div>
            <div :class="{'on-top': state === 'finish'}" ref="finish" class="box-game-step"></div>
            <div :class="{'on-top': state === 'countdown'}" ref="countdown" class="box-game-step"></div>
            <div :class="{'on-top': state === 'idle'}" ref="idle" class="box-game-step"></div>
            <div class="box-sec-dice">
                <div v-show="showDice" class="sec-dice">
                    <span v-for="(d, i) of customDice"
                    :key="`${d}${i}`" 
                    :class="`tb-dice dice_${d.dice}-${d.pattern}`"
                    :style="d.style"></span>
                </div>
            </div>
        </div>
    </div>
</template>
<script>

import {GAME_STATUS_KEY} from '@/components/game/Constant'
import randomBetween from '@/lib/randomBetween'
import axios from 'axios'
const STATE_ARR = [
    'dealing',
    'finish',
    'countdown',
    'idle',
]

export default {
    name: 'SicboGamePlay3D',
    props: [
        'gameStatus',
        'dice',
        'gameType',
    ],
    data() {
        return {
            GAME_STATUS_KEY: null,
            STATE_ARR: [],
            state: 'idle', // dealing, finish, countdown, idle
            showDice: false,
            base64Map: {
                'dealing': '',
                'finish': '',
                'countdown': '',
                'idle': '',
            },
            customDice: [],
        }
    },
    computed: {
        el() {
            return this.$refs['hand']
        },
        diceLength() {
            return this.dice.length
        },
        gameTypeClass() {
            if(this.gameType === 'bean') {
                return 'animate-fantan'
            } else {
                return 'box-bg-animated-sicbo'
            }
        },
    },
    watch: {
        gameStatus(newVal, oldVal) {
            if(newVal === GAME_STATUS_KEY.DEALING) {
                setTimeout(() => {
                    this.state = 'dealing'
                }, 3300)
                setTimeout(() => {
                    this.showDice = true
                }, 3300 + 1690)
            }else if(oldVal === GAME_STATUS_KEY.DEALING && newVal === GAME_STATUS_KEY.FINISH) {
                setTimeout(() => {
                    this.state = 'finish'
                }, 2000)
                setTimeout(() => {
                    this.showDice = false
                }, 2000 + 790)
            }else if(oldVal && newVal === GAME_STATUS_KEY.COUNTDOWN) {
                this.state = 'countdown'
            }else {
                this.state = 'idle'
            }
        },
        state(newVal) {
            if(newVal !== 'idle') {
                this.setImage(newVal)
            }
        },
        diceLength(newVal) {
            if(newVal === 0) {
                this.customDice = []
                return
            }
            this.customDice.push({
                dice: this.dice[newVal - 1],
                pattern: this.randomDicePattern(),
                style: this.randomDicePosition(newVal - 1)
            })
        },
    },
    methods: {
        // async gifToBase64(url) {
        //     return new Promise((resolve) => {
        //         const xhr = new XMLHttpRequest();
        //         xhr.onload = () => {
        //             const reader = new FileReader();
        //             reader.onloadend = () => {
        //                 resolve(reader.result)
        //             }
        //             reader.readAsDataURL(xhr.response);
        //         };
        //         xhr.open('GET', url);
        //         xhr.responseType = 'blob';
        //         xhr.send();
        //     })
        // },
        randomDicePattern() {
            return randomBetween(1,4)
        },
        randomDicePosition(diceIndex) {
            if(diceIndex === 0) {
                return {
                    top: `${randomBetween(-5, 5)}px`,
                    left: `${10 + randomBetween(5, 10)}px`
                }
            } else if(diceIndex === 1) {
                return {
                    top: `${randomBetween(-5, 5)}px`,
                    left: `${40 + randomBetween(-5, 5)}px`
                }
            } else if(diceIndex === 2) {
                return {
                    top: `${randomBetween(-5, 5)}px`,
                    left: `${70 + randomBetween(-10, -5)}px`
                }
            }
        },
        setImage(state) {
            const el = this.$refs[state]
            if(!el) {
                return
            }
            el.style['background-image'] =  `url(${this.base64Map[state].replace("image/gif","image/gif;rnd="+Math.random())})`
        }
    },
    async created() {
        this.GAME_STATUS_KEY = GAME_STATUS_KEY
        this.STATE_ARR = STATE_ARR
        const base64Res = await axios.get('/images/game/hilo/animation/base64.json')
        this.base64Map = base64Res.data
        for(const state of STATE_ARR) {
            // const base64 = await this.gifToBase64(`/images/game/hilo/animation/${state}.gif`)
            // this.base64Map[state] = base64
            this.setImage(state)
        }
    },
}
</script>
<style scoped src="@/assets/css/animate-games.css"></style>
<style scoped>
    .sec-dice {
        /*display: flex;*/
        /*width: 110px;*/
        /*height: 50px!important;*/
        /*position: absolute;*/
        bottom: -54px;
        display: flex;
        top: 0;
        margin: auto;
        width: 110px;
        height: 31px!important;
        position: absolute;
    }
    .box-sec-dice {
        height: 35vh;
        display: flex;
        justify-content: center;
        align-items: center;
        /*position: relative;*/
    }
    .tb-dice {
        position: absolute;
    }
</style>