<template>

    <div class="wrapContentIndex  GameType-casino">
<!--    <div class="GameType GameType-casino">-->

        <div class="wrapContentIndexTitle">
            <button style="padding: 0 10px;" class="btn " @click="goHome()"><i class="fas fa-chevron-left"></i></button>
            <h3>เกมส์คาสิโน</h3>
        </div>
        <div class="navTopDesktop">
            <MenuNavTop  @showMenuNav="showMenuNav"  @showChatWithAdmin="showChatWithAdmin" @showDepositWithdrawModal="showDepositWithdrawModal"></MenuNavTop>
        </div>
<!--        <div class="box-img">-->
<!--                        <div class="box-user-img"><img src="@/assets/images/icon/set-icon/icon-casino.png"/></div>-->

<!--            <strong>เกมส์คาสิโน</strong>-->
<!--                            <small>Casino Online</small>-->

<!--        </div>-->
        <MenuBar></MenuBar>
        <div class="wrapContentIndexInfo">
            <div class="contentFlexIndex">
                <div class="contentFlexIndexRight">
                    <div class="content-page-spin">
                        <div class="container">
                            <div class="container-fluid-game container-fluid-game-spin">
                                <div class="wrap-content-games">
                                    <div v-for="(tableType, key) of tableTypeArr" :key="key" v-show="getTableArrByType(tableType.type).length > 0" class="wrapper-box-type">
                                        <div class="box-type">
                                            <div class="fa-pull-left txt-header">
                                                <img :src="tableType.icon"/> {{tableType.title}}
                                            </div>
                                        </div>
                                        <div class="box-top-user-flex">
                                            <!-- <div @click="play(tableType)" class="box-top-user-list">
                                                <div class="box-games-list">
                                                    <div :class="`box-img-list ${tableType.imageBackGroundClass}`"></div>
                                                </div>
                                            </div> -->

                                            <carousel
                                                    :speed="100"
                                                    :per-page="1"
                                                    :perPageCustom="[[768, 2], [1400, 5],]"
                                                    :mouseDrag="true"
                                                    :spacePadding="1"
                                                    :navigationEnabled="true"
                                                    :paginationEnabled="false"
                                                    navigationNextLabel="❯"
                                                    navigationPrevLabel="❮"
                                                    paginationPosition="bottom"
                                            >


                                                <slide @slideclick="play(table)" v-for="(table, key) of getTableArrByType(tableType.type)" :key="key">
                                                    <div class="box-table-stat mb-4">
                                                        <div class="content-table-info">
                                                            <div class="table-name"><span>โต๊ะ {{table.table_name}}</span></div>
                                                            <BaccaratItem v-if="parseInt(tableType.type) === TABLE_TYPE_KEY.baccarat || parseInt(tableType.type) === TABLE_TYPE_KEY.baccarat2 || parseInt(tableType.type) === TABLE_TYPE_KEY.baccarat3"
                                                                          :table_id="table.table_id"
                                                                          :table_round_main="table.table_round_main"></BaccaratItem>
                                                            <DragonTigerItem v-if="parseInt(tableType.type) === TABLE_TYPE_KEY.dragontiger"
                                                                             :table_id="table.table_id"
                                                                             :table_round_main="table.table_round_main"></DragonTigerItem>
                                                            <SicboItem v-if="parseInt(tableType.type) === TABLE_TYPE_KEY.sicbo"
                                                                       :table_id="table.table_id"
                                                                       :table_round_main="table.table_round_main"
                                                                       :limit="8"></SicboItem>
                                                            <FantanItem v-if="parseInt(tableType.type) === TABLE_TYPE_KEY.bean"
                                                                        :table_id="table.table_id"
                                                                        :table_round_main="table.table_round_main"
                                                                        :limit="8"></FantanItem>
                                                        </div>
                                                    </div>
                                                </slide>


                                            </carousel>
                                        </div>

                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="navButtonMobile">
                <MenuNav  @showMenuNav="showMenuNav"  @showChatWithAdmin="showChatWithAdmin" @showDepositWithdrawModal="showDepositWithdrawModal"></MenuNav>
            </div>
        </div>

    </div>
</template>
<script>



    import MenuBar from '@/components/MenuBar'
    import { Carousel, Slide } from 'vue-carousel'
    import MenuNavTop from '@/components/MenuNavTop'
    import MenuNav from '@/components/MenuNav'
// import MenuBar from '@/components/Unbox'
import LayoutMixin from '@/mixins/Layout'
import {TABLE_TYPE_MAP, TABLE_TYPE_KEY, IMAGE_ICON_PATH} from '@/components/game/Constant'
import BaccaratItem from '@/components/game/baccarat/GameTypeItem'
import DragonTigerItem from '@/components/game/dragontiger/GameTypeItem'
import SicboItem from '@/components/game/sicbo/GameTypeItem'
import FantanItem from '@/components/game/fantan/GameTypeItem'
import GameTypeMixin from '@/mixins/GameType'

const IMAGE_BACKGROUND_CLASS = {
    0: 'box-img-list-baccarat',
    1: 'box-img-list-sicbo',
    2: 'box-img-list-bean',
    3: 'box-img-list-dragonTiger',
    4: 'box-img-list-baccarat',
    5: 'box-img-list-baccarat',
}

export default {
    name: 'GameType',
    components: {
        MenuBar,
        BaccaratItem,
        DragonTigerItem,
        SicboItem,
        FantanItem,
        Carousel,
        Slide,
        MenuNavTop,
        MenuNav,
    },
    mixins: [
        LayoutMixin,
        GameTypeMixin,
    ],
    data() {
        return {
            tableTypeArr: [],
            TABLE_TYPE_KEY: null,
        }
    },
    methods: {
        goHome() {
            this.$router.push({name: 'Index'})
        },
        getTableArrByType(table_type) {
            return this.baccaratTableList.filter((table) => {
                return parseInt(table.table_type) === parseInt(table_type)
            })
        },
        // play(table) {
        //     this.$router.push({name: table.routeName})
        // },
        play(table) {
            this.$router.push({
                name: TABLE_TYPE_MAP[table.table_type]['routeName'],
                query: {
                    table_id: table.table_id
                },
            })
        }
    },
    created() {
        for (const key in TABLE_TYPE_MAP) {
            // if(!this.isOnDev) {
            //     if(parseInt(key) === TABLE_TYPE_KEY.baccarat2) {
            //         continue
            //     }
            //     if(parseInt(key) === TABLE_TYPE_KEY.baccarat3) {
            //         continue
            //     }
            // }
            this.tableTypeArr.push({
                title: TABLE_TYPE_MAP[key]['title'],
                icon: IMAGE_ICON_PATH[key],
                type: key,
                imageBackGroundClass: IMAGE_BACKGROUND_CLASS[key],
                routeName: TABLE_TYPE_MAP[key]['routeName'],
            })
        }
        this.TABLE_TYPE_KEY = TABLE_TYPE_KEY
    }
}
</script>
<style src="@/assets/css/unbox.css" scoped></style>
<style>
    .GameType-casino span.box-txt-back:before{
        content: '\e88a';
        font: 25px "Material Icons";
        margin-right: 5px;
    }
    .GameType-casino .content-table-info-right {
        background-color: #150214!important;
    }
    .GameType-casino .content-table-info-left {
        height: 130px;
    }
    .GameType-casino .user-list-detail{margin: 0;}
    .GameType-casino .user-list-detail:last-child{border: 0;}
    .GameType-casino .user-list-detail .row-content-his{height: 30px;}
    .GameType-casino .box-table-stat.content-table-info .user-list-detail{
        display: flex;
    }
    .GameType-casino .sec-Hilo .list-dice.dice-cs,
    .GameType-casino .sec-Fantan .list-dice.dice-cs{
        display: flex;
        align-items: center;
    }
    .GameType-casino .box-current-result{
        display: flex;
        justify-content: space-between;
    }
    .GameType-casino .box-table-stat .content-table-info .list-dice {
        display: flex;
    }
    .GameType-casino .box-table-stat .content-table-info .user-list-detail span.dice{
        display: inherit;
        background-size: contain!important;
        font-size: 12px!important;
        height: 16px!important;
        min-height: inherit !important;
        padding-left: 18px !important;
    }
    .GameType-casino .box-table-stat .box-current-result{
        padding: 5px 0!important;
    }
    .GameType-casino .content-page-spin{position: relative}
    .GameType-casino .box-img{
        top: 5px!important;
        left: 35px;
        display: flex;
        align-items: center;
        justify-content: center;
        height: 35px!important;
        z-index: 0;
    }
    @media only screen
        and (max-device-width: 1300px)
        and (orientation: landscape) {  }
</style>

<style scoped>
    .container-fluid-game-spin{overflow-y: auto;height: 100%}
    .container-fluid-game-spin .wrap-content-games{margin-top: 20px;}

    .content-table-info{display: flex}
    .content-table-info-all{display: inline-block; width: 100%; margin-right: 3px;}

    .content-table-info .table-name span{
        transform: rotate(-90deg);
        display: inline-block;
        height: 30px;
        width: 106px;
        text-align: left;
        left: -38px;
        top: 72px;
        position: relative;
        color: white;
        font-weight: 400;
        font-size: 12px;
    }
    .content-table-info .table-name{
        position: relative;
        top: 0;
        left: 0;
        width: 18px;
    }
    .content-table-info {border: solid 1px #150214;}

    .GameType {
        background-image: linear-gradient(
                135deg, #F372D4 10%, #c765f0 100%);
    }

    .content-page {
        background-color: #fff;
        border-radius: 20px 20px 0 0;
        padding: 5px 0;
    }

    .wrapper-box {margin-top: 15px;}

    .box-games-list {
        width: 145px;
        padding: 0;
        border-radius: 10px;
    }

    .box-img-list {
        position: relative;
        border-radius: 10px;
        background-size: cover;
        background-position: center;
        height: 80px;
        z-index: 2;
        background-image: url(../assets/images/casino/sexy-baccarat/sexy-bacarat.png);
    }

    .box-type {
        font-size: 13px;
        margin-bottom: 5px;
        clear: both;
        width: 50px;
        height: 20px;
    }

    .wrapper-box-type {margin-bottom: 10px;}

    .box-games-list {
        width: 295px;
        height: 165px;
        margin-bottom: 20px;
        padding: 0;
        border-radius: 10px;
        position: relative;
    }

    .box-top-user-flex {
        display: inline-block;
        padding: 2px 1px;
        position: relative;
        width: 100%;
        overflow-y: initial;
        overflow-x: initial;
    }

    .box-top-user-list {
        display: inline-block;
        flex: none;
        margin-right: 0;
    }

    .box-img-list {
        border-radius: 10px;
        background-size: cover;
        background-position: center;
        height: 100%;
        z-index: 2;
    }

    .box-games-list:after,
    .box-games-list:before {
        content: ' ';
        background-color: rgba(0, 0, 0, 0.3);
        border-radius: 20px;
        padding: 25px 0;
        bottom: -10px;
        position: absolute;
        top: 0;
        left: 0;
        width: 95%;
        right: 0;
        margin: auto;
        z-index: 1;
    }
    .box-games-list:after {width: 85%;bottom: -15px;}

    .box-img-list.box-img-list-baccarat {
        background-image: url(../assets/images/casino/casino-baccarat.jpg);
    }

    .box-img-list.box-img-list-sicbo {
        background-image: url(../assets/images/casino/casino-HiLo.jpg);
    }

    .box-img-list.box-img-list-bean {
        background-image: url(../assets/images/casino/casino-Been.jpg);
    }

    .box-img-list.box-img-list-dragonTiger {
        background-image: url(../assets/images/casino/casino-dragonTiger.jpg);
    }

    .box-type {
        font-size: 13px;
        margin-bottom: 5px;
        clear: both;
        height: 20px;
    }

    .box-type > .txt-header {
        font-weight: bold;
        font-size: 16px;
        color: #000;
        font-family: "Athiti", sans-serif;
        width: 50px;
        text-align: center;
        padding-top: 5px;
    }

    .box-type a {
        color: #888;
    }

    .box-type img {
        height: 40px;
        margin-top: -5px;
    }

    .wrapper-box-type {
        display: flex;
        width: auto;
        min-height: 150px;
        margin: 0;
        padding: 0 0 0 10px;
        animation: slide-top .5s cubic-bezier(0.250, 0.460, 0.450, 0.940) 3s both;
    }
    .wrapper-box-type:nth-child(2) {animation-delay: 3.1s}
    .wrapper-box-type:nth-child(3) {animation-delay: 3.2s}
    .wrapper-box-type:nth-child(4) {animation-delay: 3.3s}
    @keyframes slide-top {
        0% {
            -webkit-transform: translateY(-100px);
            transform: translateY(-100px);
            opacity: 0;
        }
        100% {
            -webkit-transform: translateY(0);
            transform: translateY(0);
            opacity: 1;
        }
    }
    .wrapper-box-type:last-child{margin-bottom: 30px;}
    .box-table-stat{
        display: flex;
        height: 172px;
        flex-wrap: nowrap;
        overflow-x: auto;
        overflow-y: hidden;
        margin-bottom: 0!important;
    }
    .box-table-stat .content-table-info {
        height: 172px;
        width: 100%;
        margin-right: 5px;
        border-radius: 6px;
        overflow: hidden;
        background-color: #150214;
    }
    .box-table-stat .content-table-info-left{
        width: 100%!important;
        height: 100px;
    }
    .box-table-stat .content-table-info-right{
        width: 100% !important;
        display: flex;
    }
    .box-table-stat .content-table-info-right .box-blocker,
    .box-table-stat .content-table-info-right .box-current-result{
        margin-top: 0!important;
        display: flex;
        justify-content: space-between;
    }
    .box-table-stat .content-table-info-right .box-current-result > .current-box{
        display: inline-block;
        margin-bottom: 0;
        border-radius: 5px;
        font-size: 14px;
        height: 25px;
        padding: 2px 10px;
        text-transform: uppercase;
    }
    .box-table-stat .point-result{
        font-size: 16px;
    }


    .box-table-stat .box-table-list ,
    .box-table-stat .box-table-list > .b-child-tb {
        padding: 1px;
    }
    .box-table-stat .user-list-detail span {
        font-size: 12px;
    }

    .box-table-list {
        width: 100%;
        background-color: #fff;
        color: #000;
        padding: 5px;
        display: flex;
    }

    .box-table-list > .b-child-tb {
        width: 100%;
        padding: 0px 5px;
    }

    .dice-cs {
        font-size: inherit;
        color: #000;
        margin: 0px;
    }

    .dice-cs span {
        margin-right: 2px;
    }

    .dice-txt {
        color: #000;
        font-size: 14px;
    }

    .box-game-result {
        text-align: center;
        width: 100%;
        color: #fff;

    }

    .user-list-detail {
        border-bottom: solid 1px #eee;
    }

    .dice-lg span.dice {
        display: inline-block;
        min-height: 2em;
        width: 19px;
        margin: 0 auto;
        background-size: 18px;
        background-position: center;
        background-repeat: no-repeat;
    }

    span.text-lo, span.text-hi, span.text-bgwhite {
        width: 17px !important;
        height: 17px !important;
        padding: 1px 2px;
        line-height: 12px;
        font-size: 12px !important;
        margin: 0px 5px;
        box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
    }

    span.text-bgwhite {
        color: #000;
        padding: 0px 8px;
        font-weight: 600;
        border-radius: 3px;
        box-shadow: rgba(0, 0, 0, 0.16) 0px 1px 4px;
    }

    .box-blocker {
        margin-top: 30px;
    }

    .color-white {
        background-color: #fff;
        color: #000;
    }

    .color-red {
        background-color: #c52828 !important;
        color: #fff !important;
    }

    .color-green {
        background-color: #28a745 !important;
        color: #fff !important;
    }

    .color-yellow {
        background-color: #fab600;
        color: #fff !important;
    }

    .box-score-table {
        display: flex;
    }

    .box-score-table table {
        border: 0 !important;
        width: 100%;
        height: 100%;
    }

    .box-score-table td {
        border-collapse: collapse;
    }

    .column {
        display: flex;
        float: left;
        width: 41%;
        /*border-right: 1px;*/
        /*border-left: 1px;*/
        /*border-style: solid;*/
        /*border-color: rgb(66, 65, 65);*/
    }

    .column1 {
        float: left;
        width: 100%;
    }

    .box-score-table .score-scroll {
        margin: 0;
        width: auto;
    }

    .score-scroll:hover {
        overflow-x: overlay;
    }

    div.score-scroll {
        display: block;
        position: relative;
        width: 100%;
        overflow-x: hidden;
        overflow-y: hidden;
        white-space: nowrap;
        border-bottom: solid 1px #000;
    }

    .score-scroll {
        background-color: transparent;
    }

    .board-backgroundx {
        border: solid 1px #ddd !important;
    }

    .text-score_bigroad {
        /* font-size: 14px; */
        width: 9px;
        height: 9px;
        display: block;
        margin: 1px;
    }

    .text-score {
        width: 15.5px;
        height: 15.5px;
        display: block;
        margin: 2px;
    }

    .text-score, .text-score-status, .text-score_all, .text-score-topic, .text-status-primary, .text-status-secondary, .text-red-bcc, .text-score_eye, .text-score_bigroad, .text-blue-bcc, .text-score_bigeye, .text-score_small {
        text-shadow: 0.5px 0.5px 0.5px #eee;
    }

    .text-box-score-right_eye {
        width: 4px;
        height: 2px;
    }

    .text-box-score-right_eye {
        display: table;
        margin-top: 1px;
        margin-bottom: 1px;
        margin-right: 1px;
        margin-left: 1px;
        padding-top: 1px;
    }

    .text-box-score-right_small {
        width: 2px;
        height: 2px;
    }

    .text-box-score-right_small {
        display: table;
        margin-top: 1px;
        margin-bottom: 1px;
        margin-right: 1px;
        margin-left: 1px;
        padding-top: 1px;
    }

    .color-red {
        background-color: #c52828 !important;
    }

    .color-blue {
        background-color: #006aff !important;
    }

    .color-yellow {
        background-color: #fab600;
    }

    .box-lot-cell {
        text-align: center;
        font-size: 13px;
        width: 18px;
        height: 18px;
        border-radius: 50%;
        cursor: pointer;
        display: inline-block;
        line-height: 17px;
        color: #fff;
        float: left;
        margin: 0 auto;
        font-family: "Athiti", sans-serif;
        margin-top: -1px;
        margin-left: -2px;
        position: relative;
    }

    .box-lot-cell_sm, .box-lot-cell_mini {
        width: 15px;
        height: 15px;
        border-radius: 50%;
        display: inline-block;
        float: left;
        font-family: "Athiti", sans-serif;
        margin-top: -1px;
        margin-left: -2px;
        border: solid 2px;
    }

    .box-lot-cell_mini {
        width: 6px;
        height: 6px;
        float: right;
    }

    .float-dot {
        display: block;
        width: 8px;
        height: 8px;
        border-radius: 50%;
        position: absolute;
        border: solid 1px #fff;
    }

    .dot-red {
        background-color: #c52828 !important;
        top: -1px;
        left: -2px;
    }

    .dot-blue {
        background-color: #006aff !important;
        bottom: -1px;
        right: 0px;
    }

    .border-red {
        border-color: #c52828 !important;
    }

    .border-blue {
        border-color: #006aff !important;
    }

    .border-green {
        border-color: #28a745 !important;
    }

    .content-table-info-right strong {
        font-size: 8vw !important;
    }

    .content-table-info-right strong {
        font-size: 8vw !important;
    }

    .box-st {
        padding: 5px;
        border-bottom: solid 1px #000;
    }

    .cols-1, .cols-2 {
        display: flex;
        color: #fff;
        text-align: center;
    }

    .cols-1 span, .cols-2 span {
        padding: 4px;
        margin: 2px;
        font-size: 16px;
        font-weight: 500;
        border: solid 1px #000;
        background-color: rgba(0, 0, 0, 0.4);
    }

    .cols-1 span {
        width: 33.3%;
    }

    .cols-2 span {
        width: 50%;
    }

    .box-current-result {
        color: #fff;
        display: flex;
        align-items: center;
        justify-content: center;
        width: 15px;
        height: 15px;
    }

    .box-current-result p {
        margin: 0px;
        font-size: 1.6em;
    }

    .box-current-result > .current-box {
        display: inline-block;
        margin-bottom: 10px;
        border-radius: 5px;
        font-size: 2em;
        padding: 2px 10px 0px 10px;
        text-transform: uppercase;
    }
    .GameType-casino .content-page{
        height: 100vh;
        overflow-y: scroll;
    }

    .point-result {
        font-size: 2.2em !important;
    }
    @media (orientation: landscape) {
        .GameType{
            overflow-y: auto;
            height: 100vh;
            position: relative;
        }
        .GameType .content-page-spin .box-img .box-user-img{display: none!important}
        .GameType .content-page-spin .box-img{
            top: -48px!important;
            left: 30px!important;
        }
        .box-current-result > .current-box {
            margin-bottom: 7px !important;
            font-size: 16px !important;
        }
        .content-page-spin .box-img{
            top: -50px!important;
            left: 20px!important;
        }

        .GameType .content-page-spin .box-img{
            top: -47px!important;
            left: 25px!important;
        }

        .box-img-list {
            background-size: contain;
        }

        .box-games-list {
            max-width: 100%;
            height: 120px;
            padding: 0;
            border-radius: 10px;
            position: relative;
        }

        .box-top-user-list {
            width: 100%;
        }

        .content-page {
            position: relative;
        }
        .box-table-stat .content-table-info {
            width: 300px!important;
            height: auto !important;
        }

        .box-table-stat .content-table-info .list-dice {
            font-size: 20px !important;
        }
        .box-table-stat .content-table-info .box-dices {
            align-items: flex-end;
            padding-left: 5px;
        }
        .box-table-stat .content-table-info .box-current-result p{
            font-size: 17px!important;
        }
    }
    /* Portrait */




</style>
<style>
    @media only screen
    and (min-device-width: 768px)
    and (max-device-width: 1024px)
    and (orientation: portrait)
    and (-webkit-min-device-pixel-ratio: 1) {
        .box-current-result p ,
        .box-table-stat .point-result{
            font-size: 23px !important;
            font-weight: 600;
            line-height: 1;
        }
    }
</style>