
<template>
    <div class="MessageBaccaratAlert MessageUnboxItem incoming_msg">

        <!--<div class="incoming_msg_img">-->
            <!--<img-->
                <!--src="@/assets/images/icon/4382504261561520738.png"-->
                <!--alt="sunil"-->
            <!--/>-->
        <!--</div>-->
        <div v-if="message_data" class="received_msg type-activity">
            <!--<h3>Bacarat</h3>-->
            <div class="received_withd_msg">
                <div :class="`box-top-user-list box-top-user-list-unbox ${backgroundColorClass}`">
                    <div @click="play" class="box-games-list box-jackpot">
                        <div class="list-jackpot">
                            <div class="item-unbox">
                                <img :src="message_data.icon_url" alt="" />
                            </div>
                            <div class="detail-jackpot">
                                <div class="detail-jackpot-name">
                                    <span>คุณ {{message_data.username}}</span>

                                </div>
                                <span>
                                    <strong>
                                        <span> ได้รับ {{message_data.item_name}}</span>
                                    </strong>
                                    <strong class="txt-large">฿{{message_data.result_return | numberFormat}}</strong>
                                </span>
                                <div class="detail-jackpot-bg"></div>
                            </div>
                        </div>
                    </div>
                </div>
                <!-- <div class="box-top-user-list box-top-user-list-unbox unbox-gold">
                    <div class="box-games-list box-jackpot">
                        <div class="list-jackpot">
                            <div class="item-unbox">
                                <img src="https://pesstar.com/images/caseitem/data_10041/21.png" alt="" />
                            </div>
                            <div class="detail-jackpot">
                                <span>
                                    <strong>คุณ  Taylor ได้รับ </strong>
                                    <strong class="txt-large">฿235,200</strong>
                                </span>
                                <div class="detail-jackpot-bg"></div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="box-top-user-list box-top-user-list-unbox unbox-red">
                    <div class="box-games-list box-jackpot">
                        <div class="list-jackpot">
                            <div class="item-unbox">
                                <img src="https://pesstar.com/images/caseitem/data_10041/19.png" alt="" />
                            </div>
                            <div class="detail-jackpot">
                                <span>
                                    <strong>คุณ  Taylor ได้รับ </strong>
                                    <strong class="txt-large">฿76,480</strong>
                                </span>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="box-top-user-list box-top-user-list-unbox unbox-pink">
                    <div class="box-games-list box-jackpot">
                        <div class="list-jackpot">
                            <div class="item-unbox">
                                <img src="https://pesstar.com/images/caseitem/data_10041/55.png" alt="" />
                            </div>
                            <div class="detail-jackpot">
                                <span>
                                    <strong>คุณ  Taylor ได้รับ </strong>
                                    <strong class="txt-large">฿25,760</strong>
                                </span>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="box-top-user-list box-top-user-list-unbox unbox-purple">
                    <div class="box-games-list box-jackpot">
                        <div class="list-jackpot">
                            <div class="item-unbox">
                                <img src="https://pesstar.com/images/caseitem/data_10041/46.png" alt="" />
                            </div>
                            <div class="detail-jackpot">
                                <span>
                                    <strong>คุณ  Taylor ได้รับ </strong>
                                    <strong class="txt-large">฿10,720</strong>
                                </span>
                            </div>
                        </div>
                    </div>
                </div> -->
            </div>
            <!--<span class="time_date"> 11:01 AM    |    June 9</span>-->
        </div>
    </div>

</template>
<script>

import {UNBOX_BACKGROUND_COLOR_CLASS} from './Constant'

export default {
    name: 'MessageLottoResult',
    props: ['message'],
    computed: {
        message_data(){
            return (this.message) ? this.message.data : null
        },
        timestamp(){
            return (this.message) ? this.message.timestamp : ''
        },
        backgroundColorClass() {
            if(this.message_data) {
                return UNBOX_BACKGROUND_COLOR_CLASS[this.message_data.name_color]
            } else {
                return ''
            }
        },
    },
    methods: {
        play() {
            this.$router.push({name: 'Unbox'})
        },
    },
}
</script>