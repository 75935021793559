<template>

    <div class="GameList GameList-popular" v-if="list2">
        <AllProvider ></AllProvider>
        <div class="box-type-slot">
            <div @click="openSexyBaccaratGame()" class="box-type-slot-sexy">

            </div>
            <div @click="openSaGame()" class="box-type-slot-saGaming"></div>
        </div>

        <div class="box-list-game-type">



            <!-- <div v-if="isOnDev" class="pop-provider">
                <div class="pop-provider-info">
                    <div v-for="(provider, key) of providerList" :key="key" @click="openGameListModal(provider)" class="pop-provider-box">
                        <img :src="provider.image"/>
                        <p>{{provider.label}}</p>
                    </div>
                </div>
            </div> -->
            <div class="GameList-popular-slot">
                <div class="box-sublist-area-topTap">
                    <!-- <span class="active">แนะนำเกม</span>
                    <span>เกมยอดนิยม</span>
                    <span>
                        <i class="fas fa-search"></i>
                        <input type="text" placeholder="ค้นหา" />
                    </span> -->
                    <span v-for="(item, key) of categories" :key="key"
                          :class="{'active': item === currentCategory}"
                          @click="selectCategory(item)">
                    {{item.label}}
                </span>
                    <span>
                    <i class="fas fa-search"></i>
                    <input v-model="keyword" type="text" placeholder="ค้นหา" />
                </span>
                </div>
                <div class="HorizontalShortList-pop">
                    <HorizontalShortList
                            v-for="(provider, key) of providerList" :key="`${key}:${tag}:${keyword}`"
                            :provider="provider"
                            :cat="cat"
                            :tag="tag"
                            :keyword="keyword"
                            @seeAll="openGameListModal"></HorizontalShortList>
                </div>
            </div>


            <div class="wrapbox-list-games">
<!--                ไลฟ์สด-->
                <div class="GameList-box" >
                    <div class="GameList-box-title">
                        <h4>ไลฟ์สด</h4>
                        <div class="btnMore"></div>
                    </div>
                    <div class="flexListgame">
                        <carousel
                                :per-page="2.2"
                                :mouseDrag="true"
                                :navigationEnabled="true"
                                :paginationEnabled="false"
                                navigationNextLabel="❯"
                                navigationPrevLabel="❮"
                                paginationPosition="bottom">
                            <slide v-for="(game,key) of list2.pop_list.baccarat" :key="key" >
                                <div class="box-list-games"   v-if="service_list.includes(game.service) || game.service==''"  @click="openGame2(game,game.service)">
                                    <div class="box-icon-games">
                                        <img :src="game.img">
                                    </div>
                                    <div class="box-detail-games">
                                        <h4>{{game.name}}</h4>
                                        <div class="info-games">
                                            <strong class="text-info"> {{game.count}} คนกำลังเล่น </strong>
                                        </div>
                                    </div>
                                </div>
                            </slide>
                        </carousel>
                    </div>
                </div>

<!--                สล็อต-->
                <div class="GameList-box" v-if="false">
                    <div class="GameList-box-title">
                        <h4>สล็อต</h4>
                        <div class="btnMore"></div>
                    </div>
                    <div class="flexListgame"  v-if="service_list.includes('pragmatic')">
                        <carousel
                                :speed="100"
                                :per-page="2.2"
                                :mouseDrag="true"
                                :spacePadding="1"
                                :navigationEnabled="true"
                                :paginationEnabled="false"
                                navigationNextLabel="❯"
                                navigationPrevLabel="❮"
                                paginationPosition="bottom">
                            <slide v-for="(game,key) of list2.pop_list.slot[0]" :key="key" >
                                <div class="box-list-games" @click="openGame2(game,'pragmatic')">
                                    <div class="box-icon-games">
                                        <div class="box-icon-games-bland">
                                            <div class="box-icon-games-bland-info">
                                                <img src="@/assets/images/slot/provider/FunkyGames.png"/>
                                            </div>
                                        </div>
                                        <img :src="game.img">

                                    </div>
                                    <div class="box-detail-games">
                                        <h4>{{game.name}}</h4>
                                        <div class="info-games" v-if="false">
                                            <strong class="text-info"> {{game.count}} คนกำลังเล่น </strong>
                                        </div>
                                    </div>
                                </div>
                            </slide>
                        </carousel>


                    </div>
                    <div class="flexListgame"  v-if="service_list.includes('cqgame')">
                        <carousel
                                :speed="100"
                                :per-page="2.2"
                                :mouseDrag="true"
                                :spacePadding="1"
                                :navigationEnabled="true"
                                :paginationEnabled="false"
                                navigationNextLabel="❯"
                                navigationPrevLabel="❮"
                                paginationPosition="bottom">
                            <slide v-for="(game,key) of list2.pop_list.slot[1]" :key="key" >
                                <div class="box-list-games" @click="openGame2(game,'cqgame')">
                                    <div class="box-icon-games"><img :src="game.img"></div>
                                    <div class="box-detail-games">
                                        <h4>{{game.name}}</h4>
                                        <div class="info-games" v-if="false">
                                            <strong class="text-info"> {{game.count}} คนกำลังเล่น </strong>
                                        </div>
                                    </div>
                                </div>
                            </slide>
                        </carousel>
                    </div>
                </div>

<!--                ยิงปลา-->
                <div class="GameList-box">
                    <div class="GameList-box-title">
                        <h4>ยิงปลา</h4>
                        <div class="btnMore"></div>
                    </div>
                    <div class="flexListgame"  v-if="service_list.includes('pragmatic')">
                        <carousel
                                :speed="100"
                                :per-page="2.2"
                                :mouseDrag="true"
                                :spacePadding="1"
                                :navigationEnabled="true"
                                :paginationEnabled="false"
                                navigationNextLabel="❯"
                                navigationPrevLabel="❮"
                                paginationPosition="bottom">
                            <slide v-for="(game,key) of list2.pop_list.fishing[0]" :key="key" >
                                <div class="box-list-games" @click="openGame2(game,'pragmatic')">
                                    <div class="box-icon-games">
                                        <img :src="game.img">
                                    </div>
                                    <div class="box-detail-games">
                                        <h4>{{game.name}}</h4>
                                        <div class="info-games">
                                            <strong class="text-info"> {{game.count}} คนกำลังเล่น </strong>
                                        </div>
                                    </div>
                                </div>

                            </slide>
                        </carousel>


                    </div>
                    <div class="flexListgame"  v-if="service_list.includes('cqgame')">
                                            <carousel
                                                    :speed="100"
                                                    :per-page="2.2"
                                                    :mouseDrag="true"
                                                    :spacePadding="1"
                                                    :navigationEnabled="true"
                                                    :paginationEnabled="false"
                                                    navigationNextLabel="❯"
                                                    navigationPrevLabel="❮"
                                                    paginationPosition="bottom">
                                                <slide v-for="(game,key) of list2.pop_list.fishing[1]" :key="key" >
                                                    <div class="box-list-games" @click="openGame2(game,'cqgame')">
                                                        <div class="box-icon-games"><img :src="game.img"></div>
                                                        <div class="box-detail-games">
                                                            <h4>{{game.name}}</h4>
                                                            <div class="info-games">
                                                                <strong class="text-info"> {{game.count}} คนกำลังเล่น </strong>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </slide>
                                            </carousel>


                    </div>
                </div>
                <div class="GameList-box" v-if=" service_list.includes('kp') || service_list.includes('queenmaker') ">
                    <div class="GameList-box-title">
                        <h4>P2P</h4>
                        <div  class="btnMore"></div>
                    </div>
                    <div class="flexListgame">
                        <carousel
                                :speed="100"
                                :per-page="2.2"
                                :mouseDrag="true"
                                :spacePadding="1"
                                :navigationEnabled="true"
                                :paginationEnabled="false"
                                navigationNextLabel="❯"
                                navigationPrevLabel="❮"
                                paginationPosition="bottom">
                            <slide v-for="(game,key) of list2.pop_list.p2p[0]" :key="key"  >

                                <div class="box-list-games" @click="openGame2(game,'kp')">
                                    <div class="box-icon-games"><img :src="game.img"></div>
                                    <div class="box-detail-games">
                                        <h4>{{game.name}}</h4>
                                        <div class="info-games">
                                            <strong class="text-info"> {{game.count}} คนกำลังเล่น </strong>
                                        </div>
                                    </div>
                                </div>

                            </slide>
                        </carousel>


                    </div>
                    <div class="flexListgame">
                        <carousel
                                :speed="100"
                                :per-page="2.2"
                                :mouseDrag="true"
                                :spacePadding="1"
                                :navigationEnabled="true"
                                :paginationEnabled="false"
                                navigationNextLabel="❯"
                                navigationPrevLabel="❮"
                                paginationPosition="bottom">
                            <slide v-for="(game,key) of list2.pop_list.p2p[1]" :key="key"  >

                                <div class="box-list-games" @click="openGame2(game,'queenmaker')">
                                    <div class="box-icon-games"><img :src="game.img"></div>
                                    <div class="box-detail-games">
                                        <h4>{{game.name}}</h4>
                                        <div class="info-games">
                                            <strong class="text-info"> {{game.count}} คนกำลังเล่น </strong>
                                        </div>
                                    </div>
                                </div>

                            </slide>
                        </carousel>


                    </div>
                </div>

<!--                New-->

            </div>
        </div>
        <modal name="more-game-modal" height="80%" width="80%">
            <div style="height: 100%; overflow-y: scroll;">
                <div v-for="(gameType, key) of gameTypeArr" :key="key">
                    <div v-show="currentBrand === gameType.slug">
                        <h3 style="color: black; padding: 4px 15px;">{{gameType.title}}</h3>
                        <div @click="openGame(gameType.slug, game)" 
                        v-for="(game, key) of getGameArr(gameType.slug, true)" 
                        :key="key" 
                        class="box-list-games"
                        style="padding: 4px 15px;">
                            <div class="box-icon-games"><img :src="calIcon(gameType.slug, key)"></div>
                            <div class="box-detail-games">
                                <h4>{{game.title}}</h4>
                                <div class="info-games">
                                    <strong class="text-info"> {{getPlayingCount() | numberFormat}} คนกำลังเล่น </strong>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </modal>
        <div class="GameList-mainGame" :class="{'GameListMainGameShow':isShowGameListModal}">
            <GamelistGameList v-if="currentProvider" :provider="currentProvider" @close="closeGameListModal"></GamelistGameList>
        </div>
    </div>
</template>
<script>

    import axiosWrapper from '@/axiosWrapper'
    import randomBetween from '@/lib/randomBetween'
    import store from '@/store'
    import UserMixin from '@/mixins/UserMixin'
    import { Carousel, Slide } from 'vue-carousel'
    import  AllProvider from '@/components/AllProvider'
    import GamelistGameList from '@/components/gamelist/GameList'
    import HorizontalShortList from '@/components/gamelist/HorizontalShortList'
    import {isStringContainThaiAlpha, removeThaiAlpha} from '@/lib/TextHandle'

    export default {
        name: 'GameList',
        mixins: [UserMixin],
        components: {
            Carousel,
            Slide,
            AllProvider,
            GamelistGameList,
            HorizontalShortList,
        },
        data() {
            return {
                gameTypeArr: [
                    {
                        slug: 'queenmaker',
                        title: 'QUEENMAKER',
                    },
                    {
                        slug: 'kp',
                        title: 'KP',
                    },
                    {
                        slug: 'cqgame',
                        title: 'CQGAME',
                    },
                ],
                games: null,
                icons: {
                    queenmaker: [
                        '/images/icon/set-icon/icon-casino.png',
                        '/images/icon/set-icon/icon-slot.png',
                        '/images/icon/set-icon/icon-casino.png',
                        '/images/icon/set-icon/icon-football.png',
                        '/images/icon/set-icon/icon-fishing.png',
                        '/images/icon/set-icon/icon-lotto.png',
                        '/images/icon/set-icon/icon-roulette.png',
                        '/images/icon/set-icon/icon-case.png',
                    ],
                    kp: [
                        '/images/icon/set-icon/icon-casino.png',
                        '/images/icon/set-icon/icon-football.png',
                        '/images/icon/set-icon/icon-fishing.png',
                        '/images/icon/set-icon/icon-lotto.png',
                        '/images/icon/set-icon/icon-roulette.png',
                        '/images/icon/set-icon/icon-case.png',
                        '/images/icon/set-icon/icon-casino.png',
                        '/images/icon/set-icon/icon-slot.png',
                    ],
                    cqgame: [
                        '/images/icon/set-icon/icon-fishing.png',
                        '/images/icon/set-icon/icon-lotto.png',
                        '/images/icon/set-icon/icon-roulette.png',
                        '/images/icon/set-icon/icon-case.png',
                        '/images/icon/set-icon/icon-casino.png',
                        '/images/icon/set-icon/icon-slot.png',
                        '/images/icon/set-icon/icon-casino.png',
                        '/images/icon/set-icon/icon-football.png',
                    ],
                },
                currentBrand: 'queenmaker', // queenmaker, kp, cqgame
                game_pop_list:{
                    'sa':[],
                    'sexy':[],
                    'pragmatic':[],
                    'fishing':[],
                },
                game_pop_list_selected:{
                    'sa':[],
                    'sexy':[],
                    'pragmatic':[],
                    'fishing':[],
                },
                list2:null,
                service_list : `${process.env.VUE_APP_PES_SERVICE_LIST}`.split(","),
                // providerList: [
                //     {
                //         slug: 'PGSOFT',
                //         value: 'PG Slot',
                //         label: 'PG Slot',
                //         image: '/images/slot/pop/PGSlot.jpg',
                //         perPage : 3.2
                //     },
                //     {
                //         slug: 'BPG',
                //         value: 'BluePrint',
                //         label: 'BluePrint',
                //         image: '/images/slot/pop/BluePint.jpg',
                //         wrapperClass: 'GameList-box-wide',
                //         perPage : 2.3
                //     },
                //     {
                //         slug: 'MICRO',
                //         value: 'Micro Gaming',
                //         label: 'Micro Gaming',
                //         image: '/images/slot/pop/MicroGaming.jpg',
                //         perPage : 3.2
                //     },
                //     {
                //         slug: 'CQ9',
                //         value: 'CQ9',
                //         label: 'CQ9',
                //         image: '/images/slot/pop/cq9.jpg',
                //     },
                //     {
                //         slug: 'RLX',
                //         value: 'Relax Gaming',
                //         label: 'Relax Gaming',
                //         image: '/images/slot/pop/relax_gaming.jpg',
                //         wrapperClass: 'GameList-box-wide',
                //         perPage : 2.3
                //     },
                //     {
                //         slug: 'PRAGMATIC',
                //         value: 'Pragmatic Play',
                //         label: 'Pragmatic Play',
                //         image: '/images/slot/pop/Pragmatic-Play.jpg',
                //         wrapperClass: 'GameList-box-wide',
                //         perPage : 2.8
                //     },
                //     {
                //         slug: 'AMBPOKER',
                //         value: 'AMB Poker',
                //         label: 'AMB Poker',
                //         image: '/images/slot/pop/ambpoker.jpg',
                //         wrapperClass: 'GameList-box-vertical',
                //         perPage : 3.7
                //     },
                //     {
                //         slug: 'JILI',
                //         value: 'JILI',
                //         label: 'JILI',
                //         image: '/images/slot/pop/JILI.jpg',
                //         perPage : 3.2
                //     },
                //     {
                //         slug: 'SLOTXO',
                //         value: 'Slot XO',
                //         label: 'Slot XO',
                //         image: '/images/slot/pop/slotxo.jpg',
                //     },
                //     {
                //         slug: 'DS',
                //         value: 'Dragoon Soft',
                //         label: 'Dragoon Soft',
                //         image: '/images/slot/pop/DragoonSoft.jpg',
                //         wrapperClass: 'GameList-box-wide',
                //     },
                //     {
                //         slug: 'NE',
                //         value: 'NETENT',
                //         label: 'NETENT',
                //         image: '/images/slot/pop/NETENT.jpg',
                //         wrapperClass: 'GameList-box-wide',
                //     },
                //     {
                //         slug: 'YGG',
                //         value: 'Yggdrasil',
                //         label: 'Yggdrasil',
                //         image: '/images/slot/pop/Yggdrasil.jpg',
                //         wrapperClass: 'GameList-box-wide',
                //         perPage : 2.3
                //     },
                // ],
                providerList: [
                    {
                        slug: 'PGSOFT',
                        label: 'PGSOFT',
                        image: '/images/slot/provider/PGSOFT.png',
                        wrapperClass: 'GameList-box-square',
                        perPage : 2.8
                    },
                    {
                        slug: 'BPG',
                        label: 'Blueprint',
                        image: '/images/slot/provider/Blueprint.png',
                        wrapperClass: 'GameList-box-wide',
                        perPage : 2.3
                    },

                    {
                        slug: 'PRAGMATIC',
                        label: 'PragmaticPlay',
                        image: '/images/slot/provider/PragmaticPlay.png',
                        wrapperClass: '',
                        perPage : 2.5
                    },
                    {
                        slug: 'AMBPOKER',
                        label: 'Amb Poker',
                        image: '/images/slot/provider/AmbPoker.png',
                        wrapperClass: 'GameList-box-height',
                        perPage : 3.2
                    },
                    {
                        slug: 'MICRO',
                        label: 'MicroGaming',
                        image: '/images/slot/provider/MicroGaming.png',
                        wrapperClass: 'GameList-box-square',
                        perPage : 3.2
                    },
                    {
                        slug: 'RLX',
                        label: 'RelaxGaming',
                        image: '/images/slot/provider/RelaxGaming.png',
                        wrapperClass: 'GameList-box-wide',
                        perPage : 2.2
                    },
                    {
                        slug: 'YGG',
                        label: 'Yggdrasil',
                        image: '/images/slot/provider/Yggdrasil.png',
                        wrapperClass: 'GameList-box-wide',
                        perPage : 2.5
                    },
                    {
                        slug: 'JILI',
                        label: 'JILI',
                        image: '/images/slot/provider/JILI.png',
                        wrapperClass: 'GameList-box-square',
                        perPage : 3.2
                    },
                ],
                currentProvider: null,
                isShowGameListModal: false,
                cat: null,
                tag: 'HOT',
                keyword: '',
                horizontalListSortBy: null,
                horizontalListSortDesc: null,
                categories: [
                    {
                        key: 'HOT',
                        label: 'เกมยอดนิยม',
                    },
                    {
                        key: 'RECOMMEND',
                        label: 'แนะนำเกม',
                    },
                ],
                currentCategory: null,
            }
        },
        computed: {
            is_member() {
                return store.state.is_member
            },
            pes_profile() {
                return store.state.pes_profile
            },
            token() {
                return this.pes_profile.token
            },
            gameProviderList() {
                return store.state.gameProviderList
            },
            appImageProfile(){
                return store.state.appInfo.app_image_profile
            },
        },
        watch: {
            keyword(newVal) {
                if(newVal) {
                    if(isStringContainThaiAlpha(newVal)) {
                        this.$bvToast.toast('กรุณากรอกข้อมูลเป็นภาษาอังกฤษ', {
                            title: 'แจ้งเตือน',
                            toaster: 'b-toaster-top-full'
                        })
                        this.keyword = removeThaiAlpha(newVal)
                    }
                }
            },
        },
        methods: {
            async getGameServiceList() {
               const res = await axiosWrapper({
                    method: 'get',
                    url: `${this.envDomains.VUE_APP_PES_BASE_URL}/api/game/v0/slot/get.php`,
                })

                // if (!res.data.success) {
                //     this.$alert(res.data.message, 'แจ้งเตือน', {
                //         confirmButtonText: 'ตกลง',
                //         customClass: 'custom-message-box'
                //     })
                //     return
                // }

                return res.data;
            },
            async getGameList(brand = 'queenmaker') {

                const res = await axiosWrapper({
                    method: 'post',
                    url: `${this.envDomains.VUE_APP_API}/api/game/list`,
                    data: {
                        brand
                    }
                })

                // if (!res.data.success) {
                //     this.$alert(res.data.message, 'แจ้งเตือน', {
                //         confirmButtonText: 'ตกลง',
                //         customClass: 'custom-message-box'
                //     })
                //     return
                // }

                return res.data.data
            },
            async getGameListAll() {
                const queenmaker =  await this.getGameList('queenmaker')
                const kp = await this.getGameList('kp')
                const cqgame = await this.getGameList('cqgame')
                return {
                    queenmaker,
                    kp,
                    cqgame,
                }
            },
            getGameArr(slug, isFull = false) {

                try {

                    if(!this.games) {
                        return []
                    }
                    if(!this.games[slug]) {
                        return []
                    }
                    if(!Array.isArray(!this.games[slug])) {
                        return []
                    }
                    if(isFull) {
                        return this.games[slug]
                    }
                    return this.games[slug].slice(0, 2)

                } catch(err) {
                    console.log('getGameArr err', err.message)
                } 
            },
            calIcon(brand, key) {
                return this.icons[brand][key % this.icons[brand].length]
            },
            getPlayingCount() {
                return randomBetween(111, 1111)
            },
            openGame(brand, game) {

                if (!this.is_member) {
                    this.notifyToSignUp('เพื่อเล่น')
                    return
                }

                const baseUrl = `/game/casinoonline/sa_sexy_redirect.php?service=${brand}`

                let query = ''
                if (brand === 'queenmaker') {
                    query = `&gpcode=${game.gpcode}&gcode=${game.gcode}`
                } else if (brand === 'kp') {
                    query = `&gameId=${game.gameId}`
                } else if (brand === 'cqgame') {
                    query = `&gamehall=${game.gamehall}&gamecode=${game.gamecode}`
                }
                if(this.appImageProfile){
                    query = query+'&logo_img='+this.appImageProfile
                }

                const url = `${this.envDomains.VUE_APP_PES_BASE_URL}${baseUrl}${query}&token=${this.token}`
                window.open(url, '_blank')
            },
            openGame2(game,service){
                if (!this.is_member) {
                    this.notifyToSignUp('เพื่อเล่น')
                    return
                }
                var params =[
                    
                ];
                
                if(service=='pragmatic'){
                    params.push('code='+game.gameId);
                }else if(service=='sa'){
                    params.push('code='+game.code);
                }
                if(this.appImageProfile){
                    params.push('logo_img='+this.appImageProfile);
                }
                 const url = `${this.envDomains.VUE_APP_PES_BASE_URL}/game/casinoonline/sa_sexy_redirect.php?mode=mobile&service=${service}&${params.join('&')}&token=${this.token}`
                if(service=='sa'){
                    window.location.href=url
                }else{
                    this.$router.push({ name: 'GamePlayFrame', "query": { 'url': encodeURIComponent(url) } })
                }
                
                
            },
            showMoreGame(brand) {
                this.currentBrand = brand
                this.$modal.show('more-game-modal')
            },
            showMoreGame2(type){
                this.currentBrand = type;
                this.$modal.show('more-game-modal')
            },
            openSexyBaccaratGame() {
                const provider = {
                    slug: 'SEXY',
                    label: 'Sexy Baccarat',
                    image: '/images/slot/provider/SexyBaccarat-icon.png',
                }
                this.openGameListModal(provider)
            },
            openSaGame() {
                const provider = {
                    slug: 'SAGAME',
                    label: 'SA Gaming',
                    image: '/images/slot/provider/SAGaming-icon.png',
                }
                this.openGameListModal(provider)
            },
            openGameListModal(provider) {
                this.currentProvider = provider
                this.isShowGameListModal = true
            },
            closeGameListModal() {
                this.currentProvider = null
                this.isShowGameListModal = false
            },
            selectCategory(item) {
                this.currentCategory = item
                this.calculateFilterAndSort()
            },
            calculateFilterAndSort() {
                this.clearFilterAndSort()
                const currentCategoryKey = this.currentCategory.key
                if(currentCategoryKey === 'HOT' || currentCategoryKey === 'RECOMMEND') {
                    this.tag = currentCategoryKey
                } else if(currentCategoryKey === 'Name') {
                    this.tag = null
                    this.horizontalListSortBy = currentCategoryKey
                    this.horizontalListSortDesc = 'false'
                } else {
                    this.clearFilterAndSort()
                }
            },
            clearFilterAndSort() {
                this.tag = 'HOT'
                this.horizontalListSortBy = null
                this.horizontalListSortDesc = null
            },
        },
        async mounted() {
            this.list2 =await  this.getGameServiceList();
            store.commit('updateAllGameList', this.list2)

            this.currentCategory = this.categories[0]
            this.calculateFilterAndSort()
        },
    }
</script>