<template>
  <nav
    class="nav-menu ShortList"
    v-if="gameList.length > 0 && (checkGameProviderAvailable(provider.slug) || provider.slug==null)"
    :class="`GameList-box ${
      provider.wrapperClass ? provider.wrapperClass : 'GameList-box-square'
    }`"
  >
    <div class="nav-menu-topic">
      <img :src="provider.image" />
      <h4>{{ provider.label }}</h4>
      <div
        v-if="showMore"
        class="btn-list-games"
        @click="
          !checkServiceProvider(provider.slug) ? seeAll(provider.slug) : false
        "
      >
        <span>All</span>
      </div>
    </div>
    <ul class="nav nav-pills nav-pills-slot">
      <li
        class="nav-item"
        v-for="(game, key) of gameList"
        :key="key"
        :class="[
          checkServiceProvider(provider.slug),
          toLowerCase(game.Categories[0]),
          `item-game-${provider.slug? provider.slug.toLowerCase():''}`
        ]"
      >
        <div
          class="nav-item-info"
          @click="!checkServiceProvider(provider.slug) ? play(game) : false"
        >
          <div class="overlay"></div>
          <a href="#">
            <el-image :src="getGameImage(game)">
              <el-image slot="error" :src="game.Image1">
                <div class="noneImageSlot" slot="error">
                  <i class="fas fa-image"></i>
                </div>
              </el-image>
            </el-image>

            <!-- <img :src="getGameImage(game)"  /> -->
          </a>
        </div>

        <div class="-text-nav-menu">{{ game.Name }}</div>
      </li>
      <li class="nav-item nav-itemAll">
        <div
          class="btn-list-games"
          @click="
            !checkServiceProvider(provider.slug) ? seeAll(provider.slug) : false
          "
        >
          <span>All</span>
        </div>
      </li>
    </ul>
  </nav>
</template>
<script>
import axiosWrapper from "@/axiosWrapper";
import GameListMixin from "./GameListMixin";
import localStorageCache from "@/lib/localStorageCache";
import store from "@/store";

export default {
  name: "GameListHorizonalShortList2",
  mixins: [GameListMixin],
  components: {},
  props: [
    "provider",
    "cat",
    "perPage",
    "tag",
    "sortBy",
    "sortDesc",
    "keyword",
    "showMore",
  ],
  data() {
    return {
      isOnLoad: false,
      gameList: [],
      isRefreshCarouselWidth: false,
      routeName : 'Home',
    };
  },
  computed: {
    pes_profile() {
      return store.state.pes_profile;
    },
    service() {
      // return localStorage.service
      return store.state.service;
    },
  },
  methods: {
    checkServiceProvider(provider) {
      if (this.pes_profile && provider) {
        let service = provider.toLowerCase();

        const service_obj = this.service;
        if (service_obj) {
          if (service_obj[service]) {
            let is_login = service_obj[service].login;
            if (is_login == false) {
              return "disabledNavItem";
            } else {
              return "";
            }
          } else if (service_obj["amb_" + service]) {
            let is_login = service_obj["amb_" + service].login;
            if (is_login == false) {
              return "disabledNavItem";
            } else {
              return "";
            }
          } else if (service_obj["qt_" + provider]) {
            let is_login = service_obj["qt_" + provider].login;
            if (is_login == false) {
              return "disabledNavItem";
            } else {
              return "";
            }
          } else {
            return "";
            // console.log(service);
            // console.log('qt_'+service);
            // console.log('amb_'+service);
            // console.log(service_obj)
          }
        }
      } else {
        return "";
      }
    },
    async loadGameList() {
      this.isOnLoad = true;

      const provider = this.provider.slug;

      const cat = this.cat;
      const tag = this.tag;
      const sortBy = this.sortBy;
      const sortDesc = this.sortDesc;
      const keyword = this.keyword;

      const localStorageCacheKey = `horizontalShortList;${provider};${cat};${tag};${sortBy};${sortDesc}:${keyword}`;

      // const dataFromCache = localStorageCache.get(localStorageCacheKey)

      // if(dataFromCache) {
      //     this.gameList = dataFromCache
      //     this.isOnLoad = false
      //     return
      // }

      const res = await axiosWrapper({
        method: "post",
        url: `${this.envDomains.VUE_APP_API}/api/gamelist-api/game`,
        data: {
          page: 1,
          itemsPerPage: 6,
          provider: provider,
          cat: cat,
          tag: tag,
          sortBy: sortBy,
          sortDesc: sortDesc,
          keyword: keyword,
        },
      });

      this.isOnLoad = false;

      if (res.status !== 200) {
        this.$bvToast.toast(res.data.message, {
          title: "แจ้งเตือน",
          toaster: "b-toaster-top-full",
        });
        return null;
      }
      this.gameList = res.data.items;
      if (res.data.items.length > 0) {
        localStorageCache.set(localStorageCacheKey, this.gameList, 1);
      }
    },
    async seeAll(slug) {
      this.$emit("seeAll", slug?  slug.toLowerCase():null, null);
    },
    getGameImage(game) {
      return `${this.envDomains.VUE_APP_API}/images/gameSlot/${game.ApiName}_${game.GameCode}.png`;
    },
    // imageLoaded() {
    //     this.refreshCarouselWidth()
    // },
    // refreshCarouselWidth(){
    //     if(this.isRefreshCarouselWidth) {
    //         return
    //     }
    //     if(!this.$refs.carousel) {
    //         return
    //     }
    //     this.$refs.carousel.computeCarouselWidth()
    //     this.isRefreshCarouselWidth = true
    // },
    play(game) {
      if (!this.is_member) {
        this.notifyToSignUp("เพื่อเล่น");
        return;
      }
      var params = [];
      let openNewTab = false;
      if (game.ApiName == "amb") {
        params.push("productId=" + game.Provider);
        params.push("gameId=" + game.GameCode);
        if (game.Provider.toLowerCase() == "sagame") {
          openNewTab = true;
        }
      } else if (game.ApiName == "qt") {
        params.push("gameId=" + game.GameCode);
      } else if (game.ApiName == "pragmatic") {
        params.push("code=" + game.GameCode);
      } else if (game.service == "sa") {
        params.push("code=" + game.code);
        openNewTab = true;
      }
      
      if (this.appImageProfile) {
        params.push("logo_img=" + this.appImageProfile);
      }
      const url = `${
        this.envDomains.VUE_APP_PES_BASE_URL
      }/game/casinoonline/sa_sexy_redirect.php?mode=mobile&service=${
        game.ApiName
      }&${params.join("&")}&token=${this.token}`;
      if (openNewTab == false) {
        this.$router.push({
          name: "GamePlayFrame",
          query: { url: encodeURIComponent(url) ,routeName : this.routeName },
        });
      } else {
        window.location.href = url;
      }
    },
    toLowerCase(str) {
      if (str) {
        return str.toLowerCase();
      } else {
        return str;
      }
    },
  },
  mounted() {
    this.loadGameList();
    let pathname =window.location.pathname;
    let pathnamesplit = pathname.split("/");
    this.routeName = pathnamesplit[1]? pathnamesplit[1]:'Home';
  },
};
</script>
