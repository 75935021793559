<template>
    <div class="Alarm">
        <span>{{hours | fillZero}}:{{minutes | fillZero}}:{{seconds | fillZero}}</span>
    </div>
</template>
<script>

export default {
    name: 'Alarm',
    props: ['start', 'pause'],
    data(){
        return {
            remain: null,
            interval: null
        }
    },
    computed: {
        hours(){
            return (this.remain) ? this.remain.get('hours') : 0
        },
        minutes(){
            return (this.remain) ? this.remain.get('minutes') : 0
        },
        seconds(){
            return (this.remain) ? this.remain.get('seconds') : 0
        },
    },
    filters: {
        fillZero(value){
            if(value.toString().length === 1){
                return `0${value}`;
            }else{
                return value;
            }
        }
    },
    methods: {
        startTimer(){
            this.remain = this.$moment.duration(this.start, 'minutes')
            this.interval = setInterval(() => {
                if(this.pause){
                    return
                }
                if(this.remain.as('seconds') <= 0){
                    this.$emit('timeOut')
                    this.restartTimer()
                    return
                }
                this.remain.subtract(1, 'seconds')
            }, 1000)
        },
        stopTimer(){
            clearInterval(this.interval)
        },
        restartTimer(){
            this.stopTimer()
            this.startTimer()
        }
    },
    mounted(){
        this.startTimer()
    },
    beforeDestroy(){
        this.stopTimer()
    }
}
</script>