<template>
    <div>
        <div v-if="false" class="box-right-btn">
            <button type="submit" @click="OpenPanel()">เปิด/ปิด</button>
            <button type="submit" @click="WinBanker()">เจ้ามือชนะ</button>
            <button type="submit" @click="WinPlayer()">ผู้เล่นชนะ</button>
            <button type="submit" @click="TieScare()">เสมอ</button>
            <button type="submit" @click="GetCard()">แจกไพ่</button>
            <button type="submit" @click="GetCardAll()">แจกไพ่ทั้งหมด</button>
            <button type="submit" @click="FlipCard()">เปิดไพ่</button>
            <button type="submit" @click="FlipCardAll()">เปิดไพ่ทั้งหมด</button>
<!--            <button type="submit" @click="CloseFlipCard()">ปิดไพ่</button>-->
        </div>
        <div class="box-panelCardMiddle" :class="{'OpanPanel':isPanelOpen}">
            <div class="box-panelCardMiddle-info" :class="{
                'win-banker': isBankerWin,
                'win-player': isPlayerWin,
                'tie-score': isTie,
                'getCardAll':get_card_all}">
                <div class="tie-active"></div>
                <div class="cardMiddleInfoLeft"  :class="{'corCardFlipAll':flip_card_all}">
                    <div class="box-insurance-float">
                        <div v-for="(item, key) of warrantyHistories(0)" :key="key" style="display: flex;">
                            <span>1:{{item.multiplier}}</span>
                            <span>{{item.myBetAmount}}</span>
                        </div>
                    </div>
                    <div class="win-active"></div>
                    <div class="cardMiddleInfoLeft-content">
                        <p>ผู้เล่น</p>
                        <strong>{{playerPointDisplay}}</strong>
                    </div>
                    <Card :card="cards.gameInfoCardPlayer2" :dataOpenDelay="cardDataOpenDelays.cardPlayer2"></Card>
                    <Card :card="cards.gameInfoCardPlayer1" :dataOpenDelay="cardDataOpenDelays.cardPlayer1"></Card>
                    <Card :card="cards.gameInfoCardPlayer0" isHorizontal="yes" :dataOpenDelay="cardDataOpenDelays.cardPlayer0"></Card>
                    <!-- <span class="corCard" :class="{'corCardFlip':flip_card}">
                               <div class="cardMain">
                                   <div class="content-cardMain">
                                       <div class="front-cardMain"> <img src="@/assets/images/pokercard/card-back.png"/></div>
                                       <div class="back-cardMain"> <img src="@/assets/images/pokercard/0101.png"/></div>
                                   </div>
                               </div>

                            </span>
                    <span class="corCard">
                               <div class="cardMain">
                                   <div class="content-cardMain">
                                       <div class="front-cardMain"> <img src="@/assets/images/pokercard/card-back.png"/></div>
                                       <div class="back-cardMain"> <img src="@/assets/images/pokercard/0101.png"/></div>
                                   </div>
                               </div>
                            </span>
                    <span class="corCard" :class="{'getCard':get_card}">
                        <div class="corCardRote">
                            <div class="cardMain">
                                   <div class="content-cardMain">
                                       <div class="front-cardMain"><img src="@/assets/images/pokercard/card-back.png"/></div>
                                       <div class="back-cardMain"><img src="@/assets/images/pokercard/0101.png"/></div>
                                   </div>
                               </div>
                        </div>
                    </span> -->
                </div>
                <div class="cardMiddleInfoRight" :class="{'corCardFlipAll':flip_card_all}">
                    <div class="box-insurance-float">
                        <div v-for="(item, key) of warrantyHistories(1)" :key="key" style="display: flex;">
                            <span>{{item.myBetAmount}}</span>
                            <span>1:{{item.multiplier}}</span>
                        </div>
                    </div>
                    <div class="cardMiddleInfoRight-content">
                        <strong>{{bankerPointDisplay}}</strong>
                        <p>เจ้ามือ</p>
                    </div>
                    <div class="win-active"></div>
                    <Card :card="cards.gameInfoCardBanker2" isHorizontal="yes" :dataOpenDelay="cardDataOpenDelays.cardBanker2"></Card>
                    <Card :card="cards.gameInfoCardBanker0" :dataOpenDelay="cardDataOpenDelays.cardBanker0"></Card>
                    <Card :card="cards.gameInfoCardBanker1" :dataOpenDelay="cardDataOpenDelays.cardBanker1"></Card>
                    <!-- <span class="corCard">
                        <div class="corCardRote">
                            <div class="cardMain">
                                   <div class="content-cardMain">
                                       <div class="front-cardMain"><img src="@/assets/images/pokercard/card-back.png"/></div>
                                       <div class="back-cardMain"><img src="@/assets/images/pokercard/0101.png"/></div>
                                   </div>
                               </div>
                        </div>
                    </span>
                    <span class="corCard">
                               <div class="cardMain">
                                   <div class="content-cardMain">
                                       <div class="front-cardMain"> <img src="@/assets/images/pokercard/card-back.png"/></div>
                                       <div class="back-cardMain"> <img src="@/assets/images/pokercard/0101.png"/></div>
                                   </div>
                               </div>

                            </span>
                    <span class="corCard">
                               <div class="cardMain">
                                   <div class="content-cardMain">
                                       <div class="front-cardMain"> <img src="@/assets/images/pokercard/card-back.png"/></div>
                                       <div class="back-cardMain"> <img src="@/assets/images/pokercard/0101.png"/></div>
                                   </div>
                               </div>
                            </span> -->
                </div>
            </div>
        </div>
    </div>



</template>
<script>

    import Card from '../FloatOpenCardCard'

    export default {
        name: 'FloatOpenCard',
        props: [
            'isPanelOpen',
            'playerPointDisplay',
            'bankerPointDisplay',
            'isPlayerWin',
            'isBankerWin',
            'isTie',
            'cards',
            'cardDataOpenDelays',
            'warrantyHistory',
        ],
        components: {
            Card,
        },
        data() {
            return {
                open_panel: false,
                win_banker: false,
                win_player: false,
                tie_score: false,
                flip_card: false,
                flip_card_all: false,
                get_card: false,
                get_card_all: false,
            }
        },
        methods: {
            OpenPanel() {
                if(this.open_panel){
                    this.open_panel = false;
                }else{
                    this.open_panel = true;
                }
                this.win_player = false;
                this.win_banker = false;
                this.tie_score = false;
                this.flip_card = false;
            },
            WinPlayer() {
                this.win_player = true;
                this.win_banker = false;
                this.tie_score = false;
            },
            WinBanker() {
                this.win_banker = true;
                this.win_player = false;
                this.tie_score = false;
            },
            TieScare() {
                this.tie_score = true;
                this.win_player = false;
                this.win_banker = false;
            },
            FlipCard() {
                if(this.flip_card){
                    this.flip_card = false;
                }else{
                    this.flip_card = true;
                }
                this.flip_card_all = false;
            },
            FlipCardAll() {
                if(this.flip_card_all){
                    this.flip_card_all = false;
                }else{
                    this.flip_card_all = true;
                }
                this.flip_card = false;
            },
            GetCard() {
                if(this.get_card){
                    this.get_card = false;
                }else{
                    this.get_card = true;
                }
            },
            GetCardAll() {
                if(this.get_card_all){
                    this.get_card_all = false;
                }else{
                    this.get_card_all = true;
                }
            },
            warrantyHistories(side) {
                const arr = []
                for(const item of this.warrantyHistory[side]) {
                    if(!item.multiplier) {
                        continue
                    }
                    arr.push({
                        multiplier: item.multiplier,
                        myBetAmount: (item.myBetAmount) ? item.myBetAmount : '-',
                    })
                }
                return arr
            },
        },
    }

</script>