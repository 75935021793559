<template>

    <div class="MessageIncomingText incoming_msg">
        <div class="incoming_msg-info">
            <div class="incoming-flex">
                <div class="incoming_msg_img" :class="{'active-bg':picture_bg_url}">
                    <!-- <el-image :src="user_picture_url">
                        <img slot="error" src="/images/avatar.png" />
                    </el-image> -->
                    <!-- <Avatar mode="default"></Avatar> -->
                    <!--                <div class="frame-profile frame-premium-2"></div>-->
                    <Avatar mode="image" :src="user_picture_url"></Avatar>
<!--                    <div class="bg-profile sp-premium-2" ></div>-->
                    <div :class="{'bg-profile sp-premium-2 ':picture_bg_url}" :style="picture_bg_url_style"></div>
                </div>
                <div class="incoming-content">
                    <div class="incoming-content-title">
                        <UserLevel :level="user_level"></UserLevel>
                        <div class="received_msg">
                            <h3>{{user_display_name}}</h3>
                        </div>
<!--                        messageCatBaccarat-->
<!--                        messageCatDragonTiger-->
<!--                        messageCatHilo-->
<!--                        messageCatFantan-->
                        <div class="messageCat messageCatBaccarat" v-if="false">
                            Baccarat
                        </div>
                    </div>
                    <div class="box-msg box-msg-chat">
                        {{message.data.message}}
                        <Feeling @react="type => { $emit('react', {type, message}) }"/>
                    </div>
                    <div class="received_withd_msg">
                        <span class="time_date"> {{message.timestamp | chatMessageDateTimeFormat}}</span>
                    </div>
                </div>
            </div>
        </div>

    </div>
</template>
<script>
import Feeling from '@/components/Feeling'
import store from '@/store'
import Avatar from '@/components/Avatar'
import UserLevel from '@/components/user/Level'
export default {
    name: 'MessageIncomingText',
    props: ['message'],
    components: {
        Feeling,
        Avatar,
        UserLevel,
    },
    computed: {
        user_info_arr(){
            return store.state.user_info_arr
        },
        user_info(){
            return this.user_info_arr.find((user_info) => {
                return parseInt(user_info.id) === parseInt(this.message.user_id)
            })
        },
        user_display_name(){
            return (this.user_info) ? this.user_info.display_name : ''
        },
        user_level(){
            return (this.user_info) ? this.user_info.level : 0
        },
        user_picture_url(){
            return (this.user_info) ? this.user_info.picture_url : ''
        },
        picture_bg_url(){
            return (this.user_info) ? this.user_info.picture_bg_url : ''
        },
        picture_bg_url_style() {
            return (this.picture_bg_url) ? `background-image:url(${this.picture_bg_url})` : ''
        },
    },
}
</script>

<style scoped>
    .incoming_msg_img{
        /*margin-bottom: 32px;*/
    }
</style>