<template>
<!--    <div class="PromotionTime contentFloatCoupon isShowModal">-->
    <div class="PromotionTime contentFloatCoupon" :class="{'FloatCouponHide': !isShowModal,'FloatCouponShow': isShowModal}">
        <div class="contentFloatCouponInfo" >
<!--            boxFloatCouponSuccess-->
            <div v-if="modalMode === 'status'" class="boxFloatCoupon" :class="{'boxFloatCouponSuccess': isCanClaimReward}">
<!--            <div v-if="modalMode === 'status'" class="boxFloatCoupon">-->
                <div v-if="!isCanClaimReward && parseFloat(balance) < 5" class="btnClose btnCouponCancel" @click="toCancelPromotionTime"><i class="fas fa-times"></i></div>
                <div v-if="!isCanClaimReward" class="timeCoupon">
                    <small>กิจกรรมนี้จะสิ้นสุดใน</small>
                    <!-- <span>02:00:03</span> -->
                    <Alarm :start="alarmStart" @timeout="timeOutHandle"></Alarm>
                </div>
                <div class="title">
<!--                    <h2>สถานะกิจกรรมโปรโมชั่น</h2>-->
<!--                        <div class="timeCoupon">กิจกรรมนี้จะสิ้นสุดใน : <span>02:00:03</span></div>-->

                </div>

                <div class="content">
                    <div class="text">
                        <!--                    <h6>หมายเหตุ</h6>-->
                        <p>อยู่ระหว่างกิจกรรมจะไม่สามารถทำธุรกรรมทางการเงินได้</p>
                    </div>
                    <div class="contentInfoCoupon">
                        <div class="rowTop">
                            <!-- <span>{{turnoverLabel}}</span> -->

                            <div class="rowTopInfo">
                                <strong>฿{{balance | numberFormat}}</strong>
                                <button @click="refresh" class="btn" :class="{'on-refresh': isOnRefresh}" :disabled="isOnRefresh">
                                <span class="icon">
                                    <i class="fas fa-redo"></i>
                                </span>
                                </button>
                            </div>
                            <span>ยอดเงินทั้งหมด</span>
                        </div>
                        <div class="contentInfoCouponBox">
                            <div class="rowContent">
                                <div class="rowLeft">
                                    <h6>ยอดเงินปัจจุบัน</h6>
                                    <!-- <span>{{targetLabel}}</span> -->
                                    <ul>
                                        <li>
                                            <p>ยอดเงินสดปัจจุบัน</p>
                                            <strong v-if="!balanceCanWithdraw || parseFloat(balanceCanWithdraw) === 0">-</strong>
                                            <strong v-else>฿{{balanceCanWithdraw | numberFormat}}</strong></li>
                                        <li>
                                            <span v-if="target_balance">
                                                <p>เป้าหมาย</p>
                                                <strong>{{target_balance | numberFormat}}</strong>
                                            </span>
                                            <span v-else>ไม่ต้องทำยอด</span>
                                        </li>
                                    </ul>
                                    <div v-if="target_balance" class="contentInfoCouponProgress">
                                        <div class="progress">
                                            <div class="progress-bar"  :style="`width: ${balanceOfTargetPercent}%;`"></div>
                                            <span>{{balanceOfTargetPercent | numberFormat(0)}}%</span>
                                        </div>
                                        <div class="contentInfoCouponProgressAward">
                                            <img src="@/assets/images/coin-small-1.png"/>
                                        </div>
                                    </div>
                                </div>
                                <div class="rowRight">
                                    <h6>ยอดเทิร์นปัจจุบัน</h6>
                                    <ul>
                                        <li>
                                            <p>เทิร์นปัจจุบัน</p>
                                            <strong v-if="!turnover || parseFloat(turnover) === 0">-</strong>
                                            <strong v-else>฿{{turnover | numberFormat}}</strong>
                                        </li>
                                        <li>
                                            <span v-if="target_turnover">
                                                <p>เป้าหมาย</p>
                                                <strong>{{target_turnover | numberFormat}}</strong>
                                            </span>
                                            <span v-else>ไม่ต้องทำเทิร์น</span>
                                        </li>
                                    </ul>
                                    <!-- <span>ถอนได้</span> -->




                                    <div v-if="target_turnover" class="contentInfoCouponProgress">
                                        <div class="progress">
                                            <div class="progress-bar"  :style="`width: ${turnoverOfTargetTurnoverPercent}%;`"></div>
                                            <span>{{turnoverOfTargetTurnoverPercent | numberFormat(0)}}%</span>
                                        </div>
                                        <div class="contentInfoCouponProgressAward">
                                            <img src="@/assets/images/coin-small-1.png"/>
                                        </div>
                                    </div>
                                </div>
                                <!-- <div class="rowRight" :class="{'rowRightSuccess': isCanClaimReward}">
                                    <span>ถอนได้</span>
                                    <strong>฿{{target_return | numberFormat}}</strong>
                                </div> -->
                            </div>
                            <div class="rowContentBottomInfo" :class="{'RCBM-NoLimit': !isHaveTargetReturn}">
                                <div class="rowContentBottom">
                                    <span>ถอนได้</span>
                                    <strong v-if="isHaveTargetReturn">฿{{target_return | numberFormat}}</strong>
                                    <strong v-else>ไม่จำกัดยอด</strong>
                                </div>
                                <!--                    <div class="text">-->
                                <!--                        <h6>หมายเหตุ</h6>-->
                                <!--                        <p>อยู่ระหว่างกิจกรรมจะไม่สามารถทำธุรกรรมทางการเงินได้</p>-->
                                <!--                    </div>-->
                                <div class="rowContentBtn">
<!--                                    <span v-if="!isCanClaimReward" class="btnCouponCancel" @click="toCancelPromotionTime">ยกเลิก</span>-->
                                    <span v-if="isCanClaimReward" @click="toClaimManual" class="btnCouponClaim">รับเงิน [ {{target_return_calculate | numberFormat}}บาท ]</span>
                                    <span v-else @click="toPlay" class="btnCouponPlay">เล่นเลย</span>
                                </div>
                            </div>
                            <div class="rowContentBottomInfo RCBM-NoLimit" v-if="false">
                                <div class="rowContentBottom">
                                    <span>ถอนได้</span>
                                    <strong>ไม่จำกัดยอด</strong>
                                </div>
                                <div class="rowContentBtn">
                                    <span class="btnCouponClaim">รับเงิน 20 บาท</span>
                                </div>
                            </div>
                        </div>
                    </div>


                </div>
            </div>
            <div v-if="modalMode === 'reward-notification'" class="boxFloatCoupon alertFloatCoupon">
                <div class="title">
<!--                    <h2>แจ้งรับรางวัล</h2>-->
<!--                        <p>คุณได้รับเงิน</p>-->
                </div>
                <div class="content">
                    <div class="rowTop">
                        <!-- <h6>เครดิต</h6> -->
                        <h6>{{rewardToClaim.label}}</h6>
                        <img v-if="parseInt(rewardToClaim.reward_type) === REWARD_TYPE_KEY.CREDIT" src="@/assets/images/coin-big-1.png"/>
                        <img v-else-if="parseInt(rewardToClaim.reward_type) === REWARD_TYPE_KEY.UNBOX" class="itemCase" src="@/assets/images/case-test.png"/>
                        <strong>฿{{rewardToClaim.reward_value | numberFormat}}</strong>
                        <div class="spinWinCoupon"></div>
                    </div>
                    <div class="text"  style="margin: 10px">
                        <p v-if="rewardToClaim.target_balance">- ทำยอด {{rewardToClaim.target_balance | numberFormat}}</p>
                        <p v-if="rewardToClaim.target_turnover">- ทำเทิร์นได้ {{rewardToClaim.target_turnover | numberFormat}}</p>
                        <p v-if="rewardToClaim.target_return">- ถอนได้ {{rewardToClaim.target_return | numberFormat}}</p>
                    </div>
                    <div class="rowContentBtn">
                        <span @click="rewardNotificationOk" class="btnCouponClaim">ตกลง</span>
                    </div>
                </div>
            </div>
            <!--            modal แก้ไข-->
            <div v-if="false" class="boxFloatCoupon">
                <div class="timeCoupon">
                    <small>กิจกรรมนี้จะสิ้นสุดใน</small>
                     <span>02:00:03</span>
<!--                    <Alarm :start="alarmStart" @timeout="timeOutHandle"></Alarm>-->
                </div>
                <div class="title">
                    <h2>สถานะกิจกรรมโปรโมชั่น</h2>
                    <!--                        <div class="timeCoupon">กิจกรรมนี้จะสิ้นสุดใน : <span>02:00:03</span></div>-->

                </div>
                <div class="content">
                    <div class="rowTop">
                        <span>ยอดเงินทั้งหมด</span>
                        <div class="rowTopInfo">
                            <strong>฿100</strong>
                        </div>
                    </div>
                    <div class="rowContent">
                        <div class="rowLeft">
                            <span>ยอดปัจจุบัน</span>
                            <strong>20</strong>
                            <small>เป้าหมาย 1,000</small>
                        </div>
                        <div class="rowRight">
                            <span>เทิร์นปัจจุบัน</span>
                            <strong>10</strong>
                            <small>เป้าหมาย 1,000</small>
                        </div>
                    </div>
                    <div class="rowContentBottom">
                            <span>ถอนได้</span>
                            <strong>฿1000</strong>
                    </div>
                    <div class="text">
                        <h6>หมายเหตุ</h6>
                        <p>อยู่ระหว่างกิจกรรมจะไม่สามารถทำธุรกรรมทางการเงินได้</p>
                    </div>
                    <div class="rowContentBtn">
                        <span class="btnCouponCancel">ยกเลิก</span>
<!--                        <span v-if="isCanClaimReward" @click="toClaimManual" class="btnCouponClaim">รับเงิน</span>-->
                    </div>
                </div>
            </div>

<!--            modal แก้ไข-->
            <div class="boxFloatCoupon alertFloatCoupon" v-if="false">
                <div class="title">
<!--                    <h2>แจ้งรับรางวัล</h2>-->
                    <!--                        <p>คุณได้รับเงิน</p>-->
                </div>
                <div class="content">
                    <div class="rowTop">
                        <!-- <h6>เครดิต</h6> -->
                        <h6>ได้รับเครดิต</h6>
                        <img src="@/assets/images/coin-big-1.png"/>
<!--                        <img v-else-if="parseInt(rewardToClaim.reward_type) === REWARD_TYPE_KEY.UNBOX" class="itemCase" src="@/assets/images/case-test.png"/>-->
                        <strong>฿50</strong>
                        <div class="spinWinCoupon"></div>
                    </div>
                    <div class="text">
                        <p>- ทำยอด 300</p>
                        <p>- ทำเทิร์นได้ 500</p>
                        <p>- ถอนได้ 150</p>
                    </div>
                    <div class="rowContentBtn">
                        <span class="btnCouponClaim">ตกลง</span>
                    </div>
                </div>
            </div>

            <div v-if="modalMode === 'cancel-confirmation'" class="boxFloatCoupon alertFloatCoupon alertFloatCouponCancel">
                <div class="title">
                    <h2>แจ้งเตือน</h2>
                    <p>ยกเลิกกิจกรรมโปรโมชั่น</p>
                </div>
                <div class="content">
                    <div class="text">
                        <h6>คำเตือน</h6>
                        <p>- เครดิตจะเท่ากับ 0</p>
                        <p>- เติม-ถอนได้ปกติ</p>
                        <p>- รับ โบนัส กิจกรรมได้ตามปกติ</p>
                    </div>
                    <div class="text" style="background-color: rgba(0,0,0,0.61); border-radius: 10px; padding: 10px; margin-top: 10px;">
                        <h6>กรณีกดปุ่ม"ตกลง"</h6>
                        <p style="font: 600 14px/20px Athiti;">ลูกค้าจะไม่สามารถใส่โค้ดเดิมได้อีก  ระบบจะถือว่าลูกค้ากดรับโปรโมชั่นนี้ไปเรียบร้อยแล้ว</p>
                    </div>
                    <div class="rowContentBtn">
                        <span @click="cancelCancelPromotionTime" class="btnCouponCancel">ยกเลิก</span>
                        <span @click="confirmCancelPromotionTime" class="btnCouponClaim">ตกลง</span>
                    </div>
                </div>
            </div>

        </div>

        <b-modal ref="message-modal" hide-header centered
        ok-title="ตกลง" modal-class="editModal" ok-only button-size="lg">
            <span>{{message}}</span>
        </b-modal>
    </div>
</template>
<script>

import Alarm from './PromotionTimeAlarm.vue'
import axiosWrapper from '@/axiosWrapper'
import store from '@/store'
import UserMixin from '@/mixins/UserMixin'
import {REWARD_TYPE_KEY} from './Constant'

export default {
    name: 'PromotionTime',
    mixins: [
        UserMixin,
    ],
    components: {
        Alarm,
    },
    props: [
        'bus'
    ],
    data() {
        return {
            isShowModal: false,
            modalMode: '', //status, reward-notification, cancel-confirmation
            rewardToClaim: null,
            REWARD_TYPE_KEY: REWARD_TYPE_KEY,
            turnover: 0,
            isOnRefresh: false,
            message: '',
        }
    },
    computed: {
        isCanClaimReward() {
            return parseFloat(this.balanceCanWithdraw) >= parseFloat(this.target_balance) && parseFloat(this.turnover) >= parseFloat(this.target_turnover)
        },
        alarmStart() {
            // return (this.pvm) ? this.pvm.end_datetime : ''
            return (this.current_pv) ? this.current_pv.end_datetime : ''
        },
        pes_profile() {
            return store.state.pes_profile
        },
        token() {
            return (this.pes_profile) ? this.pes_profile.token : ''
        },
        current_pv_id() {
            return (this.pes_profile) ? this.pes_profile.current_pv_id : null
        },
        pv_info() {
            return (this.pes_profile) ? this.pes_profile.pv_info : null
        },
        pvm() {
            return (this.pv_info) ? this.pv_info.pvm : null
        },
        pv() {
            if(!this.pv_info) {
                return []
            }
            if(!this.pv_info.pv) {
                return []
            }
            return this.pv_info.pv
        },
        current_pv() {
            return this.pv.find((item) => {
                return parseInt(item.pid) === parseInt(this.current_pv_id)
            })
        },
        target_turnover() {
            return (this.pvm) ? this.pvm.target_turnover : 0
        },
        target_balance() {
            return (this.pvm) ? this.pvm.target_balance : 0
        },
        target_return() {
            return (this.pvm) ? this.pvm.target_return : 0
        },
        target_return_limit() {
            return (this.pvm) ? this.pvm.target_return_limit : 0
        },
        target_return_calculate() {
            if(this.target_return && parseFloat(this.target_return) > 0) {
                return this.target_return
            } else if(parseFloat(this.target_return_limit) > 0) {
                return Math.min(this.balanceCanWithdraw, this.target_return_limit)
            } else {
                return this.balanceCanWithdraw
            }
        },
        isHaveTargetReturn() {
            return this.target_return && parseFloat(this.target_return) > 0
        },
        targetMode() {
            if(this.target_turnover) {
                return 'turnover'
            } else if(this.target_balance) {
                return 'balance'
            } else {
                return ''
            }
        },
        target() {
            if(this.targetMode === 'turnover') {
                return this.target_turnover
            } else if(this.targetMode === 'balance') {
                return this.target_balance
            } else {
                return 0
            }
        },
        reward_id() {
            return (this.pvm) ? this.pvm.reward_id : ''
        },
        turnoverLabel() {
            if(this.targetMode === 'turnover') {
                return 'เทิร์นที่ทำได้'
            } else if(this.targetMode === 'balance') {
                return 'ยอดเงินที่ทำได้'
            } else {
                return ''
            }
        },
        targetLabel() {
            if(this.targetMode === 'turnover') {
                return 'เทิร์นที่ต้องทำ'
            } else if(this.targetMode === 'balance') {
                return 'ยอดที่ต้องทำ'
            } else {
                return ''
            }
        },
        balance() {
            return this.pes_profile['THB_free']
        },
        balances() {
            return this.pes_profile.balances
        },
        thbCredit() {
            if(!this.balances) {
                return 0
            }
            if(!this.balances[0]) {
                return 0
            }
            return this.balances[0]['THB_credit']
        },
        balanceCanWithdraw() {
            return parseFloat(this.balance) - parseFloat(this.thbCredit)
        },
        currentGain() {
            if(this.targetMode === 'turnover') {
                return this.turnover
            } else if(this.targetMode === 'balance') {
                return this.balanceCanWithdraw
            } else {
                return 0
            }
        },
        balanceOfTargetPercent() {
            try {
                if(!this.balanceCanWithdraw) {
                    return 0
                }
                if(!this.target_balance) {
                    return 0
                }
                return Math.min((this.balanceCanWithdraw / this.target_balance) * 100, 100)
            } catch(err) {
                console.log('balanceOfTargetPercent err', err.message)
                return 0
            }  
        },
        turnoverOfTargetTurnoverPercent() {
            try {
                if(!this.turnover) {
                    return 0
                }
                if(!this.target_turnover) {
                    return 0
                }
                return Math.min((this.turnover / this.target_turnover) * 100, 100)
            } catch(err) {
                console.log('turnoverOfTargetTurnoverPercent err', err.message)
                return 0
            }  
        },
    },
    watch: {
        current_pv_id(newVal) {
            if(newVal) {
                this.showStatusModal()
            } else {
                this.closeModal()
            }
        },
        balance(newVal) {
            if(parseFloat(newVal) < 1) {
                this.closeModal()
            }
        },
    },
    methods: {
        openModal(mode) {
            let delay = 0
            if(this.isShowModal) {
                this.isShowModal = false
                delay = 1
            }
            setTimeout(() => {
                this.modalMode = mode
                this.isShowModal = true
            }, delay)
        },
        closeModal() {
            this.isShowModal = false
            setTimeout(() => {
                this.modalMode = ''
            }, 400)
        },
        toClaimManual() {
            const reward = {
                label: 'คุณได้รับเงิน',
                reward_type: REWARD_TYPE_KEY.CREDIT,
                reward_value: this.target_return_calculate,
                callback: 'claim',
            }
            this.toClaimReward(reward)
        },
        toClaimReward(reward) {
            this.rewardToClaim = reward
            this.showRewardNotificationModal()
        },
        async rewardNotificationOk() {
            if(this.rewardToClaim.callback === 'showStatusModal') {
                this.showStatusModal()
            } else if(this.rewardToClaim.callback === 'goUnboxPlay') {
                this.$router.push({name: 'UnboxPlay', params: {id: this.reward_id}})
            } else if(this.rewardToClaim.callback === 'claim') {
                const isClaimSuccess = await this.claim()
                if(!isClaimSuccess) {
                    return
                }
                this.closeModal()
            }
        },
        showRewardNotificationModal() {
            this.openModal('reward-notification')
        },
        async showStatusModal() {

            if(parseFloat(this.balance) < 1) {
                console.log('showStatusModal error balance < 1')
                return
            }

            const expire = this.$moment(this.alarmStart)
            const now = this.$moment()

            if(now.isAfter(expire)) {
                console.log('showStatusModal error promotion is expired')
                return
            }

            this.openModal('status')

            this.isOnRefresh = true
            this.loadTurnover()
            this.isOnRefresh = false
        },
        showCancelConfirmationModal() {
            this.openModal('cancel-confirmation')
        },
        toCancelPromotionTime() {
            this.showCancelConfirmationModal()
        },
        cancelCancelPromotionTime() {
            this.showStatusModal()
        },
        async confirmCancelPromotionTime() {
            const isClaimSuccess = await this.claim()
            if(!isClaimSuccess) {
                return
            }
            this.closeModal()
        },
        async claim() {

            const loading = this.$loading()

            const res = await axiosWrapper({
                method: 'post',
                url: `${this.envDomains.VUE_APP_API}/api/voucher/claim`,
                data: {
                    token: this.token,
                },
            })

            const loadUserProcess = await this.loadUserData(this.token, 'yes')

            loading.close()

            if(res.status !== 200) {
                this.message = res.data.message
                this.$refs['message-modal'].show()
                return false
            }

            if(!loadUserProcess.success) {
                this.message = `ไม่สามารถโหลดโปรไฟล์ผู้ใช้ กรุณาลองใหม่อีกครั้ง CODE[${loadUserProcess.message}]`
                this.$refs['message-modal'].show()
                return false
            }
            
            return true
        },
        async loadTurnover() {

            if(!this.current_pv_id) {
                return
            }

            const res = await axiosWrapper({
                method: 'post',
                url: `${this.envDomains.VUE_APP_API}/api/voucher/turnover`,
                data: {
                    token: this.token,
                },
            })

            if(res.status !== 200) {
                this.$bvToast.toast(`ไม่สามารถโหลดยอดเทิร์น ${res.data.message}`, {
                    title: 'แจ้งเตือน',
                    toaster: 'b-toaster-top-full'
                })
                return
            }

            this.turnover = res.data.data.turnover
        },
        async loadBalance() {
            const loadUserProcess = await this.loadUserData(this.token, 'yes')
            if(!loadUserProcess.success) {
                this.message = `ไม่สามารถโหลดโปรไฟล์ผู้ใช้ กรุณาลองใหม่อีกครั้ง CODE[${loadUserProcess.message}]`
                this.$refs['message-modal'].show()
            }
        },
        async refresh() {
            if(this.isOnRefresh) {
                return
            }

            this.isOnRefresh = true

            await this.loadBalance()
            await this.loadTurnover()

            this.isOnRefresh = false
        },
        async timeOutHandle() {
            this.closeModal()
            const loading = this.$loading()
            await this.loadUserData(this.token, 'yes')
            loading.close()
        },
        toPlay() {
            this.$router.push({name: 'IndexPopular'})
        },
    },
    async mounted() {
        if(this.bus) {
            this.bus.$on('toClaimReward', this.toClaimReward)
        }
        await this.loadBalance()
        if(this.current_pv_id) {
            this.showStatusModal()
        }
    },
    beforeDestroy() {
        if(this.bus) {
            this.bus.$off('toClaimReward', this.toClaimReward)
        }
    },
}
</script>