import moment from 'moment-timezone'
moment.tz.setDefault('Asia/Bangkok')

export const set = (key, value, keepDays) => {

    try {
        const expire = moment().add(keepDays, 'days').format('YYYY-MM-DD HH:mm:ss')
        localStorage.setItem(key, JSON.stringify({
            value: value,
            expire: expire
        }))
    } catch(err) {
        console.log('localStorageCache.set err', err.message)
    }
}
export const get = (key) => {

    try {
        const rawStorageItem = localStorage.getItem(key)
        if(!rawStorageItem) {
            return null
        }
        const storageItem = JSON.parse(rawStorageItem)
        const now = moment()
        const expire = moment(storageItem.expire)
        const diff = moment.duration(expire.diff(now))
        if(diff.as('seconds') > 0) {
            return storageItem.value
        } else {
            return null
        }
    } catch(err) {
        console.log('localStorageCache.get err', err.message)
        return null
    }
}

export default {
    set,
    get,
}