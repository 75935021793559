<template>
    <div class="GameCard">
        <div class="flip-card">
            <div ref="cardInner" class="flip-card-inner">
                <div class="flip-card-back">
                    <img src="@/assets/images/pokercard/card-back.png"/>
                </div>
                <div class="flip-card-front" :class="{'reverse': flipAngle !== 'x'}">
                    <img :src="imagePath">
                </div>
            </div>
        </div>
    </div>
</template>
<script>

import {CARD_TYPE_IMAGE, CARD_NUMBER_IMAGE} from './Constant'

export default {
    name: 'GameCard',
    props: [
        'state', //back, front
        'type',
        'number',
        'flipAngle',
    ],
    computed: {
        imagePath() {
            return `${this.envDomains.VUE_APP_API}/images/pokercard/${CARD_TYPE_IMAGE[this.type]}${CARD_NUMBER_IMAGE[this.number]}.png`
        },
    },
    watch: {
        state(newVal) {
            if(newVal === 'front') {
                if(!this.number) {
                    return
                }
                if(this.flipAngle === 'x') {
                    this.$refs['cardInner'].style.transform = 'rotateX(180deg)'
                } else {
                    this.$refs['cardInner'].style.transform = 'rotateY(180deg)'
                }
            } else {
                if(this.flipAngle === 'x') {
                    this.$refs['cardInner'].style.transform = 'rotateX(360deg)'
                } else {
                    this.$refs['cardInner'].style.transform = 'rotateY(360deg)'
                }
            }
        },
    },
}
</script>
<style scoped>
.flip-card {
    perspective: 1000px;
}

.flip-card-inner {
    position: relative;
    transition: transform 0.5s;
    transform-style: preserve-3d;
    width: 32px;
    height: 42px;
}

.flip-card:hover .flip-card-inner {
    transform: rotateX(180deg);
}

.flip-card-front, .flip-card-back {
    position: absolute;
    -webkit-backface-visibility: hidden;
    backface-visibility: hidden;
}

.flip-card-front {
    transform: rotateY(180deg);
}
.flip-card-front.reverse {
    transform: rotateY(180deg) scaleX(-1);
}
</style>