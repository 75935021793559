<script>

import store from '@/store'

export default {
    name: 'GameTableListItemMixin',
    computed: {
        gameList() {
            return store.state.game.baccaratGameList
        },
        tableList() {
            return store.state.game.baccaratTableList
        },
        table() {
            return this.getTableByTableId(this.table_id)
        },
        tableId() {
            return (this.table) ? this.table.table_id : null
        },
        tableRefGbsId() {
            return (this.table) ? this.table.ref_gbs_id : null
        },
        tableStatus() {
            return (this.table) ? this.table.table_game_status : null
        },
        game() {
            return this.getGameByGBSId(this.tableId, this.tableRefGbsId)
        },
        secondStartRemain() {
            return (this.game) ? this.game.countdown_datetime : null
        },
    },
    methods: {
        getStatArrByTable(table_id, table_round_main) {
            return this.gameList.filter((game) => {
                return parseInt(game.table_id) === parseInt(table_id) &&
                    parseInt(game.round_main) === parseInt(table_round_main)
            })
        },
        getTableByTableId(table_id) {
            return this.tableList.find((table) => {
                return parseInt(table.table_id) === parseInt(table_id)
            })
        },
        getGameByGBSId(table_id, gbs_id) {
            return this.gameList.find((game) => {
                return parseInt(game.gbs_id) === parseInt(gbs_id) && 
                    parseInt(game.table_id) === parseInt(table_id)
            })
        },
    },
}
</script>