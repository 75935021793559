<template>
    <div class="DiceFace">
        <div v-show="number === 1" class="dice dice-one"></div>
        <div v-show="number === 2" class="dice dice-two"></div>
        <div v-show="number === 3" class="dice dice-three"></div>
        <div v-show="number === 4" class="dice dice-four"></div>
        <div v-show="number === 5" class="dice dice-five"></div>
        <div v-show="number === 6" class="dice dice-six"></div>
    </div>
</template>
<script>

export default {
    name: 'DiceFace',
    props: [
        'number',
    ],
}
</script>
<style scoped>
.DiceFace {
    position: relative;
    width: 30px;
    height: 30px;
}

.DiceFace .dice {
    width: 100%;
    height: 100%;
    background-size: cover;
    border: solid 1px black;
    border-radius: 2px;
}

.DiceFace .dice-one {
    background-image: url(../../../assets/images/dice/one.png);
}
.DiceFace .dice-two {
    background-image: url(../../../assets/images/dice/two.png);
}
.DiceFace .dice-three {
    background-image: url(../../../assets/images/dice/three.png);
}
.DiceFace .dice-four {
    background-image: url(../../../assets/images/dice/four.png);
}
.DiceFace .dice-five {
    background-image: url(../../../assets/images/dice/five.png);
}
.DiceFace .dice-six {
    background-image: url(../../../assets/images/dice/six.png);
}
</style>