<template>
    <div class="box-menu-game">
        <div @click="exit" class="box-menu-list box-menu-list-close menuIconTable">
            <i class="fas fa-times"></i>
        </div>
        <div @click="toggleTicketPanel"
                class="box-menu-list menuIconTable">
            <i class="fas fa-list"></i>
        </div>
        <div @click="showSideMenu"
                class="box-menu-list menuIconTable"
                :class="{'on': is_show_side_menu}"
                id="StContentTable">
            <i class="fas fa-th-large"></i>
        </div>
        <div @click="toggleStatePanel"
                class="box-menu-list menu-list menuIcon"
                :class="{'active': calActiveStatButton()}"
                id="StContentPlay">
            <i class="fas fa-chart-bar"></i>
        </div>
        <div @click="togglePlayPanel"
                class="box-menu-list menu-list"
                :class="{'active': orientation === 'portrait' || panel_to_show === 'play'}"
                id="StContentState">
            <span>เล่น</span>
        </div>
        <div @click="goDepositWithdraw" class="box-menu-list boxMLBDepositWithdraw">
            <span>เติม</span>
        </div>
    </div>
</template>
<script>
export default {
    name: 'GameVerticalTool',
    props: [
        'is_show_side_menu',
        'panel_to_show',
        'orientation',
        'isShowStatPanel',
    ],
    methods: {
        exit() {
            this.$emit('exit')
        },
        toggleTicketPanel() {
            this.$emit('toggleTicketPanel')
        },
        showSideMenu() {
            this.$emit('showSideMenu')
        },
        toggleStatePanel() {
            this.$emit('toggleStatePanel')
        },
        togglePlayPanel() {
            this.$emit('togglePlayPanel')
        },
        goDepositWithdraw() {
            this.$emit('goDepositWithdraw')
        },
        calActiveStatButton() {
            if(this.orientation === 'portrait') {
                return this.isShowStatPanel
            } else {
                return this.panel_to_show === 'state'
            }
        },
    },
}
</script>