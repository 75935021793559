<template>
    <div class="bg-overlay bg-sky">

        <div class="wrapper-box-games">
            <div class="SlotsOuterContainer">
                <div class="btn-exit" @click="exit"> </div>
                <div class="slotMachineContainer">
                    <div v-if="is_on_result" class="slot-result tracking-in-expand">{{result_reward | numberFormat}}</div>
                    <div class="ReelContainer">
                        <div class="reel" id="reel1" style="left:10px;"></div>
                        <div class="reel" id="reel2" style="left: 85px;"></div>
                        <div class="reel" id="reel3"  style="left: 160px;"></div>
                        <div class="reelOverlay"></div>
                    </div>
                    <CoinGush 
                    @click="is_show_coin_gush = false"
                    @done="is_show_coin_gush = false"
                    v-if="is_show_coin_gush" 
                    :coin_count="150"
                    :coin_duration="3"/>
                </div>
            </div>
            <div class="SlotSpinnerContainer">
                <div class="SpinProgressBar">
                    <div class="box-txt-progress">40/50</div>
                    <div class="progress">
                        <div class="progress-bar progress-bar-striped bg-pink" role="progressbar"
                             style="width: 75%;" aria-valuenow="55" aria-valuemin="0" aria-valuemax="100">
                        </div>
                    </div>
                </div>
            </div>
            <div @click="spin" class="SlotBtnSpin">
                <div class="btn-spin"></div>
            </div>
        </div>

    </div>
</template>
<script>

import CoinGush from '@/components/CoinGush'

export default {
    name: 'MiniSlot',
    components: {
        CoinGush
    },
    data(){
        return {
            stripHeight: 346,
            alignmentOffset: 48, // (container height - stripHeight/numIconsPerReel) / 2
            firstReelStopTime: 667,
            secondReelStopTime: 575,
            thirdReelStopTime: 568,
            payoutStopTime: 700,
            reelSpeedDifference: 0,
            reelSpeed1Delta: 100,
            reelSpeed1Time: 0,
            reelSpeed2Delta: 100,
            positioningTime: 200,
            bounceHeight: 50,
            bounceTime: 1e3,
            numIconsPerReel: 6,
            resultDelay: 300,
            is_on_spin: false,
            reel_result_arr: [],
            reel_interval_map : {},
            is_on_result: false,
            result_reward: 0,
            is_show_coin_gush: false
        }
    },
    methods: {
        async spin(){
            if(this.is_on_spin){
                return
            }
            this.is_on_spin = true
            this.is_on_result = false
            this.is_show_coin_gush = false
            this.startReelSpin(1, 0)
            this.startReelSpin(2, this.secondReelStopTime)
            this.startReelSpin(3, this.secondReelStopTime + this.thirdReelStopTime)

            this.reel_result_arr = await this.loadResult()

            this.result_reward = this.calReward()

            let timeout = 80
            setTimeout(() => {
                this.stopReelSpin(1, this.reel_result_arr[0])
            }, timeout += this.firstReelStopTime)
            setTimeout(() => {
                this.stopReelSpin(2, this.reel_result_arr[1])
            }, timeout += this.secondReelStopTime)
            setTimeout(() => {
                this.stopReelSpin(3, this.reel_result_arr[2])
                this.is_on_spin = false
            }, timeout += this.thirdReelStopTime)
            setTimeout(() => {
                this.is_on_result = true
                this.is_show_coin_gush = true
            }, timeout += this.resultDelay)
        },
        async loadResult(){
            return new Promise((resolve) => {
                setTimeout(() => {
                    resolve([
                        Math.floor((Math.random() * this.numIconsPerReel) + 1),
                        Math.floor((Math.random() * this.numIconsPerReel) + 1),
                        Math.floor((Math.random() * this.numIconsPerReel) + 1)
                    ])
                }, 400)
            })
        },
        calReward(){
            let result_str = ''
            for(const o of this.reel_result_arr){
                result_str += o+""
            }
            return parseInt(result_str)*10000
        },
        startReelSpin(index, stop_time){

            const _this = this
            const reel = document.querySelector('#reel'+index)

            const now_timestamp = Date.now()
            const init_top = -(Math.random() * _this.stripHeight * 2)
            reel.style.top = init_top+"px"
            let second_top = parseInt(init_top, 10)
            this.reel_interval_map[index] = setInterval(() => {
                reel.style.top = second_top+"px"
                second_top += Date.now() < now_timestamp + _this.reelSpeed1Time + stop_time ? _this.reelSpeed1Delta : _this.reelSpeed2Delta
                second_top += index * _this.reelSpeedDifference, second_top > 0 && (second_top = 2 * -_this.stripHeight)
            }, 20)
        },
        stopReelSpin(index, result){

            const _this = this
            const reel = document.querySelector('#reel'+index)

            clearInterval(this.reel_interval_map[index])
            this.reel_interval_map[index] = null

            const item_height = _this.stripHeight / _this.numIconsPerReel
            const result_top = - _this.stripHeight - (result - 1) * item_height + _this.alignmentOffset

            reel.style.top = `${result_top - _this.stripHeight}px`
            reel.animate([
                {top: `${result_top + this.bounceHeight}px`}
            ],{
                duration: this.positioningTime,
                easing: 'linear'
            })
            setTimeout(() => {
                reel.animate([
                    {top: `${result_top + this.bounceHeight}px`},
                    {top: `${result_top}px`}
                ],{
                    duration: this.bounceTime,
                    easing: 'cubic-bezier(.2,2.06,0,.15)'
                })
                reel.style.top = `${result_top}px`
            }, this.positioningTime)
        },
        exit(){
            this.$emit('exit')
        }
    }
}
</script>
<style src="@/assets/css/slot.css" scoped></style>
<style scoped>

    .btn-exit {
        position: absolute;
        width: 42px;
        height: 42px;
        display: block;
        right: -10px;
        top: -18px;
        box-shadow: none !important;
        border-radius: 50%;
        background-image: url(../assets/images/spin/btn-close.png);
        background-size: cover;
    }

    .slot-result{
        position: absolute;
        width: 100%;
        top: 78px;
        padding: 10px;
        font-weight: bold;
        text-align: center;
        color: #fff;
        text-shadow: 0px 2px #000;
        font-size: 1.6em;
    }

    .tracking-in-expand {
        -webkit-animation: tracking-in-expand 0.7s cubic-bezier(0.215, 0.610, 0.355, 1.000) both;
        animation: tracking-in-expand 0.7s cubic-bezier(0.215, 0.610, 0.355, 1.000) both;
    }
    @-webkit-keyframes tracking-in-expand {
        0% {
            letter-spacing: -0.5em;
            opacity: 0;
        }
        40% {
            opacity: 0.6;
        }
        100% {
            opacity: 1;
        }
    }
    @keyframes tracking-in-expand {
        0% {
            letter-spacing: -0.5em;
            opacity: 0;
        }
        40% {
            opacity: 0.6;
        }
        100% {
            opacity: 1;
        }
    }

</style>