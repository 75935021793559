<template>
    <div class="ImageBackground" 
    :class="(customClass) ? customClass : ''" 
    ref="imageBackground">
    </div>
</template>
<script>
export default {
    name: 'ImageBackground',
    props: ['url', 'customClass'],
    watch: {
        url(newValue){
            this.setImage(newValue)
        }
    },
    methods: {
        setImage(url){
            if(!url){
                this.$refs.imageBackground.style['background-image'] = `url(${this.envDomains.VUE_APP_API}/images/default-background.jpg)`
                return
            }
            this.$refs.imageBackground.style['background-image'] = `url(${url})`
        }
    },
    mounted(){
        this.setImage(this.url)
    }
}
</script>
<style scoped>
.ImageBackground {
    background-image: url(../assets/images/default-background.jpg)
}
</style>