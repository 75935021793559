<template>
    <div class="QuickBet QuickBet-popup" :class="{'keyboard-padding': is_keyboard_open}">
        <div v-if="is_load" class="v-blank-wrapper">
            <span class="loading-box" style="font-size: 22px;">
                <p> Loading...</p>

            </span>
        </div>
        <div v-else-if="price && match_found">
            <div class="qb-header-header">
                <h4>เดิมพันเดี่ยว</h4>
                <div style="flex: 1; text-align: right; padding: 2px;">
                    <span v-if="is_member">
                        ยอดเงิน {{user.THB_free | toFixed(2) | comma(2) }}
                        <!-- <span style="font-size: 10px;">โบนัส {{user.balances[0]['THB_credit'] | toFixed(2) | comma(2) }}</span> -->
                    </span>
                </div>
            </div>
            <div class="qb-header" :class="{'live': price.odd_info.is_live}">
                <div class="qb-team-home" :class="{'underline': price.odd_info.favorite_team === 'H'}">
                    <span>{{price.odd_info.match_home_name}}</span>
                </div>
                <div class="qb-vs">
                    VS
                </div>
                <div class="qb-team-away" :class="{'underline': price.odd_info.favorite_team === 'A'}">
                    <span>{{price.odd_info.match_away_name}}</span>
                </div>
            </div>
            <div class="qb-body" :class="{'live': price.odd_info.is_live}">
                <div class="qb-mode-bet">
                    <div @click="select_mode('HDP')" class="qb-mode-bet-button">
                        <span :class="{'selected': mode === 'HDP'}" class="tag qb-mode-bet-button">ต่อรอง HDP</span>
                    </div>
                    <div @click="select_mode('OU')" class="qb-mode-bet-button">
                        <span :class="{'selected': mode === 'OU'}" class="tag qb-mode-bet-button">สูง-ต่ำ OU</span>
                    </div>
                </div>
                <div v-if="mode === 'HDP'">
                    <div v-for="(odd, i) in price.hdp_list" :key="odd.odds_id" class="qb-price-wrapper">
                        <QuickBetOddPrice
                                :i="i"
                                :is_live="price.odd_info.is_live"
                                :odd_info="price.odd_info"
                                :odd_id="odd.odds_id"
                                odd_name="odds_n1"
                                :price="odd.odds_n1"
                                :odds_x3="odd.odds_x3"
                                mode="HDP"
                                mode_sub="FT"
                                :mode_value="odd.odds_hdp"
                                choose="H"
                                :my_odd="my_odd"
                                :query_team="query_team"
                                :option="option"/>
                        <div class="qb-mode-value">
                            {{odd.odds_hdp | changeHDP}}
                        </div>
                        <QuickBetOddPrice
                                :i="i"
                                :is_live="price.odd_info.is_live"
                                :odd_info="price.odd_info"
                                :odd_id="odd.odds_id"
                                odd_name="odds_n2"
                                :price="odd.odds_n2"
                                :odds_x3="odd.odds_x3"
                                mode="HDP"
                                mode_sub="FT"
                                :mode_value="odd.odds_hdp"
                                choose="A"
                                :my_odd="my_odd"
                                :query_team="query_team"
                                :option="option"/>
                    </div>
                </div>
                <div v-if="mode === 'OU'">
                    <div v-for="(odd, i) in price.ou_list" :key="odd.odds_id" class="qb-price-wrapper">
                        <QuickBetOddPrice
                                :i="i"
                                :is_live="price.odd_info.is_live"
                                :odd_info="price.odd_info"
                                :odd_id="odd.odds_id"
                                odd_name="odds_n1"
                                :price="odd.odds_n1"
                                :odds_x3="odd.odds_x3"
                                mode="OU"
                                mode_sub="FT"
                                :mode_value="odd.odds_hdp"
                                choose="Over"
                                :my_odd="my_odd"
                                :query_team="query_team"
                                :option="option"/>
                        <div class="qb-mode-value">
                            {{odd.odds_hdp | changeHDP}}
                        </div>
                        <QuickBetOddPrice
                                :i="i"
                                :is_live="price.odd_info.is_live"
                                :odd_info="price.odd_info"
                                :odd_id="odd.odds_id"
                                odd_name="odds_n2"
                                :price="odd.odds_n2"
                                :odds_x3="odd.odds_x3"
                                mode="OU"
                                mode_sub="FT"
                                :mode_value="odd.odds_hdp"
                                choose="Under"
                                :my_odd="my_odd"
                                :query_team="query_team"
                                :option="option"/>
                    </div>
                </div>
                <div class="qb-body-middle">
                    <div v-if="bet_single" class="bet-preview">
                        <div class="flex item-center">
                            <span>{{bet_single.mode | calModeName}}:</span>
                            <span>{{displayModeValue(bet_single.mode, bet_single.mode_value, bet_single.favorite, bet_single.choose) | toFixed(2)}}</span>
                        </div>
                        <div class="flex item-center">
                            <span class="color-blue" :class="{'color-red': bet_single.favorite === bet_single.choose}">
                                {{bet_single.choose | calChooseName(bet_single.match_home_name, bet_single.match_away_name, bet_single.mode)}}
                            </span>
                            @<BetPanelOddPrice :myOdd="bet_single.odds" :price="bet_single.odds_changed"/>
                        </div>
                    </div>
                    <div class="box-odd-input">
                        <div class="box-left">
                            <input v-model="accept_any_odd" type="checkbox" id="acceptAnyOdd">
                            <label for="acceptAnyOdd">Accept <strong>any</strong> odds</label>
                            <ul>
                                <li>Bet {{min_bet}}</li>
                                <li><span class="nbsp"></span></li>
                                <li>-</li>
                                <li><span class="nbsp"></span></li>
                                <li>{{max_bet | comma(2)}}</li>
                            </ul>
                        </div>
                        <div class="box-right">
                            <label>
                                <input @focus="add_keyboard_margin" @blur="remove_keyboard_margin" class="input is-small" v-model="amount" ref="amount_input" type="number" placeholder="จำนวนเงิน" style="width: 100%;">
                            </label>
                        </div>
                    </div>
                    <div class="flex" style="margin-top: 4px; justify-content: space-between; padding: 0 4px;">
                        <div>เงินเดิมพัน</div>
                        <div>{{amount | toFixed(2) | comma(2)}}</div>
                    </div>
                    <div class="flex" style="margin-top: 4px; justify-content: space-between; padding: 0 4px 6px 4px;">
                        <div>การชนะ</div>
                        <div class="color-blue">{{win_amount | toFixed(2) | comma(2)}}</div>
                    </div>
                </div>
            </div>
            <div class="qb-footer">
                <button v-if="!is_embed" @click="cancelAll" class="button is-small is-outlined">ยกเลิก</button>
                <button @click="goBet" class="button is-small is-link">วางเดิมพัน</button>
            </div>
        </div>
        <div v-else class="v-blank-wrapper" style="display: flex; justify-content: center; align-item: center;">
            <div class="text-center">
                <h3>Match not found.</h3>
                <button v-if="!is_embed" @click="exit" class="btn bg-gradient-btn">Close</button>
            </div>
        </div>
        <PleaseWaitModal @cancel="cancelWaitBet" />
        <BetMessageModal @okay="closeBetMessage" @opened="modal_opened" @closed="modal_closed" />
        <modal name="toast-modal" :height="'auto'" width="98%" :pivotY="0.2" :scrollable="true">
            <div class="v-modal-content-wrapper flex-column">
                <div class="v-modal-body flex item-center">
                    <div>{{toastMessage}}</div>
                </div>
                <div class="v-modal-footer flex">
                    <el-button @click="$modal.hide('toast-modal')">OK</el-button>
                </div>
            </div>
        </modal>
    </div>
</template>

<script>

import store from '@/store'
import BigNumber from 'bignumber.js/bignumber'
import _find from 'lodash/find'
import _isEmpty from 'lodash/isEmpty'
import querystring from 'query-string'
import {mixin as VueTimers} from 'vue-timers'
import debounce from '@/debounce'
import QuickBetOddPrice from "./QuickBetOddPrice";
import BetPanelOddPrice from "./BetPanelOddPrice";
import axiosWrapper from '@/axiosWrapper'
import PleaseWaitModal from "./modals/PleaseWaitModal";
import BetMessageModal from "./modals/BetMessageModal";
import UserMixin from '@/mixins/UserMixin'

export default {
    name: "QuickBet",
    components: {
        BetPanelOddPrice,
        QuickBetOddPrice,
        PleaseWaitModal,
        BetMessageModal,
    },
    mixins: [
        VueTimers,
        UserMixin,
    ],
    timers: {
        startBet: {
            time: 2000
        },
        get_price_by_match_id: {
            time: 10000,
            autostart: true,
            repeat: true
        }
    },
    props: [
        'match_id',
        'query_amount',
        'query_team',
        'data_option',
    ],
    data() {
        return {
            mode: 'HDP',
            amount: 20,
            betMode: 'single',
            bet_message_status: '',
            bet_message: '',
            bet_messages: [],
            bet_success_data: null,
            bet_timeout: 30,
            price: false,
            is_keyboard_open: false,
            is_load: false,
            match_found: false,
            toastMessage: '',
        }
    },
    computed: {
        is_member: function() {
            return store.state.is_member
        },
        user: function(){
            return store.state.pes_profile;
        },
        odd_style: function () {
            return store.state.football.odd_style;
        },
        sport_selected: function () {
            return store.state.football.sport_selected;
        },
        bet_list_count: function () {
            return store.state.football.bet_list.length;
        },
        bet_single: function () {
            return store.state.football.bet_list[store.state.football.bet_list.length - 1];
        },
        bet_list: function () {
            return store.state.football.bet_list;
        },
        accept_any_odd: {
            get: function () {
                return store.state.football.accept_any_odd;
            },
            set: function (newVal) {
                store.commit('football/updateAcceptAnyOdd', newVal);
            }
        },
        is_live: function () {
            let found = _find(this.bet_list, function (o) {
                return o.is_live === 1;
            });
            if (found) {
                return 1;
            } else {
                return 0;
            }
        },
        min_bet: function () {
            return 20;
        },
        max_bet: function () {

            let max_bet = 9999;

            if (this.bet_list.length > 0) {
                if (this.betMode === 'single') {
                    max_bet = this.getMaxBet(this.bet_list[0]);
                }
            } else {
                max_bet = 9999;
            }

            return max_bet;
        },
        win_amount: function () {

            let win_amount = 0;

            if (this.betMode === 'single') {
                win_amount = this.calReturn(this.bet_list[0]);
            }

            return win_amount;
        },
        my_odd: function(){
            try{
                return store.state.football.bet_list[store.state.football.bet_list.length - 1].odds;
            }catch(err){
                return false;
            }
        },
        query_display_mode(){
            return this.$route.query.display_mode
        },
        is_embed(){
            return this.query_display_mode === 'embed'
        },
        option() {
            if(this.data_option) {
                return this.data_option
            } else {
                let priceLength = 0
                if(this.mode === 'hdp') {
                    priceLength = (this.price.hdp_list) ? this.price.hdp_list.length : 0
                } else {
                    priceLength = (this.price.ou_list) ? this.price.ou_list.length : 0
                }
                const i = Math.max(parseInt(Math.ceil(priceLength / 2)) - 1, 0)
                return {
                    i: i,
                    oddName: 'odds_n1'
                }
            }
        },
    },
    watch: {
        amount: debounce(function (newVal) {

            newVal = parseFloat(newVal);
            this.amount = newVal;

            if (newVal < this.min_bet) {
                this.amount = this.min_bet;
            } else if (newVal > this.max_bet) {
                this.amount = this.max_bet;
            } else if (isNaN(newVal)) {
                this.amount = this.min_bet;
            }
        }, 1400),
        min_bet(newVal){
            if (newVal > this.amount) {
                this.amount = newVal;
            }
        },
        max_bet(newVal){
            if (newVal < this.amount) {
                this.amount = newVal;
            }
        },
        is_keyboard_open: debounce(function(new_val){
            if(new_val){
                this.$el.scrollTo(0, this.$el.scrollHeight);
            }
        }, 400)
    },
    methods: {
        select_mode: function(mode){
            this.mode = mode;
        },
        cancelAll: async function () {
            await store.dispatch('football/cancelBetAll');
            this.$emit('exit')
        },
        exit(){
            this.$emit('exit')
        },
        getMaxBet: function (bet) {

            let totalX3 = bet.odds_x3;

            if (totalX3 === 300) { // 22.06
                return 6620;
            } else if (totalX3 === 500) { // 22.6
                return 11300;
            } else if (totalX3 === 1000) { // 22.599
                return 22599;
            } else if (totalX3 === 1500) { // 22.37
                return 33563;
            } else if (totalX3 === 2000) { // 22.599
                return 45198;
            } else if (totalX3 === 3000) { // 22.599
                return 67797;
            } else {
                if (totalX3) {
                    return totalX3 * 22;
                } else {
                    return 50 * 22;
                }
            }
        },
        calReturn: function (bet) {

            if(bet){
                let amount = new BigNumber(this.amount);
                let odds = new BigNumber(this.$options.filters.priceByCurrency(bet.odds));
                let two = new BigNumber(2);

                let p_return = 0;

                if (bet.mode === '1X2') {
                    p_return = amount.multipliedBy(odds);
                } else {
                    if (this.odd_style === 'MY') {
                        if (odds > 0) {
                            p_return = amount.multipliedBy(odds).plus(amount);
                        } else if (odds < 0) {
                            p_return = amount.multipliedBy(two);
                        }
                    } else if (this.odd_style === 'HK') {
                        p_return = amount.multipliedBy(odds).plus(amount);
                    } else if (this.odd_style === 'EU') {
                        p_return = amount.multipliedBy(odds);
                    }
                }

                p_return = p_return.toFixed(2);

                return p_return;
            }else{
                return 0;
            }
        },
        goBet: async function () {

            if(this.user){
                let max_payment = this.user.sports.football.max_payment || 100000;
                let max_match = this.user.sports.football.max_match || 25;

                if(this.bet_list_count === 0){
                    this.toastMessage = 'Please select bet'
                    this.$modal.show('toast-modal')
                } else if (parseFloat(this.win_amount) > parseFloat(max_payment)) {
                    this.bet_message_status = 'reach_max_payment'
                    this.bet_message = `Reach max payment, your max payment is ${this.$options.filters.comma(this.$options.filters.toFixed(max_payment))}`
                    this.$modal.show('bet-message', {
                        bet_message: this.bet_message,
                        bet_messages: this.bet_messages
                    });
                } else if (parseFloat(this.bet_list.length) > parseFloat(max_match)) {
                    this.bet_message_status = 'reach_max_match'
                    this.bet_message = `Reach max combo, your max combo is ${max_match}`
                    this.$modal.show('bet-message', {
                    bet_message: this.bet_message,
                    bet_messages: this.bet_messages
                });
                } else {
                    this.$modal.show('please-wait');
                    this.$timer.stop('startBet');
                    this.$timer.start('startBet');
                }
            }else{
                this.notifyToSignUp('เพื่อเล่น')
            }
        },
        startBet: async function () {

            let payload = await this.generatePayload();
            const res = await axiosWrapper({
                method: 'post',
                url: `${this.envDomains.VUE_APP_123API}/proxy/ticket`,
                data: querystring.stringify(payload)
            });
            if (res.status === 400) {

                this.bet_message_status = 'bet_code_400';

                if (res.data.status === 'invalid_bet') {

                    for (let item of res.data.data.items) {

                        if (item.validate.desc) {
                            this.bet_message = item.validate.desc;
                        }

                        if (item.validate.status === 'odd_change') {
                            let changedOdd = await store.dispatch('football/toUpdateBet', {
                                match_id: item.match_id,
                                odd_info: item.validate.data
                            });
                            let oldPrice = this.$options.filters.toFixed(this.$options.filters.priceByCurrency(changedOdd.oldVal), 3);
                            let newPrice = this.$options.filters.toFixed(this.$options.filters.priceByCurrency(changedOdd.newVal), 3);
                            this.bet_messages.push(changedOdd.bet.match_home_name + '-vs-' + changedOdd.bet.match_away_name + ' ' + oldPrice + ' to ' + newPrice);
                        }
                    }
                } else {
                    this.bet_message = res.data.desc;
                }
            } else if (res.error) {

                this.bet_message_status = 'bet_api_error';

                // alert(res.desc);
                this.bet_message = res.desc;
            } else {
                const data = res.data;
                if (data.code === -3 || data.code === -2 || data.code === -1) {//other error
                    // alert(data.msg);
                    if (data.msg === 'Failed to authenticate token.') {
                        this.bet_message = data.msg + ' Please sign in again.';
                    } else {
                        this.bet_message = data.msg;
                    }
                } else if (data.code === 0) {//bet error

                    this.bet_message_status = 'bet_error';

                    if (!_isEmpty(data.msg)) {

                        // alert(data.msg);//odd change
                        this.bet_message = data.msg;

                        for (let d in data.data) {
                            if (data.data[d].code === 0) {
                                let changedOdd = await store.dispatch('football/toUpdateBet', {
                                    match_id: d,
                                    odd_info: data.data[d].data.odds_change
                                });
                                console.log('changedOdd', changedOdd);
                                let oldPrice = this.$options.filters.toFixed(this.$options.filters.priceByCurrency(changedOdd.oldVal), 3);
                                let newPrice = this.$options.filters.toFixed(this.$options.filters.priceByCurrency(changedOdd.newVal), 3);
                                this.bet_messages.push(changedOdd.bet.match_home_name + '-vs-' + changedOdd.bet.match_away_name + ' ' + oldPrice + ' to ' + newPrice);
                            } else if (data.data[d].code === -1) {
                                console.log('missing odd');
                            } else if (data.data[d].code === -2) {
                                console.log('time out');
                            }
                        }
                    }
                } else if (data.code === 1) {//bet success

                    this.bet_message_status = 'bet_success';
                    this.bet_success_data = data.data;

                    // alert(data.msg);
                    this.bet_message = data.msg;
                    // store.commit('removeBetAll');

                    this.requestUserReload()
                }
            }
            this.$modal.hide('please-wait');
            if (this.bet_message) {
                this.$modal.show('bet-message', {
                    bet_message_status: this.bet_message_status,
                    bet_message: this.bet_message,
                    bet_messages: this.bet_messages,
                    bet_success_data: this.bet_success_data,
                    amount: this.amount,
                    win_amount: this.win_amount,
                });
            }
        },
        generatePayload: async function () {

            let bet_list = {};

            for (let i = 0; i < this.bet_list.length; i++) {

                let bet = Object.assign({}, this.bet_list[i]);
                bet.team_home_name = bet.match_home_name;
                bet.team_away_name = bet.match_away_name;
                bet_list[bet.match_id] = bet;
            }

            return {
                amount: this.amount,
                token: this.user.token,
                bet_list: JSON.stringify(bet_list),
                currency: 'THB',
                is_live: this.is_live,
                sport: this.sport_selected,
            };
        },
        closeBetMessage: async function () {

            if(this.bet_message_status === 'bet_success'){
                store.commit('football/removeBetAll');
                this.$emit('exit')
            }

            this.$modal.hide('bet-message');
            if (this.bet_message_status === 'reach_max_payment') {
                this.amount = this.min_bet;
            }
            this.bet_message_status = '';
            this.bet_message = '';
            this.bet_messages = [];
        },
        cancelWaitBet: function () {
            this.$timer.stop('startBet');
            this.$modal.hide('please-wait');
        },
        modal_opened: function(){
            store.commit('football/update_modal_is_open', true);
        },
        modal_closed: function(){
            store.commit('football/update_modal_is_open', false);
        },
        get_price_by_match_id: async function(){
            let price_res = await axiosWrapper({
                method: 'GET',
                url: `${this.envDomains.VUE_APP_LIFF_API}/api/price/get_by_match_id?match_id=${this.match_id}`
            });
            if(price_res.status === 400){
                return false;
            }else if(price_res.status === 200){

                this.price = price_res.data;

                if (this.bet_list_count > 0) {

                    let bet = this.bet_single;

                    let price_list_mode_key = {
                        'HDP': 'hdp_list',
                        'OU': 'ou_list'
                    };

                    let newOdd = _find(this.price[price_list_mode_key[this.mode]], function (o) {
                        return o.odds_id === bet.odd_id;
                    });

                    if(newOdd){
                        if (this.accept_any_odd) {
                            bet.odds = newOdd[bet.odd_name];
                        } else {
                            bet.odds_changed = newOdd[bet.odd_name];
                        }
                        store.commit('football/updateBetSamePos', {
                            i: 0,
                            bet: bet
                        })
                    }
                }
                return true;
            }else{
                this.toastMessage = 'Something wrong, please try again.'
                this.$modal.show('toast-modal')
                return false;
            }
        },
        add_keyboard_margin: function(){
            this.is_keyboard_open = true;
        },
        remove_keyboard_margin: function(){
            this.is_keyboard_open = false;
        },
        displayModeValue: function(mode, mode_value, favorite, choose){
            if(mode === 'HDP'){

                if(mode_value){

                    if(favorite === choose){
                        return Math.abs(mode_value)*-1;
                    }else{
                        return Math.abs(mode_value);
                    }
                }else{
                    return 0;
                }
            }else{
                return mode_value;
            }
        },
        requestUserReload() {
            store.commit('updateNeedUserReloadWithForceRefreshBalance', true)
        },
    },
    mounted: async function() {

        this.is_load = true

        this.match_found = await this.get_price_by_match_id();
        if(!this.match_found){
            this.$timer.stop('get_price_by_match_id');
        }

        this.is_load = false

        if(this.query_amount) {
            this.amount = this.query_amount
        }
    },
    beforeDestroy() {
        store.dispatch('football/clearBet')
    }
}
</script>

<style scoped>

    .QuickBet {
        border: solid 1px lightgray;
    }
    .qb-header {
        display: flex;
        justify-content: space-around;
        align-items: center;
        color: black;
        background-color: var(--bg-blue-dark);
        padding: 10px 4px;
        line-height: 1.2;
    }


    .qb-body {
        display: flex;
        flex-direction: column;
        background-color: #d8efff;
        color: black;
        padding: 10px 0;
    }
    .qb-body input{
        border-radius: 10px; border: 0;
        height: 35px;
        padding: 0 5px;
    }
    .qb-mode-bet {
        display: block;
        justify-content: center;
        margin: 0 auto;
        padding: 5px 0;
        border-radius: 15px;
        background: rgba(255, 255, 255, 0.61);
        /*background: rgba(120, 177, 219, 0.20);*/
    }
    .qb-mode-bet-button {
        margin: 0 4px;
        display: inline-block;
    }
    .qb-price-wrapper {
        display: flex;
        justify-content: center;
        align-items: center;
        width: 100%;
        margin: 4px;
        font-size: 14px;
    }
    .qb-mode-value {
        width: 50px;
        text-align: center;
    }
    .qb-price {
        margin: 4px 10px;
    }
    .qb-price span{
        padding: 0 20px;
        color: black;
        background-color: #e6f6fe;
    }
    .qb-price.selected span{
        padding: 0 20px;
        color: white;
        background-color: #347ab8;
    }
    .qb-footer {
        display: flex;
        justify-content: center;
        color: black;
        background-color: white;
        padding: 5px;
    }
    .qb-footer button {
        -webkit-appearance: none;
        align-items: center;
        border: 1px solid transparent;
        border-radius: 10px;
        box-shadow: none;
        display: inline-flex;
        height: 2.5em;
        justify-content: flex-start;
        line-height: 1.5;
        padding-bottom: calc(.5em - 1px);
        padding-left: calc(.75em - 1px);
        padding-right: calc(.75em - 1px);
        padding-top: calc(.5em - 1px);
        position: relative;
        vertical-align: top;
        margin: 5px;
    }


    .v-modal-content-wrapper {
        padding: 14px 18px;
        border: solid 2px black;
        overflow-y: auto;
        min-height: 180px;
    }

    .v-modal-body {
        word-break: break-all;
        flex: 1;
        overflow-y: auto;
    }

    .v-modal-footer {
        height: 24px;
        justify-content: flex-end;
        align-items: flex-end;
    }

    .v-blink-blink {
        animation: v-blinker 1s linear infinite;
    }


    @keyframes v-blinker {
        50% {
            opacity: 0;
        }
    }
    .keyboard-padding {
        height: auto;
        padding-bottom: 150px;
    }
    .underline span{
        border-bottom: solid 1px var(--white);
        /*text-decoration: underline;*/
    }
    .bet-preview {
        display: block;
        margin: 10px;
        background-color: rgb(255, 255, 255);
        border-radius: 10px;
        padding: 4px;
        font-size: 14px;
    }
    .bet-preview * {
        margin: 0 2px;
    }
    .v-blank-wrapper {
        margin: 100px 0;
    }
</style>