import { render, staticRenderFns } from "./MiniSicbo.vue?vue&type=template&id=23d5422c&scoped=true&"
import script from "./MiniSicbo.vue?vue&type=script&lang=js&"
export * from "./MiniSicbo.vue?vue&type=script&lang=js&"
import style0 from "@/assets/css/baccarat-style.css?vue&type=style&index=0&id=23d5422c&scoped=true&lang=css&"
import style1 from "@/assets/css/animate-games.css?vue&type=style&index=1&id=23d5422c&scoped=true&lang=css&"
import style2 from "./MiniSicbo.vue?vue&type=style&index=2&lang=css&"
import style3 from "./MiniSicbo.vue?vue&type=style&index=3&id=23d5422c&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "23d5422c",
  null
  
)

export default component.exports