<template>
    <div class="AdminToolTimelines">
        <Timeline v-for="(timeline, index) of timelines" 
            :key="index" 
            :index="index"
            :form="timeline"
            @remove="remove"
            @stepChange="handleStepChange"/>
        <div @click="add" class="add-more-content-button">
            <i class="el-icon-plus" style="font-size: 6rem;"></i>
        </div>
    </div>
</template>
<script>

import Timeline from './Timeline'

export default {
    name: 'AdminToolTimelines',
    props: {
        bus: {},
        limit: {
            default: 10
        },
        type: {}
    },
    components: {
        Timeline
    },
    data(){
        return {
            timelines: [],
        }
    },
    watch: {
        timelines: {
            deep: true,
            handler(newValue){
                this.$emit('change', {
                    data: newValue,
                    type: 'timeline'
                })
            }
        }
    },
    methods: {
        add(){
            if(this.timelines.length >= this.limit){
                this.$alert(`เพิ่มได้ไม่เกิน ${this.limit}`, 'แจ้งเตือน', {
                    type: 'info',
                    confirmButtonText: 'ตกลง',
                    customClass: 'custom-message-box'
                })
                return
            }
            this.timelines.push({
                title: '',
                description: '',
                steps: []
            })
        },
        remove(index){
            this.timelines.splice(index, 1)
        },
        clear(){
            this.timelines = []
            this.add()
        },
        handleStepChange(){
            this.$emit('change', {
                data: this.timelines,
                type: 'timeline',
            })
        }
    },
    mounted(){
        this.add()
        this.bus.$on('clear', this.clear)
    }
}
</script>
<style scoped>
.AdminToolTimelines {
    display: flex;
    width: calc(100vw - 80px);
    overflow: scroll
}
.AdminToolTimelines * {
    margin-left: 10px;
    flex-shrink: 0;
}
.add-more-content-button {
    display: flex;
    justify-content: center;
    align-items: center;
    border: solid 1px #b3d8ff;
    border-radius: 5px;
    color: #409EFF;
    background: #ecf5ff;
    width: 400px;
    height: 400px;
    cursor: pointer;
}
</style>