<template>
    <div class="MiniGame GameType miniDragon-tiger">
        <FloatButtomRightPanel/>
        <div class="close-baccarat">
            <div @click="exit"><i class="fa fa-times"></i></div>
        </div>
        <modal name="drawer-right" transition="modal-slide-left" class="editModalstat"
               :pivotY="1"
               :pivotX="1"
               :adaptive="true"
               width="300px"
               height="100%">
            <GameTableList :tableList="baccaratTableList"></GameTableList>
        </modal>
        <modal name="ticket-drawer" transition="modal-slide-left" class="editModalstat"
            :pivotY="1"
            :pivotX="1"
            :adaptive="true"
            width="300px"
            height="100%">
            <TicketList :ticketList="getTicketListByGame(tableType, tableId)"></TicketList>
        </modal>
        <div class="float-stream">
            <GameHeader :table="currentTable"></GameHeader>

            <!-- Animate 3D dragontiger -->
            <!-- <div v-if="liveMode === '3d'" class="box-game-animated animate-dragonTiger">
                <div class="box-animated">
                    <div class="hand">
                        <div class="hand-active_dragontiger"></div>
                            <div class="sec-cards section-dragontiger-card">
                                <div class="sec-half">
                                    <div class="box-show-cards fa-pull-right">
                                        <div class="pos-2">
                                            <div class="flip-cards floating-cards-effect">
                                                <div class="flip-card-inner">
                                                    <div class="card-front"></div>
                                                    <div class="card-back"></div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div class="sec-half">
                                    <div class="box-show-cards fa-pull-left">
                                        <div class="pos-2">
                                            <div class="flip-cards">
                                                <div class="flip-card-inner">
                                                    <div class="card-front"></div>
                                                    <div class="card-back"></div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>

                    </div>
                </div>
            </div> -->

           <GamePlay3D
           v-if="liveMode === '3d'"
           :gameStatus="gameStatus"
           :gameResult="gameResult"
           :cardDragon="gameInfoCardDragon"
           :cardTiger="gameInfoCardTiger"
           ></GamePlay3D>

            <!--End-->

            <div v-if="noTopSection === 'yes'" id="bankerDiv" style="bottom: unset; top: 0;">
                <UserBetChip
                v-for="(bankerPay, key) of bankerPayArr" :key="key"
                :state="bankerPay.state"
                :chip="bankerPay.chip"
                :value="bankerPay.value"
                :user_choose="bankerPay.user_choose"></UserBetChip>
            </div>
            <div v-else class="middle-stream">
                <div class="middle-stream-info">
                    <div id="bankerDiv">
                        <UserBetChip
                        v-for="(bankerPay, key) of bankerPayArr" :key="key"
                        :state="bankerPay.state"
                        :chip="bankerPay.chip"
                        :value="bankerPay.value"
                        :user_choose="bankerPay.user_choose"></UserBetChip>
                    </div>
                    <div class="box-balance-game">
                        <p>ราคาเดิมพัน</p>
                        <span>{{totalUserBet | numberFormat}}</span>
                        <span class="balance-box balance-box-selectValue" style="left: 1px; right: unset;"
                              @click="toSelectBetLimit"><i class="fas fa-coins"></i>  เลือกวงเงิน</span>
                        <span class="balance-box">balance: {{balance | numberFormat}}</span>
                        <span v-if="liveMode === '3d'" @click="toggleLiveMode" class="box-switch-mode">
                            <span>3D</span>
                        </span>
                        <span v-else @click="toggleLiveMode" class="box-switch-mode">
                            <span>สด</span>
                        </span>
                        <div class="bx-volume-game">
                            <SoundToggleButton v-if="isOnDev" @click="toggleGameSound" :on="isGameSoundOn"></SoundToggleButton>
                        </div>
                    </div>
                    <div v-if="false" class="contentPanel-card ">
                        <div class="banker">
                            <ul>
                                <li>มังกร</li>
                                <li>{{dragonPointDisplay}}</li>
                            </ul>
                            <div class="box-cardpoker">
                                <div class="box-cardpoker-top">
                                    <div class="card-position-p1">
                                        <GameCard
                                                :show="!!getCardNumber(gameInfoCardDragon)"
                                                :type="(gameInfoCardDragon) ? gameInfoCardDragon.card_type : 0"
                                                :number="(gameInfoCardDragon) ? gameInfoCardDragon.card_number : 0"
                                                :dataOpenDelay="dataOpenCardDelay"></GameCard>
                                        <!-- <div class="flip-vertical-left  card-object-vf   ">
                                        </div> -->
                                        <p id="point_p2" style="display:none">p2</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="player">
                            <ul>
                                <li>เสือ</li>
                                <li>{{tigerPointDisplay}}</li>
                            </ul>
                            <div class="box-cardpoker">
                                <div class="box-cardpoker-top">
                                    <div class="card-position-p1">
                                        <GameCard
                                        :show="!!getCardNumber(gameInfoCardTiger)"
                                        :type="(gameInfoCardTiger) ? gameInfoCardTiger.card_type : 0"
                                        :number="(gameInfoCardTiger) ? gameInfoCardTiger.card_number : 0"
                                        :dataOpenDelay="dataOpenCardDelay"></GameCard>
                                        <!-- <div class="flip-vertical-left  card-object-vf   ">
                                        </div> -->
                                        <p id="point_p2" style="display:none">p2</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div class="circle-wrap">
                        <p id="countdown">
                            <span v-if="gameStatus === GAME_STATUS.COUNTDOWN">{{secondCountDown}}</span>
                            <span class="txt-status" v-else>{{gameStatus | displayTableGameStatus}}</span>
                        </p>
                    </div>
                    <!-- <img src="@/assets/images/simple-2.jpg"/> -->
                    <!--<img src="images/simple-2.jpg">-->
                    <VideoPlayer 
                    v-show="liveMode === 'video'" 
                    :tableVideoStreamPath="tableVideoStreamPath"
                    :srcHLS="tableVideoSrcHLS"
                    :srcFLV="tableVideoSrcFLV"
                    :width="videoPlayerWidth"
                    :height="videoPlayerHeight"
                    :playerBus="playerBus"
                    @ready="videoPlayerReadyHandle"
                    ></VideoPlayer>
                    <GameVerticalTool
                            :panel_to_show="panel_to_show"
                            :orientation="orientation"
                            :isShowStatPanel="isShowStatPanel"
                            @exit="exit"
                            @toggleTicketPanel="toggleTicketPanel"
                            @showSideMenu="showSideMenu"
                            @toggleStatePanel="toggleStatePanel"
                            @togglePlayPanel="togglePlayPanel"
                            @goDepositWithdraw="goDepositWithdraw">
                    </GameVerticalTool>
                </div>

                <div @click="togglePanelBottom" class="btn-control-bottom"
                     :class="{'current': !is_show_panel_bottom}"></div>
            </div>
            <div v-if="noTopSection === 'yes'" id="playerDiv"></div>
            <div v-show="is_show_panel_bottom" class="bottom-stream bottom-stream-dragonTiger" id="panelBottom">
                <div class="box-progressBar">
                    <div class="box-progressBar-info">
                        <div class="progress-bar-user progress-bar-player" :style="`width: ${bet_dragon_percent}%`">
                            <span v-show="bet_dragon_percent > 0">{{bet_dragon_percent | numberFormat}}</span>
                        </div>
                        <div class="progress-bar-user progress-bar-tie" :style="`width: ${bet_pair_percent}%`">
                            <span v-show="bet_pair_percent > 0">{{bet_pair_percent | numberFormat}}%</span>
                        </div>
                        <div class="progress-bar-user progress-bar-tie2" :style="`width: ${bet_tie_percent}%`">
                            <span v-show="bet_tie_percent > 0">{{bet_tie_percent | numberFormat}}%</span>
                        </div>
                        <div class="progress-bar-user progress-bar-banker" :style="`width: ${bet_tiger_percent}%`">
                            <span v-show="bet_tiger_percent > 0">{{bet_tiger_percent | numberFormat}}%</span>
                        </div>
                    </div>
                </div>
                <FloatOpenCardDT 
                :isPanelOpen="isFloatOpenCardPanelOpen"
                :tigerPointDisplay="tigerPointDisplay"
                :dragonPointDisplay="dragonPointDisplay"
                :isDragonWin="isPanelWin(GAME_RESULT.DRAGON_WIN, gameResult)"
                :isTigerWin="isPanelWin(GAME_RESULT.TIGER_WIN, gameResult)"
                :isTie="isPanelWin(GAME_RESULT.TIE, gameResult)"
                :cards="gameInfoCards"
                :cardDataOpenDelays="dataOpenDelays">
                </FloatOpenCardDT>
                <div v-show="orientation === 'portrait' || panel_to_show === 'play'" class="st-content-play" style="display: block">
                    <div class="content-panel-play content-panel-play-DragonTiger" id="contentDragonTiger">
                        <!-- <div class="bg-winner-active" v-show="gameStatus === GAME_STATUS.FINISH" style="z-index: 15;">
                            <div v-show="gameResult === GAME_RESULT.TIGER_WIN" class="tiger-win"><span></span></div>
                            <div v-show="gameResult === GAME_RESULT.TIE" class="tie-win"><span></span></div>
                            <div v-show="gameResult === GAME_RESULT.DRAGON_WIN" class="dragon-win"><span></span></div>
                        </div> -->
                        <div class="box-panel-play">
                            <div ref="left-panel-play" class="left-panel-play">
                                <FloatChip
                                        v-for="(bet, i) of getOtherBetListByChoose(USER_CHOOSE.DRAGON_WIN)" :key="i"
                                        :value="bet.amount"
                                        :choose="bet.choose"
                                        :bound="getPanelPlayฺBound('dragon-block')">
                                </FloatChip>
                                <DragonTigerPanelPlay
                                        ref="dragon-block"
                                        customClass="btn-dragon deck"
                                        label="มังกร"
                                        labelMulti="1:1"
                                        :user_choose="user_choose"
                                        choice="DRAGON_WIN"
                                        :userBetArr="userBetArrDragon"
                                        :amount="amount"
                                        :isPanelWin="isPanelWin(GAME_RESULT.DRAGON_WIN, gameResult)"
                                        :shipState="calPanelChipState(GAME_RESULT.DRAGON_WIN)"
                                        :isOnSendBet="isOnSendBet"
                                        @selectBet="selectBet"
                                        @confirmBet="confirmBet"
                                        @cancelBet="cancelBet">
                                </DragonTigerPanelPlay>
                            </div>
                            <div class="middle-panel-play">
                                <DragonTigerPanelPlay
                                ref="pair-block"
                                customClass="btn-tie deck tie-bg"
                                label="คู่ เสมอ"
                                labelMulti="30:1"
                                :user_choose="user_choose"
                                choice="PAIR"
                                :userBetArr="userBetArrPair"
                                :amount="amount"
                                :isPanelWin="isOptionalPanelWin('is_pair')"
                                :shipState="calOptionalPanelChipState('is_pair')"
                                :isOnSendBet="isOnSendBet"
                                @selectBet="selectBet"
                                @confirmBet="confirmBet"
                                @cancelBet="cancelBet">
                                    <FloatChip
                                    v-for="(bet, i) of getOtherBetListByChoose(USER_CHOOSE.PAIR)" :key="i"
                                    :value="bet.amount"
                                    :choose="bet.choose"
                                    :bound="getPanelPlayฺBound('pair-block')">
                                    </FloatChip>
                                </DragonTigerPanelPlay>
                                <DragonTigerPanelPlay
                                ref="tie-block"
                                customClass="btn-tie deck tie-bg tie-bg-green"
                                label="เสมอ"
                                labelMulti="8:1"
                                :user_choose="user_choose"
                                choice="TIE"
                                :userBetArr="userBetArrTie"
                                :amount="amount"
                                :isPanelWin="isPanelWin(GAME_RESULT.TIE, gameResult)"
                                :shipState="calPanelChipState(GAME_RESULT.TIE)"
                                :isOnSendBet="isOnSendBet"
                                @selectBet="selectBet"
                                @confirmBet="confirmBet"
                                @cancelBet="cancelBet">
                                    <FloatChip
                                    v-for="(bet, i) of getOtherBetListByChoose(USER_CHOOSE.TIE)" :key="i"
                                    :value="bet.amount"
                                    :choose="bet.choose"
                                    :bound="getPanelPlayฺBound('tie-block')">
                                    </FloatChip>
                                </DragonTigerPanelPlay>
                            </div>
                            <div class="right-panel-play">
                                <FloatChip
                                        v-for="(bet, i) of getOtherBetListByChoose(USER_CHOOSE.TIGER_WIN)" :key="i"
                                        :value="bet.amount"
                                        :choose="bet.choose"
                                        :bound="getPanelPlayฺBound('tiger-block')">
                                </FloatChip>
                                <DragonTigerPanelPlay
                                        ref="tiger-block"
                                        customClass="btn-tiger deck"
                                        label="เสือ"
                                        labelMulti="1:1"
                                        :user_choose="user_choose"
                                        choice="TIGER_WIN"
                                        :userBetArr="userBetArrTiger"
                                        :amount="amount"
                                        :isPanelWin="isPanelWin(GAME_RESULT.TIGER_WIN, gameResult)"
                                        :shipState="calPanelChipState(GAME_RESULT.TIGER_WIN)"
                                        :isOnSendBet="isOnSendBet"
                                        @selectBet="selectBet"
                                        @confirmBet="confirmBet"
                                        @cancelBet="cancelBet">
                                </DragonTigerPanelPlay>
                            </div>
                        </div>
                    </div>

                    <div class="box-panel-play-tool">

                        <div class="box-coin">
                            <GameChipForSelect
                                    v-for="(chip, i) of chipForSelectArr" :key="i"
                                    :value="chip"
                                    :active="amount === chip"
                                    @click="selectChip(chip)">
                            </GameChipForSelect>
                        </div>
                    </div>
                </div>
                <div v-if="orientation === 'landscape' && panel_to_show === 'state'" class="st-content-state">
                    <Stat :statArr="statArr" :betLimitConfig="currentBetLimitConfig"></Stat>
                </div>
            </div>

            <div id="playerDiv"></div>
            <!-- <Stat v-if="false" :statArr="statArr" :betLimitConfig="currentBetLimitConfig"></Stat> -->
            <Stat v-if="orientation === 'portrait' && isShowStatPanel" :statArr="statArr" :betLimitConfig="currentBetLimitConfig"></Stat>

            <GameTopPlayer
            :list="bet_user_ranking"
            :bet_list="bet_user_bet_list"
            :tableType="tableType">
            </GameTopPlayer>
        </div>
        <Chat v-if="tableId && noChat !== 'yes'" :data_room_id="`_table_id=${tableId}`"></Chat>

        <modal name="result-modal" :height="'auto'" width="98%" classes="modal-background-transparent modalAlert">
            <div class="flex-center-center">
                <div class="Aligner-item Aligner-item-result">
                    <Result :profitLoss="profitLoss"></Result>
                </div>
            </div>
        </modal>

        <modal name="not-bet-modal" :height="'auto'" width="98%" classes="modal-background-transparent modalAlert"
               @opened="autoCloseModal('not-bet-modal')">
            <div class="flex-center-center">
                <div class="Aligner-item" style="color: white;">
                    รอบยังไม่เปิด
                    <br>
                    กรุณารอ
                </div>
            </div>
        </modal>

        <modal name="bet-message-modal" :height="'auto'" width="98%" classes="modal-background-transparent modalAlert"
               @opened="autoCloseModal('bet-message-modal')">
            <div class="flex-center-center">
                <div class="Aligner-item" style="color: white;">
                    <p><i class="fas fa-exclamation-triangle"></i> {{betMessage}}</p>
                </div>
            </div>
        </modal>

        <modal name="select-bet-limit-modal" :height="'auto'" width="98%" class="editModalSelect"
               classes="modal-background-transparent"
               :clickToClose="false">
            <div class="flex-center-center">
                <div class="Aligner-item Aligner-item-select-value" style="color: white; text-align: center;">
                    <p><i class="fas fa-coins"></i> เลือกวงเงิน</p>
                    <button v-for="(betLimit, key) of betLimitArr" :key="key"
                            class="btn btn-cs" style="margin: 4px 0;"
                            @click="setBetLimit(betLimit.config_id)">
                        {{betLimit.min_bet}} - {{betLimit.max_bet}}
                    </button>
                </div>
            </div>
        </modal>

        <b-modal ref="error-message-modal"
                 hide-header
                 centered
                 ok-title="ตกลง"
                 cancel-title="รอ"
                 @ok="$router.replace({name: 'GameType'})">
            <div class="modal-notify-text-center">
                <h5>{{loadGameTableErrorMessage}}</h5>
            </div>
        </b-modal>

        <GoLobbyModal></GoLobbyModal>
    </div>
</template>
<script>

    import GameMixin from '@/mixins/Game'
    import GameTableList from '@/components/game/TableList'
    import GameHeader from '@/components/game/Header'
    import GameVerticalTool from '@/components/game/VerticalTool'
    import UserMixin from '@/mixins/UserMixin'
    import Timer from '@/lib/Timer'
    import debounce from '@/debounce'

    import randomBetween from '@/lib/randomBetween'
    import GameCard from '@/components/game/Card'
    import GameTopPlayer from '@/components/game/TopPlayer'
    import GameChipForSelect from '@/components/game/ChipForSelect'
    import {
        DRAGON_TIGER_USER_CHOOSE,
        GAME_STATUS_KEY,
        DRAGON_TIGER_GAME_RESULT_KEY,
        CHIP_MAP,
    } from '@/components/game/Constant'
    import {SOUND, soundLoad} from '@/components/game/dragontiger/Constant'
    import DragonTigerPanelPlay from '@/components/game/dragontiger/PanelPlay'
    import Stat from '@/components/game/dragontiger/Stat'
    import FloatChip from '@/components/game/FloatChip'
    import waitForSecond from '@/lib/waitForSecond'
    import Chat from '@/components/Chat'
    import TicketList from '@/components/game/TicketList'
    import Result from '@/components/game/Result'
    import VideoPlayer from './VideoPlayer'
    import UserBetChip from './UserBetChip'
    import GamePlay3D from './dragontiger/GamePlay3D'
    import SoundToggleButton from './SoundToggleButton.vue'
    import FloatButtomRightPanel from '@/components/FloatButtomRightPanel'
    import FloatOpenCardDT from '@/components/game/dragontiger/FloatOpenCardDT'

    const SECOND_START = 15

    export default {
        name: 'MiniDragonTiger',
        components: {
            FloatOpenCardDT,
            GameTableList,
            GameHeader,
            GameVerticalTool,
            GameTopPlayer,
            GameChipForSelect,
            DragonTigerPanelPlay,
            GameCard,
            FloatChip,
            Stat,
            Chat,
            TicketList,
            Result,
            VideoPlayer,
            UserBetChip,
            GamePlay3D,
            SoundToggleButton,
            FloatButtomRightPanel,
        },
        mixins: [
            GameMixin,
            UserMixin,
        ],
        data() {
            return {
                chip_arr: [5, 10, 50, 100, 500, 1000],
                amount: 10,
                user_choose: 0,
                otherBetChipArr: [],
                otherBetChooseArr: [],
                otherBetArr: [],
                otherBetTimer: null,
                totalAmount: 0,
                USER_CHOOSE: null,
                GAME_STATUS: null,
                GAME_RESULT: null,
                cardsFromAdd: [],
                timer: null,
                secondCountDown: SECOND_START,
                loading: null,
                tigerPointDisplay: '-',
                dragonPointDisplay: '-',
                randomBetPercentMap: {
                    1: 47,
                    2: 47,
                    3: 4,
                    5: 2,
                },
                isShowStatPanel: false,
            }
        },
        computed: {
            secondRemain() {
                const now = this.$moment()
                if (!this.countDownStart) {
                    return SECOND_START

                }
                const timestamp = this.$moment(this.countDownStart)
                const remain = parseInt(this.$moment.duration(now.diff(timestamp)).asSeconds())
                return (SECOND_START - remain > 0) ? SECOND_START - remain : 0
            },
            cards() {
                if (this.cardsFromAdd.length > 0) {
                    return this.cardsFromAdd
                } else if (this.gameInfo) {
                    return this.gameInfo.card
                } else {
                    return []
                }
            },
            dataOpenCardDelay() {
                if(this.liveMode === '3d') {
                    return 3000
                } else {
                    return 400
                }
            },
            dataOpenDelays() {
                return {
                    cardDragon: this.dataOpenCardDelay,
                    cardTiger: this.dataOpenCardDelay,
                }
            },
            tigerPoint: function () {
                if (!this.gameInfoCardTiger) {
                    return '-'
                }
                const card1Number = this.getCardNumber(this.gameInfoCardTiger)

                return this.calCardPoint(card1Number)
            },
            dragonPoint: function () {
                if (!this.gameInfoCardDragon) {
                    return '-'
                }
                const card1Number = this.getCardNumber(this.gameInfoCardDragon)

                return this.calCardPoint(card1Number)
            },
            gameInfoCardTiger() {
                return this.getInfoCard(1, 0)
            },
            gameInfoCardDragon() {
                return this.getInfoCard(0, 0)
            },
            gameInfoCards() {
                return {
                    gameInfoCardTiger: this.gameInfoCardTiger,
                    gameInfoCardDragon: this.gameInfoCardDragon,
                }
            },
            userBetArrDragon() {
                return this.filterUserBetArrByUserChoose(this.USER_CHOOSE.DRAGON_WIN)
            },
            userBetArrTiger() {
                return this.filterUserBetArrByUserChoose(this.USER_CHOOSE.TIGER_WIN)
            },
            userBetArrTie() {
                return this.filterUserBetArrByUserChoose(this.USER_CHOOSE.TIE)
            },
            userBetArrPair() {
                return this.filterUserBetArrByUserChoose(this.USER_CHOOSE.PAIR)
            },
            isFloatOpenCardPanelOpen() {
                return parseInt(this.gameStatus) === GAME_STATUS_KEY.DEALING || parseInt(this.gameStatus) === GAME_STATUS_KEY.FINISH
            },
            pointComplete() {
                return this.tigerPointDisplay !== '-' && this.dragonPointDisplay !== '-'
            },
            bet_dragon() {
                return (this.currentGame) ? this.currentGame.bet_dragon : 0
            },
            bet_tiger() {
                return (this.currentGame) ? this.currentGame.bet_tiger : 0
            },
            bet_tie() {
                return (this.currentGame) ? this.currentGame.bet_tie : 0
            },
            bet_pair() {
                return (this.currentGame) ? this.currentGame.bet_pair : 0
            },
            bet_sum() {
                return (this.currentGame) ? this.currentGame.bet_sum : 0
            },
            bet_dragon_percent() {
                if(!this.bet_sum) {
                    return this.randomBetPercentMap[this.USER_CHOOSE.DRAGON_WIN]
                }
                return (this.bet_dragon) ? (parseFloat(this.bet_dragon) / parseFloat(this.bet_sum)) * 100 : 0
            },
            bet_tiger_percent() {
                if(!this.bet_sum) {
                    return this.randomBetPercentMap[this.USER_CHOOSE.TIGER_WIN]
                }
                return (this.bet_tiger) ? (parseFloat(this.bet_tiger) / parseFloat(this.bet_sum)) * 100 : 0
            },
            bet_tie_percent() {
                if(!this.bet_sum) {
                    return this.randomBetPercentMap[this.USER_CHOOSE.TIE]
                }
                return (this.bet_tie) ? (parseFloat(this.bet_tie) / parseFloat(this.bet_sum)) * 100 : 0
            },
            bet_pair_percent() {
                if(!this.bet_sum) {
                    return this.randomBetPercentMap[this.USER_CHOOSE.PAIR]
                }
                return (this.bet_pair) ? (parseFloat(this.bet_pair) / parseFloat(this.bet_sum)) * 100 : 0
            },
        },
        watch: {
            gameStatus(newVal, oldVal) {
                if(this.GAME_STATUS.DEALING === parseInt(newVal)) {
                    this.playStopBetSound()
                    this.cancelBet()
                }
                if (parseInt(oldVal) === this.GAME_STATUS.DEALING && parseInt(newVal) === this.GAME_STATUS.FINISH) {//end round

                    const dragonPointSound = SOUND['POINT']['DRAGON'][parseInt(this.dragonPoint)]
                    const tigerPointSound = SOUND['POINT']['TIGER'][parseInt(this.tigerPoint)]
                    const resultSound = SOUND['RESULT'][parseInt(this.gameResult)]

                    this.playGameSound(dragonPointSound)
                    setTimeout(() => {
                        this.playGameSound(tigerPointSound)
                    }, 1300)
                    setTimeout(() => {
                        this.playGameSound(resultSound)
                    }, 1300 + 1300)

                    setTimeout(() => {
                        this.clearCardsFromAdd()
                        this.clearUserBetArr()
                        this.clearOtherBet()
                        this.clearBet()
                        this.onRoundEnd()
                    }, 2 * 1000)
                }
                if (parseInt(newVal) === this.GAME_STATUS.COUNTDOWN) {
                    setTimeout(() => {
                        this.playStartBetSound()
                    }, 2000)
                    this.startCountDown()
                    this.startOtherBet()
                    this.generateRandomBetPercent()
                } else {
                    this.stopCountDown()
                    this.stopOtherBet()
                }
            },
            tigerPoint(newVal) {
                if (newVal === '-') {
                    this.tigerPointDisplay = newVal
                } else {
                    setTimeout(() => {
                        this.tigerPointDisplay = newVal
                    }, 400 + this.dataOpenCardDelay)
                }
            },
            dragonPoint(newVal) {
                if (newVal === '-') {
                    this.dragonPointDisplay = newVal
                } else {
                    setTimeout(() => {
                        this.dragonPointDisplay = newVal
                    }, 400 + this.dataOpenCardDelay)
                }
            },
            secondRemain(newVal, oldVal) {
                if (oldVal === 0 && newVal > 0) {
                    this.stopCountDown()
                    this.startCountDown()
                    if (this.gameStatus === this.GAME_STATUS.COUNTDOWN) {
                        this.stopOtherBet()
                        this.startOtherBet()
                    }
                }
            },
            orientation(newVal) {
                if(newVal === 'portrait') {
                    this.isShowStatPanel = true
                }
            },
        },
        methods: {
            confirmBet() {
                this._confirmBet()
            },
            showLoadTableGameError() {
                this.$refs['error-message-modal'].show()
            },
            getInfoCard(side, index) {
                if (this.cards) {
                    return this.cards.find((card) => {
                        return card.side === side && card.index === index
                    })
                } else {
                    return null
                }
            },
            getCardNumber(card) {
                if (!card || !card.card_number) {
                    return 0
                }
                return parseInt(card.card_number)
            },
            calCardPoint(cardNumber) {
                return parseInt(cardNumber)
            },
            filterUserBetArrByUserChoose(user_choose) {
                return this.userBetArr.filter((bet) => {
                    return bet.user_choose === user_choose
                })
            },
            onAddCard(data) {
                try {
                    if(!this.currentGame || !this.currentTable) {
                        return
                    }
                    if(parseInt(data.gbs_id) === parseInt(this.currentGame.gbs_id) &&
                    parseInt(data.game_index) === parseInt(this.currentTable.table_type)) {
                        this.cardsFromAdd.push(data.card)
                    }
                } catch(err) {
                    console.log('onAddCard err', err.message)
                }
            },
            clearCardsFromAdd() {
                this.cardsFromAdd = []
            },
            getPanelPlayฺBound(ref) {
                const width = this.$refs[ref].$el.offsetWidth
                const height = this.$refs[ref].$el.offsetHeight
                return {width, height}
            },
            getOtherBetListByChoose(choose) {
                return this.otherBetArr.filter((bet) => {
                    return parseInt(bet.choose) === parseInt(choose)
                })
            },
            otherBet() {
                this.otherBetArr.push({
                    choose: this.otherBetChooseArr[randomBetween(0, this.otherBetChooseArr.length - 1)],
                    amount: this.otherBetChipArr[randomBetween(0, this.otherBetChipArr.length - 1)],
                })
            },
            clearOtherBet() {
                this.otherBetArr = []
            },
            startOtherBet: debounce(async function () {

                await waitForSecond(2000)

                if (this.otherBetTimer) {
                    this.otherBetTimer.start()
                    return
                }
                this.otherBetTimer = Timer(() => {
                    this.otherBet()
                    let nextTimeout = 1000
                    if (parseInt(this.secondCountDown) > 0) {
                        nextTimeout = 33 * this.secondCountDown
                    }
                    return nextTimeout + randomBetween(0, 44)
                }, 1000)
            }, 100),
            stopOtherBet: debounce(function () {
                if (!this.otherBetTimer) {
                    return
                }
                this.otherBetTimer.stop()
            }, 100),
            isPanelWin(panel, result) {
                return panel === result && this.pointComplete
            },
            isOptionalPanelWin(option) {
                if(!this.currentGame) {
                    return false
                }
                return this.currentGame[option]
            },
            calPanelChipState(panel) {
                if(!this.gameResult) {
                    return 'playerPlace'
                }
                if(this.GAME_RESULT.TIE === this.gameResult) {
                    return 'playerPlace'
                } else if(panel === this.gameResult) {
                    return 'goPlayer'
                } else {
                    return 'goBanker'
                }
            },
            calOptionalPanelChipState(option) {
                if(!this.gameResult) {
                    return 'playerPlace'
                }
                if(!this.currentGame) {
                    return 'playerPlace'
                }
                if(this.currentGame[option]) {
                    return 'goPlayer'
                } else {
                    return 'goBanker'
                }
            },
            generateRandomBetPercent() {
                let percentRemain = 100
                const percentsConfig = [
                    {
                        slug: 2,
                        min: 30,
                        max: 80,
                    },
                    {
                        slug: 3,
                        min: 0,
                        max: 8,
                    },
                    {
                        slug: 5,
                        min: 0,
                        max: 4,
                    },
                    {
                        slug: 1,
                        min: 30,
                        max: 80,
                    },
                ]
                for(const percentConfig of percentsConfig) {
                    if(percentConfig.slug === 1) {
                        this.randomBetPercentMap[percentConfig.slug] = percentRemain
                    } else {
                        const percent = randomBetween(percentConfig.min, percentConfig.max)
                        const percentLimit = Math.min(percentRemain, percent)
                        this.randomBetPercentMap[percentConfig.slug] = percentLimit
                        percentRemain -= percentLimit
                    }
                }
            },
            toggleStatePanel() {
                if(this.orientation === 'portrait') {
                    this.isShowStatPanel = !this.isShowStatPanel
                } else {
                    this.panel_to_show = 'state'
                }
                this.clearOtherBet()
            },
        },
        created() {
            this.USER_CHOOSE = DRAGON_TIGER_USER_CHOOSE
            this.GAME_STATUS = GAME_STATUS_KEY
            this.GAME_RESULT = DRAGON_TIGER_GAME_RESULT_KEY

            for (const key in CHIP_MAP) {
                this.otherBetChipArr.push(key)
            }
            for (const key in this.USER_CHOOSE) {
                this.otherBetChooseArr.push(this.USER_CHOOSE[key])
            }
        },
        async mounted() {

            if (this.gameStatus === this.GAME_STATUS.COUNTDOWN) {
                this.startCountDown()
                this.startOtherBet()
            }
            this.amount = this.chipForSelectArr[0]

            soundLoad()
            this.generateRandomBetPercent()

            if(this.orientation === 'portrait') {
                this.isShowStatPanel = true
            }
        },
        beforeDestroy() {
            this.stopCountDown()
            this.stopOtherBet()
        }
    }
</script>
<style scoped src="@/assets/css/baccarat-style.css"></style>
<style scoped src="@/assets/css/animate-games.css"></style>
<style>
    /*.box-progressBar{display: none}*/
    @media (orientation: landscape) {
        .LiffGameHome .box-panel-play{
            height: 100%;
        }
        .LiffGameHome  #bankerDiv {
            width: 50px;
            height: 50px;
            position: absolute;
            margin: auto;
            right: 0;
            bottom: 100px;
            left: 0;
            z-index: 2;
        }
        .LiffGameHome #playerDiv {
            width: 5vw;
            height: 5vw;
            position: fixed;
            bottom: 0;
            left: 0;
            right: 0;
            margin: 0 auto;
        }
        .LiffGameHome .MiniBaccarat .bottom-stream{
            position: relative;
            height: 100%;
        }
        .LiffGameHome .content-panel-play {
            display: block;
            width: auto;
            height: 100%;
            padding-bottom: 60px;
        }
        .LiffGameHome .content-panel-play-miniBacarat,
        .LiffGameHome .content-panel-play-DragonTiger{
            position: relative!important;
        }
        .LiffGameHome .box-panel-play-tool{
            position: absolute!important;
            right: 0;
            left: 0;
            bottom: 0;
            justify-content: center;
            border-radius: 0;
        }
        .LiffGameHome .st-content-play{
            height: 100%;
        }
        .LiffGameHome  .box-coin {
            flex-direction: row;
        }
        .LiffGameHome .b-coin {
            width: 40px;
            height: 40px;
            margin: 0 6px;
        }
        .LiffGameHome .float-stream{
            flex-direction: column;
            justify-content: space-around;
            position: relative;
            height: 100vh;
        }
        .LiffGameHome .GameTopPlayer.box-top-user{
            position: fixed;
            top: 0;
            width: 300px;
            height: 57px;
            z-index: 100;
        }
    }
</style>
<style scoped>
    .box-menu-game {
        z-index: 9 !important;
    }




    .btn-cs {
        width: 100%;
        background-color: rgba(243, 114, 212, 0.9);
        border-radius: 20px;
        color: #fff !important;
        text-align: center !important;
        padding: 2px 5px !important;
    }

    /*.close-baccarat {*/
    /*    position: fixed;*/
    /*    top: 26px;*/
    /*    z-index: 9;*/
    /*    right: 20px;*/
    /*    width: 25px;*/
    /*    height: 25px;*/
    /*    display: block;*/
    /*    background-color: #000;*/
    /*    border-radius: 50%;*/
    /*    color: #fff;*/
    /*    font-size: 12px;*/
    /*    border: solid 1px #fab600;*/
    /*    text-align: center;*/
    /*    line-height: 22px;*/
    /*    box-shadow: rgba(0, 0, 0, 0.25) 0px 14px 28px, rgba(0, 0, 0, 0.22) 0px 10px 10px;*/
    /*}*/


    .btn-player, .btn-banker {
        bottom: -2px;
    }


    .user-list-detail {
        line-height: 20px;
    }

    .flex-center-center {
        display: flex;
        justify-content: center;
        align-items: center;
    }

    .balance-box {
        position: absolute;
        bottom: 1px;
        right: 1px;
    }

    .modal-notify-text-center {
        display: flex;
        justify-content: center;
        height: 100px;
        align-items: center;
    }

    .Aligner-item{
        font-family: "Athiti", sans-serif;
        background-color: rgba(0,0,0,0.9);
        padding: 15px 10px !important;
        border-radius: 10px !important;
    }

    .Aligner-item p{
        font-weight: bold;
        margin-bottom: 5px;
        font-size: 13px;
    }

    .Aligner-item i{
        color: #fab602;
    }

    .v--modal-overlay {
        background: rgba(0, 0, 0, 0.6) !important;
    }

    .FloatChip {
        width: 16px !important;
    }

    .bottom-stream-dragonTiger .left-panel-play,
    .bottom-stream-dragonTiger .right-panel-play {
        height: auto !important;
    }

    .tie-bg {
        padding: 10px;
    }

    .tie-bg:first-child {
        margin-bottom: 5px;
    }

    .player, .banker, .banker1 {
        height: 68px !important;
    }

    @media screen and (min-width: 600px) {
        .circle-wrap {
            top: 10vw!important;

        }
    }

</style>
<style>
    .modal-background-transparent {
        background-color: transparent;
    }
</style>
<style>
    @media only screen
    and (max-device-width: 1300px)
    and  (orientation: landscape) {

        .GameType .messaging{display: none!important}
        .GameType .box-menu-game{
            top: 50px!important;
        }
    }
</style>