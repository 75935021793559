<template>
    <div class="VideoPlayer">
        <div v-if="videoStreamStatus === 'join'" :key="loadTimestamp">
            <div v-if="$isIos">
                <VideoPlayerHLS
                    v-if="playerSelect === 'hls'"
                    :src="srcHLS"
                    :width="width"
                    :height="height"
                    :isMute="isMute"
                    @error="handleError"
                    @ready="handleReady"
                    :playerBus="playerBus"></VideoPlayerHLS>
                <VideoPlayerFLV2Canvas
                    v-else
                    :src="srcFLV"
                    :width="width"
                    :height="height"
                    :isMute="isMute"
                    @error="handleError"
                    @ready="handleReady"
                    :playerBus="playerBus"></VideoPlayerFLV2Canvas>
            </div>
            <div v-else>
                <VideoPlayerFLV
                    :src="srcFLV"
                    :width="width"
                    :height="height"
                    :isMute="isMute"
                    @error="handleError"
                    @ready="handleReady"
                    :playerBus="playerBus"></VideoPlayerFLV>
            </div>
        </div>
<!--        <div v-else-if="videoStreamStatus === 'error'" class="error-section">-->
<!--            <button @click="refresh" class="btn btn-light">เกิดปัญหา กดเพื่อโหลดใหม่</button>-->
<!--        </div>-->
        <div v-else-if="videoStreamStatus === 'error'" class="error-section">
            <i class="fas fa-exclamation-triangle"></i>
            <span><strong>การเชื่อมต่อขัดข้อง</strong> กดปุ่ม เพื่อโหลดใหม่</span>
            <button @click="refresh" class="btn btn-light"><i class="fas fa-redo-alt"></i> โหลดใหม่</button>
        </div>
        <div v-if="!is_member && !dataToken" class="error-section">
            <button @click="goSignUp" class="btn btn-light">ล็อกอิน/สมัครสมาชิก เพื่อดูไลฟ์</button>
        </div>
<!--        <div v-else-if="videoStreamStatus === 'error'" class="error-section">-->

    </div>
</template>
<script>

import VideoPlayerHLS from './VideoPlayerHLS'
import VideoPlayerFLV2Canvas from './VideoPlayerFLV2Canvas'
import VideoPlayerFLV from './VideoPlayerFLV'
import store from '@/store'
import axiosWrapper from '@/axiosWrapper'
import UserMixin from '@/mixins/UserMixin'
// import {mixin as VueTimers} from 'vue-timers'

export default {
    name: 'VideoPlayer',
    mixins: [
        UserMixin,
        // VueTimers,
    ],
    // timers: {
    //     refresh: {
    //         autostart: true,
    //         time: 5 * 60 * 1000,
    //         repeat: true,
    //     },
    // },
    components: {
        VideoPlayerHLS,
        VideoPlayerFLV2Canvas,
        VideoPlayerFLV,
    },
    props: [
        'tableVideoStreamPath',
        'srcHLS',
        'srcFLV',
        'width',
        'height',
        'playerBus',
        'isMute',
        'playerSelect',
        'dataToken',
    ],
    data() {
        return {
            videoStreamStatus: 'idle', // 'idle', 'join', 'leave', 'error',
            loadTimestamp: this.$moment().valueOf(),
        }
    },
    computed: {
        is_member() {
            return store.state.is_member
        },
        pes_profile() {
            return store.state.pes_profile
        },
        token() {
            return (this.pes_profile) ? this.pes_profile.token : ''
        },
    },
    watch: {
        tableVideoStreamPath(newVal) {
            if(newVal) {
                this.videoStreamJoin()
            }
        },
    },
    methods: {
        async videoStreamJoin() {

            if(!this.is_member && !this.dataToken) {
                return
            }

            const streamPath = this.tableVideoStreamPath
            const token = this.token || this.dataToken
            let data = ''
            if(this.is_member) {
                data = `username: ${this.pes_profile.username}, user_id: ${this.pes_profile.user_id}`
            }
            if(this.dataToken) {
                data = this.dataToken
            }

            if(!streamPath || this.videoStreamStatus === 'join') {
                return
            }
            
            const res = await axiosWrapper({
                method: 'post',
                url: `${this.envDomains.VUE_APP_API}/api/game/video-stream/join`,
                data: {
                    streamPath,
                    token,
                    data,
                }
            })
            if(res.status === 200 && !res.data.error) {
                this.videoStreamStatus = 'join'
            }
        },
        async videoStreamLeave() {

            if(!this.is_member && !this.dataToken) {
                return
            }

            const streamPath = this.tableVideoStreamPath
            const token = this.token || this.dataToken

            if(!streamPath || this.videoStreamStatus === 'leave') {
                return
            }
            
            const res = await axiosWrapper({
                method: 'post',
                url: `${this.envDomains.VUE_APP_API}/api/game/video-stream/leave`,
                data: {
                    streamPath,
                    token,
                }
            })
            if(res.status === 200 && !res.data.error) {
                this.videoStreamStatus = 'leave'
            }
        },
        handleError() {
            this.videoStreamStatus = 'error'
        },
        handleReady() {
            this.$emit('ready')
        },
        async refresh() {
            this.videoStreamStatus = 'idle'
            await this.videoStreamLeave()
            await this.videoStreamJoin()
        },
        fastRefresh() {
            this.loadTimestamp = this.$moment().valueOf()
        },
    },
    mounted() {
        this.videoStreamJoin()
        if(this.playerBus) {
            this.playerBus.$on('fastRefresh', this.fastRefresh)
        }
    },
    beforeDestroy() {
        this.videoStreamLeave()
        if(this.playerBus) {
            this.playerBus.$off('fastRefresh', this.fastRefresh)
        }
    }
}
</script>
<style scoped>
.VideoPlayer {
    width: 100%;
    height: 100%;
}
@media only screen
and (max-device-width: 1300px)
and  (orientation: landscape) {
    .VideoPlayer {
        height: 100vh;
    }
}
.VideoPlayer .error-section {
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    margin: auto;
    width: 160px;
    height: 150px;
    border-radius: 10px;
    text-align: center;
    /*display: flex;*/
    /*justify-content: center;*/
    /*align-items: center;*/
    background-color: white;
    z-index: 10;
    padding: 10px;
}

.VideoPlayer .error-section span{display: inline-block; width: 100%; margin-bottom: 10px;}

.VideoPlayer .error-section i{color: #aaa; font-size: 1.5rem}
.VideoPlayer .error-section .btn i{ color: white; font-size: 14px;}
.VideoPlayer .error-section .btn{
    border-radius: 20px;
    color: white;
    background: linear-gradient(to bottom, #753189 0%, #d20a84 100%)!important;
}
</style>