import { render, staticRenderFns } from "./GamePlay3D.vue?vue&type=template&id=5e9da0d8&scoped=true&"
import script from "./GamePlay3D.vue?vue&type=script&lang=js&"
export * from "./GamePlay3D.vue?vue&type=script&lang=js&"
import style0 from "@/assets/css/animate-games.css?vue&type=style&index=0&id=5e9da0d8&scoped=true&lang=css&"
import style1 from "./GamePlay3D.vue?vue&type=style&index=1&id=5e9da0d8&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "5e9da0d8",
  null
  
)

export default component.exports