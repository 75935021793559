<template>
    <div v-if="topWin.round_bill" class="MessageLotto incoming_msg">
        <div @click="play" :class="`box-msg ${iconClass}`">
            <div class="top-lotto-detail">
                <ul>
                    <li><img src="@/assets/images/bg-topUp/winner-icon.png" alt="" /></li>
                    <li>
                        <div class="lotto-date-bg">
                            <span>งวดวันที่ {{topWin.round_bill.date | lottoPriceDateTimeFormat}} </span>
                            <span v-if="topWin.round_bill.sub_round">รอบที่ {{topWin.round_bill.sub_round}}</span>
                        </div>
                    </li>
                </ul>
            </div>
            <div class="box-top-user-flex-lt">
                <ul class="box-result-lt">
                    <li>
                        <div class="n-box-result">
                            <p>{{topWin.bet_type | lottoBetTypeDisplay}}</p>
                            <span><strong>{{topWin.bet_number}}</strong></span>
                        </div>
                    </li>
                    <li>
                        <div class="box-top-user-list">
                            <div class="user-pic">
                                <Avatar mode="image" :dataSize="33" :src="getUserPictureUrl(message_data.user_id)"></Avatar>
                                <!-- <img src="@/assets/images/profile1.jpg" /> -->
                            </div>
                            <div class="user-list-detail">
                                <span>{{topWin.result | numberFormat}}</span>
                            </div>
                        </div>
                    </li>
                </ul>
            </div>
        </div>
    </div>
</template>

<script>

import {CHAT_ICON_CLASS} from '@/components/lotto/Constant'
import store from '@/store'
import sort from '@/lib/sort'
import Avatar from '@/components/Avatar'

// const MESSAGE_DATA = {
//     "lotto_type": "hanoy_special",
//       "user_id": 1462,
//       "amount": "15.00000000",
//       "bet_list": [
//         {
//           "id": 521911,
//           "id_user": 312,
//           "bill_id": "1618893927037312",
//           "lotto_type": "hanoy_special",
//           "round_id": 29391,
//           "bet_type": "song_tua_lang",
//           "bet_number": "44",
//           "amount": 5,
//           "reward": 90,
//           "status": "win",
//           "result": 450,
//           "created_at": "2021-04-20T04:45:28.000Z",
//           "updated_at": "2021-04-20T04:45:28.000Z",
//           "deleted_at": null,
//           "round_bill": {
//             "id": 29391,
//             "lotto_type": "hanoy_special",
//             "date": "2021-03-12",
//             "status": "open",
//             "description": "ปิดรับ 18:00",
//             "timeout": "2021-04-20T06:00:00.000Z",
//             "sub_round": null,
//             "created_at": "2021-03-12T07:07:57.000Z",
//             "updated_at": "2021-04-20T04:27:27.000Z",
//             "deleted_at": null
//           }
//         },
//         {
//           "id": 521912,
//           "id_user": 312,
//           "bill_id": "1618893927037312",
//           "lotto_type": "hanoy_special",
//           "round_id": 29391,
//           "bet_type": "song_tua_bon",
//           "bet_number": "23",
//           "amount": 5,
//           "reward": 90,
//           "status": "win",
//           "result": 450,
//           "created_at": "2021-04-20T04:45:28.000Z",
//           "updated_at": "2021-04-20T04:45:28.000Z",
//           "deleted_at": null,
//           "round_bill": {
//             "id": 29391,
//             "lotto_type": "hanoy_special",
//             "date": "2021-03-12",
//             "status": "open",
//             "description": "ปิดรับ 18:00",
//             "timeout": "2021-04-20T06:00:00.000Z",
//             "sub_round": null,
//             "created_at": "2021-03-12T07:07:57.000Z",
//             "updated_at": "2021-04-20T04:27:27.000Z",
//             "deleted_at": null
//           }
//         },
//         {
//           "id": 521913,
//           "id_user": 312,
//           "bill_id": "1618893927037312",
//           "lotto_type": "hanoy_special",
//           "round_id": 29391,
//           "bet_type": "sam_tua_bon",
//           "bet_number": "123",
//           "amount": 5,
//           "reward": 754,
//           "status": "win",
//           "result": 3770,
//           "created_at": "2021-04-20T04:45:28.000Z",
//           "updated_at": "2021-04-20T04:45:28.000Z",
//           "deleted_at": null,
//           "round_bill": {
//             "id": 29391,
//             "lotto_type": "hanoy_special",
//             "date": "2021-03-12",
//             "status": "open",
//             "description": "ปิดรับ 18:00",
//             "timeout": "2021-04-20T06:00:00.000Z",
//             "sub_round": null,
//             "created_at": "2021-03-12T07:07:57.000Z",
//             "updated_at": "2021-04-20T04:27:27.000Z",
//             "deleted_at": null
//           }
//         }
//       ]
//     }

export default {
    name: 'MessageLottoResultWinner',
    props: [
        'message',
        'float',
    ],
    components: {
        Avatar,
    },
    computed: {
        message_data(){
            // return MESSAGE_DATA
            return (this.message) ? this.message.data : null
        },
        timestamp(){
            return (this.message) ? this.message.timestamp : ''
        },
        topWin() {
            const sortedArr = sort(this.message_data.bet_list, 'result', true)
            return sortedArr[0]
        },
        lotto_type() {
            return (this.topWin) ? this.topWin.lotto_type : ''
        },
        iconClass() {
            return CHAT_ICON_CLASS[this.lotto_type]
        },
        user_info_arr(){
            return store.state.user_info_arr
        },
    },
    methods: {
        play() {
            store.commit('lotto/updatePageBetQueryLottoType', this.lotto_type)
            store.commit('lotto/updateCurrentPage', 'bet')
            this.$router.push({name: 'LottoBet'})
        },
        getUserInfo(pes_user_id) {
            return this.user_info_arr.find((user_info) => {
                return parseInt(user_info.pes_user_id) === parseInt(pes_user_id)
            })
        },
        getUserDisplayName(pes_user_id) {
            const userInfo = this.getUserInfo(pes_user_id)
            return (userInfo) ? userInfo.display_name : ''
        },
        getUserPictureUrl(pes_user_id) {
            const userInfo = this.getUserInfo(pes_user_id)
            return (userInfo) ? userInfo.picture_url : ''
        },
    },
}
</script>
