export default function isOldIos() {

    try {

        let verArr = []
        if (/iP(hone|od|ad|pro)/.test(navigator.platform)) {
            // supports iOS 2.0 and later: <http://bit.ly/TJjs1V>
            var v = (navigator.appVersion).match(/OS (\d+)_(\d+)_?(\d+)?/);
            verArr = [parseInt(v[1], 10), parseInt(v[2], 10), parseInt(v[3] || 0, 10)];
        }
        if(verArr.length === 0) {
            return false
        }
        return verArr[0] <= 12

    } catch(err) {
        return false
    }
}