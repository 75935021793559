<template>
    <button @click="select_number" class="PricePanel btn btn-outline-custom btn-block"
            :class="{
                'disabled line-through': !is_allow,
                'active': is_selected
            }">
        <span>
            {{number}}
        </span>
        <div v-if="!is_allow" class="label-price">
            -
        </div>
        <div v-else-if="price_limited && priceDown" class="label-price down-price" :class="{'blink_blink': isChange}">
<!--            <i class="fas fa-arrow-down"></i>-->
            {{price_limited | numberFormat}}
        </div>
        <div v-else-if="price_limited && priceUp" class="label-price up-price" :class="{'blink_blink': isChange}">
<!--            <i class="fas fa-arrow-up"></i>-->
            {{price_limited | numberFormat}}
        </div>
        <div v-else-if="price_limited" class="label-price">
            {{price_limited | numberFormat}}
        </div>
    </button>
</template>

<script>

    import PriceMixin from '../mixins/PriceMixin'
    import BetMixin from '../mixins/BetMixin'
    import store from '@/store'
    import {PriceRatio} from '../Constant'

    export default {
        name: 'PricePanel',
        mixins: [PriceMixin, BetMixin],
        props: ['number', 'number_setting_arr', 'bet_type_group', 'is_allow'],
        computed: {
            reward_limit(){
                return store.state.lotto.reward_limit
            },
            multi_min: function(){
                try{
                    return (this.reward_limit) ? this.reward_limit[this.round_lotto_type][this.bet_type_group]['min'] : 0;
                }catch(err){
                    return 0
                }
            },
            multi_max: function(){
                try{
                    return (this.reward_limit) ? this.reward_limit[this.round_lotto_type][this.bet_type_group]['max'] : 0;
                }catch(err){
                    return 0
                } 
            },
            // is_allow: function(){
            //     const is_number_disable = this.checkIsNumberDisable(this.number_setting_arr, this.category_arr, this.number)
            //     const is_reward_exceed = this.checkIsRewardExceed(this.price, this.multi_min, this.multi_max)
            //     return !is_number_disable && !is_reward_exceed
            // },
            bet_list: function(){
                return store.state.lotto.bet_list;
            },
            is_selected: function(){
                const found = this.bet_list.find((o) => {
                    return o.number === this.number;
                });
                return !!(found);
            },
            select_category_list(){
                return store.state.lotto.select_category_list
            },
            category_arr(){
                return this.select_category_list.map((o) => {
                    return o.slug
                })
            },
            price_calculated(){

                if(this.select_category_list.length > 0){

                    const isSamTuaType = (bet_type) => {
                        return this.select_category_list.find((category) => {
                            return category.slug === bet_type
                        })
                    }

                    let price_ratio = 1
                    if(isSamTuaType('sam_tua_bon')){
                        price_ratio = 1
                    }else if(isSamTuaType('sam_tua_tod')){
                        price_ratio = PriceRatio.sam_tua_tod
                    }else if(isSamTuaType('sam_tua_nha') || isSamTuaType('sam_tua_lang')){
                        price_ratio = PriceRatio.sam_tua_nha_lang
                    }
                    return parseInt(parseFloat(this.price)*parseFloat(price_ratio))
                }else{
                    return this.price
                }
            },
            price_limited(){
                if(!this.multi_min || !this.multi_max){
                    return this.price_calculated
                }else if(parseFloat(this.price_calculated) < this.multi_min){
                    return parseFloat(this.multi_min)-1
                }else if(parseFloat(this.price_calculated) > this.multi_max){
                    return parseFloat(this.multi_max)+1
                }else{
                    return this.price_calculated
                }
            }
        },
        methods: {
            select_number: function(){
                if(this.is_allow){
                    if(this.is_selected){
                        this.$emit('cancel', this.number);
                    }else{
                        this.$emit('select_number', Array.from(''+this.number));
                    }
                }else{
                    this.$bvToast.toast('ยอดซื้อเต็ม!! กำลังปรับเรทอัตราจ่ายใหม่', {title: 'แจ้งเตือน'});
                }
            },
        }
    }
</script>

<style scoped>
    .blink_blink {
        animation: blinker 1s linear infinite;
    }
    @keyframes blinker {
        50% {
            opacity: 10%;
        }
    }
    .line-through {
        text-decoration: line-through;
    }
    .label-price.up-price ,
    .label-price.down-price{
        border-radius: 6px;
        /*width: 30px;*/
        /*display: block;*/
        /*margin: 0 auto;*/
    }
    .label-price.up-price {
        color: white;
        background-color: green;
    }
    .label-price.down-price {
        color: white;
        background-color: red;
    }
</style>