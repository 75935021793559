<template>
    <div class="MiniGame MiniBaccarat">
        <FloatButtomRightPanel/>

<!--        <div v-if="version === 2" class="modalInsurance" >-->
        <div v-if="version === 2 || version === 3" class="modalInsurance" :class="{'hide': !isShowWarrantyModal || warrantyDisplayCountdownSecond <= 0}">
<!--        <div class="modalInsurance" >-->
            <div class="box-Insurance">
                <div class="box-Insurance-info">
                    <!--ผู้เล่นประกันการเดิมพัน-->
                    <div v-if="warrantyInfo.mode === 'play'" class="box-Insurance-info-content" style=" align-items: inherit">
<!--                    <div class="box-Insurance-info-content" style=" align-items: inherit">-->
                        <h4>ประกันการเดิมพัน</h4>
                        <div class="box-coundown-bar">
                            <div class="coundown-bar">
                                <span :style="`width: ${warrantyDisplayCountdownPercent}%`"></span>
                            </div>
                            <span class="coundownNum">{{warrantyDisplayCountdownSecond}}</span>
                        </div>
                        <h3>ประกัน 
                            <span v-if="userBetSidePoint === 9">
                                เสมอ
                            </span>
                            <span v-else>
                                แพ้
                            </span>
                            1:{{warrantyInfo.multiplier}} 
                        </h3>
                        <div class="row-detail-insurance">
                            <p>คุณเดิมพันผู้เล่น</p>
                            <p>{{warrantyInfo.totalUserBet | numberFormat}}</p>
                        </div>
                        <div class="row-detail-insurance">
                            <p>เงินที่สามารถชนะ</p>
                            <p>{{parseInt(warrantyForm.amount) * parseFloat(warrantyInfo.multiplier) | numberFormat}}</p>
                        </div>
                        <div class="row-detail-insurance">
                            <p>คุณเดิมพันประกันภัย</p>
                            <p>{{warrantyForm.amount | numberFormat}}</p>
                        </div>
                        <div class="slidecontainer">
                            <div class="value-slide">{{warrantyForm.amount | numberFormat}}</div>
                            <input type="range" 
                            :min="warrantyInfo.min" 
                            :max="warrantyInfo.max" 
                            v-model="warrantyForm.amount" class="slider">
                        </div>
                        <div class="slidecontainer-info">
                            <p>{{warrantyInfo.min | numberFormat}}</p>
                            <p>{{warrantyInfo.max | numberFormat}}</p>
                        </div>
                        <div class="box-select-num-br">
                            <div v-for="(item, key) of warrantyAmountPercents" :key="key" 
                            @click="() => {warrantyAmountPercent = item}"
                            :class="{'active': warrantyAmountPercent === item}"
                            class="select-num-br">
                            {{item}}%
                            </div>
                        </div>
                        <div class="box-Insurance-footter">
                            <button @click="sendWarrantyBet" type="submit" class="btn bg-gradient-btn">ตกลง</button>
                            <button @click="closeWarrantyModal" type="submit" class="btn btn-outline-primary">ยกเลิก</button>
                        </div>
                    </div>

                    <!--เริ่มวางเดิมพัน-->
                    <div v-else-if="warrantyInfo.mode === 'see'" class="box-Insurance-info-content box-Insurance-info-content-2" style="height: 100px;">
<!--                    <div class="box-Insurance-info-content box-Insurance-info-content-2" style="height: 100px;">-->
                        <div class="box-coundown-bar">
                            <div class="coundown-bar">
                                <span :style="`width: ${warrantyDisplayCountdownPercent}%`"></span>
                            </div>
                            <span class="coundownNum">{{warrantyDisplayCountdownSecond}}</span>
                        </div>
                        <h3><i class="fas fa-hourglass-start"></i> รอต่อรอง</h3>
                    </div>

                </div>
                <div class="content-bg"></div>
            </div>
        </div>

        <div class="close-baccarat">
            <div @click="exit"><i class="fa fa-times"></i></div>
        </div>
        <modal name="drawer-right" transition="modal-slide-left" class="MtableList"
               :pivotY="1"
               :pivotX="1"
               :adaptive="true"
               width="300px"
               height="100%">
            <GameTableList :tableList="baccaratTableList"></GameTableList>
        </modal>
        <modal name="ticket-drawer" transition="modal-slide-left" class="editModalstat"
               :pivotY="1"
               :pivotX="1"
               :adaptive="true"
               width="300px"
               height="100%">
            <TicketList :ticketList="getTicketListByGame(tableType, tableId)"></TicketList>
        </modal>

        <div class="float-stream" id="FloatStream">
            <GameTopPlayer
                    :list="bet_user_ranking"
                    :bet_list="bet_user_bet_list"
                    :tableType="tableType">
            </GameTopPlayer>
            <GameHeader :table="currentTable"></GameHeader>


            <!--Animate baccarat cards-->

            <!-- <div v-if="liveMode === '3d'" class="box-game-animated animate-baccarat">
                <div class="box-animated">
                    <div class="hand hand-active_baccarat">
                        <div class="sec-cards">
                            <div class="sec-half">
                                <div class="box-show-cards fa-pull-right">
                                    <div class="pos-1">
                                        <div class="flip-cards">
                                            <div class="flip-card-inner">
                                                <div class="card-front"></div>
                                                <div class="card-back"></div>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="pos-2">
                                        <div class="flip-cards">
                                            <div class="flip-card-inner">
                                                <div class="card-front"></div>
                                                <div class="card-back"></div>
                                            </div>
                                        </div>
                                        <div class="flip-cards floating-cards-effect">
                                            <div class="flip-card-inner">
                                                <div class="card-front"></div>
                                                <div class="card-back"></div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="sec-half">
                                <div class="box-show-cards fa-pull-left">
                                    <div class="pos-1">
                                        <div class="flip-cards">
                                            <div class="flip-card-inner">
                                                <div class="card-front"></div>
                                                <div class="card-back"></div>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="pos-2">
                                        <div class="flip-cards">
                                            <div class="flip-card-inner">
                                                <div class="card-front"></div>
                                                <div class="card-back"></div>
                                            </div>
                                        </div>
                                        <div class="flip-cards">
                                            <div class="flip-card-inner">
                                                <div class="card-front"></div>
                                                <div class="card-back"></div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div> -->

<!--            && noTopSection !== 'yes'-->
            <div v-if="liveMode === '3d'">
                <GamePlay3Dv2 v-if="version === 3"
                              :gameStatus="gameStatus"
                              :gameResult="gameResult"
                              :cardPlayer0="gameInfoCardPlayer2"
                              :cardPlayer1="gameInfoCardPlayer1"
                              :cardPlayer2="gameInfoCardPlayer0"
                              :cardBanker0="gameInfoCardBanker0"
                              :cardBanker1="gameInfoCardBanker1"
                              :cardBanker2="gameInfoCardBanker2"
                              @stateChanged="animationStateChangeHandle"
                ></GamePlay3Dv2>
                <GamePlay3D v-else
                            :gameStatus="gameStatus"
                            :gameResult="gameResult"
                            :cardPlayer0="gameInfoCardPlayer2"
                            :cardPlayer1="gameInfoCardPlayer1"
                            :cardPlayer2="gameInfoCardPlayer0"
                            :cardBanker0="gameInfoCardBanker0"
                            :cardBanker1="gameInfoCardBanker1"
                            :cardBanker2="gameInfoCardBanker2"
                            :isOnWarranty="isOnWarranty"
                            @stateChanged="animationStateChangeHandle"
                ></GamePlay3D>
            </div>
            

            <!--End-->

            <div v-if="noTopSection === 'yes'" style="bottom: unset; top: 0;">
                <div class="middle-stream">
                    <div class="middle-stream-info">
                        <div id="bankerDiv">
                            <UserBetChip
                                    v-for="(bankerPay, key) of bankerPayArr" :key="key"
                                    :state="bankerPay.state"
                                    :chip="bankerPay.chip"
                                    :value="bankerPay.value"
                                    :user_choose="bankerPay.user_choose"></UserBetChip>
                        </div>
                        <div class="box-balance-game">
                            <div class="circle-wrap">
                                <p id="countdown">
                                    <span v-if="gameStatus === GAME_STATUS.COUNTDOWN">
                                        <span v-if="isOnWarranty">{{warrantyDisplayCountdownSecond}}</span>
                                        <span v-else><i class="fas fa-hourglass-half"></i> {{secondCountDown}}</span>
                                    </span>
                                    <span class="txt-status" v-else>{{gameStatus | displayTableGameStatus}}</span>
                                </p>
                            </div>
                            <span class="balance-box balance-box-selectValue" style="left: 1px; right: unset;" @click="toSelectBetLimit">วงเงิน</span>
                            <div class="boxBetValue">
<!--                                <p>ราคาเดิมพัน</p>-->
                                <span>ราคาเดิมพัน : {{totalUserBet | numberFormat}}</span>
                            </div>
<!--                            <span v-if="liveMode === '3d'" @click="toggleLiveMode" class="box-switch-mode">-->
<!--                                <span>3D</span>-->
<!--                            </span>-->
<!--                            <span v-else @click="toggleLiveMode" class="box-switch-mode">-->
<!--                                <span>สด</span>-->
<!--                            </span>-->
<!--                            <div class="bx-volume-game">-->
<!--                                <SoundToggleButton v-if="isOnDev" @click="toggleGameSound" :on="isGameSoundOn"></SoundToggleButton>-->
<!--                            </div>-->

                            <span class="balance-box balance-box-total">{{balance | numberFormat}}</span>
                        </div>

                    </div>
                </div>



            </div>



            <div v-else class="middle-stream">
                <div class="middle-stream-info">
                    <div id="bankerDiv">
                        <UserBetChip
                                v-for="(bankerPay, key) of bankerPayArr" :key="key"
                                :state="bankerPay.state"
                                :chip="bankerPay.chip"
                                :value="bankerPay.value"
                                :user_choose="bankerPay.user_choose"></UserBetChip>
                    </div>

                    <div class="box-balance-game">
                        <p>ราคาเดิมพัน</p>
<!--                        <p @click="testModal">ราคาเดิมพัน</p>-->
                        <span>{{totalUserBet | numberFormat}}</span>
                        <span class="balance-box balance-box-selectValue" style="left: 1px; right: unset;"
                              @click="toSelectBetLimit"><i class="fas fa-coins"></i>  เลือกวงเงิน</span>
                        <span v-if="liveMode === '3d'" @click="toggleLiveMode" class="box-switch-mode">
                            <span>3D</span>
                        </span>
                        <span v-else @click="toggleLiveMode" class="box-switch-mode">
                            <span>สด</span>
                        </span>
                        <div class="bx-volume-game">
                            <SoundToggleButton v-if="isOnDev" @click="toggleGameSound" :on="isGameSoundOn"></SoundToggleButton>
                        </div>
                        <span class="balance-box balance-box-selectValue" style="left: 1px; right: unset;" @click="toSelectBetLimit"><i class="fas fa-coins"></i>  เลือกวงเงิน</span>
                        <span class="balance-box">Balance : {{balance | numberFormat}}</span>
                    </div>
                    <div v-if="false" class="contentPanel-card">
                        <div class="banker">
                            <ul>
                                <li>ผู้เล่น</li>
                                <li>{{playerPointDisplay}}</li>
                            </ul>
                            <div class="box-cardpoker">
                                <div class="box-cardpoker-top">
                                    <div class="card-position-p2">
                                        <!-- <div class="flip-vertical-left  card-object-vf "> -->
                                            <GameCard
                                                    :show="!!getCardNumber(gameInfoCardPlayer2)"
                                                    :type="(gameInfoCardPlayer2) ? gameInfoCardPlayer2.card_type : 0"
                                                    :number="(gameInfoCardPlayer2) ? gameInfoCardPlayer2.card_number : 0"
                                                    :dataOpenDelay="dataOpenCardDelay"></GameCard>
                                        <!-- </div> -->
                                        <p style="display:none">p1</p>
                                    </div>
                                    <div class="card-position-p1">
                                        <!-- <div class="flip-vertical-left  card-object-vf   "> -->
                                            <GameCard
                                                    :show="!!getCardNumber(gameInfoCardPlayer1)"
                                                    :type="(gameInfoCardPlayer1) ? gameInfoCardPlayer1.card_type: 0"
                                                    :number="(gameInfoCardPlayer1) ? gameInfoCardPlayer1.card_number: 0"
                                                    :dataOpenDelay="dataOpenCardDelay"></GameCard>
                                        <!-- </div> -->
                                        <p style="display:none">p2</p>
                                    </div>
                                </div>
                                <div class="box-cardpoker-bottom">
                                    <div class="card-position-p3">
                                        <!-- <div class="flip-vertical-left card-object-vf "> -->
                                            <GameCard
                                                    :show="!!getCardNumber(gameInfoCardPlayer0)"
                                                    :type="(gameInfoCardPlayer0) ? gameInfoCardPlayer0.card_type : 0"
                                                    :number="(gameInfoCardPlayer0) ? gameInfoCardPlayer0.card_number : 0"
                                                    :dataOpenDelay="dataOpenCardDelay + dataOpenJuaCardDelay"></GameCard>
                                            <p style="display:none">p3</p>
                                        <!-- </div> -->
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="player">
                            <ul>
                                <li>เจ้ามือ</li>
                                <li>{{bankerPointDisplay}}</li>
                            </ul>
                            <div class="box-cardpoker">
                                <div class="box-cardpoker-top">
                                    <div class="card-position-p2">
                                        <!-- <div class="flip-vertical-left  card-object-vf "> -->
                                            <GameCard
                                                    :show="!!getCardNumber(gameInfoCardBanker0)"
                                                    :type="(gameInfoCardBanker0) ? gameInfoCardBanker0.card_type : 0"
                                                    :number="(gameInfoCardBanker0) ? gameInfoCardBanker0.card_number : 0"
                                                    :dataOpenDelay="dataOpenCardDelay"></GameCard>
                                        <!-- </div> -->
                                        <p id="point_p1" style="display:none">p1</p>
                                    </div>
                                    <div class="card-position-p1">
                                        <!-- <div class="flip-vertical-left  card-object-vf   "> -->
                                            <GameCard
                                                    :show="!!getCardNumber(gameInfoCardBanker1)"
                                                    :type="(gameInfoCardBanker1) ? gameInfoCardBanker1.card_type : 0"
                                                    :number="(gameInfoCardBanker1) ? gameInfoCardBanker1.card_number : 0"
                                                    :dataOpenDelay="dataOpenCardDelay"></GameCard>
                                        <!-- </div> -->
                                        <p id="point_p2" style="display:none">p2</p>
                                    </div>
                                </div>
                                <div class="box-cardpoker-bottom">
                                    <div class="card-position-p3">
                                        <!-- <div class="flip-vertical-left card-object-vf "> -->
                                            <GameCard
                                                    :show="!!getCardNumber(gameInfoCardBanker2)"
                                                    :type="(gameInfoCardBanker2) ? gameInfoCardBanker2.card_type : 0"
                                                    :number="(gameInfoCardBanker2) ? gameInfoCardBanker2.card_number : 0"
                                                    :dataOpenDelay="dataOpenCardDelay + dataOpenJuaCardDelay"></GameCard>
                                            <p id="point_p3" style="display:none">p3</p>
                                        <!-- </div> -->
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="circle-wrap">
                        <p id="countdown">
                            <span v-if="gameStatus === GAME_STATUS.COUNTDOWN">
                                <span v-if="isOnWarranty">{{warrantyDisplayCountdownSecond}}</span>
                                <span v-else>{{secondCountDown}}</span>
                            </span>
                            <span class="txt-status" v-else>{{gameStatus | displayTableGameStatus}}</span>
                        </p>
                    </div>
                    <!-- <div style="height: 248px; background-color: black;"></div> -->
                    <!-- http://54.255.218.240:8000/live/demo1.flv?token=abc -->
                    <VideoPlayer 
                    v-show="liveMode === 'video'" :tableVideoStreamPath="tableVideoStreamPath"
                    :srcHLS="tableVideoSrcHLS"
                    :srcFLV="tableVideoSrcFLV"
                    :width="videoPlayerWidth"
                    :height="videoPlayerHeight"
                    :playerBus="playerBus"
                    playerSelect="hls"
                    @ready="videoPlayerReadyHandle"
                    ></VideoPlayer>
                    <!-- <img src="@/assets/images/simple-1.jpg"/> -->
                    <GameVerticalTool
                            :panel_to_show="panel_to_show"
                            :orientation="orientation"
                            :isShowStatPanel="isShowStatPanel"
                            @exit="exit"
                            @toggleTicketPanel="toggleTicketPanel"
                            @showSideMenu="showSideMenu"
                            @toggleStatePanel="toggleStatePanel"
                            @togglePlayPanel="togglePlayPanel"
                            @goDepositWithdraw="goDepositWithdraw">
                    </GameVerticalTool>
                </div>
                <div @click="togglePanelBottom" class="btn-control-bottom"
                     :class="{'current': !is_show_panel_bottom}"></div>
            </div>
            <div v-if="noTopSection === 'yes'" id="playerDiv"></div>
            <div v-show="is_show_panel_bottom" class="bottom-stream" id="panelBottom">
                <div class="box-progressBar">
                    <div class="box-progressBar-info">
                        <div class="progress-bar-user progress-bar-player" :style="`width: ${bet_player_percent}%`">
                            <span v-show="bet_player_percent > 0">{{bet_player_percent | numberFormat}}%</span>
                        </div>
                        <div class="progress-bar-user progress-bar-player-2" :style="`width: ${bet_player_pair_percent}%`">
                            <span v-show="bet_player_pair_percent > 0">{{bet_player_pair_percent | numberFormat}}%</span>
                        </div>
                        <div class="progress-bar-user progress-bar-tie" :style="`width: ${bet_tie_percent}%`">
                            <span v-show="bet_tie_percent > 0">{{bet_tie_percent | numberFormat}}%</span>
                        </div>
                        <div class="progress-bar-user progress-bar-banker-2" :style="`width: ${bet_banker_pair_percent}%`">
                            <span v-show="bet_banker_pair_percent > 0">{{bet_banker_pair_percent | numberFormat}}%</span>
                        </div>
                        <div class="progress-bar-user progress-bar-banker" :style="`width: ${bet_banker_percent}%`">
                            <span v-show="bet_banker_percent > 0">{{bet_banker_percent | numberFormat}}%</span>
                        </div>
                    </div>
                </div>
                <FloatOpenCard 
                :isPanelOpen="isFloatOpenCardPanelOpen"
                :playerPointDisplay="playerPointDisplay"
                :bankerPointDisplay="bankerPointDisplay"
                :isPlayerWin="isPanelWin(GAME_RESULT.PLAYER_WIN)"
                :isBankerWin="isPanelWin(GAME_RESULT.BANKER_WIN)"
                :isTie="isPanelWin(GAME_RESULT.TIE)"
                :cards="gameInfoCards"
                :cardDataOpenDelays="dataOpenDelays"
                :warrantyHistory="warrantyHistory">
                </FloatOpenCard>
                <div v-show="orientation === 'portrait' || panel_to_show === 'play'" class="st-content-play">
                    <div class="content-panel-play content-panel-play-miniBacarat" id="contentBaccarat">
                        <!-- <div class="bg-winner-active" :class="{'hide': gameResult === GAME_RESULT.WAIT}" style="z-index: 8;">
                            <div v-show="gameResult === GAME_RESULT.BANKER_WIN" class="banker-win"><span></span></div>
                            <div v-show="gameResult === GAME_RESULT.BANKER_WIN && isBankerPair" class="banker-pair-win"><span></span></div>
                            <div v-show="gameResult === GAME_RESULT.TIE" class="tie-win"><span></span></div>
                            <div v-show="gameResult === GAME_RESULT.PLAYER_WIN" class="player-win"><span></span></div>
                            <div v-show="gameResult === GAME_RESULT.PLAYER_WIN && isPlayerPair" class="player-pair-win"><span></span></div>
                        </div> -->
                        <div class="box-panel-play">
                            <div class="left-panel-play">
                                <BaccaratPanelPlay
                                        ref="player-pair-block"
                                        customClass="btn-banker-pair"
                                        label="ผู้เล่นคู่"
                                        labelMulti="11:1"
                                        :user_choose="user_choose"
                                        choice="PLAYER_PAIR"
                                        :userBetArr="userBetArrPlayerPair"
                                        :amount="amount"
                                        :isPanelWin="isOptionalPanelWin(isPlayerPair)"
                                        :shipState="calOptionalPanelChipState('is_player_pair')"
                                        :isOnSendBet="isOnSendBet"
                                        @selectBet="selectBet"
                                        @confirmBet="confirmBet"
                                        @cancelBet="cancelBet">
                                    <FloatChip
                                            v-for="(bet, i) of getOtherBetListByChoose(USER_CHOOSE.PLAYER_PAIR)"
                                            :key="i"
                                            :value="bet.amount"
                                            :choose="bet.choose"
                                            :bound="getPanelPlayฺBound('player-pair-block')">
                                    </FloatChip>
                                </BaccaratPanelPlay>
                                <BaccaratPanelPlay
                                        ref="player-block"
                                        customClass="btn-banker"
                                        label="ผู้เล่น"
                                        labelMulti="1:1"
                                        :user_choose="user_choose"
                                        choice="PLAYER_WIN"
                                        :userBetArr="userBetArrPlayer"
                                        :amount="amount"
                                        :isPanelWin="isPanelWin(GAME_RESULT.PLAYER_WIN)"
                                        :shipState="calPanelChipState(GAME_RESULT.PLAYER_WIN)"
                                        :isOnSendBet="isOnSendBet"
                                        @selectBet="selectBet"
                                        @confirmBet="confirmBet"
                                        @cancelBet="cancelBet">
                                    <FloatChip
                                            v-for="(bet, i) of getOtherBetListByChoose(USER_CHOOSE.PLAYER_WIN)" :key="i"
                                            :value="bet.amount"
                                            :choose="bet.choose"
                                            :bound="getPanelPlayฺBound('player-block')">
                                    </FloatChip>
                                </BaccaratPanelPlay>
                            </div>
                            <div class="middle-panel-play">
                                <BaccaratPanelPlay
                                        ref="tie-block"
                                        customClass="btn-tie"
                                        label="เสมอ"
                                        labelMulti="8:1"
                                        :user_choose="user_choose"
                                        choice="TIE"
                                        :userBetArr="userBetArrTie"
                                        :amount="amount"
                                        :isPanelWin="isPanelWin(GAME_RESULT.TIE)"
                                        :shipState="calPanelChipState(GAME_RESULT.TIE)"
                                        :isOnSendBet="isOnSendBet"
                                        @selectBet="selectBet"
                                        @confirmBet="confirmBet"
                                        @cancelBet="cancelBet">
                                    <FloatChip
                                            v-for="(bet, i) of getOtherBetListByChoose(USER_CHOOSE.TIE)" :key="i"
                                            :value="bet.amount"
                                            :choose="bet.choose"
                                            :bound="getPanelPlayฺBound('tie-block')">
                                    </FloatChip>
                                </BaccaratPanelPlay>
                            </div>
                            <div class="right-panel-play">
                                <BaccaratPanelPlay
                                        ref="banker-pair-block"
                                        customClass="btn-player-pair"
                                        label="เจ้ามือคู่"
                                        labelMulti="11:1"
                                        :user_choose="user_choose"
                                        choice="BANKER_PAIR"
                                        :userBetArr="userBetArrBankerPair"
                                        :amount="amount"
                                        :isPanelWin="isOptionalPanelWin(isBankerPair)"
                                        :shipState="calOptionalPanelChipState('is_banker_pair')"
                                        :isOnSendBet="isOnSendBet"
                                        @selectBet="selectBet"
                                        @confirmBet="confirmBet"
                                        @cancelBet="cancelBet">
                                    <FloatChip
                                            v-for="(bet, i) of getOtherBetListByChoose(USER_CHOOSE.BANKER_PAIR)"
                                            :key="i"
                                            :value="bet.amount"
                                            :choose="bet.choose"
                                            :bound="getPanelPlayฺBound('banker-pair-block')">
                                    </FloatChip>
                                </BaccaratPanelPlay>
                                <BaccaratPanelPlay
                                        ref="banker-block"
                                        customClass="btn-player"
                                        label="เจ้ามือ"
                                        labelMulti="0.95:1"
                                        :user_choose="user_choose"
                                        choice="BANKER_WIN"
                                        :userBetArr="userBetArrBanker"
                                        :amount="amount"
                                        :isPanelWin="isPanelWin(GAME_RESULT.BANKER_WIN)"
                                        :shipState="calPanelChipState(GAME_RESULT.BANKER_WIN)"
                                        :isOnSendBet="isOnSendBet"
                                        @selectBet="selectBet"
                                        @confirmBet="confirmBet"
                                        @cancelBet="cancelBet">
                                    <FloatChip
                                            v-for="(bet, i) of getOtherBetListByChoose(USER_CHOOSE.BANKER_WIN)" :key="i"
                                            :value="bet.amount"
                                            :choose="bet.choose"
                                            :bound="getPanelPlayฺBound('banker-block')">
                                    </FloatChip>
                                </BaccaratPanelPlay>
                            </div>
                        </div>
                    </div>
                    <div class="box-panel-play-tool">
                        <div class="box-coin">
                            <GameChipForSelect
                                    v-for="(chip, i) of chipForSelectArr" :key="i"
                                    :value="chip"
                                    :active="amount === chip"
                                    @click="selectChip(chip)">
                            </GameChipForSelect>
                        </div>
                    </div>
                </div>
                <div v-if="orientation === 'landscape' && panel_to_show === 'state'" class="st-content-state">
                    <Stat :statArr="statArr" :betLimitConfig="currentBetLimitConfig"></Stat>
                </div>
            </div>
<!--            <div style="height: 4px;"></div>-->
            <div id="playerDiv"></div>
            <Stat v-if="orientation === 'portrait' && isShowStatPanel" :statArr="statArr" :betLimitConfig="currentBetLimitConfig"></Stat>
        </div>

        <Chat v-if="tableId && noChat !== 'yes'" :data_room_id="`_table_id=${tableId}`"></Chat>

        <modal name="result-modal" :height="'auto'" width="98%" classes="modal-background-transparent">
            <div class="flex-center-center">
                <div class="Aligner-item Aligner-item-result">
                    <Result :profitLoss="profitLoss"></Result>
                </div>
            </div>
        </modal>
<!--        <modal name="result-modal2" :height="'auto'" width="98%" classes="modal-background-transparent">-->
<!--            <div class="flex-center-center">-->
<!--                <div class="Aligner-item">-->
<!--                    <Result :profitLoss="0"></Result>-->
<!--                </div>-->
<!--            </div>-->
<!--        </modal>-->

<!--        -->
        <modal name="not-bet-modal" :height="'auto'" width="98%" classes="modal-background-transparent modalAlert" @opened="autoCloseModal('not-bet-modal')">
            <div class="flex-center-center">
                <div class="Aligner-item Aligner-item-alert">
                    <div class="Aligner-item-info">
                        รอบยังไม่เปิด
                        <br>
                        กรุณารอ
                    </div>
                </div>
            </div>
        </modal>

<!--        -->
        <modal name="bet-message-modal" :height="'auto'" width="98%" classes="modal-background-transparent modalAlert"
               @opened="autoCloseModal('bet-message-modal')">
            <div class="flex-center-center">
                <div class="Aligner-item" style="color: white;">
                    <div class="Aligner-item-info">
                        <p><i class="fas fa-exclamation-triangle"></i> {{betMessage}}</p>
                    </div>
                </div>
            </div>
        </modal>

<!--        -->
        <modal name="send-warranty-success-modal" :height="'auto'" width="98%" classes="modal-background-transparent modalAlert"
               @opened="autoCloseModal('send-warranty-success-modal')">
            <div class="flex-center-center">
                <div class="Aligner-item" style="color: white;">
                    <div class="Aligner-item-info">
                        <p>เดิมพันสำเร็จ</p>
                    </div>
                </div>
            </div>
        </modal>

        <modal name="select-bet-limit-modal" :height="'auto'" width="98%" class="editModalSelect"
               classes="modal-background-transparent"
               :clickToClose="false">
            <div class="flex-center-center">
                <div class="Aligner-item Aligner-item-select-value" style="color: white; text-align: center;">
                    <p><i class="fas fa-coins"></i> เลือกวงเงิน</p>
                    <button v-for="(betLimit, key) of betLimitArr" :key="key"
                            class="btn btn-cs" style="margin: 4px 0; width: 100%;"
                            @click="setBetLimit(betLimit.config_id)">
                        {{betLimit.min_bet}} - {{betLimit.max_bet}}
                    </button>
                </div>
            </div>
        </modal>

        <b-modal ref="error-message-modal"
                 hide-header
                 centered
                 ok-title="ตกลง"
                 cancel-title="รอ"
                 @ok="$router.replace({name: 'GameType'})">
            <div class="modal-notify-text-center">
                <h5>{{loadGameTableErrorMessage}}</h5>
            </div>
        </b-modal>

        <GoLobbyModal></GoLobbyModal>
    </div>
</template>
<script>

    import randomBetween from '@/lib/randomBetween'
    import GameMixin from '@/mixins/Game'
    import GameCard from '@/components/game/Card'
    import GameTableList from '@/components/game/TableList'
    import GameTopPlayer from '@/components/game/TopPlayer'
    import GameChipForSelect from '@/components/game/ChipForSelect'
    import {
        USER_CHOOSE, 
        GAME_STATUS_KEY, 
        GAME_RESULT_KEY, 
        CHIP_MAP, 
        USER_CHOOSE_PROBABILITES,
    } from '@/components/game/Constant'
    import {SOUND, soundLoad} from '@/components/game/baccarat/Constant'
    import BaccaratPanelPlay from '@/components/game/baccarat/PanelPlay'
    import Timer from '@/lib/Timer'
    import Stat from '@/components/game/baccarat/Stat'
    import GameHeader from '@/components/game/Header'
    import GameVerticalTool from '@/components/game/VerticalTool'
    import debounce from '@/debounce'
    import FloatChip from '@/components/game/FloatChip'
    import waitForSecond from '@/lib/waitForSecond'
    import Chat from '@/components/Chat'
    import TicketList from '@/components/game/TicketList'
    import Result from '@/components/game/Result'
    import VideoPlayer from './VideoPlayer'
    import UserBetChip from './UserBetChip'
    import GamePlay3D from './baccarat/GamePlay3D'
    import GamePlay3Dv2 from './baccarat/GamePlay3Dv2'
    import {mixin as VueTimers} from 'vue-timers'
    import axiosWrapper from '@/axiosWrapper'
    import SoundToggleButton from './SoundToggleButton.vue'
    import FloatButtomRightPanel from '@/components/FloatButtomRightPanel'
    import FloatOpenCard from '@/components/game/baccarat/FloatOpenCard'

    const SECOND_START = 30

    export default {
        name: 'MiniBaccarat',
        components: {
            FloatOpenCard,
            FloatButtomRightPanel,
            GameCard,
            GameTableList,
            GameTopPlayer,
            GameChipForSelect,
            BaccaratPanelPlay,
            Stat,
            GameHeader,
            GameVerticalTool,
            FloatChip,
            Chat,
            TicketList,
            Result,
            VideoPlayer,
            UserBetChip,
            GamePlay3D,
            GamePlay3Dv2,
            SoundToggleButton,
        },
        mixins: [
            GameMixin,
            VueTimers,
        ],
        timers: {
            _warantyCountdown: {
                time: 100,
                repeat: true
            },
        },
        data() {
            return {
                chip_arr: [5, 10, 50, 100, 500, 1000],
                amount: 10,
                user_choose: 0,
                otherBetChipArr: [],
                otherBetChooseArr: [],
                otherBetArr: [],
                otherBetTimer: null,
                totalAmount: 0,
                USER_CHOOSE: null,
                GAME_STATUS: null,
                GAME_RESULT: null,
                cardsFromAdd: [],
                timer: null,
                secondCountDown: this.secondStart,
                loading: null,
                playerPointDisplay: '-',
                bankerPointDisplay: '-',
                animationState: 'idle', // idle, showResult, clearCard
                customGameInfoCardPlayer0: null,
                customGameInfoCardPlayer1: null,
                customGameInfoCardPlayer2: null,
                customGameInfoCardBanker0: null,
                customGameInfoCardBanker1: null,
                customGameInfoCardBanker2: null,
                customGameResult: null,
                isOnWarranty: false,
                isClientEnterOnWarrantyHandled: false,
                isShowWarrantyModal: false,
                warrantyInfo: {
                    totalUserBet: 0,
                    side: -1,
                    mode: 'see', //see, play
                    min: 0,
                    max: 0,
                    multiplier: 0,
                    countdownSecondTotal: 20,
                    countdownSecond: 0,
                },
                warrantyForm: {
                    amount: 1,
                },
                warrantyAmountPercents : [
                    10,
                    20,
                    50,
                    100,
                ],
                warrantyAmountPercent: 100,
                warrantyHistory: {
                    0: [
                        {
                            multiplier: 0,
                            myBetAmount: 0,
                        },
                        {
                            multiplier: 0,
                            myBetAmount: 0,
                        },
                        {
                            multiplier: 0,
                            myBetAmount: 0,
                        },
                    ],
                    1: [
                        {
                            multiplier: 0,
                            myBetAmount: 0,
                        },
                        {
                            multiplier: 0,
                            myBetAmount: 0,
                        },
                        {
                            multiplier: 0,
                            myBetAmount: 0,
                        },
                    ],
                },
                warrantyOrder: -1,
                isShowStatPanel: false,
                randomPercentMap: {
                    1: 46,
                    2: 46,
                    3: 4,
                    5: 2,
                    6: 2,
                },
            }
        },
        computed: {
            secondStart() {
                if((this.version === 2 || this.version === 3)) {
                    return 20
                } else {
                    return SECOND_START
                }
            },
            isPlayerPair() {
                return (this.currentGame) ? this.currentGame.is_player_pair === 1 : false
            },
            isBankerPair() {
                return (this.currentGame) ? this.currentGame.is_banker_pair === 1 : false
            },
            secondRemain() {
                const now = this.$moment()
                if (!this.countDownStart) {
                    return this.secondStart

                }
                const timestamp = this.$moment(this.countDownStart)
                const remain = parseInt(this.$moment.duration(now.diff(timestamp)).asSeconds())
                return (this.secondStart - remain > 0) ? this.secondStart - remain : 0
            },
            cards() {
                return this.cardsFromAdd
            },
            playerPoint: function () {
                if (!this.gameInfoCardPlayer0 && !this.gameInfoCardPlayer1 && !this.gameInfoCardPlayer2) {
                    return '-'
                }
                const card1Number = this.getCardNumber(this.gameInfoCardPlayer0)
                const card2Number = this.getCardNumber(this.gameInfoCardPlayer1)
                const card3Number = this.getCardNumber(this.gameInfoCardPlayer2)

                return (this.calCardPoint(card1Number) + this.calCardPoint(card2Number) + this.calCardPoint(card3Number)) % 10
            },
            bankerPoint: function () {
                if (!this.gameInfoCardBanker0 && !this.gameInfoCardBanker1 && !this.gameInfoCardBanker2) {
                    return '-'
                }
                const card1Number = this.getCardNumber(this.gameInfoCardBanker0)
                const card2Number = this.getCardNumber(this.gameInfoCardBanker1)
                const card3Number = this.getCardNumber(this.gameInfoCardBanker2)

                return (this.calCardPoint(card1Number) + this.calCardPoint(card2Number) + this.calCardPoint(card3Number)) % 10
            },
            gameInfoCardPlayer0() {
                const infoCard = this.getInfoCard(0, 0)
                return infoCard || this.customGameInfoCardPlayer0
            },
            gameInfoCardPlayer1() {
                const infoCard = this.getInfoCard(0, 1)
                return infoCard || this.customGameInfoCardPlayer1
            },
            gameInfoCardPlayer2() {
                const infoCard = this.getInfoCard(0, 2)
                return infoCard || this.customGameInfoCardPlayer2
            },
            gameInfoCardBanker0() {
                const infoCard = this.getInfoCard(1, 0)
                return infoCard || this.customGameInfoCardBanker0
            },
            gameInfoCardBanker1() {
                const infoCard = this.getInfoCard(1, 1)
                return infoCard || this.customGameInfoCardBanker1
            },
            gameInfoCardBanker2() {
                const infoCard = this.getInfoCard(1, 2)
                return infoCard || this.customGameInfoCardBanker2
            },
            gameInfoCards() {
                return {
                    gameInfoCardPlayer0: this.gameInfoCardPlayer0,
                    gameInfoCardPlayer1: this.gameInfoCardPlayer1,
                    gameInfoCardPlayer2: this.gameInfoCardPlayer2,
                    gameInfoCardBanker0: this.gameInfoCardBanker0,
                    gameInfoCardBanker1: this.gameInfoCardBanker1,
                    gameInfoCardBanker2: this.gameInfoCardBanker2,
                }
            },
            userBetArrPlayerPair() {
                return this.filterUserBetArrByUserChoose(USER_CHOOSE.PLAYER_PAIR)
            },
            userBetArrPlayer() {
                return this.filterUserBetArrByUserChoose(USER_CHOOSE.PLAYER_WIN)
            },
            userBetArrTie() {
                return this.filterUserBetArrByUserChoose(USER_CHOOSE.TIE)
            },
            userBetArrBankerPair() {
                return this.filterUserBetArrByUserChoose(USER_CHOOSE.BANKER_PAIR)
            },
            userBetArrBanker() {
                return this.filterUserBetArrByUserChoose(USER_CHOOSE.BANKER_WIN)
            },
            dataOpenCardDelay() {
                if(this.version === 3) {
                    return 0
                } 
                if(this.liveMode === '3d') {
                    return 8000
                } else {
                    return 400
                }
            },
            dataOpenJuaCardDelay() {
                if(this.liveMode === '3d') {
                    return 3000
                } else {
                    return 0
                }
            },
            dataOpenDelays() {
                return {
                    cardPlayer0: this.dataOpenCardDelay + this.dataOpenJuaCardDelay,
                    cardPlayer1: this.dataOpenCardDelay,
                    cardPlayer2: this.dataOpenCardDelay,
                    cardBanker0: this.dataOpenCardDelay,
                    cardBanker1: this.dataOpenCardDelay,
                    cardBanker2: this.dataOpenCardDelay + this.dataOpenJuaCardDelay,
                }
            },
            warrantyDisplayCountdownPercent() {
                return (parseInt(this.warrantyInfo.countdownSecond) / parseInt(this.warrantyInfo.countdownSecondTotal)) * 100
            },
            warrantyDisplayCountdownSecond() {
                return parseInt(parseInt(this.warrantyInfo.countdownSecond) / 10)
            },
            userBetConfirms() {
                return this.userBetArr.filter((userBet) => {
                    return userBet.state === 'confirm'
                })
            },
            userBetSide() {
                if(this.userBetConfirms.length === 0) {
                    return -1
                }
                const userBetConfirm = this.userBetConfirms.find((userBetConfirm) => {
                    return userBetConfirm.user_choose === USER_CHOOSE.PLAYER_WIN || userBetConfirm.user_choose === USER_CHOOSE.BANKER_WIN
                })
                if(!userBetConfirm) {
                    return -1
                }
                const user_choose = userBetConfirm.user_choose
                if(user_choose === USER_CHOOSE.PLAYER_WIN) {
                    return 0
                } else if(user_choose === USER_CHOOSE.BANKER_WIN) {
                    return 1
                } else {
                    return -1
                }
            },
            warrantyUserTotalBet() {

                let user_choose = -1
                if(this.userBetSide === 0) {
                    user_choose = USER_CHOOSE.PLAYER_WIN
                } else if(this.userBetSide === 1) {
                    user_choose = USER_CHOOSE.BANKER_WIN
                }

                const betOnWarrantySides = this.userBetConfirms.filter((userBetConfirm) => {
                    return userBetConfirm.user_choose === user_choose
                })

                return betOnWarrantySides.reduce((sum, bet) => {
                    return parseInt(sum) + parseInt(bet.amount)
                }, 0)
            },
            userChooseWarranty() {
                if(this.userBetSide === 0) {
                    const userWarrantyOrders = [
                        USER_CHOOSE.PLAYER0_WARRANTY,
                        USER_CHOOSE.PLAYER_WARRANTY,
                        USER_CHOOSE.PLAYER2_WARRANTY,
                    ]
                    return userWarrantyOrders[this.warrantyOrder]
                } else if(this.userBetSide === 1) {
                    const userWarrantyOrders = [
                        USER_CHOOSE.BANKER0_WARRANTY,
                        USER_CHOOSE.BANKER_WARRANTY,
                        USER_CHOOSE.BANKER2_WARRANTY,
                    ]
                    return userWarrantyOrders[this.warrantyOrder]
                } else {
                    return -1
                }
            },
            userBetSidePoint() {
                if(this.userBetSide === 0) {
                    return parseInt(this.playerPointDisplay)
                } else if(this.userBetSide === 1) {
                    return parseInt(this.bankerPointDisplay)
                } else {    
                    return 0
                }
            },
            isFloatOpenCardPanelOpen() {
                if(parseInt(this.gameStatus) === GAME_STATUS_KEY.DEALING || parseInt(this.gameStatus) === GAME_STATUS_KEY.FINISH) {
                    return true
                } else if(parseInt(this.gameStatus) === GAME_STATUS_KEY.COUNTDOWN && this.isOnWarranty) {
                    return true
                } else {
                    return false
                }
            },
            bet_banker() {
                return (this.currentGame) ? this.currentGame.bet_banker : 0
            },
            bet_player() {
                return (this.currentGame) ? this.currentGame.bet_player : 0
            },
            bet_banker_pair() {
                return (this.currentGame) ? this.currentGame.bet_banker_pair : 0
            },
            bet_player_pair() {
                return (this.currentGame) ? this.currentGame.bet_player_pair : 0
            },
            bet_tie() {
                return (this.currentGame) ? this.currentGame.bet_tie : 0
            },
            bet_sum() {
                return (this.currentGame) ? this.currentGame.bet_sum : 0
            },
            bet_banker_percent() {
                if(!this.bet_sum) {
                    return this.randomPercentMap[USER_CHOOSE.BANKER_WIN]
                }
                return (this.bet_banker) ? (parseFloat(this.bet_banker) / parseFloat(this.bet_sum)) * 100 : 0
            },
            bet_player_percent() {
                if(!this.bet_sum) {
                    return this.randomPercentMap[USER_CHOOSE.PLAYER_WIN]
                }
                return (this.bet_player) ? (parseFloat(this.bet_player) / parseFloat(this.bet_sum)) * 100 : 0
            },
            bet_banker_pair_percent() {
                if(!this.bet_sum) {
                    return this.randomPercentMap[USER_CHOOSE.BANKER_PAIR]
                }
                return (this.bet_banker_pair) ? (parseFloat(this.bet_banker_pair) / parseFloat(this.bet_sum)) * 100 : 0
            },
            bet_player_pair_percent() {
                if(!this.bet_sum) {
                    return this.randomPercentMap[USER_CHOOSE.PLAYER_PAIR]
                }
                return (this.bet_player_pair) ? (parseFloat(this.bet_player_pair) / parseFloat(this.bet_sum)) * 100 : 0
            },
            bet_tie_percent() {
                if(!this.bet_sum) {
                    return this.randomPercentMap[USER_CHOOSE.TIE]
                }
                return (this.bet_tie) ? (parseFloat(this.bet_tie) / parseFloat(this.bet_sum)) * 100 : 0
            },
        },
        watch: {
            gameStatus(newVal, oldVal) {
                if (this.GAME_STATUS.DEALING === parseInt(newVal)) {
                    this.playStopBetSound()
                    this.cancelBet()
                }
                if (parseInt(oldVal) === this.GAME_STATUS.DEALING && parseInt(newVal) === this.GAME_STATUS.FINISH) {//end round

                    const playerPointSound = SOUND['POINT']['PLAYER'][parseInt(this.playerPoint)]
                    const bankerPointSound = SOUND['POINT']['BANKER'][parseInt(this.bankerPoint)]
                    const resultSound = SOUND['RESULT'][parseInt(this.gameResult)]

                    this.playGameSound(playerPointSound)
                    setTimeout(() => {
                        this.playGameSound(bankerPointSound)
                    }, 1300)
                    setTimeout(() => {
                        this.playGameSound(resultSound)
                    }, 1300 + 1300)

                    setTimeout(() => {

                        if(this.liveMode === 'video') {
                            this.clearUserBetArr()
                            this.clearBet()
                            this.clearOtherBet()
                        }

                        this.onRoundEnd()
                    }, 2 * 1000)
                }
                if (parseInt(newVal) === this.GAME_STATUS.COUNTDOWN) {
                    if(!this.isOnWarranty) {
                        this.clearCardsFromAdd()
                        this.generateRandomBetPercent()
                    }
                    this.startCountDown()
                    this.startOtherBet()
                    if(this.liveMode === 'video') {
                        this.clearCustomData()
                    }
                    if(!this.isOnWarranty) {
                        setTimeout(() => {
                            this.playStartBetSound()
                        }, 2000)
                        this._clearWarrantyHistory()
                    }
                } else {
                    this.stopCountDown()
                    this.stopOtherBet()
                }
                if(parseInt(newVal) === GAME_STATUS_KEY.SHUFFLING) {
                    this.clearCustomData()
                }
                if(parseInt(oldVal) === GAME_STATUS_KEY.COUNTDOWN && newVal && this.isOnWarranty) {
                    this.$timer.stop('_warantyCountdown')
                    this.closeWarrantyModal()
                    this.isOnWarranty = false
                }
            },
            playerPoint(newVal) {
                if (newVal === '-') {
                    this.playerPointDisplay = newVal
                } else {
                    let delaySecond = 400 + this.dataOpenCardDelay
                    if(this.getCardNumber(this.gameInfoCardPlayer0)) {
                        delaySecond += this.dataOpenJuaCardDelay
                    }
                    setTimeout(() => {
                        if(this.playerPoint === '-') {
                            return
                        }
                        this.playerPointDisplay = newVal
                    }, delaySecond)
                }
            },
            bankerPoint(newVal) {
                if (newVal === '-') {
                    this.bankerPointDisplay = newVal
                } else {
                    let delaySecond = 400 + this.dataOpenCardDelay
                    if(this.getCardNumber(this.gameInfoCardBanker2)) {
                        delaySecond += this.dataOpenJuaCardDelay
                    }
                    setTimeout(() => {
                        if(this.bankerPoint === '-') {
                            return
                        }
                        this.bankerPointDisplay = newVal
                    }, delaySecond)
                }
            },
            secondRemain(newVal, oldVal) {
                if (oldVal === 0 && newVal > 0) {
                    this.stopCountDown()
                    this.startCountDown()
                    if (this.gameStatus === this.GAME_STATUS.COUNTDOWN) {
                        this.stopOtherBet()
                        this.startOtherBet()
                    }
                }
            },
            gameInfoCardPlayer0(newVal) {
                if(newVal) {
                    this.customGameInfoCardPlayer0 = newVal
                }
            },
            gameInfoCardPlayer1(newVal) {
                if(newVal) {
                    this.customGameInfoCardPlayer1 = newVal
                }
            },
            gameInfoCardPlayer2(newVal) {
                if(newVal) {
                    this.customGameInfoCardPlayer2 = newVal
                }
            },
            gameInfoCardBanker0(newVal) {
                if(newVal) {
                    this.customGameInfoCardBanker0 = newVal
                }
            },
            gameInfoCardBanker1(newVal) {
                if(newVal) {
                    this.customGameInfoCardBanker1 = newVal
                }
            },
            gameInfoCardBanker2(newVal) {
                if(newVal) {
                    this.customGameInfoCardBanker2 = newVal
                }
            },
            gameResult(newVal) {
                if(newVal) {
                    this.customGameResult = newVal
                }
            },
            warrantyAmountPercent(newVal) {
                if(newVal) {
                    this._calculateWarrantFormAmount()
                }
            },
            orientation(newVal) {
                if(newVal === 'portrait') {
                    this.isShowStatPanel = true
                }
            },
        },
        methods: {

            getInfoCard(side, index) {
                if (this.cards) {
                    return this.cards.find((card) => {
                        return card.side === side && card.index === index
                    })
                } else {
                    return null
                }
            },
            getCardNumber(card) {
                if (!card || !card.card_number) {
                    return 0
                }
                return parseInt(card.card_number)
            },
            calCardPoint(cardNumber) {
                if (parseInt(cardNumber) >= 10) {
                    return 0
                }
                return parseInt(cardNumber)
            },
            filterUserBetArrByUserChoose(user_choose) {
                return this.userBetArr.filter((bet) => {
                    return bet.user_choose === user_choose
                })
            },
            onAddCard(data) {
                try {
                    if (!this.currentGame || !this.currentTable) {
                        return
                    }
                    if (parseInt(data.gbs_id) === parseInt(this.currentGame.gbs_id) &&
                        parseInt(data.game_index) === parseInt(this.currentTable.table_type)) {
                        this.cardPush(data.card)
                    }
                } catch (err) {
                    console.log('onAddCard err', err.message)
                }
            },
            cardPush(card) {
                const i = this.cardsFromAdd.findIndex((existCard) => {
                    return parseInt(existCard.side) === parseInt(card.side) && parseInt(existCard.index) === parseInt(card.index)
                })
                if(i > -1) {
                    this.cardsFromAdd.splice(i, 1, card)
                } else {
                    this.cardsFromAdd.push(card)
                }
            },
            clearCardsFromAdd() {
                this.cardsFromAdd = []
            },
            confirmBet() {
                this._confirmBet()
            },
            showLoadTableGameError() {
                this.$refs['error-message-modal'].show()
            },
            getPanelPlayฺBound(ref) {
                const width = this.$refs[ref].$el.offsetWidth
                const height = this.$refs[ref].$el.offsetHeight
                return {width, height}
            },
            getOtherBetListByChoose(choose) {
                return this.otherBetArr.filter((bet) => {
                    return parseInt(bet.choose) === parseInt(choose)
                })
            },
            otherBet() {
                // this.otherBetArr.push({
                //     choose: this.otherBetChooseArr[randomBetween(0, this.otherBetChooseArr.length - 1)],
                //     amount: this.otherBetChipArr[randomBetween(0, this.otherBetChipArr.length - 1)],
                // })
            },
            startOtherBet: debounce(async function () {

                if(this.liveMode === '3d' && this.animationState !== 'clearCard') {
                    return
                }
                if(this.isOnWarranty) {
                    return
                }

                await waitForSecond(2000)

                if (this.otherBetTimer) {
                    this.otherBetTimer.start()
                    return
                }
                this.otherBetTimer = Timer(() => {
                    this.otherBet()
                    let nextTimeout = 1000
                    if (parseInt(this.secondCountDown) > 0) {
                        nextTimeout = 33 * this.secondCountDown
                    }
                    return nextTimeout + randomBetween(0, 44)
                }, 1000)
            }, 100),
            stopOtherBet: debounce(function () {
                if (!this.otherBetTimer) {
                    return
                }
                this.otherBetTimer.stop()
            }, 100),
            clearOtherBet() {
                this.otherBetArr = []
            },
            isPanelWin(panel) {
                if(this.liveMode === '3d') {
                    return this.animationState === 'showResult' && panel === this.customGameResult
                } else {
                    return panel === this.customGameResult
                }
            },
            isOptionalPanelWin(value) {
                if(this.liveMode === '3d') {
                    return this.animationState === 'showResult' && value
                } else {
                    return !!value
                }
            },
            calPanelChipState(panel) {
                if(this.liveMode === '3d' && this.animationState !== 'showResult') {
                    return 'playerPlace'
                }
                if (!this.customGameResult) {
                    return 'playerPlace'
                }
                if (GAME_RESULT_KEY.TIE === this.customGameResult) {
                    return 'playerPlace'
                } else if (panel === this.customGameResult) {
                    return 'goPlayer'
                } else {
                    return 'goBanker'
                }
            },
            calOptionalPanelChipState(option) {

                if(this.liveMode === '3d' && this.animationState !== 'showResult') {
                    return 'playerPlace'
                }
                if (!this.customGameResult) {
                    return 'playerPlace'
                }
                if (!this.currentGame) {
                    return 'playerPlace'
                }
                if (this.currentGame[option]) {
                    return 'goPlayer'
                } else {
                    return 'goBanker'
                }
            },
            async animationStateChangeHandle(state) {
                this.animationState = state
                if(state === 'showResult') {
                    if(this.is_member && this.userBetArr.length > 0){
                        this.showBetResult()
                        this.calExtraPayArr(this.customExtraPayData)
                    }
                }else if (state === 'clearCard') {
                    this.clearUserBetArr()
                    this.clearBet()
                    this.clearCustomData()
                    this.clearOtherBet()

                    await waitForSecond(2000)

                    this.startOtherBet()
                }
            },
            clearCustomData() {
                this.clearCustomCard()
                this.clearCustomGameResult()
                this.customExtraPayData = null
            },
            clearCustomCard() {
                this.customGameInfoCardPlayer0 = null
                this.customGameInfoCardPlayer1 = null
                this.customGameInfoCardPlayer2 = null
                this.customGameInfoCardBanker0 = null
                this.customGameInfoCardBanker1 = null
                this.customGameInfoCardBanker2 = null
            },
            clearCustomGameResult() {
                this.customGameResult = null
            },
            _onAddCardCountDownHandle(data) {
                
                const {
                    gbs_id,
                    game_index,
                    countdown_datetime,
                    countdown1_second,
                    countdown2_second, 
                    countdown3_second, 
                    table_game_status,
                    baccarat_warranty_side, 
                    baccarat_warranty_payout,
                    baccarat0_warranty_side,
                    baccarat0_warranty_payout,
                    baccarat2_warranty_side,
                    baccarat2_warranty_payout,
                } = data

                if (!this.currentGame || !this.currentTable) {
                    return
                }
                if (!( parseInt(gbs_id) === parseInt(this.currentGame.gbs_id) && parseInt(game_index) === parseInt(this.currentTable.table_type)) ) {
                    return
                }

                // this.$json('_onAddCardCountDownHandle card', data.card)

                const warrantyHistoryMap = {
                    baccarat_warranty_side, 
                    baccarat_warranty_payout,
                    baccarat0_warranty_side,
                    baccarat0_warranty_payout,
                    baccarat2_warranty_side,
                    baccarat2_warranty_payout,
                }

                this._updateWarrantyHistory(warrantyHistoryMap)

                this.cardPush(data.card)

                this.currentTable.table_game_status = table_game_status

                this.warrantyInfo.totalUserBet = this.warrantyUserTotalBet
                this.warrantyInfo.side = this.userBetSide

                const getWarrantySide = (baccarat2_warranty_side, baccarat_warranty_side, baccarat0_warranty_side) => {
                    if(parseInt(baccarat2_warranty_side) >= 0) {
                        this.warrantyOrder = 2
                        return baccarat2_warranty_side
                    }
                    if(parseInt(baccarat_warranty_side) >= 0) {
                        this.warrantyOrder = 1
                        return baccarat_warranty_side
                    }
                    if(parseInt(baccarat0_warranty_side) >= 0) {
                        this.warrantyOrder = 0
                        return baccarat0_warranty_side
                    }
                }

                const warrantySide = getWarrantySide(baccarat2_warranty_side, baccarat_warranty_side, baccarat0_warranty_side)
                if(warrantySide === this.warrantyInfo.side) {
                    this.warrantyInfo.mode = 'play'
                } else {
                    this.warrantyInfo.mode = 'see'
                }
                this.warrantyInfo.multiplier = baccarat2_warranty_payout || baccarat_warranty_payout || baccarat0_warranty_payout
                this.warrantyInfo.min = 1
                this.warrantyInfo.max = this._calculateWarrantyMax(warrantySide, this.warrantyInfo.totalUserBet, this.warrantyInfo.multiplier)
                this.warrantyAmountPercent = 100
                this._calculateWarrantFormAmount()

                this._calculateWarrantyCountdown(countdown_datetime, countdown1_second, countdown2_second, countdown3_second)

                this.isOnWarranty = true
                this.openWarrantyModal()

                this.$timer.start('_warantyCountdown')
            },
            _calculateWarrantyMax(warrantySide, totalUserBet, multiplier) {

                try {

                    const LIMIT_ALL_PERCENT = 120
                    const LIMIT_EACH_PERCENT = 100

                    const alreadyWarrantyPayout = parseFloat(this.warrantyHistory[warrantySide].reduce((sum, item) => {
                        return parseInt(sum) + ((parseInt(item.myBetAmount) * parseFloat(item.multiplier)))
                    }, 0))

                    if(this.isOnDev) {
                        console.log('alreadyWarrantyPayout', alreadyWarrantyPayout)    
                    }

                    const limitAll = parseInt((LIMIT_ALL_PERCENT/100) * parseInt(totalUserBet))
                    const limitEach = parseInt((LIMIT_EACH_PERCENT/100) * parseInt(totalUserBet))
                    const maxAll = parseInt((limitAll - alreadyWarrantyPayout) / parseFloat(multiplier))
                    const maxEach = parseInt(limitEach / parseFloat(multiplier))

                    if(this.isOnDev) {
                        console.log(`maxAll: ${maxAll}, maxEach: ${maxEach}`)    
                    }
                    
                    return (maxAll >= 0) ? Math.min(maxAll, maxEach) : maxEach

                } catch(err) {
                    console.log('_calculateWarrantyMax err', err.message)
                    return 0
                }
            },
            _warantyCountdown() {
                if(this.warrantyInfo.countdownSecond <= 0) {
                    this.$timer.stop('_warantyCountdown')
                    this.isOnWarranty = false
                    return
                }
                this.warrantyInfo.countdownSecond--
                if(this.warrantyInfo.countdownSecond <= 0) {
                    this.$timer.stop('_warantyCountdown')
                    this.closeWarrantyModal()
                    this.isOnWarranty = false
                }
            },
            sendWarrantyBet: debounce(async function() {
                
                const loading = this.$loading()

                const res = await axiosWrapper({
                method: 'post',
                    url: `${this.envDomains.VUE_APP_API}/api/game/baccarat/bet`,
                    data: {
                        amount: this.warrantyForm.amount,
                        game: this.currentTable.table_type,
                        gbs_id: this.currentGame.gbs_id,
                        token: this.pes_profile.token,
                        user_choose: this.userChooseWarranty,
                    },
                })

                loading.close()

                if(res.data.code === 1) {
                    this.addTicketList(res.data.data)
                    this.closeWarrantyModal()
                    this.$modal.show('send-warranty-success-modal')
                    this._updateWarrantyHistoryMyBetAmount(this.userChooseWarranty, this.warrantyOrder, this.warrantyForm.amount)
                } else {
                    this.betMessage = res.data.msg
                    this.$modal.show('bet-message-modal')
                }
            }, 400),
            openWarrantyModal() {
                this.isShowWarrantyModal = true
            },
            closeWarrantyModal() {
                this.isShowWarrantyModal = false
            },
            _clientEnterOnWarranty(data) {

                if(this.isClientEnterOnWarrantyHandled) {
                    return
                }

                const {
                    countdown_datetime,
                    countdown1_second,
                    countdown2_second,
                    countdown3_second,
                } = data

                const secondRemain = this._calculateWarrantyCountdown(countdown_datetime, countdown1_second, countdown2_second, countdown3_second)

                this.warrantyInfo.mode = 'see'

                if (this.gameStatus !== this.GAME_STATUS.COUNTDOWN || secondRemain <= 0) {
                    return
                }

                this.isOnWarranty = true
                this.isClientEnterOnWarrantyHandled = true
                this.openWarrantyModal()

                this.$timer.start('_warantyCountdown')
            },
            _calculateWarrantyCountdown(countdown_datetime, countdown1_second, countdown2_second, countdown3_second) {

                let countdown_second = 0
                if(countdown3_second) {
                    countdown_second = countdown3_second
                } else if(countdown2_second) {
                    countdown_second = countdown2_second
                } else if (countdown1_second) {
                    countdown_second = countdown1_second
                }

                this.warrantyInfo.countdownSecondTotal = countdown_second * 10
                const now = this.$moment()
                const countdownDatetimeTimestamp = this.$moment(countdown_datetime)
                const secondDiff = parseInt(this.$moment.duration(now.diff(countdownDatetimeTimestamp)).asSeconds())
                const secondRemain = countdown_second - secondDiff
                this.warrantyInfo.countdownSecond = secondRemain * 10

                return secondRemain
            },
            _calculateWarrantFormAmount() {
                if(!this.warrantyInfo.max) {
                    this.warrantyForm.amount = 0
                }
                this.warrantyForm.amount = Math.ceil((this.warrantyAmountPercent / 100) * this.warrantyInfo.max)
            },
            _updateWarrantyHistory(map) {

                try {

                    const {
                        baccarat_warranty_side, 
                        baccarat_warranty_payout,
                        baccarat0_warranty_side,
                        baccarat0_warranty_payout,
                        baccarat2_warranty_side,
                        baccarat2_warranty_payout,
                    } = map
                    
                    if(parseInt(baccarat2_warranty_side) >= 0) {
                        this.warrantyHistory[baccarat2_warranty_side][2]['multiplier'] = baccarat2_warranty_payout
                    }
                    if(parseInt(baccarat_warranty_side) >= 0) {
                        this.warrantyHistory[baccarat_warranty_side][1]['multiplier'] = baccarat_warranty_payout
                    }
                    if(parseInt(baccarat0_warranty_side) >= 0) {
                        this.warrantyHistory[baccarat0_warranty_side][0]['multiplier'] = baccarat0_warranty_payout
                    }
                    this._saveWarrantyHistory()

                } catch (err) {
                    console.log('_updateWarrantyHistory err', err.message)
                }
            },
            _updateWarrantyHistoryMyBetAmount(userChooseWarranty, warrantyOrder, amount) {
                const sideMap = {}
                sideMap[USER_CHOOSE.PLAYER0_WARRANTY] = 0
                sideMap[USER_CHOOSE.PLAYER_WARRANTY] = 0
                sideMap[USER_CHOOSE.PLAYER2_WARRANTY] = 0
                sideMap[USER_CHOOSE.BANKER0_WARRANTY] = 1
                sideMap[USER_CHOOSE.BANKER_WARRANTY] = 1
                sideMap[USER_CHOOSE.BANKER2_WARRANTY] = 1
                const side = sideMap[userChooseWarranty]
                this.warrantyHistory[side][warrantyOrder]['myBetAmount'] = amount
                this._saveWarrantyHistory()
            },
            _clearWarrantyHistory() {
                this.warrantyHistory = {
                    0: [
                        {
                            multiplier: 0,
                            myBetAmount: 0,
                        },
                        {
                            multiplier: 0,
                            myBetAmount: 0,
                        },
                        {
                            multiplier: 0,
                            myBetAmount: 0,
                        },
                    ],
                    1: [
                        {
                            multiplier: 0,
                            myBetAmount: 0,
                        },
                        {
                            multiplier: 0,
                            myBetAmount: 0,
                        },
                        {
                            multiplier: 0,
                            myBetAmount: 0,
                        },
                    ],
                }
                this._saveWarrantyHistory()
            },
            testModal() {
                // this.betMessage = 'test'
                // this.$modal.show('bet-message-modal')
                this.$modal.show('send-warranty-success-modal')
                // this.$modal.show('not-bet-modal')
            },
            _saveWarrantyHistory() {
                if(!this.currentGame) {
                    return
                }
                const dataToSave = JSON.stringify({
                    gbs_id: this.currentGame.gbs_id,
                    data: this.warrantyHistory,
                })
                localStorage.setItem(`warrantyHistory?table_id=${this.tableId}`, dataToSave)
            },
            _loadWarrantyHistory() {
                try {
                    if(this.isAlreadyLoadWarrantyHistory) {
                        return
                    }
                    this.isAlreadyLoadWarrantyHistory = true
                    const warrantyHistory = JSON.parse(localStorage.getItem(`warrantyHistory?table_id=${this.tableId}`))
                    if(!warrantyHistory) {
                        return
                    }
                    if(this.isOnDev) {
                        console.log(`_loadWarrantyHistory ${this.currentGame.gbs_id} === ${warrantyHistory.gbs_id}`)
                    }
                    if(parseInt(this.currentGame.gbs_id) !== parseInt(warrantyHistory.gbs_id)) {
                        return
                    }
                    this.warrantyHistory = warrantyHistory.data
                } catch(err) {
                    console.log('loadWarrantyHistory err', err.message)
                }
            },
            _loadCards() {
                if(this.isAlreadyLoadCards) {
                    return
                }
                if(!this.gameInfo) {
                    return
                }
                if(!this.gameInfo.card) {
                    return
                }
                for(const card of this.gameInfo.card) {
                    if(card.card_number){
                        this.cardPush(card)
                    }
                }
                this.isAlreadyLoadCards = true
                if(this.playerPoint !== '-') {
                    this.playerPointDisplay = this.playerPoint
                }
                if(this.bankerPoint !== '-') {
                    this.bankerPointDisplay = this.bankerPoint
                }
            },
            toggleStatePanel() {
                if(this.orientation === 'portrait') {
                    this.isShowStatPanel = !this.isShowStatPanel
                } else {
                    this.panel_to_show = 'state'
                }
                this.clearOtherBet()
            },
            generateRandomBetPercent() {
                let percentRemain = 100
                const percentsConfig = [
                    {
                        slug: 2,
                        min: 30,
                        max: 80,
                    },
                    {
                        slug: 3,
                        min: 0,
                        max: 4,
                    },
                    {
                        slug: 5,
                        min: 0,
                        max: 2,
                    },
                    {
                        slug: 6,
                        min: 0,
                        max: 2,
                    },
                    {
                        slug: 1,
                        min: 30,
                        max: 50,
                    },
                ]
                for(const percentConfig of percentsConfig) {
                    if(percentConfig.slug === 1) {
                        this.randomPercentMap[percentConfig.slug] = percentRemain
                    } else {
                        const percent = randomBetween(percentConfig.min, percentConfig.max)
                        const percentLimit = Math.min(percentRemain, percent)
                        this.randomPercentMap[percentConfig.slug] = percentLimit
                        percentRemain -= percentLimit
                    }
                }
            },
        },
        created() {
            this.USER_CHOOSE = USER_CHOOSE
            this.GAME_STATUS = GAME_STATUS_KEY
            this.GAME_RESULT = GAME_RESULT_KEY

            for (const key in CHIP_MAP) {
                this.otherBetChipArr.push(key)
            }
            for (const key in USER_CHOOSE) {

                const userChoose = USER_CHOOSE[key]

                const probabilites = USER_CHOOSE_PROBABILITES[userChoose]

                for(let i = 0;i < probabilites;i++) {
                    this.otherBetChooseArr.push(userChoose)
                }
            }
        },
        async mounted() {
            // this.$modal.show('result-modal2')
            if (this.gameStatus === this.GAME_STATUS.COUNTDOWN) {
                this.startCountDown()
                this.startOtherBet()
            }
            this.amount = this.chipForSelectArr[0]
            this._calculateWarrantFormAmount()
            soundLoad()
            if(this.orientation === 'portrait') {
                this.isShowStatPanel = true
            }
            this.generateRandomBetPercent()
        },
        beforeDestroy() {
            this.stopCountDown()
            this.stopOtherBet()
        }
    }

</script>

<style scoped src="@/assets/css/baccarat-style.css"></style>
<style scoped src="@/assets/css/animate-games.css"></style>

<style>

    @media (orientation: landscape) {
        .LiffGameHome .box-panel-play{
            height: 100%;
        }
        .LiffGameHome  #bankerDiv {
            width: 50px;
            height: 50px;
            position: absolute;
            margin: auto;
            right: 0;
            bottom: 100px;
            left: 0;
            z-index: 2;
        }
        .LiffGameHome #playerDiv {
            width: 5vw;
            height: 5vw;
            position: fixed;
            bottom: 0;
            left: 0;
            right: 0;
            margin: 0 auto;
        }
        .LiffGameHome .MiniBaccarat .bottom-stream{
            position: relative;
            height: 100%;
        }
        .LiffGameHome .content-panel-play {
            display: block;
            width: auto;
            height: 100%;
            padding-bottom: 50px;
        }
        .LiffGameHome .content-panel-play-miniBacarat,
        .LiffGameHome .content-panel-play-DragonTiger{
            position: relative!important;
        }
        .LiffGameHome .box-panel-play-tool{
            position: absolute!important;
            right: 0;
            left: 0;
            bottom: 0;
            justify-content: center;
            border-radius: 0;
        }
        .LiffGameHome .st-content-play{
            height: 100%;
        }
        .LiffGameHome .MiniBaccarat .box-coin {
            flex-direction: row;
        }
        .LiffGameHome .b-coin {
            width: 40px;
            height: 40px;
            margin: 0 6px;
        }
        .LiffGameHome .float-stream{
            flex-direction: column;
            justify-content: space-around;
            position: relative;
            height: 100vh;
        }
        .LiffGameHome .GameTopPlayer.box-top-user{
            position: fixed;
            top: 0;
            width: 300px;
            height: 57px;
            z-index: 100;
        }
    }
</style>
<style scoped>
    .TableSlideOut {
        z-index: 1000000;
    }

    .box-menu-game {
        z-index: 9 !important;
    }





    .user-list-detail {
        line-height: 20px;
    }

    .flex-center-center {
        display: flex;
        justify-content: center;
        align-items: center;
    }

    .balance-box {
        position: absolute;
        bottom: 1px;
        right: 1px;
    }

    .modal-notify-text-center {
        display: flex;
        justify-content: center;
        height: 100px;
        align-items: center;
    }

    .btn-cs {
        background-color: rgba(243, 114, 212, 0.9);
        border-radius: 20px;
        color: #fff !important;
        text-align: center !important;
        padding: 2px 5px !important;
    }

    .Aligner-item{
        font-family: "Athiti", sans-serif;
        background-color: rgba(0,0,0,0.9);
        padding: 15px 10px !important;
        border-radius: 10px !important;
    }

    .Aligner-item p{
        font-weight: bold;
        margin-bottom: 5px;
        font-size: 13px;
    }

    .Aligner-item i{
        color: #fab602;
    }

    .v--modal-overlay {
        background: rgba(0, 0, 0, 0.6) !important;
    }

    .FloatChip {
        width: 18px;
    }

    @media screen and (min-width: 600px) {
        .circle-wrap {
            /*top: 20vw !important;*/

        }

        .MiniBaccarat .messaging {
            display: none !important
        }
    }
</style>
<style>
    .modal-background-transparent {
        background-color: transparent;
    }
</style>