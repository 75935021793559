<template>
    <div class="YeekeeGuess">


        <div class="content-YeekeeGuess">
            <div class="content-YeekeeGuess-right">
                <div v-if="round" class="top-YeekeeGuess">
                    <h4>
                        <span v-if="is_round_open"><i class="fa fa-calculator"></i> ยิงเลข หวยไฮเทค</span>
                        <span class="top-YeekeeGuess-result" v-else>
<!--                            <img src="@/assets/images/bg-topUp/winner-icon.png"/>-->
<!--                            <i  class="fas fa-trophy"></i> ผลการออกรางวัล-->
                            <img src="@/assets/images/trophy-icon.png"/> ผลการออกรางวัล
                        </span>
                    </h4>
<!--                    <button @click="botGo(1000)">GOOOOO!</button>-->
                    <div class="top-YeekeeGuess-right">
                        <Alarm @timeout="closeGuess" :start="round.timeout" :extra_minutes="2" display_mode="guess"/>
                        <span v-if="mode !== 'static'" @click="$emit('close_yeekee_guess_modal')" class="close-send-modal">
                            <i class="fas fa-times"></i>
                        </span>
                    </div>

                </div>
                <div v-if="is_round_open" class="content-YeekeeGuess">
                    <div class="round-title">
                        รอบที่ <span v-if="round">{{round.sub_round}}</span>
                    </div>

                    <div class="content-YeekeeGuess-info">
                        <h5>ผลรวมหวยไฮเทค</h5>
                        <div class="yeekee-balance mb-2"><strong>{{total}}</strong></div>
                        <div class="text-center text-secondary">
                            <span class="content-num-after" v-if="number_str">{{number_str}}</span>
                            <span class="content-num-after content-num-after-gray" v-else>กรอกตัวเลข 5 หลัก</span>
                        </div>
                        <div class="key-pad-yeekee">
                            <NumPad
                                        @choose_number="choose_number"
                                        @backspace="backspace"
                                        @random="random"
                                        @submit="submit"
                                        :submit_button_label="submit_button_label"
                                        :is_submit_button_disabled="is_submit_button_disabled"
                                        mode="yeekee"/>

                        </div>
                    </div>
                </div>
                <div v-else class="row">
                    <div class="col-12">
                        <YeekeeDetail @refresh="loadData" :round_id="round_id"/>
                    </div>
                </div>
            </div>
            <div class="wrapper-number">
                <div class="col-12 top-wrapper-number"><h4><i class="fas fa-users"></i> รายชื่อผู้ทายเลข</h4></div>
                <div class="wrapper-number-yeekee">
                    <div v-for="data of list" :key="data.id" class="box-number-ranking"
                         :class="{'box-number-ranking-dark': data.is_winner}">
                        <div class="box-number-yeekee-left">
                            <div class="box-ranking">อันดับที่ {{data.ranking}}</div>
                            <h3 :class="{'text-dark': !data.is_winner}">{{data.number | fillZero}}</h3>
                        </div>

                        <div class="box-number-yeekee">
                            <div class="detail-number-yeekee">
                                <div><span :class="{'text-dark': !data.is_winner}">ผู้ส่งเลข :</span>
                                    {{data.user_name | strCensored}}
                                </div>
                                <div><span :class="{'text-dark': !data.is_winner}">เมื่อ :</span>
                                    {{data.created_at | dateTimeFormat}}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>

    import NumPad from "./NumPad";
    import Alarm from "./Alarm";
    import axiosWrapper from "@/axiosWrapper";
    import store from "@/store";
    import {mixin as VueTimers} from 'vue-timers';
    import YeekeeDetail from "./YeekeeDetail";
    import debounce from "@/debounce";
    import AppMixin from '../mixins/App'

    const WAITING_SECONDS = 10;

    export default {
        name: "YeekeeGuess",
        components: {YeekeeDetail, Alarm, NumPad},
        mixins: [VueTimers, AppMixin],
        timers: {
            countDown: {
                time: 1000,
                repeat: true
            },
            loadData: {
                time: 60000,
                repeat: true,
                autostart: true,
            }
        },
        props: ['round', 'mode'],
        computed: {
            number_str: function () {
                return this.number_arr.join('');
            },
            user_profile: function () {
                return store.state.user_profile;
            },
            pes_profile: function () {
                return store.state.pes_profile;
            },
            submit_button_label: function () {
                return (this.cool_down_seconds > 0) ? this.cool_down_seconds : 'เพิ่มเลข';
            },
            round_id: function () {
                return (this.round) ? this.round.id : null;
            },
            round_timeout: function () {
                return (this.round) ? this.round.timeout : null;
            },
            is_round_open:  {
                get(){
                    if (this.round) {
                        const now = this.$moment();
                        const timeout = this.$moment(this.round.timeout).add(2, 'minutes');
                        return (!this.is_timeout && now.isSameOrBefore(timeout));
                    } else {
                        return false;
                    }
                },
                set(value){
                    this.is_timeout = !value;
                }
            },
        },
        data(){
            return {
                total: 0,
                list: [],
                number_arr: [],
                digit: 5,
                is_submit_button_disabled: false,
                cool_down_seconds: 0,
                is_timeout: false,
            }
        },
        watch: {
            cool_down_seconds: function (new_val) {
                if (new_val === 0) {
                    this.stopCoolDown();
                }
            },
            round_id: function (new_val) {
                if (new_val) {
                    this.loadData();
                }
            },
            round_timeout(new_val){
                if(new_val){
                    this.is_timeout = false;
                }
            }
        },
        methods: {
            choose_number: async function (number) {
                if (this.number_arr.length < 5) {
                    this.number_arr.push(number);
                }
            },
            backspace: function () {
                if (this.number_arr.length > 0) {
                    this.number_arr.pop();
                }
            },
            random: function () {
                this.number_arr = ((Math.floor(Math.random() * 100000)) + '').split('');
                this.fill_zero();
            },
            fill_zero: function () {
                const diff = this.digit - this.number_arr.length;
                for (let i = 0; i < diff; i++) {
                    this.number_arr.splice(0, 0, 0);
                }
            },
            submit: debounce(async function () {

                if (this.is_submit_button_disabled) {
                    return;
                }

                if(!this.pes_profile){
                    this.$bvToast.toast('กรุณาลงชื่อเข้าใช้ก่อน', {title: 'แจ้งเตือน'});
                    return;
                }

                if (this.number_str.length !== 5) {
                    this.$bvToast.toast('กรุณากรอกตัวเลข 5 หลัก', {title: 'แจ้งเตือน'});
                    return;
                }

                const res = await axiosWrapper({
                    method: 'POST',
                    url: `${this.envDomains.VUE_APP_LOTTO_API}/api/guess/create`,
                    data: {
                        round_id: this.round.id,
                        number: this.number_str,
                        token: this.pes_profile.token,
                        user_name: this.user_profile.display_name,
                    }
                });
                if (res.status === 200) {
                    this.$bvToast.toast('เพิ่มเลขสำเร็จ', {title: 'แจ้งเตือน'});
                    this.loadData();
                    this.clearNumber();
                    this.startCoolDown();
                } else {
                    this.$bvToast.toast(res.data.message, {title: 'แจ้งเตือน'});
                }
            }, 333),
            clearNumber: function () {
                this.number_arr = [];
            },
            startCoolDown: function () {
                this.cool_down_seconds = WAITING_SECONDS;
                this.is_submit_button_disabled = true;
                this.$timer.start('countDown');
            },
            countDown: function () {
                if (this.cool_down_seconds > 0) {
                    this.cool_down_seconds--;
                }
            },
            stopCoolDown: function () {
                this.is_submit_button_disabled = false;
                this.$timer.stop('countDown');
            },
            loadData: async function () {

                if (this.round_id) {
                    const res = await axiosWrapper({
                        method: 'POST',
                        url: `${this.envDomains.VUE_APP_LOTTO_API}/api/guess/get_list`,
                        data: {
                            round_id: this.round_id
                        }
                    });
                    if (res.status === 200) {
                        this.total = res.data.total;
                        this.list = res.data.list;
                    } else {
                        this.$bvToast.toast(res.data.message, {title: 'แจ้งเตือน'});
                    }
                }
            },
            closeGuess: async function(){
                this.is_round_open = false;
                this.$emit('close_guess');
            },
            // botGo: async function(count){
            //
            //     for(let i = 0; i < count;i++){
            //
            //         this.random();
            //
            //         await axiosWrapper({
            //             method: 'POST',
            //             url: `${this.envDomains.VUE_APP_LOTTO_API}/api/guess/create`,
            //             data: {
            //                 round_id: this.round.id,
            //                 number: this.number_str,
            //                 token: this.pes_profile.token,
            //             }
            //         });
            //
            //     }
            // }
        },
        mounted() {
            this.loadData();
        }
    }
</script>

<style scoped>

</style>