import axiosWrapper from '@/axiosWrapper'
import qs from 'query-string'

export default {
    async getAccessToken(code, client_id, client_secret, redirect_uri) {
        return await axiosWrapper({
            method: 'POST',
            headers: {
                'Content-Type' : 'application/x-www-form-urlencoded',
            },
            url: `https://api.line.me/oauth2/v2.1/token`,
            data: qs.stringify({
                grant_type: 'authorization_code',
                code: code,
                redirect_uri: redirect_uri,
                client_id: client_id,
                client_secret: client_secret,
            })
        })
    },
    async getProfile(accessToken) {
        return await axiosWrapper({
            method: 'GET',
            url: `https://api.line.me/v2/profile`,
            headers: {
                'Authorization' : `Bearer ${accessToken}`,
            },
        })
    }
}