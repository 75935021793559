export default async (file) => {
    return new Promise((resolve) => {
        const reader = new FileReader()
        reader.readAsDataURL(file)
        reader.onload = () => {
            resolve({
                success: true,
                data: reader.result,
            })
        }
        reader.onerror = (error) => {
            resolve({
                success: false,
                data: error,
            })
        }
    })
}