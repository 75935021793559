<template>
    <div class="AdminBot">
        <div class="v-content-inner">
            <div class="v-toolbar">
                <div class="toolbar-item">
                    บอทส่งข้อความ ทุกๆ &#60;minutes&#62; นาที <strong style="margin-left: 4px;">(กรุณาเปิดหน้านี้ทิ้งไว้)</strong>
                </div>
                <div class="toolbar-item">
                    
                </div>
            </div>
            <div class="v-list">
                <div class="v-bot-wrapper">
                    <el-card v-for="bot of botArr" :key="bot.type" class="v-box-card">
                        <div slot="header" class="v-box-card-header">
                            <span>{{bot.label}}</span>
                            <div class="v-card-right-tool">
                                <el-input-number 
                                v-if="bot.state === 'idle'"
                                v-model="bot.minute" 
                                :min="1"
                                size="small"
                                style="margin-right: 10px;"></el-input-number>
                                <Alarm v-else :pause="bot.state === 'pause'" :start="bot.minute" @timeOut="sendMessage(bot)" style="margin-right: 10px;" />
                                <el-button v-show="bot.state === 'idle'" 
                                    @click="start(bot)" 
                                    icon="fas fa-play"
                                    size="small"
                                    plain
                                    :disabled="!bot.data"/>
                                <el-button-group>
                                    <el-button v-if="bot.state === 'pause'" 
                                    @click="start(bot)" 
                                    icon="fas fa-play"
                                    size="small"
                                    plain/>
                                    <el-button v-else-if="bot.state === 'running'" 
                                    @click="pause(bot)" 
                                    icon="fas fa-pause"
                                    size="small"
                                    plain/>
                                    <el-button v-show="bot.state !== 'idle'" 
                                    @click="stop(bot)" 
                                    icon="fas fa-stop"
                                    size="small"
                                    plain/>
                                </el-button-group>
                                <div style="width: 5px;"></div>
                                <el-button @click="refresh(bot)"
                                :icon="`fas fa-sync ${(!!bot.isLoading) ? 'fa-spin' : ''}`" 
                                size="small" 
                                :disabled="(!!bot.isLoading) ? true : false"></el-button>
                            </div>
                        </div>
                        <div>
                            <MessageTopUp v-if="bot.type === 'top_up'" :message="bot.data"/>
                            <MessageLottoPrice v-else-if="bot.type === 'lotto_price'" :message="bot.data"/>
                            <MessageLive v-else-if="bot.type === 'live'" :message="bot.data"/>
                            <MessageFootball v-else-if="bot.type === 'football'" :message="bot.data"/>
                            <MessageLottoNews v-else-if="bot.type === 'lotto_news'" :message="bot.data"/>
                            <MessageSystem v-else-if="bot.type === 'system'" :message="bot.data"/>
                            <MessageGallery v-else-if="bot.type === 'gallery'" :message="bot.data"/>
                            <MessageTimeline v-else-if="bot.type === 'timeline'" :message="bot.data"/>
                            <MessagePromotion v-else-if="bot.type === 'promotion'" :message="bot.data" />
                            <MessageRich v-else-if="bot.type === 'rich'" :message="bot.data" />
                        </div>
                    </el-card>
                </div>
                
            </div>
            <div class="v-toolbar-bottom">
                
            </div>
        </div>
    </div>
</template>
<script>

import MessageTopUp from '@/components/message/TopUp'
import MessageLottoPrice from '@/components/message/LottoPrice'
import MessageLive from '@/components/message/Live'
import MessageFootball from '@/components/message/Football'
import MessageLottoNews from '@/components/message/LottoNews'
import MessageSystem from '@/components/message/System'
import MessageGallery from '@/components/message/Gallery'
import MessageTimeline from '@/components/message/Timeline'
import MessagePromotion from '@/components/message/Promotion'
import MessageRich from '@/components/message/Rich'
import Alarm from '@/components/Alarm'
import axios from 'axios'
import store from '@/store'
// import Vue from 'vue'

export default {
    name: 'AdminBot',
    components: {
        MessageTopUp,
        MessageLottoPrice,
        MessageLive,
        MessageFootball,
        MessageLottoNews,
        MessageSystem,
        MessageGallery,
        MessageTimeline,
        MessagePromotion,
        MessageRich,
        Alarm
    },
    data(){
        return {
            botArr: [
                {type: 'top_up', label: 'เติมเงิน', minute: 3, state: 'idle', data: null, isLoading: false,},
                {type: 'lotto_price', label: 'ราคาหวย', minute: 3, state: 'idle', data: null, isLoading: false,},
                {type: 'live', label: 'ไลฟ์/วิดีโอ', minute: 3, state: 'idle', data: null, isLoading: false,},
                {type: 'football', label: 'ฟุตบอล', minute: 3, state: 'idle', data: null, isLoading: false,},
                {type: 'lotto_news', label: 'ข่าวหวย', minute: 3, state: 'idle', data: null, isLoading: false,},
                {type: 'system', label: 'ระบบ', minute: 3, state: 'idle', data: null, isLoading: false,},
                {type: 'gallery', label: 'แกลอรี่', minute: 3, state: 'idle', data: null, isLoading: false,},
                {type: 'timeline', label: 'timeline', minute: 3, state: 'idle', data: null, isLoading: false,},
                {type: 'promotion', label: 'โปรโมชั่น', minute: 3, state: 'idle', data: null, isLoading: false,},
                {type: 'rich', label: 'ริชเมสเสจ', minute: 3, state: 'idle', data: null, isLoading: false,},
            ]
        }
    },
    computed: {
        admin(){
            return store.state.admin
        },
        token(){
            return this.admin.token
        },
        room_id(){
            return this.admin.room_id
        }
    },
    methods: {
        start(bot){
            bot.state = 'running'
        },
        pause(bot){
            bot.state = 'pause'
        },
        stop(bot){
            bot.state = 'idle'
        },
        async refresh(bot) {
            bot.isLoading = true
            await this._loadOneLatestMessage(bot)
            setTimeout(() => {
                bot.isLoading = false
            }, 1000)
        },
        async _loadOneLatestMessage(bot) {
            const res = await axios({
                method: 'POST',
                url: `${this.envDomains.VUE_APP_API}/api/chat/admin-get-one-latest-message`,
                data: {
                    token: this.token,
                    room_id: this.room_id,
                    type: bot.type,
                }
            })
            if(!res.data.success){
                this.$alert(res.data.message, 'แจ้งเตือน', {
                    type: 'error',
                    confirmButtonText: 'ตกลง',
                    customClass: 'custom-message-box'
                })
                return
            }
            bot['data'] = res.data.data
        },
        async sendMessage(bot){

            if(!bot.data || !bot.data.data){
                return
            }

            if(bot.data.type === 'lotto_news') {
                const items = bot.data.data
                const filteredItems = []
                for(const item of items) {
                    if(!item.expire) {
                        filteredItems.push(item)
                        continue
                    }
                    const expire = this.$moment(item.expire)
                    const now = this.$moment()
                    if(now.isSameOrBefore(expire)) {
                        filteredItems.push(item)
                    }
                }
                bot.data.data = filteredItems
                if(filteredItems.length === 0) {
                    return
                }
            } else if(bot.data.type === 'football') {
                const items = bot.data.data
                const filteredItems = []
                for(const item of items) {
                    const kickoff_time = this.$moment(item.kickoff_time)
                    const now = this.$moment()
                    if(now.isSameOrBefore(kickoff_time)) {
                        filteredItems.push(item)
                    }
                }
                bot.data.data = filteredItems
                if(filteredItems.length === 0) {
                    return
                }
            }

            const res = await axios({
                method: 'POST',
                url: `${this.envDomains.VUE_APP_API}/api/chat/admin-send`,
                data: {
                    room_id: this.room_id,
                    token: this.token,
                    admin_id: this.admin.id,
                    data: bot.data.data,
                    type: bot.type,
                    timestamp: this.$moment().toISOString()
                }
            })
            if(!res.data.success){
                console.log(`bot ${bot.type} error ${res.data.message}`)
                return
            }
        },
        async getLatestMessage(){

            const loading = this.$loading()

            try{
                const res = await axios({
                    method: 'POST',
                    url: `${this.envDomains.VUE_APP_API}/api/chat/admin-get-latest-message`,
                    data: {
                        token: this.token,
                        room_id: this.room_id
                    }
                })
                if(!res.data.success){
                    this.$alert(res.data.message, 'แจ้งเตือน', {
                        type: 'error',
                        confirmButtonText: 'ตกลง',
                        customClass: 'custom-message-box'
                    })
                    loading.close()
                    return
                }

                const data = res.data.data

                for(const bot of this.botArr){
                    bot['data'] = data[bot.type]
                }

                loading.close()
            }catch(err){
                this.$alert('ไม่สามารถโหลดข้อมูล กรุณาลองอีกครั้ง', 'แจ้งเตือน', {
                    type: 'error',
                    confirmButtonText: 'ตกลง',
                    customClass: 'custom-message-box'
                })
                loading.close()
                return
            }
        }
    },
    mounted(){
        this.getLatestMessage()
    }
}
</script>
<style scoped>
.v-bot-wrapper {
    display: flex;
    flex-wrap: wrap;
}
.v-card-right-tool {
    display: flex;
    align-items: center;
}
.v-box-card {
    width: 380px;
    margin: 4px;
}
.v-box-card-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    min-height: 40px;
}
</style>
<style>
.AdminBot .el__button {
    margin: 0;
}
</style>