<template>
    <div @click="selectBet(USER_CHOOSE[choice], $event)"
        :class="`${customClass} ${calBtnBlockPlayIsActive(USER_CHOOSE[choice])} ${isPanelWinClass}`">
        <ConfirmPanel v-show="isShowConfirmPanel"
        @confirm="confirmBet"
        @cancel="cancelBet">
        </ConfirmPanel>
        <DummyUser></DummyUser>
        <UserBetChip 
        v-if="calBtnBlockPlayIsActive(USER_CHOOSE[choice]) === 'active'" 
        :state="shipState"
        :chip="(isShowExistBet) ? latestChip : amount" 
        :value="(isShowExistBet) ? calTotalAmount : totalAmountToBet" 
        :panel="USER_CHOOSE[choice]">
        </UserBetChip>
        <!-- <div v-if="isShowExistBet">
            <Chip :value="latestChip"></Chip>
        </div>
        <div v-else>
            <Chip :value="amount"></Chip>
        </div>
        <div v-show="choice !== 'TIE'" class="box-select-coin">
            <span v-if="isShowExistBet">
                {{calTotalAmount}}
            </span>
            <span v-else>
                {{totalAmountToBet}}
            </span>
        </div> -->
        <div v-show="choice !== 'TIE'" class="label-btn">{{label}}</div>
        <span v-show="choice !== 'TIE'" class="price-player">{{labelMulti}}</span>
        <div v-show="choice === 'TIE'" class="btn-tie-box deck" >
            <!-- <div class="box-select-coin">
                <span v-if="isShowExistBet">
                    {{calTotalAmount}}
                </span>
                <span v-else>
                    {{totalAmountToBet}}
                </span>
            </div> -->
            <div class="label-btn">{{label}}</div>
            <span class="price-player">{{labelMulti}}</span>
        </div>
        <slot></slot>
    </div>
</template>
<script>

import {USER_CHOOSE} from '../Constant'
import ConfirmPanel from '../ConfirmPanel'
// import Chip from '../Chip'
import UserBetChip from '../UserBetChip'
import DummyUser from '../DummyUser'

export default {
    name: 'BaccaratPanelPlay',
    components: {
        ConfirmPanel,
        // Chip,
        UserBetChip,
        DummyUser
    },
    props: [
        'customClass',
        'label',
        'labelMulti',
        'user_choose',
        'choice',
        'userBetArr',
        'amount',
        'isPanelWin',
        'shipState',
        'isOnSendBet',
    ],
    data() {
        return {
            USER_CHOOSE: null,
        }
    },
    computed: {
        userBetArrWait() {
            return this.userBetArr.filter((userBet) => {
                return userBet.state === 'wait'
            })
        },
        userBetArrConfirm() {
            return this.userBetArr.filter((userBet) => {
                return userBet.state === 'confirm'
            })
        },
        isShowConfirmPanel() {
            return (this.user_choose === this.USER_CHOOSE[this.choice]) && !this.isOnSendBet
        },
        isShowExistBet() {
            return this.userBetArrConfirm.length > 0 && this.userBetArrWait.length === 0
        },
        latestChip() {
            return this.latestUserBetByUserChoose.latest_chip
        },
        totalAmountToBet() {
            return this.userBetArrWait.reduce((sum, bet) => {
                return parseInt(sum) + parseInt(bet.amount)
            }, 0)
        },
        latestUserBetByUserChoose() {
            return this.userBetArr[this.userBetArr.length - 1]
        },
        calTotalAmount() {
            return this.userBetArrConfirm.reduce((sum, bet) => {
                return parseInt(sum) + parseInt(bet.amount)
            }, 0)
        },
        isPanelWinClass() {
            if(this.isPanelWin) {
                return 'active-blinking'
            } else {
                return ''
            }
        }
    },
    methods: {
        calBtnBlockPlayIsActive(user_choose) {

            if(this.user_choose === user_choose) {
                return 'active'
            }
            const foundInBetArr = this.userBetArr.find((bet) => {
                return bet.user_choose === user_choose
            })
            return (foundInBetArr) ? 'active': ''
        },
        confirmBet() {
            this.$emit('confirmBet')
        },
        cancelBet() {
            this.$emit('cancelBet')
        },
        selectBet(data, e) {
            if(e.target.classList.contains('bx-close') || e.target.classList.contains('bx-confirm')) {
                return
            }
            this.$emit('selectBet', data)
        },
    },
    created() {
        this.USER_CHOOSE = USER_CHOOSE
    }
}
</script>

<style>
    /*.left-panel-play, .middle-panel-play, .right-panel-play{*/
    /*    height: 31vw;*/
    /*}*/

    /*.btn-tie-box{*/
    /*    height: auto;*/
    /*}*/



</style>