<template>
    <div class="MiniGame MiniSicbo containner-all">

        <FloatButtomRightPanel/>
        <div class="close-baccarat">
            <div @click="exit"><i class="fa fa-times"></i></div>
        </div>
        <modal name="drawer-right" transition="modal-slide-left" class="MtableList"
               :pivotY="1"
               :pivotX="1"
               :adaptive="true"
               width="300px"
               height="100%">
            <GameTableList :tableList="baccaratTableList"></GameTableList>
        </modal>
        <modal name="drawer-stat-right" transition="modal-slide-left" class="editModalstat"
               :pivotY="1"
               :pivotX="1"
               :adaptive="true"
               width="300px"
               height="100%">
            <Stat :statArr="statArr"></Stat>
        </modal>
        <modal name="ticket-drawer" transition="modal-slide-left" class="editModalstat"
               :pivotY="1"
               :pivotX="1"
               :adaptive="true"
               width="300px"
               height="100%">
            <TicketList :ticketList="getTicketListByGame(tableType, tableId)"></TicketList>
        </modal>

        <div class="float-stream float-stream-sicbo">
            <GameHeader :table="currentTable"></GameHeader>

            <!--Animate hilo shake-->
            <!-- <div class="box-animated-hilo">
                <div class="box-game-animated"></div>
                <div class="box-game-step-1"></div>
                <div class="box-game-step-2"></div>
                <div class="box-game-step-3"></div>
                <div class="box-sec-dice">
                    <div class="sec-dice">
                        <span class="tb-dice dice_1-1 "></span>
                        <span class="tb-dice dice_3-2"></span>
                        <span class="tb-dice dice_5-3"></span>
                    </div>
                </div>
            </div> -->
            <!--End-->

            <GamePlay3D
            v-if="liveMode === '3d'"
            :gameStatus="gameStatus"
            :dice="dice"
            ></GamePlay3D>

            <div v-if="noTopSection === 'yes'" id="bankerDiv" style="bottom: unset; top: 0;">
                <UserBetChip
                        v-for="(bankerPay, key) of bankerPayArr" :key="key"
                        :state="bankerPay.state"
                        :chip="bankerPay.chip"
                        :value="bankerPay.value"
                        :user_choose="bankerPay.user_choose"></UserBetChip>
            </div>
            <div v-else class="middle-stream middle-phone middle-st-sicbo">
                <div class="middle-stream-info">

                    <div id="bankerDiv">
                        <UserBetChip
                                v-for="(bankerPay, key) of bankerPayArr" :key="key"
                                :state="bankerPay.state"
                                :chip="bankerPay.chip"
                                :value="bankerPay.value"
                                :user_choose="bankerPay.user_choose"></UserBetChip>
                    </div>
                    <span v-if="liveMode === '3d'" @click="toggleLiveMode" class="box-switch-mode">
                            <span>3D</span>
                        </span>
                    <span v-else @click="toggleLiveMode" class="box-switch-mode">
                            <span>สด</span>
                        </span>
                    <div class="box-balance-game">
                        <div class="price-value-hilo">
                            <p>ราคาเดิมพัน</p>
                            <span>{{totalUserBet | numberFormat}}</span>
                        </div>

                        <div class="bx-volume-game">
                            <SoundToggleButton v-if="isOnDev" @click="toggleGameSound" :on="isGameSoundOn"></SoundToggleButton>
                        </div>
                        <span class="balance-box balance-box-selectValue" style="left: 1px; right: unset;"
                              @click="toSelectBetLimit"><i class="fas fa-coins"></i> เลือกวงเงิน</span>
                        <span class="balance-box balance-box-all">balance: {{balance | numberFormat}}</span>
                    </div>
                    <div v-if="false" class="contentPanel-card">
                        <div class="player" style="width: 111px; height: 64px;">
                            <ul>
                                <li>Dice</li>
                                <li>{{dicePoint}}</li>
                            </ul>
                            <div class="dice-row">
                                <transition-group name="bounce" style="display: flex;">
                                    <DiceFace v-for="(d, i) of dice" :key="`${d}${i}`" :number="d"></DiceFace>
                                </transition-group>
                            </div>
                        </div>
                    </div>
                    <div class="circle-wrap">
                        <p id="countdown">
                            <span v-if="gameStatus === GAME_STATUS.COUNTDOWN">{{secondCountDown}}</span>
                            <span class="txt-status" v-else>{{gameStatus | displayTableGameStatus}}</span>
                        </p>
                    </div>
                    <!-- <img src="@/assets/images/simple-3.jpg" alt="sunil"/> -->
                    <!--<img src="images/simple-3.jpg">-->
                    <VideoPlayer 
                    v-show="liveMode === 'video'" 
                    :tableVideoStreamPath="tableVideoStreamPath"
                    :srcHLS="tableVideoSrcHLS"
                    :srcFLV="tableVideoSrcFLV"
                    :width="videoPlayerWidth"
                    :height="videoPlayerHeight"
                    :playerBus="playerBus"
                    @ready="videoPlayerReadyHandle"
                    ></VideoPlayer>
                    <GameVerticalTool
                            :panel_to_show="panel_to_show"
                            @exit="exit"
                            @toggleTicketPanel="toggleTicketPanel"
                            @showSideMenu="showSideMenu"
                            @toggleStatePanel="toggleStatePanel"
                            @togglePlayPanel="togglePlayPanel"
                            @goDepositWithdraw="goDepositWithdraw">
                    </GameVerticalTool>
                </div>

                <div @click="togglePanelBottom" class="btn-control-bottom"
                     :class="{'current': !is_show_panel_bottom}"></div>
            </div>
            <div v-if="noTopSection === 'yes'" id="playerDiv"></div>
            <div v-show="is_show_panel_bottom" class="bottom-stream deck" id="panelBottom">
                <FloatOpenDice
                :isPanelOpen="isFloatOpenDicePanelOpen"
                :dicePoint="dicePoint"
                :dice="dice"
                :gameResult="gameResult"></FloatOpenDice>
                <div class="content-panel-sicbo" :class="{'toggle-box': isTogglePlayBox}" id="contentPanelSicbo">

                    <!-- <div class="bg-winner-active" v-show="gameStatus === GAME_STATUS.FINISH" style="z-index: 15;">
                        <div v-show="gameResult === GAME_RESULT.HI" class="hi-win"><span></span></div>
                        <div v-show="gameResult === GAME_RESULT.HILO11" class="hilo11-win"><span></span></div>
                        <div v-show="gameResult === GAME_RESULT.LOW" class="low-win"><span></span></div>
                    </div> -->
                    <div class="content-sicbo">

                        <span @click="togglePlayBox" class="close-box-sicbo close-bt"><i class="fa fa-chevron-down"></i> </span>
                        <div class="content-sicbo-center">
                            <div class="content-sicbo-center-info">

                                <div class="sb-row">
                                    <div class="sb-box">
                                        <PanelPlay
                                                customClass="bx-dice sc-vertical sc-vertical-1"
                                                choice="1LOW"
                                                :userBetArr="filterUserBetArrByUserChoose(USER_CHOOSE['1LOW'])"
                                                :user_choose="user_choose"
                                                :amount="amount"
                                                :isPanelWin="isOptionalPanelWin('is_1low')"
                                                :shipState="calOptionalPanelChipState('is_1low')"
                                                :isOnSendBet="isOnSendBet"
                                                @selectBet="selectBet"
                                                @confirmBet="confirmBet"
                                                @cancelBet="cancelBet"
                                                style="right: -15px">
                                            <div class="box-progressBar">
                                                <div class="box-progressBar-info">
                                                    <div class="progress-bar-user progress-bar-player" :style="`width: ${calBetPercent('1LOW')}%`"></div>
                                                </div>
                                            </div>
                                        </PanelPlay>
                                        <PanelPlay
                                                customClass="bx-dice sc-horizontal"
                                                choice="12"
                                                :userBetArr="filterUserBetArrByUserChoose(USER_CHOOSE[12])"
                                                :user_choose="user_choose"
                                                :amount="amount"
                                                :isPanelWin="isOptionalPanelWin('is_12')"
                                                :shipState="calOptionalPanelChipState('is_12')"
                                                :isOnSendBet="isOnSendBet"
                                                @selectBet="selectBet"
                                                @confirmBet="confirmBet"
                                                @cancelBet="cancelBet"
                                                style="bottom: -15px">
                                            <div class="box-progressBar">
                                                <div class="box-progressBar-info">
                                                    <div class="progress-bar-user progress-bar-player" :style="`width: ${calBetPercent(12)}%`"></div>
                                                </div>
                                            </div>
                                        </PanelPlay>
                                        <PanelPlay
                                                ref="1-block"
                                                customClass="bx-dice bx-dice-one sb-red"
                                                choice="1"
                                                :userBetArr="filterUserBetArrByUserChoose(USER_CHOOSE[1])"
                                                :user_choose="user_choose"
                                                :amount="amount"
                                                :isPanelWin="isOptionalPanelWin('is_1')"
                                                :shipState="calOptionalPanelChipState('is_1')"
                                                :isOnSendBet="isOnSendBet"
                                                @selectBet="selectBet"
                                                @confirmBet="confirmBet"
                                                @cancelBet="cancelBet">
                                            <DiceFaceOne></DiceFaceOne>
                                            <div class="box-progressBar">
                                                <div class="box-progressBar-info">
                                                    <div class="progress-bar-user progress-bar-player" :style="`width: ${calBetPercent(1)}%`"></div>
                                                </div>
                                            </div>
                                        </PanelPlay>
                                        <FloatChip
                                                v-for="(bet, i) of getOtherBetListByChoose(USER_CHOOSE[1])" :key="i"
                                                :value="bet.amount"
                                                :choose="bet.choose"
                                                :bound="getPanelPlayฺBound('1-block')">
                                        </FloatChip>
                                    </div>
                                    <div class="sb-box">
                                        <PanelPlay
                                                customClass="bx-dice sc-horizontal"
                                                choice="3LOW"
                                                :userBetArr="filterUserBetArrByUserChoose(USER_CHOOSE['3LOW'])"
                                                :user_choose="user_choose"
                                                :amount="amount"
                                                :isPanelWin="isOptionalPanelWin('is_3low')"
                                                :shipState="calOptionalPanelChipState('is_3low')"
                                                :isOnSendBet="isOnSendBet"
                                                @selectBet="selectBet"
                                                @confirmBet="confirmBet"
                                                @cancelBet="cancelBet"
                                                style="bottom: -15px;right: 0;left: 13px;width: 60%;">
                                            <div class="box-progressBar">
                                                <div class="box-progressBar-info">
                                                    <div class="progress-bar-user progress-bar-player" :style="`width: ${calBetPercent('3LOW')}%`"></div>
                                                </div>
                                            </div>
                                        </PanelPlay>
                                        <PanelPlay
                                                ref="low-block"
                                                customClass="bx-dice"
                                                choice="LOW"
                                                :userBetArr="filterUserBetArrByUserChoose(USER_CHOOSE['LOW'])"
                                                :user_choose="user_choose"
                                                :amount="amount"
                                                :isPanelWin="isPanelWin(GAME_RESULT.LOW, gameResult)"
                                                :shipState="calPanelChipState(GAME_RESULT.LOW)"
                                                :isOnSendBet="isOnSendBet"
                                                @selectBet="selectBet"
                                                @confirmBet="confirmBet"
                                                @cancelBet="cancelBet">
                                            <div class="b-down">
                                                <span class="sb-red">ต่ำ</span>
                                            </div>
                                            <div class="box-progressBar">
                                                <div class="box-progressBar-info">
                                                    <div class="progress-bar-user progress-bar-player" :style="`width: ${calBetPercent('LOW')}%`"></div>
                                                </div>
                                            </div>
                                        </PanelPlay>
                                        <FloatChip
                                                v-for="(bet, i) of getOtherBetListByChoose(USER_CHOOSE['LOW'])" :key="i"
                                                :value="bet.amount"
                                                :choose="bet.choose"
                                                :bound="getPanelPlayฺBound('low-block')">
                                        </FloatChip>
                                    </div>
                                    <div class="sb-box">
                                        <PanelPlay
                                                customClass="bx-dice sc-vertical sc-vertical-2"
                                                choice="6HI"
                                                :userBetArr="filterUserBetArrByUserChoose(USER_CHOOSE['6HI'])"
                                                :user_choose="user_choose"
                                                :amount="amount"
                                                :isPanelWin="isOptionalPanelWin('is_6hi')"
                                                :shipState="calOptionalPanelChipState('is_6hi')"
                                                :isOnSendBet="isOnSendBet"
                                                @selectBet="selectBet"
                                                @confirmBet="confirmBet"
                                                @cancelBet="cancelBet"
                                                style="right: -15px">
                                            <div class="box-progressBar">
                                                <div class="box-progressBar-info">
                                                    <div class="progress-bar-user progress-bar-player" :style="`width: ${calBetPercent('6HI')}%`"></div>
                                                </div>
                                            </div>
                                        </PanelPlay>
                                        <PanelPlay
                                                customClass="bx-dice sc-horizontal"
                                                choice="4HI"
                                                :userBetArr="filterUserBetArrByUserChoose(USER_CHOOSE['4HI'])"
                                                :user_choose="user_choose"
                                                :amount="amount"
                                                :isPanelWin="isOptionalPanelWin('is_4hi')"
                                                :shipState="calOptionalPanelChipState('is_4hi')"
                                                :isOnSendBet="isOnSendBet"
                                                @selectBet="selectBet"
                                                @confirmBet="confirmBet"
                                                @cancelBet="cancelBet"
                                                style="bottom: -15px;right: 0;left: 13px;width: 50%;">
                                            <div class="box-progressBar">
                                                <div class="box-progressBar-info">
                                                    <div class="progress-bar-user progress-bar-player" :style="`width: ${calBetPercent('4HI')}%`"></div>
                                                </div>
                                            </div>
                                        </PanelPlay>
                                        <PanelPlay
                                                ref="hi-block"
                                                customClass="bx-dice"
                                                choice="HI"
                                                :userBetArr="filterUserBetArrByUserChoose(USER_CHOOSE['HI'])"
                                                :user_choose="user_choose"
                                                :amount="amount"
                                                :isPanelWin="isPanelWin(GAME_RESULT.HI, gameResult)"
                                                :shipState="calPanelChipState(GAME_RESULT.HI)"
                                                :isOnSendBet="isOnSendBet"
                                                @selectBet="selectBet"
                                                @confirmBet="confirmBet"
                                                @cancelBet="cancelBet">
                                            <div class="b-height">
                                                <span>สูง</span>
                                            </div>
                                            <div class="box-progressBar">
                                                <div class="box-progressBar-info">
                                                    <div class="progress-bar-user progress-bar-player" :style="`width: ${calBetPercent('HI')}%`"></div>
                                                </div>
                                            </div>
                                        </PanelPlay>
                                        <FloatChip
                                                v-for="(bet, i) of getOtherBetListByChoose(USER_CHOOSE['HI'])" :key="i"
                                                :value="bet.amount"
                                                :choose="bet.choose"
                                                :bound="getPanelPlayฺBound('hi-block')">
                                        </FloatChip>
                                    </div>
                                    <div class="sb-box sb-box-dice-six">
                                        <PanelPlay
                                                customClass="bx-dice sc-horizontal"
                                                choice="56"
                                                :userBetArr="filterUserBetArrByUserChoose(USER_CHOOSE[56])"
                                                :user_choose="user_choose"
                                                :amount="amount"
                                                :isPanelWin="isOptionalPanelWin('is_56')"
                                                :shipState="calOptionalPanelChipState('is_56')"
                                                :isOnSendBet="isOnSendBet"
                                                @selectBet="selectBet"
                                                @confirmBet="confirmBet"
                                                @cancelBet="cancelBet"
                                                style="bottom: -15px; right: 0; left: auto">
                                            <div class="box-progressBar">
                                                <div class="box-progressBar-info">
                                                    <div class="progress-bar-user progress-bar-player" :style="`width: ${calBetPercent(56)}%`"></div>
                                                </div>
                                            </div>
                                        </PanelPlay>
                                        <PanelPlay
                                                ref="6-block"
                                                customClass="bx-dice content-circle-dice"
                                                choice="6"
                                                :userBetArr="filterUserBetArrByUserChoose(USER_CHOOSE['6'])"
                                                :user_choose="user_choose"
                                                :amount="amount"
                                                :isPanelWin="isOptionalPanelWin('is_6')"
                                                :shipState="calOptionalPanelChipState('is_6')"
                                                :isOnSendBet="isOnSendBet"
                                                @selectBet="selectBet"
                                                @confirmBet="confirmBet"
                                                @cancelBet="cancelBet">
                                            <DiceFaceSix></DiceFaceSix>
                                            <div class="box-progressBar">
                                                <div class="box-progressBar-info">
                                                    <div class="progress-bar-user progress-bar-player" :style="`width: ${calBetPercent('6')}%`"></div>
                                                </div>
                                            </div>
                                        </PanelPlay>
                                        <FloatChip
                                                v-for="(bet, i) of getOtherBetListByChoose(USER_CHOOSE['6'])" :key="i"
                                                :value="bet.amount"
                                                :choose="bet.choose"
                                                :bound="getPanelPlayฺBound('6-block')">
                                        </FloatChip>
                                    </div>
                                </div>
                                <div class="sb-row">
                                    <div class="sb-box sb-box-dice-two">
                                        <PanelPlay
                                                customClass="bx-dice sc-vertical sc-vertical-3"
                                                choice="23"
                                                :userBetArr="filterUserBetArrByUserChoose(USER_CHOOSE[23])"
                                                :user_choose="user_choose"
                                                :amount="amount"
                                                :isPanelWin="isOptionalPanelWin('is_23')"
                                                :shipState="calOptionalPanelChipState('is_23')"
                                                :isOnSendBet="isOnSendBet"
                                                @selectBet="selectBet"
                                                @confirmBet="confirmBet"
                                                @cancelBet="cancelBet"
                                                style="right: -15px; top: 15%; bottom: 0;">
                                            <div class="box-progressBar">
                                                <div class="box-progressBar-info">
                                                    <div class="progress-bar-user progress-bar-player" :style="`width: ${calBetPercent(23)}%`"></div>
                                                </div>
                                            </div>
                                        </PanelPlay>
                                        <PanelPlay
                                                ref="2-block"
                                                customClass="bx-dice content-circle-dice circle-dice-2"
                                                choice="2"
                                                :userBetArr="filterUserBetArrByUserChoose(USER_CHOOSE['2'])"
                                                :user_choose="user_choose"
                                                :amount="amount"
                                                :isPanelWin="isOptionalPanelWin('is_2')"
                                                :shipState="calOptionalPanelChipState('is_2')"
                                                :isOnSendBet="isOnSendBet"
                                                @selectBet="selectBet"
                                                @confirmBet="confirmBet"
                                                @cancelBet="cancelBet">
                                            <DiceFaceTwo></DiceFaceTwo>
                                            <div class="box-progressBar">
                                                <div class="box-progressBar-info">
                                                    <div class="progress-bar-user progress-bar-player" :style="`width: ${calBetPercent('2')}%`"></div>
                                                </div>
                                            </div>
                                        </PanelPlay>
                                        <FloatChip
                                                v-for="(bet, i) of getOtherBetListByChoose(USER_CHOOSE['2'])" :key="i"
                                                :value="bet.amount"
                                                :choose="bet.choose"
                                                :bound="getPanelPlayฺBound('2-block')">
                                        </FloatChip>
                                    </div>
                                    <div class="sb-box">
                                        <PanelPlay
                                                customClass="bx-dice sc-vertical sc-vertical-4"
                                                choice="34"
                                                :userBetArr="filterUserBetArrByUserChoose(USER_CHOOSE[34])"
                                                :user_choose="user_choose"
                                                :amount="amount"
                                                :isPanelWin="isOptionalPanelWin('is_34')"
                                                :shipState="calOptionalPanelChipState('is_34')"
                                                :isOnSendBet="isOnSendBet"
                                                @selectBet="selectBet"
                                                @confirmBet="confirmBet"
                                                @cancelBet="cancelBet"
                                                style="right: -15px; top: 15%; bottom: 0;">
                                            <div class="box-progressBar">
                                                <div class="box-progressBar-info">
                                                    <div class="progress-bar-user progress-bar-player" :style="`width: ${calBetPercent(34)}%`"></div>
                                                </div>
                                            </div>
                                        </PanelPlay>
                                        <PanelPlay
                                                ref="3-block"
                                                customClass="bx-dice content-circle-dice"
                                                choice="3"
                                                :userBetArr="filterUserBetArrByUserChoose(USER_CHOOSE['3'])"
                                                :user_choose="user_choose"
                                                :amount="amount"
                                                :isPanelWin="isOptionalPanelWin('is_3')"
                                                :shipState="calOptionalPanelChipState('is_3')"
                                                :isOnSendBet="isOnSendBet"
                                                @selectBet="selectBet"
                                                @confirmBet="confirmBet"
                                                @cancelBet="cancelBet">
                                            <DiceFaceThree></DiceFaceThree>

                                            <div class="box-progressBar">
                                                <div class="box-progressBar-info">
                                                    <div class="progress-bar-user progress-bar-player" :style="`width: ${calBetPercent('3')}%`"></div>
                                                </div>
                                            </div>
                                        </PanelPlay>
                                        <FloatChip
                                                v-for="(bet, i) of getOtherBetListByChoose(USER_CHOOSE['3'])" :key="i"
                                                :value="bet.amount"
                                                :choose="bet.choose"
                                                :bound="getPanelPlayฺBound('3-block')">
                                        </FloatChip>
                                    </div>
                                    <div class="sb-box">
                                        <PanelPlay
                                                customClass="bx-dice sc-vertical sc-vertical-5"
                                                choice="45"
                                                :userBetArr="filterUserBetArrByUserChoose(USER_CHOOSE[45])"
                                                :user_choose="user_choose"
                                                :amount="amount"
                                                :isPanelWin="isOptionalPanelWin('is_45')"
                                                :shipState="calOptionalPanelChipState('is_45')"
                                                :isOnSendBet="isOnSendBet"
                                                @selectBet="selectBet"
                                                @confirmBet="confirmBet"
                                                @cancelBet="cancelBet"
                                                style="right: -15px; top: 15%; bottom: 0;">
                                            <div class="box-progressBar">
                                                <div class="box-progressBar-info">
                                                    <div class="progress-bar-user progress-bar-player" :style="`width: ${calBetPercent(45)}%`"></div>
                                                </div>
                                            </div>
                                        </PanelPlay>
                                        <PanelPlay
                                                ref="4-block"
                                                customClass="bx-dice content-circle-dice circle-dice-2"
                                                choice="4"
                                                :userBetArr="filterUserBetArrByUserChoose(USER_CHOOSE['4'])"
                                                :user_choose="user_choose"
                                                :amount="amount"
                                                :isPanelWin="isOptionalPanelWin('is_4')"
                                                :shipState="calOptionalPanelChipState('is_4')"
                                                :isOnSendBet="isOnSendBet"
                                                @selectBet="selectBet"
                                                @confirmBet="confirmBet"
                                                @cancelBet="cancelBet">
                                            <DiceFaceFour></DiceFaceFour>
                                            <div class="box-progressBar">
                                                <div class="box-progressBar-info">
                                                    <div class="progress-bar-user progress-bar-player" :style="`width: ${calBetPercent('4')}%`"></div>
                                                </div>
                                            </div>
                                        </PanelPlay>
                                        <FloatChip
                                                v-for="(bet, i) of getOtherBetListByChoose(USER_CHOOSE['4'])" :key="i"
                                                :value="bet.amount"
                                                :choose="bet.choose"
                                                :bound="getPanelPlayฺBound('4-block')">
                                        </FloatChip>
                                    </div>
                                    <div class="sb-box sb-box-dice-five">
                                        <PanelPlay
                                                ref="5-block"
                                                customClass="bx-dice content-circle-dice circle-dice-2"
                                                choice="5"
                                                :userBetArr="filterUserBetArrByUserChoose(USER_CHOOSE['5'])"
                                                :user_choose="user_choose"
                                                :amount="amount"
                                                :isPanelWin="isOptionalPanelWin('is_5')"
                                                :shipState="calOptionalPanelChipState('is_5')"
                                                :isOnSendBet="isOnSendBet"
                                                @selectBet="selectBet"
                                                @confirmBet="confirmBet"
                                                @cancelBet="cancelBet">
                                            <DiceFaceFive></DiceFaceFive>
                                            <div class="box-progressBar">
                                                <div class="box-progressBar-info">
                                                    <div class="progress-bar-user progress-bar-player" :style="`width: ${calBetPercent('5')}%`"></div>
                                                </div>
                                            </div>
                                        </PanelPlay>
                                        <FloatChip
                                                v-for="(bet, i) of getOtherBetListByChoose(USER_CHOOSE['5'])" :key="i"
                                                :value="bet.amount"
                                                :choose="bet.choose"
                                                :bound="getPanelPlayฺBound('5-block')">
                                        </FloatChip>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="sb-row sb-row-1">
                            <div class="sb-box sb-box-flex-0-9 sb-box-one-point-tree">
                                <PanelPlay
                                        ref="13-block"
                                        customClass="bx-dice"
                                        choice="13"
                                        :userBetArr="filterUserBetArrByUserChoose(USER_CHOOSE['13'])"
                                        :user_choose="user_choose"
                                        :amount="amount"
                                        :isPanelWin="isOptionalPanelWin('is_13')"
                                        :shipState="calOptionalPanelChipState('is_13')"
                                        :isOnSendBet="isOnSendBet"
                                        @selectBet="selectBet"
                                        @confirmBet="confirmBet"
                                        @cancelBet="cancelBet">
                                    <div class="box-progressBar">
                                        <div class="box-progressBar-info">
                                            <div class="progress-bar-user progress-bar-player" :style="`width: ${calBetPercent('13')}%`"></div>
                                        </div>
                                    </div>
                                    <div class="rotate-top-left">
                                        <div class="rotate-top-left-info">
                                            <span class="sb-red">1</span>
                                            <span class="sb-dot"></span>
                                            <span>3</span>
                                        </div>

                                    </div>
                                </PanelPlay>
                                <FloatChip
                                        v-for="(bet, i) of getOtherBetListByChoose(USER_CHOOSE['13'])" :key="i"
                                        :value="bet.amount"
                                        :choose="bet.choose"
                                        :bound="getPanelPlayฺBound('13-block')">
                                </FloatChip>
                            </div>
                            <div class="sb-box sb-box-flex-1-4 sb-box-text-top">
                                <PanelPlay
                                        ref="5LOW-block"
                                        customClass="bx-dice"
                                        choice="5LOW"
                                        :userBetArr="filterUserBetArrByUserChoose(USER_CHOOSE['5LOW'])"
                                        :user_choose="user_choose"
                                        :amount="amount"
                                        :isPanelWin="isOptionalPanelWin('is_5low')"
                                        :shipState="calOptionalPanelChipState('is_5low')"
                                        :isOnSendBet="isOnSendBet"
                                        @selectBet="selectBet"
                                        @confirmBet="confirmBet"
                                        @cancelBet="cancelBet">
                                    <div class="box-progressBar">
                                        <div class="box-progressBar-info">
                                            <div class="progress-bar-user progress-bar-player" :style="`width: ${calBetPercent('5LOW')}%`"></div>
                                        </div>
                                    </div>
                                    <div class="b-five-down">
                                        <span>5</span>
                                        <span class="sb-red">ต่ำ</span>
                                    </div>
                                </PanelPlay>
                                <FloatChip
                                        v-for="(bet, i) of getOtherBetListByChoose(USER_CHOOSE['5LOW'])" :key="i"
                                        :value="bet.amount"
                                        :choose="bet.choose"
                                        :bound="getPanelPlayฺBound('5LOW-block')">
                                </FloatChip>
                            </div>
                            <div class="sb-box sb-box-11Hilo sb-box-flex-2 sb-box-text-top">
                                <PanelPlay
                                        ref="HILO11-block"
                                        customClass="bx-dice"
                                        choice="HILO11"
                                        :userBetArr="filterUserBetArrByUserChoose(USER_CHOOSE['HILO11'])"
                                        :user_choose="user_choose"
                                        :amount="amount"
                                        :isPanelWin="isPanelWin(GAME_RESULT.HILO11, gameResult)"
                                        :shipState="calPanelChipState(GAME_RESULT.HILO11)"
                                        :isOnSendBet="isOnSendBet"
                                        @selectBet="selectBet"
                                        @confirmBet="confirmBet"
                                        @cancelBet="cancelBet">

                                    <div class="b-11-hilo">
                                        <span class="sb-red">
                                           <ul>
                                               <li>ไฮ</li>
                                               <li>
                                                   <p>11</p>
                                               </li>
                                               <li>โล</li>
                                           </ul>
                                        </span>
                                    </div>
                                    <div class="box-progressBar">
                                        <div class="box-progressBar-info">
                                            <div class="progress-bar-user progress-bar-player" :style="`width: ${calBetPercent('HILO11')}%`"></div>
                                        </div>
                                    </div>
                                </PanelPlay>
                                <FloatChip
                                        v-for="(bet, i) of getOtherBetListByChoose(USER_CHOOSE['HILO11'])" :key="i"
                                        :value="bet.amount"
                                        :choose="bet.choose"
                                        :bound="getPanelPlayฺBound('HILO11-block')">
                                </FloatChip>
                            </div>
                            <div class="sb-box sb-box-flex-1-4 sb-box-text-top">
                                <PanelPlay
                                        ref="6LOW-block"
                                        customClass="bx-dice"
                                        choice="6LOW"
                                        :userBetArr="filterUserBetArrByUserChoose(USER_CHOOSE['6LOW'])"
                                        :user_choose="user_choose"
                                        :amount="amount"
                                        :isPanelWin="isOptionalPanelWin('is_6low')"
                                        :shipState="calOptionalPanelChipState('is_6low')"
                                        :isOnSendBet="isOnSendBet"
                                        @selectBet="selectBet"
                                        @confirmBet="confirmBet"
                                        @cancelBet="cancelBet">
                                    <div class="box-progressBar">
                                        <div class="box-progressBar-info">
                                            <div class="progress-bar-user progress-bar-player" :style="`width: ${calBetPercent('6LOW')}%`"></div>
                                        </div>
                                    </div>
                                    <div class="b-six-down">
                                        <span>6</span>
                                        <span class="sb-red">ต่ำ</span>
                                    </div>
                                </PanelPlay>
                                <FloatChip
                                        v-for="(bet, i) of getOtherBetListByChoose(USER_CHOOSE['6LOW'])" :key="i"
                                        :value="bet.amount"
                                        :choose="bet.choose"
                                        :bound="getPanelPlayฺBound('6LOW-block')">
                                </FloatChip>
                            </div>
                            <div class="sb-box sb-box-flex-0-9 sb-box-four-point-six">
                                <PanelPlay
                                        ref="46-block"
                                        customClass="bx-dice"
                                        choice="46"
                                        :userBetArr="filterUserBetArrByUserChoose(USER_CHOOSE['46'])"
                                        :user_choose="user_choose"
                                        :amount="amount"
                                        :isPanelWin="isOptionalPanelWin('is_46')"
                                        :shipState="calOptionalPanelChipState('is_46')"
                                        :isOnSendBet="isOnSendBet"
                                        @selectBet="selectBet"
                                        @confirmBet="confirmBet"
                                        @cancelBet="cancelBet">
                                    <div class="box-progressBar">
                                        <div class="box-progressBar-info">
                                            <div class="progress-bar-user progress-bar-player" :style="`width: ${calBetPercent('46')}%`"></div>
                                        </div>
                                    </div>
                                    <div class="rotate-top-right b-four-point-six">
                                        <div class="rotate-top-right-info">
                                            <span>4</span>
                                            <span class="sb-dot"></span>
                                            <span>6</span>
                                        </div>
                                    </div>
                                </PanelPlay>
                                <FloatChip
                                        v-for="(bet, i) of getOtherBetListByChoose(USER_CHOOSE['46'])" :key="i"
                                        :value="bet.amount"
                                        :choose="bet.choose"
                                        :bound="getPanelPlayฺBound('46-block')">
                                </FloatChip>
                            </div>
                        </div>
                        <div class="sb-row">
                            <div class="sb-box sb-box-four-point-one">
                                <PanelPlay
                                        ref="41-block"
                                        customClass="bx-dice"
                                        choice="41"
                                        :userBetArr="filterUserBetArrByUserChoose(USER_CHOOSE['41'])"
                                        :user_choose="user_choose"
                                        :amount="amount"
                                        :isPanelWin="isOptionalPanelWin('is_41')"
                                        :shipState="calOptionalPanelChipState('is_41')"
                                        :isOnSendBet="isOnSendBet"
                                        @selectBet="selectBet"
                                        @confirmBet="confirmBet"
                                        @cancelBet="cancelBet">
                                    <div class="b-four-point-one">
                                        <span class="sb-red">4</span>
                                        <span class="sb-dot sb-red"></span>
                                        <span class="sb-red">1</span>
                                    </div>
                                    <div class="box-progressBar">
                                        <div class="box-progressBar-info">
                                            <div class="progress-bar-user progress-bar-player" :style="`width: ${calBetPercent('41')}%`"></div>
                                        </div>
                                    </div>
                                </PanelPlay>
                                <FloatChip
                                        v-for="(bet, i) of getOtherBetListByChoose(USER_CHOOSE['41'])" :key="i"
                                        :value="bet.amount"
                                        :choose="bet.choose"
                                        :bound="getPanelPlayฺBound('41-block')">
                                </FloatChip>
                            </div>
                            <div class="sb-box sb-box-flex-4"></div>
                            <div class="sb-box sb-box-six-point-one">
                                <PanelPlay
                                        ref="61-block"
                                        customClass="bx-dice"
                                        choice="61"
                                        :userBetArr="filterUserBetArrByUserChoose(USER_CHOOSE['61'])"
                                        :user_choose="user_choose"
                                        :amount="amount"
                                        :isPanelWin="isOptionalPanelWin('is_61')"
                                        :shipState="calOptionalPanelChipState('is_61')"
                                        :isOnSendBet="isOnSendBet"
                                        @selectBet="selectBet"
                                        @confirmBet="confirmBet"
                                        @cancelBet="cancelBet">
                                    <div class="b-six-point-one">
                                        <span>6</span>
                                        <span class="sb-dot"></span>
                                        <span class="sb-red">1</span>
                                    </div>
                                    <div class="box-progressBar">
                                        <div class="box-progressBar-info">
                                            <div class="progress-bar-user progress-bar-player" :style="`width: ${calBetPercent('61')}%`"></div>
                                        </div>
                                    </div>
                                </PanelPlay>
                                <FloatChip
                                        v-for="(bet, i) of getOtherBetListByChoose(USER_CHOOSE['61'])" :key="i"
                                        :value="bet.amount"
                                        :choose="bet.choose"
                                        :bound="getPanelPlayฺBound('61-block')">
                                </FloatChip>
                            </div>
                        </div>
                        <div class="sb-row">
                            <div class="sb-box sb-box-six-point-two">
                                <PanelPlay
                                        ref="62-block"
                                        customClass="bx-dice"
                                        choice="62"
                                        :userBetArr="filterUserBetArrByUserChoose(USER_CHOOSE['62'])"
                                        :user_choose="user_choose"
                                        :amount="amount"
                                        :isPanelWin="isOptionalPanelWin('is_62')"
                                        :shipState="calOptionalPanelChipState('is_62')"
                                        :isOnSendBet="isOnSendBet"
                                        @selectBet="selectBet"
                                        @confirmBet="confirmBet"
                                        @cancelBet="cancelBet">
                                    <div class="b-six-point-two">
                                        <span>6</span>
                                        <span class="sb-dot"></span>
                                        <span>2</span>
                                    </div>
                                    <div class="box-progressBar">
                                        <div class="box-progressBar-info">
                                            <div class="progress-bar-user progress-bar-player" :style="`width: ${calBetPercent('62')}%`"></div>
                                        </div>
                                    </div>
                                </PanelPlay>
                                <FloatChip
                                        v-for="(bet, i) of getOtherBetListByChoose(USER_CHOOSE['62'])" :key="i"
                                        :value="bet.amount"
                                        :choose="bet.choose"
                                        :bound="getPanelPlayฺBound('62-block')">
                                </FloatChip>
                            </div>
                            <div class="sb-box sb-box-flex-4"></div>
                            <div class="sb-box sb-box-five-two">
                                <PanelPlay
                                        ref="52-block"
                                        customClass="bx-dice"
                                        choice="52"
                                        :userBetArr="filterUserBetArrByUserChoose(USER_CHOOSE['52'])"
                                        :user_choose="user_choose"
                                        :amount="amount"
                                        :isPanelWin="isOptionalPanelWin('is_52')"
                                        :shipState="calOptionalPanelChipState('is_52')"
                                        :isOnSendBet="isOnSendBet"
                                        @selectBet="selectBet"
                                        @confirmBet="confirmBet"
                                        @cancelBet="cancelBet">
                                    <div class="b-five-two">
                                        <span>5</span>
                                        <span class="sb-dot"></span>
                                        <span>2</span>
                                    </div>
                                    <div class="box-progressBar">
                                        <div class="box-progressBar-info">
                                            <div class="progress-bar-user progress-bar-player" :style="`width: ${calBetPercent('52')}%`"></div>
                                        </div>
                                    </div>
                                </PanelPlay>
                                <FloatChip
                                        v-for="(bet, i) of getOtherBetListByChoose(USER_CHOOSE['52'])" :key="i"
                                        :value="bet.amount"
                                        :choose="bet.choose"
                                        :bound="getPanelPlayฺBound('52-block')">
                                </FloatChip>
                            </div>
                        </div>
                        <div class="sb-row">
                            <div class="sb-box sb-box-flex-1-5 sb-box-one-two-tree">
                                <PanelPlay
                                        ref="123-block"
                                        customClass="bx-dice"
                                        choice="123"
                                        :userBetArr="filterUserBetArrByUserChoose(USER_CHOOSE['123'])"
                                        :user_choose="user_choose"
                                        :amount="amount"
                                        :isPanelWin="isOptionalPanelWin('is_123')"
                                        :shipState="calOptionalPanelChipState('is_123')"
                                        :isOnSendBet="isOnSendBet"
                                        @selectBet="selectBet"
                                        @confirmBet="confirmBet"
                                        @cancelBet="cancelBet">
                                    <div class="rotate-top-right b-one-two-tree">
                                        <div class="rotate-top-right-info">
                                            <span class="sb-red">1</span>
                                            <span class="sb-dot"></span>
                                            <span>2</span>
                                            <span class="sb-dot"></span>
                                            <span>3</span>
                                        </div>
                                    </div>
                                    <div class="box-progressBar">
                                        <div class="box-progressBar-info">
                                            <div class="progress-bar-user progress-bar-player" :style="`width: ${calBetPercent('123')}%`"></div>
                                        </div>
                                    </div>
                                </PanelPlay>
                                <FloatChip
                                        v-for="(bet, i) of getOtherBetListByChoose(USER_CHOOSE['123'])" :key="i"
                                        :value="bet.amount"
                                        :choose="bet.choose"
                                        :bound="getPanelPlayฺBound('123-block')">
                                </FloatChip>
                            </div>
                            <div class="sb-box sb-box-text-bottom">
                                <PanelPlay
                                        ref="63-block"
                                        customClass="bx-dice"
                                        choice="63"
                                        :userBetArr="filterUserBetArrByUserChoose(USER_CHOOSE['63'])"
                                        :user_choose="user_choose"
                                        :amount="amount"
                                        :isPanelWin="isOptionalPanelWin('is_63')"
                                        :shipState="calOptionalPanelChipState('is_63')"
                                        :isOnSendBet="isOnSendBet"
                                        @selectBet="selectBet"
                                        @confirmBet="confirmBet"
                                        @cancelBet="cancelBet">
                                    <div class="b-two-point-tree">
                                        <span>6</span>
                                        <span class="sb-dot"></span>
                                        <span>3</span>
                                    </div>
                                    <div class="box-progressBar">
                                        <div class="box-progressBar-info">
                                            <div class="progress-bar-user progress-bar-player" :style="`width: ${calBetPercent('63')}%`"></div>
                                        </div>
                                    </div>
                                </PanelPlay>
                                <FloatChip
                                        v-for="(bet, i) of getOtherBetListByChoose(USER_CHOOSE['63'])" :key="i"
                                        :value="bet.amount"
                                        :choose="bet.choose"
                                        :bound="getPanelPlayฺBound('63-block')">
                                </FloatChip>
                            </div>
                            <div class="sb-box sb-box-text-bottom">
                                <PanelPlay
                                        ref="42-block"
                                        customClass="bx-dice"
                                        choice="42"
                                        :userBetArr="filterUserBetArrByUserChoose(USER_CHOOSE['42'])"
                                        :user_choose="user_choose"
                                        :amount="amount"
                                        :isPanelWin="isOptionalPanelWin('is_42')"
                                        :shipState="calOptionalPanelChipState('is_42')"
                                        :isOnSendBet="isOnSendBet"
                                        @selectBet="selectBet"
                                        @confirmBet="confirmBet"
                                        @cancelBet="cancelBet">
                                    <div class="b-fore-point-two">
                                        <span class="sb-red">4</span>
                                        <span class="sb-dot"></span>
                                        <span>2</span>
                                    </div>
                                    <div class="box-progressBar">
                                        <div class="box-progressBar-info">
                                            <div class="progress-bar-user progress-bar-player" :style="`width: ${calBetPercent('42')}%`"></div>
                                        </div>
                                    </div>
                                </PanelPlay>
                                <FloatChip
                                        v-for="(bet, i) of getOtherBetListByChoose(USER_CHOOSE['42'])" :key="i"
                                        :value="bet.amount"
                                        :choose="bet.choose"
                                        :bound="getPanelPlayฺBound('42-block')">
                                </FloatChip>
                            </div>
                            <div class="sb-box sb-box-text-bottom">
                                <PanelPlay
                                        ref="53-block"
                                        customClass="bx-dice"
                                        choice="53"
                                        :userBetArr="filterUserBetArrByUserChoose(USER_CHOOSE['53'])"
                                        :user_choose="user_choose"
                                        :amount="amount"
                                        :isPanelWin="isOptionalPanelWin('is_53')"
                                        :shipState="calOptionalPanelChipState('is_53')"
                                        :isOnSendBet="isOnSendBet"
                                        @selectBet="selectBet"
                                        @confirmBet="confirmBet"
                                        @cancelBet="cancelBet">
                                    <div class="b-five-point-three">
                                        <span>5</span>
                                        <span class="sb-dot"></span>
                                        <span>3</span>
                                    </div>
                                    <div class="box-progressBar">
                                        <div class="box-progressBar-info">
                                            <div class="progress-bar-user progress-bar-player" :style="`width: ${calBetPercent('53')}%`"></div>
                                        </div>
                                    </div>
                                </PanelPlay>
                                <FloatChip
                                        v-for="(bet, i) of getOtherBetListByChoose(USER_CHOOSE['53'])" :key="i"
                                        :value="bet.amount"
                                        :choose="bet.choose"
                                        :bound="getPanelPlayฺBound('53-block')">
                                </FloatChip>
                            </div>
                            <div class="sb-box sb-box-text-bottom">
                                <PanelPlay
                                        ref="51-block"
                                        customClass="bx-dice"
                                        choice="51"
                                        :userBetArr="filterUserBetArrByUserChoose(USER_CHOOSE['51'])"
                                        :user_choose="user_choose"
                                        :amount="amount"
                                        :isPanelWin="isOptionalPanelWin('is_51')"
                                        :shipState="calOptionalPanelChipState('is_51')"
                                        :isOnSendBet="isOnSendBet"
                                        @selectBet="selectBet"
                                        @confirmBet="confirmBet"
                                        @cancelBet="cancelBet">
                                    <div class="b-five-point-one">
                                        <span>5</span>
                                        <span class="sb-dot"></span>
                                        <span class="sb-red">1</span>
                                    </div>
                                    <div class="box-progressBar">
                                        <div class="box-progressBar-info">
                                            <div class="progress-bar-user progress-bar-player" :style="`width: ${calBetPercent('51')}%`"></div>
                                        </div>
                                    </div>
                                </PanelPlay>
                                <FloatChip
                                        v-for="(bet, i) of getOtherBetListByChoose(USER_CHOOSE['51'])" :key="i"
                                        :value="bet.amount"
                                        :choose="bet.choose"
                                        :bound="getPanelPlayฺBound('51-block')">
                                </FloatChip>
                            </div>
                            <div class="sb-box sb-box-flex-1-5 sb-box-four-point-five-point-six">
                                <PanelPlay
                                        ref="456-block"
                                        customClass="bx-dice"
                                        choice="456"
                                        :userBetArr="filterUserBetArrByUserChoose(USER_CHOOSE['456'])"
                                        :user_choose="user_choose"
                                        :amount="amount"
                                        :isPanelWin="isOptionalPanelWin('is_456')"
                                        :shipState="calOptionalPanelChipState('is_456')"
                                        :isOnSendBet="isOnSendBet"
                                        @selectBet="selectBet"
                                        @confirmBet="confirmBet"
                                        @cancelBet="cancelBet">
                                    <div class="rotate-top-left b-four-point-five-point-six">
                                        <div class="rotate-top-left-info">
                                            <span class="sb-red">4</span>
                                            <span class="sb-dot"></span>
                                            <span>5</span>
                                            <span class="sb-dot"></span>
                                            <span>6</span>
                                        </div>
                                    </div>
                                    <div class="box-progressBar">
                                        <div class="box-progressBar-info">
                                            <div class="progress-bar-user progress-bar-player" :style="`width: ${calBetPercent('456')}%`"></div>
                                        </div>
                                    </div>
                                </PanelPlay>
                                <FloatChip
                                        v-for="(bet, i) of getOtherBetListByChoose(USER_CHOOSE['456'])" :key="i"
                                        :value="bet.amount"
                                        :choose="bet.choose"
                                        :bound="getPanelPlayฺBound('456-block')">
                                </FloatChip>
                            </div>
                        </div>
                    </div>

                </div>
                <div class="box-panel-play-tool">
                    <div class="box-coin">
                        <GameChipForSelect
                                v-for="(chip, i) of chipForSelectArr" :key="i"
                                :value="chip"
                                :active="amount === chip"
                                @click="selectChip(chip)">
                        </GameChipForSelect>
                    </div>
                </div>
            </div>
            <div id="playerDiv"></div>

            <GameTopPlayer
                    :list="bet_user_ranking"
                    :bet_list="bet_user_bet_list"
                    :tableType="tableType">
            </GameTopPlayer>
        </div>
        <Chat v-if="tableId && noChat !== 'yes'" :data_room_id="`_table_id=${tableId}`"></Chat>

        <modal name="result-modal" :height="'auto'" width="98%" classes="modal-background-transparent">
            <div class="flex-center-center">
                <div class="Aligner-item Aligner-item-result">
                    <Result :profitLoss="profitLoss"></Result>
                </div>
            </div>
        </modal>
<!--                <modal name="result-modal2" :height="'auto'" width="98%" classes="modal-background-transparent">-->
<!--                    <div class="flex-center-center">-->
<!--                        <div class="Aligner-item Aligner-item-result">-->
<!--                            <Result :profitLoss="0"></Result>-->
<!--                        </div>-->
<!--                    </div>-->
<!--                </modal>-->

        <modal name="not-bet-modal" :height="'auto'" width="98%" classes="modal-background-transparent modalAlert"
               @opened="autoCloseModal('not-bet-modal')">
            <div class="flex-center-center">
                <div class="Aligner-item" style="color: white;">
                    รอบยังไม่เปิด
                    <br>
                    กรุณารอ
                </div>
            </div>
        </modal>

        <modal name="bet-message-modal" :height="'auto'" width="98%" classes="modal-background-transparent modalAlert"
               @opened="autoCloseModal('bet-message-modal')">
            <div class="flex-center-center">
                <div class="Aligner-item" style="color: white;">
                    <p><i class="fas fa-exclamation-triangle"></i> {{betMessage}}</p>
                </div>
            </div>
        </modal>

        <modal name="select-bet-limit-modal" :height="'auto'" width="98%" class="editModalSelect"
               classes="modal-background-transparent"
               :clickToClose="false">
            <div class="flex-center-center">
                <div class="Aligner-item Aligner-item-select-value" style="color: white; text-align: center;">
                    <p><i class="fas fa-coins"></i> เลือกวงเงิน</p>
                    <button v-for="(betLimit, key) of betLimitArr" :key="key"
                            class="btn btn-cs" style="margin: 4px 0; width: 100%;"
                            @click="setBetLimit(betLimit.config_id)">
                        {{betLimit.min_bet}} - {{betLimit.max_bet}}
                    </button>
                </div>
            </div>
        </modal>

        <b-modal ref="error-message-modal"
                 hide-header
                 centered
                 ok-title="ตกลง"
                 cancel-title="รอ"
                 @ok="$router.replace({name: 'GameType'})">
            <div class="modal-notify-text-center">
                <h5>{{loadGameTableErrorMessage}}</h5>
            </div>
        </b-modal>

        <GoLobbyModal></GoLobbyModal>
    </div>
</template>
<script>

    import GameMixin from '@/mixins/Game'
    import GameTableList from '@/components/game/TableList'
    import GameHeader from '@/components/game/Header'
    import GameVerticalTool from '@/components/game/VerticalTool'
    import UserMixin from '@/mixins/UserMixin'
    import Timer from '@/lib/Timer'
    import debounce from '@/debounce'

    import randomBetween from '@/lib/randomBetween'
    import GameTopPlayer from '@/components/game/TopPlayer'
    import GameChipForSelect from '@/components/game/ChipForSelect'
    import {GAME_STATUS_KEY, CHIP_MAP} from '@/components/game/Constant'
    import {USER_CHOOSE, GAME_RESULT_KEY, SOUND, soundLoad, USER_CHOOSE_MAP_TO_BET} from '@/components/game/sicbo/Constant'
    import FloatChip from '@/components/game/FloatChip'
    import waitForSecond from '@/lib/waitForSecond'
    import Stat from './sicbo/Stat'
    import PanelPlay from '@/components/game/sicbo/PanelPlay'

    import DiceFaceOne from '@/components/game/sicbo/dice-face/One'
    import DiceFaceTwo from '@/components/game/sicbo/dice-face/Two'
    import DiceFaceThree from '@/components/game/sicbo/dice-face/Three'
    import DiceFaceFour from '@/components/game/sicbo/dice-face/Four'
    import DiceFaceFive from '@/components/game/sicbo/dice-face/Five'
    import DiceFaceSix from '@/components/game/sicbo/dice-face/Six'

    import DiceFace from '@/components/game/sicbo/DiceFace'
    import Chat from '@/components/Chat'
    import TicketList from '@/components/game/TicketList'
    import Result from '@/components/game/Result'
    import VideoPlayer from './VideoPlayer'
    import UserBetChip from './UserBetChip'
    import GamePlay3D from './sicbo/GamePlay3D'
    import SoundToggleButton from './SoundToggleButton.vue'
    import FloatButtomRightPanel from '@/components/FloatButtomRightPanel'
    import FloatOpenDice from '@/components/game/sicbo/FloatOpenDice'


    const SECOND_START = 22

    export default {
        name: 'MiniSicBo',
        components: {
            FloatOpenDice,
            FloatButtomRightPanel,
            GameTableList,
            GameHeader,
            GameVerticalTool,
            GameTopPlayer,
            GameChipForSelect,
            FloatChip,
            Stat,
            PanelPlay,
            DiceFaceOne,
            DiceFaceTwo,
            DiceFaceThree,
            DiceFaceFour,
            DiceFaceFive,
            DiceFaceSix,
            DiceFace,
            Chat,
            TicketList,
            Result,
            VideoPlayer,
            UserBetChip,
            GamePlay3D,
            SoundToggleButton,
        },
        mixins: [
            GameMixin,
            UserMixin,
        ],
        data() {
            return {
                chip_arr: [5, 10, 50, 100, 500, 1000],
                amount: 10,
                user_choose: 0,
                otherBetChipArr: [],
                otherBetChooseArr: [],
                otherBetArr: [],
                otherBetTimer: null,
                totalAmount: 0,
                USER_CHOOSE: null,
                GAME_STATUS: null,
                GAME_RESULT: null,
                timer: null,
                secondCountDown: SECOND_START,
                loading: null,
                diceFromAdd: [],
                isTogglePlayBox: false,
                randomBetPercentMap: {
                    'hi': 0,
                    'low': 0,
                    'hilo11': 0,
                    'other': 0,
                },
            }
        },
        computed: {
            secondRemain() {
                const now = this.$moment()
                if (!this.countDownStart) {
                    return SECOND_START

                }
                const timestamp = this.$moment(this.countDownStart)
                const remain = parseInt(this.$moment.duration(now.diff(timestamp)).asSeconds())
                return (SECOND_START - remain > 0) ? SECOND_START - remain : 0
            },
            dice() {
                if (this.diceFromAdd.length > 0) {
                    return this.diceFromAdd
                } else if (this.gameInfo) {
                    return this.gameInfo.dice
                } else {
                    return []
                }
            },
            dicePoint() {
                if (!this.dice) {
                    return '-'
                }
                if (this.dice.length === 0) {
                    return '-'
                }
                return this.dice.reduce((sum, item) => {
                    return parseInt(sum) + parseInt(item)
                }, 0)
            },
            isFloatOpenDicePanelOpen() {
                return parseInt(this.gameStatus) === GAME_STATUS_KEY.DEALING || parseInt(this.gameStatus) === GAME_STATUS_KEY.FINISH
            },
        },
        watch: {
            gameStatus(newVal, oldVal) {
                if (this.GAME_STATUS.DEALING === parseInt(newVal)) {
                    this.playStopBetSound()
                    this.cancelBet()
                }
                if (parseInt(oldVal) === this.GAME_STATUS.DEALING && parseInt(newVal) === this.GAME_STATUS.FINISH) {//end round

                    this.playGameSound(SOUND['POINT'][parseInt(this.dicePoint)])

                    setTimeout(() => {
                        this.clearDiceFromAdd()
                        this.clearUserBetArr()
                        this.clearOtherBet()
                        this.clearBet()
                        this.onRoundEnd()
                    }, 2 * 1000)
                }
                if (parseInt(newVal) === this.GAME_STATUS.COUNTDOWN) {

                    setTimeout(() => {
                        const shakeSound = SOUND['SHAKE'][randomBetween(0, SOUND['SHAKE'].length - 1)]
                        this.playGameSound(shakeSound)
                    }, 2000)
                    
                    this.playStartBetSound()

                    this.startCountDown()
                    this.startOtherBet()
                    this.generateRandomBetPercent()
                } else {
                    this.stopCountDown()
                    this.stopOtherBet()
                }
                if(this.noTopSection !== 'yes') {
                    if (parseInt(newVal) === this.GAME_STATUS.DEALING) {
                        this.isTogglePlayBox = true
                    } else {
                        this.isTogglePlayBox = false
                    }
                }
            },
            secondRemain(newVal, oldVal) {
                if (oldVal === 0 && newVal > 0) {
                    this.stopCountDown()
                    this.startCountDown()
                    if (this.gameStatus === this.GAME_STATUS.COUNTDOWN) {
                        this.stopOtherBet()
                        this.startOtherBet()
                    }
                }
            }
        },
        methods: {
            toggleStatePanel() {
                this.$modal.show('drawer-stat-right')
            },
            showLoadTableGameError() {
                this.$refs['error-message-modal'].show()
            },
            filterUserBetArrByUserChoose(user_choose) {
                return this.userBetArr.filter((bet) => {
                    return bet.user_choose === user_choose
                })
            },
            getPanelPlayฺBound(ref) {
                const width = this.$refs[ref].$el.offsetWidth
                const height = this.$refs[ref].$el.offsetHeight
                return {width, height}
            },
            getOtherBetListByChoose(choose) {
                return this.otherBetArr.filter((bet) => {
                    return parseInt(bet.choose) === parseInt(choose)
                })
            },
            otherBet() {
                this.otherBetArr.push({
                    choose: this.otherBetChooseArr[randomBetween(0, this.otherBetChooseArr.length - 1)],
                    amount: this.otherBetChipArr[randomBetween(0, this.otherBetChipArr.length - 1)],
                })
            },
            clearOtherBet() {
                this.otherBetArr = []
            },
            startOtherBet: debounce(async function () {

                await waitForSecond(2000)

                if (this.otherBetTimer) {
                    this.otherBetTimer.start()
                    return
                }
                this.otherBetTimer = Timer(() => {
                    this.otherBet()
                    let nextTimeout = 1000
                    if (parseInt(this.secondCountDown) > 0) {
                        nextTimeout = 33 * this.secondCountDown
                    }
                    return nextTimeout + randomBetween(0, 44)
                }, 1000)
            }, 100),
            stopOtherBet: debounce(function () {
                if (!this.otherBetTimer) {
                    return
                }
                this.otherBetTimer.stop()
            }, 100),
            async confirmBet() {
                this._confirmBet()
            },
            async onDice(data) {
                if (!this.currentGame || !this.currentTable) {
                    return
                }
                if (parseInt(data.gbs_id) === parseInt(this.currentGame.gbs_id) &&
                    parseInt(data.game_index) === parseInt(this.currentTable.table_type)) {
                    this.diceFromAdd = []
                    for (const item of data.dice) {
                        await waitForSecond(500)
                        setTimeout(() => {
                            this.playGameSound(SOUND['DICE'][parseInt(item)])
                        }, 300)
                        this.diceFromAdd.push(item)
                    }
                }
            },
            clearDiceFromAdd() {
                this.diceFromAdd = []
            },
            togglePlayBox() {
                this.isTogglePlayBox = !this.isTogglePlayBox
            },
            isPanelWin(panel, result) {
                return panel === result
            },
            isOptionalPanelWin(option) {
                if (!this.currentGame) {
                    return false
                }
                return this.currentGame[option]
            },
            calPanelChipState(panel) {
                if (!this.gameResult) {
                    return 'playerPlace'
                }
                if (panel === this.gameResult) {
                    return 'goPlayer'
                } else {
                    return 'goBanker'
                }
            },
            calOptionalPanelChipState(option) {
                if (!this.gameResult) {
                    return 'playerPlace'
                }
                if (!this.currentGame) {
                    return 'playerPlace'
                }
                if (this.currentGame[option]) {
                    return 'goPlayer'
                } else {
                    return 'goBanker'
                }
            },
            calBetPercent(user_choose) {
                if(!this.currentGame) {
                    return 0
                }
                if(!this.currentGame.bet_sum) {
                    if(user_choose === 'HI') {
                        return this.randomBetPercentMap['hi']
                    } else if(user_choose === 'LOW') {
                        return this.randomBetPercentMap['low']
                    } else if(user_choose === 'HILO11') {
                        return this.randomBetPercentMap['hilo11']
                    } else{
                        return this.randomBetPercentMap['other']
                    }
                }
                const bet_sum = this.currentGame.bet_sum
                const bet = this.currentGame[USER_CHOOSE_MAP_TO_BET[user_choose]]
                if(!bet) {
                    return 0
                }
                return (parseFloat(bet) / parseFloat(bet_sum)) * 100
            },
            generateRandomBetPercent() {
                let percentRemain = 100
                const hi = randomBetween(20, 80)
                percentRemain -= hi
                this.randomBetPercentMap = {
                    'hi': hi,
                    'low': percentRemain,
                    'hilo11': randomBetween(0, 10),
                    'other': randomBetween(0, 4),
                }
            },
        },
        created() {
            this.USER_CHOOSE = USER_CHOOSE
            this.GAME_STATUS = GAME_STATUS_KEY
            this.GAME_RESULT = GAME_RESULT_KEY

            for (const key in CHIP_MAP) {
                this.otherBetChipArr.push(key)
            }
            for (const key in this.USER_CHOOSE) {
                this.otherBetChooseArr.push(this.USER_CHOOSE[key])
            }
        },
        async mounted() {
            // this.$modal.show('result-modal2')
            if (this.gameStatus === this.GAME_STATUS.COUNTDOWN) {
                this.startCountDown()
                this.startOtherBet()
            }
            this.amount = this.chipForSelectArr[0]
            soundLoad()
            this.generateRandomBetPercent()
        },
        beforeDestroy() {
            this.stopCountDown()
            this.stopOtherBet()
        }
    }
</script>
<style scoped src="@/assets/css/baccarat-style.css"></style>
<style scoped src="@/assets/css/animate-games.css"></style>
<style>
    @media (orientation: landscape) {
        .LiffGameHome .box-panel-play{height: 100%;}
        .LiffGameHome  #bankerDiv {
            width: 50px;
            height: 50px;
            position: absolute;
            margin: auto;
            right: 0;
            bottom: 100px;
            left: 0;
            z-index: 2;
        }
        .LiffGameHome #playerDiv {
            width: 5vw;
            height: 5vw;
            position: fixed;
            bottom: 0;
            left: 0;
            right: 0;
            margin: 0 auto;
        }
        .LiffGameHome  .bottom-stream{position: relative;height: 100%;box-shadow: none!important;border: 0;}
        .LiffGameHome .content-panel-play {display: block;width: auto;height: 100%;padding-bottom: 50px;}
        .LiffGameHome .content-panel-play-miniBacarat,
        .LiffGameHome .content-panel-play-DragonTiger{
            position: relative!important;
        }
        .LiffGameHome .box-panel-play-tool{
            position: absolute!important;
            right: 0;
            left: 0;
            bottom: 0;
            justify-content: center;
            border-radius: 0;
        }
        .LiffGameHome .box-coin {flex-direction: row;}
        .LiffGameHome .st-content-play{height: 100%;}
        .LiffGameHome .MiniBaccarat .box-coin {flex-direction: row;}
        .LiffGameHome .b-coin {
            width: 40px;
            height: 40px;
            margin: 0 6px;
        }
        .LiffGameHome .float-stream{
            position: fixed;
            flex-direction: column;
            justify-content: space-around;
            top: 0;
            left: 0;
            right: 0;
            bottom: 0;
            overflow: hidden;
        }
        .LiffGameHome .GameTopPlayer.box-top-user{
            position: fixed;
            top: 0;
            width: 300px;
            height: 57px;
            z-index: 100;
        }
        .LiffGameHome .content-sicbo{
            position: relative!important;
            width: 100%!important;
            height: 100%!important;
        }
        .LiffGameHome .content-panel-sicbo{
            position: relative!important;
            height: 100%!important;
            margin: 0!important;
            padding-bottom: 60px!important;
        }
        .LiffGameHome .toggle-box .content-sicbo {bottom: 0!important;}
    }
</style>
<style scoped>
    .box-menu-game {
        z-index: 14 !important;
    }

    .content-page {
        /*height: calc(100vh - 60px);*/
        height: 90vh;
        background-color: #fff;
        border-radius: 20px 20px 0px 0px;
        margin-top: -15px;
        padding: 15px;
    }

    .close-bt {
        background-color: #fff !important;
        left: 1px;
        color: #000;
    }

    .btn-player, .btn-banker {
        bottom: -2px;
    }




    .user-list-detail {
        line-height: 20px;
    }

    .flex-center-center {
        display: flex;
        justify-content: center;
        align-items: center;
    }

    .balance-box {
        position: absolute;
        bottom: 1px;
        right: 1px;
    }

    .modal-notify-text-center {
        display: flex;
        justify-content: center;
        height: 100px;
        align-items: center;
    }

    .btn-cs {
        background-color: rgba(243, 114, 212, 0.9);
        border-radius: 20px;
        color: #fff !important;
        text-align: center !important;
        padding: 2px 5px !important;
    }


    .Aligner-item{
        font-family: "Athiti", sans-serif;
        background-color: rgba(0,0,0,0.9);
        padding: 15px 10px !important;
        border-radius: 10px !important;
    }

    .Aligner-item p{
        font-weight: bold;
        margin-bottom: 5px;
        font-size: 13px;
    }

    .Aligner-item i{
        color: #fab602;
    }


    .FloatChip {
        width: 18px;
    }

    .dice-row {
        display: flex;
        padding: 4px;
    }

    .dice-row * {
        margin: 0 2px;
    }

    .bounce-enter-active {
        animation: bounce-in .5s;
    }

    .bounce-leave-active {
        animation: bounce-in .3s reverse;
    }

    @keyframes bounce-in {
        0% {
            transform: scale(0);
        }
        50% {
            transform: scale(1.1);
        }
        100% {
            transform: scale(1);
        }
    }

    .slide-top {
        -webkit-animation: slide-top 2s cubic-bezier(0.250, 0.460, 0.450, 0.940) both;
        animation: slide-top 2s cubic-bezier(0.250, 0.460, 0.450, 0.940) both;
        animation-iteration-count: infinite;
    }

    @keyframes slide-top {
        0% {
            -webkit-transform: translateY(0px);
            transform: translateY(0px);
        }

        100% {
            -webkit-transform: translateY(-60px);
            transform: translateY(-60px);
        }
    }



</style>