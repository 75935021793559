<template>
    <div @click="selectBet(USER_CHOOSE[choice], $event)"
        :class="`${customClass} ${calBtnBlockPlayIsActive(USER_CHOOSE[choice])}`">
        <ConfirmPanel v-show="isShowConfirmPanel"
        @confirm="confirmBet"
        @cancel="cancelBet">
        </ConfirmPanel>
        <DummyUser></DummyUser>
        <div v-show="isPanelWin" class="box-result"></div>
        <UserBetChip 
        v-if="calBtnBlockPlayIsActive(USER_CHOOSE[choice]) === 'active-select'" 
        :state="shipState"
        :chip="(isShowExistBet) ? latestChip : amount" 
        :value="(isShowExistBet) ? calTotalAmount : totalAmountToBet" 
        :panel="USER_CHOOSE[choice]">
        </UserBetChip>
        <!-- <div v-if="isShowExistBet">
            <Chip :value="latestUserBetByUserChoose.latest_chip"></Chip>
        </div>
        <div v-else>
            <Chip :value="amount"></Chip>
        </div>
        <div class="box-select-coin">
            <span v-if="isShowExistBet">
                {{calTotalAmount}}
            </span>
            <span v-else>
                {{totalAmountToBet}}
            </span>
        </div> -->
        <slot></slot>
    </div>
</template>
<script>

import {USER_CHOOSE} from './Constant'
import ConfirmPanel from '../ConfirmPanel'
// import Chip from '../Chip'
import UserBetChip from '../UserBetChip'
import DummyUser from '../DummyUser'

export default {
    name: 'SicboPanelPlay',
    components: {
        ConfirmPanel,
        // Chip,
        UserBetChip,
        DummyUser
    },
    props: [
        'customClass',
        'user_choose',
        'choice',
        'userBetArr',
        'amount',
        'isPanelWin',
        'shipState',
        'isOnSendBet',
    ],
    data() {
        return {
            USER_CHOOSE: null,
        }
    },
    computed: {
        userBetArrWait() {
            return this.userBetArr.filter((userBet) => {
                return userBet.state === 'wait'
            })
        },
        userBetArrConfirm() {
            return this.userBetArr.filter((userBet) => {
                return userBet.state === 'confirm'
            })
        },
        isShowConfirmPanel() {
            return (this.user_choose === this.USER_CHOOSE[this.choice]) && !this.isOnSendBet
        },
        isShowExistBet() {
            return this.userBetArrConfirm.length > 0 && this.userBetArrWait.length === 0
        },
        latestChip() {
            return this.latestUserBetByUserChoose.latest_chip
        },
        totalAmountToBet() {
            return this.userBetArrWait.reduce((sum, bet) => {
                return parseInt(sum) + parseInt(bet.amount)
            }, 0)
        },
        latestUserBetByUserChoose() {
            return this.userBetArr[this.userBetArr.length - 1]
        },
        calTotalAmount() {
            return this.userBetArrConfirm.reduce((sum, bet) => {
                return parseInt(sum) + parseInt(bet.amount)
            }, 0)
        },
    },
    methods: {
        calBtnBlockPlayIsActive(user_choose) {

            if(this.user_choose === user_choose) {
                return 'active-select'
            }
            const foundInBetArr = this.userBetArr.find((bet) => {
                return bet.user_choose === user_choose
            })
            return (foundInBetArr) ? 'active-select': ''
        },
        confirmBet() {
            this.$emit('confirmBet')
        },
        cancelBet() {
            this.$emit('cancelBet')
        },
        selectBet(data, e) {
            if(e.target.classList.contains('bx-close') || e.target.classList.contains('bx-confirm')) {
                return
            }
            this.$emit('selectBet', data)
        },
    },
    created() {
        this.USER_CHOOSE = USER_CHOOSE
    }
}
</script>
<style>
    .box-result{
        border: solid 2px #ffbf00;
        box-shadow: 0 1px 10px #ffbf00;
        background-color: rgba(255, 191, 0, 0.35);
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        z-index: 10;

        animation: blinker 1s linear infinite;
    }
    @keyframes blinker {
        50% {
            opacity: 0;
        }
    }
</style>