<template>
    <div class="content-table-info-all ">
        <div v-if="hideHeader !== 'yes'" class="content-table-info-right">
            <div class="sec-Fantan w-100">
                <div v-if="tableStatus === GAME_STATUS.FINISH && statLatest" class="box-game-result">
                    <div class="user-list-detail border-0">
                        <div class="box-blocker">
                            <div class="box-dices">
                                <div class="list-dice dice-lg">
                                    <span v-for="(i, key) of getDice(statLatest)" :key="key" :class="`dice dice-${i}`"></span>
                                </div>
                            </div>
                            <div>
                                <span :class="`text-bgwhite ${classComputed(statLatest.game_result)}`">{{calDicePoint(statLatest)}}</span>
                            </div>
                        </div>
                    </div>
                </div>
                <div v-else class="box-current-result">
                    <TableStatus 
                    mode="plain"
                    :status="tableStatus" 
                    :secondStart="secondStart"
                    :secondStartRemain="secondStartRemain"></TableStatus>
                </div>
            </div>
        </div>
        <div class="content-table-info-left" :class="`game-screenshot-table-id-${table_id}`">
            <div class="sec-Fantan box-table-list">
                <div class="b-child-tb border-right">
                    <div v-for="stat of statArrLeft" :key="stat.gbs_id" class="user-list-detail">
                        <div class="row-content-his">
                            <div class="box-dices">
                                <div class="list-dice dice-cs">
                                    <span v-for="(i, key) of getDice(stat)" :key="key" :class="`dice dice-${i}`"></span>
                                    <span :class="`text-bgwhite ${classComputed(stat.game_result)}`">{{calDicePoint(stat)}}</span>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="b-child-tb">
                    <div v-for="stat of statArrRight" :key="stat.gbs_id" class="user-list-detail">
                        <div class="row-content-his">
                            <div class="box-dices">
                                <div class="list-dice dice-cs">
                                    <span v-for="(i, key) of getDice(stat)" :key="key" :class="`dice dice-${i}`"></span>
                                    <span :class="`text-bgwhite ${classComputed(stat.game_result)}`">{{calDicePoint(stat)}}</span>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>
<script>

import TableListItemMixin from './TableListItemMixin'
import TableStatus from '../TableStatus'
import {GAME_STATUS_KEY} from '../Constant'

export default {
    name: 'GameTypeItem',
    mixins: [
        TableListItemMixin,
    ],
    components: {
        TableStatus,
    },
    props: [
        'hideHeader'
    ],
    data() {
        return {
            GAME_STATUS: null,
        }
    },
    computed: {
        statArrLeft() {
            return this.statArr.slice(0, 4)
        },
        statArrRight() {
            return this.statArr.slice(4, 8)
        },
    },
    created() {
        this.GAME_STATUS = GAME_STATUS_KEY
    }
}
</script>
<style src="@/assets/css/game-type-item.css" scoped></style>