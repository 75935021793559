<template>
    <div class="Category" @click="select_category">
        <div v-if="version && version === 2" class="box-reverse-number">
            <button class="btn btn-block" :class="{'active': is_selected}">
                <i class="fas fa-random"></i> กลับตัวเลข
            </button>
        </div>
        <button v-else :class="{'active': is_selected}" class="btn h-100 d-flex justify-content-between align-items-center">
            <div :class="background_color" class="btn-select">
                <i>{{category_obj.name}}</i>
            </div>
            <div>
                <span v-if="reward">{{rewardLimit | floatFormat}}</span>
                <i v-else class="fas fa-random"/>
            </div>
        </button>
    </div>
</template>

<script>

    import store from '@/store'
    import _find from 'lodash/find'
    import _findIndex from 'lodash/findIndex'
    import {PriceRatio} from '../Constant'

    export default {
        name: "Category",
        props: [
            'round_lotto_type',
            'category_obj',
            'version',
            'setting',
            'max_reward',
        ],
        computed: {
            background_color: function(){
                if(this.category_obj.slug === 'song_tua_bon' || this.category_obj.slug === 'song_tua_lang' || this.category_obj.slug === 'song_tua_glub'){
                    return 'bg-two-bt';
                }else if(this.category_obj.slug === 'wing_bon' || this.category_obj.slug === 'wing_lang'){
                    return 'bg-run-bt';
                }else if(this.category_obj.slug === 'sam_tua_bon' || this.category_obj.slug === 'sam_tua_lang' || this.category_obj.slug === 'sam_tua_tod' || this.category_obj.slug === 'sam_tua_nha' || this.category_obj.slug === 'sam_tua_glub'){
                    return 'bg-three-bt';
                }else{
                    return '';
                }
            },
            border: function(){
                if(this.category_obj.slug === 'song_tua_bon' || this.category_obj.slug === 'song_tua_lang' || this.category_obj.slug === 'song_tua_glub'){
                    return 'border-two-bt';
                }else if(this.category_obj.slug === 'wing_bon' || this.category_obj.slug === 'wing_lang'){
                    return 'border-run-bt';
                }else if(this.category_obj.slug === 'sam_tua_bon' || this.category_obj.slug === 'sam_tua_lang' || this.category_obj.slug === 'sam_tua_tod' || this.category_obj.slug === 'sam_tua_nha' || this.category_obj.slug === 'sam_tua_glub'){
                    return 'border-three-bt';
                }else{
                    return '';
                }
            },
            select_category_list: function(){
                return store.state.lotto.select_category_list;
            },
            is_selected: function(){
                let _this = this;
                let select_category = _find(this.select_category_list, function(o){ return o.slug === _this.category_obj.slug});
                return (select_category);
            },
            is_show_reward: function(){
                if(this.category_obj.slug === 'song_tua_bon' ||
                    this.category_obj.slug === 'song_tua_lang' ||
                    this.category_obj.slug === 'sam_tua_bon' ||
                    this.category_obj.slug === 'sam_tua_lang' ||
                    this.category_obj.slug === 'sam_tua_tod' ||
                    this.category_obj.slug === 'sam_tua_nha'){
                    if(this.round_lotto_type === 'yeekee'){
                        return this.category_obj.reward;
                    }else{
                        return null;
                    }
                }else{
                    return this.category_obj.reward;
                }
            },
            price_table(){
                return store.state.lotto.price_table
            },
            bet_type_group() {
                return this.category_obj.slug
            },
            reward_limit(){
                return store.state.lotto.reward_limit
            },
            multi_min: function(){
                try{
                    return (this.reward_limit) ? this.reward_limit[this.round_lotto_type][this.bet_type_group]['min'] : 0;
                }catch(err){
                    return 0
                }
            },
            multi_max: function(){
                try{
                    return (this.reward_limit) ? this.reward_limit[this.round_lotto_type][this.bet_type_group]['max'] : 0;
                }catch(err){
                    return 0
                } 
            },
            reward(){
                if(this.price_table){
                    if(!this.price_table[this.round_lotto_type]) {
                        return null
                    }

                    if(this.max_reward) {
                        let price_ratio = 1
                        if(this.category_obj.slug === 'sam_tua_bon'){
                            price_ratio = 1
                        }else if(this.category_obj.slug === 'sam_tua_tod'){
                            price_ratio = PriceRatio.sam_tua_tod
                        }else if(this.category_obj.slug === 'sam_tua_nha' || this.category_obj.slug === 'sam_tua_lang'){
                            price_ratio = PriceRatio.sam_tua_nha_lang
                        }
                        return parseFloat(this.max_reward) * parseFloat(price_ratio)
                    } else {
                        return this.price_table[this.round_lotto_type][this.category_obj.slug]
                    }

                }else{
                    return null;
                }
            },
            rewardLimit() {
                if(!this.multi_min || !this.multi_max){
                    return this.reward
                }else if(parseFloat(this.reward) < this.multi_min){
                    return parseFloat(this.multi_min)
                }else if(parseFloat(this.reward) > this.multi_max){
                    return parseFloat(this.multi_max)
                }else{
                    return this.reward
                }
            }
        },
        methods: {
            select_category: function(){
                if(this.is_selected){
                    let _this = this;
                    let i = _findIndex(this.select_category_list, function(o){ return o.slug === _this.category_obj.slug});
                    this.$emit('un_select', i);
                }else{
                    this.$emit('select', this.category_obj);
                }
            }
        }
    }
</script>

<style scoped>

</style>