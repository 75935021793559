<template>
    <div class="Baccarat Baccarat2">
        <!-- <div v-if="!isOnDev" style="text-align: center; padding-top: 20px;">
            <h4>ยังไม่เปิดให้บริการ ขออภัยในความไม่สะดวก</h4>
            <router-link to="/">
                <el-button>กลับ</el-button>
            </router-link>
        </div>
        <MiniBaccarat v-else @exit="$router.back()" :key="queryTableId" :version="2"></MiniBaccarat> -->
        <MiniBaccarat @exit="$router.back()" :key="queryTableId" :version="2"></MiniBaccarat>
    </div>
</template>
<script>

import MiniBaccarat from '@/components/game/MiniBaccarat'

export default {
    name: 'Baccarat2',
    components: {
        MiniBaccarat,
    },
    computed: {
        queryTableId() {
            return this.$route.query.table_id
        },
    },
}
</script>
<style scoped>

</style>