<template>
    <div class="Uploader">
        <div class="image-preview" v-if="imageData.length > 0" style="text-align: center;">
            <img class="preview" :src="imageData"/>
        </div>
        <div>
            <input id="upload" :disabled="on_upload" class="file-input" type="file" @change="onFileChanged">
            <label for="upload" class="btn btn-outline-primary">
                <span class="file-icon">
                    <i class="fas fa-upload"/>    
                </span>
                <span v-if="!on_upload"> เลือกรูปภาพ</span>
                <span v-if="on_upload"> กำลังโหลด...</span>
            </label>
        </div>
    </div>
</template>

<script>

    import axios from 'axios';
    import FormData from 'form-data'
    import Jimp from 'jimp';

    export default {
        name: "Uploader",
        props: [
            'bus'
        ],
        data() {
            return {
                selectedFile: null,
                imageData: '',
                upload_progress: 0,
                on_upload: false
            }
        },
        methods: {
            onFileChanged (event) {
                this.selectedFile = event.target.files[0];
                this.previewImage();
            },
            previewImage: function(){
                const reader = new FileReader();
                reader.onload = (e) => {
                    this.imageData = e.target.result;
                };
                reader.readAsDataURL(this.selectedFile);

                this.on_upload = true;
                let _this = this;
                const reader2 = new FileReader();
                reader2.onload = (e) => {

                    let array_buffer = e.target.result;

                    Jimp.read(array_buffer)
                        .then(image => {
                            if(image.bitmap.width > 1000){
                                return image.resize(1000, Jimp.AUTO).quality(50).getBase64Async(Jimp.AUTO);
                            }else{
                                return image.quality(10).getBase64Async(Jimp.AUTO);
                            }
                        })
                        .then(base64 => {
                            _this.$emit('image_selected', base64);
                            _this.on_upload = false;
                        })
                        .catch(err => {
                            console.log('resize err', err);
                            alert('upload error');
                        });
                };
                reader2.readAsArrayBuffer(this.selectedFile);
            },
            onUpload() {
                const _this = this;
                const formData = new FormData();
                formData.append('primary', this.selectedFile, this.selectedFile.name);
                axios.post(`${this.envDomains.VUE_APP_LIFF_API}/api/file/upload`, formData, {
                    onUploadProgress: progressEvent => {
                        this.upload_progress = (progressEvent.loaded/progressEvent.total)*100;
                    }
                }).then(function (res) {
                    _this.$emit('done', res.data);
                    _this.clearData();
                }).catch(function (err) {
                    _this.$emit('err', err);
                })
            },
            clearData: function(){
                this.upload_progress = 0;
                this.imageData = '';
                this.selectedFile = null;
            }
        },
        mounted() {
            const _this = this;
            this.bus.$on('clear_data', function(){
                _this.clearData();
            });
        }
    }
</script>

<style scoped>
img.preview {
    width: 300px;
    background-color: white;
    border: 1px solid #DDD;
    padding: 5px;
}
.file-input {
    display: none;
}
</style>