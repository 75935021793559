<template>
    <div class="Loading">
        <div v-if="mode === 'fantasy'" class="loading-fantasy">
            <div class="spinner-grow text-primary" role="status">
                <span class="sr-only">Loading...</span>
            </div>
            <div class="spinner-grow text-success" role="status">
                <span class="sr-only">Loading...</span>
            </div>
            <div class="spinner-grow text-danger" role="status">
                <span class="sr-only">Loading...</span>
            </div>
            <div class="spinner-grow text-warning" role="status">
                <span class="sr-only">Loading...</span>
            </div>
            <div class="spinner-grow text-info" role="status">
                <span class="sr-only">Loading...</span>
            </div>
        </div>
        <div v-if="mode === 'spinner'" class="loading-spinner">
            <div class="spinner-border text-primary" role="status">
                <span class="sr-only">Loading...</span>
            </div>
        </div>
        <div class="loading-text">
            {{_message}}
        </div>
    </div>
</template>
<script>
export default {
    name: 'Loading',
    props: ['mode', 'message'],
    computed: {
        _message() {
            return this.message || 'กำลังโหลด กรุณารอสักครู่'
        }
    }
}
</script>
<style scoped>

</style>