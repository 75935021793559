<template>
<div class="StatBigRoad">
    <div v-for="(box, key) of boxArr" :key="key" class="baccarat-stat-item">
        <BigRoadMark :stat="getMark(box.row, box.col)"></BigRoadMark>
    </div>
</div>
</template>
<script>

const MAX_MARK_LENGTH = 504
const ROW_MAX = 6
const COL_WIDTH = 11

import BigRoadMark from './BigRoadMark'
import {DRAGON_TIGER_GAME_RESULT_KEY} from '../Constant'

export default {
    name: 'StatBigRoad',
    components: {
        BigRoadMark,
    },
    props: [
        'statArr',
    ],
    data() {
        return {
            boxArr: [],
        }
    },
    computed: {
        statArrFilter() {
            return this.statArr.filter((stat) => {
                return !(parseInt(stat.game_result) === DRAGON_TIGER_GAME_RESULT_KEY.WAIT || parseInt(stat.game_result) === DRAGON_TIGER_GAME_RESULT_KEY.CANCEL)
            })
        },
        statArrSort() {

            const arr = []

            let onLongRow = false
            let currentLongRow = ROW_MAX
            let latestLongCol = -1
            let latestCol = -1

            let row = 0
            let col = 0

            let latestResult = null

            const isTieOnFirstGame = () => {
                if(this.statArrFilter.length === 0) {
                    return false
                }
                return this.statArrFilter[0].game_result === DRAGON_TIGER_GAME_RESULT_KEY.TIE
            }
            const isOnlyFirstGame = () => {
                return this.statArrFilter.length === 1
            }

            if(isOnlyFirstGame() && isTieOnFirstGame()) {
                arr.push({
                    row: 0,
                    col: 0,
                    isTie: 1,
                    longRowOnCol: -1,
                })
                return arr
            }

            this.statArrFilter.map((stat) => {

                if(stat.game_result) {

                    const newResult = stat.game_result

                    const isTie = newResult === DRAGON_TIGER_GAME_RESULT_KEY.TIE

                    if(!latestResult) {
                        row = 0
                        col = 0
                    } else if(isTie) {
                        row = row + 0
                        col = col + 0
                    } else if(latestResult === newResult) {

                        if(row === currentLongRow - 1) {// long row case
                            
                            col += 1

                            onLongRow = true
                            latestLongCol = col
                            if(latestCol < 0) {
                                latestCol = col
                            }

                        } else {
                            row += 1
                        }
                        
                    } else {

                        if(onLongRow) { // change from long row case

                            onLongRow = false

                            if(currentLongRow === 1) { //Sextuple Dragon case start new col after latestLongCol
                                col = latestLongCol + 1
                            } else {
                                col = latestCol
                            }
                            
                            row = 0

                            latestCol = -1
                            currentLongRow -= 1

                        } else {
                            col += 1
                            row = 0
                        }
                    }

                    if(col > latestLongCol) {
                        currentLongRow = ROW_MAX
                    }

                    if(isTie) {
                        if(arr.length > 0) {
                            arr[arr.length - 1].isTie = 1
                        }
                    } else {

                        const data = {
                            row,
                            col,
                            result: stat.game_result,
                            longRowOnCol: (onLongRow) ? (latestCol - 1) : -1,
                        }

                        arr.push(data)

                        latestResult = stat.game_result
                    }
                }
            })

            if(isTieOnFirstGame()) {
                const position = 0
                if(arr[position]) {
                    arr[position].isTie = 1
                }
            }
            
            return arr
        },
        latestStatArrSort() {
            if(this.statArrSort.length > 0) {
                return this.statArrSort[this.statArrSort.length - 1]
            } else {
                return 0
            }
        },
        latestStatArrSortCol() {
            if(!this.latestStatArrSort) {
                return 0
            }
            return this.latestStatArrSort.col
        },
        existStatWidth() {
            return parseInt(this.latestStatArrSortCol * COL_WIDTH)
        },
    },
    watch: {
        existStatWidth(newVal) {
            if(newVal) {
                this.$emit('existStatWidthChange', {
                    existStatWidth: newVal, 
                    colWidth: COL_WIDTH,
                })
            }
        },
    },
    methods: {
        generateBoxArr() {

            let row = 0
            let col = 0

            for(let i = 0;i < MAX_MARK_LENGTH;i++) {
                col = parseInt(i / ROW_MAX)
                this.boxArr.push({
                    row,
                    col,
                })
                row = (row + 1) % ROW_MAX
            }
        },
        getMark(row, col) {
            const found = this.statArrSort.find((stat) => {
                return stat.row === row && stat.col === col
            })
            return (found) ? found : ''
        },
    },
    created() {
        this.generateBoxArr()
    },
    mounted() {
        setTimeout(() => {
            this.$emit('existStatWidthChange', {
                existStatWidth: this.existStatWidth, 
                colWidth: COL_WIDTH,
            })
        }, 1000)
    }
}
</script>
<style scoped>
.StatBigRoad {
    display: flex;
    flex-direction: column;
    flex-wrap: wrap;
}
.baccarat-stat-item {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 11px;
    width: 11px;
    border-collapse: collapse;
    border-right: solid 1px #ddd;
    border-bottom: solid 1px #ddd;
}
</style>