<template>
    <div class="PageHome">

        <div class="container">

            <div class="box-main-home w-100 row-menu-grid align-self-stretch">
                <div class="box-list-home-main">
                    <div class="box-list-home-list">
                        <div class="box-list-home">
                            <router-link :to="{name: 'LottoBet'}" class="menu-grid bx-bet">
                                <div class="ribbon-hot"></div>
                                <div class="box-menu-index">

                                    <img src="@/assets/images/icon/set-icon/icon-lotto-bet.png"/>
                                    <span>แทงหวย</span>
                                    <!--								<div class="bg-circle-list">-->
                                    <!--									<i class="icon-lotto"></i>-->
                                    <!--								</div>-->
                                    <!--								<span>แทงหวย</span>-->
                                </div>
                            </router-link>
                        </div>
                        <div class="box-list-home">
                            <router-link :to="{name: 'LottoBill'}" @click="goPage('bill')" href="#" class="menu-grid bx-poy">
                                <div class="box-menu-index">

                                    <img src="@/assets/images/icon/set-icon/icon-lotto-bill.png"/>
                                    <span>โพยหวย</span>
                                    <!--								<div class="bg-circle-list">-->
                                    <!--									<i class="fas fa-receipt" />-->
                                    <!--								</div>-->
                                    <!--								<span>โพยหวย</span>-->
                                </div>
                            </router-link>
                        </div>
                        <div class="box-list-home">
                            <router-link :to="{name: 'LottoResult'}" @click="goPage('result')" href="#" class="menu-grid bx-result">
                                <div class="box-menu-index">
                                    <img src="@/assets/images/icon/set-icon/icon-lotto-result.png"/>
                                    <span>ผลรางวัล</span>

                                    <!--								<div class="bg-circle-list">-->
                                    <!--									<i class="fas fa-trophy"></i>-->
                                    <!--								</div>-->
                                    <!--								<span>ผลรางวัล</span>-->
                                </div>
                            </router-link>
                        </div>
                        <div class="box-list-home hide">
                            <a @click="goAppPage('DepositWithdraw')" href='javascript:;' class="menu-grid bx-refer">
                                <div class="box-menu-index">
                                    <img src="@/assets/images/icon/set-icon/icon-lotto-dw.png"/>
                                    <!--							<div class="bg-circle-list">-->
                                    <!--							<i class="fas fa-donate"></i>-->
                                    <!--							</div>-->
                                    <!--							<span>ฝากถอน</span>-->
                                </div>
                            </a>
                        </div>
                        <div class="box-list-home hide">
                            <a @click="goAppPage('Account')" href='javascript:;' class="menu-grid bx-user">
                                <div class="box-menu-index">
                                    <img src="@/assets/images/icon/set-icon/icon-lotto-ac.png"/>
                                    <!--							<div class="bg-circle-list">-->
                                    <!--							<i class="fas fa-wallet"></i>-->
                                    <!--							</div>-->
                                    <!--							<span>จัดการบัญชี</span>-->
                                </div>
                            </a>
                        </div>
                    </div>
                </div>
                <div class="wrap-content-home">
<!--                    bx-desktop-->
                    <div class="container-lotto container-lotto-mobile  bx-mobile bx-ipad">
                        <!-- <div class="PriceTable card">
                            <div class="card-header">
                                <div class="LottoTypeIcon custom-price-table">
                                    <img src="/img/icon-lotto-hitec.5a0b92f7.png" class="lotto-type-icon logo-yeekee-image">
                                </div>
                                <div class="card-header-detail">
                                    <strong class="card-box"> หวยไฮเทค </strong>
                                    <div class="Alarm">
                                        <div class="Alarm-box"><span>18:14:33</span></div>
                                    </div>
                                </div>
                            </div>
                            <div class="colContent-priceIndex">
                                <table>
                                        <tbody>
                                            <tr>
                                                <td class="text-center">3 ตัวบน</td>
                                                <td class="text-center text-danger table-warning">
                                                    <b >900 <small>บาท</small></b>
                                                </td>
                                            </tr>
                                                    <tr><td class="text-center">2 ตัวบน</td>
                                                    <td  class="text-center text-danger table-warning">
                                                        <b>90 <small>บาท</small></b>
                                                    </td>
                                            </tr>
                                            <tr>
                                                <td class="text-center">2 ตัวล่าง</td>
                                                <td class="text-center"><b>90 <small>บาท</small></b>
                                        </td>
                                    </tr>
                                        </tbody>
                                </table>
                            </div>
                        </div> -->
                        <div class="PriceTable card" v-for="(lottoType, key) of lottoTypeArr" :key="key">
                            <PriceTableNew :lotto_type="lottoType" />
                        </div>
                        
                        <!-- <div class="PriceTable card">
                            <div class="card-header">
                                <div class="LottoTypeIcon custom-price-table">
                                    <img src="/img/icon-lotto-hitec.5a0b92f7.png" class="lotto-type-icon logo-yeekee-image">
                                </div>
                                <div class="card-header-detail">
                                    <strong class="card-box"> หวยไฮเทค </strong>
                                    <div class="Alarm">
                                        <div class="Alarm-box"><span>18:14:33</span></div>
                                    </div>
                                </div>
                            </div>
                            <div class="colContent-priceIndex">
                                <table>
                                    <tbody>
                                    <tr>
                                        <td class="text-center">3 ตัวบน</td>
                                        <td class="text-center text-danger table-warning">
                                            <b >900 <small>บาท</small></b>
                                        </td>
                                    </tr>
                                    <tr><td class="text-center">2 ตัวบน</td>
                                        <td  class="text-center text-danger table-warning">
                                            <b>90 <small>บาท</small></b>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td class="text-center">2 ตัวล่าง</td>
                                        <td class="text-center"><b>90 <small>บาท</small></b>
                                        </td>
                                    </tr>
                                    </tbody>
                                </table>
                            </div>
                        </div>
                        <div class="PriceTable card">
                            <div class="card-header">
                                <div class="LottoTypeIcon custom-price-table">
                                    <img src="/img/icon-lotto-hitec.5a0b92f7.png" class="lotto-type-icon logo-yeekee-image">
                                </div>
                                <div class="card-header-detail">
                                    <strong class="card-box"> หวยไฮเทค </strong>
                                    <div class="Alarm">
                                        <div class="Alarm-box"><span>18:14:33</span></div>
                                    </div>
                                </div>
                            </div>
                            <div class="colContent-priceIndex">
                                <table>
                                    <tbody>
                                    <tr>
                                        <td class="text-center">3 ตัวบน</td>
                                        <td class="text-center text-danger table-warning">
                                            <b >900 <small>บาท</small></b>
                                        </td>
                                    </tr>
                                    <tr><td class="text-center">2 ตัวบน</td>
                                        <td  class="text-center text-danger table-warning">
                                            <b>90 <small>บาท</small></b>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td class="text-center">2 ตัวล่าง</td>
                                        <td class="text-center"><b>90 <small>บาท</small></b>
                                        </td>
                                    </tr>
                                    </tbody>
                                </table>
                            </div>
                        </div> -->
                    </div>
                    <div class="container-lotto  bx-desktop bx-ipad">
                        <carousel
                                :perPage="1.2"
                                :mouseDrag="true"
                                :spacePadding="5"
                                :navigationEnabled="true"
                                :paginationEnabled="false"
                                navigationNextLabel="❯"
                                navigationPrevLabel="❮"
                        >
                            <slide v-for="(lottoType, key) of lottoTypeArr" :key="key" class="PriceTable card">
                                <PriceTable :lotto_type="lottoType" />
                            </slide>
                        </carousel>
                    </div>
                    <div class="rule-home">
                        <About />
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>

    import store from "@/store";
import PriceTable from "./components/PriceTable";
import PriceTableNew from "./components/PriceTableNew";
import About from "./components/About";
import AppMixin from './mixins/App'
import UserMixin from '@/mixins/UserMixin'
import { Carousel, Slide } from 'vue-carousel'
import {LOTTO_TYPE} from './Constant'


export default {
    name: "PageHome",
    mixins: [
        AppMixin,
        UserMixin,

    ],
    components: {
		About, 
		PriceTable,
        PriceTableNew,
        Carousel,
        Slide,

	},
    data() {
        return {
            lottoTypeArr: [],
        }
    },
    computed: {
        user_profile: function () {
            return store.state.user_profile;
        },
        pes_profile: function () {
            return store.state.pes_profile;
        },
        is_member(){
            return store.state.is_member
        },
    },
    methods: {

        goPage(page) {

            if(page === 'bill') {
                if(!this.is_member){
                    this.notifyToSignUp('เพื่อดูโพยหวย')
                    return
                }
            }
            store.commit('lotto/updateCurrentPage', page)
        },
        goAppPage(name) {
            if(!this.is_member) {
                this.notifyToSignUp()
                return
            }
            this.$router.push({name})
        }
    },
    created() {
        for(const key in LOTTO_TYPE) {
            this.lottoTypeArr.push(key);
        }
    },
    mounted() {
        this.loadPriceTable()
        this.loadRewardLimit()
    }
};
</script>

<style src="@/assets/css/lotto/reset.css"></style>
<style src="@/assets/css/lotto/style.css"></style>
<style src="@/assets/css/lotto/money.css"></style>




<style scoped>


    .VueCarousel-slide{
        flex-basis: initial;
    }
    .h-profile .text-right {
        margin-right: 44px;
    }
    /*.box-list-home-main {background-color: #fff; padding:6px 0 0}*/
    a.menu-grid, a.menu-grid:hover {
        color: #000;
    }
    a.menu-grid.bx-result .bg-circle-list {
        background-color: green;
        color: white;
    }
    .box-img{
        position: absolute;
        top: 7px;
        left: 35px;
        padding: 0 15px;
        flex: 3;
        height: unset;
        z-index: 1;
    }
    .box-user-img,
    .box-user-img img {
        display: inline-block;
        width: 40px;
        height: 40px;
        border-radius: 50%;
        margin-right: 10px;
    }

    .box-img,
    .box-menu-img {
        display: flex;
        color: #fff;
        font-family: "Athiti", sans-serif;
    }

    .box-img small {
        display: block;
        margin-top: -5px;
    }
    .container-lotto .PriceTable{
        background-color: transparent;
        background: linear-gradient(to bottom, #753189 0%, #d20a84 100%);
    }
    .container-lotto{background-color: #fff}
    .rule-home{padding: 0 10px;}



</style>
