<template>

    <div class="GameType PromotionUnboxPlay">

        <div class="content-page content-page-unboxplay">
            <div class="row-spin-content">

<!--                modal ตอนเริ่มต้น-->
                <div class="beforeSpin-content" :class="hideStep1?'hide':''">
                    <div class="PromotionUnboxPlay-topic">
                        <img src="@/assets/images/casino-hitech.png"/>
                        <h1>คลิกเพื่อรับของรางวัล</h1>
                        <!-- <h3>คุณได้รับฟรีสปิน 3 ครั้ง</h3> -->
                    </div>
                    <div class="case-open-promotion">
                        <img src="@/assets/images/box-gift.png"/>
                    </div>

                    <div class="box-spinner-btn">
                        <div class="btn" @click="clickStep1()"><span>คลิก Spin</span></div>
                    </div>
                </div>
<!--                โบนัสรายวัล-->
                <div class="box-bonus-day"  v-if="hideStep2==false">
                    <div class="content-bonus-day">
                        <div  class="title">
                            <div class="title-logo"><img src="/img/icon-bonus.8ca88b00.gif" alt=""></div>
                            <div class="close-box-bonus"></div>
                        </div>
                        <div class="content-all">
                            <div class="content">
                                <div class="box-bonus1  get-bonus-active"><span data-v-4a501398=""
                                                                                                  class="day-bonus">1</span>
                                    <div data-v-4a501398="" class="box-bonus1-content">
                                        <div data-v-4a501398="" class="box-bonus1-item"><img data-v-4a501398=""
                                                                                             src="/img/coin-small-1.32e4b4fd.png"
                                                                                             alt=""></div>
                                        <div data-v-4a501398="" class="box-bonus1-value">฿10</div>
                                        <div data-v-4a501398="" class="box-bonus1-btn claim_daily_bonus">
                                            <button data-v-4a501398="" type="button" class="btn"></button>
                                        </div>
                                    </div>
                                </div>
                                <div class="box-bonus1">
                                    <span data-v-4a501398=""
                                                                                 class="day-bonus">2</span>
                                    <div  class="box-bonus1-content">
                                        <div data-v-4a501398="" class="box-bonus1-item"><img data-v-4a501398=""
                                                                                             src="/img/coin-small-1.32e4b4fd.png"
                                                                                             alt=""></div>
                                        <div data-v-4a501398="" class="box-bonus1-value">฿10</div>
                                        <div data-v-4a501398="" class="box-bonus1-btn claim_daily_bonus">
                                            <button data-v-4a501398="" type="button" class="btn"></button>
                                        </div>
                                    </div>
                                </div>
                                <div class="box-bonus1">
                                    <span data-v-4a501398=""
                                                                                 class="day-bonus">3</span>
                                    <div data-v-4a501398="" class="box-bonus1-content">
                                        <div data-v-4a501398="" class="box-bonus1-item"><img data-v-4a501398=""
                                                                                             src="/img/coin-small-1.32e4b4fd.png"
                                                                                             alt=""></div>
                                        <div data-v-4a501398="" class="box-bonus1-value">฿10</div>
                                        <div data-v-4a501398="" class="box-bonus1-btn claim_daily_bonus">
                                            <button data-v-4a501398="" type="button" class="btn"></button>
                                        </div>
                                    </div>
                                </div>
                                <div class="box-bonus1">
                                    <span class="day-bonus">4</span>
                                    <div class="box-bonus1-content">
                                        <div data-v-4a501398="" class="box-bonus1-item"><img data-v-4a501398=""
                                                                                             src="/img/coin-small-1.32e4b4fd.png"
                                                                                             alt=""></div>
                                        <div data-v-4a501398="" class="box-bonus1-value">฿10</div>
                                        <div data-v-4a501398="" class="box-bonus1-btn claim_daily_bonus">
                                            <button data-v-4a501398="" type="button" class="btn"></button>
                                        </div>
                                    </div>
                                </div>
                                <div class="box-bonus1">
                                    <span data-v-4a501398=""
                                                                                 class="day-bonus">5</span>
                                    <div data-v-4a501398="" class="box-bonus1-content">
                                        <div data-v-4a501398="" class="box-bonus1-item"><img data-v-4a501398=""
                                                                                             src="/img/coin-small-1.32e4b4fd.png"
                                                                                             alt=""></div>
                                        <div data-v-4a501398="" class="box-bonus1-value">฿10</div>
                                        <div data-v-4a501398="" class="box-bonus1-btn claim_daily_bonus">
                                            <button data-v-4a501398="" type="button" class="btn"></button>
                                        </div>
                                    </div>
                                </div>
                                <div class="box-bonus1">
                                    <span data-v-4a501398=""
                                                                                 class="day-bonus">6</span>
                                    <div data-v-4a501398="" class="box-bonus1-content">
                                        <div data-v-4a501398="" class="box-bonus1-item"><img data-v-4a501398=""
                                                                                             src="/img/coin-small-1.32e4b4fd.png"
                                                                                             alt=""></div>
                                        <div data-v-4a501398="" class="box-bonus1-value">฿10</div>
                                        <div data-v-4a501398="" class="box-bonus1-btn claim_daily_bonus">
                                            <button data-v-4a501398="" type="button" class="btn"></button>
                                        </div>
                                    </div>
                                </div>
                                <div class="last-box-bonus1">
                                    <div class="box-bonus1"><span data-v-4a501398=""
                                                                                     class="day-bonus">7</span>
                                        <div data-v-4a501398="" class="box-bonus1-content">
                                            <div data-v-4a501398="" class="box-bonus1-item"><img data-v-4a501398=""
                                                                                                 src="https://pesstar.com/case/images/case/case_20190603120620.png"
                                                                                                 alt=""></div>
                                            <div data-v-4a501398="" class="box-bonus1-value">Free X1</div>
                                            <div data-v-4a501398="" class="box-bonus1-btn claim_daily_bonus">
                                                <button data-v-4a501398="" type="button" class="btn"></button>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div class="box-spinner-btn">
                                    <div class="btn" @click="clickStep2()"><span>Claim Bonus</span></div>
                                </div>
                            </div>
                            <div class="content-bg"></div>
                        </div>
                    </div>
                    <div class="overlay-box"></div>
                </div>
<!--                ของรางวัลที่ได้รับ-->
                <div class="box-bonus-day box-bonus-day-award" v-if="hideStep3==false">
                    <div class="content-bonus-day">
                        <div  class="title">
                            <div class="title-logo"><img src="@/assets/images/bg-topUp/icon-bonus-award.png"/></div>
                            <div class="close-box-bonus" @click="closeStep3()"></div>
                        </div>
                        <div class="content-all">
                            <div class="content">
                                <!-- <div class="box-bonus1">
                                    <span class="day-bonus">วันที่ 1</span>
                                    <div class="box-bonus1-content">
                                        <div class="box-bonus1-item">
                                            <img src="/img/coin-small-1.32e4b4fd.png" alt=""></div>
                                        <div class="box-bonus1-value">฿10</div>
                                    </div>
                                </div> -->
                                <div class="wrapper-cards">
                                    <div v-for="(item,key) of item_received_list" :key="key">
                                        <div class="wrap-card-box">
                                            <div class="card-box">
                                                <img :src="item.icon_url"/>
                                                <span>{{item.item_name}}</span>
                                                <label>฿{{item.prices}}</label>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div class="box-total-bonus-day-award">
                                    <span>ราคาทั้งหมด :</span>
                                    <span>฿100</span>
                                </div>
                                <div class="box-spinner-btn">
                                    <div class="btn" @click="clickStep3()"><span>รับรางวัล</span></div>
                                </div>
                            </div>
                            <div class="content-bg"></div>
                        </div>
                    </div>
                    <div class="overlay-box"></div>
                </div>


                <div class="main-spin-content">
                    <div class="PromotionUnboxPlay-topic">
                        <img src="@/assets/images/casino-hitech.png"/>
                        <h1>คลิกเพื่อรับของรางวัล</h1>
                        <!-- <h3>คุณได้รับฟรีสปิน 3 ครั้ง</h3> -->
                    </div>
                    <div class="container-spin-game">
                        <div class="container-spin-game-info">
                            <div>
                                <div class="main-case-box card z-depth-2 main-case-box-level-3" :id="'main-case-box-0'"
                                     style="width: 100%; position: relative;">
                                    <div class="case-box">
                                        <div class="case-container" style="display: block;">
                                            <div id="div-case-inner-0" class="case-inner" v-if="item_list[0]">
                                                       <div v-for="(item, item_key) of item_list[0]" :key="item_key"
                                                             class="case-item z-depth-1" :class="['case-item-'+color_maping[item.quality_color]]" :id="'div-case-item-'+0+'-'+item_key"
                                                             v-bind:style="{ 'border-left-color': '#'+item.quality_color, 'border-right-color':'#'+item.quality_color,'background-color':convertColor(item.quality_color,0.3) }" >
                                                        <div class="border-top-style" v-bind:style="{'background-color':convertColor(item.quality_color,0.9) }"></div>
                                                        <div class="case-item-shadow">&nbsp;</div>
                                                        <img class="case-item-img" draggable="false"  :src="item.icon_url">
                                                        <div class="case-item-value white-text" style="background: -moz-linear-gradient(left, rgba(0,0,0,0.2) 0%, rgba(0,0,0,0.6) 100%), #808080;background: -webkit-linear-gradient(left, rgba(0,0,0,0.2) 0%,rgba(0,0,0,0.6) 100%), #808080;background: linear-gradient(to right, rgba(0,0,0,0.2) 0%,rgba(0,0,0,0.6) 100%), #808080; cursor: default;"
                                                         v-bind:style="{'background-color':convertColor(item.quality_color,0.9) }" >
                                                            <div style="max-height:38px; overflow: hidden"></div>
                                                            <div class="item-name">{{item.item_name}}</div>
                                                        </div>
                                                        <div class="case-item-bg">&nbsp;</div>
                                                    </div>
                                                    
                                            </div>
                                            <div class="case-marker"></div>
                                        </div>
                                        <div class="center-align"
                                             style="background-color: rgba(0, 0, 0, 0.8); position: absolute; top: 0px; width: 100%; height: 100%; font-size: 1.2em; display: none;">
                                            <img   idraggable="false" src=""
                                                 style="padding-top: 20px; top: 0px; width: 150px; height: auto;">
                                            <div  style="color: rgb(255, 255, 255);"></div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="bg-image"
                                 style="background-image: url(&quot;https://pesstar.com/case/images/case_import/10044.png&quot;);"></div>
                        </div>
                    </div>
                    <div class="box-spinner-btn" v-if="claimReward">
                        <div class="btn" @click="clickStep4()"><span>รับรางวัล</span></div>

                    </div>
                    <div class="box-spinner" v-else>
                        <span>Spin</span>
                        <i class="fas fa-spinner"></i>
                    </div>
                    <div class="wrapper-cards"  v-if="item_received_list.length>0">
                        <div v-for="(item,key) of item_received_list" :key="key">
                            <div class="wrap-card-box">
                                <div class="card-box">
                                    <img :src="item.icon_url"/>
                                    <span>{{item.item_name}}</span>
                                    <label>฿{{item.prices}}</label>
                                </div>
                            </div>
                        </div>
                        <!-- <div>
                            <div class="wrap-card-box">
                                <div class="card-box">
                                    <img src="https://pesstar.com/images/caseitem/data_10044/2.png"/>
                                    <span>MAC PRO 3.0GHz 8Core</span>
                                </div>
                            </div>
                        </div>
                        <div>
                            <div class="wrap-card-box">
                                <div class="card-box">
                                    <img src="https://pesstar.com/images/caseitem/data_10044/3.png"/>
                                    <span>MacBook Pro (15-inch) with Touch Bar</span>
                                </div>
                            </div>
                        </div> -->
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
var currentCase;
// var userConfigGame;
var listCaseGroup;
var ITEMLIST;
var color_class_mapping = {
    'd99700': 'gold',
    'c9005a': 'red',
    '7900df': 'purple',
    'c700df': 'pink',
    '2a5aab': 'blue',
    'b4b4b2': 'white',
};
const color_class_level={
    
    'd99700': 1,
    '7900df': 3,
    'c9005a': 2,
    'c700df': 4,
    '2a5aab': 5,
    'b4b4b2': 6,
}
let case_start = new Audio(require("@/assets/sound/csgo_ui_crate_open.wav"));
let starburst = $(".starburst");
starburst.hide();
var sum_count = 0;
var num = 1;
let offsetOverride = -1;
// volume for cases
let masterVolume = 0.25;
var all_audio_file = {};
// All the case sounds

case_start.volume = masterVolume * 0.5;
all_audio_file['case_start'] = case_start;
let clicks = []

for (let i = 0; i < 20; i++) {
    clicks[i] = new Audio(require("@/assets/sound/csgo_ui_crate_item_scroll.wav"));
    clicks[i].volume = masterVolume * 0.5;
    all_audio_file['click_' + i] = clicks[i];
}


let case_done = {};

case_done.blue = new Audio(require("@/assets/sound/item_reveal3_rare.wav"));
case_done.blue.volume = masterVolume * 0.5;
all_audio_file['case_done_blue'] = case_done.blue;
case_done.purple = new Audio(require("@/assets/sound/item_reveal4_mythical.wav"));
case_done.purple.volume = masterVolume * 0.5;
all_audio_file['case_done_purple'] = case_done.purple;

case_done.pink = new Audio(require("@/assets/sound/item_reveal5_legendary.wav"));
case_done.pink.volume = masterVolume * 0.6;
all_audio_file['case_done_pink'] = case_done.pink;

case_done.red = new Audio(require("@/assets/sound/item_reveal6_ancient.wav"));
case_done.red.volume = masterVolume * 0.8;
all_audio_file['case_done_red'] = case_done.red;

case_done.gold = new Audio(require("@/assets/sound/item_reveal6_ancient.wav"));
case_done.gold.volume = masterVolume * 0.8;
all_audio_file['case_done_gold'] = case_done.gold;
let canPlayClick = true;
let clickInterval = 0;
let clicknum = 0;
// var item_test_list =[];
// var item_test_count=0;
var spin_again = false;
var SELF_VUE;

// import store from '@/store'
import axiosWrapper from '@/axiosWrapper'
import UserMixin from '@/mixins/UserMixin'

import $ from 'jquery';
window.jQuery = $;
window.$ = $;
import bez from  'jquery-bez'
bez
import BigNumber from 'bignumber.js'
    export default {
        name: 'PromotionPlay',
        mixins: [
            UserMixin,
        ],
        data() {
          return {
               'hideStep1':false,
               'hideStep2':true,
               'hideStep3':true,
               'hideStep5':true,
               'claimReward':false,
               case_obj:null,
               case_group:{},
               case_item:[],  
               all_item_list:[],
               item_list:[
                    null,
               ],
               color_maping : color_class_mapping,
               item_received_list:[],

          };
        },
          methods: {
               convertColor(color,opacity){
                var rgbaCol = 'rgba(' + parseInt(color.slice(-6, -4), 16)
                    + ',' + parseInt(color.slice(-4, -2), 16)
                    + ',' + parseInt(color.slice(-2), 16)
                    + ','+opacity+')';
                    return rgbaCol;
            },
               clickStep1(){
                    this.hideStep1 =true;
                    betGameMuti();
               },
               clickStep2(){
                    this.hideStep2 =true;
                    // this.hideStep3=false;
               },
               clickStep3(){
                    this.hideStep3=true;
                    this.goSignUp();
               },
               clickStep4(){
                   this.hideStep3=false;
                   
               },
               closeStep3(){
                    this.hideStep3=true;
               },
               async getDataByGame() {
                const res = await axiosWrapper({
                    method: 'get',
                    url: `${this.envDomains.VUE_APP_PES_BASE_URL}/api/game/v0/getDataByGame.php?id=1`
                });
                return res.data;
             },
         },
         async mounted() {
              console.log('init ');
               var obj= await  this.getDataByGame();
               console.log('get data success');
               var case_obj = obj.all_case[0];
               this.case_obj =case_obj;

            var case_group = obj.all_case_group;
            this.case_group = case_group;
           
            var case_item = obj.all_case_item;
            this.case_item = case_item;

            var all_item_list =case_group;
            var item_key_list ={};
            for(var key_item =0;key_item<case_item.length;key_item++){
                var item = case_item[key_item];
                item_key_list[item.id]=item;
            }
            var start = new BigNumber(0);
            for(var key_index=0;key_index<all_item_list.length;key_index++){
                var ci  = all_item_list[key_index];
             
                all_item_list[key_index].start =start.toNumber()
                start = start.plus(ci.percent);
                all_item_list[key_index].end = start.toNumber();
                all_item_list[key_index].is_show  = key_index<10?true:false;
                all_item_list[key_index].color_level = color_class_level[ci.quality_color];
                
                // all_item_list[key_index].percent = new BigNumber(all_item_list[key_index].percent).toFixed(8);
                var case_item_id = ci.case_item_id;
                if(case_item_id in item_key_list){
                    
                    var item_key_list_item = item_key_list[case_item_id];

                    for (const property in item_key_list_item) {
                    // for(var key_id in item_key_list_item){
                        if(!(property in ci)){
                            all_item_list[key_index][property]=item_key_list_item[property];
                        }
                    }
                    
                    
                }
            }
          
            ITEMLIST = case_item;
            currentCase = case_obj.id;
            this.all_item_list = all_item_list;
            listCaseGroup = converseCaseGroupNew(case_group);
            var _item_list = initCaseData(0);
            this.item_list= [_item_list];
             SELF_VUE = this;

          //   console.log(this.case_obj);
          //      console.log(this.case_group);
          //   console.log(this.case_item);
          //   console.log(this.all_item_list);
          //   console.log(this.item_list[0]);
         },
    }

    function initCaseData(index){
         console.log("init case");
    var amount = 45;
    var fixPercent = 1;
    var itemList = initItemDataGuaranteePercent(index, amount, fixPercent);
    var new_item_list =[];
    for(var i=0;i<itemList.length;i++){
     //    console.log(itemList[i].item_name);
        new_item_list.push(itemList[i]);
       
    }
    return new_item_list;
}
function initItemDataGuaranteePercent(case_index, total, fixPercent) {
    fixPercent = fixPercent ? fixPercent : 1;

    var listItem = [];
    var percentSumList = {};
    var percentItemList = {};
    var percentItemCountList = {};
    
    for (var i = 0; i < listCaseGroup[currentCase].length; i++) {
        var obj = iterationCopy((listCaseGroup[currentCase][i]));
        listItem.push(obj);

    }
    // console.log(listItem);
    var percentList = [];
    var totlaSumPercent = new BigNumber(0);
    for (var j = 0; j < listItem.length; j++) {
        var percent = listItem[j].percent;
        totlaSumPercent = totlaSumPercent.plus(percent);
        percentList.push(percent);
        if (percent in  percentSumList) {
            percentSumList[percent] = percentSumList[percent].plus(percent);
            percentItemList[percent].push(j);
        } else {
            percentSumList[percent] = new BigNumber(percent);
            percentItemList[percent] = [j];
        }
    }
    
    var uniqueNames = [];
    $.each(percentList, function (ii, el) {
        if ($.inArray(el, uniqueNames) === -1)
            uniqueNames.push(el);
    });
    percentList = uniqueNames;
    percentList.sort(function (a, b) {
        return a - b
    });
    // var totalAmount = 0;
    for (var k = 0; k < percentList.length; k++) {
        var _percent = percentList[k];
        var amount = Math.ceil(((_percent / 100) * (total)));
        percentItemCountList[_percent] = amount;
        // totalAmount += amount;
    }

    var itemBeforeShffle = [];
    var itemFixed = [];
    for (var _i = 0; _i < percentList.length; _i++) {
        var itemCount = percentItemCountList[percentList[_i]];
        var percent2 = percentList[_i];
        var indexList = getIemShffle(percentItemList[percent2], itemCount);
        if (percent2 < fixPercent) {
            for (var index1 = 0; index1 < indexList.length; index1++) {
                var indexItem1 = indexList[index1];
                itemFixed.push(iterationCopy(listItem[indexItem1]));
            }
        } else {
            for (var index2 = 0; index2 < indexList.length; index2++) {
                var indexItem2 = indexList[index2];
                itemBeforeShffle.push(iterationCopy(listItem[indexItem2]));
            }
        }

    }
    // console.log(itemFixed.length);
    // console.log(itemBeforeShffle);
    // return;
    var itemAfterShuffle = shuffleWithNumber(itemBeforeShffle, total - itemFixed.length);

    for (var i2 = 0; i2 < itemFixed.length; i2++) {
        itemAfterShuffle.push(itemFixed[i2]);
    }
    // console.log("V2. Guarantee item under 1% : "+itemFixed.length+" piece");
    itemAfterShuffle = shuffle(itemAfterShuffle);
    // console.log(itemAfterShuffle);

    if (itemAfterShuffle.length < total) {
        for (var index = itemAfterShuffle.length; index < total; index++) {
            var random = Math.floor(Math.random() * listItem.length);
            itemAfterShuffle.push(iterationCopy(listItem[random]));

        }
    }
//     console.log("size:"+itemAfterShuffle.length);
    return itemAfterShuffle;

}
function iterationCopy(src) {
    let target = {};
    for (let prop in src) {
        if (prop in src) {
            target[prop] = src[prop];
        }
    }
    return target;
}
function getIemShffle(a, number) {
    
    // console.log(idList);
    var data = [];
    var radio = Math.ceil(number / a.length);
    // console.log("radio:"+radio);
    if (radio > 1) {
        // var _radio = Math.ceil(a.length / number);
        // console.log("RADIO:"+_radio);
        for (let i = 0; i < a.length; i++) {
            for (var index = 0; index < radio; index++) {
                data.push(a[i]);
            }
        }
    } else {
        for (let i = a.length - 1; i > 0; i--) {
            const j = Math.floor(Math.random() * (i + 1));
            [a[i], a[j]] = [a[j], a[i]];
        }

        for (let i = 0; i < radio; i++) {
            data.push(a[i]);
        }
    }
    return data;
}
function shuffleWithNumber(a, number) {
    for (let i = a.length - 1; i > 0; i--) {
        const j = Math.floor(Math.random() * (i + 1));
        [a[i], a[j]] = [a[j], a[i]];
    }
    var data = [];
    for (var i = 0; i < number && i < a.length; i++) {
        data.push(a[i]);
    }
    return data;
}
function shuffle(a) {
    for (let i = a.length - 1; i > 0; i--) {
        const j = Math.floor(Math.random() * (i + 1));
        [a[i], a[j]] = [a[j], a[i]];
    }
    return a;
}
function converseCaseGroupNew(data) {
    var dataReturn = {};

    for (var i = 0; i < data.length; i++) {
        var result = getItemListByCaseId(data[i]);
        if (data[i]['case_case_id'] in dataReturn) {
            dataReturn[data[i]['case_case_id']].push(result);
        } else {
            dataReturn[data[i]['case_case_id']] = [];
            dataReturn[data[i]['case_case_id']].push(result);

        }
    }
    return dataReturn;
}
function getItemListByCaseId(obj) {
    var result;
//     console.log(ITEMLIST);
    for (var i = 0; i < ITEMLIST.length; i++) {
        // console.log(obj.case_item_id+":"+itemlist[i].id);
        if (obj.case_item_id == ITEMLIST[i].id) {
            // indexRemove = i;
            result = iterationCopy(ITEMLIST[i]);
            result.percent = obj.percent;
            break;
        }
    }

    return result;
}
function betGameMuti() {
     var result ={
          icon_url:'https://pesstar.com/images/caseitem/data_lottery/61.png',
          item_brand:'Voucher',
          item_name:'Voucher 100',
          name_color:'b4b4b2',
          quality_color:'b4b4b2',
          prices:100,
     };
     showBetResultV2(result,0, true);


}
function showBetResultV2(data, number) {
    
    $("#main-case-box-" + number).find(".case-inner").css({transform: "translateX(0px) translateZ(0)"});
    $("#main-case-box-" + number).find(".case-container").show();
    $("#main-case-box-" + number).find(".case-image").hide();
    $("#main-case-box-" + number).find("#case-img").hide();
    $("#main-case-box-" + number).find("#case-reward").hide();
    $("#main-case-box-" + number).find(".case-choice-btn").addClass("disabled-btn").removeClass("hoverable").removeClass("waves-light").addClass("waves-none").css("cursor", "default");

    var is_mute = $('#is_mute').attr('_value');
    // console.log(is_mute);
    if (is_mute==0) {
        // console.log("play odn start...");
        var promise = case_start.play();
        if (promise) {
            //Older browsers may not return a promise, according to the MDN website
            promise.catch(function (error) {
                console.log("error play sound start");
                console.error(error);
            });
        }
    }


    let offset = 0;
    if (offsetOverride >= 0 && offsetOverride <= 5) {
        offset = offsetOverride;
    } else {
        offset = Math.random();
    }
    let markerOffset = $("#main-case-box-" + number).find(".case-container").width() / 2;
    var targetPositionLeft = $('#div-case-item-' + number + '-37').offset().left;
    var firstPositionLeft = $('#div-case-item-' + number + '-0').offset().left;
    // offset=0;
    let finalSpinnerPosition = (-1 * ((targetPositionLeft - firstPositionLeft) - markerOffset)) - (180 * offset);

    //scale(0.9, 0.835)
    $("#main-case-box-" + number).find(".case-inner").addClass("rolling").css({transform: "translateX(" + finalSpinnerPosition * 1 + "px) translateZ(0)"});
    let last = 0;
    if (number == 0) {
        // var bezierEasing = [0, 0.5, 0.5, 0];
        // var tFunc = "cubic-bezier(" + bezierEasing.join(",") + ")";
        // $("#trans").css("-webkit-translation-timing-function", tFunc);
        
        
        $({tracker: 500}).animate({tracker: finalSpinnerPosition}, {duration: 9500, easing: $.bez([.31, .9985, .31, .9985]),
            step: function (now) { // called every frame
                if (last > Math.floor((now - markerOffset - 5) / 200)) {
                    playClick();
                    last = Math.floor((now - markerOffset - 5) / 200);
                }
            }
        })
    }

    setTimeout(() => {
        starburst.show();
        $("#main-case-box-" + number).find(".case-inner").removeClass("rolling")
        setTimeout(function () {
            $("#main-case-box-" + number).find("#case-spin-btn").show();
            sum_count++;

            var amount = num;
            if (sum_count == amount) {
                
               
                showAllItem(data);
                

            }

        }, 333)

        if (number == 0) {
            var is_mute = $('#is_mute').attr('_value');
            if (is_mute==0) {

                setTimeout(function () {
                    case_done["blue"].play();
                    // if (promise) {
                    //     //Older browsers may not return a promise, according to the MDN website
                    //     promise.catch(function (error) {
                    //         console.log("error play sound on end .");
                    //
                    //
                    //     });
                    // } else {
                    //
                    // }
                }, 100);

            }

        }

        $("#main-case-box-" + number).find(".case-image").show();
        $("#main-case-box-" + number).find("#case-spin-btn").text("Spin Again!")
        $("#main-case-box-" + number).find(".case-choice-btn").removeClass("disabled-btn").addClass("hoverable").addClass("waves-light").removeClass("waves-none").css("cursor", "pointer");
        $("#main-case-box-" + number).find("#grate-img").animate({top: -250}, 1250, $.bez([0.42, 0, .8, 1]));
    }, 9500);
    updateRewardItem(number, data);

}
function playClick() {
    if (canPlayClick) {
        canPlayClick = false;
        var is_mute = $('#is_mute').attr('_value');
        if (is_mute==0) {
            var promise = clicks[clicknum++ % 20].play();
            if (promise) {
                //Older browsers may not return a promise, according to the MDN website
                promise.catch(function (error) {
                    console.error(error);
                });
            }
        }

        setTimeout(function () {
            canPlayClick = true;
        }, 200)
    } else {
        if (clickInterval == 0) {
            clickInterval = setInterval(function () {
                if (canPlayClick) {
                    setTimeout(function () {
                        canPlayClick = true;
                    }, 200)
                    var is_mute = $('#is_mute').attr('_value');
                    if (is_mute==0) {
                        var promise = clicks[clicknum++ % 20].play();
                        if (promise) {
                            //Older browsers may not return a promise, according to the MDN website
                            promise.catch(function (error) {
                                console.error(error);
                            });
                        }
                    }

                    clearInterval(clickInterval);
                    clickInterval = 0;
                    canPlayClick = false;
                }
            }, 5);
        }
    }
}
function showAllItem(data) {
     SELF_VUE.item_received_list.push(data);
     SELF_VUE.claimReward=true;
     SELF_VUE.hideStep3 = false;
}

function updateRewardItem(number, item) {
    if (spin_again == true) {
        initCaseData(number);
    }
    var div = $('#div-case-item-' + number + '-37');
    div.children('img').attr("src", item.icon_url);
    var color = item.name_color;
    var item_name = item.item_name;
    div.removeClass('case-item-purple ' +
            'case-item-red ' +
            'case-item-pink ' +
            'case-item-white ' +
            'case-item-blue ' +
            'case-item-gold');
    var bg_color_class = color_class_mapping[color];
    div.addClass('case-item-' + bg_color_class);
    color = '#' + color;
    div.css('border-left-color', color);
    div.css('border-right-color', color);
    var rgbaCol = 'rgba(' + parseInt(color.slice(-6, -4), 16)
            + ',' + parseInt(color.slice(-4, -2), 16)
            + ',' + parseInt(color.slice(-2), 16)
            + ',0.3)';
    div.css('background-color', rgbaCol);
    var rgbaCol2 = 'rgba(' + parseInt(color.slice(-6, -4), 16)
            + ',' + parseInt(color.slice(-4, -2), 16)
            + ',' + parseInt(color.slice(-2), 16)
            + ',0.9)';
    div.children('div.border-top-style').css('background-color', rgbaCol2);
    // div.css('o',color);
    // div.children('div.case-item-value').attr('style',style);
    div.children('div.case-item-value').children('div.item-name').html(item_name);
}
</script>

<style src="@/assets/css/unbox.css?id=13" scoped></style>
<style scoped>


</style>