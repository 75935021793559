<template>
    <div class="BaccaratScoreboardHeader">
        <div class="scoreboard-header-item">
            <div class="bx-baccarat-p"></div>
            <span>{{playerCount}}</span>
        </div>
        <div class="scoreboard-header-item">
            <div class="bx-baccarat-pp"></div>
            <span>{{playerPairCount}}</span>
        </div>
        <div class="scoreboard-header-item">
            <div class="bx-baccarat-t"></div>
            <span>{{tieCount}}</span>
        </div>
        <div class="scoreboard-header-item">
            <div class="bx-baccarat-bp"></div>
            <span>{{bankerPairCount}}</span>
        </div>
        <div class="scoreboard-header-item">
            <div class="bx-baccarat-b"></div>
            <span>{{bankerCount}}</span>
        </div>
    </div>
</template>
<script>

import {GAME_RESULT_KEY} from '../Constant'

export default {
    name: 'BaccaratScoreboardHeader',
    props: [
        'statArr',
    ],
    computed: {
        playerCount() {
            return this.statArr.filter((stat) => {
                return stat.game_result === GAME_RESULT_KEY.PLAYER_WIN
            }).length
        },
        bankerCount() {
            return this.statArr.filter((stat) => {
                return stat.game_result === GAME_RESULT_KEY.BANKER_WIN
            }).length
        },
        tieCount() {
            return this.statArr.filter((stat) => {
                return stat.game_result === GAME_RESULT_KEY.TIE
            }).length
        },
        playerPairCount() {
            return this.statArr.filter((stat) => {
                return stat.is_player_pair === 1
            }).length
        },
        bankerPairCount() {
            return this.statArr.filter((stat) => {
                return stat.is_banker_pair === 1
            }).length
        },
    }
}
</script>
<style scoped>
.BaccaratScoreboardHeader {
    display: flex; 
    align-items: center;
}
.scoreboard-header-item {
    margin: 0 5px;
}
.bx-baccarat-pp::before {
    font-size: 14px;
}
.bx-baccarat-bp::before {
    font-size: 14px;
}
</style>