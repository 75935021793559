import {mixin as VueTimers} from "vue-timers";

export default {
    name: 'PriceMixin',
    mixins: [VueTimers],
    props: [
        'price',
        'price_change',
    ],
    data: function() {
        return {
            isChange: false,
            priceUp: false,
            priceDown: false,
        };
    },
    timers: {
        clearBlinkBlink: {
            time: 8000
        }
    },
    watch: {
        price: function(newVal, oldVal) {
            this.price_change_display(newVal, oldVal);
        },
        price_change: function(newVal, oldVal) {
            this.price_change_display(newVal, oldVal);
        },
    },
    methods: {
        clearBlinkBlink: function(){
            this.isChange = false;
            this.priceUp = false;
            this.priceDown = false;
        },
        price_change_display: function(newVal, oldVal){
            if(!this.isChange){
                this.$timer.stop('clearBlinkBlink');
                this.isChange = true;
                if(newVal > oldVal){
                    this.priceUp = true;
                }else{
                    this.priceDown = true;
                }
                this.$timer.start('clearBlinkBlink');
            }
        }
    }
}