<template>
    <div>
        <div v-if="false" class="box-right-btn">
            <button type="submit" @click="OpenPanel()">เปิด/ปิด</button>
            <button type="submit" @click="GetDiceAll()">แจกลูกเต๋า</button>
<!--            <button type="submit" @click="CloseFlipCard()">ปิดไพ่</button>-->
        </div>
        <div class="box-panelCardMiddle" :class="{'OpanPanel': isPanelOpen}">
            <div class="box-panelCardMiddle-info" :class="{'getDiceAll': diceComplete}">
                <div class="cardMiddleInfoFull DiceFace" >
                    <div class="cardMiddleInfoLeft-content">
                        <strong v-if="isShowDicePoint">{{dicePoint}}</strong>
                        <strong v-else>-</strong>
                        <p v-if="GAME_RESULT_KEY.HI === gameResult">สูง</p>
                        <p v-else-if="GAME_RESULT_KEY.HILO11 === gameResult" class="text-red">ไฮโล</p>
                        <p v-else-if="GAME_RESULT_KEY.LOW === gameResult" class="text-red">ต่ำ</p>
                    </div>
                    <span v-for="(d, i) of dice" :key="`${d}${i}`" class="corDice">
                        <div :class="`dice ${calDiceClass(d)}`"></div>
                    </span>
                    <!-- <span class="corDice">
                        <div class="dice dice-one"></div>
                    </span>
                    <span class="corDice">
                        <div class="dice dice-two"></div>
                    </span>
                    <span class="corDice">
                        <div class="dice dice-three"></div>
                    </span> -->
                </div>
            </div>
        </div>
    </div>



</template>
<script>

    import {GAME_RESULT_KEY} from '@/components/game/sicbo/Constant'

    const DICE_CLASS = {
        1: 'dice-one',
        2: 'dice-two',
        3: 'dice-three',
        4: 'dice-four',
        5: 'dice-five',
        6: 'dice-six',
    }

    export default {
        name: 'FloatOpenDice',
        props: [
            'isPanelOpen',
            'dicePoint',
            'dice',
            'gameResult',
        ],
        data() {
            return {
                open_panel: false,
                get_dice_all: false,
                isShowDicePoint: false,
                GAME_RESULT_KEY: null,
            }
        },
        computed: {
            diceComplete() {
                return this.dice.length === 3
            },
        },
        watch: {
            diceComplete(newVal) {
                if(newVal) {
                    setTimeout(() => {
                        this.isShowDicePoint = true
                    }, 700)
                } else {
                    this.isShowDicePoint = false
                }
            },
        },
        methods: {
            OpenPanel() {
                if(this.open_panel){
                    this.open_panel = false;
                }else{
                    this.open_panel = true;
                }
                this.get_dice_all = false;
            },
            GetDiceAll() {
                if(this.get_dice_all){
                    this.get_dice_all = false;
                }else{
                    this.get_dice_all = true;
                }
            },
            calDiceClass(number) {
                return DICE_CLASS[number]
            },
        },
        created() {
            this.GAME_RESULT_KEY = GAME_RESULT_KEY
        },
    }

</script>

<style>

    .DiceFace .dice {
        width: 45px;
        height: 45px;
        background-size: cover;
        border: solid 1px black;
        border-radius: 2px;
    }

    .DiceFace .dice-one {
        background-image: url(../../../assets/images/dice/one.png);
    }
    .DiceFace .dice-two {
        background-image: url(../../../assets/images/dice/two.png);
    }
    .DiceFace .dice-three {
        background-image: url(../../../assets/images/dice/three.png);
    }
    .DiceFace .dice-four {
        background-image: url(../../../assets/images/dice/four.png);
    }
    .DiceFace .dice-five {
        background-image: url(../../../assets/images/dice/five.png);
    }
    .DiceFace .dice-six {
        background-image: url(../../../assets/images/dice/six.png);
    }
    .box-panelCardMiddle{z-index: 12;}
</style>