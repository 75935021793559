<template>
    <div>
        <button @click="$emit('click')">
            <i v-if="on" class="fas fa-volume-up"></i>
            <i v-else class="fas fa-volume-mute"></i>
        </button>
    </div>
</template>
<script>
export default {
    name: 'GameSoundToggleButton',
    props: ['on'],
}
</script>