<template>

    <div class="wrapContentIndex wrapContentIndexCasino MiniGameFull">

        <div class="wrapContentIndexTitle">
            <div class="hamburger-lines" @click="OpenListProvider()">
                <span class="line line1"></span>
                <span class="line line2"></span>
                <span class="line line3"></span>
            </div>
            <h3>คาสิโน</h3>
        </div>
        <div class="navTopDesktop">
            <MenuNavTop @showMenuNav="showMenuNav"  @showChatWithAdmin="showChatWithAdmin" @showDepositWithdrawModal="showDepositWithdrawModal"></MenuNavTop>
        </div>
        <MenuBar :callMenu="menuNumber" :callDWMenu="showDepositWithdrawNumber">

        </MenuBar>
        <div class="wrapContentIndexInfo">
            <div class="contentFlexIndex">
                <div class="contentFlexIndexLeft active">
                    <MenuLeft></MenuLeft>
                </div>
                <div class="contentFlexIndexRight">
                    <div class="Baccarat">
                        <MiniBaccarat @exit="$router.back()" :key="queryTableId"></MiniBaccarat>
                    </div>
                </div>
            </div>
            <div class="navButtonMobile">
                <MenuNav @showMenuNav="showMenuNav"  @showChatWithAdmin="showChatWithAdmin" @showDepositWithdrawModal="showDepositWithdrawModal"></MenuNav>
            </div>
        </div>
    </div>

</template>
<script>

import MiniBaccarat from '@/components/game/MiniBaccarat'
import MenuBar from '@/components/MenuBar'
import MenuLeft from '@/components/MenuLeft'
import MenuNav from '@/components/MenuNav'
import MenuNavTop from '@/components/MenuNavTop'
export default {
    name: 'BaccaratFull',
    components: {
        MiniBaccarat,
        MenuBar,
        MenuLeft,
        MenuNav,
        MenuNavTop,
    },
    data(){
        return {
            showChatWithAdminNumber:0,
            showDepositWithdrawNumber:0,
        }
    },
    computed: {
        queryTableId() {
            return this.$route.query.table_id
        },
    },
}
</script>
<style scoped>

</style>