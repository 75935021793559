<template>
    <div class="Admin">
        <el-menu :default-active="activeIndex" mode="horizontal" @select="handleSelect">
            <el-menu-item index="toggleLeftMenu"><el-button icon="el-icon-s-fold" size="small"/></el-menu-item>
            <el-menu-item index="SuperAdminAdmin" style="font-size: 22px;">CHAT SUPER ADMIN</el-menu-item>
            <el-menu-item index="logout" style="float: right;"><el-button>LOGOUT</el-button></el-menu-item>
        </el-menu>
        <div class="v-content-wrapper">
            <div class="v-content-left">
                <el-menu :default-active="activeIndex" class="el-menu-vertical-demo" :collapse="isCollapse" @select="handleSelect">
                    <el-menu-item index="SuperAdminAdmin">
                        <i class="el-icon-user"/>
                        <span slot="title">แอดมิน</span>
                    </el-menu-item>
                    <el-menu-item index="SuperAdminSetting">
                        <i class="el-icon-setting"/>
                        <span slot="title">ตั้งค่า</span>
                    </el-menu-item>
                </el-menu>
            </div>
            <div class="v-content-right">
                <router-view/>
            </div>
        </div>
    </div>
</template>
<script>

import store from '@/store'
import axios from 'axios'

export default {
    name: 'SuperAdmin',
    data(){
        return {
            isCollapse: true,
        }
    },
    computed: {
        activeIndex: function(){
            return this.$route.name;
        },
        super_admin(){
            return store.state.super_admin
        },
        token(){
            return (this.super_admin) ? this.super_admin.token : ''
        }
    },
    methods: {
        handleSelect(key) {
            if(key === 'toggleLeftMenu'){
                this.toggleLeftMenu();
            }else if(key === 'logout'){
                this.logout();
            }else if(this.$route.name !== key){
                this.$router.push({name: key});
            }
        },
        toggleLeftMenu: function(){
            this.isCollapse = !this.isCollapse;
        },
        logout: async function(){
            await axios({
                method: 'POST',
                url: `${this.envDomains.VUE_APP_API}/api/super-admin/logout`,
                data: {
                    token: this.token,
                }
            })
            await store.dispatch('superAdminLogout')
            window.location.reload()
        },
        async isSuperAdmin(){
            if(this.super_admin){
                const res = await axios({
                    method: 'POST',
                    url: `${this.envDomains.VUE_APP_API}/api/super-admin/auth`,
                    data: {
                        token: this.token,
                    }
                })
                return res.data.success
            }else{
                return false
            }
        }
    },
    async mounted(){
        if(!await this.isSuperAdmin()){
            this.logout()
        }
    }
}
</script>
<style>
    .v-flex {
        display: flex;
    }
    .v-flex-row-center {
        justify-content: center;
    }
    .el-menu-vertical-demo {
        min-height: 100%;
    }
    .el-menu-vertical-demo:not(.el-menu--collapse) {
        width: 200px;
    }
    .v-content-wrapper {
        display: flex;
    }
    .v-content-left {
        height: calc(100vh - 61px);
    }
    .v-content-right {
        height: calc(100vh - 61px);
        width: 100%;
        overflow-y: auto;
    }
    .v-content-inner {
        padding: 8px;
    }
    .v-toolbar {
        display: flex;
        justify-content: space-between;
        align-items: center;
        padding: 4px;
        margin-bottom: 4px;
    }
    .v-toolbar .toolbar-item {
        display: flex;
    }
    .v-list {
        padding: 4px;
    }
    .v-toolbar-bottom {
        display: flex;
        justify-content: center;
        padding: 8px;
    }
    .v-table-tools {
        justify-content: flex-start;
        display: flex;
    }
    .color-primary {
        color: #409EFF;
    }
    .color-success {
        color: #67C23A;
    }
    .color-warning {
        color: #E6A23C;
    }
    .color-danger {
        color: #F56C6C;
    }
    .color-info {
        color: #909399;
    }
    .text-color-regular {
        color: #606266;
    }
</style>