<template>
    <div class="FloatChip" ref="FloatChip" :class="`mini-ship-${coinClass}`"></div>
</template>
<script>

import {CHIP_MAP} from './Constant'
import randomBetween from '@/lib/randomBetween'

const DURATION = 100
const EASING = 'ease-in'
const CHIP_WIDTH = 20
const CHIP_HEIGHT = 20
const POSTION_PADDING = 5

export default {
    name: 'FloatChip',
    props: ['value', 'choose', 'bound'],
    computed: {
        coinClass() {
            return CHIP_MAP[parseInt(this.value)]['classMini']
        },
        el() {
            return this.$refs.FloatChip
        },
    },
    methods: {
        throwIn() {

            const transform = `rotateZ(${randomBetween(30, 330)}deg)`
            const startTop = `${randomBetween(1, 50) + this.bound.height}px`
            const startLeft = `${randomBetween(1 - this.bound.width, 50 + this.bound.width)}px`
            const toTop = `${randomBetween(POSTION_PADDING, this.bound.height - CHIP_HEIGHT - POSTION_PADDING)}px`
            const toLeft = `${randomBetween(POSTION_PADDING, this.bound.width - CHIP_WIDTH - POSTION_PADDING)}px`
            
            this.el.animate(
                [
                    {top: startTop, left: startLeft, transform: 'rotateZ(0deg)'}, 
                    {top: toTop, left: toLeft, transform}
                ],
                {
                    duration: DURATION, 
                    easing: EASING,
                }
            )
            setTimeout(() => {
                this.el.style['top'] = toTop
                this.el.style['left'] = toLeft
                this.el.style['transform'] = transform
            }, DURATION)
        }
    },
    mounted() {
        this.throwIn()
    }
}
</script>
<style scoped>
.FloatChip {
    position: absolute;
    width: 20px;
    height: 20px;
    background-repeat: no-repeat;
    background-size: contain;
    z-index: 1;
    border-radius: 50%;
    box-shadow: 2px 0px #ddd;
}

.sb-box .FloatChip {
    z-index: 2;
}

.mini-ship-c5 {
    background-image: url(../../assets/images/c-5.png)
}
.mini-ship-c10 {
    background-image: url(../../assets/images/c-10.png)
}
.mini-ship-c20 {
    background-image: url(../../assets/images/c-20.png)
}
.mini-ship-c50 {
    background-image: url(../../assets/images/c-50.png)
}
.mini-ship-c100 {
    background-image: url(../../assets/images/c-100.png)
}
.mini-ship-c200 {
    background-image: url(../../assets/images/c-200.png)
}
.mini-ship-c500 {
    background-image: url(../../assets/images/c-500.png)
}
.mini-ship-c1k {
    background-image: url(../../assets/images/c-1k.png)
}
.mini-ship-c2k {
    background-image: url(../../assets/images/c-2k.png)
}
.mini-ship-c5k {
    background-image: url(../../assets/images/c-5k.png)
}
.mini-ship-c10k {
    background-image: url(../../assets/images/c-10k.png)
}
.mini-ship-c20k {
    background-image: url(../../assets/images/c-20k.png)
}
.mini-ship-c50k {
    background-image: url(../../assets/images/c-50k.png)
}

</style>