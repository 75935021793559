<template>
    <div class="MessageFootball incoming_msg">
<!--        <div class="incoming_msg_img">-->
<!--            <img src="@/assets/images/icon/football.png" alt="sunil" />-->
<!--        </div>-->
        <div class="received_msg">
            <!--<h3>Football</h3>-->
            <div class="received_withd_msg">


                        <carousel
                                :per-page="1.5"
                                :mouseDrag="true"
                                :navigationEnabled="true"
                                :paginationEnabled="false"
                                navigationNextLabel="❯"
                                navigationPrevLabel="❮"
                                paginationPosition="bottom"
                        >

                                <slide v-for="(m, i) of message_data" :key="i">

                                        <div class="box-games-list">
                                            <div class="box-games-list-info">
                                                <div class="heading-table-lotto bg-heading-fb">
                                                    <div class="heading-line">
                                                        <h2>{{m.league_name}}</h2>
                                                        <div>
                                                            {{m.kickoff_time | lottoPriceDateTimeFormat}}
                                                        </div>
                                                        <!--                                            <div>-->
                                                        <!--                                                เวลา : {{m.kickoff_time | timeFormat}}-->
                                                        <!--                                            </div>-->
                                                    </div>
                                                </div>
                                                <div class="box-detail-line">
                                                    <div class="box-teams">
                                                        <div>
                                                            <img :src="getCbjAdminImage(m.picture_match_home)"
                                                                 @error="iconErrorHandle"/>
                                                            <strong :class="{'underline': calIsFavoriteTeam('H', m.odds)}">
                                                                {{m.match_home_name}}
                                                            </strong>
                                                        </div>
                                                        <div class="box-middle-live">
                                                            <strong @click="play(m)" class="text-vs">
                                                                {{m.kickoff_time | timeFormat}}
                                                            </strong>
                                                            <!-- <img src="@/assets/images/football-live/get.png" alt="sunil" />
                                                            <img src="@/assets/images/football-live/Thairath_TV_Logo.svg.png" alt="sunil" /> -->
                                                            <el-image fit="contain" :src="getCbjAdminImage(m.match_tv)">
                                                                <img slot="error" src="@/assets/images/football-live/get.png" alt="sunil" />
                                                            </el-image>
                                                            <!--                                                <strong class="text-vs">VS</strong>-->
                                                        </div>
                                                        <div>
                                                            <img :src="getCbjAdminImage(m.picture_match_away)"
                                                                 @error="iconErrorHandle"/>
                                                            <strong :class="{'underline': calIsFavoriteTeam('A', m.odds)}">
                                                                {{m.match_away_name}}
                                                            </strong>
                                                        </div>
                                                    </div>
                                                    <div class="box-teams-bg"></div>
                                                    <div class="box-info-football">
                                                        <div>
                                                            <strong class="text-hdp">HDP</strong>
                                                        </div>
                                                        <table class="table-hdp">
                                                            <tr v-for="(hdp, i) of m.odds" :key="i">
                                                                <td>
                                                                    <span @click="play(m, {i: i, oddName: 'odds_n1'})" :class="{'underline': calIsFavoritePrice('H', hdp)}">
                                                                        {{hdp.odds_n1}}
                                                                    </span>
                                                                </td>
                                                                <td>
                                                                    <span class="text-hdp">{{hdp.odds_hdp | changeHDP}}</span>
                                                                </td>
                                                                <td>
                                                                    <span @click="play(m, {i: i, oddName: 'odds_n2'})" :class="{'underline': calIsFavoritePrice('A', hdp)}">
                                                                        {{hdp.odds_n2}}
                                                                    </span>
                                                                </td>
                                                            </tr>
                                                        </table>
                                                    </div>
                                                    <div @click="play(m)" class="bt-play-game bt-black">
                                                        เล่นเลย
                                                    </div>
                                                </div>
                                            </div>

                                        </div>


                                </slide>

                        </carousel>



                <span class="time_date"> {{timestamp | chatMessageDateTimeFormat}}</span>
            </div>
        </div>
        <el-dialog
            :title="current_league_name"
            :visible.sync="is_play_dialog_visible"
            width="100%"
            custom-class="custom-dialog"
            append-to-body>
            <span v-if="is_play_dialog_visible">
                <QuickBet 
                :match_id="current_match_id" 
                :data_option="option"
                @exit="is_play_dialog_visible = false"></QuickBet>
            </span>
        </el-dialog>
    </div>
</template>
<script>

import store from '@/store'
import UserMixin from '@/mixins/UserMixin'
import QuickBet from '@/components/football/QuickBet'
import { Carousel, Slide } from 'vue-carousel'

export default {
    name: 'MessageFootball',
    props: ['message'],
    components: {
        QuickBet,
        Carousel,
        Slide,
    },
    mixins: [UserMixin],
    data(){
        return {
            play_iframe_src: '',
            is_play_dialog_visible: false,
            current_match: null,
            option: null,
        }
    },
    computed: {
        message_data(){
            return (this.message) ? this.message.data : null
        },
        timestamp(){
            return (this.message) ? this.message.timestamp : ''
        },
        default_logo(){
            return '/images/icon/football.png'
        },
        pes_profile(){
            return store.state.pes_profile
        },
        token(){
            return (this.pes_profile) ? this.pes_profile.token : ''
        },
        is_member(){
            return store.state.is_member
        },
        current_match_id(){
            return (this.current_match) ? this.current_match.match_id : ''
        },
        current_league_name(){
            return (this.current_match) ? this.current_match.league_name : ''
        }
    },
    filters: {
        adminImage(raw) {
            return raw.replace('/images', '/admin/images')
        },
    },
    methods: {
        play(match, option = null) {
            this.option = option
            this.current_match = match
            this.is_play_dialog_visible = true
        },
        iconErrorHandle(event) {
            event.target.src = this.default_logo
        },
        calIsFavoriteTeam(team, odds) {

            const negative_list = odds.filter((o) => {
                return o.odds_hdp < 0;
            });
            const positive_list = odds.filter((o) => {
                return o.odds_hdp > 0;
            });
            if (negative_list.length > 0 && positive_list.length === 0) {
                return 'A' === team
            } else if (positive_list.length > 0 && negative_list.length === 0) {
                return 'H' === team
            } else {
                return false
            }
        },
        calIsFavoritePrice(team, odd) {
            if (odd.odds_hdp > 0) {
                return 'H' === team
            } else if (odd.odds_hdp < 0) {
                return 'A' === team
            } else {
                return false
            }
        },
        getCbjAdminImage(src) {
            if(!src) {
                return ''
            }
            if(src.includes('http')) {
                return src
            }
            return `${this.envDomains.VUE_APP_CBJADMIN_BASE_URL}/${src}`
        },
    }
}
</script>
<style scoped>
.QuickBet{
    border: 0px !important;
}
.custom-iframe {
    width: 100%; 
    height: 440px; 
    border: 0;
}
</style>
<style>
.custom-dialog .el-dialog__body {
    padding: 0;
}

.custom-dialog{
    border-radius: 20px 20px 0px 0px !important;
    position: fixed !important;
    bottom: -50px !important;
    left: 0px;
    width: 100% !important;
    transition: .35s ease-in-out;
}
.underline {
    /* border-bottom: solid 1px black; */
    text-decoration: underline;
}
.VueCarousel-navigation{
    /*position: absolute;*/
    /*z-index: 2;*/
    /*bottom: 0;*/
    /*top: 0;*/
    /*margin: auto;*/
}

@media only screen
and (min-device-width: 1300px)
and (orientation: landscape){
    .custom-dialog{
        width: 375px!important;
        left: 0!important;
        right: 0!important;
        margin: auto!important;
        bottom: 0!important;
    }
}
</style>