export const USER_CHOOSE = {
    WHITE: 1,
    GREEN: 2,
    YELLOW: 3,
    RED: 5,
}

export const USER_CHOOSE_MAP = {
    1: {
        TH: 'ขาว',
        number: '1',
    },
    2: {
        TH: 'เขียว',
        number: '2',
    },
    3: {
        TH: 'เหลือง',
        number: '3',
    },
    5: {
        TH: 'แดง',
        number: '4',
    },
}

export const GAME_RESULT_KEY = {
    WAIT: 0, // รอผลออก
    WHITE: 1, //ขาวแพ้
    GREEN: 2,//เขียวแพ้
    YELLOW: 3,//เหลืองแพ้
    CANCEL: 4,//ยกเลิก
    RED: 5,//แดงแพ้
}

export const GAME_RESULT_MAP = {
    0: {
        title: 'รอผลออก',
    },
    1: {
        title: 'ขาว',
    },
    2: {
        title: 'เขียว',
    },
    3: {
        title: 'เหลือง',
    },
    4: {
        title: 'ยกเลิก',
    },
    5: {
        title: 'แดง',
    },
}

export const GAME_RESULT = {
    0: {
        EN: 'WAIT',
        TH: 'รอผลออก'
    },
    1: {
        EN: 'white',
        TH: 'ขาว'
    },
    2: {
        EN: 'green',
        TH: 'เขียว'
    },
    3: {
        EN: 'yellow',
        TH: 'เหลือง'
    },
    4: {
        EN: 'CANCEL',
        TH: 'ยกเลิก'
    },
    5: {
        EN: 'red',
        TH: 'แดง'
    },
}

const chipArr = [
    {
        chip: 5,
        quantity: 6,
    },
    {
        chip: 10,
        quantity: 2,
    },
    {
        chip: 50,
        quantity: 3,
    },
    {
        chip: 100,
        quantity: 3,
    },
    {
        chip: 500,
        quantity: 3,
    },
]

export const PANEL_ARR = [
    {
        1: {
            chipArr: [...chipArr]
        },
        2: {
            chipArr: [...chipArr]
        },
        3: {
            chipArr: [...chipArr]
        },
        5: {
            chipArr: [...chipArr]
        },
    },
    {
        1: {
            chipArr: [...chipArr]
        },
        2: {
            chipArr: [...chipArr]
        },
        3: {
            chipArr: [...chipArr]
        },
        5: {
            chipArr: [...chipArr]
        },
    },
    {
        1: {
            chipArr: [...chipArr]
        },
        2: {
            chipArr: [...chipArr]
        },
        3: {
            chipArr: [...chipArr]
        },
        5: {
            chipArr: [...chipArr]
        },
    },
]