<template>
<!--    style="display: none"-->
    <div v-if="false" >
        <div class="box-dummy-user">
            <div class="box-dummy-user-info">

                <img src="@/assets/images/profile1.jpg"/>

                <div class="box-dummy-user-detail">
<!--                    <div class="box-dummy-user-detail-top">-->
<!--                        <div class="UserLevel box-level-incoming_msg bx-lv-0">-->
<!--                            <span>0</span>-->
<!--                        </div>-->
<!--                        <p>4133aa0532</p>-->
<!--                    </div>-->
                    <div class="box-dummy-user-detail-content">
                        <strong>500</strong>
                    </div>

                </div>

            </div>
        </div>
    </div>
</template>
<script>
export default {
    name: 'DummyUser',


}
</script>

<style scoped>
</style>