<template>
    <div class="Baccarat">
        <MiniBaccarat @exit="$router.back()" :key="queryTableId"></MiniBaccarat>
    </div>
</template>
<script>

import MiniBaccarat from '@/components/game/MiniBaccarat'

export default {
    name: 'Baccarat',
    components: {
        MiniBaccarat,
    },
    computed: {
        queryTableId() {
            return this.$route.query.table_id
        },
    },
}
</script>
<style scoped>

</style>