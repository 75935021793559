<template>
    <div class="GameConfirmPanel box-panel-float">
        <div class="box-panel-float-info">
            <div @click="cancel" class="bx-close"></div>
            <div @click="confirm" class="bx-confirm"></div>
        </div>
    </div>
</template>
<script>
export default {
    name: 'GameConfirmPanel',
    methods: {
        confirm() {
            this.$emit('confirm')
        },
        cancel() {
            this.$emit('cancel')
        },
    },
}
</script>