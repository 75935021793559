<template>
    <div v-on:scroll="horizontalScroll" class="TableHeader">
        <div v-if="lineMode === 'single'" class="table-header" :class="{'bg-live': mode === 'live', 'bg-non-live': mode === 'nonlive'}">
            <div class="flex-basis-50">Time</div>
            <div class="flex-basis-200">Event</div>
            <div class="flex-basis-50">HDP</div>
            <div class="flex-basis-50">Home</div>
            <div class="flex-basis-50">Away</div>
            <div class="flex-basis-50">Goal</div>
            <div class="flex-basis-50">Over</div>
            <div class="flex-basis-50">Under</div>
            <div class="flex-basis-50">FH.HDP</div>
            <div class="flex-basis-50">Home</div>
            <div class="flex-basis-50">Away</div>
            <div class="flex-basis-50">FH.G</div>
            <div class="flex-basis-50">Over</div>
            <div class="flex-basis-50">Under</div>
            <div class="flex-basis-50">+ More</div>
        </div>
        <div v-if="lineMode === 'double'" class="table-header-double-line" :class="{'bg-live': mode === 'live', 'bg-non-live': mode === 'nonlive'}">
            <div class="flex-basis-50">Time</div>
            <div class="flex-basis-200">Event</div>
            <div class="flex-column" style="flex-basis: 344px;">
                <div>Full Time</div>
                <div class="flex">
                    <div style="flex-basis: 101px;">HDP</div>
                    <div style="flex-basis: 121px;">OU</div>
                    <div style="flex-basis: 51px;">1X2</div>
                    <div style="flex-basis: 71px;">OE</div>
                </div>
            </div>
            <div class="flex-column" style="flex-basis: 273px;">
                <div>First Half</div>
                <div class="flex">
                    <div style="flex-basis: 101px;">HDP</div>
                    <div style="flex-basis: 121px;">OU</div>
                    <div style="flex-basis: 51px;">1X2</div>
                </div>
            </div>
            <div class="flex-basis-50">+ More</div>
        </div>
    </div>

</template>

<script>
    export default {
        name: "TableHeader",
        props: ['mode', 'lineMode'],
        methods: {
            horizontalScroll: function(e){
                this.$emit('scroll', e);
            }
        }
    }
</script>

<style scoped>
    .table-header {
        display: flex;
        text-align: start;
        color: #ffffff;
        font-size: 12px;
        padding: 1px 2px;
        height: 30px;
    }
    .table-header > div {
        padding: 5px 1.5px 5px 0;
    }
    .table-header-double-line {
        display: flex;
        align-items: center;
        text-align: start;
        color: #ffffff;
        font-size: 12px;
        padding: 1px 2px;
        height: 30px;
    }
    .table-header-double-line > div {
        /*padding: 5px 1.5px 5px 0;*/
    }
    .bg-live {
        background-color: #f47f51;
    }
    .bg-non-live {
        background-color: #123052;
    }
    /*div {*/
    /*    border: solid 1px red;*/
    /*}*/
    @media only screen and (max-width: 820px){
        .table-header {
            width: 820px;
        }
        .table-header-double-line {
            width: 820px;
        }
    }
</style>