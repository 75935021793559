<template>

    <div class="BillDetail">
        <div class="BillDetailInfo row-menu-grid align-self-stretch">
            <div class="nav-top" >
                <div class="col-nav" style="display: block;">
                    <button @click="goBack" class="btn">
                        <i class="fas fa-chevron-left"></i>
                    </button>
                </div>
                <h2>รายละเอียดโพย</h2>
            </div>
            <div v-if="data" class="container-bill-detail">
<!--                <div class="topic-header-page">-->
<!--                    <button v-if="false" @click="goBack" class="btn">-->
<!--                        <i class="fas fa-chevron-left"></i> <small>ย้อนกลับ</small>-->
<!--                    </button>-->
<!--                    <h3>รายละเอียดโพย</h3>-->
<!--                </div>-->
                <div class="content-bill-detail">
                        <div class="topic-content-bill-detail">
                            <button v-if="false" @click="goBack" class="btn btn-back">
                                <i class="fas fa-chevron-left"></i>
                            </button>
                            <div class="top-bill-detail">
                                <ul class="top-bill-detail-info-title">
                                    <li>
                                        <h2>{{data.lotto_type | lottoTypeDisplay}}</h2>
                                        <div v-show="data.sub_round" class="ribbon-lotto">
                                            <h3>รอบที่ {{data.sub_round}}</h3>
                                        </div>
                                    </li>
                                    <li>
                                        <strong>งวดประจำวันที่ : </strong>
                                        <span>{{toMoment(data.round) | dateTimeFormat('DD/MM/YYYY')}}</span>
                                        <!-- <button v-if="bill_status === 'running'"
                                        @click="toCancelBill"
                                        class="cancelBillButton btn btn-bill-return btn-sm btn-outline-danger">
                                            คืนโพย
                                        </button> -->
                                    </li>
                                </ul>
                                <ul class="top-bill-detail-info">
                                    <li>
                                        <strong>โพยเลขที่ :</strong>
                                        <span >#{{data.bill_id}}</span>
                                    </li>
                                    <li>
                                        <strong>วันที่ทำรายการ :</strong><span>{{data.created_at | dateTimeFormat}}</span>
                                    </li>
                                </ul>
                            </div>

                        </div>
                    <div class="box-wrap-bull-detail">
                                <span>

                                    <div class="tx-balance">{{data.amount | numberFormat}}</div>
                                    <p>ยอดแทง</p>
                                </span>
                        <span>

                                    <div v-if="bill_status === 'running' || bill_status === 'cancel'" class="tx-balance">-</div>
                                    <div v-else class="tx-balance" :class="{'text-success': profit_loss > 0}"> {{profit_loss | numberFormat | is_number_positive}}</div>
                                     <p>ผลได้/เสีย</p>
                                </span>
                    </div>

                        <div class="content-bill-detail-info">
                            <div class="table-responsive table-content-bill-detail">
                                <table class="table-bill-detail table-hover">
                                    <thead>
                                    <tr>
                                        <th>ประเภทการแทง</th>
                                        <th>ตัวเลขที่เลือก</th>
                                        <th>ราคาจ่าย</th>
                                        <th>จำนวนเงิน</th>
                                        <th>เลขที่ออก</th>
                                        <th>ผลการออกรางวัล</th>
                                    </tr>
                                    </thead>
                                    <tbody>
                                    <tr v-for="bill in bill_list" :key="bill.id">
                                        <td><span>{{bill.bet_type | bet_type_display}}</span></td>
                                        <td><span class="betNum-bill">{{bill.bet_number}}</span></td>
                                        <td><span>{{bill.reward | floatFormat}}</span></td>
                                        <td><span>{{bill.amount | floatFormat}}</span></td>
                                        <td><span>{{bill.result_number_list | array_to_str_list((bill.bet_type === 'wing_bon' || bill.bet_type === 'wing_lang') ? '' : ', ')}}</span></td>
                                        <td><span class="badge"
                                                    :class="{
                                    'badge-success': bill.status === 'win',
                                    'badge-danger': bill.status === 'lose',
                                    'badge-warning': bill.status === 'running',
                                    'badge-secondary': bill.status === 'cancel'
                                }"
                                        >{{bill.status | lottoStatusDisplay}}</span></td>
                                    </tr>
                                    </tbody>
                                </table>
                            </div>
                        </div>
                    </div>

            </div>
        </div>
        <b-modal @ok="cancelBill" id="cancel_bill_confirm_modal" hide-header centered>
            <p class="my-4">คุณต้องการคืนโพยเลขที่ #{{bill_id}} ใช่หรือไม่ ?</p>
            <p class="my-4">คุณสามารถยกเลิกโพยได้ 5 ครั้ง ต่อวัน</p>
        </b-modal>
    </div>
</template>

<script>

    import axios_wrapper from '@/axiosWrapper';
    import store from '@/store'
    import AppMixin from '../mixins/App'

    export default {
        name: "BillDetail",
        props: [
            'bill_id'
        ],
        mixins: [
            AppMixin
        ],
        data(){
            return {
                data: null,
                loading: null,
            }
        },
        computed: {
            bill_list: function(){
                return (this.data) ? this.data.bill_list : [];
            },
            profit_loss: function(){
                return (this.data) ? parseFloat(this.data.result) - parseFloat(this.data.amount) : 0;
            },
            pes_profile: function () {
                return store.state.pes_profile;
            },
            token(){
                return this.pes_profile.token
            },
            first_bill(){
                return this.bill_list[0]
            },
            bill_status(){
                return this.first_bill.status
            },
        },
        watch: {
            bill_id(newVal) {
                if(newVal) {
                    this.get_data()
                }
            }
        },
        methods: {
            get_data: async function(){
                if(!this.bill_id) {
                    return
                }
                this.loading = this.$loading()
                const res = await axios_wrapper({
                    method: 'POST',
                    url: `${this.envDomains.VUE_APP_LOTTO_API}/api/bill/get_by_bill_id`,
                    data: {
                        bill_id: this.bill_id,
                        token: this.token
                    }
                });
                if(res.status === 200){
                    this.data = res.data;
                }else{
                    this.$bvToast.toast(res.data.message, {title: 'แจ้งเตือน'});
                }
                this.loading.close()
            },
            goBack: function () {
                this.$emit('close')
            },
            toMoment: function(val){
                return this.$moment(val);
            },
            toCancelBill(){
                this.$bvModal.show('cancel_bill_confirm_modal')
            },
            async cancelBill(){
                const res = await axios_wrapper({
                    method: 'POST',
                    url: `${this.envDomains.VUE_APP_LOTTO_API}/api/bill/cancel`,
                    data: {
                        bill_id: this.bill_id,
                        token: this.token
                    }
                });
                if(res.status === 200){
                    this.get_data()
                    this.$bvToast.toast(`คืนโพยสำเร็จ`, {title: 'แจ้งเตือน'});
                }else{
                    this.$bvToast.toast(res.data.message, {title: 'แจ้งเตือน'});
                }
            }
        },
        mounted() {
            this.get_data();
        }
    }
</script>

<style scoped>
    /* /deep/ .topic-header-page-desktop button.btn{display: block;} */
    /*@media screen and (max-width: 768px) {*/
    /*    .BillDetail {*/
    /*        min-height: 100vh;*/
    /*    }*/

    /*}*/
    /*@media screen and (min-width: 769px) {*/
    /*    .BillDetail {*/
    /*        min-height: calc(100vh - 64px);*/
    /*    }*/
    /*}*/

    .top-bill-detail-info li {
        /*margin: 0 4px;*/
    }
    .cancelBillButton {
        margin-left: 4px;
    }
</style>