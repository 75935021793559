<template>
    <div v-show="false" class="Feeling box-feeling">
        <div v-show="isShowPanel" class="box-select-feeling">
            <span v-for="(react, key) of reacts" :key="key" 
            :class="react.class"
            @click="handleReact(react.key)"
            >
            </span>
        </div>
        <div class="v-reacted-wrapper">
            <span v-for="(react, key) of reacted.types" 
            :key="key"
            class="v-reacted-icon"
            :class="calReactClass(react)"
            ></span>
            <span class="v-reacted-count">{{reacted.count | numberFormat}}</span>
        </div>
        <div @click="togglePanel" class="box-bt-feeling">
            <span><i class="far fa-smile"></i></span> <span>+</span>
        </div>
    </div>
</template>
<script>

import randomBetween from '@/lib/randomBetween'

export default {
    name: 'Feeling',
    data(){
        return {
            isShowPanel: false,
            reacts: [
                {key: 'angry', class: 'i-angry'},
                {key: 'smile', class: 'i-smile'},
                {key: 'cry', class: 'i-cry'},
                {key: 'love', class: 'i-love'},
                {key: 'laugh', class: 'i-laugh'},
                {key: 'like', class: 'i-like'},
                {key: 'dislike', class: 'i-dislike'},
            ],
            reacted: {
                count: randomBetween(100, 1000),
                types: ['like', 'laugh', 'love']
            }
        }
    },
    methods: {
        calReactClass(type){
            return this.reacts.find((react) => {return react.key === type}).class
        },
        togglePanel(){
            this.isShowPanel = !this.isShowPanel
        },
        handleReact(type){
            this.$emit('react', type)
            this.isShowPanel = false
        },
    }
}
</script>
<style scoped>
.v-reacted-wrapper {
    display: flex;
    position: absolute;
    right: 1px;
    bottom: -20px;
    padding: 0px 5px;
    background-color: #fff;
    box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
    border-radius: 10px;
    font-size: 12px;
    color: #888;
}
.v-reacted-wrapper span.v-reacted-icon {
    display: inline-block;
    color: #d49c08;
    margin-right: 1px;
    cursor: pointer;
    width: 12px;
    height: 16px;
    vertical-align: middle;
}
.box-bt-feeling {
    right: -35px;
}
.v-reacted-wrapper span.i-angry:before {
    content: '\f556';
    font: 800 12px 'Font Awesome\ 5 Free';
}

.v-reacted-wrapper span.i-smile:before {
    content: '\f118';
    font: 800 12px 'Font Awesome\ 5 Free';
}

.v-reacted-wrapper span.i-cry:before {
    content: '\f5b4';
    font: 800 12px 'Font Awesome\ 5 Free';
}

.v-reacted-wrapper span.i-love:before {
    content: '\f004';
    color: #e72a2a;
    font: 800 12px 'Font Awesome\ 5 Free';
}

.v-reacted-wrapper span.i-laugh:before {
    content: '\f59a';
    font: 800 12px 'Font Awesome\ 5 Free';
}

.v-reacted-wrapper span.i-like:before {
    content: '\f164';
    font: 800 12px 'Font Awesome\ 5 Free';
}

.v-reacted-wrapper span.i-dislike:before {
    content: '\f165';
    font: 800 12px 'Font Awesome\ 5 Free';
}
</style>