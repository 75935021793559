<script>

import ReconnectingWebSocket from 'reconnecting-websocket'
import Keyboard from '@/components/Keyboard'
import MessageTopUp from '@/components/message/TopUp'
import MessageTimeline from '@/components/message/Timeline'
import MessageGallery from '@/components/message/Gallery'
import MessageLive from '@/components/message/Live'
import MessageDateSeperator from '@/components/message/DateSeperator'
import MessageOwnerText from '@/components/message/OwnerText'
import MessageIncomingText from '@/components/message/IncomingText'
import MessageFootball from '@/components/message/Football'
import MessageSystem from '@/components/message/System'
import MessageLottoNews from '@/components/message/LottoNews'
import MessageLottoPrice from '@/components/message/LottoPrice'
import MessageBaccarat from '@/components/message/Baccarat'
import MessageSicbo from '@/components/message/Sicbo'
import MessageUnboxItemAlert from '@/components/message/UnboxItemAlert'
import MessageBaccaratAlert from '@/components/message/BaccaratAlert'
import MessageRoulette from '@/components/message/Roulette'
import MessageLottoResult from '@/components/message/LottoResult'
import MessageLottoResultWinner from '@/components/message/LottoResultWinner'
import ResultMiniGame from '@/components/message/ResultMiniGame'
import MessageMinigame from '@/components/message/MinigameAlert'
import IncomingChat from '@/components/message/IncomingChat'
import IncomingEmoji from '@/components/message/IncomingEmoji'
import MessagePromotion from '@/components/message/Promotion'
import MessageBonus from '@/components/message/Bonus'
import MessageRich from '@/components/message/Rich'
import BalloonGame from '@/components/balloon/Game'
import axios from 'axios'
import store from '@/store'
import AdditionalDataMixin from '@/mixins/AdditionalDataMixin'
import Pyro from '@/components/Pyro'
import UserMixin from '@/mixins/UserMixin'
import LayoutMixin from '@/mixins/Layout'
import {mixin as VueTimers} from 'vue-timers'
import randomBetween from '@/lib/randomBetween'
const AT_TOP_OFFSET = 640
const AT_BOTTOM_OFFSET = 100
const INIT_MESSAGE_COUNT = 30
const PREV_MESSAGE_COUNT = 50

// const DEMO_BALLOON = {
//     "type": "balloon_game",
//     "data": {
//         "list": [
//             {
//                 "user_id": 1407,
//                 "amount": "1000.00000000",
//                 "bet_list": {
//                     "user_choose": "2",
//                     "table_id": 1,
//                     "gbs_id": "86431",
//                     "round_main": 6,
//                     "round_sub": 61,
//                     "table_name": "Baccarat B1",
//                     "game_info": {
//                         "card": [
//                             {
//                                 "side": 0,
//                                 "card_type": 2,
//                                 "index": 2,
//                                 "card_number": 10
//                             },
//                             {
//                                 "side": 1,
//                                 "card_type": 0,
//                                 "index": 0,
//                                 "card_number": 12
//                             },
//                             {
//                                 "side": 0,
//                                 "card_type": 2,
//                                 "index": 1,
//                                 "card_number": 11
//                             },
//                             {
//                                 "side": 1,
//                                 "card_type": 3,
//                                 "index": 1,
//                                 "card_number": 5
//                             },
//                             {
//                                 "side": 0,
//                                 "card_type": 0,
//                                 "index": 0,
//                                 "card_number": 8
//                             },
//                             {
//                                 "side": 1,
//                                 "card_type": null,
//                                 "index": 2,
//                                 "card_number": null
//                             }
//                         ]
//                     },
//                     "real_amount": "1000",
//                     "amount": "1000",
//                     "result": 1,
//                     "result_return": 2000,
//                     "profit_loss": 1000,
//                     "payout": 2
//                 }
//             },
//             {
//                 "user_id": 1476,
//                 "amount": "1000.00000000",
//                 "bet_list": {
//                     "user_choose": "2",
//                     "table_id": 1,
//                     "gbs_id": "86435",
//                     "round_main": 7,
//                     "round_sub": 4,
//                     "table_name": "Baccarat B1",
//                     "game_info": {
//                         "card": [
//                             {
//                                 "side": 0,
//                                 "card_type": 0,
//                                 "index": 2,
//                                 "card_number": 7
//                             },
//                             {
//                                 "side": 1,
//                                 "card_type": 1,
//                                 "index": 0,
//                                 "card_number": 9
//                             },
//                             {
//                                 "side": 0,
//                                 "card_type": 2,
//                                 "index": 1,
//                                 "card_number": 1
//                             },
//                             {
//                                 "side": 1,
//                                 "card_type": 1,
//                                 "index": 1,
//                                 "card_number": 2
//                             },
//                             {
//                                 "side": 0,
//                                 "card_type": null,
//                                 "index": 0,
//                                 "card_number": null
//                             },
//                             {
//                                 "side": 1,
//                                 "card_type": null,
//                                 "index": 2,
//                                 "card_number": null
//                             }
//                         ]
//                     },
//                     "real_amount": "1000",
//                     "amount": "1000",
//                     "result": 1,
//                     "result_return": 2000,
//                     "profit_loss": 1000,
//                     "payout": 2
//                 }
//             }
//         ],
//         "table_type": 3,
//     },
//     "timestamp": 1616489700717,
// }

export default {
    name: 'ChatMixin',
    components: {
        Keyboard,
        MessageLive,
        MessageTopUp,
        MessageUnboxItemAlert,
        MessageDateSeperator,
        MessageOwnerText,
        MessageIncomingText,
        MessageFootball,
        MessageSystem,
        MessageLottoNews,
        MessageLottoPrice,
        MessageBaccarat,
        MessageSicbo,
        MessageBaccaratAlert,
        MessageTimeline,
        MessageGallery,
        MessageRoulette,
        MessageLottoResult,
        MessageLottoResultWinner,
        MessageMinigame,
        ResultMiniGame,
        IncomingChat,
        IncomingEmoji,
        Pyro,
        BalloonGame,
        MessagePromotion,
        MessageBonus,
        MessageRich,
    },
    mixins: [
        AdditionalDataMixin, 
        UserMixin, 
        LayoutMixin,
        VueTimers,
    ],
    timers: {
        clearBalloonGameData: {
            time: 2 * 8 * 1000,
        },
        clearBalloonLottoResultData: {
            time: 4 * 8 * 1000,
        },
        appendDemoMessage: {
            time: 5 * 1000,
            repeat: true,
        },
    },
    props: [
        'data_room_id',
    ],
    data(){
        return {
            message_arr: [],
            is_on_init: true,
            socket: null,
            socket_status: '',
            notify: null,
            is_at_top: false,
            is_on_load_prev: false,
            is_no_more_prev: false,
            is_chat_disable: false,
            message_scroll: null,
            is_open_box_full_video: false,
            is_box_mini_video: false,
            is_big_win: false,
            is_slide_message_show: false,
            current_live_url: '',
            balloonGameData: null,
            balloonLottoResultData: null,
            demo_message_arr: [],
            isShowEmojiPanel: false,
        }
    },
    computed: {
        first_message(){
            return this.message_arr[0]
        },
        latest_message(){
            return this.message_arr[this.message_arr.length - 1]
        },
        token(){
            return this.pes_profile.token
        },
        user_profile(){
            return store.state.user_profile
        },
        user_id(){
            return (this.user_profile) ? this.user_profile.id : ''
        },
        pes_profile(){
            return store.state.pes_profile
        },
        is_member(){
            return store.state.is_member
        },
        message_api_id(){
            return store.state.message_api_id
        },
        room_id(){
            if(this.data_room_id) {
                if(this.data_room_id.includes('table_id')) {// is game room
                    return this.data_room_id
                }
                return `${this.message_api_id}${this.data_room_id}`
            } else {
                return `${this.message_api_id}`
            }
        },
        user_info_arr(){
            return store.state.user_info_arr
        },
        is_can_load_prev(){
            return !this.is_on_load_prev && !this.is_no_more_prev && this.first_message
        },
        is_user_ban: {
            get(){
                return store.state.is_user_ban
            },
            set(val){
                store.commit('updateIsUserBan', val)
            }
        },
        embedMode() {
            return store.state.embedMode
        },
        messageSectionWidth: {
            get() {
                return store.state.messageSectionWidth
            },
            set(value) {
                store.commit('updateMessageSectionWidth', value)
            }
        },
    },
    watch: {
        socket_status(new_val){
            if(new_val === 'close'){
                this.$message({
                    message: 'การเชื่อมต่อขัดข้อง กำลังเชื่อมต่อใหม่อีกครั้ง',
                    type: 'warning',
                    offset: -1,
                    duration: 10*1000
                })
            }
        },
        is_big_win(new_val){
            if(new_val){
                const _this = this
                setTimeout(() => {
                    _this.is_big_win = false
                }, 10000)
            }
        },
        message_scroll(newVal) {
            if(newVal) {
                this.messageSectionWidth = newVal.clientWidth
            }
        },
    },
    methods: {
        async init(){
            
            try{

                const localChatMesssageArr = JSON.parse(localStorage.getItem('chat_message_arr_room_id='+this.room_id))
                if(localChatMesssageArr && localChatMesssageArr.length > 0) {
                    this.appendMessage(localChatMesssageArr)
                    this.scrollToBottom('auto', 1)
                } else {
                    this.message_arr = []
                }

                this.is_on_init = true
                
                const chat_init_res = await axios({
                    method: 'POST',
                    url: `${this.envDomains.VUE_APP_API}/api/chat/init`,
                    data: {
                        room_id: this.room_id,
                        limit: INIT_MESSAGE_COUNT,
                        is_guess: (!this.is_member) ? 'yes' : 'no',
                    }
                })
                if(!chat_init_res.data.success){
                    this.$message({
                        message: chat_init_res.data.message,
                        type: 'error',
                        offset: -1
                    })
                    return
                }

                localStorage.setItem('chat_message_arr_room_id='+this.room_id, JSON.stringify(chat_init_res.data.data))

                this.message_arr = []

                this.appendMessage(chat_init_res.data.data)
                if(this.is_member){
                    this.joinRoom()
                } else {
                    this.demo_message_arr = chat_init_res.data.demo_message_arr
                    const demoMessageIdleUntil = localStorage.getItem('demoMessageIdleUntil')
                    if(demoMessageIdleUntil) {
                        const demoMessageIdleUntilMoment = this.$moment(demoMessageIdleUntil)
                        const now = this.$moment()
                        const isAfter = now.isAfter(demoMessageIdleUntilMoment)
                        if(isAfter) {
                            this.$timer.start('appendDemoMessage')
                        } else {
                            this.appendMessage(this.demo_message_arr)
                        }
                    } else {
                        this.$timer.start('appendDemoMessage')
                    }
                }
                
                this.is_on_init = false

                this.scrollToBottom('auto', 1)

            }catch(err){
                console.log('init err', err.message)
            }
        },
        idArrFromMessageArr(message_arr){
            return Array.from(new Set(message_arr.map((message) => {
                return message.user_id
            })
            .filter((id) => {
                return !!id
            })))
        },
        idPesArrFromMessageArr(message_arr){

            const filteredArr = message_arr.filter((message) => {
                return message.type === 'lotto_win'
            })

            return Array.from(new Set(filteredArr.map((message) => {
                return message.data.user_id
            })
            .filter((id) => {
                return !!id
            })))
        },
        checkAlreadyIncomingChat(user_id) {
            return !!(this.message_arr.find((message) => {
                return message.type === 'incoming_chat' && message.user_id === user_id
            }))
        },
        appendDemoMessage() {

            const message = this.demo_message_arr.splice(0, 1)
            if(message.length > 0) {
                this.appendMessage(message)
                const newTime = randomBetween(1, 4)
                if(newTime === 0) {
                    this.appendMessage(message)
                } else {
                    this.timers.appendDemoMessage.time = newTime * 1000
                    this.$timer.stop('appendDemoMessage')
                    this.$timer.start('appendDemoMessage')
                }
            } else {
                this.$timer.stop('appendDemoMessage')
                setTimeout(() => {
                    this.scrollToBottom()
                }, 400)
                setTimeout(() => {
                    store.commit('updateNeedManageDailyBonusMockup', true)
                    localStorage.setItem('demoMessageIdleUntil', this.$moment().add('minute', 5).format('YYYY-MM-DD HH:mm:ss'))
                }, 15 * 1000)
            }
            if(this.checkIsOnBottom()){
                this.scrollToBottom()
            }
        },
        async appendMessage(message_arr){

            const id_arr = this.idArrFromMessageArr(message_arr)
            this.loadUserInfoArr(id_arr)

            const id_pes_arr = this.idPesArrFromMessageArr(message_arr)
            this.loadUserInfoArrByPesUserId(id_pes_arr)

            for(let i = 0;i < message_arr.length;i++){
                const message = message_arr[i]
                // const reacts = await this.getReact(message)
                // console.log('reacts', reacts)
                this.dateSeperate(this.latest_message, message)

                if(message.type === 'incoming_chat') {
                    const already = this.checkAlreadyIncomingChat(message.user_id)
                    if(already) {
                        return
                    }
                }
                this.message_arr.push(message)
            }
        },
        prependMessage(message_arr){

            const id_arr = this.idArrFromMessageArr(message_arr)
            this.loadUserInfoArr(id_arr)

            const id_pes_arr = this.idPesArrFromMessageArr(message_arr)
            this.loadUserInfoArrByPesUserId(id_pes_arr)

            for(let i = 0;i < message_arr.length;i++){
                const message = message_arr[i]
                this.dateSeperatePrev(this.first_message, message)
                this.message_arr.splice(0, 0, message)
            }
        },
        checkIsOnBottom(){
            if(!this.message_scroll){
                return false
            }
            return !!((window.innerHeight + this.message_scroll.scrollTop + AT_BOTTOM_OFFSET) >= this.message_scroll.scrollHeight)
        },  
        checkIsOwnerMessage(message_id, user_id){
            return parseInt(message_id) === parseInt(user_id)
        },
        scrollToBottom(mode = 'smooth', delay = 100){
            setTimeout(() => {
                if(this.message_scroll){
                    this.message_scroll.style['scroll-behavior'] = mode
                    this.message_scroll.scrollTo(0, this.message_scroll.scrollHeight)
                }
                window.scrollTo(0, document.body.scrollHeight)
            }, delay)
        },
        notifyNewMessage(){
            if(this.notify){
                this.notify.close()
            }
            this.notify = this.$notify({
                dangerouslyUseHTMLString: true,
                message: '<div><i class="far fa-comment-dots"></i> มีข้อความใหม่</div>',
                duration: 0,
                onClick: () => {
                    this.scrollToBottom()
                    this.notify.close()
                }
            })
        },
        dateSeperate(prev, cur){
            if(prev){
                const prev_date = this.$moment(prev.timestamp).format('YYYY-MM-DD')
                const cur_date = this.$moment(cur.timestamp).format('YYYY-MM-DD')
                if(prev_date !== cur_date){
                    this.message_arr.push({
                        type: 'date_seperator',
                        timestamp: cur.timestamp
                    })
                }
            }
        },
        dateSeperatePrev(prev, cur){
            if(prev){
                const prev_date = this.$moment(prev.timestamp).format('YYYY-MM-DD')
                const cur_date = this.$moment(cur.timestamp).format('YYYY-MM-DD')
                if(prev_date !== cur_date){
                    this.message_arr.splice(0, 0, {
                        type: 'date_seperator',
                        timestamp: prev.timestamp
                    })
                }
            }
        },
        clearBalloonGameData() {
            this.balloonGameData = null
            this.$timer.stop('clearBalloonGameData')
        },
        clearBalloonLottoResultData() {
            this.balloonLottoResultData = null
            this.$timer.stop('clearBalloonLottoResultData')
        },
        onBalloonGameHandle(data) {
            
            this.balloonGameData = data
            const pes_id_list = this.balloonGameData.data.list.map((o) => {
                return o.user_id
            })
            this.loadUserInfoArrByPesUserId(pes_id_list)

            this.$timer.start('clearBalloonGameData')
        },
        onBalloonLottoResultHandle(data) {
            this.balloonLottoResultData = data
            this.$timer.start('clearBalloonLottoResultData')
        },
        messageRecieve(event){

            const message_recieve = JSON.parse(event.data)

            if(message_recieve.type === 'baccarat_alert'){
                this.is_big_win = true
            }

            if(message_recieve.type === 'incoming_chat' && message_recieve.user_id === this.user_id){
                return
            }

            if(message_recieve.type === 'balloon_game') {
                this.onBalloonGameHandle(message_recieve)
                return
            }

            if(message_recieve.type === 'balloon_lotto_result') {
                this.onBalloonLottoResultHandle(message_recieve)
                return
            }

            this.appendMessage([message_recieve])

            if(this.checkIsOwnerMessage(message_recieve.user_id, this.user_id)){
                this.scrollToBottom()
            }else{
                if(this.checkIsOnBottom()){
                    this.scrollToBottom()
                }else{
                    if(message_recieve.type === 'incoming_chat'){
                        return
                    }
                    // this.notifyNewMessage()
                }
            }
        },
        socketOpenHandle(){
            if(!this.room_id){
                return
            }
            this.socket_status = 'open'
            if(this.socket.readyState === 1){
                this.socket.send(JSON.stringify({
                    task: 'join_room',
                    data: {
                        token: this.token,
                        room_id: this.room_id
                    }
                }))
            }
        },
        destroySocket() {
            if(this.socket) {
                this.socket.removeEventListener('open', this.socketOpenHandle)
                this.socket.removeEventListener('message', this.messageRecieve)
                this.socket.close()
            }
        },
        joinRoom(){
            this.destroySocket()
            this.socket = new ReconnectingWebSocket(`${this.envDomains.VUE_APP_WS}/api/chat/join`)
            this.socket.addEventListener('open', this.socketOpenHandle)
            this.socket.addEventListener('message', this.messageRecieve)
        },
        async sendMessage(context){

            try{

                const {type, message, detail} = context

                if(!this.is_member){
                    this.notifyToSignUp('เพื่อส่งข้อความแชท')
                    return
                }

                if(!message || this.is_chat_disable){
                    return
                }

                const chat_send_res = await axios({
                    method: 'POST',
                    url: `${this.envDomains.VUE_APP_API}/api/chat/send`,
                    data: {
                        token: this.token,
                        room_id: this.room_id,
                        type: type,
                        data: {
                            message: message,
                            detail: detail,
                        },
                        user_id: this.user_id
                    }
                })
                if(!chat_send_res.data.success){
                    this.is_chat_disable = true
                    if(chat_send_res.data.message_code === 'user_banned'){
                        this.$alert('บัญชีของคุณถูกระงับ', 'แจ้งเตือน', {
                            confirmButtonText: 'ตกลง',
                            customClass: 'custom-message-box'
                        })
                        this.is_user_ban = true
                        return
                    }
                    this.$message({
                        message: chat_send_res.data.message,
                        type: 'error',
                        offset: -1
                    })
                    return
                }
                this.is_chat_disable = false
            }catch(err){
                console.log('sendMessage err', err.message)
            }
        },
        
        onScrollHandle(scroll_pos){
            if(scroll_pos < AT_TOP_OFFSET){
                this.is_at_top = true
            }else{
                this.is_at_top = false
            }
        },
        async loadPrevMessage(){
            if(this.is_can_load_prev){
                try{
                    this.is_on_load_prev = true
                    const res = await axios({
                        method: 'POST',
                        url: `${this.envDomains.VUE_APP_API}/api/chat/load-prev-message`,
                        data: {
                            room_id: this.room_id,
                            latest_message: this.first_message.id || 1,
                            limit: PREV_MESSAGE_COUNT
                        }
                    })
                    this.is_on_load_prev = false
                    if(!res.data.success){
                        this.$message({
                            message: res.data.message,
                            type: 'error',
                            offset: -1
                        })
                        return
                    }
                    if(res.data.data.length > 0){
                        this.is_no_more_prev = false
                    }else{
                        this.is_no_more_prev = true
                    }
                    this.prependMessage(res.data.data)
                }catch(err){
                    console.log('loadPrevMessage err', err.message)
                }
            }
        },
        openBoxFullVideo(url){
            this.current_live_url = url
            this.is_open_box_full_video = true
        },
        closeBoxFullVideo(){
            this.is_box_mini_video = false
            this.is_open_box_full_video = false
        },
        async handleReact(data) {

            if(!this.is_member){
                return
            }

            const res = await axios({
                method: 'POST',
                url: `${this.envDomains.VUE_APP_API}/api/react/create`,
                data: {
                    token: this.token,
                    hash: data.message.hash,
                    type: data.type
                }
            })
            if(!res.data.success){
                this.$message({
                    message: res.data.message,
                    type: 'error',
                    offset: -1
                })
                return
            }
        },
        async getReact(message){

            const data = {
                data: [],
                total: 0,
            }

            if(!message.hash || message.type === 'incoming_chat') {
                return data
            }
            const res = await axios({
                method: 'post',
                url: `${this.envDomains.VUE_APP_API}/api/react/get-by-message-id`,
                data: {
                    message_id:message.id,
                    hash: message.hash,
                }
            })
            if(!res.data.success) {
                console.log('getReact err', res.data.message)
                return data
            }
            data['data'] = res.data.data
            data['total'] = res.data.total
            return data
        },
    },
    mounted(){

        this.init()

        // this.balloonGameData = DEMO_BALLOON
        // const pes_id_list = this.balloonGameData.data.list.map((o) => {
        //     return o.user_id
        // })
        // this.loadUserInfoArrByPesUserId(pes_id_list)
        
        let last_known_scroll_position = 0
        let ticking = false

        const _this = this

        this.message_scroll = document.getElementById("msg_history")

        if(!this.message_scroll){
            return
        }

        this.message_scroll.addEventListener('scroll', function() {
            last_known_scroll_position = _this.message_scroll.scrollTop
            if(!ticking){
                window.requestAnimationFrame(function() {
                    _this.onScrollHandle(last_known_scroll_position)
                    ticking = false
                });
                ticking = true
            }
        })
    },
    beforeDestroy() {
        this.destroySocket()
    },
}
</script>