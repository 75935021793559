<template>
    <div class="AdminToolTopUp">

    </div>
</template>
<script>

import axios from 'axios'

export default {
    name: 'AdminToolTopUp',
    props: ['channel', 'bus'],
    data(){
        return {
            data: null
        }
    },
    methods: {
        async loadTopUp(){

            const loading = this.$loading()

            const res = await axios({
                method: 'POST',
                url: `${this.envDomains.VUE_APP_LIFF_API}/api/transaction/get-latest-done-by-channel`,
                data: {
                    channel: this.channel,
                    limit: 3
                }
            })
            if(!res.data.success){
                this.$alert(res.data.message, 'แจ้งเตือน', {
                    type: 'error',
                    confirmButtonText: 'ตกลง',
                    customClass: 'custom-message-box'
                })
                loading.close()
                return
            }
            this.data = res.data.data
            this.$emit('change', {
                data: this.data,
                type: 'top_up'
            })
            loading.close()
        }
    },
    mounted(){
        this.bus.$on('loadTopUp', this.loadTopUp)
        if(!this.data){
            this.loadTopUp()
        }
    },
    beforeDestroy(){
        this.bus.$off('loadTopUp', this.loadTopUp)
    }
}
</script>