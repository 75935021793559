<template>
    <div class="MessageOwnerText msg-owner">
        <div class="incoming_msg-info">
            <div class="incoming-flex">
                <div class="incoming_msg_img msg-owner">
                    <!--                 <img :src="user_picture_url" :alt="user_display_name" />-->
                    <!-- <img src="/images/avatar.png" style="object-fit: cover;"> -->
                    <el-image :src="user_picture_url">
                        <img slot="error" src="/images/avatar.png" />
                    </el-image>
                </div>
                <div class="incoming-content">
                    <div class="incoming-content-title">
                        <UserLevel :level="user_level"></UserLevel>
                    </div>
                    <div class="box-msg bg-msg-owner box-msg-chat">
                        {{message.data.message}}
                    </div>
                    <div class="received_msg hide">

                    </div>
                    <div class="received_withd_msg hide">

                        <span class="time_date"> {{message.timestamp | chatMessageDateTimeFormat}}</span>
                    </div>
                </div>

            </div>

        </div>

    </div>
</template>
<script>

import store from '@/store'
import UserLevel from '@/components/user/Level'

export default {
    name: 'MessageOwnerText',
    props: ['message'],
    components: {
        UserLevel,
    },
    computed: {
        user_info_arr(){
            return store.state.user_info_arr
        },
        user_info(){
            return this.user_info_arr.find((user_info) => {
                return parseInt(user_info.id) === parseInt(this.message.user_id)
            })
        },
        user_display_name(){
            return (this.user_info) ? this.user_info.display_name : ''
        },
        user_level(){
            return (this.user_info) ? this.user_info.level : 0
        },
        user_picture_url(){
            return (this.user_info) ? this.user_info.picture_url : ''
        },
    },
}
</script>