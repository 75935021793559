<template>
    <div class="GameScoreboard">
        <div v-if="queryTableId" class="scoreboard-row">
            <div v-if="tableByQueryTableId" class="scoreboard-item-wrapper">
                <div v-if="isVideoPlayerReady" class="video-player-ready"></div>
                <VideoPlayer 
                v-if="queryShowVideo === 'yes'"
                :tableVideoStreamPath="tableVideoStreamPath"
                :srcHLS="tableVideoSrcHLS"
                :srcFLV="tableVideoSrcFLV"
                :width="videoPlayerWidth"
                :height="videoPlayerHeight"
                :dataToken="videoToken"
                playerSelect="hls"
                @ready="videoPlayerReadyHandle"
                ></VideoPlayer>
                <GameScoreboardComponent
                :tableType="tableByQueryTableId.table_type"
                :table="tableByQueryTableId"
                :statArr="getStatArrByTable(tableByQueryTableId.table_id, tableByQueryTableId.table_round_main)"></GameScoreboardComponent>
            </div>
        </div>
        <div v-else class="scoreboard-row">
            <div class="scoreboard-col-left">
                <div v-for="(tableType, key) of tableTypeLeftArr" :key="key">
                    <div v-for="(table, key) of getTableArrByType(tableType)" :key="key" 
                    class="scoreboard-item-wrapper"
                    :class="{'shrink': isSicboOrBean(tableType)}">
                        <GameScoreboardComponent
                        :tableType="tableType"
                        :table="table"
                        :statArr="getStatArrByTable(table.table_id, table.table_round_main)"></GameScoreboardComponent>
                    </div>
                </div>
            </div>
            <div class="scoreboard-col-right">
                <div v-for="(tableType, key) of tableTypeRightArr" :key="key">
                    <div v-for="(table, key) of getTableArrByType(tableType)" :key="key" 
                    class="scoreboard-item-wrapper"
                    :class="{'shrink': isSicboOrBean(tableType)}">
                        <GameScoreboardComponent
                        :tableType="tableType"
                        :table="table"
                        :statArr="getStatArrByTable(table.table_id, table.table_round_main)"></GameScoreboardComponent>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>
<script>

import GameTypeMixin from '@/mixins/GameType'
import {TABLE_TYPE_KEY} from '@/components/game/Constant'
import GameScoreboardComponent from '@/components/game/Scoreboard'
import VideoPlayer from '@/components/game/VideoPlayer'
import store from '@/store'

export default {
    name: 'GameScoreboard',
    mixins: [
        GameTypeMixin,
    ],
    components: {
        GameScoreboardComponent,
        VideoPlayer,
    },
    data() {
        return {
            TABLE_TYPE_KEY: null,
            tableTypeLeftArr: [],
            tableTypeRightArr: [],
            videoPlayerWidth: 420,
            videoPlayerHeight: 250,
            isVideoPlayerReady: false,
        }
    },
    computed: {
        is_member() {
            return store.state.is_member
        },
        pes_profile() {
            return store.state.pes_profile
        },
        queryTableId() {
            return this.$route.query['table-id']
        },
        queryShowVideo() {
            return this.$route.query['show-video']
        },
        tableByQueryTableId() {
            return this.baccaratTableList.find((table) => {
                return parseInt(table.table_id) === parseInt(this.queryTableId)
            })
        },
        tableChannelInfo() {
            return (this.tableByQueryTableId) ? this.tableByQueryTableId.channel_info : null
        },
        tableVideoStreamPath() {
            return (this.tableChannelInfo) ? this.tableChannelInfo.streamPath : ''
        },
        videoToken() {
            return (this.is_member) ? this.pes_profile.token : `guest:${this.$moment().valueOf()}`
        },
        tableVideoSrcHLS() {
            return (this.tableChannelInfo) ? `${this.tableChannelInfo.url.hls}?token=${this.videoToken}` : ''
        },
        tableVideoSrcFLV() {
            return (this.tableChannelInfo) ? `${this.tableChannelInfo.url['http-flv']}?token=${this.videoToken}` : ''
        },
    },
    methods: {
        getTableArrByType(table_type) {
            return this.baccaratTableList.filter((table) => {
                return parseInt(table.table_type) === parseInt(table_type)
            })
        },
        getStatArrByTable(table_id, table_round_main) {
            return this.baccaratGameList.filter((game) => {
                return parseInt(game.table_id) === parseInt(table_id) &&
                    parseInt(game.round_main) === parseInt(table_round_main)
            })
        },
        isSicboOrBean(table_type) {
            return parseInt(table_type) === TABLE_TYPE_KEY.sicbo || parseInt(table_type) === TABLE_TYPE_KEY.bean
        },
        videoPlayerReadyHandle() {
            this.isVideoPlayerReady = true
        },
    },
    created() {
        this.TABLE_TYPE_KEY = TABLE_TYPE_KEY
    },
    mounted() {
        // if(this.isOnDev) {
        //     this.tableTypeLeftArr = [TABLE_TYPE_KEY.baccarat, TABLE_TYPE_KEY.dragontiger, TABLE_TYPE_KEY.baccarat2, TABLE_TYPE_KEY.baccarat3]
        //     this.tableTypeRightArr = [TABLE_TYPE_KEY.sicbo, TABLE_TYPE_KEY.bean]
        // } else {
        //     this.tableTypeLeftArr = [TABLE_TYPE_KEY.baccarat, TABLE_TYPE_KEY.dragontiger]
        //     this.tableTypeRightArr = [TABLE_TYPE_KEY.sicbo, TABLE_TYPE_KEY.bean]
        // }
        this.tableTypeLeftArr = [TABLE_TYPE_KEY.baccarat, TABLE_TYPE_KEY.dragontiger, TABLE_TYPE_KEY.baccarat2, TABLE_TYPE_KEY.baccarat3]
        this.tableTypeRightArr = [TABLE_TYPE_KEY.sicbo, TABLE_TYPE_KEY.bean]
    },
}
</script>
<style scoped>
.GameScoreboard {
    background-color: black;
    min-height: 100vh;
    padding: 20px;
}
.scoreboard-row {
    display: flex;
}
.scoreboard-col-left, .scoreboard-col-right {
    display: flex;
    margin-left: 30px;
    flex-direction: column;
}
.scoreboard-item-wrapper {
    margin-bottom: 30px;
    background-color: white;
    width: 420px;
}
/deep/ .VideoPlayer {
    height: unset!important;
    background-color: black;
}
/deep/ .VideoPlayerFLV .toggle-mute-button {
    display: none;
}
</style>