import { render, staticRenderFns } from "./FloatOpenCard.vue?vue&type=template&id=6f8dd10d&"
import script from "./FloatOpenCard.vue?vue&type=script&lang=js&"
export * from "./FloatOpenCard.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports