import store from '@/store';
import {PriceRatio} from '../Constant'

export default {
    name: 'BetMixin',
    computed: {
        category_list: function(){
            return store.state.lotto.category_list;
        },
        price_dynamic: function(){
            return store.state.lotto.price_dynamic;
        },
        price_dynamic_3: function(){
            return store.state.lotto.price_dynamic_3;
        },
        price_dynamic_run: function(){
            return store.state.lotto.price_dynamic_run;
        },
        price_dynamic_3_run: function(){
            return store.state.lotto.price_dynamic_3_run;
        },
        round_obj(){
            return store.state.lotto.round_obj;
        },
        round_lotto_type: function(){
            return (this.round_obj) ? this.round_obj.lotto_type : '';
        },
        bet_list: function(){
            return store.state.lotto.bet_list;
        },
        price_table: {
            get(){
                return store.state.lotto.price_table
            },
            set(val){
                store.commit('lotto/updatePriceTable', val)
            }
        },
        reward_limit: {
            get(){
                return store.state.lotto.reward_limit
            },
            set(val){
                store.commit('lotto/updateRewardLimit', val)
            }
        },
    },
    methods: {
        bet_get_price: function (bet_type, number = null) {

            // - over(เต็ง หรือ บน) ใช้ multi_now
            // - switch(โต๊ด) ใช้ multi_now*0.16
            // - front(หน้า) ใช้ multi_now*0.6
            // - end(ท้าย) ใช้ multi_now*0.6

            try {

                const reward_limit = this.reward_limit[this.round_lotto_type]

                const samTuaTodCal = () => {
                    return parseInt(parseFloat(this.price_dynamic_3[number].reward)*PriceRatio.sam_tua_tod)
                }

                const samTuaNhaLangCal = () => {
                    return parseInt(parseFloat(this.price_dynamic_3[number].reward)*PriceRatio.sam_tua_nha_lang)
                }

                if (bet_type === 'song_tua_bon') {
                    return (this.price_dynamic) ? this.priceLimitFilter(reward_limit, this.price_dynamic[number].reward, 'song_tua_bon') : 0;
                }else if(bet_type === "song_tua_lang"){
                    return (this.price_dynamic) ? this.priceLimitFilter(reward_limit, this.price_dynamic[number].reward, 'song_tua_lang') : 0;
                }else if(bet_type === 'sam_tua_bon'){
                    return (this.price_dynamic_3) ? this.priceLimitFilter(reward_limit, this.price_dynamic_3[number].reward, 'sam_tua_bon') : 0;
                }else if(bet_type === 'sam_tua_tod'){
                    return (this.price_dynamic_3) ? this.priceLimitFilter(reward_limit, samTuaTodCal(), 'sam_tua_tod') : 0;
                }else if(bet_type === 'sam_tua_lang'){
                    return (this.price_dynamic_3) ? this.priceLimitFilter(reward_limit, samTuaNhaLangCal(), 'sam_tua_lang') : 0;
                }else if(bet_type === 'sam_tua_nha'){
                    return (this.price_dynamic_3) ? this.priceLimitFilter(reward_limit, samTuaNhaLangCal(), 'sam_tua_nha') : 0;
                }else if(bet_type === 'wing_bon'){
                    return (this.price_dynamic_3_run) ? this.priceLimitFilter(reward_limit, parseFloat(this.price_dynamic_3_run[number].reward), 'wing_bon') : 0;
                }else if(bet_type === 'wing_lang'){
                    return (this.price_dynamic_run) ? this.priceLimitFilter(reward_limit, parseFloat(this.price_dynamic_run[number].reward), 'wing_lang') : 0;
                }else {
                    return this.category_list[bet_type].reward;
                }
            } catch (err) {
                console.log('bet_type', bet_type)
                console.log('number', number)
                console.log('get_price err', err.message);
                this.$bvToast.toast('ไม่สามารถโหลดข้อมูลรางวัลได้', {title: 'แจ้งเตือน'});
                return 0;
            }
        },
        priceLimitFilter(limit, price, bet_type){
            if(limit){
                if(parseFloat(price) < parseFloat(limit[bet_type]['min'])){
                    return parseFloat(limit[bet_type]['min']) - 1
                }else if(parseFloat(price) > parseFloat(limit[bet_type]['max'])){
                    return parseFloat(limit[bet_type]['max']) + 1
                }else{
                    return price
                }
            }else{
                return price
            }
        },
        bet_cancel_all: function () {
            store.dispatch('lotto/clearBetList');
        },
        update_bet_list: function () {
            this.bet_list.map((o, i) => {
                o.reward = this.bet_get_price(o.bet_type, o.number);
                o.i = i;
                store.commit('lotto/update_bet_list', o);
                return o;
            });
        },
        checkIsNumberDisable(number_setting_arr, category_arr, number){

            try{

                if(number_setting_arr.length <= 0){
                    return false
                }
                for(let category of category_arr){
                    if(!number_setting_arr[category]) {
                        continue
                    }
                    const number_setting = number_setting_arr[category][parseInt(number)]
                    if(!number_setting){
                        continue
                    }
                    const isNumberReachPayinLimit = () => {
                        if(!number_setting.total_payin_limit) {
                            return false
                        }
                        return parseFloat(number_setting.total_payin) >= parseFloat(number_setting.total_payin_limit)
                    }
                    if(!number_setting.is_enable || isNumberReachPayinLimit()){
                        return true
                    }
                }
                return false
            }catch(err){ 
                console.log('checkIsNumberDisable', err.message)
                return false
            }   
        },
        checkIsRewardExceed(price, multi_min, multi_max){
            if(!price || !multi_min || !multi_max){
                return false
            }else if(price){
                const price_float = parseFloat(price)
                const min = parseFloat(multi_min)
                const max = parseFloat(multi_max)
                return price_float < 0 || price_float < min || price_float > max
            }else{
                return true
            }
        },
        getSongTuaBonPrice(number){
            return this.price_dynamic[number].reward
        }
    }
}