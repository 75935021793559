<script>

import axiosWrapper from '@/axiosWrapper'
import store from '@/store'

export default {
    name: 'AccountMixin',
    data() {
        return {
            isShowEditDisplayName: false,
            display_name: '',
        }
    },
    computed: {
        pes_profile() {
            return store.state.pes_profile
        },
        user_profile() {
            return store.state.user_profile
        },
        token() {
            return this.pes_profile.token
        },
    },
    methods: {
        toEditDisplayName() {
            this.display_name = this.user_profile.display_name
            this.isShowEditDisplayName = true
        },
        async confirmEditDisplayName() {
            
            try{

                if(!this.display_name) {
                    return
                }

                const res = await axiosWrapper({
                    method: 'POST',
                    url: `${this.envDomains.VUE_APP_LIFF_API}/api/user/change-display-name`,
                    data: {
                        token: this.token,
                        display_name: this.display_name,
                    }
                })
                if(res.status === 200) {
                    this.isShowEditDisplayName = false
                    this.requestUserReload()
                } else {
                    console.log('confirmEditDisplayName fail', res.data)
                    this.$bvToast.toast(res.data.message, {
                        title: 'แจ้งเตือน',
                        toaster: 'b-toaster-top-full'
                    })
                }
            }catch(err){
                console.log('confirmEditDisplayName err', err.message)
                this.$bvToast.toast('เกิดปัญหาบางอย่าง กรุณาลองใหม่อีกครั้ง', {
                    title: 'แจ้งเตือน',
                    toaster: 'b-toaster-top-full'
                })
            }
        },
        cancelEditDisplayName() {
            this.isShowEditDisplayName = false
        },
        requestUserReload() {
            store.commit('updateNeedUserReload', true)
        },
        async confirmChangeImageProfile(picture_url) {

            try{

                if(!picture_url) {
                    return
                }

                const res = await axiosWrapper({
                    method: 'POST',
                    url: `${this.envDomains.VUE_APP_LIFF_API}/api/user/change-image-profile`,
                    data: {
                        token: this.token,
                        picture_url: picture_url,
                    }
                })
                if(res.status === 200) {
                    this.requestUserReload()
                } else {
                    console.log('confirmChangeImageProfile fail', res.data)
                    this.$bvToast.toast(res.data.message, {
                        title: 'แจ้งเตือน',
                        toaster: 'b-toaster-top-full'
                    })
                }
            }catch(err){
                console.log('confirmChangeImageProfile err', err.message)
                this.$bvToast.toast('เกิดปัญหาบางอย่าง กรุณาลองใหม่อีกครั้ง', {
                    title: 'แจ้งเตือน',
                    toaster: 'b-toaster-top-full'
                })
            }
        },
    }
}
</script>