<template>
    <div class="AuthView">

        <div class="AuthView-content">
            <el-button
                    class="close-button"
                    @click="$router.push({name: 'Home'})"
                    icon="el-icon-close"
                    circle
            ></el-button>
        </div>

        <Auth @success="$router.back()"></Auth>
    </div>
</template>
<script>

import Auth from '@/components/Auth'

export default {
    name: 'AuthView',
    components: {
        Auth,
    }
}
</script>
<style scoped>
.close-button {
    position: absolute;
    top: 10px;
    right: 10px;
    z-index: 5;
}

</style>