<template>

    <div class="bg-overlay bg-mini-wheel bg-zoom">
        <div class="wrapper-box-games">
            <div style="position: relative;">
                <div class="btn-exit" @click="exit"></div>
                <div class="wheel-page-games-list w-100 slide-top">
                    <div class="wheel-wrap">
                        <div class="box-popup-price tracking-in-expand" v-if="is_show_result">45,000,000</div>
                        <!--<div class="wheel-wing"></div>-->
                        <div class="wheel-img">
                            <div class="wheel-img__wrap">
                                <div class="wheel-canvas-layout">
                                    <div class="wheel-canvas-cells" :class="{'spinning': is_on_spin}"
                                         style="transform: rotate(0deg);">
                                        <div class="cell cell-0 restricted-1">
                                            <div class="divider-0"></div>
                                            <p>30M</p>
                                        </div>
                                        <div class="cell cell-1 restricted-2">
                                            <div class="divider-1"></div>
                                            <p>60M</p>
                                        </div>
                                        <div class="cell cell-2 restricted-3">
                                            <div class="divider-2"></div>
                                            <p>90M</p>
                                        </div>
                                        <div class="cell cell-3 restricted-4">
                                            <div class="divider-3"></div>
                                            <p>120M</p>
                                        </div>
                                        <div class="cell cell-4 restricted-5">
                                            <div class="divider-4"></div>
                                            <p>150M</p>
                                        </div>
                                        <div class="cell cell-5 restricted-6">
                                            <div class="divider-5"></div>
                                            <p>200M</p>
                                        </div>
                                        <div class="cell cell-6 restricted-7">
                                            <div class="divider-6"></div>
                                            <p>350M</p>
                                        </div>
                                        <div class="cell cell-7 restricted-8">
                                            <div class="divider-7"></div>
                                            <p>450M</p>
                                        </div>
                                        <div class="cell cell-8 restricted-9">
                                            <div class="divider-8"></div>
                                            <p>550M</p>
                                        </div>
                                        <div class="cell cell-9 restricted-10">
                                            <div class="divider-9"></div>
                                            <p>650M</p>
                                        </div>
                                        <div class="cell cell-10 restricted-11">
                                            <div class="divider-10"></div>
                                            <p>750M</p>
                                        </div>
                                        <div class="cell cell-11 restricted-12">
                                            <div class="divider-11"></div>
                                            <p>800M</p>
                                        </div>
                                        <div class="cell cell-12 restricted-13">
                                            <div class="divider-12"></div>
                                            <p>850M</p>
                                        </div>
                                        <div class="cell cell-13 restricted-14">
                                            <div class="divider-13"></div>
                                            <p>900M</p>
                                        </div>
                                    </div>
                                    <div class="wheel-canvas-center">
                                        <div class="wheel-canvas-center-waiting">
                                            <!--<div class="wheel-canvas-title"><span></span></div>-->
                                            <div class="wheel-canvas-subtitle"></div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div @click="spin" class="SlotBtnSpin scale-in-center" v-show="!is_on_spin">
                        <div class="btn-spin"></div>
                    </div>

                </div>
            </div>
        </div>
    </div>

</template>
<script>
    export default {
        name: 'MiniWheel',
        data() {
            return {
                is_on_spin: false,
                is_show_result: false
            }
        },
        watch: {
            is_on_spin(new_val) {
                if (new_val) {
                    const _this = this
                    setTimeout(() => {
                        _this.is_on_spin = false
                        _this.is_show_result = true
                    }, 1000 * 2)
                }
            }
        },
        methods: {
            spin() {
                if (this.is_on_spin) {
                    return
                }
                this.is_show_result = false
                this.is_on_spin = true
            },
            exit() {
                this.$emit('exit')
            }
        }
    }
</script>
<style src="@/assets/css/slot.css" scoped></style>
<style scoped>

    .SlotBtnSpin{
        position: absolute;
        transform: translate(-50%, -50%);
        left: 28%;
        margin-top: 0px !important;
        height: 52px;
        width: 125px;
        background-image: url(../assets/images/spin/btn-spin.png);
        background-size: cover;
        background-repeat: no-repeat;
    }

    .btn-exit {
        position: absolute;
        width: 32px;
        height: 32px;
        display: block;
        right: -30px;
        top: -115px;
        box-shadow: none !important;
        border-radius: 50%;
        background-image: url(../assets/images/spin/btn-close.png);
        background-size: cover;
    }

    .box-popup-price {
        width: 100%;
        position: absolute;
        z-index: 999;
        -webkit-text-stroke: 2px #333;
        font-size: 2.8em;
        margin-top: 126px;
        text-align: center;
        font-weight: bold;
        color: #be1a1e;
        text-shadow: 0 0 8px  #fff,
        0 0 10px #fff,
        0 0 20px  #fff,
        0 0 40px  #fff,
        0 0 80px  #fff,
        0 0 90px  #fff,
        0 0 100px  #fff,
        0 0 150px  #fff;
    }

    .bg-zoom{
        background-size: cover;
        height: 100vh;
        width: 100%;
        -webkit-animation: shrink 2s ease-out both;
        animation: shrink 2s ease-out both;
    }
    @keyframes shrink {
        0% {
            transform: scale(1);
        }
        100% {
            transform: scale(1.14);
        }
    }

    .slide-top {
        -webkit-animation: slide-top 1.5s cubic-bezier(0.250, 0.460, 0.450, 0.940) both;
        animation: slide-top 1.5s cubic-bezier(0.250, 0.460, 0.450, 0.940) both;
    }

    @keyframes slide-top {
        0% {
            -webkit-transform: translateY(0);
            transform: translateY(0);
        }
        100% {
            -webkit-transform: translateY(-130px);
            transform: translateY(-130px);
        }
    }

    .scale-in-center {
        -webkit-animation: scale-in-center 1.5s cubic-bezier(0.250, 0.460, 0.450, 0.940) both;
        animation: scale-in-center 1.5s cubic-bezier(0.250, 0.460, 0.450, 0.940) both;
    }

    @-webkit-keyframes scale-in-center {
        0% {
            -webkit-transform: scale(0);
            transform: scale(0);
            opacity: 1;
        }
        100% {
            -webkit-transform: scale(1);
            transform: scale(1);
            opacity: 1;
        }
    }
    @keyframes scale-in-center {
        0% {
            -webkit-transform: scale(0);
            transform: scale(0);
            opacity: 1;
        }
        100% {
            -webkit-transform: scale(1);
            transform: scale(1);
            opacity: 1;
        }
    }

    .tracking-in-expand {
        -webkit-animation: tracking-in-expand 0.7s cubic-bezier(0.215, 0.610, 0.355, 1.000) both;
        animation: tracking-in-expand 0.7s cubic-bezier(0.215, 0.610, 0.355, 1.000) both;
    }
    @-webkit-keyframes tracking-in-expand {
        0% {
            letter-spacing: -0.5em;
            opacity: 0;
        }
        40% {
            opacity: 0.6;
        }
        100% {
            opacity: 1;
        }
    }
    @keyframes tracking-in-expand {
        0% {
            letter-spacing: -0.5em;
            opacity: 0;
        }
        40% {
            opacity: 0.6;
        }
        100% {
            opacity: 1;
        }
    }




</style>