<template>
    <div class="Auth" :class="{'line-only': isInLineApp}">

        <div class="Auth-wrapper">
            <div class="boxProfileLine">
                <img v-if="this.appImageProfile" :src="this.appImageProfile"/>
                <img v-else src="@/assets/images/profile2.jpg">
                <h2> {{this.appTitle?this.appTitle:'123LineAdd'}}</h2>
            </div>
            <div v-if="isInLineApp" class="sign-in-wrapper line-only-wrapper">
                <div class="auth-row">
                    <h1>เข้าสู่ระบบ</h1>
                </div>
                <div class="auth-row">
                    <LineLoginButton mode="login" style="width: 100%;"></LineLoginButton>
                </div>
            </div>
            <div v-else class="Auth-wrapper-content">
                <div v-show="page === 'home'" class="home-wrapper">
                    <div class="auth-row-content">
                        <div class="auth-row">
                            <button @click="page = 'sign-in'" class="btn btn-primary">ล็อกอิน</button>
                        </div>
                        <div class="auth-row">
                            หรือ
                        </div>
                        <div class="auth-row">
                            <button @click="goRegisterPage" class="btn btn-outline-primary">สมัครสมาชิก</button>
                        </div>
                    </div>
                </div>

                <div v-show="page === 'sign-in'" class="sign-in-wrapper">
                    <!-- <button @click="page = 'home'" type="button" class="btn btn-secondary auth-back-button">กลับ</button> -->

                    <div class="auth-row">
                        <h1>เข้าสู่ระบบ</h1>
                    </div>

                    <!--                <div class="auth-row">-->
                    <!--                    เลือกวิธีเข้าสู่ระบบ-->
                    <!--                </div>-->
                    <div class="auth-row">
                        <div class="auth-row-tab">
                            <div class="tab-pane active">
                                <form>
<!--                                    <div class="auth-row" style="justify-content: start;">-->
<!--                                        ชื่อผู้ใช้ / หมายเลขโทรศัพท์-->
<!--                                    </div>-->
                                    <div class="auth-row">
                                        <input v-model="username" type="text" class="form-control" placeholder="ชื่อผู้ใช้ / หมายเลขโทรศัพท์">
                                    </div>
<!--                                    <div class="auth-row" style="justify-content: start;">-->
<!--                                        รหัสผ่าน-->
<!--                                    </div>-->
                                    <div class="auth-row">
                                        <input v-model="password" type="password" class="form-control" placeholder="รหัสผ่าน">
                                    </div>
                                    <div class="auth-row" style="margin: 22px 0">
                                        <vue-recaptcha
                                                ref="recaptcha"
                                                @verify="loginWithPasswordRecaptchaVerified"
                                                @expired="loginWithPasswordRecaptchaExpired"
                                                :sitekey="googleCaptchaSiteKey">
                                        </vue-recaptcha>
                                    </div>
                                    <div class="auth-row">
                                        <button @click="authWithPassword" type="button" class="btn btn-primary">เข้าสู่ระบบ</button>
                                    </div>
                                    <h5 class="middleLineOr">หรือ</h5>
                                    <div class="auth-row">
                                        <!-- <div style="text-align: center; font-weight: bold;">สมัครผ่านไลน์</div> -->
                                        <LineLoginButton mode="login" style="width: 100%;" label="ล็อกอินผ่านไลน์"></LineLoginButton>

                                    </div>
                                </form>
                            </div>
                        </div>
                    </div>

                    <div class="auth-row auth-row-reg" style="">
                        <!-- <div style="display: inline-block; width: 100%; text-align: center;margin-top: 10px;"> -->
                        <!-- <p>ยังไม่ได้เป็นสมาชิก</p> -->
                        <!-- <button @click="goRegisterPage" class="btn btn-outline-primary">สมัครสมาชิก</button> -->
                        <!-- <div style="height: 10px;"></div> -->
                        <!-- <LineLoginButton mode="login"></LineLoginButton> -->
                        <!-- </div> -->
                        <div class="flex-space-between">
                            <span @click="goRegisterPage" class="small-link-text">สมัครสมาชิกคลิกที่นี่</span>
                            <span @click="goResetPasswordPage" class="small-link-text">ลืมรหัสผ่าน</span>
                        </div>
                    </div>

                </div>
                <div v-show="page === 'register'" class="register-wrapper">
                    <div>
                        <button @click="page = 'sign-in'" class="btn btn-secondary auth-back-button">กลับ</button>
                        <div class="auth-row">
                            <h1>สมัครสมาชิก</h1>
                        </div>

                        <div class="auth-row" style="justify-content: start; color: red; font-size: 11px;">
                            <span class="alert"> *** กรุณากรอกหมายเลขโทรศัพท์ให้ถูกต้อง จะมีการยืนยัน OTP เมื่อมีการแจ้งถอน ถ้ายืนยันไม่ผ่าน จะไม่สามารถถอนเงินได้ หมายเลขโทรศัพท์ไม่สามารถแก้ไขได้ ***</span>
                        </div>
<!--                        <div class="auth-row" style="justify-content: start;">-->
<!--                            หมายเลขโทรศัพท์-->
<!--                        </div>-->
                        <div class="auth-row">
                            <input v-model="registerWithMobilePhoneAndPasswordForm.mobilePhoneNumber" type="text" class="form-control" placeholder="หมายเลขโทรศัพท์ 08xxxxxxxx">
                        </div>
<!--                        <div class="auth-row" style="justify-content: start;">-->
<!--                            รหัสผ่าน-->
<!--                        </div>-->
                        <div class="auth-row">
                            <input v-model="registerWithMobilePhoneAndPasswordForm.password" type="password" class="form-control" placeholder="รหัสผ่าน">
                        </div>
<!--                        <div class="auth-row" style="justify-content: start;">-->
<!--                            ยืนยันรหัสผ่าน-->
<!--                        </div>-->
                        <div class="auth-row">
                            <input v-model="registerWithMobilePhoneAndPasswordForm.confirmPassword" type="password" class="form-control" placeholder="ยืนยันรหัสผ่าน">
                        </div>
                        <div class="auth-row">
                            <button @click="registerWithMobilePhoneAndPassword" type="button" class="btn btn-primary">ยืนยัน</button>
                        </div>
                        <div class="auth-row" style="flex-direction: column;">
                            <h5 class="middleLineOr">หรือ</h5>
                            <!-- <div style="text-align: center; font-weight: bold;">สมัครผ่านไลน์</div> -->
                            <LineLoginButton mode="login" style="width: 100%;" label="สมัครสมาชิกผ่านไลน์"></LineLoginButton>
                        </div>
                        <div class="auth-row" style="display:block; font-size: 11px; color: gray;">
                            This site is protected by reCAPTCHA and the Google
                            <a href="https://policies.google.com/privacy">Privacy Policy</a> and
                            <a href="https://policies.google.com/terms">Terms of Service</a> apply.
                        </div>
                        <div class="auth-row">
                            <span @click="page = 'sign-in'" class="small-link-text">กลับไปหน้า Login</span>
                        </div>
                    </div>
                </div>
                <div v-show="page === 'reset-password'" class="register-wrapper">
                    <div class="auth-row">
                        <h1>ลืมรหัสผ่าน</h1>
                    </div>
                    <div class="auth-row">
                        <button type="button" class="btn btn-primary">ตั้งรหัสผ่านใหม่</button>
                    </div>
                    <div class="auth-row" style="justify-content: start;">
                        ใส่รหัสผ่าน
                    </div>
                    <div class="auth-row">
                        <input v-model="resetPasswordForm.password" type="password" class="form-control" placeholder="ใส่รหัสผ่าน">
                    </div>
                    <div class="auth-row" style="justify-content: start;">
                        ยืนยันรหัสผ่าน
                    </div>
                    <div class="auth-row">
                        <input v-model="resetPasswordForm.confirmPassword" type="password" class="form-control" placeholder="ยืนยันรหัสผ่าน">
                    </div>
                    <div class="auth-row">
                        <button @click="resetPassword" type="button" class="btn btn-primary">ตั้งรหัสผ่าน</button>
                    </div>
                    <div class="auth-row">
                        <span @click="page = 'sign-in'" class="small-link-text">กลับไปหน้า Login</span>
                    </div>
                </div>
                <div v-show="page === 'reset-password-verify'" class="register-wrapper">
                    <div class="auth-row" style="flex-direction: column; justify-content: center; text-align: center">
                        <h1>ลืมรหัสผ่าน</h1>
                        <hr>
                        <span>ใส่หมายเลขโทรศัพท์</span>
                    </div>
<!--                    <div class="auth-row">-->
<!--                        <button type="button" class="btn btn-primary">ใส่หมายเลขโทรศัพท์</button>-->
<!--                    </div>-->
                    <OTP @submit="toResetPassword" submitText="Reset รหัสผ่าน" :showLabel="false"></OTP>
                    <div class="auth-row">
                        <span @click="page = 'sign-in'" class="small-link-text">กลับไปหน้า Login</span>
                    </div>
                </div>
                <div v-show="page === 'reset-password-done'" class="register-wrapper">
                    <div class="auth-row">
                        <h1>ลืมรหัสผ่าน</h1>
                    </div>
                    <div class="auth-row">
                        <button type="button" class="btn btn-primary">รหัสผ่านใหม่</button>
                    </div>
                    <div class="auth-row" style="justify-content: start;">
                        รหัสผ่านใหม่ของคุณคือ
                    </div>
                    <div class="auth-row">
                        <input v-model="resetPasswordForm.password" class="form-control" disabled>
                    </div>
                    <div class="auth-row">
                        <button @click="resetPasswordDoneSubmit" type="button" class="btn btn-primary">ไปหน้า Login</button>
                    </div>
                </div>
            </div>
            <div class="bg-home-wrapper2"></div>
        </div>

        <b-modal ref="notify-mobile-phone-strict-modal" hide-header centered
                 ok-title="ตกลง" modal-class="editModal" ok-only button-size="lg">
            <div class="style-modal">
                <h3>แจ้งเตือน</h3>
                <span class="message-notify-danger"> *** กรุณากรอกหมายเลขโทรศัพท์ให้ถูกต้อง จะมีการยืนยัน OTP เมื่อมีการแจ้งถอน ถ้ายืนยันไม่ผ่าน จะไม่สามารถถอนเงินได้ หมายเลขโทรศัพท์ไม่สามารถแก้ไขได้ ***</span>
            </div>

        </b-modal>
    </div>
</template>
<script>

    import store from '@/store'
    import axiosWrapper from '@/axiosWrapper'
    import OTP from '@/components/OTP'
    import UserMixin from '@/mixins/UserMixin'
    import {IMAGE_MAP} from '@/components/user/Constant'
    import randomBetween from '@/lib/randomBetween'
    import LineLoginButton from '@/components/user/LineLoginButton'
    import VueRecaptcha from 'vue-recaptcha';

    export default {
        name: 'Auth',
        components: {
            OTP,
            LineLoginButton,
            VueRecaptcha,
        },
        mixins: [
            UserMixin,
        ],
        data() {
            return {
                page: 'sign-in', //home, sign-in, register, reset-password, reset-password-verify, reset-password-done
                mobile_phone_number: '',
                otp: '',
                username: '',
                password: '',
                first_name: '',
                last_name: '',
                loading: null,
                isLoginWithPasswordRecaptchaVerified: false,
                loginWithPasswordRecaptchaToken: '',
                isOnRegisterWithMobilePhoneAndPassword: false,
                registerWithMobilePhoneAndPasswordForm: {
                    mobilePhoneNumber: '',
                    password: '',
                    confirmPassword: '',
                },
                isOnResetPassword: false,
                resetPasswordForm: {
                    mobile_phone_number: '',
                    otp: '',
                    password: '',
                    confirmPassword: '',
                },
                isOnOtpVerify: false,
            }
        },
        computed: {
            channel() {
                return store.state.channel
            },
            message_api_id() {
                return store.state.message_api_id
            },
            referral_code() {
                return store.state.referral_code
            },
            reward_id() {
                return store.state.reward_id
            },
            isInLineApp() {
                return store.state.isInLineApp
            },
            appImageProfile(){
                return store.state.appInfo.app_image_profile
            },
            appTitle(){
                return store.state.appInfo.VUE_APP_TITLE
            }
        },
        methods: {
            async authWithMobilePhoneNumber(data) {

                const channel = this.channel
                const {mobile_phone_number, otp} = data

                this.loading = this.$loading()

                const res = await axiosWrapper({
                    method: 'POST',
                    url: `${this.envDomains.VUE_APP_LIFF_API}/api/user/auth-with-mobile-phone-number`,
                    data: {
                        channel,
                        mobile_phone_number,
                        otp,
                    }
                })
                if(res.status === 200) {
                    this.signIn(res.data.pes_profile.token)
                    this.$emit('success')
                    setTimeout(() => {
                        location.reload()
                    }, 100)
                } else {
                    this.$bvToast.toast(res.data.message, {
                        title: 'แจ้งเตือน',
                        toaster: 'b-toaster-top-full'
                    })
                }

                this.loading.close()
            },
            async authWithPassword() {

                if(!this.isLoginWithPasswordRecaptchaVerified) {
                    this.$bvToast.toast('กรุณายืนยันไม่ใช่หุ่นยนต์', {
                        title: 'แจ้งเตือน',
                        toaster: 'b-toaster-top-full'
                    })
                    return
                }

                const username = this.username
                const password = this.password

                this.loading = this.$loading()

                const res = await axiosWrapper({
                    method: 'POST',
                    url: `${this.envDomains.VUE_APP_LIFF_API}/api/user/auth-with-password`,
                    data: {
                        username,
                        password,
                        recaptchaToken: this.loginWithPasswordRecaptchaToken,
                        domain: window.location.hostname,
                    }
                })
                if(res.status === 200) {
                    this.signIn(res.data.data.token)
                    this.$emit('success')
                    setTimeout(() => {
                        location.reload()
                    }, 100)
                } else {
                    this.$bvToast.toast(res.data.message, {
                        title: 'แจ้งเตือน',
                        toaster: 'b-toaster-top-full'
                    })
                }

                this.loading.close()

                this.$refs.recaptcha.reset()
                this.isLoginWithPasswordRecaptchaVerified = false
                this.loginWithPasswordRecaptchaToken = ''
            },
            async register(data) {

                // await this.$recaptchaLoaded()
                // const recaptchaToken = await this.$recaptcha('login')

                const channel = this.channel
                const message_api_id = this.message_api_id
                const mobile_phone_number = data.mobile_phone_number
                const otp = data.otp
                const first_name = this.first_name
                const last_name = this.last_name
                const referral_code = this.referral_code
                const reward_id = this.reward_id
                const picture_url = IMAGE_MAP[0][randomBetween(0, IMAGE_MAP[0].length - 1)]

                if(!first_name || !last_name) {
                    this.$bvToast.toast('กรุณากรอกชื่อ นามสกุล', {
                        title: 'แจ้งเตือน',
                        toaster: 'b-toaster-top-full'
                    })
                    return
                }

                this.loading = this.$loading()

                const isRouteQueryChannelAvailable = await this.checkRouteQueryChannelAvailable()
                if(!isRouteQueryChannelAvailable) {
                    this.loading.close()
                    return
                }

                const res = await axiosWrapper({
                    method: 'POST',
                    url: `${this.envDomains.VUE_APP_LIFF_API}/api/user/register`,
                    data: {
                        channel,
                        message_api_id,
                        mobile_phone_number,
                        otp,
                        first_name,
                        last_name,
                        referral_code,
                        reward_id,
                        picture_url,
                        user_agent: navigator.userAgent,
                        web_referrer: document.referrer,
                        // recaptchaToken,
                    }
                })

                if(res.status === 200) {
                    if(res.data.token) {
                        this.signIn(res.data.token)
                        sessionStorage.setItem('new_member_register',1);
                        sessionStorage.setItem('is_packet_show',1);
                        this.$emit('success')
                        setTimeout(() => {
                            location.reload()
                        }, 100)
                    } else {
                        this.authWithMobilePhoneNumber({mobile_phone_number, otp})
                    }
                } else {
                    this.$bvToast.toast(res.data.message, {
                        title: 'แจ้งเตือน',
                        toaster: 'b-toaster-top-full'
                    })
                }

                this.loading.close()
            },
            loginWithPasswordRecaptchaVerified(response) {
                this.isLoginWithPasswordRecaptchaVerified = true
                this.loginWithPasswordRecaptchaToken = response
            },
            loginWithPasswordRecaptchaExpired() {
                this.isLoginWithPasswordRecaptchaVerified = false
                this.loginWithPasswordRecaptchaToken = ''
            },
            async registerWithMobilePhoneAndPassword() {

                if(this.isOnRegisterWithMobilePhoneAndPassword) {
                    return
                }

                this.isOnRegisterWithMobilePhoneAndPassword = true

                const channel = this.channel
                const message_api_id = this.message_api_id
                const mobile_phone_number = this.registerWithMobilePhoneAndPasswordForm.mobilePhoneNumber
                const password = this.registerWithMobilePhoneAndPasswordForm.password
                const confirm_password = this.registerWithMobilePhoneAndPasswordForm.confirmPassword
                const referral_code = this.referral_code
                const reward_id = this.reward_id
                const picture_url = IMAGE_MAP[0][randomBetween(0, IMAGE_MAP[0].length - 1)]

                if(!mobile_phone_number || !password || !confirm_password) {
                    this.$bvToast.toast('กรุณากรอกข้อมูลให้ครบถ้วน', {
                        title: 'แจ้งเตือน',
                        toaster: 'b-toaster-top-full'
                    })
                    this.isOnRegisterWithMobilePhoneAndPassword = false
                    return
                }

                if(password !== confirm_password) {
                    this.$bvToast.toast('รหัสผ่านไม่ตรงกัน กรุณาลองใหม่อีกครั้ง', {
                        title: 'แจ้งเตือน',
                        toaster: 'b-toaster-top-full'
                    })
                    this.isOnRegisterWithMobilePhoneAndPassword = false
                    return
                }

                this.loading = this.$loading()

                const isRouteQueryChannelAvailable = await this.checkRouteQueryChannelAvailable()
                if(!isRouteQueryChannelAvailable) {
                    this.loading.close()
                    this.isOnRegisterWithMobilePhoneAndPassword = false
                    return
                }

                const res = await axiosWrapper({
                    method: 'POST',
                    url: `${this.envDomains.VUE_APP_LIFF_API}/api/user/register-with-mobile-phone-and-password`,
                    data: {
                        channel,
                        message_api_id,
                        mobile_phone_number,
                        password,
                        referral_code,
                        reward_id,
                        picture_url,
                        user_agent: navigator.userAgent,
                        web_referrer: document.referrer,
                    }
                })

                if(res.status === 200) {
                    this.signIn(res.data.token)
                    sessionStorage.setItem('new_member_register',1);
                    sessionStorage.setItem('is_packet_show',1);
                    this.$emit('success')
                    setTimeout(() => {
                        location.reload()
                    }, 100)
                } else {
                    this.$bvToast.toast(res.data.message, {
                        title: 'แจ้งเตือน',
                        toaster: 'b-toaster-top-full'
                    })
                }

                this.loading.close()
                this.isOnRegisterWithMobilePhoneAndPassword = false
            },
            goResetPasswordPage() {
                this.page = 'reset-password-verify'
            },
            async toResetPassword(data) {

                if(this.isOnOtpVerify) {
                    return
                }

                this.isOnOtpVerify = true
                this.loading = this.$loading()

                this.resetPasswordForm.mobile_phone_number = data.mobile_phone_number
                this.resetPasswordForm.otp = data.otp

                const mobile_phone_number = this.resetPasswordForm.mobile_phone_number
                const otp = this.resetPasswordForm.otp

                const otpVerifyProgress = await axiosWrapper({
                    method: 'POST',
                    url: `${this.envDomains.VUE_APP_LIFF_API}/api/user/otp-verify`,
                    data: {
                        mobile_phone_number: mobile_phone_number,
                        otp: otp,
                    }
                })

                this.isOnOtpVerify = false
                this.loading.close()

                if(otpVerifyProgress.status !== 200) {
                    this.$bvToast.toast(otpVerifyProgress.data.message, {
                        title: 'แจ้งเตือน',
                        toaster: 'b-toaster-top-full',
                        noAutoHide: true,
                    })
                    return
                }

                this.page = 'reset-password'
            },
            async resetPassword() {

                if(this.isOnResetPassword) {
                    return
                }

                const password = this.resetPasswordForm.password
                const confirmPassword = this.resetPasswordForm.confirmPassword

                if(!password || !confirmPassword) {
                    this.$bvToast.toast('กรุณากรอกข้อมูลให้ครบถ้วน', {
                        title: 'แจ้งเตือน',
                        toaster: 'b-toaster-top-full'
                    })
                    return
                }
                if(password !== confirmPassword) {
                    this.$bvToast.toast('รหัสผ่านไม่ตรงกัน กรุณาลองใหม่อีกครั้ง', {
                        title: 'แจ้งเตือน',
                        toaster: 'b-toaster-top-full'
                    })
                    return
                }

                this.isOnResetPassword = true
                this.loading = this.$loading()

                const mobile_phone_number = this.resetPasswordForm.mobile_phone_number
                const otp = this.resetPasswordForm.otp

                const resetPasswordProgress = await axiosWrapper({
                    method: 'POST',
                    url: `${this.envDomains.VUE_APP_LIFF_API}/api/user/reset-password`,
                    data: {
                        mobile_phone_number: mobile_phone_number,
                        otp: otp,
                        password: password,
                    }
                })

                this.isOnResetPassword = false
                this.loading.close()

                if(resetPasswordProgress.status !== 200) {
                    this.$bvToast.toast(resetPasswordProgress.data.message, {
                        title: 'แจ้งเตือน',
                        toaster: 'b-toaster-top-full',
                        noAutoHide: true,
                    })
                    return
                }
                this.page = 'reset-password-done'
            },
            clearResetPasswordForm() {
                this.resetPasswordForm = {
                    mobile_phone_number: '',
                    otp: '',
                    password: '',
                    confirmPassword: '',
                }
            },
            resetPasswordDoneSubmit() {
                this.clearResetPasswordForm()
                this.page = 'sign-in'
            },
            goRegisterPage() {
                this.$refs['notify-mobile-phone-strict-modal'].show()
                this.page = 'register'
            },
        },
    }
</script>
<style scoped>
    .flex-space-between {
        width: 100%;
        display: flex;
        justify-content: space-between;
    }
    .small-link-text {
        cursor: pointer;
        font-size: 12px;
        color: #753189;
        margin-bottom: 10px;
    }
    .message-notify-danger {
        color: #E83B4E;
        width: 100%;
        text-align: center;
        padding: 3px 5px;
        /*background-color: rgba(232, 59, 78, 0.21);*/
        margin-bottom: 0;
        border: 1px solid transparent;
        border-radius: 0.25rem;
    }
    .boxProfileLine{
        /*position: absolute;*/
        display: flex;
        align-items: center;
        flex-direction: column;
        /*top: -123px;*/
        width: 100%;
        margin-bottom: 15px;
    }
    .boxProfileLine img{
        width: 55px;
        height: 55px;
        border-radius: 50%;
        border: solid 1px rgba(255, 255, 255, 0.35);
    }
    .boxProfileLine h2{
        font: 700 16px Athiti;
        color: white;
    }
    .Auth-wrapper-content{
        position: relative;
        background-color: #fff;
        border-radius: 20px;
        padding-top: 5px;
        width: 100%;
        height: 100%;
        z-index: 3;
        top: 0;

    }


</style>
<style>

    @media only screen
    and (min-device-width: 1300px)
    and (orientation: landscape){
        .editModal .modal-dialog{
            width: 350px!important;
        }
    }
    .editModal h5.modal-title{font: 800 23px Athiti;}
    .editModal i{margin: 20px 0;}
    .editModal .modal-footer,
    .editModal .modal-body{ z-index: 2}
    .editModal .modal-body h4{ font: 800 16px Athiti;}
    .editModal .form-row-flex h4{ margin: 0 10px 0 0;}
    .editModal .form-row-flex{
        align-items: center;
    }
    .editModal p{margin-bottom: 10px;}
    .editModal .Uploader{
        display: flex;
        justify-content: center;
        align-items: center;
        flex-direction: column;
    }
    .editModal .Uploader .btn .file-icon{margin-right: 10px;}
    .editModal .Uploader .btn{
        padding: 0 10px;
        height: 50px;
        display: flex;
        align-items: center;
        justify-content: center;
    }
    .editModal .Uploader label{margin-top: 10px;}
    .editModal .form-row-content{
        background-color: #eee;
        border-radius: 10px;
        padding: 10px;
    }
    .editModal .modal-content{border-radius: 20px; z-index: 2}
    .editModal .modal-content:after{
        width: 80%;
        content: '';
        background-color: rgba(255, 255, 255, 0.3);
        border-radius: 20px;
        position: absolute;
        bottom: -10px;
        top: 0;
        left: 10px;
        right: 10px;
        z-index: 1;
        margin: auto;
    }
    .editModal .modal-content .btn-secondary{
        border-color: #d20a84;color: #d20a84;
        background-color: transparent;
    }
    .editModal .modal-content .btn-primary{
        color: white!important;
        border: 0;
        background-color: #d20a84;
        background-image:  linear-gradient(to bottom, #753189 0%, #d20a84 100%)!important;
    }
</style>