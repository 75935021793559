<template>
    <div v-if="src" class="VideoPlayerHLS">
        <video-player class="vjs-big-play-centered"
            ref="videoPlayer"
            :playsinline="true"
            :options="playerOptions" 
            @ready="playerReadied"
            @canplay="onPlayerCanplay($event)"
            @error="onPlayerError">
        </video-player>
        <modal name="reload-video-modal" height="60" width="60" :pivotY="0.2">
            <div style="display:flex; justify-content: center; align-item: center; padding: 10px;">
                <button @click="play" class="btn btn-primary"><i class="fa fa-play"></i></button>
            </div>
        </modal>
        <button @click="toggleMute" class="btn btn-light toggle-mute-button">
            <i v-show="isPlayerMute" class="fa fa-volume-mute"></i>
            <i v-show="!isPlayerMute" class="fa fa-volume-up"></i>
        </button>
    </div>
</template>
<script>

import 'video.js/dist/video-js.css'
import { videoPlayer } from 'vue-video-player'
import videojs from 'video.js'
window.videojs = videojs
require('videojs-contrib-hls/dist/videojs-contrib-hls.js')

export default {
    name: 'VideoPlayerHLS',
    components: {
        videoPlayer,
    },
    props: [
        'src',
        'width',
        'height',
        'playerBus',
        'isMute',
    ],
    data() {
        return {
            isPlayerReady: false,
            isPlayerOnAutoPlay: false,
            isPlayerMute: true,
            autoplay: 'muted',
        }
    },
    computed: {
        player() {
            if(!this.$refs.videoPlayer) {
                return null
            }
            return this.$refs.videoPlayer.player
        },
        playerOptions() {
            return {
                autoplay: this.autoplay,
                controls: false,
                height: this.height,
                width: this.width,
                controlBar: {
                    fullscreenToggle: false
                },
                sources: [{
                    withCredentials: false,
                    type: "application/x-mpegURL",
                    src: this.src,
                }],
                flash: { hls: { withCredentials: false }},
                html5: { hls: { withCredentials: false }},
            }
        },
    },
    methods: {
        playerReadied() {
            this.isPlayerReady = true
            this.$emit('ready')
        },
        onPlayerCanplay(player) {
            player.play()
        },
        onPlayerError(error) {
            this.$emit('error', error)
        },
        play() {

            this.$modal.hide('reload-video-modal')

            const promise = this.player.play();

            if (promise !== undefined) {
                promise.then(() => {
                    this.isPlayerOnAutoPlay = true
                }).catch(() => {
                    this.isPlayerOnAutoPlay = false
                    this.$modal.show('reload-video-modal')
                });
            } else {
                this.isPlayerOnAutoPlay = false
                this.$modal.show('reload-video-modal')
            }
        },
        handleClick() {

            if(!this.player){
                return
            }
            if(!this.isPlayerReady) {
                return
            }
            if(this.isPlayerOnAutoPlay) {
                return
            }
            this.play(this.player)
        },
        toggleMute() {
            this.isPlayerMute = !this.isPlayerMute
            this.player.muted(this.isPlayerMute)
        },
        fullscreen() {
            
            const element = document.querySelector('.VideoPlayerHLS video')
            
            const requestFullScreen = element.requestFullScreen || element.mozRequestFullScreen || element.webkitRequestFullScreen;
            if (!requestFullScreen) {
                this.$bvToast.toast('เบราว์เซอร์ไม่รองรับ ขออภัยในความไม่สะดวก', {
                    title: 'แจ้งเตือน',
                    toaster: 'b-toaster-top-full'
                })
                return
            }
            requestFullScreen.bind(element)();
            if (screen.orientation && screen.orientation.lock) {
                screen.orientation.lock('landscape');
            }
        },
        mute() {
            this.isPlayerMute = true
            this.player.muted(true)
        },
        unMute() {
            this.isPlayerMute = false
            this.player.muted(false)
        },
    },
    mounted() {
        if(typeof this.isMute === "boolean") {
            if(!this.isMute) {
                this.isPlayerMute = this.isMute
                this.autoplay = true
            }
        }
        if(this.playerBus) {
            this.playerBus.$on('fullscreen', this.fullscreen)
            this.playerBus.$on('mute', this.mute)
            this.playerBus.$on('unMute', this.unMute)
        }
    },
    beforeDestroy() {
        if(this.playerBus) {
            this.playerBus.$off('fullscreen', this.fullscreen)
            this.playerBus.$off('mute', this.mute)
            this.playerBus.$off('unMute', this.unMute)
        }
    }
}
</script>
<style scoped>
.video-js .vjs-tech {
    position: absolute!important;
    top: 0;
    left: 0;
    width: 100%!important;
    height: 100%!important;
}
/*.toggle-mute-button {*/
/*    position: absolute;*/
/*    z-index: 102;*/
/*    bottom: 50px;*/
/*    left: 10px;*/
/*    width: 40px;*/
/*    height: 40px;*/
/*    border-radius: 50%;*/
/*}*/
@media (orientation: landscape) {
    .toggle-mute-button {
        /*bottom: 90px;*/
    }
}
</style>