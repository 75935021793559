import axiosWrapper from '@/axiosWrapper'

export default {
    name: 'ApiMixin',
    methods: {
        async apiGetConfig(lotto_type){
            try{
                const get_config_res = await axiosWrapper({
                    method: 'POST',
                    url: `${this.envDomains.VUE_APP_LOTTO_ADMIN_API}/api/config/get`,
                    data: {
                        lotto_type
                    }
                })
                if(get_config_res.status === 200){
                    return {success: true, ...get_config_res.data}
                }else{
                    return {success: false, ...get_config_res.data}
                }
            }catch(err){
                return {
                    success: false, 
                    message: 'ไม่สามารถโหลดข้อมูลได้ กรุณาลองใหม่อีกครั้ง',
                    message_code: err.message
                }
            }
        }
    }
}