export const SYSTEM_CHAT_TYPE = [
    'top_up', 
    'football', 
    'system', 
    'lotto_news', 
    'lotto_price', 
    'baccarat', 
    'sicbo', 
    'baccarat_alert', 
    'live', 
    'gallery', 
    'timeline',
    'incoming_chat',
    'lotto_result',
    'balloon_game',
    'unbox',
    'bonus',
    'balloon_lotto_result',
    'lotto_win',
    'promotion',
    'rich',
    'game_stat',
]

export const CHAT_TYPE = [
    'text',
    'sticker',
]

export const LOTTO_TYPE = [
    'government',
    'hanoy',
    'lao',
    'malay',
    'yeekee',
    'hanoy_special',
    'hanoy_vip',
]

export const LOTTO_TYPE_FLAG = {
    'government': 'TH.png',
    'hanoy': 'VN.png',
    'lao': 'LA.png',
    'malay': 'MY.png',
    'yeekee': ''
}

export const LOTTO_BET_TYPE = [
    'sam_tua_bon',
    'sam_tua_tod',
    'sam_tua_nha',
    'sam_tua_lang',
    'song_tua_bon',
    'song_tua_lang',
    'wing_bon',
    'wing_lang'
]

export const LOTTO_BET_TYPE_LABEL = {
    'sam_tua_bon': '3 ตัวบน',
    'sam_tua_tod': '3 ตัวโต๊ด',
    'sam_tua_nha': '3 ตัวหน้า',
    'sam_tua_lang': '3 ตัวล่าง',
    'song_tua_bon': '2 ตัวบน',
    'song_tua_lang': '2 ตัวล่าง',
    'wing_bon': 'วิ่งบน',
    'wing_lang': 'วิ่งล่าง'
}

export const SPORT_ARR = [
    {
        slug: 'football',
        name: 'ฟุตบอล'
    },
    {
        slug: 'basketball',
        name: 'บาสเก็ตบอล'
    },
    {
        slug: 'tennis',
        name: 'เทนนิส'
    },
    {
        slug: 'volleyball',
        name: 'วอลเล่ย์บอล'
    },
    {
        slug: 'badminton',
        name: 'แบดมินตัน'
    },
    {
        slug: 'muaythai',
        name: 'มวยไทย'
    },
    {
        slug: 'boxing',
        name: 'มวยสากล'
    }
]

export const BANK_LIST = [
    {
        swift_code: 'BKKBTHBK',
        short_name: 'BBL',
        name: 'ธนาคารกรุงเทพ',
        name_en: 'Bangkok Bank',
        bank_code: 'BBL_THB',
        icon_class: 'icon-bbl'
    },
    {
        swift_code: 'KASITHBK',
        short_name: 'KBANK',
        name: 'ธนาคารกสิกรไทย',
        name_en: 'Kasikorn Bank',
        bank_code: 'KKB_THB',
        icon_class: 'icon-kbank'
    },
    {
        swift_code: 'KRTHTHBK',
        short_name: 'KTB',
        name: 'ธนาคารกรุงไทย',
        name_en: 'Krung Thai Bank',
        bank_code: 'KTB_THB',
        icon_class: 'icon-ktb'
    },
    // {
    //     swift_code: 'TMBKTHB',
    //     short_name: 'TMB',
    //     name: 'ธนาคารทหารไทย',
    //     name_en: 'Thai Military Bank',
    //     bank_code: 'TMB_THB',
    //     icon_class: 'icon-tmb'
    // },
    {
        swift_code: 'SICOTHBK',
        short_name: 'SCB',
        name: 'ธนาคารไทยพาณิชย์',
        name_en: 'Siam Commercial Bank',
        bank_code: 'SCB_THB',
        icon_class: 'icon-scb'
    },
    {
        swift_code: 'AYUDTHBK',
        short_name: 'BAY',
        name: 'ธนาคารกรุงศรีอยุธยา',
        name_en: 'KrungSri (Bank of Ayudhya Public Company Limited)',
        bank_code: 'BAY_THB',
        icon_class: 'icon-bay'
    },
    // {
    //     swift_code: 'KIFITHB1',
    //     short_name: 'KKP',
    //     name: 'ธนาคารเกียรตินาคิน'
    // },
    {
        swift_code: 'UBOBTHBK',
        short_name: 'CIMBT',
        name: 'ธนาคารซีไอเอ็มบีไทย',
        name_en: 'CICM CLICKS',
        bank_code: 'CIMB_THB',
        icon_class: 'icon-cimb'
    },
    // {
    //     swift_code: 'TFPCTHB1',
    //     short_name: 'TISCO',
    //     name: 'ธนาคารทิสโก้'
    // },
    // {
    //     swift_code: 'THBKTHBK',
    //     short_name: 'TBANK',
    //     name: 'ธนาคารธนชาต',
    //     name_en: 'Thanachart Bank',
    //     bank_code: 'TCB_THB',
    //     icon_class: 'icon-tbank'
    // },
    {
        swift_code: 'UOVBTHBK',
        short_name: 'UOBT',
        name: 'ธนาคารยูโอบี',
        name_en: 'United Overseas Bank',
        bank_code: 'UOB_THB',
        icon_class: 'icon-uob'
    },
    // {
    //     swift_code: 'BAABTHBK',
    //     short_name: 'BAAC',
    //     name: 'ธนาคารเพื่อการเกษตรและสหกรณ์การเกษตร'
    // },
    {
        swift_code: 'GSBATHBK',
        short_name: 'GSB',
        name: 'ธนาคารออมสิน',
        name_en: 'Government Savings Bank',
        bank_code: 'GSB_THB',
        icon_class: 'icon-gsb'
    },
    // {
    //     swift_code: 'TIBTTHBK',
    //     short_name: 'ISBT',
    //     name: 'ธนาคารอิสลามแห่งประเทศไทย'
    // }
    {

        swift_code: 'TMBKTHBK',
        short_name : 'TTB',
        name : 'ธนาคารทหารไทยธนชาต',
        name_en : 'TMBThanachart Bank Public Company Limited',
        bank_code: 'TTB_THB',
        icon_class :'icon-ttb'
    },
    {
        swift_code: 'BAABTHBK',
        short_name: 'BAAC',
        name: 'ธนาคารเพื่อการเกษตรและสหกรณ์การเกษตร',
        name_en: 'Bank for Agriculture and Agricultural Cooperatives',
        bank_code: 'BAAC_THB',
        icon_class: 'icon-baac'
    },
]
