<template>
    <div class="UserBetChip" ref="UserBetChip" :class="`user-bet-chip-${coinClass}`">
        <div class="box-select-coin">
            <span>{{value}}</span>
        </div>
    </div>
</template>
<script>

import {CHIP_MAP} from './Constant'

const DURATION = 600
const EASING = 'ease-in'
const POSTION_PADDING = 14
import randomBetween from '@/lib/randomBetween'

export default {
    name: 'UserBetChip',
    props: ['state', 'chip', 'value', 'user_choose'], // state = playerPlace, goPlayer, goBanker, bankerPlace
    computed: {
        coinClass() {
            if(!this.chip) {
                return ''
            }
            return CHIP_MAP[parseInt(this.chip)]['classMini']
        },
        el() {
            return this.$refs.UserBetChip
        },
    },
    watch: {
        state(newVal) {
            if(newVal === 'goBanker') {
                setTimeout(() => {
                    this.goBanker()
                }, 2 * DURATION)
            } else if(newVal === 'goPlayer') {
                setTimeout(() => {
                    this.goPlayer()
                }, 2 * DURATION)
            } else if(newVal === 'playerPlace') {
                this.playerPlace()
            }
        },
    },
    methods: {
        getBankerPosition() {
            const el = document.querySelector('#bankerDiv')
            const viewportOffset = el.getBoundingClientRect()
            const {top, left} = viewportOffset
            return {top, left}
        },
        getPlayerPosition() {
            const el = document.querySelector('#playerDiv')
            const viewportOffset = el.getBoundingClientRect()
            const {top, left} = viewportOffset
            return {top, left}
        },
        getCurrentPosition() {
            const viewportOffset = this.el.getBoundingClientRect()
            const {top, left} = viewportOffset
            return {top, left}
        },
        getPanelPosition(user_choose) {
            const el = document.querySelector(`.UserBetChip[panel='${user_choose}']`)
            const viewportOffset = el.getBoundingClientRect()
            const {top, left} = viewportOffset
            return {top, left}
        },
        playerPlace() {
            // this.el.style['top'] = 0
            this.el.style['top'] = '-15px'
            this.el.style['right'] = 0
            this.el.style['bottom'] = 0
            this.el.style['left'] = 0
            this.el.style['margin'] = 'auto'
        },
        goBanker() {

            const currentPosition = this.getCurrentPosition()
            const bankerPosition = this.getBankerPosition()

            const startTop = `${currentPosition.top}px`
            const startLeft = `${currentPosition.left}px`
            const toTop = `${bankerPosition.top}px`
            const toLeft = `${bankerPosition.left}px`

            this.el.style['position'] = 'fixed'
            this.el.style['margin'] = 'unset'

            this.el.animate(
                [
                    {top: startTop, left: startLeft}, 
                    {top: toTop, left: toLeft}
                ],
                {
                    duration: DURATION, 
                    easing: EASING,
                }
            )
            setTimeout(() => {
                this.el.style['display'] = 'none'
            }, DURATION)
        },
        bankerPlace() {

            const currentPosition = this.getCurrentPosition()
            const panelPosition = this.getPanelPosition(this.user_choose)

            const startTop = `${currentPosition.top}px`
            const startLeft = `${currentPosition.left}px`
            const toTop = `${panelPosition.top - POSTION_PADDING}px`
            const toLeft = `${panelPosition.left - POSTION_PADDING}px`

            this.el.style['position'] = 'fixed'
            this.el.style['margin'] = 'unset'

            this.el.animate(
                [
                    {top: startTop, left: startLeft}, 
                    {top: toTop, left: toLeft}
                ],
                {
                    duration: DURATION, 
                    easing: EASING,
                }
            )
            setTimeout(() => {
                this.el.style['top'] = toTop
                this.el.style['left'] = toLeft
                setTimeout(() => {
                    this.goPlayer()
                }, DURATION)
            }, DURATION)
        },
        playerPlaceExtra() {

            const currentPosition = this.getCurrentPosition()
            const panelPosition = this.getPanelPosition(this.user_choose)

            const startTop = `${currentPosition.top}px`
            const startLeft = `${currentPosition.left}px`
            const toTop = `${panelPosition.top - randomBetween(-10, 24)}px`
            const toLeft = `${panelPosition.left - randomBetween(-10, 24)}px`

            this.el.style['position'] = 'fixed'
            this.el.style['margin'] = 'unset'

            this.el.animate(
                [
                    {top: startTop, left: startLeft}, 
                    {top: toTop, left: toLeft}
                ],
                {
                    duration: DURATION, 
                    easing: EASING,
                }
            )
            setTimeout(() => {
                this.el.style['top'] = toTop
                this.el.style['left'] = toLeft
                setTimeout(() => {
                    this.goBanker()
                }, DURATION)
            }, DURATION)
        },
        goPlayer() {

            const currentPosition = this.getCurrentPosition()
            const playerPosition = this.getPlayerPosition()

            const startTop = `${currentPosition.top}px`
            const startLeft = `${currentPosition.left}px`
            const toTop = `${playerPosition.top}px`
            const toLeft = `${playerPosition.left}px`

            this.el.style['position'] = 'fixed'
            this.el.style['margin'] = 'unset'

            this.el.animate(
                [
                    {top: startTop, left: startLeft}, 
                    {top: toTop, left: toLeft}
                ],
                {
                    duration: DURATION, 
                    easing: EASING,
                }
            )
            setTimeout(() => {
                this.el.style['display'] = 'none'
            }, DURATION)
        },
        test() {
            this.goBanker()
        },
    },
    mounted() {
        if(this.state === 'bankerPay') {
            this.bankerPlace()
        } else if(this.state === 'playerPlace') {
            this.playerPlace()
        } else if(this.state === 'playerPay') {
            this.playerPlaceExtra()
        }
    },
}
</script>
<style scoped>
.UserBetChip {
    position: absolute;
    width: 45px;
    height: 45px;
    background-repeat: no-repeat;
    background-size: contain;
    z-index: 2;
    border-radius: 50%;
    box-shadow: 2px 0px #ddd;
    text-align: center;
    /* top: -15px!important; */
}

@media (orientation: landscape) {
    .UserBetChip {
        width: 45px;
        height: 45px;
    }
}

.box-select-coin {
    display: block;
}

.user-bet-chip-c5 {
    background-image: url(../../assets/images/c-5.png)
}
.user-bet-chip-c10 {
    background-image: url(../../assets/images/c-10.png)
}
.user-bet-chip-c20 {
    background-image: url(../../assets/images/c-20.png)
}
.user-bet-chip-c50 {
    background-image: url(../../assets/images/c-50.png)
}
.user-bet-chip-c100 {
    background-image: url(../../assets/images/c-100.png)
}
.user-bet-chip-c200 {
    background-image: url(../../assets/images/c-200.png)
}
.user-bet-chip-c500 {
    background-image: url(../../assets/images/c-500.png)
}
.user-bet-chip-c1k {
    background-image: url(../../assets/images/c-1k.png)
}
.user-bet-chip-c2k {
    background-image: url(../../assets/images/c-2k.png)
}
.user-bet-chip-c5k {
    background-image: url(../../assets/images/c-5k.png)
}
.user-bet-chip-c10k {
    background-image: url(../../assets/images/c-10k.png)
}
.user-bet-chip-c20k {
    background-image: url(../../assets/images/c-20k.png)
}
.user-bet-chip-c50k {
    background-image: url(../../assets/images/c-50k.png)
}

</style>