<script>

import GameTableListItemMixin from '@/mixins/GameTableListItem'
import {DRAGON_TIGER_GAME_RESULT, DRAGON_TIGER_REWARD_RATIO} from '../Constant'

const classMap = {
    0: '',
    1: 'color-blue',
    2: 'color-red',
    3: 'color-yellow',
    4: '',
}

const LIMIT = 60
const PADDING_COUNT_FOR_LATEST = 0

export default {
    name: 'DragonTigerTableListItem',
    props: [
        'table_id',
        'table_round_main',
    ],
    mixins: [
        GameTableListItemMixin,
    ],
    data() {
        return {
            secondStart: 15,
        }
    },
    computed: {
        rawStatArr() {
            return this.getStatArrByTable(this.table_id, this.table_round_main)
        },
        statArr() {

            const start = Math.max(0, this.rawStatArr.length - 1 - PADDING_COUNT_FOR_LATEST - LIMIT)
            const notInclude = this.rawStatArr.length - 1 - PADDING_COUNT_FOR_LATEST

            return this.rawStatArr.slice(start, notInclude)
        },
        statLatest() {
            if(this.rawStatArr.length > 1) {
                return this.rawStatArr[this.rawStatArr.length - 1 - PADDING_COUNT_FOR_LATEST]
            } else {
                return null
            }
        },
    },
    methods: {
        classComputed(raw) {
            if(!raw) {
                return ''
            }
            return classMap[raw]
        },
        resultComputed(raw) {
            if(!raw) {
                return ''
            }
            return DRAGON_TIGER_GAME_RESULT[raw]['TH']
        },
        ratioDisplay(raw) {
            if(!raw) {
                return ''
            }
            if(!DRAGON_TIGER_REWARD_RATIO[raw]) {
                return ''
            }
            return DRAGON_TIGER_REWARD_RATIO[raw]['label']
        },
        calWinnerPoint(game_info) {

            if(!game_info) {
                return ''
            }
            if(!game_info.result || !game_info.card_list) {
                return ''
            }
            if(!game_info.result.game_result) {
                return ''
            }
            const cardListMap = {
                1: 'dragon',
                2: 'tiger',
                3: 'dragon',
            }
            
            const cardListName = cardListMap[game_info.result.game_result]

            return this.calCardPoint(game_info.card_list[cardListName])
        },
        calPoint(card_list) {
            return card_list.reduce((sum, item) => {
                return sum + this.calCardPoint(item)
            }, 0) % 10
        },
        calCardPoint(cardNumber) {
            if(!cardNumber) {
                return 0
            }
            if(parseInt(cardNumber) >= 10) {
                return 0
            }
            return parseInt(cardNumber)
        },
    },
}
</script>