<template>
    <span ref="corCard" class="corCard" :class="{'getCard':showCustom}">
        <div v-if="isHorizontal === 'yes'" class="corCardRote">
            <div class="cardMain">
                <div class="content-cardMain">
                    <div class="front-cardMain"><img src="@/assets/images/pokercard/card-back.png"/></div>
                    <div class="back-cardMain"><img :src="imagePath"/></div>
                </div>
            </div>
        </div>
        <div v-else class="cardMain">
            <div class="content-cardMain">
                <div class="front-cardMain"><img src="@/assets/images/pokercard/card-back.png"/></div>
                <div class="back-cardMain"><img :src="imagePath"/></div>
            </div>
        </div>
    </span>
</template>
<script>

import {CARD_TYPE_IMAGE, CARD_NUMBER_IMAGE} from './Constant'

export default {
    name: 'BaccaratFloatOpenCardCard',
    props: [
        'card',
        'isHorizontal',
        'dataOpenDelay',
    ],
    data() {
        return {
            showCustom: false,
        }
    },
    computed: {
        isHaveCard() {
            if(!this.card) {
                return false
            }
            if(this.cardNumber) {
                return true
            } else {
                return false
            }
        },
        cardType() {
            if(!this.card) {
                return ''
            }
            return this.card.card_type
        },
        cardNumber() {
            if(!this.card) {
                return ''
            }
            return this.card.card_number
        },
        imagePath() {
            return `${this.envDomains.VUE_APP_API}/images/pokercard/${CARD_TYPE_IMAGE[this.cardType]}${CARD_NUMBER_IMAGE[this.cardNumber]}.png`
        },
        openDelay() {
            return (this.dataOpenDelay) ? this.dataOpenDelay : 400
        },
    },
    watch: {
        isHaveCard(newVal) {
            if(newVal) {
                this.show()
            } else {
                this.hide()
            }
        },
        showCustom(newVal) {
            if(newVal) {
                setTimeout(() => {
                    this.$refs['corCard'].classList.add('corCardFlip')
                }, 400)
            }
        },
    },
    methods: {
        show() {
            setTimeout(() => {
                if(!this.cardNumber) {
                    return
                }
                this.showCustom = true
            }, this.openDelay - 400)
        },
        hide() {
            this.$refs['corCard'].classList.remove('corCardFlip')
            this.showCustom = false
        }
    },
    mounted() {
        if(this.isHaveCard) {
            this.show()
        } else {
            this.hide()
        }
    }
}
</script>