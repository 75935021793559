<template>
    <div class="boxHitecCasino">
        <div class="nav-menu-topic ">
                                        <img  src="@/assets/images/casino-hitech.png">
                                        <h4>ไฮเทค คาสิโน</h4>
                                    </div>
                                    <ul>
                                        <li @click="openGameCasino('Baccarat')">
                                            <img src="@/assets/images/icon/set-icon/icon-baccarat-2.png"/>
                                            <span>บาคาร่า</span>
                                        </li>
                                        <!-- <li @click="openGameCasino('Baccarat3')">
                                            <img src="@/assets/images/icon/set-icon/icon-baccaratVIP.png"/>
                                            <span>บาคาร่า VIP</span>
                                        </li> -->
                                        <li @click="openGameCasino('MiniSicbo')">
                                            <img src="@/assets/images/icon/set-icon/icon-hilo-2.png"/>
                                            <span>ไฮโล</span>
                                        </li>
                                        <li @click="openGameCasino('MiniFantan')">
                                            <img src="@/assets/images/icon/set-icon/icon-fantan.png"/>
                                            <span>ถั่วหยิบ</span>
                                        </li>
                                        <li @click="openGameCasino('MiniDragonTiger')">
                                            <img src="@/assets/images/icon/set-icon/icon-tiger-dagon.png"/>
                                            <span>เสือมังกร</span>
                                        </li>
                                    </ul>
    </div>
</template>
<script>
    export default {
        name: 'GameListCasinoList',
        methods: {
            openGameCasino(game){
                this.$router.push({ name:game});            
            },
        }
    }
</script>