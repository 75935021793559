<template>
    <div class="GoLobbyModal">
        <modal name="go-lobby-modal" 
            height="250px"
            width="200px"
            :clickToClose="false"
            @opened="openedHandle">
            <div class="custom-modal-content">
                <div class="title">
                    <h3><i class="fas fa-exclamation-triangle"></i> แจ้งเตือน</h3>
                </div>
                <div>
                    ขออภัย! คุณกลับไปที่ล็อบบี้เพราะว่าไม่ได้วางเดิมพันมาหลายรอบ
                </div>
                <div class="custom-modal-footer">
                    <div>
                      <span>{{secondCoundown}}</span>
                        <button @click="goLobby" class="btn btn-primary">
<!--                            <i class="fas fa-check"></i>-->
                            ตกลง
                        </button>
                    </div>
                </div>
            </div>
        </modal>
    </div>
</template>
<script>

import {mixin as VueTimers} from 'vue-timers'
const COUNTDOWN_START_SECOND = 5

export default {
    name: 'GoLobbyModal',
    mixins: [VueTimers],
    timers: {
        goLobby: {
            time: 5000,
        },
        countdown: {
            time: 1000,
            repeat: true,
        },
    },
    data() {
        return {
            secondCoundown: COUNTDOWN_START_SECOND,
        }
    },
    methods: {
        goLobby() {
            this.$router.replace({name: 'GameType'})
        },
        countdown() {
            if(this.secondCoundown > 0) {
                this.secondCoundown -= 1
            }
        }, 
        openedHandle() {
            this.$timer.start('goLobby')
            this.$timer.start('countdown')
        },
    },
}
</script>
<style scoped>
.custom-modal-content {
    padding: 10px;
}
.custom-modal-footer {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 10px;
}
    .custom-modal-content .title h3{
        font: 600 18px Athiti;
        border-bottom: solid 1px #ddd;
    }
    .GoLobbyModal .custom-modal-footer{text-align: center}
    .GoLobbyModal .custom-modal-footer span{
        display: inline-block;
        width: 100%;
        margin-bottom: 15px;
    }
.GoLobbyModal .custom-modal-footer button.btn-primary{
    font-size: 20px;
    border-color: #753189;
    background-color: rgba(243, 114, 212, 0.9);
border-radius: 10px;
}
</style>