<template>
    <div class="AdminToolContent">
        <el-button @click="toggleSelectTemplateModal" type="primary" plain>เลือกเทมเพลต</el-button>
        <div class="v-line-seperator"></div>
        <div>URL</div>
        <div v-for="(formUrl, i) of form.urls" :key="i">
            {{formUrl.label}}
            <el-input v-model="formUrl.value" placeholder="https://www.youtube.com/embed/ykCpjm5mLMU"></el-input>
            <div class="v-line-seperator"></div>
        </div>
        <div class="v-line-seperator"></div>
        <div>อัพโหลดรูปพื้นหลัง</div>
        <Uploader  
            :data="{token}"
            :bus="uploadBus"
            :multiple="false"
            @done="_handleUploadDone"
            @remove="_handleUploadRemove"
            :minWidth="imageDimensionLimit.minWidth"
            :minHeight="imageDimensionLimit.minHeight"
            :maxWidth="imageDimensionLimit.maxWidth"
            :maxHeight="imageDimensionLimit.maxHeight"/>
        <div class="v-line-seperator"></div>
        <el-button @click="toRemove" icon="el-icon-close" class="content-remove-button" circle plain></el-button>

        <el-dialog
        title="เลือกเทมเพลต"
        :visible.sync="isShowSelectTemplateDialog"
        width="59%"
        @open="templateDialogOpenHandle">
            <div class="content-select-rich" >
                <div style="display: flex;">
                    <div @click="selectTemplate(0)" style="cursor: pointer;">
                        <Type0 showGrid="yes" :class="{'template-selected': templateSelected === 0}"></Type0>
                    </div>
                </div>
                <div style="display: flex; flex: 1; flex-wrap: wrap;">
                    <div @click="selectTemplate(1)" style="cursor: pointer;">
                        <Type1 showGrid="yes" :class="{'template-selected': templateSelected === 1}"></Type1>
                    </div>
                    <div @click="selectTemplate(2)" style="cursor: pointer;">
                        <Type2 showGrid="yes" :class="{'template-selected': templateSelected === 2}"></Type2>
                    </div>
                    <div @click="selectTemplate(3)" style="cursor: pointer;">
                        <Type3 showGrid="yes" :class="{'template-selected': templateSelected === 3}"></Type3>
                    </div>
                    <div @click="selectTemplate(4)" style="cursor: pointer;">
                        <Type4 showGrid="yes" :class="{'template-selected': templateSelected === 4}"></Type4>
                    </div>
                    <div @click="selectTemplate(5)" style="cursor: pointer;">
                        <Type5 showGrid="yes" :class="{'template-selected': templateSelected === 5}"></Type5>
                    </div>
                    <div @click="selectTemplate(6)" style="cursor: pointer;">
                        <Type6 showGrid="yes" :class="{'template-selected': templateSelected === 6}"></Type6>
                    </div>
                    <div @click="selectTemplate(7)" style="cursor: pointer;">
                        <Type7 showGrid="yes" :class="{'template-selected': templateSelected === 7}"></Type7>
                    </div>
                    <div @click="selectTemplate(8)" style="cursor: pointer;">
                        <Type8 showGrid="yes" :class="{'template-selected': templateSelected === 8}"></Type8>
                    </div>
                </div>
            </div>
            <span slot="footer" class="dialog-footer">
                <el-button @click="submitTemplateType">ตกลง</el-button>
                <el-button @click="toggleSelectTemplateModal">ปิด</el-button>
            </span>
        </el-dialog>
    </div>
</template>

<script>

import Uploader from '@/components/Uploader'
import store from '@/store'
import Vue from 'vue'
import Type0 from '@/components/message/richMessageTemplate/Type0'
import Type1 from '@/components/message/richMessageTemplate/Type1'
import Type2 from '@/components/message/richMessageTemplate/Type2'
import Type3 from '@/components/message/richMessageTemplate/Type3'
import Type4 from '@/components/message/richMessageTemplate/Type4'
import Type5 from '@/components/message/richMessageTemplate/Type5'
import Type6 from '@/components/message/richMessageTemplate/Type6'
import Type7 from '@/components/message/richMessageTemplate/Type7'
import Type8 from '@/components/message/richMessageTemplate/Type8'

const TEMPLATE_TYPE_INFO_MAP = {
    0: {
        urls: ['A'],
    },
    1: {
        urls: ['A'],
    },
    2: {
        urls: ['A', 'B'],
    },
    3: {
        urls: ['A', 'B'],
    },
    4: {
        urls: ['A', 'B', 'C'],
    },
    5: {
        urls: ['A', 'B', 'C', 'D'],
    },
    6: {
        urls: ['A', 'B', 'C'],
    },
    7: {
        urls: ['A', 'B', 'C'],
    },
    8: {
        urls: ['A', 'B', 'C', 'D', 'E', 'F'],
    },
}

export default {
    name: 'AdminToolContent',
    components: {
        Uploader,
        Type0,
        Type1,
        Type2,
        Type3,
        Type4,
        Type5,
        Type6,
        Type7,
        Type8,
    },
    props: ['index', 'form', 'bus', 'type'],
    data(){
        return {
            uploadBus: new Vue(),
            isShowSelectTemplateDialog: false,
            templateSelected: -1,
        }
    },
    computed: {
        admin(){
            return store.state.admin
        },
        token(){
            return this.admin.token
        },
        imageDimensionLimit() {
            if(this.templateSelected === 0) {
                return {
                    minWidth: 1040,
                    minHeight: 520,
                    maxWidth: 1040,
                    maxHeight: 2080,
                }
            } else {
                return {
                    minWidth: 1040,
                    minHeight: 1040,
                    maxWidth: 1040,
                    maxHeight: 1040,
                }
            }
        },
    },
    methods: {
        toggleSelectTemplateModal() {
            this.isShowSelectTemplateDialog = !this.isShowSelectTemplateDialog
        },
        templateDialogOpenHandle() {
            this.templateSelected = this.form.template_type
        },
        toRemove(){
            this.$alert(`ต้องการลบ?`, 'แจ้งเตือน', {
                type: 'warning',
                showCancelButton: true,
                confirmButtonText: 'ตกลง',
                cancelButtonText: 'ยกเลิก',
                customClass: 'custom-message-box',
                callback: action => {
                    if(action === 'confirm'){
                        this._remove()
                    }
                }
            })
        },
        _remove(){
            this.uploadBus.$emit('clear')
            this.$emit('remove', this.index)
        },
        _handleUploadDone(data){
            this.form.thumbnail_mode = 'upload'
            if(this.uploadLimit){
                this.form.thumbnails.push(data)
            }else{
                this.form.thumbnail = data
            }
        },
        _handleUploadRemove(data){
            if(this.uploadLimit){
                const indexToRemove= this.form.thumbnails.findIndex((t) => {
                    return t === data
                })
                this.form.thumbnails.splice(indexToRemove, 1)
            }else{
                this.form.thumbnail = ''
            }
        },
        selectTemplate(template) {
            this.templateSelected = template
        },
        submitTemplateType() {
            this.form.template_type = this.templateSelected
            this._clearForm()
            this.toggleSelectTemplateModal()
        },
        _generateFormUrls() {
            const urls = TEMPLATE_TYPE_INFO_MAP[this.form.template_type]['urls']
            this.form.urls = []
            for(const url of urls) {
                this.form.urls.push({
                    label: url,
                    value: '',
                })
            }
        },
        _clearForm() {
            this.form.thumbnail = ''
            this._generateFormUrls()
            this.uploadBus.$emit('clear')
        },
    },
    mounted(){
        this.bus.$on('clear', () => {
            this.uploadBus.$emit('clear')
        })
        this._generateFormUrls()
    }
}
</script>
<style>
    .AdminToolContent .el-dialog__title{font-size: 18px!important;}
    .AdminToolContent .el-dialog__header{padding: 15px 0 0!important;}
    .AdminToolContent .el-dialog__body{padding: 15px!important;}
</style>
<style scoped>
.AdminToolContent {
    position: relative;
    width: 400px;
    border: solid gray 1px;
    padding: 30px 10px 10px 10px;
    border-radius: 5px;
}
.content-remove-button{
    position: absolute;
    top: 4px;
    right: 4px;
}

.content-select-rich{
    display: flex;
}
.content-select-rich .rich-message-item{
    border: solid 2px #fff;
    box-shadow: 0 5px 8px #ddd;
    background-color: rgba(0, 0, 0, 0.05);
    border-radius: 15px;
}
.content-select-rich .rich-message-item.custom {
    min-height: 100px;
    max-height: 420px;
    width: 200px;
}
.content-select-rich .rich-message-item.custom.extend {
    height: 420px;
}
.AdminToolContent .el-dialog__title{

}
.content-select-rich .template-selected {
    /*background-color: rgba(0, 0, 0, 0.15);*/
    box-shadow: 0 5px 8px #777!important;
    transition: all .3s;
    border-color:  #656565;
}
</style>