<template>

    <div class="boxBetPanel">
        <div v-if="bet_list_count" class="BetPanel" :class="{'live': is_live, 'nonlive': !is_live, 'keyboard-padding': is_keyboard_open}">
            <div class="BetPanel-h-tab flex color-blue2">
                <div v-on:click="selectBetMode('single')" :class="{'v-tab-active': betMode === 'single'}">
                    เดิมพันเดียว
                </div>
                <div v-on:click="selectBetMode('combo')" :class="{'v-tab-active': betMode === 'combo'}">
                    คอมโบ
                </div>
            </div>
            <div class="BetPanel-content" v-show="betMode === 'single'">
                <div class="v-header" :class="{'live': is_live, 'nonlive': !is_live}">
                    <div class="flex inner">
                        <div>
                            {{sport_selected}}<span class="nbsp"></span>-<span class="nbsp"></span>{{bet_single.mode | calModeName}}
                        </div>
                        <div>
                            <RefreshButton start_seconds="5"></RefreshButton>
                        </div>
                    </div>
                </div>
                <div class="v-combo-body v-combo-body-one">
                    <div class="v-combo-body-one-top">
                        <div class="v-team_name">
                            <span :class="{ 'color-red': bet_single.mode_value > 0}">{{bet_single.match_home_name}}</span>
                        </div>
                        <p>-vs-</p>
                        <div class="v-team_name">
                            <span :class="{ 'color-red': bet_single.mode_value < 0}">{{bet_single.match_away_name}}</span>
                        </div>
                    </div>
                    <div class="v-league_name">{{bet_single.league_name_en}}</div>
                    <div class="v-league-detail v-round-border" :class="{'live': is_live, 'nonlive': !is_live}"
                         style="font-size: 12px;">
                        <div v-if="bet_single.mode_value !== ''">
<!--                            {{bet_single.mode | calModeName}}<span class="nbsp"></span>:<span class="nbsp"></span>{{bet_single.mode_value | toFixed(2)}}-->
                            <div v-if="bet_single.mode_value !== ''">{{bet_single.mode | calModeName}}&nbsp;:&nbsp;{{displayModeValue(bet_single.mode, bet_single.mode_value, bet_single.favorite, bet_single.choose) | toFixed(2)}}</div>




                        </div>
                        <div class="flex item-center">
                    <span class="color-blue" :class="{'color-red': bet_single.favorite === bet_single.choose}">
                        {{bet_single.choose | calChooseName(bet_single.match_home_name, bet_single.match_away_name, bet_single.mode)}}
                    </span>
                            <span class="nbsp"></span>@





                            <BetPanelOddPrice :myOdd="bet_single.odds" :price="bet_single.odds_changed"/>
                        </div>
                    </div>
                </div>
            </div>
            <div class="BetPanel-content-combo" v-show="betMode === 'combo'">
                <div class="combo-row" v-for="bet in bet_list" :key="bet.match_id">
                    <div class="v-header" :class="{'live': bet.is_live, 'nonlive': !bet.is_live}">
                        <div class="flex item-center-vertical inner">
                            <div class="box-league-name" style="font-size: 10px;">
                                {{bet.league_name_en}}
                            </div>
                            <div @click="cancelBet(bet)">
                                <span class="icon"><i class="fa fa-times"></i></span>
                            </div>
                        </div>
                    </div>
                    <div class="v-combo-body" :class="{'live': bet.is_live, 'nonlive': !bet.is_live}" style="">
                        <div style="text-align: start;" class="color-blue">
                            <span :class="{ 'color-red': bet.mode_value > 0}">{{bet.match_home_name}}</span>
                            <span class="nbsp"></span><span style="color: black;">-vs-</span><span class="nbsp"></span>
                            <span :class="{ 'color-red': bet.mode_value < 0}">{{bet.match_away_name}}</span>
                        </div>
                        <div class="flex" style="text-align: start;">
                            {{bet.mode | calModeName()}}
<!--                            <div v-if="bet.mode_value !== ''"><span class="nbsp"></span>:<span class="nbsp"></span>{{bet.mode_value | toFixed(2)}}</div>-->
                            <div v-if="bet.mode_value !== ''">&nbsp;:&nbsp;{{displayModeValue(bet.mode, bet.mode_value, bet.favorite, bet.choose) | toFixed(2)}}</div>

                        </div>
                        <div style="text-align: start;">
                            <div class="flex">
                            <span class="color-blue" :class="{'color-red': bet.favorite === bet.choose}">
                                {{bet.choose | calChooseName(bet.match_home_name, bet.match_away_name, bet.mode)}}
                            </span>
                                <span class="nbsp"></span>@
                                <BetPanelOddPrice :myOdd="bet.odds" :price="bet.odds_changed"/>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div style="clear: both"></div>
            <div class="BetPanel-bottom">
                <!--<div class="v-separator"></div>-->
                <div class="BetPanel-bottom-detail" style="padding: 4px 12px; color: black;">
                    <div class="BetPanel-bottom-detail-top">
                        <div class="BetPanel-bottom-checkbox"
                             style="margin: 4px 15px 4px 0; font-size: 11px; color: black;">
                            <input v-model="accept_any_odd" type="checkbox" id="acceptAnyOdd">
                            <label for="acceptAnyOdd">Accept <strong>any</strong> odds</label>
                        </div>
                        <div class="BetPanel-bottom-input" style="justify-content: start;">
                            <input @focus="add_keyboard_margin" @blur="remove_keyboard_margin" class="input is-small" v-model="amount" ref="amount_input" type="number" placeholder="จำนวนเงิน"
                                   style="width: 100%;">
                        </div>
                    </div>
                    <div class="BetPanel-bottom-detail-middle">
                    <div class="flex" style="margin-top: 4px;">
                        <div style="font-size: 10px; color: red;">Bet {{min_bet}}<span class="nbsp"></span>-<span class="nbsp"></span>{{max_bet | comma(2)}}




                        </div>
                    </div>
                    <div class="flex" style="margin-top: 4px; justify-content: space-between;">
                        <div>เงินเดิมพัน</div>
                        <div>{{amount | toFixed(2) | comma(2)}}</div>
                    </div>
                    <div class="flex" style="margin-top: 4px; justify-content: space-between;">
                        <div>การชนะ</div>
                        <div class="color-blue">{{win_amount | toFixed(2) | comma(2)}}</div>
                    </div>
                   </div>
                    <div class="flex box-button" style="margin: 4px 0; justify-content: space-around;">
                        <button class="button is-small" v-on:click="goBet" style="width: 50%;">วางเดิมพัน</button>
                        <button class="button is-small" v-on:click="cancelAll" style="width: 50%;">ยกเลิก</button>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>
<script>

    import _find from 'lodash/find'
    import _isEmpty from 'lodash/isEmpty'
    import store from '@/store'
    import RefreshButton from './RefreshButton.vue'
    import BetPanelOddPrice from './BetPanelOddPrice.vue'
    import BigNumber from 'bignumber.js'
    import querystring from 'query-string'
    import debounce from '@/debounce'
    import {mixin as VueTimers} from 'vue-timers'
    import axiosWrapper from '@/axiosWrapper'
    import UserMixin from '@/mixins/UserMixin'

    export default {
        name: "BetPanel",
        mixins: [
            VueTimers,
            UserMixin,
        ],
        components: {
            RefreshButton,
            BetPanelOddPrice
        },
        timers: {
            startBet: {
                time: 2000
            }
        },
        props: [
            'modal_bus'
        ],
        data(){
            return {
                betMode: 'single',
                // amount: 20,
                bet_message_status: '',
                bet_message: '',
                bet_messages: [],
                bet_success_data: null,
                bet_timeout: 30,
                is_keyboard_open: false
            }
        },
        computed: {
            user: function () {
                return store.state.pes_profile;
            },
            odd_style: function () {
                return store.state.football.odd_style;
            },
            sport_selected: function () {
                return store.state.football.sport_selected;
            },
            bet_list_count: function () {
                return store.state.football.bet_list.length;
            },
            bet_single: function () {
                return store.state.football.bet_list[store.state.football.bet_list.length - 1];
            },
            bet_list: function () {
                return store.state.football.bet_list;
            },
            accept_any_odd: {
                get: function () {
                    return store.state.football.accept_any_odd;
                },
                set: function (newVal) {
                    store.commit('football/updateAcceptAnyOdd', newVal);
                }
            },
            is_live: function () {
                let found = _find(this.bet_list, function (o) {
                    return o.is_live === 1;
                });
                if (found) {
                    return 1;
                } else {
                    return 0;
                }
            },
            min_bet: function () {

                let min_bet = 0;

                if (this.betMode === 'single') {
                    min_bet = 20;
                } else if (this.betMode === 'combo') {
                    min_bet = 10;
                }

                return min_bet;
            },
            max_bet: function () {

                let max_bet = 9999;

                if (this.bet_list.length > 0) {
                    if (this.betMode === 'single') {
                        max_bet = this.getMaxBet(this.bet_list[0]);
                    } else if (this.betMode === 'combo') {

                        let sum_maxbet = 0;

                        for (let i = 0; i < this.bet_list.length; i++) {

                            let bet = this.bet_list[i];
                            sum_maxbet += this.getMaxBet(bet);
                        }

                        let count_bet = this.bet_list.length + 0.5;
                        max_bet = sum_maxbet / count_bet;
                    }
                } else {
                    max_bet = 9999;
                }

                return max_bet;
            },
            win_amount: function () {

                let win_amount = 0;

                if (this.betMode === 'single') {
                    win_amount = this.calReturn(this.bet_list[0]);
                } else if (this.betMode === 'combo') {
                    win_amount = this.calReturnCombo();
                }

                return win_amount;
            },
            amount: {
                get(){
                    return store.state.football.amount
                },
                set(value){
                    store.commit('football/updateAmount', value)
                }
            },
            is_member() {
                return store.state.is_member
            }
        },
        watch: {
            bet_list_count(newVal){
                if (newVal > 1) {
                    this.betMode = 'combo';
                } else {
                    this.betMode = 'single';
                }

                if (newVal > 0) {
                    store.commit('football/updateSecondsInterval', 5000);
                } else {
                    store.commit('football/updateSecondsInterval', 10000);
                }
            },
            amount: debounce(function (newVal) {

                newVal = parseFloat(newVal);
                this.amount = newVal;

                if (newVal < this.min_bet) {
                    this.amount = this.min_bet;
                } else if (newVal > this.max_bet) {
                    this.amount = this.max_bet.toFixed(2);
                } else if (isNaN(newVal)) {
                    this.amount = this.min_bet;
                }
            }, 1400),
            min_bet(newVal){
                if (newVal > this.amount) {
                    this.amount = newVal;
                }
            },
            max_bet(newVal){
                if (newVal < this.amount) {
                    this.amount = newVal;
                }
            },
            betMode(newVal){
                if (newVal === 'single') {
                    store.commit('football/updateEuOnly', false);
                } else if (newVal === 'combo') {
                    store.commit('football/changeOddStyle', 'EU');
                    store.commit('football/updateEuOnly', true);
                }
            },
            is_keyboard_open: debounce(function(new_val){
                if(new_val){
                    this.$el.scrollTo(0, this.$el.scrollHeight);
                }
            }, 400)
        },
        methods: {
            selectBetMode: function (mode) {

                if (mode === 'combo') {
                    if (this.bet_list_count > 1) {
                        this.betMode = mode;
                    }
                } else {
                    this.betMode = mode;
                }
            },
            cancelBet: function (bet) {
                store.dispatch('football/cancelBet', bet);
            },
            cancelAll: function () {
                store.dispatch('football/cancelBetAll');
            },
            getMaxBet: function (bet) {

                let totalX3 = bet.odds_x3;

                if (totalX3 === 300) { // 22.06
                    return 6620;
                } else if (totalX3 === 500) { // 22.6
                    return 11300;
                } else if (totalX3 === 1000) { // 22.599
                    return 22599;
                } else if (totalX3 === 1500) { // 22.37
                    return 33563;
                } else if (totalX3 === 2000) { // 22.599
                    return 45198;
                } else if (totalX3 === 3000) { // 22.599
                    return 67797;
                } else {
                    if (totalX3) {
                        return totalX3 * 22;
                    } else {
                        return 50 * 22;
                    }
                }
            },
            calReturn: function (bet) {

                let amount = new BigNumber(this.amount);
                let odds = new BigNumber(this.$options.filters.priceByCurrency(bet.odds));
                let two = new BigNumber(2);

                let p_return = 0;

                if (bet.mode === '1X2') {
                    p_return = amount.multipliedBy(odds);
                } else {
                    if (this.odd_style === 'MY') {
                        if (odds > 0) {
                            p_return = amount.multipliedBy(odds).plus(amount);
                        } else if (odds < 0) {
                            p_return = amount.multipliedBy(two);
                        }
                    } else if (this.odd_style === 'HK') {
                        p_return = amount.multipliedBy(odds).plus(amount);
                    } else if (this.odd_style === 'EU') {
                        p_return = amount.multipliedBy(odds);
                    }
                }

                p_return = p_return.toFixed(2);

                return p_return;
            },
            calReturnCombo: function () {

                let p_amount_win_total;

                let p_amount_cur = new BigNumber(this.amount);
                let p_total_odds = new BigNumber(1);

                for (let i = 0; i < this.bet_list.length; i++) {

                    let bet = this.bet_list[i];

                    let row_eu_odd = new BigNumber(this.$options.filters.priceByCurrency(bet.odds));
                    p_total_odds = row_eu_odd.multipliedBy(p_total_odds);
                }

                p_amount_win_total = p_total_odds.multipliedBy(p_amount_cur);
                return p_amount_win_total.toFixed(2);
            },
            goBet: async function () {

                if(!this.is_member) {
                    this.notifyToSignUp('เพื่อเล่น')
                    return
                }

                let max_payment = this.user.sports.football.max_payment || 100000;
                let max_match = this.user.sports.football.max_match || 25;

                if (parseFloat(this.win_amount) > parseFloat(max_payment)) {
                    this.bet_message_status = 'reach_max_payment'
                    this.bet_message = `Reach max payment, your max payment is ${this.$options.filters.comma(this.$options.filters.toFixed(max_payment))}`
                    this.$modal.show('bet-message', {
                        bet_message: this.bet_message,
                        bet_messages: this.bet_messages
                    });
                } else if (parseFloat(this.bet_list.length) > parseFloat(max_match)) {
                    this.bet_message_status = 'reach_max_match'
                    this.bet_message = `Reach max combo, your max combo is ${max_match}`
                    this.$modal.show('bet-message', {
                        bet_message: this.bet_message,
                        bet_messages: this.bet_messages
                    });
                } else {
                    this.$modal.show('please-wait');
                    this.$timer.stop('startBet');
                    this.$timer.start('startBet');
                }
            },
            startBet: async function () {

                let payload = await this.generatePayload();
                const res = await axiosWrapper({
                    method: 'post',
                    url: `${this.envDomains.VUE_APP_123API}/proxy/ticket`,
                    data: querystring.stringify(payload)
                });
                if (res.status === 400) {

                    this.bet_message_status = 'bet_code_400';

                    if (res.data.status === 'invalid_bet') {

                        for (let item of res.data.data.items) {

                            if (item.validate.desc) {
                                this.bet_message = item.validate.desc;
                            }

                            if (item.validate.status === 'odd_change') {
                                let changedOdd = await store.dispatch('football/toUpdateBet', {
                                    match_id: item.match_id,
                                    odd_info: item.validate.data
                                });
                                let oldPrice = this.$options.filters.toFixed(this.$options.filters.priceByCurrency(changedOdd.oldVal), 3);
                                let newPrice = this.$options.filters.toFixed(this.$options.filters.priceByCurrency(changedOdd.newVal), 3);
                                this.bet_messages.push(changedOdd.bet.match_home_name + '-vs-' + changedOdd.bet.match_away_name + ' ' + oldPrice + ' to ' + newPrice);
                            }
                        }
                    } else {
                        this.bet_message = res.data.desc;
                    }
                } else if (res.error) {

                    this.bet_message_status = 'bet_api_error';

                    // alert(res.desc);
                    this.bet_message = res.desc;
                } else {
                    const data = res.data;
                    if (data.code === -3 || data.code === -2 || data.code === -1) {//other error
                        // alert(data.msg);
                        if (data.msg === 'Failed to authenticate token.') {
                            this.bet_message = data.msg + ' Please sign in again.';
                        } else {
                            this.bet_message = data.msg;
                        }
                    } else if (data.code === 0) {//bet error

                        this.bet_message_status = 'bet_error';

                        if (!_isEmpty(data.msg)) {

                            // alert(data.msg);//odd change
                            this.bet_message = data.msg;

                            for (let d in data.data) {
                                if (data.data[d].code === 0) {
                                    let changedOdd = await store.dispatch('football/toUpdateBet', {
                                        match_id: d,
                                        odd_info: data.data[d].data.odds_change
                                    });
                                    console.log('changedOdd', changedOdd);
                                    let oldPrice = this.$options.filters.toFixed(this.$options.filters.priceByCurrency(changedOdd.oldVal), 3);
                                    let newPrice = this.$options.filters.toFixed(this.$options.filters.priceByCurrency(changedOdd.newVal), 3);
                                    this.bet_messages.push(changedOdd.bet.match_home_name + '-vs-' + changedOdd.bet.match_away_name + ' ' + oldPrice + ' to ' + newPrice);
                                } else if (data.data[d].code === -1) {
                                    console.log('missing odd');
                                } else if (data.data[d].code === -2) {
                                    console.log('time out');
                                }
                            }
                        }
                    } else if (data.code === 1) {//bet success

                        this.bet_message_status = 'bet_success';
                        this.bet_success_data = data.data;

                        // alert(data.msg);
                        this.bet_message = data.msg;
                        // store.commit('removeBetAll');

                        this.requestUserReload()
                    }
                }
                this.$modal.hide('please-wait');
                if (this.bet_message) {
                    this.$modal.show('bet-message', {
                        bet_message_status: this.bet_message_status,
                        bet_message: this.bet_message,
                        bet_messages: this.bet_messages,
                        bet_success_data: this.bet_success_data,
                        amount: this.amount,
                        win_amount: this.win_amount,
                    });
                }
            },
            requestUserReload() {
                store.commit('updateNeedUserReloadWithForceRefreshBalance', true)
            },
            generatePayload: async function () {

                let bet_list = {};

                for (let i = 0; i < this.bet_list.length; i++) {

                    let bet = Object.assign({}, this.bet_list[i]);
                    // let bet = this.bet_list[i]//test

                    // bet.mode_value_raw = bet.mode_value;
                    // bet.odds_raw = bet.odds;

                    // bet.mode_value = this.$options.filters.changeHDP(bet.mode_value);
                    // bet.odds = this.$options.filters.toFixed(this.$options.filters.priceByCurrency(bet.odds), 3);
                    bet.team_home_name = bet.match_home_name;
                    bet.team_away_name = bet.match_away_name;
                    // bet.odds += 0.1;//test
                    bet_list[bet.match_id] = bet;
                }

                return {
                    amount: this.amount,
                    token: this.user.token,
                    bet_list: JSON.stringify(bet_list),
                    currency: 'THB',
                    is_live: this.is_live,
                    sport: this.sport_selected,
                };
            },
            closeBetMessage: async function () {

                if(this.bet_message_status === 'bet_success'){
                    store.commit('football/removeBetAll');
                }

                this.$modal.hide('bet-message');
                if (this.bet_message_status === 'reach_max_payment') {
                    this.amount = this.min_bet;
                }
                this.bet_message_status = '';
                this.bet_message = '';
                this.bet_messages = [];
            },
            cancelWaitBet: function () {
                this.$timer.stop('startBet');
                this.$modal.hide('please-wait');
            },
            modal_opened: function(){
                store.commit('football/update_modal_is_open', true);
            },
            modal_closed: function(){
                store.commit('football/update_modal_is_open', false);
            },
            add_keyboard_margin: function(){
                this.is_keyboard_open = true;
            },
            remove_keyboard_margin: function(){
                this.is_keyboard_open = false;
            },
            displayModeValue: function(mode, mode_value, favorite, choose){
                if(mode === 'HDP'){

                    if(mode_value){

                        if(favorite === choose){
                            return Math.abs(mode_value)*-1;
                        }else{
                            return Math.abs(mode_value);
                        }
                    }else{
                        return 0;
                    }
                }else{
                    return mode_value;
                }
            }
        },
        mounted() {
            const _this = this;
            this.modal_bus.$on('cancelWaitBet', function(){
                _this.cancelWaitBet();
            });
            this.modal_bus.$on('closeBetMessage', function(){
                _this.closeBetMessage();
            });

            // this.$modal.show('please-wait');
        }
    }
</script>
<style>
    .boxBetPanel{
        max-height: 400px; overflow-y: auto;
    }

</style>
<style scoped>
    .BetPanel-h-tab{
        font-size: 0.85rem;
        justify-content: space-around;
        line-height: 25px;
        background-color: #fff;
        color: #aaa;
        display: flex;
        text-align: center;
    }
    .BetPanel-h-tab div{flex: 1 auto}
    .BetPanel-content {
        text-align: center;
    }
    .v-tab-active{
        /*background-color: #363636;*/
        border-bottom: solid 2px #363636;
        color: #363636;
    }
    .BetPanel {
        color: white;
    }

    .BetPanel.live {
        background-color: #ffddcc;
    }

    .BetPanel.nonlive {
        background-color: #d6e8fe;
    }

    .v-header {
        font-size: 12px;
        color: black;
    }

    .v-header.live {
        border-bottom: solid 1px #df7845;
        background-color: #ffa375;
    }

    .v-header.nonlive {
        border-bottom: solid 1px #b8d8fe;
        background-color: #c5dfff;
    }

    .v-header .inner {
        justify-content: space-between;
        padding: 4px 8px;
    }

    .v-team_name {
        font-size: 14px;
        margin-top: 2px;
        color: #005cce;
    }

    .v-league_name {
        font-size: 12px;
        margin-top: 2px;
        color: #808080;
    }

    .v-round-border {
        border-radius: 6px;
        color: black;
        padding: 4px 8px;
        margin: 4px 12px;
    }

    .v-round-border.live {
        border: 0;
        background-color: rgba(0,0,0,0.10);
    }

    .v-round-border.nonlive {
        border: solid 1px #b8d8fe;
        background-color: #ecf5ff;
    }


    .v-separator {
        border-top: solid 1px lightgray;
        width: 100%;
    }

    .v-combo-body {
        padding: 4px 10px 4px 10px;
        font-size: 0.87rem;
        color: black;
    }

    .v-combo-body.live {
        background-color: #ffddcc;
    }

    .v-combo-body.nonlive {
        background-color: #d6e8fe;
    }

    .v-modal-content-wrapper {
        padding: 14px 18px;
        border: solid 2px black;
        overflow-y: auto;
        min-height: 180px;
    }

    .v-modal-body {
        word-break: break-all;
        flex: 1;
        overflow-y: auto;
    }

    .v-modal-footer {
        height: 24px;
        justify-content: flex-end;
        align-items: flex-end;
    }

    .v-blink-blink {
        animation: v-blinker 1s linear infinite;
    }
.BetPanel-bottom-detail-top{
    display: flex;
    padding: 0 30px;
}
.BetPanel-bottom-detail-middle{
    display: block;
    padding: 0 30px;
    font-size: 0.83em;
}
.box-button button{
    margin:  0 5px;
    background-color: #fff;
    border-color: #dbdbdb;
    border-width: 1px;
    font-size: 1rem;
    color: #363636;
        border-radius: 290486px;
    cursor: pointer;
    justify-content: center;
    padding-bottom: calc(.5em - 1px);
    padding-left: 1em;
    padding-right: 1em;
    padding-top: calc(.5em - 1px);
    text-align: center;
}
.box-button button:first-child{
    background-color: #3273dc;
    border-color: transparent;
    color: #fff;
}
.BetPanel-bottom-input input{border-radius: 6px;}
    @keyframes v-blinker {
        50% {
            opacity: 0;
        }
    }

    .keyboard-padding {
        height: auto;
        padding-bottom: 150px;
    }

</style>