export default {
    namespaced: true,
    state: {
        baccaratTableList: [],
        baccaratGameList: [],
        currentTable: null,
        currentGame: null,
        bet_limit_config: [],
        baccarat_bet_limit: [],
        currentBetLimitConfig: null,
    },
    mutations: {
        updateBaccaratTableList(state, data) {
            state.baccaratTableList = data
        },
        updateBaccaratTable(state, data) {
            state.baccaratTableList.splice(data.i, 1, data.data)
        },
        updateBaccaratGameList(state, data) {
            state.baccaratGameList = data
        },
        updateBaccaratGame(state, data) {
            state.baccaratGameList.splice(data.i, 1, data.data)
        },
        updateCurrentTable(state, data) {
            state.currentTable = data
        },
        updateCurrentGame(state, data) {
            state.currentGame = data
        },
        updateBetLimitConfig(state, data) {
            state.bet_limit_config = data
        },
        updateBaccaratBetLimit(state, data) {
            state.baccarat_bet_limit = data
        },
        updateCurrentBetLimitConfig(state, data) {
            state.currentBetLimitConfig = data
        },
    },
    actions: {
        
    },
}