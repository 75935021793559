<template>
    <div class="AdminTools">
        <div class="v-content-inner">
            <div class="v-toolbar">
                <div class="toolbar-item">
                    
                </div>
                <div class="toolbar-item">
                    
                </div>
            </div>
            <div class="v-list">
                <div v-if="LIFF_GAME_CHAT_BOT_URL" style="display: flex;">
                    <div>
                        <div style="font-size: 14px;">
                            ลิ้งค์สำหรับบอทเกม:
                        </div>
                        <div style="font-size: 12px;">
                            คัดลอกลิ้งค์ไปเปิดในช่องแชท LINE
                        </div>
                    </div>
                    <div style="margin-left: 10px;">
                        <el-link type="primary" :underline="false">
                            <span>{{LIFF_GAME_CHAT_BOT_URL}}</span>    
                        </el-link> 
                        <el-button @click="copyToClipboard(LIFF_GAME_CHAT_BOT_URL)" icon="el-icon-copy-document" type="text" style="margin-left: 10px;"></el-button>
                    </div>
                </div>
            </div>
            <div class="v-toolbar-bottom">
                
            </div>
        </div>
    </div>
</template>
<script>

import store from '@/store'
import axiosWrapper from '@/axiosWrapper'
import copy from 'copy-to-clipboard'

export default {
    name: 'AdminTools',
    data() {
        return {
            resource: null
        }
    },
    computed: {
        admin() {
            return store.state.admin
        },
        LIFF_GAME_CHAT_BOT_URL() {
            if(!this.resource) {
                return ''
            }
            return this.resource['LIFF_GAME_CHAT_BOT_URL']
        },
    },
    methods: {
        async _loadAdminResource() {
            const res = await axiosWrapper({
                method: 'POST',
                url: `${this.envDomains.VUE_APP_LIFF_API}/api/channel/get-liff-link`,
                data: {
                    channel: this.admin.channel,
                    line: this.admin.line_oa,
                    get_all: 'yes',
                }
            })
            if(res.status !== 200) {
                return
            }
            this.resource = res.data
        },
        copyToClipboard(text) {
            copy(text)
            this.$message({
                message: 'คัดลอกแล้ว',
                type: 'success'
            })
        },
    },
    mounted() {
        this._loadAdminResource()
    },
}

</script>