<template>

    <div class="box-advertise-games box-advertise-game-bottom" :class="{'OverTopHome':is_over_top}">
        <VideoGuide></VideoGuide>
        <div class="box-jackpotSpin">
            <div class="box-jackpotSpin-info JP-grand" :class="{'hide': showCaseModal[0]}">
                <div class="btn-close-popup" @click="hideCaseModal(0)"></div>
                <div class="topic-jp"></div>
                <div class="title-jp">
                    <strong>{{caseModal[0].case_name}}</strong>
                </div>
                <div class="value-jp">
                    <span>{{caseModal[0].bonus_current}}</span>
                </div>
                <div @click="clickToSpin(caseModal[0].id)" class="box-item-spin" :style="{'background-image' : 'url('+caseModal[0].choose_case+')'}"></div>
            </div>
            <div class="box-jackpotSpin-info JP-major" :class="{'hide': showCaseModal[1]}">
                <div class="btn-close-popup" @click="hideCaseModal(1)"></div>
                <div class="topic-jp"></div>
                <div class="title-jp">
                    <strong>{{caseModal[1].case_name}}</strong>
                </div>
                <div class="value-jp">
                    <span>{{caseModal[1].bonus_current}}</span>
                </div>
                <div @click="clickToSpin(caseModal[1].id)" class="box-item-spin" :style="{'background-image' : 'url('+caseModal[1].choose_case+')'}"></div>
            </div>
            <div class="box-jackpotSpin-info JP-mini" :class="{'hide': showCaseModal[3]}">
                <div class="btn-close-popup" @click="hideCaseModal(3)"></div>
                <div class="topic-jp"></div>
                <div class="title-jp">
                    <strong>{{caseModal[3].case_name}}</strong>
                </div>
                <div class="value-jp">
                    <span>{{caseModal[3].bonus_current}}</span>
                </div>
                <div @click="clickToSpin(caseModal[3].id)" class="box-item-spin" :style="{'background-image' : 'url('+caseModal[3].choose_case+')'}"></div>
            </div>
        </div>
        <div  class="box-jackpotSpin box-jackpotSlot" v-if="false"  :class="showJackplot?'':'hide'">
            <div class="box-jackpotSpin-info slot-grand" :class="showJackplot?'':'hide'">
                <div class="btn-close-popup" @click="hideJackplot();"></div>
                <div class="topic-jp"></div>
                <div class="value-jp">
                    <span id="jackplot-modal-value">1,821,501.989</span>
                </div>
                <div @click="toGameJackPlot()" class="box-item-spin" :style="{'background-image':'url('+jackpot_game_img+')'}"></div>
            </div>
        </div>
        <div @click="togglePanelHome">
            <ChatWithAdmin :showChatWithAdmin="showChatWithAdminNumber"></ChatWithAdmin>
        </div>

    </div>


</template>
<script>
    import store from '@/store'
    import LayoutMixin from '@/mixins/Layout'
    import $ from 'jquery'
    import VideoGuide from '@/components/game/VideoGuide'
    import ChatWithAdmin from '@/components/user/ChatWithAdmin'

    window.jQuery = $;
    window.$ = $;
    import ReconnectingWebSocket from 'reconnecting-websocket'
    var ws;
    var ALL_CASE_LOCAL;
     var all_case=window.localStorage.getItem('all_case');
    if(all_case){
        ALL_CASE_LOCAL=JSON.parse(all_case);

    }
    var SELF_VUE;
    export default {
        name: 'FloatButtomRightPanel',
        components: {
            VideoGuide,
            ChatWithAdmin,
        },
        props: ['callChatWithAdmin'],
        mixins: [
            LayoutMixin,
        ],
        watch :{
            
            callChatWithAdmin: function (oldVal,newVal){
                if(newVal==1 && oldVal==0){
                    this.showChatWithAdminNumber=newVal;
                    let self = this;
                    setTimeout(()=>{
                        self.showChatWithAdminNumber=0;
                    },100);
                }
               
            }
        },
        data() {
            return {
                showChatWithAdminNumber:0,
                is_over_top:false,
                showJackplot:false,
                jackpot_game_id :null,
                jackpot_game_img:null,
                showCaseModal:[
                    true,true,true,true,true
                ],
                caseModal:[
                   {},{},{},{},{}
                ],
            }
        },
        destroyed: function() {
    //  console.log('my-component destroyed');
            ws.close();
        },
        computed: {

            user_profile() {
                return store.state.user_profile
            },
            pes_profile() {
                return store.state.pes_profile
            },
            is_member() {
                return store.state.is_member
            },
            appImageProfile(){
                return store.state.appInfo.app_image_profile
            },
        },
        methods: {
            togglePanelHome() {
                if(this.is_over_top){
                    this.is_over_top = false;
                }else{
                    this.is_over_top = true;
                }
            },
            initWs() {
                    var hostname =`${this.envDomains.VUE_APP_PES_WS_BASE_URL}`;
                    var token = this.token;
                    ws = new ReconnectingWebSocket(hostname, token, {debug: false, reconnectInterval: 3000});
                    ws.onmessage = function (msg) {
                        var response = JSON.parse(msg.data);
                        manageWSContent(response, 'all');

                    }
                    ws.onopen = function () {
                        var data = {'type': 'subscribe', list: ['chat', 'spiner']};
                        wsSendData(data);
                    };

                },
            playGame(game) {
                if(game === 'football') {
                    this.current_game = game
                    this.openDrawer()
                    return
                }
            },
            getJackPot(){
                var self = this;
                $.get(`${this.envDomains.VUE_APP_PES_BASE_URL}/api/game/v0/service/pragmatic/jackpots.php`,function(response){
                    var thb_rate=(response.data.THB_rate);
                    
                    var data_list = response.data.data;
            var hide_bonus_slot_noti=self.$cookies.get('hide_bonus_slot_noti');
            var game_jp_list ={};
            if(!hide_bonus_slot_noti){
                self.showJackplot=true;
                var number = Math.floor(Math.random() * data_list.length);
                for(let i=0;i<data_list.length;i++){
                    let games = data_list[i].games;
                    let amount = data_list[i].amount*thb_rate;
                    if(!(games in game_jp_list)){
                        game_jp_list[games]=[];
                    }
                    game_jp_list[games].push(amount);
                }
                var targetData = data_list[number];
                var amount = targetData.amount*thb_rate;
                var games = targetData.games;
                var game_list=games.split(',');

                var number2  =Math.floor(Math.random() * game_list.length);
                var game_name = game_list[number2];
                var game_name_jp = game_name.replace('_jp','');
                self.jackpot_game_id =game_name;
                var img_game ='https://asia.pragmaticplay.net/game_pic/rec/325/'+game_name_jp+'.png';
                self.jackpot_game_img =img_game;

                // if(self.pes_profile){
                //     $('div.box-noti-slot').children('div.content-noti-slot').children('a').attr('href','/game/casinoonline/sa_sexy_redirect.php?mode=mobile&by=&service=pragmatic&code='+game_name).attr('target','_blank');
                // }
                updateBonusNumber('#jackplot-modal-value',amount);


                // $('div.box-noti-slot').children('div.content-noti-slot').children('div.thumbnail-slot').children('img').attr('src',img_game);

                }
                });

            },
            hideJackplot(){
                this.showJackplot=false;
                var date = new Date();
            date.setTime(date.getTime() + (15 * 60 * 1000));
            this.$cookies.set('hide_bonus_slot_noti', 1, {expires: date, path: '/'});
            },
            hideCaseModal(bonus_level){ 
                this.$set(this.showCaseModal, bonus_level, true);
                // this.showCaseModal[bonus_level]=true;

                var date = new Date();
            date.setTime(date.getTime() + (15 * 60 * 1000));
            this.$cookies.set('hide_bonus_unbox_noti_'+bonus_level, 1, {expires: date, path: '/'});
            },
            toGameJackPlot(){
                window.open(`${this.envDomains.VUE_APP_PES_BASE_URL}/game/casinoonline/sa_sexy_redirect.php?mode=mobile&by=&service=pragmatic&code=`+this.jackpot_game_id+'&token='+this.pes_profile.token+(this.appImageProfile?('&logo_img='+this.appImageProfile):'') ,'_blank');
            },
            clickToSpin(id){
                window.open('/unbox-play/'+id,'_blank');
            },
            
        },
        async mounted() {
            // this.getJackPot();
            // var self = this;
            // setInterval(() => {
            //     self.getJackPot();
            // }, 1000*60);
            this.initWs();
            SELF_VUE = this;
        },
    }
    function updateBonusNumber(id,value){

$(id).each(function() {
  var self = $(this),
    countTo = value;

  $({
    countNum: self.text().replace(/,/g,'')
  }).animate({
      countNum: countTo
    },

    {
      duration: 500,
      easing: 'linear',
      step: function() {
    
        self.text(commaSeparateNumber(parseFloat(this.countNum).toFixed(3).replace(/(\d)(?=(\d{3})+\.)/g, '$1,')));
        
      },
      complete: function() {
        self.text(commaSeparateNumber(parseFloat(this.countNum).toFixed(3).replace(/(\d)(?=(\d{3})+\.)/g, '$1,')));
        // alert('finished');
      }
    }
  );

});
}
function commaSeparateNumber(val) {
  while (/(\d+)(\d{3})/.test(val.toString())) {
    val = val.toString().replace(/(\d+)(\d{3})/, '$1' + ',' + '$2');
  }
  return val;
}
function  wsSendData(_data) {
     ws.send(JSON.stringify(_data));
}
function  manageWSContent(response,page){
                    var type = response.type;
                    var data = response.data;
                    if(page=='all') {
                        if (type == 'chat') {
                            var init1 = response.init;
                            init1;
                        }else if(type=='balance'){
                            data;
                        }else if(type=='unbox-packet'){
                            data;
                        
                        } else if (type == 'bonus') {
                            for (var i = 0; i < data.length; i++) {
                                var b = data[i];
                                if (b != null) {
                                    // var bonus_config_id = b.gbc_ref_id;
                                    // var bonus_current = b.bonus_current;
                                    // var bonus_status = b.bonus_status;
                                    // var bonus_level = b.bonus_level;
                                    // var bonus_start = b.bonus_start;
                                    // var bonus_start = b.bonus_start;
                                    manageShowUnboxBonus(b);
                                
                                    
                                }

                            }
                        } else if (type == 'bonus_reward') {
                            data;
                        } else if (type == 'item_recent') {
                            type;
                        }else if(type=='noti_msg') {
                            type;
                        }else if(type=='lotto_noti1') {

                            type;

                        }else if(type=='lotto_noti2'){
                            type;
                        } else if (response.code == 1) {
                            type;
                        }
                    }else if(page=='spiner'){
                        page;

                    }else if(page=='mini_game'){
                        if (type == 'chat') {
                            var init2 = response.init;
                            init2;
                            // manageChat(data, init);
                        }else if(type=='last_result'){
                            type;
                        }else if(type=='noti_msg'){
                            type;
                        }
                    }else{
                        type;
                    }

                } 
                const target_percent = 95;
const target_waiting_time = 5;
var target_waiting_status=true;
function manageShowUnboxBonus(b){
    var bonus_config_id = b.gbc_ref_id;
    var bonus_current = b.bonus_current;
    // var bonus_status = b.bonus_status;
    var bonus_level = b.bonus_level;
    // var bonus_start = b.bonus_start;
    var bonus_end = b.bonus_end;
    var percent=(bonus_current/bonus_end)*100;
    // console.log('hide_bonus_unbox_noti_'+bonus_level);
    // console.log(SELF_VUE.$cookies.get('hide_bonus_unbox_noti_'+bonus_level));
    if(percent>=target_percent && !SELF_VUE.$cookies.get('hide_bonus_unbox_noti_'+bonus_level)){

        
        var case_all_list=ALL_CASE_LOCAL[0];
        if(ALL_CASE_LOCAL){
        var case_interest_list =[];
        for(var i=0;i<case_all_list.length;i++){
            var c = case_all_list[i];
            if(c.bonus_config_id==bonus_config_id){
                case_interest_list.push(c);
            }
        }
        if(case_interest_list.length>0){
            // SELF_VUE.showCaseModal[bonus_level]=false;
           
            SELF_VUE.caseModal[bonus_level] ={

            }
            

            
            if(target_waiting_status){
                target_waiting_status=false;
                var rand=Math.floor(Math.random() * case_interest_list.length);
                var case_selected = case_interest_list[rand];
                var name_case = case_selected.name_case;
                var choose_case = case_selected.choose_case;
                var id =case_selected.id;
                
                SELF_VUE.$set(SELF_VUE.showCaseModal, bonus_level, false);
                SELF_VUE.caseModal[bonus_level] ={
                    caes_name : name_case,
                    bonus_current : numberWithCommasNormal(bonus_current),
                    bonus_percent : percent,
                    choose_case: choose_case,
                    id:id,
                }
                
                setTimeout(function(){
                    target_waiting_status=true;
                },target_waiting_time*1000);
            }

        }
        }



    }
}
function numberWithCommasNormal(x) {

    var parts = x.toString().split(".");
    parts[0] = parts[0].replace(/\B(?=(\d{3})+(?!\d))/g, ",");
    //console.log(x);
    //console.log(parts.join("."));
    return parts.join(".");


}
</script>
<style>
    .box-advertise-game-bottom {
        position: absolute;
        display: flex;
        flex-direction: column;
        align-items: flex-end;
        justify-content: flex-end;
        width: auto!important;
        top: auto!important;
        bottom: 10px;
        z-index: 300!important;
        /*bottom: 65px!important;*/
    }
    .box-advertise-game-bottom .list-user-play{
        position: relative;
        width: 43px;
        bottom: 10px;
    }
    .box-advertise-game-bottom .box-jackpotSpin{
        position: relative;
        bottom: auto;
    }
    .box-advertise-game-bottom.OverTopHome{
        z-index: 128!important;
    }
</style>
<style scoped>
    .item-carousel {
        height: 65px;
        background-size: cover;
        background-repeat: no-repeat;
    }
    .item-carousel.first {
        /*background-image: url("https://chat.liffline.com/images/carousel/2.jpg");*/
        background-image: url(../assets/images/icon/set-icon/mini-icon-bet.png);
    }
    .item-carousel.second {
        /*background-image: url("https://chat.liffline.com/images/carousel/2.jpg");*/
        background-image: url(../assets/images/icon/set-icon/mini-icon-casino.png);
    }
    .item-carousel.third {
        /*background-image: url("https://chat.liffline.com/images/carousel/3.jpg");*/
        background-image: url(../assets/images/icon/set-icon/mini-icon-p2p.png);
    }
    .item-carousel.four {
        /*background-image: url("https://chat.liffline.com/images/carousel/3.jpg");*/
        background-image: url(../assets/images/icon/set-icon/mini-icon-shooting.png);
    }
    .item-carousel.five {
        background-image: url(../assets/images/icon/set-icon/mini-icon-slot.png);
    }
    .item-carousel.six {
        background-image: url(../assets/images/icon/set-icon/mini-icon-unbox.png);
    }
    .box-advertise-games{
        position: absolute;
        z-index: 112;
        width: 65px;
        top: 110px;
        right: 10px;
    }

    .VueCarousel-dot{

        width: 5px !important;
        height: 3px !important;
    }
    @media (orientation: landscape) {
        .box-advertise-games{
            top: 55px;
            right: -60px;
        }

    }
</style>