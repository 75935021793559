<template>
    <div class="ImageTitleDescription">
        <div class="v-image">
            <el-avatar :size="50" :src="image"></el-avatar>
        </div>
        <div class="v-text">
            <div class="v-title">
                <strong>{{title}}</strong>
            </div>
            <div class="v-description">
                {{description}}
            </div>
        </div>
    </div>
</template>
<script>
export default {
    name: 'ImageTitleDescription',
    props: ['image', 'title', 'description'],
}
</script>
<style scoped>
.ImageTitleDescription {
    display: flex;
    align-items: center;
}
.ImageTitleDescription .v-image{
    display: flex;
    flex-basis: 50;
}
.ImageTitleDescription .v-text {
    display: flex;
    flex-direction: column;
    padding: 10px;
    text-align: left;
}
.ImageTitleDescription .v-title {
    font-size: 20px;
    color: black;
}
.ImageTitleDescription .v-description {
    font-size: 14px;
    color: #365f96;
}
</style>