<template>
    <div class="ChatListIndex">
            <MenuBar></MenuBar>
        <div class="box-img">
            <h4>ไลฟ์สด</h4>
        </div>
        <div class="content-page">
            <div v-if="false" class="cat-ChatListIndex">
                <carousel
                        :per-page="3.8"
                        :mouseDrag="true"
                        :spacePadding="5"
                        :navigationEnabled="true"
                        :paginationEnabled="false"
                        navigationNextLabel="❯"
                        navigationPrevLabel="❮"
                >
                    <slide>
                        <div class="list-tab active">ทั้งหมด</div>
                    </slide>
                    <slide>
                        <div class="list-tab">ไลฟ์สดหวย</div>
                    </slide>
                    <slide>
                        <div class="list-tab">ไลฟ์สล็อต</div>
                    </slide>
                    <slide>
                        <div class="list-tab">ไลฟ์สดบาคาร่า</div>
                    </slide>
                    <slide>
                        <div class="list-tab">ไลฟ์สดฟุตบอล</div>
                    </slide>
                </carousel>
            </div>
            <div class="ChatListIndex-content">
                <div class="ChatList-title">
                    <h4><img src="@/assets/images/icon/set-icon/icon-lotto2.png">ไลฟ์สด หวยออนไลน์</h4>
                </div>
                <div class="container-ChatListIndex">
                    <div class="box-ChatList">
                        <div class="box-ChatList-info">
                            <div class="view-cont">225</div>
                            <span>steamer01</span>
                            <img src="@/assets/images/profile1.jpg"/>
                        </div>
                    </div>
                    <div class="box-ChatList">
                        <div class="box-ChatList-info">
                            <div class="view-cont">225</div>
                            <span>steamer02</span>
                            <img src="@/assets/images/profile1.jpg"/>
                        </div>
                    </div>
                    <div class="box-ChatList">
                        <div class="box-ChatList-info">
                            <div class="view-cont">225</div>
                            <span>steamer02</span>
                            <img src="@/assets/images/profile1.jpg"/>
                        </div>
                    </div>
                </div>
                <div class="container-ChatListIndex">
                    <div class="box-ChatList">
                        <div class="box-ChatList-info">
                            <div class="view-cont">225</div>
                            <span>steamer01</span>
                            <img src="@/assets/images/profile1.jpg"/>
                        </div>
                    </div>
                    <div class="box-ChatList">
                        <div class="box-ChatList-info">
                            <div class="view-cont">225</div>
                            <span>steamer02</span>
                            <img src="@/assets/images/profile1.jpg"/>
                        </div>
                    </div>
                    <div class="box-ChatList">
                        <div class="box-ChatList-info">
                            <div class="view-cont">225</div>
                            <span>steamer02</span>
                            <img src="@/assets/images/profile1.jpg"/>
                        </div>
                    </div>
                </div>
            </div>
            <div class="ChatListIndex-content">
                <div class="ChatList-title">
                    <h4><img src="@/assets/images/icon/set-icon/icon-hilo-2.png">ไลฟ์สด ไฮโล</h4>
                </div>
                <div class="container-ChatListIndex">
                    <div class="box-ChatList">
                        <div class="box-ChatList-info">
                            <div class="view-cont">225</div>
                            <span>steamer01</span>
                            <img src="@/assets/images/profile1.jpg"/>
                        </div>
                    </div>
                    <div class="box-ChatList">
                        <div class="box-ChatList-info">
                            <div class="view-cont">225</div>
                            <span>steamer02</span>
                            <img src="@/assets/images/profile1.jpg"/>
                        </div>
                    </div>
                    <div class="box-ChatList">
                        <div class="box-ChatList-info">
                            <div class="view-cont">225</div>
                            <span>steamer02</span>
                            <img src="@/assets/images/profile1.jpg"/>
                        </div>
                    </div>
                </div>
            </div>
            <div class="ChatListIndex-content">
                <div class="ChatList-title">
                    <h4><img src="@/assets/images/icon/set-icon/icon-lotto2.png">ไลฟ์สด หวยออนไลน์</h4>
                </div>
                <div class="container-ChatListIndex">
                    <div class="box-ChatList">
                        <div class="box-ChatList-info">
                            <div class="view-cont">225</div>
                            <span>steamer01</span>
                            <img src="@/assets/images/profile1.jpg"/>
                        </div>
                    </div>
                    <div class="box-ChatList">
                        <div class="box-ChatList-info">
                            <div class="view-cont">225</div>
                            <span>steamer02</span>
                            <img src="@/assets/images/profile1.jpg"/>
                        </div>
                    </div>
                    <div class="box-ChatList">
                        <div class="box-ChatList-info">
                            <div class="view-cont">225</div>
                            <span>steamer02</span>
                            <img src="@/assets/images/profile1.jpg"/>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>


<script>
    import MenuBar from '@/components/MenuBar'
    import { Carousel, Slide } from 'vue-carousel'

    export default {
        name: "ChatRoomIndex",
        components: {
            Carousel,
            Slide,
            MenuBar,
        },
    };
</script>

<style src="@/assets/css/slot.css" scoped></style>
<style>
    .Home .menu-click {
        display: block
    }
    .ChatListIndex{
        background-image: linear-gradient(
                135deg
                , #F372D4 10%, #c765f0 100%);
         }
    .ChatListIndex .box-img h4{
        font: 800 20px/1 Athiti;
        margin: 0;
    }
    .ChatListIndex .box-img{
        top: 5px!important;
        left: 35px;
        display: flex;
        align-items: center;
        justify-content: center;
        height: 35px!important;
    }
    .ChatListIndex .content-page {
        background-color: #fff;
        border-radius: 20px 20px 0 0;
        padding: 5px 0;
    }
    .container-ChatListIndex{
        display: flex;
        flex-wrap: wrap;
        justify-content: center;
    }
    .ChatListIndex{}
    .box-ChatList{
        display: flex;
        width: 32%;
        cursor: pointer;
    }
    .box-ChatList-info{

    }
    .view-cont{right: 5px; top: 5px; font-size: 12px;}
    .box-ChatList span{left: 5px; bottom: 5px;}
    .view-cont,
    .box-ChatList span{
        position: absolute;
        color: white;
    }
    .view-cont:before {
        content: '\e8f4';
        font: 11px/20px "Material Icons";
        margin-right: 5px;
    }
    .ChatList-title{padding: 10px 0 0 10px;}
    .ChatList-title h4 img{height: 35px; margin-right: 5px;}
    .ChatList-title h4{font: 800 18px Athiti; margin: 0;}
    .box-ChatList-info{
        position: relative;
        margin: 5px
    }
    .box-ChatList-info img{border-radius: 6px;}
    .cat-ChatListIndex{margin-top: 10px;}
    .cat-ChatListIndex .list-tab{
        display: flex;
        align-items: center;
        justify-content: center;
        border-radius: 15px;
        font: 600 14px Athiti;
        height: 30px;
        border: solid 1px rgba(45, 45, 62, 0.2);
        margin-right: 5px;
        padding: 0 5px;
        cursor: pointer;
        position: relative;
    }
    .cat-ChatListIndex .list-tab.active {
        background-color: rgba(208, 11, 132, 1);
        font: 800 14px Athiti;
        color: white;
    }
    .ChatListIndex-content{margin-bottom: 10px;}
</style>
<style src="@/assets/css/home.css" scoped></style>
