import ReconnectingWebSocket from 'reconnecting-websocket'

export default {
    name: 'GameWebSocketMixin',
    data() {
        return {
            webSocket: null,
            token: '',
            tables: [],
            currentTable: null,
            games: [],
            currentGames: null,
        }
    },
    computed: {
        tablesLength() {
            return this.tables.length
        },
        gamesLength() {
            return this.games.length
        },
    },
    watch: {
        tablesLength(newVal) {
            if(newVal) {
                this._loadGame(this.currentTable)
            }
        },
        gamesLength(newVal) {
            if(newVal) {
                this._loadGame(this.currentTable)
            }
        },
        currentTable(newVal) {
            if(newVal) {
                this._loadGame(newVal)
            }
        },
    },
    methods: {
        _webSocketOpenHandle() {
            if(this.webSocket.readyState === ReconnectingWebSocket.OPEN){
                this.webSocket.send(JSON.stringify({
                    type: 'subscribe',
                    list: ['baccarat'],
                }))
            }
        },
        _webSocketMessageRecieveHandle(event) {
            const message = JSON.parse(event.data)
            const sub_type = message.sub_type
            if(sub_type === 'init') {
                this._subTypeInitHandle(message)
            } else if (sub_type === 'table') {
                this._subTypeTableHandle(message)
            } else if (sub_type === 'table_game') {
                this._subTypeTableGameHandle(message.data.table, message.data.game)
            }
            this._messageRecieveHandle(message)
        },
        _messageRecieveHandle(message) {
            console.log('_messageRecieveHandle', message)
        },
        _initWebSocket() {
            this.webSocket = new ReconnectingWebSocket(`${this.envDomains.VUE_APP_PES_WS_BASE_URL}`, this.token, {
                debug: false, 
                reconnectInterval: 3000
            })
            this.webSocket.addEventListener('open', this._webSocketOpenHandle)
            this.webSocket.addEventListener('message', this._webSocketMessageRecieveHandle)
        },
        _closeWebSocket() {
            if(!this.webSocket) {
                return
            }
            this.webSocket.close()
        },
        _tableListToArr(map) {
            const arr = []
            for(const key in map) {
                if(map[key].table_status) {
                    arr.push(map[key])
                }
            }
            return arr
        },
        _subTypeInitHandle(message) {
            this.tables = this._tableListToArr(message.data.baccarat_table_list)
        },
        _subTypeTableHandle(message) {
            const newData = message.data
            this._updateTables(newData)
            this._updateCurrentTable(newData)
        },
        _subTypeTableGameHandle(table, game) {
            this._updateTables(table)
            this._updateCurrentTable(table, game)
            this._updateGames(game)
            this._updateCurrentGame(table, game)
        },
        _updateTables(newData) {
            if(!newData.table_status) {
                return
            }
            if(this.tables.length > 0) {
                const i = this.tables.findIndex((table) => {
                    return parseInt(table.table_id) === parseInt(newData.table_id)
                })
                if(i < 0) {
                    this.tables.push(newData)
                } else {
                    this.tables.splice(i, 1, newData)
                }
            }
        },
        _updateCurrentTable(newData) {
            if(!this.currentTable) {
                return
            }
            if(parseInt(this.currentTable.table_id) === parseInt(newData.table_id)) {
                this.currentTable = newData
            }
        },
        _updateGames(newGame) {
            if(!newGame) {
                return
            }
            if(this.games.length > 0) {
                const i = this.games.findIndex((game) => {
                    return parseInt(game.gbs_id) === parseInt(newGame.gbs_id) &&
                        parseInt(game.table_id) === parseInt(newGame.table_id)
                })
                if(i < 0) {
                    this.games.push(newGame)
                } else {
                    this.games.splice(i, 1, newGame)
                }
            } else {
                this.games.push(newGame)
            }
        },
        _updateCurrentGame(table, game) {

            if(!this.currentGame || !table || !game) {
                return
            }
            if(parseInt(this.currentTable.table_id) === parseInt(table.table_id)) {
                this.currentGame = game
            }
        },
        _findGameByGBSId(table_id, gbs_id) {
            return this.games.find((game) => {
                return parseInt(game.gbs_id) === parseInt(gbs_id) && 
                    parseInt(game.table_id) === parseInt(table_id)
            }) 
        },
        _loadGame(table) {
            if(!table) {
                return
            }
            const game = this._findGameByGBSId(table.table_id, table.ref_gbs_id)
            this.currentGame = game
        },
    },
    beforeDestroy() {
        this._closeWebSocket()
    },
}