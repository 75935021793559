<template>
    <div class="BetPanelOddPrice flex item-center">
        <span v-if="accept_any_odd">
            <span :class="{ 'blink_blink': isChange}">&nbsp;{{myOdd | priceByCurrency | toFixed(2)}}&nbsp;</span>
        </span>
        <span v-if="!accept_any_odd">
            <span>&nbsp;<span :class="{'line-through': price && myOdd !== price}">{{myOdd | priceByCurrency | toFixed(2)}}</span>&nbsp;</span>
            <span v-if="myOdd !== price" class="price" :class="{ 'blink_blink': isChange}">{{price | priceByCurrency | toFixed(2)}}</span>
        </span>
    </div>
</template>

<script>

    import store from '@/store'
    import {mixin as VueTimers} from 'vue-timers'

export default {
    name: 'BetPanelOddPrice',
    mixins: [VueTimers],
    props: ['myOdd', 'price'],
    data: function() {
        return {
            isChange: false,
        };
    },
    timers: {
        clearBlinkBlink: { 
            time: 4000
        }
    },
    watch: {
        myOdd: function() {
            this.$timer.stop('clearBlinkBlink');
            this.isChange = true;
            this.$timer.start('clearBlinkBlink');
        },
        price: function() {
            this.$timer.stop('clearBlinkBlink');
            this.isChange = true;
            this.$timer.start('clearBlinkBlink');
        }
    },
    computed: {
        accept_any_odd: function(){
            return store.state.football.accept_any_odd;
        }
    },
    methods: {
        clearBlinkBlink: function(){
            this.isChange = false;
        },
    }

}
</script>

<style scoped>
    .BetPanelOddPrice * {
        margin: 0 2px;
    }
    .line-through {
        text-decoration: line-through;
    }
    .blink_blink {
        animation: blinker 1s linear infinite;
    }
    @keyframes blinker {
        50% {
            background-color: yellow;
        }
    }
</style>