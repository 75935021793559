<template>
<div class="AdminChatBot">
    <div v-if="isOnLoad">
        <div style="height: 20px;"></div>
        กำลังโหลด... โปรดรอ
    </div>
    <div v-else-if="errMessage">
        <div>
            {{errMessage}}
        </div>
    </div>
    <div v-else-if="!LIFF_GAME_URL">
        <div style="height: 20px;"></div>
        ยังไม่มีการตั้งค่า LIFF_GAME_URL กรุณาติดต่อผู้ดูแล
    </div>
    <div v-else>
        <div style="font-size: 28px;">แอดมิน แชทบอท</div>
        <div style="height: 10px;"></div>
        <div>
            <div v-if="currentTable">
                สถานะเกม: <strong class="color-blue">{{tableGameStatusDisplay}}</strong>
            </div>
            <div style="height: 10px;"></div>
            โต๊ะ: 
            <select v-model="currentTable">
                <option :value="null">เลือกโต๊ะ</option>
                <option v-for="(table, key) of tables" :key="key" :value="table">
                    id: {{table.table_id}} [{{table.table_name}}]
                </option>
            </select>
        </div>
        <div style="height: 10px;"></div>
        <div>
            สถานะบอท: <strong :class="{'color-green': state === 'run'}">{{stateDisplay}}</strong>
        </div>
        <div style="height: 10px;"></div>
        <button :disabled="!canStart" @click="toggleBotState">
            <span v-if="state === 'idle'">รันบอท</span>
            <span v-else>หยุด</span>
        </button>
    </div>
    <div style="width: 100%; text-align: right; padding: 10px;">
        <el-button @click="$emit('logout')" size="small">ลงชื่อออก</el-button>
    </div>
</div>
</template>
<script>

import GameWebSocketMixin from '../../mixin/GameWebSocket.js'
import liff from '@line/liff'
import {
    TABLE_TYPE_KEY,
    TABLE_GAME_STATUS, 
    TABLE_GAME_STATUS_KEY, 
    CARD_FACE,
    CARD_POINT,
    DICE_FACE,
    BACCARAT_SIDE,
    BACCARAT_CARD_ORDER,
    BACCARAT_GAME_RESULT,
    DT_SIDE, 
    DT_GAME_RESULT, 
    SICBO_GAME_RESULT,
    BEAN_GAME_RESULT,
    // CARD_TYPE_IMAGE, 
    // CARD_NUMBER_IMAGE,
} from '../../lib/Constant.js'
import store from '@/store'
import axiosWrapper from '@/axiosWrapper'
import {TABLE_TYPE_MAP} from '@/components/game/Constant'

export default {
    name: 'AdminChatBot',
    mixins: [
        GameWebSocketMixin,
    ],
    data() {
        return {
            state: 'idle', //idle, run
            errMessage: '',
            isOnLoad: true,
            resource: null,
        }
    },
    computed: {
        stateDisplay() {
            if(this.state === 'idle') {
                return 'ว่าง'
            } else {
                return 'กำลังรัน'
            }
        },
        canStart() {
            return !!this.currentTable && this.LIFF_GAME_URL
        },
        tableGameStatus() {
            return (this.currentTable) ? this.currentTable.table_game_status : null 
        },
        tableGameStatusDisplay() {
            return (this.tableGameStatus) ? TABLE_GAME_STATUS[this.tableGameStatus]['TH'] : ''
        },
        admin() {
            return store.state.admin
        },
        LIFF_GAME_URL() {
            if(!this.resource) {
                return ''
            }
            return this.resource['LIFF_GAME_URL']
        },
    },
    watch: {
        tableGameStatus() {
            this._tableGameStatusChangeHandle()
        },
    },
    methods: {
        toggleBotState() {
            if(this.state === 'idle') {
                this._start()
            } else {
                this._stop()
            }
        },
        _start() {
            this.state = 'run'
        },
        _stop() {
            this.state = 'idle'
        },
        _messageRecieveHandle(message) {
            if(!message.sub_type) {
                return
            }
            if(message.sub_type === 'add_card') {
                this._subTypeAddCardHandle(message)
                return
            }
            if(message.sub_type === 'dice') {
                this._subTypeDiceHandle(message)
            }
        },
        _initLiff() {

            const liffId = this.$route.query['liff-id']

            if(!liffId) {
                this.errMessage = 'ลิ้งค์ไม่ถูกต้อง ?liff-id is required.'
                return
            }

            liff.init({ liffId, })
            .then(() => {
                if(!liff.isInClient() && !liff.isLoggedIn() && process.env['NODE_ENV'] !== 'development') {
                    liff.login({redirectUri: window.location.href})
                    return
                }
            })
            .catch((error) => {
                console.log('liff init error: ' + error)
            })
        },
        _liffSendMessage(messages) {
            if(this.state === 'idle') {
                return
            }
            if(process.env['NODE_ENV'] === 'development') {
                console.log('_liffSendMessage', messages[0].text ||  messages[0].altText)
                return
            }
            liff.sendMessages(messages).then(function() {
                console.log('Message sent')
            }).catch(function(error) {
                console.log('liff sending error: ' + error)
            });
        },
        _subTypeAddCardHandle(message) {
            const data = message.data
            if (!this.currentTable) {
                return
            }
            if (parseInt(data.gbs_id) === parseInt(this.currentTable.ref_gbs_id) &&
                parseInt(data.game_index) === parseInt(this.currentTable.table_type)) {
                
                const card = data.card

                // const image = `${window.location.origin}/images/cards/${CARD_TYPE_IMAGE[card.card_type]}${CARD_NUMBER_IMAGE[card.card_number]}.png`

                // this._liffSendMessage([
                //     {
                //         "type": "template",
                //         "altText": `จั่วไพ่ ${DT_SIDE[card.side]}`,
                //         "template": {
                //             "type": "buttons",
                //             "thumbnailImageUrl": image,
                //             "imageSize": "contain",
                //             "text": `จั่วไพ่ ${DT_SIDE[card.side]}`,
                //             "actions": [
                //                 {
                //                     "type": "uri",
                //                     "label": "เล่นเลย",
                //                     "uri": this.envDomains.VUE_APP_CHAT_BASE_URL
                //                 }
                //             ]
                //         }
                //     }
                // ])

                let text = ''

                if(parseInt(this.currentTable.table_type) === TABLE_TYPE_KEY.BACCARAT) {
                    text = `จั่วไพ่ ${BACCARAT_SIDE[card.side]} ใบที่ ${BACCARAT_CARD_ORDER[card.side][card.index]['order']}:`
                    text += ` ${CARD_FACE[card.card_type]['emoji']}${CARD_POINT[card.card_number]['point']}`
                } else if(parseInt(this.currentTable.table_type) === TABLE_TYPE_KEY.DT) {
                    text = `จั่วไพ่ ${DT_SIDE[card.side]}: ${CARD_FACE[card.card_type]['emoji']}${CARD_POINT[card.card_number]['point']}`
                }
                
                this._liffSendMessage([
                    {
                        "type": "text",
                        "text": text,
                    }
                ])
            }
        },
        _subTypeDiceHandle(message) {
            const data = message.data
            if (!this.currentTable) {
                return
            }
            if (parseInt(data.gbs_id) === parseInt(this.currentTable.ref_gbs_id) &&
                parseInt(data.game_index) === parseInt(this.currentTable.table_type)) {
                
                const dices = data.dice

                let text = `ทอยลูกเต๋า`
                for(const dice of dices) {
                    text += ` ${DICE_FACE[dice]['emoji']}`
                }
                
                this._liffSendMessage([
                    {
                        "type": "text",
                        "text": text,
                    }
                ])
            }
        },
        _tableGameStatusChangeHandle() {
            if(parseInt(this.tableGameStatus) === TABLE_GAME_STATUS_KEY.DEALING) {
                return
            }
            if(parseInt(this.tableGameStatus) === TABLE_GAME_STATUS_KEY.FINISH) {

                let text = ''

                if(parseInt(this.currentTable.table_type) === TABLE_TYPE_KEY.BACCARAT) {
                    text = `ออก${BACCARAT_GAME_RESULT[this.currentGame.game_result]['TH']}`
                } else if(parseInt(this.currentTable.table_type) === TABLE_TYPE_KEY.DT) {
                    text = `ออก${DT_GAME_RESULT[this.currentGame.game_result]['TH']}`
                } else if(parseInt(this.currentTable.table_type) === TABLE_TYPE_KEY.SICBO) {
                    text = `ออก${SICBO_GAME_RESULT[this.currentGame.game_result]['TH']}`
                } else if(parseInt(this.currentTable.table_type) === TABLE_TYPE_KEY.BEAN) {
                    text = `ออก${BEAN_GAME_RESULT[this.currentGame.game_result]['TH']}`
                }

                this._liffSendMessage([
                    {
                        "type": "text",
                        "text": text,
                    }
                ])
                return
            }
            if(parseInt(this.tableGameStatus) === TABLE_GAME_STATUS_KEY.COUNTDOWN) {
                this._liffSendMessage([
                    {
                        "type": "template",
                        "altText": `เริ่มเกมใหม่เปิดให้แทงได้`,
                        "template": {
                            "type": "buttons",
                            "text": `เริ่มเกมใหม่เปิดให้แทงได้`,
                            "imageBackgroundColor": "#12182d",
                            "thumbnailImageUrl": `${this.envDomains.VUE_APP_API}/upload-images/game-stat/table-id-${this.currentTable.table_id}.png?timestamp=${this.$moment().valueOf()}`,
                            "imageSize": "contain",
                            "actions": [
                                {
                                    "type": "uri",
                                    "label": "เล่นเลย",
                                    // "uri": `${this.LIFF_GAME_URL}/?table_type=${this.currentTable.table_type}&table_id=${this.currentTable.table_id}`
                                    "uri": `${this.envDomains.VUE_APP_API}${TABLE_TYPE_MAP[this.currentTable.table_type]['routePath']}?table_id=${this.currentTable.table_id}`
                                }
                            ]
                        }
                    }
                ])
                return
            }
            this._liffSendMessage([
                {
                    "type": "text",
                    "text": this.tableGameStatusDisplay,
                }
            ])
        },
        async _loadAdminResource() {
            const res = await axiosWrapper({
                method: 'POST',
                url: `${this.envDomains.VUE_APP_LIFF_API}/api/channel/get-liff-link`,
                data: {
                    channel: this.admin.channel,
                    line: this.admin.line_oa,
                    get_all: 'yes',
                }
            })
            if(res.status !== 200) {
                return
            }
            this.resource = res.data
        },
    },
    async mounted() {
        this._initLiff()
        this._initWebSocket()
        this.isOnLoad = true
        await this._loadAdminResource()
        this.isOnLoad = false
    },
}
</script>
<style scoped>
.AdminChatBot {
    text-align: center;
}
.color-green {
    color: green;
}
.color-blue {
    color: blue;
}
</style>