<template>
    <div @click="selectChip" :class="`FantanChip card-hilo ${chipClass}`">
        <slot></slot>
    </div>
</template>
<script>

import {CHIP_MAP} from '../Constant'

export default {
    name: 'FantanChip',
    props: [
        'value',
    ],
    computed: {
        chipClass() {
            return CHIP_MAP[parseInt(this.value)]['class']
        },
        // selected() {
        //     if(parseInt(this.panelIndex) === parseInt(this.currentPanelIndex) && 
        //     parseInt(this.choice) === parseInt(this.user_choose) &&
        //     parseInt(this.value) === parseInt(this.amount)) {
        //         return 'selected'
        //     }
        //     const foundInBetArr = this.userBetArr.find((bet) => {
        //         return bet.user_choose === this.choice && 
        //         bet.amount === this.value && 
        //         bet.panelIndex === this.panelIndex
        //     })
        //     return (foundInBetArr) ? 'selected': ''
        // },
        // isShowConfirmPanel() {
        //     return parseInt(this.choice) === parseInt(this.user_choose) && 
        //             parseInt(this.value) === parseInt(this.amount) &&
        //             parseInt(this.panelIndex) === parseInt(this.currentPanelIndex)
        // },
    },
    methods: {
        selectChip() {
            this.$emit('selectChip', this.value)
        },
    },
}
</script>
<style scoped>
.FantanChip {
    background-size: cover;
    margin: 0 2px;
    width: 22px;
    height: 22px;
    border: solid 1px gray;
    border-radius: 50%;
}
.FantanChip.c-5 {
    background-image: url(../../../assets/images/c-5.png);
}
.FantanChip.c-10 {
    background-image: url(../../../assets/images/c-10.png);
}
.FantanChip.c-20 {
    background-image: url(../../../assets/images/c-20.png);
}
.FantanChip.c-50 {
    background-image: url(../../../assets/images/c-50.png);
}
.FantanChip.c-100 {
    background-image: url(../../../assets/images/c-100.png);
}
.FantanChip.c-200 {
    background-image: url(../../../assets/images/c-200.png);
}
.FantanChip.c-500 {
    background-image: url(../../../assets/images/c-500.png);
}
.FantanChip.c-1k {
    background-image: url(../../../assets/images/c-1k.png);
}
.FantanChip.c-2k {
    background-image: url(../../../assets/images/c-2k.png);
}
.FantanChip.c-5k {
    background-image: url(../../../assets/images/c-5k.png);
}
.FantanChip.c-10k {
    background-image: url(../../../assets/images/c-10k.png);
}
.FantanChip.c-20k {
    background-image: url(../../../assets/images/c-20k.png);
}
.FantanChip.c-50k {
    background-image: url(../../../assets/images/c-50k.png);
}
</style>