<template>
    <div class="MessageTopUp incoming_msg">
        <!--<div class="incoming_msg_img">-->
            <!--<img src="@/assets/images/img-other/topup2.png" alt="sunil" />-->
        <!--</div>-->
        <div class="received_msg type-activity">
            <!--<h3>Topup</h3>-->
            <div class="received_withd_msg">
                <div class="box-msg bg-topup">
                    <div class="box-top-user-flex">
                        <div class="box-top-user-logo">
                            <div class="f3 blink delay-x1" style="top: -37px; left: -71px; "></div>
                            <div class="f3 blink delay-x15" style="top: -15px; left: -90px;"></div>
                            <div class="f3 blink delay-x1" style="top: -32px; right: 68px;  "></div>
                            <div class="f3 blink delay-x15" style="top: -11px; right: 70px;"></div>
                        </div>
                        <div class="box-slide-show">
                            <div class="box-slide-show-info">
<!--                        <marquee>-->
                            <div v-for="(data, i) of data_arr" :key="i" class="box-top-user-list">
                                <!-- <div class="user-pic">
                                    <el-image :src="data['user.picture_url']">
                                        <img slot="error" src="/images/avatar.png" />
                                    </el-image>
                                </div> -->

                                <Avatar :dataSize="20" mode="image" :src="data['user.picture_url']" style="margin-right: 5px;"></Avatar>
                                <div class="user-list-detail">
                                    <div class="user-list-detail-content">
                                        <div
                                                :class="{
                                        'bt-topup': data.transaction_type === 'deposit',
                                        'bt-withdraw': data.transaction_type === 'withdraw',
                                        }">
                                            <strong>{{data.transaction_type | transactionTypeDisplay}}</strong>
                                        </div>
                                    </div>
                                    <span>{{data.amount | numberFormat}} บาท</span>
                                </div>
                            </div>
                                </div>
<!--                        </marquee>-->
                        </div>
                    </div>
                    <Feeling/>
                </div>
                <!--<span class="time_date"> {{timestamp | chatMessageDateTimeFormat}}</span>-->
            </div>
        </div>
    </div>
</template>
<script>
import Feeling from '@/components/Feeling'
import Avatar from '@/components/Avatar'
export default {
    name: 'MessageTopUp',
    components: {
        Feeling,
        Avatar,
    },
    props: ['message'],
    computed: {
        timestamp(){
            return (this.message) ? this.message.timestamp : ''
        },
        data_arr(){
            if(!this.message){
                return []
            }
            if(this.message.data){
                return this.message.data
            }
            return [
                {
                    'user.picture_url': '/images/profile4.jpg',
                    transaction_type: 'deposit',
                    amount: 10000
                },
                {
                    'user.picture_url': '/images/profile2.jpg',
                    transaction_type: 'withdraw',
                    amount: 3000
                },
                {
                    'user.picture_url': '/images/profile3.jpg',
                    transaction_type: 'deposit',
                    amount: 5000
                }
            ]
        }
    }
}
</script>