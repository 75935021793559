<template>
    <div class="box-game-animated animate-dragonTiger">
        <div class="box-animated">
            <div class="hand">
                <div ref="gifImage" class="hand-active_dragontiger"></div>
                <div class="sec-cards section-dragontiger-card">
                    <div class="sec-half">
                        <div class="box-show-cards fa-pull-right" style="margin: 0 22px 0 0;">
                            <div class="pos-2">
                                <div class="flip-cards"
                                :class="{'floating-cards-effect': dragonCardState === 'float'}">
                                    <!-- <div class="flip-card-inner">
                                        <div class="card-front"></div>
                                        <div class="card-back"></div>
                                    </div> -->
                                    <CardAnimation
                                    v-show="state === 'end_game' || dragonCardState !== 'idle' || customCardDragon"
                                    :state="cardFaceState"
                                    :type="(customCardDragon) ? customCardDragon.card_type : 0"
                                    :number="(customCardDragon) ? customCardDragon.card_number : 0"
                                    flipAngle="x"></CardAnimation>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="sec-half">
                        <div class="box-show-cards fa-pull-left" style="">
                            <div class="pos-2">
                                
                                <div class="flip-cards"
                                :class="{'floating-cards-effect': tigerCardState === 'float'}">
                                    <!-- <div class="flip-card-inner">
                                        <div class="card-front"></div>
                                        <div class="card-back"></div>
                                    </div> -->
                                    <CardAnimation
                                    v-show="state === 'end_game' || tigerCardState !== 'idle' || customCardTiger"
                                    :state="cardFaceState"
                                    :type="(customCardTiger) ? customCardTiger.card_type : 0"
                                    :number="(customCardTiger) ? customCardTiger.card_number : 0"
                                    flipAngle="x"></CardAnimation>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>
<script>

import {GAME_STATUS_KEY, DRAGON_TIGER_GAME_RESULT_KEY} from '@/components/game/Constant'
import axios from 'axios'
import CardAnimation from '@/components/game/CardAnimation'
const STATE_ARR = [
    'put_card',
    'tiger_win',
    'dragon_win',
    'draw',
    'end_game',
    'open_card',
]

const DURATION_BETWEEN_CARD = 800

export default {
    name: 'SicboGamePlay3D',
    components: {
        CardAnimation,
    },
    props: [
        'gameStatus',
        'gameResult',
        'cardDragon',
        'cardTiger',
    ],
    data() {
        return {
            GAME_STATUS_KEY: null,
            STATE_ARR: [],
            state: 'end_game',
            base64Map: null,
            dragonCardState: 'idle', //idle float, place
            tigerCardState: 'idle', //idle float, place
            cardFaceState: 'back', //back, front
            customCardDragon: null,
            customCardTiger: null,
        }
    },
    computed: {
        el() {
            return this.$refs['hand']
        },
        diceLength() {
            return this.dice.length
        },
        gameTypeClass() {
            if(this.gameType === 'bean') {
                return 'animate-fantan'
            } else {
                return 'box-bg-animated-sicbo'
            }
        },
        isCardComplete() {
            return !!(this.customCardDragon && this.customCardTiger)
        },
    },
    watch: {
        gameStatus(newVal, oldVal) {
            if(newVal === GAME_STATUS_KEY.DEALING) {
                this.tigerCardState = 'place'
                this.dragonCardState = 'place'
            }else if(oldVal === GAME_STATUS_KEY.DEALING && newVal === GAME_STATUS_KEY.FINISH) {
                this.tigerCardState = 'place'
                this.dragonCardState = 'place'
                setTimeout(() => {
                    if(parseInt(this.gameResult) === DRAGON_TIGER_GAME_RESULT_KEY.TIGER_WIN) {
                        this.state = 'dragon_win'
                    } else if(parseInt(this.gameResult) === DRAGON_TIGER_GAME_RESULT_KEY.DRAGON_WIN) {
                        this.state = 'tiger_win'
                    } else if(parseInt(this.gameResult) === DRAGON_TIGER_GAME_RESULT_KEY.TIE) {
                        this.state = 'draw'
                    }
                }, 1000)
            }else if(oldVal && newVal === GAME_STATUS_KEY.COUNTDOWN) {
                setTimeout(() => {
                    this.dragonCardState = 'idle'
                    this.tigerCardState = 'idle'
                    this.cardFaceState = 'back'
                }, 2000)
                setTimeout(() => {
                    this.state = 'put_card'
                }, 2000 + 2000)
            }else {
                this.state = 'end_game'
                this.cardFaceState = 'back'
            }
        },
        state(newVal) {
            if(newVal) {
                if(newVal === 'put_card') {
                    setTimeout(() => {
                        this.dragonCardState = 'float'
                        setTimeout(() => {
                            this.dragonCardState = 'place'
                        }, 1000)
                        setTimeout(() => {
                            this.tigerCardState = 'float'
                        }, DURATION_BETWEEN_CARD)
                        setTimeout(() => {
                            this.tigerCardState = 'place'
                        }, DURATION_BETWEEN_CARD + 2000)
                    }, 1000)
                }
                this.setImage(newVal)
            }
        },
        cardDragon(newVal) {
            if(newVal) {
                this.customCardDragon = newVal
            } else {
                setTimeout(() => {
                    this.customCardDragon = null
                }, 2000)
            }
        },
        cardTiger(newVal) {
            if(newVal) {
                this.customCardTiger = newVal
            } else {
                setTimeout(() => {
                    this.customCardTiger = null
                }, 2000)
            }
        },
        isCardComplete(newVal) {
            if(newVal && this.gameStatus === GAME_STATUS_KEY.DEALING) {
                this.state = 'open_card'
                setTimeout(() => {
                    this.cardFaceState = 'front'
                }, 1600)
            } else {
                this.cardFaceState = 'back'
            }
        },
    },
    methods: {
        setImage(state) {
            const el = this.$refs['gifImage']
            if(!state || !el || !this.base64Map) {
                return
            }
            el.style['background-image'] =  `url(${this.base64Map[state].replace("image/gif","image/gif;rnd="+Math.random())})`
            el.style['background-size'] = '110%'
        }
    },
    async created() {
        this.GAME_STATUS_KEY = GAME_STATUS_KEY
        this.STATE_ARR = STATE_ARR
        const base64Res = await axios.get('/images/game/dragon-tiger/animation/base64.json')
        this.base64Map = base64Res.data
        this.setImage(this.state)
    },
}
</script>
<style scoped src="@/assets/css/animate-games.css"></style>