<template>
    <div class="BaccaratStat box-panel-play-state">
        <div class="top-info-panel-play-state">
            <ul class="text-play-state">
                <li>
                    <span>สูงสุด :</span>{{maxBet | numberFormat}}<span></span>
                </li>
                <li><span>ต่ำสุด :</span><span>{{minBet | numberFormat}}</span></li>
            </ul>
            <ul class="data-play-state">
                <li>
                    <div class="bx-baccarat-p"></div>
                    <span>{{playerCount}}</span></li>
                <li>
                    <div class="bx-baccarat-pp"></div>
                    <span>{{playerPairCount}}</span></li>
                <li>
                    <div class="bx-baccarat-t"></div>
                    <span>{{tieCount}}</span></li>
                <li>
                    <div class="bx-baccarat-bp"></div>
                    <span>{{bankerPairCount}}</span></li>
                <li>
                    <div class="bx-baccarat-b"></div>
                    <span>{{bankerCount}}</span></li>
            </ul>
        </div>
        <div class="info-panel-play-state">
            <BaccaratScoreboard :statArr="statArr"></BaccaratScoreboard>
            <!-- <div class="box-score-table" id="stat-box-bottom" style="height: 139px;">
                <div class="column" ref="column-left" style="overflow-x: scroll;">
                    <Main @existStatWidthChange="mainScroll" :statArr="statArr"></Main>
                </div>
                <div class="column1 big-road-wrapper" ref="column-right">
                    <BigRoad @existStatWidthChange="bigRoadScroll" :statArr="statArr"></BigRoad>
                </div>
            </div> -->
            <!--<img src="@/assets/images/simple-4.jpg">-->

        </div>
    </div>
</template>
<script>

import {GAME_RESULT_KEY} from '../Constant'
import StatScrollMixin from '../StatScrollMixin'
import BaccaratScoreboard from '@/components/game/baccarat/Scoreboard'

export default {
    name: 'BaccaratStat',
    mixins: [
        StatScrollMixin,
    ],
    components: {
        BaccaratScoreboard,
    },
    props: [
        'statArr',
        'betLimitConfig',
    ],
    computed: {
        playerCount() {
            return this.statArr.filter((stat) => {
                return stat.game_result === GAME_RESULT_KEY.PLAYER_WIN
            }).length
        },
        bankerCount() {
            return this.statArr.filter((stat) => {
                return stat.game_result === GAME_RESULT_KEY.BANKER_WIN
            }).length
        },
        tieCount() {
            return this.statArr.filter((stat) => {
                return stat.game_result === GAME_RESULT_KEY.TIE
            }).length
        },
        playerPairCount() {
            return this.statArr.filter((stat) => {
                return stat.is_player_pair === 1
            }).length
        },
        bankerPairCount() {
            return this.statArr.filter((stat) => {
                return stat.is_banker_pair === 1
            }).length
        },
        maxBet() {
            if(!this.betLimitConfig) {
                return 500
            }
            return this.betLimitConfig.max_bet
        },
        minBet() {
            if(!this.betLimitConfig) {
                return 5
            }
            return this.betLimitConfig.min_bet
        },
    },
}
</script>

<style scoped>
    .box-score-table {
        border: solid 1px;
        display: flex;
    }

    .box-score-table table {
        border: 0 !important;
        width: 100%;
        height: 100%;
    }

    .box-score-table td {
        border-collapse: collapse;
    }

    .column {
        display: flex;
        float: left;
        width: 41%;
        border-right: solid 1px;
        /*border-right: 1px;*/
        /*border-left: 1px;*/
        /*border-style: solid;*/
        /*border-color: rgb(66, 65, 65);*/
    }

    .column1 {
        display: flex;
        float: left;
        width: 59%;
    }

    .box-score-table .score-scroll {
        margin: 0;
        width: auto;
    }

    .score-scroll:hover {
        overflow-x: overlay;
    }

    div.score-scroll {
        display: block;
        position: relative;
        width: 100%;
        overflow-x: hidden;
        overflow-y: hidden;
        white-space: nowrap;
        border-bottom: solid 1px #000;
    }

    .score-scroll {
        background-color: transparent;
    }

    .board-backgroundx {
        border: solid 1px #ddd !important;
    }

    .text-score_bigroad {
        /* font-size: 14px; */
        width: 9px;
        height: 9px;
        display: block;
        margin: 1px;
    }

    .text-score {
        width: 15.5px;
        height: 15.5px;
        display: block;
        margin: 2px;
    }

    .text-score, .text-score-status, .text-score_all, .text-score-topic, .text-status-primary, .text-status-secondary, .text-red-bcc, .text-score_eye, .text-score_bigroad, .text-blue-bcc, .text-score_bigeye, .text-score_small {
        text-shadow: 0.5px 0.5px 0.5px #eee;
    }

    .text-box-score-right_eye {
        width: 4px;
        height: 2px;
    }

    .text-box-score-right_eye {
        display: table;
        margin-top: 1px;
        margin-bottom: 1px;
        margin-right: 1px;
        margin-left: 1px;
        padding-top: 1px;
    }

    .text-box-score-right_small {
        width: 2px;
        height: 2px;
    }

    .text-box-score-right_small {
        display: table;
        margin-top: 1px;
        margin-bottom: 1px;
        margin-right: 1px;
        margin-left: 1px;
        padding-top: 1px;
    }

    .color-red {
        background-color: #c52828 !important;
    }

    .color-blue {
        background-color: #006aff !important;
    }

    .color-yellow {
        background-color: #fab600;
    }

    .box-lot-cell {
        text-align: center;
        font-size: 13px;
        width: 18px;
        height: 18px;
        border-radius: 50%;
        cursor: pointer;
        display: inline-block;
        line-height: 17px;
        color: #fff;
        float: left;
        margin: 0 auto;
        font-family: "Athiti", sans-serif;
        margin-top: -1px;
        margin-left: -2px;
        position: relative;
    }

    .box-lot-cell_sm, .box-lot-cell_mini {
        width: 11px;
        height: 11px;
        border-radius: 50%;
        display: inline-block;
        float: left;
        font-family: "Athiti", sans-serif;
        margin-top: -1px;
        margin-left: -2px;
        border: solid 2px;
        position: relative;
    }

    .box-lot-cell_sm > i.float-dot{
        width: 5px;
        height: 5px;
    }

    .box-lot-cell_sm > .dot-blue{
        bottom: -2px !important;
        right: -2px !important;
    }

    .box-lot-cell_sm > .line-past{
        display: inline-block;
        width: 9px;
        height: 9px;
        border-left: solid 1px green;
        -ms-transform: rotate(50deg); /* IE 9 */
        transform: rotate(50deg);
        position: absolute;
        top: 2px;
    }

    .box-lot-cell_mini {
        width: 6px;
        height: 6px;
        float: right;
    }

    .float-dot{
        display: block;
        width: 8px;
        height: 8px;
        border-radius: 50%;
        position: absolute;
        border: solid 1px #fff;
        z-index: 9;
    }

    .dot-red{
        background-color: #c52828 !important;
        top: -1px;
        left: -2px;
    }

    .dot-blue{
        background-color: #006aff !important;
        bottom: -1px;
        right: 0px;
    }

    .border-red {
        border-color: #c52828 !important;
    }

    .border-blue {
        border-color: #006aff !important;
    }
    .big-road-wrapper {
        height: 68px; 
        overflow-x: scroll; 
        border-bottom: solid 1px black;
    }

</style>