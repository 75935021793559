export const BILL_KEY_ARR = [
    'sam_tua_bon',
    'sam_tua_tod',
    'sam_tua_nha',
    'sam_tua_lang',
    'song_tua_bon',
    'song_tua_lang',
    'wing_bon',
    'wing_lang',
]

export const BILL_KEY_PRIORITY = {
    'sam_tua_bon': 0,
    'sam_tua_tod': 1,
    'sam_tua_nha': 2,
    'sam_tua_lang': 3,
    'song_tua_bon': 4,
    'song_tua_lang': 5,
    'wing_bon': 6,
    'wing_lang': 7,
}

export const LOTTO_TYPE = {
    'yeekee': 'หวยไฮเทค',
    'government': 'หวยไทย',
    'hanoy_special': 'ฮานอยพิเศษ',
    'hanoy': 'หวยฮานอย',
    'hanoy_vip': 'ฮานอย vip',
    'lao': 'หวยลาว',
    'malay': 'หวยมาเลย์',
}

export const LOTTO_STATUS = {
    'win': 'ถูกรางวัล',
    'lose': 'ไม่ถูกรางวัล',
    'running': 'รอผลออก',
    'cancel': 'ยกเลิก'
}

export const CATEGORY_LIST = {
    'sam_tua_bon': {
        slug: 'sam_tua_bon',
        name: '3 ตัวบน',
        reward: 900,
        group: ['sam_tua_tod', 'sam_tua_nha', 'sam_tua_lang', 'sam_tua_glub'],
        max_per_bill: 2000,
        max_per_number: 2000
    },
    'sam_tua_tod': {
        slug: 'sam_tua_tod',
        name: '3 ตัวโต๊ด',
        reward: 150,
        group: ['sam_tua_bon', 'sam_tua_nha', 'sam_tua_lang'],
        max_per_bill: 1000,
        max_per_number: 1000
    },
    'sam_tua_nha': {
        slug: 'sam_tua_nha',
        name: '3 ตัวหน้า',
        reward: 450,
        group: ['sam_tua_bon', 'sam_tua_tod', 'sam_tua_lang', 'sam_tua_glub'],
        max_per_bill: 2000,
        max_per_number: 2000
    },
    'sam_tua_lang': {
        slug: 'sam_tua_lang',
        name: '3 ตัวล่าง',
        reward: 450,
        group: ['sam_tua_bon', 'sam_tua_tod', 'sam_tua_nha', 'sam_tua_glub'],
        max_per_bill: 2000,
        max_per_number: 2000
    },
    'song_tua_bon': {
        slug: 'song_tua_bon',
        name: '2 ตัวบน',
        reward: 90,
        group: ['song_tua_lang', 'song_tua_glub'],
        max_per_bill: 2000,
        max_per_number: 2000
    },
    'song_tua_lang': {
        slug: 'song_tua_lang',
        name: '2 ตัวล่าง',
        reward: 90,
        group: ['song_tua_bon', 'song_tua_glub'],
        max_per_bill: 2000,
        max_per_number: 2000
    },
    'sam_tua_glub': {
        slug: 'sam_tua_glub',
        name: '3 ตัวกลับ',
        reward: '',
        group: ['sam_tua_bon', 'sam_tua_nha', 'sam_tua_lang']
    },
    'song_tua_glub': {
        slug: 'song_tua_glub',
        name: '2 ตัวกลับ',
        reward: '',
        group: ['song_tua_bon', 'song_tua_lang']
    },
    'wing_bon': {
        slug: 'wing_bon',
        name: 'วิ่งบน',
        reward: 3.2,
        group: ['wing_lang'],
        max_per_bill: 2000,
        max_per_number: 2000
    },
    'wing_lang': {
        slug: 'wing_lang',
        name: 'วิ่งล่าง',
        reward: 4.2,
        group: ['wing_bon'],
        max_per_bill: 2000,
        max_per_number: 2000
    }
}

export const PriceRatio = {
    'sam_tua_tod': 0.16,
    'sam_tua_nha_lang': 0.6
}

export const CATEGORY_SONG_TUA_LIST = [
    {
        slug: 'sib_kao_pratu',
        name: '19 ประตู',
        ui_class: 'btn-outline-danger'
    },
    {
        slug: 'lek_ble',
        name: 'เลขเบิ้ล',
        ui_class: 'btn-outline-danger',
        number_list: ['00', '11', '22', '33', '44', '55', '66', '77', '88', '99']
    },
    {
        slug: 'rood_nha',
        name: 'รูดหน้า',
        ui_class: 'btn-outline-secondary'
    },
    {
        slug: 'rood_lang',
        name: 'รูดหลัง',
        ui_class: 'btn-outline-secondary'
    },
    {
        slug: 'song_tua_tum',
        name: 'สองตัวต่ำ',
        ui_class: 'btn-outline-info',
        number_list: ['00', '01', '02', '03', '04', '05', '06', '07', '08', '09', '10',
        '11', '12', '13', '14', '15', '16', '17', '18', '19', '20',
        '21', '22', '23', '24', '25', '26', '27', '28', '29', '30',
        '31', '32', '33', '34', '35', '36', '37', '38', '39', '40',
        '41', '42', '43', '44', '45', '46', '47', '48', '49']
    },
    {
        slug: 'song_tua_soong',
        name: 'สองตัวสูง',
        ui_class: 'btn-outline-info',
        number_list: ['50', '51', '52', '53', '54', '55', '56', '57', '58', '59', '60',
        '61', '62', '63', '64', '65', '66', '67', '68', '69', '70',
        '71', '72', '73', '74', '75', '76', '77', '78', '79', '80',
        '81', '82', '83', '84', '85', '86', '87', '88', '89', '90',
        '91', '92', '93', '94', '95', '96', '97', '98', '99']
    },
    {
        slug: 'song_tua_khee',
        name: 'สองตัวคี่',
        ui_class: 'btn-outline-success',
        number_list: ['01', '03', '05', '07', '09', '11', '13', '15', '17', '19',
        '21', '23', '25', '27', '29', '31', '33', '35', '37', '39',
        '41', '43', '45', '47', '49', '51', '53', '55', '57', '59',
        '61', '63', '65', '67', '69', '71', '73', '75', '77', '79',
        '81', '83', '85', '87', '89', '91', '93', '95', '97', '99']
    },
    {
        slug: 'song_tua_khoo',
        name: 'สองตัวคู่',
        ui_class: 'btn-outline-success',
        number_list: ['00', '02', '04', '06', '08', '10', '12', '14', '16', '18',
        '20', '22', '24', '26', '28', '30', '32', '34', '36', '38',
        '40', '42', '44', '46', '48', '50', '52', '54', '56', '58',
        '60', '62', '64', '66', '68', '70', '72', '74', '76', '78',
        '80', '82', '84', '86', '88', '90', '92', '94', '96', '98']
    },
]

export const BILL_KEY = [
    'sam_tua_bon',
    'sam_tua_tod',
    'sam_tua_nha',
    'sam_tua_lang',
    'song_tua_bon',
    'song_tua_lang',
    'wing_bon',
    'wing_lang',
]

export const CONDITION_LIST = [
    {
        title: "3 ตัวบน <div class=\"fa-pull-right\"><i class=\"fas fa-angle-down\" /></div>",
        description: "แทงขั้นต่ำต่อครั้ง : 1.00<br>\n" +
            "แทงสูงสุดต่อครั้ง : 2000.00<br>\n" +
            "แทงสูงสุดต่อเลข : 2000.00"
    },
    {
        title: "3 ตัวโต๊ด <div class=\"fa-pull-right\"><i class=\"fas fa-angle-down\"></i></div>",
        description: "แทงขั้นต่ำต่อครั้ง : 1.00<br>\n" +
            "                                        แทงสูงสุดต่อครั้ง : 1000.00<br>\n" +
            "                                        แทงสูงสุดต่อเลข : 1000.00"
    },
    {
        title: "3 ตัวล่าง <div class=\"fa-pull-right\"><i class=\"fas fa-angle-down\"></i></div>",
        description: "แทงขั้นต่ำต่อครั้ง : 1.00<br>\n" +
            "                                        แทงสูงสุดต่อครั้ง : 2000.00<br>\n" +
            "                                        แทงสูงสุดต่อเลข : 2000.00"
    },
    {
        title: "3 ตัวหน้า <div class=\"fa-pull-right\"><i class=\"fas fa-angle-down\"></i></div>",
        description: "แทงขั้นต่ำต่อครั้ง : 1.00<br>\n" +
            "                                        แทงสูงสุดต่อครั้ง : 2000.00<br>\n" +
            "                                        แทงสูงสุดต่อเลข : 2000.00"
    },
    {
        title: "2 ตัวบน <div class=\"fa-pull-right\"><i class=\"fas fa-angle-down\"></i></div>",
        description: "แทงขั้นต่ำต่อครั้ง : 1.00<br>\n" +
            "                                        แทงสูงสุดต่อครั้ง : 2000.00<br>\n" +
            "                                        แทงสูงสุดต่อเลข : 2000.00"
    },
    {
        title: "2 ตัวล่าง <div class=\"fa-pull-right\"><i class=\"fas fa-angle-down\"></i></div>",
        description: "แทงขั้นต่ำต่อครั้ง : 1.00<br>\n" +
            "                                        แทงสูงสุดต่อครั้ง : 2000.00<br>\n" +
            "                                        แทงสูงสุดต่อเลข : 2000.00"
    },
    {
        title: "วิ่งบน/วิ่งล่าง <div class=\"fa-pull-right\"><i class=\"fas fa-angle-down\"></i></div>",
        description: "แทงขั้นต่ำต่อครั้ง : 10.00<br>\n" +
            "                                        แทงสูงสุดต่อครั้ง : 2000.00<br>\n" +
            "                                        แทงสูงสุดต่อเลข : 2000.00"
    },
]

export const bet_type_arr = [
    'sam_tua_bon',
    'sam_tua_tod',
    'sam_tua_nha',
    'sam_tua_lang',
    'song_tua_bon',
    'song_tua_lang',
    'wing_bon',
    'wing_lang',
]

export const lotto_type_arr = [
    'yeekee',
    'government',
    'hanoy_special',
    'hanoy',
    'hanoy_vip',
    'lao',
    'malay',
]

export const LOTTO_SHORTCUT_MINUTE_TO_SHOW = {
    'government': 60*24*2,
    'hanoy': 60,
    'lao': 60,
    'malay': 60,
    'yeekee': 5,
    'hanoy_special': 60,
    'hanoy_vip': 60,
}

export const CHAT_ICON_CLASS = {
    government: 'box-msg-hThai',
    hanoy: 'box-msg-hHanoy',
    lao: 'box-msg-hLoa',
    malay: 'box-msg-hMalay',
    yeekee: 'box-msg-hitec',
    hanoy_special: 'box-msg-hHanoy-spachial',
    hanoy_vip: 'box-msg-hHanoy-vip',
}