<template>

    <div class="GameList GameListCasino">
        <AllProvider :cat="cat"></AllProvider>
        <div class="box-list-game-type">

            <div class="boxHiTech-game">
                <h4>ไฮเทค คาสิโน</h4>
                <ul>
                    <li @click="play(item)" v-for="(item, key) of quickTables" :key="key">
                        <img :src="item.icon"/>
                    </li>
                    <!-- <li><img src="@/assets/images/icon/set-icon/icon-baccarat-2.png"></li>
                    <li><img src="@/assets/images/icon/set-icon/icon-tiger-dagon.png"></li>
                    <li><img src="@/assets/images/icon/set-icon/icon-hilo-2.png"></li>
                    <li><img src="@/assets/images/icon/set-icon/icon-fantan.png"></li> -->
                </ul>
            </div>
            <div class="box-sublist-games sub-menu-casino">
                <div class="GameList-box">
                    <carousel
                            :per-page="4.8"
                            :mouseDrag="true"
                            :spacePadding="5"
                            :navigationEnabled="true"
                            :paginationEnabled="false"
                            navigationNextLabel="❯"
                            navigationPrevLabel="❮"
                    >
                        <slide>
                            <div @click="showDivByGameType('all')" class="sm-tab" v-bind:class="{ active: isBtnAll}">ทั้งหมด</div>
                        </slide>
                        <slide>
                            <div @click="showDivByGameType('baccarat')" class="sm-tab" v-bind:class="{ active: isBtnBaccarat}">บาคาร่า</div>
                        </slide>
                        <slide>
                            <div  @click="showDivByGameType('sicbo')" class="sm-tab" v-bind:class="{ active: isBtnSicbo}">ไฮโล</div>
                        </slide>
                        <slide>
                            <div  @click="showDivByGameType('dragontiger')" class="sm-tab" v-bind:class="{ active: isBtnDragontiger}">เสือมังกร</div>
                        </slide>
                        <slide>
                            <div  @click="showDivByGameType('roulette')"  class="sm-tab" v-bind:class="{ active: isBtnRoulette}">รูเล็ท</div>
                        </slide>
                        <slide>
                            <div @click="showDivByGameType('blackjack')" class="sm-tab" v-bind:class="{ active: isBtnBlackjack}">แบล็คเจ็ค</div>
                        </slide>
                        <slide>
                            <div  @click="showDivByGameType('other')" class="sm-tab" v-bind:class="{ active: isBtnOther}">อื่นๆ</div>
                        </slide>
                    </carousel>
                </div>
            </div>
            <div v-if="list2" class="wrapbox-list-games">
                <div class="GameList-box">
                    <div class="GameList-box-title">
                        <h4><img src="@/assets/images/slot/provider/PragmaticPlay.png" style="width: 30px;"> Pragmatic Play</h4>
                    </div>
                    <div class="flexListgame"  v-if="service_list.includes('pragmatic')">
                        <carousel
                                :per-page="2.2"
                                :mouseDrag="true"
                                :navigationEnabled="true"
                                :paginationEnabled="false"
                                navigationNextLabel="❯"
                                navigationPrevLabel="❮"
                                ref="carousel"
                                v-images-loaded="imageLoaded"
                        >
                            <Slide v-for="(game,key) of list2.casino[0]" :key="key" :class="game.cat_type" >
                                <div class="box-list-games" @click="openGame2(game,'pragmatic')">
                                    <div class="box-icon-games">
                                        <img :src="game.img">
                                    </div>
                                    <div class="box-detail-games">
                                        <h4>{{game.name}} </h4>
                                    </div>
                                </div>
                            </Slide>
                        </carousel>
                    </div>
                </div>
                <div class="GameList-box"   v-if="service_list.includes('sa')">
                    <div class="GameList-box-title">
                        <h4><img src="@/assets/images/slot/SAGaming-icon.png" style="width: 30px;"> SA Casino</h4>
                    </div>
                    <div class="content-sa-casino">
                        <div class="wrap-content-games-line">
                            <ul>
                                <li v-for="(game,key) of list2.casino[1]" :key="key" :class="game.cat_type">
                                    <div class="sa_btn" @click="openGame2(game,'sa')">
                                        <img :src="game.img"/>
                                        <div class="btn-play btn-play-y">เล่นเกม {{game.name}}</div>
                                    </div>
                                </li>
                            </ul>
                        </div>
                    </div>
                </div>
                <div class="GameList-box"  v-if="service_list.includes('sexy')">
                    <div class="GameList-box-title">
                        <h4><img src="@/assets/images/casino/logo-sexy-baccarat.png" style="width: 25px;"> Sexy Baccarat
                        </h4>
                    </div>
                    <div class="content-sa-casino">
                        <div class="wrap-content-games-line">
                            <ul>
                                <li  v-for="(game,key) of list2.casino[2]" :key="key" :class="game.cat_type">
                                    <div class="sa_btn" @click="openGame2(game,'sexy')">
                                        <img :src="game.img"/>
                                        <div class="btn-play btn-play-y">เล่นเกม  {{game.name}}</div>
                                    </div>
                                </li>
                            </ul>
                        </div>
                    </div>
                </div>

                <div class="wrapbox-list-games" style="display: none;">
                    <div
                            v-for="(game, key) of games"
                            :key="key"
                            class="box-list-games">
                        <div class="box-icon-games"><img :src="calIcon(key)"></div>
                        <div class="box-detail-games">
                            <h4>{{game.title}}</h4>
                            <div class="info-games">
                                {{game.description}} |
                                <strong class="text-info">
                                    {{getPlayingCount() | numberFormat}} คนกำลังเล่น
                                </strong>
                            </div>
                        </div>
                        <div class="box-btn">
                            <button @click="openGame(game)" class="btn btn-block btn-sm btn-info">เข้าสู่เกมส์</button>
                        </div>
                    </div>
                </div>

            </div>
            <HorizontalShortList
                    v-for="(provider, key) of providerList" :key="`${key}`"
                    :provider="provider"
                    :cat="cat"
                    @seeAll="openGameListModal">

            </HorizontalShortList>
        </div>


        <div class="GameList-mainGame" :class="{'GameListMainGameShow':isShowGameListModal}">
            <GamelistGameList v-if="currentProvider" :provider="currentProvider" :cat="cat" @close="closeGameListModal"></GamelistGameList>
        </div>

    </div>
</template>
<script>
    import { Carousel, Slide } from 'vue-carousel'
    import axiosWrapper from '@/axiosWrapper'
    import randomBetween from '@/lib/randomBetween'
    import store from '@/store'
    import UserMixin from '@/mixins/UserMixin'
    import $ from 'jquery'

    import  AllProvider from '@/components/AllProvider'
    import HorizontalShortList from '@/components/gamelist/HorizontalShortList'
    import GamelistGameList from '@/components/gamelist/GameList'

    import imagesLoaded from 'vue-images-loaded'
    window.jQuery = $;
    window.$ = $;

    import {TABLE_TYPE_MAP} from '@/components/game/Constant'
    const QUICK_TABLES = [0, 3, 1, 2]
    const QUICK_TABLE_ICONS = {
        0: '/images/icon/set-icon/icon-baccarat-22.png',
        1: '/images/icon/set-icon/icon-hilo-4.png',
        2: '/images/icon/set-icon/icon-fantan3.png',
        3: '/images/icon/set-icon/icon-tiger-dagon3.png',
    }

    export default {
        name: 'GameList',
        mixins: [UserMixin],
        directives: {
            imagesLoaded
        },
        components: {
            Carousel,
            Slide,
            AllProvider,
            HorizontalShortList,
            GamelistGameList,
        },
        data() {
            return {
                games: [],
                icons: [
                    '/images/icon/set-icon/icon-casino.png',
                    '/images/icon/set-icon/icon-slot.png',
                    '/images/icon/set-icon/icon-casino.png',
                    '/images/icon/set-icon/icon-football.png',
                    '/images/icon/set-icon/icon-fishing.png',
                    '/images/icon/set-icon/icon-lotto.png',
                    '/images/icon/set-icon/icon-roulette.png',
                    '/images/icon/set-icon/icon-case.png',
                ],
                selectedBrand: 'queenmaker',
                base_pes_url : '',
                isBtnAll:true,
                isBtnBaccarat:false,
                isBtnRoulette:false,
                isBtnDragontiger:false,
                isBtnBlackjack:false,
                isBtnSicbo:false,
                isBtnOther:false,
                list2:null,
                service_list : `${process.env.VUE_APP_PES_SERVICE_LIST}`.split(","),
                quickTables: [],
                cat: null,
                currentProvider: null,
                providerList: [
                    {
                        slug: 'SEXY',
                        label: 'Sexy Baccarat',
                        image: '/images/slot/provider/SexyBaccarat-icon.png',
                        wrapperClass: 'GameList-box-square',
                        perPage : 2.8
                    },
                    {
                        slug: 'PRETTY',
                        label: 'Pretty',
                        image: '/images/slot/provider/PRETTY.png',
                        wrapperClass: 'GameList-box-wide',
                    },
                    {
                        slug: 'EVO',
                        label: 'Evolution Gaming',
                        image: '/images/slot/provider/EvolutionGaming.png',
                        wrapperClass: 'GameList-box-wide',
                    },
                ],
                isShowGameListModal: false,
            }
        },
        computed: {
            is_member() {
                return store.state.is_member
            },
            pes_profile() {
                return store.state.pes_profile
            },
            token() {
                return this.pes_profile.token
            },
            getAllGameList(){
                return store.state.allGameList;
            },
            appImageProfile(){
                return store.state.appInfo.app_image_profile
            },
        },
        methods: {
            imageLoaded() {
                if(!this.$refs.carousel) {
                    return
                }
                this.$refs.carousel.computeCarouselWidth()
            },
            openGame2(game,service){
                if (!this.is_member) {
                    this.notifyToSignUp('เพื่อเล่น')
                    return
                }
                var params =[
                    
                ];
                
                if(service=='pragmatic'){
                    params.push('code='+game.gameId);
                }else if(service=='sa'){
                    params.push('code='+game.code);
                }
                if(this.appImageProfile){
                    params.push('logo_img='+this.appImageProfile);
                }
                const url = `${this.envDomains.VUE_APP_PES_BASE_URL}/game/casinoonline/sa_sexy_redirect.php?mode=mobile&service=${service}&${params.join('&')}&token=${this.token}`
                if(service=='sa'){
                    window.location.href=url
                }else{
                    this.$router.push({ name: 'GamePlayFrame', "query": { 'url': encodeURIComponent(url) } })
                }
                
                
            },
            async getGameList(brand = 'queenmaker') {

                const res = await axiosWrapper({
                    method: 'post',
                    url: `${this.envDomains.VUE_APP_API}/api/game/list`,
                    data: {
                        brand
                    }
                })

                if (!res.data.success) {
                    this.$alert(res.data.message, 'แจ้งเตือน', {
                        confirmButtonText: 'ตกลง',
                        customClass: 'custom-message-box'
                    })
                    return
                }

                return res.data.data
            },
            calIcon(key) {
                return this.icons[key % this.icons.length]
            },
            getPlayingCount() {
                return randomBetween(111, 1111)
            },
            openGame(game) {

                if (!this.is_member) {
                    this.notifyToSignUp('เพื่อเล่น')
                    return
                }

                const baseUrl = `/game/casinoonline/sa_sexy_redirect.php?service=${this.selectedBrand}`

                let query = ''
                if (this.selectedBrand === 'queenmaker') {
                    query = `&gpcode=${game.gpcode}&gcode=${game.gcode}`
                } else if (this.selectedBrand === 'kp') {
                    query = `&gameId=${game.gameId}`
                } else if (this.selectedBrand === 'cqgame') {
                    query = `&gamehall=${game.gamehall}&gamecode=${game.gamecode}`
                }
                if(this.appImageProfile){
                    query = query+'&logo_img='+this.appImageProfile
                }

                const url = `${this.envDomains.VUE_APP_PES_BASE_URL}${baseUrl}${query}&token=${this.token}`
                window.open(url, '_blank')
            },
            getPesBaseUrl(){
                return `${this.envDomains.VUE_APP_PES_BASE_URL}`;

            },
            getPesToken(){
                return this.token;
            },
            showDivByGameType(type){
        
        if(type!='all'){
            $('.baccarat,.dragontiger,.sicbo,.roulette,.blackjack,.other').hide();
            this.isBtnAll=false;
            this.isBtnBaccarat=false;
            this.isBtnRoulette=false;
            this.isBtnBlackjack=false;
            this.isBtnSicbo=false;
            this.isBtnDragontiger=false;
            this.isBtnOther=false;
            $('.'+type).show();
            this['isBtn'+type.charAt(0).toUpperCase() + type.slice(1)] =true;
        }else{
            $('.baccarat,.dragontiger,.sicbo,.roulette,.blackjack,.other').show();
            this.isBtnAll=true;
            this.isBtnBaccarat=false;
            this.isBtnRoulette=false;
            this.isBtnBlackjack=false;
            this.isBtnSicbo=false;
            this.isBtnDragontiger=false;
            this.isBtnOther=false;
        } 
        },
            play(item) {
                this.$router.push({name: item.routeName})
            },
            openGameListModal(provider) {
                this.currentProvider = provider
                this.isShowGameListModal = true
            },
            closeGameListModal() {
                this.currentProvider = null
                this.isShowGameListModal = false
            },
            async getGameServiceList() {
               const res = await axiosWrapper({
                    method: 'get',
                    url: `${this.envDomains.VUE_APP_PES_BASE_URL}/api/game/v0/slot/get.php`,
                })
                return res.data;
            },
        },
        created() {
            this.base_pes_url = this.envDomains.VUE_APP_PES_BASE_URL
        },
        async mounted() {

            // this.list2=this.getAllGameList;
            // var self= this;
            // if(this.list2==null){
            //     var clearInv=setInterval(function(){
            //         self.list2 = self.getAllGameList;
            //         if(self.list2!=null){
            //             clearInterval(clearInv);
            //         }

            //     },500)
            // }
            this.list2 =await  this.getGameServiceList();

            this.quickTables = QUICK_TABLES.map((table) => {
                return {
                    title: TABLE_TYPE_MAP[table].title,
                    routeName: TABLE_TYPE_MAP[table].routeName,
                    icon: QUICK_TABLE_ICONS[table]
                }
            })

        },
    }

    
</script>