<template>
    <div class="TableOdd">
        <div class="balance-text">
                    <span v-if="user && user.balances">
<!--                        จำนวนเงินของคุณ THB -->
                        {{user.balances[0]['THB_free'] | toFixed(2) | comma(2) }}
                    </span>
        </div>
        <div class="TableOdd-top">
            <div class="scrolling-wrapper-flexbox">
                <div @click="selectSport(sport.slug, sport.sport)" v-for="sport of sportList"
                     :key="sport.slug"
                     class="card-list"
                     :class="{'active': sport.sport === sport_selected_name}">
                    <div class="list-sport">
                        <span :class="'icon-'+sport.slug"></span>
                    </div>
                </div>
            </div>
            <div class="title-sport">
                <h4> <span :class="'icon-'+sport_selected"></span> {{sport_selected_name}}</h4>
                <div class="title-sport-group-header">
                    <el-button @click="openDrawer('my-bet')" type="small" style="margin: 0;">My Bets</el-button>
                    <div class="diy-space small"></div>
                    <el-button @click="openDrawer('result')" type="small" style="margin: 0;">Result</el-button>
                    <div class="diy-space small"></div>
                    <el-button @click="openDrawer('announcement')" type="small" style="margin: 0;">Announcement</el-button>
                </div>
            </div>
            <div class="group-header-wraper">
                <div class="group-header">
                    <!--                <h5>Filter</h5>-->
                    <!--                <div class="diy-enter small"></div>-->
                    <div class="flex justify-center">
                        <!--                    <div class="diy-space"></div>-->
                        <el-select v-model="sortBy" size="mini">
                            <el-option label="sort normal" value="normal">
                            <span>
                                <img src="@/assets/images/soccer-game/sort-normal.png" style="height: 12px;">
                                <span class="diy-space small"></span>
                                sort normal
                            </span>
                            </el-option>
                            <el-option label="sort by time" value="time">
                            <span>
                                <img src="@/assets/images/soccer-game/sort-time.png" style="height: 12px;">
                                <span class="diy-space small"></span>
                                sort by time
                            </span>
                            </el-option>
                        </el-select>
                        <!--                    <div class="diy-space small"></div>-->
                        <el-select v-model="lineMode" size="mini">
                            <el-option label="Single Line" value="single">
                            <span>
                                <img src="@/assets/images/soccer-game/single-line.png" style="height: 12px;">
                                <span class="diy-space small"></span>
                                Single Line
                            </span>
                            </el-option>
                            <el-option label="Double Line" value="double">
                            <span>
                                <img src="@/assets/images/soccer-game/double-line.png" style="height: 12px;">
                                <span class="diy-space small"></span>
                                Double Line
                            </span>
                            </el-option>
                        </el-select>
                        <!--                    <div class="diy-space small"></div>-->
                        <el-select v-model="odd_style" size="mini">
                            <el-option label="มาเลย์" value="MY">
                            <span>
                                <img src="@/assets/images/soccer-game/my.png" style="height: 12px;">
                                <span class="diy-space small"></span>
                                มาเลย์
                            </span>
                            </el-option>
                            <el-option label="ฮ่องกง" value="HK">
                            <span>
                                <img src="@/assets/images/soccer-game/hk.png" style="height: 12px;">
                                <span class="diy-space small"></span>
                                ฮ่องกง
                            </span>
                            </el-option>
                            <el-option label="ยูโร" value="EU">
                            <span>
                                <img src="@/assets/images/soccer-game/oo.png" style="height: 12px;">
                                <span class="diy-space small"></span>
                                ยูโร
                            </span>
                            </el-option>
                        </el-select>
                        <!--                    <div class="diy-space"></div>-->
                        <el-button @click="showLeagueFilterModal" icon="fas fa-check" plain size="small">
                            <span>({{selectLeagueLength}}/{{allLeagueLength}})leagues</span>
                        </el-button>
                    </div>
                </div>

                <div>


                </div>
            </div>
        </div>


        <div v-if="lives && lives.length > 0">
            <div class="topic-table topic-table-live flex"
                 style="padding: 1px 2px; font-size: 14px;">
                <div>Today - Live</div>
                <span class="nbsp"></span>
                <div>
                    <RefreshButton start_seconds="20" v-on:click="refresh('live')"></RefreshButton>
                </div>
                <div></div>
            </div>
            <TableHeader v-on:scroll="horizontalScroll" v-if="header_position_fix === 0" :class="`custom-header-sticky top-${top_offset}`" :lineMode="lineMode" mode="live"/>
            <div v-on:scroll="horizontalScroll" class="scrolling-wrapper-sport">
                <div class="scrolling-info-sport">
                    <TableHeader v-if="header_position_fix !== 0" :lineMode="lineMode" mode="live"/>
                    <div class="table-header-position"></div>
                    <div v-for="(league, i) in lives" :key="league.match_id+'live'">
                        <TableOddRow v-if="league.isShow"
                                     :lineMode="lineMode"
                                     mode="live"
                                     :league="league"
                                     :i="i"
                                     :header_position_fix="sub_header_position_fix"/>
                    </div>
                </div>
            </div>
        </div>

        <div v-if="!$route.query.mode || $route.query.mode !== 'live'">
            <div class="topic-table topic-table-nonLive flex"
                style="padding: 1px 2px; font-size: 14px;">
                <div>Today - Non-Live</div>
                <span class="nbsp"></span>
                <div>
                    <RefreshButton v-on:click="refresh('non-live')"></RefreshButton>
                </div>
                <div></div>
            </div>
            <TableHeader v-on:scroll="horizontalScroll" v-if="header_position_fix === 1" :class="`custom-header-sticky top-${top_offset}`" :lineMode="lineMode" mode="nonlive"/>
            <div v-if="nonlives && nonlives.length > 0">
                <div v-on:scroll="horizontalScroll" class="scrolling-wrapper-sport">
                    <div class="scrolling-info-sport">
                        <TableHeader v-if="header_position_fix !== 1" :lineMode="lineMode" mode="nonlive"/>
                        <div class="table-header-position"></div>
                        <div v-for="(league, i) in nonlives" :key="league.match_id+'nonlive'">
                            <TableOddRow v-if="league.isShow"
                                        :lineMode="lineMode"
                                        mode="nonlive"
                                        :league="league"
                                        :i="i+lives.length"
                                        :header_position_fix="sub_header_position_fix"/>
                        </div>
                    </div>
                </div>
            </div>
            <div v-else>
                <div style="text-align: center; width: 100%; padding-top: 10px;">ไม่มีคู่แมทช์การแข่งขัน</div>
            </div>
        </div>

        <modal name="v-drawer" class="editModalMyBet" transition="modal-slide" :adaptive="true" width="100%" height="100%"
               style="z-index: 999;">
            <div class="v-drawer-wrapper">
                <div class="v-drawer-inner">
                    <br>
                    <Result v-if="drawer_page === 'result'"></Result>
                    <MyBet v-if="drawer_page === 'my-bet'"></MyBet>
                    <Announcement v-if="drawer_page === 'announcement'"></Announcement>
                    <el-button
                    @click="closeDrawer"
                    icon="el-icon-close"
                    circle
                    style="position: absolute; top: 10px; right: 10px;">    
                    </el-button>
                </div>
            </div>
        </modal>

        <modal name="league-filter" :pivotY="0.6" height="60%" width="90%" class="content-league-filter">
            <div class="modal-content-wrapper">
                <h3>
                    <span class="icon"><i class="fas fa-list"></i></span>
                    &nbsp;League Lists
                </h3>
                <div class="league-item-wrapper">
                    <input type="checkbox" id="selectAll" v-model="isShowAllLeague">
                    <label for="selectAll">---- All League ----</label>
                </div>
                <div class="league-item-wrapper" v-for="league in lives" :key="league.match_id">
                    <input type="checkbox" :id="league.league_id" v-model="league.isShow">
                    <label :for="league.league_id">{{ league.league_name }}</label>
                </div>
                <div class="league-item-wrapper" v-for="league in nonlives" :key="league.match_id">
                    <input type="checkbox" :id="league.league_id" v-model="league.isShow">
                    <label :for="league.league_id">{{ league.league_name }}</label>
                </div>
            </div>
        </modal>
    </div>

</template>

<script>

    import _filter from 'lodash/filter'
    import _find from 'lodash/find'
    import _sortBy from 'lodash/sortBy'
    import _concat from 'lodash/concat'
    import TableOddRow from './TableOddRow.vue'
    import RefreshButton from './RefreshButton.vue'
    import TableHeader from './TableHeader.vue'
    import moment from 'moment'
    import store from '@/store'
    import debounce from '@/debounce'
    import axiosWrapper from '@/axiosWrapper'
    import Result from './drawers/Result.vue'
    import MyBet from './drawers/MyBet.vue'
    import Announcement from './drawers/Announcement.vue'
    import {mixin as VueTimers} from 'vue-timers'
    import { SPORT_ARR } from '@/constant'

    export default {
        name: 'TableOdd',
        mixins: [VueTimers],
        props: [
            'top_offset'
        ],
        components: {
            TableOddRow,
            RefreshButton,
            TableHeader,
            Result,
            MyBet,
            Announcement,
        },
        timers: {
            updateLives: {
                time: 10000,
                repeat: true,
            },
            updateNonLives: {
                time: 10000,
                repeat: true,
            },
        },
        data: function () {
            return {
                raws_live: [],
                raws_nonlive: [],
                lives: [],
                nonlives: [],
                v_live: 0,
                v_nonlive: 0,
                sortBy: 'normal',
                leagues: [],
                isShowAllLeague: true,
                lineMode: 'single',
                header_position_list: [],
                header_position_fix: -1,
                sub_header_position_list: [],
                sub_header_position_fix: -1,
                drawer_page: "",
                sport_selected_name: 'Football',
                sportList: [
                    {
                        sport: 'Football',
                        slug: 'football',
                        count: 0
                    },
                    {
                        sport: 'Basketball',
                        slug: 'basketball',
                        count: 0
                    },
                    {
                        sport: 'Tennis',
                        slug: 'tennis',
                        count: 0
                    },
                    {
                        sport: 'Volleyball',
                        slug: 'volleyball',
                        count: 0
                    },
                    {
                        sport: 'Badminton',
                        slug: 'badminton',
                        count: 0
                    },
                    {
                        sport: 'Muaythai',
                        slug: 'muaythai',
                        count: 0
                    },
                    {
                        sport: 'Boxing',
                        slug: 'boxing',
                        count: 0
                    },
                ],
                dropdown_active: ''
            };
        },
        watch: {
            isShowAllLeague: function (newVal) {
                for (let i = 0; i < this.lives.length; i++) {
                    this.lives[i].isShow = newVal;
                }
                for (let i = 0; i < this.nonlives.length; i++) {
                    this.nonlives[i].isShow = newVal;
                }
            },
            lineMode: function () {
                localStorage.setItem('lineMode', this.lineMode);
            },
            sortBy: function(newVal) {
                if(newVal) {
                    this.sort(newVal)
                }
            },
            seconds_interval: function (newVal) {
                this.timers.updateLives.time = newVal;
                this.timers.updateNonLives.time = newVal;
                this.restartTimer();
            },
            sport_selected: function () {
                store.dispatch('football/cancelBetAll');
                this.getLives();
                this.getNonLives();
            },
        },
        computed: {
            seconds_interval: function () {
                return store.state.football.seconds_interval;
            },
            bet_list: function () {
                return store.state.football.bet_list;
            },
            allLeagueLength: function () {
                return this.lives.length + this.nonlives.length;
            },
            selectLeagueLength: function () {

                let livesLength = _filter(this.lives, function (o) {
                    return o.isShow;
                });
                let nonlivesLength = _filter(this.nonlives, function (o) {
                    return o.isShow;
                });

                if (this.lives.length > 0) {
                    localStorage.setItem('data_live', JSON.stringify(this.lives));
                }
                if (this.nonlives.length > 0) {
                    localStorage.setItem('data_nonlive', JSON.stringify(this.nonlives));
                }

                return livesLength.length + nonlivesLength.length;
            },
            odd_style: {
                get() {
                    return store.state.football.odd_style;
                },
                set(value) {
                    store.commit('football/changeOddStyle', value);
                }
            },
            odd_style_eu_only: function () {
                return store.state.football.odd_style_eu_only;
            },
            raws_all: function () {
                return _concat(this.raws_live, this.raws_nonlive);
            },
            accept_any_odd: function () {
                return store.state.football.accept_any_odd;
            },
            sport_selected: function () {
                return store.state.football.sport_selected;
            },
            favorite_list: function () {
                return store.state.football.favorite_list;
            },
            isMobile: function () {
                return true;
            },
            user: function () {
                return store.state.pes_profile;
            },
        },
        methods: {
            restartTimer: function () {
                this.$timer.stop('updateLives');
                this.$timer.stop('updateNonLives');
                this.$timer.start('updateLives');
                this.$timer.start('updateNonLives');
            },
            matchTimeOutScreen: async function(raws, mode = 'live'){

                const timeout_minute = 110;

                let data = [];
                for(let raw of raws){
                    // console.log('kickoff_time', moment(raw.match_kickoff_time).format('YYYY-MM-DD HH:mm'));
                    // console.log('current_time', moment().format('YYYY-MM-DD HH:mm'));
                    // console.log('timeout_time', moment(raw.match_kickoff_time).add(timeout_minute, 'minutes').format('YYYY-MM-DD HH:mm'));
                    let timeout = moment(raw.match_kickoff_time);
                    if(mode === 'live'){
                        timeout = timeout.add(timeout_minute, 'minutes');
                    }

                    if(moment() < timeout){
                        data.push(raw);
                    }
                }
                return data;
            },
            extractData: async function(raws, mode="nonlive"){

                raws = await this.matchTimeOutScreen(raws, mode);

                let oldData = JSON.parse(localStorage.getItem('data_' + mode));

                const leagues = [];

                if (this.sortBy === 'time') {
                    for (let i = 0; i < raws.length; i++) {
                        raws[i].timestampToSort = moment(raws[i].match_kickoff_time).valueOf();
                    }
                    raws = _sortBy(raws, [function (o) {
                        return o.timestampToSort;
                    }]);
                }

                for (let i = 0; i < raws.length; i++) {

                    let raw = raws[i];
                    let league = _find(leagues, function (o) {
                        return o.league_id === raw.league_id;
                    });

                    if (mode === "live") {
                        raw.timestamp = raw.live_minute;
                    } else if (mode === "nonlive") {
                        raw.timestamp = moment(raw.match_kickoff_time).format("HH:mm");
                    }

                    let odd_list = [];

                    let odds = raw.odds;

                    let hdps = _filter(odds, function (o) {
                        return o.odds_type === "HDP";
                    });
                    let goals = _filter(odds, function (o) {
                        return o.odds_type === "GOAL";
                    });
                    let _1x2s = _filter(odds, function (o) {
                        return o.odds_type === "1X2";
                    });
                    let oes = _filter(odds, function (o) {
                        return o.odds_type === "OE";
                    });
                    let fh_hdps = _filter(odds, function (o) {
                        return o.odds_type === "FH.HDP";
                    });
                    let fh_goals = _filter(odds, function (o) {
                        return o.odds_type === "FH.GOAL";
                    });
                    let fh_1x2s = _filter(odds, function (o) {
                        return o.odds_type === "FH.1X2";
                    });

                    // console.log('hdps', hdps);
                    // console.log('goals', goals);
                    // console.log('fh_goals', fh_goals);

                    for (let j = 0; j < hdps.length; j++) {
                        // let odd = odds[j];

                        let timestamp = "";
                        let live_half = "";
                        let match_home_name = "";
                        let match_away_name = "";
                        let live_home_red_card = 0;
                        let live_away_red_card = 0;

                        if (j === 0) {
                            timestamp = raw.timestamp;
                            live_half = raw.live_half;
                            live_home_red_card = raw.live_home_red_card;
                            live_away_red_card = raw.live_away_red_card;
                            match_home_name = raw.match_home_name;
                            match_away_name = raw.match_away_name;
                        }

                        let blank_odd = {
                            "odds_id": "",
                            "odds_mid": "",
                            "odds_type": "",
                            "odds_x2": "",
                            "odds_x3": "",
                            "odds_hdp": "",
                            "odds_n1": "",
                            "odds_n2": "",
                            "sbo_odds_id": "",
                        };

                        // hdps[0].odds_n1 -= Math.random();
                        odd_list.push({
                            odd_info: {
                                league_id: raw.league_id,
                                league_name: raw.league_name,
                                match_id: raw.match_id,
                                kickoff_time: raw.match_kickoff_time,
                                info_home_score: raw.info_home_score || '',
                                info_away_score: raw.info_away_score || '',
                                match_home_name: raw.match_home_name,
                                match_away_name: raw.match_away_name,
                            },
                            match_home_name: match_home_name,
                            match_away_name: match_away_name,
                            timestamp: timestamp,
                            live_half: live_half,
                            live_home_red_card: live_home_red_card,
                            live_away_red_card: live_away_red_card,
                            hdp: hdps[j],
                            goal: goals[j] || blank_odd,
                            _1x2: _1x2s[j] || blank_odd,
                            oe: oes[j] || blank_odd,
                            fh_hdp: fh_hdps[j] || blank_odd,
                            fh_goal: fh_goals[j] || blank_odd,
                            fh_1x2: fh_1x2s[j] || blank_odd,
                        })
                    }

                    if (league) {
                        league.odd_list = _concat(league.odd_list, odd_list)
                    } else {

                        let isShow = true;

                        let oldDataLeague = _find(oldData, function (o) {
                            return o.league_id === raw.league_id;
                        });

                        if (oldDataLeague) {
                            isShow = oldDataLeague.isShow;
                        }

                        leagues.push({
                            league_id: raw.league_id,
                            league_name: raw.league_name,
                            match_id: raw.match_id,
                            isShow: isShow,
                            odd_list: odd_list
                        })
                    }
                }

                localStorage.setItem('data_' + mode, JSON.stringify(leagues));

                return leagues;
            },
            updateOdd: async function (raws_update, mode) {

                for (let i = 0; i < raws_update.length; i++) {

                    let odds_update = raws_update[i];

                    let found_match;

                    if (mode === 'live') {
                        found_match = _find(this.raws_live, function (o) {
                            return o.match_id === odds_update.match_id
                        });
                    } else {
                        found_match = _find(this.raws_nonlive, function (o) {
                            return o.match_id === odds_update.match_id
                        });
                    }

                    if (found_match) {

                        let found_odd = _find(found_match.odds, function (o) {
                            return o.odds_id === odds_update.odds_id
                        });

                        if (found_odd) {

                            if (odds_update.odds_n1) {
                                found_odd.odds_n1 = odds_update.odds_n1;
                            }
                            if (odds_update.odds_n2) {
                                found_odd.odds_n2 = odds_update.odds_n2;
                            }
                            if (odds_update.odds_n3) {
                                found_odd.odds_n2 = odds_update.odds_n2;
                            }
                        }
                    }
                }
            },
            updateBetList: debounce(function (raws) {

                for (let i = 0; i < this.bet_list.length; i++) {
                    let bet = this.bet_list[i];
                    let newMatch = _find(raws, function (o) {
                        return o.match_id === bet.match_id;
                    });
                    if (newMatch) {
                        let newOdd = _find(newMatch.odds, function (o) {
                            return o.odds_id === bet.odd_id;
                        });
                        if (newOdd) {
                            if (this.accept_any_odd) {
                                bet.odds = newOdd[bet.odd_name];
                            } else {
                                bet.odds_changed = newOdd[bet.odd_name];
                            }
                            store.commit('football/updateBetSamePos', {
                                i: i,
                                bet: bet
                            })
                        }
                    } else {
                        store.dispatch('football/cancelBet', bet);
                    }
                }
            }, 1000),
            updateFavoriteList: debounce(function (raws) {

                let _this = this;

                let favorite_list = _filter(this.favorite_list, function (o) {
                    return o.sport === _this.sport_selected;
                });

                for (let i = 0; i < favorite_list.length; i++) {
                    let d = favorite_list[i];
                    let found;
                    if (d.mode === 'league') {
                        found = _find(raws, function (o) {
                            return o.league_id === d.fav_id;
                        });
                    } else if (d.mode === 'match') {
                        found = _find(raws, function (o) {
                            return o.match_id === d.fav_id;
                        });
                    }
                    if (!found) {
                        store.dispatch('football/unFavorite', d);
                    }
                }
            }, 1000),
            get_position_list: function (selector) {
                let row_list = Array.prototype.slice.call(document.querySelectorAll(selector));
                return row_list.map((row) => {
                    return row.offsetTop
                });
            },
            update_header_position_list: debounce(function () {
                this.header_position_list = this.get_position_list('.table-header-position');
            }, 1000),
            update_sub_header_position_list: debounce(function () {
                this.sub_header_position_list = this.get_position_list('.sub-header-position');
            }, 1000),
            getLives: async function () {

                if (this.sport_selected === 'football') {
                    const res_live = await axiosWrapper({
                        method: 'GET',
                        url: `${this.envDomains.VUE_APP_123API}/match/live`//'/non-live.json'
                    });
                    this.raws_live = res_live.data.data;
                    this.lives = await this.extractData(res_live.data.data, "live");
                    if (this.bet_list.length > 0) {
                        this.updateBetList(this.raws_all);
                    }
                    if (this.favorite_list.length > 0) {
                        this.updateFavoriteList(this.raws_all);
                    }
                    this.restartTimer();
                } else {
                    this.lives = [];
                }
                this.update_sub_header_position_list();
            },
            updateLives: async function () {

                if (this.sport_selected === 'football') {
                    const res = await axiosWrapper({
                        method: 'get',
                        url: `${this.envDomains.VUE_APP_123API}/match/live/update?v=${this.v_live}`
                    });
                    const data = res.data;
                    if (data.version) {
                        this.v_live = data.version;
                    }
                    if (data.data) {
                        if (data.data.odds_update) {
                            await this.updateOdd(data.data.odds_update, 'live');
                            this.lives = await this.extractData(this.raws_live, "live");
                            if (this.bet_list.length > 0) {
                                this.updateBetList(this.raws_all);
                            }
                        }

                    }
                }
            },
            getNonLives: async function () {

                let url;

                if (this.sport_selected === 'football') {
                    url = `${this.envDomains.VUE_APP_123API}/match/non-live`;
                } else {
                    url = `${this.envDomains.VUE_APP_123API}/sport/${this.sport_selected}/match`
                }

                const res_nonlive = await axiosWrapper({
                    method: 'GET',
                    url: url//'/non-live.json'
                });
                this.raws_nonlive = res_nonlive.data.data;
                this.nonlives = await this.extractData(res_nonlive.data.data);
                if (this.bet_list.length > 0) {
                    this.updateBetList(this.raws_all);
                }
                if (this.favorite_list.length > 0) {
                    this.updateFavoriteList(this.raws_all);
                }
                this.restartTimer();

                this.update_sub_header_position_list();
            },
            updateNonLives: async function () {

                if (this.sport_selected === 'football') {
                    const res = await axiosWrapper({
                        method: 'get',
                        url: `${this.envDomains.VUE_APP_123API}/match/non-live/update?v=${this.v_nonlive}`
                    });
                    const data = res.data;
                    if (data.version) {
                        this.v_nonlive = data.version;
                    }
                    if (data.data) {
                        if (data.data.odds_update) {
                            await this.updateOdd(data.data.odds_update, 'nonlive');
                            this.nonlives = await this.extractData(this.raws_nonlive);
                            if (this.bet_list.length > 0) {
                                this.updateBetList(this.raws_all);
                            }
                        }
                    }
                } else {
                    this.getNonLives();
                }
            },
            refresh: debounce(function (mode) {
                if (mode === 'live') {
                    this.getLives();
                } else if (mode === 'non-live') {
                    this.getNonLives();
                }
            }, 1000),
            sort(mode){
                this.sortBy = mode;
                this.refresh('live');
                this.refresh('non-live');
                localStorage.setItem('sortBy', mode);
                this.dropdown_active = '';
            },
            selectLineMode(mode){
                this.lineMode = mode;
                this.dropdown_active = '';
            },
            selectOddStyle(odd_style){
                store.commit('football/changeOddStyle', odd_style);
                this.dropdown_active = '';
            },
            showLeagueFilterModal () {
                this.$modal.show('league-filter');
            },
            hideLeagueFilterModal () {
                this.$modal.hide('league-filter');
            },
            get_sticky_position: function (header_position_list, header_offset) {

                if (header_position_list.length > 0) {

                    if (window.pageYOffset + header_offset < header_position_list[0]) {
                        return -1;
                    }

                    if (window.pageYOffset + header_offset > header_position_list[header_position_list.length - 1]) {
                        return header_position_list.length - 1;
                    }

                    for (let i = 0; i < header_position_list.length - 1; i++) {
                        let header_position = header_position_list[i];
                        let header_position_next = header_position_list[i + 1];
                        if (window.pageYOffset + header_offset >= header_position && window.pageYOffset + header_offset < header_position_next) {
                            return i;
                        }
                    }
                }
            },
            handleScroll: debounce(function () {

                let header_offset = 50 + 12;
                // let sub_header_offset = 50+30+22;

                if (this.isMobile) {
                    // header_offset = 42 + 12;
                    header_offset = 12;
                    if(this.top_offset){
                        header_offset += parseInt(this.top_offset);
                    }
                    // sub_header_offset = 45+32+30+22;
                }

                this.header_position_fix = this.get_sticky_position(this.header_position_list, header_offset);
                // this.sub_header_position_fix = this.get_sticky_position(this.sub_header_position_list, sub_header_offset);
            }, 10),
            horizontalScroll: function (e) {
                if (this.isMobile) {
                    let header_scroll_list = Array.prototype.slice.call(document.querySelectorAll('.custom-header-sticky'));
                    let body_scroll_list = Array.prototype.slice.call(document.querySelectorAll('.scrolling-wrapper-sport'));
                    header_scroll_list.map((scroll_item) => {
                        scroll_item.scrollLeft = e.target.scrollLeft;
                    });
                    body_scroll_list.map((scroll_item) => {
                        scroll_item.scrollLeft = e.target.scrollLeft;
                    });
                }
            },
            openDrawer: function (drawer_page) {
                this.drawer_page = drawer_page;
                this.$modal.show('v-drawer');
            },
            closeDrawer: function () {
                this.drawer_page = "";
                this.$modal.hide('v-drawer');
            },
            selectSport: function (slug, sport) {
                this.sport_selected_name = sport;
                store.commit('football/updateSportSelected', slug);
                store.commit('football/updateFavoriteSelected', '');
            }
        },
        created() {
            if (localStorage.getItem('sortBy')) {
                this.sortBy = localStorage.getItem('sortBy');
            }
            if (localStorage.getItem('lineMode')) {
                this.lineMode = localStorage.getItem('lineMode');
            }
            this.sport_arr = SPORT_ARR
        },
        mounted () {
            this.getLives();
            this.getNonLives();

            this.update_header_position_list();
            window.addEventListener('scroll', this.handleScroll);
        }
    }
</script>

<style scoped>

    .scrolling-wrapper-sport {
        font: 14px Helvetica!important;
    }
    .scrolling-wrapper-sport .v-row{
        font: 14px Helvetica!important;
    }
    .title-sport {
        display: flex;
        width: 100%;
        text-align: left;
        justify-content: space-between;
        margin: 0 5px 5px 5px;
    }
    .title-sport h4 span{
        display: inline-block;
        vertical-align: bottom;
        position: relative;
        top: 2px;
    }
    .title-sport h4{
        font: 800 1.2rem/1.2rem Athiti;
        padding: 10px 0;
        margin: 0;
    }
    .title-sport .el-button{
        display: inline-block;
        padding: 1px 9px!important;
        border-radius: 10px;
        height: 30px;
        background-color: #EDE6F2;
        color: #54117F;
        font-weight: 800;
    }
    .title-sport-group-header{margin: 5px 10px 0 0; display: flex;}
    .group-header{
        margin: 0 5px 5px 5px;
    }

    .group-header .el-select:nth-child(2){margin-left: 5px;}
    .group-header .el-select:nth-child(2),
    .group-header .el-select:nth-child(3){
    margin-right: 5px;
    }
    .group-header .el-button{
        height: 28px;
    }
    .group-header h5{
        display: inline-block;
        font-size: 14px;
        /*background-color: #EDE6F2;*/
        margin: 0;
        vertical-align: top;
    }
    .group-header h5:before{
        vertical-align: top;
        content: '\ef4f';
        display: inline-block;
        font:  14px "Material Icons";
    }
    .scrolling-wrapper-flexbox {
        display: flex;
        flex-wrap: nowrap;
        overflow-x: auto;
        justify-content: space-between;
        /*height: 50pt;*/
        /*background-color: #f5f5f5;*/
        color: #cdd3d9;
        position: relative;
        padding: 5px 0 0 5px;
        /*border-bottom: solid 1px #ccc;*/
        /*-webkit-box-shadow: 0 8px 6px -8px #aaa;*/
        /*box-shadow: 0 8px 6px -8px #aaa;*/
        z-index: 1;
    }
    .scrolling-wrapper-flexbox .active{
        /*transform: scale(1.2);*/
        color: white;
    }
    .scrolling-wrapper-flexbox .active span{
        background-color: var(--main-color);

    }
    .card-list.active:after{
        /*content: '';*/
        /*position: absolute;*/
        /*left: 0;*/
        /*bottom: 0;*/
        /*right: 0;*/
        /*height: 5px;*/
        /*background-color: #000;*/
    }
    .card-list{
        flex: 0 0 auto;
        text-align: center;
        position: relative;
        padding: 0 1vw;
    }
    .list-sport {
        margin: 0 auto;
        display: block;
        /*background-color: #aaa;*/
        /*border-radius: 50px;*/
    }
    .list-sport span.icon-boxing{font-size: 30px;}
    .card-list span{
        cursor: pointer;
        height: 37px;
        width: 37px;
        border-radius: 50%;
        border:  solid 1px rgba(205, 211, 217, 0.54);
        background-color: transparent;
        display: inline-block;
        font-size: 30px;
        line-height: 30pt;
        animation: scale-in-center 0.5s cubic-bezier(0.680, -0.550, 0.265, 1.550) both;
    }
    .card-list span:hover{
        transition: all .3s;
        border:  solid 1px rgba(42, 43, 48, 0.95);
    }
    @keyframes scale-in-center {
        0% {transform: scale(0);opacity: 1;}
        100% {transform: scale(1);opacity: 1;}
    }
    .modal-content-wrapper {
        padding: 10px;
        /*border: solid 2px black;*/
        overflow-y: auto;
        height: 100%;
        border-radius: 10px;
    }

    .content-league-filter .v--modal-background-click .v--modal-box{
        border-radius: 20px!important;
    }
    .content-league-filter h3{font: 600 18px Athiti; text-align: center; display: inline-block;width: 100%}
    .league-item-wrapper label{margin-left: 8px; margin-bottom: 0; display: inline}
    .league-item-wrapper input[type="checkbox"]{
        width: 20px;
        height: 20px;
        background-color: #aaa;
        border-radius: 50%;
    }

    .league-item-wrapper {
        font: 14px Helvetica;
        padding: 15px 0;
        /*border-bottom: solid 1px lightgray;*/
    }
    .league-item-wrapper:nth-of-type(odd) {
        background-color: #f9f9f9;
    }
    .el-input__inner{
        border-radius: 10px;
    }
    .TableOdd-top{
        position: sticky;
        top: 0;
        z-index: 2;
        background-color: white;
        padding-bottom: 5px;
        border-bottom: solid 1px #eee;
    }
    @media only screen and (max-width: 820px) {
        .custom-header-sticky {
            /*top: 42px;*/
            top: 0;
        }
    }

    @media only screen and (min-width: 820px) {
        .custom-header-sticky {
            top: 0;
        }
    }

    .custom-header-sticky {
        position: sticky;
        z-index: 999;
    }

    .custom-header-sticky.top-45 {
        top: 45px!important;;
    }

    @media only screen and (max-width: 820px) {
        .scrolling-wrapper-sport {
            display: flex;
            flex-wrap: nowrap;
            overflow: hidden;
            overflow-x: auto;
            font-size: 14px;
            font-family: Helvetica Neue, Helvetica, Arial, sans-serif;
        }

        .scrolling-info-sport {
            width: 820px;
            flex: 0 0 auto;
        }

        .custom-header-sticky {
            overflow: hidden;
            overflow-x: auto;
        }
    }

    .balance-text {
        font-size: 14px; 
        text-align: left;
        color: #66b1ff;
    }
    @media only screen
    and (min-device-width: 1300px)
    and (orientation: landscape){
        .title-sport,
        .scrolling-wrapper-flexbox{
            justify-content: center;
        }
        .title-sport h4{margin-right: 30px;}
    }
</style>
<style>
    .modal-slide-enter-active,
    .modal-slide-leave-active {
        transition: all 0.4s;
    }

    .modal-slide-enter,
    .modal-slide-leave-active {
        opacity: 0;
        transform: translateX(100%);
    }

    .v-drawer-wrapper {
        /*margin-top: 70px;*/
        overflow: auto;
        height: 100vh;
    }

    .v-drawer-inner {
        width: 900px;
    }
</style>