module.exports = {
    generateDuplicateMap(arr, col){
        return arr.reduce((a, e) => {
            a[e[col]] = ++a[e[col]] || 0;
            return a;
        }, {});
    },
    findDuplicate(arr, col, map){
        return arr.filter(e => map[e[col]]);
    },
    groupBy(arr, col){
        return arr.reduce((rv, x) => {
            (rv[x[col]] = rv[x[col]] || []).push(x);
            return rv;
        }, {});
    }
}