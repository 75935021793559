<template>
    <div v-show="card" class="CardStatic">
        <img :class="customClass" :src="imagePath">
    </div>
</template>
<script>

import {CARD_TYPE_IMAGE, CARD_NUMBER_IMAGE} from './Constant'

export default {
    name: 'CardStatic',
    props: [
        'card',
        'size',
    ],
    computed: {
        cardType() {
            if(!this.card) {
                return 0;
            }
            return this.card.card_type;
        },
        cardNumber() {
            if(!this.card) {
                return 0;
            }
            return this.card.card_number;
        },
        imagePath() {
            return `${this.envDomains.VUE_APP_API}/images/pokercard/${CARD_TYPE_IMAGE[this.cardType]}${CARD_NUMBER_IMAGE[this.cardNumber]}.png`;
        },
        customClass() {
            if(this.size === 'medium') {
                return 'size-medium';
            } else {
                return 'size-mini';
            }
        },
    },
}
</script>
<style scoped>
.size-medium {
    width: 25px;
    height: 35px;
}
.size-mini {
    width: 16px;
    height: 22px;
}
</style>