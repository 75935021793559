<template>
    <div>
        <div class="navButtonMobileInfo">
                <ul v-if="menu_list.length>0" class="nav">
                    <li v-for="(menu, key) of menu_list" :key="key" :class="getClassMenu(menu.id)"  >
                        <div v-if="menu.id=='support' && menu_left_setting_mapping[menu.id]!=false" class="nevNotification hide"><span>1</span></div>
                        <a v-if="menu_left_setting_mapping[menu.id]!=false" class="nav-link text-white" @click="onClickByMenu(menu.id)">
                            <div class="navTopBarIcon iconMain" :class="[menu.class]"></div>
                            <span>{{menu.name}}</span>
                        </a>
                    </li>
                    
                </ul>
                <ul v-else class="nav">
                    
                    <li>
                        <a class="nav-link text-white" @click="showDailyMission" >
                            <div class="navTopBarIcon iconMain iconMission"></div>
                            <span>ภาระกิจ</span>
                        </a>
                    </li>
                    <li class="NavShopDiamond">
                        <a class="nav-link text-white">
                            <div class="navTopBarIcon iconMain iconExchangeDiamond"></div>
                            <span>แลกเพชร</span>
                        </a>
                    </li>
                    <li class="NavTopPromotion activePromotionNav">
                        <a  @click="toShopPage" class="nav-link text-white">
                            <div class="navTopBarIcon iconMain iconPromotion"></div>
                            <span>โปรโมชั่น</span>
                        </a>
                    </li>
                    <li class="NavTopPrivilege">
                        <a href="#" class="nav-link text-white" @click="showPrivilege">
                            <div class="navTopBarIcon iconMain iconPrivilege"></div>
                            <span>สิทธิพิเศษ</span>
                        </a>
                    </li>
                    <li class="activeDepositWithdrawNav" @click="toAccountPage" >
                        <a class="nav-link text-white">
                            <div class="navTopBarIcon iconMain iconDw"></div>
                            <span>ฝาก-ถอน</span>
                        </a>
                    </li>
                    <li>
                        <div class="nevNotification hide"><span>1</span></div>
                        <a href="#" class="nav-link text-white" @click="showChatWithAdmin">
                            <div class="navTopBarIcon iconMain iconSupport"></div>
                            <p>Support</p>
                        </a>
                    </li>
                </ul>
        </div>
    </div>
</template>
<script>


export default {
    name: 'MenuNavTop',
    components: {

    },
    computed: {
        menu_setting(){
            return localStorage.getItem('menu_setting')? JSON.parse(localStorage.getItem('menu_setting')) :null;
        },
        menu_left_setting(){
            return localStorage.getItem('menu_left_setting')? JSON.parse(localStorage.getItem('menu_left_setting')) :null;
        },
    },
    methods: {
        toAccountPage() {
            this.$router.push({name: 'DepositWithdraw'})
        },
        toShopPage() {
            this.$router.push({name: 'Package'})
        },
        showDailyMission(){
            this.$emit('showMenuNav',1);
        },
        showPrivilege(){
            this.$emit('showMenuNav',2);
        },
        showChatWithAdmin(){
            this.$emit('showChatWithAdmin');
        },
        showDepositWithdrawModal(){
            this.$emit('showDepositWithdrawModal');
        },
        getClassMenu(id){
            if(id=='exchange_diamond'){
                return ['NavShopDiamond']
            }else if(id=='promotion'){
                return ['NavTopPromotion','activePromotionNav']
            }else if(id=='privilege'){
                return ['NavTopPrivilege']
            }else if(id=='deposit_withdraw'){
                return ['activeDepositWithdrawNav']
            }else{
                return [];
            }

        },
        onClickByMenu(id){
            if(id=='exchange_diamond'){
                null;
            }else if(id=='promotion'){
                this.toShopPage()
            }else if(id=='privilege'){
                this.showPrivilege()
            }else if(id=='deposit_withdraw'){
                this.showDepositWithdrawModal()
            }else if(id=='mission'){
                this.showDailyMission()
            }else if(id=='support'){
                this.showChatWithAdmin()
            }else if(id=='spin_fortune'){
                null
            }else if(id=='casino'){
                this.$router.push({'name':'IndexCasino'})

            }else if(id=='fishing'){
                this.$router.push({'name':'IndexFishing'})
            }else if(id=='lotto'){
                this.$router.push({name:'LottoHome'})
            }else if(id=='slot'){
                this.$router.push({'name':'IndexSlot'})
            }else if(id=='sport'){
                this.$router.push({'name':'IndexSport'})
            }else if(id=='unbox'){
                this.$router.push({'name':'IndexUnbox'})

            }else if(id=='popular'){
                this.$router.push({'name':'IndexPopular'})
            }else{
                null;
            }
        },
    },
    data() {
        return {
            'menu_list':[],
            'menu_left_setting_mapping':{}
        }
    },
    mounted() {
         
        if(this.menu_setting){
            for(let menu in this.menu_setting){
                this.menu_list.push(this.menu_setting[menu])
            }
        }
        if(this.menu_left_setting){
            for(let menu in this.menu_left_setting){
                let m = this.menu_left_setting[menu];
                let id = m.id;
                let is_show  = m.is_show;
                this.menu_left_setting_mapping[id]=is_show;
            }
        }
        
        
    }

}
</script>
<style scoped>
</style>