<template>
    <div class="CategoryOption">
        <div v-for="category_option of category_option_list"
             :key="category_option.slug" class="bg-btn">
                <button @click="select_category_option(category_option)"
                        :class="category_option.ui_class"
                        class="btn btn-sm btn-block">
                    {{category_option.name}}
                </button>
        </div>
    </div>
</template>

<script>
    export default {
        name: "CategoryOption",
        props: [
            'category_option_list'
        ],
        methods: {
            select_category_option: function(category_option){
                this.$emit('select_category_option', category_option);
            }
        }
    }
</script>

<style scoped>

</style>