<template>
<!--    <div class="Chat activeChat">-->
    <div class="Chat" :class="{'activeChat': isShowEmojiPanel}">
        <Pyro v-if="is_big_win"/>
        <!--Box Full Video-->
        <div v-if="is_open_box_full_video" class="box-full-video" :class="{'open': is_open_box_full_video, 'box-mini': is_box_mini_video}">
            <div class="box-ui-btn">
                <div class="bt-menu-close" @click="closeBoxFullVideo"><i class="fas fa-times"></i></div>
                <div class="bt-menu-resize" @click="is_box_mini_video = !is_box_mini_video">
                    <i class="fas" :class="{'fa-compress-alt': !is_box_mini_video, 'fa-expand': is_box_mini_video}"></i>
                </div>
            </div>
            <iframe :src="current_live_url"
                    frameborder="0"
                    allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                    allowfullscreen></iframe>
        </div>
        <div class="messaging">
            <div class="inbox_msg">
<!--                <MessageMinigame></MessageMinigame>-->
<!--                v-if="balloonGameData"-->
                <BalloonGame  v-if="balloonGameData" :data="balloonGameData"></BalloonGame>
                <div class="mesgs">
                    <div id="msg_history" class="msg_history">
                        <div v-if="is_on_load_prev" class="loading-wrapper">
                            <div class="spinner-border" role="status" style="margin-right: 4px;">
                                <span class="sr-only">กำลังโหลด...</span>
                            </div>
                            <span>กำลังโหลด...</span>
                        </div>
                        <!-- <div class="loadMore-chat" v-if="is_member && message_arr.length > 0 && !is_on_init && is_can_load_prev" style="display: flex; justify-content: center;margin-bottom: 10px;">
                            <el-button @click="loadPrevMessage" round>โหลดข้อความก่อนหน้า</el-button>
                        </div> -->
                        <div class="all-message">
                            <div v-for="(message, i) of message_arr" :key="i" class="">
                                <MessageTopUp v-if="message.type === 'top_up'"
                                              :message="message"/>
                                <MessageDateSeperator v-else-if="message.type === 'date_seperator'"
                                                      :message="message"/>
                                <MessageOwnerText v-else-if="message.type === 'text' && checkIsOwnerMessage(message.user_id, user_id)"
                                                  :message="message"/>
                                <MessageIncomingText v-else-if="message.type === 'text'"
                                                     :message="message"
                                                     @react="handleReact"/>
                                <MessageFootball v-else-if="message.type === 'football'"
                                                 :message="message"/>
                                <MessageSystem v-else-if="message.type === 'system'"
                                               :message="message"/>
                                <MessageLottoNews v-else-if="message.type === 'lotto_news'"
                                                  :message="message"/>
                                <MessageLottoPrice v-else-if="message.type === 'lotto_price'"
                                                   :message="message"/>
                                <MessageBaccarat v-else-if="message.type === 'baccarat'"/>
                                <MessageSicbo v-else-if="message.type === 'sicbo'"/>
                                <MessageBaccaratAlert v-else-if="message.type === 'baccarat_alert'"/>
                                <MessageLive @click="openBoxFullVideo" v-else-if="message.type === 'live'"
                                             :message="message"></MessageLive>
                                <MessageGallery v-else-if="message.type === 'gallery'"
                                                :message="message"/>
                                <MessageTimeline v-else-if="message.type === 'timeline'"
                                                 :message="message"
                                ></MessageTimeline>
                                <IncomingChat
                                        v-else-if="message.type === 'incoming_chat'"
                                        :message="message"></IncomingChat>
                                <MessageLottoResult
                                        v-else-if="message.type === 'lotto_result'"
                                        :message="message"></MessageLottoResult>
                                <MessageUnboxItemAlert
                                        v-else-if="message.type === 'unbox'"
                                        :message="message"></MessageUnboxItemAlert>
                                <MessageBonus
                                        v-else-if="message.type === 'bonus'"
                                        :message="message"></MessageBonus>
                                <MessageLottoResultWinner
                                        v-else-if="message.type === 'lotto_win'"
                                        :message="message"></MessageLottoResultWinner>
                                <MessagePromotion 
                                        v-else-if="message.type === 'promotion'"
                                        :message="message"></MessagePromotion>
                                <MessageRich 
                                        v-else-if="message.type === 'rich'" 
                                        :message="message"></MessageRich>
                                <IncomingEmoji
                                        v-else-if="message.type === 'sticker'" 
                                        :message="message"></IncomingEmoji>
                                <ResultMiniGame
                                        v-else-if="message.type === 'game_stat'"
                                        :message="message"></ResultMiniGame>
                            </div>
                        </div>


<!--                        <IncomingEmoji></IncomingEmoji>-->
                       <!-- <MessageRoulette v-if="false"></MessageRoulette> -->
                       <!-- <MessageLottoResultWinner></MessageLottoResultWinner> -->

                       <!-- <MessagePromotion></MessagePromotion> -->
                       <!-- <MessageBaccaratAlert></MessageBaccaratAlert> -->
<!--                        <ResultMiniGame></ResultMiniGame>-->

                       <MessageLottoResult 
                       v-if="balloonLottoResultData" 
                       :message="balloonLottoResultData"
                       :float="true"></MessageLottoResult>
                    </div>
                    <Keyboard :socket_status="socket_status" @send="sendMessage" @isShowEmojiPanelChange="value => isShowEmojiPanel = value"/>

                    <div class="GameTopPlayer box-top-user" style="display: none">
                        <div class="box-top-user-logo">
                            <div class="f3 blink delay-x1" style="top: -37px; left: -117px;">
                            </div><div class="f3 blink delay-x15" style="top: -15px; left: -77px;"></div>
                            <div class="f3 blink delay-x1" style="top: -32px; right: 0px;"></div>
                            <div class="f3 blink delay-x15" style="top: -11px; right: 20px;"></div>
                        </div>
                        <div class="box-top-user-flex">
                            <marquee>
                                <div class="box-top-user-list">
                                    <div class="user-list-detail">
                                        <div class="user-list-detail-content">
                                            <div class="Avatar">
                                                <span class="el-avatar el-avatar--large el-avatar--circle">
                                                    <img src="/images/avatar.png" style="object-fit: cover;"></span>
                                            </div>
                                            <strong></strong>
                                            <div class="groups-bx">
                                            <div><div class="bx-baccarat-p"></div>
                                            </div></div><span>100 บาท</span></div>
                                    </div>
                                </div>
                            </marquee>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>
<script>

import ChatMixin from '@/mixins/Chat'

export default {
    name: 'Chat',
    mixins: [
        ChatMixin,
    ],
}
</script>