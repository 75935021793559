
<template>
    <div class="MessagePromotion incoming_msg">

        <div class="received_msg type-activity">
            <!--<h3>Bacarat</h3>-->
            <div class="received_withd_msg">
                   <div class="shop-content shop-content-play" v-if="message">
                       <carousel
                               :per-page="2"
                               :mouseDrag="true"
                               :navigationEnabled="true"
                               :paginationEnabled="false"
                               navigationNextLabel="❯"
                               navigationPrevLabel="❮"
                               ref="carousel"
                               v-images-loaded="imageLoaded"
                       >
                           <slide v-for="(gp,key) of packet_list" :key="key">
                               <div v-if="gp.packet_type==0" class="shop-card shop-card-money card-gold" :class="{'shop-card-sold-out':(gp.packet_limit>0 &&  gp.balance_user_count<=0)}">
                                   <div class="time-event" v-if="gp.packet_end_datetime">
                                                    <span :id="'packet-'+gp.packet_id">End in ?</span>
                                                </div>
                                   <div v-if="gp.packet_item_addition_qty>0" class="shop-ribbon free-ribbon-top" style="right: -8px;left: auto; top: -10px;">
                                                    <strong>รับเพิ่ม</strong>
                                                    <h4>
                                                        {{gp.packet_item_addition_qty}}
                                                    </h4>
                                                    <div class="shop-ribbon-info"></div>
                                                </div>
                                   <div v-if="gp.packet_is_best_seller" class="tag-best-sale"><span>ขายดี</span></div>
                                   <div class="card-hole"><span></span></div>
                                   <div class="box-detail-shop">
                                       <div class="topic-pack-name">
                                           <span>แพ็คเกจสล็อต</span>
                                       </div>
                                       <h3>{{gp.packet_name}}</h3>
                                       <p>+50 Credit</p>
                                       <div class="box-detail-shop-thumb">
                                                        <div v-if="gp.packet_discount" class="shop-ribbon ribbon-red" style="right: 6px;left: auto; top: 6px;">
                                                            <strong>ลด</strong>
                                                            <h4>฿{{gp.packet_discount}}</h4>
                                                            <div class="shop-ribbon-info"></div>
                                                        </div>
                                                        <img src="@/assets/images/coin-big-1.png">
                                                    </div>
                                       <div class="value-pack">
                                                        <strong>฿{{gp.packet_price}}</strong>
                                                        <span class="value-discount">฿{{gp.packet_full_price}}</span>
                                                    </div>
                                       <span v-if="gp.packet_limit>0" class="limit-shop">{{gp.balance_user_count>0?'ซื้อได้ '+gp.balance_user_count+' ครั้ง':'หมดจำนวนที่ซื้อได้'}}</span>
                                   </div>
                                   <div class="shop-card-btn btnPay purchase_packet_btn" :packet_id="gp.packet_id" @click="purchasePacket(gp.packet_id,0)">
                                                    <span>ซื้อแพ็กเกจ</span>
                                                </div>
                                   <div class="shop-card-bg">
                                                    <div></div>
                                                </div>
                                   <div class="shop-card-bg-hover">
                                                    <div></div>
                                                </div>
                               </div>
                               <div v-else-if="gp.packet_type==1" class="shop-card shop-card-spin" :attr_="gp.packet_limit+':'+  gp.balance_user_count" :class="[card_spin_level_list[key],{'shop-card-sold-out':(gp.packet_limit>0 &&  gp.balance_user_count<=0)}]">
                                                <div class="shop-ribbon free-ribbon-top" style="right: -8px;left: auto; top: 0;">
                                                <strong>ฟรีสปิน</strong>
                                                <h4>x{{gp.packet_item_qty}}</h4>
                                                <div class="shop-ribbon-info"></div>
                                            </div>
                                                <div class="time-event" v-if="gp.packet_end_datetime">

                                                <span :id="'packet-'+gp.packet_id">End in ?</span>
                                            </div>
                                                <div class="card-hole"><span></span></div>
                                                <div class="box-detail-shop">
                                                    <div class="topic-pack-name">
                                                        <span>แพ็คเกจสุดคุ้ม</span>
                                                    </div>
                                                <h3>{{gp.packet_name}}</h3>
                                                <p>{{gp.name_case}}</p>
                                                <div class="box-detail-shop-thumb">
                                                    <div v-if="gp.packet_item_addition_qty"  class="shop-ribbon ribbon-red" style="right: 6px;left: auto; top: 6px;">
                                                        <strong>เพิ่ม</strong>
                                                        <h4>{{gp.packet_item_full_qty}}+{{gp.packet_item_addition_qty}}</h4>
                                                        <div class="shop-ribbon-info"></div>
                                                    </div>
                                                    <img :src="gp.item_img">
                                                </div>
                                                <div class="value-pack">
                                                    <strong>฿{{gp.packet_price}}</strong>
                                                    <span class="value-discount">฿{{gp.packet_full_price}}</span>
                                                </div>
                                                    <span v-if="gp.packet_limit>0" class="limit-shop">{{gp.balance_user_count>0?'ซื้อได้ '+gp.balance_user_count+' x ครั้ง':'หมดจำนวนที่ซื้อได้'}}</span>
                                            </div>
                                                <div class="shop-card-btn btnPay purchase_packet_btn" :packet_id="gp.packet_id" @click="purchasePacket(gp.packet_id,1)">
                                                    <span>ซื้อแพ็กเกจ</span>
                                                </div>
                                                <div class="shop-card-bg"><div></div></div>
                                                <div class="shop-card-bg-hover"><div></div></div>

                                            </div>
                               <div v-else-if="gp.packet_type==2" class="shop-card shop-card-slot" :class="[card_bonus_slot_level_list[key],{'shop-card-sold-out':(gp.packet_limit>0 &&  gp.balance_user_count<=0)}]">
                                                <div class="shop-ribbon free-ribbon-top" style="right: -8px;left: auto; top: 0;">
                                                    <strong>ฟรีสปิน</strong>
                                                    <h4>x{{gp.packet_item_qty}}</h4>
                                                    <div class="shop-ribbon-info"></div>
                                                </div>
                                                <div class="time-event" v-if="gp.packet_end_datetime">

                                                    <span :id="'packet-'+gp.packet_id">End in ?</span>
                                                </div>
                                                <div class="card-hole"><span></span></div>

                                                <div class="box-detail-shop">
                                                    <div class="topic-pack-name">
                                                        <span>แพ็คเกจสุดคุ้ม</span>
                                                    </div>
                                                    <h3>{{gp.packet_name}}</h3>
                                                    <p>{{gp.name_case}}</p>
                                                    <div class="box-detail-shop-thumb">
                                                        <div v-if="gp.packet_item_addition_qty"  class="shop-ribbon ribbon-red" style="right: 6px;left: auto; top: 6px;">
                                                            <strong>เพิ่ม</strong>
                                                            <h4>{{gp.packet_item_full_qty}}+{{gp.packet_item_addition_qty}}</h4>
                                                            <div class="shop-ribbon-info"></div>
                                                        </div>
                                                        <img :src="'https://asia.pragmaticplay.net/game_pic/rec/325/'+gp.ref_id+'.png'">
                                                    </div>
                                                    <div class="value-pack">
                                                        <strong>฿{{gp.packet_price}}</strong>
                                                        <span class="value-discount">฿{{gp.packet_full_price}}</span>
                                                    </div>
                                                    <span v-if="gp.packet_limit>0" class="limit-shop">{{gp.balance_user_count>0?'ซื้อได้ '+gp.balance_user_count+' ครั้ง':'หมดจำนวนที่ซื้อได้'}}</span>
                                                </div>
                                                <div class="shop-card-btn btnPay purchase_packet_btn" :packet_id="gp.packet_id" @click="purchasePacket(gp.packet_id,2)">
                                                    <span>ซื้อแพ็กเกจ</span>
                                                </div>
                                                <div class="shop-card-bg">
                                                <div></div>
                                            </div>
                                                <div class="shop-card-bg-hover">
                                                    <div></div>
                                                </div>

                                            </div>
                           </slide>


                       </carousel>



                       <!-- <div data-v-77a61008="" class="shop-card card-red"><div data-v-77a61008="" class="card-hole"><span data-v-77a61008=""></span></div><div data-v-77a61008="" class="topic-pack-name"><span data-v-77a61008="">แพ็คซื้อสปินโบนัส</span></div><div data-v-77a61008="" class="box-detail-shop"><h3 data-v-77a61008="">Wild West Gold</h3><div data-v-77a61008="" class="box-detail-shop-thumb"><div data-v-77a61008="" class="shop-ribbon ribbon-pay-bonus"><strong data-v-77a61008="">ซื้อ</strong><span data-v-77a61008="">Spin Bonus</span><div data-v-77a61008="" class="shop-ribbon-info"></div></div><img data-v-77a61008="" src="https://asia.pragmaticplay.net/game_pic/rec/325/vs40wildwest.png"></div></div><a data-v-77a61008="" href="https://pesstar.com/game/casinoonline/sa_sexy_redirect.php?mode=mobile&amp;by=&amp;service=pragmatic&amp;code=vs40wildwest" target="_blank"><div data-v-77a61008="" class="shop-card-btn btnPlay"><span data-v-77a61008="">เล่นเกม</span></div><div data-v-77a61008="" class="shop-card-bg"><div data-v-77a61008=""></div></div><div data-v-77a61008="" class="shop-card-bg-hover"><div data-v-77a61008=""></div></div></a></div>
                       <div data-v-77a61008="" class="shop-card card-red"><div data-v-77a61008="" class="card-hole"><span data-v-77a61008=""></span></div><div data-v-77a61008="" class="topic-pack-name"><span data-v-77a61008="">แพ็คซื้อสปินโบนัส</span></div><div data-v-77a61008="" class="box-detail-shop"><h3 data-v-77a61008="">Sweet Bonanza</h3><div data-v-77a61008="" class="box-detail-shop-thumb"><div data-v-77a61008="" class="shop-ribbon ribbon-pay-bonus"><strong data-v-77a61008="">ซื้อ</strong><span data-v-77a61008="">Spin Bonus</span><div data-v-77a61008="" class="shop-ribbon-info"></div></div><img data-v-77a61008="" src="https://asia.pragmaticplay.net/game_pic/rec/325/vs20fruitsw.png"></div></div><a data-v-77a61008="" href="https://pesstar.com/game/casinoonline/sa_sexy_redirect.php?mode=mobile&amp;by=&amp;service=pragmatic&amp;code=vs20fruitsw" target="_blank"><div data-v-77a61008="" class="shop-card-btn btnPlay"><span data-v-77a61008="">เล่นเกม</span></div><div data-v-77a61008="" class="shop-card-bg"><div data-v-77a61008=""></div></div><div data-v-77a61008="" class="shop-card-bg-hover"><div data-v-77a61008=""></div></div></a></div>
                       <div data-v-77a61008="" class="shop-card card-red"><div data-v-77a61008="" class="card-hole"><span data-v-77a61008=""></span></div><div data-v-77a61008="" class="topic-pack-name"><span data-v-77a61008="">แพ็คซื้อสปินโบนัส</span></div><div data-v-77a61008="" class="box-detail-shop"><h3 data-v-77a61008="">Sweet Bonanza Xmas</h3><div data-v-77a61008="" class="box-detail-shop-thumb"><div data-v-77a61008="" class="shop-ribbon ribbon-pay-bonus"><strong data-v-77a61008="">ซื้อ</strong><span data-v-77a61008="">Spin Bonus</span><div data-v-77a61008="" class="shop-ribbon-info"></div></div><img data-v-77a61008="" src="https://asia.pragmaticplay.net/game_pic/rec/325/vs20sbxmas.png"></div></div><a data-v-77a61008="" href="https://pesstar.com/game/casinoonline/sa_sexy_redirect.php?mode=mobile&amp;by=&amp;service=pragmatic&amp;code=vs20sbxmas" target="_blank"><div data-v-77a61008="" class="shop-card-btn btnPlay"><span data-v-77a61008="">เล่นเกม</span></div><div data-v-77a61008="" class="shop-card-bg"><div data-v-77a61008=""></div></div><div data-v-77a61008="" class="shop-card-bg-hover"><div data-v-77a61008=""></div></div></a></div>
                       <div data-v-77a61008="" class="shop-card card-red"><div data-v-77a61008="" class="card-hole"><span data-v-77a61008=""></span></div><div data-v-77a61008="" class="topic-pack-name"><span data-v-77a61008="">แพ็คซื้อสปินโบนัส</span></div><div data-v-77a61008="" class="box-detail-shop"><h3 data-v-77a61008="">Release the Kraken</h3><div data-v-77a61008="" class="box-detail-shop-thumb"><div data-v-77a61008="" class="shop-ribbon ribbon-pay-bonus"><strong data-v-77a61008="">ซื้อ</strong><span data-v-77a61008="">Spin Bonus</span><div data-v-77a61008="" class="shop-ribbon-info"></div></div><img data-v-77a61008="" src="https://asia.pragmaticplay.net/game_pic/rec/325/vs20kraken.png"></div></div><a data-v-77a61008="" href="https://pesstar.com/game/casinoonline/sa_sexy_redirect.php?mode=mobile&amp;by=&amp;service=pragmatic&amp;code=vs20kraken" target="_blank"><div data-v-77a61008="" class="shop-card-btn btnPlay"><span data-v-77a61008="">เล่นเกม</span></div><div data-v-77a61008="" class="shop-card-bg"><div data-v-77a61008=""></div></div><div data-v-77a61008="" class="shop-card-bg-hover"><div data-v-77a61008=""></div></div></a></div>
                    -->
                   </div>
        
        </div>
    </div>
        <!--        Modal แจ้งเตือน-->
        <div class="box-award box-alert" id="modal_alert" :class="alert_show" v-if="alert_obj">
            <div class="box-alert-content">

                <div class="content-all">
                    <div class="content">
                        <div class="title" style="color: white">
                            <h1>{{alert_obj.title}}</h1>
                        </div>
                        <p>{{alert_obj.content}}</p>
                        <div class="footer footer-center">
                            <button v-if="alert_obj.close_btn" type="button" class="btn btn-default-outline" @click="closeAlert()" >Close
                            </button>
                            <button v-if="alert_obj.ok_btn" type="button" class="btn" @click="confirmAlert()" >OK</button>
                        </div>
                    </div>
                    <div class="content-bg"></div>
                </div>



            </div>
            <div class="overlay-box"></div>
        </div>
<!--        ได้รับของรางวัล-->
        <div class="box-award" v-if="claim_bonus_obj">
            <div class="box-alert-content">
                <div class="title title-center">
                    <h1>{{claim_bonus_obj.title}}</h1>
                </div>
                <div class="content-all">
                    <div class="content">
                        <div class="row-box-award">
                            <ul>
                                <li>
                                    <div class="item-award">
                                        <div class="set-coin"><img :src="claim_bonus_obj.img" alt="" /></div>
                                        <span class="Value-item">{{claim_bonus_obj.packet_name}}</span>
                                    </div>
                                    <h5>{{claim_bonus_obj.packet_name2}}</h5>
                                </li>
                                <li v-if="false">
                                    <div class="item-award" >
                                        <div class="set-coin"><img src="@/assets/images/coin-small-1.png" alt="" /></div>
                                        <span class="Value-item">฿10</span>
                                    </div>
                                    <h5>Credit</h5>
                                </li>
                            </ul>
                        </div>
                        <div class="footer footer-center">
                            <button type="button" class="btn bg-gradient-btn cliam_bonus_btn" @click="confirmPurchasePakcet(claim_bonus_obj.packet_id,claim_bonus_obj.packet_type)"  >{{claim_bonus_obj.btn_title}}</button>
                            <button type="button" class="btn" @click="cancelPurchase()">ยกเลิก</button>
                        </div>
                    </div>
                    <div class="content-bg"></div>
                </div>



            </div>
            <div class="overlay-box"></div>
        </div>
    </div>

</template>
<script>
    var is_ios = (navigator.userAgent.match(/iPhone/i) || navigator.userAgent.match(/iPad/i) || navigator.userAgent.match(/iPod/i));
    import { Carousel, Slide } from 'vue-carousel'
    import store from '@/store'
    import $ from 'jquery'
    import moment from 'moment';
    window.jQuery = $;
    window.$ = $;

import imagesLoaded from 'vue-images-loaded'

export default {
    name: 'Promotion',
    directives: {
        imagesLoaded
    },
    props: [
        'message'
    ],
    components: {
        Carousel,
        Slide,
        // MenuBar,
        // MenuLeft,
    },

    data() {
    
        return {
            PES_BASE_URL: '',
            embedMode:null,
            prgmatci_game: this.getPragmaticGame(),
            card_spin_level_list:[
                'shop-card-event card-premium-event-v1',
                'card-gold-d1',
                'card-silver',
                'card-greed',
                'card-brown',
                'card-gold-d1',
                'card-silver',
                'card-greed',
                'card-brown',
                'card-gold-d1',
                'card-silver',
                'card-greed',
                'card-brown',
                'card-gold-d1',
                'card-silver',
                'card-greed',
                'card-brown',
            ],
            card_bonus_slot_level_list:[
                'shop-card-event card-premium-event-v2',
                'card-premium-event-v2',
                'card-gold-d1',
                'card-silver',
                'card-greed',
                'card-brown',
                'card-premium-event-v2',
                'card-gold-d1',
                'card-silver',
                'card-greed',
                'card-brown',
                'card-premium-event-v2',
                'card-gold-d1',
                'card-silver',
                'card-greed',
                'card-brown',
            ],

            alert_obj:null,
            claim_bonus_obj:null,  
            alert_show:'', 
            packet_list :[],
            GAME_PACKET_LIST:[],
            
        }
    },
    computed: {
            is_member() {
                return store.state.is_member
            },
            pes_profile() {
                return store.state.pes_profile
            },
            token() {
                return this.pes_profile.token
            },
            appImageProfile(){
                return store.state.appInfo.app_image_profile
            },
        },
    methods:{
        openGame(){

        },
        openLeftMenu(){

        },
        showMenuLeft(){

        },
        closeLeftMenu(){

        },
        getGamePacket(data){
            var self = this;
            if(this.pes_profile){
                $.get( `${this.envDomains.VUE_APP_PES_BASE_URL}/api/game/v1/game/packet/get.php?token=`+this.pes_profile.token,function(response){
                    if(response.code==1){
                        self.GAME_PACKET_LIST= response.data;
                        for(let i=0;i<data.length;i++){
                            var gp = self.getGP(data[i].packet_id);
                            self.packet_list.push(gp);
                        }
                        
                    }
                });
            
        
            }else{
                $.get( `${this.envDomains.VUE_APP_PES_BASE_URL}/api/game/v0/game/packet/get.php`,function(response){
                    if(response.code==1){
                        self.GAME_PACKET_LIST= response.data;
                        for(let i=0;i<data.length;i++){
                            var gp = self.getGP(data[i].packet_id);
                            self.packet_list.push(gp);
                        }
                    }
                });
            
        
            }

        },
        setTimer(gp){
            var toDate = is_ios? new Date(moment(gp.packet_end_datetime).format('YYYY/MM/DD HH:mm:ss')): new Date(gp.packet_end_datetime);
                        // console.log(gp.packet_id,toDate);
                        timeBetweenDatesV2(toDate, 'packet-'+gp.packet_id);
                        setInterval(function(){
                            timeBetweenDatesV2(toDate, 'packet-'+gp.packet_id);
                
                        },1000);
        },
        getPragmaticGame(){
            var list ={
                                    'vs40wildwest':'Wild West Gold',
                                    'vs20fruitsw':'Sweet Bonanza',
                                    'vs20sbxmas':'Sweet Bonanza Xmas',
                                    'vs20kraken':'Release the Kraken',
                                    'vswaysrhino':'Great Rhino Megaways',
                                    'vs20fruitparty':'Fruit Party',
                                    'vs1600drago':'Drago - Jewels of Fortune',
                                    'vs10bookoftut':'Book of Tut'
                                    };

                                    return list;
        },
        purchasePacket(packet_id,packet_type){
            var gp=this.getGP(packet_id,packet_type);
            
            var claim_bonus_obj={
                title:''+gp.packet_name,
                packet_name : gp.packet_name,
                packet_name2 : gp.packet_name,
                img:'/images/coin-big-1.png',
                btn_title:'ยืนยันการซื้อ',
                packet_id :gp.packet_id,
                packet_type :  packet_type,
            }
            var ref_id = gp.ref_id;
            if(packet_type==1){
                claim_bonus_obj.img = gp.item_img;
            }else if(packet_type==2){
                claim_bonus_obj.img = 'https://asia.pragmaticplay.net/game_pic/rec/325/'+ref_id+'.png';
            }
            this.claim_bonus_obj=claim_bonus_obj;
            

        },
        closeAlert(){
            var self = this;
            this.alert_show ='hide';
            setTimeout(() => {
                self.alert_obj=null;
            }, 1000*0.3);
            
        },
        confirmAlert(alert_obj){
            var self = this;
            this.closeAlert();      
            setTimeout(function(){
            if(alert_obj){
                if(typeof alert_obj.after_ok =='string'){  
                     console.log('evel');
                     eval(alert_obj.after_ok);
                }else{
                    self.alert_obj = alert_obj.after_ok;
                    self.alert_show ='';
                }
            }
            },200)
            
            
            

        },
        confirmPurchasePakcet(packet_id,pakcet_type){
                var gp = this.getGP(packet_id,pakcet_type);
                var self = this;
                if(self.pes_profile){
                    $.post( `${this.envDomains.VUE_APP_PES_BASE_URL}/api/game/v1/game/packet/purchase.php`,{packet_id:packet_id,token:this.token},function(response){
                        if(response.code==1){
                            self.claim_bonus_obj=null;
                            var alert_obj_after_ok=null;
                            var alert_obj={
                                title:'Message',
                                content:'Purchase Success.',
                                ok_btn:true,
                                close_btn:false,
                                after_ok: null,
                            }
                            
                            var data = response.data;
                            var balance= data.balance; 
                            
                            self.pes_profile.THB_free = balance;

                            if(gp.packet_type==1){
                                // var conf= confirm('Do you want spin now?');
                                // if(conf){
                                //     window.location.href='/unbox-play/'+gp.ref_id;
                                // }
                                alert_obj_after_ok={
                                    title:'Confirm',
                                    content:'Do you want spin now?',
                                    ok_btn:true,
                                    close_btn:true,
                                    after_ok:"window.location.href='/unbox-play/"+gp.ref_id +"'",
                                }

                            }else if(gp.packet_type==2){
                                // var r= confirm('Do you want go to slot game now?');
                                // if(r){
                                //      window.open(`${self.envDomains.VUE_APP_PES_BASE_URL}/game/casinoonline/sa_sexy_redirect.php?mode=desktop&by=&service=pragmatic&code=`+gp.ref_id,'_blank')
                                // }
                                var cmd =`window.open("${self.envDomains.VUE_APP_PES_BASE_URL}/game/casinoonline/sa_sexy_redirect.php?mode=desktop&by=&service=pragmatic&code=`+gp.ref_id+'&token='+self.pes_profile.token+(self.appImageProfile?('&logo_img='+self.appImageProfile):'')+ "\",'_blank')";
                                
                                alert_obj_after_ok={
                                    title:'Confirm',
                                    content:'Do you want go to slot game now?',
                                    ok_btn:true,
                                    close_btn:true,
                                    after_ok:cmd,
                                }

                            }

                            alert_obj.after_ok=alert_obj_after_ok;
                            self.alert_obj =alert_obj;
                            self.alert_show ='';

                        }else{
                            self.claim_bonus_obj=null;
                            var alert_obj2={
                                title:'Message',
                                content:response.msg,
                                ok_btn:true,
                                close_btn:false,
                                // after_ok: alert_obj_after_ok,
                            }
                            self.alert_obj = alert_obj2;
                            self.alert_show ='';
                            // alert(response.msg);
                        }
                        

                    });
        
                }else{
                    this.$modal.show('AuthModal');
                }
     
        },
        getGP(packet_id,packet_type){
            var gp;packet_type;

            for(let i=0;i<this.GAME_PACKET_LIST.length;i++){
                // console.log(packet_id,this.GAME_PACKET_LIST[i].packet_id);
                if(packet_id==this.GAME_PACKET_LIST[i].packet_id){
                    gp = this.GAME_PACKET_LIST[i];
                    break;
                }
            }
            // console.log(gp);
            
            return gp;
        },
        cancelPurchase(){
            this.claim_bonus_obj =null;
        },
        goToGame(key){
            if(this.pes_profile){
                var url=`${this.envDomains.VUE_APP_PES_BASE_URL}/game/casinoonline/sa_sexy_redirect.php?mode=mobile&by=&service=pragmatic&code=`+key+'&token='+this.pes_profile.token+(this.appImageProfile?('&logo_img='+this.appImageProfile):'');
                window.open(url,'_blank');
            }else{
                this.$modal.show('AuthModal');
            }
            
        },
        imageLoaded() {
            if(!this.$refs.carousel) {
                return
            }
            this.$refs.carousel.computeCarouselWidth()
        },
    },
     watch: {
        
        message: function(obj) { 
            if(obj.data){
                if(this.message.data.length!=this.GAME_PACKET_LIST.length){
                    this.getGamePacket(obj.data);
                }
                
            }
            
        }
      },
      created() {
          this.PES_BASE_URL = this.envDomains.VUE_APP_PES_BASE_URL
      },
    async mounted() {
        if(this.message) {
            if(this.message.data){
                this.getGamePacket(this.message.data);
            }
        }
    }

}
function timeBetweenDatesV2(toDate, prefix = '') {
        // console.log(prefix);
        var dateEntered = toDate;
                var now = new Date();
                var difference = dateEntered.getTime() - now.getTime();
                if (difference <= 0) {
        // Timer done
        
        $('#'+prefix).text('หมดเวลา');
        return 1;
        } else {
        
        var seconds = Math.floor(difference / 1000);
                var minutes = Math.floor(seconds / 60);
                var hours = Math.floor(minutes / 60);
                var days = Math.floor(hours / 24);
                
                minutes %= 60;
                seconds %= 60;
                hours %=24;
                if(days>0){
                        $("#" + prefix).text('เหลือเวลา '+days+' days');
                }else{
                        if(hours>0){
                                $("#" + prefix).text('เหลือเวลา '+hours+':'+minutes+':'+seconds);

                        }else if(minutes>0){
                                $("#" + prefix ).text('เหลือเวลา '+minutes+':'+seconds);
                        }else{
                                $("#" + prefix).text('เหลือเวลา '+seconds+' second');
                        }

                }
                // console.log($("#" + prefix).text());

                return 0;
        }
        }
</script>
<style src="@/assets/css/package.css" scoped></style>
<style scoped>
    .content-bonus-day,
    .box-alert-content {
        width: 100%;
        height: 310px;
    }
    .row-box-award ul li{
        width: auto;
        background-color: transparent;
    }
    .row-box-award .set-coin,
    .row-box-award .Value-item {
        width: 30%;
    }
</style>

