<template>
    <div ref="richMessageItem" class="rich-message-item square">
        <div @click="openLink(0)" class="rich-message-box" :class="{'show-grid': isShowGrid}" 
        style="width: 100%; height: 50%; border-left: none; border-right: none; border-top: none;">
            <span class="rich-message-box-label">A</span>
        </div>
        <div @click="openLink(1)" class="rich-message-box" :class="{'show-grid': isShowGrid}" 
        style="width: 100%; height: 50%; border-left: none; border-right: none; border-bottom: none;">
            <span class="rich-message-box-label">B</span>
        </div>
    </div>
</template>
<script>
import RichMessagetTemplateMixin from './mixins'
export default {
    name: 'RichMessageTemplateType0',
    mixins: [
        RichMessagetTemplateMixin,
    ],
}
</script>
<style scoped src="./style.css"></style>