<template>
    <div v-if="round_map" class="LottoTypePlateList all-content-tab">
<!--        all-content-tab-list-->
        <carousel
                :speed="300"
                :per-page="4.3"
                :perPageCustom="[[768, 5], [1400, 5]] "
                :mouseDrag="true"
                :spacePadding="5"
                :navigationEnabled="true"
                :paginationEnabled="false"
                navigationNextLabel="❯"
                navigationPrevLabel="❮"
                paginationPosition="bottom"
        >
            <slide class="all-content-tab-list">

                    <LottoTypePlate
                            @close_guess="getLatestRoundAll"
                            @selected="roundSelect"
                            :round_obj="round_map['yeekee']"
                            :selected_round="round_obj"
                            :manual_lotto_type="'yeekee'"
                    ></LottoTypePlate>

            </slide>
            <slide v-for="(lotto_type, key) of lottoTypeArr" :key="key" 
            class="all-content-tab-list"
            v-show="isRoundOpen(round_map[lotto_type])">
                    <LottoTypePlate
                            @selected="roundSelect"
                            :round_obj="round_map[lotto_type]"
                            :selected_round="round_obj"
                    ></LottoTypePlate>

            </slide>
        </carousel>



    </div>
</template>

<script>
import LottoTypePlate from "./LottoTypePlate";
import store from "@/store";
import axiosWrapper from "@/axiosWrapper";
import {lotto_type_arr} from '../Constant'
import { Carousel, Slide } from 'vue-carousel'
export default {
    name: "LottoTypePlateList",
    components: {
        Carousel,
        Slide,
        LottoTypePlate
    },
    data(){
        return {
            round_map: null,
            loading: null,
        }
    },
    computed: {
        round_obj: {
            get(){
                return store.state.lotto.round_obj;
            },
            set(value){
                store.commit('lotto/update_round_obj', value);
            }
        },
        params_round_id: function(){
            return this.pageBetQueryRoundId
        },
        pageBetQueryLottoType() {
            return store.state.lotto.pageBetQueryLottoType
        },
        pageBetQueryRoundId() {
            return store.state.lotto.pageBetQueryRoundId
        },
        query_lotto_type(){
            const lotto_type = this.pageBetQueryLottoType
            const allow_lotto_type_arr = lotto_type_arr
            if(lotto_type){
                return allow_lotto_type_arr.find((allow_lotto_type) => {
                    return allow_lotto_type === lotto_type
                })
            }else{
                return null
            }
        },
        lottoTypeArr() {
            return lotto_type_arr.filter(o => o !== 'yeekee')
        },
    },
    watch: {
        params_round_id: function(new_val){
            if(new_val){
                this.get_round_by_id(new_val);
            }
        },
        query_lotto_type(new_val){
            if(new_val){
                this.setDefaultRound(new_val);
            }
        }
    },
    methods: {
        async getLatestRoundAll() {

            this.loading = this.$loading()

            const round_res = await axiosWrapper({
                method: 'POST',
                url: `${this.envDomains.VUE_APP_LOTTO_API}/api/round/get_latest_all`
            });
            if (round_res.status === 200) {

                this.round_map = round_res.data;

                if(this.query_lotto_type){
                    this.setDefaultRound(this.query_lotto_type);
                }else if(!this.params_round_id){
                    this.setDefaultRound('government');
                }

            } else {
                this.$bvToast.toast(round_res.data.message, {title: 'แจ้งเตือน'});
            }

            this.loading.close()
        },
        setDefaultRound(lotto_type){
            let o;
            for(let i in this.round_map){
                o = this.round_map[i];
                if(o.lotto_type === lotto_type){
                    this.round_obj = o;
                    break;
                }
            }
        },
        checkRoundOpen(round_status, round_timeout) {
            const now = this.$moment();
            const timeout = this.$moment(round_timeout);
            return (round_status === 'open' && now.isSameOrBefore(timeout));
        },
        roundSelect(round){
            if(round){
                store.commit('lotto/updatePageBetQueryLottoType', '')
                store.commit('lotto/updatePageBetQueryRoundId', round.id)
                this.get_round_by_id(round.id)
            }
        },
        async get_round_by_id(id) {

            this.loading = this.$loading()

            if(!this.query_lotto_type){
                const round_res = await axiosWrapper({
                    method: 'POST',
                    url: `${this.envDomains.VUE_APP_LOTTO_API}/api/round/get_by_id`,
                    data: {
                        id: id
                    }
                });
                if (round_res.status === 200) {
                    this.round_obj = round_res.data;
                } else {
                    this.$bvToast.toast(round_res.data.message, {title: 'แจ้งเตือน'});
                }
            }

            this.loading.close()
        },
        isRoundOpen(round_obj) {
            if (round_obj) {
                const now = this.$moment();
                const timeout = this.$moment(round_obj.timeout);
                return (round_obj && round_obj.status === 'open' && now.isSameOrBefore(timeout));
            } else {
                return false;
            }
        },
    },
    mounted() {
        this.getLatestRoundAll();
        if(this.params_round_id){
            this.get_round_by_id(this.params_round_id);
        }
    }
}
</script>

<style scoped>
    .VueCarousel-slide{
        flex-basis: initial;
    }
    .LottoTypePlateList .VueCarousel{width: 100%}
</style>