<template>
    <div class="content-table-info-all ">
            <div class="content-table-info-right">
                <div class="sec-baccarat w-100">
                    <div v-if="tableStatus === GAME_STATUS.FINISH && statLatest" class="box-current-result">
                        <div :class="`current-box ${classComputed(statLatest.game_result)}`">
                            {{resultComputed(statLatest.game_result)}}
                        </div>
                        <p>
                            <small>{{ratioDisplay(statLatest.game_result)}}</small>
                        </p>
                        <p class="point-result">{{calWinnerPoint(statLatest.game_info)}}</p>
                    </div>
                    <div v-else class="box-current-result">
                        <TableStatus 
                        mode="plain"
                        :status="tableStatus" 
                        :secondStart="secondStart"
                        :secondStartRemain="secondStartRemain"></TableStatus>
                    </div>
                </div>
            </div>
            <div class="content-table-info-left" :class="`game-screenshot-table-id-${table_id}`">
                <div class="sec-Baccarat box-table-list" style="width: 100%;">
                    <div class="b-child-tb">
                        <div class="info-panel-play-state">
                            <div class="box-score-table" id="stat-box-bottom" style="height: 110px;">
                                <div class="column" ref="column-left" style="overflow-x: scroll; width: 100%;">
                                    <StatMain 
                                    @existStatWidthChange="mainScroll" 
                                    :statArr="statArr" 
                                    :dataMaxRow="5">
                                    </StatMain>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
</template>
<script>

import StatMain from './StatMain'
import TableListItemMixin from './TableListItemMixin'
import TableStatus from '../TableStatus'
import {GAME_STATUS_KEY} from '../Constant'
import StatScrollMixin from '../StatScrollMixin'

export default {
    name: 'GameTypeItem',
    components: {
        StatMain,
        TableStatus,
    },
    data() {
        return {
            GAME_STATUS: null,
        }
    },
    mixins: [
        TableListItemMixin,
        StatScrollMixin,
    ],
    created() {
        this.GAME_STATUS = GAME_STATUS_KEY
    }
}
</script>
<style src="@/assets/css/game-type-item.css" scoped></style>