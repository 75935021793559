<template>
    <div @click="$emit('click')" class="GameChipForSelect b-coin" :class="`${coinClass}`">
        
    </div>
</template>
<script>

import {CHIP_MAP} from './Constant'

export default {
    name: 'GameChipForSelect',
    props: [
        'value',
        'active',
    ],
    computed: {
        coinClass() {
            let activeClass = ''
            if(this.active) {
                activeClass = 'active'
            }
            return `${CHIP_MAP[parseInt(this.value)]['class']} ${activeClass}`
        }
    },
}
</script>