<template>
    <div class="ConfirmBet">
        <div class="content-modal-list-x">
            <div class="modal-list-middle">
                <div v-if="is_done">
                    <p><i class="fas fa-check"></i></p>
                    <p>โพยเลขที่ #{{bill_id}}</p>
                </div>
                <p v-if="round">{{round.lotto_type | lottoTypeDisplay}} {{round.date}} {{round.sub_round | sub_round_display}}</p>
                <div class="v-bill-detail-wrapper" v-for="(key, i) of bill_key" :key="i">
                    <div v-if="bet_list_filter(key).length">
                        <div class="row" style="border-bottom: solid 1px lightgray;">
                            <div class="col-3 v-header v-no-padding">{{category_list[key].name}}</div>
                            <div class="col-3 v-header v-no-padding" style="text-align: right;">จำนวนเงิน</div>
                            <div class="col-3 v-header v-no-padding" style="text-align: right;">อัตราจ่าย</div>
                            <div class="col-3 v-header v-no-padding" style="text-align: right;">เรทชนะ</div>
                        </div>
                        <div v-for="(bet, i) in bet_list_filter(key)" :key="i">
                            <div class="row">
                                <div class="col-3 v-no-padding">แทงเลข {{bet.number}}</div>
                                <div class="col-3 v-no-padding" style="text-align: right;">{{bet.amount | floatFormat}}</div>
                                <div class="col-3 v-no-padding" style="text-align: right;">{{bet.reward | floatFormat}}</div>
                                <div class="col-3 v-no-padding" style="text-align: right;">{{calWinRate(bet.amount, bet.reward) | floatFormat}}</div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="row v-total v-bill-detail-wrapper">
                    <div class="col-3 v-header v-no-padding">รวม</div>
                    <div class="col-3 v-no-padding" style="text-align: right;">{{cal_total_amount | floatFormat}}</div>
                    <div class="col-3 v-no-padding" style="text-align: right;"></div>
                    <div class="col-3 v-header v-no-padding" style="text-align: right;">{{calTotalWin | floatFormat}}</div>
                </div>
            </div>
            <div class="modal-list-bottom">
                <div v-if="is_done" class="modal-list-bottom-btn">
                    <div @click="$emit('go_bill_detail')" class="btn">ดูโพย</div>
                    <div @click="$emit('bet_done_close')" class="btn btn-black">แทงต่อ</div>
                </div>
                <div v-else class="modal-list-bottom-btn modal-list-bottom-btn-1">

                    <div @click="$emit('bet_cancel')" class="btn btn-red">ยกเลิก</div>
                    <div @click="$emit('bet_confirm')" class="btn btn-green">ส่งโพย</div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>

    import {BILL_KEY, CATEGORY_LIST} from '../Constant'
    import store from "@/store";
    import AppMixin from '../mixins/App'

    export default {
        name: "ConfirmBet",
        mixins: [
            AppMixin,
        ],
        props: [
            'round', 'is_done', 'bill_id'
        ],
        computed: {
            bet_list: function () {
                return store.state.lotto.bet_list;
            },
            cal_total_amount: function () {
                return this.bet_list.reduce((sum, number) => {
                    return parseFloat(sum) + parseFloat(number.amount);
                }, 0)
            },
            calTotalWin(){
                return this.bet_list.reduce((sum, number) => {
                    return parseFloat(sum) + parseFloat(this.calWinRate(number.amount, number.reward));
                }, 0)
            }
        },
        methods: {
            bet_list_filter: function (search) {
                return this.bet_list.filter((bet) => {
                    return bet.bet_type === search
                });
            },
            calWinRate(amount, reward){
                return (parseFloat(amount) * parseFloat(reward)).toFixed(2)
            }
        },
        created() {
            this.category_list = CATEGORY_LIST
            this.bill_key = BILL_KEY
        }
    }
</script>

<style scoped>
    .row {
        margin: 5px 0;
    }
    .ConfirmBet {
        /*font-size: 1vw;*/
    }
    .v-pull-left {
        float: left;
        text-align: left;
    }
    .v-pull-right {
        float: right;
        text-align: right;
    }
    .v-header {
        font-weight: bold;
    }
    .v-total {
        padding-top: 10px;
        background-color: #eee;
    }
    @media only screen and (max-width: 768px) {
        .v-bill-detail-wrapper {
            font-size: 14px;
        }
        .v-no-padding {
            padding: 0!important;
        }
    }
</style>