import { render, staticRenderFns } from "./MiniBaccarat.vue?vue&type=template&id=fc85219a&scoped=true&"
import script from "./MiniBaccarat.vue?vue&type=script&lang=js&"
export * from "./MiniBaccarat.vue?vue&type=script&lang=js&"
import style0 from "@/assets/css/baccarat-style.css?vue&type=style&index=0&id=fc85219a&scoped=true&lang=css&"
import style1 from "@/assets/css/animate-games.css?vue&type=style&index=1&id=fc85219a&scoped=true&lang=css&"
import style2 from "./MiniBaccarat.vue?vue&type=style&index=2&lang=css&"
import style3 from "./MiniBaccarat.vue?vue&type=style&index=3&id=fc85219a&scoped=true&lang=css&"
import style4 from "./MiniBaccarat.vue?vue&type=style&index=4&lang=css&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "fc85219a",
  null
  
)

export default component.exports