import liff from '@line/liff';

export default {
    async initLiff(liffId) {
        return new Promise((resolve) => {
            if(!liffId) {
                resolve({
                    success: false,
                    message: 'ลิ้งค์ไม่ถูกต้อง',
                    message_code: '?liff-id is required.',
                })
            }
            liff.init({ liffId, })
            .then(() => {
                if(!liff.isInClient() && !liff.isLoggedIn() && process.env['NODE_ENV'] !== 'development') {
                    liff.login({redirectUri: window.location.href})
                    return
                }
                liff.getProfile()
                    .then(profile => {
                        console.log('profile', profile)
                    })
                    .catch((err) => {
                        console.log('liff.getProfile error', err);
                    })  
                resolve({
                    success: true,
                    liff: liff,
                })
            })
            .catch((error) => {
                console.log('liff init error: ' + error)
                resolve({
                    success: false,
                    message: 'ไม่สามารถเชื่อมต่อกับเซิฟเวอร์ไลน์ได้ กรุณาลองใหม่อีกครั้ง',
                    message_code: 'liff init error: ' + error,
                })
            })
        })
    },
}