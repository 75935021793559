<template>
    <div class="key-pad">
        <div class="row p-2">
            <div class="col-12">

            </div>
            <div class="col-4">
                <button @click="choose_number(1)" class="btn btn-outline-secondary btn-block" data-id="1">1</button>
            </div>
            <div class="col-4">
                <button @click="choose_number(2)" class="btn btn-outline-secondary btn-block" data-id="2">2</button>
            </div>
            <div class="col-4">
                <button @click="choose_number(3)" class="btn btn-outline-secondary btn-block" data-id="3">3</button>
            </div>

            <!--//////////////-->
            <div class="col-4">
                <button @click="choose_number(4)" class="btn btn-outline-secondary btn-block" data-id="4">4</button>
            </div>
            <div class="col-4">
                <button @click="choose_number(5)" class="btn btn-outline-secondary btn-block" data-id="5">5</button>
            </div>
            <div class="col-4">
                <button @click="choose_number(6)" class="btn btn-outline-secondary btn-block" data-id="6">6</button>
            </div>

            <!--//////////////-->
            <div class="col-4">
                <button @click="choose_number(7)" class="btn btn-outline-secondary btn-block" data-id="7">7</button>
            </div>
            <div class="col-4">
                <button @click="choose_number(8)" class="btn btn-outline-secondary btn-block" data-id="8">8</button>
            </div>
            <div class="col-4">
                <button @click="choose_number(9)" class="btn btn-outline-secondary btn-block" data-id="9">9</button>
            </div>

            <!--//////////////-->
            <div class="col-4">
                <button v-if="mode === 'yeekee'" @click="random" class="btn btn-yellow btn-block btn-cancel">
                    <span><i class="fas fa-random"></i> สุ่มเลข</span></button>
                <button v-else @click="cancel_recent" class="btn btn-secondary btn-block btn-cancel">
                    <span>ยกเลิก</span><span>ล่าสุด</span></button>
            </div>
            <div class="col-4">
                <button @click="choose_number(0)" class="btn btn-outline-secondary btn-block" data-id="0">0</button>
            </div>
            <div class="col-4">
                <button @click="backspace" class="btn btn-danger btn-block"><i class="fas fa-backspace"></i>
                </button>
            </div>

            <div class="col-12">
                <button v-if="mode === 'yeekee'" @click="submit"
                        class="btn btn-success btn-block btn-reset"
                        :class="{'disabled': is_submit_button_disabled}"><span><i class="fas fa-plus"></i> {{submit_button_label}}</span>
                </button>
                <button v-else @click="cancel_all" :disabled="bet_list.length === 0" class="btn btn-dark btn-block btn-reset"><span>ล้าง</span><span>ข้อมูล</span>
                </button>
            </div>
            <!--//////////////-->
        </div>
    </div>
</template>

<script>

    import store from '@/store'

    export default {
        name: "NumPad",
        props: ['mode', 'submit_button_label', 'is_submit_button_disabled'],
        computed: {
            bet_list: function(){
                return store.state.lotto.bet_list;
            }
        },
        methods: {
            choose_number: function(number){
                this.$emit('choose_number', number);
            },
            backspace: function(){
                this.$emit('backspace');
            },
            cancel_recent: function(){
                this.$emit('cancel_recent');
            },
            cancel_all: function(){
                this.$emit('cancel_all');
            },
            random: function(){
                this.$emit('random');
            },
            submit: function(){
                this.$emit('submit');
            }
        }
    }
</script>

<style scoped>

</style>