<template>
    <span>
        <span class="day" v-if="remain.as('day') > 1">{{day}} วัน </span>
        <span v-if="remain.as('second') > 0">{{hour | doubleZero}}:{{minute | doubleZero}}:{{second | doubleZero}}</span>
        <span v-else>00:00:00</span>
    </span>
</template>
<script>

import AlarmMixin from '@/mixins/Alarm'

export default {
    name: 'PromotionTimeAlarm',
    mixins: [
        AlarmMixin,
    ],
}
</script>