<template>
    <div v-if="result" class="Result content-PageResult-d card">
        <div v-show="!no_header" class="card-header">
            <LottoTypeIcon :lotto_type="lotto_type"></LottoTypeIcon>
            <div class="card-header-detail">
                <strong class="card-box">
                    {{lotto_type | lottoTypeDisplay}}
                </strong>
                <span v-if="result[lotto_type]"> ประจำงวด : {{result[lotto_type].round.date}}
                                            {{(result[lotto_type].round.sub_round) ? 'รอบที่ ' + result[lotto_type].round.sub_round : ''}}
                                        </span>
            </div>
        </div>
        <div class="colContent-bill">
            <div v-if="lotto_type === 'government'" class="row ">
                <div class="col-government-thai">
                    <div class="col-government-top col-12 text-center">
                        <div class=" mb-2">
                            <strong>รางวัลที่ 1</strong>
                            <div class="txt-balance txt-balance-thai">{{get_result_str(lotto_type, 'first')}}</div>
                        </div>
                    </div>
                    <div class="col-government-3">
                        <div class="col-4 text-center border-right">
                            <div>
                                <strong>3 ตัวหน้า</strong>
                                <div class="txt-balance">
                                    {{get_result_str(lotto_type, 'sam_tua_nha_1')}}, {{get_result_str(lotto_type, 'sam_tua_nha_2')}}
                                </div>
                            </div>
                        </div>
                        <div class="col-4 text-center  border-right">
                            <div>
                                <strong>3 ตัวล่าง</strong>
                                <div class="txt-balance">{{get_result_str(lotto_type, 'sam_tua_lang_1')}}, {{get_result_str(lotto_type, 'sam_tua_lang_2')}}</div>
                            </div>
                        </div>
                        <div class="col-4 text-center">
                            <div>
                                <strong>2 ตัวล่าง</strong>
                                <div class="txt-balance">{{get_result_str(lotto_type, 'song_tua_lang')}}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div v-else class="row">
                <div class="col-government-2">
                    <div class="col-6 text-center">
                        <div class="border-right">
                            <strong>3 ตัวบน</strong>
                            <div class="txt-balance">{{get_result_str(lotto_type, 'sam_tua_bon')}}</div>
                        </div>
                    </div>
                    <div class="col-6 text-center">
                        <div>
                            <strong>2 ตัวล่าง</strong>
                            <div class="txt-balance">{{get_result_str(lotto_type, 'song_tua_lang')}}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>

import AppMixin from '../mixins/App'
import axiosWrapper from "@/axiosWrapper";
import LottoTypeIcon from './LottoTypeIcon'

export default {
    name: "Result",
    mixins: [
        AppMixin
    ],
    components: {
        LottoTypeIcon,
    },
    props: [
        'lotto_type', 'round_id', 'no_header'
    ],
    data(){
        return {
            result: null,
        }
    },
    methods: {
        getAllLottoTypeLatest: async function () {
            const res = await axiosWrapper({
                method: 'POST',
                url: `${this.envDomains.VUE_APP_LOTTO_API}/api/result/get_all_lotto_type_latest`,
            });
            if (res.status === 200) {
                this.result = res.data;
            } else {
                this.$bvToast.toast(res.data.message, {title: 'แจ้งเตือน'});
            }
        },
        get_result_str: function (lotto_type, result_type) {

            const calDummyDisplay = (result_type) => {
                if(result_type === 'first') {
                    return 'xxxxxx'
                } else if(result_type === 'sam_tua_nha_1' || result_type === 'sam_tua_nha_2' || result_type === 'sam_tua_lang_1' || result_type === 'sam_tua_lang_2' || result_type === 'sam_tua_bon') {
                    return 'xxx'
                } else if(result_type === 'song_tua_lang') {
                    return 'xx'
                }
            }

            try {

                if (this.result && this.result[lotto_type][result_type]) {
                    if(parseInt(this.result[lotto_type]['round_id']) === parseInt(this.round_id)) {
                        return this.result[lotto_type][result_type];
                    } else {
                        return calDummyDisplay(result_type);
                    }
                } else {
                    return calDummyDisplay(result_type);
                }
            } catch (err) {
                console.log('get_result_str err', err.message);
                return calDummyDisplay(result_type);
            }
        },
    },
    mounted() {
        this.getAllLottoTypeLatest();
    }
}
</script>

<style scoped>
    .card-header.card-header-bg {
        background-image: linear-gradient(135deg, #ABDCFF 10%, #0396FF 100%);
        border-bottom: 0px;
        border-radius: 15px 15px 0px 0px;
    }

    .txt-lg {
        font-size: 1.8em;
    }

    .card-radius {
        border-radius: 15px;
    }

    .card-box {
        color: #131619;
        padding: 5px 5px 5px 0px;
        font-size: 1.3em;
    }

    .card-box i, .card-box img {
        border-radius: 5px;
        padding: 5px;
        background-color: #fff;
        margin-right: 5px;
        -webkit-box-shadow: 0px 1px 5px 1px rgba(221, 221, 221, 1);
        -moz-box-shadow: 0px 1px 5px 1px rgba(221, 221, 221, 1);
        box-shadow: 0px 1px 5px 1px rgba(221, 221, 221, 1);
    }

    .card-box img {
        width: 40px;
    }
</style>