<template>
    <div class="GameScoreboardComponent">
        <div class="scoreboard-item-header">
            {{table.table_name}}
            <BaccaratScoreboardHeader v-if="parseInt(tableType) === TABLE_TYPE_KEY.baccarat || parseInt(tableType) === TABLE_TYPE_KEY.baccarat2 || parseInt(tableType) === TABLE_TYPE_KEY.baccarat3"
            :statArr="statArr"></BaccaratScoreboardHeader>
            <DragonTigerScoreboardHeader v-if="parseInt(tableType) === TABLE_TYPE_KEY.dragontiger"
            :statArr="statArr"></DragonTigerScoreboardHeader>
        </div>
        <BaccaratScoreboard v-if="parseInt(tableType) === TABLE_TYPE_KEY.baccarat || parseInt(tableType) === TABLE_TYPE_KEY.baccarat2 || parseInt(tableType) === TABLE_TYPE_KEY.baccarat3"
        :statArr="statArr"></BaccaratScoreboard>
        <DragonTigerScoreboard v-if="parseInt(tableType) === TABLE_TYPE_KEY.dragontiger"
        :statArr="statArr"></DragonTigerScoreboard>
        <div v-if="parseInt(tableType) === TABLE_TYPE_KEY.sicbo" class="scoreboard-item-content">
            <SicboGameTypeItem 
            :table_id="table.table_id"
            :table_round_main="table.table_round_main"
            :limit="8"
            hideHeader="yes"></SicboGameTypeItem>
        </div>
        <div v-if="parseInt(tableType) === TABLE_TYPE_KEY.bean" class="scoreboard-item-content">
            <FantanGameTypeItem 
            :table_id="table.table_id"
            :table_round_main="table.table_round_main"
            :limit="8"
            hideHeader="yes"></FantanGameTypeItem>
        </div>
    </div>
</template>
<script>

import BaccaratScoreboardHeader from '@/components/game/baccarat/ScoreboardHeader'
import BaccaratScoreboard from '@/components/game/baccarat/Scoreboard'
import DragonTigerScoreboardHeader from '@/components/game/dragontiger/ScoreboardHeader'
import DragonTigerScoreboard from '@/components/game/dragontiger/Scoreboard'
import SicboGameTypeItem from '@/components/game/sicbo/GameTypeItem'
import FantanGameTypeItem from '@/components/game/fantan/GameTypeItem'
import {TABLE_TYPE_KEY} from '@/components/game/Constant'

export default {
    name: 'GameScoreboardComponent',
    components: {
        BaccaratScoreboardHeader,
        BaccaratScoreboard,
        DragonTigerScoreboardHeader,
        DragonTigerScoreboard,
        SicboGameTypeItem,
        FantanGameTypeItem,
    },
    props: [
        'tableType',
        'table',
        'statArr',
    ],
    data() {
        return {
            TABLE_TYPE_KEY: null,
        }
    },
    created() {
        this.TABLE_TYPE_KEY = TABLE_TYPE_KEY
    }
}
</script>
<style scoped>
.scoreboard-item-header {
    margin: 0 2px;
    font-weight: bold;
    display: flex;
    align-items: baseline;
    justify-content: space-between;
}
.scoreboard-item-content {
    border-top: solid 1px black;
}
</style>