<template>
    <div>
        <div class="area-topTap">
            <div class="area-topTap-info">
                <div v-show="checkGameProviderAvailable(provider.slug)"  v-for="(provider, key) of providerList" :key="key" class="listTap" @click="OpenGamePopup(provider)">
                    <img :src="provider.image"/>
                </div>
                <div class="listTap listTap-all active"  @click="OpenAllProvider()">
                    <span>ทั้งหมด</span>
                </div>
            </div>
        </div>
        <!--            ////////----- Main Game------ /////////////////////-->
        <div class="GameList-mainGame" :class="{'GameListMainGameShow':is_main_modal_game}">
            <GameList v-if="currentProvider" :provider="currentProvider" :cat="cat" @close="CloseMainGame"></GameList>
        </div>

        <!--        All Provider-->
        <div class="containerAll-provider"  :class="{'GameListProviderShow':is_all_provider}">
            <div class="GameList-box-title">
                <span class="close-GameList-mainGame" @click="CloseAllProvider()"></span>
                <h4>ผู้ให้บริการ</h4>
            </div>
            <div class="containerAll-provider-info">

                <div class="container-provider">
                    <h5>คาสิโนสด</h5>
                    <div class="container-provider-box">
                        <div class="container-provider-info" style="justify-content: center">
                            <div v-show="checkGameProviderAvailable(provider.slug)" v-for="(provider, key) of topProviderList" :key="key" @click="OpenGamePopup(provider)" class="listProvider">
                                <img :src="provider.image"/>
                                <p>{{provider.label}}</p>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="container-provider">
                    <h5>สล็อต</h5>
                    <div class="container-provider-box">
                        <div class="container-provider-info">
                            <div v-show="checkGameProviderAvailable(provider.slug)" v-for="(provider, key) of allProviderList" :key="key" @click="OpenGamePopup(provider)" class="listProvider">
                                <img :src="provider.image"/>
                                <p>{{provider.label}}</p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>
<script>

    import GameList from '@/components/gamelist/GameList'
    import {PROVIDER_LIST} from '@/components/gamelist/Constant'
    import GameListMixin from '@/components/gamelist/GameListMixin'

    export default {
        name: 'AllProvider',
        mixins: [
            GameListMixin,
        ],
        components: {
            GameList,
        },
        props: [
            'cat',
        ],
        data() {
            return {
                // open_panel: false,
                is_main_modal_game: false,
                is_all_provider: false,
                topProviderList: [
                    {
                        slug: 'SAGAME',
                        label: 'SA Gaming',
                        image: '/images/slot/provider/SAGaming-icon.png',
                    },
                    {
                        slug: 'SEXY',
                        label: 'Sexy Baccarat',
                        image: '/images/slot/provider/SexyBaccarat-icon.png',
                    },
                    {
                        slug: 'PRAGMATIC',
                        label: 'Pragmatic Play',
                        image: '/images/slot/provider/PragmaticPlay.png',
                        wrapperClass: 'GameList-box-wide',
                        perPage : 2.8
                    },
                    {
                        slug: 'PRETTY',
                        label: 'Pretty',
                        image: '/images/slot/provider/PRETTY.png',
                        wrapperClass: 'GameList-box-wide',
                    },
                    {
                        slug: 'EVO',
                        label: 'Evolution Gaming',
                        image: '/images/slot/provider/EvolutionGaming.png',
                        wrapperClass: 'GameList-box-wide',
                    },
                ],
                providerList: [
                    {
                        slug: 'PGSOFT',
                        value: 'PGSOFT',
                        label: 'PGSOFT',
                        image: '/images/slot/provider/PGSOFT.png',
                        wrapperClass: '',
                        perPage : 3.2
                    },
                    {
                        slug: 'BPG',
                        value: 'Blueprint',
                        label: 'Blueprint',
                        image: '/images/slot/provider/Blueprint.png',
                        wrapperClass: 'GameList-box-wide',
                        perPage : 2.3
                    },
                    {
                        slug: 'PRAGMATIC',
                        value: 'PragmaticPlay',
                        label: 'PragmaticPlay',
                        image: '/images/slot/provider/PragmaticPlay.png',
                        wrapperClass: 'GameList-box-wide',
                        perPage : 2.8
                    },
                    {
                        slug: 'JILI',
                        value: 'JILI',
                        label: 'JILI',
                        image: '/images/slot/provider/JILI.png',
                        perPage : 3.2
                    },
                    {
                        slug: 'RLX',
                        value: 'RelaxGaming',
                        label: 'RelaxGaming',
                        image: '/images/slot/provider/RelaxGaming.png',
                        wrapperClass: 'GameList-box-wide',
                        perPage : 2.3
                    },
                    {
                        slug: 'MICRO',
                        value: 'MicroGaming',
                        label: 'MicroGaming',
                        image: '/images/slot/provider/MicroGaming.png',
                        perPage : 3.2
                    },

                ],
                currentProvider: null,
                allProviderList: [],
            }
        },
        methods: {
            OpenGamePopup(provider) {
                this.currentProvider = provider
                this.is_main_modal_game = true;
            },
            CloseMainGame() {
                this.currentProvider = null
                this.is_main_modal_game = false;
            },
            OpenAllProvider() {
                this.is_all_provider = true;
            },
            CloseAllProvider() {
                this.is_all_provider = false;
            },
        },
        mounted() {
            this.allProviderList = PROVIDER_LIST
        },
    }

</script>