<template>
    <modal name="AuthModal"
           class="editModalauth"
           :class="{'line-only': isInLineApp}"
            :adaptive="true"
            height="100%"
            width="100%"
            :pivotY="1"
            transition="modal-slide-up"
            :clickToClose="false">
        <button @click="$modal.hide('AuthModal')" class="btn btn-light auth-modal-close-button"><i class="fas fa-times"></i></button>
        <Auth @success="$modal.hide('AuthModal')"></Auth>
    </modal>
</template>
<script>

import Auth from '@/components/Auth'
import store from '@/store'

export default {
    name: 'AuthModal',
    components: {
        Auth,
    },
    computed: {
        isInLineApp() {
            return store.state.isInLineApp  
        },
    },
}
</script>
<style scoped>
.auth-modal-close-button {
    position: absolute;
    top: 10px;
    right: 10px;
    z-index: 5;
}
</style>