<template>
    <div>
        <div class="LiveList">

            <!-- <div class="LiveList-close"></div> -->

            <div class="LiveList-container">
                <div class="LiveList-content-top">
                    <h3>ถ่ายทอดสด</h3>
                    <div class="box-tag">
                        <carousel :speed="300"
                                  :per-page="3.5"
                                  :mouseDrag="true"
                                  :navigationEnabled="true"
                                  :paginationEnabled="false"
                                  navigationNextLabel="❯"
                                  navigationPrevLabel="❮"
                                  paginationPosition="bottom">
                            <slide><span class="active">ทั้งหมด</span></slide>
                            <slide><span>ฟุตบอลสด</span></slide>
                            <slide><span>ฟุตบอลออนไลน์</span></slide>
                            <slide><span>เกมสุดมันส์</span></slide>
                            <slide><span>ฟุตบอลสด</span></slide>
                            <slide><span>ฟุตบอลออนไลน์</span></slide>
                            <slide><span>เกมสุดมันส์</span></slide>
                        </carousel>
                    </div>
                </div>
                <div class="LiveList-content">
                    <ul>
                        <li>
                            <span>สด</span>
                            <img src="@/assets/images/profile1.jpg">
                            <h4>มาเก็บดาวกัน</h4>
                            <p>20</p>
                        </li>
                        <li>
                            <span>สด</span>
                            <img src="@/assets/images/profile1.jpg">
                            <h4>มาเก็บดาวกัน</h4>
                            <p>20</p>
                        </li>
                    </ul>
                    <ul>
                        <li>
                            <span>สด</span>
                            <img src="@/assets/images/profile1.jpg">
                            <h4>มาเก็บดาวกัน</h4>
                            <p>20</p>
                        </li>
                        <li>
                            <span>สด</span>
                            <img src="@/assets/images/profile1.jpg">
                            <h4>มาเก็บดาวกัน</h4>
                            <p>20</p>
                        </li>
                    </ul>
                    <ul>
                        <li>
                            <span>สด</span>
                            <img src="@/assets/images/profile1.jpg">
                            <h4>มาเก็บดาวกัน</h4>
                            <p>20</p>
                        </li>
                        <li>
                            <span>สด</span>
                            <img src="@/assets/images/profile1.jpg">
                            <h4>มาเก็บดาวกัน</h4>
                            <p>20</p>
                        </li>
                    </ul>
                    <ul>
                        <li>
                            <span>สด</span>
                            <img src="@/assets/images/profile1.jpg">
                            <h4>มาเก็บดาวกัน</h4>
                            <p>20</p>
                        </li>
                        <li>
                            <span>สด</span>
                            <img src="@/assets/images/profile1.jpg">
                            <h4>มาเก็บดาวกัน</h4>
                            <p>20</p>
                        </li>
                    </ul>
                    <ul>
                        <li>
                            <span>สด</span>
                            <img src="@/assets/images/profile1.jpg">
                            <h4>มาเก็บดาวกัน</h4>
                            <p>20</p>
                        </li>
                        <li>
                            <span>สด</span>
                            <img src="@/assets/images/profile1.jpg">
                            <h4>มาเก็บดาวกัน</h4>
                            <p>20</p>
                        </li>
                    </ul>
                    <ul>
                        <li>
                            <span>สด</span>
                            <img src="@/assets/images/profile1.jpg">
                            <h4>มาเก็บดาวกัน</h4>
                            <p>20</p>
                        </li>
                        <li>
                            <span>สด</span>
                            <img src="@/assets/images/profile1.jpg">
                            <h4>มาเก็บดาวกัน</h4>
                            <p>20</p>
                        </li>
                    </ul>
                    <ul>
                        <li>
                            <span>สด</span>
                            <img src="@/assets/images/profile1.jpg">
                            <h4>มาเก็บดาวกัน</h4>
                            <p>20</p>
                        </li>
                        <li>
                            <span>สด</span>
                            <img src="@/assets/images/profile1.jpg">
                            <h4>มาเก็บดาวกัน</h4>
                            <p>20</p>
                        </li>
                    </ul>
                    <ul>
                        <li>
                            <span>สด</span>
                            <img src="@/assets/images/profile1.jpg">
                            <h4>มาเก็บดาวกัน</h4>
                            <p>20</p>
                        </li>
                        <li>
                            <span>สด</span>
                            <img src="@/assets/images/profile1.jpg">
                            <h4>มาเก็บดาวกัน</h4>
                            <p>20</p>
                        </li>
                    </ul>
                </div>
            </div>


        </div>
        <div @click="$emit('close')" class="LiveList-bg"></div>
    </div>

</template>
<script>


    import {Carousel, Slide} from 'vue-carousel'

    export default {
        name: 'LiveList',
        components: {
            Carousel,
            Slide,
        }
    }

</script>
<style scoped>
    .VueCarousel-slide{
        flex-basis: initial;
    }
</style>
<style>
    .LiveList-close{
        position: absolute;
        color: white;
        top: 10px;
        left: -40px;
        z-index: 2;
    }
    .LiveList-close:before{
        content: '\e5cd';
        font: 30px "Material Icons";

    }
    .LiveList {
        position: fixed;
        width: 75%;
        top: 0;
        bottom: 0;
        right: 0;
        background-color: white;
        z-index: 500;
    }

    .LiveList-content-top {
        position: sticky;
        top: 0;
        z-index: 2;
        display: flex;
        flex-direction: column;
        background-color: white;
    }

    .LiveList-content-top h3 {
        margin: 5px 5px 0;
        font: 800 23px Athiti;
    }

    .LiveList ul {
        display: flex;
        padding: 0;
    }


    .LiveList ul li {
        /*width: 100px;*/
        /*height: 130px;*/
        padding: 3px;
        position: relative;
    }
    .LiveList ul li h4{
        position: absolute;
        bottom: 10px;
        left: 8px;
        color: white;
        font: 400 12px Helvetica;
        text-decoration: none;
        text-overflow: ellipsis;
        display: block; overflow: hidden;
        white-space: nowrap;
        width: 98%;
        margin: 0;
    }


    .LiveList ul li p{
        position: absolute;
        top: 5px;
        right: 10px;
        font: 11px Helvetica;
        margin: 0;
        padding: 0;
        color: white;
    }
    .LiveList ul li img {
        display: inline-block;
        max-width: 100%;
        max-height: 100%;
        border-radius: 4px;
    }
    .LiveList ul li span {
        position: absolute;
        top: 5px;
        left: 10px;
        color: white;
        padding: 0 5px;
        font-size: 11px;
        background-color: rgba(0, 0, 0, 0.71);
        border-radius: 6px;

    }
    .LiveList ul li span:before{
        content: '';
        width: 12px;
        height: 12px;
        display: inline-block;
        vertical-align: middle;
        background-size: contain;
        margin-right: 5px;
        background-repeat: no-repeat;
        background-image: url("../assets/images/live-icon.gif");
    }
    .box-tag {

        /*display: block;*/
        padding: 0 0 10px 0;

    }

    .box-tag span {
        border-radius: 15px;
        text-align: center;
        font: 400 11px Athiti;
        padding: 3px 10px;
        background-color: #eee;
        color: #000;
        display: inline-block;
        margin: 3px;
    }
    .box-tag span.active{
        background-color: #000;
        color: #fff;
    }
    .LiveList-bg {
        position: fixed;
        z-index: 499;
        top: 0;
        bottom: 0;
        left: 0;
        right: 0;
        background-color: rgba(6, 7, 32, 0.75);
    }

    .LiveList-container{
        height: 100vh;
        overflow-y: scroll;
        overflow-x: hidden;
    }
    /*////////////////////////////////////////////////////*/

    @media only screen
    and (max-device-width: 1300px)
    and (orientation: landscape) {
        .LiveList {
            width:250px;
        }
    }
</style>