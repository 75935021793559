
<template>
    <div class="MessageBaccaratAlert incoming_msg">
        <div class="received_msg type-activity">
            <div class="received_withd_msg">
                <div v-if="message_data" class="box-top-user-flex">
                    <div @click="play" :class="`box-top-user-list ${iconClass}`">
                        <div class="box-games-list box-jackpot">
                            <div class="list-jackpot">
                                <div class="topic-jackpot">
                                    <div v-if="parseInt(bonus_level) === 0" class="f3 blink delay-x1" style="top: -37px; left: -117px; "></div>
                                    <div v-if="parseInt(bonus_level) === 0" class="f3 blink delay-x15" style="top: -15px; left: -123px;"></div>
                                    <div v-if="parseInt(bonus_level) === 0" class="f3 blink delay-x1" style="top: -14px; right: 150px;  "></div>
                                    <div v-if="parseInt(bonus_level) === 0" class="f3 blink delay-x15" style="top: -11px; right: 130px;"></div>
                                </div>
                                <div class="detail-jackpot">
                                        <span>
                                            <strong>คุณ {{message_data.bonus_username}} ได้รับ</strong>
                                            <strong class="txt-large">{{message_data.bonus_current | numberFormat}}</strong>
                                        </span>
                                </div>
                            </div>
                        </div>
                    </div>
                    <!-- <div class="box-top-user-list jackpot-spin-major">
                        <div class="box-games-list box-jackpot">
                            <div class="list-jackpot">
                                <div class="topic-jackpot">
                                </div>
                                <div class="detail-jackpot">
                                        <span><strong>คุณ  Taylor ได้รับ </strong><strong class="txt-large">20,514.294</strong></span>
                                </div>
                            </div>
                        </div>
                    </div> -->
                    <!-- <div class="box-top-user-list jackpot-spin-mini">
                        <div class="box-games-list box-jackpot">
                            <div class="list-jackpot">
                                <div class="topic-jackpot">
                                </div>
                                <div class="detail-jackpot">
                                        <span><strong>คุณ  Taylor ได้รับ </strong><strong class="txt-large">1,000</strong></span>
                                </div>
                            </div>
                        </div>
                    </div> -->
                </div>
            </div>
        </div>
    </div>
</template>
<script>

const ICON_CLASS = {
    0: 'jackpot-spin-grand',
    1: 'jackpot-spin-major',
    3: 'jackpot-spin-mini',
    4: 'jackpot-spin-mini',
}

export default {
    name: 'MessageBonus',
    props: [
        'message',
    ],
    computed: {
        message_data(){
            return (this.message) ? this.message.data : null
        },
        bonus_level() {
            return (this.message_data) ? this.message_data.bonus_level : 0
        },
        timestamp(){
            return (this.message) ? this.message.timestamp : ''
        },
        iconClass() {
            return ICON_CLASS[this.bonus_level]
        },
    },
    methods: {
        play() {
            this.$router.push({name: 'Unbox'})
        },
    },
}
</script>