export const IMAGE_SET = [
    {
        slug: 0,
        class: 'pfa-set1-icon-1',
        title: 'เซท 1',
    },
    {
        slug: 1,
        class: 'pfa-set2-icon-1',
        title: 'เซท 2',
    },
    {
        slug: 2,
        class: 'pfa-set3-icon-1',
        title: 'เซท 3',
    },
    {
        slug: 3,
        class: 'pfa-set4-icon-1',
        title: 'เซท 4',
    },
    {
        slug: 4,
        class: 'pfa-set5-icon-1',
        title: 'เซท 5',
    },
]
export const IMAGE_MAP = {
    0: [
        "/images/set-profile/set1/001.png",
        "/images/set-profile/set1/002.png",
        "/images/set-profile/set1/003.png",
        "/images/set-profile/set1/004.png",
        "/images/set-profile/set1/005.png",
        "/images/set-profile/set1/006.png",
        "/images/set-profile/set1/007.png",
        "/images/set-profile/set1/008.png",
        "/images/set-profile/set1/009.png",
        "/images/set-profile/set1/010.png",
        "/images/set-profile/set1/011.png",
        "/images/set-profile/set1/012.png",
        "/images/set-profile/set1/013.png",
        "/images/set-profile/set1/014.png",
        "/images/set-profile/set1/015.png",
        "/images/set-profile/set1/016.png",
        "/images/set-profile/set1/017.png",
        "/images/set-profile/set1/018.png",
        "/images/set-profile/set1/019.png",
        "/images/set-profile/set1/020.png",
        "/images/set-profile/set1/021.png",
        "/images/set-profile/set1/022.png",
        "/images/set-profile/set1/023.png",
        "/images/set-profile/set1/024.png",
        "/images/set-profile/set1/025.png",
        "/images/set-profile/set1/026.png",
        "/images/set-profile/set1/027.png",
        "/images/set-profile/set1/028.png",
        "/images/set-profile/set1/029.png",
        "/images/set-profile/set1/030.png",
        "/images/set-profile/set1/031.png",
        "/images/set-profile/set1/032.png",
        "/images/set-profile/set1/033.png",
        "/images/set-profile/set1/034.png",
        "/images/set-profile/set1/035.png",
        "/images/set-profile/set1/036.png",
        "/images/set-profile/set1/037.png",
        "/images/set-profile/set1/038.png",
        "/images/set-profile/set1/039.png",
        "/images/set-profile/set1/040.png",
        "/images/set-profile/set1/041.png",
        "/images/set-profile/set1/042.png",
        "/images/set-profile/set1/043.png",
        "/images/set-profile/set1/044.png",
        "/images/set-profile/set1/045.png",
        "/images/set-profile/set1/046.png",
        "/images/set-profile/set1/047.png",
        "/images/set-profile/set1/048.png",
        "/images/set-profile/set1/049.png",
        "/images/set-profile/set1/050.png"
    ],
    1: [
        "/images/set-profile/set2/001.png",
        "/images/set-profile/set2/002.png",
        "/images/set-profile/set2/003.png",
        "/images/set-profile/set2/004.png",
        "/images/set-profile/set2/005.png",
        "/images/set-profile/set2/006.png",
        "/images/set-profile/set2/007.png",
        "/images/set-profile/set2/008.png",
        "/images/set-profile/set2/009.png",
        "/images/set-profile/set2/010.png",
        "/images/set-profile/set2/011.png",
        "/images/set-profile/set2/012.png",
        "/images/set-profile/set2/013.png",
        "/images/set-profile/set2/014.png",
        "/images/set-profile/set2/015.png",
        "/images/set-profile/set2/016.png",
        "/images/set-profile/set2/017.png",
        "/images/set-profile/set2/018.png",
        "/images/set-profile/set2/019.png",
        "/images/set-profile/set2/020.png",
        "/images/set-profile/set2/021.png",
        "/images/set-profile/set2/022.png",
        "/images/set-profile/set2/023.png",
        "/images/set-profile/set2/024.png",
        "/images/set-profile/set2/025.png",
        "/images/set-profile/set2/026.png",
        "/images/set-profile/set2/027.png",
        "/images/set-profile/set2/028.png",
        "/images/set-profile/set2/029.png",
        "/images/set-profile/set2/030.png",
        "/images/set-profile/set2/031.png",
        "/images/set-profile/set2/032.png",
        "/images/set-profile/set2/033.png",
        "/images/set-profile/set2/034.png",
        "/images/set-profile/set2/035.png",
        "/images/set-profile/set2/036.png",
        "/images/set-profile/set2/037.png",
        "/images/set-profile/set2/038.png",
        "/images/set-profile/set2/039.png",
        "/images/set-profile/set2/040.png"
    ],
    2: [
        "/images/set-profile/set3/001.png",
        "/images/set-profile/set3/002.png",
        "/images/set-profile/set3/003.png",
        "/images/set-profile/set3/004.png",
        "/images/set-profile/set3/005.png",
        "/images/set-profile/set3/006.png",
        "/images/set-profile/set3/007.png",
        "/images/set-profile/set3/008.png",
        "/images/set-profile/set3/009.png",
        "/images/set-profile/set3/010.png",
        "/images/set-profile/set3/011.png",
        "/images/set-profile/set3/012.png",
        "/images/set-profile/set3/013.png",
        "/images/set-profile/set3/014.png",
        "/images/set-profile/set3/015.png",
        "/images/set-profile/set3/016.png",
        "/images/set-profile/set3/017.png",
        "/images/set-profile/set3/018.png",
        "/images/set-profile/set3/019.png",
        "/images/set-profile/set3/020.png",
        "/images/set-profile/set3/021.png",
        "/images/set-profile/set3/022.png",
        "/images/set-profile/set3/023.png",
        "/images/set-profile/set3/024.png",
        "/images/set-profile/set3/025.png",
        "/images/set-profile/set3/026.png",
        "/images/set-profile/set3/027.png",
        "/images/set-profile/set3/028.png",
        "/images/set-profile/set3/029.png",
        "/images/set-profile/set3/030.png",
        "/images/set-profile/set3/031.png",
        "/images/set-profile/set3/032.png",
        "/images/set-profile/set3/033.png",
        "/images/set-profile/set3/034.png",
        "/images/set-profile/set3/035.png",
        "/images/set-profile/set3/036.png",
        "/images/set-profile/set3/037.png",
        "/images/set-profile/set3/038.png",
        "/images/set-profile/set3/039.png",
        "/images/set-profile/set3/040.png",
        "/images/set-profile/set3/041.png",
        "/images/set-profile/set3/042.png",
        "/images/set-profile/set3/043.png",
        "/images/set-profile/set3/044.png",
        "/images/set-profile/set3/045.png",
        "/images/set-profile/set3/046.png",
        "/images/set-profile/set3/047.png",
        "/images/set-profile/set3/048.png",
        "/images/set-profile/set3/049.png",
        "/images/set-profile/set3/050.png"
    ],
    3: [
        "/images/set-profile/set4/001.png",
        "/images/set-profile/set4/002.png",
        "/images/set-profile/set4/003.png",
        "/images/set-profile/set4/004.png",
        "/images/set-profile/set4/005.png",
        "/images/set-profile/set4/006.png",
        "/images/set-profile/set4/007.png",
        "/images/set-profile/set4/008.png",
        "/images/set-profile/set4/009.png",
        "/images/set-profile/set4/010.png",
        "/images/set-profile/set4/011.png",
        "/images/set-profile/set4/012.png",
        "/images/set-profile/set4/013.png",
        "/images/set-profile/set4/014.png",
        "/images/set-profile/set4/015.png",
        "/images/set-profile/set4/016.png",
        "/images/set-profile/set4/017.png",
        "/images/set-profile/set4/018.png",
        "/images/set-profile/set4/019.png",
        "/images/set-profile/set4/020.png",
        "/images/set-profile/set4/021.png",
        "/images/set-profile/set4/022.png",
        "/images/set-profile/set4/023.png",
        "/images/set-profile/set4/024.png",
        "/images/set-profile/set4/025.png",
        "/images/set-profile/set4/026.png",
        "/images/set-profile/set4/027.png",
        "/images/set-profile/set4/028.png",
        "/images/set-profile/set4/029.png",
        "/images/set-profile/set4/030.png",
        "/images/set-profile/set4/031.png",
        "/images/set-profile/set4/032.png",
        "/images/set-profile/set4/033.png",
        "/images/set-profile/set4/034.png",
        "/images/set-profile/set4/035.png",
        "/images/set-profile/set4/036.png",
        "/images/set-profile/set4/037.png",
        "/images/set-profile/set4/038.png",
        "/images/set-profile/set4/039.png",
        "/images/set-profile/set4/040.png"
    ],
    4: [
        "/images/set-profile/set5/001.png",
        "/images/set-profile/set5/002.png",
        "/images/set-profile/set5/003.png",
        "/images/set-profile/set5/004.png",
        "/images/set-profile/set5/005.png",
        "/images/set-profile/set5/006.png",
        "/images/set-profile/set5/007.png",
        "/images/set-profile/set5/008.png",
        "/images/set-profile/set5/009.png",
        "/images/set-profile/set5/010.png",
        "/images/set-profile/set5/011.png",
        "/images/set-profile/set5/012.png",
        "/images/set-profile/set5/013.png",
        "/images/set-profile/set5/014.png",
        "/images/set-profile/set5/015.png",
        "/images/set-profile/set5/016.png",
        "/images/set-profile/set5/017.png",
        "/images/set-profile/set5/018.png",
        "/images/set-profile/set5/019.png",
        "/images/set-profile/set5/020.png",
        "/images/set-profile/set5/021.png",
        "/images/set-profile/set5/022.png",
        "/images/set-profile/set5/023.png",
        "/images/set-profile/set5/024.png",
        "/images/set-profile/set5/025.png",
        "/images/set-profile/set5/026.png",
        "/images/set-profile/set5/027.png",
        "/images/set-profile/set5/028.png",
        "/images/set-profile/set5/029.png",
        "/images/set-profile/set5/030.png",
        "/images/set-profile/set5/031.png",
        "/images/set-profile/set5/032.png",
        "/images/set-profile/set5/033.png",
        "/images/set-profile/set5/034.png",
        "/images/set-profile/set5/035.png",
        "/images/set-profile/set5/036.png",
        "/images/set-profile/set5/037.png",
        "/images/set-profile/set5/038.png",
        "/images/set-profile/set5/039.png",
        "/images/set-profile/set5/040.png",
        "/images/set-profile/set5/041.png",
        "/images/set-profile/set5/042.png",
        "/images/set-profile/set5/043.png",
        "/images/set-profile/set5/044.png",
        "/images/set-profile/set5/045.png",
        "/images/set-profile/set5/046.png",
        "/images/set-profile/set5/047.png",
        "/images/set-profile/set5/048.png",
        "/images/set-profile/set5/049.png",
        "/images/set-profile/set5/050.png"
    ]
}
export const REWARD_TYPE_KEY = {
    CREDIT: 0,
    UNBOX: 1,
}