import store from '@/store'
const MARGIN_OFFSET_WIDTH = 10

export default {
    name: 'RichMessageTemplateMixin',
    props: [
        'showGrid',
        'data',
    ],
    computed: {
        isShowGrid() {
            return this.showGrid === 'yes'
        },
        thumbnail() {
            return (this.data) ? this.data.thumbnail : ''
        },
        messageSectionWidth() {
            return store.state.messageSectionWidth
        },
    },
    watch: {
        thumbnail(newVal) {
            this._setImageBackground(newVal)
        },
    },
    methods: {
        async _setImageBackground(imagePath) {

            const getImageDimension = async (url) => {
                return new Promise((resolve) => {
                    const img = new Image();
                    img.onload = function() {
                        resolve({
                            width: this.width,
                            height: this.height,
                        })
                    }
                    img.src = url
                })
            }

            const element = this.$refs.richMessageItem

            const wrapperWidth = parseInt(this.messageSectionWidth - (MARGIN_OFFSET_WIDTH * 2))

            element.style['width'] = `${wrapperWidth}px`
            element.style['height'] = `${wrapperWidth}px`

            if(!imagePath) {
                return
            }

            const url = `${this.envDomains.VUE_APP_API}/${imagePath}`
            
            element.style['background'] = `url(${url})`
            element.style['background-size'] = 'cover'
            element.style['background-repeat'] = 'no-repeat'

            if(element.classList.contains('custom')) {
                const imageDimension = await getImageDimension(url)

                const elementWidth = parseInt(element.style['width'].replace('px', ''))
                const imageScaleRatio = elementWidth / imageDimension.width
                const scaleHeight = parseInt(imageDimension.height * imageScaleRatio)

                element.style['height'] = `${scaleHeight}px`
            }
            
        },
        openLink(index) {
            if(!this.data) {
                return
            }
            const url = this.data.urls[index]['value']
            if(!url) {
                return
            }
            window.open(url)
        },
    },
    mounted() {
        this._setImageBackground((this.data) ? this.data.thumbnail : '')
    },
}