<template>
<div class="Drawer">

    <b-modal id="pleaseWaitModal" 
    no-close-on-backdrop 
    no-close-on-esc 
    hide-header
    hide-footer
    centered>
        <Loading
        mode="spinner"
        message="กำลังบันทึกข้อมูล กรุณารอสักครู่"></Loading>
    </b-modal>
</div>
</template>
<script>

import LayoutMixin from '@/mixins/Layout'
import Loading from '@/components/Loading'

export default {
    name: 'Drawer',
    components: {
        Loading,
    },
    mixins: [
        LayoutMixin,
    ],
    watch: {
        showDrawer(newVal) {
            if(!newVal) {
                this.clearCurrentGame()
            }
        },
    },
    methods: {
        close() {
            this.closeDrawer()
        },
        clearCurrentGame() {
            this.$bvModal.show('pleaseWaitModal')
            setTimeout(() => {
                this.current_game = ''
            }, 400)
        },
        destroyedHandle() {
            this.$bvModal.hide('pleaseWaitModal')
        }
    },
}
</script>
<style scoped>
.Drawer {
    width: 100%;
    height: 100vh;
    position: absolute;
    top: 0;
    background-color: white;
    z-index: 999;
    left: 0;
    overflow-y: auto;
}
</style>