<template>
    <div :class="`GameResult ${resultClass}`">
        <!-- win-game lose-game draw-game -->
        <div class="topic-result">
            <div class="box-blink">
                <div class="f3 blink delay-x1" style="top: -37px; left: -51px; "></div>
                <div class="f3 blink delay-x15" style="top: -15px; left: -65px;"></div>
                <div class="f3 blink delay-x1" style="top: -32px; right: -50px;  "></div>
                <div class="f3 blink delay-x15" style="top: -11px; right: -37px;"></div>
            </div>
        </div>

<!--        <div class="text-result" v-show="profitLoss > 0">คุณชนะ</div>-->
<!--        <div class="text-result" v-show="profitLoss < 0">คุณเสีย</div>-->
        <div class="item-point">
            <span v-show="profitLoss > 0" class="txt-green">
                    <img src="@/assets/images/coin-big-1.png" alt="" />

<!--                <p>ได้</p>-->
                <h3>{{profitLoss | floatFormat}}</h3>
                <div class="spin-win"></div>
            </span>
            <span v-show="profitLoss < 0" class="txt-red">
                <p>เสีย</p>
                <h3>{{profitLoss | floatFormat}}</h3>

            </span>
            <span v-show="profitLoss === 0">
                 <p>ได้</p>
                <h3>0</h3>
            </span>
        </div>
    </div>
</template>
<script>
export default {
    name: 'GameResult',
    props: [
        'profitLoss',
    ],
    computed: {
        resultClass() {
            if(parseFloat(this.profitLoss) > 0) {
                return 'win-game'
            } else if(parseFloat(this.profitLoss) < 0) {
                return 'lose-game'
            } else if(parseFloat(this.profitLoss) === 0) {
                return 'draw-game'
            } else {
                return ''
            }
        },
    },
}
</script>

<style scoped>
    .text-result{
        text-align: center;
        color: #fff !important;
        font-size: 22px;
    }
</style>