export const groupBy = (arr, col) => {
    return arr.reduce((rv, x) => {
        (rv[x[col]] = rv[x[col]] || []).push(x);
        return rv;
    }, {});
}
export const removeDuplicate = (arr) => {
    return Array.from(new Set(arr));
};
export const objectToArray = (obj) => {
    const arr = []
    for(const key in obj) {
        arr.push(obj[key])
    }
    return arr
}