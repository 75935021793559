<template>
    <div class="About">
        <h3>กฎและกติกาการแทงหวยออนไลน์</h3>
        <p>เพื่อหลีกเลี่ยงปัญหาต่างๆในขณะที่ใช้งานเว็บไซต์ สมาชิกกรุณาอ่านกฎกติกาอย่างละเอียดเเละระมัดระวัง เมื่อสมาชิกเข้าสู่หน้าเดิมพันจะถือว่าท่านได้ยอมรับข้อตกลงของเว็บไซต์เรา กรุณาอ่านและทำความเข้าใจก่อนทำการสมัครสมาชิก</p>
        <span>
              <h4>เงื่อนไขและข้อตกลง</h4>
              <ol>
                  <li>1. เงื่อนไขและข้อตกลงสำหรับท่านที่สมัครและมีบัญชีลูกค้าเปิดกับเว็บไซต์ ลูกค้าต้องยอมรับกับเงือนไขและข้อตกลงของบริษัท เมื่อมีการลงทะเบียนกับบริษัทแล้ว ทางเราจะถือว่าลูกค้ายอมรับและเข้าใจเป็นอย่างดีแล้ว</li>
                  <li>2. บุดคลที่มีความประสงค์สมัครสมาชิกและเปิดบัญชีกับทางบริษัท จะต้องเป็นผู้มีอายุมากกว่า 18 ปีบริบูรณ์</li>
                  <li>3. ห้ามมิให้ใช้ โปรแกรมอัตโนมัติ โปรแกรมช่วยเล่น โปรแกรมโกงทุกชนิด สำหรับลูกค้าที่มีพฤติกรรมจงใจใช้โปรแกรมดังกล่าว หากทางเว็บไซต์ตรวจพบ ทางเว็บไซต์ขอสงวนสิทธิ์ในการยึดเครดิตที่มีในยูสเซอร์ของลูกค้าทั้งหมด โดยไม่ต้องแจ้งล่วงหน้า</li>
                  <li>4. เว็บไซต์มีสิทธิ์ปรับปรุงเปลี่ยนแปลงได้ตลอดเวลาโดยไม่ต้องมีการแจ้งให้ทราบ กติกาและเงื่อนไข กฎและข้อบังคับ ที่เห็นจากเวลาหนึ่งถึงเวลาหนึ่ง ซึ่งควรจะเป็นความรับผิดชอบของท่านในการตรวจสอบกติกาและเงื่อนไขสำหรับการเปลี่ยนแปลงหรือปรับปรุงที่มีเพื่อการใช้เว็บไซต์นี้ต่อ การแก้ไขให้ถูกต้องในกติกาและเงื่อนไขนี้จะมีผลทันทีหลังจากการติดประกาศ</li>
                  <li>5. ถ้าเกิดการคลาดเคลื่อนหรือผิดพลาดในการจ่ายเงินรางวัลหรือเดิมพันของระบบ ไม่ว่าเกิดจากเหตุการณ์ใดๆก็ดี บริษัทจะยึดถือตามรายงานจากระบบเป็นหลัก</li>
                  <li>6. ทางทีมงานจะไม่รับผิดชอบใดๆ ทั้งสิ้น ต่อลูกค้าที่ใช้ Username ร่วมกัน เพราะถือเป็นข้อตกลงที่ลูกค้าต้องพึงเก็บรักษาไว้เป็นอย่างดี โดยไม่ให้บุคคลอื่นทราบ</li>
                  <li>7. เว็บไซต์อาจระงับหรือยกเลิกบัญชีชั่วคราวของท่านได้ทันที หากตรวจพบว่าท่านทำผิดกติกาข้อหนึ่งข้อใดที่กล่าวมาข้างต้น ในกรณีที่มีความผิดพลาดที่เกิดจากท่าน บริษัทขอสงวนสิทธิ์ในการตรวจสอบแก้ไข ภายใต้เงื่อนไขและกติกาที่ระบุไว้ในเว็บไซต์ และขอสงวนสิทธิ์ในการระงับการถอนเงินไว้ชั่วคราวจนกว่าจะตรวจสอบแก้ไขและหาข้อเท็จจริงได้</li>
                  <li>8. การตัดสินของทางเว็บไซต์ถือเป็นเด็ดขาดและสิ้นสุด ลูกค้าไม่มีสิทธิ์โต้แย้ง อุทธรณ์หรือขอให้มีการเปลี่ยนแปลงใดๆ ทั้งสิ้นไม่ว่าด้วยเหตุผลใดก็ตาม</li>
              </ol>
          </span>
        <span>
                <h4>การรับวางเดิมพัน</h4>
                <ol>
                    <li>1. ทุกอัตราการจ่ายเงินรางวัล เว็บไซต์เรามีการเปลี่ยนแปลงขึ้นหรือลงอยู่ตลอดเวลา ก่อนที่ลูกค้าจะทำการเดิมพันใดๆ ลูกค้าต้องมีหน้าที่ตรวจสอบอัตราการจ่าย ณ.เวลาที่เดิมพันให้ถี่ถ้วน ก่อนทำการเดิมพัน <br>
                        <small>**หากมีปัญหาในการรับรางวัล ทางเว็บไซต์จะยึดระบบที่เห็นในหน้ารายงานเป็นหลักเท่านั้น**</small>
                    </li>
                    <li>2. ลูกค้ามีหน้าที่รับผิดชอบความถูกต้องของรายละเอียดการวางเดิมพัน เมื่อท่าน ลงเดิมพันแล้วและมีการยืนยันรับวางเดิมพันแล้วลูกค้าจะไม่สามารถยกเลิกหรือ เปลี่ยนแปลงได้</li>
                    <li>3. บริษัทฯ ขอสงวนสิทธิ์ในการระงับตลาดการวางเดิมพันชั่วคราวได้ทุกเวลา ใน ระหว่างการหยุดรับเราจะปฏิเสธการวางเดิมพัน บริษัทฯ ขอสงวนสิทธิ์ในการหยุด รับการเดิมพันได้ทุกเกมส์ทุกเวลา โดยมิต้องแจ้งให้ทราบล่วงหน้า</li>
                    <li>4. เงินที่ได้จากการชนะจะถูกโอนเข้าบัญชีของลูกค้าตามที่ลูกค้าได้แจ้งไว้เท่านั้น ถ้าต้องการเปลี่ยนแปลงหรือแก้ไขบัญชีในการรับโอนเงินกรุณาต้อต่อเจ้าหน้าที่ โดยตรง</li>
                    <li>5. บริษัทฯจะไม่รับผิดชอบในกรณีที่อุปกรณ์หรือการติดต่อล้มเหลวซึ่งเป็นผลให้ ไม่สามารถวางพนันตามที่ลูกค้าต้องการได้</li>
                </ol>
            </span>
        <span>
              <h4>ด้านความปลอดภัยและการคุ้มครองข้อมูลบุคคล</h4>
              <p>
                  เรามุ่งมั่นในการคุ้มครองข้อมูลส่วนบุคคลของลูกค้า ข้อมูลที่ทางลูกค้าให้มาจะถูกเก็บไว้เป็นความลับขั้นสูงสุด ดังนั้นทางเว็บไซต์จึงได้นำโปรแกรมการคุ้มครองข้อมูลและความเป็นส่วนตัวในระดับสากลมาใช้เพื่อสร้างและรักษามาตรฐานสำหรับการเก็บรวบรวม
                  การรักษาความปลอดภัย หรือการประมวลผลข้อมูลส่วนบุคคลในระดับสูง นโยบายการคุ้มครองข้อมูลส่วนบุคคลและความเป็นส่วนตัวสากลนี้เป็นรากฐานของโปรแกรมการคุ้มครองข้อมูลและความเป็นส่วนตัว เพื่อป้องกันไม่ให้ข้อมูลรั่วไหลหรือนำไปใช้อย่างไม่ถูกต้อง
                  ในกรณีที่ท่านมีข้อสงสัย ข้อเสนอแนะ หรือข้อติชมใดๆ เกี่ยวกับนโยบายการคุ้มครองข้อมูลนี้ เรายินดีที่จะตอบข้อสงสัย รับฟังข้อเสนอแนะ และคำติชมทั้งหลาย อันจะเป็นประโยชน์ต่อการปรับปรุงการให้บริการของทางเว็บไซต์ต่อไป เรายินดีให้บริการท่านตลอด24ชั่วโมง
              </p>
          </span>
    </div>
</template>

<script>
    export default {
        name: "About"
    }
</script>

<style scoped>
    .About {font-size: 14px;}
    .About h3{
        color: #c0392b;
        font: 800 25px/23px "Athiti";
    }
    .About h4{font-size: 18px;  color: #c0392b;position: relative; margin-bottom: 10px;}
    /*.About h4:after{*/
    /*    content: ' ';*/
    /*    position: absolute;*/
    /*    left: 0;*/
    /*    bottom: -5px;*/
    /*    height: 5px;*/
    /*    width: 20%;*/
    /*    background-color: #c0392b;*/
    /*}*/
    .About li ,
    .About p{font-size: 14px}
    .About span{
        display: inline-block;
        margin-bottom: 10px;
        padding-bottom: 10px;
    }
    .About span:last-child{
        border-bottom: 0;
    }
</style>