<template>
    <div :class="`UserLevel box-level-incoming_msg bx-lv-${levelClass}`">
        <span>
            <i class="far fa-gem"></i> 
            {{level}}
        </span>
    </div>
</template>
<script>
export default {
    name: 'UserLevel',
    props: [
        'level',
    ],
    computed: {
        levelClass() {
            return parseInt(parseInt(this.level)/10)
        },
    },
}
</script>