<template>
    <div class="MessageSystem incoming_msg">
        <div class="incoming_msg_img">
            <img src="@/assets/images/img-other/bell.png" alt="sunil" />
        </div>
        <div class="received_msg">
            <div class="received_withd_msg">
                <div>
                    <div class="box-top-user-flex">
                        <div v-for="(m, i) of message_data" :key="i" class="box-top-user-list">
                            <div class="box-games-list">
                                <ImageBackground 
                                :url="generateThumbnailUrl(m.thumbnail)" 
                                customClass="box-img-game image-background"/>
                                <div class="box-info-game">
                                    <h3>{{m.title || 'หัวข้อ'}}</h3>
                                    <div class="txt-detail">
                                        {{m.description || 'คำอธิบาย'}}
                                    </div>
                                    <a :href="m.url" target="blank">
                                        <div class="bt-play-game">เล่นเลย</div>
                                    </a>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <span class="time_date"> {{timestamp | chatMessageDateTimeFormat}}</span>
            </div>
        </div>
    </div>
</template>
<script>

import ImageBackground from '@/components/ImageBackground'

export default {
    name: 'MessageSystem',
    props: ['message'],
    components: {ImageBackground},
    computed: {
        message_data(){
            return (this.message) ? this.message.data : null
        },
        timestamp(){
            return (this.message) ? this.message.timestamp : ''
        }
    },
    methods: {
        generateThumbnailUrl(src){
            return (src) ? `${this.envDomains.VUE_APP_API}/${src}` : ''
        }
    }
}
</script>