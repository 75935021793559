<template>
<div>
    <div class="MessageIncomingText incoming_msg incomingSticker">
        <div class="incoming_msg-info">
            <div class="incoming-flex">
                <div class="incoming_msg_img">
                    <el-image :src="user_picture_url">
                        <img slot="error" src="/images/avatar.png" />
                    </el-image>
                    <div class="" style="background-image: url(&quot;&quot;);"></div>
                </div>
                <div class="incoming-content">
                    <div class="incoming-content-title">
                        <UserLevel :level="user_level"></UserLevel>
                        <div class="received_msg">
                            <h3>{{user_display_name}}</h3>
                        </div>
                    </div>
                    <div class="box-msg box-msg-chat">
                        <div :class="message.data.class" :style="{'background-image':'url('+(message.data.url?message.data.url:'')+')'}"></div>
                    </div>
                </div>

            </div>

        </div>
    </div>
    <!-- <div class="MessageIncomingText incoming_msg incomingSticker">
        <div class="incoming_msg-info">
            <div class="incoming-flex">
                <div class="incoming_msg_img">
                    <div class="Avatar">
                    <span class="el-avatar el-avatar--large el-avatar--circle">
                        <img src="/images/set-profile/set1/048.png" style="object-fit: cover;">
                    </span>
                    </div>
                    <div class="" style="background-image: url(&quot;&quot;);"></div>
                </div>
                <div class="incoming-content">
                    <div class="incoming-content-title">
                        <div class="UserLevel box-level-incoming_msg bx-lv-0">
                            <span><i class="far fa-gem"></i> 0 </span></div>
                        <div class="received_msg">
                            <h3>aa149</h3>
                        </div>
                    </div>
                    <div class="box-msg box-msg-chat">
                        <div class="sticker-1-2"></div>
                    </div>
                </div>

            </div>

        </div>
    </div>
    <div class="MessageIncomingText incoming_msg incomingSticker">
        <div class="incoming_msg-info">
            <div class="incoming-flex">
                <div class="incoming_msg_img">
                    <div class="Avatar">
                    <span class="el-avatar el-avatar--large el-avatar--circle">
                        <img src="/images/set-profile/set1/048.png" style="object-fit: cover;">
                    </span>
                    </div>
                    <div class="" style="background-image: url(&quot;&quot;);"></div>
                </div>
                <div class="incoming-content">
                    <div class="incoming-content-title">
                        <div class="UserLevel box-level-incoming_msg bx-lv-0">
                            <span><i class="far fa-gem"></i> 0 </span></div>
                        <div class="received_msg">
                            <h3>aa149</h3>
                        </div>
                    </div>
                    <div class="box-msg box-msg-chat">
                        <div class="sticker-3-5"></div>
                    </div>
                </div>

            </div>

        </div>
    </div> -->
</div>

</template>
<script>

import store from '@/store'
import UserLevel from '@/components/user/Level'

export default {
    name: 'IncomingEmoji',
    props: ['message'],
    components: {
        UserLevel,
    },
    computed: {
        user_info_arr(){
            return store.state.user_info_arr
        },
        user_info(){
            return this.user_info_arr.find((user_info) => {
                return parseInt(user_info.id) === parseInt(this.message.user_id)
            })
        },
        user_display_name(){
            return (this.user_info) ? this.user_info.display_name : ''
        },
        user_level(){
            return (this.user_info) ? this.user_info.level : 0
        },
        user_picture_url(){
            return (this.user_info) ? this.user_info.picture_url : ''
        },
    },
}
</script>

<style scoped>
    .incoming_msg_img{
        /*margin-bottom: 32px;*/
    }
</style>