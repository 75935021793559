<template>
  <div
    class="wrapContentIndex wrapContentIndexFishing"
    :class="{ wrapContentAllInto: providerInfo }"
  >
    <div class="contentOverlay" :class="{ popupProvider: isHideProvider }">
      <div class="btn-close-popupAllgame" @click="ClosePopupProvider()"></div>
      <ProviderList @openGameList="ClosePopupProvider"></ProviderList>
    </div>
    <FloatButtomRightPanel :callChatWithAdmin="showChatWithAdminNumber" />
    <div class="wrapContentIndexTitle">
      <div class="hamburger-lines" @click="OpenListProvider()">
        <span class="line line1"></span>
        <span class="line line2"></span>
        <span class="line line3"></span>
      </div>
      <h3>ยิงปลา</h3>
    </div>
    <div class="navTopDesktop">
      <MenuNavTop
        @showMenuNav="showMenuNav"
        @showChatWithAdmin="showChatWithAdmin" @showDepositWithdrawModal="showDepositWithdrawModal"
      ></MenuNavTop>
    </div>
    <MenuBar :callMenu="menuNumber" :callDWMenu="showDepositWithdrawNumber"></MenuBar>
    <div class="wrapContentIndexInfo">
      <div class="contentFlexIndex">
        <div class="contentFlexIndexLeft active">
          <MenuLeft></MenuLeft>
        </div>
        <div class="contentFlexIndexRight">
          <!--                    <div class="contentFlexIndexRightInfo">-->
          <div class="container">
            <GameListCasino v-if="false"></GameListCasino>
            <div
              class="containerGameList"
              :class="{ GameListContainer: isHideListGameContent }"
            >
              <div class="containerGameListinfo">
                <HorizontalShortList2
                  v-for="(provider, key) of providerList"
                  :key="key"
                  :provider="provider"
                  :cat="catPrev"
                  :showMore="true"
                  @seeAll="OpenListAllGame"
                >
                </HorizontalShortList2>
              </div>
            </div>
            <div
              v-if="providerInfo"
              class="containerGameListinfoAll AllContainerGameList"
              :class="[
                providerInfo.wrapperClass,
                isHideListGame ? 'allListGameProvider' : '',
              ]"
            >
              <div class="nav-menu-topic">
                <button
                  type="button"
                  class="btn btn-link"
                  @click="CloseListAllGame()"
                >
                  <i class="fas fa-angle-left"></i> <span>กลับ</span>
                </button>
                <img :src="providerInfo.img" />
                <h4>{{ providerInfo.name }}</h4>

                <div class="box-sublist-area-topTap">
                  <span class="boxTopTapInput">
                    <i class="fas fa-search"></i>
                    <input v-model="keyword" type="text" placeholder="ค้นหา" />
                  </span>
                </div>
              </div>

              <ListAllGame
                :list="showList"
                :cat=" providerInfo.cat ?  providerInfo.cat : cat "
                :provider="currentProvider"
                :keyword="keyword"
              ></ListAllGame>
              <div class="load-more-section">
                <Loading v-if="isOnLoad" mode="spinner"></Loading>
                <button
                  v-else-if="isCanLoadMore"
                  @click="loadMore"
                  type="button"
                  class="btn btnLoadMore"
                ></button>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="navButtonMobile">
        <MenuNav
          @showMenuNav="showMenuNav"
          @showChatWithAdmin="showChatWithAdmin" @showDepositWithdrawModal="showDepositWithdrawModal"
        ></MenuNav>
      </div>
    </div>
  </div>
</template>
<script>
import FloatButtomRightPanel from "@/components/FloatButtomRightPanel";
import ListAllGame from "@/components/ListAllGame2";
import MenuBar from "@/components/MenuBar";
import MenuLeft from "@/components/MenuLeft";
import MenuNav from "@/components/MenuNav";
import MenuNavTop from "@/components/MenuNavTop";
import HorizontalShortList2 from "@/components/gamelist/HorizontalShortList2";
import ProviderList from "@/components/ProviderList";
import {
  ALL_PROVIDER_LIST,
  PES_PROVIDER_LIST,
} from "@/components/gamelist/Constant";
export default {
  name: "IndexFishing",
  components: {
    FloatButtomRightPanel,
    MenuBar,
    MenuLeft,
    MenuNav,
    MenuNavTop,
    HorizontalShortList2,
    ListAllGame,
    ProviderList,
  },
  data() {
    return {
      showChatWithAdminNumber: 0,
      showDepositWithdrawNumber:0,
      menuNumber: 0,
      isHideProvider: false,
      games: [],
      icons: [
        "/images/icon/set-icon/icon-casino.png",
        "/images/icon/set-icon/icon-slot.png",
        "/images/icon/set-icon/icon-casino.png",
        "/images/icon/set-icon/icon-football.png",
        "/images/icon/set-icon/icon-fishing.png",
        "/images/icon/set-icon/icon-lotto.png",
        "/images/icon/set-icon/icon-roulette.png",
        "/images/icon/set-icon/icon-case.png",
      ],
      selectedBrand: "queenmaker",
      list2: null,
      service_list: `${process.env.VUE_APP_PES_SERVICE_LIST}`.split(","),
      cat: "FISHING",
      catPrev: "FISHING",
      tag: "",
      tagPrev: "",
      keyword: "",
      keywordPrev: "",
      providerList: [
        {
          slug: "JILI",
          label: "JILI",
          image: "/images/slot/provider/JILI.png",
          wrapperClass: "GameList-box-square",
          perPage: 2.8,
        },
        {
          slug: "PRAGMATIC",
          label: "PragmaticPlay",
          image: "/images/slot/provider/PragmaticPlay.png",
          wrapperClass: "",
          perPage: 2.5,
        },
        {
          slug: "CQ9",
          label: "CQ9",
          image: "/images/slot/provider/CQ9.png",
          wrapperClass: "",
          perPage: 3.2,
        },
      ],
      currentProvider: "jili",
      providerInfo: null,
      isShowGameListModal: false,
      showList: [],
      isHideListGame: true,
      isHideListGameContent: false,
      providerMap: {
        pragmatic: {
          img: "/images/slot/provider/PragmaticPlay.png",
          name: "Pragmatic Play",
          wrapperClass: "GameList-box-wide",
        },
        sa: {
          img: "/images/slot/provider/SAGaming-icon.png",
          name: "Sa Gaming",
          wrapperClass: "GameList-box-wide",
        },
        sexy: {
          img: "/images/slot/provider/SexyBaccarat-icon.png",
          name: "Sexy Baccarat",
          wrapperClass: "GameList-box-wide",
        },
        pretty: {
          img: "/images/slot/provider/PRETTY.png",
          name: "Pretty",
          wrapperClass: "GameList-box-wide",
        },
        evo: {
          img: "Evolution Gaming",
          name: "/images/slot/provider/EvolutionGaming.png",
          wrapperClass: "GameList-box-wide",
        },
        jili: {
          img: "/images/slot/provider/JILI.png",
          name: "Jili",
        },
        cq9: {
          name: "CQ9",
          img: "/images/slot/provider/CQ9.png",
          wrapperClass: "GameList-box-square",
        },
      },
      isOnLoad: false,
      isCanLoadMore: false,
      page: 1,
      itemsPerPage: 15,
    };
  },
  methods: {
    showChatWithAdmin() {
      this.showChatWithAdminNumber = 1;
      let self = this;
      setTimeout(() => {
        self.showChatWithAdminNumber = 0;
      }, 100);
    },
    showDepositWithdrawModal(){
                this.showDepositWithdrawNumber=1;
                let self =this;
                setTimeout(()=>{
                    self.showDepositWithdrawNumber=0;
                },100);
                
    },

    showMenuNav(menuNumber) {
      this.menuNumber = menuNumber;
      let self = this;
      setTimeout(() => {
        self.menuNumber = 0;
      }, 1000);
    },
    getProviderInfo(provider) {
      const PROVIDER = provider.toUpperCase();
      var info = null;
      for (let i = 0; i < PES_PROVIDER_LIST.length; i++) {
        let p = PES_PROVIDER_LIST[i];
        if (p.slug == PROVIDER) {
          info = {
            img: p.image,
            name: p.label,
            wrapperClass: p.wrapperClass,
          };
          break;
        }
      }
      if (info) return info;
      for (let i = 0; i < ALL_PROVIDER_LIST.length; i++) {
        let p = ALL_PROVIDER_LIST[i];
        if (p.slug == PROVIDER) {
          info = {
            img: p.image,
            name: p.label,
            wrapperClass: p.wrapperClass,
          };
          break;
        }
      }
      if (info) {
        return info;
      } else {
        return {
          name: "",
          img: "",
          wrapperClass: "",
        };
      }
    },
    OpenListProvider() {
      this.isHideProvider = true;
    },
    ClosePopupProvider(provider, cat) {
      if (provider) {
        if (this.currentProvider) {
          this.currentProvider = null;
          this.providerInfo = null;
          let self = this;
          setTimeout(() => {
            self.providerInfo = provider;
            self.currentProvider = provider.slug;
            self.catPrev = this.cat;
            self.cat = null;
            self.providerInfo.cat = cat ? cat : null;
            self.tagPrev = this.tag;
            self.tag = null;
            self.keywordPrev = this.keyword;
            self.keyword = "";
            self.OpenListAllGame(provider.slug, null, cat);
            self.isHideProvider = false;
          }, 50);
        } else {
          this.providerInfo = provider;
          this.currentProvider = provider.slug;
          this.catPrev = this.cat;
          this.cat = null;
          this.providerInfo.cat = cat ? cat : null;
          this.tagPrev = this.tag;
          this.tag = null;
          this.keywordPrev = this.keyword;
          this.keyword = "";
          this.OpenListAllGame(provider.slug, null, cat);
          this.isHideProvider = false;
        }
      } else {
        this.isHideProvider = false;
      }
    },

    OpenListAllGame(service, list,cat) {
      this.showList = list;
      this.currentProvider = service.toLowerCase();
      this.providerInfo = this.getProviderInfo(service);
      if(cat){
        this.providerInfo.cat = cat;
      }
      this.isHideListGame = false;
      this.isHideListGameContent = true;
    },

    CloseListAllGame() {
      this.isHideListGame = true;
      this.isHideListGameContent = false;
      this.providerInfo = null;
      this.currentProvider = null;
      this.tag = this.tagPrev;
      this.cat = this.catPrev;
      this.keyword = this.keywordPrev;
    },
  },
};
</script>
<style>
.wrapContentIndexFishing .ListRecommend-sub {
  display: none;
}
.wrapContentIndexFishing .pop-provider {
  padding-top: 10px;
}
.wrapContentIndexFishing .active .itemList li.item.itemFishing {
  background-color: white;
  border: solid 1px rgba(0, 0, 0, 0.84);
  box-shadow: 0 5px 10px rgba(0, 0, 0, 0.3);
}
.wrapContentIndexFishing .active .itemList li.item.itemFishing p,
.wrapContentIndexFishing .active .itemList li.item.itemFishing span {
  color: black;
}


/*Theme*/
.themeBlackGold .wrapContentIndexFishing .active .itemList li.item.itemFishing {
  background-image: linear-gradient(to bottom, #fec225 0%, #e19d0e 100%) !important;
  background-color: transparent;
  border: solid 1px rgba(254, 194, 37, 0.84);
  box-shadow: 0 5px 10px rgba(254, 194, 37, 0.3);
}
.themeDarkGray .wrapContentIndexFishing .active .itemList li.item.itemFishing {
  background-color: #5f646a!important;
  border: solid 1px rgba(95, 100, 106, 0.84);
  box-shadow: 0 5px 10px rgba(59, 59, 59, 0.3);
}
.themeDarkGray .wrapContentIndexFishing .active .itemList li.item.itemFishing p,
.themeDarkGray .wrapContentIndexFishing .active .itemList li.item.itemFishing span {
  color: #fff!important;
}
.themeLightGray .wrapContentIndexFishing .active .itemList li.item.itemFishing {
  background-color: #edeef5 !important;
  border: solid 1px rgb(232, 232, 232) !important;
  box-shadow: none;
}
.themeLightGray .wrapContentIndexFishing .active .itemList li.item.itemFishing p,
.themeLightGray .wrapContentIndexFishing .active .itemList li.item.itemFishing span {
  color: #222!important;
}
</style>
