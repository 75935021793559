<template>
    <div v-on:click="toBet" v-if="price" class="OddPrice flex item-center qb-price" :class="{'red': is_red, 'onBet': onBet}">
        <span class="tag" :class="{'blink_blink': isChange}">
            <span v-if="my_odd && onBet && !accept_any_odd && my_odd !== price" style="margin-right: 4px;" class="v-price line-through">{{my_odd}}</span>
            <span class="v-price" :class="{'underline': is_favorite}" style="margin-right: 4px;">{{price | priceByCurrency | toFixed(2)}}</span>
            <span v-if="priceUp" class="price-up"></span>
            <span v-if="priceDown" class="price-down"></span>
        </span>
    </div>
</template>

<script>

    import _findIndex from 'lodash/findIndex'
    import store from '@/store'
    import {mixin as VueTimers} from 'vue-timers'

    export default {
        name: 'QuickBetOddPrice',
        mixins: [VueTimers],
        props: [
            'i',
            'is_live',
            'odd_info',
            'odd_id',
            'odd_name',
            'price',
            'odds_x3',
            'mode',
            'mode_sub',
            'mode_value',
            'choose',
            'my_odd',
            'query_team',
            'option',
        ],
        data: function() {
            return {
                isChange: false,
                priceUp: false,
                priceDown: false,
            };
        },
        timers: {
            clearBlinkBlink: {
                time: 8000
            }
        },
        watch: {
            price: function(newVal, oldVal) { // watch it
                this.$timer.stop('clearBlinkBlink');
                // console.log('price changed: ', newVal, ' | was: ', oldVal);
                this.isChange = true;
                if(newVal > oldVal){
                    this.priceUp = true;
                }else{
                    this.priceDown = true;
                }
                this.$timer.start('clearBlinkBlink')
            }
        },
        computed: {
            odd_style: function(){
                return store.state.football.odd_style;
            },
            onBet: function(){

                if(this.bet_list.length > 0){

                    let _this =  this;

                    let i = _findIndex(this.bet_list, function(o) {
                        return o.odd_id === _this.odd_id && o.odd_name === _this.odd_name;
                    });

                    if(i > -1){
                        return true;
                    }else{
                        return false;
                    }
                }else{
                    return false;
                }
            },
            bet_list: function () {
                return store.state.football.bet_list;
            },
            is_red: function(){
                return (this.$options.filters.priceByCurrency(this.price) < 0) ? true : false
            },
            accept_any_odd: function(){
                return store.state.football.accept_any_odd;
            },
            is_favorite: function(){
                if(this.mode === 'HDP'){
                    const favorite = this.calFavorite();
                    return favorite === this.choose;
                }else{
                    return false;
                }
            }
        },
        methods: {
            clearBlinkBlink: function(){
                this.isChange = false;
                this.priceUp = false;
                this.priceDown = false;
            },
            toBet: async function(){

                let toBet = {
                    is_live: this.is_live,
                    league_id: this.odd_info.league_id,
                    sbo_league_id: '',
                    league_name_en: this.odd_info.league_name,
                    match_sbo_id: '',
                    team_home_id: '',
                    team_away_id: '',
                    match_home_name: this.odd_info.match_home_name,
                    match_away_name: this.odd_info.match_away_name,
                    match_id: this.odd_info.match_id,
                    kickoff_time: this.odd_info.kickoff_time,
                    odd_id: this.odd_id,
                    odd_name: this.odd_name,
                    mode: this.mode,
                    mode_sub: this.mode_sub,
                    choose: this.choose,
                    odds: this.price,
                    odds_x3: this.odds_x3,
                    mode_value: this.mode_value,
                    favorite: this.calFavorite(),
                    odds_type: this.odd_style,
                    score: this.calScore(),
                    mode_other:'',
                    quick: true
                };

                if(!this.onBet){
                    await store.dispatch('football/toBet', toBet);
                }else{
                    await store.dispatch('football/cancelBet', toBet);
                }
            },
            calModeValue: function(){

                if(this.mode === 'HDP'){

                    if(this.mode_value){

                        const favorite = this.calFavorite();

                        if(favorite === this.choose){
                            return Math.abs(this.mode_value)*-1;
                        }else{
                            return Math.abs(this.mode_value);
                        }
                    }else{
                        return 0;
                    }
                }else{
                    return this.mode_value;
                }
            },
            calFavorite: function(){

                if(this.mode === 'HDP'){

                    if(this.mode_value){

                        if(this.mode_value > 0){
                            return 'H'
                        }else if(this.mode_value < 0){
                            return 'A'
                        }else{
                            return ''
                        }
                    }else{
                        return '';
                    }
                }else{
                    return '';
                }
            },
            calScore: function(){
                if(this.odd_info.info_home_score && this.odd_info.info_away_score){
                    return this.odd_info.info_home_score+':'+this.odd_info.info_away_score
                }else if(this.odd_info.info_home_score){
                    return this.odd_info.info_home_score+':0'
                }else if(this.odd_info.info_away_score){
                    return '0:'+this.odd_info.info_away_score
                }else{
                    if(this.is_live){
                        return '0:0'
                    }else{
                        return '-:-'
                    }
                }
            }
        },
        mounted() {
            if(this.query_team === 'home'){
                if(this.i === 0) {
                    if(this.choose === 'H' || this.choose === 'Over'){
                        this.toBet();
                    }
                }
            }else if(this.query_team === 'away'){
                if(this.i === 0) {
                    if(this.choose === 'A' || this.choose === 'Under'){
                        this.toBet();
                    }
                }
            }else if(this.option) {
                if(this.i === this.option.i && this.odd_name === this.option.oddName) {
                    this.toBet()
                }
            }
        }
    }
</script>

<style scoped>
.line-through {
    text-decoration: line-through;
}
</style>