<template>

    <div class="MessageIncomingChat incoming_msg">
        <div class="incoming_msg-info">
            <div class="incoming-flex">
                <div class="incoming_msg_img msg-invite-img">
                    <!-- <img src="/images/avatar.png" style="object-fit: cover;"> -->
                    <!--<img src="@/assets/images/icon/hilo.png" alt="sunil" />-->
                    <el-image :src="user_picture_url">
                        <img slot="error" src="/images/avatar.png" />
                    </el-image>
                </div>
                <div class="incoming-content">
                    <div class="incoming-content-title">
                        <UserLevel :level="user_level"></UserLevel>
                        <div class="received_msg">
                            <h3>{{message.data.display_name}}</h3>
                        </div>
                        <div class="box-msg box-msg-chat box-msg-inlive">
                            <strong>เข้าร่วม</strong>
                        </div>
                    </div>


                    <div class="received_msg hide">
                        <!--<h3>xxxxx</h3>-->
                        <div class="received_withd_msg">

                            <div class="box-top-user-flex">
                                <div class="box-top-user-list box-video-live">
                                    <div class="box-msg-inlive"><span>11</span>  </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="incoming_msg-info hide">
                        <div class="incoming_msg_img msg-invite-img">
                            <img src="/images/avatar.png" style="object-fit: cover;">
                            <!--<img src="@/assets/images/icon/hilo.png" alt="sunil" />-->
                        </div>
                        <div class="box-level-incoming_msg bx-lv-7"><span><i class="far fa-gem"></i> 7</span></div>

                        <div class="received_msg">
                            <h3>{{message.data.display_name}} </h3>
                        </div>
                        <div class="box-msg box-msg-chat box-msg-inlive">
                            <strong>เข้าร่วม</strong>
                        </div>
                        <div class="received_withd_msg hide">


                            <div class="box-top-user-flex">
                                <div class="box-top-user-list box-video-live">
                                    <div class="box-msg-inlive received_msg">
                                        <!--                                <h3></h3>-->
                                        <strong>เข้าร่วม</strong>

                                    </div>
                                </div>
                            </div>
                            <!-- <div class="box-top-user-flex">
                                <div class="box-top-user-list box-video-live">
                                  <div class="box-msg-inlive"><span>24</span> <i class="fa fa-heart text-red"></i> Devil45 <i class="fa fa-heart text-red"></i>  <strong>เข้าร่วมไลฟ์</strong></div>
                                </div>
                            </div>
                            <div class="box-top-user-flex">
                                <div class="box-top-user-list box-video-live">
                                    <div class="box-msg-inlive"><span>30</span> Monega_24 <strong>เข้าร่วมไลฟ์</strong></div>
                                </div>
                            </div>
                            <div class="box-top-user-flex">
                                <div class="box-top-user-list box-video-live">
                                    <div class="box-msg-inlive"><span>65</span> Oliver_PP <strong>Follow</strong> Monega_24</div>
                                </div>
                            </div> -->

                            <span class="time_date"> {{message.timestamp | chatMessageDateTimeFormat}}</span>
                        </div>
                    </div>
                </div>

            </div>

        </div>

    </div>
</template>
<script>

import store from '@/store'
import UserLevel from '@/components/user/Level'

export default {
    name: 'IncomingChat',
    props: ['message'],
    components: {
        UserLevel,
    },
    computed: {
        user_info_arr(){
            return store.state.user_info_arr
        },
        user_info(){
            return this.user_info_arr.find((user_info) => {
                return parseInt(user_info.id) === parseInt(this.message.user_id)
            })
        },
        user_display_name(){
            return (this.user_info) ? this.user_info.display_name : ''
        },
        user_level(){
            return (this.user_info) ? this.user_info.level : 0
        },
        user_picture_url(){
            return (this.user_info) ? this.user_info.picture_url : ''
        },
    },
}
</script>