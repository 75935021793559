<template>
    <div class="AdminLogin">
        <div>
            <h1>Chat Admin Login</h1>
            <el-input placeholder="username" v-model="username"></el-input>
            <div class="v-line-seperator"></div>
            <el-input @keyup.enter="login" placeholder="password" v-model="password" show-password></el-input>
            <div class="v-line-seperator"></div>
            <el-button type="primary" @click="login" style="width: 100%;">ล็อกอิน</el-button>
        </div>
    </div>
</template>
<script>

import axios from 'axios'
import store from '@/store'

export default {
    name: 'AdminLogin',
    data(){
        return {
            username: '',
            password: '',
        }
    },
    computed: {
        redirectURL() {
            return this.$route.query.redirect_url
        },
    },
    methods: {
        async login(){
            const res = await axios({
                method: 'POST',
                url: `${this.envDomains.VUE_APP_API}/api/admin/login`,
                data: {
                    username: this.username,
                    password: this.password
                }
            })
            if(!res.data.success){
                this.$alert(res.data.message, 'แจ้งเตือน', {
                    confirmButtonText: 'ตกลง',
                    customClass: 'custom-message-box'
                })
                return
            }
            store.commit('updateAdmin', res.data.data)
            if(this.redirectURL) {
                this.$router.replace(decodeURIComponent(this.redirectURL))
            } else if (window.history.length > 2) {
                this.$router.back()
            } else {
                this.$router.replace('/admin')
            }
        }
    }
}
</script>
<style scoped>
.AdminLogin {
    height: 100vh;
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
}
</style>