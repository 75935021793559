<template>
    <div class="SuperAdminAdmin">
        <div class="v-content-inner">
            <div class="v-toolbar">
                <div class="toolbar-item">
                    <el-select v-model="column_filter" placeholder="Select">
                        <el-option
                        v-for="item in [{value: 'username', label: 'username'}, {value: 'room_id', label: 'ห้อง'}]"
                        :key="item.value"
                        :label="item.label"
                        :value="item.value">
                        </el-option>
                    </el-select>
                    <el-input
                        placeholder="ค้นหา"
                        v-model="keyword"
                        clearable>
                    </el-input>
                    <el-button @click="loadDataArr" type="primary" plain>รีเฟรช</el-button>
                </div>
                <div class="toolbar-item">
                    <el-button @click="toCreate" icon="el-icon-plus" circle type="primary"></el-button>
                </div>
            </div>
            <div class="v-list">
                <el-table :data="data_arr_paging" stripe>
                    <el-table-column type="index"></el-table-column>
                    <el-table-column
                    prop="username"
                    label="username">
                    </el-table-column>
                    <el-table-column
                    prop="room_id"
                    label="ห้อง">
                    </el-table-column>
                     <el-table-column
                    prop="channel"
                    label="channel">
                    </el-table-column>
                     <el-table-column
                    prop="line_oa"
                    label="LINE OA">
                    </el-table-column>
                    <el-table-column label="เครื่องมือ">
                        <template slot-scope="scope">
                            <div class="v-table-tools">
                                <el-button @click="toUpdate(scope.row)" size="mini" type="primary" plain>แก้ไข</el-button>
                                <!-- <el-button @click="toResource(scope.row)" size="mini" type="success" plain>ข้อมูล</el-button> -->
                                <el-button @click="toRemove(scope.row)" size="mini" type="danger" plain>ลบ</el-button>
                            </div>
                        </template>
                    </el-table-column>
                </el-table>
            </div>
            <div class="v-toolbar-bottom">
                <el-pagination
                    :page-size="page_size"
                    :current-page="page"
                    layout="prev, pager, next"
                    @current-change="(current) => {page = current}"
                    :total="data_arr_filter.length">
                </el-pagination>
            </div>
        </div>
        <el-dialog
            :title="form_dialog_title"
            :visible.sync="is_form_dialog_visible">
            <span>
                username
                <el-input placeholder="461dlprb" v-model="form.username"></el-input>
                <div class="v-line-seperator"></div>
                <span v-if="form_mode === 'create'">password</span>
                <el-input v-if="form_mode === 'create'" placeholder="passwod" v-model="form.password"></el-input>
                <div v-if="form_mode === 'create'" class="v-line-seperator"></div>
                ห้อง
                <el-input placeholder="@461dlprb" v-model="form.room_id"></el-input>
                <div class="v-line-seperator"></div>
                channel
                <el-input placeholder="1654414133" v-model="form.channel"></el-input>
                <div class="v-line-seperator"></div>
                LINE OA
                <el-input placeholder="@461dlprb" v-model="form.line_oa"></el-input>
                <div class="v-line-seperator"></div>
            </span>
            <span slot="footer" class="dialog-footer">
                <el-button @click="closeFormDialog">ยกเลิก</el-button>
                <el-button type="primary" @click="submitForm">ตกลง</el-button>
            </span>
        </el-dialog>
        <el-dialog
            title="จัดการข้อมูลแอดมิน"
            :visible.sync="isResourceDialogVisible"
            :fullscreen="true">
            <div style="display: flex; justify-content: flex-end;">
                <el-button @click="toCreateResource" icon="el-icon-plus" circle type="primary"></el-button>
            </div>
            <div>
                <el-table :data="resources" stripe>
                    <el-table-column type="index"></el-table-column>
                    <el-table-column
                    prop="title"
                    label="หัวข้อ">
                    </el-table-column>
                    <el-table-column
                    prop="value"
                    label="ค่า">
                    </el-table-column>
                    <el-table-column
                    prop="description"
                    label="คำอธิบาย">
                    </el-table-column>
                    <el-table-column label="เครื่องมือ">
                        <template slot-scope="scope">
                            <div class="v-table-tools">
                                <el-button @click="toUpdateResource(scope.row)" size="mini" type="primary" plain>แก้ไข</el-button>
                                <el-button @click="toRemoveResource(scope.row)" size="mini" type="danger" plain>ลบ</el-button>
                            </div>
                        </template>
                    </el-table-column>
                </el-table>
            </div>
            <span slot="footer" class="dialog-footer">
                <el-button @click="() => {isResourceDialogVisible = false}">ปิด</el-button>
            </span>
        </el-dialog>
        <el-dialog
            :title="resourceEditDialogTitle"
            :visible.sync="isResourceEditDialogVisible">
            <div>
                หัวข้อ<el-input v-model="resourceForm.title"></el-input>
                <div style="height: 4px;"></div>
                ค่า<el-input v-model="resourceForm.value"></el-input>
                <div style="height: 4px;"></div>
                คำอธิบาย(ไม่จำเป็นต้องกรอก)<el-input v-model="resourceForm.description"></el-input>
            </div>
            <span slot="footer" class="dialog-footer">
                <el-button @click="() => {isResourceEditDialogVisible = false}">ปิด</el-button>
                <el-button type="primary" @click="submitResourceForm">ตกลง</el-button>
            </span>
        </el-dialog>
    </div>
</template>
<script>

import axios from 'axios'
import axiosWrapper from '@/axiosWrapper'
const PAGE_SIZE = 10
import store from '@/store'

export default {
    name: 'SuperAdminAdmin',
    data(){
        return {
            keyword: '',
            column_filter: 'username',
            data_arr: [],
            form: {
                username: '',
                password: '',
                room_id: '',
                channel: '',
                line_oa: '',
            },
            is_form_dialog_visible: false,
            form_dialog_title: '',
            form_mode: '',
            page: 1,
            page_size: PAGE_SIZE,
            resources: [],
            currentAdmin: null,
            isResourceDialogVisible: false,
            isResourceEditDialogVisible: false,
            resourceEditDialogTitle: '',
            resourceDialogMode: '',
            resourceForm: {
                title: '',
                value: '',
            },
        }
    },
    computed: {
        super_admin(){
            return store.state.super_admin
        },
        token(){
            return this.super_admin.token
        },
        data_arr_filter(){
            return this.data_arr.filter((user) => {
                return user[this.column_filter].includes(this.keyword)
            })
        },
        data_arr_paging(){
            return this.data_arr_filter.slice((this.page - 1) * PAGE_SIZE, (this.page - 1) * PAGE_SIZE + PAGE_SIZE)
        }
    },
    watch: {
        data_arr_filter(){
            this.page = 1
        }
    },
    methods: {
        clearForm(){
            this.form = {
                username: '',
                password: '',
                room_id: '',
                channel: '',
                line_oa: '',
            }
        },
        toCreate(){
            this.form_dialog_title = 'สร้าง'
            this.form_mode = 'create'
            this.clearForm()
            this.openFormDialog()
        },
        toUpdate(data){
            this.form_dialog_title = 'แก้ไข'
            this.form_mode = 'update'
            this.form = Object.assign({}, data)
            this.openFormDialog()
        },
        toRemove(data){
            this.$alert(`ต้องการลบ ${data.username} ?`, 'แจ้งเตือน', {
                type: 'error',
                showCancelButton: true,
                confirmButtonText: 'ตกลง',
                cancelButtonText: 'ยกเลิก',
                customClass: 'custom-message-box',
                callback: action => {
                    if(action === 'confirm'){
                        this.remove(data.id)
                    }
                }
            })
        },
        openFormDialog(){
            this.is_form_dialog_visible = true
        },
        closeFormDialog(){
            this.form_dialog_title = ''
            this.is_form_dialog_visible = false
        },
        async submitForm(){

            const loading = this.$loading()

            if(this.form_mode === 'create'){
                const res = await axios({
                    method: 'POST',
                    url: `${this.envDomains.VUE_APP_API}/api/super-admin/admin/create`,
                    data: {...this.form, token: this.token}
                })
                if(!res.data.success){
                    this.$alert(res.data.message, 'แจ้งเตือน', {
                        confirmButtonText: 'ตกลง',
                        customClass: 'custom-message-box'
                    })
                    loading.close()
                    return
                }
            }else if(this.form_mode === 'update'){
                const res = await axios({
                    method: 'POST',
                    url: `${this.envDomains.VUE_APP_API}/api/super-admin/admin/update`,
                    data: {...this.form, token: this.token}
                })
                if(!res.data.success){
                    this.$alert(res.data.message, 'แจ้งเตือน', {
                        confirmButtonText: 'ตกลง',
                        customClass: 'custom-message-box'
                    })
                    loading.close()
                    return
                }
            }
            loading.close()
            this.closeFormDialog()
            this.loadDataArr()
        },
        async remove(id){

            const loading = this.$loading()

            const res = await axios({
                method: 'POST',
                url: `${this.envDomains.VUE_APP_API}/api/super-admin/admin/remove`,
                data: {id, token: this.token}
            })
            if(!res.data.success){
                this.$alert(res.data.message, 'แจ้งเตือน', {
                    confirmButtonText: 'ตกลง',
                    customClass: 'custom-message-box'
                })
                loading.close()
                return
            }
            loading.close()
            this.loadDataArr()
        },
        async loadDataArr(){

            const loading = this.$loading()

            const res = await axios({
                method: 'POST',
                url: `${this.envDomains.VUE_APP_API}/api/super-admin/admin/get-all`,
                data: {token: this.token}
            })
            if(!res.data.success){
                this.$alert(res.data.message, 'แจ้งเตือน', {
                    confirmButtonText: 'ตกลง',
                    customClass: 'custom-message-box'
                })
                loading.close()
                return
            }
            this.data_arr = res.data.data

            loading.close()
        },
        _clearResourceForm() {
            this.resourceForm = {
                title: '',
                value: '',
                description: '',
                admin_id: this.currentAdmin.id,
            }
        },
        toResource(admin) {
            this.currentAdmin = admin
            this._loadResources()
            this.isResourceDialogVisible = true
        },
        toCreateResource() {
            this.resourceEditDialogTitle = 'สร้าง'
            this.resourceDialogMode = 'create'
            this._clearResourceForm()
            this.isResourceEditDialogVisible = true
        },
        toUpdateResource(data) {
            this.resourceEditDialogTitle = 'แก้ไข'
            this.resourceDialogMode = 'update'
            this.resourceForm = Object.assign({admin_id: this.currentAdmin.id}, data)
            this.isResourceEditDialogVisible = true
        },
        toRemoveResource(data){
            this.$alert(`ต้องการลบ ${data.title} ?`, 'แจ้งเตือน', {
                type: 'error',
                showCancelButton: true,
                confirmButtonText: 'ตกลง',
                cancelButtonText: 'ยกเลิก',
                customClass: 'custom-message-box',
                callback: action => {
                    if(action === 'confirm'){
                        this._removeResource(data.id)
                    }
                }
            })
        },
        async _loadResources() {

            const loading = this.$loading()

            const res = await axiosWrapper({
                method: 'POST',
                url: `${this.envDomains.VUE_APP_API}/api/admin-resource/get-by-admin-id`,
                data: {
                    token: this.token,
                    admin_id: this.currentAdmin.id,
                }
            })
            if(res.status !== 200){
                this.$alert(res.data.message, 'แจ้งเตือน', {
                    confirmButtonText: 'ตกลง',
                    customClass: 'custom-message-box'
                })
                loading.close()
                return
            }
            this.resources = res.data

            loading.close()
        },
        async _removeResource(id){

            const loading = this.$loading()

            const res = await axiosWrapper({
                method: 'POST',
                url: `${this.envDomains.VUE_APP_API}/api/admin-resource/remove`,
                data: {id, token: this.token}
            })
            if(res.status !== 200){
                this.$alert(res.data.message, 'แจ้งเตือน', {
                    confirmButtonText: 'ตกลง',
                    customClass: 'custom-message-box'
                })
                loading.close()
                return
            }
            loading.close()
            this._loadResources()
        },
        async submitResourceForm(){

            const loading = this.$loading()

            if(this.resourceDialogMode === 'create'){
                const res = await axiosWrapper({
                    method: 'POST',
                    url: `${this.envDomains.VUE_APP_API}/api/admin-resource/create`,
                    data: {...this.resourceForm, token: this.token}
                })
                if(res.status !== 200){
                    this.$alert(res.data.message, 'แจ้งเตือน', {
                        confirmButtonText: 'ตกลง',
                        customClass: 'custom-message-box'
                    })
                    loading.close()
                    return
                }
            }else if(this.resourceDialogMode === 'update'){
                const res = await axiosWrapper({
                    method: 'POST',
                    url: `${this.envDomains.VUE_APP_API}/api/admin-resource/update`,
                    data: {...this.resourceForm, token: this.token}
                })
                if(res.status !== 200){
                    this.$alert(res.data.message, 'แจ้งเตือน', {
                        confirmButtonText: 'ตกลง',
                        customClass: 'custom-message-box'
                    })
                    loading.close()
                    return
                }
            }
            loading.close()
            this.isResourceEditDialogVisible = false
            this._loadResources()
        },
    },
    mounted(){
        this.loadDataArr()
    }
}
</script>
<style scoped>
.toolbar-item * {
    margin: 0 2px;
}
</style>