<template>
    <div class="AdminStreamer">
        <div class="v-content-inner">
            <div class="v-toolbar">
                <div class="toolbar-item" style="align-items: center;">
                    <!-- <el-input
                        placeholder="ค้นหา"
                        v-model="keyword"
                        clearable>
                    </el-input>
                    <el-button type="primary">ค้นหา</el-button> -->
                    <el-button @click="_load" type="primary" plain>รีเฟรช</el-button>
                    <span>ทั้งหมด {{streamerTotal}} ยูสเซอร์</span>
                </div>
                <div class="toolbar-item">
                    <el-button @click="toCreate" type="primary">เพิ่ม</el-button>
                </div>
            </div>
            <div class="v-list">
                <el-table :data="streamers" stripe>
                    <el-table-column type="index"></el-table-column>
                    <el-table-column label="ผู้ใช้" width="300">
                        <template slot-scope="scope">
                            <div class="user-info-wrapper">
                                <div>
                                    <el-avatar size="medium" :src="getUserImagePath(scope.row.user.picture_url)">
                                        <img src="/images/avatar.png"/>
                                    </el-avatar>
                                </div>
                                <div>{{ scope.row.user.display_name}}</div>
                                <div>
                                    <el-tag :type="isOnlineTagType(scope.row.is_online)">
                                        {{ isOnlineDisplay(scope.row.is_online)}}
                                    </el-tag>
                                </div>
                            </div>
                        </template>
                    </el-table-column>
                    <el-table-column label="stream id" prop="id" width="100"></el-table-column>
                    <el-table-column label="username" prop="user.pes_username"></el-table-column>
                    <el-table-column label="password" prop="user.pes_password"></el-table-column>
                    <el-table-column label="ช่องทาง">
                        <template slot-scope="scope">
                            {{streamTypeDisplay(scope.row.stream_type)}}
                        </template>
                    </el-table-column>
                    <el-table-column label="หมวดหมู่">
                        <template slot-scope="scope">
                            {{streamOnDisplay(scope.row.stream_on)}}
                        </template>
                    </el-table-column>
                    <el-table-column label="เล่นอัตโนมัติ" width="100">
                        <template slot-scope="scope">
                            {{isAutoplayDisplay(scope.row.is_autoplay)}}
                        </template>
                    </el-table-column>
                    <el-table-column label="เริ่ม" width="170">
                        <template slot-scope="scope">
                            {{dateTimeDisplay(scope.row.start_at)}}
                        </template>
                    </el-table-column>
                    <el-table-column label="จบ" width="170">
                        <template slot-scope="scope">
                            {{dateTimeDisplay(scope.row.end_at)}}
                        </template>
                    </el-table-column>
                    <el-table-column label="แจกเครดิต" prop="give_credit" width="100"></el-table-column>
                    <el-table-column label="เครื่องมือ" width="300">
                        <template slot-scope="scope">
                            <div class="v-table-tools">
                                <!-- <el-button size="mini" plain>รายละเอียด</el-button> -->
                                <el-button @click="seeInfo(scope.row.stream_server_api_stream_path)" type="success" size="mini" plain>ดูข้อมูลการสตรีม</el-button>
                                <el-button @click="toEdit(scope.row)" type="primary" size="mini" plain>แก้ไข</el-button>
                                <el-button @click="toRemove(scope.row)" type="danger" size="mini" plain>ลบ</el-button>
                            </div>
                        </template>
                    </el-table-column>
                </el-table>
            </div>
            <div class="v-toolbar-bottom">
                <el-pagination
                    @current-change="(current) => {page = current}"
                    :page-size="limit"
                    layout="prev, pager, next"
                    :total="streamerTotal">
                </el-pagination>
            </div>
        </div>

        <el-dialog
        :title="handleDialogTitle"
        :visible.sync="isHandleDialogVisible">
            <div>
                ชื่อแสดงผล (display name)
                <el-input v-model="form.display_name"></el-input>
                <div class="v-line-seperator"></div>
                รูปโปรไฟล์ (profile image)
                <div class="v-line-seperator"></div>
                <el-avatar :size="60" :src="getUserImagePath(form.picture_url)">
                    <img src="/images/avatar.png"/>
                </el-avatar>
                <Uploader @done="(url) => {form.picture_url = url}" :data="{token}"/>
                <div class="v-line-seperator"></div>
                รูปภาพปก (cover image)
                <div class="v-line-seperator"></div>
                <el-image
                style="width: 100px!important; height: 100px!important;"
                :src="getUserImagePath(form.cover_image)"
                fit="cover">
                    <div slot="error" class="image-slot" style="display: flex; justify-content: center; align-items: center; height: 100%; background-color: lightgray;">
                        <i class="el-icon-picture-outline"></i>
                    </div>
                </el-image>
                <div class="v-line-seperator"></div>
                <Uploader @done="(url) => {form.cover_image = url}" :data="{token}"/>
                <div class="v-line-seperator"></div>
                ช่องทาง
                <el-select v-model="form.stream_type" placeholder="เลือก">
                    <el-option
                    v-for="item in stream_types"
                    :key="item.value"
                    :label="item.label"
                    :value="item.value">
                    </el-option>
                </el-select>
                <div class="v-line-seperator"></div>
                หมวดหมู่
                <el-select v-model="form.stream_on" placeholder="เลือก">
                    <el-option
                    v-for="item in stream_ons"
                    :key="item.value"
                    :label="item.label"
                    :value="item.value">
                    </el-option>
                </el-select>
                <div class="v-line-seperator"></div>
                ห้อง
                <el-select v-model="form.room_id" placeholder="เลือก">
                    <el-option
                    v-for="item in rooms"
                    :key="item.slug"
                    :label="item.slug"
                    :value="item.slug">
                    </el-option>
                </el-select>
                <div class="v-line-seperator"></div>
                <div>
                    สถานะ (กรณีช่องทาง Youtube)
                    <br>
                    <div class="v-line-seperator"></div>
                    <el-radio v-model="form.is_online" :label="true">ออนไลน์</el-radio>
                    <el-radio v-model="form.is_online" :label="false">ออฟไลน์</el-radio>
                    <div class="v-line-seperator"></div>
                </div>
                <div>
                    URL การสตรีม (กรณีช่องทาง Youtube)
                    <el-input :placeholder="stream_url_placeholder" v-model="form.stream_url"></el-input>
                    <div class="v-line-seperator"></div>
                </div>
                <div>
                    แนวตั้ง/แนวนอน
                    <br>
                    <div class="v-line-seperator"></div>
                    <el-radio v-model="form.is_video_horizontal" :label="false">แนวตั้ง</el-radio>
                    <el-radio v-model="form.is_video_horizontal" :label="true">แนวนอน</el-radio>
                    <div class="v-line-seperator"></div>
                </div>
                <div>
                    เล่นอัตโนมัติ
                    <br>
                    <div class="v-line-seperator"></div>
                    <el-radio v-model="form.is_autoplay" :label="false">ไม่</el-radio>
                    <el-radio v-model="form.is_autoplay" :label="true">เล่น</el-radio>
                    <div class="v-line-seperator"></div>
                </div>
                <!-- <div>
                    เร่ิม
                    <br>
                    <div class="v-line-seperator"></div>
                    <el-time-picker placeholder="เลือกเวลาเริ่ม" value-format="HH:mm:ss" v-model="form.start"></el-time-picker>
                    <div class="v-line-seperator"></div>
                </div>
                <div>
                    จบ
                    <br>
                    <div class="v-line-seperator"></div>
                    <el-time-picker placeholder="เลือกเวลาจบ" value-format="HH:mm:ss" v-model="form.end"></el-time-picker>
                    <div class="v-line-seperator"></div>
                </div> -->
                <div>
                    เร่ิม
                    <br>
                    <div class="v-line-seperator"></div>
                    <el-date-picker
                    v-model="form.start_at"
                    type="datetime"
                    placeholder="เลือกเวลาเริ่ม">
                    </el-date-picker>
                    <div class="v-line-seperator"></div>
                    <!-- start_at_date: {{start_at_date}}, start_at_iso: {{start_at_iso}} -->
                </div>
                <div>
                    จบ
                    <br>
                    <div class="v-line-seperator"></div>
                    <el-date-picker
                    v-model="form.end_at"
                    type="datetime"
                    placeholder="เลือกเวลาจบ">
                    </el-date-picker>
                    <div class="v-line-seperator"></div>
                </div>
                แจกเครดิต
                <el-input-number v-model="form.give_credit" :step="10"></el-input-number>
                <div class="v-line-seperator"></div>
            </div>
            <span slot="footer" class="dialog-footer">
                <el-button type="primary" @click="submit">ตกลง</el-button>
                <el-button @click="closeHandleDialog">ปิด</el-button>
            </span>
        </el-dialog>

        <el-dialog
        title="ข้อมูลการสตรีม"
        :visible.sync="isStreamInfoDialogVisible">
            <div v-if="currentStreamerInfo">
                <div>
                    <span>name: </span>
                    <strong>{{currentStreamerInfo.name}}</strong>
                </div>
                <div>
                    <span>streamKey: </span>
                    <strong>{{currentStreamerInfo.streamKey}}</strong>
                    <el-button @click="copyToClipboard(currentStreamerInfo.streamKey)" icon="el-icon-copy-document" type="text" style="margin-left: 10px;"></el-button>
                </div>
            </div>
        </el-dialog>
    </div>
</template>
<script>

import store from '@/store'
import Uploader from '@/components/admin-tool/Uploader'
import axiosWrapper from '@/axiosWrapper'
import {IMAGE_MAP} from '@/components/user/Constant'
import randomBetween from '@/lib/randomBetween'
import copy from 'copy-to-clipboard'
const STREAM_TYPE_LABEL = {
    web: 'เว็บไซต์',
    youtube: 'Youtube',
}
const STREAM_ON_LABEL = {
    game: 'เกม',
    general: 'ทั่วไป',
}

export default {
    name: 'AdminStreamer',
    components: {
        Uploader,
    },
    data() {
        return {
            keyword: '',
            isHandleDialogVisible: false,
            page: 1,
            limit: 20,
            streamers: [],
            streamerTotal: 0,
            stream_types: [
                {
                    label: 'เว็บไซต์',
                    value: 'web',
                },
                {
                    label: 'Youtube',
                    value: 'youtube',
                },
            ],
            stream_ons: [
                {
                    label: 'เกม',
                    value: 'game',
                },
                {
                    label: 'ทั่วไป',
                    value: 'general',
                },
            ],
            form: {
                display_name: '',
                picture_url: '',
                cover_image: '',
                stream_type: 'web',
                is_online: true,
                stream_url: '',
                stream_on: 'game',
                room_id: null,
                is_video_horizontal: false,
                is_autoplay: false,
                start: null,
                end: null,
                give_credit: 0,
                start_at: null,
                end_at: null,
            },
            formMode: 'create', //create, edit
            currentStreamerInfo: null,
            isStreamInfoDialogVisible: false,
            rooms: [],
        }
    },
    computed: {
        admin(){
            return store.state.admin
        },
        token(){
            return this.admin.token
        },
        handleDialogTitle() {
            if(this.formMode === 'create') {
                return 'เพิ่มสตรีมเมอร์'
            } else if(this.formMode === 'edit') {
                return 'แก้ไขสตรีมเมอร์'
            } else {
                return ''
            }
        },
        stream_url_placeholder() {
            return `https://www.youtube.com/watch?v=jiPr-t4yRos`
        },
        start_at_date() {
            return this.$moment(this.form.start_at).toDate()
        },
        start_at_iso() {
            return this.$moment(this.form.start_at).toISOString()
        },
    },
    watch: {
        page(newVal) {
            if(newVal) {
                this._load()
            }
        },
    },
    methods: {
        isOnlineTagType(is_online) {
            if(is_online) {
                return 'success'
            } else {
                return 'info'
            }
        },
        isOnlineDisplay(is_online) {
            if(is_online) {
                return 'ออนไลน์'
            } else {
                return 'ออฟไลน์'
            }
        },
        getUserImagePath(src) {
            if(!src) {
                return ''
            }
            if(process.env.NODE_ENV === 'development') {
                return `${this.envDomains.VUE_APP_API}${src}`
            }
            return src
        },
        streamTypeDisplay(src) {
            if(!src) {
                return ''
            }
            return STREAM_TYPE_LABEL[src]
        },
        streamOnDisplay(src) {
            if(!src) {
                return ''
            }
            return STREAM_ON_LABEL[src]
        },
        isAutoplayDisplay(src) {
            return (src) ? 'เล่น' : 'ไม่'
        },
        dateTimeDisplay(raw) {
            return (raw) ? this.$moment(raw).format('YYYY-MM-DD HH:mm:ss') : ''
        },
        _clearForm() {
            this.form = {
                display_name: '',
                picture_url: IMAGE_MAP[0][randomBetween(0, IMAGE_MAP[0].length - 1)],
                cover_image: '',
                stream_type: 'web',
                is_online: true,
                stream_url: '',
                stream_on: 'game',
                room_id: null,
                is_video_horizontal: false,
                is_autoplay: false,
                start: null,
                end: null,
                give_credit: 0,
                start_at: null,
                end_at: null,
            }
        },
        openHandleDialog() {
            this.isHandleDialogVisible = true
        },
        closeHandleDialog() {
            this.isHandleDialogVisible = false
        },
        toCreate() {
            this.formMode = 'create'
            this._clearForm()
            this.openHandleDialog()
        },
        toEdit(item) {
            this.formMode = 'edit'
            this.form = {
                id: item.id,
                user_id: item.user.user_id,
                display_name: item.user.display_name,
                picture_url: item.user.picture_url,
                cover_image: item.user.cover_image,
                stream_type: item.stream_type,
                is_online: item.is_online,
                stream_url: item.stream_url,
                stream_on: item.stream_on,
                room_id: item.room_id,
                is_video_horizontal: item.is_video_horizontal,
                is_autoplay: item.is_autoplay,
                start: item.start,
                end: item.end,
                give_credit: item.give_credit,
                start_at: item.start_at,
                end_at: item.end_at,
            }
            this.openHandleDialog()
        },
        submit() {
            if(this.formMode === 'create') {
                this._createSubmit()  
            } else if(this.formMode === 'edit') {
                this._updateSubmit()
            }
        },
        async _createSubmit() {

            const loading = this.$loading()

            const res = await axiosWrapper({
                method: 'POST',
                url: `${this.envDomains.VUE_APP_LIFF_API}/api/streamer/create`,
                data: {
                    token: this.admin.token,
                    channel: this.admin.channel,
                    message_api_id: this.admin.line_oa,
                    ...this.form,
                }
            })

            loading.close()

            if(res.status !== 200) {
                this.$alert(res.data.message, 'แจ้งเตือน', {
                    type: 'error',
                    confirmButtonText: 'ตกลง',
                    customClass: 'custom-message-box'
                })
                return
            }

            this._load()
            this.closeHandleDialog()
        },
        async _updateSubmit() {

            const loading = this.$loading()

            const res = await axiosWrapper({
                method: 'POST',
                url: `${this.envDomains.VUE_APP_LIFF_API}/api/streamer/update`,
                data: {
                    token: this.admin.token,
                    ...this.form,
                }
            })

            loading.close()

            if(res.status !== 200) {
                this.$alert(res.data.message, 'แจ้งเตือน', {
                    type: 'error',
                    confirmButtonText: 'ตกลง',
                    customClass: 'custom-message-box'
                })
                return
            }

            this._load()
            this.closeHandleDialog()
        },
        toRemove(item) {
            this.$confirm(`ต้องการลบ ${item.user.display_name} ?`, 'แจ้งเตือน', {
                confirmButtonText: 'ตกลง',
                cancelButtonText: 'ยกเลิก',
                type: 'warning'
            }).then(async () =>  {
                this.remove(item)
            })
        },
        async remove(item) {

            const loading = this.$loading()

            const res = await axiosWrapper({
                method: 'POST',
                url: `${this.envDomains.VUE_APP_LIFF_API}/api/streamer/remove`,
                data: {
                    token: this.admin.token,
                    channel: this.admin.channel,
                    id: item.id,
                    user_id: item.user.user_id,
                    pes_user_id: item.user.pes_user_id,
                }
            })

            loading.close()

            if(res.status !== 200) {
                this.$alert(res.data.message, 'แจ้งเตือน', {
                    type: 'error',
                    confirmButtonText: 'ตกลง',
                    customClass: 'custom-message-box'
                })
                return
            }

            this._load()
        },
        async _load() {

            const loading = this.$loading()

            const res = await axiosWrapper({
                method: 'POST',
                url: `${this.envDomains.VUE_APP_LIFF_API}/api/streamer/gets`,
                data: {
                    token: this.admin.token,
                    channel: this.admin.channel,
                    page: this.page,
                    limit: this.limit,
                }
            })

            loading.close()

            if(res.status !== 200) {
                this.$alert(res.data.message, 'แจ้งเตือน', {
                    type: 'error',
                    confirmButtonText: 'ตกลง',
                    customClass: 'custom-message-box'
                })
                return
            }

            this.streamers = res.data.rows
            this.streamerTotal = res.data.count
        },
        async seeInfo(streamPath) {

            this.currentStreamerInfo = null
            
            const loading = this.$loading()
            const res = await axiosWrapper({
                method: 'POST',
                url: `${this.envDomains.VUE_APP_LIFF_API}/api/streamer/admin/get-stream-info`,
                data: {
                    token: this.token,
                    streamPath: streamPath,
                }
            })
            loading.close()

            if(res.status === 200) {
                this.currentStreamerInfo = res.data
                this.isStreamInfoDialogVisible = true
            } else {
                this.$message({
                    message: res.data.message,
                    type: 'error'
                })
            }
        },
        copyToClipboard(text) {
            copy(text)
            this.$message({
                message: 'คัดลอกแล้ว',
                type: 'success'
            })
        },
        async _loadRooms() {
            const res = await axiosWrapper({
                method: 'POST',
                url: `${this.envDomains.VUE_APP_API}/api/admin/room/gets`,
                data: {
                    token: this.token,
                }
            })
            if(res.status === 200) {
                this.rooms = res.data
            } else {
                this.$message({
                    message: res.data.message,
                    type: 'error'
                })
            }
        },
    },
    mounted() {
        this._load()
        this._loadRooms()
    }
}
</script>
<style scoped>
    .toolbar-item * {
        margin: 0 2px;
    }
    .user-info-wrapper {
        display: flex;
        align-items: center;
    }
    .user-info-wrapper div {
        margin: 0 2px;
    }
</style>