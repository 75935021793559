<template>
    <div class="Announcement" style="padding: 2px;">
        <h3 style="font-size: 22px; font-weight: bold;">Announcement</h3>
        <div class="flex">
            <datepicker
                    @selected="selectDatePicker"
                    :value="momentToDate"
                    format="yyyy-MM-dd">

            </datepicker>
        </div>
        {{paramsChanged}}
        <h2 v-if="isLoading" class="v-blink_blink">Loading...</h2>
        <div v-if="announcement_list.length === 0" style="width: 100%;">
            <div style="position: absolute; top: 100px; left: 40%;">
                no data
            </div>
        </div>
        <div v-for="(data, i) in announcement_list" :key="data.gid">
            <div v-if="data.new_val !== noScoreStr" class="flex v-result-row" :class="{'highlight': i%2 === 0}">
                <div class="flex v-column item-center-vertical" style="flex-basis: 130px;">
                    <div class="inner">
                        {{data.created_at}}
                    </div>
                </div>
                <div class="flex v-column item-center-vertical" style="flex: 1; text-align: start; border-right: none;">
                    <div class="inner">
                        [{{data.sport_type}}&nbsp;{{data.kickoff_time}}]&nbsp;{{data.message}}
                        <br/>from&nbsp;{{data.exist_val}}&nbsp;to&nbsp;{{data.new_val}}
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>

    import moment from 'moment'
    import Datepicker from 'vuejs-datepicker'
    import debounce from '@/debounce'
    import axiosWrapper from '@/axiosWrapper'

    export default {
        name: "Announcement",
        components: {
            Datepicker
        },
        data() {
            return {
                startdate: moment(),
                announcement_list: [],
                isLoading: false,
                noScoreStr: '"-:-"',
            }
        },
        computed: {
            paramsChanged: function(){
                this.getData(this.startdate);
                return "";
            },
            momentToDate: function(){
                return this.startdate.toDate();
            },
            dateStr: function(){
                return this.startdate.format('YYYYMMDD');
            },
        },
        methods: {
            selectDatePicker: function(date){
                this.startdate = moment(date);
            },
            getData: debounce(async function(){

                this.isLoading = true;

                this.announcement_list = [];

                const res = await axiosWrapper({
                    method: 'get',
                    url: `${this.envDomains.VUE_APP_123API}/announcement/gets?startdate=${this.dateStr}&enddate=${this.dateStr}`
                });
                this.isLoading = false;
                const data = res.data;
                this.announcement_list = data.data;
            }, 400),
        }
    }
</script>

<style scoped>
    .v-result-row{
        font-size: 12px;
        justify-content: flex-start;
    }
    .v-result-row .v-column{
        border-right: solid 1px lightgray;
        border-bottom: solid 1px lightgray;
        height: auto;
    }
    .v-result-row .v-column .inner{
        margin: 8px;
    }
    .v-result-row.highlight{
        background-color: #f2f2f2;
    }

    .v-blink_blink {
        animation: blinker 1s linear infinite;
    }
    @keyframes blinker {
        50% {
            opacity: 0;
        }
    }
</style>