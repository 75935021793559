<template>
    <div class="VideoPlayerYoutube">
        <youtube 
        :video-id="videoId" 
        :player-vars="playerVars" 
        :width="width"
        :height="height"
        ref="youtube"></youtube>
    </div>
</template>
<script>

export default {
    name: 'VideoPlayerYoutube',
    props: [
        'videoUrl',
        'width',
        'height',
        'playerBus',
        'isMute',
    ],
    data() {
        return {
            playerVars: {
                autoplay: 1,
                controls: 0,
                playsinline: 1,
                mute: (this.isMute) ? 1 : 0,
            },
        }
    },
    computed: {
        player() {
            return this.$refs.youtube.player
        },
        videoId() {
            return this.$youtube.getIdFromUrl(this.videoUrl)
        },
    },
    methods: {
        fullscreen() {

            const iframe = document.querySelector('.VideoPlayerYoutube iframe')
            
            const requestFullScreen = iframe.requestFullScreen || iframe.mozRequestFullScreen || iframe.webkitRequestFullScreen;
            if (!requestFullScreen) {
                this.$bvToast.toast('เบราว์เซอร์ไม่รองรับ ขออภัยในความไม่สะดวก', {
                    title: 'แจ้งเตือน',
                    toaster: 'b-toaster-top-full'
                })
                return
            }
            requestFullScreen.bind(iframe)();
            if (screen.orientation && screen.orientation.lock) {
                screen.orientation.lock('landscape');
            }
        },
        mute() {
            this.player.mute()
        },
        unMute() {
            this.player.unMute()
        },
    },
    async mounted() {
        await this.player.playVideo()
        if(this.playerBus) {
            this.playerBus.$on('fullscreen', this.fullscreen)
            this.playerBus.$on('mute', this.mute)
            this.playerBus.$on('unMute', this.unMute)
        }
    },
    beforeDestroy() {
        if(this.playerBus) {
            this.playerBus.$off('fullscreen', this.fullscreen)
            this.playerBus.$off('mute', this.mute)
            this.playerBus.$off('unMute', this.unMute)
        }
    }
}
</script>
<style scoped>
.VideoPlayerYoutube {
    width: 100%;
    height: 100%;
}
</style>