<template>
    <div>
        <div v-if="false" class="box-right-btn">
            <button type="submit" @click="OpenPanel()">เปิด/ปิด</button>
            <button type="submit" @click="WinBanker()">เสือชนะ</button>
            <button type="submit" @click="WinPlayer()">มังกรชนะ</button>
            <button type="submit" @click="TieScare()">เสมอ</button>
            <button type="submit" @click="GetCard()">แจกไพ่</button>
            <button type="submit" @click="GetCardAll()">แจกไพ่ทั้งหมด</button>
            <button type="submit" @click="FlipCard()">เปิดไพ่</button>
            <button type="submit" @click="FlipCardAll()">เปิดไพ่ทั้งหมด</button>
<!--            <button type="submit" @click="CloseFlipCard()">ปิดไพ่</button>-->
        </div>
        <div class="box-panelCardMiddle" :class="{'OpanPanel':isPanelOpen}">
            <div class="box-panelCardMiddle-info" :class="{
                'win-banker': isTigerWin,
                'win-player': isDragonWin,
                'tie-score': isTie,
                'getCardAll':get_card_all}">
                <div class="tie-active"></div>
                <div class="cardMiddleInfoLeft"  :class="{'corCardFlipAll':flip_card_all}">
                    <div class="win-active"></div>
                    <div class="cardMiddleInfoLeft-content">
                        <p>มังกร</p>
                        <strong>{{dragonPointDisplay}}</strong>
                    </div>
                    <Card :card="cards.gameInfoCardDragon" :dataOpenDelay="cardDataOpenDelays.cardDragon"></Card>
                    <!-- <span class="corCard" :class="{'corCardFlip':flip_card}">
                               <div class="cardMain">
                                   <div class="content-cardMain">
                                       <div class="front-cardMain"> <img src="@/assets/images/pokercard/card-back.png"/></div>
                                       <div class="back-cardMain"> <img src="@/assets/images/pokercard/0101.png"/></div>
                                   </div>
                               </div>

                            </span> -->
                </div>
                <div class="cardMiddleInfoRight" :class="{'corCardFlipAll':flip_card_all}">
                    <div class="cardMiddleInfoRight-content">
                        <strong>{{tigerPointDisplay}}</strong>
                        <p>เสือ</p>
                    </div>
                    <div class="win-active"></div>
                    <Card :card="cards.gameInfoCardTiger" :dataOpenDelay="cardDataOpenDelays.cardTiger"></Card>
                    <!-- <span class="corCard" :class="{'getCard':get_card}">
                               <div class="cardMain">
                                   <div class="content-cardMain">
                                       <div class="front-cardMain"> <img src="@/assets/images/pokercard/card-back.png"/></div>
                                       <div class="back-cardMain"> <img src="@/assets/images/pokercard/0101.png"/></div>
                                   </div>
                               </div>
                            </span> -->
                </div>
            </div>
        </div>
    </div>



</template>
<script>

    import Card from '../FloatOpenCardCard'

    export default {
        name: 'FloatOpenCardDT',
        components: {
            Card,
        },
        props: [
            'isPanelOpen',
            'tigerPointDisplay',
            'dragonPointDisplay',
            'isDragonWin',
            'isTigerWin',
            'isTie',
            'cards',
            'cardDataOpenDelays',
        ],
        data() {
            return {
                open_panel: false,
                win_banker: false,
                win_player: false,
                tie_score: false,
                flip_card: false,
                flip_card_all: false,
                get_card: false,
                get_card_all: false,
            }
        },
        methods: {
            OpenPanel() {
                if(this.open_panel){
                    this.open_panel = false;
                }else{
                    this.open_panel = true;
                }
                this.win_player = false;
                this.win_banker = false;
                this.tie_score = false;
                this.flip_card = false;
            },
            WinPlayer() {
                if(this.win_player){
                    this.win_player = false;
                }else{
                    this.win_player = true;
                }
                this.win_banker = false;
                this.tie_score = false;
            },
            WinBanker() {
                if(this.win_banker){
                    this.win_banker = false;
                }else{
                    this.win_banker = true;
                }
                this.win_player = false;
                this.tie_score = false;
            },
            TieScare() {
                if(this.tie_score){
                    this.tie_score = false;
                }else{
                    this.tie_score = true;
                }
                this.win_player = false;
                this.win_banker = false;
            },
            FlipCard() {
                if(this.flip_card){
                    this.flip_card = false;
                }else{
                    this.flip_card = true;
                }
                this.flip_card_all = false;
            },
            FlipCardAll() {
                if(this.flip_card_all){
                    this.flip_card_all = false;
                }else{
                    this.flip_card_all = true;
                }
                this.flip_card = false;
            },


            GetCard() {
                if(this.get_card){
                    this.get_card = false;
                }else{
                    this.get_card = true;
                }
            },
            GetCardAll() {
                if(this.get_card_all){
                    this.get_card_all = false;
                }else{
                    this.get_card_all = true;
                }
            }
        },
    }

</script>
<style>
    .box-panelCardMiddle-info {
        height: 165px;
    }
    .cardMiddleInfoLeft{
        justify-content: flex-end;
        padding-right: 10px;
    }
    .cardMiddleInfoRight{
        justify-content: flex-start;
        padding-left: 10px;
    }
</style>