import { render, staticRenderFns } from "./Promotion.vue?vue&type=template&id=12bb76fa&scoped=true&"
import script from "./Promotion.vue?vue&type=script&lang=js&"
export * from "./Promotion.vue?vue&type=script&lang=js&"
import style0 from "@/assets/css/package.css?vue&type=style&index=0&id=12bb76fa&scoped=true&lang=css&"
import style1 from "./Promotion.vue?vue&type=style&index=1&id=12bb76fa&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "12bb76fa",
  null
  
)

export default component.exports