<template>
    <div class="DragonTigerTableListItem content-table-info">
        <div class="content-table-info-left">
            <div class="sec-Baccarat box-table-list">
                <div class="b-child-tb">
                    <div class="info-panel-play-state">
                        <div class="box-score-table" id="stat-box-bottom" style="height: 110px;">
                            <div class="column" ref="column-left" style="overflow-x: scroll; width: 100%;">
                                <StatMain @existStatWidthChange="mainScroll" 
                                :statArr="statArr"
                                :dataMaxRow="5"></StatMain>
                            </div>
                            <div v-if="false" class="column1">
                                <div class="score-scroll score-scroll1" id="big_road">
                                    <table border="1" cellpadding="0" cellspacing="0">
                                        <tbody>
                                        <tr>
                                            <td width="1" height="2" class="board-backgroundx"
                                                style="border-width:1px; border-style:dotted; border-color:#DDDDDD">
                                                <div class="text-score_bigroad text-center text-box-score-right_all"><span
                                                        class="box-lot-cell_sm border-green"></span>
                                                </div>
                                            </td>
                                            <td width="1" height="2" class="board-backgroundx"
                                                style="border-width:1px; border-style:dotted; border-color:#DDDDDD">
                                                <div class="text-score_bigroad text-center text-box-score-right_all"><span
                                                        class="box-lot-cell_sm border-blue"></span>
                                                </div>
                                            </td>
                                            <td width="1" height="2" class="board-backgroundx"
                                                style="border-width:1px; border-style:dotted; border-color:#DDDDDD">
                                                <div class="text-score_bigroad text-center text-box-score-right_all"><span
                                                        class="box-lot-cell_sm border-red"></span>
                                                </div>
                                            </td>
                                            <td width="1" height="2" class="board-backgroundx"
                                                style="border-width:1px; border-style:dotted; border-color:#DDDDDD">
                                                <div class="text-score_bigroad text-center text-box-score-right_all"><span
                                                        class="box-lot-cell_sm border-red"></span>
                                                </div>
                                            </td>
                                            <td width="1" height="2" class="board-backgroundx"
                                                style="border-width:1px; border-style:dotted; border-color:#DDDDDD">
                                                <div class="text-score_bigroad text-center text-box-score-right_all"><span
                                                        class="box-lot-cell_sm border-blue"></span>
                                                </div>
                                            </td>
                                            <td width="1" height="2" class="board-backgroundx"
                                                style="border-width:1px; border-style:dotted; border-color:#DDDDDD">
                                                <div class="text-score_bigroad text-center text-box-score-right_all"><span
                                                        class="box-lot-cell_sm border-blue"></span>
                                                </div>
                                            </td>
                                            <td width="1" height="2" class="board-backgroundx"
                                                style="border-width:1px; border-style:dotted; border-color:#DDDDDD">
                                                <div class="text-score_bigroad text-center text-box-score-right_all"><span
                                                        class="box-lot-cell_sm border-blue"></span>
                                                </div>
                                            </td>
                                        </tr>
                                        <tr>
                                            <td width="1" height="2" class="board-backgroundx"
                                                style="border-width:1px; border-style:dotted; border-color:#DDDDDD">
                                                <div class="text-score_bigroad text-center text-box-score-right_all"><span
                                                        class="box-lot-cell_sm border-red"></span>
                                                </div>
                                            </td>
                                            <td width="1" height="2" class="board-backgroundx"
                                                style="border-width:1px; border-style:dotted; border-color:#DDDDDD">
                                                <div class="text-score_bigroad text-center text-box-score-right_all"><span
                                                        class="box-lot-cell_sm border-blue"></span>
                                                </div>
                                            </td>
                                            <td width="1" height="2" class="board-backgroundx"
                                                style="border-width:1px; border-style:dotted; border-color:#DDDDDD">
                                                <div class="text-score_bigroad text-center text-box-score-right_all"></div>
                                            </td>
                                            <td width="1" height="2" class="board-backgroundx"
                                                style="border-width:1px; border-style:dotted; border-color:#DDDDDD">
                                                <div class="text-score_bigroad text-center text-box-score-right_all"></div>
                                            </td>
                                            <td width="1" height="2" class="board-backgroundx"
                                                style="border-width:1px; border-style:dotted; border-color:#DDDDDD">
                                                <div class="text-score_bigroad text-center text-box-score-right_all"></div>
                                            </td>
                                            <td width="1" height="2" class="board-backgroundx"
                                                style="border-width:1px; border-style:dotted; border-color:#DDDDDD">
                                                <div class="text-score_bigroad text-center text-box-score-right_all"></div>
                                            </td>
                                            <td width="1" height="2" class="board-backgroundx"
                                                style="border-width:1px; border-style:dotted; border-color:#DDDDDD">
                                                <div class="text-score_bigroad text-center text-box-score-right_all"></div>
                                            </td>
                                        </tr>
                                        <tr>
                                            <td width="1" height="2" class="board-backgroundx"
                                                style="border-width:1px; border-style:dotted; border-color:#DDDDDD">
                                                <div class="text-score_bigroad text-center text-box-score-right_all"><span
                                                        class="box-lot-cell_sm border-red"></span>
                                                </div>
                                            </td>
                                            <td width="1" height="2" class="board-backgroundx"
                                                style="border-width:1px; border-style:dotted; border-color:#DDDDDD">
                                                <div class="text-score_bigroad text-center text-box-score-right_all"><span
                                                        class="box-lot-cell_sm border-blue"></span>
                                                </div>
                                            </td>
                                            <td width="1" height="2" class="board-backgroundx"
                                                style="border-width:1px; border-style:dotted; border-color:#DDDDDD">
                                                <div class="text-score_bigroad text-center text-box-score-right_all"></div>
                                            </td>
                                            <td width="1" height="2" class="board-backgroundx"
                                                style="border-width:1px; border-style:dotted; border-color:#DDDDDD">
                                                <div class="text-score_bigroad text-center text-box-score-right_all"></div>
                                            </td>
                                            <td width="1" height="2" class="board-backgroundx"
                                                style="border-width:1px; border-style:dotted; border-color:#DDDDDD">
                                                <div class="text-score_bigroad text-center text-box-score-right_all"></div>
                                            </td>
                                            <td width="1" height="2" class="board-backgroundx"
                                                style="border-width:1px; border-style:dotted; border-color:#DDDDDD">
                                                <div class="text-score_bigroad text-center text-box-score-right_all"></div>
                                            </td>
                                            <td width="1" height="2" class="board-backgroundx"
                                                style="border-width:1px; border-style:dotted; border-color:#DDDDDD">
                                                <div class="text-score_bigroad text-center text-box-score-right_all"></div>
                                            </td>
                                        </tr>
                                        <tr>
                                            <td width="1" height="2" class="board-backgroundx"
                                                style="border-width:1px; border-style:dotted; border-color:#DDDDDD">
                                                <div class="text-score_bigroad text-center text-box-score-right_all"></div>
                                            </td>
                                            <td width="1" height="2" class="board-backgroundx"
                                                style="border-width:1px; border-style:dotted; border-color:#DDDDDD">
                                                <div class="text-score_bigroad text-center text-box-score-right_all"></div>
                                            </td>
                                            <td width="1" height="2" class="board-backgroundx"
                                                style="border-width:1px; border-style:dotted; border-color:#DDDDDD">
                                                <div class="text-score_bigroad text-center text-box-score-right_all"></div>
                                            </td>
                                            <td width="1" height="2" class="board-backgroundx"
                                                style="border-width:1px; border-style:dotted; border-color:#DDDDDD">
                                                <div class="text-score_bigroad text-center text-box-score-right_all"></div>
                                            </td>
                                            <td width="1" height="2" class="board-backgroundx"
                                                style="border-width:1px; border-style:dotted; border-color:#DDDDDD">
                                                <div class="text-score_bigroad text-center text-box-score-right_all"></div>
                                            </td>
                                            <td width="1" height="2" class="board-backgroundx"
                                                style="border-width:1px; border-style:dotted; border-color:#DDDDDD">
                                                <div class="text-score_bigroad text-center text-box-score-right_all"></div>
                                            </td>
                                            <td width="1" height="2" class="board-backgroundx"
                                                style="border-width:1px; border-style:dotted; border-color:#DDDDDD">
                                                <div class="text-score_bigroad text-center text-box-score-right_all"></div>
                                            </td>
                                        </tr>
                                        <tr>
                                            <td width="1" height="2" class="board-backgroundx"
                                                style="border-width:1px; border-style:dotted; border-color:#DDDDDD">
                                                <div class="text-score_bigroad text-center text-box-score-right_all"></div>
                                            </td>
                                            <td width="1" height="2" class="board-backgroundx"
                                                style="border-width:1px; border-style:dotted; border-color:#DDDDDD">
                                                <div class="text-score_bigroad text-center text-box-score-right_all"></div>
                                            </td>
                                            <td width="1" height="2" class="board-backgroundx"
                                                style="border-width:1px; border-style:dotted; border-color:#DDDDDD">
                                                <div class="text-score_bigroad text-center text-box-score-right_all"></div>
                                            </td>
                                            <td width="1" height="2" class="board-backgroundx"
                                                style="border-width:1px; border-style:dotted; border-color:#DDDDDD">
                                                <div class="text-score_bigroad text-center text-box-score-right_all"></div>
                                            </td>
                                            <td width="1" height="2" class="board-backgroundx"
                                                style="border-width:1px; border-style:dotted; border-color:#DDDDDD">
                                                <div class="text-score_bigroad text-center text-box-score-right_all"></div>
                                            </td>
                                            <td width="1" height="2" class="board-backgroundx"
                                                style="border-width:1px; border-style:dotted; border-color:#DDDDDD">
                                                <div class="text-score_bigroad text-center text-box-score-right_all"></div>
                                            </td>
                                            <td width="1" height="2" class="board-backgroundx"
                                                style="border-width:1px; border-style:dotted; border-color:#DDDDDD">
                                                <div class="text-score_bigroad text-center text-box-score-right_all"></div>
                                            </td>
                                        </tr>
                                        <tr>
                                            <td width="1" height="2" class="board-backgroundx"
                                                style="border-width:1px; border-style:dotted; border-color:#DDDDDD">
                                                <div class="text-score_bigroad text-center text-box-score-right_all"></div>
                                            </td>
                                            <td width="1" height="2" class="board-backgroundx"
                                                style="border-width:1px; border-style:dotted; border-color:#DDDDDD">
                                                <div class="text-score_bigroad text-center text-box-score-right_all"></div>
                                            </td>
                                            <td width="1" height="2" class="board-backgroundx"
                                                style="border-width:1px; border-style:dotted; border-color:#DDDDDD">
                                                <div class="text-score_bigroad text-center text-box-score-right_all"></div>
                                            </td>
                                            <td width="1" height="2" class="board-backgroundx"
                                                style="border-width:1px; border-style:dotted; border-color:#DDDDDD">
                                                <div class="text-score_bigroad text-center text-box-score-right_all"></div>
                                            </td>
                                            <td width="1" height="2" class="board-backgroundx"
                                                style="border-width:1px; border-style:dotted; border-color:#DDDDDD">
                                                <div class="text-score_bigroad text-center text-box-score-right_all"></div>
                                            </td>
                                            <td width="1" height="2" class="board-backgroundx"
                                                style="border-width:1px; border-style:dotted; border-color:#DDDDDD">
                                                <div class="text-score_bigroad text-center text-box-score-right_all"></div>
                                            </td>
                                            <td width="1" height="2" class="board-backgroundx"
                                                style="border-width:1px; border-style:dotted; border-color:#DDDDDD">
                                                <div class="text-score_bigroad text-center text-box-score-right_all"></div>
                                            </td>
                                        </tr>
                                        <tr>
                                            <td width="1" height="2" class="board-backgroundx"
                                                style="border-width:1px; border-style:dotted; border-color:#DDDDDD">
                                                <div class="text-score_bigroad text-center text-box-score-right_all"></div>
                                            </td>
                                            <td width="1" height="2" class="board-backgroundx"
                                                style="border-width:1px; border-style:dotted; border-color:#DDDDDD">
                                                <div class="text-score_bigroad text-center text-box-score-right_all"></div>
                                            </td>
                                            <td width="1" height="2" class="board-backgroundx"
                                                style="border-width:1px; border-style:dotted; border-color:#DDDDDD">
                                                <div class="text-score_bigroad text-center text-box-score-right_all"></div>
                                            </td>
                                            <td width="1" height="2" class="board-backgroundx"
                                                style="border-width:1px; border-style:dotted; border-color:#DDDDDD">
                                                <div class="text-score_bigroad text-center text-box-score-right_all"></div>
                                            </td>
                                            <td width="1" height="2" class="board-backgroundx"
                                                style="border-width:1px; border-style:dotted; border-color:#DDDDDD">
                                                <div class="text-score_bigroad text-center text-box-score-right_all"></div>
                                            </td>
                                            <td width="1" height="2" class="board-backgroundx"
                                                style="border-width:1px; border-style:dotted; border-color:#DDDDDD">
                                                <div class="text-score_bigroad text-center text-box-score-right_all"></div>
                                            </td>
                                            <td width="1" height="2" class="board-backgroundx"
                                                style="border-width:1px; border-style:dotted; border-color:#DDDDDD">
                                                <div class="text-score_bigroad text-center text-box-score-right_all"></div>
                                            </td>
                                        </tr>
                                        <tr>
                                            <td width="1" height="2" class="board-backgroundx"
                                                style="border-width:1px; border-style:dotted; border-color:#DDDDDD">
                                                <div class="text-score_bigroad text-center text-box-score-right_all"></div>
                                            </td>
                                            <td width="1" height="2" class="board-backgroundx"
                                                style="border-width:1px; border-style:dotted; border-color:#DDDDDD">
                                                <div class="text-score_bigroad text-center text-box-score-right_all"></div>
                                            </td>
                                            <td width="1" height="2" class="board-backgroundx"
                                                style="border-width:1px; border-style:dotted; border-color:#DDDDDD">
                                                <div class="text-score_bigroad text-center text-box-score-right_all"></div>
                                            </td>
                                            <td width="1" height="2" class="board-backgroundx"
                                                style="border-width:1px; border-style:dotted; border-color:#DDDDDD">
                                                <div class="text-score_bigroad text-center text-box-score-right_all"></div>
                                            </td>
                                            <td width="1" height="2" class="board-backgroundx"
                                                style="border-width:1px; border-style:dotted; border-color:#DDDDDD">
                                                <div class="text-score_bigroad text-center text-box-score-right_all"></div>
                                            </td>
                                            <td width="1" height="2" class="board-backgroundx"
                                                style="border-width:1px; border-style:dotted; border-color:#DDDDDD">
                                                <div class="text-score_bigroad text-center text-box-score-right_all"></div>
                                            </td>
                                            <td width="1" height="2" class="board-backgroundx"
                                                style="border-width:1px; border-style:dotted; border-color:#DDDDDD">
                                                <div class="text-score_bigroad text-center text-box-score-right_all"></div>
                                            </td>
                                        </tr>
                                        </tbody>
                                    </table>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="content-table-info-right">
            <div class="sec-baccarat w-100">
                <div v-if="tableStatus === GAME_STATUS.FINISH && statLatest" class="box-current-result">
                    <div :class="`current-box ${classComputed(statLatest.game_result)}`">
                        {{resultComputed(statLatest.game_result)}}
                    </div>
                    <p><small>{{ratioDisplay(statLatest.game_result)}}</small></p>
                    <p class="point-result">{{calWinnerPoint(statLatest.game_info)}}</p>
                </div>
                <div v-else class="box-current-result full">
                    <TableStatus 
                    :status="tableStatus" 
                    :secondStart="secondStart"
                    :secondStartRemain="secondStartRemain"></TableStatus>
                </div>
            </div>
        </div>
    </div>
</template>
<script>

import TableListItemMixin from './TableListItemMixin'
import StatMain from './StatMain'
import TableStatus from '../TableStatus'
import {GAME_STATUS_KEY} from '../Constant'
import StatScrollMixin from '../StatScrollMixin'

export default {
    name: 'DragonTigerTableListItem',
    props: [
        'table_id',
        'table_round_main',
    ],
    components: {
        StatMain,
        TableStatus,
    },
    data() {
        return {
            GAME_STATUS: null,
        }
    },
    mixins: [
        TableListItemMixin,
        StatScrollMixin,
    ],
    created() {
        this.GAME_STATUS = GAME_STATUS_KEY
    }
}
</script>
<style scoped>
.box-table-list {
    width: 100%;
    background-color: #fff;
    color: #000;
    padding: 5px;
    display: flex;
}

.box-table-list > .b-child-tb {
    width: 100%;
}

.dice-cs {
    font-size: inherit;
    color: #000;
    margin: 0px;
}

.dice-cs span {
    margin-right: 2px;
}

.dice-txt {
    color: #000;
    font-size: 14px;
}

.content-table-info-right {

}

.box-game-result {
    text-align: center;
    width: 100%;
    color: #fff;

}

.user-list-detail {
    border-bottom: solid 1px #eee;
}

.dice-lg span.dice {
    display: inline-block;
    min-height: 2em;
    width: 19px;
    margin: 0 auto;
    background-size: 18px;
    background-position: center;
    background-repeat: no-repeat;
}

span.text-lo, span.text-hi, span.text-bgwhite {
    width: 17px !important;
    height: 17px !important;
    padding: 1px 2px;
    line-height: 12px;
    font-size: 12px !important;
    margin: 0px 5px;
    box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
}

span.text-bgwhite {
    color: #000;
    padding: 0px 8px;
    font-weight: 600;
    border-radius: 3px;
    box-shadow: rgba(0, 0, 0, 0.16) 0px 1px 4px;
}

.box-blocker {
    margin-top: 20px;
}

.color-white {
    background-color: #fff;
    color: #000;
}

.color-red {
    background-color: #c52828 !important;
    color: #fff !important;
}

.color-blue {
    background-color: #006aff;
    color: #fff !important;
}

.color-green {
    background-color: #28a745 !important;
    color: #fff !important;
}

.color-yellow {
    background-color: #fab600;
    color: #fff !important;
}

.box-score-table {
    display: flex;
}

.box-score-table table {
    border: 0 !important;
    width: 100%;
    height: 100%;
}

.box-score-table td {
    border-collapse: collapse;
}

.column {
    display: flex;
    float: left;
    width: 41%;
    border-right: solid 1px;
    /*border-right: 1px;*/
    /*border-left: 1px;*/
    /*border-style: solid;*/
    /*border-color: rgb(66, 65, 65);*/
}

.column1 {
    float: left;
    width: 100%;
}

.box-score-table .score-scroll {
    margin: 0;
    width: auto;
}

.score-scroll:hover {
    overflow-x: overlay;
}

div.score-scroll {
    display: block;
    position: relative;
    width: 100%;
    overflow-x: hidden;
    overflow-y: hidden;
    white-space: nowrap;
    border-bottom: solid 1px #000;
}

.score-scroll {
    background-color: transparent;
}

.board-backgroundx {
    border: solid 1px #ddd !important;
}

.text-score_bigroad {
    /* font-size: 14px; */
    width: 9px;
    height: 9px;
    display: block;
    margin: 1px;
}

.text-score {
    width: 15.5px;
    height: 15.5px;
    display: block;
    margin: 2px;
}

.text-score, .text-score-status, .text-score_all, .text-score-topic, .text-status-primary, .text-status-secondary, .text-red-bcc, .text-score_eye, .text-score_bigroad, .text-blue-bcc, .text-score_bigeye, .text-score_small {
    text-shadow: 0.5px 0.5px 0.5px #eee;
}

.text-box-score-right_eye {
    width: 4px;
    height: 2px;
}

.text-box-score-right_eye {
    display: table;
    margin-top: 1px;
    margin-bottom: 1px;
    margin-right: 1px;
    margin-left: 1px;
    padding-top: 1px;
}

.text-box-score-right_small {
    width: 2px;
    height: 2px;
}

.text-box-score-right_small {
    display: table;
    margin-top: 1px;
    margin-bottom: 1px;
    margin-right: 1px;
    margin-left: 1px;
    padding-top: 1px;
}

.color-red {
    background-color: #c52828 !important;
}

.color-blue {
    background-color: #006aff !important;
}

.color-yellow {
    background-color: #fab600;
}

.box-lot-cell {
    text-align: center;
    font-size: 13px;
    width: 18px;
    height: 18px;
    border-radius: 50%;
    cursor: pointer;
    display: inline-block;
    line-height: 17px;
    color: #fff;
    float: left;
    margin: 0 auto;
    font-family: "Athiti", sans-serif;
    margin-top: -1px;
    margin-left: -2px;
    position: relative;
}

.box-lot-cell_sm, .box-lot-cell_mini {
    width: 15px;
    height: 15px;
    border-radius: 50%;
    display: inline-block;
    float: left;
    font-family: "Athiti", sans-serif;
    margin-top: -1px;
    margin-left: -2px;
    border: solid 2px;
}

.box-lot-cell_mini {
    width: 6px;
    height: 6px;
    float: right;
}

.float-dot {
    display: block;
    width: 8px;
    height: 8px;
    border-radius: 50%;
    position: absolute;
    border: solid 1px #fff;
}

.dot-red {
    background-color: #c52828 !important;
    top: -1px;
    left: -2px;
}

.dot-blue {
    background-color: #006aff !important;
    bottom: -1px;
    right: 0px;
}

.border-red {
    border-color: #c52828 !important;
}

.border-blue {
    border-color: #006aff !important;
}

.border-green {
    border-color: #28a745 !important;
}

.content-table-info-right strong {
    font-size: 8vw !important;
}

.box-st {
    padding: 5px;
    border-bottom: solid 1px #000;
}

.cols-1, .col.box-blockers-2 {
    display: flex;
    color: #fff;
    text-align: center;
}

.cols-1 span, .cols-2 span {
    padding: 4px;
    margin: 2px;
    font-size: 16px;
    font-weight: 500;
    border: solid 1px #000;
    background-color: rgba(0, 0, 0, 0.4);
}

.cols-1 span {
    width: 33.3%;
}

.cols-2 span {
    width: 50%;
}

.box-current-result{
    text-align: center;
    color: #fff;
    font-family: "Teko", sans-serif;
    padding: 8px 5px;
}

.box-current-result.full {
    display: flex;
    justify-content: center;
    height: 100%;
}

.box-current-result p{
    margin: 0px;
    font-size: 20px;
}

.box-current-result > .current-box{
    display: inline-block;
    margin-bottom: 0px;
    border-radius: 5px;
    font-size: 18px;
    padding: 2px 10px 0px 10px;
    text-transform: uppercase;
}

.point-result{
    font-size: 16px;
}
</style>