<template>
    <div v-if="round_obj || lotto_type === 'yeekee'" class="LottoTypePlate box-content-tab" :class="{'active': is_selected}">
        <div @click="select_round" class="content-all-left">
            <div class="left-detail">
                <div v-if="lotto_type === 'yeekee'">
                    <div v-if="is_on_result" class="spinner-border spinner-border-sm" role="status">
                        <span class="sr-only">Loading...</span>
                    </div>
                    <LottoTypeIcon v-else lotto_type="yeekee"></LottoTypeIcon>
                </div>
                <LottoTypeIcon v-else :lotto_type="lotto_type"></LottoTypeIcon>
            </div>
            <div class="box-content-tab-detail">
                <h3>
                    <span class="t-lotto">{{lotto_type | lottoTypeDisplay}}</span>
                    <span class="t-top-lotto">{{sub_round}}</span>
                </h3>
                <div>
                    <p>{{description}}</p>
                </div>
                <ul>
                    <li v-if="lotto_type === 'yeekee'">
                        <Alarm v-show="!is_round_open" @timeout="closeGuessHandle" :extra_minutes="2" :start="timeout"></Alarm>
                        <Alarm @timeout="timeoutHandle" :start="timeout"></Alarm>
                        <span v-if="is_on_result">กำลังออกผลรางวัล</span>
                    </li>
                    <li v-else>
                        <Alarm :start="timeout" :show_on_timeout="true"></Alarm>
                    </li>
                </ul>
            </div>
        </div>
    </div>
</template>

<script>

import AppMixin from '../mixins/App'
import Alarm from "./Alarm";
import LottoTypeIcon from './LottoTypeIcon'

export default {
    name: "LottoTypePlate",
    mixins: [
        AppMixin
    ],
    components: {
        Alarm,
        LottoTypeIcon,
    },
    props: [
        'round_obj', 'selected_round', 'manual_lotto_type'
    ],
    data(){
        return {
            is_on_result: false,
        }
    },
    computed: {
        lotto_type: function(){
            return (this.round_obj) ? this.round_obj.lotto_type : this.manual_lotto_type;
        },
        description: function(){
            return this.round_obj.description;
        },
        sub_round: function(){
            return (this.round_obj.sub_round) ? 'รอบที่ '+this.round_obj.sub_round : '';
        },
        timeout: function(){
            return this.round_obj.timeout;
        },
        is_selected: function(){
            if(this.selected_round){
                return (this.round_obj.id === this.selected_round.id);
            }else{
                return false;
            }
        },
        is_round_open:  {
            get: function(){
                if (this.round_obj) {
                    const now = this.$moment();
                    const timeout = this.$moment(this.round_obj.timeout);
                    return (this.round_obj && this.round_obj.status === 'open' && now.isSameOrBefore(timeout));
                } else {
                    return false;
                }
            },
            set: function(new_val){
                if(this.round_obj){
                    this.round_obj.status = new_val;
                }
            }
        },
    },
    watch: {
        timeout(){
            this.is_on_result = false;
        }
    },
    methods: {
        select_round: function(){
            this.$emit('selected', this.round_obj);
        },
        timeoutHandle(){
            if(this.lotto_type === 'yeekee'){
                this.is_round_open = 'close';
            }
        },
        closeGuessHandle(){

            if(this.lotto_type === 'yeekee'){

                this.is_on_result = true;

                const _this = this;
                setTimeout(() => {
                    _this.is_on_result = false;
                    _this.$emit('close_guess');
                }, 20*1000);
            }
        }
    }
}
</script>

<style scoped>

</style>