<template>
    <div>
        <vue-recaptcha @verify="recaptchaVerified"
            :sitekey="googleCaptchaSiteKey">
        </vue-recaptcha>
        recaptchaToken: {{ recaptchaToken }}
    </div>
</template>
<script>

import VueRecaptcha from 'vue-recaptcha';

export default {
    name: 'TestCaptcha',
    components: { VueRecaptcha },
    data() {
        return {
            recaptchaToken: '',
        }
    },
    methods: {
        recaptchaVerified(response) {
            this.recaptchaToken = response
        }
    },
}
</script>