<template>
    <div class="GameTableStatus">
        <span v-if="mode ==='plain'">
            <span v-if="status === GAME_STATUS.COUNTDOWN">{{secondCountDown}}</span>
            <span v-else>{{status | displayTableGameStatus}}</span>
        </span>
        <p v-else class="status-detail">
            <span class="count-down-text" v-if="status === GAME_STATUS.COUNTDOWN">{{secondCountDown}}</span>
            <span v-else>{{status | displayTableGameStatus}}</span>
        </p>
    </div>
</template>
<script>

import {GAME_STATUS_KEY, TABLE_GAME_STATUS} from './Constant'
import CountDownMixin from '@/mixins/CountDown'

export default {
    name: 'GameTableStatus',
    props: [
        'status',
        'mode',
    ],
    mixins: [
        CountDownMixin,
    ],
    data() {
        return {
            GAME_STATUS: null,
        }
    },
    filters: {
        displayTableGameStatus(raw, lang = 'EN') {
            if(!TABLE_GAME_STATUS[raw]) {
                return ''
            }
            return TABLE_GAME_STATUS[raw][lang]
        },
    },
    watch: {
        status(newVal) {
            if(parseInt(newVal) === this.GAME_STATUS.COUNTDOWN) {
                this.startCountDown()
            } else {
                this.stopCountDown()
            }
        }
    },
    created() {
        this.GAME_STATUS = GAME_STATUS_KEY
    },
    mounted() {
        if(this.status === this.GAME_STATUS.COUNTDOWN) {
            this.startCountDown()
        }
    },
    beforeDestroy() {
        this.stopCountDown()
    }
}
</script>
<style scoped>
.GameTableStatus {
    display: flex;
    justify-content: center;
    align-items: center;
}

.GameTableStatus .status-detail {
    font-size: 20px;
}

.count-down-text {
    font-size: 40px;
}

/* .circle-wrap {
        width: 38px;
        height: 38px;
        text-align: center;
        top: 30vw;
        left: 4vw;
        font-weight: 600;
        line-height: inherit;
        font-size: 1.45em;
        box-shadow: rgba(0, 0, 0, 0.25) 0px 14px 28px, rgba(0, 0, 0, 0.22) 0px 10px 10px;
    }


    .circle-wrap span.txt-status{
        font-size: 8px !important;
        display: block;
        margin-top: 13px;
    } */


</style>