<script>

import { LOTTO_TYPE, LOTTO_STATUS, CATEGORY_LIST } from '../Constant'
import numeral from 'numeral'
import store from '@/store'
import moment from 'moment-timezone'
moment.tz.setDefault('Asia/Bangkok')
import axiosWrapper from '@/axiosWrapper'

export default {
    name: 'AppMixin',
    computed: {
        price_table: {
            get(){
                return store.state.lotto.price_table
            },
            set(val){
                store.commit('lotto/updatePriceTable', val)
            }
        },
        currentPage() {
            return store.state.lotto.currentPage
        },
        reward_limit: {
            get(){
                return store.state.lotto.reward_limit
            },
            set(val){
                store.commit('lotto/updateRewardLimit', val)
            }
        },
    },
    filters: {
        floatFormat(value, digits = 2) {

            try{
                let format = '0,0.';

                for(let i = 0;i < digits;i++){
                    format += '0'
                }

                value = parseFloat(value);

                if(Number(value) === value && value % 1 !== 0){//is float

                    value = value.toFixed(digits);

                    if(isNaN(parseFloat(value))){
                        return "0.00";
                    }else{
                        return numeral(value).format(format);
                    }
                }else{
                    return numeral(value).format(format);
                }

            }catch(err){
                return '';
            }
        },
        lottoTypeDisplay(value) {
            return LOTTO_TYPE[value]
        },
        lottoStatusDisplay(value) {
            return LOTTO_STATUS[value]
        },
        bet_type_display(value) {
            return CATEGORY_LIST[value]['name']
        },
        dateTimeFormat(value, format = 'YYYY-MM-DD HH:mm:ss') {
            return moment(value).format(format);
        },
        dateFormat(value) {
            return moment(value).locale('th').format('D MMMM YYYY')
        },
        is_number_positive(value) {
            const number = parseFloat(value);
            if(number > 0){
                return '+'+value;
            }else{
                return value;
            }
        },
        array_to_str_list(list, seperator = ', ') {
            let str = '';
            for(let i = 0;i < list.length;i++){
                str += list[i] || '';
                if(i < list.length - 1){
                    str += seperator;
                }
            }
            return str
        },
        sub_round_display(value) {
            if(value){
                return 'รอบที่ '+value;
            }else{
                return '';
            }
        },
        fillZero(value, digit = 5) {
            let value_str = value.toString();
            const diff = digit - value_str.length;
            for(let i = 0;i < diff;i++){
                value_str = '0'+value_str;
            }
            return value_str
        },
        strCensored(value) {
            if(value){
                return value.substring(0,2)+'***'+value.substring(value.length - 2,value.length);
            }else{
                return '';
            }
        },
    },
    methods: {
        goHome() {
            store.commit('lotto/updateCurrentPage', 'home')
            this.$router.replace({name: 'LottoHome'})
        },
        async loadPriceTable(){
            const res = await axiosWrapper({
                method: 'POST',
                url: `${this.envDomains.VUE_APP_LOTTO_ADMIN_API}/api/price/load_table`,
                data: {
                    no_round: 'yes'
                }
            })
            if(res.status === 200) this.price_table = res.data
        },
        async loadRewardLimit(){
            const res = await axiosWrapper({
                method: 'POST',
                url: `${this.envDomains.VUE_APP_LOTTO_ADMIN_API}/api/reward/get-all`
            })
            if(res.status === 200) this.reward_limit = res.data
        },
        exit() {
            this.$router.replace({name: 'Home'})
        },
    },
}
</script>