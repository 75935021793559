export const TABLE_TYPE = {
    0: {
        slug: 'baccarat',
        label: 'บาคาร่า',
        iconClass: 'minigame-baccarat',
        rowColorClass: 'blue-msg',
        routerName: 'Baccarat',
    },
    1: {
        slug: 'sicbo',
        label: 'ไอโล',
        iconClass: 'minigame-sicbo',
        rowColorClass: 'red-msg',
        routerName: 'MiniSicbo',
    },
    2: {
        slug: 'bean',
        label: 'ถั่วหยิบ',
        iconClass: 'minigame-fantan',
        rowColorClass: 'green-msg',
        routerName: 'MiniFantan',
    },
    3: {
        slug: 'dragontiger',
        label: 'เสือมังกร',
        iconClass: 'minigame-dragonTiger',
        rowColorClass: 'gold-msg',
        routerName: 'MiniDragonTiger',
    },
}