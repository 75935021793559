<template>
    <div class="RedCard">
        <div v-for="i in quantity" :key="i" class="red-card-piece"></div>
    </div>
</template>
<script>
export default {
    name: 'RedCard',
    props: ['quantity']
}
</script>
<style scoped>
    .RedCard {
        display: flex;
        margin: 0 5px 0 2px;
    }
    .red-card-piece {
        background-color: red;
        border: solid white 1px;
        height: 10px;
        width: 6px;
        margin-left: 1px;
    }
</style>