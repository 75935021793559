<template>
    <div class="OTP">
<!--        <div v-show="showLabel" class="auth-row" style="justify-content: start;">-->
<!--            หมายเลขโทรศัพท์-->
<!--        </div>-->
<!--        <div class="auth-row-otp-form">-->
        <div class="auth-row auth-row-otp-form">
            <input v-model="mobile_phone_number" type="text" class="form-control" placeholder="08XXXXXXXX" :disabled="isFixMobilePhoneNumber">
            <button @click="otpRequest" type="button" class="btn btnGetOTC btn-outline-primary">
                <span v-if="otpProcessState === 'idle'">ขอ OTP</span>
                <span v-if="otpProcessState === 'requested'">ขอ OTP (โปรดรอ {{otpRequestDelaySecond}} วินาที)</span>
            </button>
        </div>
        <!-- <div v-show="otpProcessState === 'idle'" class="auth-row">
            <vue-recaptcha @verify="otpRequestRecaptchaVerified"
                        :sitekey="googleCaptchaSiteKey">
                </vue-recaptcha>
        </div> -->

        <div v-show="showLabel" class="auth-row" style="justify-content: start;">
            <p>กรอกรหัส OTP ที่ส่งไปยัง เบอร์มือถือของท่าน</p>
        </div>
        <div class="auth-row">
            <input v-model="otp" type="text" class="form-control" placeholder="รหัส OTP">
        </div>
        <!-- <div v-show="otpProcessState === 'requested'" class="auth-row">
            <vue-recaptcha @verify="submitRecaptchaVerified"
                        :sitekey="googleCaptchaSiteKey">
                </vue-recaptcha>
        </div> -->
        <div class="auth-row">
            <vue-recaptcha
                    ref="recaptcha"
                    @verify="otpRequestRecaptchaVerified"
                    @expired="otpRequestRecaptchaExpired"
                    :sitekey="googleCaptchaSiteKey">
            </vue-recaptcha>
        </div>
        <div class="form-row formRowBtn">
            <button @click="submit" type="button" class="btn btn-primary btn-block">{{submitText || 'ตกลง'}}</button>
        </div>
    </div>
</template>
<script>

import axiosWrapper from '@/axiosWrapper'
import debounce from '@/debounce'
import VueRecaptcha from 'vue-recaptcha';
const OTP_REQUEST_DELAY_SECOND = 60

export default {
    name: 'OTP',
    components: {
        VueRecaptcha,
    },
    props: [
        'submitText',
        'showLabel',
        'dataMobilePhoneNumber',
        'isFixMobilePhoneNumber',
    ],
    data() {
        return {
            mobile_phone_number: '',
            otp: '',
            otpProcessState: 'idle', //idle, requested
            otpDelayInterval: null,
            otpRequestDelaySecond: OTP_REQUEST_DELAY_SECOND,
            isOtpRequestRecaptchaVerified: false,
            otpRequestRecaptchaToken: '',
            isSubmitRecaptchaVerified: false,
        }
    },
    methods: {
        otpRequest: debounce(async function() {

            // await this.$recaptchaLoaded()
            // const recaptchaToken = await this.$recaptcha('login')

            if(!this.isOtpRequestRecaptchaVerified) {
                this.$bvToast.toast('กรุณายืนยันไม่ใช่หุ่นยนต์', {
                    title: 'แจ้งเตือน',
                    toaster: 'b-toaster-top-full'
                })
                return
            }

            if(this.otpProcessState === 'requested') {
                return
            }
            if(!this.mobile_phone_number) {
                this.$bvToast.toast('กรุณากรอกเบอร์โทรศัพท์', {
                    title: 'แจ้งเตือน',
                    toaster: 'b-toaster-top-full'
                })
                return
            }

            const res = await axiosWrapper({
                method: 'POST',
                url: `${this.envDomains.VUE_APP_LIFF_API}/api/otp/request`,
                data: {
                    "msisdn": this.mobile_phone_number,
                    "length": 5,
                    "word": "OTP คือ",
                    "minute": 1,
                    "sender": "OTP",
                    "recaptchaToken": this.otpRequestRecaptchaToken,
                    domain: window.location.hostname,
                }
            });
            if(res.data.error || res.status !== 200) {
                this.$bvToast.toast(res.data.description, {
                    title: 'แจ้งเตือน',
                    toaster: 'b-toaster-top-full'
                })
            }else{
                this.$bvToast.toast('ส่ง OTP แล้ว กรุณาตรวจดูโทรศัพท์ของคุณ', {
                    title: 'แจ้งเตือน',
                    toaster: 'b-toaster-top-full'
                })
                this.otpRequestDelaySecondCountDown()
                this.otpProcessState = 'requested'
            }
            this.$refs.recaptcha.reset()
            this.isOtpRequestRecaptchaVerified = false
            this.otpRequestRecaptchaToken = ''
        }, 444),
        otpRequestDelaySecondCountDown() {

            this.otpRequestDelaySecond = OTP_REQUEST_DELAY_SECOND
            this.otpDelayInterval = setInterval(() => {
                this.otpRequestDelaySecond--
                if(this.otpRequestDelaySecond == 0) {
                    this.otpProcessState = 'idle'
                }
            }, 1000)
        },
        submit: debounce(function() {
            // if(!this.isSubmitRecaptchaVerified) {
            //     this.$bvToast.toast('กรุณายืนยันไม่ใช่หุ่นยนต์', {
            //         title: 'แจ้งเตือน',
            //         toaster: 'b-toaster-top-full'
            //     })
            //     return
            // }
            const mobile_phone_number = this.mobile_phone_number
            const otp = this.otp
            if(!otp) {
                this.$bvToast.toast('กรุณากรอกรหัสผ่าน', {
                    title: 'แจ้งเตือน',
                    toaster: 'b-toaster-top-full'
                })
                return
            }
            this.$emit('submit', {mobile_phone_number, otp})
        }, 444),
        otpRequestRecaptchaVerified(response) {
            this.isOtpRequestRecaptchaVerified = true
            this.otpRequestRecaptchaToken = response
        },
        otpRequestRecaptchaExpired() {
            this.isOtpRequestRecaptchaVerified = false
            this.otpRequestRecaptchaToken = ''
        },
        // submitRecaptchaVerified() {
        //     this.isSubmitRecaptchaVerified = true
        // },
    },
    mounted() {
        if(this.dataMobilePhoneNumber) {
            this.mobile_phone_number = this.dataMobilePhoneNumber
        }
    },
    beforeDestroy() {
        if(this.otpDelayInterval) {
            clearInterval(this.otpDelayInterval)
        }
    }
}
</script>
<style scoped>
.auth-row {
    display: flex;
    justify-content: center;
    margin: 10px;
}
</style>