import axiosWrapper from '@/axiosWrapper';
import store from '@/store';
import debounce from "@/debounce";
import {PriceRatio} from '../Constant'

export default {
    name: 'XXSettingMixin',
    data(){
        return {
            setting_xx: null,
            setting_xxx: null,
        }
    },
    computed: {
        round_obj(){
            return store.state.lotto.round_obj;
        },
        lotto_type: function(){
            return (this.round_obj) ? this.round_obj.lotto_type : '';
        },
    },
    watch: {
        lotto_type(new_val){
            if(new_val){
                this.load_setting();
            }
        }
    },
    methods: {
        get_setting: async function(digit_str){
            const progress_res = await axiosWrapper({
                method: 'POST',
                url: `${this.envDomains.VUE_APP_LOTTO_ADMIN_API}/api/${digit_str}/setting/get_latest`,
                data: {
                    lotto_type: this.lotto_type
                }
            });
            if (progress_res.status === 200) {
                return progress_res.data.data;
            } else {
                return null;
            }
        },
        load_setting: debounce(async function(){
            this.setting_xx = await this.get_setting('xx');
            this.setting_xxx = await this.get_setting('xxx');
            Object.assign(this.setting_xxx, {
                multi_tod: this.setting_xxx.normal_multi*PriceRatio.sam_tua_tod,
                multi_nha_lang: this.setting_xxx.normal_multi*PriceRatio.sam_tua_nha_lang
            })
        }, 333),
    },
    created(){
        this.load_setting();
    }
}