<template>
    <div class="result" style="padding: 2px;">

        <h3 style="font-size: 22px; font-weight: bold;">Result</h3>
        <div class="flex item-center-vertical">
            <div style="margin-right: 2px;">
                <el-select size="small" v-model="sport_type">
                    <el-option v-for="sport in sports" :key="sport.value" 
                    :value="sport.value" 
                    :label="sport.name">
                    {{sport.name}}
                    </el-option>
                </el-select>
            </div>
            <div style="margin-right: 2px;">
                <datepicker
                        @selected="selectDatePicker"
                        :value="momentToDate"
                        format="yyyy-MM-dd">
                </datepicker>
            </div>
            <el-button
            size="mini"
            v-on:click="selectDate('today')" 
            style="margin: 1px;">
            วันนี้
            </el-button>
            <el-button
            size="mini"
            v-on:click="selectDate('yesterday')" 
            style="margin: 1px;">
            เมื่อวานนี้
            </el-button>
            <div style="margin: 2px;">
                <el-select size="small" v-model="select_league">
                    <el-option value="" label="ทุกลีก">ทุกลีก</el-option>
                    <el-option v-for="data in data_list" :key="data.league_id" 
                    :value="data.league_id"
                    :label="data.league_name_en">
                    {{data.league_name_en}}
                    </el-option>
                </el-select>
            </div>
        </div>
        <div style="margin-top: 10px;">
            <div v-if="sport_type === 'volleyball' || sport_type === 'tennis' || sport_type === 'badminton'" class="flex item-center-vertical table-header">
                <div class="flex v-column" style="flex-basis: 130px; ">Date & Time</div>
                <div class="flex v-column" style="flex-basis: 200px;">Event</div>
                <div class="flex v-column" style="flex-basis: 40px; justify-content: center;">G1</div>
                <div class="flex v-column" style="flex-basis: 40px; justify-content: center;">G2</div>
                <div class="flex v-column" style="flex-basis: 40px; justify-content: center;">G3</div>
                <div class="flex v-column" style="flex-basis: 40px; justify-content: center;">G4</div>
                <div class="flex v-column" style="flex-basis: 40px; justify-content: center;">G5</div>
                <div class="flex v-column" style="flex-basis: 110px;">
                    <span v-if="sport_type === 'volleyball' || sport_type === 'badminton'">Total Points Won</span>
                    <span v-if="sport_type === 'tennis'">Total Games Won</span>
                </div>
                <div class="flex v-column" style="flex-basis: 110px;">Total Sets Won</div>
                <div class="flex v-column" style="flex: 1; border: none;">+ More</div>
            </div>
            <div v-if="sport_type === 'football' || sport_type === 'basketball'" class="flex item-center-vertical table-header">
                <div class="flex v-column" style="flex-basis: 130px; ">Date & Time</div>
                <div class="flex v-column" style="flex-basis: 410px;">Event</div>
                <div class="flex v-column" style="flex-basis: 110px;">First Half Score</div>
                <div class="flex v-column" style="flex-basis: 110px;">Full time Score</div>
                <div class="flex v-column" style="flex: 1; border: none;">+ More</div>
            </div>
            <div v-if="sport_type === 'muaythai' || sport_type === 'boxing'" class="flex item-center-vertical table-header">
                <div class="flex v-column" style="flex-basis: 130px; ">Date & Time</div>
                <div class="flex v-column" style="flex-basis: 220px;">Event</div>
                <div class="flex v-column" style="flex-basis: 220px;">Winner</div>
                <div class="flex v-column" style="flex-basis: 150px;">Round</div>
                <div class="flex v-column" style="flex: 1; border: none;">+ More</div>
            </div>
            {{paramsChanged}}
            <h2 v-if="isLoading" class="blink_blink">Loading...</h2>

            <div v-for="data in data_list" :key="data.league_id">
                <div v-if="select_league === data.league_id || select_league === ''">
                    <div class="flex league-label">
                        {{data.league_name_en}}
                    </div>
                    <div v-for="(match, index) in data.matches" :key="match.result_id">
                        <div v-if="sport_type === 'volleyball' || sport_type === 'tennis' || sport_type === 'badminton'" class="flex result-row" style="height: auto;" :class="{'highlight': index%2 === 1}">
                            <div class="flex v-column" style="flex-basis: 130px; font-size: 12px;">{{match.kickoff_time}}</div>
                            <div class="flex-column item-center v-column" style="flex-basis: 200px; align-items: start;">
                                <div style="text-align: left; margin: 4px 4px 4px 0;">{{match.team_home_name_en}}</div>
                                <div style="text-align: left; margin: 4px 4px 4px 0;">{{match.team_away_name_en}}</div>
                            </div>
                            <div class="flex v-column item-center" style="flex-basis: 40px; font-size: 11px;">
                                {{match.results[0].home_g1_score | nullToDash}}
                                :
                                {{match.results[0].away_g1_score | nullToDash}}
                            </div>
                            <div class="flex v-column item-center" style="flex-basis: 40px; font-size: 11px;">
                                {{match.results[0].home_g2_score | nullToDash}}
                                :
                                {{match.results[0].away_g2_score | nullToDash}}
                            </div>
                            <div class="flex v-column item-center" style="flex-basis: 40px; font-size: 11px;">
                                {{match.results[0].home_g3_score | nullToDash}}
                                :
                                {{match.results[0].away_g3_score | nullToDash}}
                            </div>
                            <div class="flex v-column item-center" style="flex-basis: 40px; font-size: 11px;">
                                {{match.results[0].home_g4_score | nullToDash}}
                                :
                                {{match.results[0].away_g4_score | nullToDash}}
                            </div>
                            <div class="flex v-column item-center" style="flex-basis: 40px; font-size: 11px;">
                                {{match.results[0].home_g5_score | nullToDash}}
                                :
                                {{match.results[0].away_g5_score | nullToDash}}
                            </div>
                            <div class="flex v-column item-center" style="flex-basis: 110px;">
                                <div v-if="match.is_refunded">
                                    Refunded
                                </div>
                                <div v-if="!match.is_refunded">
                                    {{match.results[0].home_total_point_win | nullToDash}}
                                    &nbsp;:&nbsp;
                                    {{match.results[0].away_total_point_win | nullToDash}}
                                </div>
                            </div>
                            <div class="flex v-column item-center" style="flex-basis: 110px;">
                                <div v-if="match.is_refunded">
                                    Refunded
                                </div>
                                <div v-if="!match.is_refunded">
                                    {{match.results[0].home_total_set_win | nullToDash}}
                                    &nbsp;:&nbsp;
                                    {{match.results[0].away_total_set_win | nullToDash}}
                                </div>
                            </div>
                            <div class="flex v-column" style="flex: 1; border: none;"></div>
                        </div>
                        <div v-if="sport_type === 'football' || sport_type === 'basketball'" class="flex result-row" :class="{'highlight': index%2 === 1}">
                            <div class="flex v-column" style="flex-basis: 130px; font-size: 12px;">{{match.kickoff_time}}</div>
                            <div class="flex v-column" style="flex-basis: 410px;">
                                {{match.team_home_name_en}} - vs - {{match.team_away_name_en}}
                            </div>
                            <div class="flex v-column item-center" style="flex-basis: 110px;">
                                <div v-if="match.is_refunded">
                                    Refunded
                                </div>
                                <div v-if="!match.is_refunded">
                                    {{match.results[0].home_first_half_score | nullToDash}}
                                    &nbsp;:&nbsp;
                                    {{match.results[0].away_first_half_score | nullToDash}}
                                </div>
                            </div>
                            <div class="flex v-column item-center" style="flex-basis: 110px;">
                                <div v-if="match.is_refunded">
                                    Refunded
                                </div>
                                <div v-if="!match.is_refunded">
                                    {{match.results[0].home_full_time_score | nullToDash}}
                                    &nbsp;:&nbsp;
                                    {{match.results[0].away_full_time_score | nullToDash}}
                                </div>
                            </div>
                            <div class="flex v-column" style="flex: 1; border: none;"></div>
                        </div>
                        <div v-if="sport_type === 'muaythai' || sport_type === 'boxing'" class="flex result-row" style="height: auto;" :class="{'highlight': index%2 === 1}">
                            <div class="flex v-column" style="flex-basis: 130px; font-size: 12px;">{{match.kickoff_time}}</div>
                            <div class="flex-column item-center v-column" style="flex-basis: 220px; align-items: start;">
                                <div style="text-align: left; margin: 4px 4px 4px 0;">{{match.team_home_name_en}}</div>
                                <div style="text-align: left; margin: 4px 4px 4px 0;">{{match.team_away_name_en}}</div>
                            </div>
                            <div class="flex v-column item-center" style="flex-basis: 220px;">
                                <div v-if="match.is_refunded">
                                    Refunded
                                </div>
                                <div v-if="!match.is_refunded">
                                    {{match.results[0].winner}}
                                </div>
                            </div>
                            <div class="flex v-column item-center" style="flex-basis: 150px;">
                                <div v-if="match.is_refunded">
                                    Refunded
                                </div>
                                <div v-if="!match.is_refunded">
                                    {{match.results[0].roundval}}
                                </div>
                            </div>
                            <div class="flex v-column" style="flex: 1; border: none;"></div>
                        </div>
                    </div>
                </div>
            </div>
        </div>

    </div>

</template>

<script>

    import moment from 'moment'
    import Datepicker from 'vuejs-datepicker';
    import axiosWrapper from '@/axiosWrapper'

    export default {
        name: "Result",
        components: {
            Datepicker
        },
        data() {
            return {
                sports: [
                    {
                        value: 'football',
                        name: 'ฟุตบอล',
                    },
                    {
                        value: 'basketball',
                        name: 'บาสเก็ตบอล',
                    },
                    {
                        value: 'volleyball',
                        name: 'วอลเลย์บอล',
                    },
                    {
                        value: 'muaythai',
                        name: 'มวยไทย',
                    },
                    {
                        value: 'tennis',
                        name: 'เทนนิส',
                    },
                    {
                        value: 'boxing',
                        name: 'มวยสากล',
                    },
                    {
                        value: 'badminton',
                        name: 'แบดมินตัน',
                    },
                ],
                startdate: moment().subtract(1, 'd'),
                sport_type: 'football',
                select_league: '',
                data_list: [],
                isLoading: false
            }
        },
        computed: {
            paramsChanged: function(){
                this.getData(this.startdate, this.sport_type)
                return "";
            },
            momentToDate: function(){
                return this.startdate.toDate();
            }
        },
        methods: {
            selectDate: function(startdate){

                if(startdate === 'today'){
                    this.startdate = moment()
                }else if(startdate === 'yesterday'){
                    this.startdate = moment().subtract(1, 'd')
                }else{
                    this.startdate = startdate;
                }
            },
            selectDatePicker: function(date){
                this.startdate = moment(date);
            },
            getData: async function(startdate, sport_type){
                this.isLoading = true;
                this.data_list = [];
                this.select_league = '';
                let result_res = await axiosWrapper({
                    method: 'GET',
                    url: `${this.envDomains.VUE_APP_123API}/result/by-date?startdate=${startdate.format('YYYYMMDD')}&sport_type=${sport_type}`
                });
                if(result_res.data){
                    this.data_list = result_res.data.data;
                }else{
                    console.log('getData err', result_res);
                }
                this.isLoading = false;
            }
        }
    }
</script>

<style scoped>
    .table-header {
        background-color: #121e29;
        color: white;
        height: 48px;
        font-size: 14px;
        font-weight: bold;
    }
    .table-header .v-column{
        margin: 0 0 0 4px;
        border-right: solid 1px lightgray;
        height: 100%;
        align-items: center;
    }
    .league-label {
        background-color: #182d38;
        color: white;
        font-weight: bold;
        font-size: 12px;
        padding: 4px;
    }
    .result-row{
        font-size: 12px;
        height: 30px;
    }
    .result-row .v-column{
        margin: 0 0 0 4px;
        border-right: solid 1px lightgray;
        height: auto;
        align-items: center;
    }
    .result-row.highlight{
        background-color: #f2f2f2;
    }

    .blink_blink {
        animation: blinker 1s linear infinite;
    }
    @keyframes blinker {
        50% {
            opacity: 0;
        }
    }

</style>