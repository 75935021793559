<template>
    <div class="PageYeekee container container-fluid">
        <div class="w-100 row-menu-grid align-self-stretch">
            <div class="nav-top">
                <div class="col-nav">
                    <button @click="go_back" class="btn ">
                        <i class="fas fa-times"></i>
                    </button>
                </div>
                <h2>หวยไฮเทคทั้งหมด</h2>
            </div>
            <div class="wrap-content-scroll">
                <div class="row">
                    <div v-show="round_list_sort.length === 0" class="yeekee-loading">
                        <Loading mode="spinner"></Loading>
                    </div>
                    <transition name="fade">
                        <div v-show="round_list_sort.length > 0" class="row row-mainMenu-yeekee">
                            <div v-for="(round, i) of round_list_sort" :key="i" class="col-menu">
                                <router-link event="" to="#">
                                    <div @click="go_bet(round.id)" :class="{'lotto-closed': !round.is_round_open, 'active': i === 1}" class="lotto-card border-yeekee">
                                        <span class="title">รอบที่</span>
                                        <div class="ribbon-lotto"><strong>{{round.sub_round}}</strong></div>
                                        <div class="lotto-head lotto-yeekee">
                                            <span class="badge">{{round.description}}</span>
                                        </div>
                                        <div class="lotto-time time-government">
                                            <span v-if="round.is_round_open" class="countdown"><Alarm @timeout="load_round_list" :start="round.timeout"/></span>
                                            <span v-else class="countdown">ยังไม่รับแทง</span>
                                        </div>
                                    </div>
                                </router-link>
                            </div>
                        </div>
                    </transition>

                </div>
            </div>

        </div>
        <b-modal id="YeekeeDetailModal" size="lg" title="รายละเอียดผล หวยไฮเทค" hide-footer>
            <YeekeeDetail :round_id="selected_round_id"/>
        </b-modal>
    </div>
</template>

<script>

    import axios_wrapper from '@/axiosWrapper'
    import Alarm from "./Alarm";
    import YeekeeDetail from "./YeekeeDetail";
    import Loading from "@/components/Loading";
    import store from '@/store'

    export default {
        name: "PageYeekee",
        components: {
            Loading, 
            Alarm, 
            YeekeeDetail,
        },
        computed: {
            round_list_sort: function(){

                if(this.round_list.length > 0){

                    const extracted = this.round_list.map((o) => {
                        o.is_round_open = this.is_round_open(o.status, o.timeout);
                        return o;
                    });
                    const open_round = extracted.filter((o) => {
                        return o.is_round_open;
                    });
                    const close_round = extracted.filter((o) => {
                        return !o.is_round_open;
                    });

                    const result = open_round.concat(close_round);

                    if(close_round.length > 0){
                        const latest_closed = close_round.pop();
                        result.splice(0, 0, latest_closed);
                    }

                    return result;
                }else{
                    return [];
                }
            },
        },
        data(){
            return {
                round_list: [],
                selected_round_id: null,
            }
        },
        methods: {
            load_round_list: async function(){

                const res = await axios_wrapper({
                    method: 'POST',
                    url: `${this.envDomains.VUE_APP_LOTTO_API}/api/round/get_yeekee_round`
                });
                if(res.status === 200){
                    this.round_list = res.data;
                }else{
                    this.$bvToast.toast('ไม่สามารถโหลดข้อมูลหวย', {title: 'แจ้งเตือน'});
                }
            },
            is_round_open: function (status, timeout_str) {
                const now = this.$moment();
                const timeout = this.$moment(timeout_str);
                return (status === 'open' && now.isSameOrBefore(timeout));
            },
            go_bet: function(id){
                store.commit('lotto/updatePageBetQueryLottoType', '')
                store.commit('lotto/updatePageBetQueryRoundId', id)
                this.go_back()
            },
            go_back: function(){
                this.$emit('close')
            },
            showYeekeeDetail: function(round_id){
                if(round_id){
                    this.selected_round_id = round_id;
                    this.$bvModal.show('YeekeeDetailModal');
                }
            },
        },
        mounted() {
            this.load_round_list();
        }
    }
</script>
<style>
    @media only screen
    and (min-device-width: 1300px)
    and (orientation: landscape) {
        .PageBet-yeekee .v--modal,
        .PageBet-yeekee .v--modal .BillDetail{
            width: 375px!important;
            position: fixed;
            left: 0!important;
            right: 0;
            margin: auto;
        }
        .PageYeekee .wrap-content-scroll{
            position: fixed;
            height: calc(100vh - 60px);
            overflow-y: scroll;
            overflow-x: hidden;
            width: 375px;
        }
    }
</style>
<style scoped>
    @media screen and (max-width: 768px) {
        .PageYeekee {
            min-height: 100vh;
        }
    }
    @media screen and (min-width: 769px) {
        .PageYeekee {
            min-height: calc(100vh - 64px);
        }
    }

    .yeekee-loading {
        display: flex;
        justify-content: center;
        align-items: center;
        height: 100vh;
        width: 100%;
    }
</style>