import { render, staticRenderFns } from "./YeekeeGuess.vue?vue&type=template&id=b94748e0&scoped=true&"
import script from "./YeekeeGuess.vue?vue&type=script&lang=js&"
export * from "./YeekeeGuess.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "b94748e0",
  null
  
)

export default component.exports