<template>
    <div class="BaccaratStatMain">
        <div v-for="(box, key) of boxArr" :key="key" class="baccarat-stat-item">
            <Mark
            :result="getResult(key)"
            :isBankerPair="getBankerPair(key)"
            :isPlayerPair="getPlayerPair(key)">
            </Mark>
        </div>
    </div>
</template>
<script>

import Mark from './StatMark'
import {GAME_RESULT_KEY} from '@/components/game/Constant'
const MAX_MARK_LENGTH = 84
const MAX_ROW = 6
const COL_WIDTH = 22

export default {
    name: 'BaccaratStatMain',
    props: [
        'statArr',
        'dataMaxRow',
    ],
    components: {
        Mark,
    },
    data() {
        return {
            boxArr: [],
        }
    },
    computed: {
        statArrFilter() {
            return this.statArr.filter((stat) => {
                return !(parseInt(stat.game_result) === GAME_RESULT_KEY.WAIT || parseInt(stat.game_result) === GAME_RESULT_KEY.CANCEL)
            })
        },
        maxRow() {
            return (this.dataMaxRow) ? this.dataMaxRow : MAX_ROW
        },
        statArrLength() {
            return this.statArrFilter.length
        },
        statArrColCount() {
            return Math.ceil(this.statArrLength / this.maxRow)  
        },
        existStatWidth() {
            return parseInt(this.statArrColCount * COL_WIDTH)
        },
    },
    watch: {
        existStatWidth(newVal) {
            if(newVal) {
                this.$emit('existStatWidthChange', {
                    existStatWidth: newVal, 
                    colWidth: COL_WIDTH,
                })
            }
        },
    },
    methods: {
        getStat(i) {
            if(this.statArrFilter.length <= 0) {
                return null
            }
            return this.statArrFilter[i]
        },
        getResult(i) {
            const stat = this.getStat(i)
            if(!stat){
                return ''
            }
            return stat.game_result
        },
        getBankerPair(i) {
            const stat = this.getStat(i)
            if(!stat){
                return ''
            }
            return stat.is_banker_pair
        },
        getPlayerPair(i) {
            const stat = this.getStat(i)
            if(!stat){
                return ''
            }
            return stat.is_player_pair
        }
    },
    created() {
        for(let i = 0;i < MAX_MARK_LENGTH;i++) {
            this.boxArr.push(i)
        }
    },
    mounted() {
        setTimeout(() => {
            this.$emit('existStatWidthChange', {
                existStatWidth: this.existStatWidth, 
                colWidth: COL_WIDTH,
            })
        }, 1000)
    }
}
</script>
<style scoped>
.BaccaratStatMain {
    display: flex;
    flex-direction: column;
    flex-wrap: wrap;
}
.baccarat-stat-item {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 22px;
    width: 22px;
    border-collapse: collapse;
    border: dotted 1px #ddd;
}
</style>