<template>
  <div
    class="containerGameList"
    @scroll="onScrollHandle"
    ref="contentWrapper"
    style="overflow-y: auto; height: calc(100vh - 153px)"
  >
    <div class="containerGameListinfo">
      <div
        class="GameListInfoFeature"
        v-if="popularList.length > 0 && providerInfo"
      >
        <div class="GameListInfoPopular">
          <div class="GameListInfoTopic">
            <i class="fas fa-fire"></i>
            <h3>ยอดนิยม</h3>
          </div>
          <nav class="nav-menu">
            <div class="nav nav-pills">
              <carousel
                :per-page="
                  providerInfo['perPage'] ? providerInfo['perPage'] : 2.2
                "
                :per-page-custom="providerInfo['perPageCustom']"
                v-bind:loop="true"
                :mouseDrag="true"
                :navigationEnabled="true"
                :paginationEnabled="false"
                navigationNextLabel="❯"
                navigationPrevLabel="❮"
              >
                <Slide v-for="(game, key) of popularList" :key="key">
                  <div class="nav-item" @click="play(game)">
                    <div class="nav-item-info">
                      <div class="overlay"></div>
                      <a href="#">
                        <el-image :src="getGameImage(game)">
                          <el-image slot="error" :src="game.Image1">
                            <div class="noneImageSlot" slot="error">
                              <i class="fas fa-image"></i>
                            </div>
                          </el-image>
                        </el-image>
                      </a>
                    </div>
                    <div class="-text-nav-menu">
                      {{ game.name ? game.name : game.Name }}
                    </div>
                  </div>
                </Slide>
              </carousel>
            </div>
          </nav>
          <div class="GameListInfoPopularBG"></div>
        </div>
        <div class="GameListInfoGuide">
          <div class="GameListInfoTopic">
            <i class="fas fa-star"></i>
            <h3>เกมแนะนำ</h3>
          </div>
          <nav class="nav-menu">
            <div class="nav nav-pills">
              <carousel
                :per-page="
                  providerInfo['perPage'] ? providerInfo['perPage'] : 2.2
                "
                :per-page-custom="[
                  [768, 2.2],
                  [1400, 6],
                ]"
                :mouseDrag="true"
                :navigationEnabled="true"
                :paginationEnabled="false"
                navigationNextLabel="❯"
                navigationPrevLabel="❮"
              >
                <Slide v-for="(game, key) of popularList" :key="key">
                  <div class="nav-item" @click="play(game)">
                    <div class="nav-item-info">
                      <div class="overlay"></div>
                      <a href="#">
                        <el-image :src="getGameImage(game)">
                          <el-image slot="error" :src="game.Image1">
                            <div class="noneImageSlot" slot="error">
                              <i class="fas fa-image"></i>
                            </div>
                          </el-image>
                        </el-image>
                      </a>
                    </div>
                    <div class="-text-nav-menu">
                      {{ game.name ? game.name : game.Name }}
                    </div>
                  </div>
                </Slide>
              </carousel>
            </div>
          </nav>
        </div>
      </div>

      <div class="GameListInfoAllGame">
        <div class="GameListInfoTopic">
          <i class="fas fa-gamepad"></i>
          <h3>เกมทั้งหมด</h3>
        </div>
        <nav class="nav-menu">
          <ul class="nav nav-pills">
            <li
              class="nav-item"
              v-for="(game, key) of list ? list : gameList"
              :key="key"
            >
              <div class="nav-item-info" @click="play(game)">
                <div class="overlay"></div>
                <a href="#">
                  <el-image :src="getGameImage(game)">
                    <el-image
                      slot="error"
                      :src="game.Image1 ? game.Image1 : game.img"
                    >
                      <div class="noneImageSlot" slot="error">
                        <i class="fas fa-image"></i>
                      </div>
                    </el-image>
                  </el-image>
                </a>
              </div>

              <div class="-text-nav-menu">
                {{ game.name ? game.name : game.Name }}
              </div>
            </li>
          </ul>
        </nav>
      </div>
    </div>
    <div class="load-more-section">
      <Loading v-if="isOnLoad" mode="spinner"></Loading>
      <button
        v-else-if="isCanLoadMore"
        @click="loadMore"
        type="button"
        class="btn btnLoadMore"
      ></button>
    </div>
  </div>
</template>
<script>
import { Carousel, Slide } from "vue-carousel";
import store from "@/store";
import localStorageCache from "@/lib/localStorageCache";
import axiosWrapper from "@/axiosWrapper";
import Loading from "@/components/Loading";
import { ALL_PROVIDER_LIST } from "./gamelist/Constant";
import GameListMixin from "@/components/gamelist/GameListMixin";
const AT_BOTTOM_OFFSET = 600;
export default {
  name: "ListAllGame2",
  props: ["list", "provider", "cat", "tag", "keyword", "perPage"],
  mixins: [GameListMixin],
  components: {
    Carousel,
    Slide,
    Loading,
  },
  data() {
    return {
      page: 1,
      itemsPerPage: 15,
      gameList: [],
      popularList: [],
      recommendList: [],
      isOnLoad: false,

      isCanLoadMore: false,
      providerInfo: null,
    };
  },
  computed: {
    menuBarNavigation: {
      get() {
        return store.state.menuBarNavigation;
      },
      set(value) {
        store.commit("updateMenuBarNavigation", value);
      },
    },
  },
  watch: {
    async currentTag(newVal) {
      if (newVal) {
        this.load();
      }
    },
    keyword(newVal) {
      newVal;
      this.load();
    },
  },
  methods: {
    getGameImage(game) {
      return `${this.envDomains.VUE_APP_API}/images/gameSlot/${game.ApiName}_${game.GameCode}.png`;
    },
    async getGameList(_tag) {
      this.isOnLoad = true;

      const provider = this.provider != "FISHING" ? this.provider : null;
      const cat = this.cat;
      const page = this.page;
      const tag = _tag ? _tag : this.tag;
      const itemsPerPage = this.itemsPerPage;
      const keyword = this.keyword;

      const localStorageCacheKey = `GameList;${provider};${cat};${page};${itemsPerPage};${tag};${keyword}`;

      const res = await axiosWrapper({
        method: "post",
        url: `${this.envDomains.VUE_APP_API}/api/gamelist-api/game`,
        data: {
          page: page,
          itemsPerPage: itemsPerPage,
          provider: provider,
          cat: cat,
          tag: tag,
          keyword: keyword,
        },
      });

      this.isOnLoad = false;

      if (res.status !== 200) {
        this.$bvToast.toast(res.data.message, {
          title: "แจ้งเตือน",
          toaster: "b-toaster-top-full",
        });
        return null;
      }
      this.isCanLoadMore = !!res.data.next;

      if (res.data.items.length > 0) {
        localStorageCache.set(localStorageCacheKey, res.data.items, 1);
      }
      return res.data.items;
    },
    async load() {
      this.gameList = [];
      this.page = 1;
      const gameList = await this.getGameList();
      if (gameList) {
        this.gameList = gameList;
      }
    },
    async loadMore() {
      if (this.isOnLoad || !this.isCanLoadMore) {
        return;
      }
      this.page++;
      const gameList = await this.getGameList();
      if (gameList) {
        this.gameList = this.gameList.concat(gameList);
      }
    },
    onScrollHandle() {
      const contentWrapper = this.$refs.contentWrapper;
      if (
        window.innerHeight + contentWrapper.scrollTop + AT_BOTTOM_OFFSET >=
        contentWrapper.scrollHeight
      ) {
        this.loadMore();
      }
    },
    selectTag(tag) {
      this.currentTag = tag;
    },
    closeGameListModal() {
      this.menuBarNavigation.provider = null;
      this.$emit("close");
    },
    getProviderIcon(provider) {
      const providerInfo = ALL_PROVIDER_LIST.find((item) => {
        return item.slug === provider.slug;
      });
      if (!providerInfo) {
        return "";
      }
      return providerInfo.image;
    },
    getProviderInfo(provider) {
      const providerInfo = ALL_PROVIDER_LIST.find((item) => {
        // console.log(item.slug.toUpperCase(), provider.toUpperCase())
        return item.slug.toUpperCase() === provider.toUpperCase();
      });
      // console.log(providerInfo);
      return providerInfo?providerInfo: {
          slug: null,
          cat: "FISHING",
          label: "ยิงปลา",
          image: "",
          wrapperClass: "GameList-box-wide",
        }
    },
    play(game) {
      if (!this.is_member) {
        this.notifyToSignUp("เพื่อเล่น");
        return;
      }
      var params = [];
      let openNewTab = false;
      if (game.ApiName == "amb") {
        params.push("productId=" + game.Provider);
        params.push("gameId=" + game.GameCode);
        if (game.Provider.toLowerCase() == "sagame") {
          openNewTab = true;
        }
      } else if (game.ApiName == "qt") {
        params.push("gameId=" + game.GameCode);
      } else if (game.ApiName == "pragmatic") {
        params.push("code=" + game.GameCode);
      } else if (game.service == "sa") {
        params.push("code=" + game.code);
        openNewTab = true;
      }
      if (this.appImageProfile) {
        params.push("logo_img=" + this.appImageProfile);
      }
      const url = `${
        this.envDomains.VUE_APP_PES_BASE_URL
      }/game/casinoonline/sa_sexy_redirect.php?mode=mobile&service=${
        game.ApiName
      }&${params.join("&")}&token=${this.token}`;
      if (openNewTab == false) {
        this.$router.push({
          name: "GamePlayFrame",
          query: { url: encodeURIComponent(url) },
        });
      } else {
        window.location.href = url;
      }
    },
  },
  async mounted() {
    this.providerInfo = this.getProviderInfo(this.provider);
    if (!this.list) {
      this.popularList = await this.getGameList("HOT");
      this.recommendList = await this.getGameList("RECOMMEND");
    }
    if (this.providerInfo) {
      if (!this.providerInfo.perPageCustom) {
        this.providerInfo.perPageCustom = [
          [768, 2.2],
          [1400, 6],
        ];
      }
    }else{
        this.providerInfo.perPageCustom = [
          [768, 2.2],
          [1400, 6],
        ];
    }

    this.menuBarNavigation.provider = this.provider;

    // console.log(this.providerInfo)
    // this.getHot()
    // this.getRecommend()
    this.load();
  },
};
</script>
<style>
.wrapContentIndexCasino .active .itemList li.item.itemCasino {
  background-color: white;
  border: solid 1px rgba(0, 0, 0, 0.84);
  box-shadow: 0 5px 10px rgba(0, 0, 0, 0.3);
}
.wrapContentIndexCasino .active .itemList li.item.itemCasino p,
.wrapContentIndexCasino .active .itemList li.item.itemCasino span {
  color: black;
}
</style>
