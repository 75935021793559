<script>

import {mixin as VueTimers} from 'vue-timers'

export default {
    name: 'AlarmMixin',
    mixins: [VueTimers],
    timers: {
        count_down: {
            time: 1000,
            repeat: true
        },
        refresh: {
            time: 60*1000,
            repeat: true,
        }
    },
    props: [
        'start', 'extra_minutes', 'display_mode', 'show_on_timeout'
    ],
    data(){
        return {
            remain: this.$moment.duration(0, 'seconds')
        }
    },
    watch: {
        start: function(){
            this.$timer.stop('refresh');
            this.$timer.start('refresh');
            this.init_time();
        },
    },
    computed: {
        day: function(){
            return this.remain.get('day');
        },
        hour: function(){
            return this.remain.get('hour');
        },
        minute: function(){
            return this.remain.get('minute');
        },
        second: function(){
            return this.remain.get('second');
        },
    },
    filters: {
        doubleZero: function(value){
            if(value.toString().length === 1){
                return `0${value}`;
            }else{
                return value;
            }
        }
    },
    created() {
        this.$timer.stop('refresh');
        this.$timer.start('refresh');
        this.init_time();
    },
    methods: {
        init_time: function(){

            this.$timer.stop('count_down');

            let start_obj = (this.start) ? this.$moment(this.start) : this.$moment();

            if(this.extra_minutes){
                start_obj.add(this.extra_minutes, 'minutes');
            }

            let current_obj = this.$moment();

            this.remain = this.$moment.duration(start_obj.diff(current_obj));

            if(this.remain.as('second') > 0) {
                this.$timer.start('count_down');
            }
        },
        count_down: function(){
            if(this.remain.as('second') > 0){
                this.remain.subtract(1, 'seconds');
                this.$emit('countDown', this.remain.as('minute'))
            }else {
                this.$timer.stop('count_down');
                this.$timer.stop('refresh');
                this.$emit('timeout');
            }
        },
        refresh(){
            this.init_time();
        }
    }
}
</script>