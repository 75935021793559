import Vue from 'vue'
import VueRouter from 'vue-router'
// import Home from '../views/Home.vue'
import SuperAdmin from '../views/super-admin/Admin.vue'
import SuperAdminLogin from '../views/super-admin/Login.vue'
import SuperAdminAdmin from '../views/super-admin/AdminAdmin.vue'
import SuperAdminSetting from '../views/super-admin/Setting.vue'
import Admin from '../views/admin/Admin.vue'
import AdminLogin from '../views/admin/Login.vue'
import AdminUser from '../views/admin/User.vue'
import AdminMessage from '../views/admin/Message.vue'
import AdminBot from '../views/admin/Bot.vue'
import AdminTools from '../views/admin/Tools.vue'
import AdminStreamer from '../views/admin/Streamer.vue'
import Baccarat from '../views/Baccarat'
import MiniSicbo from '../views/MiniSicbo.vue'
import MiniFantan from '../views/MiniFantan.vue'
import MiniDragonTiger from '../views/MiniDragonTiger.vue'
import Baccarat2 from '../views/Baccarat2'
import Baccarat3 from '../views/Baccarat3'
import LiffGame from '../views/liff-game/Home.vue'
import LineLogin from '../views/LineLogin.vue'
import LineSync from '../views/LineSync.vue'
import Package from '../views/Package'
import GameType from '../views/GameType.vue'
import GameLobby from '../views/GameLobby.vue'
import BaccaratFull from '../views/BaccaratFull.vue'
// import Unbox from '../views/Unbox.vue'
// import Football from '../views/Football.vue'
// import LottoHome from '../views/lotto/Home.vue'
import LottoBet from '../views/lotto/Bet.vue'
import Profile from '../views/Profile.vue'
import Auth from '../views/Auth.vue'
import ChatRoom from '../views/ChatRoom.vue'
import ChatRoomIndex from '../views/ChatRoomList.vue'
import GamePlayFrame from '../views/GamePlayFrame.vue'

// import Embed from '../views/Embed.vue'
import store from '@/store'
import {TABLE_TYPE_KEY} from '@/components/game/Constant'
// import MainChat from "../views/MainChat";

import IndexCasino from '../views/IndexCasino.vue'
import IndexPopular from '../views/IndexPopular.vue'
import IndexSlot from '../views/IndexSlot.vue'
import IndexFishing from '../views/IndexFishing.vue'
import IndexLotto from '../views/IndexLotto.vue'
import IndexSport from '../views/IndexSport.vue'
import IndexUnbox from '../views/IndexUnbox.vue'

import DiamondMode from '../views/DiamondMode.vue'

import AdminGameChatBot from '../line-chat-bot/view/admin/GameChatBot.vue'
import PromotionPlay from '../views/PromotionPlay.vue'
import TestCaptcha from '../views/TestCaptcha.vue'
import GameScoreboard from '../views/GameScoreboard.vue'

Vue.use(VueRouter)

const isMember = () => {
	return !!localStorage.getItem('token')
}

const isMemberMiddleWare = (to, from, next) => {
	if(!isMember()){
		next({name: 'Auth', query: to.query})
	}else{
		next();
	}
}

const isGuessMiddleWare = (to, from, next) => {
	if(isMember()){
		next({name: 'Profile', query: to.query})
	}else{
		next();
	}
}

const isAdminMiddleWare = (to, from, next) => {
	if(!isAdmin() && to.name !== 'AdminLogin'){
		next({
			name: 'AdminLogin',
			query: {
				redirect_url: encodeURIComponent(to.fullPath),
			}
		})
	}else{
		next()
	}
}
const menu_left_setting= localStorage.getItem('menu_left_setting')? JSON.parse(localStorage.getItem('menu_left_setting')) :null;

const IndexHome = menu_left_setting? menu_left_setting['menu1']['id']   : 'popular';
let IndexPage;
if(IndexHome=='popular'){
	IndexPage = IndexPopular
}else if(IndexHome=='casino'){
	IndexPage = IndexCasino
}else if(IndexHome=='slot'){
	IndexPage = IndexSlot
}else if(IndexHome=='fishing'){
	IndexPage = IndexFishing
}else if(IndexHome=='sport'){
	IndexPage  = IndexSport
}else if(IndexHome=='lotto'){
	IndexPage = IndexLotto
}else if(IndexHome=='unbox'){
	IndexPage = IndexUnbox
}

const routes = [
	{
		path:'/',
		name:'Index',
		component : IndexPage
	},
	{

		path: '/IndexPopular',
		name: 'IndexPopular',
		component: () => import('../views/IndexPopular.vue')
	},
	{
		path: '/chatroomList',
		name: 'ChatRoomIndex',
		component: ChatRoomIndex
	},
	{
		path: '/chatroom',
		name: 'ChatRoom',
		component: ChatRoom
	},
	// {
	// 	path: '/embed',
	// 	name: 'Embed',
	// 	component: Embed
	// },
	{
		path: '/football',
		name: 'Football',
		// component: Football
		component: () => import('../views/IndexSport.vue')
	},
	{
		beforeEnter: isGuessMiddleWare,
		path: '/auth',
		name: 'Auth',
		component: Auth
	},
	{
		path: '/game-type',
		name: 'GameType',
		component: GameType
	},
	{
		path: '/game-lobby',
		name: 'GameLobby',
		component: GameLobby
	},
    {
        path: '/list-game',
        name: 'ListGame',
        component: () => import('../views/ListGame.vue')
    },
    {
		beforeEnter: isMemberMiddleWare,
        path: '/profile',
        name: 'Profile',
        component: Profile
	},
	{
        path: '/package',
        name: 'Package',
        component: Package
    },
	{
        path: '/about',
        name: 'About',
        component: () => import('../views/About.vue')
    },
    {
		// beforeEnter: isMemberMiddleWare,
        path: '/unbox',
        name: 'Unbox',
        // component: Unbox
		component: () => import('../views/IndexUnbox.vue')
    },
    {
		// beforeEnter: isMemberMiddleWare,
        path: '/unbox-play/:id',
        name: 'UnboxPlay',
        component: () => import('../views/UnboxPlay.vue')
	},
    {
        path: '/spin-circle/:id',
        name: 'SpinCircle',
        component: () => import('../views/SpinCircle.vue')
    },
    {
        path: '/fruit-slot',
        name: 'FruitSlot',
        component: () => import('../views/FruitSlot.vue')
    },
	{
        path: '/mari-slot',
        name: 'MariSlot',
        component: () => import('../views/MariSlot.vue')
    },
	{
        path: '/mini-baccarat',
        name: 'Baccarat',
		component: Baccarat,
		meta: {
			table_type: TABLE_TYPE_KEY.baccarat
		},
	},
    {
        path: '/mini-sicbo',
        name: 'MiniSicbo',
		component: MiniSicbo,
		meta: {
			table_type: TABLE_TYPE_KEY.sicbo
		},
    },
    {
        path: '/mini-fantan',
        name: 'MiniFantan',
		component: MiniFantan,
		meta: {
			table_type: TABLE_TYPE_KEY.bean
		},
    },
    {
        path: '/mini-dragontiger',
        name: 'MiniDragonTiger',
		component: MiniDragonTiger,
		meta: {
			table_type: TABLE_TYPE_KEY.dragontiger
		},
	},
	{
        path: '/mini-baccarat2',
        name: 'Baccarat2',
		component: Baccarat2,
		meta: {
			table_type: TABLE_TYPE_KEY.baccarat2
		},
	},
	{
        path: '/mini-baccarat3',
        name: 'Baccarat3',
		component: Baccarat3,
		meta: {
			table_type: TABLE_TYPE_KEY.baccarat3
		},
	},
	{
		path: '/baccaratFull',
		name: 'BaccaratFull',
		component: BaccaratFull,
	},
	{
        path: '/liff/game',
        name: 'LiffGame',
        component: LiffGame,
    },
	{
        path: '/line/login',
        name: 'LineLogin',
        component: LineLogin,
    },
	{
        path: '/line/sync',
        name: 'LineSync',
        component: LineSync,
    },
	{
		path: '/IndexCasino',
		name: 'IndexCasino',
		component: IndexCasino
	},
	{
		path: '/IndexPopular',
		name: 'IndexPopular',
		component: IndexPopular
	},
	{
		path: '/IndexSlot',
		name: 'IndexSlot',
		component: IndexSlot
	},
	{
		path: '/IndexFishing',
		name: 'IndexFishing',
		component: IndexFishing
	},
	{
		path: '/IndexLotto',
		name: 'IndexLotto',
		component: IndexLotto
	},
	{
		path: '/IndexSport',
		name: 'IndexSport',
		component: IndexSport
	},
	{
		path: '/IndexUnbox',
		name: 'IndexUnbox',
		component: IndexUnbox
	},
	{
		path: '/diamondMode',
		name: 'DiamondMode',
		component: DiamondMode
	},
	{
        path: '/live-stream',
        name: 'LiveStream',
        component: () => import('../views/LiveStream.vue')
    },
    {
		beforeEnter: isGuessMiddleWare,
        path: '/promotionPlay',
        name: 'PromotionPlay',
        component: PromotionPlay,
    },
	{
		path: '/liveRoom',
		name: 'LiveRoom',
		component: () => import('../views/LiveRoom.vue')
	},
    {
		beforeEnter: isMemberMiddleWare,
        path: '/statement',
        name: 'Statement',
        component: () => import('../views/Statement.vue')
    },
	{
		beforeEnter: isMemberMiddleWare,
        path: '/account',
        name: 'Account',
        component: () => import('../views/Account.vue')
	},
	{
		beforeEnter: isMemberMiddleWare,
        path: '/deposit-withdraw',
        name: 'DepositWithdraw',
        component: () => import('../views/DepositWithdraw.vue')
	},
	{
        path: '/lotto',
        name: 'LottoHome',
        // component: LottoHome
		component: () => import('../views/IndexLotto.vue')
	},
	{
        path: '/lotto/bet',
        name: 'LottoBet',
        component: LottoBet
	},
	{
		beforeEnter: isMemberMiddleWare,
        path: '/lotto/bill',
        name: 'LottoBill',
        component: () => import('../views/lotto/Bill.vue')
	},
	{
        path: '/lotto/result',
        name: 'LottoResult',
        component: () => import('../views/lotto/Result.vue')
    },
	{
		path: '/test-captcha',
        name: 'TestCaptcha',
        component: TestCaptcha,
	},
	{
		path: '/game-scoreboard',
        name: 'GameScoreboard',
        component: GameScoreboard,
	},
	{
		path: '/game-playFrame',
		name: 'GamePlayFrame',
		component: GamePlayFrame,
	},
	{
		path: '/super-admin-login',
		name: 'SuperAdminLogin',
		component: SuperAdminLogin,
		meta: {
			group: 'admin'
		}
	},
	{
		path: '/super-admin',
		name: 'SuperAdmin',
		component: SuperAdmin,
		meta: {
			group: 'admin'
		},
		beforeEnter: (to, from, next) => {
            if(!isSuperAdmin() && to.name !== 'SuperAdminLogin'){
                next({
					name: 'SuperAdminLogin'
				})
            }else{
                next()
            }
        },
		redirect: '/super-admin/admin',
		children: [
			{
				path: '/super-admin/admin',
				name: 'SuperAdminAdmin',
				component: SuperAdminAdmin,
				meta: {
					group: 'admin'
				}
			},
			{
				path: '/super-admin/setting',
				name: 'SuperAdminSetting',
				component: SuperAdminSetting,
				meta: {
					group: 'admin'
				}
			},
		]
	},
	{
		path: '/admin-login',
		name: 'AdminLogin',
		component: AdminLogin,
		meta: {
			group: 'admin'
		}
	},
	{
		path: '/admin',
		name: 'Admin',
		component: Admin,
		meta: {
			group: 'admin'
		},
		beforeEnter: isAdminMiddleWare,
		redirect: '/admin/user',
		children: [
			{
				path: '/admin/user',
				name: 'AdminUser',
				component: AdminUser,
				meta: {
					group: 'admin'
				}
			},
			{
				path: '/admin/message',
				name: 'AdminMessage',
				component: AdminMessage,
				meta: {
					group: 'admin'
				}
			},
			{
				path: '/admin/bot',
				name: 'AdminBot',
				component: AdminBot,
				meta: {
					group: 'admin'
				}
			},
			{
				path: '/admin/game-chat-bot',
				name: 'AdminGameChatBot',
				component: AdminGameChatBot,
				meta: {
					group: 'admin'
				}
			},
			{
				path: '/admin/tools',
				name: 'AdminTools',
				component: AdminTools,
				meta: {
					group: 'admin'
				}
			},
			{
				path: '/admin/streamer',
				name: 'AdminStreamer',
				component: AdminStreamer,
				meta: {
					group: 'admin'
				}
			},

		]
	},
]

const isSuperAdmin = function(){
    return store.state.super_admin
}
const isAdmin = function(){
    return store.state.admin
}

const router = new VueRouter({
	mode: 'history',
	base: process.env.BASE_URL,
	routes
})

export default router
