<template>
    <div class="BaccaratStatMark">
        <div v-show="result" class="text-score text-center text-box-score-right_peal">
            <span :class="`box-lot-cell ${backgroundClass}`">
                <i v-show="isBankerPair" class="float-dot dot-red"></i>
                <i v-show="isPlayerPair" class="float-dot dot-blue"></i>
                {{label}}
            </span>
        </div>
    </div>
</template>
<script>

const backgroundClassMap = {
    0: '',
    1: 'color-red',
    2: 'color-blue',
    3: 'color-yellow',
    5: '',
}

const labelMap = {
    0: '',
    1: 'B',
    2: 'P',
    3: 'T',
    5: '',
}

export default {
    name: 'BaccaratStatMark',
    props: [
        'result',
        'isPlayerPair',
        'isBankerPair',
    ],
    computed: {
        backgroundClass() {
            return backgroundClassMap[this.result]
        },
        label() {
            return labelMap[this.result]
        },
    }
}
</script>
<style scoped>
.text-score_bigroad {
    /* font-size: 14px; */
    width: 9px;
    height: 9px;
    display: block;
    margin: 1px;
}

.text-score {
    width: 15.5px;
    height: 15.5px;
    display: block;
    margin: 2px;
}

.text-score, .text-score-status, .text-score_all, .text-score-topic, .text-status-primary, .text-status-secondary, .text-red-bcc, .text-score_eye, .text-score_bigroad, .text-blue-bcc, .text-score_bigeye, .text-score_small {
    text-shadow: 0.5px 0.5px 0.5px #eee;
}

.text-box-score-right_eye {
    width: 4px;
    height: 2px;
}

.text-box-score-right_eye {
    display: table;
    margin-top: 1px;
    margin-bottom: 1px;
    margin-right: 1px;
    margin-left: 1px;
    padding-top: 1px;
}

.text-box-score-right_small {
    width: 2px;
    height: 2px;
}

.text-box-score-right_small {
    display: table;
    margin-top: 1px;
    margin-bottom: 1px;
    margin-right: 1px;
    margin-left: 1px;
    padding-top: 1px;
}

.color-red {
    background-color: #c52828 !important;
}

.color-blue {
    background-color: #006aff !important;
}

.color-yellow {
    background-color: #fab600;
}

.box-lot-cell {
    text-align: center;
    font-size: 13px;
    width: 18px;
    height: 18px;
    border-radius: 50%;
    cursor: pointer;
    display: inline-block;
    line-height: 17px;
    color: #fff;
    float: left;
    margin: 0 auto;
    font-family: "Athiti", sans-serif;
    margin-top: -1px;
    margin-left: -2px;
    position: relative;
}

.box-lot-cell_sm, .box-lot-cell_mini {
    width: 11px;
    height: 11px;
    border-radius: 50%;
    display: inline-block;
    float: left;
    font-family: "Athiti", sans-serif;
    margin-top: -1px;
    margin-left: -2px;
    border: solid 2px;
}

.box-lot-cell_mini {
    width: 6px;
    height: 6px;
    float: right;
}

.float-dot{
    display: block;
    width: 8px;
    height: 8px;
    border-radius: 50%;
    position: absolute;
    border: solid 1px #fff;
}

.dot-red{
    background-color: #c52828 !important;
    top: -1px;
    left: -2px;
}

.dot-blue{
    background-color: #006aff !important;
    bottom: -1px;
    right: 0px;
}

.border-red {
    border-color: #c52828 !important;
}

.border-blue {
    border-color: #006aff !important;
}
</style>