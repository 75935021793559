import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import jquery from 'jquery'
import moment from 'moment-timezone'
import 'bootstrap'
import 'bootstrap/dist/css/bootstrap.css'
import 'bootstrap-vue/dist/bootstrap-vue.css'
import BootstrapVue from 'bootstrap-vue'
import ElementUI from 'element-ui'
import 'element-ui/lib/theme-chalk/index.css'
import locale from 'element-ui/lib/locale/lang/th'
import numeral from 'numeral'
import VModal from 'vue-js-modal'
import BigNumber from "bignumber.js";
// import { VueReCaptcha } from 'vue-recaptcha-v3'
import VueYoutube from 'vue-youtube'
import isMobile from './isMobile'
import isOldIos from './isOldIos'
import isIos from './isIos'
import isMobileTablet from './isMobileTablet'
import {BANK_LIST} from './constant'
import { CATEGORY_LIST } from './components/lotto/Constant'

Vue.use(BootstrapVue)
Vue.use(ElementUI, {locale})
Vue.use(VModal)
// Vue.use(VueReCaptcha, { siteKey: process.env.VUE_APP_GOOGLE_CAPTCHA_SITE_KEY })
Vue.use(VueYoutube)

moment.tz.setDefault('Asia/Bangkok')
Vue.prototype.$jquery = jquery
Vue.prototype.$moment = moment
Vue.prototype.$VConsole = window.VConsole
Vue.prototype.$is_mobile = isMobile()
Vue.prototype.$isOldIos = isOldIos()
Vue.prototype.$isIos = isIos()
Vue.prototype.$isMobileTablet = isMobileTablet()
Vue.prototype.$json = (label, object) => {
    console.log(label, JSON.stringify(object, null, 2))
}

Vue.config.productionTip = false

const toBuddhistYear = (raw) => {
	return parseInt(raw) + 543
}

Vue.filter('chatMessageDateTimeFormat', function(raw){
    try{
		const timestamp = (raw) ? moment(raw) : moment()
		return `${timestamp.format('HH:mm A')} | ${timestamp.fromNow()}`
	}catch(err){
		console.log('chatMessageDateTimeFormat err', err.message)
		return ''
	}
})
Vue.filter('seperatorDateTimeFormat', function(raw){
    try{
		return moment(raw).format('ddd M/D')
	}catch(err){
		console.log('seperatorDateTimeFormat err', err.message)
		return ''
	}
})
Vue.filter('lottoPriceDateTimeFormat', function(raw){
    try{
		const timestamp = (raw) ? moment(raw).locale('th') : moment()
		return `${timestamp.format('DD MMMM')} ${toBuddhistYear(timestamp.format('YYYY'))}`
	}catch(err){
		console.log('lottoPriceDateTimeFormat err', err.message)
		return ''
	}
})
Vue.filter('timeFormat', function(raw){
    try{
		return moment(raw).format('HH:mm')
	}catch(err){
		console.log('timeFormat err', err.message)
		return ''
	}
})
Vue.filter('transactionTypeDisplay', function(raw){
    try{
		const map = {
			'deposit': 'เติมเงิน',
			'withdraw': 'ถอนเงิน'
		}
		return map[raw]
	}catch(err){
		console.log('transactionTypeDisplay err', err.message)
		return ''
	}
})

Vue.filter("numberFormat", function (value, digits = 2) {

    try{

        let format = '0,0.';

        for(let i = 0;i < digits;i++){
            format += '0'
        }

        value = parseFloat(value);

        if(Number(value) === value && value % 1 !== 0){//is float

            value = value.toFixed(digits);

            if(isNaN(parseFloat(value))){
                return "0.00";
            }else{
                return numeral(value).format(format);
            }
        }else{
            return numeral(value).format('0,0');
        }

    }catch(err){
        console.log('numberFormat err', err.message)
        return '';
    }
});

Vue.filter("toFixed", function (value, digits) {

    try{
        value = parseFloat(value);

        if(value === 0){
            return 0;
        }
        if(!value){
            return '';
        }

        value = value.toFixed(digits);

        if(digits > 2){
            value = value.replace(/\.?0$/, '');
        }

        if(isNaN(parseFloat(value))){
            return 0;
        }else{
            return value;
        }

    }catch(err){
        return "";
    }
});

Vue.filter("comma", function (value, float_digits) {

    try{

        let format = "0,0";

        if(float_digits){
            format+=".";
        }

        for(let i = 0;i < float_digits; i++){
            format += "0"
        }

        let comma = numeral(value).format(format);

        if(isNaN(parseFloat(comma))){
            return 0;
        }else{
            return comma;
        }

    }catch(err){
        return "";
    }
});

Vue.filter("floatFormat", function (value, digits = 2) {

    try{

        let format = '0,0.';

        for(let i = 0;i < digits;i++){
            format += '0'
        }

        value = parseFloat(value);

        if(Number(value) === value && value % 1 !== 0){//is float

            value = value.toFixed(digits);

            if(isNaN(parseFloat(value))){
                return "0.00";
            }else{
                return numeral(value).format(format);
            }
        }else{
            return numeral(value).format(format);
        }

    }catch(err){
        return '';
    }
});

Vue.filter("nullToDash", function (value) {
    if(value !== null){
        return value;
    }else{
        return "-";
    }
});

Vue.filter("nullToZero", function (value) {
    if(value){
        return value;
    }else{
        return 0;
    }
});

Vue.filter("priceByCurrency", function (price, odd_style = store.state.football.odd_style) {

    // let odd_style = store.state.odd_style;

    let odds;
    let one;

    if(price){

        if (odd_style === 'MY') {

            one = new BigNumber(1);
            odds = new BigNumber(price);

            if (price > 2) {
                odds = one.dividedBy(one.minus(odds));
            } else {
                odds = odds.minus(one);
            }
        } else if (odd_style === 'HK') {

            one = new BigNumber(1);
            odds = new BigNumber(price);
            odds = odds.minus(one);

        } else if (odd_style === 'EU') {

            odds = new BigNumber(price);
        }

        return odds;

    }else{

        return "";
    }
});

Vue.filter('changeHDP', function(value){

    let hdp = Math.abs(value);
    let arr_hdp = hdp.toString().split('.');
    if (arr_hdp[1] === '25') {
        hdp = arr_hdp[0] + '-' + arr_hdp[0] + '.5';
    } else if (arr_hdp[1] === '75') {
        let x = new BigNumber(arr_hdp[0]);
        let o = new BigNumber(1);
        let p = x.plus(o);
        hdp = arr_hdp[0] + '.5-' + p;
    } else {
        hdp = new BigNumber(hdp);
        hdp = hdp.toFixed(1);
    }

    return hdp;
});

Vue.filter("calModeName", function (value) {
    const nameMap = {
        'HDP': 'Handicap',
        'OU': 'Over/Under',
        '1X2': '1X2',
        'OE': 'Odd/Even',
    };
    return nameMap[value]
});

Vue.filter("calChooseName", function (value, match_home_name, match_away_name, mode) {
    const nameMap = {
        'HDP': {
            'H': match_home_name,
            'A': match_away_name,
        },
        'OU': {
            'Over': 'Over',
            'Under': 'Under'
        },
        '1X2': {
            '1': match_home_name,
            '2': match_away_name,
            'X': 'Draw',
        },
        'OE': {
            'Odd': 'Odd',
            'Even': 'Even'
        },
    };
    return nameMap[mode][value]
});

Vue.filter('scoreNotNull', function(value){
    if(value === '-:-'){
        return '0:0';
    }
    return value
});

Vue.filter('halfToH', function(value){
    if(value){
        if(value === '1ST'){
            return '1H';
        }else if(value === '2ND'){
            return '2H';
        }else if(value === 'HT'){
            return value;
        }else if(value === 'Standby' || value === 'Stanby'){
            return '';
        }
    }else{
        return '';
    }
});

Vue.filter('strOverflowHidden', function(value, limit = 2){
    let str = value+"";
    return (str.length > limit) ? '' : value;
});

Vue.filter('dateTimeFormat', function(value){
    return moment(value).format('YYYY-MM-DD HH:mm')
});

Vue.filter('dateTimeFormat2', function(value){
    return moment(value).format('YY/MM/DD HH:mm')
});

Vue.filter('bank_display', function(code, mode='default'){
    const bank = BANK_LIST.find((o) => {
        return o.swift_code === code
    })
    if(bank){
        if(mode === 'short'){
            return `${bank.name}`;
        }else{
            return `${bank.name}:${bank.short_name}`;
        }
    }else{
        return '';
    }
});

Vue.filter('bank_icon_class', function(code){
    const bank = BANK_LIST.find((o) => {
        return o.swift_code === code
    })
    if(bank){
        return bank.icon_class;
    }else{
        return '';
    }
});

Vue.filter('displayNameShort', function(raw){
    if(!raw) {
        return ''
    }
    return raw.slice(0, 4)
});
Vue.filter('displayNameCensor', function(raw){
    if(!raw) {
        return ''
    }
    return raw.substring(0,2)+'***'+raw.substring(raw.length - 2,raw.length)
});

Vue.filter('lottoBetTypeDisplay', function(raw){
    return CATEGORY_LIST[raw]['name']
});

Vue.mixin({
    computed: {
        envDomains() {
            return store.state.envDomains
        },
        googleCaptchaSiteKey() {
            return this.envDomains.VUE_APP_GOOGLE_CAPTCHA_SITE_KEY
        },
        isOnDev() {
            return process.env.NODE_ENV === 'development' || window.location.hostname === 'chat.liffline.com' || window.location.hostname === 'chat.slapi1.com'
        },
    },
})

new Vue({
	router,
	store,
	render: h => h(App)
}).$mount('#app')