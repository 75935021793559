<script>
export default {
    name: 'ResizeMixin',
    data() {
        return {
            resizeButtonTopLeft: null,
            resizeButtonTopRight: null,
            resizeButtonBottomRight: null,
            resizeButtonBottomLeft: null,
            isOnResize: false,
            resizeOriginalX: 0,
            resizeOriginalY: 0,
            resizeCurrentX: 0,
            resizeCurrentY: 0,
            elmnt: null,
            isVerticalFull: false,
            isHorizontalFull: false,
            resizeFromPosition: '',
        }
    },
    methods: {
        addResizeable() {
            setTimeout(() => {
                this.elmnt = this.$refs.boxVideoGuideWrapper
                this.resizeButtonTopLeft = this.$refs.resizeButtonTopLeft
                this.resizeButtonTopRight = this.$refs.resizeButtonTopRight
                this.resizeButtonBottomRight = this.$refs.resizeButtonBottomRight
                this.resizeButtonBottomLeft = this.$refs.resizeButtonBottomLeft
                this.resetResize()
                if(this.$is_mobile) {
                    this.resizeButtonTopLeft.addEventListener('touchstart', this.resizeStart)
                    this.resizeButtonTopRight.addEventListener('touchstart', this.resizeStart)
                    this.resizeButtonBottomRight.addEventListener('touchstart', this.resizeStart)
                    this.resizeButtonBottomLeft.addEventListener('touchstart', this.resizeStart)
                } else {
                    this.resizeButtonTopLeft.addEventListener('mousedown', this.resizeStart)
                    this.resizeButtonTopRight.addEventListener('mousedown', this.resizeStart)
                    this.resizeButtonBottomRight.addEventListener('mousedown', this.resizeStart)
                    this.resizeButtonBottomLeft.addEventListener('mousedown', this.resizeStart)
                }
            }, 1)
        },
        resizeStart(e) {
            if(this.isHorizontalFull || this.isVerticalFull) {
                return
            }
            e = e || window.event;
            this.resizeFromPosition = e.target.getAttribute('data-position')
            this.isOnResize = true
            e.preventDefault();
            this.resizeOriginalX = e.clientX || e.touches[0].clientX;
            this.resizeOriginalY = e.clientY || e.touches[0].clientY;
            if(this.$is_mobile) {
                document.addEventListener('touchmove', this.resizeDrag)
                document.addEventListener('touchend', this.resizeEnd)
            } else {
                document.addEventListener('mousemove', this.resizeDrag)
                document.addEventListener('mouseup', this.resizeEnd)
            }
        },
        resizeDrag(e) {
            if(this.isHorizontalFull || this.isVerticalFull) {
                return
            }
            e = e || window.event;
            e.preventDefault();

            const resizeCurrentX = e.clientX || e.touches[0].clientX
            const resizeCurrentY = e.clientY || e.touches[0].clientY
            this.resizeCurrentX = this.resizeOriginalX - resizeCurrentX;
            this.resizeCurrentY = this.resizeOriginalY - resizeCurrentY;
            this.resizeOriginalX = resizeCurrentX;
            this.resizeOriginalY = resizeCurrentY;

            if(this.resizeFromPosition === 'top-left') {
                this.elmnt.style.top = (this.elmnt.offsetTop - this.resizeCurrentY) + "px";
                this.elmnt.style.left = (this.elmnt.offsetLeft - this.resizeCurrentX) + "px";
            } else if(this.resizeFromPosition === 'top-right') {
                this.elmnt.style.top = (this.elmnt.offsetTop - this.resizeCurrentY) + "px";
            } else if(this.resizeFromPosition === 'bottom-left') {
                this.elmnt.style.left = (this.elmnt.offsetLeft - this.resizeCurrentX) + "px";
            }

            const originalWidth = this.elmnt.clientWidth
            let xMinusFlag = 1
            let yMinusFlag = 1
            if(this.resizeFromPosition === 'top-right') {
                xMinusFlag = -1
            } else if(this.resizeFromPosition === 'bottom-right') {
                xMinusFlag = -1
                yMinusFlag = -1
            } else if(this.resizeFromPosition === 'bottom-left') {
                yMinusFlag = -1
            }

            const newWidth = parseInt(originalWidth) + (parseInt(this.resizeCurrentX) * xMinusFlag)
            const originalHeight = this.elmnt.clientHeight
            const newHeight = parseInt(originalHeight) + (parseInt(this.resizeCurrentY) * yMinusFlag)

            this.elmnt.style.setProperty("width", newWidth+"px", "important")
            this.elmnt.style.setProperty("height", newHeight+"px", "important")
            this.$refs.webVideoWrapper.style.setProperty("width", newWidth+"px", "important")
            this.$refs.webVideoWrapper.style.setProperty("height", newHeight+"px", "important")

        },
        resizeEnd() {
            this.isOnResize = false
            if(this.$is_mobile) {
                document.removeEventListener('touchmove', this.resizeDrag)
                document.removeEventListener('touchend', this.resizeEnd)
            } else {   
                document.removeEventListener('mousemove', this.resizeDrag)
                document.removeEventListener('mouseup', this.resizeEnd)
            }
            this.calculateWebVideoWrapper()
        },
        removeResizeable() {
            if(this.$is_mobile) {
                if(this.resizeButtonTopLeft) {
                    this.resizeButtonTopLeft.removeEventListener('touchstart', this.resizeStart)
                }
                if(this.resizeButtonTopRight) {
                    this.resizeButtonTopRight.removeEventListener('touchstart', this.resizeStart)
                }
                if(this.resizeButtonBottomRight) {
                    this.resizeButtonBottomRight.removeEventListener('touchstart', this.resizeStart)
                }
                if(this.resizeButtonBottomLeft) {
                    this.resizeButtonBottomLeft.removeEventListener('touchstart', this.resizeStart)
                }
                document.removeEventListener('touchmove', this.resizeDrag)
                document.removeEventListener('touchend', this.resizeEnd)
            } else {
                if(this.resizeButtonTopLeft) {
                    this.resizeButtonTopLeft.removeEventListener('mousedown', this.resizeStart)
                }
                if(this.resizeButtonTopRight) {
                    this.resizeButtonTopRight.removeEventListener('mousedown', this.resizeStart)
                }
                if(this.resizeButtonBottomRight) {
                    this.resizeButtonBottomRight.removeEventListener('mousedown', this.resizeStart)
                }
                if(this.resizeButtonBottomLeft) {
                    this.resizeButtonBottomLeft.removeEventListener('mousedown', this.resizeStart)   
                }
                document.removeEventListener('mousemove', this.resizeDrag)
                document.removeEventListener('mouseup', this.resizeEnd)
            }
        },
        resetResize() {
            this.elmnt.style.removeProperty('top')
            this.elmnt.style.removeProperty('left')
            this.elmnt.style.removeProperty('width')
            this.elmnt.style.removeProperty('height')
            this.$refs.webVideoWrapper.style.removeProperty('width')
            this.$refs.webVideoWrapper.style.removeProperty('height')
        },
    },
}
</script>