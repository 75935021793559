<template>
    <div class="YeekeeDetail">
        <div class="YeekeeDetail-btn" v-show="!(yeekee_detail && yeekee_detail.result)" >
            <button class="btn btn-outline-secondary" @click="refresh">
                <span v-if="!on_refresh">Refresh</span>
                <span v-else>
                    <span>Loading...</span>
                    <div class="spinner-border spinner-border-sm" role="status">
                      <span class="sr-only">Loading...</span>
                    </div>
                </span>
            </button>
        </div>
        <div class="v-yeekee-detail-wrapper" v-if="yeekee_detail && yeekee_detail.result">
            <div class="card">
                <div class="card-header">
                    <div class="row">
                        <div class="col-12">
                            หวยไฮเทค <span>รอบที่ {{yeekee_detail.result.round.sub_round}}</span>
                        </div>
                    </div>
                </div>
                <div class="YeekeeDetail-card">
                    <div class="row">
                        <div class="col-12">
                            <div class="v-result-element">
                                <div>ผลรางวัล</div>
                                <h2>
                                    <span>{{yeekee_detail.result.first.substring(0, yeekee_detail.result.first.length - 5)}}</span>
                                    <span class="text-blue">{{yeekee_detail.result.song_tua_lang}}</span>
                                    <span class="text-danger">{{yeekee_detail.result.sam_tua_bon}}</span>
                                </h2>
                            </div>
                        </div>
                    </div>
                    <div class="row">
                        <div class="col-6 border-right">
                            <div class="v-result-element">
                                <div>3 ตัวบน</div>
                                <h3 class="text-danger">
                                    {{yeekee_detail.result.sam_tua_bon}}

                                </h3>
                            </div>
                        </div>
                        <div class="col-6">
                            <div class="v-result-element">
                                <div>2 ตัวล่าง</div>
                                <h3 class="text-blue">
                                    {{yeekee_detail.result.song_tua_lang}}

                                </h3>
                            </div>
                        </div>
                    </div>
                </div>


                <div class="YeekeeDetail-card-1">
                        <div class="v-result-element">
                            <div>ผลรวมยิงเลข</div>
                            <h3>
                                {{yeekee_detail.guess.total}}

                            </h3>
                        </div>
                        <div class="v-result-element">
                            <div>เลขแถวที่ 16</div>
                            <h3>
                                {{winner_16.number}}

                            </h3>
                        </div>
                </div>
                <h4 class="user-bonus-hitech">
                    <img src="@/assets/images/coin-small-1.png"/> สมาชิกที่ได้รับเงินรางวัล
                </h4>
                <div class="YeekeeDetail-card">
                    <div class="row">
                        <div class="col-6 border-right">
                            <div class="v-result-element">
                                <div>สมาชิกยิงเลข อันดับ 1</div>
                                <div class="text-pink">
                                    {{winner_1.user_name | strCensored}}

                                </div>
                            </div>
                        </div>
                        <div class="col-6">
                            <div class="v-result-element">
                                <div>สมาชิกยิงเลข อันดับ 16</div>
                                <div class="text-pink">
                                    {{winner_16.user_name | strCensored}}

                                </div>
                            </div>
                        </div>
                    </div>
                </div>

            </div>
        </div>
        <div v-else style="text-align: center; color: red;">
            <br><br><br><br>
            <h1>กำลังออกผลรางวัล</h1>
            <br>
            <h4>โปรดรอสักครู่หรือกดปุ่ม</h4>
            <h4>Refresh</h4>
        </div>
    </div>
</template>

<script>

    import axios_wrapper from "@/axiosWrapper";
    import AppMixin from '../mixins/App'

    export default {
        name: "YeekeeDetail",
        mixins: [
            AppMixin,
        ],
        props: [
            'round_id'
        ],
        data(){
            return {
                yeekee_detail: null,
                on_refresh: false,
            }
        },
        computed: {
            winner_1: function () {
                if (this.yeekee_detail && this.yeekee_detail.guess.list[0]) {
                    return this.yeekee_detail.guess.list[0];
                } else {
                    return '';
                }
            },
            winner_16: function () {
                if (this.yeekee_detail && this.yeekee_detail.guess.list[15]) {
                    return this.yeekee_detail.guess.list[15];
                } else {
                    return '';
                }
            }
        },
        watch: {
            round_id: function (new_val) {
                if (new_val) {
                    this.getYeekeeDetail();
                }
            }
        },
        methods: {
            getYeekeeDetail: async function () {

                this.on_refresh = true;

                if (this.round_id) {
                    const res = await axios_wrapper({
                        method: 'post',
                        url: `${this.envDomains.VUE_APP_LOTTO_API}/api/result/get_yeekee_detail`,
                        data: {
                            round_id: this.round_id
                        }
                    });
                    this.yeekee_detail = res.data;
                }

                this.on_refresh = false;
            },
            refresh: function(){
                this.getYeekeeDetail();
                this.$emit('refresh');
            }
        },
        mounted() {

            this.getYeekeeDetail();

            const _this = this;

            setTimeout(() => {
                _this.getYeekeeDetail();
            }, 20*1000);
        }
    }
</script>

<style scoped>
    .YeekeeDetail-btn{
        /*display: flex; */
        /*justify-content: flex-end; margin: 0 5px 10px 0;*/
        position: absolute;
        width: 100px;
        left: 0;
        right: 0;
        margin: auto;
    }
    .v-result-element {
        display: flex;
        align-items: center;
        flex-direction: column;
    }

    .v-result-element div:nth-child(2) {
        font-weight: bold;
    }
</style>