<template>
<div class="RichMessage">
    <div v-for="(message, i) of message_data" :key="i">
        <Type0 v-if="message.template_type === 0" :showGrid="showGrid" :data="message"></Type0>
        <Type1 v-else-if="message.template_type === 1" :showGrid="showGrid" :data="message"></Type1>
        <Type2 v-else-if="message.template_type === 2" :showGrid="showGrid" :data="message"></Type2>
        <Type3 v-else-if="message.template_type === 3" :showGrid="showGrid" :data="message"></Type3>
        <Type4 v-else-if="message.template_type === 4" :showGrid="showGrid" :data="message"></Type4>
        <Type5 v-else-if="message.template_type === 5" :showGrid="showGrid" :data="message"></Type5>
        <Type6 v-else-if="message.template_type === 6" :showGrid="showGrid" :data="message"></Type6>
        <Type7 v-else-if="message.template_type === 7" :showGrid="showGrid" :data="message"></Type7>
        <Type8 v-else-if="message.template_type === 8" :showGrid="showGrid" :data="message"></Type8>
    </div>
</div>
</template>

<script>

import Type0 from '@/components/message/richMessageTemplate/Type0'
import Type1 from '@/components/message/richMessageTemplate/Type1'
import Type2 from '@/components/message/richMessageTemplate/Type2'
import Type3 from '@/components/message/richMessageTemplate/Type3'
import Type4 from '@/components/message/richMessageTemplate/Type4'
import Type5 from '@/components/message/richMessageTemplate/Type5'
import Type6 from '@/components/message/richMessageTemplate/Type6'
import Type7 from '@/components/message/richMessageTemplate/Type7'
import Type8 from '@/components/message/richMessageTemplate/Type8'

export default {
    name: 'RichMessage',
    components: {
        Type0,
        Type1,
        Type2,
        Type3,
        Type4,
        Type5,
        Type6,
        Type7,
        Type8,
    },
    props: ['message', 'showGrid'],
    computed: {
        message_data(){
            return (this.message) ? this.message.data : null
        },
        timestamp(){
            return (this.message) ? this.message.timestamp : ''
        },
        isShowGrid() {
            return this.showGrid === 'yes'
        },
    },
}
</script>
<style scoped>
.RichMessage {
    display: flex;
}
</style>