<template>
    <div class="PageBet" :class="{'PageBet-yeekee': round_lotto_type === 'yeekee'}">
        <div class="nav-top hide">
            <div class="col-nav">
                <a @click="goHome" href="#">
                    <button class="btn ">
                        <i class="fas fa-chevron-left"></i>
                    </button>
                </a>
            </div>
            <h3>แทงหวย</h3>
            <div v-if="$isMobileTablet" class="row-balance">
                <div class="text-right">
                    <h5>ยอดเงินคงเหลือ</h5>
                    <span>{{pes_balance | floatFormat }} ฿</span>
                </div>
            </div>
            <el-button
                    @click="exit"
                    class="lotto-drawer-close-button"
                    icon="el-icon-close"
                    circle></el-button>
        </div>
        <div class="container container-fluid">
            <div class="w-100 row-menu-grid align-self-stretch">
                    <div style="position: relative;">


                        <b-modal id="YeekeeGuessModal" size="lg" hide-header hide-footer modal-class="hitechGuessModal">
                            <YeekeeGuess :round="round_obj" @close_yeekee_guess_modal="$bvModal.hide('YeekeeGuessModal')" @close_guess="is_yeekee_guess_open = false"/>
                        </b-modal>

                        <div class="wrap-content-scroll" style="height: auto">

                            <LottoTypePlateList></LottoTypePlateList>

                            <div v-show="round_obj" class="wrapper-bill">

                                <div class="box-bill">
<!--                                    all-header-box-none-open-->
                                    <div class="all-header-box" :class="{'all-header-box-none-open': !is_round_open}" id="AllHeaderBox">
                                        <span class="bx-none-open" v-if="!is_round_open">&nbsp;ยังไม่เปิดรับแทง</span>
                                        <div class="header-box lotto-title">

                                            <div class="lotto-flag">
                                                <LottoTypeIcon :lotto_type="lotto_type" customClass="lotto-flag"></LottoTypeIcon>
                                            </div>

                                            <div class="lotto-title-detail">
                                                <h3 class="t-h3">
                                                    <span>{{round_lotto_type | lottoTypeDisplay}}</span>
                                                    <div v-show="round_sub_round" class="yeekee-top-round">
                                                        <h3><span>รอบที่ {{round_sub_round}}</span></h3>
                                                    </div>
                                                </h3>
                                                <div class="lotto-title-detail-into">
                                                    <small>{{round_date}}</small>
                                                    <div class="text-description">{{round_description}}</div>
                                                </div>

                                            </div>
                                        </div>

                                        <div :class="{'height-50': is_round_open}" class="header-box-th-time-day">

                                            <Alarm @timeout="is_round_open = 'close'" :start="round_timeout" display_mode="main_bet"></Alarm>
                                            <div 
                                            v-if="(round_obj && round_lotto_type) === 'yeekee'" 
                                            @click="() => {$modal.show('YeekeeModal')}">
                                                <div :class="{'top-80': is_round_open}" class="btn-yeekee-all-left"><div class="btn">ดูรอบหวยไฮเทคทั้งหมด</div></div>
                                            </div>
                                            <div v-if="is_round_open" class="box-btn box-btn-yeekee">
                                                <button v-if="(round_obj && round_lotto_type) === 'yeekee'"
                                                        @click="$bvModal.show('YeekeeGuessModal')"
                                                        class="btn btn-sm btn-dark btn-click">
                                                    <span class="box-btn-yeekee-1">ยิงเลข</span>
                                                </button>
                                            </div>

                                        </div>
                                    </div>
                                    <div class="contentLottoArea  d-flex flex-row justify-content-between align-items-stretch flex-fill" :class="{'v-extend-margin-bottom': $isMobileTablet}">
                                        <div class="content-tanghuy ">
                                            <div v-show="!is_round_open" style="display: none;">
                                                <YeekeeGuess v-if="round_lotto_type === 'yeekee'" mode="static" :round="round_obj" @close_guess="is_yeekee_guess_open = false"/>
                                                <div v-else class="wrap-result-fill">
                                                    <div class="wrap-result-fill-left">
                                                        <Result :lotto_type="round_lotto_type" :round_id="round_id" :no_header="true"/>
                                                        <div class="wrap-table-left">
                                                            <ResultPrevious :lotto_type="round_lotto_type"/>
                                                        </div>
                                                    </div>
                                                    <div class="wrap-result-fill-right">
                                                        <h4>อัตราการจ่าย{{round_lotto_type | lottoTypeDisplay}}</h4>
                                                        <PriceTable :lotto_type="round_lotto_type"/>
                                                    </div>
                                                </div>
                                            </div>
                                            <div v-show="is_round_open" class="colLeft">
                                                <div class="header-top-menu">
                                                    <ul class="nav nav-tabs nav-tabs-custom nav-tabs-head" id="myTab" role="tablist">
                                                        <li class="nav-item">
                                                            <a class="nav-link active" id="select1-tab" data-toggle="tab"
                                                               href="#select1" role="tab" aria-controls="home" aria-selected="true"><i
                                                                    class="fas fa-th"></i> เลือกจากแผง</a>
                                                        </li>
                                                        <li class="nav-item">
                                                            <a class="nav-link" id="select2-tab" data-toggle="tab" href="#select2"
                                                               role="tab" aria-controls="profile" aria-selected="false"><i
                                                                    class="far fa-hand-pointer"></i> กดเลขเอง</a>
                                                        </li>
                                                    </ul>
                                                </div>

                                                <div class="tab-content" id="myTabContent">
                                                    <div class="tab-pane fade show active" id="select1" role="tabpanel"
                                                         aria-labelledby="home-tab">
                                                        <div class="box-sub-menu">
                                                            <ul class="nav nav-tabs nav-select-tabs" id="myTab2" role="tablist">
                                                                <li class="nav-item">
                                                                    <a class="nav-link" id="home-tab" data-toggle="tab"
                                                                       href="#three-tab" role="tab" aria-controls="home"
                                                                       aria-selected="true">
                                                                        <span>สามตัว </span>
                                                                    </a>
                                                                </li>
                                                                <li class="nav-item">
                                                                    <a class="nav-link active" id="profile-tab" data-toggle="tab"
                                                                       href="#two-tab" role="tab" aria-controls="profile"
                                                                       aria-selected="false">
                                                                        <span>สองตัว </span>
                                                                    </a>
                                                                </li>
                                                                <li class="nav-item">
                                                                    <a class="nav-link" id="contact-tab" data-toggle="tab"
                                                                       href="#run-tab" role="tab" aria-controls="contact"
                                                                       aria-selected="false">
                                                                        <span>เลขวิ่ง</span>
                                                                    </a>
                                                                </li>
                                                            </ul>
                                                        </div>
                                                        <div class="tab-content" id="myTabContent2">
                                                            <div class="tab-pane fade" id="three-tab"
                                                                 role="tabpanel" aria-labelledby="home-tab">
                                                                <div class="wrap-number-box">
                                                                    <div class="left-section">
                                                                        <div class="box-section text-center">
                                                                            <h6>เลือกรายการ</h6>
                                                                            <div class="row-btn-box-huay row-btn-box-huay-2">
                                                                                <div class="box-select-cat-group">
                                                                                    <div class="box-select-cat-group-info box-select-haft">
                                                                                        <div class="box-select-cat">
                                                                                            <Category @select="select_category"
                                                                                                      @un_select="un_select_category"
                                                                                                      :round_lotto_type="round_lotto_type"
                                                                                                      :category_obj="category_list['sam_tua_bon']"
                                                                                                      :setting="setting_xxx"
                                                                                                      :max_reward="max_reward_3"/>
                                                                                        </div>
                                                                                        <div class="box-select-cat">
                                                                                            <Category @select="select_category"
                                                                                                      @un_select="un_select_category"
                                                                                                      :round_lotto_type="round_lotto_type"
                                                                                                      :category_obj="category_list['sam_tua_tod']"
                                                                                                      :setting="setting_xxx"
                                                                                                      :max_reward="max_reward_3"/>
                                                                                        </div>
                                                                                    </div>
                                                                                    <div class="box-select-cat-group-info box-select-haft">
                                                                                        <div v-if="round_lotto_type === 'government'" class="box-select-cat">
                                                                                            <Category @select="select_category"
                                                                                                      @un_select="un_select_category"
                                                                                                      :round_lotto_type="round_lotto_type"
                                                                                                      :category_obj="category_list['sam_tua_nha']"
                                                                                                      :setting="setting_xxx"
                                                                                                      :max_reward="max_reward_3"/>
                                                                                        </div>
                                                                                        <div v-if="round_lotto_type === 'government'" class="box-select-cat">
                                                                                            <Category @select="select_category"
                                                                                                      @un_select="un_select_category"
                                                                                                      :round_lotto_type="round_lotto_type"
                                                                                                      :category_obj="category_list['sam_tua_lang']"
                                                                                                      :setting="setting_xxx"
                                                                                                      :max_reward="max_reward_3"/>
                                                                                        </div>
                                                                                    </div>

                                                                                    <div class="box-select-cat-group-info box-select-haft">
                                                                                        <div class="box-select-cat">
                                                                                            <Category @select="select_category"
                                                                                                      @un_select="un_select_category"
                                                                                                      :round_lotto_type="round_lotto_type"
                                                                                                      :category_obj="category_list['sam_tua_glub']"
                                                                                                      :version="2"
                                                                                            />
                                                                                        </div>
                                                                                    </div>
                                                                                </div>
                                                                            </div>
                                                                            <div class="btn-select-price">
                                                                                <h6>รายการที่เลือก</h6>
                                                                                <div class="label-btn-select-price">
                                                                                    <CategorySelected :select_category_list="select_category_list" :category_option="category_option" />
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                        <Condition mode="sam_tua" 
                                                                        :lotto_type="round_lotto_type" 
                                                                        :setting_xx="setting_xx" 
                                                                        :setting_xxx="setting_xxx"
                                                                        :config="config"
                                                                        :price_table="price_table"/>
                                                                    </div>
                                                                    <div class="section-number">
                                                                        <div class="box-section">
                                                                            <!--<h6>เลือกตัวเลข</h6>-->
                                                                            <div class="section-number-row">
                                                                                <div class="box-search-number">
                                                                                    <input v-model="price_dynamic_filter_by" type="text" class="form-control bg-black"
                                                                                           placeholder="ค้นหาตัวเลข">
                                                                                </div>
                                                                                <div class="fa-pull-left-sort">
                                                                                    <ul class="nav nav-pills">
                                                                                        <li class="nav-item dropdown">
                                                                                            <label>
                                                                                                <select v-model="price_dynamic_sort_by">
                                                                                                    <option value="number">เรียงจากเลข</option>
                                                                                                    <option value="price_desc">เรียงจากราคาดีที่สุด</option>
                                                                                                    <option value="price">เรียงจากราคาน้อยที่สุด</option>
                                                                                                </select>
                                                                                            </label>
                                                                                        </li>
                                                                                    </ul>
                                                                                </div>

                                                                            </div>
                                                                            <ul v-show="!price_dynamic_filter_by && price_dynamic_sort_by === 'number'" class="nav nav-pills nav-pills-custom"
                                                                                id="pills-tab" role="tablist">
                                                                                <li v-for="(p, i) of price_panel_3_pages" :key="i" class="nav-item">
                                                                                    <a class="nav-link" :class="{'active': i === 0}"
                                                                                       id="pills-home-tab" data-toggle="pill"
                                                                                       :href="'#tab-three-'+i"
                                                                                       :i="i"
                                                                                       role="tab"
                                                                                       aria-controls="pills-home"
                                                                                       aria-selected="true">{{p}}</a>
                                                                                </li>
                                                                            </ul>
                                                                            <div class="tab-content w-100">
                                                                                <div class="box-number">
                                                                                    <div v-show="price_3_dynamic_filtered.length === 0" class="v-loading-wrapper">
                                                                                        <div>
                                                                                            กำลังโหลด
                                                                                            <div class="spinner-border spinner-border-sm" role="status">
                                                                                                <span class="sr-only">Loading...</span>
                                                                                            </div>
                                                                                        </div>
                                                                                    </div>
                                                                                    <ul v-show="price_3_dynamic_filtered.length > 0" class="list-number">
                                                                                        <li v-for="(v, i) of price_3_paging(price_panel_3_page)" :key="i">
                                                                                            <PricePanel
                                                                                                    @select_number="(number) => { choose_number_list = number; add_bet(); }"
                                                                                                    @cancel="(number) => { cancel_by_number(number) }"
                                                                                                    :price="v.reward"
                                                                                                    :number="v.number_display"
                                                                                                    :number_setting_arr="number_setting_arr"
                                                                                                    :is_allow="v.is_allow"
                                                                                                    bet_type_group='sam_tua_bon'></PricePanel>
                                                                                        </li>
                                                                                    </ul>
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            <div class="tab-pane fade show active" id="two-tab" role="tabpanel"
                                                                 aria-labelledby="profile-tab">
                                                                <div class="wrap-number-box">
                                                                    <div class="left-section">
                                                                        <div class="box-section text-center">
                                                                            <h6>เลือกรายการ</h6>
                                                                            <div class="row-btn-box-huay row-btn-box-huay-2 row-btn-box-huay-option">
                                                                                <div class="box-select-cat-group">
                                                                                    <div class="box-select-cat-group-info box-select-haft">
                                                                                        <div class="box-select-cat">
                                                                                            <Category @select="select_category"
                                                                                                      @un_select="un_select_category"
                                                                                                      :round_lotto_type="round_lotto_type"
                                                                                                      :category_obj="category_list['song_tua_bon']"
                                                                                                      :setting="setting_xx"
                                                                                                      :max_reward="max_reward"/>
                                                                                        </div>
                                                                                        <div class="box-select-cat">
                                                                                            <Category @select="select_category"
                                                                                                      @un_select="un_select_category"
                                                                                                      :round_lotto_type="round_lotto_type"
                                                                                                      :category_obj="category_list['song_tua_lang']"
                                                                                                      :setting="setting_xx"
                                                                                                      :max_reward="max_reward"/>
                                                                                        </div>
                                                                                    </div>
                                                                                    <div class="box-select-cat-group-info box-select-haft">
                                                                                        <div class="box-select-cat">
                                                                                            <Category @select="select_category"
                                                                                                      @un_select="un_select_category"
                                                                                                      :round_lotto_type="round_lotto_type"
                                                                                                      :category_obj="category_list['song_tua_glub']"
                                                                                                      :version="2"/>

                                                                                        </div>
                                                                                    </div>
                                                                                </div>
                                                                            </div>
                                                                            <div class="btn-select-price">
                                                                                <h6>รายการที่เลือก</h6>
                                                                                <div class="label-btn-select-price">
                                                                                    <CategorySelected :select_category_list="select_category_list" :category_option="category_option" />
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                        <Condition mode="song_tua" 
                                                                        :lotto_type="round_lotto_type" 
                                                                        :setting_xx="setting_xx" 
                                                                        :setting_xxx="setting_xxx"
                                                                        :config="config"
                                                                        :price_table="price_table"/>
                                                                    </div>
                                                                    <div class="section-number">
                                                                        <div class="box-section box-number">
                                                                            <!--<h6>เลือกตัวเลข</h6>-->
                                                                            <div class="section-option-number">
                                                                                <div class="box-option-number">
                                                                                    <OptionNumberListPanel
                                                                                            @cancel="cancel_by_bet_list_index"
                                                                                            @select_category_option="select_category_option"
                                                                                            category="sib_kao_pratu"
                                                                                            :number_setting_arr="number_setting_arr"/>
                                                                                </div>
                                                                                <div class="box-option-number">
                                                                                    <OptionNumberListPanel
                                                                                            @cancel="cancel_by_bet_list_index"
                                                                                            @select_category_option="select_category_option"
                                                                                            category="rood_nha"
                                                                                            :number_setting_arr="number_setting_arr"/>
                                                                                </div>
                                                                                <div class="box-option-number">
                                                                                    <OptionNumberListPanel
                                                                                            @cancel="cancel_by_bet_list_index"
                                                                                            @select_category_option="select_category_option"
                                                                                            category="rood_lang"
                                                                                            :number_setting_arr="number_setting_arr"/>
                                                                                </div>
                                                                            </div>
                                                                            <div class="section-number-row">
                                                                                <div class="box-search-number">
                                                                                    <input v-model="price_dynamic_filter_by" type="text" class="form-control bg-black"
                                                                                           placeholder="ค้นหาตัวเลข">
                                                                                </div>
                                                                                <div class="fa-pull-left-sort">
                                                                                    <ul class="nav nav-pills">
                                                                                        <li class="nav-item dropdown">
                                                                                            <label>
                                                                                                <select v-model="price_dynamic_sort_by">
                                                                                                    <option value="number">เรียงจากเลข</option>
                                                                                                    <option value="price_desc">เรียงจากราคาดีที่สุด</option>
                                                                                                    <option value="price">เรียงจากราคาน้อยที่สุด</option>
                                                                                                </select>
                                                                                            </label>
                                                                                        </li>
                                                                                    </ul>
                                                                                </div>
                                                                            </div>
                                                                            <div v-show="price_dynamic_filtered.length === 0" class="v-loading-wrapper">
                                                                                <div>
                                                                                    กำลังโหลด
                                                                                    <div class="spinner-border spinner-border-sm" role="status">
                                                                                        <span class="sr-only">Loading...</span>
                                                                                    </div>
                                                                                </div>
                                                                            </div>
                                                                            <ul v-show="price_dynamic_filtered.length > 0" class="list-number">
                                                                                <li v-for="(v, i) of price_dynamic_filtered" :key="i">
                                                                                    <PricePanel
                                                                                            @select_number="(number) => { choose_number_list = number; add_bet(); }"
                                                                                            @cancel="(number) => { cancel_by_number(number) }"
                                                                                            :price="v.reward"
                                                                                            :number="v.number_display"
                                                                                            :number_setting_arr="number_setting_arr"
                                                                                            :is_allow="v.is_allow"
                                                                                            bet_type_group='song_tua_bon'></PricePanel>
                                                                                </li>
                                                                            </ul>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            <div class="tab-pane fade" id="run-tab" role="tabpanel"
                                                                 aria-labelledby="contact-tab">
                                                                <div class="wrap-number-box">
                                                                    <div class="left-section">
                                                                        <div class="box-section text-center">

                                                                            <h6>เลือกรายการ</h6>
                                                                            <div class="row row-btn-box-huay row-btn-box-huay-2">
                                                                                <div class="box-select-cat-group">
                                                                                    <div class="box-select-cat-group-info box-select-haft">
                                                                                        <div class="box-select-cat">
                                                                                            <Category @select="select_category"
                                                                                                      @un_select="un_select_category"
                                                                                                      :round_lotto_type="round_lotto_type"
                                                                                                      :category_obj="category_list['wing_bon']"
                                                                                                      :setting="setting_xx"
                                                                                                      :max_reward="max_reward_wing_bon"/>
                                                                                        </div>
                                                                                        <div class="box-select-cat">
                                                                                            <Category @select="select_category"
                                                                                                      @un_select="un_select_category"
                                                                                                      :round_lotto_type="round_lotto_type"
                                                                                                      :category_obj="category_list['wing_lang']"
                                                                                                      :setting="setting_xx"
                                                                                                      :max_reward="max_reward_wing_lang"/>
                                                                                        </div>
                                                                                    </div>
                                                                                </div>
                                                                            </div>
                                                                            <div class="btn-select-price">
                                                                                <h6>รายการที่เลือก</h6>
                                                                                <div class="label-btn-select-price">
                                                                                    <CategorySelected :select_category_list="select_category_list" :category_option="category_option" />
                                                                                </div>

                                                                            </div>
                                                                        </div>
                                                                        <Condition mode="wing" 
                                                                        :lotto_type="round_lotto_type" 
                                                                        :setting_xx="setting_xx" 
                                                                        :setting_xxx="setting_xxx"
                                                                        :config="config"
                                                                        :price_table="price_table"/>
                                                                    </div>

                                                                    <div class="section-number">
                                                                        <div class="box-section">
                                                                            <h6>เลือกตัวเลข</h6>
                                                                            <div class="box-number">
                                                                                <ul class="list-number">
                                                                                    <li v-for="(v, i) of price_dynamic_filtered" :key="i">
                                                                                        <PricePanel
                                                                                                @select_number="(number) => { choose_number_list = number; add_bet(); }"
                                                                                                @cancel="(number) => { cancel_by_number(number) }"
                                                                                                :price="v.reward"
                                                                                                :number="v.number_display"
                                                                                                :number_setting_arr="number_setting_arr"
                                                                                                :is_allow="v.is_allow"
                                                                                                :bet_type_group="wing_type_cal"></PricePanel>
                                                                                    </li>
                                                                                </ul>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div class="tab-pane fade" id="select2" role="tabpanel"
                                                         aria-labelledby="profile-tab">
                                                        <div class="box-wrap-content">
                                                            <div class="left-section left-section-profile-tab">
                                                                <div class="box-section text-center">
                                                                    <div class="row-btn-box-huay row-btn-box-huay-m row-btn-box-huay-option">
                                                                        <h6>เลือกรายการ</h6>
                                                                        <div class="box-select-cat-group">
                                                                            <div class="box-select-cat-group-info box-select-haft">
                                                                                <div class="box-select-cat">
                                                                                    <Category @select="select_category"
                                                                                              @un_select="un_select_category"
                                                                                              :round_lotto_type="round_lotto_type"
                                                                                              :category_obj="category_list['sam_tua_bon']"
                                                                                              :setting="setting_xxx"
                                                                                              :max_reward="max_reward_3"/>
                                                                                </div>
                                                                                <div class="box-select-cat">
                                                                                    <Category @select="select_category"
                                                                                              @un_select="un_select_category"
                                                                                              :round_lotto_type="round_lotto_type"
                                                                                              :category_obj="category_list['sam_tua_tod']"
                                                                                              :setting="setting_xxx"
                                                                                              :max_reward="max_reward_3"/>
                                                                                </div>
                                                                            </div>
                                                                            <div class="box-select-cat-group-info box-select-haft">
                                                                                <div v-if="round_lotto_type === 'government'" class="box-select-cat">
                                                                                    <Category @select="select_category"
                                                                                              @un_select="un_select_category"
                                                                                              :round_lotto_type="round_lotto_type"
                                                                                              :category_obj="category_list['sam_tua_nha']"
                                                                                              :setting="setting_xxx"
                                                                                              :max_reward="max_reward_3"/>
                                                                                </div>
                                                                                <div v-if="round_lotto_type === 'government'" class="box-select-cat">
                                                                                    <Category @select="select_category"
                                                                                              @un_select="un_select_category"
                                                                                              :round_lotto_type="round_lotto_type"
                                                                                              :category_obj="category_list['sam_tua_lang']"
                                                                                              :setting="setting_xxx"
                                                                                              :max_reward="max_reward_3"/>
                                                                                </div>
                                                                            </div>
                                                                            <div class="box-select-cat-group-info box-select-haft">
                                                                                <div class="box-select-cat">
                                                                                    <Category @select="select_category"
                                                                                              @un_select="un_select_category"
                                                                                              :round_lotto_type="round_lotto_type"
                                                                                              :category_obj="category_list['song_tua_bon']"
                                                                                              :setting="setting_xx"
                                                                                              :max_reward="max_reward"/>
                                                                                </div>
                                                                                <div class="box-select-cat">
                                                                                    <Category @select="select_category"
                                                                                              @un_select="un_select_category"
                                                                                              :round_lotto_type="round_lotto_type"
                                                                                              :category_obj="category_list['song_tua_lang']"
                                                                                              :setting="setting_xx"
                                                                                              :max_reward="max_reward"/>
                                                                                </div>
                                                                            </div>
                                                                            <div class="box-select-cat-group-info box-select-haft">
                                                                                <div class="box-select-cat">
                                                                                    <Category @select="select_category"
                                                                                              @un_select="un_select_category"
                                                                                              :round_lotto_type="round_lotto_type"
                                                                                              :category_obj="category_list['song_tua_glub']"/>
                                                                                </div>
                                                                                <div class="box-select-cat">
                                                                                    <Category @select="select_category"
                                                                                              @un_select="un_select_category"
                                                                                              :round_lotto_type="round_lotto_type"
                                                                                              :category_obj="category_list['sam_tua_glub']"/>
                                                                                </div>
                                                                            </div>
                                                                            <div class="box-select-cat-group-info box-select-haft">
                                                                                <div class="box-select-cat">
                                                                                    <Category @select="select_category"
                                                                                              @un_select="un_select_category"
                                                                                              :round_lotto_type="round_lotto_type"
                                                                                              :category_obj="category_list['wing_bon']"
                                                                                              :setting="setting_xx"
                                                                                              :max_reward="max_reward_wing_bon"/>
                                                                                </div>
                                                                                <div class="box-select-cat">
                                                                                    <Category @select="select_category"
                                                                                              @un_select="un_select_category"
                                                                                              :round_lotto_type="round_lotto_type"
                                                                                              :category_obj="category_list['wing_lang']"
                                                                                              :setting="setting_xx"
                                                                                              :max_reward="max_reward_wing_lang"/>
                                                                                </div>
                                                                            </div>


                                                                            <div v-show="have_option" class="row section-option">
                                                                                <h6>เลือก Option</h6>
                                                                                <div class="box-option">
                                                                                    <CategoryOption
                                                                                            @select_category_option="select_category_option"
                                                                                            :category_option_list="category_option_list" />
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                    <div class="btn-select-price">
                                                                        <h6>รายการที่เลือก</h6>
                                                                        <div class="label-btn-select-price">
                                                                            <CategorySelected :select_category_list="select_category_list" :category_option="category_option" />
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                                <Condition mode="all" 
                                                                :lotto_type="round_lotto_type" 
                                                                :setting_xx="setting_xx" 
                                                                :setting_xxx="setting_xxx"
                                                                :config="config"
                                                                :price_table="price_table"/>
                                                            </div>
                                                            <div class="section-number">
                                                                <div class="box-section text-center">
                                                                    <h6>ระบุตัวเลข</h6>
                                                                    <div v-show="show_numpad" class="row-numpad">
                                                                        <div class="bt-label-number">
                                                                                    <label v-for="(c, i) of choose_number_list" :key="i"
                                                                                           class="number">{{c}}</label>
                                                                            </div>
                                                                        <NumPad @choose_number="choose_number"
                                                                                @backspace="backspace"
                                                                                @cancel_recent="cancel_recent"
                                                                                @cancel_all="$bvModal.show('betCancelAllConfirm')"/>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
<!--                                            <div class="colLeft"></div>-->
                                        </div>
                                    </div>

                                </div>
                            </div>
                        </div>
                    </div>
            </div>
        </div>

        <div v-show="is_round_open && bet_list.length > 0" class="v-float-number" :class="{'v-throw-to-bottom': is_bouncing}">
            <div class="v-inner">
                <span v-if="bet_list.length > 0">{{bet_list[0].number}}</span>
            </div>
        </div>
        <div class="v-fixed-bet-panel-mobile-all">
            <div v-if="is_round_open" class="v-fixed-bet-panel-mobile">
                <div class="v-inner">
                    <div class="v-left">
                        <div><span>รายการแทง </span><span>{{bet_list.length}} รายการ</span></div>
                        <div class="v-text-overflow-ellipsis">{{bet_list_number_str}}</div>
                    </div>
                    <div class="v-right">
                        <div v-if="bet_list.length > 0" class="v-bet-list-length-float">
                            <div>{{bet_list.length}}</div>
                        </div>
                        <button @click="showBetPanel" class="btn btn-warning" style="width: 100%;">ใส่ราคา</button>
                    </div>
                </div>
            </div>

            <Drawer @modal_state_update="(state) => show_bet_panel = state" transition="modal-slide-up">
                <template v-slot:v_drawer_body>
                    <div class="box-list-header">

<!--                            รายการแทง-->
                            <div @click="show_bet_panel = false" class="bt-closed">
                                <i class="fas fa-chevron-down"></i></div>

                    </div>
                    <div class="content-list-bet" id="bet_panel_wrapper" style="height: 100%; ">
                        <div class="box-list-bet " style="margin-top: 37px">
                            <BetPanel :config="config" :setting_xx="setting_xx" :setting_xxx="setting_xxx" @goBillDetail="goBillDetail" />
                        </div>
                    </div>
                </template>
            </Drawer>
        </div>


        <b-modal @ok="bet_cancel_all" id="betCancelAllConfirm" hide-header centered
        ok-title="ตกลง"
        cancel-title="ยกเลิก">
            <p class="my-4">ต้องการลบเลขทั้งหมด?</p>
        </b-modal>

        <modal name="billDetailModal" class="billDetailModal-box"
        :adaptive="true"
        height="100%"
        width="100%"
        :pivotY="1"
        transition="modal-slide-up">
            <BillDetail :bill_id="selectedBillId" @close="() => {$modal.hide('billDetailModal')}"></BillDetail>
        </modal>

        <modal name="YeekeeModal"
        :adaptive="true"
        height="100%"
        width="100%"
        :pivotY="1"
        transition="modal-slide-up"
        class="allYeekee-modal">
            <Yeekee @close="() => {$modal.hide('YeekeeModal')}"></Yeekee>
        </modal>

        <b-modal ref="chooseNumberBeforeAddPriceModal" hide-header centered
        ok-only 
        ok-title="ตกลง">
            <p class="my-4">กรุณาเลือกตัวเลข ก่อนใส่ราคา</p>
        </b-modal>
    </div>
</template>

<script>

    import store from '@/store'
import {mixin as VueTimers} from 'vue-timers'
import {
    CATEGORY_LIST,
    CATEGORY_SONG_TUA_LIST,
    BILL_KEY,
    CONDITION_LIST,
    bet_type_arr,
} from './Constant'
import array_permutation from "./lib/array_permutation";
import search_wrapper from './lib/search_wrapper';
import sort_wrapper from './lib/sort_wrapper';
import {rood_lang_number_list, rood_nha_number_list, sib_kao_pratu_number_list} from "./lib/cal_bet_mode"
import axios_wrapper from '@/axiosWrapper'
import waitForSecond from './lib/waitForSecond'
import RewardHandle from './lib/RewardHandle'

import Category from "./components/Category";
import NumPad from "./components/NumPad";
import Drawer from "./components/Drawer";
import Alarm from "./components/Alarm";
import PricePanel from "./components/PricePanel";
import CategoryOption from "./components/CategoryOption";
import CategorySelected from "./components/CategorySelected";
import YeekeeGuess from "./components/YeekeeGuess";
import OptionNumberListPanel from "./components/OptionNumberListPanel";
import BetPanel from "./components/BetPanel";
import LottoTypePlateList from "./components/LottoTypePlateList";
import Condition from "./components/Condition";
import Result from "./components/Result";
import PriceTable from "./components/PriceTable";
import ResultPrevious from "./components/ResultPrevious";
import BillDetail from './components/BillDetail'
import Yeekee from './components/Yeekee'
import LottoTypeIcon from './components/LottoTypeIcon'

import AppMixin from './mixins/App'
import XXSettingMixin from "./mixins/XXSettingMixin"
import BetMixin from './mixins/BetMixin';
import NumberSettingMixin from './mixins/NumberSettingMixin'
import ApiMixin from './mixins/ApiMixin'

    export default {
            name: "PageBet",
            mixins: [
                AppMixin,
                VueTimers,
                XXSettingMixin,
                BetMixin,
                NumberSettingMixin,
                ApiMixin,
            ],
            timers: {
                get_dynamic_reward: {
                    autostart: true,
                    time: 10000,
                    repeat: true,
                },
            },
            components: {
                ResultPrevious,
                Result,
                Condition,
                LottoTypePlateList,
                BetPanel,
                PriceTable,
                OptionNumberListPanel,
                YeekeeGuess,
                CategorySelected,
                CategoryOption,
                PricePanel,
                Alarm,
                Drawer,
                NumPad,
                Category,
                BillDetail,
                Yeekee,
                LottoTypeIcon,
            },
            data() {
                return {
                    category_option_list: [],
                    condition_list: [],
                    choose_number_list: [],
                    current_digit: 0,
                    show_bet_panel: false,
                    category_option: false,
                    price_dynamic_arr: [],
                    price_dynamic_arr_run: [],
                    price_dynamic_arr_3: [],
                    price_dynamic_arr_3_run: [],
                    price_panel_3_pages: ['000', '100', '200', '300', '400', '500', '600', '700', '800', '900'],
                    price_panel_3_page: 0,
                    price_panel_tab: '',
                    price_dynamic_filter_by: '',
                    price_dynamic_sort_by: 'number',
                    is_guess_timeout: false,
                    is_bouncing: false,
                    number_setting_arr: [],
                    config: null,
                    selectedBillId: '',
                }
            },
            computed: {
                channel: function () {
                    return store.state.channel;
                },
                is_member() {
                    return store.state.is_member
                },
                user_profile() {
                    return store.state.user_profile
                },
                pes_profile() {
                    return store.state.pes_profile
                },
                pes_balance: function () {
                    if (this.pes_profile) {
                        if (this.pes_profile.balances[0]['THB_free']) {
                            return parseFloat(this.pes_profile.balances[0]['THB_free']);
                        } else {
                            return 0;
                        }
                    } else {
                        return 0;
                    }
                },
                select_category_list: function () {
                    return store.state.lotto.select_category_list;
                },
                select_category_arr(){
                    return this.select_category_list.map((o) => {
                        return o.slug
                    })
                },
                have_option: function () {
                    let song_tua = this.select_category_list.find((o) => {
                        return o.slug === 'song_tua_bon' || o.slug === 'song_tua_lang'
                    });
                    let song_tua_glub = this.select_category_list.find((o) => {
                        return o.slug === 'song_tua_glub'
                    });
                    return !!(song_tua && !song_tua_glub);
                },
                show_numpad: function () {
                    if (this.select_category_list.length === 1) {
                        return !(this.select_category_list.find((o) => {
                            return o.slug === 'sam_tua_glub' || o.slug === 'song_tua_glub'
                        }));
                    } else if (this.select_category_list.length > 1) {
                        return true;
                    } else {
                        return false;
                    }
                },
                number_choose_digits: function () {

                    const one = this.select_category_list.find((o) => {
                        return o.slug === 'wing_bon' || o.slug === 'wing_lang'
                    });
                    const two = this.select_category_list.find((o) => {
                        return o.slug === 'song_tua_bon' || o.slug === 'song_tua_lang' || o.slug === 'song_tua_glub'
                    });
                    const three = this.select_category_list.find((o) => {
                        return o.slug === 'sam_tua_bon' || o.slug === 'sam_tua_tod' || o.slug === 'sam_tua_nha' || o.slug === 'sam_tua_lang' || o.slug === 'sam_tua_glub'
                    });
                    if (one || this.category_option.slug === 'sib_kao_pratu'
                        || this.category_option.slug === 'rood_nha'
                        || this.category_option.slug === 'rood_lang'
                        || this.price_panel_tab === '#run-tab') {
                        return 1;
                    } else if (two) {
                        return 2;
                    } else if (three) {
                        return 3;
                    } else {
                        return 0;
                    }
                },
                bet_list: function () {
                    return store.state.lotto.bet_list
                },
                bet_list_length: function () {
                    return this.bet_list.length;
                },
                is_round_open:  {
                    get: function(){
                        if (this.round_obj) {
                            const now = this.$moment();
                            const timeout = this.$moment(this.round_obj.timeout);
                            return (this.round_obj && this.round_obj.status === 'open' && now.isSameOrBefore(timeout));
                        } else {
                            return false;
                        }
                    },
                    set: function(new_val){
                        if(this.round_obj){
                            this.round_obj.status = new_val;
                        }
                    }
                },
                is_yeekee_guess_open: {
                    get(){
                        if (this.round_obj) {
                            const now = this.$moment();
                            const timeout = this.$moment(this.round_obj.timeout).add(2, 'minutes');
                            return (!this.is_guess_timeout && now.isSameOrBefore(timeout));
                        } else {
                            return false;
                        }
                    },
                    set(value){
                        this.is_guess_timeout = !value;
                    }
                },
                accept_any_odd: {
                    get: function () {
                        return store.state.lotto.accept_any_odd;
                    },
                    set: function (new_val) {
                        store.commit('lotto/update_accept_any_odd', new_val);
                    }
                },
                wing_type_cal(){
                    const isWingBon = this.select_category_list.find((category) => {
                        return category.slug === 'wing_bon'
                    })
                    const isWingLang = this.select_category_list.find((category) => {
                        return category.slug === 'wing_lang'
                    })
                    if(isWingBon){
                        return 'wing_bon'
                    }else if(isWingLang){
                        return 'wing_lang'
                    }else{
                        return 'wing_bon'
                    }
                },
                price_dynamic_filtered: {
                    get(){
                        if(this.price_panel_tab === '#run-tab'){
                            if(this.wing_type_cal === 'wing_bon'){
                                return this.price_dynamic_arr_3_run.map((o) => {
                                    o.number_display = parseInt(o.number)+""
                                    return o
                                })
                            }else if(this.wing_type_cal === 'wing_lang'){
                                return this.price_dynamic_arr_run.map((o) => {
                                    o.number_display = parseInt(o.number)+""
                                    return o
                                })
                            }else{
                                return []
                            }
                        }else{
                            const keyword = this.price_dynamic_filter_by;
                            const col = (this.price_dynamic_sort_by === 'number') ? this.price_dynamic_sort_by : 'reward';
                            const desc = (this.price_dynamic_sort_by === 'price_desc');

                            return sort_wrapper(search_wrapper(this.price_dynamic_arr, keyword), col, desc);
                        }
                    },
                    async set(arr){
                        this.price_dynamic_arr = []
                        await waitForSecond(400)
                        this.price_dynamic_arr = arr
                    }
                },
                price_3_dynamic_filtered: {
                    get(){
                        const keyword = this.price_dynamic_filter_by;
                        const col = (this.price_dynamic_sort_by === 'number') ? this.price_dynamic_sort_by : 'reward';
                        const desc = (this.price_dynamic_sort_by === 'price_desc');

                        return sort_wrapper(search_wrapper(this.price_dynamic_arr_3, keyword), col, desc);
                    },
                    async set(arr){
                        this.price_dynamic_arr_3 = []
                        await waitForSecond(400)
                        this.price_dynamic_arr_3 = arr
                    }
                },
                round_lotto_type: function(){
                    return (this.round_obj) ? this.round_obj.lotto_type : '';
                },
                round_description: function(){
                    return (this.round_obj) ? this.round_obj.description : '';
                },
                round_timeout: function(){
                    return (this.round_obj) ? this.round_obj.timeout : '';
                },
                round_date: function(){
                    return (this.round_obj) ? this.round_obj.date : '';
                },
                round_sub_round: function(){
                    return (this.round_obj && this.round_obj.sub_round) ? this.round_obj.sub_round : '';
                },
                is_pes_page: function(){
                    return !!(this.$route.meta && this.$route.meta.group === 'pes');
                },
                round_id: function(){
                    return (this.round_obj) ? this.round_obj.id : null;
                },
                category_list: function(){
                    return store.state.lotto.category_list;
                },
                price_dynamic: {
                    get(){
                        return store.state.lotto.price_dynamic;
                    },
                    set(value){
                        store.commit('lotto/update_price_dynamic', value);
                    }
                },
                price_dynamic_run: {
                    get(){
                        return store.state.lotto.price_dynamic_run;
                    },
                    set(value){
                        store.commit('lotto/updatePriceDynamicRun', value);
                    }
                },
                price_dynamic_3: {
                    get(){
                        return store.state.lotto.price_dynamic_3;
                    },
                    set(value){
                        store.commit('lotto/update_price_dynamic_3', value);
                    }
                },
                price_dynamic_3_run: {
                    get(){
                        return store.state.lotto.price_dynamic_3_run;
                    },
                    set(value){
                        store.commit('lotto/updatePriceDynamic3Run', value);
                    }
                },
                amount_all(){
                    return store.state.lotto.amount_all;
                },
                round_obj: {
                    get(){
                        return store.state.lotto.round_obj;
                    },
                    set(value){
                        store.commit('lotto/update_round_obj', value);
                    }
                },
                bet_list_index: {
                    get(){
                        return store.state.lotto.bet_list_index;
                    },
                    set(value){
                        store.commit('lotto/update_bet_list_index', value);
                    }
                },
                bet_list_number_str(){
                    let str = '';
                    for(let i = 0;i < this.bet_list.length;i++){
                        let bet = this.bet_list[i];
                        str += bet.number;
                        if(i+1 < this.bet_list.length){
                            str += ', ';
                        }
                    }
                    return (str) ? str : '-ไม่มีรายการ-';
                },
                query_bet_type(){
                    return this.$route.query.bet_type;
                },
                query_number(){
                    return this.$route.query.number;
                },
                max_reward(){
                    return RewardHandle.getMaxReward(this.price_dynamic_arr)
                },
                max_reward_3(){
                    return RewardHandle.getMaxReward(this.price_dynamic_arr_3)
                },
                max_reward_wing_bon(){
                    return RewardHandle.getMaxReward(this.price_dynamic_arr_3_run)
                },
                max_reward_wing_lang(){
                    return RewardHandle.getMaxReward(this.price_dynamic_arr_run)
                },
                reward_limit: {
                    get(){
                        return store.state.lotto.reward_limit
                    },
                    set(val){
                        store.commit('lotto/updateRewardLimit', val)
                    }
                },
                isOnBetDoneModalShow() {
                    return store.state.lotto.isOnBetDoneModalShow
                },
            },
            watch: {
                number_choose_digits: function () {
                    this.clear_choose_number();
                },
                bet_list_length: function (new_val, old_val) {
                    if (new_val === 0) {
                        this.bet_list_index = 0;
                        this.show_bet_panel = false;
                    }else{
                        if((new_val - old_val) === 1){
                            this.is_bouncing = false;
                            const _this = this;
                            setTimeout(function(){
                                _this.is_bouncing = true;
                            }, 100);
                        }
                    }
                },
                amount_all: function (new_val) {
                    if (new_val) {
                        for (let i = 0; i < this.bet_list.length; i++) {
                            this.bet_list[i].amount = new_val;
                        }
                    }
                },
                select_category_list: function () {
                    if (!this.have_option) {
                        this.category_option = false;
                    }
                },
                category_option: function (new_val) {
                    if (new_val) {
                        let i = this.select_category_list.findIndex((o) => {
                            return o.slug === 'song_tua_glub'
                        });
                        if (i > -1) {
                            store.commit('lotto/remove_select_category', i);
                        }
                    }
                },
                show_bet_panel: function (new_val) {
                    if (new_val) {
                        this.$modal.show('bet_panel_drawer');
                    } else {
                        this.$modal.hide('bet_panel_drawer');
                    }
                },
                round_id: async function(){
                    store.dispatch('lotto/clearBetList')
                    await Promise.all([
                        this.get_dynamic_reward(),
                        this.loadNumberSettingArr(this.round_lotto_type),
                        this.loadConfig(this.round_lotto_type),
                    ])
                    if(this.query_number && this.is_round_open){
                        this.addNumberByQuery(this.query_number)
                    }
                },
                query_bet_type(new_val){
                    if(new_val){
                        this.setCategoryByQueryBetType(new_val);
                    }
                },
                price_dynamic_sort_by(new_val){
                    if(new_val){
                        this.price_dynamic_filtered = this.price_dynamic_arr
                        this.price_3_dynamic_filtered = this.price_dynamic_arr_3
                    }
                },
                accept_any_odd(newVal) {
                    if(newVal) {
                        console.log('accept_any_odd', newVal)
                        this.get_dynamic_reward()
                    }
                },
                round_lotto_type(newVal) {
                    if(newVal) {
                        this.get_dynamic_reward()
                    }
                }
            },
            methods: {
                goHome() {
                    this.$router.push({name: 'IndexLotto'})
                },
                category_list_extract: function () {
                    let category_list = this.select_category_list.map((o) => {
                        return o.slug
                    });
                    let is_glub = !!(category_list.find((o) => {
                        return o === 'song_tua_glub' || o === 'sam_tua_glub'
                    }));
                    if (is_glub) {
                        category_list = category_list.filter((o) => {
                            return o !== 'song_tua_glub' && o !== 'sam_tua_glub'
                        })
                    }
                    return {
                        is_glub: is_glub,
                        category_list: category_list
                    }
                },
                number_list_to_str: function (arr) {
                    let number_str = '';
                    for (let choose_number of arr) {
                        number_str += choose_number + '';
                    }
                    return number_str;
                },
                clear_choose_number: async function () {
                    this.choose_number_list = [];
                    for (let i = 0; i < this.number_choose_digits; i++) {
                        this.choose_number_list.push('');
                    }
                    this.current_digit = 0;
                },
                select_category: function (category) {

                    const select_category_list = this.select_category_list.filter((c) => {

                        const in_group = category.group.find((g) => {
                            return g === c.slug
                        });

                        return (in_group);
                    });

                    store.commit('lotto/clear_select_category');
                    for (let select_category of select_category_list) {
                        store.commit('lotto/add_select_category', select_category);
                    }
                    store.commit('lotto/add_select_category', category);
                },
                un_select_category: function (i) {
                    store.commit('lotto/remove_select_category', i);
                },
                select_category_option: function (category_option) {

                    const song_tua_glub_index = this.select_category_list.findIndex((select_category) => {
                        return select_category.slug === 'song_tua_glub'
                    })

                    if(song_tua_glub_index > -1){
                        store.commit('lotto/remove_select_category', song_tua_glub_index)
                    }

                    if (category_option.number_list) {
                        this.category_option = category_option;
                        this.add_bet();
                    } else {
                        if (this.category_option.slug === category_option.slug) {
                            this.category_option = false;
                        } else {
                            this.category_option = category_option;
                        }
                    }
                },
                choose_number: async function (number) {
                    if (this.current_digit < this.choose_number_list.length) {
                        this.choose_number_list.splice(this.current_digit++, 1, number);
                        if (this.current_digit === this.choose_number_list.length) {
                            const _this = this;
                            setTimeout(() => {
                                _this.add_bet();
                            }, 400);
                        }
                    }
                },
                backspace: function () {
                    if (this.current_digit > 0) {
                        this.choose_number_list.splice(--this.current_digit, 1, '');
                    }
                },
                cancel_recent: function () {

                    if (this.bet_list.length > 0) {

                        this.bet_list_index--;

                        let bet_list = this.bet_list.filter((bet) => {
                            return bet.bet_list_index !== this.bet_list_index
                        });

                        store.dispatch('lotto/clearBetListOnly');
                        for (let bet of bet_list) {
                            store.commit('lotto/add_bet_list', bet);
                        }
                    }
                },
                cancel_by_number: function (number) {
                    store.dispatch('lotto/removeBetListByNumber', number);
                },
                cancel_by_bet_list_index: function (bet_list_index) {
                    store.dispatch('lotto/removeBetListByBetListIndex', bet_list_index);
                },
                add_bet: async function (optional_number_arr = []) {

                    if(this.select_category_list.length <= 0){
                        this.$bvToast.toast(`กรุณาเลือกรายการก่อน`, {'title': 'แจ้งเตือน', 'autoHideDelay': 1000});
                        return false;
                    }

                    let number_str = this.number_list_to_str(this.choose_number_list);

                    let category_extracted = this.category_list_extract();

                    let list_to_bet = [];

                    let add_case

                    if(optional_number_arr.length > 0){
                        for (let optional_number of optional_number_arr) {
                            list_to_bet.push({
                                number: optional_number.number,
                                amount: this.amount_all || 1,
                                category_list: [optional_number.category]
                            });
                        }
                    }else if(this.category_option) {
                        if (this.category_option.number_list) {
                            for (let number of this.category_option.number_list) {
                                list_to_bet.push({
                                    number: number,
                                    amount: this.amount_all || 1,
                                    category_list: category_extracted.category_list
                                });
                            }
                            this.category_option = false;
                        } else {

                            let number_list = [];

                            if (this.category_option.slug === 'sib_kao_pratu') {
                                number_list = sib_kao_pratu_number_list(number_str);
                            } else if (this.category_option.slug === 'rood_nha') {
                                number_list = rood_nha_number_list(number_str);
                            } else if (this.category_option.slug === 'rood_lang') {
                                number_list = rood_lang_number_list(number_str);
                            }

                            for (let number of number_list) {
                                list_to_bet.push({
                                    number: number,
                                    amount: this.amount_all || 1,
                                    category_list: category_extracted.category_list
                                });
                            }
                        }
                    }else if(category_extracted.is_glub){

                        let glub_list = new Set(array_permutation(this.choose_number_list));

                        for (let glub of glub_list) {
                            list_to_bet.push({
                                number: glub,
                                amount: this.amount_all || 1,
                                category_list: category_extracted.category_list
                            });
                        }
                    }else{
                        add_case = 'single'
                        list_to_bet.push({
                            number: number_str,
                            amount: this.amount_all || 1,
                            category_list: category_extracted.category_list
                        });
                    }

                    const checkNumberEnable = (bet_type, number, price) => {
                        const is_number_disable = this.checkIsNumberDisable(this.number_setting_arr, [bet_type], number)
                        const multi_min = this.reward_limit[this.round_lotto_type][bet_type]['min']
                        const multi_max = this.reward_limit[this.round_lotto_type][bet_type]['max']
                        const is_reward_exceed = this.checkIsRewardExceed(price, multi_min, multi_max)
                        return !is_number_disable && !is_reward_exceed
                    }

                    let bet_add_count = 0

                    for (let to_bet of list_to_bet) {

                        for (let category of to_bet.category_list) {

                            const reward = this.bet_get_price(category, to_bet.number)

                            let bet = {
                                bet_id: this.bet_list.length - 1,
                                number: to_bet.number,
                                amount: to_bet.amount,
                                bet_type: category,
                                bet_list_index: this.bet_list_index,
                                reward: reward,
                                number_bet_type: to_bet.number+':'+category,
                                is_allow: checkNumberEnable(category, to_bet.number, reward)
                            };

                            // if(checkNumberEnable(bet.bet_type, bet.number, bet.reward)){
                                store.commit('lotto/add_bet_list', bet);
                                bet_add_count++
                            // }
                        }
                    }

                    sort_wrapper(this.bet_list, 'bet_id', true)

                    if(bet_add_count > 0){
                        this.$bvToast.toast(`เพิ่ม ${bet_add_count} รายการ`, {'title': 'สำเร็จ', 'autoHideDelay': 1000});
                        this.bet_list_index++;
                    }else{
                        if(add_case === 'single'){
                            this.$bvToast.toast('ยอดซื้อเต็ม!! กำลังปรับเรทอัตราจ่ายใหม่', {title: 'แจ้งเตือน'});
                        }
                    }
                    await this.clear_choose_number();
                },
                calNumberIsAllow(number, bet_type_group, price) {

                    if(!this.reward_limit) {
                        return true
                    }
                    if(!price) {
                        return false
                    }
                    const is_number_disable = this.checkIsNumberDisable(this.number_setting_arr, this.select_category_arr, number)

                    const multi_min = this.reward_limit[this.round_lotto_type][bet_type_group]['min']
                    const multi_max = this.reward_limit[this.round_lotto_type][bet_type_group]['max']
                    const is_reward_exceed = this.checkIsRewardExceed(price, multi_min, multi_max)
                    return !is_number_disable && !is_reward_exceed
                },
                get_dynamic_reward: async function () {
                    const progress_res = await axios_wrapper({
                        method: 'POST',
                        url: `${this.envDomains.VUE_APP_LOTTO_ADMIN_API}/api/price/load_dynamic`,
                        data: {
                            lotto_type: this.round_lotto_type
                        }
                    });
                    if (progress_res.status === 200) {

                        this.price_dynamic = progress_res.data.data
                        this.price_dynamic_run = progress_res.data.data_run

                        this.price_dynamic_arr = progress_res.data.data_arr.map((price) => {
                            price.is_allow = this.calNumberIsAllow(price.number_display, 'song_tua_bon', price.reward)
                            return price
                        })
                        this.price_dynamic_arr_run = progress_res.data.data_arr_run.map((price) => {
                            price.is_allow = this.calNumberIsAllow(price.number_display, this.wing_type_cal, price.reward)
                            return price
                        })

                        this.price_dynamic_3 = progress_res.data.data_3
                        this.price_dynamic_3_run = progress_res.data.data_3_run

                        this.price_dynamic_arr_3 = progress_res.data.data_arr_3.map((price) => {
                            price.is_allow = this.calNumberIsAllow(price.number_display, 'sam_tua_bon', price.reward)
                            return price
                        })
                        this.price_dynamic_arr_3_run = progress_res.data.data_arr_3_run.map((price) => {
                            price.is_allow = this.calNumberIsAllow(price.number_display, this.wing_type_cal, price.reward)
                            return price
                        })

                        if (this.accept_any_odd && !this.isOnBetDoneModalShow) {
                            this.update_bet_list();
                        }
                    } else {
                        this.$bvToast.toast(progress_res.data.message, {title: 'แจ้งเตือน'});
                    }
                    return true
                },
                price_3_paging: function(page){
                    if(this.price_dynamic_filter_by){
                        return this.price_3_dynamic_filtered;
                    }else if(this.price_dynamic_sort_by === 'price' || this.price_dynamic_sort_by === 'price_desc'){
                        return this.price_3_dynamic_filtered.slice(0, 100);
                    }else{
                        return this.price_3_dynamic_filtered.slice(page*100, page*100+100);
                    }
                },
                checkRoundOpen: function (round_status, round_timeout) {
                    const now = this.$moment();
                    const timeout = this.$moment(round_timeout);
                    return (round_status === 'open' && now.isSameOrBefore(timeout));
                },
                setCategoryByTab(tab){

                    store.commit('lotto/clear_select_category');

                    if(tab === '#three-tab' || tab === '#select2'){
                        this.select_category(this.category_list['sam_tua_bon'])
                    }else if(tab === '#two-tab'){
                        this.select_category(this.category_list['song_tua_bon']);
                    }else if(tab === '#run-tab'){
                        this.select_category(this.category_list['wing_bon']);
                    }
                },
                setCategoryByQueryBetType(query_bet_type){

                    const selectCategory = (bet_type, is_set_tap) => {

                        let allow_arr;
                        if(this.round_lotto_type !== 'government'){
                            allow_arr = bet_type_arr.filter((o) => {return (o !== 'sam_tua_nha' && o !== 'sam_tua_lang')});
                        }else {
                            allow_arr = bet_type_arr;
                        }

                        let is_allow = allow_arr.find((o) => {
                            return o === bet_type;
                        });

                        if(is_allow){
                            if(is_set_tap){
                                this.$jquery('a[data-toggle="tab"][href="'+this.getTabNameByBetType(bet_type)+'"]').tab('show');
                            }
                            this.select_category(this.category_list[bet_type]);
                        }else{
                            this.select_category(this.category_list['song_tua_bon']);
                        }
                    }

                    store.commit('lotto/clear_select_category')

                    if(!query_bet_type){
                        this.select_category(this.category_list['song_tua_bon'])
                        return
                    }

                    const bet_types = query_bet_type.split(',')

                    for(let i = 0;i < bet_types.length;i++){
                        const bet_type = bet_types[i]
                        selectCategory(bet_type, (i+1) === bet_types.length)
                    }
                },
                getTabNameByBetType(bet_type){
                    const map = {
                        'sam_tua_bon': '#three-tab',
                        'sam_tua_tod': '#three-tab',
                        'sam_tua_nha': '#three-tab',
                        'sam_tua_lang': '#three-tab',
                        'song_tua_bon': '#two-tab',
                        'song_tua_lang': '#two-tab',
                        'wing_bon': '#run-tab',
                        'wing_lang': '#run-tab',
                    }
                    return map[bet_type];
                },
                showBetPanel(){
                    if(this.bet_list.length === 0) {
                        this.$refs['chooseNumberBeforeAddPriceModal'].show()
                        return
                    }
                    this.show_bet_panel = true;
                },
                async loadNumberSettingArr(lotto_type){
                    this.number_setting_arr = await this.getNumberSettingArr(lotto_type)
                    return true
                },
                async loadConfig(lotto_type){
                    this.config = await this.apiGetConfig(lotto_type)
                    return true
                },
                addNumberByQuery(query){

                    const getCategoryAllowLength = (select_category) => {
                        let allow_length = 0
                        if(select_category.includes('sam')){
                            allow_length = 3
                        }else if(select_category.includes('song')){
                            allow_length = 2
                        }else if(select_category.includes('wing')){
                            allow_length = 1
                        }
                        return allow_length
                    }

                    const isAllowBetType = (bet_type) => {
                        let allow_arr = bet_type_arr
                        if(this.round_lotto_type !== 'government'){
                            allow_arr = bet_type_arr.filter((o) => {
                                return (o !== 'sam_tua_nha' && o !== 'sam_tua_lang')
                            })
                        }
                        return allow_arr.find((o) => {
                            return o === bet_type
                        })
                    }

                    const number_arr = query.split(',')
                    .map((raw) => {
                        const [category, number] = raw.split(':')
                        return {
                            category,
                            number: number.replace(/[^0-9]/ig, '')
                        }
                    })
                    .filter((o) => {
                        return o.number.length === getCategoryAllowLength(o.category)
                            && isAllowBetType(o.category)
                    })
                    if(!number_arr || number_arr.length === 0) {
                        return
                    }
                    this.add_bet(number_arr)
                    this.showBetPanel()
                },
                goBillDetail(bill_id) {
                    this.selectedBillId = bill_id
                    this.$modal.show('billDetailModal')
                },
            },
            created() {

                store.commit('lotto/update_category_list', CATEGORY_LIST);

                this.category_option_list = CATEGORY_SONG_TUA_LIST;
                this.bill_key = BILL_KEY;
                this.condition_list = CONDITION_LIST;
            },
            async mounted() {

                if(this.$route.query['lotto_type']) {
                    store.commit('lotto/updatePageBetQueryLottoType', this.$route.query['lotto_type'])
                }

                this.loadPriceTable()
                this.loadRewardLimit()

                const _this = this;

                this.$jquery('a[data-toggle="tab"]').on('shown.bs.tab click', function (e) {

                    _this.category_option = false;

                    if(e.target.getAttribute('href') === '#select1'){
                        _this.price_panel_tab = findActiveTab(1).getAttribute('href');
                    }else{
                        _this.price_panel_tab = e.target.getAttribute('href');
                    }

                    if(!_this.query_bet_type || e.type === 'click'){
                        _this.setCategoryByTab(_this.price_panel_tab);
                    }
                });

                this.$jquery('a[data-toggle="pill"]').on('shown.bs.tab', function (e) {
                    _this.price_panel_3_page = e.target.getAttribute('i')
                });

                const findActiveTab = function(i){
                    const tab = _this.$jquery('a[data-toggle="tab"].active');
                    return tab[i];
                }

                this.setCategoryByQueryBetType(this.query_bet_type)
            }
        };



</script>

<style src="@/assets/css/lotto/reset.css"></style>
<style src="@/assets/css/lotto/style.css"></style>
<style src="@/assets/css/lotto/money.css"></style>
<style src="@/assets/css/lotto/bet.css"></style>

<style scoped>
    .sticky{
        position: fixed;
        width: 100%;
        top: 0;
    }
    .ViewBetLotto .box-txt{display: none}
    .nav-top{
        position: absolute;
    }
    .nav-top .text-right {
        margin-right: 44px;
    }
    .nav-top h3{
        position: absolute;
        font: 800 18px Athiti;
        top: -39px;
        left: 48px;

    }
    .v-extend-margin-bottom {
        /*padding-bottom: 5px !important;*/
        overflow: hidden;
        /*min-height: 100vh;*/
    }

    .v-fixed-bet-panel-mobile {
        display: flex;
        position: fixed;
        bottom: 0;
        height: 70px;
        width: 100%;
        background-color: #fff;
        color: #000;
        z-index: 2;
        border-top: solid 1px #aaa;
    }

    .v-fixed-bet-panel-mobile .v-inner {
        display: flex;
        padding: 5px 10px;
        width: 100%;
        justify-content: space-between;
        align-items: center;
    }

    .v-fixed-bet-panel-mobile .v-inner .v-left {
        width: calc(100% - 130px);
    }

    .v-fixed-bet-panel-mobile .v-inner .v-right {
        width: 120px;
    }

    .v-text-overflow-ellipsis {
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
        width: 100%;
        line-height: 1;
    }

    .v-float-number {
        height: 50px;
        width: 50px;
        border-radius: 50%;
        background-color: #43434f;
        color: #f5ba62;
        position: fixed;
        left: calc(50% - 25px);
        bottom: 0;
    }

    .v-float-number .v-inner {
        display: flex;
        justify-content: center;
        align-items: center;
        height: 100%;
        width: 100%;
        font-size: 22px;
    }

    .v-throw-to-bottom {
        animation-duration: 0.5s;
        animation-iteration-count: 1;
        animation-name: throw-to-bottom;
        animation-timing-function: cubic-bezier(1, 0.2, 0.1, 0.1);
    }
    @keyframes throw-to-bottom {
        0% {
            bottom: calc(50% - 25px);
            transform: scale(1, 1);
        }
        10% {
            transform: scale(1.5, 1.5);
        }
        100% {
            bottom: 0;
            transform: scale(0.3, 0.3);
        }
    }

    .box-content-tab-select {
        position: relative;
        opacity: 1;
        filter: grayscale(0);
        z-index: 10;
    }
    .box-content-tab-select h3 {
        line-height: 60px;
        font-size: 23px;
    }
    .box-content-tab-dropdown ul {
        color: #000;
        width: 100%;
        display: flex;
        border-bottom: solid 1px rgba(0, 0, 0, 0.2);
    }
    .box-content-tab-dropdown ul:hover {
        background-color: rgba(0, 0, 0, 0.05);
        cursor: pointer;
    }
    .box-content-tab-dropdown ul:last-child {
        border-bottom: 0;
    }
    .box-content-tab-dropdown ul li {
        padding: 5px 5%;
        vertical-align: top;
        flex: 1;
    }
    .box-content-tab-dropdown ul li:first-child {
        text-align: left;
    }
    .box-content-tab-dropdown ul li:last-child {
        text-align: right;
    }
    .box-content-tab-dropdown ul li h4 {
        margin: 0;
        font-size: 16px;
    }
    .box-content-tab-dropdown ul li p {
        margin: 0;
        font-size: 11px;
        color: rgba(0, 0, 0, 0.3);
    }
    .box-content-tab-dropdown {
        display: none;
        position: absolute;
        background-color: #eee;
        left: 0;
        top: 80px;
        right: 0;
        border-radius: 6px;
        font-size: 16px;
        z-index: 100;
    }
    .box-content-tab-select:hover .box-content-tab-dropdown {
        display: block;
    }
    .box-content-tab-select:after {
        border: 3px solid;
        border-color: #eee;
        border-radius: 2px;
        border-right: 0;
        border-top: 0;
        content: " ";
        display: block;
        height: 0.625em;
        margin-top: -0.4375em;
        pointer-events: none;
        position: absolute;
        top: 50%;
        transform: rotate(-45deg);
        transform-origin: center;
        width: 0.625em;
        right: 10px;
    }

    @media (min-width: 320px) and (max-width: 768px) {
    }



    .bounce-7 {
        animation-duration: 1s;
        animation-iteration-count: 1;
        animation-name: bounce-7;
        animation-timing-function: cubic-bezier(0.28, 0.84, 0.42, 1);
    }
    @keyframes bounce-7 {
        0% {
            transform: scale(1, 1) translateY(0);
        }
        10% {
            transform: scale(1.1, 0.9) translateY(0);
        }
        30% {
            transform: scale(0.9, 1.1) translateY(-30px);
        }
        50% {
            transform: scale(1.05, 0.95) translateY(0);
        }
        57% {
            transform: scale(1, 1) translateY(-7px);
        }
        64% {
            transform: scale(1, 1) translateY(0);
        }
        100% {
            transform: scale(1, 1) translateY(0);
        }
    }

    .v-bet-list-length-float {
        height: 24px;
        /*width: 24px;*/
        background-color: #000;
        color: white;
        border-radius: 15px;
        position: absolute;
        top: 4px;
        right: 2px;
        padding: 1px 5px;
        display: flex;
        justify-content: center;
        align-items: center;
    }

    .v-loading-wrapper {
        width: 100%;
        min-height: 100px;
        display: flex;
        justify-content: center;
        align-items: center;
    }

    #bill_panel .modal-dialog {
        margin: 0 !important;
    }
    .nav-top{width: 100%; height: auto; border: 0;}
    .nav-top .col-nav{
        position: absolute;
        top: -52px;
        z-index: 3;
    }
    .nav-top .row-balance{display: none}
    .nav-top .el-button.is-circle{display: none}
    /*.all-header-box,*/

    .wrap-content-scroll{
        /*overflow: hidden;*/
        /*border-radius: 20px 20px 0;*/
    }
    @media (orientation: landscape) {
        .v-fixed-bet-panel-mobile {
            /*height: 70px;*/
            /*width: auto;*/
            /*right: 0;*/
            /*left: 350px;*/
            /*box-shadow: 0 2px 15px #000;*/
        }
    }




</style>

