<template>
    <div class="Uploader">
        <el-upload
        ref="upload"
        class="upload-demo"
        :action="action"
        :limit="limit"
        :multiple="multiple"
        :before-remove="beforeRemove"
        :on-exceed="handleExceed"
        :on-change="handleChange"
        :on-remove="handleRemove"
        :on-success="handleSuccess"
        :on-error="handleError"
        :file-list="fileList"
        :data="data"
        :auto-upload="!isImageDimensionLimit">
        <el-button v-if="!isImageDimensionLimit" size="small" type="primary">อัพโหลด</el-button>
        <el-button v-if="isImageDimensionLimit" slot="trigger" size="small" type="primary" :disabled="isOnVerify">เลือกไฟล์</el-button>
        <el-button v-if="isImageDimensionLimit" style="margin-left: 10px;" size="small" type="success" @click="submitUpload" :disabled="isOnVerify || this.fileList.length === 0">
            <span v-if="isOnVerify">กำลังอัพโหลดรูปภาพ...</span>
            <span v-else>อัพโหลด</span>
        </el-button>
        <div slot="tip" class="el-upload__tip">
            <div>jpg/png ไฟล์ขนาดไม่เกิน 500kb</div>
            <div v-if="isImageDimensionLimit">ขนาดรูป กว้าง {{widthLimitLabel}} พิกเซล x สูง {{heightLimitLabel}} พิกเซล</div>
        </div>
        </el-upload>
    </div>
</template>
<script>
export default {
    name: 'Uploader',
    props: ['limit', 'multiple', 'data', 'bus', 'minWidth', 'minHeight', 'maxWidth', 'maxHeight'],
    data() {
        return {
            fileList: [],
            isOnVerify: false,
        }
    },
    computed: {
        action(){
            return `${this.envDomains.VUE_APP_API}/api/admin/image-upload`
        },
        isImageDimensionLimit() {
            return this.minWidth && this.minHeight && this.maxWidth && this.maxHeight
        },
        widthLimitLabel() {
            if(this.minWidth === this.maxWidth) {
                return this.minWidth
            } else {
                return `${this.minWidth} - ${this.maxWidth}`
            }
        },
        heightLimitLabel() {
            if(this.minHeight === this.maxHeight) {
                return this.minHeight
            } else {
                return `${this.minHeight} - ${this.maxHeight}`
            }
        },
    },
    methods: {
        handleExceed() {
            const message = `เลือกรูปได้ไม่เกิน ${this.limit} ไฟล์`
            this.$alert(message, 'แจ้งเตือน', {
                confirmButtonText: 'ตกลง',
                customClass: 'custom-message-box'
            })
        },
        handleChange(file, fileList) {
            if(!this.limit){
                this.fileList = [fileList[fileList.length - 1]]
            }
            if(this.isImageDimensionLimit) {
                this.fileList = fileList
            }
        },
        beforeRemove(file) {
            return this.$confirm(`ต้องการลบ ${ file.name } ?`, {title: 'แจ้งเตือน'});
        },
        handleRemove(file){
            this.$emit('remove', file.response.url)
        },
        handleSuccess(response){
            this.$emit('done', response.url)
        },
        handleError(err){
            this.$alert(err.message, 'แจ้งเตือน', {
                confirmButtonText: 'ตกลง',
                customClass: 'custom-message-box'
            })
        },
        clear(){
            this.fileList = []
        },
        async submitUpload() {

            const getImageDimension = async (url) => {
                return new Promise((resolve) => {
                    const img = new Image();
                    img.onload = function() {
                        resolve({
                            width: this.width,
                            height: this.height,
                        })
                    }
                    img.src = url
                })
            }

            const getImageUrl = async (file) => {
                return new Promise((resolve) => {
                    const fileReader = new FileReader()
                    fileReader.onload = function() {
                        resolve(this.result)
                    }
                    fileReader.readAsDataURL(file)
                })
            }

            const verify = async (file) => {

                let widthValid = false
                let heightValid = false

                const imageUrl = await getImageUrl(file)
                const imageDimension = await getImageDimension(imageUrl)

                if(this.minWidth === this.maxWidth) {
                    if(imageDimension.width === this.minWidth) {
                        widthValid = true
                    }
                } else {
                    if(imageDimension.width >= this.minWidth && imageDimension.width <= this.maxWidth) {
                        widthValid = true
                    }
                }

                if(this.minHeight === this.maxHeight) {
                    if(imageDimension.height === this.minHeight) {
                        heightValid = true
                    }
                } else {
                    if(imageDimension.height >= this.minHeight && imageDimension.height <= this.maxHeight) {
                        heightValid = true
                    }
                }

                if(widthValid && heightValid) {
                    return true
                } else {
                    this.$alert('ขนาดรูปไม่ถูกต้อง', 'แจ้งเตือน', {
                        confirmButtonText: 'ตกลง',
                        customClass: 'custom-message-box'
                    })
                    return false
                }
            }

            this.isOnVerify = true

            for(const file of this.fileList) {
                file.isVerify = await verify(file.raw)
            }
            this.fileList = this.fileList.filter((file) => {
                return file.isVerify
            })

            this.isOnVerify = false

            if(this.fileList.length <= 0) {
                return
            }

            this.$refs.upload.submit()
        },
    },
    mounted(){
        if(this.bus){
            this.bus.$on('clear', this.clear)
        }
    }
}
</script>