<template>

    <div class="wrapContentIndex wrapContentIndexPopular wrapDiamondMode">
        <!--     ///////////Modal////////////////////////////////-->
        <!--            แลกเพชร-->
        <div class="OverAllContainer hide">
            <div class="ContentShopExchange">
                <div class="title">
                    <div class="title-logo">
                        <img src="@/assets/images/bg-topUp/icon-Exchange.png" class="title-logo-img">
                    </div>
                    <div class="close-box-bonus"></div>
                </div>
                <div class="ContentShopExchangeInfo">
                    <ul class="activeExchange">
                        <li>
                            <img src="@/assets/images/diamond-icon.png"/>20,000
                        </li>
                        <li><i class="fas fa-chevron-circle-right"></i></li>
                        <li>
                            <img src="@/assets/images/coin-small-1.png"/>200
                        </li>
                        <li>
                            <div class="buttonBonus"><span>แลก</span></div>
                        </li>
                    </ul>
                    <ul>
                        <li>
                            <img src="@/assets/images/diamond-icon.png"/>9,000
                        </li>
                        <li><i class="fas fa-chevron-circle-right"></i></li>
                        <li>
                            <img src="@/assets/images/coin-small-1.png"/>90
                        </li>
                        <li>
                            <div class="buttonBonus"><span>แลก</span></div>
                        </li>
                    </ul>
                    <ul>
                        <li>
                            <img src="@/assets/images/diamond-icon.png"/>7,000
                        </li>
                        <li><i class="fas fa-chevron-circle-right"></i></li>
                        <li>
                            <img src="@/assets/images/coin-small-1.png"/>70
                        </li>
                        <li>
                            <div class="buttonBonus"><span>แลก</span></div>
                        </li>
                    </ul>
                    <ul>
                        <li>
                            <img src="@/assets/images/diamond-icon.png"/>5,000
                        </li>
                        <li><i class="fas fa-chevron-circle-right"></i></li>
                        <li>
                            <img src="@/assets/images/coin-small-1.png"/>50
                        </li>
                        <li>
                            <div class="buttonBonus"><span>แลก</span></div>
                        </li>
                    </ul>
                    <ul>
                        <li>
                            <img src="@/assets/images/diamond-icon.png"/>3,000
                        </li>
                        <li><i class="fas fa-chevron-circle-right"></i></li>
                        <li>
                            <img src="@/assets/images/coin-small-1.png"/>30
                        </li>
                        <li>
                            <div class="buttonBonus"><span>แลก</span></div>
                        </li>
                    </ul>
                    <ul>
                        <li>
                            <img src="@/assets/images/diamond-icon.png"/>1,000
                        </li>
                        <li><i class="fas fa-chevron-circle-right"></i></li>
                        <li>
                            <img src="@/assets/images/coin-small-1.png"/>10
                        </li>
                        <li>
                            <div class="buttonBonus"><span>แลก</span></div>
                        </li>
                    </ul>
                </div>
            </div>
        </div>

        <!--            ยืนยันแลกเงิน     -->
        <div class="box-award box-level-up hide">
            <div class="box-alert-content">
                <div class="content-all">
                    <div class="contentTopOver">
                        <div class="footer">
                            <button type="button" class="btn">ยืนยัน</button>
                        </div>
                    </div>
                    <div class="content">
                        <div class="close-box-bonus"></div>
                        <div class="box-level-up">
                            <div class="box-level-up-detail">
                                <h5>การแลกสำเร็จ</h5>
                                <strong>คุณได้รับของรางวัล</strong>
                            </div>
                            <div class="row-box-award">
                                <div class="item-award">
                                    <div class="set-coin">
                                        <img src="@/assets/images/coin-small-1.png"/>
                                        <img src="@/assets/images/spint-gold.png" class="bg-spin">
                                    </div>
                                    <strong><img src="@/assets/images/coin.png"/> 30</strong>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="content-bg"></div>
                </div>
            </div>
        </div>

        <!--            สปิน วงล้อ -->
        <div class="OverAllContainer ">  <!-- class active  OverAllContainerShow -->
            <div class="ContentSpinFortune">  <!-- class active  activeSpinWheel -->
                <div class="title">
                    <div class="title-logo">
                        <img src="@/assets/images/bg-topUp/icon-spinFortune.png" class="title-logo-img">
                    </div>
                    <img src="@/assets/images/spint-gold.png" class="bg-spin ">
                    <div class="close-box-bonus"></div>
                </div>
                <div class="wheel-canvas-layout">
                    <div class="btnStart">
                        <span>
                            <div class="Loading">
                            <div class="loading-fantasy">
                                <div class="spinner-grow"></div>
                                <div class="spinner-grow"></div>
                                <div class="spinner-grow"></div>
                            </div>
                        </div>
                        </span>

                    </div>
                    <div class="boxWinkWheels">
                        <div class="boxWink" style="top: 49px; left: 52px;"></div>
                        <div class="boxWink" style="top: -12px; left: 201px;"></div>
                        <div class="boxWink" style="top: 52px; left: 354px;"></div>
                        <div class="boxWink" style="top: 204px; left: 411px;"></div>
                        <div class="boxWink" style="top: 354px; left: 350px;"></div>
                        <div class="boxWink" style="top: 413px; left: 198px;"></div>
                        <div class="boxWink" style="top: 349px; left: 52px;"></div>
                        <div class="boxWink" style="top: 198px; left: -13px;"></div>
                    </div>
                    <div class="wheel-canvas-triangle-down"></div>
                    <div class="wheelBgBorder"></div>
                    <div class="wheel-canvas-cells">
                        <div class="cell cell-1">
                            <div class="cellInfo">
                                <img src="@/assets/images/spin/diamond1.png">
                                <div class="badge">50</div>
                            </div>
                        </div>
                        <div class="cell cell-2">
                            <div class="cellInfo">
                                <img src="@/assets/images/spin/diamond1.png">
                                <div class="badge">500</div>
                            </div>
                        </div>
                        <div class="cell cell-3">
                            <div class="cellInfo">
                                <img src="@/assets/images/spin/diamond1.png">
                                <div class="badge">1,000</div>
                            </div>
                        </div>
                        <div class="cell cell-4">
                            <div class="cellInfo">
                                <img src="@/assets/images/spin/diamond1.png">
                                <div class="badge">5,000</div>
                            </div>
                        </div>
                        <div class="cell cell-5">
                            <div class="cellInfo">
                                <img src="@/assets/images/spin/diamond1.png">
                                <div class="badge">10,000</div>
                            </div>
                        </div>
                        <div class="cell cell-6">
                            <div class="cellInfo">
                                <img src="@/assets/images/spin/diamond1.png">
                                <div class="badge">20,000</div>
                            </div>
                        </div>
                        <div class="cell cell-7">
                            <div class="cellInfo">
                                <img src="@/assets/images/spin/diamond1.png">
                                <div class="badge">50,000</div>
                            </div>
                        </div>
                        <div class="cell cell-8">
                            <div class="cellInfo">
                                <img src="@/assets/images/spin/diamond1.png">
                                <div class="badge">100,000</div>
                            </div>
                        </div>

                    </div>
                </div>

            </div>
        </div>

        <!--            ได้เพขร     -->
        <div class="box-award box-level-up getDiamond hide">
            <div class="box-alert-content">
                <div class="content-all">
                    <div class="contentTopOver">
                        <div class="footer">
                            <button type="button" class="btn">ยืนยัน</button>
                        </div>
                    </div>
                    <div class="content">
                        <div class="close-box-bonus"></div>
                        <div class="box-level-up">
                            <div class="box-level-up-detail">
                                <h5>คุณได้รับเพชร</h5>
                                <!--                                <strong>คุณได้รับของรางวัล</strong>-->
                            </div>
                            <div class="row-box-award">
                                <div class="item-award">
                                    <div class="set-coin">
                                        <img src="@/assets/images/spin/diamond1.png"/>
                                        <img src="@/assets/images/spint-blue.png" class="bg-spin">
                                    </div>
                                    <strong><img src="@/assets/images/diamond-icon.png"/> 100</strong>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="content-bg"></div>
                </div>
            </div>
        </div>

        <div class="wrapContentIndexTitle">
            <div class="boxSwitchDiamondMode">
                <ul>
                    <li class="active"><img src="@/assets/images/diamond-icon.png"/><span>โหมดเพชร</span></li>
                    <li><img src="@/assets/images/coin-small-1.png"/><span>เครดิต</span></li>
                </ul>
            </div>
        </div>
        <MenuBar></MenuBar>
        <div class="wrapContentIndexInfo">
            <div class="popSlide bx-mobile bx-ipad">
                <carousel
                        :autoplayTimeout="5000"
                        :perPage="1"
                        :autoplay="true"
                        :mouseDrag="true"
                        :loop="true"
                        :centerMode="true"
                        :navigationEnabled="true"
                        :paginationEnabled="false"
                        navigationNextLabel="❯"
                        navigationPrevLabel="❮"
                >
                    <slide>
                        <div>
                            <img src="@/assets/images/banner/banner1.jpg"/>
                        </div>
                    </slide>
                    <slide>
                        <div>
                            <img src="@/assets/images/banner/banner2.jpg"/>
                        </div>
                    </slide>
                    <slide>
                        <div>
                            <img src="@/assets/images/banner/banner3.jpg"/>
                        </div>
                    </slide>
                    <slide>
                        <div>
                            <img src="@/assets/images/banner/banner4.jpg"/>
                        </div>
                    </slide>
                    <slide>
                        <div>
                            <img src="@/assets/images/banner/banner5.jpg"/>
                        </div>
                    </slide>
                    <slide>
                        <div>
                            <img src="@/assets/images/banner/banner6.jpg"/>
                        </div>
                    </slide>
                </carousel>
            </div>
            <div class="contentFlexIndex">
                <div class="contentFlexIndexRight">
                    <span class="topText">
                        <marquee>
                          <h2>คาสิโนออนไลน์ บาคาร่า ไฮโล เสือมังกร เว็บตรง</h2>
                        </marquee>
                    </span>
                    <div class="popSlide bx-desktop bx-ipad">
                        <carousel
                                :perPage="3"
                                :perPageCustom="[ [768, 2],[1400, 3],]"
                                :autoplay="true"
                                :mouseDrag="true"
                                :loop="true"
                                :centerMode="true"
                                :spacePadding="20"
                                :navigationEnabled="true"
                                :paginationEnabled="false"
                                navigationNextLabel="❯"
                                navigationPrevLabel="❮">
                            <slide>
                                <div>
                                    <img src="@/assets/images/banner/banner1.jpg"/>
                                </div>
                            </slide>
                            <slide>
                                <div>
                                    <img src="@/assets/images/banner/banner2.jpg"/>
                                </div>
                            </slide>
                            <slide>
                                <div>
                                    <img src="@/assets/images/banner/banner3.jpg"/>
                                </div>
                            </slide>
                            <slide>
                                <div>
                                    <img src="@/assets/images/banner/banner4.jpg"/>
                                </div>
                            </slide>
                            <slide>
                                <div>
                                    <img src="@/assets/images/banner/banner5.jpg"/>
                                </div>
                            </slide>
                            <slide>
                                <div>
                                    <img src="@/assets/images/banner/banner6.jpg"/>
                                </div>
                            </slide>
                        </carousel>
                    </div>
                    <div class="container">
                        <div class="wrapDiamondModeContainer">
                            <div class="boxHitecCasino boxHitecCasino1">
                                <div class="nav-menu-topic ">
                                    <img src="/img/casino-hitech.13762e26.png">
                                    <h4>ไฮเทค คาสิโน</h4>
                                </div>
                                <ul>
                                    <li><img src="@/assets/images/diamondMode/diamond_banner_bacarat.png"/></li>
                                    <li><img src="@/assets/images/diamondMode/diamond_banner_hilo.png"/></li>
                                    <li><img src="@/assets/images/diamondMode/diamond_banner_TG.png"/></li>
                                    <li><img src="@/assets/images/diamondMode/diamond_banner_fantan.png"/></li>
                                </ul>
                            </div>
                            <div class="boxHitecCasino boxHitecCasino2">
                                <div class="nav-menu-topic ">
                                    <img src="@/assets/images/gameOther.png">
                                    <h4>เกมอื่นๆ</h4>
                                </div>
                                <ul>
                                    <li>
                                        <div class="lottoDiamondMode">
                                            <span class="roundLotto">รอบที่ 10</span>
                                            <div class="tableLottoPrice">
                                                <ul>
                                                    <li>
                                                        <small>บาทละ</small>
                                                        <strong>900</strong>
                                                        <p>3 ตัวบน</p>
                                                    </li>
                                                    <li>
                                                        <small>บาทละ</small>
                                                        <strong>900</strong>
                                                        <p>3 ตัวบน</p>
                                                    </li>
                                                </ul>
                                            </div>
                                            <div class="timeLotto">
                                                สิ้นสุดในเวลา : 12:12:65
                                            </div>
                                        </div>
<!--                                        <img src="@/assets/images/diamondMode/diamond_banner_lotto2.png"/>-->
                                    </li>
                                    <li class="bx-desktop" style="overflow: hidden">
                                        <div class="boxSpinWheelSection">
                                            <img src="@/assets/images/spin/logo-spin1.png" class="logoSpinWheel">
                                            <img src="@/assets/images/spin/logo-spin1BG.png" class="bg-spin bg-spinWheel ">
                                            <img src="@/assets/images/spint-gold.png" class="bg-spin ">
                                            <img src="@/assets/images/diamondMode/diamond_banner_spin.png"/>
                                        </div>

                                    </li>
                                </ul>
                            </div>
                            <div class="boxHitecCasino boxHitecCasino2">
                                <div class="nav-menu-topic" style="margin-top: 20px;">
                                    <img src="@/assets/images/icon/set-icon/icon-spin2.png">
                                    <h4>กล่องสุ่ม</h4>
                                </div>
                                <div class="wrap-games-list full-width bt-jackpot-gold">
                                    <div class="container-fluid-jackpot">
                                        <div class="container-fluid-jackpot-info">
                                            <div class="container-jackpot-grand">
                                                <div class="detail">
                                                    <h2>GRAND</h2>
                                                    <ul>
                                                        <li><span class="bonus-10-0">75,728.71</span>
                                                        </li>
                                                        <li><p>70,000-3,50,000</p>
                                                        </li>
                                                    </ul>
                                                </div>
                                                <div class="progress">
                                                    <div role="progressbar" aria-valuenow="21"
                                                         aria-valuemin="0" aria-valuemax="100" class="progress-bar"
                                                         style="width: 21%;">
                                                        <div class="wink"></div>
                                                    </div>
                                                </div>
                                                <div class="jackpot-bg"></div>
                                            </div>
                                            <div class="container-jackpot-minor container-jackpot-major">
                                                <div class="detail"><h2>MAJOR</h2>
                                                    <ul>
                                                        <li><span class="bonus-10-1">25,728.71</span></li>
                                                        <li><p>20,000-70,000</p></li>
                                                    </ul>
                                                </div>
                                                <div class="progress">
                                                    <div role="progressbar" aria-valuenow="36"
                                                         aria-valuemin="0" aria-valuemax="100" class="progress-bar"
                                                         style="width: 36%;">
                                                        <div class="wink"></div>
                                                    </div>
                                                </div>
                                                <div class="jackpot-bg"></div>
                                            </div>
                                            <div class="container-jackpot-mini">
                                                <div class="detail"><h2>MINI</h2>
                                                    <ul>
                                                        <li><span class="bonus-10-3">1,311.32</span></li>
                                                        <li><p>700-2,500</p></li>
                                                    </ul>
                                                </div>
                                                <div class="progress">
                                                    <div role="progressbar" aria-valuenow="52"
                                                         aria-valuemin="0" aria-valuemax="100" class="progress-bar"
                                                         style="width: 52%;">
                                                        <div class="wink"></div>
                                                    </div>
                                                </div>
                                                <div class="jackpot-bg"></div>
                                            </div>
                                            <div class="container-jackpot-bonus">
                                                <div class="detail"><h2>FIXED</h2>
                                                    <ul>
                                                        <li><p>1,000</p></li>
                                                    </ul>
                                                </div>
                                                <div class="jackpot-bg"></div>
                                            </div>
                                        </div>
                                        <div class="container-fluid-jackpot-bg"></div>
                                    </div>
                                    <div class="row">
                                        <div class="content-jackpot-box">
                                            <div class="content-jackpot-box-info">
                                                <div>
                                                    <div class="box-jackpot-item">
                                                        <a href="/unbox-play/1" class="">
                                                            <div class="info-jackpot-item"><!---->
                                                                <div class="item-jackpot-case-image">
                                                                    <div style="background-image: url(&quot;https://pesstar.com/case/images/case/case_20190603120620.png&quot;);"></div>
                                                                </div>
                                                                <div class="item-detail"><h2>฿386 </h2><h4>
                                                                    Chanel</h4></div>
                                                                <div class="background-bg-case"></div>
                                                            </div>
                                                        </a>
                                                    </div>
                                                </div>
                                                <div>
                                                    <div class="box-jackpot-item">
                                                        <a href="/unbox-play/1" class="">
                                                            <div class="info-jackpot-item"><!---->
                                                                <div class="item-jackpot-case-image">
                                                                    <div style="background-image: url(&quot;https://pesstar.com/case/images/case/case_20190603120620.png&quot;);"></div>
                                                                </div>
                                                                <div class="item-detail"><h2>฿386 </h2><h4>
                                                                    Chanel</h4></div>
                                                                <div class="background-bg-case"></div>
                                                            </div>
                                                        </a>
                                                    </div>
                                                </div>
                                                <div>
                                                    <div class="box-jackpot-item">
                                                        <a href="/unbox-play/1" class="">
                                                            <div class="info-jackpot-item"><!---->
                                                                <div class="item-jackpot-case-image">
                                                                    <div style="background-image: url(&quot;https://pesstar.com/case/images/case/case_20190603120620.png&quot;);"></div>
                                                                </div>
                                                                <div class="item-detail"><h2>฿386 </h2><h4>
                                                                    Chanel</h4></div>
                                                                <div class="background-bg-case"></div>
                                                            </div>
                                                        </a>
                                                    </div>
                                                </div>
                                                <div>
                                                    <div class="box-jackpot-item">
                                                        <a href="/unbox-play/1" class="">
                                                            <div class="info-jackpot-item"><!---->
                                                                <div class="item-jackpot-case-image">
                                                                    <div style="background-image: url(&quot;https://pesstar.com/case/images/case/case_20190603120620.png&quot;);"></div>
                                                                </div>
                                                                <div class="item-detail"><h2>฿386 </h2><h4>
                                                                    Chanel</h4></div>
                                                                <div class="background-bg-case"></div>
                                                            </div>
                                                        </a>
                                                    </div>
                                                </div>
                                                <div>
                                                    <div class="box-jackpot-item">
                                                        <a href="/unbox-play/1" class="">
                                                            <div class="info-jackpot-item"><!---->
                                                                <div class="item-jackpot-case-image">
                                                                    <div style="background-image: url(&quot;https://pesstar.com/case/images/case/case_20190603120620.png&quot;);"></div>
                                                                </div>
                                                                <div class="item-detail"><h2>฿386 </h2><h4>
                                                                    Chanel</h4></div>
                                                                <div class="background-bg-case"></div>
                                                            </div>
                                                        </a>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>

                                    </div>
                                </div>
                            </div>
                        </div>

                    </div>
                </div>
            </div>
        </div>
        <div class="navTopDesktop">
            <MenuNavTop></MenuNavTop>
        </div>
        <div class="navButtonMobile">
            <MenuNav></MenuNav>
        </div>
    </div>
</template>
<script>
    import MenuBar from "@/components/MenuBar";
    import MenuNav from "@/components/MenuNav";
    import MenuNavTop from "@/components/MenuNavTop";
    import {Carousel, Slide} from "vue-carousel";

    export default {
        name: "DiamondMode",
        components: {
            MenuBar,
            MenuNav,
            MenuNavTop,
            Carousel,
            Slide,
        },
    };
</script>

<style src="@/assets/css/spin-circle.css"></style>
