<template>

    <div class="PriceTableInfo" @click="goBet">
        <div class="card-header">
            <LottoTypeIcon :lotto_type="lotto_type" customClass="custom-price-table"></LottoTypeIcon>
            <!-- <div class="LottoTypeIcon custom-price-table">
                <img src="/img/icon-lotto-hitec.5a0b92f7.png" class="lotto-type-icon logo-yeekee-image">
            </div> -->
            <div class="card-header-detail">
                <strong class="card-box"> {{lotto_type | lottoTypeDisplay}} </strong>
                <Alarm :start="timeout" ></Alarm>


                <!-- <div class="Alarm">
                    <div class="Alarm-box"><span>18:14:33</span></div>
                </div> -->
            </div>
        </div>
        <div class="colContent-priceIndex">
            <table>
                    <tbody>
                        <tr v-for="(bill_key, i) of bill_key_arr" :key="i">
                            <td v-if="bill_key=='sam_tua_bon' || bill_key=='song_tua_lang' || bill_key=='song_tua_bon'" class="text-center">{{bill_key | bet_type_display}}</td>
                            <td v-if="bill_key=='sam_tua_bon' || bill_key=='song_tua_lang' || bill_key=='song_tua_bon'" lass="text-center" 
                            :class="{'text-danger table-warning': bill_key === 'sam_tua_bon' || bill_key === 'song_tua_bon'}">
                                <b>{{getPrice(bill_key) | numberFormat}} <small>บาท</small></b>
                            </td>
                        </tr>
                        
                    </tbody>
            </table>
        </div>
    </div>
</template>

<script>

import { BILL_KEY_ARR } from '../Constant';
import store from '@/store'
import AppMixin from '../mixins/App'
import LottoTypeIcon from './LottoTypeIcon'
import Alarm from "./Alarm";

export default {
    name: "PriceTableNew",
    mixins: [
        AppMixin,
    ],
    components: {
        LottoTypeIcon,
        Alarm,
    },
    props: [
        'lotto_type',
    ],
    computed: {
        price_table(){
            return store.state.lotto.price_table
        },
        price_map(){
            try{
                if(this.price_table){
                    return this.price_table[this.lotto_type]
                }else{
                    return {
                        sam_tua_bon: 900,
                        sam_tua_tod: 150,
                        sam_tua_nha: 450,
                        sam_tua_lang: 450,
                        song_tua_bon: 90,
                        song_tua_lang: 90,
                        wing_bon: 3,
                        wing_lang: 4,
                    }
                }
            }catch(err){
                return null;
            }
        },
        timeout() {
            if(!this.price_map) {
                return null
            }
            if(!this.price_map.round) {
                return null
            }
            return this.price_map.round.timeout
        },
        bill_key_arr(){
            if(this.lotto_type === 'government'){
                return BILL_KEY_ARR;
            }else{
                return BILL_KEY_ARR.filter((o) => {
                    return !(o === 'sam_tua_nha' || o === 'sam_tua_lang');
                });
            }
        }
    },
    methods: {
        getPrice(bet_type){
            try{
                return this.price_map[bet_type];
            }catch(err){
                return 0;
            }
        },
        goBet(){
            store.commit('lotto/updatePageBetQueryLottoType', this.lotto_type)
            store.commit('lotto/updateCurrentPage', 'bet')
            this.$router.push({name: 'LottoBet'})
        }
    }
}
</script>

<style scoped>

    .PriceTable {
        cursor: pointer;
    }

    .card-header{
        display: flex;
        padding: 8px;
        text-align: left;
        background-color: transparent;
        border:0;
    }
    .card-box {
        padding: 0;
    }
    .card-header p{font: 11px Helvetica; margin-bottom: 0}
    .card-header img{
        width: 35px;
        height: 35px;
        border-radius: 50%;
        margin-right: 10px;
    }
    .card-header i {
        font-size: 35px;
        height: 30px;
        margin: 5px;
        color: #282831 !important;
    }
    .card-header strong{
        font-size: 20px!important;
        line-height: 1;
        margin-top: -2px;
    }

    .card{
        width: 46%;
        margin: 8px;
        padding: 8px;
        height: auto;
        border-radius: 8px;
        border: solid 1px #ddd!important;
    }
    .card:first-child{
        border-color: red!important;
    }
    .card.card-last-update{
        border:0;
        padding: 0;
    }
    .card.card-last-update:hover{  box-shadow: none;  }

    .card.card-yeekee {
        width: 48%;
    }
    .card .logo-yeekee{
        background-color: #f5ba62;
        width: 35px;
        height: 35px;
        line-height: 35px;
        margin: 0 10px 0 0;
    }
    .card .logo-yeekee:before{font: 800 12px/35px 'Athiti';}

    .colContent-priceIndex{
        padding: 1px 8px;
        margin: 0 1px 1px 1px;
        border-radius: 8px;
        background-color: #fff;
    }
    .left-home .card{
        width: auto;
        flex: 1;
        margin: 5px;
        padding: 0;
        height: auto;
        border-radius: 8px!important;
        border: solid 1px #000!important;
        background-color: #2C2A2E;

        /*background-color: #f5ba62;*/
        overflow: hidden;
        font-family: "Athiti", Helvetica, sans-serif;
        transition: all .3s ease-in-out;
    }
    .left-home .card-header{
        color: #fff;
    }
    .left-home .card:hover{
        box-shadow: 0 0 5px rgba(0, 0, 0, 0.35);
        border: solid 1px rgba(0, 0, 0, 0.2)!important;
    }
    /*.left-home .card:nth-child(1){}*/
    /*.left-home .card:nth-child(2){float: right}*/
    /*.left-home .card:nth-child(3),*/
    /*.left-home .card:nth-child(4),*/
    .left-home .card:nth-child(5){
        /*width: 31.5%;*/margin-right: 0;
    }
    .left-home .card .colContent-priceIndex{
        height: 315px;
    }
    .colContent-priceIndex table{width: 100%}
    .colContent-priceIndex table tr span{color: red; margin-right: 5px;}
    .colContent-priceIndex table{width: 100%}
    .colContent-priceIndex table tr{text-align: left; font-size: 18px;}
    .colContent-priceIndex table tr td:first-child{text-align: left;}
    .colContent-priceIndex table tr th{
        vertical-align: bottom;
        border-bottom: 2px solid #ddd;
    }
    .colContent-priceIndex table tr td{
        text-align: center;
        font-size: 16px;
        font-weight: 800;
        padding: 6px;
        line-height: 1.42857143;
    }
    .colContent-priceIndex table tbody tr:nth-of-type(odd) {
        background-color: rgba(0, 0, 0, 0.05);
    }
    /*.left-home .card:nth-child(1) .colContent-priceIndex table ,*/
    /*.left-home .card:nth-child(2) .colContent-priceIndex table{height: 328px}*/

    .colContent-priceIndex .table-warning,
    .colContent-priceIndex .table-warning > th,
    .colContent-priceIndex .table-warning > td {
        background-color: #f5ba62;
        color: #000;
        transition: all .3s ease-in-out;
    }
    .colContent-priceIndex .table-warning b {
        display: block;
        color: #000;
        font-weight: 800;
        animation: beat .3s infinite alternate;
    }
    /*.card:hover .colContent-priceIndex .table-warning,*/
    /*.card:hover .colContent-priceIndex .table-warning > th,*/
    /*.card:hover .colContent-priceIndex .table-warning > td {*/
    /*    background-color: #753189;*/
    /*    color: white;*/
    /*    transition: all .3s;*/
    /*}*/
    .PriceTable.card:hover .colContent-priceIndex .table-warning,
    .PriceTable.card:hover .colContent-priceIndex .table-warning > th,
    .PriceTable.card:hover .colContent-priceIndex .table-warning > td {
        background-color: rgba(0, 128, 0, 0.81);

    }
    .PriceTable.card:hover .colContent-priceIndex .table-warning b{ color: #fff!important;}
    .PageHomeLotto .colContent-priceIndex .table-warning b{color: #fff!important;}
    /*.card:hover .colContent-priceIndex .table-warning b {*/
    /*    color: white;*/
    /*}*/
    .v-loading-wrapper {
        width: 100%;
        min-height: 100px;
        display: flex;
        justify-content: center;
        align-items: center;
    }
    @keyframes beat{
        to { transform: scale(1.2); }
    }
    @media (min-width: 963px) and (max-width: 1300px){
        /*.left-home .card{*/
        /*    width: 45%;*/
        /*}*/
        .card-header strong{
            font-size: 20px!important;
        }
        .card-header img{
            width: 40px;
            height: 40px;
            margin-right: 5px;
        }
        .card .logo-yeekee{
            background-color: #f5ba62;
            width: 40px;
            height: 40px;
            line-height: 40px;
            margin: 0 10px 0 0;
        }
        .card-header p{
            font-size: 12px;
            line-height: 14px;
        }
        .colContent-priceIndex table tr{
            font-size: 15px;
        }
        .colContent-priceIndex table tr td{
            font-size: 14px;
            padding: 5px 8px;
        }
        .left-home .card:nth-child(1) .colContent-priceIndex table ,
        .left-home .card:nth-child(2) .colContent-priceIndex table{height: 270px}
        .left-home .card:nth-child(1) .colContent-priceIndex,
        .left-home .card:nth-child(2) .colContent-priceIndex{
            height: 290px;
        }
        .left-home .card:nth-child(3) .colContent-priceIndex,
        .left-home .card:nth-child(4) .colContent-priceIndex,
        .left-home .card:nth-child(5) .colContent-priceIndex{
            height: 222px;
        }
        .left-home .card:nth-child(3) .colContent-priceIndex table ,
        .left-home .card:nth-child(4) .colContent-priceIndex table ,
        .left-home .card:nth-child(5) .colContent-priceIndex table{height: 200px}
    }


</style>