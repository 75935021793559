<template>
    <div class="info-label-box-mini-time">
        <!-- <span v-if="remain.as('day') > 1" style="margin-right: 4px;">{{day}}&nbsp;วัน</span> -->
        <!-- <span v-else>{{hour | doubleZero}}:{{minute | doubleZero}}:{{second | doubleZero}}</span> -->
            <span class="day" v-if="remain.as('day') > 1"><p>{{day}}</p><p>วัน</p></span>
            <span>{{hour | doubleZero}}:{{minute | doubleZero}}:{{second | doubleZero}}</span>
    </div>
</template>
<script>

import AlarmMixin from '@/mixins/Alarm'

export default {
    name: 'LottoShortCutAlarm',
    mixins: [
        AlarmMixin,
    ],
}
</script>