<template>
    <!--message gallery-->
    <div class="MessageGallery incoming_msg">
        <div class="incoming_msg_img"><img src="@/assets/images/img-other/bell.png" alt="" /></div>
        <div class="received_msg">
            <div class="received_withd_msg">
                <div>
                    <div class="box-top-user-flex">
                        <div v-for="(m, i) of message_data" :key="i" class="box-top-user-list">
                            <a :href="m.url" target="blank">
                                <div class="box-games-list">
                                    <div class="box-img-gallery">
                                        <ImageBackground 
                                        :url="generateThumbnailUrl(m.thumbnails[0])" 
                                        customClass="box-img-lg gallery-img1"/>
                                        <div class="box-img-sm">
                                            <ImageBackground 
                                            :url="generateThumbnailUrl(m.thumbnails[1])" 
                                            customClass="gallery-img2"/>
                                            <ImageBackground 
                                            :url="generateThumbnailUrl(m.thumbnails[2])" 
                                            customClass="gallery-img3"/>
                                        </div>
                                    </div>
                                    <div class="box-info-game">
                                        <h3>{{m.title}}</h3>
                                        <div class="box-star">
                                            <span><i class="fa fa-star"></i> </span>
                                            <span><i class="fa fa-star"></i> </span>
                                            <span><i class="fa fa-star"></i> </span>
                                            <span><i class="fa fa-star"></i> </span>
                                            <span><i class="fa fa-star"></i> </span>
                                            <span class="ml-1">5.0</span>
                                        </div>
                                        <div class="txt-detail box-bg">
                                            {{m.description}}
                                        </div>
                                    </div>
                                </div>
                            </a>
                        </div>
                    </div>
                </div>
                <span class="time_date"> {{timestamp | chatMessageDateTimeFormat}}</span>
            </div>
        </div>
    </div>
</template>
<script>

import ImageBackground from '@/components/ImageBackground'

export default {
    name: 'MessageGallery',
    props: ['message'],
    components: {ImageBackground},
    computed: {
        message_data(){
            return (this.message) ? this.message.data : null
        },
        timestamp(){
            return (this.message) ? this.message.timestamp : ''
        }
    },
    methods: {
        generateThumbnailUrl(src){
            return (src) ? `${this.envDomains.VUE_APP_API}/${src}` : ''
        }
    }
}
</script>

<style scoped>

    .gallery-img1{
        background-image: url(../../assets/images/img-other/jamnean.jpg);
    }

    .gallery-img2{
        background-image: url(../../assets/images/img-other/thairath.jpg);
    }

    .gallery-img3{
        background-image: url(../../assets/images/img-other/2314096.jpg);
    }


</style>