<script>

import store from '@/store'
import axiosWrapper from '@/axiosWrapper'
import Cookie from '@/Cookie'

export default {
    name: 'UserMixin',
	computed: {
		routeQueryChannel() {
            return store.state.routeQueryChannel
        },
		routeQueryMessageApiId() {
            return store.state.routeQueryMessageApiId
        },
	},
	methods: { 
		async goSignUp(){
			this.$modal.show('AuthModal')
		},
		notifyToSignUp(optional_message = ''){
			this.$alert(`กรุณาล็อกอิน/สมัครสมาชิก ${optional_message}`, 'แจ้งเตือน', {
				confirmButtonText: 'ตกลง',
				customClass: 'custom-message-box',
				callback: action => {
					if(action === 'confirm'){
						this.goSignUp()
					}
				}
			})
		},
		signIn(token) {
			localStorage.setItem('token', token)
		},
		signOut() {
			localStorage.removeItem('token')
			this.$cookies.remove("sp_bonus_login_7day")
			this.$cookies.remove("dont_first_deposit_today")
		},
		requestUserReload() {
			store.commit('updateNeedUserReload', true)
		},
		requestUserReloadWithForceRefreshBalance() {
            store.commit('updateNeedUserReloadWithForceRefreshBalance', true)
        },
		async getChannelInfo(channel, message_api_id) {
            const res = await axiosWrapper({
                method: 'post',
                url: `${this.envDomains.VUE_APP_LIFF_API}/api/channel/get-channel-info`,
                data: {
                    channel: channel,
                    line: message_api_id,
                }
            })
            if(res.status === 200 && res.data.code === 200 && res.data.message === 'success') {
                return res.data.result
            } else {
                return null
            }
        },
		async checkRouteQueryChannelAvailable() {

			const channel = this.routeQueryChannel || Cookie.get('channel')
			const message_api_id = this.routeQueryMessageApiId || Cookie.get('message_api_id')

			if(!channel || !message_api_id) {
                this.showErrorMessage('ลิ้งค์ไม่ถูกต้อง กรุณาลองใหม่อีกครั้ง')
                return false
            }

            const channelInfo = await this.getChannelInfo(channel, message_api_id)
            if(!channelInfo) {
                this.showErrorMessage('ลิ้งค์ไม่ถูกต้อง กรุณาลองใหม่อีกครั้ง')
                return false
            }
            if(!channelInfo.match) {
                this.showErrorMessage('ลิ้งค์ไม่ถูกต้อง กรุณาลองใหม่อีกครั้ง')
                return false
            }

			return true
		},
		showErrorMessage(message) {
			this.$alert(`${message}`, 'แจ้งเตือน', {
				confirmButtonText: 'ตกลง',
				customClass: 'custom-message-box',
			})
		},
		async loadUserData(token, forceRefreshBalance = 'no') {

            try{
                const res = await axiosWrapper({
                    method: 'POST',
                    url: `${this.envDomains.VUE_APP_LIFF_API}/api/user/auth-with-token`,
                    data: {
                        token,
                        forceRefreshBalance,
                    }
                });
                if(res.status === 200) {
					store.commit('updatePesProfile', res.data.pes_profile)
					store.commit('updateUserProfile', res.data.user_profile)
					return {
						success: true
					}
                } else if(res.data.message_code === 'failed_to_authenticate_token') {
                    console.log('loadUserData failed_to_authenticate_token', res.data.message)
					return {
						success: false,
						message: res.data.message
					}
                } else {
                    console.log('loadUserData fail', res.data)
					return {
						success: false,
						message: res.data.message
					}
                }
            }catch(err){
                console.log('loadUserData err', err.message)
				return {
					success: false,
					message: 'เกิดความผิดพลาดบางอย่าง กรุณาลองอีกครั้ง'
				}
            }
        },
	}
}
</script>