/* eslint no-use-before-define: 0 */
// Generated by CoffeeScript 1.7.1
export var DivSugar
let __indexOf = [].indexOf || function (item) { for (var i = 0, l = this.length; i < l; i++) { if (i in this && this[i] === item) return i; } return -1; };

DivSugar = {
    _initialize: function () {
        var div, msg, prefix, prefixFuncName, prefixProp, prefixes, prop, propName, props, requestAnimationFrame, updateTasks, upperProp, _i, _j, _k, _len, _len1, _len2;
        this.VERSION = '1.2.1';
        this.EPSILON = 0.0001;
        this.NUM_OF_DIGITS = 4;
        this.DEG_TO_RAD = Math.PI / 180;
        this.RAD_TO_DEG = 180 / Math.PI;
        // console.log("DivSugar: Version " + this.VERSION);
        this.rootTask = null;
        this._frameCount = 0;
        this._currentId = 0;
        this._keyStates = {};
        this._mouseX = 0;
        this._mouseY = 0;
        this._mouseStates = {};
        this._touchFinger = -1;
        this._css3DTransforms = true;
        prefixes = ['webkit', 'Moz', 'O', 'ms'];
        props = ['transform', 'transformStyle', 'transformOrigin', 'perspective', 'perspectiveOrigin', 'backfaceVisibility'];
        div = document.createElement('div');
        for (_i = 0, _len = props.length; _i < _len; _i++) {
            prop = props[_i];
            upperProp = prop.charAt(0).toUpperCase() + prop.substring(1);
            propName = '_css' + upperProp;
            if (prop in div.style) {
                this[propName] = prop;
            } else {
                for (_j = 0, _len1 = prefixes.length; _j < _len1; _j++) {
                    prefix = prefixes[_j];
                    prefixProp = prefix + upperProp;
                    if (prefixProp in div.style) {
                        this[propName] = prefixProp;
                        break;
                    }
                }
            }
            if (this[propName] != null) {
                // console.log("DivSugar: Use '" + this[propName] + "'");
            } else {
                // console.log("DivSugar: Can't find '" + prop + "'");
                this._css3DTransforms = false;
            }
        }
        if (!this._css3DTransforms) {
            msg = "DivSugar: CSS 3D Transforms is not supported";
            // console.log(msg);
            alert(msg);
        }
        if ('requestAnimationFrame' in window) {
            requestAnimationFrame = 'requestAnimationFrame';
        } else {
            for (_k = 0, _len2 = prefixes.length; _k < _len2; _k++) {
                prefix = prefixes[_k];
                prefixFuncName = prefix.charAt(0).toLowerCase() + prefix.substring(1) + 'RequestAnimationFrame';
                if (prefixFuncName in window) {
                    requestAnimationFrame = prefixFuncName;
                    break;
                }
            }
        }
        if (requestAnimationFrame != null) {
            this._requestAnimationFrame = (function (_this) {
                return function (callback) {
                    return window[requestAnimationFrame](callback);
                };
            })(this);
            // console.log("DivSugar: Use '" + requestAnimationFrame + "'");
        } else {
            this._requestAnimationFrame = function (callback) {
                return window.setTimeout(callback, 1000 / 60);
            };
            // console.log("DivSugar: Can't find 'requestAnimationFrame'");
        }
        document.addEventListener('keydown', (function (_this) {
            return function (e) {
                var keyCode, keyState;
                keyCode = e.keyCode;
                keyState = _this._keyStates[keyCode];
                if ((keyState == null) || keyState < 0) {
                    return _this._keyStates[keyCode] = _this._frameCount;
                }
            };
        })(this), true);
        document.addEventListener('keyup', (function (_this) {
            return function (e) {
                var keyCode, keyState;
                keyCode = e.keyCode;
                keyState = _this._keyStates[keyCode];
                if ((keyState == null) || keyState > 0) {
                    return _this._keyStates[keyCode] = -_this._frameCount;
                }
            };
        })(this), true);
        document.addEventListener('mousemove', (function (_this) {
            return function (e) {
                _this._mouseX = e.clientX;
                return _this._mouseY = e.clientY;
            };
        })(this), true);
        document.addEventListener('mousedown', (function (_this) {
            return function (e) {
                var button, mouseState;
                button = e.button;
                mouseState = _this._mouseStates[button];
                if ((mouseState == null) || mouseState < 0) {
                    return _this._mouseStates[button] = _this._frameCount;
                }
            };
        })(this), true);
        document.addEventListener('mouseup', (function (_this) {
            return function (e) {
                var button, mouseState;
                button = e.button;
                mouseState = _this._mouseStates[button];
                if ((mouseState == null) || mouseState > 0) {
                    return _this._mouseStates[button] = -_this._frameCount;
                }
            };
        })(this), true);
        document.addEventListener('touchmove', (function (_this) {
            return function (e) {
                var touch, _l, _len3, _ref, _results;
                _ref = e.targetTouches;
                _results = [];
                for (_l = 0, _len3 = _ref.length; _l < _len3; _l++) {
                    touch = _ref[_l];
                    if (touch.identifier === _this._touchFinger) {
                        _this._mouseX = touch.clientX;
                        _results.push(_this._mouseY = touch.clientY);
                    } else {
                        _results.push(void 0);
                    }
                }
                return _results;
            };
        })(this), true);
        document.addEventListener('touchstart', (function (_this) {
            return function (e) {
                var mouseState, touch;
                if (_this._touchFinger === -1) {
                    touch = e.changedTouches[0];
                    _this._mouseX = touch.clientX;
                    _this._mouseY = touch.clientY;
                    _this._touchFinger = touch.identifier;
                    mouseState = _this._mouseStates[0];
                    if ((mouseState == null) || mouseState < 0) {
                        return _this._mouseStates[0] = _this._frameCount;
                    }
                }
            };
        })(this), true);
        document.addEventListener('touchend', (function (_this) {
            return function (e) {
                var mouseState, touch, _l, _len3, _ref, _results;
                _ref = e.changedTouches;
                _results = [];
                for (_l = 0, _len3 = _ref.length; _l < _len3; _l++) {
                    touch = _ref[_l];
                    if (touch.identifier === _this._touchFinger) {
                        _this._touchFinger = -1;
                        mouseState = _this._mouseStates[0];
                        if ((mouseState == null) || mouseState > 0) {
                            _results.push(_this._mouseStates[0] = -_this._frameCount);
                        } else {
                            _results.push(void 0);
                        }
                    } else {
                        _results.push(void 0);
                    }
                }
                return _results;
            };
        })(this), true);
        updateTasks = (function (_this) {
            return function () {
                var curTime, deltaTime;
                curTime = new Date().getTime();
                deltaTime = curTime - _this._lastUpdatedTime;
                _this._lastUpdatedTime = curTime;
                _this.rootTask.update(deltaTime);
                _this._frameCount++;
                return _this._requestAnimationFrame(updateTasks);
            };
        })(this);
        this._lastUpdatedTime = new Date().getTime();
        return this._requestAnimationFrame(updateTasks);
    },
    getFrameCount: function () {
        return this._frameCount;
    },
    getKeyState: function (keyCode, state) {
        var keyState;
        keyState = this._keyStates[keyCode];
        switch (state) {
            case 'on':
                return (keyState != null) && (0 < keyState && keyState < this._frameCount);
            case 'off':
                return (keyState == null) || (-this._frameCount < keyState && keyState < 0);
            case 'pressed':
                return keyState === this._frameCount - 1;
            case 'released':
                return keyState === 1 - this._frameCount;
            default:
                throw "DivSugar: Unknown key state '" + state + "'";
        }
    },
    getMouseX: function () {
        return this._mouseX;
    },
    getMouseY: function () {
        return this._mouseY;
    },
    getMouseState: function (button, state) {
        var mouseState;
        mouseState = this._mouseStates[button];
        switch (state) {
            case 'on':
                return (mouseState != null) && (0 < mouseState && mouseState < this._frameCount);
            case 'off':
                return (mouseState == null) || (-this._frameCount < mouseState && mouseState < 0);
            case 'pressed':
                return mouseState === this._frameCount - 1;
            case 'released':
                return mouseState === 1 - this._frameCount;
            default:
                throw "DivSugar: Unknown mouse state '" + state + "'";
        }
    },
    inherit: function (C, P) {
        var F;
        F = function () { };
        F.prototype = P.prototype;
        C.prototype = new F();
        C.uber = P.prototype;
        C.prototype.constructor = C;
        return this;
    },
    generateId: function () {
        return "_divsugar_id_" + (++this._currentId);
    },
    getImageSize: function (src, callback) {
        var image;
        image = new Image();
        image.src = src;
        image.onload = function () {
            return callback(image.width, image.height);
        };
        return this;
    },
    getCSSColor: function (r, g, b) {
        r = Math.floor(r + 0.5);
        g = Math.floor(g + 0.5);
        b = Math.floor(b + 0.5);
        r = r < 0 ? 0 : r > 255 ? 255 : r;
        g = g < 0 ? 0 : g > 255 ? 255 : g;
        b = b < 0 ? 0 : b > 255 ? 255 : b;
        r = r.toString(16);
        g = g.toString(16);
        b = b.toString(16);
        if (r.length <= 1) {
            r = '0' + r;
        }
        if (g.length <= 1) {
            g = '0' + g;
        }
        if (b.length <= 1) {
            b = '0' + b;
        }
        return '#' + r + g + b;
    }
};

(window.DivSugar = DivSugar)._initialize();

DivSugar.Vector = (function () {
    function Vector(x, y, z) {
        var vec;
        switch (arguments.length) {
            case 0:
                this.x = this.y = this.z = 0;
                break;
            case 1:
                vec = x;
                this.x = vec.x;
                this.y = vec.y;
                this.z = vec.z;
                break;
            case 3:
                this.x = x;
                this.y = y;
                this.z = z;
                break;
            default:
                throw 'DivSugar: Invalid number of arguments';
        }
    }

    Vector.prototype.set = function (x, y, z) {
        var vec;
        switch (arguments.length) {
            case 1:
                vec = x;
                this.x = vec.x;
                this.y = vec.y;
                this.z = vec.z;
                break;
            case 3:
                this.x = x;
                this.y = y;
                this.z = z;
                break;
            default:
                throw 'DivSugar: Invalid number of arguments';
        }
        return this;
    };

    Vector.prototype.neg = function () {
        this.x = -this.x;
        this.y = -this.y;
        this.z = -this.z;
        return this;
    };

    Vector.prototype.add = function (vec) {
        this.x += vec.x;
        this.y += vec.y;
        this.z += vec.z;
        return this;
    };

    Vector.prototype.sub = function (vec) {
        this.x -= vec.x;
        this.y -= vec.y;
        this.z -= vec.z;
        return this;
    };

    Vector.prototype.mul = function (s) {
        this.x *= s;
        this.y *= s;
        this.z *= s;
        return this;
    };

    Vector.prototype.div = function (s) {
        var rs;
        rs = 1 / s;
        this.x *= rs;
        this.y *= rs;
        this.z *= rs;
        return this;
    };

    Vector.prototype.norm = function () {
        return Math.sqrt(this.x * this.x + this.y * this.y + this.z * this.z);
    };

    Vector.prototype.sqNorm = function () {
        return this.x * this.x + this.y * this.y + this.z * this.z;
    };

    Vector.prototype.dist = function (vec) {
        return DivSugar.Vector._tmpVec1.set(this).sub(vec).norm();
    };

    Vector.prototype.sqDist = function (vec) {
        return DivSugar.Vector._tmpVec1.set(this).sub(vec).sqNorm();
    };

    Vector.prototype.dot = function (vec) {
        return this.x * vec.x + this.y * vec.y + this.z * vec.z;
    };

    Vector.prototype.cross = function (vec) {
        return this.set(this.y * vec.z - this.z * vec.y, this.z * vec.x - this.x * vec.z, this.x * vec.y - this.y * vec.x);
    };

    Vector.prototype.normalize = function () {
        var norm;
        norm = this.norm();
        if (norm < DivSugar.EPSILON) {
            return this.set(DivSugar.Vector.X_UNIT);
        } else {
            return this.div(norm);
        }
    };

    Vector.prototype.rotate = function (rotateX, rotateY, rotateZ) {
        var cos, sin;
        if (arguments.length !== 3) {
            throw 'DivSugar: Invalid number of arguments';
        }
        if (rotateX !== 0) {
            sin = Math.sin(rotateX * DivSugar.DEG_TO_RAD);
            cos = Math.cos(rotateX * DivSugar.DEG_TO_RAD);
            this.set(this.x, this.y * cos - this.z * sin, this.z * cos + this.y * sin);
        }
        if (rotateY !== 0) {
            sin = Math.sin(rotateY * DivSugar.DEG_TO_RAD);
            cos = Math.cos(rotateY * DivSugar.DEG_TO_RAD);
            this.set(this.x * cos + this.z * sin, this.y, this.z * cos - this.x * sin);
        }
        if (rotateZ !== 0) {
            sin = Math.sin(rotateZ * DivSugar.DEG_TO_RAD);
            cos = Math.cos(rotateZ * DivSugar.DEG_TO_RAD);
            this.set(this.x * cos - this.y * sin, this.y * cos + this.x * sin, this.z);
        }
        return this;
    };

    Vector.prototype.lerp = function (to, ratio) {
        var vec;
        if (ratio > 1 - DivSugar.EPSILON) {
            this.set(to);
        } else if (ratio >= DivSugar.EPSILON) {
            vec = DivSugar.Vector._tmpVec1;
            vec.set(to).mul(ratio);
            this.mul(1 - ratio).add(vec);
        }
        return this;
    };

    Vector.prototype.toLocal = function (mat) {
        var vec;
        vec = DivSugar.Vector._tmpVec1;
        vec.set(this.sub(mat.trans));
        return this.set(vec.dot(mat.xAxis) / mat.xAxis.sqNorm(), vec.dot(mat.yAxis) / mat.yAxis.sqNorm(), vec.dot(mat.zAxis) / mat.zAxis.sqNorm());
    };

    Vector.prototype.toGlobal = function (mat) {
        var vec1, vec2, vec3;
        vec1 = DivSugar.Vector._tmpVec1;
        vec2 = DivSugar.Vector._tmpVec2;
        vec3 = DivSugar.Vector._tmpVec3;
        vec1.set(mat.xAxis).mul(this.x);
        vec2.set(mat.yAxis).mul(this.y);
        vec3.set(mat.zAxis).mul(this.z);
        return this.set(vec1).add(vec2).add(vec3).add(mat.trans);
    };

    Vector.prototype.toLocal_noTrans = function (mat) {
        return this.set(this.dot(mat.xAxis) / mat.xAxis.sqNorm(), this.dot(mat.yAxis) / mat.yAxis.sqNorm(), this.dot(mat.zAxis) / mat.zAxis.sqNorm());
    };

    Vector.prototype.toGlobal_noTrans = function (mat) {
        var vec1, vec2, vec3;
        vec1 = DivSugar.Vector._tmpVec1;
        vec2 = DivSugar.Vector._tmpVec2;
        vec3 = DivSugar.Vector._tmpVec3;
        vec1.set(mat.xAxis).mul(this.x);
        vec2.set(mat.yAxis).mul(this.y);
        vec3.set(mat.zAxis).mul(this.z);
        return this.set(vec1).add(vec2).add(vec3);
    };

    Vector.prototype.equal = function (vec) {
        return this.x === vec.x && this.y === vec.y && this.z === vec.z;
    };

    Vector.prototype.toString = function () {
        return "(" + this.x + ", " + this.y + ", " + this.z + ")";
    };

    return Vector;

})();

DivSugar.Vector.ZERO = new DivSugar.Vector(0, 0, 0);

DivSugar.Vector.X_UNIT = new DivSugar.Vector(1, 0, 0);

DivSugar.Vector.Y_UNIT = new DivSugar.Vector(0, 1, 0);

DivSugar.Vector.Z_UNIT = new DivSugar.Vector(0, 0, 1);

DivSugar.Vector._tmpVec1 = new DivSugar.Vector();

DivSugar.Vector._tmpVec2 = new DivSugar.Vector();

DivSugar.Vector._tmpVec3 = new DivSugar.Vector();

DivSugar.Matrix = (function () {
    function Matrix(mat) {
        switch (arguments.length) {
            case 0:
                this.xAxis = new DivSugar.Vector(DivSugar.Vector.X_UNIT);
                this.yAxis = new DivSugar.Vector(DivSugar.Vector.Y_UNIT);
                this.zAxis = new DivSugar.Vector(DivSugar.Vector.Z_UNIT);
                this.trans = new DivSugar.Vector(DivSugar.Vector.ZERO);
                break;
            case 1:
                this.xAxis = new DivSugar.Vector(mat.xAxis);
                this.yAxis = new DivSugar.Vector(mat.yAxis);
                this.zAxis = new DivSugar.Vector(mat.zAxis);
                this.trans = new DivSugar.Vector(mat.trans);
                break;
            case 12:
                this.xAxis = new DivSugar.Vector(arguments[0], arguments[1], arguments[2]);
                this.yAxis = new DivSugar.Vector(arguments[3], arguments[4], arguments[5]);
                this.zAxis = new DivSugar.Vector(arguments[6], arguments[7], arguments[8]);
                this.trans = new DivSugar.Vector(arguments[9], arguments[10], arguments[11]);
                break;
            default:
                throw 'DivSugar: Invalid number of arguments';
        }
    }

    Matrix.prototype.set = function (mat) {
        switch (arguments.length) {
            case 1:
                this.xAxis.set(mat.xAxis);
                this.yAxis.set(mat.yAxis);
                this.zAxis.set(mat.zAxis);
                this.trans.set(mat.trans);
                break;
            case 12:
                this.xAxis.set(arguments[0], arguments[1], arguments[2]);
                this.yAxis.set(arguments[3], arguments[4], arguments[5]);
                this.zAxis.set(arguments[6], arguments[7], arguments[8]);
                this.trans.set(arguments[9], arguments[10], arguments[11]);
                break;
            default:
                throw 'DivSugar: Invalid number of arguments';
        }
        return this;
    };

    Matrix.prototype.fromQuaternion = function (quat) {
        var quatW, quatX, quatY, quatZ, wx2, wy2, wz2, x2, xx2, xy2, xz2, y2, yy2, yz2, z2, zz2;
        quatX = quat.x;
        quatY = quat.y;
        quatZ = quat.z;
        quatW = quat.w;
        x2 = quatX + quatX;
        y2 = quatY + quatY;
        z2 = quatZ + quatZ;
        wx2 = quatW * x2;
        wy2 = quatW * y2;
        wz2 = quatW * z2;
        xx2 = quatX * x2;
        xy2 = quatX * y2;
        xz2 = quatX * z2;
        yy2 = quatY * y2;
        yz2 = quatY * z2;
        zz2 = quatZ * z2;
        this.set(1 - (yy2 + zz2), xy2 + wz2, xz2 - wy2, xy2 - wz2, 1 - (xx2 + zz2), yz2 + wx2, xz2 + wy2, yz2 - wx2, 1 - (xx2 + yy2), 0, 0, 0);
        return this;
    };

    Matrix.prototype.orthonormalize = function () {
        var vec1, vec2, vec3;
        vec1 = DivSugar.Matrix._tmpVec1;
        vec2 = DivSugar.Matrix._tmpVec2;
        vec3 = DivSugar.Matrix._tmpVec3;
        vec3.set(this.zAxis).normalize();
        vec1.set(this.yAxis).cross(this.zAxis).normalize();
        vec2.set(vec3).cross(vec1);
        this.xAxis.set(vec1);
        this.yAxis.set(vec2);
        this.zAxis.set(vec3);
        return this;
    };

    Matrix.prototype.translate = function (offsetX, offsetY, offsetZ) {
        var vec1, vec2, vec3;
        if (arguments.length !== 3) {
            throw 'DivSugar: Invalid number of arguments';
        }
        vec1 = DivSugar.Matrix._tmpVec1;
        vec2 = DivSugar.Matrix._tmpVec2;
        vec3 = DivSugar.Matrix._tmpVec3;
        vec1.set(this.xAxis).mul(offsetX);
        vec2.set(this.yAxis).mul(offsetY);
        vec3.set(this.zAxis).mul(offsetZ);
        this.trans.add(vec1).add(vec2).add(vec3);
        return this;
    };

    Matrix.prototype.rotate = function (angleX, angleY, angleZ) {
        var cos, mat, rad, sin;
        if (arguments.length !== 3) {
            throw 'DivSugar: Invalid number of arguments';
        }
        if (angleX !== 0) {
            rad = angleX * DivSugar.DEG_TO_RAD;
            sin = Math.sin(rad);
            cos = Math.cos(rad);
            mat = DivSugar.Matrix._tmpMat1;
            mat.set(1, 0, 0, 0, cos, sin, 0, -sin, cos, 0, 0, 0).toGlobal(this);
            this.set(mat);
        }
        if (angleY !== 0) {
            rad = angleY * DivSugar.DEG_TO_RAD;
            sin = Math.sin(rad);
            cos = Math.cos(rad);
            mat = DivSugar.Matrix._tmpMat1;
            mat.set(cos, 0, -sin, 0, 1, 0, sin, 0, cos, 0, 0, 0).toGlobal(this);
            this.set(mat);
        }
        if (angleZ !== 0) {
            rad = angleZ * DivSugar.DEG_TO_RAD;
            sin = Math.sin(rad);
            cos = Math.cos(rad);
            mat = DivSugar.Matrix._tmpMat1;
            mat.set(cos, sin, 0, -sin, cos, 0, 0, 0, 1, 0, 0, 0).toGlobal(this);
            this.set(mat);
        }
        return this;
    };

    Matrix.prototype.rotateAround = function (axis, angle) {
        var cos, cos2, mat, rad, sin, x, xx, xy, xz, y, yy, yz, z, zz;
        x = axis.x;
        y = axis.y;
        z = axis.z;
        xx = x * x;
        yy = y * y;
        zz = z * z;
        xy = x * y;
        xz = x * z;
        yz = y * z;
        rad = angle * DivSugar.DEG_TO_RAD;
        sin = Math.sin(rad);
        cos = Math.cos(rad);
        cos2 = 1 - cos;
        mat = DivSugar.Matrix._tmpMat1;
        mat.set(xx * cos2 + cos, xy * cos2 + z * sin, xz * cos2 - y * sin, xy * cos2 - z * sin, yy * cos2 + cos, yz * cos2 + x * sin, xz * cos2 + y * sin, yz * cos2 - x * sin, zz * cos2 + cos, 0, 0, 0).toGlobal(this);
        this.set(mat);
        return this;
    };

    Matrix.prototype.scale = function (scaleX, scaleY, scaleZ) {
        if (arguments.length !== 3) {
            throw 'DivSugar: Invalid number of arguments';
        }
        this.xAxis.mul(scaleX);
        this.yAxis.mul(scaleY);
        this.zAxis.mul(scaleZ);
        return this;
    };

    Matrix.prototype.slerp = function (to, ratio) {
        var quat1, quat2, vec;
        if (ratio > 1 - DivSugar.EPSILON) {
            this.set(to);
        } else if (ratio >= DivSugar.EPSILON) {
            vec = DivSugar.Matrix._tmpVec1;
            quat1 = DivSugar.Matrix._tmpQuat1;
            quat2 = DivSugar.Matrix._tmpQuat2;
            quat1.fromMatrix(this);
            quat2.fromMatrix(to);
            vec.set(this.trans).lerp(to.trans, ratio);
            this.fromQuaternion(quat1.slerp(quat2, ratio));
            this.trans.set(vec);
        }
        return this;
    };

    Matrix.prototype.slerp_noTrans = function (to, ratio) {
        var quat1, quat2;
        if (ratio > 1 - DivSugar.EPSILON) {
            this.set(to);
            this.trans.set(DivSugar.Vector.ZERO);
        } else if (ratio >= DivSugar.EPSILON) {
            quat1 = DivSugar.Matrix._tmpQuat1;
            quat2 = DivSugar.Matrix._tmpQuat2;
            quat1.fromMatrix(this);
            quat2.fromMatrix(to);
            this.fromQuaternion(quat1.slerp(quat2, ratio));
        } else {
            this.trans.set(DivSugar.Vector.ZERO);
        }
        return this;
    };

    Matrix.prototype.toLocal = function (mat) {
        var rsqXA, rsqYA, rsqZA, vec;
        vec = DivSugar.Matrix._tmpVec1;
        rsqXA = 1 / mat.xAxis.sqNorm();
        rsqYA = 1 / mat.yAxis.sqNorm();
        rsqZA = 1 / mat.zAxis.sqNorm();
        vec.set(this.trans).sub(mat.trans);
        this.set(this.xAxis.dot(mat.xAxis) * rsqXA, this.xAxis.dot(mat.yAxis) * rsqYA, this.xAxis.dot(mat.zAxis) * rsqZA, this.yAxis.dot(mat.xAxis) * rsqXA, this.yAxis.dot(mat.yAxis) * rsqYA, this.yAxis.dot(mat.zAxis) * rsqZA, this.zAxis.dot(mat.xAxis) * rsqXA, this.zAxis.dot(mat.yAxis) * rsqYA, this.zAxis.dot(mat.zAxis) * rsqZA, vec.dot(mat.xAxis) * rsqXA, vec.dot(mat.yAxis) * rsqYA, vec.dot(mat.zAxis) * rsqZA);
        return this;
    };

    Matrix.prototype.toGlobal = function (mat) {
        this.xAxis.toGlobal_noTrans(mat);
        this.yAxis.toGlobal_noTrans(mat);
        this.zAxis.toGlobal_noTrans(mat);
        this.trans.toGlobal(mat);
        return this;
    };

    Matrix.prototype.toLocal_noTrans = function (mat) {
        var rsqXA, rsqYA, rsqZA;
        rsqXA = 1 / mat.xAxis.sqNorm();
        rsqYA = 1 / mat.yAxis.sqNorm();
        rsqZA = 1 / mat.zAxis.sqNorm();
        this.set(this.xAxis.dot(mat.xAxis) * rsqXA, this.xAxis.dot(mat.yAxis) * rsqYA, this.xAxis.dot(mat.zAxis) * rsqZA, this.yAxis.dot(mat.xAxis) * rsqXA, this.yAxis.dot(mat.yAxis) * rsqYA, this.yAxis.dot(mat.zAxis) * rsqZA, this.zAxis.dot(mat.xAxis) * rsqXA, this.zAxis.dot(mat.yAxis) * rsqYA, this.zAxis.dot(mat.zAxis) * rsqZA, 0, 0, 0);
        return this;
    };

    Matrix.prototype.toGlobal_noTrans = function (mat) {
        this.xAxis.toGlobal_noTrans(mat);
        this.yAxis.toGlobal_noTrans(mat);
        this.zAxis.toGlobal_noTrans(mat);
        this.trans.set(DivSugar.Vector.ZERO);
        return this;
    };

    Matrix.prototype.lookAt = function (from, to, up) {
        this.zAxis.set(from).sub(to).normalize();
        this.xAxis.set(up).cross(this.zAxis).normalize();
        this.yAxis.set(this.zAxis).cross(this.xAxis);
        this.trans.set(from);
        return this;
    };

    Matrix.prototype.equal = function (mat) {
        return this.xAxis.equal(mat.xAxis) && this.yAxis.equal(mat.yAxis) && this.zAxis.equal(mat.zAxis) && this.trans.equal(mat.trans);
    };

    Matrix.prototype.toString = function () {
        return "(" + (this.xAxis.toString()) + ", " + (this.yAxis.toString()) + ", " + (this.zAxis.toString()) + ", " + (this.trans.toString()) + ")";
    };

    Matrix.prototype.toCSSTransform2D = function () {
        var nod, tr, xa, ya;
        nod = DivSugar.NUM_OF_DIGITS;
        xa = this.xAxis;
        ya = this.yAxis;
        tr = this.trans;
        return "matrix(" + (xa.x.toFixed(nod)) + ", " + (xa.y.toFixed(nod)) + ", " + (ya.x.toFixed(nod)) + ", " + (ya.y.toFixed(nod)) + ", " + (tr.x.toFixed(nod)) + ", " + (tr.y.toFixed(nod)) + ")";
    };

    Matrix.prototype.toCSSTransform3D = function () {
        var nod, tr, xa, ya, za;
        nod = DivSugar.NUM_OF_DIGITS;
        xa = this.xAxis;
        ya = this.yAxis;
        za = this.zAxis;
        tr = this.trans;
        return 'matrix3d(' + ("" + (xa.x.toFixed(nod)) + ", " + (xa.y.toFixed(nod)) + ", " + (xa.z.toFixed(nod)) + ", 0, ") + ("" + (ya.x.toFixed(nod)) + ", " + (ya.y.toFixed(nod)) + ", " + (ya.z.toFixed(nod)) + ", 0, ") + ("" + (za.x.toFixed(nod)) + ", " + (za.y.toFixed(nod)) + ", " + (za.z.toFixed(nod)) + ", 0, ") + ("" + (tr.x.toFixed(nod)) + ", " + (tr.y.toFixed(nod)) + ", " + (tr.z.toFixed(nod)) + ", 1)");
    };

    return Matrix;

})();

if (DivSugar._css3DTransforms) {
    DivSugar.Matrix.prototype._toCSSTransform = DivSugar.Matrix.prototype.toCSSTransform3D;
} else {
    DivSugar.Matrix.prototype._toCSSTransform = DivSugar.Matrix.prototype.toCSSTransform2D;
}

DivSugar.Matrix.UNIT = new DivSugar.Matrix(1, 0, 0, 0, 1, 0, 0, 0, 1, 0, 0, 0);

DivSugar.Matrix._tmpVec1 = new DivSugar.Vector();

DivSugar.Matrix._tmpVec2 = new DivSugar.Vector();

DivSugar.Matrix._tmpVec3 = new DivSugar.Vector();

DivSugar.Matrix._tmpMat1 = new DivSugar.Matrix();

DivSugar.Quaternion = (function () {
    function Quaternion(x, y, z, w) {
        var quat;
        switch (arguments.length) {
            case 0:
                this.x = this.y = this.z = this.w = 0;
                break;
            case 1:
                quat = x;
                this.x = quat.x;
                this.y = quat.y;
                this.z = quat.z;
                this.w = quat.w;
                break;
            case 4:
                this.x = x;
                this.y = y;
                this.z = z;
                this.w = w;
                break;
            default:
                throw 'DivSugar: Invalid number of arguments';
        }
    }

    Quaternion.prototype.set = function (x, y, z, w) {
        var quat;
        switch (arguments.length) {
            case 1:
                quat = x;
                this.x = quat.x;
                this.y = quat.y;
                this.z = quat.z;
                this.w = quat.w;
                break;
            case 4:
                this.x = x;
                this.y = y;
                this.z = z;
                this.w = w;
                break;
            default:
                throw 'DivSugar: Invalid number of arguments';
        }
        return this;
    };

    Quaternion.prototype.fromMatrix = function (mat) {
        var k, matXAxis, matYAxis, matZAxis, root, scale, trace;
        matXAxis = mat.xAxis;
        matYAxis = mat.yAxis;
        matZAxis = mat.zAxis;
        trace = matXAxis.x + matYAxis.y + matZAxis.z;
        if (trace > 0) {
            root = Math.sqrt(trace + 1);
            scale = 0.5 / root;
            this.set((matYAxis.z - matZAxis.y) * scale, (matZAxis.x - matXAxis.z) * scale, (matXAxis.y - matYAxis.x) * scale, root * 0.5);
        } else {
            k = matYAxis.y > matXAxis.x ? (matZAxis.z > matYAxis.y ? 2 : 1) : (matZAxis.z > matXAxis.x ? 2 : 0);
            switch (k) {
                case 0:
                    root = Math.sqrt(matXAxis.x - (matYAxis.y + matZAxis.z) + 1);
                    scale = root !== 0 ? 0.5 / root : root;
                    this.set(root * 0.5, (matXAxis.y + matYAxis.x) * scale, (matZAxis.x + matXAxis.z) * scale, (matYAxis.z - matZAxis.y) * scale);
                    break;
                case 1:
                    root = Math.sqrt(matYAxis.y - (matZAxis.z + matXAxis.x) + 1);
                    scale = root !== 0 ? 0.5 / root : root;
                    this.set((matXAxis.y + matYAxis.x) * scale, root * 0.5, (matYAxis.z + matZAxis.y) * scale, (matZAxis.x - matXAxis.z) * scale);
                    break;
                case 2:
                    root = Math.sqrt(matZAxis.z - (matXAxis.x + matYAxis.y) + 1);
                    scale = root !== 0 ? 0.5 / root : root;
                    this.set((matZAxis.x + matXAxis.z) * scale, (matYAxis.z + matZAxis.y) * scale, root * 0.5, (matXAxis.y - matYAxis.x) * scale);
            }
        }
        return this;
    };

    Quaternion.prototype.slerp = function (to, ratio) {
        var cosOmega, omega, quat, scale0, scale1, sinOmega;
        if (ratio > 1 - DivSugar.EPSILON) {
            this.set(to);
        } else if (ratio >= DivSugar.EPSILON) {
            quat = DivSugar.Quaternion._tmpQuat1;
            cosOmega = this.x * to.x + this.y * to.y + this.z * to.z + this.w * to.w;
            if (cosOmega < 0) {
                cosOmega = -cosOmega;
                quat.set(-to.x, -to.y, -to.z, -to.w);
            } else {
                quat.set(to);
            }
            if (cosOmega >= 1) {
                this.set(to);
            } else {
                omega = Math.acos(cosOmega > 1 ? 1 : cosOmega);
                sinOmega = Math.sin(omega);
                scale0 = Math.sin(omega * (1 - ratio)) / sinOmega;
                scale1 = Math.sin(omega * ratio) / sinOmega;
                this.set(this.x * scale0 + quat.x * scale1, this.y * scale0 + quat.y * scale1, this.z * scale0 + quat.z * scale1, this.w * scale0 + quat.w * scale1);
            }
        }
        return this;
    };

    Quaternion.prototype.equal = function (quat) {
        return this.x === quat.x && this.y === quat.y && this.z === quat.z && this.w === quat.w;
    };

    Quaternion.prototype.toString = function () {
        return "(" + this.x + ", " + this.y + ", " + this.z + ", " + this.w + ")";
    };

    return Quaternion;

})();

DivSugar.Quaternion._tmpQuat1 = new DivSugar.Quaternion();

DivSugar.Matrix._tmpQuat1 = new DivSugar.Quaternion();

DivSugar.Matrix._tmpQuat2 = new DivSugar.Quaternion();

DivSugar.Scene = (function () {
    function Scene(id) {
        this.div = document.createElement('div');
        if (id != null) {
            this.div.id = id;
        }
        this.div.style.margin = '0px';
        this.div.style.padding = '0px';
        this.div.style.position = 'relative';
        this.div.style.overflow = 'hidden';
        this.div.sugar = this;
        this._isScene = true;
        this._viewWidth = 1;
        this._centerNode = new DivSugar.Node();
        this._centerNode.div.style[DivSugar._cssTransformOrigin] = '0 0';
        this._centerNode.div.style[DivSugar._cssPerspectiveOrigin] = '0 0';
        this._centerNode.div.sugar = this;
        this.div.appendChild(this._centerNode.div);
        this._rootNode = new DivSugar.Node();
        this._rootNode.div.sugar = this;
        this._centerNode.div.appendChild(this._rootNode.div);
        this.setViewAngle(45);
        this.setSize(400, 300);
        this.setPosition(0, 0);
        this.setVisible(true);
        this.setClip(true);
        this.setOpacity(1);
        this.setImage('#0000ff');
        this.setImageClip(0, 0, 1, 1);
    }

    Scene.prototype.getParent = function () {
        return this.div.parentNode;
    };

    Scene.prototype.append = function (child) {
        this._rootNode.div.appendChild(child.div);
        return this;
    };

    Scene.prototype.appendTo = function (parent) {
        parent.appendChild(this.div);
        return this;
    };

    Scene.prototype.remove = function (child) {
        var _ref;
        if (_ref = child.div, __indexOf.call(this._rootNode.div.childNodes, _ref) >= 0) {
            this._rootNode.div.removeChild(child.div);
        }
        return this;
    };

    Scene.prototype.getViewAngle = function () {
        return this._viewAngle;
    };

    Scene.prototype.getPerspective = function () {
        return this._perspective;
    };

    Scene.prototype.setViewAngle = function (viewAngle) {
        this._viewAngle = viewAngle;
        this._perspective = Math.tan((90 - viewAngle / 2) * DivSugar.DEG_TO_RAD) * this._viewWidth / 2;
        this._centerNode.div.style[DivSugar._cssPerspective] = "" + (this._perspective.toFixed(DivSugar.NUM_OF_DIGITS)) + "px";
        return this;
    };

    Scene.prototype.getWidth = function () {
        return this._width;
    };

    Scene.prototype.getHeight = function () {
        return this._height;
    };

    Scene.prototype.getViewWidth = function () {
        return this._viewWidth;
    };

    Scene.prototype.getViewHeight = function () {
        return this._viewHeight;
    };

    Scene.prototype.setSize = function (width, height, viewWidth, viewHeight) {
        if (viewWidth == null) {
            viewWidth = width;
        }
        if (viewHeight == null) {
            viewHeight = height;
        }
        this._width = width;
        this._height = height;
        this._viewWidth = viewWidth;
        this._viewHeight = viewHeight;
        this.div.style.width = "" + (width.toFixed(DivSugar.NUM_OF_DIGITS)) + "px";
        this.div.style.height = "" + (height.toFixed(DivSugar.NUM_OF_DIGITS)) + "px";
        this._centerNode.setPosition(width / 2, height / 2, 0);
        this._rootNode.setTransform(DivSugar.Matrix.UNIT).setPosition(-width / 2, -height / 2, 0).scale(width / viewWidth, height / viewHeight, 1);
        this.setViewAngle(this._viewAngle);
        return this;
    };

    Scene.prototype.getPositionX = function () {
        return this._positionX;
    };

    Scene.prototype.getPositionY = function () {
        return this._positionY;
    };

    Scene.prototype.setPosition = function (x, y) {
        if (arguments.length !== 2) {
            throw 'DivSugar: Invalid number of arguments';
        }
        this._positionX = x;
        this._positionY = y;
        this.div.style.left = "" + (x.toFixed(DivSugar.NUM_OF_DIGITS)) + "px";
        this.div.style.top = "" + (y.toFixed(DivSugar.NUM_OF_DIGITS)) + "px";
        return this;
    };

    Scene.prototype.getVisible = function () {
        return this._visible;
    };

    Scene.prototype.setVisible = function (visible) {
        this._visible = visible;
        this.div.style.visibility = visible ? 'visible' : 'hidden';
        return this;
    };

    Scene.prototype.getClip = function () {
        return this._clip;
    };

    Scene.prototype.setClip = function (clip) {
        this._clip = clip;
        this.div.style.overflow = clip ? 'hidden' : 'visible';
        return this;
    };

    Scene.prototype.getOpacity = function () {
        return this._opacity;
    };

    Scene.prototype.setOpacity = function (opacity) {
        this._opacity = opacity;
        this.div.style.opacity = opacity.toFixed(DivSugar.NUM_OF_DIGITS);
        return this;
    };

    Scene.prototype.getImage = function () {
        return this._image;
    };

    Scene.prototype.setImage = function (src, callback) {
        this._image = src;
        if (src == null) {
            this.div.style.backgroundColor = 'transparent';
            this.div.style.backgroundImage = 'none';
        } else if (src.charAt(0) === '#') {
            this.div.style.backgroundColor = src;
            this.div.style.backgroundImage = 'none';
        } else {
            this.div.style.backgroundColor = 'transparent';
            this.div.style.backgroundImage = "url(" + src + ")";
            if (callback != null) {
                DivSugar.getImageSize(src, callback);
            }
        }
        return this;
    };

    Scene.prototype.getImageClipU1 = function () {
        return this._imageClipU1;
    };

    Scene.prototype.getImageClipV1 = function () {
        return this._imageClipV1;
    };

    Scene.prototype.getImageClipU2 = function () {
        return this._imageClipU2;
    };

    Scene.prototype.getImageClipV2 = function () {
        return this._imageClipV2;
    };

    Scene.prototype.setImageClip = function (u1, v1, u2, v2) {
        var h, nod, w, x, y;
        u1 = u1 < 0 ? 0 : u1 > 1 ? 1 : u1;
        v1 = v1 < 0 ? 0 : v1 > 1 ? 1 : v1;
        u2 = u2 < 0 ? 0 : u2 > 1 ? 1 : u2;
        v2 = v2 < 0 ? 0 : v2 > 1 ? 1 : v2;
        this._imageClipU1 = u1;
        this._imageClipV1 = v1;
        this._imageClipU2 = u2;
        this._imageClipV2 = v2;
        w = 1 / (u2 - u1) * 100;
        h = 1 / (v2 - v1) * 100;
        x = w > 100 ? u1 * w / (w - 100) * 100 : 0;
        y = h > 100 ? v1 * h / (h - 100) * 100 : 0;
        nod = DivSugar.NUM_OF_DIGITS;
        this.div.style.backgroundPosition = "" + (x.toFixed(nod)) + "% " + (y.toFixed(nod)) + "%";
        this.div.style.backgroundSize = "" + (w.toFixed(nod)) + "% " + (h.toFixed(nod)) + "%";
        return this;
    };

    Scene.prototype.adjustLayout = function (parentWidth, parentHeight, style) {
        switch (style) {
            case 'center':
                break;
            case 'contain':
                if (parentWidth < parentHeight * this._viewWidth / this._viewHeight) {
                    this.setSize(parentWidth, parentWidth * this._viewHeight / this._viewWidth, this._viewWidth, this._viewHeight);
                } else {
                    this.setSize(parentHeight * this._viewWidth / this._viewHeight, parentHeight, this._viewWidth, this._viewHeight);
                }
                break;
            case 'cover':
                if (parentWidth > parentHeight * this._viewWidth / this._viewHeight) {
                    this.setSize(parentWidth, parentWidth * this._viewHeight / this._viewWidth, this._viewWidth, this._viewHeight);
                } else {
                    this.setSize(parentHeight * this._viewWidth / this._viewHeight, parentHeight, this._viewWidth, this._viewHeight);
                }
                break;
            default:
                throw "DivSugar: Unknown layout style '" + style + "'";
        }
        this.setPosition((parentWidth - this._width) / 2, (parentHeight - this._height) / 2);
        return this;
    };

    Scene.prototype.getLocalPosition = function (clientX, clientY, vec) {
        var rect, x, y;
        rect = this.div.getBoundingClientRect();
        x = (clientX - rect.left) * this._viewWidth / this._width;
        y = (clientY - rect.top) * this._viewHeight / this._height;
        vec.set(x, y, 0);
        return this;
    };

    return Scene;

})();

DivSugar.Node = (function () {
    function Node(id) {
        this.div = document.createElement('div');
        if (id != null) {
            this.div.id = id;
        }
        this.div.style.margin = '0px';
        this.div.style.padding = '0px';
        this.div.style.position = 'absolute';
        this.div.style[DivSugar._cssTransformStyle] = 'preserve-3d';
        this.div.style[DivSugar._cssTransformOrigin] = '0% 0%';
        this.div.sugar = this;
        this._transform = new DivSugar.Matrix();
        this._animTasks = [];
        this.setSize(0, 0);
        this.setPosition(0, 0, 0);
        this.setVisible(true);
        this.setBackface(true);
        this.setClip(false);
        this.setOpacity(1);
        this.setImage(null);
        this.setImageClip(0, 0, 1, 1);
    }

    Node.prototype.getParent = function () {
        var parent;
        parent = this.div.parentNode;
        if (parent != null) {
            return parent.sugar;
        } else {
            return null;
        }
    };

    Node.prototype.append = function (child) {
        this.div.appendChild(child.div);
        return this;
    };

    Node.prototype.appendTo = function (parent) {
        if (parent._rootNode != null) {
            parent._rootNode.div.appendChild(this.div);
        } else {
            parent.div.appendChild(this.div);
        }
        return this;
    };

    Node.prototype.remove = function (child) {
        var _ref;
        if (_ref = child.div, __indexOf.call(this.div.childNodes, _ref) >= 0) {
            this.div.removeChild(child.div);
        }
        return this;
    };

    Node.prototype.getWidth = function () {
        return this._width;
    };

    Node.prototype.getHeight = function () {
        return this._height;
    };

    Node.prototype.setSize = function (width, height) {
        this._width = width;
        this._height = height;
        this.div.style.width = "" + (width.toFixed(DivSugar.NUM_OF_DIGITS)) + "px";
        this.div.style.height = "" + (height.toFixed(DivSugar.NUM_OF_DIGITS)) + "px";
        return this;
    };

    Node.prototype.getPositionX = function () {
        return this._transform.trans.x;
    };

    Node.prototype.getPositionY = function () {
        return this._transform.trans.y;
    };

    Node.prototype.getPositionZ = function () {
        return this._transform.trans.z;
    };

    Node.prototype.getPosition = function (vec) {
        vec.x = this._transform.trans.x;
        vec.y = this._transform.trans.y;
        vec.z = this._transform.trans.z;
        return this;
    };

    Node.prototype.setPosition = function (x, y, z) {
        var vec;
        switch (arguments.length) {
            case 1:
                vec = x;
                this._transform.trans.set(vec);
                break;
            case 3:
                this._transform.trans.x = x;
                this._transform.trans.y = y;
                this._transform.trans.z = z;
                break;
            default:
                throw 'DivSugar: Invalid number of arguments';
        }
        this.div.style[DivSugar._cssTransform] = this._transform._toCSSTransform();
        return this;
    };

    Node.prototype.getTransform = function (mat) {
        mat.set(this._transform);
        return this;
    };

    Node.prototype.setTransform = function (mat) {
        this._transform.set(mat);
        this.div.style[DivSugar._cssTransform] = this._transform._toCSSTransform();
        return this;
    };

    Node.prototype.getVisible = DivSugar.Scene.prototype.getVisible;

    Node.prototype.setVisible = DivSugar.Scene.prototype.setVisible;

    Node.prototype.getBackface = function () {
        return this._backface;
    };

    Node.prototype.setBackface = function (backface) {
        this._backface = backface;
        this.div.style[DivSugar._cssBackfaceVisibility] = backface ? 'visible' : 'hidden';
        return this;
    };

    Node.prototype.getClip = DivSugar.Scene.prototype.getClip;

    Node.prototype.setClip = DivSugar.Scene.prototype.setClip;

    Node.prototype.getOpacity = DivSugar.Scene.prototype.getOpacity;

    Node.prototype.setOpacity = DivSugar.Scene.prototype.setOpacity;

    Node.prototype.getImage = DivSugar.Scene.prototype.getImage;

    Node.prototype.setImage = DivSugar.Scene.prototype.setImage;

    Node.prototype.getImageClipU1 = DivSugar.Scene.prototype.getImageClipU1;

    Node.prototype.getImageClipV1 = DivSugar.Scene.prototype.getImageClipV1;

    Node.prototype.getImageClipU2 = DivSugar.Scene.prototype.getImageClipU2;

    Node.prototype.getImageClipV2 = DivSugar.Scene.prototype.getImageClipV2;

    Node.prototype.setImageClip = DivSugar.Scene.prototype.setImageClip;

    Node.prototype.translate = function (offsetX, offsetY, offsetZ) {
        if (arguments.length !== 3) {
            throw 'DivSugar: Invalid number of arguments';
        }
        this._transform.translate(offsetX, offsetY, offsetZ);
        this.div.style[DivSugar._cssTransform] = this._transform._toCSSTransform();
        return this;
    };

    Node.prototype.rotate = function (angleX, angleY, angleZ) {
        if (arguments.length !== 3) {
            throw 'DivSugar: Invalid number of arguments';
        }
        this._transform.rotate(angleX, angleY, angleZ);
        this.div.style[DivSugar._cssTransform] = this._transform._toCSSTransform();
        return this;
    };

    Node.prototype.rotateAround = function (axis, angle) {
        this._transform.rotateAround(axis, angle);
        this.div.style[DivSugar._cssTransform] = this._transform._toCSSTransform();
        return this;
    };

    Node.prototype.scale = function (scaleX, scaleY, scaleZ) {
        if (arguments.length !== 3) {
            throw 'DivSugar: Invalid number of arguments';
        }
        this._transform.scale(scaleX, scaleY, scaleZ);
        this.div.style[DivSugar._cssTransform] = this._transform._toCSSTransform();
        return this;
    };

    Node.prototype.playAnimation = function (animation) {
        var animTask;
        animTask = new DivSugar.Task();
        animTask.animation = animation;
        animTask._cmdIndex = 0;
        animTask._firstFrame = true;
        animTask.onUpdate = (function (_this) {
            return function () {
                return _this._updateAnimation(animTask);
            };
        })(this);
        animTask.onDestroy = (function (_this) {
            return function () {
                return _this._destroyAnimation(animTask);
            };
        })(this);
        animTask.appendTo(DivSugar.rootTask);
        this._animTasks.push(animTask);
        return animTask;
    };

    Node.prototype._updateAnimation = function (animTask) {
        var a0, a1, anim, clip, command, i, param, pos, size, value, _ref, _ref1, _ref2, _ref3, _ref4;
        while (animTask.deltaTime > 0) {
            if (animTask._cmdIndex >= animTask.animation.length) {
                animTask.destroy();
                return;
            }
            command = animTask.animation[animTask._cmdIndex];
            switch (command[0]) {
                case 'to':
                    if (animTask._firstFrame) {
                        animTask._firstFrame = false;
                        animTask._currentTime = 0;
                        animTask._totalTime = (_ref = command[2]) != null ? _ref : 0;
                        animTask._easeFunc = (_ref1 = command[3]) != null ? _ref1 : DivSugar.Ease.linear;
                        animTask._fromTransform = null;
                        _ref2 = command[1];
                        for (param in _ref2) {
                            value = _ref2[param];
                            switch (param) {
                                case 'size':
                                    animTask._fromSize = [this._width, this._height];
                                    break;
                                case 'position':
                                    animTask._fromPosition = [this._transform.trans.x, this._transform.trans.y, this._transform.trans.z];
                                    break;
                                case 'transform':
                                    if (animTask._fromTransform == null) {
                                        animTask._fromTransform = new DivSugar.Matrix(this._transform);
                                    }
                                    break;
                                case 'visible':
                                    this.setVisible(value);
                                    break;
                                case 'backface':
                                    this.setBackface(value);
                                    break;
                                case 'clip':
                                    this.setClip(value);
                                    break;
                                case 'opacity':
                                    animTask._fromOpacity = this._opacity;
                                    break;
                                case 'image':
                                    this.setImage(value);
                                    break;
                                case 'imageClip':
                                    animTask._fromImageClip = [this._imageClipU1, this._imageClipV1, this._imageClipU2, this._imageClipV2];
                                    break;
                                case 'translate':
                                    if (animTask._fromTransform == null) {
                                        animTask._fromTransform = new DivSugar.Matrix(this._transform);
                                    }
                                    break;
                                case 'rotate':
                                    if (animTask._fromTransform == null) {
                                        animTask._fromTransform = new DivSugar.Matrix(this._transform);
                                    }
                                    break;
                                case 'scale':
                                    if (animTask._fromTransform == null) {
                                        animTask._fromTransform = new DivSugar.Matrix(this._transform);
                                    }
                                    break;
                                default:
                                    throw "DivSugar: Unknown animation parameter '" + param + "'";
                            }
                        }
                    }
                    if (animTask._fromTransform != null) {
                        this._transform.set(animTask._fromTransform);
                    }
                    if (animTask._totalTime > animTask._currentTime + animTask.deltaTime) {
                        animTask._currentTime += animTask.deltaTime;
                        animTask.deltaTime = 0;
                    } else {
                        animTask.deltaTime -= animTask._totalTime;
                        animTask._currentTime = animTask._totalTime;
                        animTask._cmdIndex++;
                        animTask._firstFrame = true;
                    }
                    if (animTask._totalTime > 0) {
                        a1 = animTask._easeFunc(animTask._currentTime / animTask._totalTime);
                        a0 = 1 - a1;
                    } else {
                        a1 = 1;
                        a0 = 0;
                    }
                    _ref3 = command[1];
                    for (param in _ref3) {
                        value = _ref3[param];
                        switch (param) {
                            case 'size':
                                size = animTask._fromSize;
                                this.setSize(size[0] * a0 + value[0] * a1, size[1] * a0 + value[1] * a1);
                                break;
                            case 'position':
                                pos = animTask._fromPosition;
                                this.setPosition(pos[0] * a0 + value[0] * a1, pos[1] * a0 + value[1] * a1, pos[2] * a0 + value[2] * a1);
                                break;
                            case 'transform':
                                this.setTransform(DivSugar.Node._tmpMat1.set(this._transform).slerp(value, a1));
                                break;
                            case 'opacity':
                                this.setOpacity(animTask._fromOpacity * a0 + value * a1);
                                break;
                            case 'imageClip':
                                clip = animTask._fromImageClip;
                                this.setImageClip(clip[0] * a0 + value[0] * a1, clip[1] * a0 + value[1] * a1, clip[2] * a0 + value[2] * a1, clip[3] * a0 + value[3] * a1);
                                break;
                            case 'translate':
                                this.translate(value[0] * a1, value[1] * a1, value[2] * a1);
                                break;
                            case 'rotate':
                                this.rotate(value[0] * a1, value[1] * a1, value[2] * a1);
                                break;
                            case 'scale':
                                this.scale(a0 + value[0] * a1, a0 + value[1] * a1, a0 + value[2] * a1);
                        }
                    }
                    break;
                case 'wait':
                    if (animTask._firstFrame) {
                        animTask._firstFrame = false;
                        animTask._waitTime = command[1];
                    }
                    if (animTask._waitTime > animTask.deltaTime) {
                        animTask._waitTime -= animTask.deltaTime;
                        animTask.deltaTime = 0;
                    } else {
                        animTask.deltaTime -= animTask._waitTime;
                        animTask._waitTime = 0;
                        animTask._cmdIndex++;
                        animTask._firstFrame = true;
                    }
                    break;
                case 'play':
                    for (i in command) {
                        anim = command[i];
                        if (i > 0) {
                            this.playAnimation(command[i]);
                        }
                    }
                    animTask._cmdIndex++;
                    animTask._firstFrame = true;
                    break;
                case 'call':
                    for (i in command) {
                        anim = command[i];
                        if (i > 0) {
                            command[i]();
                        }
                    }
                    animTask._cmdIndex++;
                    animTask._firstFrame = true;
                    break;
                case 'repeat':
                    if (animTask._repeatCount == null) {
                        animTask._repeatCount = (_ref4 = command[1]) != null ? _ref4 : -1;
                    }
                    if (animTask._repeatCount === 0) {
                        animTask._cmdIndex++;
                        animTask._firstFrame = true;
                    } else {
                        if (animTask._repeatCount > 0) {
                            animTask._repeatCount--;
                        }
                        animTask._cmdIndex = 0;
                        animTask._firstFrame = true;
                    }
                    break;
                default:
                    throw "DivSugar: Unknown animation command '" + command[0] + "'";
            }
        }
    };

    Node.prototype._destroyAnimation = function (animTask) {
        var index;
        index = this._animTasks.indexOf(animTask);
        if (index > -1) {
            return this._animTasks.splice(index, 1);
        }
    };

    Node.prototype.clearAnimations = function () {
        while (this._animTasks.length > 0) {
            this._animTasks.shift().destroy();
        }
        return this;
    };

    Node.prototype.getWorldPosition = function (vec) {
        var parent;
        vec.set(this._transform.trans);
        parent = this.div.parentNode;
        while ((parent != null) && (parent.sugar != null) && (parent.sugar._isScene == null)) {
            vec.toGlobal(parent.sugar._transform);
            parent = parent.parentNode;
        }
        return this;
    };

    Node.prototype.getWorldTransform = function (mat) {
        var parent;
        mat.set(this._transform);
        parent = this.div.parentNode;
        while ((parent != null) && (parent.sugar != null) && (parent.sugar._isScene == null)) {
            mat.toGlobal(parent.sugar._transform);
            parent = parent.parentNode;
        }
        return this;
    };

    return Node;

})();

DivSugar.Node._tmpMat1 = new DivSugar.Matrix();

DivSugar.Task = (function () {
    function Task(id) {
        this.id = id != null ? id : null;
        this.active = true;
        this.deltaTime = 0;
        this.onUpdate = null;
        this.onDestroy = null;
        this._parent = null;
        this._children = [];
    }

    Task.prototype.getParent = function () {
        return this._parent;
    };

    Task.prototype.append = function (child) {
        this.remove(child);
        this._children.push(child);
        child._parent = this;
        return this;
    };

    Task.prototype.appendTo = function (parent) {
        parent.append(this);
        return this;
    };

    Task.prototype.remove = function (child) {
        var index;
        index = this._children.indexOf(child);
        if (index > -1) {
            this._children[index] = null;
            child._parent = null;
        }
        return this;
    };

    Task.prototype.update = function (deltaTime) {
        var child, i, len;
        if (this.active) {
            this.deltaTime += deltaTime;
            if (typeof this.onUpdate === "function") {
                this.onUpdate();
            }
            this.deltaTime = 0;
            i = 0;
            len = this._children.length;
            while (i < len) {
                child = this._children[i];
                if (child != null) {
                    child.update(deltaTime);
                    i++;
                } else {
                    this._children.splice(i, 1);
                    len--;
                }
            }
        }
        return this;
    };

    Task.prototype.destroy = function () {
        var child, _i, _len, _ref, _ref1;
        if (typeof this.onDestroy === "function") {
            this.onDestroy();
        }
        if ((_ref = this._parent) != null) {
            _ref.remove(this);
        }
        _ref1 = this._children;
        for (_i = 0, _len = _ref1.length; _i < _len; _i++) {
            child = _ref1[_i];
            if (child != null) {
                child.destroy();
            }
        }
        return this;
    };

    Task.prototype.getTaskById = function (id) {
        var child, task, _i, _len, _ref;
        if (this.id === id) {
            return this;
        } else {
            _ref = this._children;
            for (_i = 0, _len = _ref.length; _i < _len; _i++) {
                child = _ref[_i];
                if (child != null) {
                    task = child.getTaskById(id);
                    if (task != null) {
                        return task;
                    }
                }
            }
            return null;
        }
    };

    return Task;

})();

DivSugar.rootTask = new DivSugar.Task();

DivSugar.Ease = {
    linear: function (t) {
        return t;
    },
    quadIn: function (t) {
        return Math.pow(t, 2);
    },
    cubicIn: function (t) {
        return Math.pow(t, 3);
    },
    quartIn: function (t) {
        return Math.pow(t, 4);
    },
    quintIn: function (t) {
        return Math.pow(t, 5);
    },
    quadOut: function (t) {
        return 1 - Math.pow(1 - t, 2);
    },
    cubicOut: function (t) {
        return 1 - Math.pow(1 - t, 3);
    },
    quartOut: function (t) {
        return 1 - Math.pow(1 - t, 4);
    },
    quintOut: function (t) {
        return 1 - Math.pow(1 - t, 5);
    },
    quadInOut: function (t) {
        if (t < 0.5) {
            return Math.pow(t * 2, 2) * 0.5;
        } else {
            return 1 - Math.abs(Math.pow(2 - t * 2, 2)) * 0.5;
        }
    },
    cubicInOut: function (t) {
        if (t < 0.5) {
            return Math.pow(t * 2, 3) * 0.5;
        } else {
            return 1 - Math.abs(Math.pow(2 - t * 2, 3)) * 0.5;
        }
    },
    quartInOut: function (t) {
        if (t < 0.5) {
            return Math.pow(t * 2, 4) * 0.5;
        } else {
            return 1 - Math.abs(Math.pow(2 - t * 2, 4)) * 0.5;
        }
    },
    quintInOut: function (t) {
        if (t < 0.5) {
            return Math.pow(t * 2, 5) * 0.5;
        } else {
            return 1 - Math.abs(Math.pow(2 - t * 2, 5)) * 0.5;
        }
    }
};