<template>
    <div class="AdminToolPromotion">
        <div class="promotion-item-wrapper" v-for="(promotion, key) of promotions" :key="key">
            <Item 
                        :packet="promotion"
                    ></Item>
            <el-button @click="remove(key)">ลบ</el-button>
        </div>
        <el-button @click="toAdd">เพิ่ม</el-button>
        <el-dialog
        title="เลือกโปรโมชัน"
        :visible.sync="selectPromotionDialogVisible"
        width="30%">
            <el-card v-for="(promotion, key) of promotionFromApies" :key="key" 
                :body-style="{ 
                    padding: '2px', 
                    cursor: 'pointer',
                }"
                style="margin: 4px;">
                <div @click="add(promotion)">
                    <Item 
                        :packet="promotion"
                    ></Item>
                </div>
            </el-card>
            <span slot="footer" class="dialog-footer">
                <el-button @click="selectPromotionDialogVisible = false">ปิด</el-button>
            </span>
        </el-dialog>
    </div>
</template>
<script>

import Item from '../PromotionItem.vue'
    import $ from 'jquery'
    // import moment from 'moment';
    window.jQuery = $;
    window.$ = $;

export default {
    name: 'AdminToolPromotion',
    props:[
        'bus'
    ],
    components: {
        Item,
    },
    data() {
        return {
            promotions: [],
            selectPromotionDialogVisible: false,
            promotionFromApies: [],
        }
    },
    watch: {
        promotions: {
            deep: true,
            handler(new_val){
                this.$emit('change', {
                    data: new_val,
                    type: 'promotion',
                })
            }
        },
    },
    methods: {
        getGamePacket(){
            var self = this;
            if(this.pes_profile){
            $.get(`${this.envDomains.VUE_APP_PES_BASE_URL}/api/game/v1/game/packet/get.php?token=`+this.pes_profile.token,function(response){
                
                
                var game_packet_list=[
                    [],
                    [],
                    []
                ];
                var game_packet_selected_list =[];
                for(var i=0;i<response.data.length;i++){
                    if(game_packet_list[response.data[i].packet_type].length==0){
                        game_packet_selected_list.push(response.data[i]);
                    }
                    game_packet_list[response.data[i].packet_type].push(response.data[i]);
                    self.promotionFromApies.push(response.data[i]);
                    // if(response.data[i].packet_end_datetime){
                    //     var gp = (response.data[i]);
                    //     // self.setTimer(gp);
                        
                    // }
                    


                }
                self.GAME_PACKET_LIST2 = game_packet_list;

                self.GAME_PACKET_SELECTED_LIST = game_packet_selected_list;
                // console.log(
                //     self.GAME_PACKET_LIST[0]
                // )
                // console.log(self.promotionFromApies);

            })
        
            }else{
            $.get(`${this.envDomains.VUE_APP_PES_BASE_URL}/api/game/v0/game/packet/get.php`,function(response){
                
                
                var game_packet_list=[
                    [],
                    [],
                    []
                ];
                var game_packet_selected_list=[];
                for(var i=0;i<response.data.length;i++){
                    if(game_packet_list[response.data[i].packet_type].length==0){
                        game_packet_selected_list.push(response.data[i]);
                    }
                    game_packet_list[response.data[i].packet_type].push(response.data[i]);
                    self.promotionFromApies.push(response.data[i]);
                    // if(response.data[i].packet_end_datetime){
                    //     var gp = (response.data[i]);
                    //     self.setTimer(gp);
                        
                    // }
                   
                }
                self.GAME_PACKET_LIST2 = game_packet_list;
                self.GAME_PACKET_SELECTED_LIST = game_packet_selected_list;
                // console.log(
                //     self.GAME_PACKET_LIST[0]
                // )

            })
        
            }

        },
        toAdd() {
            this.selectPromotionDialogVisible = true
        },
        add(promotion) {
            this.promotions.push(promotion)
        },
        remove(i) {
            this.promotions.splice(i, 1)
        },
        async _loadPromotion() {
            this.promotionFromApies = [
                {id: 1},
                {id: 2},
                {id: 3},
            ]
        },
    },
    mounted() {
        // this._loadPromotion();
        this.getGamePacket();
    }
}
</script>
<style scoped>
.AdminToolPromotion {
    display: flex;
}
</style>