<template>
    <div class="contentVideoGuide">

        <div v-if="currentStreamer" class="area-VideoGuide" :class="{'boxVideoGuideHide':videoplay_hide}">
            <div 
            ref="boxVideoGuideWrapper"
            class="boxVideoGuide" 
            :class="{
                'boxVideoGuide-horizontal': currentStreamer.is_video_horizontal, 
                'fullscreen-vertical': isVerticalFull && !currentStreamer.is_video_horizontal,
                'fullscreen-horizontal': isHorizontalFull && currentStreamer.is_video_horizontal,
            }">
                <div ref="resizeButtonTopLeft" :class="{'active': isOnResize}" data-position="top-left" class="resize-button top-left"></div>
                <div ref="resizeButtonTopRight" :class="{'active': isOnResize}" data-position="top-right" class="resize-button top-right"></div>
                <div ref="resizeButtonBottomRight" :class="{'active': isOnResize}" data-position="bottom-right" class="resize-button bottom-right"></div>
                <div ref="resizeButtonBottomLeft" :class="{'active': isOnResize}" data-position="bottom-left" class="resize-button bottom-left"></div>
                <div class="boxVideoGuide-top">
                    <img :src="getUserImagePath(currentStreamer.user.picture_url)"/>

                    <marquee  scrolldelay="250">{{currentStreamer.user.display_name}}</marquee>
                    <div class="boxVideoGuide-top-right">
                        <button @click="toggleMuteButton" class="btn btn-light toggle-mute-button">
                            <i v-show="isMute" class="fa fa-volume-mute videoActionButton"></i>
                            <i v-show="!isMute" class="fa fa-volume-up videoActionButton"></i>
                        </button>
                        <div class="full-WindowPlayer videoActionButton" @click="toggleFullVideoPlayer()"></div>
                        <div class="Window-widescreen videoActionButton" @click="toggleFullscreen()"></div>
                        <div class="closeWindowPlayer videoActionButton" @click="hideVideoPlayer()"></div>
                    </div>
                </div>
                <div class="box-status-live">Live</div>
                <div v-if="viewers" class="box-view-live">{{viewers | numberFormat}}</div>
                <div v-if="false" class="box-gift" @click="showBoxGift()"></div>
                <!-- <div v-if="currentStreamer.stream_type === 'youtube'" 
                class="contentVideo"
                v-html="currentStreamer.stream_url">
                </div> -->
                <div v-if="currentStreamer.stream_type === 'youtube'" 
                class="contentVideo"
                ref="webVideoWrapper">
                    <VideoPlayerYoutube 
                    :videoUrl="currentStreamer.stream_url"
                    :width="webVideoWrapperWidth"
                    :height="webVideoWrapperHeight"
                    :playerBus="playerBus"
                    :isMute="isMute"
                    ></VideoPlayerYoutube>
                </div>
                <div v-else-if="currentStreamer.stream_type === 'web'" 
                class="contentVideo"
                ref="webVideoWrapper">
                    <VideoPlayer 
                    :tableVideoStreamPath="currentStreamer.stream_server_api_stream_path"
                    :srcHLS="srcHLS"
                    :srcFLV="srcFLV"
                    :width="webVideoWrapperWidth"
                    :height="webVideoWrapperHeight"
                    :playerBus="playerBus"
                    :isMute="isMute"
                    playerSelect="hls"
                    ></VideoPlayer>
                </div>
            </div>
        </div>

        <div v-if="streamers.length > 0" class="list-user-play" :class="{'minimal-list':minimallist_hide}">
            <div class="list-user-play-info">
                <div v-for="(streamer, key) of streamers" :key="key" 
                class="bx-ul" 
                @click="showVideoPlayer(streamer)">
                    <div class="bx-ul-live"><span>{{streamerLableDisplay(streamer)}}</span></div>
                    <div class="bx-ul-img">
                        <img :src="getUserImagePath(streamer.user.picture_url)"/>
                    </div>
                </div>
                <div class="bx-ul bx-ul-btn" @click="hidelistPlayer()">
                    <div class="bxUlBxUlLive"><div class="bx-ul-live"></div></div>
                    <div class="bx-ul-minimal-btn"></div>
                </div>
            </div>
        </div>

<!--        GIFT-->
        <!-- <div class="container-gift" :class="{'container-gift-hide':boxgift_hide}">
            <div class="content-gift">
                <div class="content-gift-top">
                    <h4><i class="fas fa-gift"></i> ให้ทริป</h4>
                    <div class="closeContainer-gift" @click="hideBoxGift()"></div>
                </div>
                <div class="content-gift-content">
                    <div class="btn-coin-gift active">10</div>
                    <div class="btn-coin-gift">50</div>
                    <div class="btn-coin-gift">100</div>
                    <div class="btn-coin-gift">500</div>
                    <div class="btn-coin-gift">1000</div>
                </div>
                <div class="content-gift-bottom">
                    <button type="submit" class="btn bg-gradient-btn"><i class="far fa-paper-plane"></i> ส่งทริป</button>
                </div>
            </div>
            <div class="content-gift-bg"></div>
        </div> -->
    </div>
</template>

<script>

    import axiosWrapper from '@/axiosWrapper'
    // import randomBetween from '@/lib/randomBetween'
    import VideoPlayer from '@/components/game/VideoPlayer'
    import VideoPlayerYoutube from '@/components/game/VideoPlayerYoutube'
    import store from '@/store'
    import Vue from 'vue'
    import ResizeMixin from '@/mixins/Resize'

    export default {
        name: 'VideoGuide',
        props: ['stream_on'],
        components: {
            VideoPlayer,
            VideoPlayerYoutube,
        },
        mixins: [
            ResizeMixin,
        ],
        data() {
            return {
                videoplay_hide: true,
                minimallist_hide: false,
                isVerticalFull: false,
                isHorizontalFull: false,
                boxgift_hide: true,
                streamers: [],
                currentStreamer: null,
                currentStreamerInfo: null,
                currentYoutubeVideoInfo: null,
                webVideoWrapperWidth: 0,
                webVideoWrapperHeight: 0,
                isMute: false,
                playerBus: new Vue(),
                boxVideoGuideWrapperElement: null,
                pos1: 0,
                pos2: 0,
                pos3: 0,
                pos4: 0,
                elmnt: null,
            };
        },
        computed: {
            pes_profile() {
                return store.state.pes_profile  
            },
            user_profile() {
                return store.state.user_profile  
            },
            token() {
                if(this.pes_profile) {
                    return this.pes_profile.token
                }
                return ''
            },
            viewers() {
                if(!this.currentStreamer) {
                    return 0
                }
                if(this.currentStreamer.stream_type === 'web') {
                    return (this.currentStreamerInfo) ? parseInt(this.currentStreamerInfo.viewers) + 1 : 0
                } else {
                    // return randomBetween(200, 300)
                    if(!this.currentYoutubeVideoInfo) {
                        return 0
                    }
                    if(this.currentYoutubeVideoInfo.liveStreamingDetails) {
                        if(this.currentYoutubeVideoInfo.liveStreamingDetails.concurrentViewers) {
                            return this.currentYoutubeVideoInfo.liveStreamingDetails.concurrentViewers
                        }
                    }
                    return this.currentYoutubeVideoInfo.statistics.viewCount
                }
            },
            srcFLV() {
                if(!this.currentStreamerInfo) {
                    return ''
                }
                return `${this.currentStreamerInfo.url['http-flv']}?token=${this.token}`
            },
            srcHLS() {
                if(!this.currentStreamerInfo) {
                    return ''
                }
                return `${this.currentStreamerInfo.url['hls']}?token=${this.token}`
            },
        },
        methods: {
            async showVideoPlayer(streamer, isMute = false) {

                this.currentStreamer = streamer

                if(this.currentStreamer.stream_type === 'web') {
                    this.loadStreamInfo()
                } else if(this.currentStreamer.stream_type === 'youtube') {
                    this.loadYoutubeVideoInfo()
                }
                this.calculateWebVideoWrapper()

                this.isMute = isMute;
                this.isHorizontalFull = false;
                this.isVerticalFull = false;
                this.videoplay_hide = false;
                this.addDraggable()
                this.addResizeable()
            },
            hideVideoPlayer() {
                this.currentStreamer = null
                this.videoplay_hide = true;
                this.removeDraggable()
                this.removeResizeable()
                this.loadStreamers()
            },
            hidelistPlayer() {
                if(this.minimallist_hide){
                    this.minimallist_hide = false;
                }else{
                    this.minimallist_hide = true;
                }
            },
            toggleFullVideoPlayer() {
                if(this.currentStreamer.is_video_horizontal) {
                    if(this.isHorizontalFull){
                        this.isHorizontalFull = false;
                    }else{
                        this.resetDraggablePosition()
                        this.resetResize()
                        this.isHorizontalFull = true;
                    }
                } else {
                    if(this.isVerticalFull){
                        this.isVerticalFull = false;
                    }else{
                        this.resetDraggablePosition()
                        this.resetResize()
                        this.isVerticalFull = true;
                    }
                }
                this.calculateWebVideoWrapper()
            },
            showBoxGift() {
                this.boxgift_hide = false;
            },
            hideBoxGift() {
                this.boxgift_hide = true;
            },
            getUserImagePath(src) {
                if(!src) {
                    return ''
                }
                if(process.env.NODE_ENV === 'development') {
                    return `${this.envDomains.VUE_APP_API}${src}`
                }
                return src
            },
            async loadStreamers() {

                let stream_on = ''
                if(this.stream_on) {
                    stream_on = this.stream_on
                } else {
                    if(this.$route.name === 'Home') {
                        stream_on = 'general'
                    }
                }
                
                if(!stream_on) {
                    return
                }

                const res = await axiosWrapper({
                    method: 'POST',
                    url: `${this.envDomains.VUE_APP_LIFF_API}/api/streamer/get-by-stream-on`,
                    data: {
                        stream_on: stream_on,
                        limit: 4,
                    }
                })
                if(res.status === 200) {
                    this.streamers = [];
                    // console.log(this.user_profile);
                    if(this.user_profile){
                        for(let i=0;i<res.data.length;i++){
                            // console.log(res.data[i].room_id, this.user_profile.message_api_id);
                            if(res.data[i].room_id == this.user_profile.message_api_id){
                                this.streamers.push(res.data[i]);
                            }
                        }
                    }
                    
                }
            },
            async loadStreamInfo() {
                const res = await axiosWrapper({
                    method: 'POST',
                    url: `${this.envDomains.VUE_APP_LIFF_API}/api/streamer/get-stream-info`,
                    data: {
                        streamPath: this.currentStreamer.stream_server_api_stream_path,
                    }
                })
                if(res.status === 200) {
                    this.currentStreamerInfo = res.data
                }
            },
            async loadYoutubeVideoInfo() {

                const id = this.$youtube.getIdFromUrl(this.currentStreamer.stream_url)

                const res = await axiosWrapper({
                    method: 'POST',
                    url: `${this.envDomains.VUE_APP_API}/api/youtube/get-video-info`,
                    data: {
                        id: id
                    }
                })
                if(res.status === 200) {
                    this.currentYoutubeVideoInfo = res.data
                } else {
                    this.currentYoutubeVideoInfo = null
                }
            },
            calculateWebVideoWrapper() {
                setTimeout(() => {
                    const webVideoWrapper = this.$refs.webVideoWrapper
                    if(!webVideoWrapper) {
                        return
                    }
                    this.webVideoWrapperWidth = webVideoWrapper.clientWidth
                    this.webVideoWrapperHeight = webVideoWrapper.clientHeight
                }, 400)
            },
            toggleMuteButton() {
                this.isMute = !this.isMute
                if(this.isMute) {
                    this.playerBus.$emit('mute')
                } else {
                    this.playerBus.$emit('unMute')
                }
            },
            toggleFullscreen() {
                this.playerBus.$emit('fullscreen')
            },
            dragMouseDown(e) {
                if(this.isHorizontalFull || this.isVerticalFull) {
                    return
                }
                e = e || window.event;
                if(e.target.classList.contains('videoActionButton')) {
                    return
                }
                if(e.target.classList.contains('resize-button')) {
                    return
                }
                e.preventDefault();
                this.pos3 = e.clientX || e.touches[0].clientX;
                this.pos4 = e.clientY || e.touches[0].clientY;
                if(this.$is_mobile) {
                    document.addEventListener('touchmove', this.elementDrag)
                    document.addEventListener('touchend', this.closeDragElement)
                } else {
                    document.addEventListener('mousemove', this.elementDrag)
                    document.addEventListener('mouseup', this.closeDragElement)
                }
                
            },
            elementDrag(e) {
                if(this.isHorizontalFull || this.isVerticalFull) {
                    return
                }
                e = e || window.event;
                e.preventDefault();
                const clientX = e.clientX || e.touches[0].clientX
                const clientY = e.clientY || e.touches[0].clientY
                this.pos1 = this.pos3 - clientX;
                this.pos2 = this.pos4 - clientY;
                this.pos3 = clientX;
                this.pos4 = clientY;
                this.elmnt.style.top = (this.elmnt.offsetTop - this.pos2) + "px";
                this.elmnt.style.left = (this.elmnt.offsetLeft - this.pos1) + "px";
            },
            closeDragElement() {
                if(this.$is_mobile) {
                    document.removeEventListener('touchmove', this.elementDrag)
                    document.removeEventListener('touchend', this.closeDragElement)
                } else {   
                    document.removeEventListener('mousemove', this.elementDrag)
                    document.removeEventListener('mouseup', this.closeDragElement)
                }
            },
            addDraggable() {
                setTimeout(() => {
                    this.elmnt = this.$refs.boxVideoGuideWrapper
                    this.boxVideoGuideWrapperElement = this.elmnt
                    this.resetDraggablePosition()
                    if(this.$is_mobile) {
                        this.elmnt.addEventListener('touchstart', this.dragMouseDown)
                    } else {
                        this.elmnt.addEventListener('mousedown', this.dragMouseDown)
                    }
                }, 1)
            },
            removeDraggable() {
                if(this.$is_mobile) {
                    if(this.elmnt) {
                        this.elmnt.removeEventListener('touchstart', this.dragMouseDown)
                    }
                    document.removeEventListener('touchmove', this.elementDrag)
                    document.removeEventListener('touchend', this.closeDragElement)
                } else {
                    if(this.elmnt) {
                        this.elmnt.removeEventListener('mousedown', this.dragMouseDown)    
                    }
                    document.removeEventListener('mousemove', this.elementDrag)
                    document.removeEventListener('mouseup', this.closeDragElement)
                }
            },
            resetDraggablePosition() {
                this.boxVideoGuideWrapperElement.style.removeProperty('top')
                this.boxVideoGuideWrapperElement.style.removeProperty('left')
            },
            playOnMounted(id) {
                const streamer = this.streamers.find((streamer) => {
                    return parseInt(streamer.id) === parseInt(id)
                })
                if(!streamer) {
                    return
                }
                this.showVideoPlayer(streamer, true)
                // if(this.currentStreamer.is_video_horizontal) {
                //     this.isHorizontalFull = true;
                // } else {
                //     this.isVerticalFull = true;
                // }
            },
            playOnMountedByAdmin() {
                const streamer = this.streamers.find((streamer) => {
                    return streamer.is_autoplay
                })
                if(!streamer) {
                    return
                }
                this.showVideoPlayer(streamer, true)
            },
            streamerLableDisplay(streamer) {
                if(streamer.give_credit) {
                    return `แจกฟรี${streamer.give_credit}`
                }
                return 'live'
            },
        },
        async mounted() {
            await this.loadStreamers()
            const queryWatch = this.$route.query.watch
            if(queryWatch) {
                this.playOnMounted(queryWatch)
            } else {
                this.playOnMountedByAdmin()
            }
            window.addEventListener("orientationchange", this.calculateWebVideoWrapper)
        },
        beforeDestroy() {
            window.removeEventListener("orientationchange", this.calculateWebVideoWrapper)
            this.removeDraggable()
            this.removeResizeable()
        },
    }

</script>

<style scoped>
.resize-button {
    width: 15px;
    height: 15px;
    background-color: transparent;
    position: absolute;
    z-index: 12;
}
.resize-button.active {
    background-color: white;
}
.resize-button.top-left {
    top: 0;
    left: 0;
}
.resize-button.top-right {
    top: 0;
    right: 0;
}
.resize-button.bottom-right {
    bottom: 0;
    right: 0;
}
.resize-button.bottom-left {
    bottom: 0;
    left: 0;
}
</style>