<template>
    <div class="MiniSicboPage">
        <MiniSicbo @exit="$router.back()" :key="queryTableId"></MiniSicbo>
    </div>
</template>
<script>

import MiniSicbo from '@/components/game/MiniSicbo'

export default {
    name: 'MiniSicboPage',
    components: {
        MiniSicbo,
    },
    computed: {
        queryTableId() {
            return this.$route.query.table_id
        },
    },
}
</script>
<style scoped>

</style>