<template>
    <div :class="`LottoTypeIcon ${calWrapperClass}`">
        <img v-if="lotto_type === 'yeekee'" class="lotto-type-icon logo-yeekee-image" src="@/assets/images/icon/set-icon/icon-lotto-hitec.png">
<!--        <div v-if="lotto_type === 'yeekee'" class="lotto-type-icon logo-yeekee"></div>-->
        <img v-else-if="lotto_type === 'government'" class="lotto-type-icon" src="@/assets/images/flag/TH.png">
        <img v-else-if="lotto_type === 'hanoy'" class="lotto-type-icon  lotto-type-icon-nm" src="@/assets/images/flag/VN-nm.png">
        <img v-else-if="lotto_type === 'lao'" class="lotto-type-icon" src="@/assets/images/flag/LA.png">
        <img v-else-if="lotto_type === 'malay'" class="lotto-type-icon" src="@/assets/images/flag/MY.png">
        <img v-else-if="lotto_type === 'hanoy_special'" class="lotto-type-icon lotto-type-icon-sp" src="@/assets/images/flag/VN-sp.png">
        <img v-else-if="lotto_type === 'hanoy_vip'" class="lotto-type-icon lotto-type-icon-vip" src="@/assets/images/flag/VN-vip.png">
    </div>
</template>
<script>
export default {
    name: 'LottoTypeIcon',
    props: [
        'lotto_type',
        'customClass',
    ],
    computed: {
        calWrapperClass() {
            return (this.customClass) ? this.customClass : ''
        },
    }
}
</script>
<style scoped>

.lotto-type-icon  {
    width: 35px;
    height: 35px;
    border-radius: 50%;
}
.logo-yeekee-image{
    border-radius: 0;
    background-color: transparent;
}
.logo-yeekee {
    background-color: #f5ba62;
}
.logo-yeekee:before {
    content: 'ไฮเทค';
    position: absolute;
    font: 800 12px/35px 'Athiti';
    left: 0;
    top: 0;
    right: 0;
    text-align: center;
    color: #282831;
}
.left-detail img {
    height: 45px;
    width: 45px;
    border: solid 3px #fff;
    /*box-shadow: 0 3px 3px #aaa;*/
}
.lotto-type-icon-nm,
.lotto-type-icon-sp,
.lotto-type-icon-vip{border-radius: 0}
img.lotto-type-icon-nm,
img.lotto-type-icon-sp,
img.lotto-type-icon-vip{
    border: 0;
    box-shadow: none;
}
.left-detail img.logo-yeekee-image{
    border: 0!important;
    box-shadow: none!important;
}
.custom-price-table .lotto-type-icon {
    margin-right: 10px;
    width: 40px;
    height: 40px;
}
.custom-mesage-lotto-price .lotto-type-icon {
    position: relative;
    margin-right: 10px;
    width: 40px;
    height: 40px;
}
.lotto-flag .lotto-type-icon {
    width: 45px;
    height: 45px;
}
.lotto-flag .logo-yeekee:before {
    font: 800 15px/40px 'Athiti';
    line-height: 55px;
}
.custom-bill-block .logo-yeekee:before {
    font: 800 12px/35px 'Athiti';
}
.custom-list-menu-lotto .lotto-type-icon {
    width: 40px;
    height: 40px;
}
@media (min-width: 963px) and (max-width: 1300px){
    .lotto-type-icon {
        width: 40px;
        height: 40px;
    }
    .logo-yeekee:before {
        font: 800 15px/40px 'Athiti';
    }
    .custom-price-table .lotto-type-icon {
        margin-right: 5px;
    }
}

</style>