import {Howl} from 'howler'

export const TABLE_TYPE = {
    0: 'baccarat',
    1: 'sicbo',
    2: 'bean',
    3: 'dragontiger',
    4: 'baccarat2',
    5: 'baccarat3',
}

export const TABLE_TYPE_KEY = {
    baccarat: 0,
    sicbo: 1,
    bean: 2,
    dragontiger: 3,
    baccarat2: 4,
    baccarat3: 5,
}

export const TABLE_TYPE_MAP = {
    0: {
        title: 'บาคาร่า',
        routeName: 'Baccarat',
        routePath: '/mini-baccarat',
    },
    1: {
        title: 'ไฮโล',
        routeName: 'MiniSicbo',
        routePath: '/mini-sicbo',
    },
    2: {
        title: 'ถั่วหยิบ',
        routeName: 'MiniFantan',
        routePath: '/mini-fantan',
    },
    3: {
        title: 'เสือ มังกร',
        routeName: 'MiniDragonTiger',
        routePath: '/mini-dragontiger',
    },
    4: {
        title: 'บาคาร่า ประกันภัย',
        routeName: 'Baccarat2',
        routePath: '/mini-baccarat2',
    },
    5: {
        title: 'บาคาร่า vip',
        routeName: 'Baccarat3',
        routePath: '/mini-baccarat3',
    },
}

export const TABLE_GAME_STATUS = {
    0: {
        EN: 'SHUFFLING',
        TH: 'สับไพ่'
    },
    1: {
        EN: 'WAIT',
        TH: 'รอเริ่มเกม'
    },
    2: {
        EN: 'COUNTDOWN',
        TH: 'เริ่มเกมใหม่เปิดให้ bet ได้'
    },
    3: {
        EN: 'DEALING',
        TH: 'แสดงผลของเกม'
    },
    4: {
        EN: 'FINISH',
        TH: 'จบเกม'
    },
    5: {
        EN: 'PAUSE',
        TH: 'หยุดเกมชั่วคร่าว'
    },
    6: {
        EN: 'CANCEL',
        TH: 'ยกเลิกเกม'
    },
}

export const GAME_STATUS_KEY = {
    SHUFFLING: 0,
    WAIT: 1,
    COUNTDOWN: 2,
    DEALING: 3,
    FINISH: 4,
    PAUSE: 5,
    CANCEL: 6,
}

export const GAME_RESULT = {
    0: {
        EN: 'WAIT',
        TH: 'รอผลออก'
    },
    1: {
        EN: 'BANKER_WIN',
        TH: 'เจ้ามือ'
    },
    2: {
        EN: 'PLAYER_WIN',
        TH: 'ผู้เล่น'
    },
    3: {
        EN: 'TIE',
        TH: 'เสมอ'
    },
    4: {
        EN: 'CANCEL',
        TH: 'ยกเลิก'
    },
}

export const REWARD_RATIO = {
    1: {
        value: 0.95,
        label: '0.95 : 1',
    },
    2: {
        value: 1,
        label: '1 : 1',
    },
    3: {
        value: 8,
        label: '8 : 1',
    }
}

export const DRAGON_TIGER_GAME_RESULT = {
    0: {
        EN: 'WAIT',
        TH: 'รอผลออก'
    },
    1: {
        EN: 'DRAGON_WIN',
        TH: 'มังกร'
    },
    2: {
        EN: 'TIGER_WIN',
        TH: 'เสือ'
    },
    3: {
        EN: 'TIE',
        TH: 'เสมอ'
    },
    5: {
        EN: 'TIE',
        TH: 'คู่ เสมอ'
    },
    4: {
        EN: 'CANCEL',
        TH: 'ยกเลิก'
    },
}

export const DRAGON_TIGER_REWARD_RATIO = {
    1: {
        value: 1,
        label: '1 : 1',
    },
    2: {
        value: 1,
        label: '1 : 1',
    },
    3: {
        value: 8,
        label: '8 : 1',
    },
    5: {
        value: 30,
        label: '30 : 1',
    },
}

export const GAME_RESULT_KEY = {
    WAIT: 0,
    BANKER_WIN: 1,
    PLAYER_WIN: 2,
    TIE: 3,
    CANCEL: 4,
}

export const DRAGON_TIGER_GAME_RESULT_KEY = {
    WAIT: 0,
    DRAGON_WIN: 1,
    TIGER_WIN: 2,
    TIE: 3,
    CANCEL: 4,
}

export const CARD_TYPE_IMAGE = {
    0: '04',
    1: '02',
    2: '03',
    3: '01',
}

export const CARD_NUMBER_IMAGE = {
    1: '01',
    2: '02',
    3: '03',
    4: '04',
    5: '05',
    6: '06',
    7: '07',
    8: '08',
    9: '09',
    10: '10',
    11: '11',
    12: '12',
    13: '13',
}

export const CHIP_MAP = {
    5: {
        class: 'c-5',
        classMini: 'c5',
        label: '5',
    },
    10: {
        class: 'c-10',
        classMini: 'c10',
        label: '10',
    },
    20: {
        class: 'c-20',
        classMini: 'c20',
        label: '20',
    },
    50: {
        class: 'c-50',
        classMini: 'c50',
        label: '50',
    },
    100: {
        class: 'c-100',
        classMini: 'c100',
        label: '100',
    },
    200: {
        class: 'c-200',
        classMini: 'c200',
        label: '200',
    },
    500: {
        class: 'c-500',
        classMini: 'c500',
        label: '500',
    },
    1000: {
        class: 'c-1k',
        classMini: 'c1k',
        label: '1K',
    },
    2000: {
        class: 'c-2k',
        classMini: 'c2k',
        label: '2K',
    },
    5000: {
        class: 'c-5k',
        classMini: 'c5k',
        label: '5K',
    },
    10000: {
        class: 'c-10k',
        classMini: 'c10k',
        label: '10K',
    },
    20000: {
        class: 'c-20k',
        classMini: 'c20k',
        label: '20K',
    },
    50000: {
        class: 'c-50k',
        classMini: 'c50k',
        label: '50K',
    },
}

export const USER_CHOOSE = {
    BANKER_WIN: 1,
    PLAYER_WIN: 2,
    TIE: 3,
    BANKER_PAIR: 5,
    PLAYER_PAIR: 6,
    BANKER_NATURAL: 7,
    PLAYER_NATURAL: 8,
    LUCKY_SIX: 9,
    BANKER_WARRANTY: 11,
    PLAYER_WARRANTY: 12,
    BANKER0_WARRANTY: 13,
    PLAYER0_WARRANTY: 14,
    BANKER2_WARRANTY: 15,
    PLAYER2_WARRANTY: 16,
}

export const USER_CHOOSE_PROBABILITES = {
    1: 46,
    2: 46,
    3: 4,
    5: 2,
    6: 2,
    7: 0,
    8: 0,
    9: 0,
    11: 0,
    12: 0,
}

export const USER_CHOOSE_MAP = {
    1: {
        TH: 'เจ้ามือ',
    },
    2: {
        TH: 'ผู้เล่น',
    },
    3: {
        TH: 'เสมอ',
    },
    5: {
        TH: 'เจ้ามือ คู่',
    },
    6: {
        TH: 'ผู้เล่น คู่',
    },
    7: {
        TH: 'Banker Natural',
    },
    8: {
        TH: 'Player Natural',
    },
    9: {
        TH: 'Lucky Six',
    },
    11: {
        TH: 'เจ้ามือประกันภัย',
    },
    12: {
        TH: 'ผู้เล่นประกันภัย',
    },
    13: {
        TH: 'เจ้ามือประกันภัย',
    },
    14: {
        TH: 'ผู้เล่นประกันภัย',
    },
    15: {
        TH: 'เจ้ามือประกันภัย',
    },
    16: {
        TH: 'ผู้เล่นประกันภัย',
    },
}

export const DRAGON_TIGER_USER_CHOOSE = {
    DRAGON_WIN: 1,
    TIGER_WIN: 2,
    TIE: 3,
    PAIR: 5,
}

export const DRAGON_TIGER_USER_CHOOSE_MAP = {
    1: {
        TH: 'มังกร',
    },
    2: {
        TH: 'เสือ',
    },
    3: {
        TH: 'เสมอ',
    },
    5: {
        TH: 'คู่ เสมอ',
    },
}

export const BET_LIST_CLASS_MAP = {
    0: {
        1: 'bx-baccarat-b',
        2: 'bx-baccarat-p',
        3: 'bx-baccarat-t',
        5: 'bx-baccarat-bp',
        6: 'bx-baccarat-pp',
        7: '',
        8: '',
        9: '',
    },
    1: {
        1: 'text-hi',
        2: 'text-lo',
        3: 'text-hilo',
        4: 'text-white',
        5: 'text-white',
        6: 'text-white',
        7: 'text-white',
        8: 'text-white',
        9: 'text-white',
        10: 'text-white',
        11: 'text-white',
        12: 'text-white',
        13: 'text-white',
        14: 'text-white',
        15: 'text-white',
        16: 'text-white',
        17: 'text-white',
        18: 'text-white',
        19: 'text-white',
        20: 'text-white',
        22: 'text-white',
        23: 'text-white',
        24: 'text-white',
        25: 'text-white',
        26: 'text-white',
        27: 'text-white',
        28: 'text-white',
        29: 'text-white',
        30: 'text-white',
        31: 'text-white',
        32: 'text-white',
        33: 'text-white',
    },
    2: {
        1: 'fantan-bet-peg bet-1',
        2: 'fantan-bet-peg bet-2',
        3: 'fantan-bet-peg bet-3',
        5: 'fantan-bet-peg bet-4',
    },
    3: {
        1: 'bx-TD-dragon',
        2: 'bx-TD-tiger',
        3: 'bx-TD-tie',
        5: 'bx-TD-pair',
    },
}

export const TICKET_RESULT_MAP = {
    0: {
        TH: 'รอ',
        colorClass: 'text-color-white',
    },
    1: {
        TH: 'ชนะ',
        colorClass: 'text-color-green',
    },
    2: {
        TH: 'แพ้',
        colorClass: 'text-color-red',
    },
    3: {
        TH: 'เสมอ',
        colorClass: 'text-color-yello',
    },
    4: {
        TH: 'ยกเลิก',
        colorClass: 'text-color-white',
    },
}

export const TICKET_RESULT_KEY = {
    WAIT: 0,
    WIN: 1,
    LOSE: 2,
    DRAW: 3,
    CANCEL: 4,
}

export const IMAGE_ICON_PATH = {
    0: '/images/game/icon-baccarat.png',
    1: '/images/game/icon-sicbo.png',
    2: '/images/game/icon-fantan.png',
    3: '/images/game/icon-dragon-tiger.png',
    4: '/images/game/icon-baccarat.png',
    5: '/images/game/icon-baccarat.png',
}

export const SOUND = {
    START_BET: [
        new Howl({src: ['/sounds/game/start-bet/start_bet.mp3'], preload: false}),
        // new Howl({src: ['/sounds/game/start-bet/place_your_bet_please_1_th.mp3'], preload: false}),
        // new Howl({src: ['/sounds/game/start-bet/place_your_bet_please_2_th.mp3'], preload: false}),
        // new Howl({src: ['/sounds/game/start-bet/place_your_bet_please_3_th.mp3'], preload: false}),
        // new Howl({src: ['/sounds/game/start-bet/place_your_bet_please_4_th.mp3'], preload: false}),
        // new Howl({src: ['/sounds/game/start-bet/place_your_bet_please_5_th.mp3'], preload: false}),
        // new Howl({src: ['/sounds/game/start-bet/place_your_bet_please_6_th.mp3'], preload: false}),
        // new Howl({src: ['/sounds/game/start-bet/place_your_bet_please_7_th.mp3'], preload: false}),
        // new Howl({src: ['/sounds/game/start-bet/place_your_bet_please_8_th.mp3'], preload: false}),
    ],
    STOP_BET: new Howl({src: ['/sounds/game/no_more_bet_th.mp3'], preload: false}),
}

export const soundLoad = () => {
    for(const key in SOUND['START_BET']) {
        SOUND['START_BET'][key].load()
    }
    SOUND['STOP_BET'].load()
}