<template>
    <div class="MiniGame MiniFantan">
        <FloatButtomRightPanel/>
        <div class="close-baccarat">
            <div @click="exit"><i class="fa fa-times"></i></div>
        </div>
        <modal name="drawer-right" transition="modal-slide-left" class="MtableList"
               :pivotY="1"
               :pivotX="1"
               :adaptive="true"
               width="300px"
               height="100%">
            <GameTableList :tableList="baccaratTableList"></GameTableList>
        </modal>
        <modal name="drawer-stat-right" transition="modal-slide-left" class="editModalstat"
            :pivotY="1" 
            :pivotX="1" 
            :adaptive="true" 
            width="300px" 
            height="100%">
            <Stat :statArr="statArr"></Stat>
        </modal>
        <modal name="ticket-drawer" transition="modal-slide-left" class="editModalstat"
            :pivotY="1" 
            :pivotX="1" 
            :adaptive="true" 
            width="300px" 
            height="100%">
            <TicketList :ticketList="getTicketListByGame(tableType, tableId)"></TicketList>
        </modal>
        <div class="float-stream float-stream-sicbo">
            <GameHeader :table="currentTable"></GameHeader>

            <!--Animate 3D fantan-->

            <!-- <div v-if="liveMode === '3d'" class="box-game-animated animate-fantan">
                <div class="box-animated"></div>
            </div> -->
            
            <GamePlay3D
            v-if="liveMode === '3d'"
            :gameStatus="gameStatus"
            :dice="dice"
            gameType="bean"
            ></GamePlay3D>

            <!--End-->

            <div v-if="noTopSection === 'yes'" id="bankerDiv" style="bottom: unset; top: 0;">
                <UserBetChip 
                v-for="(bankerPay, key) of bankerPayArr" :key="key"
                :state="bankerPay.state" 
                :chip="bankerPay.chip"
                :value="bankerPay.value" 
                :user_choose="bankerPay.user_choose"></UserBetChip>
            </div>
            <div v-else class="middle-stream middle-phone middle-st-sicbo">
                <div class="middle-stream-info">
                    <div id="bankerDiv">
                        <UserBetChip 
                        v-for="(bankerPay, key) of bankerPayArr" :key="key"
                        :state="bankerPay.state" 
                        :chip="bankerPay.chip"
                        :value="bankerPay.value" 
                        :user_choose="bankerPay.user_choose"></UserBetChip>
                    </div>
                    <div class="box-balance-game">
                        <!-- <span class="balance-box balance-box-selectValue" style="left: 1px; right: unset;" @click="toSelectBetLimit"><i class="fas fa-coins"></i> เลือกวงเงิน</span> -->
                        <span class="balance-box">balance: {{balance | numberFormat}}</span>
                        <span v-if="liveMode === '3d'" @click="toggleLiveMode" class="box-switch-mode">
                            <span>3D</span>
                        </span>
                        <span v-else @click="toggleLiveMode" class="box-switch-mode">
                            <span>สด</span>
                        </span>
                        <div class="bx-volume-game">
                            <SoundToggleButton v-if="isOnDev" @click="toggleGameSound" :on="isGameSoundOn"></SoundToggleButton>
                        </div>
                    </div>
                    <div v-if="false" class="contentPanel-card">
                        <div class="player" style="width: 111px; height: 64px;">
                            <div style="text-align: center;">
                                <span>Dice {{dicePoint}}</span>
                            </div>
                            <div class="dice-row">
                                <transition-group name="bounce" style="display: flex;">
                                    <DiceFace v-for="(d, i) of dice" :key="`${d}${i}`" :number="d"></DiceFace>
                                </transition-group>
                            </div>
                        </div>
                    </div>
                    <div class="circle-wrap">
                        <p id="countdown">
                            <span v-if="gameStatus === GAME_STATUS.COUNTDOWN">{{secondCountDown}}</span>
                            <span class="txt-status" v-else>{{gameStatus | displayTableGameStatus}}</span>
                        </p>
                    </div>
                    <!-- <img src="@/assets/images/simple-3.jpg" /> -->
                    <VideoPlayer 
                    v-show="liveMode === 'video'" 
                    :tableVideoStreamPath="tableVideoStreamPath"
                    :srcHLS="tableVideoSrcHLS"
                    :srcFLV="tableVideoSrcFLV"
                    :width="videoPlayerWidth"
                    :height="videoPlayerHeight"
                    :playerBus="playerBus"
                    @ready="videoPlayerReadyHandle"
                    ></VideoPlayer>
                    <GameVerticalTool
                    :panel_to_show="panel_to_show"
                    @exit="exit"
                    @toggleTicketPanel="toggleTicketPanel"
                    @showSideMenu="showSideMenu"
                    @toggleStatePanel="toggleStatePanel"
                    @togglePlayPanel="togglePlayPanel"
                    @goDepositWithdraw="goDepositWithdraw">
                    </GameVerticalTool>
                </div>

                <div @click="togglePanelBottom" class="btn-control-bottom"
                :class="{'current': !is_show_panel_bottom}"></div>
            </div>
            <div v-if="noTopSection === 'yes'" id="playerDiv"></div>
            <div v-show="is_show_panel_bottom" class="bottom-stream" id="panelBottom">
                <FloatOpenDiceFT 
                :isPanelOpen="isFloatOpenDicePanelOpen"
                :dicePoint="dicePoint"
                :dice="dice"
                :gameResult="gameResult"></FloatOpenDiceFT>
                <div class="content-panel-sicbo content-fantan" :class="{'toggle-box': isTogglePlayBox}" style="background: none;">
                    <span @click="togglePlayBox" class="close-box-sicbo"><i class="fa fa-chevron-down"></i> </span>
                    <div class="wrapper-dashboard">
                        <!-- <div class="bg-winner-active" v-show="gameStatus === GAME_STATUS.FINISH" style="z-index: 25;">
                            <div v-show="gameResult === GAME_RESULT.WHITE" class="fantan-result white-lose"><span></span></div>
                            <div v-show="gameResult === GAME_RESULT.GREEN" class="fantan-result green-lose"><span></span></div>
                            <div v-show="gameResult === GAME_RESULT.YELLOW" class="fantan-result yellow-lose"><span></span></div>
                            <div v-show="gameResult === GAME_RESULT.RED" class="fantan-result red-lose"><span></span></div>
                        </div> -->
                        <div class="box-top-user-flex">
                            <carousel
                                    :speed="400"
                                    :per-page="1.1"
                                    :mouseDrag="true"
                                    :spacePadding="1"
                                    :navigationEnabled="true"
                                    :paginationEnabled="false"
                                    navigationNextLabel="❯"
                                    navigationPrevLabel="❮"
                                    paginationPosition="bottom"
                            >
                                    <slide v-for="(panel, i) of tableConfig" :key="i" class="">
                                        <div class="box-top-user-list">
                                            <div class="box-games-list box-game-fantan">
                                                <div class="box-bg-game">
                                                    <div class="box-d-price">
                                                        <div class="btn-d-price">แผง <span>{{i + 1}}</span> : <strong><i
                                                                class="fas fa-coins"></i> {{calPanelTotal(i) | numberFormat}}</strong></div>
                                                    </div>
                                                    <div class="wrapper-box-board">
                                                        <div class="floated"></div>
                                                        <div class="row-dashboard-game">
                                                            <PanelPlay
                                                                    :panelIndex="i"
                                                                    :currentPanelIndex="currentPanelIndex"
                                                                    :user_choose="user_choose"
                                                                    :amount="amount"
                                                                    :amountRemain="getAmountRemain(i, USER_CHOOSE.WHITE)"
                                                                    :chipArr="getPanelChipArr(i, USER_CHOOSE.WHITE)"
                                                                    customClass="box-d-child"
                                                                    label="1"
                                                                    :choice="USER_CHOOSE.WHITE"
                                                                    :userBetArr="filterUserBetArr(USER_CHOOSE.WHITE, i)"
                                                                    :isPanelWin="isPanelWin(GAME_RESULT.WHITE, gameResult)"
                                                                    :shipState="calPanelChipState(GAME_RESULT.WHITE)"
                                                                    :isOnSendBet="isOnSendBet"
                                                                    @selectChip="selectChip"
                                                                    @selectBet="selectBet"
                                                                    @confirmBet="confirmBet"
                                                                    @cancelBet="cancelBet">
                                                            </PanelPlay>
                                                            <PanelPlay
                                                                    :panelIndex="i"
                                                                    :currentPanelIndex="currentPanelIndex"
                                                                    :user_choose="user_choose"
                                                                    :amount="amount"
                                                                    :amountRemain="getAmountRemain(i, USER_CHOOSE.GREEN)"
                                                                    :chipArr="getPanelChipArr(i, USER_CHOOSE.GREEN)"
                                                                    customClass="box-d-child bg-d-green"
                                                                    label="2"
                                                                    :choice="USER_CHOOSE.GREEN"
                                                                    :userBetArr="filterUserBetArr(USER_CHOOSE.GREEN, i)"
                                                                    :isPanelWin="isPanelWin(GAME_RESULT.GREEN, gameResult)"
                                                                    :shipState="calPanelChipState(GAME_RESULT.GREEN)"
                                                                    :isOnSendBet="isOnSendBet"
                                                                    @selectChip="selectChip"
                                                                    @selectBet="selectBet"
                                                                    @confirmBet="confirmBet"
                                                                    @cancelBet="cancelBet">
                                                            </PanelPlay>
                                                        </div>
                                                        <div class="row-dashboard-game">
                                                            <PanelPlay
                                                                    :panelIndex="i"
                                                                    :currentPanelIndex="currentPanelIndex"
                                                                    :user_choose="user_choose"
                                                                    :amount="amount"
                                                                    :amountRemain="getAmountRemain(i, USER_CHOOSE.YELLOW)"
                                                                    :chipArr="getPanelChipArr(i, USER_CHOOSE.YELLOW)"
                                                                    customClass="box-d-child bg-d-yellow"
                                                                    label="3"
                                                                    :choice="USER_CHOOSE.YELLOW"
                                                                    :userBetArr="filterUserBetArr(USER_CHOOSE.YELLOW, i)"
                                                                    :isPanelWin="isPanelWin(GAME_RESULT.YELLOW, gameResult)"
                                                                    :shipState="calPanelChipState(GAME_RESULT.YELLOW)"
                                                                    :isOnSendBet="isOnSendBet"
                                                                    @selectChip="selectChip"
                                                                    @selectBet="selectBet"
                                                                    @confirmBet="confirmBet"
                                                                    @cancelBet="cancelBet">
                                                            </PanelPlay>
                                                            <PanelPlay
                                                                    :panelIndex="i"
                                                                    :currentPanelIndex="currentPanelIndex"
                                                                    :user_choose="user_choose"
                                                                    :amount="amount"
                                                                    :amountRemain="getAmountRemain(i, USER_CHOOSE.RED)"
                                                                    :chipArr="getPanelChipArr(i, USER_CHOOSE.RED)"
                                                                    customClass="box-d-child bg-d-red"
                                                                    label="4"
                                                                    :choice="USER_CHOOSE.RED"
                                                                    :userBetArr="filterUserBetArr(USER_CHOOSE.RED, i)"
                                                                    :isPanelWin="isPanelWin(GAME_RESULT.RED, gameResult)"
                                                                    :shipState="calPanelChipState(GAME_RESULT.RED)"
                                                                    :isOnSendBet="isOnSendBet"
                                                                    @selectChip="selectChip"
                                                                    @selectBet="selectBet"
                                                                    @confirmBet="confirmBet"
                                                                    @cancelBet="cancelBet">
                                                            </PanelPlay>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </slide>
                            </carousel>

                        </div>
                        <div class="box-balances">
                            <div class="box-balances-total">

                                <span class="text-white-50"><i class="fas fa-coins"></i> ราคาเดิมพัน :</span>
                                <div class="box-cash">
                                    <div class="fade-cash">+100</div>
                                    <strong id="cash">
                                        {{totalUserBet | numberFormat}}
                                    </strong>
                                </div>
                            </div>
                            <div class="box-list-coins"></div>
                        </div>
                    </div>
                </div>
            </div>

            <div id="playerDiv">
                <UserBetChip 
                v-for="(bankerPay, key) of playerPayArr" :key="key"
                :state="bankerPay.state" 
                :chip="bankerPay.chip" 
                :value="bankerPay.value" 
                :user_choose="bankerPay.user_choose"></UserBetChip>
            </div>

            <GameTopPlayer 
            :list="bet_user_ranking" 
            :bet_list="bet_user_bet_list" 
            :tableType="tableType">
            </GameTopPlayer>
        </div>
        <Chat v-if="tableId && noChat !== 'yes'" :data_room_id="`_table_id=${tableId}`"></Chat>


<!--        <div data-v-216d1262="" aria-expanded="true" data-modal="result-modal" class="v&#45;&#45;modal-overlay"><div class="v&#45;&#45;modal-background-click"><div class="v&#45;&#45;modal-top-right"></div> <div role="dialog" aria-modal="true" class="v&#45;&#45;modal-box modal-background-transparent box-result-minigame" style="top: 350px; left: 3px; width: 367.5px; height: auto;"><div data-v-216d1262="" class="flex-center-center "><div data-v-216d1262="" class="Aligner-item"><div data-v-2b96ce1a="" data-v-216d1262="" class="GameResult"><div data-v-2b96ce1a="" class="text-result">คุณชนะ</div><div data-v-2b96ce1a="" class="text-result" style="display: none;">คุณเสีย</div><div data-v-2b96ce1a="" class="item-point"><span data-v-2b96ce1a="" class="txt-green">ได้ 20.00</span><span data-v-2b96ce1a="" class="txt-red" style="display: none;">เสีย 20.00</span><span data-v-2b96ce1a="" style="display: none;">เสมอ</span></div></div></div></div> &lt;!&ndash;&ndash;&gt;</div></div></div>-->

        <modal name="result-modal" :height="'auto'" width="98%" classes="modal-background-transparent box-result-minigame">
            <div class="flex-center-center ">
                <div class="Aligner-item Aligner-item-result">
                    <Result :profitLoss="profitLoss"></Result>
                </div>
            </div>
        </modal>

        <modal name="not-bet-modal" :height="'auto'" width="98%" classes="modal-background-transparent modalAlert"
        @opened="autoCloseModal('not-bet-modal')">
            <div class="flex-center-center">
                <div class="Aligner-item" style="color: white;">
                    รอบยังไม่เปิด 
                    <br>
                    กรุณารอ
                </div>
            </div>
        </modal>

        <modal name="bet-message-modal" :height="'auto'" width="100%" classes="modal-background-transparent modalAlert"
        @opened="autoCloseModal('bet-message-modal')">
            <div class="flex-center-center">
                <div class="Aligner-item" style="color: white;">
                    <p><i class="fas fa-exclamation-triangle"></i> {{betMessage}}</p>
                </div>
            </div>
        </modal>

        <modal name="select-bet-limit-modal" :height="'auto'" width="98%"  class="editModalSelect"
        classes="modal-background-transparent"
        :clickToClose="false">
            <div class="flex-center-center">
                <div class="Aligner-item Aligner-item-select-value" style="color: white; text-align: center;">
                    <p><i class="fas fa-coins"></i> เลือกวงเงิน</p>
                    <button v-for="(betLimit, key) of betLimitArr" :key="key"
                    class="btn btn-cs" style="margin: 4px 0; width: 100%;"
                    @click="setBetLimit(betLimit.config_id)">
                        {{betLimit.min_bet}} - {{betLimit.max_bet}}
                    </button>
                </div>
            </div>
        </modal>

        <b-modal ref="error-message-modal" 
        hide-header
        centered
        ok-title="ตกลง"
        cancel-title="รอ"
        @ok="$router.replace({name: 'GameType'})">
            <div class="modal-notify-text-center">
                <h5>{{loadGameTableErrorMessage}}</h5>
            </div>
        </b-modal>

        <GoLobbyModal></GoLobbyModal>
    </div>
</template>
<script>
    
import GameMixin from '@/mixins/Game'
import GameTableList from '@/components/game/TableList'
import GameHeader from '@/components/game/Header'
import GameVerticalTool from '@/components/game/VerticalTool'
import UserMixin from '@/mixins/UserMixin'

import GameTopPlayer from '@/components/game/TopPlayer'
import {GAME_STATUS_KEY, CHIP_MAP} from '@/components/game/Constant'
import {USER_CHOOSE, GAME_RESULT_KEY} from '@/components/game/fantan/Constant'
import {SOUND, soundLoad} from '@/components/game/sicbo/Constant'
import Stat from '@/components/game/fantan/Stat'
import waitForSecond from '@/lib/waitForSecond'
import DiceFace from '@/components/game/sicbo/DiceFace'
import PanelPlay from '@/components/game/fantan/PanelPlay'
import Chat from '@/components/Chat'
import TicketList from '@/components/game/TicketList'
import Result from '@/components/game/Result'
import VideoPlayer from './VideoPlayer'
import UserBetChip from './UserBetChip'
import GamePlay3D from './sicbo/GamePlay3D'
import { Carousel, Slide } from 'vue-carousel'
import debounce from '@/debounce'
import SoundToggleButton from './SoundToggleButton.vue'
import randomBetween from '@/lib/randomBetween'
import FloatButtomRightPanel from '@/components/FloatButtomRightPanel'
import FloatOpenDiceFT from '@/components/game/fantan/FloatOpenDiceFT'


const SECOND_START = 30

export default {
    name: 'MiniFantan',
    components: {
        FloatOpenDiceFT,
        FloatButtomRightPanel,
        GameTableList,
        GameHeader,
        GameVerticalTool,
        GameTopPlayer,
        Stat,
        DiceFace,
        PanelPlay,
        Chat,
        TicketList,
        Result,
        VideoPlayer,
        UserBetChip,
        GamePlay3D,
        Carousel,
        Slide,
        SoundToggleButton,
    },
    mixins: [
        GameMixin,
        UserMixin,
    ],
    data() {
        return {
            chip_arr: [5, 10, 50, 100, 500, 1000],
            amount: 0,
            currentPanelIndex: -1,
            user_choose: 0,
            otherBetChipArr: [],
            otherBetChooseArr: [],
            otherBetArr: [],
            otherBetTimer: null,
            totalAmount: 0,
            USER_CHOOSE: null,
            GAME_STATUS: null,
            GAME_RESULT: null,
            timer: null,
            secondCountDown: SECOND_START,
            loading: null,
            diceFromAdd: [],
            isTogglePlayBox: false,
            panelChipArr: [],
        }
    },
    computed: {
        secondRemain() {
            const now = this.$moment()
            if (!this.countDownStart) {
                return SECOND_START

            }
            const timestamp = this.$moment(this.countDownStart)
            const remain = parseInt(this.$moment.duration(now.diff(timestamp)).asSeconds())
            return (SECOND_START - remain > 0) ? SECOND_START - remain : 0
        },
        dice() {
            if(this.diceFromAdd.length > 0) {
                return this.diceFromAdd
            } else if(this.gameInfo) {
                return this.gameInfo.dice
            } else {
                return []
            }
        },
        dicePoint() {
            if(!this.dice) {
                return '-'
            }
            if(this.dice.length === 0) {
                return '-'
            }
            const total = this.dice.reduce((sum, item) => {
                return parseInt(sum) + parseInt(item)
            }, 0)
            const calculated = total % 4
            return (calculated === 0) ? 4 : calculated
        },
        tableConfig() {
            return (this.currentTable) ? this.currentTable.table_config : null
        },
        gameTableConfig() {
            return (this.currentGame) ? this.currentGame.table_config : null
        },
        isFloatOpenDicePanelOpen() {
            return parseInt(this.gameStatus) === GAME_STATUS_KEY.DEALING || parseInt(this.gameStatus) === GAME_STATUS_KEY.FINISH
        },
    },
    watch: {
        gameStatus(newVal, oldVal) {
            if(this.GAME_STATUS.DEALING === parseInt(newVal)) {
                this.playStopBetSound()
                this.cancelBet()
            }
            if (parseInt(oldVal) === this.GAME_STATUS.DEALING && parseInt(newVal) === this.GAME_STATUS.FINISH) {//end round

                this.playGameSound(SOUND['POINT'][parseInt(this.dicePoint)])

                setTimeout(() => {
                    this.clearDiceFromAdd()
                    this.clearUserBetArr()
                    this.clearBet()
                    this.onRoundEnd()
                }, 2 * 1000)
            }
            if (parseInt(newVal) === this.GAME_STATUS.COUNTDOWN) {

                setTimeout(() => {
                    const shakeSound = SOUND['SHAKE'][randomBetween(0, SOUND['SHAKE'].length - 1)]
                    this.playGameSound(shakeSound)
                }, 2000)

                this.playStartBetSound()

                this.startCountDown()
                this.generatePanelChipArr()
            } else {
                this.stopCountDown()
            }
        },
        secondRemain(newVal, oldVal) {
            if (oldVal === 0 && newVal > 0) {
                this.stopCountDown()
                this.startCountDown()
            }
        },
        tableConfig(newVal, oldVal) {
            if(!oldVal && newVal) {
                this.generatePanelChipArr()
            }
        },
        gameTableConfig(newVal, oldVal) {
            if(!oldVal && newVal) {
                this.generatePanelChipArr()
            }
        },
        userBetArr: {
            deep: true,
            handler(newVal) {
                for(const item of newVal) {
                    if(item.state === 'confirm' && !item.isRemoveChip) {
                        const chipIndex = this.panelChipArr[item.panelIndex][item.user_choose].findIndex((panelChip) => {
                            return parseInt(panelChip.chip) === parseInt(item.amount)
                        })
                        this.panelChipArr[item.panelIndex][item.user_choose][chipIndex].quantity -= 1
                        item.isRemoveChip = true
                    }
                }
            }
        },
    },
    methods: {
        toggleStatePanel() {
            this.$modal.show('drawer-stat-right')
        },
        showLoadTableGameError() {
            this.$refs['error-message-modal'].show()
        },
        selectBet(data) {

            if(this.GAME_STATUS.COUNTDOWN !== this.gameStatus) {
                return
            }

            const user_choose = data.user_choose
            const currentPanelIndex = data.panelIndex

            let chip = this.findChip(currentPanelIndex, this.amount)

            this.user_choose = user_choose
            this.currentPanelIndex =  currentPanelIndex

            if(!chip) {
                return
            }

            this.userBetArr = this.userBetArr.filter((userBet) => {
                return userBet.state === 'confirm'
            })
            this.userBetArr.push({
                state: 'wait',
                amount: this.amount, 
                user_choose: user_choose, 
                latest_chip: this.amount,
                panelIndex: this.currentPanelIndex,
            })
        },
        clearBet() {
            this.user_choose = 0
            this.amount = 0
            this.currentPanelIndex = -1
        },
        findChip(panelIndex, amount) {
            return this.tableConfig[panelIndex].coins.find((chip) => {
                return parseInt(chip) === parseInt(amount)
            })
        },
        filterUserBetArrByUserChoose(user_choose) {
            return this.userBetArr.filter((bet) => {
                return bet.user_choose === user_choose
            })
        },
        filterUserBetArr(user_choose, panelIndex) {
            return this.userBetArr.filter((bet) => {
                return parseInt(bet.user_choose) === parseInt(user_choose) && 
                       parseInt(bet.panelIndex) === parseInt(panelIndex)
            })
        },
        async confirmBet() {
            this._confirmBet()
        },
        async onDice(data) {
            if(!this.currentGame || !this.currentTable) {
                    return
                }
                if(parseInt(data.gbs_id) === parseInt(this.currentGame.gbs_id) && 
                    parseInt(data.game_index) === parseInt(this.currentTable.table_type)) {
                this.diceFromAdd = []
                for(const item of data.dice) {
                    await waitForSecond(500)
                    setTimeout(() => {
                        this.playGameSound(SOUND['DICE'][parseInt(item)])
                    }, 300)
                    this.diceFromAdd.push(item)
                }
            }
        },
        clearDiceFromAdd() {
            this.diceFromAdd = []
        },
        togglePlayBox() {
            this.isTogglePlayBox = !this.isTogglePlayBox
        },
        calPanelTotal(panelIndex) {

            let total = 0

            if(!this.gameTableConfig) {
                return total
            }

            const amountRemain = this.gameTableConfig[panelIndex]

            for(const key in amountRemain) {
                total += parseInt(amountRemain[key])
            }

            return total
        },
        isPanelWin(panel, result) {
            return panel === result
        },
        clearOtherBet(){},
        getAmountRemain(panelIndex, user_choose) {
            if(!this.gameTableConfig) {
                return 0
            }
            return this.gameTableConfig[panelIndex][user_choose]
        },
        calPanelChipState(panel) {
            if(!this.gameResult) {
                return 'playerPlace'
            }
            if(panel !== this.gameResult) {
                return 'goPlayer'
            } else {
                return 'goBanker'
            }
        },
        generatePanelChipArr: debounce( function() {

            if(this.currentTable && this.gameTableConfig) {

                if(this.userBetArr.length > 0) {
                    return
                }

                const calCoins = (amountRemain, coins) => {

                    const calQuantity = (coins, remain) => {
                        for(const coin of coins) {
                            if(parseInt(remain) - parseInt(coin) >= 0) {
                                quantityMap[coin] = (quantityMap[coin]) ? quantityMap[coin] += 1 : 1
                                remain -= coin
                            } else {
                                break
                            }
                        }
                        if(parseInt(remain) - parseInt(coins[0]) >= 0) {
                            return calQuantity(coins, remain)
                        } else {
                            return quantityMap
                        }
                    }

                    let remain = amountRemain

                    const quantityMap = {}

                    return calQuantity(coins, remain)
                }

                const tableConfig = this.currentTable.table_config

                const panelArr = (tableConfig) ? [...tableConfig] : []
                const userChooseArr = [1, 2, 3, 5]

                const dataArr = []

                for(const [panelIndex, item] of Object.entries(panelArr)) {

                    const panelChipMap = {}

                    for(const userChoose of userChooseArr) {

                        const amountRemain = this.gameTableConfig[panelIndex][userChoose]

                        const quantityMap = calCoins(amountRemain, item.coins)

                        const chipArr = []
                        for(const coin of item.coins) {
                            chipArr.push({
                                chip: coin,
                                quantity: quantityMap[coin]
                            })
                        }
                        panelChipMap[userChoose] = chipArr
                        
                    }
                    dataArr.push(panelChipMap)
                }

                this.panelChipArr = dataArr
            } 
        }, 400),
        getPanelChipArr(panelIndex, userChoose) {
            if(this.panelChipArr.length === 0) {
                return []
            }
            return this.panelChipArr[panelIndex][userChoose]
        },
    },
    created() {
        
        this.USER_CHOOSE = USER_CHOOSE
        this.GAME_STATUS = GAME_STATUS_KEY
        this.GAME_RESULT = GAME_RESULT_KEY

        for (const key in CHIP_MAP) {
            this.otherBetChipArr.push(key)
        }
        for (const key in this.USER_CHOOSE) {
            this.otherBetChooseArr.push(this.USER_CHOOSE[key])
        }
    },
    async mounted() {

        if (this.gameStatus === this.GAME_STATUS.COUNTDOWN) {
            this.startCountDown()
        }
        soundLoad()
    },
    beforeDestroy() {
        this.stopCountDown()
    }
}
</script>
<style>

    @media (orientation: landscape) {

        .LiffGameHome .wrapper-dashboard{
            position: relative;
            background-color: #222d42;
            height: 100%;
        }
        .LiffGameHome .box-panel-play{
            height: 100%;
        }
        .LiffGameHome  #bankerDiv {
            width: 50px;
            height: 50px;
            position: absolute;
            margin: auto;
            right: 0;
            bottom: 100px;
            left: 0;
            z-index: 2;
        }
        .LiffGameHome #playerDiv {
            width: 5vw;
            height: 5vw;
            position: fixed;
            bottom: 0;
            left: 0;
            right: 0;
            margin: 0 auto;
        }
        .LiffGameHome .MiniBaccarat .bottom-stream{
            position: relative;
            height: 100%;
        }
        .LiffGameHome .content-panel-play {
            display: block;
            width: auto;
            height: 100%;
            padding-bottom: 0;
        }
        .LiffGameHome .content-panel-play-miniBacarat,
        .LiffGameHome .content-panel-play-DragonTiger{
            position: relative!important;
        }
        .LiffGameHome .box-panel-play-tool{
            position: absolute!important;
            right: 0;
            left: 0;
            bottom: 0;
            justify-content: center;
            border-radius: 0;
        }
        .LiffGameHome .st-content-play{
            height: 100%;
        }
        .LiffGameHome  .box-coin {
            flex-direction: row;
        }
        .LiffGameHome .b-coin {
            width: 40px;
            height: 40px;
            margin: 0 6px;
        }
        .LiffGameHome .float-stream{
            flex-direction: column;
            justify-content: space-around;
            position: relative;
            height: 100vh;
        }
        .LiffGameHome .GameTopPlayer.box-top-user{
            position: fixed;
            top: 0;
            width: 300px;
            height: 57px;
            z-index: 100;
        }
        .LiffGameHome .box-balances{
            position: absolute;
            bottom: 0;
            left: 0;
            right: 0;
        }
        .LiffGameHome .coins-box{
            width: 350px!important;
            background-color: #282839!important;
            padding: 7px 8px 7px 7px!important;
            height: 160px;
        }
        .LiffGameHome .box-btn-right {
            flex-direction: row !important;
        }
        .LiffGameHome .MiniFantan .box-panel-play-tool {
            width: 100% !important;
            position: unset !important;

            display: flex;
            border-radius: 20px;
        }
    }
</style>
<style>



    @media only screen
    and (max-device-width: 1300px)
    and (orientation: landscape){

        .MiniFantan .messaging{display: none!important}
        .MiniFantan .box-menu-game{
            top: 50px!important;
            right: 10px!important;
        }
        .MiniFantan .close-baccarat {top: 30px!important;
            right: 13px!important;}
    }
</style>
<style src="@/assets/css/fantan.css" scoped></style>
<style scoped src="@/assets/css/animate-games.css"></style>
<style scoped>
    .VueCarousel-slide{
        flex-basis: initial;
    }
    .box-switch-mode{
        left: 10px !important;
    }
    .box-menu-game {
        z-index: 20 !important;
    }
    .close-baccarat {
        position: fixed;
        top: 28px;
        z-index: 9;
        right: 22px;
        width: 25px;
        height: 25px;
        display: none;
        background-color: #000;
        border-radius: 50%;
        color: #fff;
        font-size: 12px;
        border: solid 1px #fab600;
        text-align: center;
        line-height: 20px;
        box-shadow: rgba(0, 0, 0, 0.25) 0px 14px 28px, rgba(0, 0, 0, 0.22) 0px 10px 10px;
    }




    .user-list-detail{
        line-height: 20px;
    }
    .flex-center-center {
        display: flex;
        justify-content: center;
        align-items: center;
    }

    .balance-box {
        position: absolute;
        bottom: 1px;
        right: 1px;
    }
    .modal-notify-text-center {
        display: flex;
        justify-content: center;
        height: 100px;
        align-items: center;
    }
    .btn-cs{
        background-color: rgba(243, 114, 212, 0.9);
        border-radius: 20px;
        color: #fff !important;
        text-align: center !important;
        padding: 2px 5px !important;
    }

    .Aligner-item{
        font-family: "Athiti", sans-serif;
        background-color: rgba(0,0,0,0.9);
        padding: 15px 10px !important;
        border-radius: 10px !important;
    }

    .Aligner-item p{
        font-weight: bold;
        margin-bottom: 5px;
        font-size: 13px;
    }

    .Aligner-item i{
        color: #fab602;
    }

    .v--modal-overlay {
        background: rgba(0, 0, 0, 0.6) !important;
    }
    .FloatChip{
        width: 18px;
    }


    .player {
        background-color: rgba(150,9,9,0.6)!important;
        opacity: 1!important;
        border: solid 0!important;
    }
    .dice-row {
        display: flex;
        padding: 4px;
    }
    .dice-row * {
        margin: 0 2px;
    }

    .bounce-enter-active {
    animation: bounce-in .5s;
    }
    .bounce-leave-active {
    animation: bounce-in .3s reverse;
    }


    @media screen and (min-width: 600px) and (max-width: 1024px) {
        .box-menu-game {
            /* display: none !important; */
            /*top: 0!important;*/
            /*bottom: 0;*/
            /*margin: auto;*/
            /*right: 10px!important;*/
            /*height: 194px;*/
        }
        .close-baccarat {
            /*right: 20px !important;*/
            /*top: 50px !important;*/
        }

    }

    @keyframes bounce-in {
    0% {
        transform: scale(0);
    }
    50% {
        transform: scale(1.1);
    }
    100% {
        transform: scale(1);
    }
    }
</style>