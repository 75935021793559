<template>
    <div class="AdminMessage">
        <div class="v-content-inner">
            <div class="v-toolbar">
                <div class="toolbar-item">
                    
                </div>
                <div class="toolbar-item">
                    
                </div>
            </div>
            <div class="v-list">
                <div style="font-size: 24px;">สร้างข้อความ</div>
                <div class="v-line-seperator"></div>
                <div>เลือกประเภทข้อความ</div>
                <div class="v-line-seperator"></div>
                <el-button @click="preview('top_up')" type="primary" :plain="!(preview_type === 'top_up')">เติมเงิน</el-button>
                <el-button @click="preview('lotto_price')" type="primary" :plain="!(preview_type === 'lotto_price')">ราคาหวย</el-button>
                <el-button @click="preview('live')" type="primary" :plain="!(preview_type === 'live')">ไลฟ์/วิดีโอ</el-button>
                <el-button @click="preview('football')" type="primary" :plain="!(preview_type === 'football')">ฟุตบอล</el-button>
                <el-button @click="preview('lotto_news')" type="primary" :plain="!(preview_type === 'lotto_news')">ข่าวหวย</el-button>
                <el-button @click="preview('system')" type="primary" :plain="!(preview_type === 'system')">ระบบ</el-button>
                <el-button @click="preview('gallery')" type="primary" :plain="!(preview_type === 'gallery')">แกลอรี่</el-button>
                <el-button @click="preview('rich')" type="primary" :plain="!(preview_type === 'rich')">ริชเมสเสจ</el-button>
                <el-button @click="preview('timeline')" type="primary" :plain="!(preview_type === 'timeline')">timeline</el-button>
                <el-button @click="preview('promotion')" type="primary" :plain="!(preview_type === 'promotion')">โปรโมชั่น</el-button>
                <el-button @click="toClear" icon="el-icon-brush">ล้าง</el-button>
                <div class="v-line-seperator"></div>
                <div v-if="preview_type === 'top_up'">
                    <AdminToolTopUp :channel="channel" :bus="bus" @change="formChange"/>
                </div>
                <div v-if="preview_type === 'lotto_price'">
                    <AdminToolLottoPrice :bus="bus" @change="formChange"/>
                </div>
                <div v-else-if="preview_type === 'live'" class="v-live-create-tool">
                    <AdminToolLive :bus="bus" @change="formChange"/>
                </div>
                <div v-else-if="preview_type === 'football'">
                    <AdminToolFootball :bus="bus" @change="formChange"/>
                </div>
                <div v-else-if="preview_type === 'lotto_news'">
                    <AdminToolContents 
                    :bus="bus" 
                    type="lotto_news"
                    @change="formChange"/>
                </div>
                <div v-else-if="preview_type === 'system'">
                    <AdminToolContents 
                    :bus="bus" 
                    type="system"
                    @change="formChange"/>
                </div>
                <div v-else-if="preview_type === 'gallery'">
                    <AdminToolContents 
                    :bus="bus" 
                    type="gallery"
                    @change="formChange"/>
                </div>
                <div v-else-if="preview_type === 'rich'">
                    <AdminToolContents 
                    :bus="bus" 
                    type="rich"
                    @change="formChange"/>
                </div>
                <div v-else-if="preview_type === 'timeline'">
                    <AdminToolTimelines
                    :bus="bus"
                    @change="formChange"/>
                </div>
                <div v-else-if="preview_type === 'promotion'">
                    <AdminToolPromotion
                    :bus="bus"
                    @change="formChange"/>
                </div>
                <div class="v-line-seperator"></div>
                <div class="v-preview">
                    <MessageTopUp v-if="preview_type === 'top_up'" :message="message"></MessageTopUp>
                    <MessageLottoPrice v-else-if="preview_type === 'lotto_price'" :message="message"/>
                    <MessageLive v-else-if="preview_type === 'live'" :message="message"/>
                    <MessageFootball v-else-if="preview_type === 'football'" :message="message"/>
                    <MessageLottoNews v-else-if="preview_type === 'lotto_news'" :message="message"/>
                    <MessageSystem v-else-if="preview_type === 'system'" :message="message"/>
                    <MessageGallery v-else-if="preview_type === 'gallery'" :message="message"/>
                    <MessageRich v-else-if="preview_type === 'rich'" :message="message" showGrid="yes"></MessageRich>
                    <MessageTimeline v-else-if="preview_type === 'timeline'" :message="message"/>
                    <MessagePromotion v-else-if="preview_type === 'promotion'" :message="message"/>
                </div>
                <div class="v-line-seperator"></div>
                <el-button @click="send" type="primary" style="width: 50%;" :disabled="!message">ส่งข้อความ</el-button>
            </div>
            <div class="v-toolbar-bottom">
                
            </div>
        </div>
    </div>
</template>
<script>

import AdminToolTopUp from '@/components/admin-tool/TopUp'
import AdminToolLottoPrice from '@/components/admin-tool/LottoPrice'
import AdminToolLive from '@/components/admin-tool/Live'
import AdminToolFootball from '@/components/admin-tool/Football'
import AdminToolContents from '@/components/admin-tool/Contents'
import AdminToolTimelines from '@/components/admin-tool/Timelines'
import AdminToolPromotion from '@/components/admin-tool/Promotion'
import MessageTopUp from '@/components/message/TopUp'
import MessageLottoPrice from '@/components/message/LottoPrice'
import MessageLive from '@/components/message/Live'
import MessageFootball from '@/components/message/Football'
import MessageLottoNews from '@/components/message/LottoNews'
import MessageSystem from '@/components/message/System'
import MessageGallery from '@/components/message/Gallery'
import MessageTimeline from '@/components/message/Timeline'
import MessagePromotion from '@/components/message/Promotion'
import MessageRich from '@/components/message/Rich'
import axios from 'axios'
import store from '@/store'
import Vue from 'vue'

export default {
    name: 'AdminMessage',
    components: {
        AdminToolTopUp,
        AdminToolLottoPrice,
        AdminToolLive,
        AdminToolFootball,
        AdminToolContents,
        AdminToolTimelines,
        AdminToolPromotion,
        MessageTopUp,
        MessageLottoPrice,
        MessageLive,
        MessageFootball,
        MessageLottoNews,
        MessageSystem,
        MessageGallery,
        MessageTimeline,
        MessagePromotion,
        MessageRich,
    },
    data(){
        return {
            preview_type: '',
            message: null,
            bus: new Vue()
        }
    },
    computed: {
        admin(){
            return store.state.admin
        },
        channel(){
            return this.admin.channel
        },
        isAvailable() {
            return process.env.NODE_ENV === 'development' || window.location.hostname === 'chat.liffline.com'
        },
    },
    methods: {
        async preview(type){

            this.clear()

            this.preview_type = type

            if(type === 'top_up'){
                this.bus.$emit('loadTopUp')
            }else if(type === 'lotto_price'){
                this.bus.$emit('loadLottoPrice')
            }else if(type === 'football'){
                this.bus.$emit('loadFootball')
            }else if(type === 'lotto_news'){
                this.bus.$emit('loadLottoNews')
            }
        },
        setMessage(data, type){
            this.message = {
                timestamp: this.$moment().toISOString(),
                type: type,
                data: data
            }
        },
        errorAlert(message){
            this.$alert(message, 'แจ้งเตือน', {
                type: 'error',
                confirmButtonText: 'ตกลง',
                customClass: 'custom-message-box'
            })
        },
        async send(){

            if(!this.message){
                return
            }

            const contentVerify = (arr) => {
                const unverified = arr.find((a) => {
                    return !a.url || !a.title || !a.description || !a.thumbnail
                })
                return !unverified
            }

            if(this.preview_type === 'live'){
                const liveVerify = (data) => {
                    return data.url && data.title && data.description && data.thumbnail
                }
                if(!liveVerify(this.message.data)){
                    this.errorAlert('กรุณากรอกข้อมูลให้ครบถ้วน URL หัวข้อ คำอธิบาย และ thumbnail')
                    return
                }
            }else if(this.preview_type === 'lotto_news' || this.preview_type === 'system'){
                if(!contentVerify(this.message.data)){
                    this.errorAlert('กรุณากรอกข้อมูลให้ครบถ้วน URL หัวข้อ คำอธิบาย และ thumbnail')
                    return
                }
            }else if(this.preview_type === 'gallery'){
                const unverified = this.message.data.find((a) => {
                    return !a.url || !a.title || !a.description
                })
                if(unverified){
                    this.errorAlert('กรุณากรอกข้อมูลให้ครบถ้วน URL หัวข้อ คำอธิบาย และ thumbnail')
                    return
                }
                const thumbnailUnverified = this.message.data.find((a) => {
                    return a.thumbnails.length !== 3
                })
                if(thumbnailUnverified){
                    this.errorAlert('กรุณาเพิ่มรูป thumbnail ให้ครบ')
                    return
                }
            }else if(this.preview_type === 'timeline'){
                const unverified = this.message.data.find((a) => {
                    return !a.title || !a.description
                })
                if(unverified){
                    this.errorAlert('กรุณากรอกข้อมูลให้ครบถ้วน หัวข้อ และ คำอธิบาย')
                    return
                }
                const stepUnverified = this.message.data.find((a) => {
                    return a.steps.length < 2
                })
                if(stepUnverified){
                    this.errorAlert('กรุณาเพิ่มขั้นตอน อย่างน้อย 2 ขั้นตอน')
                    return
                }
            }else if(this.preview_type === 'rich'){
                const isNoThumbnail = this.message.data.find((data) => {
                    return !data.thumbnail
                })
                if(isNoThumbnail) {
                    this.errorAlert('กรุณาเพิ่มรูป thumbnail ให้ครบ')
                    return
                }
            }

            const loading = this.$loading()

            const res = await axios({
                method: 'POST',
                url: `${this.envDomains.VUE_APP_API}/api/chat/admin-send`,
                data: {
                    room_id: this.admin.room_id,
                    token: this.admin.token,
                    admin_id: this.admin.id,
                    ...this.message
                }
            })
            if(!res.data.success){
                this.$alert(res.data.message, 'แจ้งเตือน', {
                    type: 'error',
                    confirmButtonText: 'ตกลง',
                    customClass: 'custom-message-box'
                })
                loading.close()
                return
            }

            this.$alert('ส่งข้อความสำเร็จ', 'แจ้งเตือน', {
                type: 'success',
                confirmButtonText: 'ตกลง',
                customClass: 'custom-message-box'
            })
            this.clear()

            loading.close()
        },
        toClear(){
            this.$alert(`ต้องการล้าง?`, 'แจ้งเตือน', {
                type: 'warning',
                showCancelButton: true,
                confirmButtonText: 'ตกลง',
                cancelButtonText: 'ยกเลิก',
                customClass: 'custom-message-box',
                callback: action => {
                    if(action === 'confirm'){
                        this.clear()
                    }
                }
            })
        },
        clear(){
            this.preview_type = ''
            this.message = null
            this.bus.$emit('clear')
        },
        formChange(ctx){
            this.setMessage(ctx.data, ctx.type)
        }
    }
}
</script>
<style scoped>
.v-preview {
    width: 50%;
    min-height: 200px;
    padding: 20px;
    background-color: #7092c0;
    border-radius: 4px;
}
.v-live-create-tool {
    width: 50%;
}
</style>