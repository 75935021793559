<template>
    <div class="Condition">
        <div class="box-section">
            <h6>เงือนไขการแทง</h6>
            <div v-if="(mode === 'sam_tua' || mode === 'all') && setting_xxx && config && price_table">
                <div class="box-condition" v-if="isInSelectCategory('sam_tua_bon')">
                    <h4>3 ตัวบน จ่าย : {{price_table[lotto_type]['sam_tua_bon'] | floatFormat}}</h4>
                    <ul>
                        <li>แทงขั้นต่ำ/ครั้ง : <span>{{setting_xxx.bet_min | floatFormat}}</span></li>
                        <li>แทงสูงสุด/ครั้ง : <span>{{config['sam_tua_bon'].bet_step_max | floatFormat}}</span></li>
                        <li>แทงสูงสุด/เลข : <span>{{config['sam_tua_bon'].bet_max | floatFormat}}</span></li>
                    </ul>
                </div>
                <div v-if="lotto_type === 'government' && isInSelectCategory('sam_tua_lang')" class="box-condition">
                    <h4>3 ตัวล่าง จ่าย : {{price_table[lotto_type]['sam_tua_lang'] | floatFormat}}</h4>
                    <ul>
                        <li>แทงขั้นต่ำ/ครั้ง : <span>{{setting_xxx.bet_min | floatFormat}}</span></li>
                        <li>แทงสูงสุด/ครั้ง : <span>{{config['sam_tua_lang'].bet_step_max | floatFormat}}</span></li>
                        <li>แทงสูงสุด/เลข : <span>{{config['sam_tua_lang'].bet_max | floatFormat}}</span></li>
                    </ul>
                </div>
                <div v-if="lotto_type === 'government' && isInSelectCategory('sam_tua_nha')" class="box-condition">
                    <h4>3 ตัวหน้า จ่าย : {{price_table[lotto_type]['sam_tua_nha'] | floatFormat}}</h4>
                    <ul>
                        <li>แทงขั้นต่ำ/ครั้ง : <span>{{setting_xxx.bet_min | floatFormat}}</span></li>
                        <li>แทงสูงสุด/ครั้ง : <span>{{config['sam_tua_nha'].bet_step_max | floatFormat}}</span></li>
                        <li>แทงสูงสุด/เลข : <span>{{config['sam_tua_nha'].bet_max | floatFormat}}</span></li>
                    </ul>
                </div>
                <div v-if="isInSelectCategory('sam_tua_tod')" class="box-condition">
                    <h4>3 ตัวโต๊ด จ่าย : {{price_table[lotto_type]['sam_tua_tod'] | floatFormat}}</h4>
                    <ul>
                        <li>แทงขั้นต่ำ/ครั้ง : <span>{{setting_xxx.bet_min | floatFormat}}</span></li>
                        <li>แทงสูงสุด/ครั้ง : <span>{{config['sam_tua_tod'].bet_step_max | floatFormat}}</span></li>
                        <li>แทงสูงสุด/เลข : <span>{{config['sam_tua_tod'].bet_max | floatFormat}}</span></li>
                    </ul>
                </div>
            </div>
            <div v-if="(mode === 'song_tua' || mode === 'all') && setting_xx && config && price_table">
                <div v-if="isInSelectCategory('song_tua_bon')" class="box-condition">
                    <h4>2 ตัวบน จ่าย : {{price_table[lotto_type]['song_tua_bon'] | floatFormat}}</h4>
                    <ul>
                        <li>แทงขั้นต่ำ/ครั้ง : <span>{{setting_xx.bet_min | floatFormat}}</span></li>
                        <li>แทงสูงสุด/ครั้ง : <span>{{config['song_tua_bon'].bet_step_max | floatFormat}}</span></li>
                        <li>แทงสูงสุด/เลข : <span>{{config['song_tua_bon'].bet_max | floatFormat}}</span></li>
                    </ul>
                </div>
                <div v-if="isInSelectCategory('song_tua_lang')" class="box-condition">
                    <h4>2 ตัวล่าง จ่าย : {{price_table[lotto_type]['song_tua_lang'] | floatFormat}}</h4>
                    <ul>
                        <li>แทงขั้นต่ำ/ครั้ง : <span>{{setting_xx.bet_min | floatFormat}}</span></li>
                        <li>แทงสูงสุด/ครั้ง : <span>{{config['song_tua_lang'].bet_step_max | floatFormat}}</span></li>
                        <li>แทงสูงสุด/เลข : <span>{{config['song_tua_lang'].bet_max | floatFormat}}</span></li>
                    </ul>
                </div>
            </div>
            <div v-if="(mode === 'wing' || mode === 'all') && setting_xx && config && price_table">
                <div v-if="isInSelectCategory('wing_bon')" class="box-condition">
                    <h4>วิ่งบน จ่าย : {{price_table[lotto_type]['wing_bon'] | floatFormat}}</h4>
                    <ul>
                        <li>แทงขั้นต่ำ/ครั้ง : <span>{{setting_xx.bet_min | floatFormat}}</span></li>
                        <li>แทงสูงสุด/ครั้ง : <span>{{config['wing_bon'].bet_step_max | floatFormat}}</span></li>
                        <li>แทงสูงสุด/เลข : <span>{{config['wing_bon'].bet_max | floatFormat}}</span></li>
                    </ul>
                </div>
                <div v-if="isInSelectCategory('wing_lang')" class="box-condition">
                    <h4>วิ่งล่าง จ่าย : {{price_table[lotto_type]['wing_lang'] | floatFormat}}</h4>
                    <ul>
                        <li>แทงขั้นต่ำ/ครั้ง : <span>{{setting_xx.bet_min | floatFormat}}</span></li>
                        <li>แทงสูงสุด/ครั้ง : <span>{{config['wing_lang'].bet_step_max | floatFormat}}</span></li>
                        <li>แทงสูงสุด/เลข : <span>{{config['wing_lang'].bet_max | floatFormat}}</span></li>
                    </ul>
                </div>
            </div>
        </div>
    </div>
</template>

<script>

    import store from '@/store'

    export default {
        name: "Condition",
        props: [
            'mode', 'lotto_type', 'setting_xx', 'setting_xxx', 'config', 'price_table'
        ],
        computed: {
            select_category_list(){
                return store.state.lotto.select_category_list;
            }
        },
        methods: {
            isInSelectCategory(category){
                return !!this.select_category_list.find((o) => {return o.slug === category})
            }
        }
    }
</script>

<style scoped>

</style>