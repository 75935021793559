<template>
    <div class="GameListFish">
        <AllProvider :cat="cat"></AllProvider>
        <div class="box-list-game-type">
            <ul  v-if="false" class="nav nav-tabs tab-cst tab-style" id="myTab" role="tablist">
                <li class="nav-item">
                    <a class="nav-link active" id="home-tab" data-toggle="tab" href="#tab-all_fish" role="tab"
                       aria-controls="home" aria-selected="true"><strong>ALL</strong></a>
                </li>
                
                <li class="nav-item" v-if="service_list.includes('pragmatic')">
                    <a class="nav-link" id="profile-tab" data-toggle="tab" href="#tab-prag_fish" role="tab"
                       aria-controls="profile" aria-selected="false"><img src="@/assets/images/casino/logo-112.png"
                                                                          style="height: 20px;margin-top: -10px;"> Pragmatic Play</a>
                </li>
                <li class="nav-item" v-if="service_list.includes('cqgame')">
                    <a class="nav-link" id="contact-tab" data-toggle="tab" href="#tab-cq_fish" role="tab"
                       aria-controls="contact" aria-selected="false"><img src="@/assets/images/casino/cq9.png"
                                                                          style="height: 15px; margin-top: 5px;"> CQ9</a>
                </li>
            </ul>

            <div class="tab-content" id="myTabContent" v-if="false">
                <div class="tab-pane fade show active" id="tab-all_fish" role="tabpanel" aria-labelledby="home-tab">
                    <div class="wrapper-box-list">
                        <div class="content-lists" v-if="service_list.includes('pragmatic')">
                            <h4><img src="@/assets/images/casino/logo-112.png"> ยิงปลา</h4>
                            <div v-for="(game,key) of list2.pop_list.fishing[0]" :key="key" class="box-s-list" @click="openGame2(game,'pragmatic')" >
                                    <div class="btnPlay-s-list"></div>
                                    <img :src="game.img">
                                    <span>{{game.name}}</span>
                            </div>
                            
                            <div class="clearfix"></div>
                        </div>
                        <div class="content-lists" v-if="service_list.includes('cqgame')">
                            <h4><img src="@/assets/images/casino/logo-112.png"> ยิงปลา</h4>
                           <div v-for="(game,key) of list2.pop_list.fishing[1]" :key="key" class="box-s-list" @click="openGame2(game,'cqgame')" >
                                    <div class="btnPlay-s-list"></div>
                                    <img :src="game.img">
                                    <span>{{game.name}}</span>
                            </div>

                        </div>
                    </div>
                </div>
                <div class="tab-pane fade" id="tab-prag_fish" role="tabpanel" aria-labelledby="profile-tab" v-if="service_list.includes('pragmatic')">
                    <div class="wrapper-box-list">
                       <div class="content-lists">
                           <h4><img src="@/assets/images/casino/logo-112.png"> ยิงปลา</h4>
                            <div v-for="(game,key) of list2.pop_list.fishing[0]" :key="key" class="box-s-list" @click="openGame2(game,'pragmatic')" >
                                    <div class="btnPlay-s-list"></div>
                                    <img :src="game.img">
                                    <span>{{game.name}}</span>
                            </div>
                           <div class="clearfix"></div>
                       </div>
                   </div>
                </div>
                <div class="tab-pane fade" id="tab-cq_fish" role="tabpanel" aria-labelledby="contact-tab" v-if="service_list.includes('cqgame')">
                    <div class="wrapper-box-list">
                        <div class="content-lists">
                            <h4><img src="@/assets/images/casino/logo-112.png"> ยิงปลา</h4>
                            <div v-for="(game,key) of list2.pop_list.fishing[1]" :key="key" class="box-s-list" @click="openGame2(game,'cqgame')" >
                                    <div class="btnPlay-s-list"></div>
                                    <img :src="game.img">
                                    <span>{{game.name}}</span>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div class="HorizontalShortList-pop">
                <HorizontalShortList 
                v-for="(provider, key) of providerList" :key="key" 
                :provider="provider"
                :cat="cat"
                @seeAll="openGameListModal"></HorizontalShortList>
            </div>
        </div>
        <div class="GameList-mainGame" :class="{'GameListMainGameShow':isShowGameListModal}">
            <GamelistGameList v-if="currentProvider" :provider="currentProvider" :cat="cat" @close="closeGameListModal"></GamelistGameList>
        </div>
    </div>
</template>
<script>

    import axiosWrapper from '@/axiosWrapper'
    import randomBetween from '@/lib/randomBetween'
    import store from '@/store'
    import UserMixin from '@/mixins/UserMixin'
    import AllProvider from '@/components/AllProvider'
    import HorizontalShortList from '@/components/gamelist/HorizontalShortList'
    import GamelistGameList from '@/components/gamelist/GameList'

    export default {
        name: 'GameList',
        mixins: [UserMixin],
        components: {
            AllProvider,
            HorizontalShortList,
            GamelistGameList,
        },
        data() {
            return {
                games: [],
                icons: [
                    '/images/icon/set-icon/icon-casino.png',
                    '/images/icon/set-icon/icon-slot.png',
                    '/images/icon/set-icon/icon-casino.png',
                    '/images/icon/set-icon/icon-football.png',
                    '/images/icon/set-icon/icon-fishing.png',
                    '/images/icon/set-icon/icon-lotto.png',
                    '/images/icon/set-icon/icon-roulette.png',
                    '/images/icon/set-icon/icon-case.png',
                ],
                selectedBrand: 'queenmaker',
                list2:null,
                service_list : `${process.env.VUE_APP_PES_SERVICE_LIST}`.split(","),
                cat: 'FISHING',
                providerList: [
                    {
                        slug: 'JILI',
                        label: 'JILI',
                        image: '/images/slot/provider/JILI.png',
                        wrapperClass: 'GameList-box-square',
                        perPage : 2.8
                    },
                    {
                        slug: 'PRAGMATIC',
                        label: 'PragmaticPlay',
                        image: '/images/slot/provider/PragmaticPlay.png',
                        wrapperClass: '',
                        perPage : 2.5
                    },
                    {
                        slug: 'CQ9',
                        label: 'CQ9',
                        image: '/images/slot/provider/CQ9.png',
                        wrapperClass: 'GameList-box-square',
                        perPage : 3.2,
                    },
                ],
                currentProvider: null,
                isShowGameListModal: false,
            }
        },
        computed: {
            is_member() {
                return store.state.is_member
            },
            pes_profile() {
                return store.state.pes_profile
            },
            token() {
                return this.pes_profile.token
            },
            getAllGameList(){
                return store.state.allGameList;
            },
            appImageProfile(){
                return store.state.appInfo.app_image_profile
            },
        },
        methods: {
            openGame2(game,service){
                if (!this.is_member) {
                    this.notifyToSignUp('เพื่อเล่น')
                    return
                }
                var params =[
                    
                ];
                
                if(service=='pragmatic'){
                    params.push('code='+game.gameId);
                }else if(service=='sa'){
                    params.push('code='+game.code);
                }
                if(this.appImageProfile){
                    params.push('logo_img='+this.appImageProfile);
                }
                const url = `${this.envDomains.VUE_APP_PES_BASE_URL}/game/casinoonline/sa_sexy_redirect.php?mode=mobile&service=${service}&${params.join('&')}&token=${this.token}`
                if(service=='sa'){
                    window.location.href=url
                }else{
                    this.$router.push({ name: 'GamePlayFrame', "query": { 'url': encodeURIComponent(url) } })
                }
                
                
            },
            async getGameList(brand = 'queenmaker') {

                const res = await axiosWrapper({
                    method: 'post',
                    url: `${this.envDomains.VUE_APP_API}/api/game/list`,
                    data: {
                        brand
                    }
                })

                if (!res.data.success) {
                    this.$alert(res.data.message, 'แจ้งเตือน', {
                        confirmButtonText: 'ตกลง',
                        customClass: 'custom-message-box'
                    })
                    return
                }

                return res.data.data
            },
            calIcon(key) {
                return this.icons[key % this.icons.length]
            },
            getPlayingCount() {
                return randomBetween(111, 1111)
            },
            openGame(game) {

                if (!this.is_member) {
                    this.notifyToSignUp('เพื่อเล่น')
                    return
                }

                const baseUrl = `/game/casinoonline/sa_sexy_redirect.php?service=${this.selectedBrand}`

                let query = ''
                if (this.selectedBrand === 'queenmaker') {
                    query = `&gpcode=${game.gpcode}&gcode=${game.gcode}`
                } else if (this.selectedBrand === 'kp') {
                    query = `&gameId=${game.gameId}`
                } else if (this.selectedBrand === 'cqgame') {
                    query = `&gamehall=${game.gamehall}&gamecode=${game.gamecode}`
                }
                if(this.appImageProfile){
                    query = query+'&logo_img='+this.appImageProfile
                }

                const url = `${this.envDomains.VUE_APP_PES_BASE_URL}${baseUrl}${query}&token=${this.token}`
                window.open(url, '_blank')
            },
            getLinkForFishing(game){
                if(game=='pp3fish' || game=='pp4fortune'){
                    return `${this.envDomains.VUE_APP_PES_BASE_URL}/game/casinoonline/sa_sexy_redirect.php?mode=desktop&by=&service=pragmatic&code=`+game+`&token=`+this.token+(this.appImageProfile?('&logo_img='+this.appImageProfile):'');

                }else{
                    return `${this.envDomains.VUE_APP_PES_BASE_URL}/game/casinoonline/sa_sexy_redirect.php?mode=desktop&by=&service=cqgame&gamehall=cq9&gamecode=`+game+`&token=`+this.token+(this.appImageProfile?('&logo_img='+this.appImageProfile):'');

                }
            },
            openGameListModal(provider) {
                this.currentProvider = provider
                this.isShowGameListModal = true
            },
            closeGameListModal() {
                this.currentProvider = null
                this.isShowGameListModal = false
            },
        },
        async mounted() {
            this.list2=this.getAllGameList;
            var self = this;
            if(this.list2==null){
                var clearInv=setInterval(function(){
                    self.list2 = self.getAllGameList;
                    if(self.list2!=null){
                        clearInterval(clearInv);
                    }

                },500)
            }
        },
    }
</script>
<style scoped>
    /*.VueCarousel-slide{*/
    /*    flex-basis: initial;*/
    /*}*/
    /* Portrait */
    @media only screen
    and (min-device-width: 768px)
    and (max-device-width: 1024px)
    and (orientation: portrait)
    and (-webkit-min-device-pixel-ratio: 1) {


    }

</style>