<template>
    <div>
        <div class="GameList GameList-electronic GameListRecommend">
            <div class="ListRecommend-sub">
                <ul>
                    <li @click="openGame('unbox')"><img src="@/assets/images/icon/set-icon/icon-spin.png"><p>เปิดกล่องสุ่ม</p></li>
                    <li @click="openGame('football')" ><img src="@/assets/images/icon/set-icon/mini-icon-bet.png"><p>เดิมพันกีฬา</p></li>
                    <li @click="openGame('lotto')"><img src="@/assets/images/icon/set-icon/icon-lotto2.png"><p>แทงหวยไทย</p></li>
                </ul>
            </div>
            <AllProvider :cat="cat" v-if="false"></AllProvider>
            <div class="pop-provider">
                <div class="box-sublist-area-topTap">
                     <span v-for="(item, key) of categories" :key="key"
                           :class="{'active': item === currentCategory}"
                           @click="selectCategory(item)">
                    {{item.label}}
                    </span>
                    <span>
                        <i class="fas fa-search"></i>
                        <input v-model="keyword" type="text" placeholder="ค้นหา" />
                    </span>
<!--                        <span v-for="(item, key) of categories" :key="key"-->
<!--                              :class="{'active': item === currentCategory}"-->
<!--                              @click="selectCategory(item)">-->
<!--                            {{item.label}}-->
<!--                        </span>-->
                    <!-- <span class="active">แนะนำเกม</span>
                    <span>เกมยอดนิยม</span>
                    <span>เรียงตามตัวอักษร</span> -->
                </div>
                <div v-if="keyword" class="box-list-game-type">
                    <div class="container-GameList-box">
                        <HorizontalShortList 
                        v-for="(provider, key) of providerList" :key="`${key}:${tag}:${horizontalListSortBy}:${keyword}`" 
                        :provider="provider"
                        :cat="cat"
                        :tag="tag"
                        :sortBy="horizontalListSortBy"
                        :sortDesc="horizontalListSortDesc"
                        :keyword="keyword"
                        @seeAll="openGameListModal"></HorizontalShortList>
                    </div>`
                </div>
                <div class="pop-provider-info">
                    <div v-show="checkGameProviderAvailable(provider.slug)" v-for="(provider, key) of providerList" :key="key" @click="openGameListModal(provider)" class="pop-provider-box">
                 <img :src="provider.image"/>
                 <p>{{provider.label}}</p>
             </div>
                </div>
            </div>
            <div class="box-list-game-type" v-if="false">
                <div class="container-GameList-box">

                    <HorizontalShortList 
                    v-for="(provider, key) of providerList" :key="`${key}:${tag}:${horizontalListSortBy}`" 
                    :provider="provider"
                    :cat="cat"
                    :tag="tag"
                    :sortBy="horizontalListSortBy"
                    :sortDesc="horizontalListSortDesc"
                    @seeAll="openGameListModal"></HorizontalShortList>
                    <!--                    PGSOFT  -->
                    <!-- <div class="GameList-box GameList-box-square">
                        <div class="GameList-box-title">
                            <h4><img src="@/assets/images/slot/provider/PGSOFT.png"/>PGSOFT</h4>
                            <div class="btnMore"></div>
                        </div>
                        <div class="flexListgame">
                            <carousel
                                    :per-page="3.2"
                                    :mouseDrag="true"
                                    :navigationEnabled="true"
                                    :paginationEnabled="false"
                                    navigationNextLabel="❯"
                                    navigationPrevLabel="❮"
                                    paginationPosition="bottom"
                                    data-ref="carousel"
                                    ref="carousel">
                                <slide>
                                    <div class="box-list-games" >
                                        <div class="box-icon-games">
                                            <img src="https://superapi-products.s3-ap-southeast-1.amazonaws.com/PGSOFT/slot_20200717123609099.png"/>
                                        </div>
                                        <div class="box-detail-games">
                                            <h4>Honey Trap of Diao Chan</h4>
                                        </div>
                                    </div>
                                </slide>
                                <slide>
                                    <div class="box-list-games" >
                                        <div class="box-icon-games">
                                            <img src="https://superapi-products.s3-ap-southeast-1.amazonaws.com/PGSOFT/slot_202007171246252525.png"/>
                                        </div>
                                        <div class="box-detail-games">
                                            <h4>Gem Saviour</h4>
                                        </div>
                                    </div>
                                </slide>
                                <slide>
                                    <div class="box-list-games" >
                                        <div class="box-icon-games">
                                            <img src="https://superapi-products.s3-ap-southeast-1.amazonaws.com/PGSOFT/slot_202007171229545454.png"/>
                                        </div>
                                        <div class="box-detail-games">
                                            <h4>Fortune Gods</h4>
                                        </div>
                                    </div>
                                </slide>
                                <slide>
                                    <div class="box-list-games" >
                                        <div class="box-icon-games">
                                            <img src="https://superapi-products.s3-ap-southeast-1.amazonaws.com/PGSOFT/slot_202007171242101010.png"/>
                                        </div>
                                        <div class="box-detail-games">
                                            <h4>Medusa II</h4>
                                        </div>
                                    </div>
                                </slide>
                                <slide>
                                    <div class="btn-list-games">
                                        <span>All</span>
                                    </div>
                                </slide>
                            </carousel>
                        </div>
                    </div> -->
                    <!--                    Blueprint-->
                    <!-- <div class="GameList-box GameList-box-wide">
                        <div class="GameList-box-title">
                            <h4><img src="@/assets/images/slot/provider/Blueprint.png"/>Blueprint</h4>
                            <div class="btnMore"></div>
                        </div>
                        <div class="flexListgame">
                            <carousel
                                    :per-page="3.2"
                                    :mouseDrag="true"
                                    :navigationEnabled="true"
                                    :paginationEnabled="false"
                                    navigationNextLabel="❯"
                                    navigationPrevLabel="❮"
                                    paginationPosition="bottom">
                                <slide>
                                    <div class="box-list-games" >
                                        <div class="box-icon-games">
                                            <img src="https://lobby-int.qtlauncher.com/images/?id=BPG-7sdeluxe_en_US&type=banner&showIcon=false&theme=dark"/>
                                        </div>
                                        <div class="box-detail-games">
                                            <h4>7s Deluxe</h4>
                                        </div>
                                    </div>
                                </slide>
                                <slide>
                                    <div class="box-list-games" >
                                        <div class="box-icon-games">
                                            <img src="https://lobby-int.qtlauncher.com/images/?id=BPG-bankinbacon_en_US&type=banner&showIcon=false&theme=dark"/>
                                        </div>
                                        <div class="box-detail-games">
                                            <h4>Bankin' Bacon</h4>
                                        </div>
                                    </div>
                                </slide>
                                <slide>
                                    <div class="box-list-games" >
                                        <div class="box-icon-games">
                                            <img src="https://lobby-int.qtlauncher.com/images/?id=BPG-barxsafecrackermegaways_en_US&type=banner&showIcon=false&theme=dark"/>
                                        </div>
                                        <div class="box-detail-games">
                                            <h4>Bar X Safe Cracker Megaways</h4>
                                        </div>
                                    </div>
                                </slide>
                                <slide>
                                    <div class="box-list-games" >
                                        <div class="box-icon-games">
                                            <img src="https://lobby-int.qtlauncher.com/images/?id=BPG-bigcatkingmegaways_en_US&type=banner&showIcon=false&theme=dark"/>
                                        </div>
                                        <div class="box-detail-games">
                                            <h4>Big Cat King Megaways</h4>
                                        </div>
                                    </div>
                                </slide>
                                <slide>
                                    <div class="btn-list-games">
                                        <span>All</span>
                                    </div>
                                </slide>
                            </carousel>
                        </div>
                    </div> -->
                    <!--                    Micro Gaming-->
                    <!-- <div class="GameList-box GameList-box-square">
                        <div class="GameList-box-title">
                            <h4><img src="@/assets/images/slot/provider/MicroGaming.png"/>Micro Gaming</h4>
                            <div class="btnMore"></div>
                        </div>
                        <div class="flexListgame">
                            <carousel
                                    :per-page="3.2"
                                    :mouseDrag="true"
                                    :navigationEnabled="true"
                                    :paginationEnabled="false"
                                    navigationNextLabel="❯"
                                    navigationPrevLabel="❮"
                                    paginationPosition="bottom">
                                <slide>
                                    <div class="box-list-games" >
                                        <div class="box-icon-games">
                                            <img src="https://www.microgaming.co.uk/-/media/1e9984480a054059bdc372d44d05fd32.ashx"/>
                                        </div>
                                        <div class="box-detail-games">
                                            <h4>Africa X UP</h4>
                                        </div>
                                    </div>
                                </slide>
                                <slide>
                                    <div class="box-list-games" >
                                        <div class="box-icon-games">
                                            <img src="https://www.microgaming.co.uk/-/media/75ea9ea216ea4c7589c52636e740ed45.ashx"/>
                                        </div>
                                        <div class="box-detail-games">
                                            <h4>Game of Thrones Power Stacks</h4>
                                        </div>
                                    </div>
                                </slide>
                                <slide>
                                    <div class="box-list-games" >
                                        <div class="box-icon-games">
                                            <img src="https://www.microgaming.co.uk/-/media/e0a9cec9b4374baabf7d4a2fc894330f.ashx"/>
                                        </div>
                                        <div class="box-detail-games">
                                            <h4>Sisters of OZ Jackpots</h4>
                                        </div>
                                    </div>
                                </slide>
                                <slide>
                                    <div class="box-list-games" >
                                        <div class="box-icon-games">
                                            <img src="https://www.microgaming.co.uk/-/media/81d9926c8b234ca584081ee8c6af227f.ashx"/>
                                        </div>
                                        <div class="box-detail-games">
                                            <h4>Real Roulette with Rishi</h4>
                                        </div>
                                    </div>
                                </slide>
                                <slide>
                                    <div class="btn-list-games">
                                        <span>All</span>
                                    </div>
                                </slide>
                            </carousel>
                        </div>
                    </div> -->
                    <!--                    Pragmatic Play-->
                    <!-- <div class="GameList-box">
                        <div class="GameList-box-title">
                            <h4><img src="@/assets/images/casino/logo-112.png"/>Pragmatic Play</h4>
                            <div class="btnMore"></div>
                        </div>
                        <div class="flexListgame">
                            <carousel
                                    :per-page="3.2"
                                    :mouseDrag="true"
                                    :navigationEnabled="true"
                                    :paginationEnabled="false"
                                    navigationNextLabel="❯"
                                    navigationPrevLabel="❮"
                                    paginationPosition="bottom">
                                <slide>
                                    <div class="box-list-games" >
                                        <div class="box-icon-games">
                                            <img src="https://asia.pragmaticplay.net/game_pic/rec/325/vs20fruitsw.png"/>
                                        </div>
                                        <div class="box-detail-games">
                                            <h4>Sweet Bonanza</h4>
                                        </div>
                                    </div>
                                </slide>
                                <slide>
                                    <div class="box-list-games" >
                                        <div class="box-icon-games">
                                            <img src="https://asia.pragmaticplay.net/game_pic/rec/325/vs20sbxmas.png"/>
                                        </div>
                                        <div class="box-detail-games">
                                            <h4>Sweet Bonanza Xmas</h4>
                                        </div>
                                    </div>
                                </slide>
                                <slide>
                                    <div class="box-list-games" >
                                        <div class="box-icon-games">
                                            <img src="https://asia.pragmaticplay.net/game_pic/rec/325/vs20fruitparty.png"/>
                                        </div>
                                        <div class="box-detail-games">
                                            <h4>Fruit Party</h4>
                                        </div>
                                    </div>
                                </slide>
                                <slide>
                                    <div class="box-list-games" >
                                        <div class="box-icon-games">
                                            <img src="https://asia.pragmaticplay.net/game_pic/rec/325/vswaysrhino.png"/>
                                        </div>
                                        <div class="box-detail-games">
                                            <h4>Great Rhino Megaways</h4>
                                        </div>
                                    </div>
                                </slide>
                                <slide>
                                    <div class="btn-list-games">
                                        <span>All</span>
                                    </div>
                                </slide>
                            </carousel>
                        </div>
                    </div> -->
                    <!--                    AmbPoker  -->
                    <!-- <div class="GameList-box GameList-box-square">
                        <div class="GameList-box-title">
                            <h4><img src="@/assets/images/slot/provider/AmbPoker.png"/>Amb Poker</h4>
                            <div class="btnMore"></div>
                        </div>
                        <div class="flexListgame">
                            <carousel
                                    :per-page="3.2"
                                    :mouseDrag="true"
                                    :navigationEnabled="true"
                                    :paginationEnabled="false"
                                    navigationNextLabel="❯"
                                    navigationPrevLabel="❮"
                                    paginationPosition="bottom">
                                <slide>
                                    <div class="box-list-games" >
                                        <div class="box-icon-games">
                                            <img src="https://ambpoker.com/cards/PD.png"/>
                                        </div>
                                        <div class="box-detail-games">
                                            <h4>Pok Deng</h4>
                                        </div>
                                    </div>
                                </slide>
                                <slide>
                                    <div class="box-list-games" >
                                        <div class="box-icon-games">
                                            <img src="https://ambpoker.com/cards2/PK.png"/>
                                        </div>
                                        <div class="box-detail-games">
                                            <h4>Kang</h4>
                                        </div>
                                    </div>
                                </slide>
                                <slide>
                                    <div class="box-list-games" >
                                        <div class="box-icon-games">
                                            <img src="https://ambpoker.com/cards/HORSERACING.png"/>
                                        </div>
                                        <div class="box-detail-games">
                                            <h4>Horse Racing</h4>
                                        </div>
                                    </div>
                                </slide>
                                <slide>
                                    <div class="box-list-games" >
                                        <div class="box-icon-games">
                                            <img src="https://ambpoker.com/cards/HILO.png"/>
                                        </div>
                                        <div class="box-detail-games">
                                            <h4>Hilo</h4>
                                        </div>
                                    </div>
                                </slide>
                                <slide>
                                    <div class="btn-list-games">
                                        <span>All</span>
                                    </div>
                                </slide>
                            </carousel>
                        </div>
                    </div> -->
                    <!--                    Relax Gaming-->
                    <!-- <div class="GameList-box GameList-box-square">
                        <div class="GameList-box-title">
                            <h4><img src="@/assets/images/slot/provider/RelaxGaming.png"/>Relax Gaming</h4>
                            <div class="btnMore"></div>
                        </div>
                        <div class="flexListgame">
                            <carousel
                                    :per-page="3.2"
                                    :mouseDrag="true"
                                    :navigationEnabled="true"
                                    :paginationEnabled="false"
                                    navigationNextLabel="❯"
                                    navigationPrevLabel="❮"
                                    paginationPosition="bottom">
                                <slide>
                                    <div class="box-list-games" >
                                        <div class="box-icon-games">
                                            <img src="https://clientarea.relax-gaming.com/gameassets/piratepays/thumbnail1x/s4hYrrUnzBW7zh7LcffU/thumbnail_PiratePays_300x300.png"/>
                                        </div>
                                        <div class="box-detail-games">
                                            <h4>Pirate Pays</h4>
                                        </div>
                                    </div>
                                </slide>
                                <slide>
                                    <div class="box-list-games" >
                                        <div class="box-icon-games">
                                            <img src="https://clientarea.relax-gaming.com/gameassets/megamine/thumbnail1x/3wz8ABYFpBGWWVEwKtbX/Thumbnail_MegaMine_300x300.png"/>
                                        </div>
                                        <div class="box-detail-games">
                                            <h4>Mega Mine</h4>
                                        </div>
                                    </div>
                                </slide>
                                <slide>
                                    <div class="box-list-games" >
                                        <div class="box-icon-games">
                                            <img src="https://clientarea.relax-gaming.com/gameassets/caribbeananne2/thumbnail1x/gepSHswnaXDf3C3d7qPu/300x300.png"/>
                                        </div>
                                        <div class="box-detail-games">
                                            <h4>Caribbean Anne 2</h4>
                                        </div>
                                    </div>
                                </slide>
                                <slide>
                                    <div class="box-list-games" >
                                        <div class="box-icon-games">
                                            <img src="https://clientarea.relax-gaming.com/gameassets/heliosfury/thumbnail1x/L8MtqPuB6SEfP9gz6NAt/Thumbnail_HF_300x300.png"/>
                                        </div>
                                        <div class="box-detail-games">
                                            <h4>Helios' Fury</h4>
                                        </div>
                                    </div>
                                </slide>
                                <slide>
                                    <div class="btn-list-games">
                                        <span>All</span>
                                    </div>
                                </slide>
                            </carousel>
                        </div>
                    </div> -->
                    <!--                    Yggdrasil -->
                    <!-- <div class="GameList-box">
                        <div class="GameList-box-title">
                            <h4><img src="@/assets/images/slot/provider/Yggdrasil.png"/>Yggdrasil</h4>
                            <div class="btnMore"></div>
                        </div>
                        <div class="flexListgame">
                            <carousel
                                    :per-page="3.2"
                                    :mouseDrag="true"
                                    :navigationEnabled="true"
                                    :paginationEnabled="false"
                                    navigationNextLabel="❯"
                                    navigationPrevLabel="❮"
                                    paginationPosition="bottom">
                                <slide>
                                    <div class="box-list-games" >
                                        <div class="box-icon-games">
                                            <img src="https://lobby-int.qtlauncher.com/images/?id=YGG-gemsinfinityreels_en_US&type=banner&showIcon=false&theme=dark"/>
                                        </div>
                                        <div class="box-detail-games">
                                            <h4>Gems Infinity Reels</h4>
                                        </div>
                                    </div>
                                </slide>
                                <slide>
                                    <div class="box-list-games" >
                                        <div class="box-icon-games">
                                            <img src="https://lobby-int.qtlauncher.com/images/?id=YGG-martianminerinfinityreels_en_US&type=banner&showIcon=false&theme=dark"/>
                                        </div>
                                        <div class="box-detail-games">
                                            <h4>Martian Miner Infinity Reels</h4>
                                        </div>
                                    </div>
                                </slide>
                                <slide>
                                    <div class="box-list-games" >
                                        <div class="box-icon-games">
                                            <img src="https://lobby-int.qtlauncher.com/images/?id=YGG-2godszeusvsthor_en_US&type=banner&showIcon=false&theme=dark"/>
                                        </div>
                                        <div class="box-detail-games">
                                            <h4>2 Gods – Zeus vs Thor</h4>
                                        </div>
                                    </div>
                                </slide>
                                <slide>
                                    <div class="box-list-games" >
                                        <div class="box-icon-games">
                                            <img src="https://lobby-int.qtlauncher.com/images/?id=YGG-ageofasgard_en_US&type=banner&showIcon=false&theme=dark"/>
                                        </div>
                                        <div class="box-detail-games">
                                            <h4>Age of Asgard</h4>
                                        </div>
                                    </div>
                                </slide>
                                <slide>
                                    <div class="btn-list-games">
                                        <span>All</span>
                                    </div>
                                </slide>
                            </carousel>
                        </div>
                    </div> -->
                    <!--                    JILI  -->
                    <!-- <div class="GameList-box GameList-box-square">
                        <div class="GameList-box-title">
                            <h4><img src="@/assets/images/slot/provider/JILI.png"/>JILI</h4>
                            <div class="btnMore"></div>
                        </div>
                        <div class="flexListgame">
                            <carousel
                                    :per-page="3.2"
                                    :mouseDrag="true"
                                    :navigationEnabled="true"
                                    :paginationEnabled="false"
                                    navigationNextLabel="❯"
                                    navigationPrevLabel="❮"
                                    paginationPosition="bottom">
                                <slide>
                                    <div class="box-list-games" >
                                        <div class="box-icon-games">
                                            <img src="https://superapi-products.s3-ap-southeast-1.amazonaws.com/JILI/GameID_1_EN.png"/>
                                        </div>
                                        <div class="box-detail-games">
                                            <h4>Royal Fishing</h4>
                                        </div>
                                    </div>
                                </slide>
                                <slide>
                                    <div class="box-list-games" >
                                        <div class="box-icon-games">
                                            <img src="https://superapi-products.s3-ap-southeast-1.amazonaws.com/JILI/GameID_2_EN.png"/>
                                        </div>
                                        <div class="box-detail-games">
                                            <h4>Chin Shi Huang</h4>
                                        </div>
                                    </div>
                                </slide>
                                <slide>
                                    <div class="box-list-games" >
                                        <div class="box-icon-games">
                                            <img src="https://superapi-products.s3-ap-southeast-1.amazonaws.com/JILI/GameID_5_EN.png"/>
                                        </div>
                                        <div class="box-detail-games">
                                            <h4>Hot Chilli</h4>
                                        </div>
                                    </div>
                                </slide>
                                <slide>
                                    <div class="box-list-games" >
                                        <div class="box-icon-games">
                                            <img src="https://superapi-products.s3-ap-southeast-1.amazonaws.com/JILI/GameID_6_EN.png"/>
                                        </div>
                                        <div class="box-detail-games">
                                            <h4>Fortune Tree</h4>
                                        </div>
                                    </div>
                                </slide>
                                <slide>
                                    <div class="btn-list-games">
                                        <span>All</span>
                                    </div>
                                </slide>
                            </carousel>
                        </div>
                    </div> -->
                </div>
            </div>
            <div class="container-provider">
<!--                <h5>สล็อต</h5>-->
                <div class="container-provider-box">
                    <div class="container-provider-info">
                        <div v-show="checkGameProviderAvailable(provider.slug)" v-for="(provider, key) of allProviderList" :key="key" @click="openGameListModal(provider)" class="listProvider">
                            <img :src="provider.image"/>
                            <p>{{provider.label}}</p>
                        </div>
                    </div>
                </div>
            </div>
            <div class="container-provider">
                <h5>คาสิโนสด</h5>
                <div class="container-provider-box">
                    <div class="container-provider-info" style="justify-content: center">
                        <div v-show="checkGameProviderAvailable(provider.slug)" v-for="(provider, key) of topProviderList" :key="key" @click="openGameListModal(provider)" class="listProvider">
                            <img :src="provider.image"/>
                            <p>{{provider.label}}</p>
                        </div>
                    </div>
                </div>
            </div>

        </div>
    <div class="GameList-mainGame" :class="{'GameListMainGameShow':isShowGameListModal}">
        <GamelistGameList v-if="currentProvider" :provider="currentProvider" :cat="cat" @close="closeGameListModal"></GamelistGameList>
    </div>
</div>

</template>


<script>

    // import { Carousel, Slide } from 'vue-carousel'
    import AllProvider from '@/components/AllProvider'
    import HorizontalShortList from '@/components/gamelist/HorizontalShortList'
    import GamelistGameList from '@/components/gamelist/GameList'
    import {RECOMMEND_PROVIDER_LIST} from '@/components/gamelist/Constant'
    import GameListMixin from '@/components/gamelist/GameListMixin'
    import {isStringContainThaiAlpha, removeThaiAlpha} from '@/lib/TextHandle'

    export default {
        name: 'GameListRemommend',
        mixins: [
            GameListMixin,
        ],
        components: {
            // Carousel,
            // Slide,
            AllProvider,
            HorizontalShortList,
            GamelistGameList,
        },
        data() {
            return {
                categories: [
                    {
                        key: 'HOT',
                        label: 'เกมยอดนิยม',
                    },
                    {
                        key: 'RECOMMEND',
                        label: 'แนะนำเกม',
                    },
                    // {
                    //     key: 'Name',
                    //     label: 'เรียงตามตัวอักษร',
                    // },
                ],
                currentCategory: null,
                providerList: [
                    {
                        slug: 'PGSOFT',
                        value: 'PG Slot',
                        label: 'PG Slot',
                        image: '/images/slot/pop/PGSlot.jpg',
                        perPage : 3.2
                    },
                    {
                        slug: 'BPG',
                        value: 'BluePrint',
                        label: 'BluePrint',
                        image: '/images/slot/pop/BluePint.jpg',
                        wrapperClass: 'GameList-box-wide',
                        perPage : 2.3
                    },
                    {
                        slug: 'PRAGMATIC',
                        value: 'Pragmatic Play',
                        label: 'Pragmatic Play',
                        image: '/images/slot/pop/Pragmatic-Play.jpg',
                        wrapperClass: 'GameList-box-wide',
                        perPage : 2.8
                    },
                    {
                        slug: 'AMBPOKER',
                        value: 'AMB Poker',
                        label: 'AMB Poker',
                        image: '/images/slot/pop/ambpoker.jpg',
                        wrapperClass: 'GameList-box-height',
                        perPage : 3.7
                    },
                    {
                        slug: 'MICRO',
                        value: 'Micro Gaming',
                        label: 'Micro Gaming',
                        image: '/images/slot/pop/MicroGaming.jpg',
                        perPage : 3.2
                    },
                    {
                        slug: 'CQ9',
                        value: 'CQ9',
                        label: 'CQ9',
                        image: '/images/slot/pop/cq9.jpg',
                        wrapperClass: 'GameList-box-square',
                    },
                    {
                        slug: 'RLX',
                        value: 'Relax Gaming',
                        label: 'Relax Gaming',
                        image: '/images/slot/pop/relax_gaming.jpg',
                        wrapperClass: 'GameList-box-wide',
                        perPage : 2.3
                    },

                    {
                        slug: 'JILI',
                        value: 'JILI',
                        label: 'JILI',
                        image: '/images/slot/pop/JILI.jpg',
                        perPage : 3.2
                    },
                    {
                        slug: 'SLOTXO',
                        value: 'Slot XO',
                        label: 'Slot XO',
                        image: '/images/slot/pop/slotxo.jpg',
                    },
                    {
                        slug: 'DS',
                        value: 'Dragoon Soft',
                        label: 'Dragoon Soft',
                        image: '/images/slot/pop/DragoonSoft.jpg',
                        wrapperClass: 'GameList-box-wide',
                    },
                    {
                        slug: 'NE',
                        value: 'NETENT',
                        label: 'NETENT',
                        image: '/images/slot/pop/NETENT.jpg',
                        wrapperClass: 'GameList-box-wide',
                    },
                    {
                        slug: 'YGG',
                        value: 'Yggdrasil',
                        label: 'Yggdrasil',
                        image: '/images/slot/pop/Yggdrasil.jpg',
                        wrapperClass: 'GameList-box-wide',
                        perPage : 2.3
                    },
                ],

                // providerList: [
                //     {
                //         slug: 'PGSOFT',
                //         label: 'PGSOFT',
                //         image: '/images/slot/provider/PGSOFT.png',
                //         wrapperClass: 'GameList-box-square',
                //         perPage : 3.2
                //     },
                //     {
                //         slug: 'BPG',
                //         label: 'Blueprint',
                //         image: '/images/slot/provider/Blueprint.png',
                //         wrapperClass: 'GameList-box-wide',
                //         perPage : 2.5
                //     },
                //     {
                //         slug: 'MICRO',
                //         label: 'MicroGaming',
                //         image: '/images/slot/provider/MicroGaming.png',
                //         wrapperClass: 'GameList-box-square',
                //         perPage : 3.2
                //     },
                //     {
                //         slug: 'PRAGMATIC',
                //         label: 'PragmaticPlay',
                //         image: '/images/slot/provider/PragmaticPlay.png',
                //         wrapperClass: '',
                //         perPage : 2.5
                //     },
                //     {
                //         slug: 'AMBPOKER',
                //         label: 'Amb Poker',
                //         image: '/images/slot/provider/AmbPoker.png',
                //         wrapperClass: 'GameList-box-height',
                //         perPage : 3.2
                //     },
                //     {
                //         slug: 'RLX',
                //         label: 'RelaxGaming',
                //         image: '/images/slot/provider/RelaxGaming.png',
                //         wrapperClass: '',
                //         perPage : 2.2
                //     },
                //     {
                //         slug: 'YGG',
                //         label: 'Yggdrasil',
                //         image: '/images/slot/provider/Yggdrasil.png',
                //         wrapperClass: '',
                //         perPage : 2.5
                //     },
                //     {
                //         slug: 'JILI',
                //         label: 'JILI',
                //         image: '/images/slot/provider/JILI.png',
                //         wrapperClass: 'GameList-box-square',
                //         perPage : 3.2
                //     },
                // ],
                currentProvider: null,
                isShowGameListModal: false,
                cat: null,
                tag: 'HOT',
                horizontalListSortBy: null,
                horizontalListSortDesc: null,
                topProviderList: [
                    {
                        slug: 'SAGAME',
                        label: 'SA Gaming',
                        image: '/images/slot/provider/SAGaming-icon.png',
                    },
                    {
                        slug: 'SEXY',
                        label: 'Sexy Baccarat',
                        image: '/images/slot/provider/SexyBaccarat-icon.png',
                    },
                    {
                        slug: 'PRAGMATIC',
                        label: 'Pragmatic Play',
                        image: '/images/slot/provider/PragmaticPlay.png',
                        wrapperClass: 'GameList-box-wide',
                        perPage : 2.8
                    },
                    {
                        slug: 'PRETTY',
                        label: 'Pretty',
                        image: '/images/slot/provider/PRETTY.png',
                        wrapperClass: 'GameList-box-wide',
                    },
                    {
                        slug: 'EVO',
                        label: 'Evolution Gaming',
                        image: '/images/slot/provider/EvolutionGaming.png',
                        wrapperClass: 'GameList-box-wide',
                    },
                ],
                allProviderList: [],
                keyword: '',
            }
        },
        watch: {
            keyword(newVal) {
                if(newVal) {
                    if(isStringContainThaiAlpha(newVal)) {
                        this.$bvToast.toast('กรุณากรอกข้อมูลเป็นภาษาอังกฤษ', {
                            title: 'แจ้งเตือน',
                            toaster: 'b-toaster-top-full'
                        })
                        this.keyword = removeThaiAlpha(newVal)
                    }
                }
            },
        },
        methods: {
            openGame(game) {
                if (game === 'game-type') {
                    // this.$router.push({name: 'GameType'})
                    // return
                    this.toggleGameHome()
                    return
                }
                if (game === 'unbox') {
                    this.$router.push({name: 'Unbox'})
                    return
                }
                if (game === 'spin_circle') {
                    this.$router.push({name: 'SpinCircle'})
                    return
                }
                if (game === 'mini_sicbo') {
                    this.$router.push({name: 'MiniSicbo'})
                    return
                }
                if (game === 'mini_fantan') {
                    this.$router.push({name: 'MiniFantan'})
                    return
                }
                if (game === 'mini_dragontiger') {
                    this.$router.push({name: 'MiniDragonTiger'})
                    return
                }
                if (game === 'live_stream') {
                    this.$router.push({name: 'LiveStream'})
                    return
                }
                if (game === 'football') {
                    // this.current_game = game
                    // this.openDrawer()
                    this.$router.push({name: 'Football'})
                    return
                }
                if (game === 'lotto') {
                    this.$router.push({name: 'LottoHome'})
                }
                this.$emit('openGame', game)
            },
            imageLoaded(instance) {
                if(!instance.elements) {
                    return
                }
                if(!instance.elements[0]) {
                    return
                }
                const el = instance.elements[0]
                if(!el) {
                    return
                }
                const ref = el.getAttribute('data-ref')
                if(!ref) {
                    return
                }
                this.$refs[ref].computeCarouselWidth()
            },
            selectCategory(item) {
                this.currentCategory = item
                this.calculateFilterAndSort()
            },
            openGameListModal(provider) {
                this.currentProvider = provider
                this.isShowGameListModal = true
            },
            closeGameListModal() {
                this.currentProvider = null
                this.isShowGameListModal = false
            },
            calculateFilterAndSort() {
                this.clearFilterAndSort()
                const currentCategoryKey = this.currentCategory.key
                if(currentCategoryKey === 'HOT' || currentCategoryKey === 'RECOMMEND') {
                    this.tag = currentCategoryKey
                } else if(currentCategoryKey === 'Name') {
                    this.tag = null
                    this.horizontalListSortBy = currentCategoryKey
                    this.horizontalListSortDesc = 'false'
                } else {
                    this.clearFilterAndSort()
                }
            },
            clearFilterAndSort() {
                this.tag = 'HOT'
                this.horizontalListSortBy = null
                this.horizontalListSortDesc = null
            },
        },
        mounted() {
            this.currentCategory = this.categories[0]
            this.calculateFilterAndSort()
            this.allProviderList = RECOMMEND_PROVIDER_LIST
        }
    }

</script>
