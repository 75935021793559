<template>
    <div class="AdminUser">
        <div class="v-content-inner">
            <div class="v-toolbar">
                <div class="toolbar-item">
                    <el-input
                        placeholder="ค้นหา"
                        v-model="keyword"
                        clearable>
                    </el-input>
                    <el-button @click="loadUserArr" type="primary" plain>รีเฟรช</el-button>
                </div>
                <div class="toolbar-item">
                    ห้อง : {{room_full_name}} {{user_arr.length}} คน
                </div>
            </div>
            <div class="v-list">
                <el-table :data="user_arr_paging" stripe>
                    <el-table-column type="index"></el-table-column>
                    <el-table-column label="ผู้ใช้">
                        <template slot-scope="scope">
                            <div class="v-user-info">
                                <el-avatar size="medium" :src="scope.row.picture_url"></el-avatar>
                                <div>{{ scope.row.display_name }}</div>
                            </div>
                        </template>
                    </el-table-column>
                    <el-table-column 
                    label="สถานะ"
                    :filters="[{text: 'ปกติ', value: false}, {text: 'โดนแบน', value: true}]" 
                    :filter-method="(value, row, column) => {return row['is_ban'] === value}">
                        <template slot-scope="scope">
                            <span v-if="scope.row.is_ban" style="opacity: 50%;">
                                โดนแบน
                                <span v-if="scope.row.ban_info.cancel_ban_time">
                                    [ถึง {{scope.row.ban_info.cancel_ban_time | dateTimeFormat}}]
                                </span>
                            </span>
                            <span v-else>ปกติ</span>
                        </template>
                    </el-table-column>
                    <el-table-column label="เครื่องมือ">
                        <template slot-scope="scope">
                            <div class="v-table-tools">
                                <el-button v-if="scope.row.is_ban" @click="toCancelBan(scope.row)" type="success" size="mini" plain>ปลดแบน</el-button>
                                <span v-else>
                                    <el-button @click="toBan(scope.row)" size="mini" type="danger" plain>แบน</el-button>
                                    <el-button @click="toBanWithCancelBanTime(scope.row, 60)" size="mini" type="danger" plain>แบน 1 ชม.</el-button>
                                </span>
                            </div>
                        </template>
                    </el-table-column>
                </el-table>
            </div>
            <div class="v-toolbar-bottom">
                <el-pagination
                        :page-size="page_size"
                        :current-page="page"
                        layout="prev, pager, next"
                        @current-change="(current) => {page = current}"
                        :total="user_arr_filter.length">
                </el-pagination>
            </div>
        </div>
    </div>
</template>
<script>

import axios from 'axios'
import store from '@/store'
import AdditionalDataMixin from '@/mixins/AdditionalDataMixin'
import axiosWrapper from '@/axiosWrapper'
const PAGE_SIZE = 10

export default {
    name: 'AdminUser',
    mixins: [
        AdditionalDataMixin
    ],
    data(){
        return {
            keyword: '',
            room_info: null,
            page: 1,
            page_size: PAGE_SIZE,
            user_arr: []
        }
    },
    computed: {
        room_name(){
            return (this.room_info) ? this.room_info.app_line_official_name : ''
        },
        room_full_name(){
            return `${this.room_name} [${this.room_id}]`
        },
        user_info_arr(){
            return store.state.user_info_arr
        },
        user_arr_filter(){
            return this.user_arr.filter((user) => {
                if(!user.display_name) {
                    return true
                }
                return user.display_name.includes(this.keyword)
            })
        },
        user_arr_paging(){
            return this.user_arr_filter.slice((this.page - 1) * PAGE_SIZE, (this.page - 1) * PAGE_SIZE + PAGE_SIZE)
        },
        admin(){
            return store.state.admin
        },
        token(){
            return (this.admin) ? this.admin.token : ''
        },
        room_id(){
            return (this.admin) ? this.admin.room_id : ''
        }
    },
    watch: {
        user_arr_filter(){
            this.page = 1
        }
    },
    methods: {
        getUserInfo(user_id, column = null){
            const user = this.user_info_arr.find((user_info) => {
                return parseInt(user_info.id) === parseInt(user_id)
            })
            if(!column){
                return user
            }
            return user[column]
        },
        async loadUserArr(){

            const loading = this.$loading()

            const res = await axios({
                method: 'POST',
                url: `${this.envDomains.VUE_APP_API}/api/admin/user/get-online`,
                data: {
                    room_id: this.room_id,
                    token: this.token
                }
            })
            if(!res.data.success){
                this.$alert(res.data.message, 'แจ้งเตือน', {
                    type: 'error',
                    confirmButtonText: 'ตกลง',
                    customClass: 'custom-message-box'
                })
                loading.close()
                return
            }
            const id_arr = res.data.data.map((d) => {
                return d.user_id
            })
            await this.loadUserInfoArr(id_arr)
            this.user_arr = res.data.data
            this.user_arr = this.user_arr.map((user) => {
                const user_info = this.getUserInfo(user.user_id)
                return {
                    ...user,
                    ...user_info
                }
            })

            loading.close()
        },
        toBan(data){
            this.$alert(`ต้องการแบน ${data.display_name} ?`, 'แจ้งเตือน', {
                type: 'error',
                showCancelButton: true,
                confirmButtonText: 'ตกลง',
                cancelButtonText: 'ยกเลิก',
                customClass: 'custom-message-box',
                callback: action => {
                    if(action === 'confirm'){
                        this.ban(data)
                    }
                }
            })
        },
        toBanWithCancelBanTime(data, minute) {
            this.$alert(`ต้องการแบน ${data.display_name} ${minute} นาที?`, 'แจ้งเตือน', {
                type: 'error',
                showCancelButton: true,
                confirmButtonText: 'ตกลง',
                cancelButtonText: 'ยกเลิก',
                customClass: 'custom-message-box',
                callback: action => {
                    if(action === 'confirm'){
                        this.banWithCancelBanTime(data, minute)
                    }
                }
            })
        },
        toCancelBan(data){
            this.$alert(`ต้องการปลดแบน ${data.display_name} ?`, 'แจ้งเตือน', {
                type: 'success',
                showCancelButton: true,
                confirmButtonText: 'ตกลง',
                cancelButtonText: 'ยกเลิก',
                customClass: 'custom-message-box',
                callback: action => {
                    if(action === 'confirm'){
                        this.cancelBan(data)
                    }
                }
            })
        },
        async ban(data){

            const loading = this.$loading()

            const res = await axios({
                method: 'POST',
                url: `${this.envDomains.VUE_APP_API}/api/admin/user/ban`,
                data: {
                    user_id: data.user_id,
                    token: this.token
                }
            })
            if(!res.data.success){
                this.$alert(res.data.message, 'แจ้งเตือน', {
                    confirmButtonText: 'ตกลง',
                    customClass: 'custom-message-box'
                })
                loading.close()
                return
            }
            this.loadUserArr()

            loading.close()
        },
        async banWithCancelBanTime(data, minute){

            const loading = this.$loading()

            const cancel_ban_time = this.$moment().add(minute, 'minutes').format('YYYY-MM-DD HH:mm:ss')

            const res = await axios({
                method: 'POST',
                url: `${this.envDomains.VUE_APP_API}/api/admin/user/ban`,
                data: {
                    user_id: data.user_id,
                    cancel_ban_time: cancel_ban_time,
                    token: this.token,
                }
            })
            if(!res.data.success){
                this.$alert(res.data.message, 'แจ้งเตือน', {
                    confirmButtonText: 'ตกลง',
                    customClass: 'custom-message-box'
                })
                loading.close()
                return
            }
            this.loadUserArr()

            loading.close()
        },
        async cancelBan(data){

            const loading = this.$loading()

            const res = await axios({
                method: 'POST',
                url: `${this.envDomains.VUE_APP_API}/api/admin/user/cancel-ban`,
                data: {
                    user_id: data.user_id,
                    token: this.token
                }
            })
            if(!res.data.success){
                this.$alert(res.data.message, 'แจ้งเตือน', {
                    confirmButtonText: 'ตกลง',
                    customClass: 'custom-message-box'
                })
                loading.close()
                return
            }
            this.loadUserArr()

            loading.close()
        },
        async loadRoomInfo(){
            try{
                const res = await axiosWrapper({
                    method: 'POST',
                    url: `${this.envDomains.VUE_APP_LIFF_API}/api/channel/get-app-info`,
                    data: {
                        line: this.room_id
                    }
                });
                if(res.status === 200){
                    this.room_info = res.data
                }
            }catch(err){
                console.log('loadRoomInfo err', err.message)
            }
        }
    },
    mounted(){
        this.loadUserArr()
        this.loadRoomInfo()
    }
}

</script>
<style scoped>
    .v-user-info {
        display: flex;
        align-items: center;
    }
    .v-user-info * {
        margin: 0 2px;
    }
    .toolbar-item * {
        margin: 0 2px;
    }
</style>