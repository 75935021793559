<script>
export default {
    name: 'SwipeMixin',
    data() {
        return {
            xDown: null,
            yDown: null,
        }
    },
    methods: {
        getTouches(evt) {
            return evt.touches
        },
        handleTouchStart(evt) {
            const firstTouch = this.getTouches(evt)[0];                                      
            this.xDown = firstTouch.clientX;                                      
            this.yDown = firstTouch.clientY;  
        },
        handleTouchMove(evt) {
            if ( ! this.xDown || ! this.yDown ) {
                return;
            }

            const xUp = evt.touches[0].clientX;                                    
            const yUp = evt.touches[0].clientY;

            const xDiff = this.xDown - xUp;
            const yDiff = this.yDown - yUp;

            if ( Math.abs( xDiff ) > Math.abs( yDiff ) ) {/*most significant*/
                if ( xDiff > 0 ) {
                    this.handleSwipeLeft()
                } else {
                    this.handleSwipeRight()
                }                       
            } else {
                if ( yDiff > 0 ) {
                    this.handleSwipeUp()
                } else { 
                    this.handleSwipeDown()
                }                                                                 
            }
            /* reset values */
            this.xDown = null;
            this.yDown = null;  
        },
        handleTouchEnd(evt) {
            if ( ! this.xDown || ! this.yDown ) {
                return;
            }

            const xUp = evt.changedTouches[0].clientX;                                    
            const yUp = evt.changedTouches[0].clientY;

            const xDiff = this.xDown - xUp;
            const yDiff = this.yDown - yUp;

            if ( Math.abs( xDiff ) > Math.abs( yDiff ) ) {/*most significant*/
                if ( xDiff > 0 ) {
                    if(xDiff > 180) {
                        this.handleSwipeLeft()    
                    }
                } else {
                    this.handleSwipeRight()
                }                       
            } else {
                if ( yDiff > 0 ) {
                    this.handleSwipeUp()
                } else { 
                    this.handleSwipeDown()
                }                                                                 
            }
            /* reset values */
            this.xDown = null;
            this.yDown = null;  
        },
        handleSwipeLeft() {},
        handleSwipeRight() {},
        handleSwipeUp() {},
        handleSwipeDown() {},
    },
    mounted(){
        document.addEventListener('touchstart', this.handleTouchStart, false);
        document.addEventListener('touchend', this.handleTouchEnd, false);
    },
    beforeDestroy() {
        document.removeEventListener('touchstart', this.handleTouchStart, false);
        document.removeEventListener('touchend', this.handleTouchEnd, false);
    }
}
</script>