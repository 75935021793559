<template>
    <div class="AdminToolTimeline">
        <div class="v-form-wrapper">
            หัวข้อ: 
            <el-input v-model="form.title" placeholder="ขั้นตอนการ Register"></el-input>
            คำอธิบาย: 
            <el-input v-model="form.description" placeholder="แสดงขั้นตอนการสมัครใช้งาน"></el-input>
            ขั้นตอน: 
            <div class="v-form-inline">
                <el-input v-model="stepText" placeholder="กรอกขั้นตอน เช่น เพิ่มเพื่อน"></el-input>
                <el-button @click="addStep(stepText)" type="primary" icon="el-icon-plus"></el-button>
            </div>
        </div>
        <div v-for="(step, key) of form.steps" :key="key">
            <div class="v-step-row">
                {{step}}
                <el-button 
                @click="removeStep(key)" 
                type="text" 
                icon="el-icon-delete">
                </el-button>
            </div>
        </div>
        <el-button @click="toRemove" icon="el-icon-close" class="content-remove-button" circle plain></el-button>
    </div>
</template>
<script>
export default {
    name: 'AdminToolTimeline',
    props: ['index', 'form'],
    data(){
        return {
            stepText: '',
        }
    },
    watch: {
        'form.steps': function(){
            this.$emit('stepChange')
        }
    },
    methods: {
        toRemove(){
            this.$alert(`ต้องการลบ?`, 'แจ้งเตือน', {
                type: 'warning',
                showCancelButton: true,
                confirmButtonText: 'ตกลง',
                cancelButtonText: 'ยกเลิก',
                customClass: 'custom-message-box',
                callback: action => {
                    if(action === 'confirm'){
                        this.remove()
                    }
                }
            })
        },
        remove(){
            this.$emit('remove', this.index)
        },
        addStep(text){
            if(!text){
                return
            }
            this.form.steps.push(text)
            this.clearStepText()
        },
        removeStep(index){
            this.form.steps.splice(index, 1)
        },
        clearStepText(){
            this.stepText = ''
        },
    },
}
</script>
<style scoped>
.AdminToolTimeline {
    position: relative;
    width: 400px;
    border: solid gray 1px;
    padding: 30px 10px 10px 10px;
    border-radius: 5px;
}
.content-remove-button{
    position: absolute;
    top: 4px;
    right: 4px;
}
.v-form-wrapper *{
    margin: 4px 0;
}
.v-step-row {
    margin: 2px 0 2px 4px;
}
.v-form-inline {
    display: flex;
}
.v-form-inline * {
    margin: 0 2px;
}
</style>