<script>

import ReconnectingWebSocket from 'reconnecting-websocket'
import store from '@/store'

export default {
    name: 'GameMixin',
    data() {
        return {
            gameSocket: null,
            gameSocketStatus: 'idle',
            gameReconnectSecond: 10,
        }
    },
    computed: {
        baccaratTableList: {
            get() {
                return store.state.game.baccaratTableList
            },
            set(value) {
                store.commit('game/updateBaccaratTableList', value)
            }
        },
        baccaratGameList: {
            get() {
                return store.state.game.baccaratGameList
            },
            set(value) {
                store.commit('game/updateBaccaratGameList', value)
            }
        },
    },
    methods: {
        tableListToArr(map) {
            const arr = []
            for(const key in map) {
                if(map[key].table_status) {
                    arr.push(map[key])
                }
            }
            return arr
        },
        baccaratInitHandle(data) {

            this.baccaratTableList = this.tableListToArr(data.data.baccarat_table_list)
            
            const baccaratGameList = data.data.baccarat_game_list.reverse()
            const sicboGameList = data.data.sicbo_game_list.reverse()
            const beanGameList = data.data.bean_game_list.reverse()
            const dragonTigerGameList = data.data.dragontiger_game_list.reverse()
            const baccarat2GameList = (data.data.baccarat2_game_list) ? data.data.baccarat2_game_list.reverse() : []
            const baccarat3GameList = (data.data.baccarat3_game_list) ? data.data.baccarat3_game_list.reverse() : []
            this.baccaratGameList = baccaratGameList.concat(sicboGameList, beanGameList, dragonTigerGameList, baccarat2GameList, baccarat3GameList)
        },
        updateTableList(newData) {
            if(!newData.table_status) {
                return
            }
            if(this.baccaratTableList.length > 0) {
                const i = this.baccaratTableList.findIndex((table) => {
                    return parseInt(table.table_id) === parseInt(newData.table_id)
                })
                if(i < 0) {
                    this.baccaratTableList.push(newData)
                } else {
                    store.commit('game/updateBaccaratTable', {
                        i: i,
                        data: newData,
                    })
                }
            }
        },
        updateGameList(newGame) {
            try {
                if(!newGame) {
                    return
                }
                if(this.baccaratGameList.length > 0) {
                    const i = this.baccaratGameList.findIndex((game) => {
                        return parseInt(game.gbs_id) === parseInt(newGame.gbs_id) &&
                            parseInt(game.table_id) === parseInt(newGame.table_id)
                    })
                    if(i < 0) {
                        this.baccaratGameList.push(newGame)
                    } else {
                        store.commit('game/updateBaccaratGame', {
                            i: i,
                            data: newGame,
                        })
                    }
                } else {
                    this.baccaratGameList.push(newGame)
                }
            }catch(err) {
                console.log('updateGameList err', err.message)
            }
        },
        onBaccaratTableUpdate(data) {
            const newData = data.data
            this.updateTableList(newData)
        },
        onBaccaratTableGameUpdate(table, game) {
            this.updateTableList(table)
            this.updateGameList(game)
        },
        gameSocketOpenHandle(){
            this.gameSocketStatus = 'open'
            if(this.gameSocket.readyState === 1){
                this.gameSocket.send(JSON.stringify({
                    type: 'subscribe',
                    list: ['baccarat'],
                }))
            }
        },
        gameMessageRecieve(event){

            try {
                const message_recieve = JSON.parse(event.data)
                if(message_recieve.type === 'baccarat') {
                    if(message_recieve.sub_type === 'init') {
                        this.baccaratInitHandle(message_recieve)
                    } else if (message_recieve.sub_type === 'table') {
                        this.onBaccaratTableUpdate(message_recieve)
                    } else if (message_recieve.sub_type === 'table_game') {
                        this.onBaccaratTableGameUpdate(message_recieve.data.table, message_recieve.data.game)
                    }
                }
            } catch(err) {
                console.log('gameMessageRecieve err', err.message)
            }
        },
        gameSocketCloseHandle(event){
            this.gameSocketStatus = 'close'
            console.log('Socket is closed. Reconnect will be attempted in 10 second', event.reason);
            setTimeout(() => {
                this.gameSocketInit()
            }, this.gameReconnectSecond*1000)
        },
        gameSocketInit(){
            this.gameSocketDestoy()
            this.gameSocket = new ReconnectingWebSocket(`${this.envDomains.VUE_APP_PES_WS_BASE_URL}`, this.token, {
                debug: false, 
                reconnectInterval: 3000
            })
            this.gameSocket.addEventListener('open', this.gameSocketOpenHandle)
            this.gameSocket.addEventListener('message', this.gameMessageRecieve)
            this.gameSocket.addEventListener('close', this.gameSocketCloseHandle)
        },
        gameSocketDestoy() {
            if(this.gameSocket){
                this.gameSocket.removeEventListener('open', this.gameSocketOpenHandle)
                this.gameSocket.removeEventListener('message', this.gameMessageRecieve)
                this.gameSocket.removeEventListener('close', this.gameSocketCloseHandle)
                this.gameSocket.close()
            }
        },
    },
    mounted() {
        this.gameSocketInit()
    },
    beforeDestroy() {
        this.gameSocketDestoy()
    }
}
</script>