<template>
    <div class="box-float-noti">
        <div @click="goBet(round.lotto_type)" v-show="round.isShow" v-for="round of roundArr" :key="round.id" class="box-mini-float">
            <div class="box-mini-img">
                <img :src="round.icon" alt="sunil" />
            </div>
            <div class="box-mini-time">
                <div class="label-box-mini-time">
                    <LottoShortCutAlarm 
                    :start="round.timeout" 
                    @timeout="refresh"
                    @countDown="handleCountDown($event, round)">
                    </LottoShortCutAlarm>
                </div>
            </div>
        </div>
<!--        <div class="box-mini-float">-->
<!--            <div class="box-mini-img"> <img src="@/assets/images/bg-topUp/lotto-p-lao.png" alt="sunil" /></div>-->
<!--            <div class="box-mini-time">-->
<!--                <div class="label-box-mini-time">-->
<!--                <span class="info-label-box-mini-time">-->
<!--                    <span>-->
<!--                        <span>15:52:34</span>-->
<!--                    </span>-->
<!--                </span>-->
<!--                    &lt;!&ndash;                <span class="info-label-box-mini-time"><span>15:52:34</span></span>&ndash;&gt;-->
<!--                </div>-->
<!--            </div>-->
<!--        </div>-->
<!--        <div class="box-mini-float">-->
<!--            <div class="box-mini-img"><img src="@/assets/images/bg-topUp/lotto-p-hanoi.png" alt="sunil" /></div>-->
<!--            <div class="box-mini-time">-->
<!--                <div class="label-box-mini-time">-->
<!--                <span class="info-label-box-mini-time">-->
<!--                    <span>-->
<!--                        <span>15:52:34</span>-->
<!--                    </span>-->
<!--                </span>-->
<!--                    &lt;!&ndash;                <span class="info-label-box-mini-time"><span>15:52:34</span></span>&ndash;&gt;-->
<!--                </div>-->
<!--            </div>-->

<!--        </div>-->
    </div>
</template>
<script>

import axiosWrapper from '@/axiosWrapper'
import {lotto_type_arr, LOTTO_SHORTCUT_MINUTE_TO_SHOW} from '@/components/lotto/Constant'
import LottoShortCutAlarm from './LottoShortcutAlarm'
import store from '@/store'
import LayoutMixin from '@/mixins/Layout'

const IMAGE_ICON_MAP = {
    'government': '/images/lotto/shortcut/lotto-p-thai.png',
    'hanoy': '/images/lotto/shortcut/lotto-p-hanoi.png',
    'lao': '/images/lotto/shortcut/lotto-p-lao.png',
    'malay': '/images/lotto/shortcut/lotto-p-malay.png',
    'yeekee': '/images/lotto/shortcut/lotto-p-yeeke.png',
    'hanoy_special': '/images/lotto/shortcut/lotto-p-hano-spachial.png',
    'hanoy_vip': '/images/lotto/shortcut/lotto-p-hano-vip.png',
}

export default {
    name: 'LottoShortcut',
    components: {
        LottoShortCutAlarm,
    },
    mixins: [
        LayoutMixin,
    ],
    data() {
        return {
            roundArr: [],
            pageState: 'focused',
        }
    },
    watch: {
        pageState(newVal) {
            if(newVal === 'focused') {
                this.refresh()
            }
        },
    },
    methods: {
        async loadData() {

            this.roundArr = []
            
            const res = await axiosWrapper({
                method: 'post',
                url: `${this.envDomains.VUE_APP_LOTTO_API}/api/round/get_latest_all`
            })
            if(res.status === 200) {
                for(const lotto_type of lotto_type_arr) {
                    const round = Object.assign(res.data[lotto_type], {icon: IMAGE_ICON_MAP[lotto_type]})
                    const isShow = this.isBeforeMinute(round)
                    Object.assign(round, {isShow})
                    this.roundArr.push(round)
                }
            }
        },
        isOpen(round) {
            const now = this.$moment()
            const timeout = this.$moment(round.timeout)
            return (round.status === 'open' && now.isSameOrBefore(timeout))
        },
        isBeforeMinute(round) {
            const timeout = this.$moment(round.timeout)
            const now = this.$moment()
            const diff = this.$moment.duration(timeout.diff(now)).as('minute')
            return diff > 0 && diff < LOTTO_SHORTCUT_MINUTE_TO_SHOW[round.lotto_type]
        },
        handleCountDown(minuteRemain, round) {
            round.isShow = minuteRemain > 0 && minuteRemain < LOTTO_SHORTCUT_MINUTE_TO_SHOW[round.lotto_type]
        },
        refresh() {
            this.loadData()
        },
        goBet(lotto_type){
            store.commit('lotto/updatePageBetQueryLottoType', lotto_type)
            store.commit('lotto/updateCurrentPage', 'bet')
            this.$router.push({name: 'LottoBet'})
        },
        onPageStateChange() {
            function getState() {
                if (document.visibilityState === 'hidden') {
                    return 'hidden';
                }
                if (document.hasFocus()) {
                    return 'focused';
                }
                return 'not_focused';
            }
            this.pageState = getState()
        }
    },
    mounted() {

        this.loadData()

        const eventArr = ['pageshow', 'focus', 'blur', 'visibilitychange', 'resume']

        eventArr.forEach((type) => {
            window.addEventListener(type, this.onPageStateChange, {capture: true})
        })
    },
    beforeDestroy() {

        const eventArr = ['pageshow', 'focus', 'blur', 'visibilitychange', 'resume']

        eventArr.forEach((type) => {
            window.removeEventListener(type, this.onPageStateChange, {capture: true})
        })
    }
}
</script>