<template>
    <modal class="betbox" name="bet-message" :height="'auto'" width="98%" :pivotY="0.2" :scrollable="true" :clickToClose="false"
           @opened="$emit('opened')"
           @closed="$emit('closed')"
           @before-open="beforeOpen">
        <div class="v-modal-content-wrapper flex-column">
            <div class="v-modal-body">
                <div style="font-size: 24px; margin-bottom: 8px; word-break: break-word;">{{bet_message}}</div>
                <div v-if="bet_messages.length > 0" style="font-size: 12px;">
                    <div style="margin-bottom: 4px;" v-for="message in bet_messages" :key="message">
                        {{message}}
                    </div>
                </div>
                <div v-show="bet_message_status === 'bet_success'" class="v-bet-detail">
                    <div v-if="bet_success_data">
                        #{{bet_success_data.gid}}
                    </div>
                    <div class="v-bet-wrapper" v-for="bet in bet_list" :key="bet.match_id">
                        <div class="v-header" :class="{'live': bet.is_live, 'nonlive': !bet.is_live}">
                            <div class="flex item-center-vertical inner">
                                <div style="font-size: 12px;">
                                    {{bet.league_name_en}}
                                </div>
                            </div>
                        </div>
                        <div class="v-combo-body" :class="{'live': bet.is_live, 'nonlive': !bet.is_live}" style="">
                            <div style="text-align: start;" class="color-blue">
                                <span :class="{ 'color-red': bet.mode_value > 0}">{{bet.match_home_name}}</span>
                                <span class="nbsp"></span><span style="color: black;">-vs-</span><span class="nbsp"></span>
                                <span :class="{ 'color-red': bet.mode_value < 0}">{{bet.match_away_name}}</span>
                            </div>
                            <div class="flex" style="text-align: start;">
                                <!--                            {{bet.mode | calModeName()}}<div v-if="bet.mode_value !== ''">&nbsp;:&nbsp;{{bet.mode_value | toFixed(2)}}</div>-->
                                {{bet.mode | calModeName()}}<div v-if="bet.mode_value !== ''">&nbsp;:&nbsp;{{displayModeValue(bet.mode, bet.mode_value, bet.favorite, bet.choose) | toFixed(2)}}</div>
                            </div>
                            <div style="text-align: start;">
                                <div class="flex">
                            <span class="color-blue" :class="{'color-red': bet.favorite === bet.choose}">
                                {{bet.choose | calChooseName(bet.match_home_name, bet.match_away_name, bet.mode)}}
                            </span>
                                    &nbsp;@{{bet.odds}}
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="flex" style="justify-content: space-between;">
                        <div>เงินเดิมพัน</div>
                        <div>{{amount | toFixed(2) | comma(2)}}</div>
                    </div>
                    <div class="flex" style="justify-content: space-between;">
                        <div>การชนะ</div>
                        <div>{{win_amount | toFixed(2) | comma(2)}}</div>
                    </div>
                </div>
            </div>
            <div class="v-modal-footer flex">
                <el-button @click="$emit('okay')">ตกลง</el-button>
            </div>
        </div>
    </modal>
</template>

<script>

    import store from '@/store';

    export default {
        name: "BetMessageModal",
        data(){
            return {
                bet_message_status: '',
                bet_message: '',
                bet_messages: [],
                bet_success_data: null,
                amount: 0,
                win_amount: 0,
            }
        },
        computed: {
            bet_list: function(){
                return store.state.football.bet_list;
            }
        },
        methods: {
            beforeOpen (e) {
                this.bet_message_status = e.params.bet_message_status;
                this.bet_message = e.params.bet_message;
                this.bet_messages = e.params.bet_messages;
                this.bet_success_data = e.params.bet_success_data;
                this.amount = e.params.amount;
                this.win_amount = e.params.win_amount;
            },
            displayModeValue: function(mode, mode_value, favorite, choose){
                if(mode === 'HDP'){

                    if(mode_value){

                        if(favorite === choose){
                            return Math.abs(mode_value)*-1;
                        }else{
                            return Math.abs(mode_value);
                        }
                    }else{
                        return 0;
                    }
                }else{
                    return mode_value;
                }
            }
        }
    }
</script>

<style scoped>

    .v-modal-content-wrapper {
        padding: 14px 18px;
        border: solid 2px black;
        overflow-y: auto;
        min-height: 180px;
    }

    .v-modal-body {
        word-break: break-all;
        flex: 1;
        overflow-y: auto;
    }

    .v-modal-footer {
        margin-top: 20px;
        height: 24px;
        justify-content: flex-end;
        align-items: flex-end;
    }

    .v-blink-blink {
        animation: v-blinker 1s linear infinite;
    }

    .v-bet-wrapper{
        margin: 5px 0;
    }

    .v-header {
        font-size: 12px;
        color: black;
    }

    .v-header.live {
        /*border-bottom: solid 1px #df7845;*/
        background-color: #ffa375;
    }

    .v-header.nonlive {
        /*border-bottom: solid 1px #b8d8fe;*/
        background-color: #c5dfff;
    }

    .v-combo-body {
        padding: 4px 30px 4px 4px;
        font-size: 0.87rem;
        color: black;
    }

    .v-combo-body.live {
        background-color: #ffddcc;
    }

    .v-combo-body.nonlive {
        background-color: #d6e8fe;
    }

</style>
<style>
    @media only screen
    and (min-device-width: 1300px)
    and (orientation: landscape){
        .betbox{
            /*left: 0!important;*/
            /*right: 0!important;*/
            /*margin: auto;*/
            /*width: 375px!important;*/
        }
        .betbox .v--modal-box{
            left: 0!important;
            right: 0!important;
            margin: auto;
            width: 375px!important;
            border-radius: 20px!important;
        }
        .betbox .v-modal-content-wrapper{
            border: 0;
        }
    }
</style>