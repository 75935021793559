<template>
    <div class="Loading">
        <div>
            กำลังโหลด
            <div class="spinner-border spinner-border-sm" role="status">
                <span class="sr-only">Loading...</span>
            </div>
        </div>
    </div>
</template>

<script>
    export default {
        name: "Loading"
    }
</script>

<style scoped>
    .Loading {
        width: 100%;
        min-height: 100px;
        display: flex;
        justify-content: center;
        align-items: center;
    }
</style>